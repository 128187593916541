<ng-container *ngIf="{
  searchOptions: searchOptions$ | async,
  pageRowsCount: pageRowsCount$ | async,
  totalRowsCount : totalRowsCount$ | async} as data">

  <!-- Summary + page size selector -->
  <div class="results-pane-title" *ngIf="data.totalRowsCount > itemsPerPageOptions[0]">
    <div>Showing {{ data.pageRowsCount }} of {{ data.totalRowsCount }} investors</div>
    <mat-form-field appearance="outline" class="remove-error-place">
      <mat-select [formControl]="perPageControl">
        <mat-option *ngFor="let amountPerPage of itemsPerPageOptions" [value]="amountPerPage">{{amountPerPage}} per page
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <table *ngIf="data.searchOptions" mat-table [dataSource]="dataSource" [trackBy]="trackByFunction" (matSortChange)="sortData($event)"
         [matSortDirection]="data.searchOptions.sortOrder" [matSortActive]="data.searchOptions.orderBy"
         class="gray-theme mat-elevation-z0" matSort matSortDisableClear
  >

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="gray-theme checkbox-col-header">
        <mat-checkbox (change)="$event ? masterToggle() : null" [indeterminate]="selection.hasValue() && !(isAllSelected$ | async)"
                      [checked]="selection.hasValue() && (isAllSelected$ | async)" [disabled]="data.pageRowsCount === 0" color="primary">
        </mat-checkbox>

      </th>
      <td mat-cell class="gray-theme checkbox-content" *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? rowCheckToggle(row) : null" [checked]="isRowChecked(row)"
                      color="accent"> </mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="name-col-header">Name</th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive name-content">
        {{ element.investingEntity.name }}
      </td>
    </ng-container>

    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="amount-col-header">Amount</th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive amount-content">
        <span *ngIf="element.lpInterestLevel === null || element.lpInterestLevel === lpInterest.NotInterested "> - </span>
        <span *ngIf="element.lpInterestLevel === lpInterest.Interested || element.lpInterestLevel === lpInterest.WouldLikeToLearnMore ">
          {{element?.currency?.symbol}}{{element.lpInterestAmount | terraNumber}}
        </span>
      </td>
    </ng-container>

    <!-- Last viewed Column -->
    <ng-container matColumnDef="last-viewed">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="last-viewed-col-header">Last viewed</th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive last-viewed-content">
        {{ element.marketingDeckLpLastDownloadTimestamp | date }}
      </td>
    </ng-container>

    <!-- Reply Column -->
    <ng-container matColumnDef="reply">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="reply-col-header">Reply</th>
      <td mat-cell *matCellDef="let element" class="reply-content">
        <span *ngIf="element.lpInterestLevel === null"> - </span>
        <span *ngIf="element.lpInterestLevel !== null">{{ element.lpInterestLevel | enumString: lpInterest }}</span>
      </td>
    </ng-container>

    <!-- Message Column -->
    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef class="message-col-header">Message</th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive message-content"
          [matTooltip]="element.lpInterestNote?.length > 20 ? element.lpInterestNote : ''" matTooltipClass="terra-tooltip-compact" >
        <span *ngIf="element.lpInterestNote?.length <= 20">{{ element.lpInterestNote }}</span>
        <span *ngIf="element.lpInterestNote?.length > 20">{{ element.lpInterestNote.substring(0,20)}}...</span>
      </td>
    </ng-container>

    <!-- Next step Column -->
    <ng-container matColumnDef="next-step">
      <th mat-header-cell *matHeaderCellDef class="next-step-col-header">Next step</th>
      <td mat-cell *matCellDef="let element" class="row-cta">

        <ng-container *ngIf="element.status === investmentStatus.Potential">
          <!-- interested in offering deck or added as regular investor -->
          <span *ngIf="element.lpInterestLevel === lpInterest.Interested || !element.isCreatedByBulkOfferingDeck">
            <!-- added as regular investor -->
            <mat-icon *ngIf="!element.isCreatedByBulkOfferingDeck" class="material-icons icon notranslate">check_circle</mat-icon>
            <!-- not added yet as regular investor -->
            <mat-icon *ngIf="element.isCreatedByBulkOfferingDeck" class="material-icons icon notranslate">person_add</mat-icon>
            <a (click)="updateStatus(element, investmentStatus.SoftCircled)">Mark as soft circled</a>
          </span>

          <!-- want to learn more or not interested -->
          <span *ngIf="element.lpInterestLevel !== lpInterest.Interested && element.isCreatedByBulkOfferingDeck">
            <mat-icon class="material-icons icon notranslate">person_add</mat-icon>
            <a (click)="updateStatus(element, investmentStatus.Potential)">Add investor</a>
          </span>
        </ng-container>

        <ng-container *ngIf="element.status !== investmentStatus.Potential">
          <span>
            <mat-icon class="material-icons icon notranslate">check_circle</mat-icon>
            <span>Completed</span>
          </span>
        </ng-container>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.selected-row]="isRowChecked(row)" [class.hide]="isLoading$ | async"
        (mouseover)="row.hover = true" (mouseout)="row.hover = false" class="value-row"></tr>

  </table>

  <terra-loader *ngIf="isLoading$ | async" class="loader"></terra-loader>

  <div class="empty-list-message" *ngIf="!(isLoading$ | async) && data.totalRowsCount == 0">
    <p>Investors not found</p>
  </div>

  <mat-paginator (page)="pageChange($event)" [length]="data.totalRowsCount" [hidePageSize]="true" [pageSize]="data.searchOptions.pageSize"
                 [pageIndex]="data.searchOptions.pageNumber" *ngIf="data.totalRowsCount > perPageControl.value"></mat-paginator>

</ng-container>


