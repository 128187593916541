<ng-template #loader>
  <terra-loader class="page-loader"></terra-loader>
</ng-template>

<ng-container *ngIf="fundraisingDetails$ | async as fundraisingDetails; else loader">
  <div class="holding-down-page-stripe" *ngIf="(fund$ | async).isPrivate">
    <mat-icon svgIcon="holding_down" class="notranslate holding-down-icon"></mat-icon>
    <span> Fund is down. When you’re ready to put it back up, go to the <a class="light link" [routerLink]="fundPage$ | async"> fund page</a> and change it.
      <a class="light link" (click)="fundDownInfo()"> Learn more</a></span>
  </div>

  <div class="breadcrumbs container" *ngIf="fund$ | async as fund">
    <a [routerLink]="fundPage$ | async"><span class="lo_sensitive">{{ fund.name }}</span> > Contributions > </a>
    <span class="lo_sensitive current">{{ fundraisingDetails.name }} for {{ fund.name }}</span>
  </div>

  <!-- Fund page header -->
  <div class="fund-title-area container">
    <h1 [ngClass]="{'status-completed': fundraisingComplete(fundraisingDetails)}" class="lo_sensitive">{{fundraisingDetails.name}}</h1>
    <span class="status" *ngIf="fundraisingComplete(fundraisingDetails)">{{fundraisingDetails.status | enumString: FundraisingStatus}}
    </span>

    <div class="actions">
      <terra-export-fundraisings-menu [fundraisingId]="fundraisingDetails.id" class="export-menu" *ngIf="fundraisingComplete(fundraisingDetails)"></terra-export-fundraisings-menu>

      <button [disabled]="fundraisingDetails.status === FundraisingStatus.Canceled" (click)="editFundraising()" mat-stroked-button class="edit-button" color="primary">
        <mat-icon class="notranslate">edit</mat-icon>
        Edit Contribution
      </button>
    </div>
  </div>

  <!-- Move to Completed -->
  <div *ngIf="showFinishedFundraisingNotification(fundraisingDetails); else actionsNeeded" id="finished-fundraising"
       class="container">
    <img src="assets/images/icons/change_status icon.svg"/>
    <span>
      <strong>Done fundraising? </strong>
      <a class="change-status light link" (click)="showMoveToCompleted()">Mark it as Complete</a>
    </span>
  </div>

  <ng-template #actionsNeeded>
    <div *ngIf="showFinishedFundraisingButActionsNeededNotification(fundraisingDetails)" id="finished-fundraising"
         class="container">
      <img src="assets/images/icons/change_status icon.svg"/>
      <span>
        <strong>Done fundraising? </strong>
        To mark it as Complete, make sure all investors are marked as either Potential, Invested or Declined.
      </span>
    </div>
  </ng-template>

  <!-- Fundraising information -->
  <section class="container information contributions" *ngIf="fund$ | async as fund" [ngClass]="{'status-completed': hideCreCard(fundraisingDetails) | async}">

    <!-- over subscription -->
    <terra-raised-amount-warning 
    [investments]="fundraisingDetails.investments" 
    [fundraisingTargetAmount]="fundraisingDetails.fundraisingTargetAmount"
    [symbol]="fundraisingDetails.fundraisingTargetCurrency.symbol"></terra-raised-amount-warning>

    <!-- Images -->
    <div class="terra-card images-card">
      <terra-holding-images [location]="fund.location" [holdingFiles]="fund.attachments"></terra-holding-images>
    </div>

    <!-- Cre Bank Card-->
    <div class="cre-bank-account terra-card" *ngIf="!(hideCreCard(fundraisingDetails) | async)">
      <terra-holding-cre-bank-account></terra-holding-cre-bank-account>
    </div>

    <!-- Fund details box -->
    <div class="terra-card fund-details">
      <div class="card-header">
        <h2>Contribution info</h2>
      </div>
      <ul class="basic-info-list">
        <!-- Bank account -->
        <li class="basic-info-item"
            [ngClass]="{ 'missing-bank-account': !getHoldingBankAccount(fundraisingDetails) }"
            *ngIf="!((isContributionInReadOnlyMode$ | async) && !getHoldingBankAccount(fundraisingDetails))">
          <span *ngIf="!getHoldingBankAccount(fundraisingDetails)" class="outer-circle">
            <mat-icon class="notranslate dollar-sign">attach_money</mat-icon>
          </span>
          <mat-icon *ngIf="getHoldingBankAccount(fundraisingDetails)" class="notranslate dollar-sign">attach_money
          </mat-icon>
          <label>Bank account: </label>
          <!-- When no bank account -->
          <span class="value" *ngIf="!(showBankInfo$ | async)">
            <span>*****</span>
          </span>
          <span *ngIf="(showBankInfo$ | async) && !getHoldingBankAccount(fundraisingDetails)">
            <a href="javascript:void(0)" (click)="editFundraising()">
              Missing
            </a>
          </span>
          <span *ngIf="(showBankInfo$ | async) && getHoldingBankAccount(fundraisingDetails)" class="value lo_sensitive"
                [matTooltip]="(getHoldingBankAccount(fundraisingDetails) | bankAccountDisplayName : true).length > 12 ? (getHoldingBankAccount(fundraisingDetails) | bankAccountDisplayName : true) : null"
                matTooltipPosition="right" matTooltipClass="terra-tooltip-compact"
          >
            {{getHoldingBankAccount(fundraisingDetails) | bankAccountDisplayName : true | truncate: 12}}
          </span>
        </li>
        <!-- Closing date -->
        <li class="basic-info-item">
          <mat-icon class="notranslate">date_range</mat-icon>
          <label>Closing date: </label>
          {{(fundraisingDetails.finalClosingDate ? fundraisingDetails.finalClosingDate : fundraisingDetails.estimatedClosingDate) | utcDate}}
        </li>
      </ul>
    </div>

    <!-- Bulk Marketing Deck -->
    <div class="bulk-offering-deck terra-card">
      <terra-bulk-offering-deck [hasCreBank]="!(hideCreCard(fundraisingDetails) | async)" [fundraisingService]="fundFundraisingService"></terra-bulk-offering-deck>
    </div>
  </section>

  <!-- Investors -->
  <section id="investors" class="container">

    <terra-fundraising-summary></terra-fundraising-summary>

    <div class="terra-holding-card terra-card-hover-effect add-investors-from-list-block" *ngIf="isCompletedContribution$ | async">
      <terra-add-investors-from-list-block title="Add Investor From Contact List"></terra-add-investors-from-list-block>
    </div>

    <terra-fundraising-nav [isReadOnlyContribution$]="isContributionInReadOnlyMode$" [isCompletedDistribution]="isCompletedContribution$ | async"></terra-fundraising-nav>
  </section>
</ng-container>
