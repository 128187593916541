<ng-container *ngIf="fund$ | async as fund">
  <form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" (ngSubmit)="stepSubmit()"
    class="form-grid terra-error-style">
    <h3 class="section-title">{{ fund.investmentStrategyType | enumString: FundInvestmentStrategyType }} investment
      report</h3>

    <!-- Report period -->
    <div class="terra-form-field-wrapper">
      <label for="ddlReportPeriod" class="text-input-label">Report period</label>
      <mat-form-field appearance="outline">
        <mat-select id="ddlReportPeriod" placeholder="Select" formControlName="reportPeriod">
          <mat-option *ngFor="let period of ReportPeriod.listAll()" [value]="period">
            {{ period | enumString: ReportPeriod }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Quarter and Year -->
    <div class="terra-form-field-wrapper quarter-and-year"
      *ngIf="pageForm.get('reportPeriod').value === ReportPeriod.Quarterly">
      <div class="quarter-field">
        <label for="ddlQuarter" class="text-input-label">Select quarter</label>
        <mat-form-field appearance="outline">
          <mat-select id="ddlQuarter" placeholder="Select" formControlName="quarter">
            <mat-option *ngFor="let quarter of [1, 2, 3, 4]" [value]="quarter">Q{{ quarter }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="year-field">
        <label for="ddlYear" class="text-input-label">Select year</label>
        <mat-form-field appearance="outline">
          <mat-select id="ddlYear" placeholder="Select" formControlName="year">
            <mat-option *ngFor="let year of yearsList" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Start and End dates of the period  -->
    <ng-container *ngIf="pageForm.get('reportPeriod').value === ReportPeriod.Custom">
      <!-- Start date -->
      <div class="terra-form-field-wrapper new-column">
        <label for="txtPeriodStartDate" class="text-input-label">First day included in report</label>
        <mat-form-field appearance="outline">
          <input matInput [min]="periodMinDate" [max]="periodMaxDate" id="txtPeriodStartDate"
            [matDatepicker]="periodStartDatePicker" formControlName="periodStartDate"
            (focus)="periodStartDatePicker.open()" class="lo_sensitive" />
          <mat-datepicker-toggle matSuffix [for]="periodStartDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #periodStartDatePicker></mat-datepicker>
          <mat-error *ngIf="periodStartDate.hasError('required')"> Required </mat-error>
          <mat-error *ngIf="periodStartDate.hasError('matDatepickerMin')">Please select a date not before
            {{ periodMinDate | date }}
          </mat-error>
          <mat-error *ngIf="periodStartDate.hasError('matDatepickerMax')">Please select a date not after
            {{ periodMaxDate | date }}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- End date -->
      <div class="terra-form-field-wrapper">
        <label for="txtPeriodEndDate" class="text-input-label">Last day included in period</label>
        <mat-form-field appearance="outline">
          <input matInput [min]="periodStartDate.value" [max]="periodMaxDate" id="txtPeriodEndDate"
            [matDatepicker]="periodEndDatePicker" formControlName="periodEndDate" (focus)="periodEndDatePicker.open()"
            class="lo_sensitive" />
          <mat-datepicker-toggle matSuffix [for]="periodEndDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #periodEndDatePicker></mat-datepicker>
          <mat-error *ngIf="periodEndDate.hasError('required')"> Required </mat-error>
          <mat-error *ngIf="periodEndDate.hasError('matDatepickerMin')">The last day must be after the first day
          </mat-error>
          <mat-error *ngIf="periodEndDate.hasError('matDatepickerMax')">Please select a date not after
            {{ periodMaxDate | date }}
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <h3 class="section-title">
      Periodic information (optional)
    </h3>
    <div class="info-message full-width">
      Any optional fields left empty will not be visible to investors in the published report
    </div>

    <!-- Gross potential Rent  -->
    <div class="terra-form-field-wrapper">
      <label for="txtGrossPotentialRent" class="text-input-label">Gross potential Rent</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtGrossPotentialRent" terraFormattedNumber formControlName="grossPotentialRent"
          class="lo_sensitive" />
        <span matSuffix>{{ fund.initialCurrency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Vacancy loss  -->
    <div class="terra-form-field-wrapper">
      <label for="txtVacancyLoss" class="text-input-label">Vacancy loss</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtVacancyLoss" terraFormattedNumber formControlName="vacancyLoss" class="lo_sensitive" />
        <span matSuffix>{{ fund.initialCurrency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Delinquent Rent  -->
    <div class="terra-form-field-wrapper">
      <label for="txtDelinquentRent" class="text-input-label">Delinquent rent</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtDelinquentRent" terraFormattedNumber formControlName="delinquentRent"
          class="lo_sensitive" />
        <span matSuffix>{{ fund.initialCurrency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Income  -->
    <div class="terra-form-field-wrapper">
      <label for="txtIncome" class="text-input-label">Income</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtIncome" terraFormattedNumber formControlName="income" class="lo_sensitive" />
        <span matSuffix>{{ fund.initialCurrency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- expenses  -->
    <div class="terra-form-field-wrapper">
      <label for="txtExpenses" class="text-input-label">Expenses</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtExpenses" terraFormattedNumber formControlName="expenses" class="lo_sensitive" />
        <span matSuffix>{{ fund.initialCurrency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Asset management fees -->
    <div class="terra-form-field-wrapper">
      <label for="txtAssetManagementFees" class="text-input-label">Asset management fees</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtAssetManagementFees" terraFormattedNumber formControlName="assetManagementFees"
          class="lo_sensitive" />
        <span matSuffix>{{ fund.initialCurrency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Net operating income (NOI) -->
    <div class="terra-form-field-wrapper">
      <label for="txtNetOperatingIncome" class="text-input-label">Net operating income (NOI)</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtNetOperatingIncome" terraFormattedNumber formControlName="netOperatingIncome"
          class="lo_sensitive" />
        <span matSuffix>{{ fund.initialCurrency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Debt Service -->
    <div class="terra-form-field-wrapper">
      <label for="txtDebtService" class="text-input-label">Debt Service</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtDebtService" terraFormattedNumber formControlName="debtService" class="lo_sensitive" />
        <span matSuffix>{{ fund.initialCurrency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Capital Expenditures -->
    <div class="terra-form-field-wrapper">
      <label for="txtCapitalExpenditures" class="text-input-label">Capital Expenditures</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtCapitalExpenditures" terraFormattedNumber formControlName="capitalExpenditures" class="lo_sensitive" />
        <span matSuffix>{{ fund.initialCurrency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Net Income (was net revenue)  -->
    <div class="terra-form-field-wrapper">
      <label for="txtNetIncome" class="text-input-label">Net income</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtNetIncome" terraFormattedNumber formControlName="netIncome" class="lo_sensitive" />
        <span matSuffix>{{ fund.initialCurrency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Vacancy rate -->
    <div class="terra-form-field-wrapper">
      <label for="txtVacancyRate" class="text-input-label">Vacancy rate</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtVacancyRate" terraFormattedNumber formControlName="vacancyRate" class="lo_sensitive" />
        <span matSuffix>%</span>
      </mat-form-field>
    </div>

    <!-- Delinquent Rate -->
    <div class="terra-form-field-wrapper">
      <label for="txtDelinquentRate" class="text-input-label">Delinquent rate</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtDelinquentRate" terraFormattedNumber formControlName="delinquentRate"
          class="lo_sensitive" />
        <span matSuffix>%</span>
      </mat-form-field>
    </div>

    <!-- Operating expense -->
    <div class="terra-form-field-wrapper">
      <label for="txtOperatingExpense" class="text-input-label">Operating expense</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtOperatingExpense" terraFormattedNumber formControlName="operatingExpense"
          class="lo_sensitive" />
        <span matSuffix>%</span>
      </mat-form-field>
    </div>

    <!-- Summary -->
    <div class="terra-form-field-wrapper full-width">
      <label for="txtSummary" class="text-input-label">Summary</label>
      <p class="field-description">
        Share the highlights of the report period and any important news with your investors here
      </p>
      <quill-editor id="txtSummary" formControlName="summary" format="html" class="lo_sensitive" placeholder="" />

      <mat-error *ngIf="pageForm.get('summary').hasError('maxlength')"> You have exceeded the maximum length of {{
        pageForm.get('summary').getError('maxlength').requiredLength | terraNumber
      }} characters </mat-error>
    </div>

    <!-- Capital information -->
    <div class="terra-form-field-wrapper full-width">
      <label for="txtCapitalInformation" class="text-input-label">Capital information</label>
      <p class="field-description">
        <!-- field description... -->
      </p>
      <quill-editor id="txtCapitalInformation" formControlName="capitalInformation" format="html" class="lo_sensitive" placeholder="" />

      <mat-error *ngIf="pageForm.get('capitalInformation').hasError('maxlength')"> You have exceeded the maximum length
        of {{
        pageForm.get('capitalInformation').getError('maxlength').requiredLength | terraNumber
      }} characters </mat-error>
    </div>

    <!-- Acquisitions & dispositions -->
    <div class="terra-form-field-wrapper full-width">
      <label for="txtAcquisitionsDispositions" class="text-input-label">Acquisitions & dispositions</label>
      <p class="field-description">
        <!-- field description... -->
      </p>
      <quill-editor id="txtAcquisitionsDispositions" formControlName="acquisitionsDispositions" format="html" class="lo_sensitive" placeholder="" />

      <mat-error *ngIf="pageForm.get('acquisitionsDispositions').hasError('maxlength')"> You have exceeded the maximum
        length of {{
        pageForm.get('acquisitionsDispositions').getError('maxlength').requiredLength | terraNumber
      }} characters</mat-error>
    </div>

    <!-- Portfolio (Asset) updates -->
    <div class="terra-form-field-wrapper full-width">
      <label for="txtHoldingUpdates" class="text-input-label">Portfolio updates</label>
      <p class="field-description">
        <!-- field description... -->
      </p>
      <quill-editor id="txtHoldingUpdates" formControlName="holdingUpdates" format="html" class="lo_sensitive" placeholder="" />

      <mat-error *ngIf="pageForm.get('holdingUpdates').hasError('maxlength')"> You have exceeded the maximum
        length of {{
        pageForm.get('holdingUpdates').getError('maxlength').requiredLength | terraNumber
      }} characters</mat-error>
    </div>

    <!-- Administrative updates -->
    <div class="terra-form-field-wrapper full-width">
      <label for="txtAdministrativeUpdates" class="text-input-label">Administrative updates</label>
      <p class="field-description">
        <!-- field description... -->
      </p>
      <quill-editor id="txtAdministrativeUpdates" formControlName="administrativeUpdates" format="html" class="lo_sensitive" placeholder="" />

      <mat-error *ngIf="pageForm.get('administrativeUpdates').hasError('maxlength')"> You have exceeded the maximum
        length of {{
        pageForm.get('administrativeUpdates').getError('maxlength').requiredLength | terraNumber
      }} characters</mat-error>
    </div>

    <h3 class="section-title">Uploads (optional)</h3>

    <terra-file-uploader no-type-select formControlName="reportPdf"
      [supportedExtensions]="reportUploaderSupportedExtensions"
      [fileTypeNotSupported]="isReportDocumentFileNotSupported" [required]="false"><label class="text-input-label"
        #reportDocument>Upload
        your own report</label>
    </terra-file-uploader>
    <p class="file-uploader-hint new-column">File can be PDF or PPT/PPTX (50 MB max file size)</p>

    <h3 class="attachments-title full-width">Attach additional reports and media files</h3>
        
      <div class="report-import-row full-width mb-4">
        <div class="container-box" >
          <terra-uploader-block formControlName="fileattachments"
            class="dashed-box h-full"
            [ngClass]="{'active': uploader.isDragover}"
            [multiple]="true"
            #uploader>      
            <div class="flex flex-column items-center  gap-1">
              <mat-icon class="notranslate help-icon w-2rem h-2rem upload-icon m-auto material-symbols-rounded">arrow_circle_up</mat-icon>
                <div class="link-and-hints">
                  <span class="link">Upload from your computer</span>
                  <span class="hint">Supports PDF, PNG, Audio/Video and any other formats</span>
                </div>
            </div>
          </terra-uploader-block>
        </div>
        
        @if (rentManagerIntegrationStatus() !== IntegrationStatus.Off) {
          <div class="container-box">
            <div class="new-badge">NEW!</div>
            @if (rentManagerIntegrationStatus() === IntegrationStatus.On) {
              <div class="dashed-box" (click)="openNav()" >
                <div class="rent-manager-logo upload-icon">
                <img src="/assets/images/logo/rent_manager_logo_full.png  " alt="Rent Manager" width="160px"/>
                </div>
                <span class="link">Import from Rent Manager</span>           
              </div>
            } @else if (rentManagerIntegrationStatus() === IntegrationStatus.Displayed) {
              <div class="dashed-box" (click)="showIntegrationRequestModal()">
                <div class="rent-manager-logo upload-icon">
                <img src="/assets/images/logo/rent_manager_logo_full.png" alt="Rent Manager" width="160px"/>
                </div>
                <span class="link">Import from Rent Manager</span>         
              </div>
            }
          </div>
        }
      </div>

    <div class="flex flex-column gap-2 full-width mb-4">
      @if (uploader.progress) {
      <div class="progress-indicator">
        <div class="progress-title">
          <div>Processing</div>
          <div>{{filesStatusTitle}}</div> 
        </div>
        <mat-progress-bar color="secondary" mode="determinate" [value]="uploader.progress" />
      </div>
    }

    <table class="gray-theme mat-elevation-z0">
      @for (item of (pageForm.get('fileattachments')?.value || []); track $index) {
      <tr >
        <td>
         <button mat-button class="preview-button" aria-label="Preview File" type="button" (click)="openPreview(item)" [disableRipple]="true">
          <div class="file-label">
              <div class="file_type">
                <mat-icon svgIcon="any_doc" />
                @if (item.fileType === 3) {
                  <img src="assets/images/logo/rent_manager_logo.png" class="file_type_logo" height="16px" alt="Rent Manager logo"/>
                }
              </div>
              {{item.title | truncate : 35}}
          </div>
        </button>
        </td>
        <td>
          {{item.sizeB | fileSize}}
        </td>
        <td >
          <div class="file-progress-indicator uploading-status">
          @if(item?.progress && item?.progress !== 100) {
            <mat-progress-spinner
            [diameter]="20"
            [mode]="'determinate'"
            [value]="item.progress" />
              Uploading...
          }
          @if(item?.id || item?.progress == 100) {
              <mat-icon class="notranslate">check_circle</mat-icon>
              Uploaded
          }
        </div>
        </td>
        <td style="text-align: right;">
          <button type="button" mat-icon-button color="primary" #topMenuTrigger="matMenuTrigger"  [disabled]="!item.id && !item?.progress" 
          [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{item:item}"  class="more-actions-toggle">
            <mat-icon class="notranslate">more_vert</mat-icon>
          </button>
        </td>
        </tr>
      }
    </table>
    </div>

    <mat-menu #actionsMenu="matMenu">
      <ng-template matMenuContent let-element="item">
        <!-- Rename file -->
         <!-- TODO: ENABLE when API is ready -->
        <!-- <button mat-menu-item (click)="rename(element)" *ngIf="selectedItems.length === 1">
          <mat-icon class="notranslate">edit</mat-icon><span>Rename</span>
        </button> -->
        <!-- Delete files -->
        <button mat-menu-item (click)="uploader.removeFile(element)">
          <mat-icon class="notranslate">delete</mat-icon><span>Delete</span>
        </button>
      </ng-template>
    </mat-menu>
  
  </form>
  <section class="footer">
    <!-- Error message -->
    <div *ngIf="isSubmitted" class="error-section">
      <p *ngIf="!pageForm.valid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
    <div class="form-actions">
      <a (click)="cancel(fund)" class="cancel-button">Cancel</a>
      <button type="submit" form="stepForm" mat-flat-button color="primary" class="page-cta">Next</button>
    </div>
  </section>
</ng-container>
