<!-- Loader -->
<terra-loader *ngIf="(isLoading$ | async)" class="page-loader"></terra-loader>

<ng-container *ngIf="holding$ | async as holding">
  <!-- holding is down -->
  <div class="holding-down-page-stripe" *ngIf="holding.isPrivate">
    <mat-icon svgIcon="holding_down" class="notranslate holding-down-icon"></mat-icon>
    <span> {{discriminatorStr | titlecase}} is down. When you’re ready to put it back up, go to the
      <a class="light link" [routerLink]="contributionsRoute">
        {{discriminatorStr | lowercase}} page</a> and change it.
      <a class="light link" (click)="holdingDownInfo()"> Learn more</a></span>
  </div>

  <!-- Breadcrumbs -->
  <div class="container-800">
    <div class="breadcrumbs">
      <a [routerLink]="contributionsRoute">
        <span class="lo_sensitive">{{ holding.name }}</span> > Contributions >
      </a>
      <Span class="current">Import History</Span>
    </div>
  </div>

  <div *ngIf="!(isLoading$ | async)" class="container-800">
    <h1>Import Contribution History</h1>
    <p><cite>Please fill in the basic details for each contribution you wish to add.</cite></p>
    <div *ngIf="contributionsDetails">
      <div class="contribution-list-item mat-expansion-panel"
           *ngFor="let details of contributionsDetails;let i = index">
        <terra-contribution-details [details]="details"></terra-contribution-details>
        <button [ngClass]="{'hidden':contributionsDetails.length === 1}" class="remove-button" mat-icon-button
                (click)="removeDetail(i)">
          <span class="material-icons">remove_circle_outline</span>
        </button>
      </div>
    </div>
    <div class="actions">
      <button class="add-contribution-button" mat-button (click)="addContribution()">
        <span class="material-icons notranslate">add_circle_outline</span>Add Contribution
      </button>
      <button class="import-contribution-button" mat-flat-button color="primary" (click)="importContribution()">
        <mat-icon class="notranslate">add</mat-icon>
        Import Contribution History
      </button>
    </div>
  </div>
</ng-container>
