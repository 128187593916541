import {Observable} from 'rxjs';

import {FundraisingReqRes} from '../../shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {GpFundReqRes} from '../GpFundReqRes.model';
import {EditFundTabNumber} from '../components/edit-fund-dialog/EditFundStepBaseAndInterface';
import {FundAndFundraisingReqRes} from '../components/create-fund/FundAndFundraisingRequest';
import {FundraisingTableItemResponse} from '../../shared/holding/fundraising/fundraisings-tab/FundraisingTableItemResponse.model';

export interface GpFundServiceInterface {
  allInvestmentsAreDone(fundraisingDetails: FundraisingReqRes): boolean;
  deleteFund();
  updateFund(model: FundAndFundraisingReqRes, skipNotification: boolean): Observable<FundAndFundraisingReqRes>;
  moveFundToUnderManagement(model: FundAndFundraisingReqRes): Observable<FundAndFundraisingReqRes>;
  moveFundraisingToCompleted(model: FundAndFundraisingReqRes): Observable<FundAndFundraisingReqRes>;
}

export class GpFundDialogContext {
  // This is used in Edit asset only
  public initialTabNumber: EditFundTabNumber;
  public scrollToId: string;

  constructor(
    public gpFundService: GpFundServiceInterface,
    public fundDetails: GpFundReqRes,
    public fundraisingDetails: FundraisingReqRes,
    public fundraisings: FundraisingTableItemResponse[]
  ) { }
}
