import {ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {filter, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, withLatestFrom} from 'rxjs';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

import {GpHoldingService} from '../../gp-holding.service';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {RoutingService} from '../../../../../services/routing.service';
import AccountType from '../../../../../shared/enums/AccountType.enum';
import {UserService} from '../../../../../services/shared/user.service';
import {FinancialPermissionLevel} from '../../../../../permission/enums/financialPermissionLevel.enum';
import {NoPermissionAction} from 'src/app/shared/directives/check-permissions.directive';
import PermissionLevel from 'src/app/permission/enums/permissionLevel.enum';
import {UnitApplicationParentType} from 'src/app/shared/enums/unit-bank-account/UnitApplicationParentType.enum';
import {UnitCreateBankAccountParams} from 'src/app/shared/models/unit-bank-account/UnitCreateBankAccountParams.model';
import {UnitCreateBankAccountComponent} from 'src/app/shared/components/unit/unit-create-bank-account/unit-create-bank-account.component';
import UnitApplicationStatus from 'src/app/shared/enums/unit-bank-account/UnitApplicationStatus.enum';
import {UnitBankAccountService} from 'src/app/shared/components/unit/unit-bank-account/unit-bank-account.service';
import {PlaidService} from 'src/app/services/shared/plaid.service';
import {PlaidDataService} from 'src/app/services/shared/plaid-data.service';
import {LinkedBankAccountService} from 'src/app/dashboard/bankAccounts/linked-bank-account.service';
import {BankAccountProvider} from 'src/app/dashboard/bankAccounts/enums/bankAccountProvider.enum';
import {BankAccountTableRowDto} from 'src/app/dashboard/bankAccounts/models/externalClientBankAccountDto.model';
import {BankAccountsMsService} from 'src/app/dashboard/bankAccounts/bank-accounts-ms.service';
import {PermissionService} from '../../../../../permission/permission.service';

@Component({
  selector: 'terra-holding-cre-bank-account',
  templateUrl: './holding-cre-bank-account.component.html',
  styleUrls: ['./holding-cre-bank-account.component.scss'],
  providers: [UnitBankAccountService, PlaidService, PlaidDataService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoldingCreBankAccountComponent extends OnDestroyMixin implements OnInit {
  // enums
  HoldingDiscriminator = HoldingDiscriminator;
  UnitApplicationStatus = UnitApplicationStatus;
  CreAccountType = AccountType;
  BankAccountProvider = BankAccountProvider;

  holdingId: number = null;
  private accountProviderToRouteProvider: Map<BankAccountProvider, (account: BankAccountTableRowDto) => string | null> = new Map([
    [BankAccountProvider.UnitAccount, (account) => this.routingService.creBankAccountPage(account.id)],
    [BankAccountProvider.ExternalAccount, (account) => this.routingService.externalBankAccountPage(account.id)],
    [BankAccountProvider.ManualAccount, (_) => null]
  ]);

  isLoading$ = new BehaviorSubject<boolean>(false);
  holding$ = this.holdingService.holding$;

  creApplication$ = this.holding$.pipe(
    untilComponentDestroyed(this),
    filter(holding => holding.unitApplications?.length > 0),
    map(holding => {
      this.holdingId = holding.id;
      return holding.unitApplications[0];
    })
  );

  isExampleHolding$ = this.holding$.pipe(
    map(holding => holding.isExample)
  );

  nonAdminViewBanking$ = this.permissionService.nonAdminViewBanking$;
  nonAdminHideBanking$ = this.permissionService.nonAdminHideBanking$;

  bankAccountsRefresh$ = this.bankAccountsMsService.bankAccountsRefresh$;
  bankAccounts$ = combineLatest([this.bankAccountsRefresh$, this.holding$]).pipe(
    untilComponentDestroyed(this),
    tap(_ => this.isLoading$.next(true)),
    switchMap(([_, holding]) => this.bankAccountsMsService.getBankAccountsByHoldingId(holding.id)),
    withLatestFrom(this.nonAdminHideBanking$),
    map(([bankRows, nonAdminHideBanking]) => {
      if (nonAdminHideBanking) {
        this.holdingService.generateRandomBalancesForAccounts(bankRows);
        this.holdingService.generateRandomNamesForAccounts(bankRows);
      }
      return bankRows;
    }),
    tap(_ => this.isLoading$.next(false)),
    shareReplay(1)
  );

  isRefreshingLinkToken$ = this.linkedBankAccountService.isRefreshingLinkToken$;

  isHovering$ = new BehaviorSubject<boolean>(false);
  NoPermissionAction = NoPermissionAction;
  FinancialPermissionLevel = FinancialPermissionLevel;
  PermissionLevel = PermissionLevel;

  constructor(private holdingService: GpHoldingService,
              private routingService: RoutingService,
              private bankAccountsMsService: BankAccountsMsService,
              private dialog: MatDialog,
              private router: Router,
              public userService: UserService,
              private linkedBankAccountService: LinkedBankAccountService,
              private permissionService: PermissionService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  goToAccount(account: BankAccountTableRowDto): string | null {
    return this.accountProviderToRouteProvider.get(account.accountProvider)?.(account);
  }

  public createCreBankAccount() {
    this.holding$.pipe(
      switchMap(holding => this.dialog.open(UnitCreateBankAccountComponent, {
        disableClose: true,
        closeOnNavigation: true,
        data: {
          preselectedType: UnitApplicationParentType.Holding,
          attachedToId: holding.id,
          hasApprovedApplication: holding.unitApplications?.some(x => x.status === UnitApplicationStatus.Approved),
          showExternalOptions: false,
          unitApplicationId: holding.unitApplicationId
        } as UnitCreateBankAccountParams
      }).afterClosed()),
      tap(newCreBankAccount => newCreBankAccount?.reason === 'new' ? this.bankAccountsMsService.bankAccountRefresh(false) : null))
      .subscribe();
  }

  createApplication() {
    this.holding$.pipe(take(1),
      tap(holding => this.router.navigate([this.routingService.createBankApplicationPage()], {queryParams: {holdingId: holding.id}}))).subscribe();
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.isHovering$.next(true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.isHovering$.next(false);
  }

  getBankStatusClass(unitAppStatus: UnitApplicationStatus | undefined) {
    switch (unitAppStatus) {
      case UnitApplicationStatus.Draft:
      case UnitApplicationStatus.Pending:
      case UnitApplicationStatus.PendingReview:
      case UnitApplicationStatus.AwaitingDocuments:
        return `pending`;
      case UnitApplicationStatus.Denied:
        return `denied`;
      default:
        return;
    }
  }

  getAppStatusClass(unitAppStatus: UnitApplicationStatus | undefined) {
    switch (unitAppStatus) {
      case UnitApplicationStatus.Draft:
      case UnitApplicationStatus.AwaitingDocuments:
        return `action-required`;
      case UnitApplicationStatus.Pending:
      case UnitApplicationStatus.PendingReview:
        return `pending`;
      case UnitApplicationStatus.Denied:
        return `denied`;
      default:
        return;
    }
  }

  getStatusText(unitAppStatus: UnitApplicationStatus | undefined) {
    switch (unitAppStatus) {
      case UnitApplicationStatus.Draft:
      case UnitApplicationStatus.AwaitingDocuments:
      case UnitApplicationStatus.Pending:
      case UnitApplicationStatus.PendingReview:
        return `Pending`;
      case UnitApplicationStatus.Denied:
        return `Denied`;
      default:
        return;
    }
  }

  connectAccount() {
    this.linkedBankAccountService.openPlaidLink(null, false);
  }
}
