import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {HttpService} from 'src/app/services/http.service';
import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {InvestorsOverviewResponse} from 'src/app/dashboard/shared/holding/investor-overview/InvestorsOverviewResponse.model';
import InvestorsOrderBy from 'src/app/shared/enums/InvestorsOrderBy.enum';
import {SearchOptionsRequest} from 'src/app/shared/models/SearchOptionsRequest.model';
import {AiRankedInvestmentReq} from '../../dashboard/shared/holding/fundraising/AI-investors-ranking/AI-ranking-display-table/AiRankedInvestmentReq.model';
import {InvestorRank} from '../../dashboard/shared/holding/fundraising/AI-investors-ranking/InvestorRank.model';
import {BulkOfferingDeckReq} from '../../dashboard/shared/holding/fundraising/bulk-offering-deck/BulkOfferingDeckReq.model';
import {EngagementSearchRequest} from '../../dashboard/shared/holding/fundraising/bulk-offering-deck/investor-engagement-dialog/engagement-display-table/engagementSearchRequest.model';
import {SearchOptionsResponse} from '../../shared/models/SearchOptionsResponse.model';
import {AppSettingsService} from '../app-settings.service';

export enum EndpointType {
  Holdings = "holdings",
  OfferingDeck = "offeringDeck"
}

@Injectable()
export class GpInvestmentDataService {
  constructor(private http: HttpService, private appSettingsService: AppSettingsService) {
  }

  protected getSearchOptionsToQuerystring(options: EngagementSearchRequest): string {
    const filter = encodeURIComponent(options.filter);
    return `?filter=${filter}&responseFilter=${options.responseFilter}&sortOrder=${options.sortOrder}&orderBy=${options.orderBy}&pageNumber=${options.pageNumber}&pageSize=${options.pageSize}`;
  }

  isLocal(): boolean {
    return this.appSettingsService.localEnv;
  }

  isOfferingDeckEndpoint(type: EndpointType): boolean {
    return type === EndpointType.OfferingDeck;
  }

  generateEndpointPrefix(holdingId: number, fundraisingId: number = null, type: EndpointType = EndpointType.Holdings) {
    return `${this.isLocal() && this.isOfferingDeckEndpoint(type) ? 'https://localhost:7162/api/' : ''}${type}/${holdingId}/fundraisings/${fundraisingId}/investments/`;
  }

  /* #region  Api methods */
  getInvestorsOverview(holdingId: number, options: SearchOptionsRequest = null, skipXirrCalculation: boolean): Observable<InvestorsOverviewResponse> {
    let queryString = skipXirrCalculation ? '?skipXirrCalculation=true' : '';
    if (options != null) {
      options.pageSize = 1000;
      options.pageNumber = 0;
      queryString = queryString.length ? `${queryString}&` : `?`;
      queryString += `sortOrder=${options.sortOrder}&orderBy=${options.orderBy}&pageNumber=${options.pageNumber}&pageSize=${options.pageSize}`;
    }
    return this.http.getTyped<InvestorsOverviewResponse>(`holdings/${holdingId}/investments` + queryString).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  createInvestmentFromInvestingEntity(holdingId: number, fundraisingId: number, investingEntityId: number) {
    const model = new InvestmentReqRes();
    model.investingEntityId = investingEntityId;
    return this.http.postTyped<InvestmentReqRes>(this.generateEndpointPrefix(holdingId, fundraisingId), model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  createInvestmentFromFundInvestingEntity(holdingId: number, fundraisingId: number, investingEntityId: number, amount: number) {
    const model = new InvestmentReqRes();
    model.investingEntityId = investingEntityId;
    model.estimatedAmount = amount;
    model.finalAmount = amount;
    return this.http.postTyped<InvestmentReqRes>(`${this.generateEndpointPrefix(holdingId, fundraisingId)}/fund-investment`, model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  createMultipleInvestmentsFromInvestingEntityIds(holdingId: number, fundraisingId: number, investingEntityIds: number[]) {
    const model = new InvestmentReqRes();
    model.bulkCreateIds = investingEntityIds;
    return this.http.postTyped<InvestmentReqRes[]>(this.generateEndpointPrefix(holdingId, fundraisingId) + 'bulk', model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  createMultipleInvestmentsFromAiRankedInvestingEntities(holdingId: number, fundraisingId: number, rankedInvestingEntities: AiRankedInvestmentReq[]) {
    return this.http.postTyped<InvestmentReqRes[]>(this.generateEndpointPrefix(holdingId, fundraisingId) + 'aiRankedBulk', rankedInvestingEntities)
      .pipe(catchError(err => {
        return throwError(err);
      }));
  }

  updateFinalAmount(holdingId: number, fundraisingId: number, investmentId: number, finalAmount: number) {
    const model = new InvestmentReqRes();
    model.finalAmount = finalAmount;

    return this.http.putTyped<InvestmentReqRes>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/finalAmount`, model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  updatePaymentSettlementDate(holdingId: number, fundraisingId: number, investmentId: number, paymentSettlementDate: Date) {
    const model = new InvestmentReqRes();
    model.paymentSettlementDate = paymentSettlementDate;

    return this.http.putTyped<InvestmentReqRes>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/payment-settlement-date`, model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  // Used for both estimated and committed amounts
  updateEstimatedAmountAndRemarks(holdingId: number, fundraisingId: number, investmentId: number, estimatedAmount: number, externalRemarks: string, internalRemarks: string) {
    const model = new InvestmentReqRes();
    model.estimatedAmount = estimatedAmount;
    model.externalRemarks = externalRemarks;
    model.internalRemarks = internalRemarks;

    return this.http.putTyped<InvestmentReqRes>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/estimatedAmount-and-remarks`, model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  updateStatus(holdingId: number, fundraisingId: number, investmentId: number, status: InvestmentStatus) {
    const model = new InvestmentReqRes();
    model.status = status;

    return this.http.putTyped<InvestmentReqRes>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/status`, model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  copyAgreementToHoldingDocuments(holdingId: number, fundraisingId: number, investmentId: number) {
    return this.http.put(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/copy-agreement`, null).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  deleteAgreement(holdingId: number, fundraisingId: number, investmentId: number) {
    return this.http.deleteTyped<InvestmentReqRes>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/agreement`).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  delete(holdingId: number, fundraisingId: number, investmentId: number): Observable<boolean> {
    return this.http.deleteTyped<boolean>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}`).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  markAsDeleted(investmentId: number): Observable<boolean> {
    return this.http.deleteTyped<boolean>(`holdings/fundraisings/investments/${investmentId}`).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  sendMarketingDeck(holdingId: number, fundraisingId: number, investmentId: number, message: string, subject: string) {
    return this.http
      .postTyped<boolean>(this.generateEndpointPrefix(holdingId, fundraisingId, EndpointType.OfferingDeck) + `${investmentId}/marketingDeck`, {
        investmentId,
        message,
        subject
      })
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  sendPaymentRequest(holdingId: number, fundraisingId: number, investmentId: number, message: string, senderReference: string, metaFileLinkIds: number[]) {
    return this.http
      .postTyped<InvestmentReqRes>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/paymentRequest`, {
        investmentId,
        message,
        senderReference,
        metaFileLinkIds
      })
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  updateDocument(holdingId: number, fundraisingId: number, investmentId: number, metaFileLinkId: number) {
    const model = new InvestmentReqRes();
    model.agreementMetaFileLinkId = metaFileLinkId;

    return this.http.putTyped<InvestmentReqRes>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/document`, model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  uploadPaymentRequestDocuments(holdingId: number, fundraisingId: number, investmentId: number, metaFileLinkId: number[]) {
    return this.http.putTyped<InvestmentReqRes>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/documents`, metaFileLinkId).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  deletePaymentRequestDocument(holdingId: number, fundraisingId: number, investmentId: number, metaFileLinkId: number) {
    return this.http.deleteTyped<InvestmentReqRes>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/document/${metaFileLinkId}`).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  generateTransferConfirmation(holdingId: number, fundraisingId: number, investmentId: number) {
    return this.http.getTyped<string>(this.generateEndpointPrefix(holdingId, fundraisingId) + `${investmentId}/transactionConfirmation`).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  updateRanking(investments: InvestmentReqRes[], holdingId: number, fundraisingId: number) {
    return this.http.putTyped<boolean>(this.generateEndpointPrefix(holdingId, fundraisingId) + 'ranking', investments).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  sendBulkOfferingDeck(holdingId: number, fundraisingId: number, request: BulkOfferingDeckReq): Observable<void> {
    return this.http.postTyped<void>(this.generateEndpointPrefix(holdingId, fundraisingId, EndpointType.OfferingDeck) + "offering-deck/bulk", request).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getInvestmentsWithOfferingDeck(searchOptions: EngagementSearchRequest): Observable<SearchOptionsResponse<InvestmentReqRes>> {
    const queryString = this.getSearchOptionsToQuerystring(searchOptions);
    const url = this.generateEndpointPrefix(searchOptions.holdingId, searchOptions.fundraisingId) + 'offeringDeck' + queryString;
    return this.http.getTyped<SearchOptionsResponse<InvestmentReqRes>>(url)
      .pipe(catchError(err => {
        return throwError(err);
      })
      );
  }

  updateCapitalCallInvestment(holdingId: number, fundraisingId: number, investment: InvestmentReqRes) {
    const url = this.generateEndpointPrefix(holdingId, fundraisingId) + investment.id;
    return this.http.putTyped<InvestmentReqRes>(url, investment).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /* #endregion */

  /* #region  Helper methods */

  sortInvestments(investments: InvestmentReqRes[], orderBy: InvestorsOrderBy): InvestmentReqRes[] {
    // We bind the comparers methods to 'this' to allow calling another comparer inside for a secondary sort.
    switch (orderBy) {
      case InvestorsOrderBy.TimeAddedNewToOld:
        return investments.sort(this.newToOldSortComparer.bind(this));
      case InvestorsOrderBy.TimeAddedOldToNew:
        return investments.sort(this.oldToNewSortComparer.bind(this));
      case InvestorsOrderBy.Name:
        return investments.sort(this.nameAToZSortComparer.bind(this));
      case InvestorsOrderBy.NameDesc:
        return investments.sort(this.nameZToASortComparer.bind(this));
      case InvestorsOrderBy.Status:
        return investments.sort(this.statusAscendingSortComparer.bind(this));
      case InvestorsOrderBy.StatusDesc:
        return investments.sort(this.statusDescendingSortComparer.bind(this));
      case InvestorsOrderBy.AmountHighToLow:
        return investments.sort(this.AmountDescendingSortComparer.bind(this));
      case InvestorsOrderBy.AmountLowToHigh:
        return investments.sort(this.AmountAscendingSortComparer.bind(this));
      case InvestorsOrderBy.RankingHighToLow:
        return investments.sort(this.RankingHighToLowComparer.bind(this));
      case InvestorsOrderBy.RankingLowToHigh:
        return investments.sort(this.RankingLowToHighComparer.bind(this));
    }
  }

  /* #region  Comparer functions for investors sorting */
  private newToOldSortComparer(a: InvestmentReqRes, b: InvestmentReqRes) {
    if (a.id < b.id) {
      return 1;
    }
    if (a.id > b.id) {
      return -1;
    }
    return 0;
  }

  private oldToNewSortComparer(a: InvestmentReqRes, b: InvestmentReqRes) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }

  private nameAToZSortComparer(a: InvestmentReqRes, b: InvestmentReqRes) {
    const x = a.investingEntity.contact.firstName + a.investingEntity.contact.lastName;
    const y = b.investingEntity.contact.firstName + b.investingEntity.contact.lastName;
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return this.newToOldSortComparer(a, b);
  }

  private nameZToASortComparer(a: InvestmentReqRes, b: InvestmentReqRes) {
    const x = a.investingEntity.contact.firstName + a.investingEntity.contact.lastName;
    const y = b.investingEntity.contact.firstName + b.investingEntity.contact.lastName;
    if (x < y) {
      return 1;
    }
    if (x > y) {
      return -1;
    }
    return this.newToOldSortComparer(a, b);
  }

  private statusAscendingSortComparer(a: InvestmentReqRes, b: InvestmentReqRes) {
    // Define the order of statuses to use for the sorting process:
    const statusOrder = [InvestmentStatus.Potential, InvestmentStatus.SoftCircled, InvestmentStatus.Signed, InvestmentStatus.Invested, InvestmentStatus.Declined];
    const statusIndexA = statusOrder.indexOf(a.status);
    const statusIndexB = statusOrder.indexOf(b.status);
    if (statusIndexA > statusIndexB) {
      return 1;
    }
    if (statusIndexA < statusIndexB) {
      return -1;
    }
    return this.newToOldSortComparer(a, b);
  }

  private statusDescendingSortComparer(a: InvestmentReqRes, b: InvestmentReqRes) {
    // Define the order of statuses to use for the sorting process:
    const statusOrder = [InvestmentStatus.Invested, InvestmentStatus.Signed, InvestmentStatus.SoftCircled, InvestmentStatus.Potential, InvestmentStatus.Declined];
    const statusIndexA = statusOrder.indexOf(a.status);
    const statusIndexB = statusOrder.indexOf(b.status);
    if (statusIndexA > statusIndexB) {
      return 1;
    }
    if (statusIndexA < statusIndexB) {
      return -1;
    }
    return this.newToOldSortComparer(a, b);
  }

  private AmountAscendingSortComparer(a: InvestmentReqRes, b: InvestmentReqRes) {
    if (a.estimatedAmount < b.estimatedAmount) {
      return -1;
    }
    if (a.estimatedAmount > b.estimatedAmount) {
      return 1;
    }
    return this.newToOldSortComparer(a, b);
  }

  private AmountDescendingSortComparer(a: InvestmentReqRes, b: InvestmentReqRes) {
    if (a.estimatedAmount < b.estimatedAmount) {
      return 1;
    }
    if (a.estimatedAmount > b.estimatedAmount) {
      return -1;
    }
    return this.newToOldSortComparer(a, b);
  }

  private RankingHighToLowComparer(a: InvestmentReqRes, b: InvestmentReqRes) {
    if (a.rank === InvestorRank.None) {
      return 1;
    }
    if (a.rank < b.rank) {
      return -1;
    }
    if (a.rank > b.rank) {
      return 1;
    }
    return this.newToOldSortComparer(a, b);
  }

  private RankingLowToHighComparer(a: InvestmentReqRes, b: InvestmentReqRes) {
    if (a.rank < b.rank) {
      return 1;
    }
    if (a.rank > b.rank) {
      return -1;
    }
    return this.newToOldSortComparer(a, b);
  }

  /* #endregion */

  /* #endregion */
}
