import {Component, OnInit, Inject} from '@angular/core';
import {Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {finalize} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

import {enterAnimation} from 'src/app/shared/animations';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {GpFundDialogContext} from '../../../Fund.context';
import {FundAndFundraisingReqRes} from 'src/app/dashboard/funds/components/create-fund/FundAndFundraisingRequest';
import {GpFundReqRes} from 'src/app/dashboard/funds/GpFundReqRes.model';
import {FundraisingReqRes} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {LenderInformation} from 'src/app/shared/models/LenderInformation.model';
import FundInvestmentStrategyType from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import LoanType from 'src/app/shared/enums/LoanType.enum';
import {DialogWithFormBase} from 'src/app/shared/types/DialogWithFormBase';
import { CustomValidators } from 'src/app/shared/validators/custom.validators';

@Component({
  selector: 'terra-partial-edit-fund-fundraising-dialog',
  templateUrl: './partial-edit-fund-fundraising-dialog.component.html',
  styleUrls: ['./partial-edit-fund-fundraising-dialog.component.scss'],
  animations: [enterAnimation]
})
export class PartialEditFundFundraisingDialogComponent extends DialogWithFormBase implements OnInit {
  pageForm: UntypedFormGroup;
  attemptSubmit = false;
  processingSubmit$ = new BehaviorSubject(false);

  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;

  selectedInvestmentType = this.context.fundDetails.investmentStrategyType;
  // selectedAreaUnit = this.context.fundDetails.areaUnits;
  selectedCurrency = this.context.fundraisingDetails.fundraisingTargetCurrency;

  // Enums
  FundInvestmentStrategyType = FundInvestmentStrategyType;
  AreaUnit = AreaUnit;
  LoanType = LoanType;

  // Enum value lists
  LoanTypesList = LoanType.listAll();

  constructor(
    public dialogRef: MatDialogRef<PartialEditFundFundraisingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: GpFundDialogContext,
    private fb: UntypedFormBuilder,
    private numberPipe: TerraNumberPipe
  ) {
    super();
  }

  ngOnInit() {
    this.generatePageForm(this.context);
  }

  generatePageForm(context: GpFundDialogContext) {
    const fundDetails = context.fundDetails;
    const fundraisingDetails = context.fundraisingDetails;

    this.pageForm = this.fb.group({
      finalClosingDate: [fundraisingDetails.estimatedClosingDate, [Validators.required]],
      totalCapitalization: [fundDetails.totalCapitalization, [Validators.required, CustomValidators.minFormattedNumber(0)]],
      totalInvestorEquity: [fundDetails.totalInvestorEquity, [Validators.required, CustomValidators.minFormattedNumber(0)]],
      gpEquityFromTotalEquity: fundDetails.gpEquityFromTotalEquity,

      lenderInformation: this.fb.group({
        lenderName: fundDetails.lenderInformation.lenderName,
        amount: fundDetails.lenderInformation.amount,
        loanToValue: fundDetails.lenderInformation.loanToValue,
        interestRate: fundDetails.lenderInformation.interestRate,
        loanType: fundDetails.lenderInformation.loanType,
        // totalFinancingPerGrossAreaUnit: fundDetails.lenderInformation.totalFinancingPerGrossAreaUnit,
        // totalFinancingPerNetAreaUnit: fundDetails.lenderInformation.totalFinancingPerNetAreaUnit
      })
    });
  }

  generateSubmitModel(notifyInvestors: boolean): FundAndFundraisingReqRes {
    const formValues = this.pageForm.value;

    const fundModel = new GpFundReqRes();
    const fundraisingModel = new FundraisingReqRes();

    fundModel.id = this.context.fundDetails.id;
    fundraisingModel.id = this.context.fundraisingDetails.id;
    fundraisingModel.isDefaultDeck = this.context.fundraisingDetails.isDefaultDeck;
    fundraisingModel.hasDeck = !!this.context.fundraisingDetails.offeringDeckId

    // Asset details
    fundraisingModel.finalClosingDate = formValues.finalClosingDate;
    fundModel.totalCapitalization = this.numberPipe.parse(formValues.totalCapitalization);
    fundModel.totalInvestorEquity = this.numberPipe.parse(formValues.totalInvestorEquity);
    fundModel.gpEquityFromTotalEquity = this.numberPipe.parse(formValues.gpEquityFromTotalEquity);

    // Lender information (optional)
    fundModel.lenderInformation = new LenderInformation();
    fundModel.lenderInformation.lenderName = formValues.lenderInformation.lenderName;
    fundModel.lenderInformation.amount = this.numberPipe.parse(formValues.lenderInformation.amount);
    fundModel.lenderInformation.loanToValue = this.numberPipe.parse(formValues.lenderInformation.loanToValue);
    fundModel.lenderInformation.interestRate = this.numberPipe.parse(formValues.lenderInformation.interestRate);
    fundModel.lenderInformation.loanType = formValues.lenderInformation.loanType;
    // fundModel.lenderInformation.totalFinancingPerGrossAreaUnit = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerGrossAreaUnit);
    // fundModel.lenderInformation.totalFinancingPerNetAreaUnit = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerNetAreaUnit);

    return new FundAndFundraisingReqRes(fundModel, fundraisingModel, notifyInvestors);
  }

  save(notifyInvestors: boolean) {
    this.attemptSubmit = true;
    this.isGeneralServerError = false;

    if (!this.pageForm.valid) {
      return;
    }

    const model = this.generateSubmitModel(notifyInvestors);
    this.processingSubmit$.next(true);
    this.context.gpFundService
      .moveFundraisingToCompleted(model)
      .pipe(
        finalize(() => this.processingSubmit$.next(false))
      )
      .subscribe(
        updatedAsset => {
          this.dialogRef.close(updatedAsset);
        },
        error => {
          this.isGeneralServerError = true;
          console.log('Error updating fund', error);
        }
      );
  }
}
