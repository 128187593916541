import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {RoutingService} from 'src/app/services/routing.service';

@Component({
  selector: 'terra-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoDialogComponent {
  constructor(private dialogRef: MatDialogRef<PromoDialogComponent>, private router: Router, private routingService: RoutingService) { }

  action() {
    this.dialogRef.close();

  }
}
