<!-- header -->
<div class="card-header header">
  <h2><strong>Fundraising Summary</strong></h2>
</div>

<div class="flex-container">
  <!-- Doughnut box -->
  <div class="terra-holding-card terra-card-hover-effect">
    <terra-fundraising-doughnut-card ></terra-fundraising-doughnut-card>
  </div>
  <!-- Statistics box -->
  <div class="terra-holding-card terra-card-hover-effect">
    <terra-fundraising-investors-statistics-card></terra-fundraising-investors-statistics-card>
  </div>
</div>