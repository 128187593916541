import { CommonModule, NgForOf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  standalone: true,
  imports: [
    NgForOf,
    CommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    FormlyModule
  ],
  styles: [`
    .vertical {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .horizontal {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
    `],
  template: `
  <div class="full-width flex my-3"
  [ngClass]="{ horizontal: props.horizontal, vertical: props.vertical }"
  >
    <label class="mb-1">{{ props.label }}: </label>
    <div class="flex flex-row gap-1" 
    >
      <mat-radio-group
        color="primary"
        class="flex"
        [ngClass]="{ horizontal: props.horizontal, vertical: props.vertical }"
        [formControl]="formControl"
        [formlyAttributes]="field">
          <mat-radio-button *ngFor="let opt of props.options" class="" [value]="opt.value || opt.id">
            {{opt.name}}
          </mat-radio-button>
      </mat-radio-group>

    </div>
  </div>
  `,
})
export class RadioTypeComponent extends FieldType<FieldTypeConfig> { }
