import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'terra-files-limit',
  templateUrl: './files-limit.component.html',
  styleUrls: ['./files-limit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // standalone
})
export class FilesLimitComponent {
  @Input() value: number = 0; // { transform: numberAttribute }
  @Input() max: number = 1; // { transform: numberAttribute }
  @Input() link?: string;
}
