import {Injectable} from '@angular/core';
import {Observable, merge, Subject, BehaviorSubject} from 'rxjs';
import {scan, map, shareReplay, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {EngagementSearchRequest} from './engagementSearchRequest.model';
import {LpInterest} from '../../../../../../models/lpInterest.enum';
import {AppQuery, AppService} from '../../../../../../../state';

@Injectable({
  providedIn: 'root'
})
export class EngagementTableService extends OnDestroyMixin {

  private _refreshData$ = new Subject<void>();
  refreshData$ = this._refreshData$.asObservable();

  private pageSize$ = new BehaviorSubject<number>(25);
  private pageNumber$ = new BehaviorSubject<number>(0);
  private textFilter$ = new BehaviorSubject<string>('');
  private responseFilter$ = new BehaviorSubject<LpInterest>(LpInterest.None);
  private holdingId$ = new Subject<number>();
  private fundraisingId$ = new Subject<number>();
  private pageNumberSource$ = this.pageNumber$.pipe(map(pageNumber => ({pageNumber}) as EngagementSearchRequest));
  private pageSizeSource$ = this.pageSize$.pipe(map(pageSize => ({pageSize}) as EngagementSearchRequest));
  private textFilterSource$ = this.textFilter$.pipe(map(filter => filter.trim()), distinctUntilChanged(), map(filter => ({filter}) as EngagementSearchRequest));
  private responseFilterSource$ = this.responseFilter$.pipe(distinctUntilChanged(), map(responseFilter => ({responseFilter}) as EngagementSearchRequest));
  private holdingIdSource$ = this.holdingId$.pipe(map(holdingId => ({holdingId}) as EngagementSearchRequest));
  private fundraisingIdSource$ = this.fundraisingId$.pipe(map(fundraisingId => ({fundraisingId}) as EngagementSearchRequest));
  private uiStateSource$: Observable<EngagementSearchRequest> = this.appQuery.gpUiPrefs.pipe(
    map(state => state.offeringDeckEngagementUiState.sort),
    map(sort => ({orderBy: sort.orderBy, sortOrder: sort.direction}) as EngagementSearchRequest));

  private searchOptionsSources$: Observable<EngagementSearchRequest> = merge(
    this.uiStateSource$,
    this.textFilterSource$,
    this.responseFilterSource$,
    this.pageNumberSource$,
    this.pageSizeSource$,
    this.holdingIdSource$,
    this.fundraisingIdSource$);

  searchOptions$: Observable<EngagementSearchRequest> = this.searchOptionsSources$
    .pipe(
      untilComponentDestroyed(this),
      scan((acc, value) => {
        // Whenever something changes, unless it's the pagenumber, return to the first page
        const pageNumber = value.pageNumber === undefined ? 0 : value.pageNumber;
        return {...acc, ...value, pageNumber} as EngagementSearchRequest;
      }, new EngagementSearchRequest()),
      debounceTime(10),
      shareReplay(1)
    );

  constructor(private appService: AppService, private appQuery: AppQuery) {
    super();
  }

  refresh() {
    this._refreshData$.next();
  }

  updateFilter(term: string) {
    this.textFilter$.next(term);
  }

  updateResponseFilter(response: LpInterest) {
    this.responseFilter$.next(response);
  }

  updatePageSize(pageSize: number) {
    this.pageSize$.next(pageSize);
  }

  updatePageNumber(page: number) {
    this.pageNumber$.next(page);
  }

  updateHoldingId(id: number) {
    this.holdingId$.next(id);
  }

  updateFundraisingId(id: number) {
    this.fundraisingId$.next(id);
  }

  sort(orderBy: string, sortOrder: string) {
    this.appService.updateOfferingDeckEngagementSortState({orderBy, direction: sortOrder === 'desc' ? 'desc' : 'asc'});
  }
}
