import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, combineLatest, merge, of } from 'rxjs';
import { map, mapTo, shareReplay, switchMap, take } from 'rxjs/operators';

import InvestingEntityType from 'src/app/dashboard/models/InvestingEntityType.enum';
import { PermissionService } from 'src/app/permission/permission.service';
import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import { StorageObjectType } from 'src/app/shared/enums/StorageFileType.enum';
import StorageObjectPermissionLevel from 'src/app/shared/enums/StorageObjectPermissionLevel.enum';
import { FilestackStatus } from 'src/app/shared/enums/filestack-status.enum';
import { LoggerService } from 'src/app/shared/errors/logger.service';
import { IdValue } from 'src/app/shared/models/IdValue.model';
import { StorageObjectReqRes } from 'src/app/shared/models/StorageObjectReqRes.model';
import { UtilsService } from '../../../../../services/utils.service';
import { BaseResponseDto } from '../../../../../shared/models/BaseResponseDto.model';
import { SetPermissionsDialogParams } from '../SetPermissionsDialogParams.model';
import { StorageObjectPermissionListReqRes } from '../StorageObjectPermissionListReqRes.model';
import { DocumentsTabService } from '../documents-tab/documents-tab.service';
import { ShareDocumentsDialogComponent } from '../share-documents-dialog/share-documents-dialog.component';

enum PermissionToFiles {
  None = 0,
  All = 1,
  Varies = 2,
}

interface ContactAndPermission {
  contact: {
    id: number;
    name: string;
    nickname: string;
    email: string;
    type: InvestingEntityType;
  }, // TODO: create new type
  permissionToFiles: PermissionToFiles;
}

@Component({
  selector: 'terra-set-documents-permissions-dialog',
  templateUrl: './set-documents-permissions-dialog.component.html',
  styleUrls: ['./set-documents-permissions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetDocumentsPermissionsDialogComponent implements OnInit {
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  /* #region  Consts */
  // enums
  StorageObjectType = StorageObjectType;
  PermissionToFiles = PermissionToFiles;

  holdingDiscriminatorLowerCase: string = HoldingDiscriminator.toString(this.context.HoldingDiscriminator).toLowerCase();

  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;
  /* #endregion */

  private allSelectedFiles$ = this.storageObjectDataService.getRecursive(this.context.storageObjectsIds).pipe(
    map(allStorageObjects => allStorageObjects.filter(so => so.storageObjectType === StorageObjectType.File)),
    shareReplay(1));

  processingSubmit$ = new BehaviorSubject(false);

  isLoadingFiles$ = merge(of(true), this.allSelectedFiles$.pipe(mapTo(false))).pipe(shareReplay(1));

  isLoading$ = combineLatest([this.isLoadingFiles$, this.processingSubmit$])
    .pipe(
      map(([loadingFiles, processingSubmit]) => loadingFiles || processingSubmit),
      shareReplay(1));

  // From the storage objects the user selected, the proccessing files were filtered ahead, and are in the context.excludedFiles property.
  filesReadyStatus$ = this.allSelectedFiles$
    .pipe(
      map(allFiles => allFiles.filter(so => so.filestackStatus === FilestackStatus.Ready)),
      shareReplay(1));

  filesProcessingStatus$ = this.allSelectedFiles$
    .pipe(
      map(allFiles => allFiles.filter(so => so.filestackStatus === FilestackStatus.Processing)),
      shareReplay(1));

  isAllSelectedFilesStillProcessing$ = combineLatest([this.allSelectedFiles$, this.filesProcessingStatus$]).pipe(
    map(([allSelectedFiles, processingFiles]) => {
      return allSelectedFiles.length === processingFiles.length && processingFiles.length > 0;
    }),
    shareReplay(1));

  // all files are private
  isAllSelectedFilesPrivate$ = this.allSelectedFiles$.pipe(
    map(selectedFiles => !selectedFiles.some(file => file.isPublic)), shareReplay(1));


  private initialContactsWithPermissions$: Observable<ContactAndPermission[]> = this.filesReadyStatus$.pipe(
    take(1),
    map(files => {
      const contactsAndPermissions = new Array<ContactAndPermission>();
      this.context.contacts.forEach(contact => {
        const contactPermission = {
          contact,
          permissionToFiles: this.getPermissionsSpanForInvestingEntityId(contact.id, files)
        };
        // add investors that have permissions to some or all files
        if (contactPermission.permissionToFiles !== PermissionToFiles.None) {
          contactsAndPermissions.push(contactPermission);
        }
      });
      return contactsAndPermissions;
    }),
    shareReplay(1)
  );

  // If when opening the dialog, the selected files are not yet shared with investors
  isFilesInitiallyNotSharedWithInvestor$ = this.initialContactsWithPermissions$.pipe(
    take(1),
    // (a single file which is shared with a single investor should make this false)
    map(contactPermissions => !contactPermissions.some(p => p.permissionToFiles !== PermissionToFiles.None))
  );

  contactPermissions$ = this.initialContactsWithPermissions$.pipe(shareReplay(1));

  // to display a message saying that the user removed permissions from all invstors
  isRemovedFromAllInvestors$ = this.contactPermissions$.pipe(
    map(contactsWithPermissions => contactsWithPermissions.every(x => x.permissionToFiles === PermissionToFiles.None)),
    shareReplay(1));

  constructor(
    private documentsTabService: DocumentsTabService,
    private storageObjectDataService: StorageObjectDataService,
    public dialogRef: MatDialogRef<ShareDocumentsDialogComponent>,
    private logger: LoggerService,
    @Inject(MAT_DIALOG_DATA) public context: SetPermissionsDialogParams,
    private utilsService: UtilsService,
    private permissionService: PermissionService) {
    dialogRef.addPanelClass('set-permissions-dialog-component');
  }

  ngOnInit() { }

  save() {
    this.isGeneralServerError = false;

    this.processingSubmit$.next(true);

    combineLatest([this.filesReadyStatus$, this.contactPermissions$]).pipe(
      take(1),
      map(([files, contactPermissions]) => this.generateSubmitModel(files, contactPermissions)),
      switchMap(model => this.documentsTabService.setPermissions(model)))
      .subscribe(
        () => {
          this.processingSubmit$.next(false);
          this.dialogRef.close(true);
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            this.isGeneralServerError = true;
            this.logger.error('SetDocumentsPermissionsDialogComponent => save()', error);
          }
          this.processingSubmit$.next(false);
        }
      );
  }

  private getPermissionsSpanForInvestingEntityId(investingEntityId: number, storageObjects: StorageObjectReqRes[]): PermissionToFiles {
    const isContactAllowedForEachFile = storageObjects.map(s => s.storageObjectPermissions
      .some(x => x.investingEntityId === investingEntityId));
    // if all allowed
    if (isContactAllowedForEachFile.every(isAllowedForFile => isAllowedForFile === true)) {
      return PermissionToFiles.All;
    } else if (isContactAllowedForEachFile.every(isAllowedForFile => isAllowedForFile === false)) {
      return PermissionToFiles.None;
    }
    // This contact is allowed on some of the selected files
    return PermissionToFiles.Varies;
  }

  private generateSubmitModel(storageObjectsToShare: StorageObjectReqRes[], contactPermissions: ContactAndPermission[]): StorageObjectPermissionListReqRes {
    const model = new StorageObjectPermissionListReqRes();
    model.storageObjects = storageObjectsToShare.map(so => ({ id: so.id, isPublic: so.isPublic }) as StorageObjectReqRes);
    // The list will include all investors that were previously shared for the selected files (all or some of them).
    // This dialog doesn't allow adding permissins,
    // so any item on the list can be set for removing permissions (from all files), or leave unchaged.
    model.ContactPermissions = contactPermissions.map(x =>
      new IdValue<StorageObjectPermissionLevel>(x.contact.id,
        x.permissionToFiles === PermissionToFiles.None ?
          StorageObjectPermissionLevel.None : StorageObjectPermissionLevel.Unchanged));

    return model;
  }
}
