
<terra-loader class="page-loader" *ngIf="isLoading$ | async"></terra-loader>

<terra-dialog-header>
  <div class="title">
    <h1>Offering Deck Engagement</h1>
    <p>See how your investors reacted to your deck</p>
  </div>
</terra-dialog-header>

<mat-dialog-content [ngClass]="{'loading': isLoading$ | async}">

  <terra-engagement-info></terra-engagement-info>

  <ng-container *ngIf="isActiveFundraising$ | async">
    <form [formGroup]="pageForm" (keydown.enter)="$event.preventDefault()" class="page-form">
      <!-- Name filter -->
      <div class="filter text-filter">
        <mat-form-field appearance="outline">
          <input type="text" placeholder="Filter by name" matInput formControlName="name" class="lo_sensitive" autocomplete="nope"
                 autocomplete="off-no-complete" />
        </mat-form-field>
      </div>

      <!-- Response filter -->
      <div class="filter response-filter">
        <mat-form-field appearance="outline">
          <mat-select formControlName="response" placeholder="Filter by response">
            <mat-option [value]="0">None</mat-option>
            <mat-option *ngFor="let response of lpInterestList" [value]="response">
              {{ response | enumString: lpInterest }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <terra-engagement-display-table></terra-engagement-display-table>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions *ngIf="isActiveFundraising$ | async">
  <button type="button" (click)="updateInvestments(investmentStatus.Potential)" mat-flat-button color="primary" class="page-cta" [disabled]="(selectedInvestments$ | async).length === 0">Add selected investors</button>
  <button type="button" (click)="updateInvestments(investmentStatus.SoftCircled)" mat-button class="tall-button" [disabled]="(selectedInvestments$ | async).length === 0">Mark selected as soft circled</button>
</mat-dialog-actions>
