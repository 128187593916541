<terra-dialog-header>
  <h1>Participating Fund</h1>
</terra-dialog-header>

<mat-dialog-content>
  <span class="description">Take partial or full ownership of this {{holdingDiscriminatorString$ | async}} with one of your existing funds.</span>
  <div class="fund-as-investor-form">
    <ng-container *ngIf="funds$ | async as funds">
      <section class="add-fund-as-investor terra-error-style" [formGroup]="fundInvestmentForm" *ngIf="(funds && funds.length > 0) || !(isFundFeatureActive$ | async)">
        <!-- Funds -->
        <mat-form-field class="select-fund" appearance="outline">
          <mat-select id="ddlFunds" placeholder="Choose a fund" formControlName="fund">
            <mat-option [value]="null">Choose a fund</mat-option>
            <mat-option *ngFor="let fund of funds" [value]="fund">
              {{ fund.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Amount -->
        <div class="amount-and-submit">
          <mat-form-field appearance="outline" class="fund-amount">
            <input matInput id="txtAmount" formControlName="amount" terraFormattedNumber placeholder="Amount to invest"
                   class="lo_sensitive capitalize" [readonly]="!(isFundFeatureActive$ | async)"/>
            <span *ngIf="isFundFeatureActive$ | async" matSuffix>{{(currency$ | async).iso }}</span>
            <mat-error *ngIf="fundInvestmentForm.get('amount').hasError('required')">Required</mat-error>
          </mat-form-field>
        </div>
      </section>

      <!-- has fund feature but 0 funds -->
      <section *ngIf="(hasNoFunds$ | async)">
        <a [href]="routingService.gpFundsPage" class="mat-stroked-button mat-primary add-new-fund">
          <mat-icon class="notranslate">add</mat-icon>
          Add a New fund
        </a>
      </section>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" (click)="addFundInvestor()" mat-flat-button color="primary" class="add-button"
          [disabled]="!(isFundSelected$ | async)">
    Add
  </button>
  <button type="button" mat-flat-button [mat-dialog-close]="false">
    Cancel
  </button>
</mat-dialog-actions>
