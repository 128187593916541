<terra-dialog-header>
  <h1 class="lo_sensitive">Ready to finalize distribution?</h1>
</terra-dialog-header>

<div mat-dialog-content [formGroup]="pageForm">
  <div class="description">
    <p>
      Make sure you reviewed the bank details of your investors. Once you finalize, these details are locked and cannot
      be modified by you
      or your investors.
    </p>
    <p>Your investors will be notified about this payout.</p>
  </div>

  <!-- Personal message -->
  <label for="txtMessage" class="text-input-label">Add a personal message (optional):</label>
  <quill-editor id="txtMessage" formControlName="personalMessage" format="html" class="lo_sensitive personal-message" placeholder="" />

  <label class="text-input-label" *ngIf="context.transfers?.length">Email preview:</label>

  <!-- Email carousel -->
  <div class="email-carousel gray-theme" *ngIf="context.transfers?.length">
    <!-- navigation - left -->
    <section class="navigation left">
      <button mat-fab *ngIf="isMultipleTransfer" (click)="previousExample()" aria-label="Previous investor"
              class="nav-button mat-elevation-z2">
        <mat-icon class="notranslate">keyboard_arrow_left</mat-icon>
      </button>
    </section>

    <!-- Email preview  -->
    <ng-container *ngIf=" {
        currentExample: currentExample$ | async,
        isLoading: isLoadingExample$ | async
      } as data">
      <terra-loader class="preview-loader" *ngIf="data.isLoading"></terra-loader>
      <ng-container *ngIf="!data.isLoading">
        <section class="email" *ngIf="data.currentExample as exampleData">
          <p>Hi {{exampleData.transfer.investingEntity.contact.firstName}}
            ({{exampleData.transfer.investingEntity.name}}),</p>
          <p><strong>{{(gpDetails$ | async).organizationDetails.name}} has finalized your distribution payment
            for:</strong></p>
          <ul class="details-list">
            <li>{{(holdingDetails$ |async).discriminator | enumString: HoldingType}}
              name: {{context.holdingName}}</li>
            <li>{{context.distribution.name}}</li>
            <li>Distribution currency: {{context.distributionSourceCurrency.iso}}</li>
          </ul>
          <ul class="details-list">
            <ng-container *ngFor="let transferDetails of exampleData.transfer.distributionTransfersDetails; let i = index">
              <li class="underline">
                Type:
                {{transferDetails.reasonForTransaction | enumString: DistributionType}}
              </li>
              <li *ngIf="transferDetails.taxes || transferDetails.adjustments">
                {{isMultiTypeDistribution ? 'Gross amount' : 'Gross distribution'}}:
                {{context.distributionSourceCurrency.symbol}}{{transferDetails.amountGross | terraCurrency}} {{ context.distributionSourceCurrency.iso | hideUsdIso }}
              </li>
              <li *ngIf="transferDetails.taxes">
                Withheld taxes:
                {{context.distributionSourceCurrency.symbol}}{{transferDetails.taxes | terraCurrency }} {{ context.distributionSourceCurrency.iso | hideUsdIso }}
              </li>
              <li *ngIf="transferDetails.adjustments">
                Adjustments:
                {{context.distributionSourceCurrency.symbol}}{{transferDetails.adjustments | terraCurrency }} {{ context.distributionSourceCurrency.iso | hideUsdIso }}
              </li>
              <li *ngIf="transferDetails.adjustmentsComments">
                Adjustments comments:
                {{transferDetails.adjustmentsComments}}
              </li>
              <li>
                {{isMultiTypeDistribution ? 'Net amount' : 'Net distribution'}}:
                {{context.distributionSourceCurrency.symbol}}{{transferDetails.amountNet | terraCurrency }} {{ context.distributionSourceCurrency.iso | hideUsdIso }}
              </li>
              <br>
            </ng-container>

            <ng-container *ngIf="isMultiTypeDistribution">
              <strong class="underline">Total distribution:</strong>
              <li *ngIf="exampleData.transfer.taxes || exampleData.transfer.adjustments">
                Gross distribution:
                {{context.distributionSourceCurrency.symbol}}{{exampleData.transfer.amountGross | terraCurrency}} {{  context.distributionSourceCurrency.iso | hideUsdIso }}
              </li>
              <li *ngIf="exampleData.transfer.taxes">
                Withheld Taxes:
                {{context.distributionSourceCurrency.symbol}}{{exampleData.transfer.taxes ? (exampleData.transfer.taxes | terraCurrency) : 0 }} {{ context.distributionSourceCurrency.iso | hideUsdIso }}
              </li>
              <li *ngIf="exampleData.transfer.adjustments">
                Adjustments:
                {{context.distributionSourceCurrency.symbol}}{{exampleData.transfer.adjustments ? (exampleData.transfer.adjustments | terraCurrency) : 0}} {{  context.distributionSourceCurrency.iso | hideUsdIso }}
              </li>
              <li>
                <strong>
                  Net distribution:
                  {{context.distributionSourceCurrency.symbol}}{{(exampleData.transfer.amountNet | terraCurrency) || 0}} {{ context.distributionSourceCurrency.iso | hideUsdIso }}
                </strong>
              </li>
              <br>
            </ng-container>
            <ng-container *ngIf="!!exampleData.transfer.clientBankAccount">
              <li *ngIf="!exampleData.transfer.externalPayment && exampleData.transfer.transactionType !== TransactionType.AchDomestic">
                Transaction fee:
                {{context.distributionSourceCurrency.symbol}}{{ exampleData.pricing.transferFee ? (exampleData.pricing.transferFee | terraCurrency) : 0}} {{ context.distributionSourceCurrency.iso | hideUsdIso }}
              </li>
              <li
                *ngIf="!!exampleData.transfer.clientBankAccount && !exampleData.transfer.externalPayment && exampleData.transfer.transactionType !== TransactionType.AchDomestic && exampleData.pricing.exchangeRate && exampleData.pricing.exchangeRate !== 1 && exampleData.pricing.fromCurrency.id !== exampleData.pricing.toCurrency.id">
                Conversion rate: ({{context.distributionSourceCurrency.iso}} - {{exampleData.transfer.clientBankAccount.preferredCurrency.iso}})
                {{ exampleData.pricing.exchangeRate | terraNumber}}
                (Inverse: {{ exampleData.pricing.exchangeRateInverse | terraNumber }})
              </li>

              <li
                *ngIf="!exampleData.transfer.externalPayment && exampleData.transfer.transactionType !== TransactionType.AchDomestic && exampleData.pricing.fromCurrency.id === exampleData.pricing.toCurrency.id">
                Markup: {{context.distributionSourceCurrency.symbol}}{{(calculatedMarkup | async).toFixed(2)}} {{context.distributionSourceCurrency.iso | hideUsdIso}}
                ({{exampleData.pricing.markupPercent}}%)
              </li>
            </ng-container>
            <!--              <li *ngIf="exampleData.transfer?.unitTransactionFee && !exampleData.transfer.externalPayment">-->
            <!--                Bank transaction fee: ${{ exampleData.transfer?.unitTransactionFee | terraCurrency }}-->
            <!--              </li>-->

            <li *ngIf="!!exampleData.transfer.clientBankAccount && !exampleData.transfer.externalPayment && exampleData.transfer.transactionType !== TransactionType.AchDomestic">
              Amount you receive:
              {{exampleData.transfer.clientBankAccount.preferredCurrency.symbol}}{{ exampleData.pricing.receiveAmount | terraCurrency}} {{ exampleData.transfer.clientBankAccount.preferredCurrency.iso | hideUsdIso }}
            </li>
            <li *ngIf="!!exampleData.transfer.clientBankAccount && exampleData.transfer.transactionType === TransactionType.AchDomestic">
              Amount you receive:
              {{exampleData.transfer.clientBankAccount.preferredCurrency.symbol}}{{ exampleData.pricing.amount | terraCurrency}} {{ exampleData.transfer.clientBankAccount.preferredCurrency.iso | hideUsdIso }}
            </li>
            <li *ngIf="!!exampleData.transfer.unitBankAccount">
              Amount you receive:
              ${{ exampleData.pricing.amount | terraCurrency}}
            </li>
          </ul>
          <ul class="details-list" *ngIf="exampleData.transfer.clientBankAccount">
            <li *ngIf="exampleData.transfer.clientBankAccount.preferredCurrency">
              Destination currency: <strong>{{exampleData.transfer.clientBankAccount.preferredCurrency.iso}}</strong>
            </li>
            <li>
              Destination bank account: <strong>****{{(exampleData.transfer.clientBankAccount.accountNumber ?
              exampleData.transfer.clientBankAccount.accountNumber : exampleData.transfer.clientBankAccount.iban)?.slice(-4)}}
            </strong>
            </li>
          </ul>

          <ul class="details-list" *ngIf="exampleData.transfer.unitBankAccount">
            <li>
              Destination currency: <strong>USD</strong>
            </li>
            <li>
              Destination bank account: <strong>****{{ exampleData.transfer.unitBankAccount.accountNumber?.slice(-4) }}
            </strong>
            </li>
          </ul>

          <ng-container *ngIf="personalMessage$ | async">
            <div [innerHTML]="pageForm.get('personalMessage').value | safeHtml"></div>
          </ng-container>
          <p>(*) Amounts are subject to change.</p>
        </section>
      </ng-container>
    </ng-container>

    <!-- navigation - right -->
    <section class="navigation right">
      <button mat-fab *ngIf="isMultipleTransfer" (click)="nextExample()" aria-label="Next investor"
              class="nav-button mat-elevation-z2">
        <mat-icon class="notranslate">keyboard_arrow_right</mat-icon>
      </button>
    </section>

  </div>
</div>

<div mat-dialog-actions>
  <button mat-flat-button type="button" (click)="submit(true)" [disabled]="isLoadingWaterfallRecalculationRequirement$ | async"
          color="accent" class="page-cta">
    Finalize & Send
    <mat-icon svgIcon="publish" class="notranslate publish-icon"></mat-icon>
    <terra-loader *ngIf="isLoadingWaterfallRecalculationRequirement$ | async"></terra-loader>
  </button>

  <button mat-stroked-button type="button" (click)="submit(false)" [disabled]="isLoadingWaterfallRecalculationRequirement$ | async"
          class="finalize-without-email">
    Finalize
    <terra-loader *ngIf="isLoadingWaterfallRecalculationRequirement$ | async"></terra-loader>
  </button>

  <button mat-button type="button" class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</div>
