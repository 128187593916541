<terra-dialog-header>
    <div class="title">
        <h2>{{data.title ? data.title : 'Upload files'}}</h2>
    </div>
</terra-dialog-header>

<mat-dialog-content class="content">
    <div class="saved-document">
        <ng-container *ngFor="let document of data.savedDocuments; let i = index">
                <a target="_blank" class="light link" href="{{ document?.url }}">
                    <mat-icon class="notranslate">insert_drive_file</mat-icon> {{
                    document?.title | truncate: 50 }}
                </a>
        </ng-container>
    </div>
    <form [formGroup]="form">
        <ng-container formArrayName="documents">
            <div class="documents" *ngFor="let document of documents.controls; let i = index">
                <terra-file-uploader no-type-select [formControl]="document" [required]="false">
                </terra-file-uploader>
            </div>
        </ng-container>
    </form>
    <a *ngIf="MAX_UPLOAD_DOCUMENT > (documents.length + data.savedDocuments.length)" class="add-more-documents-link full-width" (click)="addDocument()">
        <mat-icon class="notranslate">add_circle</mat-icon>
        Add more documents
    </a>
</mat-dialog-content>

<mat-dialog-actions>
    <button *ngIf="MAX_UPLOAD_DOCUMENT > data.savedDocuments.length" mat-flat-button type="button" class="save-button" (click)="save()">Save</button>

    <button mat-button type="button" class="cancel-button" [mat-dialog-close]="undefined">{{MAX_UPLOAD_DOCUMENT > data.savedDocuments.length ? 'Cancel' : 'Close'}}</button>
</mat-dialog-actions>
