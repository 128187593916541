<terra-dialog-header>
  <h1>Update status to Under Management</h1>
</terra-dialog-header>

<div class="content-wrapper" [ngClass]="{ 'loading': (processingSubmit$ | async)  }">
  <terra-loader class="page-loader" *ngIf="(processingSubmit$ | async)"></terra-loader>
  <mat-dialog-content class="main-content">
    <p class="info-message">
      Once in Under Management status, you cannot go back to Fundraising status
    </p>

    <p class="holding-down-stripe full-width" *ngIf="context.asset.isPrivate">
      When the asset mode is down, it will not be available on Investor Portal, and investors will not receive an update
      about the status
      change.
    </p>

    <form id="dialogForm" [formGroup]="pageForm"
          class="form-grid-new terra-error-style border-bottom"
          (keydown.enter)="$event.preventDefault()">
      <h3 class="section-title">
        Please fill in the following information before updating the asset’s status. <br/>
        This information will be shared with your investors.
      </h3>

      <h3 class="section-title">Asset details</h3>

      <!-- Initial closing date -->
      <div class="terra-form-field-wrapper">
        <terra-custom-utc-date-picker id="txtClosingDate" formControlName="finalClosingDate" label="Initial closing date"
          [requiredError]="pageForm.get('finalClosingDate').hasError('required')"
          [touched]="pageForm.get('finalClosingDate').touched"
          minErrorTxt="Please enter a valid initial closing date"></terra-custom-utc-date-picker>
      </div>

      <!-- Total capitalization -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalCapitalization" class="text-input-label">
          {{selectedSecurityType === InvestmentSecurityType.Debt ? 'Total loan' : 'Total capitalization'}}
        </label>
        <mat-form-field appearance="outline">
          <input matInput id="txtTotalCapitalization" formControlName="totalCapitalization" terraFormattedNumber
                 autocomplete="total-capitalization" class="lo_sensitive"
                 [value]="selectedSecurityType === InvestmentSecurityType.Debt ? totalLoans : pageForm.get('totalCapitalization')?.value"/>
          <span matSuffix>{{ selectedCurrency?.iso }}</span>
          <mat-error *ngIf="pageForm.get('totalCapitalization').hasError('required')"> Required</mat-error>
          <mat-error *ngIf="pageForm.get('totalCapitalization').hasError('minFormattedNumber')">Must be a greater than 0</mat-error>
          <mat-hint>
            {{selectedSecurityType === InvestmentSecurityType.Debt ? 'Total junior + senior loan' : 'Total investors equity + loan'}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- Total Investor Equity  -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalInvestorEquity" class="text-input-label">
          <!--          {{selectedSecurityType === InvestmentSecurityType.Debt  ? 'Total junior loan' : 'Total investor equity'}}-->
          Total investor equity
        </label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTotalInvestorEquity" terraFormattedNumber
                 formControlName="totalInvestorEquity"
                 class="lo_sensitive"/>
          <span matSuffix>{{ selectedCurrency?.iso }}</span>
          <mat-error *ngIf="pageForm.get('totalInvestorEquity').hasError('required')"> Required</mat-error>
          <mat-error *ngIf="pageForm.get('totalInvestorEquity').hasError('minFormattedNumber')">Must be a greater than 0</mat-error>
        </mat-form-field>
      </div>

      <!-- % of GP equity from total equity (optional)  -->
      <div class="terra-form-field-wrapper">
        <label for="txtGpEquityFromTotalEquity" class="text-input-label">
          {{selectedSecurityType === InvestmentSecurityType.Debt ? 'GP share of the loan' : '% of GP equity from total equity (optional)'}}
        </label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtGpEquityFromTotalEquity" terraFormattedNumber
                 formControlName="gpEquityFromTotalEquity"
                 class="lo_sensitive"/>
        </mat-form-field>
      </div>

      <!-- Outstanding loan amount to date -->
      <div *ngIf="selectedSecurityType === InvestmentSecurityType.Debt" class="terra-form-field-wrapper">
        <label for="txtOutstandingLoanAmountToDate" class="text-input-label">Outstanding loan amount to date
          (optional)</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtOutstandingLoanAmountToDate" terraFormattedNumber
                 formControlName="outstandingLoanAmountToDate"
                 class="lo_sensitive"/>
          <span matSuffix>{{ selectedCurrency?.iso }}</span>
        </mat-form-field>
      </div>


      <!-- Loan information -->
      <h3 class="section-title border-top">
        {{selectedSecurityType === InvestmentSecurityType.Debt ? 'Senior loan information (optional)' : 'Loan information (optional)'}}</h3>
      <ng-container formGroupName="lenderInformation">
        <!-- Lender Name -->
        <div class="terra-form-field-wrapper">
          <label for="txtLenderName" class="text-input-label">Lender name</label>
          <mat-form-field appearance="outline">
            <input matInput id="txtLenderName" formControlName="lenderName" autocomplete="lender-name"
                   class="lo_sensitive"/>
          </mat-form-field>
        </div>

        <!-- Amount -->
        <div class="terra-form-field-wrapper">
          <label for="txtLenderAmount" class="text-input-label">Amount</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtLenderAmount" terraFormattedNumber formControlName="amount"
                   class="lo_sensitive"/>
            <span matSuffix>{{ selectedCurrency?.iso }}</span>
          </mat-form-field>
        </div>

        <!-- Loan type -->
        <div class="terra-form-field-wrapper">
          <label for="ddlLoanType" class="text-input-label">Loan type</label>
          <mat-form-field appearance="outline">
            <mat-select id="ddlLoanType" formControlName="loanType" placeholder="Select">
              <mat-option [value]="null">Select</mat-option>
              <mat-option *ngFor="let item of LoanTypesList" [value]="item">{{ item | enumString: LoanType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Loan to value -->
        <div class="terra-form-field-wrapper">
          <label for="txtLoanToValue" class="text-input-label">
            Loan to {{ selectedInvestmentType === InvestmentType.Opportunistic ? 'cost' : 'value'}} %</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtLoanToValue" terraFormattedNumber formControlName="loanToValue"
                   class="lo_sensitive"/>
          </mat-form-field>
        </div>

        <!-- Interest and Interest Type -->
        <div class="terra-form-field-wrapper interest-with-type">
          <!-- Interest Rate -->
          <label for="txtInterestRate" class="interest-label text-input-label">
            Interest rate %
          </label>
          <mat-form-field class="interest-field-wrapper" appearance="outline">
            <input type="tel" matInput id="txtInterestRate" terraFormattedNumber formControlName="interestRate"
                   class="lo_sensitive"/>
          </mat-form-field>
          <!-- Interest Type -->
          <label for="ddlInterestType" class="interest-type-label text-input-label">
            Interest type
          </label>
          <mat-form-field appearance="outline" class="interest-type-ddl-wrapper">
            <mat-select id="ddlInterestType" placeholder="Select" formControlName="interestType">
              <mat-option *ngFor="let item of InterestTypesList" [value]="item">{{ item | enumString: InterestType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Closing date -->
        <div class="terra-form-field-wrapper">
          <label for="txtClosingDate" class="text-input-label">Closing date</label>
          <mat-form-field appearance="outline">
            <!-- Allow past date to support retroactive creation of funds -->
            <input matInput [min]="closingDateMinimumDate" id="txtClosingDate"
                   [matDatepicker]="closingDateDatePickerAPU"
                   autocomplete="bday" formControlName="closingDate" (focus)="closingDateDatePickerAPU.open()"
                   class="lo_sensitive"/>
            <mat-datepicker-toggle matSuffix [for]="closingDateDatePickerAPU"></mat-datepicker-toggle>
            <mat-datepicker #closingDateDatePickerAPU [startAt]="closingDateStartAt"></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Maturity date -->
        <div class="terra-form-field-wrapper">
          <label for="txtMaturityDate" class="text-input-label">Maturity date</label>
          <mat-form-field appearance="outline">
            <!-- Allow past date to support retroactive creation of funds -->
            <input matInput [min]="maturityDateMinimumDate" id="txtMaturityDate"
                   [matDatepicker]="maturityDateDatePickerAPU"
                   autocomplete="bday" formControlName="maturityDate" (focus)="maturityDateDatePickerAPU.open()"
                   class="lo_sensitive"/>
            <mat-datepicker-toggle matSuffix [for]="maturityDateDatePickerAPU"></mat-datepicker-toggle>
            <mat-datepicker #maturityDateDatePickerAPU [startAt]="maturityDateStartAt"></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Total financing per gross area (only InvestmentType.Opportunistic) -->
        <div class="terra-form-field-wrapper" *ngIf="selectedInvestmentType === InvestmentType.Opportunistic">
          <label for="txtTotalFinancingPerGrossAreaUnit" class="text-input-label">
            Total financing per gross {{ selectedAreaUnit | enumString : AreaUnit }} </label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtTotalFinancingPerGrossAreaUnit" terraFormattedNumber
                   formControlName="totalFinancingPerGrossAreaUnit" class="lo_sensitive"/>
          </mat-form-field>
        </div>

        <!-- Total financing per net area (only InvestmentType.Opportunistic) -->
        <div class="terra-form-field-wrapper" *ngIf="selectedInvestmentType === InvestmentType.Opportunistic">
          <label for="txtTotalFinancingPerNetAreaUnit" class="text-input-label">
            Total financing per net {{ selectedAreaUnit | enumString : AreaUnit }} </label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtTotalFinancingPerNetAreaUnit" terraFormattedNumber
                   formControlName="totalFinancingPerNetAreaUnit" class="lo_sensitive"/>
          </mat-form-field>
        </div>

        <!-- Additional Term -->
        <div class="terra-form-field-wrapper full-width">
          <label for="txtAdditionalTerms" class="text-input-label">Additional Terms</label>
          <p class="field-description">
            Share the loan additional terms here
          </p>
          <quill-editor id="txtAdditionalTerms" formControlName="txtAdditionalTerms" format="html" class="lo_sensitive" placeholder="" />

          <mat-error *ngIf="pageForm.get('lenderInformation.additionalTerms').hasError('maxlength')"> You have exceeded
            the maximum length of {{
            pageForm.get('lenderInformation.additionalTerms').getError('maxlength').requiredLength | terraNumber
            }} characters
          </mat-error>
        </div>
      </ng-container>

      <!-- Junior Loan information -->
      <h3 class="section-title border-top" *ngIf="selectedSecurityType === InvestmentSecurityType.Debt">
        Junior loan information (optional)
      </h3>
      <ng-container formGroupName="lenderInformation" *ngIf="selectedSecurityType === InvestmentSecurityType.Debt">
        <!-- Amount -->
        <div class="terra-form-field-wrapper">
          <label for="txtLenderAmountJunior" class="text-input-label">Amount</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtLenderAmountJunior" terraFormattedNumber formControlName="amountJunior"
                   class="lo_sensitive"/>
            <span matSuffix>{{ selectedCurrency?.iso }}</span>
          </mat-form-field>
        </div>

        <!-- Loan type -->
        <div class="terra-form-field-wrapper">
          <label for="ddlLoanTypeJunior" class="text-input-label">Loan type</label>
          <mat-form-field appearance="outline">
            <mat-select id="ddlLoanTypeJunior" formControlName="loanTypeJunior" placeholder="Select">
              <mat-option [value]="null">Select</mat-option>
              <mat-option *ngFor="let item of LoanTypesList" [value]="item">{{ item | enumString: LoanType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Loan to value -->
        <div class="terra-form-field-wrapper">
          <label for="txtLoanToValueJunior" class="text-input-label">
            Loan to {{ selectedInvestmentType === InvestmentType.Opportunistic ? 'cost' : 'value'}} %</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtLoanToValueJunior" terraFormattedNumber
                   formControlName="loanToValueJunior"
                   class="lo_sensitive"/>
          </mat-form-field>
        </div>

        <!-- Interest and Interest Type -->
        <div class="terra-form-field-wrapper interest-with-type">
          <!-- Interest Rate -->
          <label for="txtInterestRateJunior" class="interest-label text-input-label">
            Interest rate %
          </label>
          <mat-form-field class="interest-field-wrapper" appearance="outline">
            <input type="tel" matInput id="txtInterestRateJunior" terraFormattedNumber
                   formControlName="interestRateJunior"
                   class="lo_sensitive"/>
          </mat-form-field>
          <!-- Interest Type -->
          <label for="ddlInterestTypeJunior" class="interest-type-label text-input-label">
            Interest type
          </label>
          <mat-form-field appearance="outline" class="interest-type-ddl-wrapper">
            <mat-select id="ddlInterestTypeJunior" placeholder="Select" formControlName="interestTypeJunior">
              <mat-option *ngFor="let item of InterestTypesList" [value]="item">{{ item | enumString: InterestType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Closing date -->
        <div class="terra-form-field-wrapper">
          <label for="txtClosingDateJunior" class="text-input-label">Closing date</label>
          <mat-form-field appearance="outline">
            <!-- Allow past date to support retroactive creation of funds -->
            <input matInput [min]="closingDateMinimumDate" id="txtClosingDateJunior"
                   [matDatepicker]="closingDateDatePickerJunior"
                   autocomplete="bday" formControlName="closingDateJunior" (focus)="closingDateDatePickerJunior.open()"
                   class="lo_sensitive"/>
            <mat-datepicker-toggle matSuffix [for]="closingDateDatePickerJunior"></mat-datepicker-toggle>
            <mat-datepicker #closingDateDatePickerJunior [startAt]="closingDateStartAt"></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Maturity date -->
        <div class="terra-form-field-wrapper">
          <label for="txtMaturityDateJunior" class="text-input-label">Maturity date</label>
          <mat-form-field appearance="outline">
            <!-- Allow past date to support retroactive creation of funds -->
            <input matInput [min]="maturityDateMinimumDate" id="txtMaturityDateJunior"
                   [matDatepicker]="maturityDateDatePickerJunior"
                   autocomplete="bday" formControlName="maturityDateJunior"
                   (focus)="maturityDateDatePickerJunior.open()"
                   class="lo_sensitive"/>
            <mat-datepicker-toggle matSuffix [for]="maturityDateDatePickerJunior"></mat-datepicker-toggle>
            <mat-datepicker #maturityDateDatePickerJunior [startAt]="maturityDateStartAt"></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Total financing per gross area (only InvestmentType.Opportunistic) -->
        <div class="terra-form-field-wrapper" *ngIf="selectedInvestmentType === InvestmentType.Opportunistic">
          <label for="txtTotalFinancingPerGrossAreaUnitJunior" class="text-input-label">
            Total financing per gross {{ selectedAreaUnit | enumString : AreaUnit }} </label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtTotalFinancingPerGrossAreaUnitJunior" terraFormattedNumber
                   formControlName="totalFinancingPerGrossAreaUnitJunior" class="lo_sensitive"/>
          </mat-form-field>
        </div>

        <!-- Total financing per net area (only InvestmentType.Opportunistic) -->
        <div class="terra-form-field-wrapper" *ngIf="selectedInvestmentType === InvestmentType.Opportunistic">
          <label for="txtTotalFinancingPerNetAreaUnitJunior" class="text-input-label">
            Total financing per net {{ selectedAreaUnit | enumString : AreaUnit }} </label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtTotalFinancingPerNetAreaUnitJunior" terraFormattedNumber
                   formControlName="totalFinancingPerNetAreaUnitJunior" class="lo_sensitive"/>
          </mat-form-field>
        </div>

        <!-- Additional Term -->
        <div class="terra-form-field-wrapper full-width">
          <label for="txtAdditionalTermsJunior" class="text-input-label">Additional Terms</label>
          <p class="field-description">
            Share the loan additional terms here
          </p>
          <quill-editor id="txtAdditionalTermsJunior" formControlName="txtAdditionalTermsJunior" format="html" class="lo_sensitive" placeholder="" />
          
          <mat-error *ngIf="pageForm.get('lenderInformation.additionalTermsJunior').hasError('maxlength')"> You have
            exceeded the maximum length of
            {{ pageForm.get('lenderInformation.additionalTermsJunior').getError('maxlength').requiredLength | terraNumber }}
            characters
          </mat-error>
        </div>
      </ng-container>

    </form>
  </mat-dialog-content>
</div>

<mat-dialog-actions>
  <button *ngIf="!context.asset.isPrivate" type="button" form="dialogForm" [disabled]="processingSubmit$ | async"
          mat-flat-button
          color="accent" class="page-cta" (click)="save(true)">
    Update Status and Notify Investors
    <mat-icon svgIcon="publish" class="notranslate publish-icon"></mat-icon>
  </button>
  <button type="button" form="dialogForm" [disabled]="processingSubmit$ | async"
          mat-flat-button
          color="primary" class="page-cta" (click)="save(false)">
    Update Status
  </button>
  <button type="button" mat-button class="cancel-button tall-button" [disabled]="processingSubmit$ | async"
          [mat-dialog-close]="undefined">Cancel
  </button>
  <!-- Error message -->
  <div class="full-width" *ngIf="attemptSubmit">
    <p *ngIf="pageForm.invalid" class="error-message">
      Please review and correct the fields marked in red
    </p>
    <p *ngIf="pageForm.valid && isGeneralServerError" #serverError class="error-message">
      <span [innerHTML]="generalServerErrorMessage"></span></p>
  </div>
</mat-dialog-actions>
