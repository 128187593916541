import InvestmentSecurityType from '../../../../../../shared/enums/InvestmentSecurityType.enum';

export class ContributionDetailsItemReqRes {
  public name: string;
  public finalClosingDate: Date;
  public securityType: InvestmentSecurityType;
  public isFormValid: boolean;

  constructor() {
  }
}
