<ng-container
  *ngIf="{
    selectedInvestments: selectedInvestments$ | async,
    reIssueFailMsgFlag: reIssueFailMsgFlag$ | async
  } as data"
>
  <div class="header">
    <terra-capital-call-box-title
      title="Track Capital Call"
      subTitle1="The following table reflects investors' capital call status."
      subTitle2="At any time you can re-issue the call to an investor or investors by checking the checkbox near their name and clicking the 'Issue Capital Call' button."
    >
    </terra-capital-call-box-title>
  </div>
  <p *ngIf="!!data.reIssueFailMsgFlag" class="error-message">
    {{ data.reIssueFailMsgFlag }}
    <a href="//www.covercy.com/contact-us" target="_blank">contact support</a>.
  </p>
  <div class="action">
    <form [formGroup]="formGroup" class="filters">
      <!-- Name Filter -->
      <mat-form-field
        appearance="outline"
        class="text-filter-wrapper remove-error-place name-filter"
      >
        <input
          matInput
          formControlName="filter"
          id="name-filter"
          class="lo_sensitive"
          placeholder="Search by investor"
        />
        <mat-icon matPrefix class="notranslate magnify-icon">search</mat-icon>
      </mat-form-field>
      <!-- Track Status Filter -->
      <mat-form-field appearance="outline" class="remove-error-place">
        <mat-select
          matInput
          placeholder="Filter by Status"
          formControlName="trackStatus"
        >
          <mat-option [value]="null">All Statuses</mat-option>
          <mat-option
            *ngFor="let item of TrackStatus.listAll(false)"
            [value]="item"
            >{{ item | enumString : TrackStatus }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </form>

    <button
      type="button"
      class="re-issue-btn"
      mat-flat-button
      (click)="issueCapitalCall(data.selectedInvestments)"
      [disabled]="!!(disableReIssue$ | async)"
      terraCheckPermissions
      [minimumFinancialPermissionLevel]="
        FinancialPermissionLevel.AuthorizedSignatory
      "
      [minimumPermissionLevel]="PermissionLevel.Editor"
      [noPermissionAction]="NoPermissionAction.PopupOnClick"
    >
      Issue Capital Call
    </button>
  </div>
  <div class="table">
    <terra-capital-call-investments-table />
  </div>
</ng-container>
