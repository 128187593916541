<form [formGroup]="dataSource.investmentsForm" class="terra-error-style">
  <ng-container formArrayName="investments" *ngIf="{
    searchOptions: searchOptions$ | async,
    pageRowsCount: pageRowsCount$ | async,
    totalRowCount: totalRowCount$ | async,
    allowInvestorName: allowInvestorName$ | async } as data">

  <div class="hide-investor-name-message-sort" *ngIf="!data.allowInvestorName && data.totalRowCount > 0">To find a specific investor by ID please use the Advanced Search field above</div>

    <table *ngIf="data.searchOptions" mat-table [dataSource]="dataSource" [trackBy]="trackByFn"
           class="cov-basic-table capital-call-investments-table" (matSortChange)="sort($event)" matSort matSortDisableClear>
      <!-- Checkbox column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="gray-theme select-column">
          <mat-checkbox (change)="$event ? masterToggle() : null" [indeterminate]="selection.hasValue() && !(isAllSelected$ | async)"
                        [checked]="selection.hasValue() && (isAllSelected$ | async)" [disabled]="data.pageRowsCount === 0 || (isLoading$ | async)"
                        color="primary">
          </mat-checkbox>
        </th>
        <td mat-cell class="gray-theme" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="rowCheckToggle(row)" [checked]="isRowChecked(row)"
                        [disabled]="!isAllowedToIssueCapitalCall(row)" color="accent">
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- Investor Name -->
      <ng-container matColumnDef="investor" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name" [disabled]="data.allowInvestorName" class="name">Investor</th>
        <td mat-cell *matCellDef="let element" class="lo_sesitive">
          <div>{{ getFormattedInvestorName(element) | async}}</div>
          <span *ngIf="!isAccredited(element)" class="not-accredited">Accreditation Missing</span>
          <div *ngIf="element.get('trackStatus').value === TrackedStatus.Declined" class="remove-investment"
               (click)="deleteInvestment(element)">
            Remove
          </div>
        </td>
      </ng-container>
      <!-- Due Date -->
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="dueDate">Due Date</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive" [formGroup]="element">
          <terra-custom-utc-date-picker
                id="txtDueDate"
                formControlName="dueDate"
                fieldClass="remove-error-place field-date"
                ></terra-custom-utc-date-picker>
        </td>
      </ng-container>
      <!-- Amount -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="amount">Call Amount</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive" [formGroup]="element">
          <div class="terra-form-field-wrapper amount">
            <mat-form-field appearance="outline" class="remove-error-place">
              <input class="lo_sensitive" type="tel" matInput terraFormattedNumber formControlName="amount"
                [errorStateMatcher]="customErrorStateMatcher"/>
              <span matPrefix>{{(capitalCallCurrency$ | async).symbol}}</span>
            </mat-form-field>
          </div>
        </td>
      </ng-container>
      <!-- Transfer Date -->
      <ng-container matColumnDef="transferDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="transferDate">Transfer Date</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive" [formGroup]="element">
          <terra-custom-utc-date-picker
                id="txtDueDate"
                formControlName="transferDate"
                fieldClass="remove-error-place field-date"
                [maxDate]="today"
                placeholder="N/A"
                ></terra-custom-utc-date-picker>
        </td>
      </ng-container>
      <!-- Receive Date -->
      <ng-container matColumnDef="receivedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="receivedDate">Receive Date</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive" [formGroup]="element">
            <terra-custom-utc-date-picker
            id="txtDueDate"
            formControlName="receivedDate"
            fieldClass="remove-error-place field-date"
            [maxDate]="today"
            placeholder="N/A"
            ></terra-custom-utc-date-picker>
        </td>
      </ng-container>
      <!-- Track Status -->
      <ng-container matColumnDef="trackStatus">
        <th mat-header-cell *matHeaderCellDef class="track-status-th" mat-sort-header="trackStatus">Status</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive" [formGroup]="element">
          <div [ngClass]="['track-status', TrackedStatus[element.get('trackStatus').value]]">
            {{ element.get('trackStatus').value | enumString: TrackedStatus}}
          </div>
          <div class="sent-date" *ngIf="showViewedDate(element)">
            Viewed: {{element.get('viewDate').value | utcDate: 'MMM d, y'}}
          </div>
        </td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions"></th>
        <td mat-cell *matCellDef="let element" [formGroup]="element">
          <mat-icon *ngIf="data.allowInvestorName && displayAttachFilesIcon(element)" (click)="uploadFile(element)" matTooltip="Upload File" class="notranslate upload-file-icon">
            attach_file
          </mat-icon>
          <div *ngIf="displayVisibilityIcon(element)" class="visibility-container">
            <mat-icon class="notranslate visibility-icon">
              visibility
            </mat-icon>
            <!-- Payment request status -->
            <div class="visibility-tooltip">
              <terra-payment-request-status-block
                [bankAccountNickname]="fundraisingBankAccountName$ | async"
                [investment]="getInvestmentFromFormGroup(element)">
              </terra-payment-request-status-block>
            </div>
          </div>
          <div *ngIf="isSaving(element) | async" class="saving">
            <mat-progress-spinner *ngIf="isSaving(element) | async" diameter="50" mode="indeterminate"></mat-progress-spinner>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row class="capital-call-header-row" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row [id]="row.get('id').value" class="capital-call-data-row" *matRowDef="let row; columns: displayedColumns" [class.selected-row]="isRowChecked(row)"
          [class.hide]="isLoading$ | async">
      </tr>
    </table>

    <div *ngIf="data.totalRowCount === 0 && !(isLoading$ | async)" class="empty-list-message">
      <p>
        No investors to display.
      </p>
    </div>

    <!-- Loader -->
    <terra-loader *ngIf="isLoading$ | async" class="loader"></terra-loader>

    <div class="capital-call-investment-paginator">
      <mat-paginator
      (page)="pageChange($event)"
      [length]="data.totalRowCount"
      [pageSize]="data.searchOptions.pageSize"
      [pageIndex]="data.searchOptions.pageNumber"
      [pageSizeOptions]="itemsPerPageOptions"
      *ngIf="data.totalRowCount > perPageControl.value">
      </mat-paginator>
    </div>

    <br>
    <div class="remove-message" *ngIf="data.totalRowCount > 0">
      To remove investors, go to the Pipeline tab, mark them as Declined and then remove them.
    </div>
  </ng-container>
</form>

