<ng-template #loading>
  <terra-loader></terra-loader>
</ng-template>

<ng-container *ngIf="reportTableItems$ | async as reportTableItems; else loading">
  <!-- Empty state -->
  <section class="empty-state" *ngIf="emptyState$ | async; else reportsContent">
    <img src="assets/images/general/empty_state.svg" />
    <h2>Any reports you create for investors will appear here</h2>
    <button (click)="createReport()" class="create-report-button-empty" type="button" mat-flat-button color="primary">
      <mat-icon class="notranslate">add</mat-icon> Create a Report
    </button>
  </section>
  <!-- Content -->
  <ng-template #reportsContent>
    <section class="reports-content">
      <!-- Actions -->
      <div class="header-actions">
        <!-- Filter -->
        <div class="terra-form-field-wrapper">
          <label for="ddlFilterPeriod" class="text-input-label">Show reports from</label>
          <mat-form-field appearance="outline" class="remove-error-place">
            <mat-select [ngModel]="filterPeriod$.value" id="ddlFilterPeriod" (ngModelChange)="filterPeriod$.next($event)">
              <mat-option [value]="0">All time</mat-option>
              <mat-option *ngFor="let year of reportYears$ | async" [value]="year">
                {{ year }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <button (click)="createReport()" class="create-report-button" type="button" mat-stroked-button color="primary">
          <mat-icon class="notranslate">add</mat-icon> Create a Report
        </button>
      </div>

      <table mat-table [dataSource]="reportTableItems" class="cov-basic-table">
        <!-- Report period (pre-generated string) -->
        <ng-container matColumnDef="reportPeriod">
          <th mat-header-cell *matHeaderCellDef>Report period</th>
          <td mat-cell *matCellDef="let report">{{ report.reportPeriod }}</td>
        </ng-container>

        <!-- Publish date Column -->
        <ng-container matColumnDef="publishDate">
          <th mat-header-cell *matHeaderCellDef>Published on</th>
          <td mat-cell *matCellDef="let report">{{ report.publishDate ? (report.publishDate | date) : 'Not published yet' }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="actions-col-header"></th>
          <td mat-cell class="actions value-actions" *matCellDef="let report">
            <button mat-icon-button class="faq-button" (click)="onFaqRow(report.id); $event.stopPropagation(); (false)" disableRipple matTooltip="Manage FAQs">
              <mat-icon class="edit-icon">forum</mat-icon>
            </button>
            <button mat-icon-button class="edit-button" (click)="onEditRow(report.id); $event.stopPropagation(); (false)" disableRipple matTooltip="Edit">
              <mat-icon class="edit-icon">edit</mat-icon>
            </button>
            <button mat-icon-button class="delete-button" (click)="onDeleteRow(report.id); $event.stopPropagation(); (false)" disableRipple matTooltip="Delete">
              <mat-icon class="delete-icon">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row (click)="onEditRow(row.id)" *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </section>
  </ng-template>
</ng-container>
