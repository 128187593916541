<!-- This tab will only appear when asset is in Fundraising status -->

<form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" class="form-grid-new terra-error-style">
<!-- Asset photos -->
<h3 class="section-title">
  Upload asset photos (optional, highly recommended)
</h3>

<ng-container formArrayName="assetPhotos">
  <div class="photo-uploaders-row full-width">
    <terra-image-uploader-thumbnail formControlName="0"
                                    [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
    <terra-image-uploader-thumbnail formControlName="1"
                                    [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
    <terra-image-uploader-thumbnail formControlName="2"
                                    [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
    <terra-image-uploader-thumbnail formControlName="3"
                                    [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
  </div>

  <a class="add-more-photos-link full-width" (click)="showSecondPhotosRow = true" *ngIf="!showSecondPhotosRow">
    <mat-icon class="notranslate" color="primary">add_circle</mat-icon>
    Add more photos
  </a>

  <div class="photo-uploaders-row second-row full-width" *ngIf="showSecondPhotosRow">
    <terra-image-uploader-thumbnail formControlName="4"
                                    [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
    <terra-image-uploader-thumbnail formControlName="5"
                                    [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
    <terra-image-uploader-thumbnail formControlName="6"
                                    [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
    <terra-image-uploader-thumbnail formControlName="7"
                                    [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
  </div>
  <p class="error-message full-width" *ngIf="pageForm.get('assetPhotos').invalid">The images marked in red are of an
    unsupported file
    type.
  </p>
  <p class="file-uploader-hint new-column">
    File format must be JPG, JPEG or PNG (20 MB max file size)
  </p>
</ng-container>

@if(isStatusFundRaising) {
  <h3 class="section-title">Upload an offering deck (optional)</h3>

  <!-- Marketing document uploader -->
  <terra-marketing-document-uploader
  docControlName="marketingDeckDocument"
  [isSubmitAttempt]="isSubmitAttempt"
  docZoomControlName="marketingDeckDocumentZoom"
  docZoomValControlName="marketingDeckDocumentZoomValue">
  </terra-marketing-document-uploader>

  <p class="file-uploader-hint new-column">
    File format must be JPG, JPEG, PNG (20 MB max file size) or PDF (50 MB max
    file size)
  </p>
}
@else {
  <h3 class="section-title">Select a Contribution for Deck Presentation:</h3>
  <span class="full-width mb-3 mt-0 file-uploader-hint">If you would like to display an offering deck/PDF on your Invest Now opportunity page, select the contribution that includes this deck.</span>
  <mat-form-field appearance="outline" class="full-width">
    <mat-select
      [placeholder]="fundraisings.length ? 'Select a contribution' : 'No presentations added yet'"
      formControlName="defaultOfferingDeckContribution"
    >
      <mat-option
        *ngFor="let item of fundraisings"
        [value]="item.id"
        >{{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
}

  <div class="visibility-token-section sub-section full-width">
    <p-tag class="new-tag" value="New!"></p-tag>
    <div class="section-sub-title">
      <span class="sub-title-text">Is visible in opportunities page<mat-slide-toggle hideIcon labelPosition="before" class="visible-switch" formControlName="isVisible"></mat-slide-toggle></span>
      <span class="sub-title-info">If turned on, this opportunity will be available on the opportunities page</span>
      </div>
  </div>

  <div class="add-video-section sub-section full-width">
    <div class="section-sub-title">
      <span>Add a Video</span>
      <span class="sub-title-info">Upload your video to YouTube as an "Unlisted" or "Public" and paste the URL below</span>
      </div>
      <div class="field-with-stacked-button" *ngIf="!pageForm.get('shouldShowPlayer').value">
        <mat-form-field appearance="outline">
          <input matInput id="txtVideoLink" formControlName="video" class="lo_sensitive"
                 placeholder="Paste or type a link"/>
        </mat-form-field>
        <button class="add-video-button"  style="height: 48px; margin-left: 8px;" mat-raised-button color="primary" (click)="addVideo()">Add</button>
      </div>
      <div *ngIf="pageForm.get('shouldShowPlayer').value" class="ytplayer-container"><mat-icon (click)="removeVideo()">cancel</mat-icon><iframe id="ytplayer" type="text/html" width="314" height="181"
        [src]="videoUrl"
        frameborder="0"></iframe></div>
  </div>

  <div class="sub-section full-width">
    <div class="section-sub-title">
      <span>Opportunity Description</span>
      <span class="sub-title-info">Include an essential opportunity overview to engage potential investors.</span>
      </div>
      <quill-editor id="txtDescription" formControlName="description" format="html" class="lo_sensitive description-editor" placeholder="" />
  </div>

  <div class="sub-section full-width">
    <div class="section-sub-title">
      <span>Additional Documents</span>
      <span class="sub-title-info">Up to 10 attachments.</span>
      <p class="file-uploader-hint">
        Works for JPG, PNG formats (max size 20MB), or PDF (max size 50MB)
      </p>
    </div>
    <div formArrayName="attachments" class="more-attachments">
      <ng-container *ngFor="let attachment of attachments; let ind = index">
        <terra-file-uploader class="file-uploader" no-type-select [formControlName]="ind"
          [isSubmitted]="isSubmitAttempt" [required]="false">
        </terra-file-uploader>
      </ng-container>
    </div>
  </div>
</form>
