import {ChangeDetectionStrategy, Component, OnInit, Input, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {filter, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import {GpHoldingService} from '../../gp-holding.service';
import {UtilsService} from '../../../../../services/utils.service';
import {UploadOfferingDeckDialogComponent} from './upload-offering-deck-dialog/upload-offering-deck-dialog.component';
import {SnackbarService} from '../../../../../services/snackbar.service';
import {LoggerService} from '../../../../../shared/errors/logger.service';
import {SendOfferingDeckDialogComponent} from './send-offering-deck-dialog/send-offering-deck-dialog.component';
import {InvestorEngagementDialogComponent} from './investor-engagement-dialog/investor-engagement-dialog.component';
import FundraisingStatus from '../../../../../shared/enums/FundraisingStatus.enum';
import {FundraisingDetailsInterface} from '../fundraisings-tab/edit-fundraising-dialog.context';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import { AppService } from 'src/app/state';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { UserService } from 'src/app/services/shared/user.service';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';

@Component({
  selector: 'terra-bulk-offering-deck',
  templateUrl: './bulk-offering-deck.component.html',
  styleUrls: ['./bulk-offering-deck.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkOfferingDeckComponent implements OnInit {
  @Input()
  fundraisingService: FundraisingDetailsInterface;
  @Input() hasCreBank: boolean = false;
  FeatureFlags = FeatureFlags;
  fundraising$ = this.holdingService.fundraising$;
  tooltipUploadDeck ="File format must be JPG,JPEG,PNG (20 MB max file size) or PDF (50 MB max file size)."
  isSentOfferingDeck$ = this.fundraising$.pipe(map(fundraising => fundraising.isSentOfferingDeck));
  marketingDeckLink$ = combineLatest([this.userService.accountDetails$, this.holdingService.holdingId$]).pipe(
    map(([details, holdingId]) => `${this.appSettingsService.lpFrontEnvironmentAppBaseUrl}lp/opportunity/${details.organizationDetails.referralKey}/campaign/${holdingId}`),
  );
  isActiveFundraising$ = this.fundraising$.pipe(
    map(fundraising => fundraising.status === FundraisingStatus.InProgress || fundraising.status === FundraisingStatus.None),
    shareReplay(1)
  );
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public holdingService: GpHoldingService,
    private appSettingsService: AppSettingsService,
    public userService: UserService,
    private utilsService: UtilsService,
    private dialog: MatDialog,
    public snackbarService: SnackbarService,
    private loggerService: LoggerService,
    private vcr: ViewContainerRef) {
  }

  ngOnInit(): void {
  }

  openUploadModal() {
    this.fundraising$.pipe(take(1)).subscribe(fundraising => {
      const config = new MatDialogConfig();
      config.disableClose = true;
      config.data = fundraising;
      this.dialog.open(UploadOfferingDeckDialogComponent, config)
        .afterClosed()
        .pipe(take(1))
        .subscribe(updatedFundraising => {
            if (updatedFundraising) {
              this.holdingService.onUpdatingOfferingDeck(updatedFundraising);
              this.snackbarService.showGeneralMessage('Saved offering deck');
            }
          },
          error => {
            this.loggerService.warning('error uploading offering deck: ', error);
            this.snackbarService.showGeneralMessage('An error occurred, offering deck was not saved.');
          });
    });
  }

  openSendModal() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = this.holdingService;
    config.panelClass = 'send-offering-deck-dialog';
    this.dialog.open(SendOfferingDeckDialogComponent, config)
      .afterClosed()
      .pipe(
        tap(success => {
          if (success === true) {
            this.snackbarService.showGeneralMessage('Sending offering deck');
          }
          else if (success === false) {
            this.snackbarService.showGeneralMessage('An error occurred, please try again later');
          }
        })
      ).subscribe();
  }

  openEngagementModal() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.viewContainerRef = this.vcr;
    this.dialog.open(InvestorEngagementDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.holdingService.updateHoldingFromServer();
      });
  }

  removeOfferingDeck() {
    this.fundraising$.pipe(take(1)).subscribe(fundraising => {
      if (!fundraising.offeringDeck) {
        return;
      }
      this.isLoading$.next(true);
      this.fundraisingService.deleteOfferingDeck(fundraising.offeringDeck)
        .pipe(take(1))
        .subscribe(fr => {
          this.holdingService.onUpdatingOfferingDeck(fr);
          this.snackbarService.showGeneralMessage('Offering deck deleted');
          this.isLoading$.next(false);
        }, error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            this.snackbarService.showGeneralMessage('An error occurred, please try again later');
          }

          this.isLoading$.next(false);
          });
    });
  }
  notifyCopied(e: Event) {
    this.snackbarService.showGeneralMessage("Opportunity Page Link Has Copied Successfully");
    e?.preventDefault();
  }
}
