import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { enterAnimation } from 'src/app/shared/animations';
import { CreateAssetService, CreateAssetStepNumber } from './create-asset.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { TerraUtils } from 'src/app/shared/TerraUtils';

@Component({
  selector: 'terra-create-asset',
  templateUrl: './create-asset.component.html',
  styleUrls: ['./create-asset.component.scss'],
  animations: [enterAnimation],
  providers: [CreateAssetService]
})
export class CreateAssetComponent implements OnInit {

  processingSubmit$ = this.createAssetService.processingSubmit$;
  currentStep$ = this.createAssetService.currentStep$;

  constructor(private titleService: Title, private createAssetService: CreateAssetService, private dialogService: DialogService) { }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.createAssetService.createAssetForm.pristine && !this.createAssetService.assetCreated) {
      const params = new ConfirmDialogParams('Cancel asset?', 'Any information you added will be lost', 'Cancel Asset', 'Go back');
      return this.dialogService.confirmDialog(params).afterClosed();
    }
    return true;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Create Asset' + TerraUtils.consts.GpPageTitleSuffix);
  }

  navigateBackToStep(step: CreateAssetStepNumber) {
    const currentStep = this.currentStep$.value;
    if (currentStep > step) {
      this.currentStep$.next(step);
    }
  }

  cancel() {
    this.createAssetService.cancel();
  }
}
