import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {filter, map, pluck} from 'rxjs/operators';

import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {HoldingFundraisingService} from '../../holding-fundraising.service';
import {ApexLegend, ChartComponent} from 'ng-apexcharts';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import { NumberUtils } from 'src/app/shared/utils/number-utils';


interface FundraisingDoughnutData {
  investedAmount: number;
  calledAmount: number;
  signedAmount: number;
  softCircleAmount: number;
  potentialAmount: number;
  declinedAmount: number;
  unaccountedAmount: number;
}

@Component({
  selector: 'terra-fundraising-doughnut-chart',
  templateUrl: './fundraising-doughnut-chart.component.html',
  styleUrls: ['./fundraising-doughnut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundraisingDoughnutChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;

  readonly colors = ['#3D41EB', '#6CDBF5', '#03EEF5',  '#98BAE7', '#18364A', '#FF334C', '#BEC5C9'];
  readonly labels = ['Invested', 'Called', 'Signed', 'Soft Circled', 'Potential', 'Declined', 'Unaccounted'];

  chartOptions: ChartComponent;
  chartSeries$;
  chartLegend$;

  constructor(
    private holdingFundraisingService: HoldingFundraisingService,
    private terraNumber: TerraNumberPipe
    ) {
    this.setChartOption();
    this.setChartSeries();
    this.setChartLegend();
  }

  ngOnInit() {
  }

  private setChartSeries() {
    this.chartSeries$ = this.holdingFundraisingService.fundraising$.pipe(
      filter(fundraising => !!fundraising.fundraisingTargetAmount),
      map(fundraising => {
        const data = {
          investedAmount: NumberUtils.reduceDecimal(fundraising.investments.filter(inv => inv.status === InvestmentStatus.Invested)
                                                 .map(inv => inv.finalAmount)),
          calledAmount: NumberUtils.reduceDecimal(fundraising.investments.filter(inv => !!inv.paymentRequestSendDate && inv.status === InvestmentStatus.Signed)
                                                .map(inv => inv.estimatedAmount)),
          signedAmount: NumberUtils.reduceDecimal(fundraising.investments.filter(inv => inv.status === InvestmentStatus.Signed && !inv.paymentRequestSendDate)
                                               .map(inv => inv.estimatedAmount)),
          softCircleAmount: NumberUtils.reduceDecimal(fundraising.investments.filter(inv => inv.status === InvestmentStatus.SoftCircled)
                                                   .map(inv => inv.estimatedAmount)),
          potentialAmount: NumberUtils.reduceDecimal(fundraising.investments.filter(inv => inv.status === InvestmentStatus.Potential)
                                                  .map(inv => inv.estimatedAmount)),
          declinedAmount: NumberUtils.reduceDecimal(fundraising.investments.filter(inv => inv.status === InvestmentStatus.Declined)
                                                 .map(inv => inv.estimatedAmount))
        } as FundraisingDoughnutData;
        let unaccountedAmount = (fundraising.fundraisingTargetAmount - data.investedAmount - data.calledAmount - data.signedAmount - data.softCircleAmount - data.potentialAmount - data.declinedAmount);
        unaccountedAmount = unaccountedAmount > 0 ? unaccountedAmount : 0;
        return Object.values({ ...data, unaccountedAmount });
      }));
  }

  private setChartLegend(){
    this.chartLegend$ = this.holdingFundraisingService.fundraising$.pipe(
      pluck('fundraisingTargetCurrency', 'symbol'),
      map(symbol => {
      const legend = {
        position: 'right',
        fontSize: '16px',
        fontWeight: 500,
        width: 225,
        formatter: (label, opts) => {
          return `${label} - ${symbol || ''}${this.terraNumber.transform(opts.w.globals.series[opts.seriesIndex] || 0)}`;
        },
        itemMargin: {
          vertical: 5
        }
      } as ApexLegend;
      return legend;
    }));
  }

  private setChartOption() {
    this.chartOptions = {
      colors: this.colors,
      chart: {
        type: 'donut',
      },
      labels: this.labels,
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y:{
          formatter: (val: number, opts?: any): string => {
            return this.terraNumber.transform(val);
          }
        }
      },
      states: {
        hover: {
         filter: {
            type: 'darken',
            value: 0.55,
          }
        }
      }
    } as ChartComponent;
  }
}
