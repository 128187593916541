import { Component, OnInit } from '@angular/core';
import { CreateFundAssetStepBase } from '../CreateFundAssetStepBaseAndInterface';
import { CreateFundAssetStepNumber, CreateFundAssetService } from '../create-fund-asset.service';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';

@Component({
  selector: 'terra-create-fund-asset-marketing-material-step',
  templateUrl: './create-fund-asset-marketing-material-step.component.html',
  styleUrls: ['./create-fund-asset-marketing-material-step.component.scss']
})
export class CreateFundAssetMarketingMaterialStepComponent extends CreateFundAssetStepBase implements OnInit {
  HoldingDiscriminator = HoldingDiscriminator;

  readonly STEP_NUMBER: CreateFundAssetStepNumber = 3;

  showSecondPhotosRow = false;

  constructor(private createFundAssetService: CreateFundAssetService) {
    super(createFundAssetService);
  }

  ngOnInit() {
    this.createFundAssetService.currentStep$.next(this.STEP_NUMBER);
  }
}
