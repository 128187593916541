import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import {ClientBankAccountResponseBasic} from 'src/app/dashboard/models/bankAccount.model';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ResourceService} from 'src/app/services/resource.service';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {InvestmentType} from 'src/app/shared/enums/InvestmentType.enum';
import {CreateAssetService, CreateAssetStepNumber} from '../create-asset.service';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import {AppSettingsService} from 'src/app/services/app-settings.service';
import {CreateAssetStepBase} from '../CreateAssetStepBaseAndInterface';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {AddressFields, AddressFormSettings} from 'src/app/shared/components/address-form/address-form.settings';
import {UserService} from 'src/app/services/shared/user.service';
import { OwnershipCalculationType } from 'src/app/shared/enums/OwnershipCalculationType.enum';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DialogService } from 'src/app/services/dialog.service';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';

@Component({
  selector: 'terra-create-asset-information-step',
  templateUrl: './create-asset-information-step.component.html',
  styleUrls: ['./create-asset-information-step.component.scss']
})
export class CreateAssetInformationStepComponent extends CreateAssetStepBase implements OnInit {
  readonly STEP_NUMBER: CreateAssetStepNumber = 1;
  readonly OwnershipCalculationType = OwnershipCalculationType;
  
  showInternalLoader = false;
  defaultCurrencyISO = 'USD';

  // enum
  HoldingType = HoldingType;
  InvestmentType = InvestmentType;
  AreaUnit = AreaUnit;
  InvestmentSecurityType = InvestmentSecurityType;

  // Enum value lists
  assetTypesList = HoldingType.listAll();
  InvestmentTypesList = InvestmentType.listAll();
  InvestmentSecurityTypesList = InvestmentSecurityType.optionsForAssetFundraising();

  /// The date that tha picker will open to
  estimatedClosingDateStartAt = new Date();
  estimatedClosingDateMinimumDate = new Date('1970-01-01T12:00');

  currencies$: Observable<CurrencyModel[]>;
  bankAccounts$: Observable<ClientBankAccountResponseBasic[]>;

  allCountries = this.resourceService.allCountries;
  allStates = this.resourceService.allStates;
  countryCodes = TerraUtils.consts.countryCode;
  USCountryId = this.resourceService.getCountryByCode(this.countryCodes.US).id;
  forbiddenCharsInHoldingName = this.createAssetService.forbiddenCharsInHoldingName;

  allowedDataPointsListFor = TerraUtils.consts.allowedDataPointsListFor;

  addressFormSettings = new AddressFormSettings(this.createAssetService.addressFormFieldsToShow, AddressFields.Country);
  location = this.createAssetService.createAssetForm.value.step1.assetLocation;

  CapitalCommitmentAllowed = false;


  get addressForm() {
    return this.pageForm.get('assetLocation') as UntypedFormGroup;
  }

  get selectedSecurityType(): InvestmentSecurityType {
    return this.createAssetService.selectedSecurityType;
  }

  constructor(
    private telemetryService: TelemetryService,
    private resourceService: ResourceService,
    private createAssetService: CreateAssetService,
    private appSettingService: AppSettingsService,
    private userService: UserService,
    private dialogService: DialogService
  ) {
    super(createAssetService);
  }

  ngOnInit() {
    this.isSubmitted = false;
    this.createAssetService.currentStep$.next(this.STEP_NUMBER);

    this.getCurrencies();

    this.fillMockData();
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
      if (FeatureFlags.isOn(clientDetails.featureFlags, FeatureFlags.AssetsCapitalCommitments)) {
        this.CapitalCommitmentAllowed = true;
      }
      this.telemetryService.create({
        eventID: '11',
        eventTitle: 'CREATE ASSET (INIT)',
        organizationID: clientDetails.organizationDetails.id
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });
  }

  isDisplayDataPoint(assetTypeList: HoldingType[]): boolean {
    if (!this.pageForm.get('holdingType').value) {
      return false;
    }
    const holdingType = Number(this.pageForm.get('holdingType').value) as HoldingType;
    return assetTypeList.some(val => val === holdingType);
  }

  getCurrencies() {
    this.showInternalLoader = true;
    this.resourceService.getOutboundCurrencies().subscribe(response => {
      this.currencies$ = of(response.filter(c => !c.isInbound));
      // Set default currency in dropdown if it is available
      const defaultCurrency = response.filter(c => c.iso === this.defaultCurrencyISO)[0];
      if (defaultCurrency) {
        this.pageForm.get('currency').setValue(defaultCurrency);
      }
      this.showInternalLoader = false;
    });
  }

  conutryTrackByFn(index, item: CountryModel) {
    return item.id; // or index
  }

  currencyCompareFn(c1: CurrencyModel, c2: CurrencyModel) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  cancel() {
    this.createAssetService.cancel();
  }

  enableCommitmentsChanged($event: MatSlideToggleChange) {
    if ($event.checked && !this.CapitalCommitmentAllowed) {
      this.showUpgradePopup(() => $event.source.toggle());
    }
  }

  showUpgradePopup(actionAfter) {
    const confirmDialogParams = new ConfirmDialogParams();
    confirmDialogParams.actionLabel = 'Upgrade Plan';
    confirmDialogParams.title = 'Upgrade Your Subscription Plan';
    confirmDialogParams.description = `To manage Capital Commitments, you need to upgrade your subscription.
    This feature, along with other advanced capabilities, is available in our Pro subscription plan. Upgrade today to unlock these benefits!`;
    this.dialogService
      .confirmDialog(confirmDialogParams)
      .afterClosed()
      .pipe(untilComponentDestroyed(this))
      .subscribe(isConfirmed => {
        actionAfter();
        if (isConfirmed) {
          const url = 'https://www.covercy.com/contact-us/';
          window.open(url, '_blank');
        }
      });
  }

  fillMockData() {
    // if (this.appSettingService.mockData && !this.pageForm.get('assetName').value) {
    //   this.pageForm.patchValue({
    //     assetName: 'Test Asset',
    //     legalName: 'ABC Corps.',
    //     estimatedClosingDate: new Date('2019-12-12'),
    //     // currency: 4,
    //     fundraisingTargetAmount: 1000000,
    //     minimumInvestmentAmount: 20000,
    //     assetType: 2,
    //     investmentType: 1,

    //     areaUnits: AreaUnit.SquareFeet,
    //     lotSizeArea: null,
    //     stories: null,
    //     residentialUnits: null,
    //     retailUnits: null,
    //     rooms: null,
    //     grossBuildingArea: null,
    //     residentialNetSellableArea: null,
    //     retailNetSellableArea: null,
    //     assetLocation: {
    //       countryId: 117,
    //       stateName: null,
    //       cityName: 'Test City',
    //       postalCode: '214213'
    //     }
    //   });
    // }
  }
}



