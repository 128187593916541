import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {
  debounceTime,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  catchError,
  finalize,
  filter,
} from 'rxjs/operators';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Table} from 'primeng/table';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';

import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {GpFundListItemReqRes} from './GpFundReqRes.model';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {AppQuery, AppService} from 'src/app/state';
import {GpFundsPageTabs} from 'src/app/shared/enums/GpFundsPageTabs.enum';
import {GpFundDataService} from 'src/app/services/gp/gp-fund-data.service';
import FundOrderBy from 'src/app/shared/enums/FundOrderBy.enum';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import {UserService} from 'src/app/services/shared/user.service';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {BehaviorSubject, combineLatest, of, EMPTY, Observable} from 'rxjs';
import PermissionLevel from '../../permission/enums/permissionLevel.enum';
import {FinancialPermissionLevel} from 'src/app/permission/enums/financialPermissionLevel.enum';
import {PermissionService} from 'src/app/permission/permission.service';
import {FilterMetadata, MenuItem, SortEvent} from 'primeng/api';
import {IdValue} from '../../shared/models/IdValue.model';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {Router} from '@angular/router';
import {DynamicDialogParams} from 'src/app/shared/components/dynamic-dialog/dynamic-dialog.component';
import {DynamicComponentItem} from 'src/app/shared/models/dynamic-component-loader/DynamicComponentItem.model';
import {ViewAsInvestorContactsComponent} from '../contacts/components/view-as-investor-contacts/view-as-investor-contacts.component';
import {ImpersonationService} from 'src/app/services/shared/impersonation.service';
import ViewAsInvestorPermission from 'src/app/permission/enums/view-as-investor-permission';
import {RoutingService} from 'src/app/services/routing.service';
import {AlertDialogParams} from '../../shared/components/alert-dialog/alert-dialog.component';
import {ConfirmDialogParams} from '../../shared/components/confirm-dialog/confirm-dialog.component';
import {DialogService} from '../../services/dialog.service';
import {GpHoldingOverviewItemReqRes} from '../shared/holding/GpHoldingOverviewItemReqRes';
import {InvestorCommunicationDialogComponent} from '../shared/investor-communication/investor-communication-dialog/investor-communication-dialog.component';
import {GpHoldingDataService} from 'src/app/services/gp/gp-holding-data.service';
import {InvestorCommunicationResultSnackbarComponent} from '../shared/investor-communication/investor-communication-result-snackbar/investor-communication-result-snackbar.component';
import SectionsAccess from 'src/app/permission/enums/sectionsAccess.enum';
import {RecipientType} from '../shared/investor-communication/recipient-type';
import {ContactsGroupType} from '../shared/investor-communication/contacts-group-type';
import {AddBankAccountDialogComponent} from '../shared/holding/add-bank-account-dialog/add-bank-account-dialog.component';

@Component({
  selector: 'terra-funds',
  templateUrl: './funds.component.html',
  styleUrls: ['./funds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundsComponent extends OnDestroyMixin implements OnInit {
  @HostBinding('attr.class') hostClasses = 'container';
  @ViewChild('pTable') pTable: Table;

  // consts
  readonly FundStatus = HoldingStatus;
  readonly FundOrderBy = FundOrderBy;
  readonly PRIVATE_FUND_ERROR = 'PRIVATE_FUND';

  ViewAsInvestorPermission = ViewAsInvestorPermission;

  isToShowBalances: boolean;
  dealsBalancesLoadingError = false;
  tabIndex$ = this.appQuery.select((state) => state.gp.uiPrefs.funds.activeTab);
  orderBy$ = this.appQuery.select(
    (state) => state.gp.uiPrefs.funds.fundsOrderBy
  );
  refreshData$ = new BehaviorSubject<void>(null);
  statusesForSorting = HoldingStatus.listAll();
  allowEditHolding$ = this.permissionService.checkPermission(
    PermissionLevel.Editor
  );
  enableInvestorPortalToggle$ = this.allowEditHolding$.pipe(
    switchMap(([result, _]) => of(result))
  );
  enableAddBankButton$ = this.permissionService
    .checkPermission(
      PermissionLevel.Editor,
      FinancialPermissionLevel.AuthorizedSignatory
    )
    .pipe(switchMap(([result, _]) => of(result)));

  funds$ = combineLatest([this.orderBy$, this.refreshData$]).pipe(
    debounceTime(100),
    switchMap(([orderBy, _]) => this.gpFundDataService.getDealsList(orderBy)),
    tap((h) =>
      h.sort((a, b) => {
        if (a.status !== b.status) {
          return (
            this.statusesForSorting.indexOf(a.status) -
            this.statusesForSorting.indexOf(b.status)
          );
        } else {
          return a.name.localeCompare(b.name);
        }
      })
    ),
    shareReplay(1)
  );

  dealsBalances$ = combineLatest([
    this.funds$,
    this.permissionService.showBankInfo$,
  ]).pipe(
    debounceTime(100),
    tap(([_, isToShow]) => {
      this.isToShowBalances = isToShow;
    }),
    switchMap(([holdings, isToShow]) =>
      isToShow
        ? combineLatest([
          of(holdings),
          this.gpFundDataService.getDealsBalances(),
        ])
        : combineLatest([
          of(holdings),
          of(this.generateRandomBalancesForHoldings(holdings)),
        ])
    ),
    tap(([holdings, balances]) => {
      balances.forEach((balance: IdValue<number>) => {
        const holding = holdings.find((h) => h.id === balance.id);
        if (holding) {
          holding.balance = balance.value;
        }
      });
    }),
    catchError((error) => {
      this.dealsBalancesLoadingError = true;
      return of([new IdValue<number>()]);
    }),
    shareReplay(1)
  );

  useFund: boolean;
  availableStatuses$ = this.funds$.pipe(
    map((funds) => {
      const statusCounts = funds.reduce(
        (acc, value) => ({
          ...acc,
          [value.status]: acc[value.status] ? ++acc[value.status] : 1,
        }),
        {}
      );
      return HoldingStatus.listAll().map((hs) => ({
        value: hs,
        label: `${HoldingStatus.toString(hs)} (${statusCounts[hs] ?? 0})`,
      }));
    })
  );
  selectedStatus: HoldingStatus;
  selectedStatuses: HoldingStatus[] =
    JSON.parse(sessionStorage.getItem('covercy_funds') || '{}')?.filters?.[
      'status'
    ]?.value ||
    HoldingStatus.listAll().filter(
      (status) =>
        status != HoldingStatus.Realized && status != HoldingStatus.Canceled
    );
  filteringText: string =
    JSON.parse(sessionStorage.getItem('covercy_funds') || '{}')?.filters?.[
      'name'
    ]?.value || null;
    readonly initFilters: { [key in keyof {status: HoldingStatus}]: FilterMetadata[] } = {
      status: [{ value: this.selectedStatuses , matchMode: "in" }]
    };
  newFundMenuItems: MenuItem[];
  menuItems: MenuItem[] | undefined;
  rowMenuItems: MenuItem[] | undefined;

  orderByOptions$ = this.tabIndex$.pipe(
    map((tab) => {
      switch (tab) {
        case GpFundsPageTabs.Fundraisings:
          return FundOrderBy.optionsForFundraisingFunds();
        case GpFundsPageTabs.UnderManagement:
          return FundOrderBy.optionsForUnderManagementFunds();
        case GpFundsPageTabs.Canceled:
          return FundOrderBy.optionsForCanceledFunds();
        case GpFundsPageTabs.Draft:
          return FundOrderBy.optionsForDraftAssets();
        case GpFundsPageTabs.Realized:
          return FundOrderBy.optionsForRealizedAssets();
      }
    })
  );

  constructor(
    private appService: AppService,
    private appQuery: AppQuery,
    private gpFundDataService: GpFundDataService,
    private holdingDataService: GpHoldingDataService,
    private userService: UserService,
    private titleService: Title,
    private telemetryService: TelemetryService,
    private permissionService: PermissionService,
    private snackbarService: SnackbarService,
    private router: Router,
    private routingService: RoutingService,
    private dialogService: DialogService,
    private impersonationService: ImpersonationService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.titleService.setTitle('Funds' + TerraUtils.consts.GpPageTitleSuffix);

    this.userService.accountDetails$.subscribe((accountDetails) => {
      this.useFund = FeatureFlags.isOn(
        accountDetails.featureFlags,
        FeatureFlags.Fund
      );
    });
    this.userService
      .getClientDetails()
      .pipe(take(1))
      .subscribe((clientDetails) => {
        this.telemetryService.create(
          {
            eventID: '7',
            eventTitle: 'GP FUND - HOME',
            organizationID: clientDetails.organizationDetails.id,
          },
          AnalyticsServiceNameModel.Mixpanel |
          AnalyticsServiceNameModel.Insights
        );
      });
    this.menuItems = [
      {
        label: 'Actions',
        items: [
          {
            label: 'Update',
            icon: 'pi pi-refresh',
            command: () => {
              alert('update');
            },
          },
          {
            label: 'Delete',
            icon: 'pi pi-times',
            command: () => {
              alert('delete');
            },
          },
        ],
      },
      {
        label: 'Extra',
        items: [
          {
            label: 'Assets',
            icon: 'pi pi-home',
            target: '_self',
            url: '/',
          },
          {
            label: 'Export',
            icon: 'pi pi-file-export',
            command: () => {
              alert('Export');
            },
          },
        ],
      },
    ];
    this.newFundMenuItems = [
      {
        label:
          '<div class="new-fund-menu-item-label"><span class="new-fund-menu-item-label-title">Under Management</span><span class="new-fund-menu-item-label-description">Great for managing and tracking funds that are currently under management.</span></div>',
        escape: false,
        styleClass: 'new-fund-menu-item',
        routerLink: 'create-draft',
      },
      {
        label:
          '<div class="new-fund-menu-item-label"><span class="new-fund-menu-item-label-title">Initial Fundraising</span><span class="new-fund-menu-item-label-description">Great for managing initial fundraising and facilitating capital calls.</span></div>',
        escape: false,
        styleClass: 'new-fund-menu-item',
        routerLink: 'create',
      },
    ];
    const allowView$ = this.userService
      .getClientDetails()
      .pipe(map((cd) => cd.userPermission.viewAsInvestor));

    this.rowMenuItems = [
      {
        label: 'View as investor',
        icon: 'pi pi-eye',
        command: () => {
        },
      },
    ];
  }

  sortBy(orderBy: FundOrderBy) {
    this.appService.updateFundsSortState(orderBy);
  }

  onRowSelect(event) {
    console.log(event);
  }

  tableSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = value1 - value2;
      }

      return event.order * result;
    });
  }

  tabChanged(event: MatTabChangeEvent) {
    this.appService.updateFundsActiveTabState(event.index as GpFundsPageTabs);
  }

  private filterFundsByStatus(
    allFunds: GpFundListItemReqRes[],
    status: HoldingStatus
  ) {
    return allFunds ? allFunds.filter((asset) => asset.status === status) : [];
  }

  archivedSuccess() {
    this.refreshData$.next();
  }

  openViewAsInvestorDialog() {
    let dialog = null;
    const viewAsInvestorContactsComponent = new DynamicComponentItem(
      ViewAsInvestorContactsComponent,
      {output: (val) => this.openViewAsInvestorTab(val, dialog)}
    );

    this.userService
      .getClientDetails()
      .pipe(take(1))
      .subscribe((clientDetails) => {
        this.telemetryService.create(
          {
            eventID: '1221',
            sourcePage: 'GP Fund Table',
            eventTitle: 'GP-ViewAsInvestor-Clicked',
            clickFrom: 'View as Investor Button',
            isCovercyUser: clientDetails.userName.includes('@covercy.com'),
          },
          AnalyticsServiceNameModel.Mixpanel |
          AnalyticsServiceNameModel.Insights
        );
      });

    const dynamicDialogParams = new DynamicDialogParams();
    dynamicDialogParams.title = 'View as Investor';
    dynamicDialogParams.subTitle = 'Select an investor to view their Investor Portal.';
    dynamicDialogParams.dynamicComponent = viewAsInvestorContactsComponent;
    dynamicDialogParams.hideAction = true;
    dialog = this.dialogService.dynamicDialog(dynamicDialogParams);
  }

  openViewAsInvestorTab(contact, dialog) {
    this.impersonationService.viewAsInvestor(contact).subscribe({
      next: (_) => {
        if (!!dialog) {
          dialog.close();
        }
      },
      error: (err) => {
        this.errorAlertModel();
      },
    });
  }

  private errorAlertModel() {
    const dialogParams = new AlertDialogParams();

    dialogParams.title = `An error has occurred`;
    dialogParams.description = `An error has occurred. Please try again or contact support.`;
    dialogParams.actionLabel = `OK`;

    this.dialogService.alertDialog(dialogParams);
  }

  generateRandomBalancesForHoldings(holdings: any[]): IdValue<number>[] {
    const randomLowerBound = 100000;
    const randomUpperBound = 10000000;
    return holdings.map(
      (h) =>
      ({
        id: h.id,
        value:
          Math.floor(Math.random() * randomUpperBound) + randomLowerBound,
      } as IdValue<number>)
    );
  }

  investorPortalChanged(holding: GpHoldingOverviewItemReqRes) {
    this.getHoldingInvestorVisibilityDialog(holding)
      .pipe(
        take(1),
        switchMap((value) =>
          value
            ? this.getHoldingInvestorVisibilitySuccessDialog(holding)
            : this.getHoldingInvestorVisibilityFailedDialog()
        ),
        catchError((err) => {
          holding.investorPortal = !holding.investorPortal;
          this.getHoldingInvestorVisibilityFailedDialog();
          return EMPTY;
        })
      )
      .subscribe();
  }

  getHoldingInvestorVisibilityDialog(
    holding: GpHoldingOverviewItemReqRes
  ): Observable<boolean> {
    const dialogParams = new ConfirmDialogParams();
    dialogParams.actionLabel = `Proceed`;
    dialogParams.title = `<span class='large-title'>Investor Visibility</span>`;
    dialogParams.actionPosition = 'right';
    dialogParams.description = holding.investorPortal
      ? `Would you like to display this holding to your investors in the Investor Portal?`
      : `Would you like to stop displaying this holding to your investors in the Investor Portal?`;

    return this.dialogService
      .confirmDialog(dialogParams)
      .afterClosed()
      .pipe(
        tap((_) => {
          holding.isUpdating = true;
        }),
        switchMap((confirm) => {
          if (!!confirm) {
            return this.gpFundDataService.updateFundVisibility(holding);
          } else {
            holding.investorPortal = !holding.investorPortal;
            return EMPTY;
          }
        }),
        finalize(() => {
          holding.isUpdating = false;
          this.cdr.markForCheck();
        })
      );
  }

  getHoldingInvestorVisibilitySuccessDialog(
    holding: GpHoldingOverviewItemReqRes
  ): Observable<any> {
    return holding.investorPortal
      ? this.getHoldingInvestorVisibilityShowingSuccessDialog(holding)
      : this.getHoldingInvestorVisibilityHidingSuccessDialog();
  }

  getHoldingInvestorVisibilityHidingSuccessDialog(): Observable<any> {
    this.dialogService.alertDialog(
      new AlertDialogParams(
        '<span class="large-title">Success!</span>',
        `The holding is no longer visible in the Investor Portal!`,
        'Close',
        undefined,
        undefined,
        'check_circle_outline',
        'green-icon',
        'right'
      )
    );
    return EMPTY;
  }

  getHoldingInvestorVisibilityShowingSuccessDialog(
    holding: GpHoldingOverviewItemReqRes
  ): Observable<any> {
    return this.permissionService
      .checkPermission(
        PermissionLevel.Editor,
        undefined,
        SectionsAccess.Contacts,
        true,
        undefined
      )
      .pipe(
        switchMap(([result, _]) => {
          return result
            ? this.getHoldingInvestorVisibilityShowingSuccessDialogWithEmail(
              holding
            )
            : this.dialogService
              .alertDialog(
                new AlertDialogParams(
                  '<span class="large-title">Success!</span>',
                  `The holding is now visible in the Investor Portal!`,
                  undefined,
                  undefined,
                  undefined,
                  'check_circle_outline',
                  'green-icon'
                )
              )
              .afterClosed();
        })
      );
  }

  getHoldingInvestorVisibilityShowingSuccessDialogWithEmail(
    holding: GpHoldingOverviewItemReqRes
  ): Observable<any> {
    const dialogParams = new ConfirmDialogParams();
    dialogParams.actionLabel = `Send Email`;
    dialogParams.cancelLabel = 'No Thanks!';
    dialogParams.actionPosition = 'right';
    dialogParams.title = `<span class='large-title'>Success!</span>`;
    dialogParams.description = `<div class='flex flex-column gap-4'><span>The holding is now visible in the Investor Portal!</span> <span class='font-semibold'>Would you like to notify your investors by email?</span></div>`;
    dialogParams.icon = 'check_circle_outline';
    dialogParams.iconClass = 'green-icon';
    return this.dialogService
      .confirmDialog(dialogParams)
      .afterClosed()
      .pipe(
        switchMap((confirm) => {
          if (!!confirm) {
            return this.getEmailInvestorsDialog(holding);
          } else {
            return EMPTY;
          }
        })
      );
  }

  getHoldingInvestorVisibilityFailedDialog(): Observable<any> {
    this.dialogService.alertDialog(
      new AlertDialogParams(
        'Failed!',
        `The holding couldn't be made visible in the Investor Portal. Please try again later.`,
        undefined,
        undefined,
        undefined,
        'error'
      )
    );
    return EMPTY;
  }

  getEmailInvestorsDialog(
    holding: GpHoldingOverviewItemReqRes
  ): Observable<any> {
    return this.holdingDataService.getInvestedContacts(holding.id).pipe(
      take(1),
      switchMap((investedContacts) =>
        this.dialogService
          .customDialog(InvestorCommunicationDialogComponent, {
            holding,
            contentTemplateId: 1,
            subject: 'Invite to Investor Portal',
            defaultSuggestions: [
              {
                id: holding.id,
                displayName: `${holding.name} Investors`,
                recipientType: RecipientType.Group,
                type: ContactsGroupType.Holding,
              },
            ],
          })
          .afterClosed()
      ),
      filter((result) => result),
      tap((result) =>
        this.snackbarService.showSnackbarFromComponent(
          InvestorCommunicationResultSnackbarComponent,
          result,
          2,
          'investor-communication-message-snackbar'
        )
      )
    );
  }

  openUnitApp(dealId: number) {
    this.router.navigate([this.routingService.createBankApplicationPage()], {
      queryParams: {holdingId: dealId},
    });
  }

  openAddBankAccountDialog(holdingId: number) {
    this.dialogService.customDialog<AddBankAccountDialogComponent>(AddBankAccountDialogComponent, {holdingId}, false, 'add-bank-account-dialog');
  }

  protected readonly holdingStatusEnum = HoldingStatus;

  getStatusClass(holding) {
    switch (holding.status) {
      case HoldingStatus.Draft:
        return 'bg-bluegray-100';
      case HoldingStatus.Fundraising:
        return 'bg-teal-100';
      case HoldingStatus.Owned:
        return 'bg-blue-100';
      case HoldingStatus.Realized:
        return 'bg-green-100';
      case HoldingStatus.Canceled:
        return 'bg-red-100';
      default:
        return 'info';
    }
  }

  getFileUrl(holding: any) {
    return (holding?.attachments[0]?.metaFileLink?.url || '/assets/images/plg/no-deal-image.svg');
  }

  showClear(status) {
    return status !== -1;
  }

  statusFilterChanged(itemValue: any, value: Array<HoldingStatus>) {
    if (!value.length) {
      value.push(itemValue);
    }
    this.filterChanged(value, 'status', 'in');
  }

  filterChanged(value: any, field: string, matchMode: string) {
    if (!value) {
      this.clearSearch(field);
    } else if (value) {
      this.pTable.filter(value, field, matchMode);
    }
  }

  clearSearch(field: string, searchInput: HTMLInputElement = null) {
    if (searchInput) {
      searchInput.value = null;
    }
    this.pTable.filter(null, field, '');
  }

  disableInvestorPortal(holding: any) {
    return holding.status === HoldingStatus.Draft || holding.status === HoldingStatus.Canceled;
  }
}
