import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {RoutingService} from '../services/routing.service';

@Component({
  selector: 'terra-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  buttonUrl = this.routingService.defaultLoggedInPageBySubdomain;

  constructor(private titleService: Title, private routingService: RoutingService) {
  }

  ngOnInit() {
    this.titleService.setTitle('Page Not Found');
  }
}
