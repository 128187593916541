import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';

import {UserService} from '../services/shared/user.service';
import {RoutingService} from '../services/routing.service';

@Injectable({
  providedIn: 'root'
})
export class AlreadyLoggedOutGuard  {
  constructor(private userService: UserService, private routingService: RoutingService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean |
    UrlTree |
    Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree> {

    const isLoggedIn = this.userService.isLoggedIn();
    if (!isLoggedIn) {
      return this.router.parseUrl(this.routingService.loginUrlBySubdomain);
    } else {
      return true;
    }
  }
}
