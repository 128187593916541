<ng-template #loading>
  <terra-loader></terra-loader>
</ng-template>

<ng-container *ngIf="fundraisings$ | async as fundraisings; else loading">
  <!-- Empty state -->
  <section class="empty-state" *ngIf="emptyState$ | async; else contributionsContent">
    <img src="assets/images/general/empty_state.svg"/>
    <h2>No contributions yet</h2>
    <ng-container *ngIf="assetId$ | async as fundId">
      <a mat-stroked-button [routerLink]="importContributionRoute$ | async" *ngIf="!(isRealized$ | async)"
         class="import-contribution-button-empty flow-import-contributions-button covercy-wired-button" color="primary">
        <mat-icon class="notranslate">cloud_upload</mat-icon>
        Import History
      </a>
    </ng-container>

    <!-- In draft status, we only allow to import contributions. -->
    <a mat-flat-button [routerLink]="createFundraisingUrl$ | async" *ngIf="(isDraft$ | async) == false && !(isRealized$ | async)"
       class="create-contribution-button-empty" color="primary">
      <mat-icon class="notranslate">add</mat-icon>
      New Contribution
    </a>
  </section>

  <!-- Content -->
  <ng-template #contributionsContent>
    <section class="contributions-content" *ngIf="assetId$ | async as assetId">
      <!-- Actions -->
      <div class="header-actions">
        <terra-export-fundraisings-menu class="export-menu" [holdingId]="assetId"></terra-export-fundraisings-menu>

        <terra-gp-help-menu [tours]="relevantTours" *ngIf="!(isRealized$ | async)"></terra-gp-help-menu>

        <a mat-stroked-button [routerLink]="importContributionRoute$ | async"
           class="import-contribution-button flow-import-contributions-button covercy-wired-button" color="primary" *ngIf="!(isRealized$ | async) && !(isRealized$ | async)">
          <mat-icon class="notranslate">cloud_upload</mat-icon>
          Import History
        </a>

        <!-- In draft status, we only allow to import contributions. -->
        <a mat-flat-button [routerLink]="createFundraisingUrl$ | async" *ngIf="(isDraft$ | async) == false && !(isRealized$ | async)"
           class="create-contribution-button covercy-wired-button" color="primary">
          <mat-icon class="notranslate">add</mat-icon>
          New Contribution
        </a>
      </div>

      <table mat-table [dataSource]="fundraisings" class="cov-basic-table" matSort matSortDisableClear
             (matSortChange)="sortData($event)" [matSortDirection]="(searchOptions$ | async).sortOrder"
             [matSortActive]="(searchOptions$ | async).orderBy">
        <!-- Contribution Name column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Name">Contribution</th>
          <td class="column-name" mat-cell *matCellDef="let fundraising">{{ fundraising.name }}</td>
        </ng-container>

        <!-- Closing Date column -->
        <ng-container matColumnDef="closingDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="FinalClosingDate">Closing date</th>
          <td mat-cell *matCellDef="let fundraising">
            {{ (fundraising.finalClosingDate ? fundraising.finalClosingDate : fundraising.estimatedClosingDate) | utcDate }}
          </td>
        </ng-container>

        <!-- Number Of Investors column -->
        <ng-container matColumnDef="investorCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="InvestorCount">Number of investors</th>
          <td mat-cell *matCellDef="let fundraising">{{ fundraising.investorCount }}</td>
        </ng-container>

        <!-- Total Amount column -->
        <ng-container matColumnDef="totalInvestedAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="TotalInvestedAmount">Total amount</th>
          <td mat-cell *matCellDef="let fundraising">
            {{ fundraising.fundraisingTargetCurrency.symbol }}{{ (fundraising.totalInvestedAmount | terraCurrency) || 0 }}
          </td>
        </ng-container>

        <!-- Status column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Status">Status</th>
          <td mat-cell *matCellDef="let fundraising">{{ FundraisingStatus.toString(fundraising.status) }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let fundraising; columns: displayedColumns"
            [routerLink]="getFundraisingPageUrl(assetId, fundraising.id)">
        </tr>
      </table>
    </section>
  </ng-template>
</ng-container>
