export enum InvestorRank {
  None = 0,
  Higher = 1,
  Normal = 2,
  Lower = 3,
}

// tslint:disable-next-line:no-namespace
export namespace InvestorRank {
  export function toString(rank: InvestorRank): string {
    switch (rank) {
      case InvestorRank.Higher:
        return 'Higher likelihood';
      case InvestorRank.Normal:
        return 'Normal likelihood';
      case InvestorRank.Lower:
        return 'Lower likelihood';
      default:
        return null;
    }
  }

  export function parse(investorRankstr: string): InvestorRank {
    return InvestorRank[investorRankstr];
  }

  export function listAll() {
    return [InvestorRank.Higher, InvestorRank.Normal, InvestorRank.Lower];
  }
}

export default InvestorRank;
