import {Component, OnInit, ChangeDetectionStrategy, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';
import {MatSelect} from '@angular/material/select';
import {Sort} from '@angular/material/sort';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {BehaviorSubject, combineLatest, merge, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, map, scan, shareReplay, switchMap, tap, debounceTime, switchMapTo, takeUntil, filter} from 'rxjs/operators';
import {DateRange} from '@angular/material/datepicker';

import {CreBankAccountDataService} from 'src/app/services/gp/cre-bank-account-data.service';
import {GpBankAccountDataService} from 'src/app/services/gp/gp-bank-account-data.service';
import {UnitTransactionsDirection} from 'src/app/shared/components/unit/unit-transactions-list/UnitTransactionDirection';
import AccountType from 'src/app/shared/enums/AccountType.enum';
import {UnitBankAccountTransactionsSearchOptionsRequest} from 'src/app/shared/models/unit-bank-account/UnitBankAccountTransactionsSearchOptionsRequest.model';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {GpHoldingService} from '../gp-holding.service';
import {TransactionTableItem} from './transaction-table-item.model';
import {DateRangePickerComponent} from '../../../../shared/components/date-range-picker/date-range-picker.component';
import {UnitBankAccountDataService} from 'src/app/services/shared/unit-bank-account-data.service';

@Component({
  selector: 'terra-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionsComponent extends OnDestroyMixin implements OnInit {
  @ViewChild('dateRange') dateRange: DateRangePickerComponent;
  @ViewChild('accountSelectControl') accountSelectControl: MatSelect;

  transactionDirection = UnitTransactionsDirection;
  CreAccountType = AccountType;
  holdingId$ = this.gpHolderService.holdingId$;

  selectedAccount;

  dateFormat = TerraUtils.consts.DATE_ONLY;

  isLoading$ = new BehaviorSubject<boolean>(true);

  private _refreshData$ = new Subject<void>();
  refreshData$ = this._refreshData$.asObservable();

  private pageSize$ = new Subject<number>();
  private pageNumber$ = new Subject<number>();

  private filter$ = new Subject<string>();
  private dateRange$ = new Subject<Date[]>();
  private transactionType$ = new Subject<string>();
  private sort$ = new Subject<any>();// new BehaviorSubject<{ sortOrder: string, orderBy: string }>({ sortOrder: 'desc', orderBy: 'createdAt' });
  private exportFormat$ = new Subject<string>();

  private destroy$ = new Subject<void>();

  private pageSizeSource$ = this.pageSize$.pipe(debounceTime(300), map(pageSize => ({pageSize}) as UnitBankAccountTransactionsSearchOptionsRequest));
  private pageNumberSource$ = this.pageNumber$.pipe(debounceTime(300), map(pageNumber => ({pageNumber}) as UnitBankAccountTransactionsSearchOptionsRequest));
  private filterSource$ = this.filter$.pipe(debounceTime(300), map(tf => tf ? tf.trim() : ''), distinctUntilChanged(), map(filter => ({filter}) as UnitBankAccountTransactionsSearchOptionsRequest));
  private dateRangeSource$ = this.dateRange$.pipe(debounceTime(300), map(([startDate, endDate]) => ({startDate, endDate}) as UnitBankAccountTransactionsSearchOptionsRequest));
  private transactionTypeSource$ = this.transactionType$.pipe(debounceTime(300), map(tt => ({typeName: tt}) as UnitBankAccountTransactionsSearchOptionsRequest));
  private sortSource$ = this.sort$.pipe(debounceTime(300), map(sortObj => (sortObj) as UnitBankAccountTransactionsSearchOptionsRequest));


  private searchOptionsSources$: Observable<UnitBankAccountTransactionsSearchOptionsRequest> = merge(
    this.sortSource$,
    this.filterSource$,
    this.dateRangeSource$,
    this.pageSizeSource$,
    this.transactionTypeSource$,
    this.pageNumberSource$
  );

  searchOptions$: Observable<UnitBankAccountTransactionsSearchOptionsRequest> = this.searchOptionsSources$.pipe(
    untilComponentDestroyed(this),
    scan((acc, value) => {
      // Whenever something changes, unless it's the pageNumber, return to the first page
      const pageNumber = value.pageNumber === undefined ? 0 : value.pageNumber;
      const pageSize = value.pageSize === undefined ? 5 : value.pageSize;
      const sortOrder = value.sortOrder === undefined ? 'desc' : value.sortOrder;
      const orderBy = value.orderBy === undefined ? 'createdAt' : value.orderBy;

      return {...acc, ...value, pageNumber, pageSize, sortOrder, orderBy} as UnitBankAccountTransactionsSearchOptionsRequest;
    }, new UnitBankAccountTransactionsSearchOptionsRequest()), shareReplay(1)
  );

  showClearFilter$ = this.searchOptions$.pipe(
    map(searchOptions => {
      return searchOptions.filter.length > 0 || searchOptions.endDate != null || searchOptions.typeName != null
    }));

  creBankAccounts$ = this.holdingId$
    .pipe(untilComponentDestroyed(this),
      switchMap(holdingId => this.gpBankAccountDataService.getCreBanksListByHolding(holdingId)
        .pipe(tap(bankAccounts => {

          this.selectedAccount = null;

          this.pageNumber$.next(0);

          if (bankAccounts.length && bankAccounts.length === 1) {
            this.selectedAccount = bankAccounts[0];
            this.accountSelectControl.disabled = true;
          }

        }))));

  pageData$ = merge(this.refreshData$, this.searchOptions$)
    .pipe(
      untilComponentDestroyed(this),
      switchMapTo(this.searchOptions$),
      filter(searchOptions => {
        // return searchOptions !== null && this.selectedAccount;
        return searchOptions !== null
      }),
      tap(() => this.isLoading$.next(true)),
      switchMap((searchOptions) => this.selectedAccount && this.selectedAccount.id ?
        this.creBankAccountDataService.getTransactionsList(this.selectedAccount.id, searchOptions)
          .pipe(
            map(rawTransactionsList => {

              if (rawTransactionsList && rawTransactionsList.length) {

                return rawTransactionsList.map((item: any) => {
                  let transaction = new TransactionTableItem();
                  transaction.id = item.id;
                  transaction.accountName = this.selectedAccount.accountNickname;
                  transaction.date = new Date(item.createdAt);
                  // transaction.bankAccountType = this.selectedAccount.accountType;
                  transaction.direction = item.direction;
                  transaction.description = item.description;
                  transaction.transactionType = item.direction.toLowerCase() == 'debit' ? 'Contribution' : 'Distribution';
                  transaction.amount = item.amount;
                  transaction.balance = item.balance;
                  transaction.payee = item.counterparty ? item.counterparty.name : '';
                  transaction.totalRowsFromUnitApi = item.totalRowsFromUnitApi;
                  return transaction;
                });
              } else {
                return [];
              }
            })
          )
        : this.holdingId$.pipe(switchMap(holdingId => this.creBankAccountDataService.getTransactionsForHolding(holdingId, searchOptions).pipe(
          map(rawTransactionsList => {

            if (rawTransactionsList && rawTransactionsList.length) {

              return rawTransactionsList.map((item: any) => {
                let transaction = new TransactionTableItem();
                transaction.id = item.id;
                transaction.accountName = item.accountNickname;
                transaction.date = new Date(item.createdAt);
                // transaction.bankAccountType = this.selectedAccount.accountType;
                transaction.direction = item.direction;
                transaction.description = item.description;
                transaction.transactionType = item.direction.toLowerCase() == 'debit' ? 'Contribution' : 'Distribution';
                transaction.amount = item.amount;
                transaction.balance = item.balance;
                transaction.payee = item.counterparty ? item.counterparty.name : '';
                transaction.totalRowsFromUnitApi = item.totalRowsFromUnitApi;
                return transaction;
              });
            } else {
              return [];
            }
          })
        )))),
      //TO DO: iNSTEAD OF NULL get transactionsByCustomer
      tap((response: any) => {
        if (response && response.length) {
          this.totalRowsCount$.next(response[0].totalRowsFromUnitApi || 0);
        }
      }),
      tap(() => this.isLoading$.next(false)),
      shareReplay(1)
    );

  pageRowsCount$ = this.pageData$.pipe(map(rows => rows), shareReplay(1));
  totalRowsCount$ = new BehaviorSubject<number>(0);

  get transactionDateRangeGroup() {
    return <UntypedFormGroup>this.transactionsFilterForm.get('transactionDateRange');
  }

  transactionsFilterForm: UntypedFormGroup;
  displayedColumns: string[] = ['accountName', 'transactionType', 'date', 'description', 'payee', 'amount', 'balance'];


  constructor(private creBankAccountDataService: CreBankAccountDataService,
    private gpHolderService: GpHoldingService,
    private gpBankAccountDataService: GpBankAccountDataService,
    private unitBankAccountDataService: UnitBankAccountDataService
  ) {
    super();

  }

  creAccountTypes$ = this.creBankAccountDataService.getUnitAccountTypes();

  ngOnInit(): void {
    this.initForm();

    this._refreshData$.next();
  }

  private initForm() {
    this.transactionsFilterForm = new UntypedFormGroup({
      'transactionDateRange': new UntypedFormGroup({
        'transactionStartDate': new UntypedFormControl(),
        'transactionEndDate': new UntypedFormControl()
      }),
      'transactionType': new UntypedFormControl(),
      'freeText': new UntypedFormControl(),
    });

    this.transactionsFilterForm.controls.freeText.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe((term) => {
        this.filter$.next(term);
      });
  }

  clearForm() {
    this.dateRange$.next([null, null]);
    this.transactionType$.next(null);
    this.transactionsFilterForm.reset();
    this.dateRange.clearRange();
    this.dateRange$.next([null, null]);
  }

  onAccountChanged(event) {
    this._refreshData$.next();
  }

  onExportSelected(format: string) {
    this.exportFormat$.next(format);
  }

  onTransactionTypeChanged(event) {
    this.transactionType$.next(event.value);
  }

  onTransactionEndDateChanged(event) {

    let endDate = event.value;
    let startDate = this.transactionDateRangeGroup.get('transactionStartDate').value;

    if (endDate == null) {
      if (startDate == null) {
        this.dateRange$.next([startDate, endDate]);
      }
    } else {
      if (startDate != null) {

        let newEndDate = new Date(endDate);
        let modifiedDate = newEndDate.setDate(newEndDate.getDate() + 1);
        //we add one day to the endDate if not null so that the caculation take into account the whole last day.
        this.dateRange$.next([startDate, new Date(modifiedDate)]);
      }
    }

  }

  sortData(sort: Sort) {
    if (!sort.active || !sort.direction) {
      this.sort$.next({sortOrder: '', orderBy: ''});
    } else {
      this.sort$.next({sortOrder: sort.direction, orderBy: sort.active});
    }
  }

  pageChange(event: PageEvent) {
    this.pageNumber$.next(event.pageIndex);
  }

  dateRangeChanged(range: DateRange<Date>) {
    this.transactionDateRangeGroup.controls.transactionStartDate.setValue(range.start);
    this.transactionDateRangeGroup.controls.transactionEndDate.setValue(range.end);

    this.dateRange$.next([range.start, range.end]);
  }
}
