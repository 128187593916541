import {Component, OnInit, ChangeDetectionStrategy, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

import {IssueCapitalCallRequestDto} from '../models';
import {GpHolding} from '../../GpHolding.model';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {FundraisingReqRes} from '../../fundraising/fundraisings-tab/FundraisingReqRes.model';
import {InvestmentReqRes} from '../../../../models/investment.model';
import {TerraUtils} from '../../../../../shared/TerraUtils';

export class CapitalCallConfirmDialogParams {
  constructor(
    public title: string = '',
    public iconName: string = '',
    public subTitles: string = '',
    public holding: GpHolding = null,
    public fundraising: FundraisingReqRes,
    public investments: InvestmentReqRes[]
  ) { }
}
@Component({
  selector: 'terra-capital-call-confirm-dialog',
  templateUrl: './capital-call-confirm-dialog.component.html',
  styleUrls: ['./capital-call-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapitalCallConfirmDialogComponent implements OnInit {

  subscription: Subscription;

  pageForm: UntypedFormGroup;
  HoldingDiscriminator = HoldingDiscriminator;
  showSecondDocumentsRow = false;
  isSubmitted = false;
  readonly today = new Date();

  constructor(
    private dialogRef: MatDialogRef<CapitalCallConfirmDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CapitalCallConfirmDialogParams
  ) {
    dialogRef.addPanelClass('capital-call-confirm-dialog');
    this.pageForm = this.fb.group({
      message: '',
      senderReference: [{value: null, disabled: true}, Validators.maxLength(100)],
      referenceBtn: '1',
      dueDate: [this.data.investments.length === 1 ? this.data.investments[0].capitalCallDueDate : null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.subscription = this.pageForm.get('referenceBtn').valueChanges
      .subscribe(val => {
        val === '2' ? this.pageForm.get('senderReference').enable() : this.pageForm.get('senderReference').disable();
        this.pageForm.get('senderReference').setValue(null);
      }
      );
  }

  proceed() {
    this.isSubmitted = true;
    this.pageForm.markAllAsTouched();
    if (!this.pageForm.valid) {
      return;
    }
    this.unsubscribe();
    const proceedObj: IssueCapitalCallRequestDto = {
      message: this.pageForm.value.message || null,
      referenceMemo: this.pageForm.value.senderReference,
      dueDate: this.pageForm.value.dueDate,
    };
    this.dialogRef.close(proceedObj);
  }

  close() {
    this.unsubscribe();
    this.dialogRef.close(null);
  }

  unsubscribe() {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
