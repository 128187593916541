import { Component, OnInit } from '@angular/core';
 
import { AreaUnit } from 'src/app/shared/enums/AreaUnit.enum';
import { InterestType } from 'src/app/shared/enums/InterestType.enum';
import { InvestmentSecurityType } from 'src/app/shared/enums/InvestmentSecurityType.enum';
import { InvestmentType } from 'src/app/shared/enums/InvestmentType.enum';
import { LoanType } from 'src/app/shared/enums/LoanType.enum';
import { CreateAssetStepBase } from '../CreateAssetStepBaseAndInterface';
import { CreateAssetService, CreateAssetStepNumber } from '../create-asset.service';

@Component({
  selector: 'terra-create-asset-investment-details-step',
  templateUrl: './create-asset-investment-details-step.component.html',
  styleUrls: ['./create-asset-investment-details-step.component.scss']
})
export class CreateAssetInvestmentDetailsStepComponent extends CreateAssetStepBase implements OnInit {
  readonly STEP_NUMBER: CreateAssetStepNumber = 2;

  // Enums
  InvestmentType = InvestmentType;
  LoanType = LoanType;
  AreaUnit = AreaUnit;
  InterestType = InterestType;
  InvestmentSecurityType = InvestmentSecurityType;

  // Enum values list
  LoanTypesList = LoanType.listAll();
  InterestTypesList = InterestType.listAll();

  selectedInvestmentType = this.createAssetService.selectedInvestmentType;
  selectedCurrency = this.createAssetService.selectedCurrency;
  selectedSecurityType = this.createAssetService.selectedSecurityType;

  closingDateStartAt = new Date();
  closingDateMinimumDate = new Date('1970-01-01T12:00');

  maturityDateStartAt = new Date();
  maturityDateMinimumDate = new Date('1970-01-01T12:00');

  constructor(private createAssetService: CreateAssetService) {
    super(createAssetService);
  }

  ngOnInit() {
    this.createAssetService.currentStep$.next(this.STEP_NUMBER);
  }
}
