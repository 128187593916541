import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
 
import { AgreementPersonalMessageDialogParams } from './AgreementPersonalMessageDialogParams.model';

@Component({
  selector: 'terra-agreement-personal-message-dialog',
  templateUrl: './agreement-personal-message-dialog.component.html',
  styleUrls: ['./agreement-personal-message-dialog.component.scss']
})
export class AgreementPersonalMessageDialogComponent implements OnInit {

  pageForm: UntypedFormGroup;
  isSubmitted = false;
  showInternalLoader = false;

  constructor(
    public dialogRef: MatDialogRef<AgreementPersonalMessageDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public personalMessageParams: AgreementPersonalMessageDialogParams
  ) {
    dialogRef.addPanelClass('agreement-personal-message-dialog');
  }

  ngOnInit() {
    this.isSubmitted = false;
    this.pageForm = this.fb.group({
      message: ['']
    });
  }

  send() {
    this.isSubmitted = true;

    this.showInternalLoader = true;

    const message = this.pageForm.value.message || null;

    const returnParameters = new AgreementPersonalMessageDialogParams(0, null, null, message, true);

    this.dialogRef.close(returnParameters);
  }
}
