<!-- Header -->
<terra-dialog-header>
  <h1>Add Investors</h1>
</terra-dialog-header>

<mat-dialog-content>
  <form id="dialogForm" (ngSubmit)="onSubmit()" [formGroup]="pageForm" (keydown.enter)="$event.preventDefault()">
    <div class="filters-bar">

      <div class="filter text-filter">
        <mat-form-field appearance="outline">
          <input type="text" placeholder="Filter by name" matInput formControlName="term" class="lo_sensitive"
                 autocomplete="nope">
        </mat-form-field>
      </div>

      <div class="filter tags-filter">
        <mat-form-field appearance="outline">
          <mat-select matInput placeholder="Filter by tags" formControlName="multiselectTags" multiple>
            <mat-option *ngFor="let tag of allTags$ | async" [value]="tag.key"
                        class="lo_sensitive">{{ tag.value }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter contact-referrer-filter">
        <mat-form-field appearance="outline">
          <mat-select formControlName="contactReferrerId" placeholder="Contact referrer">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let contactReferrer of allContactReferrers$ | async" [value]="contactReferrer.id">
              {{ contactReferrer.name | truncate : 40 : true }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-slide-toggle hideIcon labelPosition="before" checked (change)="toggleButton($event.checked)"
                      class="toggleButton">Show all investors
    </mat-slide-toggle>


    <terra-investing-entities-display-table
      [excludeAgentContactInvestingEntities]="true"
      messageWhenListIsEmpty="All related investors already exist in distribution"
    >

    </terra-investing-entities-display-table>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" form="dialogForm" mat-flat-button color="primary" class="page-cta"
          [disabled]="(contactsToAdd$ | async).length === 0">Add
  </button>
  <button type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
