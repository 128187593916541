<div class="details-wrapper">
  <h3>Distribution details</h3>
  <hr>
  <form [formGroup]="pageForm" (keydown.enter)="$event.preventDefault()"
        class="cov-form-grid">

    <section class="form-section">
      <!-- Distribution period -->
      <div class="terra-form-field-wrapper">
        <label for="ddlDistributionPeriod" class="text-input-label">Distribution period</label>
        <mat-form-field appearance="outline">
          <mat-select id="ddlDistributionPeriod" placeholder="Select" formControlName="distributionPeriod">
            <mat-option *ngFor="let period of DistributionPeriod.listAll()"
                        [value]="period">{{ period | enumString: DistributionPeriod }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Quarter and Year -->
      <div class="terra-form-field-wrapper quarter-and-year"
           *ngIf="pageForm.get('distributionPeriod').value === DistributionPeriod.Quarterly">
        <div class="quarter-field">
          <label for="ddlQuarter" class="text-input-label">Select quarter</label>
          <mat-form-field appearance="outline">
            <mat-select id="ddlQuarter" placeholder="Select" formControlName="quarter">
              <mat-option *ngFor="let quarter of [1, 2, 3, 4]" [value]="quarter">Q{{ quarter }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="year-field">
          <label for="ddlYear" class="text-input-label">Select year</label>
          <mat-form-field appearance="outline">
            <mat-select id="ddlYear" placeholder="Select" formControlName="year">
              <mat-option *ngFor="let year of yearsList" [value]="year">{{ year }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Start and End dates of the period  -->
      <ng-container *ngIf="pageForm.get('distributionPeriod').value === DistributionPeriod.Custom">
        <!-- Start date -->
        <div class="terra-form-field-wrapper new-column">
          <terra-custom-utc-date-picker
          id="txtPeriodStartDate"
          formControlName="periodStartDate"
          label="First day included in distribution"
          [minDate]="periodMinDate"
          [maxDate]="periodMaxDate"
          [requiredError]="periodStartDate.hasError('required')"
          [touched]="periodStartDate.touched"
          [minError]="periodStartDate.hasError('minDate')"
          [maxError]="periodStartDate.hasError('maxDate')"
          ></terra-custom-utc-date-picker>
        </div>
        <!-- End date -->
        <div class="terra-form-field-wrapper">
          <terra-custom-utc-date-picker
          id="txtPeriodEndDate"
          formControlName="periodEndDate"
          label="Last day included in period"
          [minDate]="periodStartDate.value"
          [maxDate]="periodMaxDate"
          [requiredError]="periodEndDate.hasError('required')"
          [touched]="periodEndDate.touched"
          [minError]="periodEndDate.hasError('minDate')"
          [maxError]="periodEndDate.hasError('maxDate')"
          ></terra-custom-utc-date-picker>
        </div>
      </ng-container>

      <!-- Reason for distribution -->
      <div class="terra-form-field-wrapper">
        <label for="ddlTransactionPurpose" class="text-input-label">Type of distribution</label>
        <mat-form-field appearance="outline">
          <mat-select id="ddlTransactionPurpose" placeholder="Select" formControlName="distributionTransactionPurpose">
            <mat-option [value]="null">
              Select
            </mat-option>
            <mat-option *ngFor="let item of distributionTransactionPurposeAll" [value]="item">
              {{ item | enumString: DistributionTransactionPurpose }}</mat-option>
          </mat-select>
          <mat-error *ngIf="pageForm.get('distributionTransactionPurpose').hasError('required')">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- distribution SourceCurrency -->
      <div class="terra-form-field-wrapper currency-component">
        <label for="ddlCurrency" class="currency-label text-input-label">Distribution deposit currency
        </label>
        <mat-form-field appearance="outline" class="currency-ddl-wrapper">
          <mat-select id="ddlCurrency" placeholder="Select" formControlName="distributionSourceCurrency"
                      [compareWith]="currencyCompareFn">
            <mat-option *ngFor="let currency of currencies" [value]="currency">
              {{ currency.symbol }} {{ currency.iso }}</mat-option>
          </mat-select>
          <mat-error *ngIf="pageForm.get('distributionSourceCurrency').hasError('required')">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Other transfer purpose -->
      <div class="terra-form-field-wrapper"
           *ngIf="pageForm.get('distributionTransactionPurpose').value === DistributionTransactionPurpose.Other">
        <label for="txtDistributionTransactionPurposeOther" class="text-input-label">
          Transfer Purpose
        </label>
        <mat-form-field appearance="outline">
          <mat-select id="distributionTransactionPurposeOtherType" formControlName="distributionTransactionPurposeOtherType" placeholder="Select"
                      class="lo_sensitive">
            <mat-option *ngFor="let otherType of distributionTransactionOtherTypeAll" [value]="otherType">
              {{otherType | enumString: distributionTransactionOtherType}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="pageForm.get('distributionTransactionPurposeOtherType').hasError('required')">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Reason for distribution - Other -->
      <div class="terra-form-field-wrapper"
           *ngIf="pageForm.get('distributionTransactionPurpose').value === DistributionTransactionPurpose.Other">
        <label for="txtDistributionTransactionPurposeOther" class="text-input-label">
          Please specify
        </label>
        <mat-form-field appearance="outline">
          <input type="text" matInput id="txtDistributionTransactionPurposeOther"
                 formControlName="distributionTransactionPurposeOther" class="lo_sensitive"/>
          <mat-error *ngIf="pageForm.get('distributionTransactionPurposeOther').hasError('required')">
            Required
          </mat-error>
        </mat-form-field>
      </div>
    </section>

  </form>
</div>
