import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {DocumentModel} from 'src/app/shared/models/DocumentModel';
import {PaymentStatus} from 'src/app/shared/enums/PaymentStatus.enum';
import SignPlatform from 'src/app/shared/enums/SignPlatform.enum';
import {MarketingDeckSendPlatform} from 'src/app/shared/enums/MarketingDeckSendPlatform.enum';
import {MetaFileLink} from 'src/app/models/metaFileLink.model';
import PaymentPlatform from 'src/app/shared/enums/PaymentPlatform.enum';
import {LpInterest} from './lpInterest.enum';
import {BankHolidayResponse} from 'src/app/shared/models/BankHolidayResponse.model';
import {InvestingEntityReqRes} from './InvestingEntityReqRes.model';
import {InvestorRank} from '../shared/holding/fundraising/AI-investors-ranking/InvestorRank.model';

export class InvestmentReqRes {

  public id: number;
  public bulkCreateIds: number[];
  public assetId: number;
  public fundraisingId: number;

  // public contactId: number;
  // public contact: ContactReqRes;

  public investingEntity: InvestingEntityReqRes;
  public investingEntityId: number;

  public status: InvestmentStatus;
  public currency: CurrencyModel;
  public estimatedAmount: number;
  public finalAmount: number;
  public softCircledDate: Date;
  public isMarketingDeckWasSent: boolean;
  public marketingDeckSendPlatform: MarketingDeckSendPlatform;
  public marketingDeckSentEmail: string;
  public marketingDeckSendDate: Date;
  public isPaymentWasSettled: boolean;
  public isPaymentReversed: boolean;
  public isOrderCreated: boolean;
  public paymentStatus: PaymentStatus;
  public paymentStatusDates: Record<PaymentStatus, Date>;
  public paymentSettlementPlatform: PaymentPlatform;
  public paymentSettlementDate: Date;
  public paymentRequestSentEmail: string;
  public paymentRequestSendDate: Date;
  public paymentRequestDocuments: MetaFileLink[];
  public isAgreementSigned = false;
  public agreementSignPlatform: SignPlatform;
  public agreementFile: DocumentModel;
  public agreementSentEmail: string;
  public agreementSentDate: Date;
  public agreementMetaFileLinkId: number;
  public agreementMetaFileLink: MetaFileLink;
  public agreementDraftTitle: string;
  public agreemenHelloSigntTemplateId: string;
  public agreemenHelloSigntSignatureRequestId: string;

  public lpInterestLevel: LpInterest;
  public lpInterestAmount: number;
  public lpInterestNote: string;
  public lpInterestSubmittedTimestamp: Date;
  public marketingDeckLpDownloadsCount = 0;
  public marketingDeckLpLastDownloadTimestamp: Date;

  public bankHolidays: BankHolidayResponse[];

  public rank: InvestorRank;
  public insight: string;
  public isCreatedByBulkOfferingDeck: boolean;
  public externalRemarks: string;
  public internalRemarks: string;

  // capital call related
  public capitalCallDueDate: Date;
  public capitalCallViewDate: Date;
  public transferDate: Date;
  

  //total commitments related
  public totalCommitment: number;

  constructor() { }
}
