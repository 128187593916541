import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'terra-step-label',
  templateUrl: './step-label.component.html',
  styleUrls: ['./step-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // standalone
})
export class StepLabelComponent {
  @Input() title: string;
  @Input() step: number;
  @Input() completed: boolean;
  @Input() disabled: boolean = false;

  @Output() stepClick = new EventEmitter();

  onClick($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this.disabled) {
      this.stepClick.emit(this.step);
    }
  }
}
