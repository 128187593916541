import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'terra-new-version-snackbar',
  templateUrl: './new-version-snackbar.component.html',
  styleUrls: ['./new-version-snackbar.component.scss']
})
export class NewVersionSnackbarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  refreshPage() {
    window.location.reload();
  }
}
