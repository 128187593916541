import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FilestackStatus } from 'src/app/shared/enums/filestack-status.enum';

@Component({
  selector: 'terra-uploading-status',
  templateUrl: './uploading-status.component.html',
  styleUrls: ['./uploading-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // standalone
})
export class UploadingStatusComponent {
  @Input() status: FilestackStatus = FilestackStatus.None;

  FilestackStatus = FilestackStatus;
}
