import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { take } from 'rxjs/operators';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import { TerraUtils } from 'src/app/shared/TerraUtils';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GpAssetDialogContext } from '../../gp-asset/AssetDetails.context';
import { EditFundAssetService } from './edit-fund-asset.service';
import { EditFundAssetTabNumber } from './EditFundAssetStepBaseAndInterface';
import { enterAnimation } from 'src/app/shared/animations';
import { DialogWithFormBase } from 'src/app/shared/types/DialogWithFormBase';
import { RoutingService } from 'src/app/services/routing.service';
import { UserService } from 'src/app/services/shared/user.service';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';

@Component({
  selector: 'terra-edit-fund-asset-dialog',
  templateUrl: './edit-fund-asset-dialog.component.html',
  styleUrls: ['./edit-fund-asset-dialog.component.scss'],
  providers: [EditFundAssetService],
  animations: [enterAnimation]
})
export class EditFundAssetDialogComponent extends DialogWithFormBase implements OnInit {

  initialSelectedTab = this.context.initialTabNumber - 1;

  // enum
  AssetStatus = HoldingStatus;

  get pageForm(): UntypedFormGroup {
    if (!this.editFundAssetService.assetForm) {
      return null;
    }
    return this.editFundAssetService.assetForm as UntypedFormGroup;
  }

  get isStatusFundRaising() {
    return this.context.asset.status === HoldingStatus.Fundraising;
  }

  get attemptSubmit() {
    return this.editFundAssetService.attemptSubmit;
  }

  processingSubmit$ = this.editFundAssetService.processingSubmit$;

  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;

/*
  get isDeleteAssetAllowed(): boolean {
    if (!this.context.asset) {
      return false;
    }
    if (this.context.fundraisingDetails.investments.find(i => i.isAgreementSigned || i.paymentRequestSendDate !== null)) {
      return false;
    }
    return true;
  }
 */

  constructor(
    public dialogRef: MatDialogRef<EditFundAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: GpAssetDialogContext,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private router: Router,
    private editFundAssetService: EditFundAssetService,
    private routingService: RoutingService,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private utilsService: UtilsService
  ) {
    super();
    editFundAssetService.editFundAssetDialogContext = context;
    editFundAssetService.dialogRef = dialogRef;
    editFundAssetService.generateForm();
  }

  ngOnInit() {
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails =>{
      this.telemetryService.create({
        eventID: '23',
        eventTitle: 'EDIT FUND ASSET (INIT)',
        holdingID: this.context.asset.fundId,
        organizationID: clientDetails.organizationDetails.id,
        additional: {
          assetID: this.context.asset.id
        }
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });
  }

  isShowInvalidTab(tabNumber: EditFundAssetTabNumber) {
    const stepForm = this.editFundAssetService.getTabForm(tabNumber);
    if (!stepForm) {
      return null;
    }
    // The form will be in "PENDING" status while async validation is in progress.
    // In that time both valid and invalid will be false, and we don't want to display an error.
    return !stepForm.valid && stepForm.touched && stepForm.status !== 'PENDING';
  }

  saveChanges() {
    this.isGeneralServerError = false;
    this.editFundAssetService.saveChanges().subscribe(
      updatedAssetAndFundraising => {
        if (updatedAssetAndFundraising) {
          this.dialogRef.close(updatedAssetAndFundraising);
          this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails =>{
            this.telemetryService.create({
              eventID: '26',
              eventTitle: 'EDIT FUND ASSET (COMPLETED)',
              holdingID: this.context.asset.fundId,
              organizationID: clientDetails.organizationDetails.id,
              additional: {
                assetID: this.context.asset.id
              }
            }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
          });
        }
      },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          this.isGeneralServerError = true;
        }
      });
  }

  deleteAsset() {
    const confirmDialogParams = new ConfirmDialogParams();
    confirmDialogParams.actionLabel = 'Delete';
    confirmDialogParams.title = 'Are you sure you want to delete this asset?';
    confirmDialogParams.description = `This asset's information and all its related data will be deleted immediately. You cannot undo this action.`;
    this.dialogService
      .confirmDialog(confirmDialogParams)
      .afterClosed()
      .pipe(untilComponentDestroyed(this))
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.context.gpAssetService
            .deleteAsset()
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              response => {
                this.router.navigateByUrl(this.routingService.gpAssetsPage);
                this.dialogRef.close(true);
                this.snackbarService.showGeneralMessage(`Asset deleted`);
              },
              error => {
                this.snackbarService.showGeneralMessage(`Couldn't delete the asset`);
              }
            );
        }
      });
  }
}
