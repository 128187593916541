<div class="header">
  <button class="close-button" [mat-dialog-close]="false" type="button" tabindex="-1" mat-icon-button aria-label="Close"
          [matTooltip]="'Cancel transfer of ownership'" matTooltipPosition="below">
    <mat-icon class="material-icons notranslate">clear</mat-icon>
  </button>
  <div class="title">
    <p>
      <mat-icon class="material-icons notranslate">compare_arrows</mat-icon>
      Transfer Ownership
    </p>
  </div>
</div>

<div class="content">
  <form [formGroup]="pageForm" class="ownership-transfer-form terra-error-style" (ngSubmit)="onSubmit()">
    <div class="terra-form-field-wrapper from-lp-wrapper">
      <mat-label for="ddFromLp" class="text-input-label from-lp">From Investor</mat-label>
      <mat-form-field appearance="outline" class="full-width">
        <input tabindex="-1" id="ddFromLp" type="text" matInput aria-label="From Investor" placeholder="Select" [matAutocomplete]="autoCompleteFrom"
               formControlName="fromLp" (focusout)="onFocusOut('fromLp')" (focus)="clearErrors()"/>
        <mat-icon [ngClass]="{'low-opacity': (isSubmitting$ | async) || pageForm.get('fromLp').disabled}" class="notranslate" matSuffix>arrow_drop_down</mat-icon>
        <mat-autocomplete #autoCompleteFrom="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn">
          <mat-option *ngFor="let lp of (fromLps$ | async); trackBy: trackByLpFn" [value]="lp.id">
            {{lp.name}} {{getContactName(lp.contact)}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="pageForm.get('fromLp').hasError('required')">Required</mat-error>
      </mat-form-field>
    </div>

    <div class="direction-icon">
      <mat-icon class="material-icons notranslate">arrow_forward</mat-icon>
    </div>

    <div class="terra-form-field-wrapper to-lp-wrapper">
      <mat-label for="ddToLp" class="text-input-label to-lp">To Investor</mat-label>
      <mat-form-field appearance="outline" class="full-width">
        <input tabindex="-1" id="ddToLp" type="text" matInput aria-label="To Investor" placeholder="Select" [matAutocomplete]="autoCompleteTo"
               formControlName="toLp" (focusout)="onFocusOut('toLp')" (focus)="clearErrors()"/>
        <mat-icon [ngClass]="{'low-opacity': (isSubmitting$ | async) || pageForm.get('toLp').disabled}" class="material-icons notranslate" matSuffix>arrow_drop_down</mat-icon>
        <mat-autocomplete #autoCompleteTo="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn">
          <mat-option *ngFor="let lp of (toLps$ | async); trackBy: trackByLpFn" [value]="lp.id">
            {{lp.name}} {{getContactName(lp.contact)}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="pageForm.get('toLp').hasError('required')">Required</mat-error>
      </mat-form-field>
    </div>

    <div class="terra-form-field-wrapper amount">
      <mat-label class="text-input-label">Invested Amount</mat-label>
      <mat-form-field appearance="outline">
        <input tabindex="-1" type="tel" matInput id="amount" formControlName="amount" class="lo_sensitive" placeholder="e.g. 1,000" (focus)="clearErrors()"/>
        <mat-icon [ngClass]="{'low-opacity': (isSubmitting$ | async) || pageForm.get('amount').disabled}" matTooltip="Increment"
                  class="material-icons notranslate number-arrows" matSuffix (click)="pageForm.get('amount').enabled && incAmount()">arrow_drop_up
        </mat-icon>
        <mat-icon [ngClass]="{'low-opacity': (isSubmitting$ | async) || pageForm.get('amount').disabled}" matTooltip="Decrement"
                  class="material-icons notranslate number-arrows" matSuffix (click)="pageForm.get('amount').enabled && decAmount()">arrow_drop_down
        </mat-icon>
        <span [ngClass]="{'low-opacity': (isSubmitting$ | async) || pageForm.get('amount').disabled}"
              *ngIf="pageForm.get('amount').value" matPrefix>{{data.holding.initialCurrency.symbol}}</span>
        <mat-error *ngIf="pageForm.get('amount').hasError('required')">Required</mat-error>
        <mat-error *ngIf="pageForm.get('amount').hasError('minFormattedNumber')">Amount should be greater than 0</mat-error>
        <mat-error *ngIf="pageForm.get('amount').hasError('maxFormattedNumber')">Cannot exceed the invested amount of the selected investor</mat-error>
      </mat-form-field>
    </div>

    <div class="terra-form-field-wrapper percents">
      <mat-label class="text-input-label">Percent from invested amount</mat-label>
      <mat-form-field appearance="outline">
        <input tabindex="-1" type="tel" matInput id="percents" formControlName="percents" class="lo_sensitive" placeholder="e.g. 10%"/>
        <h4 [ngClass]="{'low-opacity': (isSubmitting$ | async) || pageForm.get('percents').disabled}" class="percents-icon" matSuffix>%</h4>
      </mat-form-field>
    </div>
    <!-- commitments -->
    <div class="terra-form-field-wrapper commitment-amount" *ngIf="hasCommitments$ | async">
      <mat-label class="text-input-label">Remaining commitments amount</mat-label>
      <mat-form-field appearance="outline">
        <input tabindex="-1" type="tel" matInput id="commitment-amount" formControlName="commitmentAmount" class="lo_sensitive" placeholder="e.g. 1,000" (focus)="clearErrors()"/>
        <span [ngClass]="{'low-opacity': (isSubmitting$ | async) || pageForm.get('commitmentAmount').disabled}"
              *ngIf="pageForm.get('commitmentAmount').value" matPrefix>{{data.holding.initialCurrency.symbol}}</span>
        <mat-error *ngIf="pageForm.get('commitmentAmount').hasError('required')">Required</mat-error>
        <mat-error *ngIf="pageForm.get('commitmentAmount').hasError('minFormattedNumber')">Amount should be greater than 0</mat-error>
        <mat-error *ngIf="pageForm.get('commitmentAmount').hasError('maxFormattedNumber')">Cannot exceed the remaining committed amount of the selected investor</mat-error>
      </mat-form-field>
    </div>

    <div class="terra-form-field-wrapper commitment-percents" *ngIf="hasCommitments$ | async">
      <mat-label class="text-input-label">Percent from remaining commitments</mat-label>
      <mat-form-field appearance="outline">
        <input tabindex="-1" type="tel" matInput id="commitment-percents" formControlName="commitmentPercents" class="lo_sensitive" placeholder="e.g. 10%"/>
        <h4 [ngClass]="{'low-opacity': (isSubmitting$ | async) || pageForm.get('commitmentPercents').disabled}" class="percents-icon" matSuffix>%</h4>
      </mat-form-field>
    </div>

    <div class="terra-form-field-wrapper closing-date">
      <label for="txtClosingDate" class="text-input-label">Closing date</label>
      <mat-form-field appearance="outline">
        <input tabindex="-1" matInput id="txtClosingDate" [matDatepicker]="closingDatePicker" formControlName="closingDate" (focus)="closingDatePicker.open()" class="lo_sensitive"
               [min]="minDate" [max]="maxDate"/>
        <mat-datepicker-toggle matSuffix [for]="closingDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #closingDatePicker startAt="{{maxDate}}"></mat-datepicker>
        <mat-error *ngIf="pageForm.get('closingDate').hasError('required')">Required</mat-error>
        <mat-error *ngIf="pageForm.get('closingDate').hasError('matDatepickerMax')">Future date is not allowed</mat-error>
        <mat-error *ngIf="pageForm.get('closingDate').hasError('matDatepickerMin')">Date is out of range</mat-error>
      </mat-form-field>
    </div>

    <mat-dialog-actions class="actions">
      <button mat-flat-button class="confirm-button" type="submit" color="primary" [disabled]="isSubmitting$ | async">Transfer</button>
      <button mat-flat-button class="cancel-button" type="button" [mat-dialog-close]="false" [disabled]="isSubmitting$ | async">Cancel</button>

    </mat-dialog-actions>

    <mat-dialog-actions class="error-container" *ngIf="isGeneralServerError || isFrontEndError">
      <mat-error *ngIf="pageForm.hasError('atLeastOnePositive')">Either invested or committed amount must be greater than 0</mat-error>
      <p *ngIf="isGeneralServerError" class="error-message">{{generalServerErrorMessage}}</p>
      <p *ngIf="isFrontEndError" class="error-message">{{generalFrontendError}}</p>
    </mat-dialog-actions>

    <terra-loader class="loader" *ngIf="isSubmitting$ | async"></terra-loader>
  </form>
</div>
