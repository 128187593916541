import { Component, OnInit, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { SnackbarService } from 'src/app/services/snackbar.service';
import { CapitalCallInvestmentsTableComponent } from '../capital-call-investments-table/capital-call-investments-table.component';
import { CapitalCallInvestmentsTableService } from '../capital-call-investments-table/capital-call-investments-table.service';
import { CapitalCallService } from '../capital-call.service';
import { CapitalCallResponseStatus, ErrorMessage } from '../models';
import TrackStatus from '../models/TrackStatus.enum';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';
import { NoPermissionAction } from 'src/app/shared/directives/check-permissions.directive';
import PermissionLevel from 'src/app/permission/enums/permissionLevel.enum';
import { FinancialPermissionLevel } from 'src/app/permission/enums/financialPermissionLevel.enum';

@Component({
  selector: 'terra-capital-call-track',
  templateUrl: './capital-call-track.component.html',
  styleUrls: ['./capital-call-track.component.scss'],
  providers: [CapitalCallInvestmentsTableService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapitalCallTrackComponent extends OnDestroyMixin implements OnInit, AfterViewInit {
  @ViewChild(CapitalCallInvestmentsTableComponent) investmentsTable: CapitalCallInvestmentsTableComponent;
  formGroup: UntypedFormGroup;
  ErrorMessage = ErrorMessage;
  TrackStatus = TrackStatus;
  NoPermissionAction = NoPermissionAction;
  FinancialPermissionLevel = FinancialPermissionLevel;
  PermissionLevel = PermissionLevel;

  selectedInvestments$: BehaviorSubject<UntypedFormGroup[]> = new BehaviorSubject<UntypedFormGroup[]>([]);
  reIssueFailMsgFlag$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  allInvestmentToReIssue$ = this.capitalCallService.issuableInvestments$;
  disableReIssue$ = this.selectedInvestments$.pipe(map(selectedInvestments => !selectedInvestments || selectedInvestments.length <= 0));

  constructor(
    private capitalCallService: CapitalCallService,
    private capitalCallInvestmentsTableService: CapitalCallInvestmentsTableService,
    private snackbarService: SnackbarService,
    private fb: UntypedFormBuilder,
    private utilsService: UtilsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.generateForm();
    this.filtersValueChanges();
  }

  ngAfterViewInit(): void {
    this.investmentsTable.selection.changed.pipe(
      untilComponentDestroyed(this)
    ).subscribe(_ => this.selectedInvestments$.next(this.getSelectedInvestments()));
  }

  public generateForm() {
    this.formGroup = this.fb.group({
      filter: '',
      trackStatus: null
    });
  }

  public filtersValueChanges() {
    this.formGroup.get('filter').valueChanges
      .pipe(
        untilComponentDestroyed(this),
        debounceTime(500)
      ).subscribe(nameFilter => this.capitalCallInvestmentsTableService.updateNameFilter(nameFilter));

    this.formGroup.get('trackStatus').valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        trackStatus => this.capitalCallInvestmentsTableService.updateTrackStatusFilter(trackStatus)
      );
  }

  /**
   * Re issue capital call
   * @param investmentsSelected selected investments
   */
  public issueCapitalCall(investmentsSelected: Array<UntypedFormGroup>) {
    this.reIssueFailMsgFlag$.next(null);
    const investmentsIds = this.capitalCallService.getInvestmentsIdsFormGroup(investmentsSelected);
        this.capitalCallService.issueCapitalCall(investmentsIds).subscribe(fundraising => {
      if (!!fundraising) {
        // this.snackbarService.showGeneralMessage('Processing capital call');

        this.snackbarService.showGeneralMessage('Issue capital call succeeded');
/*        if (capitalCall.status === CapitalCallResponseStatus.Success){
          this.snackbarService.showGeneralMessage('Processing capital call');
          this.investmentsTable.clearSelectedInvestments();
        }
        else{
          this.snackbarService.showGeneralMessage('Failed to Issue capital call');
          this.reIssueFailMsgFlag$.next(capitalCall.message);
        }*/
      }
      else {
        this.reIssueFailMsgFlag$.next(ErrorMessage.toString(ErrorMessage.InvalidValue));
        this.snackbarService.showGeneralMessage('Failed to Issue capital call');
      }
    },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          this.reIssueFailMsgFlag$.next(ErrorMessage.toString(ErrorMessage.GeneralError));
          this.snackbarService.showGeneralMessage('Failed to Issue capital call');
        }
      });
  }

  /**
   * Get a list of selected investments from the table
   */
  private getSelectedInvestments(): UntypedFormGroup[] {
    let selectedInvestments = new Array<UntypedFormGroup>();
    if (this.investmentsTable && this.investmentsTable.selectedInvestments) {
      selectedInvestments = this.investmentsTable.selectedInvestments;
    }
    return selectedInvestments ? selectedInvestments : [];
  }
}
