import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {debounceTime, map, shareReplay, take} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

import {GpHoldingService} from '../../../gp-holding.service';
import {EngagementDisplayTableComponent} from './engagement-display-table/engagement-display-table.component';
import {InvestmentReqRes} from '../../../../../models/investment.model';
import {EngagementTableService} from './engagement-display-table/engagement-table.service';
import {GpInvestmentDataService} from '../../../../../../services/gp/gp-investment-data.service';
import InvestmentStatus from '../../../../../../shared/enums/InvestmentStatus.enum';
import {SnackbarService} from '../../../../../../services/snackbar.service';
import {LpInterest} from '../../../../../models/lpInterest.enum';
import FundraisingStatus from "../../../../../../shared/enums/FundraisingStatus.enum";
import {BaseResponseDto} from '../../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../../services/utils.service';


@Component({
  selector: 'terra-investor-engagement-dialog',
  templateUrl: './investor-engagement-dialog.component.html',
  styleUrls: ['./investor-engagement-dialog.component.scss'],
  providers: [EngagementTableService]
})
export class InvestorEngagementDialogComponent extends OnDestroyMixin implements OnInit, AfterViewInit {
  @ViewChild(EngagementDisplayTableComponent, {static: false}) engagementTable: EngagementDisplayTableComponent;

  pageForm: UntypedFormGroup;
  selectedInvestments$ = new BehaviorSubject<InvestmentReqRes[]>([]);
  fundraising$ = this.holdingService.fundraising$;
  isLoading$ = new BehaviorSubject<boolean>(false);
  isActiveFundraising$ = this.fundraising$.pipe(
    map(fundraising => fundraising.status === FundraisingStatus.InProgress || fundraising.status === FundraisingStatus.None),
    shareReplay(1)
  );

  investmentStatus = InvestmentStatus;
  lpInterest = LpInterest;
  lpInterestList = LpInterest.listAll();

  constructor(
    private holdingService: GpHoldingService,
    public dialogRef: MatDialogRef<InvestorEngagementDialogComponent>,
    private engagementTableService: EngagementTableService,
    private fb: UntypedFormBuilder,
    private investmentDataService: GpInvestmentDataService,
    private snackbarService: SnackbarService,
    private utilsService: UtilsService
  ) {
    super();
  }

  ngOnInit() {
    this.generateForm();
  }

  ngAfterViewInit(): void {
    if (this.engagementTable) {
      this.engagementTable.selection.changed.pipe(
        untilComponentDestroyed(this),
        map(() => {
          this.selectedInvestments$.next(this.getSelectedInvestments());
        }))
        .subscribe();
    }
  }

  generateForm() {
    this.pageForm = this.fb.group({
      name: [],
      response: []
    });

    this.pageForm
      .get('name')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(300))
      .subscribe(term => {
        this.engagementTableService.updateFilter(term);
      });

    this.pageForm
      .get('response')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(200))
      .subscribe((response: LpInterest) => {
        this.engagementTableService.updateResponseFilter(response);
      });
  }

  private getSelectedInvestments() {
    let investment = new Array<InvestmentReqRes>();
    if (this.engagementTable && this.engagementTable.selectedInvestors) {
      investment = this.engagementTable.selectedInvestors;
    }
    return investment ? investment : [];
  }

  updateInvestments(status: InvestmentStatus) {
    this.isLoading$.next(true);
    const investments = this.selectedInvestments$.getValue();
    let updatesSent = 0;
    let updatedInvestments = 0;
    this.holdingService.holdingId$.pipe(take(1)).subscribe(holdingId => {
      investments.forEach(investment => {
        updatesSent++;
        this.investmentDataService.updateStatus(holdingId, investment.fundraisingId, investment.id, status)
          .pipe(take(1)).subscribe(updatedInvestment => {
            updatedInvestments++;
            if (updatedInvestments === investments.length) {
              if (status === InvestmentStatus.Potential) {
                const message = `${updatedInvestments} ${updatedInvestments > 1 ? 'investments' : 'investment'} added `;
                this.snackbarService.showGeneralMessage(message);
              } else if (status === InvestmentStatus.SoftCircled) {
                const message = `${updatedInvestments} ${updatedInvestments > 1 ? 'investments' : 'investment'} marked as soft circled`;
                this.snackbarService.showGeneralMessage(message);
              }
              this.isLoading$.next(false);
              this.dialogRef.close();
            }
          },
            error => {
              if (error instanceof BaseResponseDto) {
                this.utilsService.alertErrorMessage(error);
              }
              this.isLoading$.next(false);
            });
      });
    });
  }
}
