import { Component, OnInit, Inject } from '@angular/core';
import { InvestingEntityReqRes } from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'terra-select-contact-investing-entity',
  templateUrl: './select-contact-investing-entity.component.html',
  styleUrls: ['./select-contact-investing-entity.component.scss']
})
export class SelectContactInvestingEntityComponent implements OnInit {

  selected = new UntypedFormControl();

  constructor(
    private dialogRef: MatDialogRef<SelectContactInvestingEntityComponent>,
    @Inject(MAT_DIALOG_DATA) public investingEntities: InvestingEntityReqRes[]) {
    dialogRef.addPanelClass('terra-select-contact-investing-entity');
  }

  ngOnInit() {

  }

}
