<form
  id="stepForm"
  [formGroup]="pageForm"
  *ngIf="pageForm"
  (ngSubmit)="stepSubmit()"
  class="form-grid-new container-750 terra-error-style"
>
  <h3 class="section-title">General</h3>

  <!-- Asset Name -->
  <div class="terra-form-field-wrapper">
    <label for="txtAssetName" class="text-input-label">Asset name</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        id="txtAssetName"
        formControlName="assetName"
        autocomplete="asset-name"
        class="lo_sensitive"
        placeholder="e.g. 143 Mott st."
      />
      <mat-error *ngIf="pageForm.get('assetName').hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="pageForm.get('assetName').hasError('minlength')">
        Please enter at least 2 characters
      </mat-error>
      <mat-error *ngIf="pageForm.get('assetName').hasError('assetNameTaken')">
        An asset with the same name already exists.
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Legal Name -->
  <div class="terra-form-field-wrapper">
    <label for="txtLegalName" class="text-input-label"
      >Legal name (can be changed later)</label
    >
    <mat-form-field appearance="outline">
      <input
        matInput
        id="txtLegalName"
        formControlName="legalName"
        autocomplete="investing-entity-name"
        class="lo_sensitive"
        placeholder="e.g. ABC group LLC"
      />
      <mat-error *ngIf="pageForm.get('legalName').hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="pageForm.get('legalName').hasError('minlength')">
        Please enter at least 2 characters
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Asset type / Property type -->
  <div class="terra-form-field-wrapper">
    <label for="ddlHoldingType" class="text-input-label">Property type</label>
    <mat-form-field appearance="outline">
      <mat-select
        id="ddlHoldingType"
        placeholder="Select"
        formControlName="holdingType"
      >
        <mat-option *ngFor="let item of assetTypesList" [value]="item">{{
          item | enumString : HoldingType
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="pageForm.get('holdingType').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Investment type -->
  <div class="terra-form-field-wrapper">
    <label for="ddlInvestmentType" class="text-input-label">
      Investment type
    </label>
    <mat-form-field appearance="outline">
      <mat-select
        id="ddlInvestmentType"
        placeholder="Select"
        formControlName="investmentType"
      >
        <mat-option *ngFor="let item of InvestmentTypesList" [value]="item">{{
          item | enumString : InvestmentType
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="pageForm.get('investmentType').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Other investment type text -->
  <div
    class="terra-form-field-wrapper"
    *ngIf="pageForm.get('investmentType').value === InvestmentType.Other"
  >
    <label for="txtInvestmentTypeOther" class="text-input-label">
      Please specify
    </label>
    <mat-form-field appearance="outline">
      <input
        type="text"
        matInput
        id="txtInvestmentTypeOther"
        formControlName="investmentTypeOther"
        class="lo_sensitive"
      />
      <mat-error
        *ngIf="pageForm.get('investmentTypeOther').hasError('required')"
      >
        Required
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Asset Address -->
  <ng-container>
    <h3 class="section-title">Asset Address</h3>
    <terra-google-map-search-input
      class="full-width"
      formControlName="assetLocation"
      [isOuterFormSubmitted]="isSubmitAttempt"
    ></terra-google-map-search-input>
  </ng-container>

  <h3
    class="section-title asset-details-section-title"
    *ngIf="pageForm.get('holdingType').value"
  >
    Asset details (optional)
    <span class="area-unit-toggle">
      <label>Units:</label>
      <mat-button-toggle-group
        formControlName="areaUnits"
        aria-label="Area unit"
        [hideSingleSelectionIndicator]="true"
      >
        <mat-button-toggle [value]="AreaUnit.SquareFeet"
          >Square foot</mat-button-toggle
        >
        <mat-button-toggle [value]="AreaUnit.SquareMeters"
          >Square meter</mat-button-toggle
        >
      </mat-button-toggle-group>
    </span>
  </h3>

  <!-- Lot size in sqft  -->
  <div
    class="terra-form-field-wrapper"
    *ngIf="isDisplayDataPoint(allowedDataPointsListFor.lotSizeArea)"
  >
    <label for="txtLotSize" class="text-input-label"
      >Lot size in {{ selectedAreaUnit | enumString : AreaUnit }}</label
    >
    <mat-form-field appearance="outline">
      <input
        type="tel"
        matInput
        id="txtLotSize"
        terraFormattedNumber
        formControlName="lotSizeArea"
        class="lo_sensitive"
      />
    </mat-form-field>
  </div>

  <!-- Stories  -->
  <div
    class="terra-form-field-wrapper"
    *ngIf="isDisplayDataPoint(allowedDataPointsListFor.stories)"
  >
    <label for="txtStories" class="text-input-label"> Number of floors </label>
    <mat-form-field appearance="outline">
      <input
        type="tel"
        matInput
        id="txtStories"
        terraFormattedNumber
        formControlName="stories"
        class="lo_sensitive"
      />
    </mat-form-field>
  </div>

  <!-- Residential units  -->
  <div
    class="terra-form-field-wrapper"
    *ngIf="isDisplayDataPoint(allowedDataPointsListFor.residentialUnits)"
  >
    <label for="txtResidentialUnits" class="text-input-label">
      Number of residential units
    </label>
    <mat-form-field appearance="outline">
      <input
        type="tel"
        matInput
        id="txtResidentialUnits"
        terraFormattedNumber
        formControlName="residentialUnits"
        class="lo_sensitive"
      />
    </mat-form-field>
  </div>

  <!-- Retail units  -->
  <div
    class="terra-form-field-wrapper"
    *ngIf="isDisplayDataPoint(allowedDataPointsListFor.retailUnits)"
  >
    <label for="txtRetailUnits" class="text-input-label">
      Number of retail units
    </label>
    <mat-form-field appearance="outline">
      <input
        type="tel"
        matInput
        id="txtRetailUnits"
        terraFormattedNumber
        formControlName="retailUnits"
        class="lo_sensitive"
      />
    </mat-form-field>
  </div>

  <!-- Rooms  -->
  <div
    class="terra-form-field-wrapper"
    *ngIf="isDisplayDataPoint(allowedDataPointsListFor.rooms)"
  >
    <label for="txtRooms" class="text-input-label"> Number of rooms </label>
    <mat-form-field appearance="outline">
      <input
        type="tel"
        matInput
        id="txtRooms"
        terraFormattedNumber
        formControlName="rooms"
        class="lo_sensitive"
      />
    </mat-form-field>
  </div>

  <!-- Gross building [area] -->
  <div
    class="terra-form-field-wrapper"
    *ngIf="isDisplayDataPoint(allowedDataPointsListFor.grossBuildingArea)"
  >
    <label for="txtGrossBuildingArea" class="text-input-label">
      Gross building in {{ selectedAreaUnit | enumString : AreaUnit }}
    </label>
    <mat-form-field appearance="outline">
      <input
        type="tel"
        matInput
        id="txtGrossBuildingArea"
        terraFormattedNumber
        formControlName="grossBuildingArea"
        class="lo_sensitive"
      />
    </mat-form-field>
  </div>

  <!--Residential net sellable [area] -->
  <div
    class="terra-form-field-wrapper"
    *ngIf="
      isDisplayDataPoint(allowedDataPointsListFor.residentialNetSellableArea)
    "
  >
    <label for="txtResidentialNetSellableArea" class="text-input-label">
      Residential net sellable in {{ selectedAreaUnit | enumString : AreaUnit }}
    </label>
    <mat-form-field appearance="outline">
      <input
        type="tel"
        matInput
        id="txtResidentialNetSellableArea"
        terraFormattedNumber
        formControlName="residentialNetSellableArea"
        class="lo_sensitive"
      />
    </mat-form-field>
  </div>

  <!-- Retail net sellable [area]-->
  <div
    class="terra-form-field-wrapper"
    *ngIf="isDisplayDataPoint(allowedDataPointsListFor.retailNetSellableArea)"
  >
    <label for="txtRetailNetSellableArea" class="text-input-label">
      Retail net sellable in {{ selectedAreaUnit | enumString : AreaUnit }}
    </label>
    <mat-form-field appearance="outline">
      <input
        type="tel"
        matInput
        id="txtRetailNetSellableArea"
        terraFormattedNumber
        formControlName="retailNetSellableArea"
        class="lo_sensitive"
      />
    </mat-form-field>
  </div>

  <!-- Retail net sellable [area]-->
  <div
    class="terra-form-field-wrapper"
    *ngIf="isDisplayDataPoint(allowedDataPointsListFor.numberOfSites)"
  >
    <label for="txtNumberOfSites" class="text-input-label">
      Number of sites
    </label>
    <mat-form-field appearance="outline">
      <input
        type="tel"
        matInput
        id="txtNumberOfSites"
        terraFormattedNumber
        formControlName="numberOfSites"
        class="lo_sensitive"
      />
    </mat-form-field>
  </div>
</form>

<section class="footer">
  <div class="container-750">
    <!-- Error message -->
    <div *ngIf="isSubmitAttempt" class="error-section">
      <p *ngIf="!pageForm.valid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
    <div class="form-actions">
      <a (click)="cancel()" class="cancel-button">Cancel</a>
      <button
        type="submit"
        form="stepForm"
        mat-flat-button
        color="primary"
        class="page-cta"
      >
        Next
      </button>
    </div>
  </div>
</section>
