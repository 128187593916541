<ng-template #loading>
  <terra-loader></terra-loader>
</ng-template>

<ng-container *ngIf="showDistributionsTab$ | async; else distributionsPreview">
  <ng-container *ngIf="distributions$ | async as distributions; else loading">
    <ng-container *ngIf="emptyState$ | async; else distributionsContent">
      <terra-gp-help-menu class="empty-state-help" [tours]="relevantTours" *ngIf="!(isRealized$ | async)"></terra-gp-help-menu>

      <!-- Empty state -->
      <section class="empty-state">
        <img src="assets/images/general/empty_state.svg"/>
        <h2>No distributions yet</h2>
        <p class="no-fundraisings-message" *ngIf="!(hasFundraisings$ | async) else hasFundraisings">
          Please create at least one contribution in order to create a distribution.
        </p>
        <ng-template #hasFundraisings>
          <a mat-stroked-button [routerLink]="importDistributionUrl$ | async" *ngIf="!(isRealized$ | async)" class="import-distribution-button-empty covercy-wired-button"
             color="primary">
             <mat-icon class="notranslate">cloud_upload</mat-icon>
            Import History
          </a>

          <a mat-flat-button *ngIf="!(isDraft$ | async)" [routerLink]="createDistributionUrl$ | async"
             class="create-distribution-button-empty" color="primary">
            <mat-icon class="notranslate">add</mat-icon>
            New Distribution
          </a>
        </ng-template>
      </section>
    </ng-container>

    <!-- Content -->
    <ng-template #distributionsContent>
      <section class="distribution-content" *ngIf="holdingId$ | async as holdingId">
        <!-- Actions -->
        <div class="header-actions">

          <terra-export-distributions-menu class="export-menu" [holdingId]="holdingId"></terra-export-distributions-menu>

          <terra-gp-help-menu [tours]="relevantTours" *ngIf="!(isRealized$ | async)"></terra-gp-help-menu>

          <a type="button" [routerLink]="importDistributionUrl$ | async" class="import-distribution-button covercy-wired-button"
          mat-stroked-button color="primary" *ngIf="!(isRealized$ | async)">
            <mat-icon class="notranslate">cloud_upload</mat-icon>
            Import History
          </a>

          <a type="button" [routerLink]="createDistributionUrl$ | async" *ngIf="!(isDraft$ | async)"
          class="create-distribution-button covercy-wired-button" mat-flat-button color="primary">
            <mat-icon class="notranslate">add</mat-icon>
            New Distribution
          </a>
        </div>

        <table mat-table [dataSource]="distributions" class="cov-basic-table" matSort matSortDisableClear
               (matSortChange)="sortData($event)"
               [matSortDirection]="(searchOptions$ | async).sortOrder"
               [matSortActive]="(searchOptions$ | async).orderBy">

          <!-- Distribution for column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Name">Distribution for</th>
            <td mat-cell *matCellDef="let distribution">{{ distribution.name }}</td>
          </ng-container>

          <!-- Notice sent date column -->
          <ng-container matColumnDef="noticeSentTimeStamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="NoticeSentTimeStamp">Notice date</th>
            <td mat-cell *matCellDef="let distribution">
              {{ distribution.noticeSentTimeStamp ? (distribution.noticeSentTimeStamp | utcDate) : '' }}
            </td>
          </ng-container>

          <!-- Payout date column -->
          <ng-container matColumnDef="payoutSentTimeStamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="PayoutSentTimeStamp">Payout date</th>
            <td mat-cell *matCellDef="let distribution">
              {{ distribution.payoutSentTimeStamp ? (distribution.payoutSentTimeStamp | utcDate) : '' }}
            </td>
          </ng-container>

          <!-- overall Settlement Date date column -->
          <ng-container matColumnDef="overallSettlementDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="OverallSettlementDate">Complete date</th>
            <td mat-cell *matCellDef="let distribution">
              {{ distribution.overallSettlementDate ? (distribution.overallSettlementDate | utcDate) : '' }}
            </td>
          </ng-container>

          <!-- Total amount to be distributed column -->
          <ng-container matColumnDef="totalAmountForDistribution">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="TotalAmountForDistribution">Gross amount</th>
            <td mat-cell *matCellDef="let distribution">
              {{distribution.distributionSourceCurrency?.symbol }}{{(distribution.totalAmountForDistribution | terraCurrency) || 0}}
            </td>
          </ng-container>

          <!-- Type (DistributionTransactionPurpose/ReasonForTransaction) column -->
          <ng-container matColumnDef="reasonForTransaction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="ReasonForTransaction">Type</th>
            <td mat-cell *matCellDef="let distribution">
              {{ distribution.reasonForTransaction | enumString: DistributionType }}
            </td>
          </ng-container>

          <!-- Status column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Status">Status</th>
            <td mat-cell *matCellDef="let distribution">
              <span *ngIf="distribution.isGeneratingOrders else distributionStatus">
                <mat-icon class="processing-icon notranslate">access_time</mat-icon> Processing distribution
              </span>
              <ng-template #distributionStatus>
                {{ distribution.status | enumString: DistributionStatus }}
              </ng-template>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let distribution; columns: displayedColumns"
              [routerLink]="getDistributionPageRoute(holdingId, distribution.id)"></tr>
        </table>
      </section>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #distributionsPreview>
  <div class="feature-locked">
    <div class="example-block example-strip">
      <p>
        Collect payout bank account details from your <br> investors and notify them about upcoming payments. <br>
        Settle the payments easily and track the payment process online.
      </p>
    </div>
    <p class="upsell-text">
      <mat-icon class="notranslate">lock</mat-icon>
      <span>Interested in our premium packages? Please
        <a target="_blank" href="https://www.covercy.com/contact-us" class="contact-us-link link"> contact us</a>
      </span>
    </p>
  </div>
</ng-template>
