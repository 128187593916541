<div id="chart">
  <apx-chart
    [series]="chartSeries$ | async"
    [colors]="chartOptions.colors"
    [chart]="chartOptions.chart"
    [labels]="chartOptions.labels"
    [dataLabels]="chartOptions.dataLabels"
    [tooltip]="chartOptions.tooltip"
    [legend]="chartLegend$ | async"
    [states]="chartOptions.states"
  ></apx-chart>
</div>



