import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'terra-fundraising-summary',
  templateUrl: './fundraising-summary.component.html',
  styleUrls: ['./fundraising-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FundraisingGetStartedComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
   
  }
}
