<ng-container *ngIf="{
                        holding: holding$ | async,                      
                        hasApplication: hasApplication$ | async,
                        investorsOverview: investorsOverview$ | async
                    } as data">
    <div class="card-header-small bank-card-title">
        <h3>Actions</h3>
        <!-- <button *ngIf="context.hasApplication && context.creApplication?.status===UnitApplicationStatus.Approved" (click)="openCreateAccount()" [matTooltip]="'Add Bank Account'"
                color="primary" mat-raised-button>
          + Add Account
        </button> -->
        <!-- <button [matTooltip]="'Add Bank Account'" color="primary" mat-flat-button (click)="createBankAccount()">
            {{ data.hasApplication ? '+ Add Account' : '+ Create Account'}}
        </button> -->
    </div>


    <div class="content-inner">

        <mat-action-list>
            <button mat-list-item (click)="data.hasApplication && actionClicked($event,'Next','pay')" [ngClass]="{'disabled-action': !data.hasApplication}"
                    terraCheckPermissions [minimumFinancialPermissionLevel]="FinancialPermissionLevel.AuthorizedSignatory"
                    [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.PopupOnClick">
                <!-- <div class="round-icon">
                    <mat-icon class="action-icon material-icons-outlined">payments</mat-icon>
                </div> -->
                <div class="button-inner"
                    [matTooltip]="'Create a free Covercy account for 1-click, hassle-free distributions, vendor payments, capital calls and more'"
                    [matTooltipPosition]="'below'" [matTooltipDisabled]="data.hasApplication" matTooltipClass="terra-tooltip-compact">
                    <span>PAY</span>
                    <div class="round-icon">
                        <mat-icon class="action-icon material-icons-outlined">arrow_circle_right</mat-icon>
                    </div>
                </div>
            </button>
            <button mat-list-item (click)="data.hasApplication && actionClicked($event,'Next', 'fund')" [ngClass]="{'disabled-action': !data.hasApplication}"
                    terraCheckPermissions [minimumFinancialPermissionLevel]="FinancialPermissionLevel.AuthorizedSignatory"
                    [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.PopupOnClick">
                <!-- <div class="round-icon">
                    <mat-icon class="action-icon notranslate" svgIcon="get-cash"></mat-icon>
                </div> -->
                <div class="button-inner"
                    [matTooltip]="'Create a free Covercy account for 1-click, hassle-free distributions, vendor payments, capital calls and more'"
                    [matTooltipPosition]="'below'" [matTooltipDisabled]="data.hasApplication" matTooltipClass="terra-tooltip-compact">
                    <span>FUND MY ACCOUNT</span>
                    <div class="round-icon">
                        <mat-icon class="action-icon material-icons-outlined">arrow_circle_right</mat-icon>
                    </div>
                </div>
            </button>

            <mat-divider></mat-divider>
            <button mat-list-item
                (click)="data.hasApplication ? actionClicked($event, 'Next','contribute') : goToContribution()">
                <!-- <div class="round-icon">
                    <mat-icon class="action-icon material-symbols-outlined">currency_exchange</mat-icon>
                </div> -->
                <div class="button-inner">
                    <span>CREATE CONTRIBUTION</span>
                    <div class="round-icon">
                        <mat-icon class="action-icon material-icons-outlined">arrow_circle_right</mat-icon>
                    </div>
                </div>
            </button>
            <button mat-list-item
                (click)="data.hasApplication ? actionClicked($event,'Next','distribute') : goToDistribution()">
                <!-- <div class="round-icon">
                    <mat-icon class="action-icon material-symbols-outlined">output</mat-icon>
                </div> -->
                <div class="button-inner">
                    <span>CREATE DISTRIBUTION</span>
                    <div class="round-icon">
                        <mat-icon class="action-icon material-icons-outlined">arrow_circle_right</mat-icon>
                    </div>
                </div>
            </button>
            <mat-divider></mat-divider>

            <button mat-list-item (click)="createReport()">
                <!-- <div class="round-icon">
                    <mat-icon class="action-icon material-symbols-outlined">output</mat-icon>
                </div> -->
                <div class="button-inner">
                    <span>CREATE REPORT</span>
                    <div class="round-icon">
                        <mat-icon class="action-icon material-icons-outlined">arrow_circle_right</mat-icon>
                    </div>
                </div>
            </button>

            <button mat-list-item (click)="openFilePicker()">
                <!-- <div class="round-icon">
                    <mat-icon class="action-icon material-symbols-outlined">output</mat-icon>
                </div> -->
                <div class="button-inner">
                    <span>UPLOAD FILE</span>
                    <div class="round-icon">
                        <mat-icon class="action-icon material-icons-outlined">arrow_circle_right</mat-icon>
                    </div>
                </div>
            </button>

            <button mat-list-item (click)="openOwnershipTransferDialog()">
                <!-- <div class="round-icon">
                    <mat-icon class="action-icon material-symbols-outlined">output</mat-icon>
                </div> -->
                <div class="button-inner">
                    <span>TRANSFER OWNERSHIP</span>
                    <div class="round-icon">
                        <mat-icon class="action-icon material-icons-outlined">arrow_circle_right</mat-icon>
                    </div>
                </div>
            </button>

        </mat-action-list>

        <!-- <mat-action-list>

            <button mat-list-item color="primary" (click)="addBankAccount()">
                <div class="round-icon">
                    <mat-icon class="action-icon material-symbols-outlined">add</mat-icon>
                </div>

                <span>ADD ACCOUNT</span>

            </button>

            <mat-divider></mat-divider>
        </mat-action-list> -->

        <!-- <div class="stepper-wrapper">
            <mat-horizontal-stepper linear #stepper>
                <mat-step>

                </mat-step>
                <mat-step>
                    <div class="content-inner">
                        <mat-action-list>

                            <button mat-list-item matStepperPrevious>
                                <div class="round-icon">
                                    <mat-icon class="action-icon material-icons-outlined">arrow_circle_left</mat-icon>
                                </div>
                                <span>Back</span>
                            </button>
                            </mat-action-list>


                            <button mat-flat-button>
                                Action
                            </button>
                    </div>
                </mat-step>
            </mat-horizontal-stepper> -->


    </div>







</ng-container>
