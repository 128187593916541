import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {distinctUntilChanged} from 'rxjs/operators';

import {GpDistributionDataService} from 'src/app/services/gp/gp-distribution-data.service';
import {UtilsService} from 'src/app/services/utils.service';
import HTTP_STATUS_CODES from 'src/app/shared/enums/HttpStatusCodesEnum';
import {ErrorMatcher, ErrorType} from 'src/app/shared/errors/ErrorMatcher';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import DistributionTransactionPurpose from '../../../../../../shared/enums/DistributionTransactionPurpose.enum';
import {BaseResponseDto} from '../../../../../../shared/models/BaseResponseDto.model';

@Component({
  selector: 'terra-distribution-import-dialog',
  templateUrl: './distribution-import-dialog.component.html',
  styleUrls: ['./distribution-import-dialog.component.scss']
})
export class DistributionImportDialogComponent extends OnDestroyMixin implements OnInit {

  pageForm = this.fb.group({
    file: [null, Validators.required],
    includeProrata: false,
    proRata: ''
  });

  isSubmitted = false;
  private loadingCounter = 0;

  get loadingPage(): boolean {
    return this.loadingCounter !== 0;
  }

  isGeneralServerError = false;
  generalServerErrorMessage: string;

  supportedFileExtensions = TerraUtils.consts.supportedFileExtensions.CREATE_DISTRIBUTION_IMPORT;
  reasonsForTransfer: DistributionTransactionPurpose[] = this.dialogData.reasonsForTransfer;

  constructor(
    private gpDistributionDataService: GpDistributionDataService,
    private utilsService: UtilsService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DistributionImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
    super();
    dialogRef.addPanelClass('distribution-import-dialog');
    this.pageForm.controls['proRata'].setValue(this.dialogData.proRata);
  }

  ngOnInit() {
    this.handleFileInputValueChange();
  }

  downloadTemplate() {
    this.loadingCounter++;
    const totalProrata = this.pageForm.value.includeProrata && this.pageForm.value.proRata || 0;
    this.gpDistributionDataService.getDistributionTemplate(this.dialogData.holdingId, totalProrata, this.reasonsForTransfer)
      .subscribe(fileUrl => {
        if (fileUrl) {
          this.utilsService.downloadFileFromUrl(fileUrl);
        }
        this.loadingCounter--;
      });
  }

  import() {
    this.isSubmitted = true;
    this.isGeneralServerError = false;
    if (this.pageForm.valid) {
      this.loadingCounter++;
      const metaFileLinkId = this.pageForm.value.file.id;
      this.gpDistributionDataService.getDistributionTransfersFromExcelFile(this.dialogData.holdingId, metaFileLinkId)
        .subscribe(
          transfers => {
            this.dialogRef.close(transfers);
          },
          error => {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            } else if (ErrorMatcher.isError(error, ErrorType.BadFileFormatException)) {
              this.generalServerErrorMessage = 'Failed importing distribution. Please make sure the file type is .xlsx and that the content is formatted correctly';
            } else if (ErrorMatcher.isError(error, ErrorType.ValueNotSupportedException)) {
              // one of the numbers couldn't be parsed?
              if (error.error.data) {
                const rowNumber = this.getRowNumberFromErrorData(error.error.data);
                if (rowNumber) {
                  this.generalServerErrorMessage = `Error in row: ${rowNumber}. Please check the file and make sure all payment amount are valid.`;
                } else {
                  this.generalServerErrorMessage = `Error in row of the amounts. Please provide valid numbers as payment amounts for each transfer.`;
                }
              } else {
                this.generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;
              }
            } else if (error.status === HTTP_STATUS_CODES.NOT_ACCEPTABLE) {
              this.generalServerErrorMessage = 'Failed importing distribution. make sure the data is valid.';
            } else {
              this.generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;
            }
            this.isGeneralServerError = true;
            this.loadingCounter--;
          }
        );
    }
  }

  /** When the user clears the file uploader, clear the error messages also. */
  private handleFileInputValueChange() {
    this.pageForm.get('file').valueChanges.pipe(untilComponentDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        if (value == null) {
          this.clearErrors();
        }
      });
  }

  private getRowNumberFromErrorData(errorData: string): number {
    if (typeof errorData === 'string' && errorData.indexOf('ROW:') === 0) {
      // The format is: "ROW:NNNN"
      const number = errorData.substr(4);
      return +number;
    }
    return null;
  }

  private clearErrors() {
    this.isGeneralServerError = false;
    this.generalServerErrorMessage = null;
  }
}
