import {FundraisingStatus} from 'src/app/shared/enums/FundraisingStatus.enum';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import FundraisingType from 'src/app/shared/enums/FundraisingType.enum';

export class FundraisingTableItemResponse {
  constructor(
    public id: number = null,
    public name: string = null,
    public status: FundraisingStatus = null,
    public estimatedClosingDate: Date = null,
    public finalClosingDate: Date = null,
    public investorCount: number = null,
    public totalInvestedAmount: number = null,
    public fundraisingTargetCurrency: CurrencyModel = null,
    public type: FundraisingType = FundraisingType.None,
    public isDefaultDeck: boolean = null,
    public hasDeck: boolean = null
  ) { }
}
