import {Component, OnInit} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

import {EditAssetStepBase, EditAssetTabNumber} from '../EditAssetStepBaseAndInterface';
import {EditAssetService} from '../edit-asset.service';
import {AlertDialogParams} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {DialogService} from 'src/app/services/dialog.service';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';

@Component({
  selector: 'terra-edit-asset-tab-more-settings',
  templateUrl: './edit-asset-tab-more-settings.component.html',
  styleUrls: ['./edit-asset-tab-more-settings.component.scss']
})
export class EditAssetTabMoreSettingsComponent extends EditAssetStepBase implements OnInit {
  readonly TAB_NUMBER: EditAssetTabNumber = 5;
  showSecondDocumentsRow = false;

  get navTooltipText() {
    return `Net Asset Value (NAV) is calculated using this formula: (Estimated Market Value - Loan Amount) × Investor Ownership Percentage. To configure this field, navigate to Edit Asset → Investment Details.`;
  }

  constructor(private editAssetService: EditAssetService, private dialogService: DialogService) {
    super(editAssetService);
  }

  ngOnInit() {
    const assetPaymentRequestDocuments = this.dialogContext.asset.attachments.filter(x => x.fileType === HoldingFileType.PaymentRequestDocument);
    this.showSecondDocumentsRow = assetPaymentRequestDocuments && assetPaymentRequestDocuments.length > 4;
  }

  makeSurePrivateStateAllowed(isPrivateToggle: MatSlideToggleChange) {
    const isPrivate = isPrivateToggle.checked;
    // if attempting to change to private, make sure its allowed
    if (isPrivate) {
      if (!this.dialogContext.asset.canSetAsPrivate) {
        this.pageForm.get('isPrivate').setValue(false);
        this.dialogService.alertDialog(
          new AlertDialogParams('Change asset mode to down', `Asset mode cannot be changed to down when there are outstanding payment requests.`));
        return false;
      }
    }
  }

  makeSureNavIsAllowed(displayNetAssetToggle: MatSlideToggleChange) {
    if (displayNetAssetToggle.checked) {
      if (!this.editAssetService.assetForm.get('tab2.cumulativeInformation.estimatedMarketValue')?.value) {
        this.dialogService.alertDialog(
          new AlertDialogParams('Missing Details', `The Estimated Market Value needs to be configured as it is essential for calculating the Net Asset Value. To configure it go to Edit Asset tab → Investment Details → Estimated Market Value.`)
        ).afterClosed().subscribe(_ => {
            this.pageForm.get('displayNetAssetValue').setValue(false);
            displayNetAssetToggle.source.checked = false;
          }
        );
      }
    }
  }
}
