import { Component, signal, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition, group, query, AnimationEvent, sequence } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';

const visible = { transform: 'translateY(180px)', opacity: 0 };

@Component({
  selector: 'rm-loader',
  standalone: true,
  imports: [CommonModule, LoaderComponent],
  template: `
  <div class="flex flex-column gap-4 m-auto" [class.final]="animationState() === 'final'" [@elementGroup]="animationState()" (@elementGroup.done)="onAnimationDone($event)">
    <div class="element-container" >
      <div class="element element3">
        <img src="assets/images/logo/rent_manager_logo.png" class="company_logo" alt="Rent Manager logo"/>
      </div>
      <div class="element element2">
        <terra-loader color="primary" />
    </div>
      <div class="element element1">
        <img src="assets/images/logo/covercy_c_logo.svg" class="company_logo" alt="Covercy logo"/>
      </div>
    </div>
    <div class="status text-center ">Connecting to <strong>Rent Manager</strong></div>
  </div>
  `,
  styles: [`
    :host {
      display: flex;
      width: 100%;  
      height: 100%;
    }
    .company_logo{
      height: 60px;
      width: 60px;
      display: block;
    }
    .element-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      width: 224px;
      margin: auto;
    }
    .element {
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      transition: all 0.2s ease;
      opacity: 0;
    }
    .element1{
      opacity: 1;
    }
    .final .element {
      opacity: 1
    }
    .final .element1 { transform: translateX(-80px); }
    .final .element3 { transform: translateX(80px); }
    .status { opacity: 0; }
    .final .status { opacity: 1; }
    ::ng-deep .circle { background-color: #F79009 !important; }
  `],
  animations: [
    trigger('elementGroup', [
      state('initial', style({ opacity: 1 })),
      state('final', style({ opacity: 1 })),
      state('animating', style({ opacity: 1 })),
      transition('initial => animating', [
        style({ opacity: 1 }),
        query('.element2', style({ opacity: 0, transform: 'translateX(0)' })),
        query('.element3', style({ opacity: 0, transform: 'translateX(0)' })),
        query('.element1', style({ transform: 'translateX(0)' })),
        query('.status', style({ opacity: 0, })),
        group([
          query('.element1', [
            animate('250ms ease-out', style({ transform: 'translateX(-80px)' }))
          ]),
          query('.element3', [
            sequence([
              animate('250ms', style({ opacity: 0 })),
              animate('250ms ease-in', style({ opacity: 1 })),
              animate('250ms ease-out', style({ transform: 'translateX(80px)' }))
            ])
          ]),
          query('.element2', [
            sequence([
              animate('600ms', style({ opacity: 0 })),
              animate('150ms ease-in', style({ opacity: 1 }))
            ])
          ]),
          query('.status', [
            sequence([
              animate('700ms', style({ opacity: 0 })),
              animate('150ms ease-in', style({ opacity: 1 }))
            ])
          ])
        ])
      ]),
      transition('animating => initial', [
        group([
          query('.status', animate('150ms ease-in', style({ opacity: 0 }))),
          query('.element2', animate('250ms ease-in', style({ opacity: 0 }))),
          query('.element3', [
            sequence([
              animate('250ms', style({})),
              animate('250ms ease-in', style({ opacity: 0, transform: 'translateX(0)' }))
            ])
          ]),
          query('.element1', [
            sequence([
              animate('500ms', style({})),
              animate('300ms ease-out', style({ transform: 'translateX(0)' })),
            ])
          ])
        ])
      ])
    ])
  ]
})
export class AnimatedElementsComponent {
  animationState = signal<'initial' | 'animating' | 'final'>('initial');
  private animationInProgress = signal<boolean>(false);

  @Output() animationComplete = new EventEmitter<void>();

  enter(): void {
    // if (this.animationInProgress()) return;
    this.animationInProgress.set(true);
    this.animationState.set('animating');
  }

  leave(): void {
    // if (this.animationInProgress()) return;
    this.animationInProgress.set(true);
    this.animationState.set('animating');
    this.animationState.set('initial');
  }

  onAnimationDone(event: AnimationEvent): void {
    this.animationInProgress.set(false);
    if (this.animationState() === 'animating') {
      this.animationState.set(event.toState === 'animating' ? 'final' : 'initial');
    }

    if(event.toState === 'final'){
        this.animationComplete.emit();
    }
  }
}