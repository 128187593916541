import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InvestingEntityReqRes} from 'src/app/dashboard/models/InvestingEntityReqRes.model';

@Component({
  selector: 'terra-send-confirmation-dialog',
  templateUrl: './send-confirmation-dialog.component.html',
  styleUrls: ['./send-confirmation-dialog.component.scss']
})
export class SendConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SendConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: InvestingEntityReqRes[] ) { }

  ngOnInit(): void {}

  send(){
    this.dialogRef.close(true);
  }

}
