<ng-container *ngIf="chartSeries$ | async as chartSeries; else loader">
    <div id="chart">
        <apx-chart
            [series]="chartSeries"
            [chart]="chartOptions.chart"
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [xaxis]="chartOptions.xaxis"
            [yaxis]="chartOptions.yaxis"
            [tooltip]="chartOptions.tooltip"
            [legend]="chartOptions.legend"
            [colors]="chartOptions.colors"
            [grid]="chartOptions.grid"
            [states]="chartOptions.states"
        ></apx-chart>
        </div>
</ng-container>

<ng-template #loader>
    <terra-loader></terra-loader>
</ng-template>
