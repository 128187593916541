<button mat-button class="p-0 preview-button" aria-label="Preview File" (click)="openPreview()" [disableRipple]="true">
  <div class="file-label">
    <ng-container [ngSwitch]="file.extension">
      <ng-container *ngSwitchCase="'pdf'">
        <mat-icon svgIcon="pdf_doc"></mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'csv'">
        <mat-icon svgIcon="csv_doc"></mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'gif'">
        <mat-icon svgIcon="gif_doc"></mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'jpg'">
        <mat-icon svgIcon="jpg_doc"></mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'png'">
        <mat-icon svgIcon="png_doc"></mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'xsl'">
        <mat-icon svgIcon="xls_doc"></mat-icon>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <mat-icon svgIcon="any_doc"></mat-icon>
      </ng-container>
    </ng-container>
    {{file.displayName}}
  </div>
</button>
