// Notice:  This enum also sxists on server side. Make sure they stay in sync.
export enum PaymentPlatform {
  Covercy = 1,
  External = 2,
  Internal = 3,
  achDebit = 4
}

// tslint:disable-next-line:no-namespace
export namespace PaymentPlatform {
  export function toString(paymentPlatform: PaymentPlatform): string {
    switch (paymentPlatform) {
      case PaymentPlatform.Covercy:
        return 'Covercy';
      case PaymentPlatform.External:
        return 'External';
      case PaymentPlatform.Internal:
        return 'Internal';
      case PaymentPlatform.achDebit:
        return 'ACH Debit';
      default:
        return null;
    }
  }

  export function parse(paymentPlatformStr: string): PaymentPlatform {
    return PaymentPlatform[paymentPlatformStr];
  }
}

export default PaymentPlatform;
