import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestmentReqRes } from 'src/app/dashboard/models/investment.model';
import { NumberUtils } from 'src/app/shared/utils/number-utils';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';

@Component({
  selector: 'terra-raised-amount-warning',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './raised-amount-warning.component.html',
  styleUrls: ['./raised-amount-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RaisedAmountWarningComponent implements OnInit {
  @Input() investments: InvestmentReqRes[] = [];
  @Input() fundraisingTargetAmount:number = 0;
  @Input() symbol:string = '';

  constructor() { }

  ngOnInit(): void {
  }

  oversubscriptionAmount() {
    if (!this.investments || this.investments.length < 1) {
      return;
    }

    // Matan asked not to include potential investments
    const sum = NumberUtils.reduceDecimal(this.investments
      .filter(x => x.status !== InvestmentStatus.Declined && x.status !== InvestmentStatus.Potential)
      .map(i => i.finalAmount));

    const overSub = sum - this.fundraisingTargetAmount;

    return overSub < 0 ? 0 : overSub;
  }

}
