import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {take} from 'rxjs/operators';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import {CreateFundAssetService, CreateFundAssetStepNumber} from './create-fund-asset.service';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {UserService} from 'src/app/services/shared/user.service';

@Component({
  selector: 'terra-create-fund-asset',
  templateUrl: './create-fund-asset.component.html',
  styleUrls: ['./create-fund-asset.component.scss'],
  providers: [CreateFundAssetService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFundAssetComponent implements OnInit {

  processingSubmit$ = this.createFundAssetService.processingSubmit$;
  currentStep$ = this.createFundAssetService.currentStep$;

  constructor(
    private titleService: Title,
    private createFundAssetService: CreateFundAssetService,
    private telemetryService: TelemetryService,
    private userService: UserService
    ) { }

  ngOnInit() {
    this.titleService.setTitle('Create Fund Asset' + TerraUtils.consts.GpPageTitleSuffix);
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
      this.telemetryService.create({
        eventID: '13',
        eventTitle: 'CREATE FUND ASSET (INIT)',
        organizationID: clientDetails.organizationDetails.id
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });
  }

  navigateBackToStep(step: CreateFundAssetStepNumber) {
    const currentStep = this.currentStep$.value;
    if (currentStep > step) {
      this.currentStep$.next(step);
    }
  }

  cancel() {
    this.createFundAssetService.cancel();
  }
}
