import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, SkipSelf, inject } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'terra-marketing-document-uploader',
  templateUrl: './marketing-document-uploader.component.html',
  styleUrls: ['./marketing-document-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders:[
    {
      provide:ControlContainer,
      useFactory: () => inject(ControlContainer, {skipSelf:true})
    }
  ]
})
export class MarketingDocumentUploaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isSubmitAttempt: boolean = false;
  @Input() docControlName: string ='';
  @Input() docZoomControlName: string ='';
  @Input() docZoomValControlName: string ='';

  parentContainer = inject(ControlContainer);
  subscritpion = new Subscription();

  constructor() { }

  ngAfterViewInit(): void {
    this.disableZoomControl(this.parentFormGroup.get(this.docZoomControlName).value)
    this.subscritpion = this.parentFormGroup.get(this.docZoomControlName).valueChanges
    .subscribe(val => this.disableZoomControl(val));
  }

  private disableZoomControl(val){
    if(val === 'auto'){
      this.parentFormGroup.get(this.docZoomValControlName).disable();
      return;
    }
    this.parentFormGroup.get(this.docZoomValControlName).enable();
  }

  ngOnDestroy(): void {
    this.subscritpion.unsubscribe();
  }

  ngOnInit(): void {
    
  }

  get parentFormGroup(){
    return this.parentContainer.control as UntypedFormGroup;
  }

  sliderLabel(value: number) {
    return `${value}%`;
  }
}
