export enum PaymentStatus {
  NotSent = 0,
  Other = 1,
  Sent = 2,
  Submitted = 3,
  FundsReceived = 4,
  Approved = 5,
  Completed = 6,
  Canceled = 7,
}

// tslint:disable-next-line:no-namespace
export namespace PaymentStatus {
  export function toString(paymentStatus: PaymentStatus): string {
    switch (paymentStatus) {
      case PaymentStatus.NotSent:
        return 'Payment request not yet sent';
      case PaymentStatus.Sent:
        return 'Request sent';
      case PaymentStatus.Submitted:
        return 'Transfer submitted';
      case PaymentStatus.FundsReceived:
        return 'Funds received';
      case PaymentStatus.Approved:
        return 'Transfer approved';
      case PaymentStatus.Completed:
        return 'Transfer completed';
      case PaymentStatus.Canceled:
        return 'Transfer canceled';
      case PaymentStatus.Other:
        return 'Status unknown';
      default:
      return 'Status unknown';
    }
  }

  export function parse(paymentStatusStr: string): PaymentStatus {
    return PaymentStatus[paymentStatusStr];
  }
}

export default PaymentStatus;
