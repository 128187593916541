<ng-template #stepLoading>
  <terra-loader class="container-750 place-middle"></terra-loader>
</ng-template>

<form id="stepForm" [formGroup]="pageForm" *ngIf="stepIsLoading$ | async; else stepLoading" (ngSubmit)="stepSubmit()"
      class="form-grid-new container-750 terra-error-style">

  <h3 class="section-title">
    Bank account for collecting deposits
  </h3>

  <!-- Bank account -->
  <div class="terra-form-field-wrapper" *ngIf="!noAccessMessage">
    <label for="ddlBankAccount" class="text-input-label">Choose from existing:</label>
    <mat-form-field appearance="outline" *ngIf="bankAccounts$ | async as bankAccounts">
      <mat-select id="ddlBankAccount" [compareWith]="bankAccountComparer" [placeholder]="
          bankAccounts.length? 'Select' : 'No ' + selectedCurrency.iso + ' bank accounts found'" formControlName="bankAccount">
        <mat-option [value]="null">Select</mat-option>
        <mat-option *ngFor="let bankAccount of bankAccounts" [value]="bankAccount">
          {{ bankAccount.nickname }} {{ !bankAccount.nickname ? bankAccount.holderFullName + ', ' : '' }}
          {{ !bankAccount.nickname ? getCountryById(bankAccount.countryId).code : '' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="!noAccessMessage">
    <a class="link add-bank-account" (click)="AddBankAccount()">Add new</a>
  </div>
  <!-- Bank account -->

  <div class="full-width not-allow-message" *ngIf="noAccessMessage">
    <mat-icon class="notranslate">error</mat-icon>
    <h4>{{noAccessMessage}}</h4>
  </div>

  <h3 class="section-title">
    Investor portal
  </h3>
  <div class="full-width terra-form-field-wrapper allow-add-bank">
    <span>Investors can set bank account</span>
    <mat-slide-toggle hideIcon labelPosition="before" formControlName="allowLpSetBank"></mat-slide-toggle>
  </div>

  <!-- Eerly deposit checkbox -->
  <h3 class="section-title">
    Collect deposits into Covercy safeguarded account (optional)
  </h3>

  <p class="early-deposit-text description full-width">
    Guarantee the availability of funds at closing by requesting deposits
    during the fundraising process. The funds received will be parked in a
    segregated account until the closing terms are met.
  </p>

  <div class="full-width">
    <mat-checkbox class="regular-checkbox early-deposit-checkbox" color="primary" formControlName="isCollectDeposists">I
      want to collect
      deposits in Covercy's segregated account
    </mat-checkbox>
    <p *ngIf="pageForm.get('isCollectDeposists').value" [@enterAnimation] class="info-message">
      Your Covercy account manager will reach out shortly with more
      information about early deposits.
    </p>
  </div>

  <!-- Asset down mode -->
  <h3 class="section-title">Asset down mode</h3>
  <terra-holding-is-down-card [holdingDiscriminator]="HoldingDiscriminator.Asset"
                              [isPrivate]="pageForm.get('isPrivate').value"
                              class="full-width">
    <mat-slide-toggle hideIcon labelPosition="before" formControlName="isPrivate"></mat-slide-toggle>
  </terra-holding-is-down-card>

</form>

<section class="footer">
  <div class="container-750">
    <!-- Error message -->
    <div *ngIf="isSubmitAttempt" class="error-section">
      <p *ngIf="pageForm.invalid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
    <div class="form-actions">
      <a (click)="back()" class="cancel-button">Back</a>
      <button type="submit" form="stepForm" mat-flat-button color="primary" class="page-cta">
        Create asset
      </button>
    </div>
  </div>
</section>
