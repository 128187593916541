import { AfterViewInit, Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, EMPTY, NEVER, Observable, Subject, combineLatest, of, timer } from 'rxjs';
import { catchError, debounceTime, filter, map, shareReplay, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InvestingEntityType } from 'src/app/dashboard/models/InvestingEntityType.enum';
import { InvestmentReqRes } from 'src/app/dashboard/models/investment.model';
import { PermissionService } from 'src/app/permission/permission.service';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { GpInvestmentDataService } from 'src/app/services/gp/gp-investment-data.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { SystemFeatureFlagConsts } from 'src/app/shared/consts/SystemFeatureFlags.consts';
import { AccreditationStatus } from 'src/app/shared/enums/AccreditationStatus.enum';
import { InvestmentStatus } from 'src/app/shared/enums/InvestmentStatus.enum';
import { InvestorsOrderBy } from 'src/app/shared/enums/InvestorsOrderBy.enum';
import { PaymentPlatform } from 'src/app/shared/enums/PaymentPlatform.enum';
import { AppQuery, AppService } from 'src/app/state';
import { FeatureFlags } from '../../../../account/my-account/model/FeatureFlags.enum';
import { DialogService } from '../../../../services/dialog.service';
import { UserService } from '../../../../services/shared/user.service';
import { SnackbarService } from '../../../../services/snackbar.service';
import { UtilsService } from '../../../../services/utils.service';
import { ConfirmDialogParams } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { HoldingDiscriminator } from '../../../../shared/enums/HoldingDiscriminator.enum';
import { PaymentStatus } from '../../../../shared/enums/PaymentStatus.enum';
import { SignPlatform } from '../../../../shared/enums/SignPlatform.enum';
import { LoggerService } from '../../../../shared/errors/logger.service';
import { BaseResponseDto } from '../../../../shared/models/BaseResponseDto.model';
import {
  FindContactInListDialogComponent
} from '../../../assets/gp-asset/asset-invite-investor/find-contact-in-list-dialog/find-contact-in-list-dialog.component';
import {
  SelectContactInvestingEntityComponent
} from '../../../assets/gp-asset/asset-invite-investor/select-contact-investing-entity/select-contact-investing-entity.component';
import {
  InvestorContactDialogComponent
} from '../../../contacts/components/investor-contact-dialog/investor-contact-dialog.component';
import { InvestorContactReqRes } from '../../../contacts/models/investorContactReqRes.model';
import { InvestingEntityReqRes } from '../../../models/InvestingEntityReqRes.model';
import { AIRankingService } from '../fundraising/AI-investors-ranking/AI-ranking.service';
import { InvestorRank } from '../fundraising/AI-investors-ranking/InvestorRank.model';
import { HoldingFundraisingService } from '../fundraising/holding-fundraising.service';
import { GpHoldingService } from '../gp-holding.service';
import {
  ParticipatingFundDialogComponent
} from '../investor/participating-fund-dialog/participating-fund-dialog.component';
import { CommitmentsEnabled } from 'src/app/shared/enums/CommitmentsEnabled.enum';
import { InvestorsManagementService } from '../Investors-management/investors-management.service';

@Component({
  selector: 'terra-investors-list',
  templateUrl: './investors-list.component.html',
  styleUrls: ['./investors-list.component.scss'],
  providers: [AIRankingService]
})
export class InvestorsListComponent implements OnInit, AfterViewInit {
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  @Input() isReadOnlyContribution$: Observable<boolean>;

  // enums:
  InvestorsOrderBy = InvestorsOrderBy;
  InvestmentStatus = InvestmentStatus;
  InvestingEntityType = InvestingEntityType;
  InvestorRank = InvestorRank;
  HoldingDiscriminator = HoldingDiscriminator;
  PaymentPlatform = PaymentPlatform;
  AccreditationStatus = AccreditationStatus;

  FeatureFlagConsts = SystemFeatureFlagConsts;

  investorsOrderByOptions$ = this.allowInvestorName$.pipe(
    map(allowInvestorName => InvestorsOrderBy.listAll(allowInvestorName))
  );

  nameFilter = new UntypedFormControl();

  // to scroll to when having investment parameter
  scrollToInvestmentId = 0;
  afterInvestmentPanelExtended$ = new Subject<boolean>();
  investmentPanelExtended:number = null;

  fundraising$ = this.gpHoldingService.fundraising$;

  investments$ = this.gpHoldingService.fundraising$.pipe(
    map(fundraising => fundraising.investments),
  );

  hasCommitments$ = this.gpHoldingService.holding$.pipe(map(holding => holding.commitmentsEnabled == CommitmentsEnabled.Yes))
  
  refreshTotalCommitments$ = new Subject<void>();

  investorTotalCommitments$ = this.refreshTotalCommitments$.pipe(
    startWith(''),
    switchMap(x => this.gpHoldingService.holdingId$),
    switchMap(holdingId => this.investorsManagementService.getAllInvestorsManagement(holdingId)),
    shareReplay(1)
  );  

  isCompletedExternalContribution$ = this.gpHoldingService.isCompletedExternalContribution$;

  isLoading$ = new BehaviorSubject<boolean>(false);

  // When an investment is expended, it updated the active investment id
  activeInvestmentId$ = new BehaviorSubject<number>(null);

  investmentsSortOrder$ = this.appQuery.gpUiPrefs.pipe(
    map(uiState => uiState.fundraising.assetDetailsInvestorsSortBy));
  isFundFeatureActive$ = this.userService.userHasFeatureFlag(FeatureFlags.Fund);

  /* The order in which the investments should be sorted by.
  This is updated in these cases:
  ===============================
  1. The first time we get the investments, after they are piped through the selected sorting order.
  2. Each time the user selects one of the sorting options.
  3. When a new investment is added, we use the selected sorting order, and updated the array
  */
  private orderOfInvestmentsIds$ = new BehaviorSubject<number[]>([]);

  private nameFilterValue$ = this.nameFilter.valueChanges.pipe(startWith(''), debounceTime(200));

  orderedInvestments$ = combineLatest([
    this.investments$,
    this.orderOfInvestmentsIds$,
    this.nameFilterValue$,
    this.allowInvestorName$,
    this.investorTotalCommitments$
  ])
    .pipe(
      map(([investments, orderOfInvestmentsIds, nameFilter, allowInvestorName, investorTotalCommitments]) => {
        // Filter by name:
        const stringForSearch = allowInvestorName ? (i: InvestmentReqRes): string => `${i.investingEntity.id} ${i.investingEntity.contact.id} ${i.investingEntity.name} ${TerraUtils.getContactFullName(i.investingEntity.contact)} ` :
          (i: InvestmentReqRes): string => `${i.investingEntity.id} ${i.investingEntity.contact.id}`;

        let investmentsCopy = [...investments];
        if (nameFilter) {
          investmentsCopy = investmentsCopy.filter(i => TerraUtils.searchKeywordsInAnyOrder(nameFilter, stringForSearch(i)));
        }

        // Sort according to the order of ids in the array (the ids are ordered according to the selected option elsewhere)
        // but because we want to lock the order until the user asks to re-order, we don't use locked order here.

        const sortedInvestmentsArray: InvestmentReqRes[] = [];

        orderOfInvestmentsIds.forEach(id => {
          // find the index of the matching investment
          const invIndex = investmentsCopy.findIndex(i => i.id === id);
          // push it to the array, and remove it from the list:
          if (invIndex !== -1) {
            const investmentToPush = investmentsCopy.splice(invIndex, 1)[0];
            const investorTotalCommitmentsData = investorTotalCommitments.investorManagementModels.find(x => x.investingEntityId == investmentToPush.investingEntityId);
            investmentToPush.totalCommitment = investmentToPush.totalCommitment ?? investorTotalCommitmentsData?.totalCommitmentAmount;
            sortedInvestmentsArray.push(investmentToPush);
          }
        });

        this.isDeletingInvestment = false;
        return sortedInvestmentsArray;
      }));

  isAiRankingFeatureActive$ = this.userService.userHasFeatureFlag(FeatureFlags.AiRanking);
  isDeletingInvestment = false;
  isAllContactsVisible$ = this.permissionService.isAllContactsVisible$;

  constructor(
    private appService: AppService,
    private appQuery: AppQuery,
    public gpHoldingService: GpHoldingService,
    public gpInvestmentDataService: GpInvestmentDataService,
    private route: ActivatedRoute,
    public aiRankingService: AIRankingService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private dialogService: DialogService,
    private logger: LoggerService,
    private fundraisingService: HoldingFundraisingService,
    private investorsManagementService: InvestorsManagementService,
    private dialog: MatDialog,
    private vcr: ViewContainerRef,
    private utilsService: UtilsService,
    private permissionService: PermissionService,
    public appSettingService: AppSettingsService
  ) {
  }

  ngOnInit() {
    // Initialize the investments list with the correct selected order (done only once):
    combineLatest([this.investments$.pipe(take(1)), this.investmentsSortOrder$.pipe(take(1))]).subscribe(
      ([_investments, orderBy]) => {
        const investments = this.gpInvestmentDataService.sortInvestments(_investments, orderBy);
        const orderedIds = investments.map(i => i.id);
        this.orderOfInvestmentsIds$.next(orderedIds);
      }
    );

    // for when adding / removing investments - we want to add them to the array of investment IDs, so that they will appear:

    this.investments$.pipe(
      switchMap(investments => {
        if (investments.length === this.orderOfInvestmentsIds$.value.length) {
          return NEVER;
        } else {
          return combineLatest([of(investments), this.investmentsSortOrder$.pipe(take(1))]);
        }
      }))
      .subscribe(([investments, orderBy]) => {
        this.updateInvestmentsOrder(investments, orderBy);
        // If added new investments, clear the name filter because, so that the new investments will not be visible
        this.nameFilter.reset();
      });
  }

  ngAfterViewInit(): void {
    // TODO: a better solution is to somehow get a reference to the expansion panel, then to expend it, and finally, to scroll to it.
    // it seems that when using the [expanded] property,
    // the expansion happens before angular subscribes to the afterExpand output, and it doesn't call the method we connect to it.
    timer(400).subscribe(_ => {
      this.setInvestmentToScrollTo();
    });
  }

  setInvestmentToScrollTo() {
    this.route.queryParams
    .pipe(
      withLatestFrom(this.fundraising$)
    ).subscribe(([params, fundraising]) => {
      // the fallback is for old emails with typo
      this.scrollToInvestmentId = +params.investment || +params.investement || 0;
      this.investmentPanelExtended = fundraising.investments.length == 1 ? fundraising.investments[0].id : this.scrollToInvestmentId;
    });
  }

  updateInvestmentsOrder(investments: InvestmentReqRes[], orderBy: InvestorsOrderBy) {
    const updatedOrder = this.gpInvestmentDataService.sortInvestments(investments, orderBy);
    if (updatedOrder) {
      this.orderOfInvestmentsIds$.next(updatedOrder.map(i => i.id));
    }
  }

  setInvestorsOrder(selectedSorting: InvestorsOrderBy) {
    // Save the selected order option to the user's preferences
    this.appService.updateInvestorsSortOrder(selectedSorting);

    this.investments$.pipe(take(1)).subscribe(
      investments => {
        this.updateInvestmentsOrder(investments, selectedSorting);
      }
    );
  }

  investmentTrackByFn(index: number, item: InvestmentReqRes) {
    return item.id;
  }

  scrollToInvestmentPanel(investmentId: number) {
    const elementToScrollTo = document.querySelector('#investment_' + investmentId);
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({behavior: 'smooth'});
    }
  }

  openPanel(investmentId: number){
    this.investmentPanelExtended = investmentId;
  }

  closePanel(investmentId: number){
    if(this.investmentPanelExtended === investmentId){
      this.investmentPanelExtended = null;
    }
  }

  rankInvestors() {
    this.isLoading$.next(true);
    this.gpHoldingService.holdingId$.pipe(take(1)).subscribe(holdingId => {
      this.investments$.pipe(take(1)).subscribe(investments => {
        this.aiRankingService.reRankActiveInvestments(investments, holdingId).pipe(
          take(1),
          map(result => {
            this.isLoading$.next(false);
            if (result) {
              this.gpHoldingService.updateHoldingFromServer();
              if (investments.length > 1) {
                this.snackbarService.showGeneralMessage(`${investments.length} investments updated successfully`);
              } else {
                this.snackbarService.showGeneralMessage(`Investment updated successfully`);
              }
            }
          }),
          catchError(err => {
            this.isLoading$.next(false);
            this.snackbarService.showGeneralMessage(`An error occurred while ranking investors, please try again later`);
            this.logger.warning('unable to rerank investors ', err);
            return EMPTY;
          })
        ).subscribe();
      });
    });
  }

  deleteInvestment(investmentId: number, event: MouseEvent) {
    event.stopPropagation();
    const confirmDialogParams = new ConfirmDialogParams();
    confirmDialogParams.actionLabel = 'Delete';
    confirmDialogParams.title = 'Are you sure you want to delete this investment?';
    confirmDialogParams.description = `This investment will be deleted immediately. You cannot undo this action.`;
    this.dialogService
      .confirmDialog(confirmDialogParams)
      .afterClosed()
      .pipe(
        filter(isConfirmed => isConfirmed),
        tap(_ => this.isDeletingInvestment = true),
        switchMap(_ => {
          return this.gpInvestmentDataService.markAsDeleted(investmentId);
        })
      )
      .subscribe(response => {
          if (response) {
            this.snackbarService.showGeneralMessage(`Investment deleted`);
          } else {
            this.snackbarService.showGeneralMessage(`Couldn't delete this investment`);
            this.isDeletingInvestment = false;
          }
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            this.snackbarService.showGeneralMessage(`Couldn't delete this investment`);
          }
          this.isDeletingInvestment = false;
        }
      )
    ;
  }

  getRemarks(investment: InvestmentReqRes): string {
    let result = '';
    if (investment.externalRemarks?.length > 0) {
      result += `External Remarks:\n\n${investment.externalRemarks}\n\n`;
    }
    if (investment.internalRemarks?.length > 0) {
      result += `Internal Remarks:\n\n${investment.internalRemarks}`;
    }

    return result;
  }

  /**
   * Opens a dialog for selecting an existing contact to add as investor
   */
  public addFromContactList() {
    const config = new MatDialogConfig<number>();
    config.panelClass = 'find-contact-in-list-dialog';
    config.disableClose = true;

    this.fundraisingService.fundraisingId$.pipe(
      take(1),
      switchMap(fundraisingId => {
        config.data = fundraisingId;
        return this.dialog.open(FindContactInListDialogComponent, config).afterClosed();
      }),
      filter((investingEntityIds: number[]) => coerceBooleanProperty(investingEntityIds) && investingEntityIds.length > 0),
      withLatestFrom(this.gpHoldingService.holdingId$, this.fundraisingService.fundraisingId$),
      switchMap(([investingEntityIds, holdingId, fundraisingId]) =>
        this.gpInvestmentDataService.createMultipleInvestmentsFromInvestingEntityIds(holdingId, fundraisingId, investingEntityIds))
    ).subscribe(addedInvestments => {
        this.onSuccessfulInvestmentsCreation(addedInvestments);
      },
      err => {
        if (err instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(err);
        }
        console.log('Investments were not added', err);
      }
    );
  }

  /**
   * Opens a dialog for creating a new contact
   */
  public createNewContact() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;

    this.dialog.open<InvestorContactDialogComponent, any, InvestorContactReqRes>(InvestorContactDialogComponent, dialogConfig)
      .afterClosed().subscribe(contact => {
      if (contact && contact.contactInvestingEntities) {
        // If only 1 investing entity, select it
        if (contact.contactInvestingEntities.length === 1) {
          this.addSingleInvestor(contact.contactInvestingEntities[0].id);
        } else {
          // if more than 1 investing entity, ask the user to select which one he would like to use
          const config = new MatDialogConfig<InvestingEntityReqRes[]>();
          config.data = contact.contactInvestingEntities;
          config.closeOnNavigation = true;
          config.disableClose = true;
          this.dialog.open(SelectContactInvestingEntityComponent, config).afterClosed().subscribe(selectedInvestingEntity => {
            if (selectedInvestingEntity) {
              this.addSingleInvestor(selectedInvestingEntity.id);
            }
          });
        }
      }
    });
  }

  /**
   * Adds a single investment to the fundraising, using the provided investing entity
   * @param investingEntityId Investing entity id
   */
  private addSingleInvestor(investingEntityId: number) {
    combineLatest([this.gpHoldingService.holdingId$, this.fundraisingService.fundraisingId$])
      .pipe(
        take(1),
        switchMap(([holdingId, fundraisingId]) => {
          return this.gpInvestmentDataService.createInvestmentFromInvestingEntity(holdingId, fundraisingId, investingEntityId);
        }),
        tap(investment => {
          this.fundraisingService.updatedInvestment$.next(investment);
        })
      )
      .subscribe(
        addedInvestment => {
          this.investorsAddedMessage(1);
        },
        err => {
          console.log('Investment was not added', err);
        }
      );
  }

  /**
   * A method to call when investments created
   * @param addedInvestments New investments created
   */
  private onSuccessfulInvestmentsCreation(addedInvestments: InvestmentReqRes[]) {
    if (addedInvestments) {
      this.investorsAddedMessage(addedInvestments.length);
      addedInvestments.forEach(investment => {
        this.fundraisingService.updatedInvestment$.next(investment);
      });
    }
  }

  /**
   * Shows investor added message
   * @param numberOfInvestors Number of investors added
   */
  private investorsAddedMessage(numberOfInvestors: number) {
    const addedMessage = numberOfInvestors === 1 ? 'Investor added' : `${numberOfInvestors} investors added`;
    this.snackbarService.showGeneralMessage(addedMessage, 3, 'flow-added-investors-success');
  }

  /**
   * Opens add participating fund dialog
   */
  public openParticipatingFundDialog() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.viewContainerRef = this.vcr;
    this.dialog.open(ParticipatingFundDialogComponent, config);
  }

  public getSignedCCData(investment: InvestmentReqRes) {
    if (investment.status === InvestmentStatus.Signed && !!investment.paymentRequestSendDate) {
      if (investment.paymentStatus === PaymentStatus.Canceled) {
        return '(Canceled)';
      } else if (!investment.transferDate) {
        return '(Called)';
      } else if (!!investment.transferDate) {
        return '(Transferred)';
      }
    }

    return '';
  }

  /**
   * Get the name of investor formatted for display
   * @param investment Investment details
   */
  public getFormattedInvestorName(investment: InvestmentReqRes): Observable<string> {
    return this.fundraisingService.getFormattedInvestorName(investment);
  }

  isAwaitingGpSignature(investment: InvestmentReqRes, checkWhenInvestedStatus: boolean = true) {
    return (investment.status === InvestmentStatus.Signed || (checkWhenInvestedStatus && investment.status === InvestmentStatus.Invested)) &&
      investment.isAgreementSigned &&
      !investment.agreementMetaFileLinkId &&
      investment.agreementSignPlatform === SignPlatform.HelloSign;
  }
}
