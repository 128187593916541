import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Observable, combineLatest, BehaviorSubject, throwError, of} from 'rxjs';
import {map, switchMap, tap, shareReplay, take, catchError} from 'rxjs/operators';

import {FundFundraisingService} from '../fund-fundraising.service';
import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import {GpInvestmentDataService} from 'src/app/services/gp/gp-investment-data.service';
import {InvestmentStatus} from 'src/app/shared/enums/InvestmentStatus.enum';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {GpFundService} from '../gp-fund.service';
import {LoggerService} from 'src/app/shared/errors/logger.service';
import { HoldingInvestorService } from 'src/app/dashboard/shared/holding/holding-investor.service';

class EndpointPrefixIds {
  constructor(public fundId: number, public fundraisingId: number, public investmentId: number) {
  }
}

@Injectable()
export class FundInvestorService extends HoldingInvestorService {
  // This is set when an investorDetails Component is instantiated, in its OnInit.
  investmentId$ = new BehaviorSubject<number>(0);

  isUpdatingStatus$ = new BehaviorSubject(false);

  private _pageForm: UntypedFormGroup;
  set pageForm(value: UntypedFormGroup) {
    this._pageForm = value;
  }

  get pageForm() {
    return this._pageForm;
  }

  investmentDetails$: Observable<InvestmentReqRes> = this.investmentId$.pipe(
    switchMap(investmentId =>
      this.fundFundraisingService.fundraising$.pipe(map(fundraisingDetails => fundraisingDetails.investments.find(i => i.id === investmentId)))
    )
  );

  private endpointPrefixIds$ = combineLatest([this.gpFundService.holdingId$, this.fundFundraisingService.fundraisingId$, this.investmentId$]).pipe(
    shareReplay(1),
    map(([fundId, fundraisingId, investmentId]) => new EndpointPrefixIds(fundId, fundraisingId, investmentId))
  );

  isContributionInReadOnlyMode$ = this.fundFundraisingService.isContributionInReadOnlyMode$;

  constructor(
    private gpFundService: GpFundService,
    private fundFundraisingService: FundFundraisingService,
    private gpInvestmentDataService: GpInvestmentDataService,
    private snackbarService: SnackbarService,
    private logger: LoggerService
  ) {
    super()
  }

  deleteInvestment() {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => this.gpInvestmentDataService.delete(endpointPrefixIds.fundId, endpointPrefixIds.fundraisingId, endpointPrefixIds.investmentId)),
      switchMap(() => this.investmentId$),
      tap(investmentId => this.fundFundraisingService.deletedInvestmentId$.next(investmentId))
    );
  }

  updateFinalAmount(finalAmount: number) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this.gpInvestmentDataService.updateFinalAmount(
          endpointPrefixIds.fundId, endpointPrefixIds.fundraisingId, endpointPrefixIds.investmentId, finalAmount);
      }),
      tap(investment => {
        this.fundFundraisingService.updatedInvestment$.next(investment);
      })
    );
  }

  updatePaymentSettlementDate(updatePaymentSettlement: Date) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this.gpInvestmentDataService.updatePaymentSettlementDate(
          endpointPrefixIds.fundId, endpointPrefixIds.fundraisingId, endpointPrefixIds.investmentId, updatePaymentSettlement);
      }),
      tap(investment => {
        this.fundFundraisingService.updatedInvestment$.next(investment);
      })
    );
  }

  updateStatus(status: InvestmentStatus) {
    this.isUpdatingStatus$.next(true);

    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this.gpInvestmentDataService.updateStatus(endpointPrefixIds.fundId, endpointPrefixIds.fundraisingId, endpointPrefixIds.investmentId, status);
      }),
      tap(investment => {
        this.fundFundraisingService.updatedInvestment$.next(investment);
        this.snackbarService.showGeneralMessage('Status changed');
        // If moved to Invested status and the investment has a signed agreement, copy it to the holdi ng documents (and share with the LP)
        if (investment.status == InvestmentStatus.Invested && investment.isAgreementSigned && investment.agreementMetaFileLinkId) {
          this.copyAgreementToHoldingDocuments();
        }
        this.isUpdatingStatus$.next(false);
      }),
      catchError(err => {
          this.isUpdatingStatus$.next(false);
          return throwError(err);
        }
      )
    );
  }

  private copyAgreementToHoldingDocuments(): void {
    this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => this.gpInvestmentDataService
        .copyAgreementToHoldingDocuments(endpointPrefixIds.fundId, endpointPrefixIds.fundraisingId, endpointPrefixIds.investmentId)
      ))
      .subscribe(
        () => {
        },
        error => {
          this.logger.error('fund-investor.service.ts => copyAgreementToHoldingDocuments()',error);
        }
      );
  }

  // Used for both estimated and committed amounts
  updateEstimatedAmountAndRemarks(estimatedAmount: number, externalRemarks: string, internalRemarks: string, totalCommitments: number) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this.gpInvestmentDataService.updateEstimatedAmountAndRemarks(
          endpointPrefixIds.fundId,
          endpointPrefixIds.fundraisingId,
          endpointPrefixIds.investmentId,
          estimatedAmount,
          externalRemarks,
          internalRemarks
        );
      }),
      tap(investment => {
        investment.totalCommitment = totalCommitments;
        this.fundFundraisingService.updatedInvestment$.next(investment);
      })
    );
  }

  sendMarketingDeck(message: string, subject: string) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this.gpInvestmentDataService.sendMarketingDeck(endpointPrefixIds.fundId, endpointPrefixIds.fundraisingId, endpointPrefixIds.investmentId, message, subject);
      }),
      tap(isSent => {
        if (isSent) {
          this.investmentDetails$.pipe(take(1)).subscribe(investment => {
            const updatedInvestment = {
              ...investment,
              isMarketingDeckWasSent: true,
              marketingDeckSendDate: new Date(),
              marketingDeckSentEmail: investment.investingEntity.contact.email
            } as InvestmentReqRes;
            this.fundFundraisingService.updatedInvestment$.next(updatedInvestment);
          });
        }
      })
    );
  }

  updateDocument(metaFileLinkId: number) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this.gpInvestmentDataService.updateDocument(
          endpointPrefixIds.fundId,
          endpointPrefixIds.fundraisingId,
          endpointPrefixIds.investmentId,
          metaFileLinkId
        );
      }),
      switchMap(investment=>{
        this.copyAgreementToHoldingDocuments();
        return of(investment);
      })
    );
  }

  uploadPaymentRequestDocuments(metaFileLinkIds: number[]): Observable<InvestmentReqRes>{
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this.gpInvestmentDataService.uploadPaymentRequestDocuments(
          endpointPrefixIds.fundId,
          endpointPrefixIds.fundraisingId,
          endpointPrefixIds.investmentId,
          metaFileLinkIds
        );
      })
    );
  }

  deletePaymentRequestDocument(metaFileLinkId:number): Observable<InvestmentReqRes>{
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this.gpInvestmentDataService.deletePaymentRequestDocument(
          endpointPrefixIds.fundId,
          endpointPrefixIds.fundraisingId,
          endpointPrefixIds.investmentId,
          metaFileLinkId
        );
      })
    );
  }

  generateTransferConfirmation() {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this.gpInvestmentDataService.generateTransferConfirmation(endpointPrefixIds.fundId, endpointPrefixIds.fundraisingId, endpointPrefixIds.investmentId);
      })
    );
  }

  agreementSent(investment: InvestmentReqRes) {
    this.fundFundraisingService.updatedInvestment$.next(investment);
  }
}
