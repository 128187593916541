<ng-container *ngIf="
  (allowInvestorName$ | async) &&
  !investment.agreementMetaFileLinkId &&
  (investment.status === InvestmentStatus.Signed || investment.status === InvestmentStatus.Invested) &&
  investment.agreementSignPlatform === SignPlatform.External &&
  !investment.isOrderCreated &&
  !readOnlyMode">
  <h3 class="title">The agreement wasn’t signed via Covercy. </h3>
  <p class="description">To keep everything in one place, upload the signed agreement.</p>
  <form [formGroup]="pageForm" (keydown.enter)="$event.preventDefault()">
    <div class="form-grid">
      <terra-file-uploader no-type-select formControlName="signatureDocument">
      </terra-file-uploader>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="allowInvestorName$ | async">
  <div class="agreement-file-section" *ngIf="investment.isAgreementSigned && investment.agreementMetaFileLinkId">
    <h3 class="title">Signed agreement</h3>
        <p *ngIf="investment.agreementSignPlatform === SignPlatform.External">
            This agreement was signed outside Covercy
        </p>
        <div class="file-url-section">
            <a target="_blank" class="light link" href="{{ investment?.agreementMetaFileLink?.url }}">
                <mat-icon class="notranslate">insert_drive_file</mat-icon>
                {{ investment?.agreementMetaFileLink?.title | truncate: 120 }}
            </a>
            <ng-container *ngIf="!(deleteInProcess$ | async) else deleteLoader">
              <mat-icon class="notranslate" (click)="deleteAgreement()">clear</mat-icon>
            </ng-container>
            <ng-template #deleteLoader>
              <terra-loader></terra-loader>
            </ng-template>
            
        </div>
  </div>
  <div class="agreement-file-section mark-important" *ngIf="
    (investment.status === InvestmentStatus.Signed || investment.status === InvestmentStatus.Invested) &&
    investment.isAgreementSigned &&
    !investment.agreementMetaFileLinkId &&
    investment.agreementSignPlatform === SignPlatform.HelloSign">
    <h3 class="title">Signed agreement</h3>
    <div class="partially-signed">
      <div class="note">
        <mat-icon class="notranslate">av_timer</mat-icon>
        Agreement signed by investor, a link to download the document will be available shortly after your signature.
      </div>

      <a href="{{getSignatureUrl}}" mat-flat-button target="_blank" class="cancel-button" color="primary">
        <!--        <mat-icon class="notranslate">arrow_forward</mat-icon>-->
        Sign Now
      </a>
    </div>
  </div>
</ng-container>

<div class="agreement-file-section mark-important" *ngIf="!(allowInvestorName$ | async)">
  <h3 class="title">Agreement</h3>
  <div class="not-allow-message">
    <mat-icon class="notranslate">error</mat-icon>
    <h4>You do not have permissions to view investors' documents as these documents might include investors' names.</h4>
  </div>
</div>
