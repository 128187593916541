<section class="page-heading max-width">
  <div class="breadcrumbs" *ngIf="fund$ | async as fund">
    <a [routerLink]="getFundPageUrl(fund.id)"><span class="lo_sensitive">{{ fund.name }}</span> > Reports >
    </a>
    <span class="current">{{ reportName$ | async }} FAQs </span>
  </div>
  <h1 class="header-text">FAQ Editor</h1>
  <h2 class="sub-header-text">
    Write answers to your investor’s most pressing questions about this report
  </h2>
</section>

<ng-template #loading>
  <terra-loader></terra-loader>
</ng-template>

<ng-container *ngIf="{ fromServer: serverFaqWithoutEdited$ | async, fromLocal: newAndEditedFaqs$ | async } as faqs; else loading">
  <ng-container *ngIf="!isSaving && faqs.fromServer && faqs.fromLocal; else loading">
    <!-- No FAQs yet -->
    <div class="empty-state max-width" *ngIf="!faqs.fromServer.length && !faqs.fromLocal.length">
      <div class="question-icon">?</div>
      <h3 class="empty-state-title">Any answers to FAQs you write will appear here and be visible to investors in their portal</h3>
      <button class="empty-create-button" mat-flat-button color="primary" (click)="showCreateFaqDialog()">Write a new FAQ</button>
    </div>
    <!-- When there are existing FAQs / drafts -->
    <div class="faqs-content max-width" *ngIf="faqs.fromServer.length > 0 || faqs.fromLocal.length">
      <button (click)="showCreateFaqDialog()" class="create-button" type="button" mat-stroked-button color="primary">
        Write a new FAQ
      </button>

      <!-- FAQs list - Drafts  -->
      <ng-container *ngIf="faqs.fromLocal.length">
        <h4 class="list-title">Drafts, not published yet</h4>
        <mat-card *ngFor="let faq of faqs.fromLocal" color="primary" class="faq-card" (click)="showCreateFaqDialog(faq)">
          <mat-card-content>
            <span class="lo_sensitive question">{{ faq.question }}</span>

            <!-- Edit button -->
            <!-- <button mat-icon-button class="edit-button" (click)="showCreateFaqDialog(faq); $event.stopPropagation(); (false)" disableRipple matTooltip="Edit">
              <mat-icon color="default" class="edit-icon">edit</mat-icon>
            </button> -->

            <!-- Undo changes / delete if it's a new question  -->
            <button mat-icon-button class="delete-button" (click)="removeFromPending(faq); $event.stopPropagation(); (false)" disableRipple
              [matTooltip]="faq.id ? 'Undo changes' : 'Delete'">
              <mat-icon color="default" class="delete-icon">{{ faq.id ? 'undo' : 'delete' }}</mat-icon>
            </button>
            <!-- Delete for existing (saved) questions only -->
            <button *ngIf="faq.id" mat-icon-button class="delete-button" (click)="deleteFaq(faq.id); $event.stopPropagation(); (false)"
              disableRipple matTooltip="Delete">
              <mat-icon color="default" class="delete-icon">delete</mat-icon>
            </button>
          </mat-card-content>
        </mat-card>

        <!-- Draft list actions -->
        <ng-container *ngIf="!(isNewState$ | async)">
          <button class="publish-button tall-button" type="button" (click)="saveAndPublish()" *ngIf="faqs.fromLocal.length" mat-flat-button
            color="accent" type="button">
            Publish {{ faqs.fromLocal.length }} FAQ(s) <mat-icon svgIcon="publish" class="notranslate publish-icon"></mat-icon>
          </button>
          <button mat-button class="cancel-button tall-button" [routerLink]="getFundPageUrl((fund$ | async).id)">
            Cancel
          </button>
        </ng-container>
      </ng-container>

      <!-- FAQs list - from server-->
      <ng-container *ngIf="faqs.fromServer.length">
        <h4 class="list-title">Published</h4>
        <mat-card *ngFor="let faq of faqs.fromServer" color="primary" class="faq-card" (click)="showCreateFaqDialog(faq)">
          <mat-card-content>
            <span class="lo_sensitive question">{{ faq.question }}</span>
            <!-- <button mat-icon-button class="edit-button" (click)="showCreateFaqDialog(faq); $event.stopPropagation(); (false)" disableRipple matTooltip="Edit">
              <mat-icon color="default" class="edit-icon">edit</mat-icon>
            </button> -->
            <button mat-icon-button class="delete-button" (click)="deleteFaq(faq.id); $event.stopPropagation(); (false)" disableRipple
              matTooltip="Delete">
              <mat-icon color="default" class="delete-icon"> delete </mat-icon>
            </button>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
    <div class="page-actions">
      <!-- Close button -->
      <div class="max-width">
        <button class="close-button tall-button" *ngIf="faqs.fromServer.length > 0" mat-flat-button
          [routerLink]="getFundPageUrl((fund$ | async).id)" color="primary">
          Close
        </button>
        <ng-container *ngIf="isNewState$ | async">
          <!-- Publish (only when no existing FAQs) -->
          <button class="publish-button tall-button" type="button" (click)="saveAndPublish()" *ngIf="faqs.fromLocal.length > 0"
            mat-flat-button color="accent" type="button">
            Publish <mat-icon svgIcon="publish" iconPositionEnd class="notranslate publish-icon"></mat-icon>
          </button>
          <button mat-button class="cancel-button tall-button" [routerLink]="getFundPageUrl((fund$ | async).id)">
            Cancel
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
