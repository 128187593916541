import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { map, shareReplay, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { GpAssetService } from '../../../gp-asset.service';
import { UtilsService } from 'src/app/services/utils.service';
import { RoutingService } from 'src/app/services/routing.service';
import { CreateEditFaqDialogComponent } from 'src/app/dashboard/shared/holding/reports/create-edit-faq/create-edit-faq-dialog.component';
import { ReportFAQReqRes } from 'src/app/shared/models/reportFAQReqRes.model';
import { AssetReportFaqService } from './asset-report-faq.service';
import { CreateEditFaqDialogContext } from 'src/app/dashboard/shared/holding/reports/create-edit-faq/CreateEditFaqDialogContext';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import {BaseResponseDto} from '../../../../../../shared/models/BaseResponseDto.model';

@Component({
  selector: 'terra-asset-report-faqs',
  templateUrl: './asset-report-faqs.component.html',
  styleUrls: ['./asset-report-faqs.component.scss'],
  providers: [AssetReportFaqService]
})
export class AssetReportFaqsComponent implements OnInit {

  isSaving = false;

  asset$ = this.gpAssetService.holding$;

  report$ = this.assetReportFaqService.report$;

  reportName$ = this.report$.pipe(
    map(report => this.utilsService.getReportPeriodText(report.periodStartDate, report.periodEndDate)),
    shareReplay());

  serverFaqs$ = this.assetReportFaqService.faqs$;

  isNewState$ = this.serverFaqs$.pipe(map(faqs => !faqs || faqs.length === 0));

  newAndEditedFaqs$ = new BehaviorSubject<ReportFAQReqRes[]>([]);

  serverFaqWithoutEdited$ = combineLatest([this.serverFaqs$, this.newAndEditedFaqs$]).pipe(
    map(([serverFaqs, newAndEditedFaqs]) => {
      const listToReturn = [...serverFaqs];
      // remove the edited faqs from the server faqs (they shouldl appear in the top list)
      newAndEditedFaqs.filter(faq => faq.isEdited).forEach(faq => {
        const indexToRemove = listToReturn.findIndex(x => x.id === faq.id);
        if (indexToRemove > -1) {
          listToReturn.splice(indexToRemove, 1);
        }
      });
      return listToReturn;
    }),
    shareReplay()
  );

  constructor(
    private routingService: RoutingService,
    private assetReportFaqService: AssetReportFaqService,
    private gpAssetService: GpAssetService,
    private utilsService: UtilsService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private titleService: Title,
    private snackbarService: SnackbarService) { }

  ngOnInit() {
    this.titleService.setTitle('FAQ Editor' + TerraUtils.consts.GpPageTitleSuffix);
  }

  //// Efrat & Matan decided that there is no need to confirm when exiting the page without saving.
  // canDeactivate(): Observable<boolean> | boolean {
  //   if (this.newAndEditedFaqs$.value && this.newAndEditedFaqs$.value.length > 0) {
  //     const params = new ConfirmDialogParams('Cancel FAQ editing page?', 'Any information you added or updated will be lost', 'Cancel', 'Go back');
  //     return this.dialogService.confirmDialog(params).afterClosed();
  //   }
  //   return true;
  // }

  // only removes the Faq from the list of pending changes
  removeFromPending(faq: ReportFAQReqRes) {
    const indexToRemove = this.newAndEditedFaqs$.value.findIndex(x => x === faq);
    if (indexToRemove > -1) {
      const updatedPendingList = this.newAndEditedFaqs$.value;
      updatedPendingList.splice(indexToRemove, 1);
      this.newAndEditedFaqs$.next(updatedPendingList);
    }
  }

  // Deltes the FAQ completely
  deleteFaq(faqId: number) {
    this.dialogService.confirmDialog(new ConfirmDialogParams('Are you sure you want to delete this FAQ?', 'Once deleted, it will no longer be visible to your investors.', 'Delete FAQ'))
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.assetReportFaqService.delete(faqId).subscribe(() => {

            const indexToRemove = this.newAndEditedFaqs$.value.findIndex(x => x.id === faqId);
            if (indexToRemove > -1) {
              const updatedPendingList = this.newAndEditedFaqs$.value;
              updatedPendingList.splice(indexToRemove, 1);
              this.newAndEditedFaqs$.next(updatedPendingList);
            }

            this.snackbarService.showGeneralMessage('FAQ deleted');
          },
            error => {
              if (error instanceof BaseResponseDto) {
                this.utilsService.alertErrorMessage(error);
              }
            });
        }
      });
  }

  showCreateFaqDialog(faq: ReportFAQReqRes = null) {

    const dialogConfig = new MatDialogConfig<CreateEditFaqDialogContext>();
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'create-edit-faq-dialog';

    const dialogContext = new CreateEditFaqDialogContext();
    dialogContext.isEditMode = !!faq;
    dialogContext.reportFaq = faq || new ReportFAQReqRes();

    dialogConfig.data = dialogContext;

    this.dialog.open(CreateEditFaqDialogComponent, dialogConfig).afterClosed().subscribe((editedOrNewFaq: ReportFAQReqRes) => {
      if (editedOrNewFaq) {
        // if editing a Pending FAQ
        if (editedOrNewFaq.isEdited && !editedOrNewFaq.id) {
          // replace the faq object in the pending list:
          const originalIndex = this.newAndEditedFaqs$.value.findIndex(x => x === editedOrNewFaq);
          if (originalIndex > -1) {
            this.newAndEditedFaqs$.value.splice(originalIndex, 1, editedOrNewFaq);
            this.newAndEditedFaqs$.next(this.newAndEditedFaqs$.value);
          }
        } else if (editedOrNewFaq.isEdited && editedOrNewFaq.id) {
          // Edited server FAQ:
          // if it's was already edited and exists in the pending list, we should replace it:
          const indexOfExistingItemInPending = this.newAndEditedFaqs$.value.findIndex(x => x.id === editedOrNewFaq.id);
          if (indexOfExistingItemInPending > -1) {
            this.newAndEditedFaqs$.value.splice(indexOfExistingItemInPending, 1, editedOrNewFaq);
            this.newAndEditedFaqs$.next(this.newAndEditedFaqs$.value);
          } else {
            // otherwise, we append it:
            this.newAndEditedFaqs$.next([...this.newAndEditedFaqs$.value, editedOrNewFaq]);
          }
        } else if (!editedOrNewFaq.isEdited) {
          // New FAQ: add it to the list
          this.newAndEditedFaqs$.next([...this.newAndEditedFaqs$.value, editedOrNewFaq]);
        }
      }
    });
  }

  saveAndPublish() {
    this.isSaving = true;
    this.assetReportFaqService.createAndUpdateBulk(this.newAndEditedFaqs$.value).pipe(
      switchMap(() => this.gpAssetService.holdingId$),
      tap(assetId => {
        this.newAndEditedFaqs$.next([]);

        this.snackbarService.showGeneralMessage('FAQs published', 5);
      }))
      .subscribe(() => {
        this.isSaving = false;
      }, error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          console.log(`Couldn't publish FAQs`, error);
          this.snackbarService.showGeneralMessage(TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR, 5);
        }
        this.isSaving = false;
      });

  }

  getAssetPageUrl(assetId: number) {
    return this.routingService.gpAssetWithTab(assetId, 'reports');
  }
}
