import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, of, timer} from 'rxjs';
import {distinctUntilChanged, map, switchMap, take} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import InvestmentType from 'src/app/shared/enums/InvestmentType.enum';
import {GpAssetDataService} from 'src/app/services/gp/gp-asset-data.service';
import {GpHoldingDataService} from 'src/app/services/gp/gp-holding-data.service';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {RoutingService} from 'src/app/services/routing.service';
import {UserService} from 'src/app/services/shared/user.service';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import {CustomValidators} from 'src/app/shared/validators/custom.validators';
import {GpAssetReqRes} from '../../GpAssetReqRes.model';
import {LocationDetailsResponse} from 'src/app/shared/models/LocationDetailsResponse.model';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import {HoldingFileReqRes} from 'src/app/dashboard/shared/holding/HoldingFileReqRes.model';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';
import {GpFundService} from 'src/app/dashboard/funds/gp-fund/gp-fund.service';
import {LoggerService} from 'src/app/shared/errors/logger.service';
import {LenderInformation} from 'src/app/shared/models/LenderInformation.model';
import {ProjectedPerformance} from 'src/app/shared/models/ProjectedPerformance.model';
import {ProjectBudget} from 'src/app/shared/models/ProjectBudget.model';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';

export type CreateFundAssetStepNumber = 1 | 2 | 3;

@Injectable()
export class CreateFundAssetService extends OnDestroyMixin {

  readonly IS_STEP_SUBMITTED_FIELD_NAME = 'IS_STEP_SUBMITTED';
  readonly wizardStepPaths = ['asset-information', 'investment-details', 'marketing-material'];

  fundId$ = this.gpFundService.holdingId$;
  fund$ = this.gpFundService.holding$;

  currentStep$ = new BehaviorSubject((this.getCurrentStepIndexFromUrl() + 1 || 1) as CreateFundAssetStepNumber);
  createAssetForm: UntypedFormGroup;

  processingSubmit$ = new BehaviorSubject(false);
  isGeneralServerError = false;

  assetCreated = false;

  get selectedInvestmentType() {
    return this.getStepForm(1).get('investmentType').value as InvestmentType;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private gpAssetDataService: GpAssetDataService,
    private gpHoldingDataService: GpHoldingDataService,
    private router: Router,
    private route: ActivatedRoute,
    private numberPipe: TerraNumberPipe,
    private routingService: RoutingService,
    private userService: UserService,
    private gpFundService: GpFundService,
    private logger: LoggerService,
    private telemetryService: TelemetryService,
    private utilsService: UtilsService
  ) {
    super();
    this.generateForm();
    this.initStepsNavigationHandler();
  }

  initStepsNavigationHandler() {
    // Whenever the currentStep Changes, the user will be redirected
    this.currentStep$.pipe(distinctUntilChanged()).subscribe(
      step => {
        // Navigate to the first if the previous step was not submitted
        if (step > 1 && !this.isStepSubmitted(step - 1 as CreateFundAssetStepNumber)) {
          this.currentStep$.next(1);
        } else {
          this.fundId$.pipe(untilComponentDestroyed(this), take(1))
            .subscribe(fundId => {
              const wizardBaseUrl = this.routingService.createFundAsset(fundId);
              const specificStepUrl = this.getStepPathByNumber(step);
              this.router.navigateByUrl(`${wizardBaseUrl}/${specificStepUrl}`);
            });
        }
      });
  }

  cancel() {
    this.fundId$.pipe(untilComponentDestroyed(this), take(1))
      .subscribe(fundId => {
        this.router.navigateByUrl(this.routingService.gpFundWithTab(fundId, 'portfolio'));
      });
  }

  getStepForm(stepNumber: CreateFundAssetStepNumber) {
    const stepFormName = 'step' + stepNumber;
    if (stepFormName && this.createAssetForm) {
      return this.createAssetForm.get(stepFormName) as UntypedFormGroup;
    }
    return null;
  }

  getStepPathByNumber(step: CreateFundAssetStepNumber) {
    return this.wizardStepPaths[step - 1];
  }

  isStepSubmitted(stepNumber: CreateFundAssetStepNumber) {
    const step = this.getStepForm(stepNumber);
    return step.get(this.IS_STEP_SUBMITTED_FIELD_NAME).value as boolean;
  }

  setStepAsSubmitted(stepNumber: CreateFundAssetStepNumber, isSubmitted = true) {
    this.getStepForm(stepNumber).get(this.IS_STEP_SUBMITTED_FIELD_NAME).setValue(isSubmitted);
  }

  getCurrentStepIndexFromUrl() {
    const wizardStep = this.route.snapshot.children[0].url[0].path.toLowerCase();
    const index = this.wizardStepPaths.findIndex(x => x === wizardStep.toString());
    return index;
  }

  generateForm() {

    this.userService.accountDetails$.pipe(untilComponentDestroyed(this), take(1)).subscribe(accountDetails => {

      this.createAssetForm = this.fb.group({
        step1: this.fb.group({
          assetName: [null, [Validators.required, Validators.minLength(2)], this.validateAssetNameNotTaken.bind(this)],
          legalName: [null, [Validators.required]],
          holdingType: [null, [Validators.required]],
          investmentType: [null, [Validators.required]],
          areaUnits: AreaUnit.SquareFeet,
          lotSizeArea: null,
          stories: null,
          residentialUnits: null,
          retailUnits: null,
          rooms: null,
          grossBuildingArea: null,
          residentialNetSellableArea: null,
          retailNetSellableArea: null,
          numberOfSites: null,
          email: [accountDetails.email, [Validators.required, CustomValidators.EmailWithSpaces]],
          assetLocation: null,
        }),
        step2: this.fb.group({
          totalCapitalization: [null, [Validators.required, CustomValidators.minFormattedNumber(0)]],
          totalEquityInvestedToDate: null,
          totalReturnsToDate: null,
          totalInvestorEquity: [null, [Validators.required, CustomValidators.minFormattedNumber(0)]],

          plannedCostsToDate: null,
          actualCostsToDate: null,

          outstandingLoanAmountToDate: null,

          gpEquityFromTotalEquity: null,
          estimatedMarketValue: null,

          constructionStartDate: null,
          constructionEndDate: null,

          projectedPerformance: this.fb.group({
            cashOnCashRate: null,
            nonLeveragedCapRate: null,
            targetInvestmentIrr: null,
            targetInvestmentIrrMax: null,
            expectedRoi: null,
            targetEquityMultiplier: null,
            targetEquityMultiplierMax: null,
            targetInvestmentPeriod: null,
            preferredReturn: null
          }),
          projectBudgetOriginal: this.fb.group({
            acquisition: null,
            hardCost: null,
            softCost: null,
            financing: null,
            totalCosts: null,
            totalCostsPerGrossAreaUnit: null,
            totalCostsPerNetAreaUnit: null
          }),
          lenderInformation: this.fb.group({
            lenderName: null,
            amount: null,
            loanToValue: null,
            interestRate: null,
            interestType: null,
            closingDate: null,
            maturityDate: null,
            additionalTerms: ['', Validators.maxLength(TerraUtils.consts.validators.GENERAL_LONG_STRING_MAX_LENGTH)],
            loanType: null,
            totalFinancingPerGrossAreaUnit: null,
            totalFinancingPerNetAreaUnit: null
          })
        }),
        step3: this.fb.group({
          isPrivate: false,
          assetPhotos: this.fb.array([]),
        })
      });

      // add the IS_STEP_SUBMITTED field for each step:
      for (let i = 1; i <= 3; i++) {
        this.getStepForm(i as CreateFundAssetStepNumber).addControl(this.IS_STEP_SUBMITTED_FIELD_NAME, this.fb.control(false));
      }

      const photosArray = this.createAssetForm.get('step3.assetPhotos') as UntypedFormArray;
      for (let i = 0; i < 8; i++) {
        photosArray.push(this.fb.control(null, {asyncValidators: this.validatePhotoFileTypeSupported.bind(this)}));
      }
    });
  }

  generateSubmitModel(fundId: number): GpAssetReqRes {
    const formValues = this.createAssetForm.value;
    const step1 = formValues.step1;
    const step2 = formValues.step2;
    const step3 = formValues.step3;

    const asset = new GpAssetReqRes();

    asset.fundId = fundId;

    // Step1
    asset.name = step1.assetName;
    asset.legalName = step1.legalName;
    asset.holdingType = step1.holdingType;
    asset.investmentType = step1.investmentType;

    asset.areaUnits = this.numberPipe.parse(step1.areaUnits);
    asset.lotSizeArea = this.numberPipe.parse(step1.lotSizeArea);
    asset.stories = this.numberPipe.parse(step1.stories);
    asset.residentialUnits = this.numberPipe.parse(step1.residentialUnits);
    asset.retailUnits = this.numberPipe.parse(step1.retailUnits);
    asset.rooms = this.numberPipe.parse(step1.rooms);
    asset.grossBuildingArea = this.numberPipe.parse(step1.grossBuildingArea);
    asset.residentialNetSellableArea = this.numberPipe.parse(step1.residentialNetSellableArea);
    asset.retailNetSellableArea = this.numberPipe.parse(step1.retailNetSellableArea);
    asset.numberOfSites = this.numberPipe.parse(step1.numberOfSites);
    asset.email = step1.email;
    asset.location = {...step1.assetLocation.address, street2Name: step1.assetLocation.street2Name} as LocationDetailsResponse;


    // Step 2 Investment details
    asset.totalCapitalization = this.numberPipe.parse(step2.totalCapitalization);
    asset.totalEquityInvestedToDate = this.numberPipe.parse(step2.totalEquityInvestedToDate);
    asset.totalReturnsToDate = this.numberPipe.parse(step2.totalReturnsToDate);
    asset.totalInvestorEquity = this.numberPipe.parse(step2.totalInvestorEquity);

    asset.plannedCostsToDate = this.numberPipe.parse(step2.plannedCostsToDate);
    asset.actualCostsToDate = this.numberPipe.parse(step2.actualCostsToDate);
    asset.outstandingLoanAmountToDate = this.numberPipe.parse((step2.outstandingLoanAmountToDate));

    asset.gpEquityFromTotalEquity = this.numberPipe.parse(step2.gpEquityFromTotalEquity);
    asset.estimatedMarketValue = this.numberPipe.parse(step2.estimatedMarketValue);

    asset.constructionStartDate = step2.constructionStartDate;
    asset.constructionEndDate = step2.constructionEndDate;


    asset.projectedPerformance = new ProjectedPerformance();
    const projectedPerfModel = asset.projectedPerformance;

    projectedPerfModel.cashOnCashRate = this.numberPipe.parse(step2.projectedPerformance.cashOnCashRate);
    projectedPerfModel.nonLeveragedCapRate = this.numberPipe.parse(step2.projectedPerformance.nonLeveragedCapRate);
    projectedPerfModel.targetInvestmentIrr = this.numberPipe.parse(step2.projectedPerformance.targetInvestmentIrr);
    projectedPerfModel.targetInvestmentIrrMax = this.numberPipe.parse(step2.projectedPerformance.targetInvestmentIrrMax);
    projectedPerfModel.expectedRoi = this.numberPipe.parse(step2.projectedPerformance.expectedRoi);
    projectedPerfModel.targetEquityMultiplier = this.numberPipe.parse(step2.projectedPerformance.targetEquityMultiplier);
    projectedPerfModel.targetEquityMultiplierMax = this.numberPipe.parse(step2.projectedPerformance.targetEquityMultiplierMax);
    projectedPerfModel.targetInvestmentPeriod = this.numberPipe.parse(step2.projectedPerformance.targetInvestmentPeriod);
    projectedPerfModel.preferredReturn = this.numberPipe.parse(step2.projectedPerformance.preferredReturn);

    // Step 2 - Original budget:
    asset.projectBudgetOriginal = new ProjectBudget();
    const budgetModel = asset.projectBudgetOriginal;
    const budgetFormValues = step2.projectBudgetOriginal;
    budgetModel.acquisition = this.numberPipe.parse(budgetFormValues.acquisition);
    budgetModel.hardCost = this.numberPipe.parse(budgetFormValues.hardCost);
    budgetModel.softCost = this.numberPipe.parse(budgetFormValues.softCost);
    budgetModel.financing = this.numberPipe.parse(budgetFormValues.financing);
    budgetModel.totalCosts = this.numberPipe.parse(budgetFormValues.totalCosts);
    budgetModel.totalCostsPerGrossAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerGrossAreaUnit);
    budgetModel.totalCostsPerNetAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerNetAreaUnit);

    // Lender information:
    asset.lenderInformation = new LenderInformation();
    const lenderInfo = asset.lenderInformation;

    lenderInfo.lenderName = step2.lenderInformation.lenderName;
    lenderInfo.amount = this.numberPipe.parse(step2.lenderInformation.amount);
    lenderInfo.loanToValue = this.numberPipe.parse(step2.lenderInformation.loanToValue);
    lenderInfo.interestRate = this.numberPipe.parse(step2.lenderInformation.interestRate);
    lenderInfo.interestType = this.numberPipe.parse(step2.lenderInformation.interestType);
    lenderInfo.closingDate = step2.lenderInformation.closingDate;
    lenderInfo.maturityDate = step2.lenderInformation.maturityDate;
    lenderInfo.additionalTerms = step2.lenderInformation.additionalTerms;
    lenderInfo.loanType = step2.lenderInformation.loanType;
    lenderInfo.totalFinancingPerGrossAreaUnit = this.numberPipe.parse(step2.lenderInformation.totalFinancingPerGrossAreaUnit);
    lenderInfo.totalFinancingPerNetAreaUnit = this.numberPipe.parse(step2.lenderInformation.totalFinancingPerNetAreaUnit);


    // Step 3 Marketing material -  Add the photos:
    asset.isPrivate = !!step3.isPrivate;
    asset.attachments = new Array<HoldingFileReqRes>();
    try {
      const photosArray = step3.assetPhotos;
      photosArray
        .forEach((photoMetaFileLink: MetaFileLink) => {
          if (photoMetaFileLink) {
            const file = new HoldingFileReqRes();
            file.metaFileLinkId = photoMetaFileLink.id;
            file.fileType = HoldingFileType.Photo;
            asset.attachments.push(file);
          }
        });
    } catch (error) {
      this.logger.error('ERROR while trying to add photos to the submit model.', error);
    }


    return asset;
  }

  createFundAsset() {
    this.isGeneralServerError = false;

    if (this.createAssetForm.valid) {
      this.processingSubmit$.next(true);
      this.fundId$.pipe(
        untilComponentDestroyed(this),
        take(1),
        switchMap(fundId => {
          const model = this.generateSubmitModel(fundId);
          return this.gpAssetDataService.createFundAsset(model);
        })
      )
        .subscribe(
          asset => {
            this.processingSubmit$.next(false);
            this.assetCreated = true;
            this.router.navigateByUrl(this.routingService.gpAssetPage(asset.id));
            this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
              this.telemetryService.create({
                eventID: '17',
                eventTitle: 'CREATE FUND ASSET (COMPLETED)',
                organizationID: clientDetails.organizationDetails.id
              }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
            });
          },
          error => {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            } else {
              this.isGeneralServerError = true;
            }
            this.processingSubmit$.next(false);
          }
        );
    }
  }

  // Async validation functions:
  validateAssetNameNotTaken(control: AbstractControl, originalValue = '') {
    const {value}: { value: string } = control;

    if (!value || value === originalValue) {
      return of(null);
    }
    return timer(1000).pipe(
      switchMap(_ => this.gpAssetDataService.isAssetNameExists(value)),
      map(isAssetNameExists => {
        return isAssetNameExists ? {assetNameTaken: true} : null;
      })
    );
  }

  validatePhotoFileTypeSupported(control: AbstractControl) {
    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingDataService.isFileSupportedPhoto(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }
}
