import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CovercyBankAccountResponse } from 'src/app/shared/models/CovercyBankAccountResponse.model';
import { CurrencyModel } from 'src/app/shared/models/CurrencyModel';


export class DepositDetailsDialogContext {
  public covercySourceBankAccount: CovercyBankAccountResponse;
  public distributingEntityName: string;
  public amount: number;
  public currency: CurrencyModel;

  // CT of the dist entity + "distribution deposit"
  public transactionReference: string;
}


@Component({
  selector: 'terra-deposit-bank-account-details-dialog',
  templateUrl: './deposit-details-dialog.component.html',
  styleUrls: ['./deposit-details-dialog.component.scss']
})
export class DepositDetailsDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public context: DepositDetailsDialogContext) { }

  ngOnInit() {
  }

}
