export const SHARE_LOCATION = 'azure';
export const SHARE_CONTAINER = 'main';
export const MAX_FILE_SIZE = 500 * 1024 * 1024; // 500MB;
export const FILE_FORMATS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.tif',
  '.tiff',
  '.gif',
  '.bmp',
  '.mp3',
  '.ogg',
  '.wav',
  '.wma',
  '.flac',
  '.alac',
  '.mp4',
  '.webm',
  '.avi',
  '.mpeg',
  '.wmv',
  '.ogg',
  '.pdf',
  '.doc',
  '.docx',
  '.docm',
  '.odt',
  '.txt',
  '.ppt',
  '.pptx',
  '.pptm',
  '.odp',
  '.xls',
  '.xlsx',
  '.xlsm',
  '.ods',
  '.csv',
];
