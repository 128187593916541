<!-- Rank all loader -->
<!--<terra-loader *ngIf="isLoading$ | async" class="loader"></terra-loader>-->

<!-- List of investments -->
<mat-accordion *ngIf="fundraising$ | async as fundraising" #accordion="matAccordion" class="investors-list">

  <h2 class="title">Total Investors ({{ fundraising.investments.length }})</h2>
  <!-- Sorting / Filtering -->
  <div class="filter-and-sorting">
    <div class="wrapper">
      <!-- Name filter -->
      <div class="filter-wrapper">
        <mat-form-field appearance="outline" class="name-filter">
          <input matInput [formControl]="nameFilter" type="text" placeholder="Filter by name" autocomplete="name">
          <button *ngIf="nameFilter.value" matSuffix mat-icon-button (click)="nameFilter.reset()">
            <mat-icon class="notranslate">clear</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <mat-divider [vertical]="true" style="margin: 0 10px 0 20px"></mat-divider>

      <!-- Sorting -->
      <div class="sorting-wrapper" *ngIf="(investmentsSortOrder$ | async) as selectedOrder">
        <button mat-button [matMenuTriggerFor]="sortMenu">Sort by:
          {{ selectedOrder | enumString : InvestorsOrderBy }}
          <mat-icon class="notranslate">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #sortMenu="matMenu">
          <button *ngFor="let sortValue of investorsOrderByOptions$ | async" mat-menu-item (click)="setInvestorsOrder(sortValue)"
                  [ngClass]="{'active-sort-option': sortValue === selectedOrder }">
            {{sortValue | enumString: InvestorsOrderBy }}
            <span *ngIf="sortValue === selectedOrder">(current)</span>
          </button>
        </mat-menu>
        <button matTooltip="Refresh sorting" mat-icon-button (click)="setInvestorsOrder(selectedOrder)"
                aria-label="Refresh investors sorting">
          <mat-icon class="notranslate">refresh</mat-icon>
        </button>
      </div>

      <mat-divider [vertical]="true" style="margin: 0 5px 0 10px"></mat-divider>

      <button *ngIf="(isAiRankingFeatureActive$ | async) && !(isReadOnlyContribution$ | async)"
              type="button" mat-button class="tall-button rank-button" (click)="rankInvestors()">
        Rank investors
      </button>
      <terra-loader *ngIf="isLoading$ | async" class="loader"></terra-loader>
    </div>

    <!-- Add investor -->
    <span>
      <button mat-button [matMenuTriggerFor]="addInvestorMenu" class="add-investor-button">
        <mat-icon class="notranslate">add</mat-icon>
        Add Investor
      </button>
      <mat-menu #addInvestorMenu="matMenu">
        <button mat-menu-item (click)="addFromContactList()">
          <mat-icon class="notranslate">add</mat-icon>
          Add From Contact List
        </button>
        <button mat-menu-item (click)="createNewContact()">
          <mat-icon class="notranslate">add</mat-icon>
          Create New Contact
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item class="participating-fund" (click)="openParticipatingFundDialog()" [disabled]="!(isFundFeatureActive$ | async)">
          <span class="premium-box">PREMIUM</span>
          <mat-icon class="notranslate">add</mat-icon>
          Add Participating Fund
        </button>
      </mat-menu>
    </span>

  </div>

  <!-- investments -->
  <ng-container *ngIf="orderedInvestments$ | async as orderedInvestments">
    <terra-loader *ngIf="isDeletingInvestment; else investmentsData"></terra-loader>
    <ng-template #investmentsData>
      <mat-expansion-panel *ngFor="let investment of orderedInvestments; trackBy: investmentTrackByFn"
                           class="expansion-panel"
                           [expanded]="fundraising.investments.length === 1 || investment.id === scrollToInvestmentId"
                           (afterExpand)="scrollToInvestmentPanel(investment.id)"
                           (closed)="closePanel(investment.id)"
                           (opened)="openPanel(investment.id)"
                           [attr.id]="'investment_' + investment.id">
        <mat-expansion-panel-header class="panel-header" collapsedHeight="120px" expandedHeight="113px">
          <mat-panel-title class="panel-title">
            <span *ngIf="investment.rank !== InvestorRank.None"
                  class="color-column"
                  [ngClass]="{'high-rank-color': investment.rank === InvestorRank.Higher,
                              'normal-rank-color': investment.rank === InvestorRank.Normal,
                              'low-rank-color': investment.rank === InvestorRank.Lower}">
            </span>
            <div class="name-section">
              <div class="lo_sensitive investor-name">{{ getFormattedInvestorName(investment) | async }}
                <span class="ie-name" *ngIf="!(isAllContactsVisible$ | async) && (allowInvestorName$ | async)">Entity {{investment.investingEntityId}}</span>
              </div>
              <ng-container *terraSystemFeatureFlag="FeatureFlagConsts.SystemFeatureFlagAccreditationOn">
                <terra-chips-status *ngIf="investment.id !== investmentPanelExtended" [status]="investment.investingEntity.accreditation?.status" [statusNamespace]="AccreditationStatus"></terra-chips-status>
              </ng-container>
            </div>
            <p class="status">
              <label>Status: </label>
              <strong>{{investment.status | enumString: InvestmentStatus}}</strong> {{getSignedCCData(investment)}}

              <span *ngIf="isAwaitingGpSignature(investment, false)"> (By investor)</span>
              <span *ngIf="isAwaitingGpSignature(investment)" class="gp-sign-alert">Awaiting your signature</span>
            </p>
            <span *ngIf="investment.rank !== InvestorRank.None" class="likelihood">
              <mat-icon [svgIcon]="aiRankingService.getRankingIconName(investment.rank)"
                        class="icon notranslate"></mat-icon>
              {{ investment.rank | enumString: InvestorRank}}
            </span>
            <span *ngIf="investment.insight" class="insight">
            <label>Insight: </label>
              {{ investment.insight }}
            </span>
            <span *ngIf="investment.internalRemarks?.length > 0 || investment.externalRemarks?.length > 0"
                  class="remarks">
              <mat-icon [matTooltip]="getRemarks(investment)" matTooltipClass="terra-multiline-tooltip"
                        matTooltipPosition="left" class="notranslate">
                speaker_notes
              </mat-icon>
            </span>
            <span class="investor-title-amount"
                  [class.missing-amount]="investment.finalAmount === 0 && (isCompletedExternalContribution$ | async)">
            <p>{{ investment.currency.symbol }}{{ (investment.finalAmount ? investment.finalAmount : investment.estimatedAmount) | number }}
              <ng-container *ngIf="hasCommitments$ | async">
                {{investment.totalCommitment ? ' / ' + investment.currency.symbol + (investment.totalCommitment | number) : null}}
              </ng-container>
            </p>
            </span>
          </mat-panel-title>
          <div class="remove-button-wrapper"
               *ngIf="investment.paymentSettlementPlatform == PaymentPlatform.External || investment.paymentSettlementPlatform == PaymentPlatform.Internal">
            <button [ngClass]="{'hidden':fundraising.investments.length === 1}" class="remove-button"
                    mat-icon-button (click)="deleteInvestment(investment.id, $event)"
                    matTooltip="Delete investment">
              <mat-icon class="notranslate">remove_circle_outline</mat-icon>
            </button>
          </div>
        </mat-expansion-panel-header>
        <!-- Content -->
        <ng-template matExpansionPanelContent>
          <span *ngIf="investment.rank !== InvestorRank.None"
                class="color-column"
                [ngClass]="{'high-rank-color': investment.rank === InvestorRank.Higher,
                             'normal-rank-color': investment.rank === InvestorRank.Normal,
                             'low-rank-color': investment.rank === InvestorRank.Lower}">
          </span>
          <terra-asset-investor *ngIf="gpHoldingService.holdingDiscriminator === HoldingDiscriminator.Asset"
            [investmentId]="investment.id" [investmentDetails]="investment" [hasCommitments]="hasCommitments$ | async" [refreshCommitmentsTrigger$]="refreshTotalCommitments$"
            [ngClass]="'flow-investment-panel-status-' + investment.status"></terra-asset-investor>
          <terra-fund-investor *ngIf="gpHoldingService.holdingDiscriminator === HoldingDiscriminator.Fund"
            [investmentId]="investment.id" [investmentDetails]="investment" [hasCommitments]="hasCommitments$ | async" [refreshCommitmentsTrigger$]="refreshTotalCommitments$"
            [ngClass]="'flow-investment-panel-status-' + investment.status"></terra-fund-investor>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>

    <div *ngIf="orderedInvestments.length === 0" class="terra-card empty-state">
      <img class="image" src="assets/images/general/people.png"/>
      <h2 class="all-filtered-message">
        {{nameFilter.value ? 'Investors with the name you entered were not found' : 'Lets add your first investor'}}
      </h2>

      <ng-container *ngIf="!nameFilter.value">
        <button mat-button class="button add-existing" (click)="addFromContactList()">
          <mat-icon class="notranslate">add</mat-icon>
          Add From Contact List
        </button>
        <button mat-button class="button create-new" (click)="createNewContact()">
          <mat-icon class="notranslate">add</mat-icon>
          Create New Contact
        </button>
      </ng-container>
    </div>
  </ng-container>
</mat-accordion>
