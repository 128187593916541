<terra-loader class="page-loader" *ngIf="isLoading$ | async"></terra-loader>

<terra-dialog-header>
  <div class="title">
    <h1>Send offering deck</h1>
    <p>Share this {{holdingService.discriminatorLowercaseStr}} with your potential investors</p>
  </div>
</terra-dialog-header>

<div class="terra-error-style" [ngClass]="{'loading': isLoading$ | async}">
  <mat-dialog-content>
    <form id="dialogForm" (ngSubmit)="send()" [formGroup]="pageForm" class="vertical-form" (keydown.enter)="$event.preventDefault()">

      <!-- Subject line   -->
      <label class="text-input-label">Subject</label>
      <mat-form-field appearance="outline" class="input-width">
        <input matInput formControlName="subject"/>
        <mat-error *ngIf="pageForm.get('subject').hasError('required')">Required</mat-error>
      </mat-form-field>

      <div class="email-body-section">
        <div class="email-body-message">
          <!-- Message -->
          <label for="txtMessage" class="text-input-label">Add a personal message (optional):</label>
          <quill-editor id="txtMessage" formControlName="message" format="html" class="message-input cke_rtl" placeholder="" />

          <p class="hint">{{"* {first name} will be replaced with the contact's first name. \n* {investing entity name} will be replaced with the name of the investing entity."}}</p>
        </div>
      </div>

      <!--  File  -->
      <span>
        The following document will be attached to the offering deck:
        <a [href]="marketingDeckLink$ | async" class="link" target="_blank">{{ (fundraising$ | async).offeringDeck.title | truncate: 40 }}</a>
      </span>

      <hr />

      <!-- Error message -->
      <div *ngIf="isSubmitted$ | async">
        <p *ngIf="pageForm.invalid" class="error-message">
          Please review and correct the fields marked in red
        </p>
      </div>

      <!-- investors table filters -->
      <div class="filters-bar">
        <!-- Name filter -->
        <div class="filter text-filter">
          <mat-form-field appearance="outline">
            <input type="text" placeholder="Filter by name" matInput formControlName="name" class="lo_sensitive" autocomplete="nope"
                   autocomplete="off-no-complete" />
          </mat-form-field>
        </div>
        <!-- tag filter -->
        <div class="filter tags-filter">
          <mat-form-field appearance="outline">
            <mat-select matInput placeholder="Filter by tags" formControlName="multiselectTags" multiple>
              <mat-option *ngFor="let tag of allTags$ | async" [value]="tag.key" class="lo_sensitive">{{ tag.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- referrer filter -->
        <div class="filter contact-referrer-filter">
          <mat-form-field appearance="outline">
            <mat-select formControlName="contactReferrerId" placeholder="Contact referrer">
              <mat-option [value]="null">None</mat-option>
              <mat-option *ngFor="let contactReferrer of allContactReferrers$ | async" [value]="contactReferrer.id">
                <ng-container *ngIf="allowInvestorName$ | async">
                  {{ (contactReferrer.name + ' - ' +contactReferrer.id) | truncate : 40 : true }} 
                </ng-container>
                <ng-container *ngIf="!(allowInvestorName$ | async)">
                  {{ contactReferrer.id }} 
                </ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </form>

    <terra-investing-entities-display-table [showMessageWhenListIsEmpty]="false" [excludeAgentContactInvestingEntities]="true" >
    </terra-investing-entities-display-table>

  </mat-dialog-content>
</div>

<mat-dialog-actions>
<!--
  <button type="button" form="dialogForm" mat-flat-button color="primary" class="page-cta" (click)="sendAll()">Send all</button>
-->
  <button type="submit" form="dialogForm" mat-stroked-button color="primary" class="page-cta" [disabled]="(selectedInvestingEntities$ | async).length === 0">Send</button>
  <button type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
