import { Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  standalone: true,
  imports: [MatExpansionModule],
  styles: [`
    :host {
      display: block;
      width: 100%;
      .advanced-wrapper .mat-expansion-panel {
        box-shadow: none;
        margin: 0 -16px;
      }
    }
`],
  template: `
    <mat-accordion [displayMode]="'flat'" togglePosition="before" class="advanced-wrapper">
    <mat-expansion-panel >
      <mat-expansion-panel-header>
        <mat-panel-title>{{ props.label }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="card">
        <div class="card-body">
          <ng-container #fieldComponent />
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  `,
})
// change for group?
export class AdvancedWrapperComponent extends FieldWrapper {}