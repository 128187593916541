<div class="flex-container">
  <div>
    <span>
      <h2>
      <strong>Invested: {{ currencySymbol$ | async }}{{ (amountInvested$ | async) | terraNumber }}</strong>
      of
      {{ currencySymbol$ | async }}{{ (targetAmount$ | async) | terraNumber }}</h2>
    </span>
  </div>
  <div>
    <!-- Chart element -->
    <terra-fundraising-doughnut-chart></terra-fundraising-doughnut-chart>
  </div>
</div>