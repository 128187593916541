import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {UserService} from 'src/app/services/shared/user.service';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import {LpInterest} from 'src/app/dashboard/models/lpInterest.enum';
import {FundInvestorService} from '../../fund-investor/fund-investor.service';

@Component({
  selector: 'terra-fund-investor-reactions-to-offering-deck',
  templateUrl: './fund-investor-reactions-to-offering-deck.component.html',
  styleUrls: ['./fund-investor-reactions-to-offering-deck.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundInvestorReactionsToOfferingDeckComponent implements OnInit {

  LpInterest = LpInterest;

  isActiveFeature$ = this.userService.userHasFeatureFlag(FeatureFlags.LpEngagement);

  investmentDetails$ = this.fundInvestorService.investmentDetails$;

  constructor(private userService: UserService, private fundInvestorService: FundInvestorService) {

  }

  ngOnInit() {
  }
}
