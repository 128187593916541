import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {HoldingFileType} from 'src/app/shared/enums/HoldingFileType.enum';
import {EditFundStepBase, EditFundTabNumber} from '../EditFundStepBaseAndInterface';
import {EditFundService} from '../edit-fund.service';
import FundraisingStatus from 'src/app/shared/enums/FundraisingStatus.enum';

@Component({
  selector: 'terra-edit-fund-tab-marketing-material',
  templateUrl: './edit-fund-tab-marketing-material.component.html',
  styleUrls: ['./edit-fund-tab-marketing-material.component.scss']
})
export class EditFundTabMarketingMaterialComponent extends EditFundStepBase implements OnInit {
  @Input() isStatusFundRaising: boolean = true;

  videoUrl: SafeResourceUrl;
  showSecondPhotosRow = false;
  subscription: Subscription = new Subscription();
  readonly TAB_NUMBER: EditFundTabNumber = 3;

  constructor(private editFundService: EditFundService,
    private cdr: ChangeDetectorRef,
    protected _sanitizer: DomSanitizer) {
    super(editFundService);
  }

  ngOnInit() {
    // Show the second image uploaders row if there are more than 4 existing images:
    const fundPhotos = this.dialogContext.fundDetails.attachments.filter(x => x.fileType === HoldingFileType.Photo);
    this.showSecondPhotosRow = fundPhotos && fundPhotos.length > 4;

    if (this.pageForm.get("video").value) {
      this.videoUrl = this.getVideoUrl()
    }

    this.additionalAttachment();
    if (!this.fundraisings.length) {
      this.pageForm.get('defaultOfferingDeckContribution').disable();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  additionalAttachment() {
    this.subscription = this.pageForm.get('attachments').valueChanges.pipe()
      .subscribe((attachments: []) => {
        const remInd = attachments.findIndex(c => !c);

        if (remInd >= 0) {
          this.editFundService.popMarketingAttachment(remInd);
        }
        else if (attachments.length < 10) {
          this.editFundService.pushMarketingAttachment();
        }

        this.cdr.markForCheck();
      });
  }

  get attachments() {
    return (this.pageForm.get('attachments') as UntypedFormArray).controls;
  }

  get fundraisings() {
    return this.dialogContext.fundraisings.filter(f => f.hasDeck && f.status === FundraisingStatus.InProgress);
  }

  addVideo() {
    if (!this.pageForm.get("video").value) {
      return;
    }
    this.pageForm.get('shouldShowPlayer').setValue(true);
    this.videoUrl = this.getVideoUrl();
  }

  getVideoUrl(): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.getYoutubeVideoIdFromUrl()}?autoplay=1`);
  }

  getYoutubeVideoIdFromUrl(): string {
    const url = this.pageForm.get("video").value;
    const urlObject = new URL(url);
    let urlOrigin = urlObject.origin;
    let urlPath = urlObject.pathname;

    if (urlOrigin.search('youtu.be') > -1) {
      return urlPath.substr(1);
    }

    if (urlPath.search('embed') > -1) {
      // Örneğin "/embed/wCCSEol8oSc" ise "wCCSEol8oSc" return eder.
      return urlPath.substr(7);
    }


    return urlObject.searchParams.get('v');
  }

  removeVideo() {
    this.pageForm.get("video").reset();
    this.pageForm.get("shouldShowPlayer").setValue(false);
  }
}
