import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {debounceTime, map, shareReplay} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {
  InvestingEntitiesDisplayTableComponent
} from '../../../../../contacts/components/investing-entities-display-table/investing-entities-display-table.component';
import {ContactReferrerDataService} from 'src/app/services/gp/contact-referrer-data.service';
import {KeyValuePair} from '../../../../../../shared/types/KeyValuePair.model';
import {ContactDataService} from 'src/app/services/gp/contact-data.service';
import {
  InvestingEntityTableService
} from '../../../../../contacts/components/investing-entities-display-table/investing-entity-table.service';


export interface investorDetailsAddToDistribution {
  holdingId: number;
  distributionId: number;
}

@Component({
  selector: 'terra-investors-out-of-distribution-dialog',
  templateUrl: './investors-out-of-distribution-dialog.html',
  styleUrls: ['./investors-out-of-distribution-dialog.scss'],
  providers: [InvestingEntityTableService]
})
export class InvestorsOutOfDistributionDialog extends OnDestroyMixin implements OnInit, AfterViewInit {

  constructor(
    private investingEntityTableService: InvestingEntityTableService,
    private contactReferrerDataService: ContactReferrerDataService,
    private contactDataService: ContactDataService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<InvestorsOutOfDistributionDialog>,
    @Inject(MAT_DIALOG_DATA) public details: investorDetailsAddToDistribution,
  ) {
    super();
  }


  @ViewChild(InvestingEntitiesDisplayTableComponent) investingEntitiesTable: InvestingEntitiesDisplayTableComponent;

  pageForm: UntypedFormGroup;

  contactsToAdd$ = new BehaviorSubject<number[]>([]);

  private contactTagsResponse$ = this.contactDataService.getContactTags().pipe(shareReplay(1));

  allTags$: Observable<KeyValuePair<number, string>[]> = this.contactTagsResponse$.pipe(map(response => response.tags), shareReplay(1));
  allContactReferrers$ = this.contactReferrerDataService.getAll().pipe(
    shareReplay(1)
  );
  showMessageWhenListIsEmpty: any;

  ngAfterViewInit(): void {
    this.investingEntityTableService.updateDistributionId(this.details.distributionId);
    this.investingEntityTableService.updateHoldingId(this.details.holdingId);
    this.investingEntitiesTable.selection.changed.pipe(
      untilComponentDestroyed(this),
      map(() => {
        this.contactsToAdd$.next(this.getInvestingEntitiesToAdd());
      }))
      .subscribe();
  }

  ngOnInit(): void {
    this.pageForm = this.fb.group({
      term: [],
      multiselectTags: [],
      contactReferrerId: []
    });

    this.pageForm
      .get('term')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(300))
      .subscribe(term => {
        this.investingEntityTableService.updateFilter(term);
      });

    this.pageForm
      .get('multiselectTags')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(300))
      .subscribe(tags => {
        this.investingEntityTableService.updateTagsFilter(tags);
      });

    this.pageForm
      .get('contactReferrerId')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(200))
      .subscribe((contactReferrerId: number) => {
        this.investingEntityTableService.updateContactReferrerFilter(contactReferrerId);
      });

  }

  toggleButton(checked) {
    checked
      ? this.investingEntityTableService.updateHoldingId(null)
      : this.investingEntityTableService.updateHoldingId(this.details.holdingId);

  }

  onSubmit() {
    const investingEntitiesIdsToCreate = this.getInvestingEntitiesToAdd();
    this.dialogRef.close(investingEntitiesIdsToCreate);
  }

  private getInvestingEntitiesToAdd() {
    let investingEntityIdsToCreate: number[];
    if (this.investingEntitiesTable && this.investingEntitiesTable.selectedInvestingEntities) {
      investingEntityIdsToCreate = this.investingEntitiesTable.selectedInvestingEntities.map(c => c.id);
    }
    return investingEntityIdsToCreate ? investingEntityIdsToCreate : [];
  }
}
