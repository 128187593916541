import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {BehaviorSubject, EMPTY, forkJoin, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {EditBankAccountComponent} from 'src/app/dashboard/bankAccounts/components/edit-bank-account/edit-bank-account.component';
import {DistributionTransferReqRes} from '../DistributionTransferReqRes.model';
import {ClientBankAccountReqRes} from 'src/app/dashboard/models/bankAccount.model';
import {UserService} from 'src/app/services/shared/user.service';
import {
  ClientBankAccountDetailsComponent,
  ClientBankAccountDetailsDialogParams
} from 'src/app/shared/components/client-bank-account-details-dialog/client-bank-account-details-dialog.component';
import DistributionStatus from 'src/app/shared/enums/DistributionStatus.enum';
import {GpDistributionDataService} from 'src/app/services/gp/gp-distribution-data.service';
import {GpBankAccountDataService} from 'src/app/services/gp/gp-bank-account-data.service';
import {ResourceService} from 'src/app/services/resource.service';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {EditBankAccountContext} from 'src/app/dashboard/bankAccounts/components/edit-bank-account/EditBankAccountContext.model';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {DistributionTransactionPurpose} from '../../../../../shared/enums/DistributionTransactionPurpose.enum';
import {DistributionTransferDetailsReqRes} from '../DistributionTransferDetailsReqRes.model';
import {Router} from '@angular/router';
import {GpHoldingService} from '../../gp-holding.service';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';
import {PermissionService} from '../../../../../permission/permission.service';
import {TerraUtils} from '../../../../../shared/TerraUtils';
import {UnitBankAccountReqRes} from 'src/app/shared/models/gp/UnitBankAccountReqRes.model';
import {MergeUnitClientBankAccountDto} from 'src/app/shared/models/MergeUnitClientBankAccountDto.model';
import MergeUnitClientBankAccountType from 'src/app/shared/enums/MergeUnitClientBankAccountType.enum';
import { PaymentType } from 'src/app/shared/enums/payment-type.enum';

@Component({
  selector: 'terra-distribution-transfer-details',
  templateUrl: './distribution-transfer-details.component.html',
  styleUrls: ['./distribution-transfer-details.component.scss']
})
export class DistributionTransferDetailsComponent extends OnDestroyMixin implements OnInit, OnChanges {
  noAccessMessage = TerraUtils.consts.messages.GENERAL_RESOURCE_NOT_ALLOWED;
  DistributionStatus = DistributionStatus;
  AccountType = MergeUnitClientBankAccountType;
  PaymentType = PaymentType;

  @Output() amountChange = new EventEmitter();
  @Output() bankApproved = new EventEmitter();
  @Output() distributionTransferChange = new EventEmitter<DistributionTransferReqRes>();

  @Input() distributionId: number;
  @Input() distributionStatus: DistributionStatus;
  @Input() distributionTransfer: DistributionTransferReqRes;
  @Input() distributionCurrencyIso: string;
  @Input() distributionCurrencySymbol: string;
  @Input() bankAccounts: MergeUnitClientBankAccountDto[] = [];
  @Input() clientBankAccounts: ClientBankAccountReqRes[] = [];
  @Input() unitBankAccounts: UnitBankAccountReqRes[] = [];
  @Input() enablePaymentsViaCovercy = true;
  @Input() isThisGPDistributeTransfer: boolean;
  @Input() isParentSaving: boolean = false;
  @Input() revertAllChanges: EventEmitter<void>;
  @Input() holdingId: number;
  @Input() importedDistribution: boolean;
  @Input() distributionPurposes: DistributionTransactionPurpose[];
  @Input() updateFormFromModel: Subject<any>;
  @Input() updateGpPromote: Subject<any>;
  @Input() waterfallCalculated: Subject<any>;
  @Input() updatePaymentDateFormControl: Subject<any>;
  @Input() isCreateMode = false;
  @Input() disableGrossAmount = false;
  @Input() disableEditMode: boolean = false;


  get isEditMode() {
    return !!this.distributionId && this.router.url.includes('/edit');
  }

  get isViewMode() {
    return !this.isEditMode && !!this.distributionId
  }

  get isApproveBankAccount() {
    return this.distributionTransfer
      && (this.isEditMode || this.isCreateMode)
      && !this.distributionTransfer.bankAccountDetailsApprovedByGp
      && !this.distributionTransfer.isBankAccountChangeUnsaved
      && (!!this.distributionTransfer.unitBankAccountId || !!this.distributionTransfer.clientBankAccountId);
  }

  pageForm: UntypedFormGroup;

  selectedBankAccount: MergeUnitClientBankAccountDto = null;

  private bankAccountSelectionChanged$ = new EventEmitter<number>();
  revertAllAmountChanges$ = new EventEmitter<void>();
  transferDetailsAmountsChanged$ = new Subject();

  // In order to tell if it changed or not
  originalBankAccountId: number = null;


  organizationDetailsId$ = this.userService.accountDetails$
    .pipe(
      map(accountDetails => accountDetails.organizationDetails.id),
      shareReplay(1)
    );

  get lpClientDetailsId() {
    if (this.distributionTransfer && this.distributionTransfer.investingEntity
      && this.distributionTransfer.investingEntity.contact) {
      return this.distributionTransfer.investingEntity.contact.lpClientDetailsId;
    }
    return null;
  }

  get isSingleReasonForTransfer(): boolean {
    return !!this.distributionPurposes && this.distributionPurposes.length === 1;
  }

  isSaving$ = new BehaviorSubject(false);
  isServerError = false;

  isCurrencyError = false;

  private _savingCurrency$ = new BehaviorSubject(false);
  savingCurrency$ = this._savingCurrency$.pipe(distinctUntilChanged());

  // The LP can select from our outbound currencies (currencies Covercy can send out to clients)
  outboundCurrencies$ = this.resourceService.getOutboundCurrencies()
    .pipe(
      map(response => response.filter(c => !c.isInbound)),
      shareReplay(1)
    );

  @Output() isBusy = forkJoin([this.savingCurrency$, this.isSaving$]).pipe(
    map(allItems => allItems.some(x => x === true)),
    distinctUntilChanged()
  );

  isPercents: boolean;
  taxesSummary: number;
  initialFormValue: any;

  // enums
  DistributionTransactionPurpose = DistributionTransactionPurpose;

  showBankInfo$ = this.permissionService.showBankInfo$;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private userService: UserService,
    private gpDistributionDataService: GpDistributionDataService,
    private gpHoldingService: GpHoldingService,
    private resourceService: ResourceService,
    private gpBankAccountDataService: GpBankAccountDataService,
    private terraNumberPipe: TerraNumberPipe,
    private router: Router,
    private utilsService: UtilsService,
    private permissionService: PermissionService
  ) {
    super();
  }

  ngOnInit() {
    this.clearSelectedBankAccountIfInvalid(this.distributionTransfer);

    if (this.distributionTransfer.clientBankAccount) {
      this.selectedBankAccount = this.bankAccounts.find(b => b.accountType === MergeUnitClientBankAccountType.ExternalAccount &&
          b.id === this.distributionTransfer.clientBankAccount.id) ??
        new MergeUnitClientBankAccountDto(this.distributionTransfer.clientBankAccount);
      this.originalBankAccountId = this.distributionTransfer.clientBankAccount.id;
    } else if (this.distributionTransfer.clientBankAccountId > 0) {
      this.selectedBankAccount = this.bankAccounts.find(b => b.accountType === MergeUnitClientBankAccountType.ExternalAccount &&
        b.id === this.distributionTransfer.clientBankAccountId);
      this.originalBankAccountId = this.selectedBankAccount && this.selectedBankAccount?.id;
    } else if (this.distributionTransfer.unitBankAccount) {
      this.selectedBankAccount = this.bankAccounts.find(b => b.accountType === MergeUnitClientBankAccountType.CovercyAccount &&
        b.id === this.distributionTransfer.unitBankAccount.id);
      this.originalBankAccountId = this.distributionTransfer.unitBankAccount.id;
    } else if (this.distributionTransfer.unitBankAccountId > 0) {
      this.selectedBankAccount = this.bankAccounts.find(b => b.accountType === MergeUnitClientBankAccountType.CovercyAccount &&
        b.id === this.distributionTransfer.unitBankAccountId);
      this.originalBankAccountId = this.selectedBankAccount && this.selectedBankAccount?.id;
    }

    this.initPageForm();
    this.populateForm(this.distributionTransfer);
    this.reflectFormValuesOnInputTransfer();
    this.subscribeToTransferDetailsAmountsChanges();
    this.subscribeToTransferDetailsPaymentDateChanges();

    // When the selected bank account changes, if a bank is selected, and the preferred currency is missing, make the currency mandatory,
    this.pageForm.get('bankAccount').valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe((bankAccount: MergeUnitClientBankAccountDto) => {

        if (bankAccount?.accountType === MergeUnitClientBankAccountType.ExternalAccount) {

          // If there is a selected bank account, and this bank account is missing the preferred currency, the currency is required.
          const currencyFormControl = this.pageForm.get('preferredCurrency');
          currencyFormControl.setValidators(bankAccount && !bankAccount.preferredCurrencyId ? Validators.required : []);
          // and clear the currency drop down in case it was filled when another bank account was selected
          if (!bankAccount || !bankAccount.preferredCurrencyId) {
            currencyFormControl.setValue(null);
          }
          currencyFormControl.updateValueAndValidity();

          this.amountChange.emit();
        }

      });

    // In edit mode
    if(this.isViewMode || this.disableEditMode){
      this.disableEditDistributionViewMode();
    }


    // else {
    // when in Create state:
    this.handleEmitSignalToUpdateFeesCalculationWhenNeeded();
    // }

    // when revert all changes is called from outside, undo the bank account selection change
    if (this.revertAllChanges) {
      this.revertAllChanges.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.revertBankAccountChange();
        if (this.pageForm) {
          this.revertAllAmountChanges$.emit();
          this.pageForm.reset(this.initialFormValue);
          this.updateChangesPropertiesOnTransfer();
        }
      });
    }
  }

  private initPageForm() {
    this.pageForm = this.fb.group({
      paymentsViaCovercy: null,
      paymentSettlementDate: null,
      bankAccount: null,
      preferredCurrency: [null, this.selectedBankAccount && this.selectedBankAccount?.accountType === MergeUnitClientBankAccountType.ExternalAccount ? Validators.required : []],
      referenceMemo: [this.distributionTransfer.referenceMemo]
    });
  }

  revertBankAccountChange() {
    if (this.pageForm.get('bankAccount').enabled) {
      this.pageForm.get('bankAccount').setValue(this.bankAccounts.find(b => b.id === this.originalBankAccountId));
      this.pageForm.get('bankAccount').updateValueAndValidity();
      this.bankAccountSelectionChanged$.emit(this.originalBankAccountId);
      this.updateChangesPropertiesOnTransfer();
    }
  }

  /** Create a new investing entity bank account */
  addBankAccount() {
    const dialogConfig = new MatDialogConfig<EditBankAccountContext>();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'create-bank-account-dialog';
    dialogConfig.data = {
      enablePreferredCurrency: true,
      isAnOrganizationAccountDefault: false,
      investingEntity: this.distributionTransfer.investingEntity,
    } as EditBankAccountContext;

    const createdBankAccount$: Observable<ClientBankAccountReqRes> = this.dialog.open(EditBankAccountComponent, dialogConfig)
      .afterClosed().pipe(
        switchMap(createdAccount => createdAccount ? of(createdAccount) : EMPTY));

    createdBankAccount$.pipe(
      untilComponentDestroyed(this))
      .subscribe(createdBankAccount => {

        const lpBankAccount = this.convertLpBankAccount(createdBankAccount);
        this.bankAccounts.push(lpBankAccount);
        this.clientBankAccounts.push(createdBankAccount);
        this.pageForm.get('bankAccount').setValue(lpBankAccount);
      });
  }

  private convertLpBankAccount(ba: ClientBankAccountReqRes) {
    const bankAccount: MergeUnitClientBankAccountDto = {
      id: ba.id,
      accountType: MergeUnitClientBankAccountType.ExternalAccount,
      holderFullName: ba.holderFullName,
      accountNickname: ba.nickname,
      swift: ba.swift,
      accountNumber: ba.accountNumber,
      paymentType: ba.paymentType,
      iban: ba.iban,
      iso: ba.preferredCurrency?.iso,
      holderLocationDetails_Id: ba.holderLocationDetails_Id,
      preferredCurrencyId: ba.preferredCurrencyId,
      countryName: ba.country?.name
    } as MergeUnitClientBankAccountDto;
    return bankAccount;
  }

  bankAccountComparer(bank1: any, bank2: any) {
    if (bank1 && bank2) {
      return bank1.id === bank2.id;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.enablePaymentsViaCovercy && !!this.pageForm) {
      this.pageForm.get('paymentsViaCovercy').setValue(changes.enablePaymentsViaCovercy.currentValue);
      if(!changes.enablePaymentsViaCovercy.currentValue){
        this.disablePaymentsViaCovercyForm();
      }
      else if(!this.isViewMode && !this.disableEditMode){
        this.enablePaymentsViaCovercyForm();
      }
    }

    if (changes.distributionTransfer) {
      this.populateForm(changes.distributionTransfer.currentValue);
    }

    if (changes.bankAccounts && !changes.bankAccounts.currentValue) {
      this.bankAccounts = this.bankAccounts || [];
    }
  }

  showClientBankAccountDetails(bankAccountId: number) {
    if (!bankAccountId) {
      return;
    }
    const config = new MatDialogConfig<ClientBankAccountDetailsDialogParams>();
    const data = new ClientBankAccountDetailsDialogParams();
    data.clientBankAccountId = bankAccountId;
    config.data = data;
    config.panelClass = 'bank-account-info-dialog';

    this.dialog.open(ClientBankAccountDetailsComponent, config);
  }

  get allowEditBankAccount(): boolean{
    return this.isCreateMode || (this.isEditMode && (!this.disableEditMode || this.distributionTransfer.externalPayment));
  }

  // saving only the bank account preferred currency
  setPreferredCurrency() {
    this.isCurrencyError = false;
    const selectedCurrencyFormControl = this.pageForm.get('preferredCurrency');
    if (!selectedCurrencyFormControl.valid) {
      return;
    }

    this._savingCurrency$.next(true);
    this.gpBankAccountDataService.setPreferredCurrency(this.selectedBankAccount?.id, selectedCurrencyFormControl.value.id)
      .pipe(tap(() => {
        // Update the preferred currency in the selected bank account
        this.setCurrencyInBankAccountModel(this.distributionTransfer.clientBankAccount, selectedCurrencyFormControl.value);
        // Update the preferred currency for the bank account in the drop down list:
        const selectedBankAccountListItem = this.clientBankAccounts.find(cba => cba.id === this.distributionTransfer.clientBankAccount.id);
        if (selectedBankAccountListItem) {
          this.setCurrencyInBankAccountModel(selectedBankAccountListItem, selectedCurrencyFormControl.value);
        }
      }))
      .subscribe(() => {
          this._savingCurrency$.next(false);
        },
        error => {
          this.isCurrencyError = true;
          this._savingCurrency$.next(false);
        });
  }

  private populateForm(transfer: DistributionTransferReqRes) {
    if (this.pageForm) {
      const calculatedGross = transfer.amountNet + (transfer.taxes || 0) - (transfer.adjustments || 0);
      const clientBankAccount = this.selectedBankAccount?.accountType === MergeUnitClientBankAccountType.ExternalAccount ? this.clientBankAccounts.find(ba => ba.id === this.selectedBankAccount?.id) : null;
      this.pageForm.patchValue({
        paymentsViaCovercy: this.enablePaymentsViaCovercy ? !transfer.externalPayment : false,
        paymentSettlementDate: transfer.externalPayment ? transfer.paymentSettlementDate : null,
        bankAccount: this.selectedBankAccount,
        // For GP distribution only - Older bank account were created without the preferred currency,
        // so here we let the GP fill the missing data
        preferredCurrency: [!!clientBankAccount ? clientBankAccount.preferredCurrency : null,
          !!clientBankAccount ? Validators.required : []],
      });
      this.initialFormValue = this.pageForm.value;
      if(this.distributionStatus === DistributionStatus.Completed){
        this.pageForm.get('paymentSettlementDate').addValidators(Validators.required);
        this.pageForm.get('paymentSettlementDate').markAsTouched();
        this.pageForm.get('paymentSettlementDate').updateValueAndValidity();
      }
      if(!this.enablePaymentsViaCovercy){
        this.pageForm.get('paymentsViaCovercy').disable();
      }
    }
  }

  private setCurrencyInBankAccountModel(clientBankAccount: ClientBankAccountReqRes, currency: CurrencyModel) {
    clientBankAccount.preferredCurrencyId = currency.id;
    clientBankAccount.preferredCurrency = {...currency};
  }

  ignoreBankAccount() {
    this.pageForm.get('bankAccount').setValue(this.bankAccounts.find(b => b.id === this.distributionTransfer.previuosClientBankAccountId));
    this.pageForm.get('bankAccount').updateValueAndValidity();
  }

  approveBankAccount() {
    if (this.isCreateMode) {
      this.distributionTransfer.bankAccountDetailsApprovedByGp = true;
      return;
    }
    this.isServerError = false;
    this.isSaving$.next(true);

    this.gpDistributionDataService.ApproveBankAccountForDistributionTransfer(this.holdingId, this.distributionId, this.distributionTransfer.id)
      .subscribe(
        () => {
          // update distribution to not have IsUnsavedChanges flag:
          this.removeApproveBankAccountFlag();
          this.bankApproved.emit();
          this.isSaving$.next(false);
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            this.isServerError = true;
          }
          this.isSaving$.next(false);
        });

  }

  currencyCompareFn(c1: CurrencyModel, c2: CurrencyModel) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  private updateChangesPropertiesOnTransfer() {
    // Update the change detection flag if bank account was changes:
    if (!!this.originalBankAccountId || !!this.selectedBankAccount?.id) {
      this.distributionTransfer.isBankAccountChangeUnsaved = this.originalBankAccountId !== this.selectedBankAccount?.id;
    }
    this.distributionTransfer.isFormDirty = this.pageForm.dirty;
  }

  private removeApproveBankAccountFlag() {
    this.distributionTransfer.bankAccountDetailsApprovedByGp = true;
  }

  private disableEditDistributionViewMode() {
    // In edit mode, ViaCovercy toggle is disabled
    this.disablePaymentsViaCovercyForm();
    if(this.isViewMode || this.pageForm.get('paymentsViaCovercy').getRawValue()){
      this.pageForm.get('paymentSettlementDate').disable();
    }
    this.pageForm.get('referenceMemo').disable();
    // bank account is completely hidden when it should not be available for change. logic is in the template.
  }

  private disablePaymentsViaCovercyForm(){
    this.pageForm.get('paymentsViaCovercy').disable();
  }

  private enablePaymentsViaCovercyForm(){
    this.pageForm.get('paymentsViaCovercy').enable();
  }

  private handleEmitSignalToUpdateFeesCalculationWhenNeeded() {
    // Emit this event to signal that something that affects the fees calculation has changed.
    this.pageForm.get('paymentsViaCovercy').valueChanges.pipe(
      untilComponentDestroyed(this)
    ).subscribe(() => {
      this.amountChange.emit();
    });
  }

  /** When one of the transfer values is changed, the transfer in the input should also reflect the change. */
  private reflectFormValuesOnInputTransfer() {
    this.pageForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        const formRawValues = this.pageForm.getRawValue();
        this.distributionTransfer.externalPayment = !formRawValues.paymentsViaCovercy;
        this.distributionTransfer.paymentSettlementDate = formRawValues.paymentSettlementDate;
        this.distributionTransfer.clientBankAccountId = formRawValues.bankAccount && formRawValues.bankAccount.accountType === MergeUnitClientBankAccountType.ExternalAccount ? formRawValues.bankAccount.id : null;
        this.distributionTransfer.unitBankAccountId = formRawValues.bankAccount && formRawValues.bankAccount.accountType === MergeUnitClientBankAccountType.CovercyAccount ? formRawValues.bankAccount.id : null;
        this.distributionTransfer.referenceMemo = formRawValues.referenceMemo;
        this.selectedBankAccount = formRawValues.bankAccount || null;
        if (this.selectedBankAccount && this.selectedBankAccount.id !== this.distributionTransfer.clientBankAccount?.id && this.selectedBankAccount.id !== this.distributionTransfer.unitBankAccount?.id) {
          this.distributionTransfer.clientBankAccount = this.selectedBankAccount && this.selectedBankAccount?.accountType === MergeUnitClientBankAccountType.ExternalAccount ? this.clientBankAccounts.find(b => b.id === this.selectedBankAccount?.id) : null;
          this.distributionTransfer.unitBankAccount = this.selectedBankAccount && this.selectedBankAccount?.accountType === MergeUnitClientBankAccountType.CovercyAccount ? this.unitBankAccounts.find(b => b.id === this.selectedBankAccount?.id) : null;
        }
        else if(!this.selectedBankAccount){
          this.distributionTransfer.clientBankAccount = null;
          this.distributionTransfer.unitBankAccount = null;
        }
        // Update the change detection flag if bank account was changes:
        this.updateChangesPropertiesOnTransfer();
      });
  }

  private clearSelectedBankAccountIfInvalid(transfer: DistributionTransferReqRes) {
    if (transfer.clientBankAccount && !transfer.clientBankAccount.holderLocationDetails) {
      transfer.clientBankAccount = null;
      transfer.clientBankAccountId = null;
    }
  }

  getBankAccountDetails(bankAccount: MergeUnitClientBankAccountDto): string {

    let details = '';
    if (!bankAccount)
      return details;

    var bankAccountPrototype = Object.getPrototypeOf(bankAccount);
    // var convertedBankAccount;

    if (bankAccount.accountType === MergeUnitClientBankAccountType.ExternalAccount) {
      if (bankAccount.accountNumber) {
        details = details.concat('Account number that ends with: ****' + bankAccount.accountNumber.slice(-4));
      }
      if (bankAccount.iban) {
        details = details.concat('IBAN: ****' + bankAccount.iban.slice(-4));
      }
      if (bankAccount.holderFullName) {
        details = details.concat('\nOn the name of: ' + bankAccount.holderFullName);
      }
      if (bankAccount.preferredCurrency && bankAccount.preferredCurrency.iso) {
        details = details.concat('\nDestination currency: ' + bankAccount.preferredCurrency.iso);
      } else {
        details = details.concat('\nDestination currency: MISSING');
      }

      details = details.concat('\nDestination country: ' + bankAccount.countryName);
    } else {
      if (bankAccount.accountNumber) {
        details = details.concat('Account number that ends with: ****' + bankAccount.accountNumber.slice(-4));
      }
      if (bankAccount.accountNickname) {
        details = details.concat('\nAccount name: ' + bankAccount.accountNickname);
      }
      if (bankAccount.preferredCurrency && bankAccount.preferredCurrency.iso) {
        details = details.concat('\nDestination currency: ' + bankAccount.preferredCurrency.iso);
      } else {
        details = details.concat('\nDestination currency: USD');
      }
    }
    if(!!bankAccount.paymentType){
      details = details.concat('\nPayment method: ' + PaymentType.toString(bankAccount.paymentType));
    }
    return details;
  }

  /**
   * Get transfer details at the specified index of transfer details array
   * @param index The index of the transfer details
   */
  getTransferDetails(index: number): DistributionTransferDetailsReqRes {
    if (this.distributionPurposes.length > 1 && // meaning it's multi-type
      this.distributionTransfer.distributionTransfersDetails.length > index &&
      this.distributionTransfer.distributionTransfersDetails[index].reasonForTransaction === DistributionTransactionPurpose.None
    ) {
      this.distributionTransfer.distributionTransfersDetails[index].reasonForTransaction = this.distributionPurposes[index];
    } else if (this.distributionTransfer.distributionTransfersDetails.length <= index ||
      this.distributionTransfer.distributionTransfersDetails[index].reasonForTransaction !== this.distributionPurposes[index]) {
      const newDistributionTransferDetails = new DistributionTransferDetailsReqRes();
      newDistributionTransferDetails.reasonForTransaction = this.distributionPurposes[index];
      this.distributionTransfer.distributionTransfersDetails.splice(index, 0, newDistributionTransferDetails);
    }

    return this.distributionTransfer.distributionTransfersDetails[index];
  }

  /**
   * Subscribe to event emitted by DistributionTransferAmounts component
   */
  private subscribeToTransferDetailsAmountsChanges() {
    this.transferDetailsAmountsChanged$.pipe(
      untilComponentDestroyed(this)
    ).subscribe(_ => {
      this.amountChange.emit();
    });
  }

  private subscribeToTransferDetailsPaymentDateChanges() {
    const paymentSettlementDateControle = this.pageForm.get('paymentSettlementDate');
    this.updatePaymentDateFormControl?.pipe(
      untilComponentDestroyed(this)
    ).subscribe(_ => {
      paymentSettlementDateControle.setValue(this.distributionTransfer.paymentSettlementDate);
      paymentSettlementDateControle.updateValueAndValidity();
    });
  }
}
