import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
  ViewContainerRef,
} from "@angular/core";
import {
  untilComponentDestroyed,
  OnDestroyMixin,
} from "@w11k/ngx-componentdestroyed";
import { map, switchMap, tap } from "rxjs/operators";
import { GpHoldingService } from "../gp-holding.service";
import { UserService } from "../../../../services/shared/user.service";
import FeatureFlags from "../../../../account/my-account/model/FeatureFlags.enum";
import { combineLatest } from "rxjs";
import FundraisingStatus from "src/app/shared/enums/FundraisingStatus.enum";
import { InvestorsManagementService } from "../Investors-management/investors-management.service";
import OwnershipCalculationType from "src/app/shared/enums/OwnershipCalculationType.enum";

@Component({
  selector: "terra-under-management-information",
  templateUrl: "./under-management-information.component.html",
  styleUrls: ["./under-management-information.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnderManagementInformationComponent
  extends OnDestroyMixin
  implements OnInit
{
  @HostBinding("class.three-columns") displayCreCard: boolean;
  @HostBinding("class.three-columns") displayBankPlaceholder: boolean;

  holding$ = this.holdingService.holding$;
  totalContributions$ = this.holdingService.fundraisings$.pipe(
    map((fundraisings) =>
      fundraisings.reduce(
        (acc, val) =>
          (acc +=
            val.status === FundraisingStatus.Completed
              ? val.totalInvestedAmount
              : 0),
        0
      )
    )
  );

  calculationType$ = this.holding$.pipe(map((holding) => holding.ownershipCalculationType));
  totalCommitments$ = this.holding$.pipe(switchMap((holding) => this.investorsManagementService.getTotalCommitmentsForHolding(holding.id)));

  shouldShowContributionWarningMessage$ = combineLatest([
    this.calculationType$,
    this.holding$,
    this.totalContributions$,
  ]).pipe(
    map(
      ([calculationType, holding, sumContributions]) =>
        calculationType == OwnershipCalculationType.ByContributions && Math.abs(holding.totalInvestorEquity - sumContributions) > 0.0001
    )
  );

  shouldShowCommitmentWarningMessage$ = combineLatest([
    this.calculationType$,
    this.holding$,
    this.totalCommitments$,
  ]).pipe(
    map(
      ([calculationType, holding, sumCommitments]) =>
        calculationType == OwnershipCalculationType.ByCommitments && Math.abs(holding.totalInvestorEquity - sumCommitments) > 0.0001
    )
  );

  hasCreFeatureFlag$ = this.userService.userHasFeatureFlag(
    FeatureFlags.CreBanking
  );

  constructor(
    private _vcr: ViewContainerRef,
    private holdingService: GpHoldingService,
    private userService: UserService,
    private investorsManagementService: InvestorsManagementService
  ) {
    super();
  }

  ngOnInit(): void {
    this.hasCreFeatureFlag$
      .pipe(
        untilComponentDestroyed(this),
        map((hasCreFeatureFlag) => {
          this.displayCreCard = hasCreFeatureFlag;
          this.displayBankPlaceholder = !this.displayCreCard;
        })
      )
      .subscribe();
  }

  goToEditHoldingInvestmentDetails() {
    this.holdingService.showEditHolding(this._vcr, 2);
  }
}
