import {Component, OnInit, ChangeDetectionStrategy, Input, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {MatTabGroup} from '@angular/material/tabs';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {map} from 'rxjs/operators';

import {HoldingFundraisingService} from '../holding-fundraising.service';
import InvestmentStatus from '../../../../../shared/enums/InvestmentStatus.enum';

@Component({
  selector: 'terra-fundraising-nav',
  templateUrl: './fundraising-nav.component.html',
  styleUrls: ['./fundraising-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundraisingNavComponent extends OnDestroyMixin implements OnInit {
  @Input() isReadOnlyContribution$: Observable<boolean>;
  @Input() isCompletedDistribution = false;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  constructor(private fundraisingService: HoldingFundraisingService) {
    super();
  }

  ngOnInit(): void {
  }

  get numberOfInvestorsReadyForCapitalCall$(): Observable<number> {
    return this.fundraisingService.fundraising$.pipe(
      untilComponentDestroyed(this),
      map(fundraising => fundraising.investments.filter(inv => inv.status === InvestmentStatus.Signed && !inv.paymentRequestSendDate).length)
    );
  }

  public navigateToAllInvestors() {
    this.tabGroup.selectedIndex = 0;
  }
}
