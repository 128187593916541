import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {map, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';

import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import {EditFundAssetStepBase, EditFundAssetTabNumber} from '../EditFundAssetStepBaseAndInterface';
import InvestmentType from 'src/app/shared/enums/InvestmentType.enum';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ResourceService} from 'src/app/services/resource.service';
import {EditFundAssetService} from '../edit-fund-asset.service';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {AddressFields, AddressFormSettings} from 'src/app/shared/components/address-form/address-form.settings';

@Component({
  selector: 'terra-edit-fund-asset-tab-asset-information',
  templateUrl: './edit-fund-asset-tab-asset-information.component.html',
  styleUrls: ['./edit-fund-asset-tab-asset-information.component.scss']
})
export class EditFundAssetTabAssetInformationComponent extends EditFundAssetStepBase implements OnInit {
  readonly TAB_NUMBER: EditFundAssetTabNumber = 1;

  showInternalLoader = false;

  // enum
  HoldingType = HoldingType;
  InvestmentType = InvestmentType;
  AreaUnit = AreaUnit;
  InvestmentSecurityType = InvestmentSecurityType;


  // Enum value lists
  assetTypesList = HoldingType.listAll();
  InvestmentTypesList = InvestmentType.listAll();
  InvestmentSecurityTypesList = InvestmentSecurityType.optionsForAssetFundraising();


  /// The date that tha picker will open to
  estimatedClosingDateStartAt = new Date();
  estimatedClosingDateMinimumDate = new Date('1970-01-01T12:00');

  currencies$: Observable<CurrencyModel[]> = this.resourceService.getOutboundCurrencies().pipe(
    map(response => response.filter(c => !c.isInbound)),
    shareReplay()
  );



  /*
  get selectedCurrency() {
    return this.editFundAssetService.selectedCurrency;
  }*/

  // bankAccounts$: Observable<ClientBankAccountResponseBasic[]>;

  allCountries = this.resourceService.allCountries;
  allStates = this.resourceService.allStates;
  countryCodes = TerraUtils.consts.countryCode;
  USCountryId = this.resourceService.getCountryByCode(this.countryCodes.US).id;


  allowedDataPointsListFor = this.editFundAssetService.allowedDataPointsListFor;
  addressFormSettings = new AddressFormSettings(undefined, AddressFields.Country);
  location = this.editFundAssetService.editFundAssetDialogContext.asset.location;

  isDisplayDataPoint(assetTypeList: HoldingType[]): boolean {
    if (!this.pageForm.get('holdingType').value) {
      return false;
    }
    const holdingType = Number(this.pageForm.get('holdingType').value) as HoldingType;
    return assetTypeList.some(val => val === holdingType);
  }

  get addressForm() {
    return this.pageForm.get('assetLocation') as UntypedFormGroup;
  }

  constructor(
    private resourceService: ResourceService,
    private editFundAssetService: EditFundAssetService
  ) {
    super(editFundAssetService);
  }

  ngOnInit() {
    if(this.isExample){
      this.pageForm.get('name').disable();
      this.pageForm.get('legalName').disable();
    }
  }

  conutryTrackByFn(index, item: CountryModel) {
    return item.id; // or index
  }

  currencyCompareFn(c1: CurrencyModel, c2: CurrencyModel) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
