import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {shareReplay, switchMap, take} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {TerraUtils} from '../../../../../../../shared/TerraUtils';
import {GpInvestmentDataService} from '../../../../../../../services/gp/gp-investment-data.service';
import {InvestmentReqRes} from '../../../../../../models/investment.model';
import {GpHoldingService} from '../../../../gp-holding.service';
import {GpFundraisingDataService} from '../../../../../../../services/gp/gp-fundraising-data.service';
import {OfferingDeckEngagementResponse} from '../../../../../../../shared/models/OfferingDeckEngagementResponse.model';
import {FundraisingReqRes} from "../../../fundraisings-tab/FundraisingReqRes.model";

enum EngagementStatus {
  Viewed= 0,
  NotViewed = 1
}

@Component({
  selector: 'terra-engagement-info',
  templateUrl: './engagement-info.component.html',
  styleUrls: ['./engagement-info.component.scss']
})
export class EngagementInfoComponent extends OnDestroyMixin implements OnInit {
  fundraising$ = this.holdingService.fundraising$;
  holdingId$ = this.holdingService.holdingId$;
  engagement$ = combineLatest([this.holdingId$, this.fundraising$]).pipe(
    untilComponentDestroyed(this),
    switchMap(([holdingId, fundraising]) => {
      return this.fundraisingDataService.getOfferingDeckEngagementData(holdingId, fundraising.id);
    }),
    shareReplay(1)
  );

  isLoading$ = new BehaviorSubject<boolean>(false);

  chartData = null;
  chartOptions = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          font: {
            size: 14,
            weight: 'bold'
          },
          generateLabels: (chart) => {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
                return data.labels.map(function(label, i) {
                    const meta = chart.getDatasetMeta(0);
                    const ds = data.datasets[0];
                    const fill = ds.backgroundColor[i];
                    var arcOpts = chart.options.elements.arc;
                    var stroke = ds.borderColor ?? arcOpts.borderColor;
                    var bw = ds.borderWidth ?? arcOpts.borderWidth;

      // We get the value of the current label
      const value = chart.config.data.datasets[0].data[i];

                    return {
                        // Instead of `text: label,`
                        // We add the value to the string
                        text: `${label} : ${value}%`,
                        fillStyle: fill,
                        strokeStyle: stroke,
                        lineWidth: bw,
                        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                        index: i
                    };
                });
            } else {
                return [];
            }
        }
        },
        fullSize: false
      },
      tooltip: {
        padding: 16,
        bodyFont: {
          size: 14
        },
        bodyColor: 'rgb(0,0,0)',
        titleColor: 'rgb(0,0,0)',
        cornerRadius: 0,
        backgroundColor: 'rgba(255,255,255,0.9)',
        borderWidth: 1,
        borderColor: '#DCDCDC',
        callbacks: {
          title: (context) => "",
          label: (context) => {
            return `${context.label} : ${context.formattedValue}%`;
          }
        }
      }
    },
    cutout: 70,
    responsive: true
  };

  constructor(
    private fundraisingDataService: GpFundraisingDataService,
    private holdingService: GpHoldingService
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading$.next(true);
    this.engagement$.pipe(take(1)).subscribe(engagement => {
      this.setChartData(engagement);
      this.isLoading$.next(false);
    });
  }

  setChartData(engagement: OfferingDeckEngagementResponse) {
    this.chartData = {
      labels: ['Replied', 'Viewed', 'Not viewed'],
      showLabels: false,
      datasets: [
        {
          data: [
            this.getPercentage(engagement.replies, engagement.sent + engagement.opportunityLinkViews),
            this.getPercentage(engagement.views + engagement.opportunityLinkViews - engagement.replies, engagement.sent + engagement.opportunityLinkViews),
            this.getPercentage(engagement.sent - engagement.views, engagement.sent + engagement.opportunityLinkViews)
          ],
          backgroundColor: ['#3D41EB', '#C7C8F6', '#BEC5C9']
        }
      ]
    };
  }

  getPercentage(value: number, total: number): number {
    return (100 * value) / total;
  }
}
