import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { distinctUntilChanged, take } from 'rxjs/operators';

import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';
import { GpDistributionDataService } from '../../../../../../services/gp/gp-distribution-data.service';
import { UserService } from '../../../../../../services/shared/user.service';
import { SnackbarService } from '../../../../../../services/snackbar.service';
import { UtilsService } from '../../../../../../services/utils.service';
import { TerraUtils } from '../../../../../../shared/TerraUtils';
import HTTP_STATUS_CODES from '../../../../../../shared/enums/HttpStatusCodesEnum';
import { ErrorMatcher, ErrorType } from '../../../../../../shared/errors/ErrorMatcher';
import { BaseResponseDto } from '../../../../../../shared/models/BaseResponseDto.model';
import { DistributionsImportResponse } from '../../../../../../shared/models/DistributionsImportResponse.model';
import { DistributionDetailsItemReqRes } from '../distribution-details/DistributionDetailsItemReqRes';

@Component({
  selector: 'terra-import-distributions-dialog',
  templateUrl: './import-distributions-dialog.component.html',
  styleUrls: ['./import-distributions-dialog.component.scss']
})
export class ImportDistributionsDialogComponent extends OnDestroyMixin implements OnInit {
  supportedFileExtensions = TerraUtils.consts.supportedFileExtensions.CREATE_DISTRIBUTION_IMPORT;
  isGeneralServerError = false;
  generalServerErrorMessage: string;
  fullErrorListMessage: string;
  isSubmitted = false;
  showMoreLines = false;
  hasMoreErrorLines = false;
  errorListStatus = 'more';
  private distributions: DistributionDetailsItemReqRes[];
  private holdingId: any;
  private loadingCounter = 0;

  pageForm = this.fb.group({
    file: [null, Validators.required],
  });

  get distributionTitle(): string {
    return 'distribution' + (this.distributions.length > 1 ? 's' : '');
  }

  get loadingPage(): boolean {
    return this.loadingCounter !== 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private gpDistributionDataService: GpDistributionDataService,
    private dialogRef: MatDialogRef<ImportDistributionsDialogComponent>,
    private utilsService: UtilsService,
    private fb: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private telemetryService: TelemetryService
  ) {
    super();
    this.distributions = dialogData.distributions;
    this.holdingId = dialogData.holdingId;
    dialogRef.addPanelClass('import-distributions-dialog');
  }

  ngOnInit() {
    this.handleFileInputValueChange();
  }

  downloadTemplate() {
    this.loadingCounter++;
    this.gpDistributionDataService.getMultiDistributionsTemplate(this.holdingId, this.distributions)
      .subscribe(fileUrl => {
        if (fileUrl) {
          this.utilsService.downloadFileFromUrl(fileUrl);
        }
        this.loadingCounter--;
      });
  }

  private handleFileInputValueChange() {
    this.pageForm.get('file').valueChanges.pipe(untilComponentDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        if (value == null) {
          this.clearErrors();
        }
      });
  }

  private clearErrors() {
    this.isGeneralServerError = false;
    this.generalServerErrorMessage = null;
    this.fullErrorListMessage = null;
    this.hasMoreErrorLines = false;
    this.showMoreLines = false;
    this.errorListStatus = 'more';
  }

  import() {
    this.isSubmitted = true;
    this.isGeneralServerError = false;
    this.clearErrors();
    if (this.pageForm.valid) {
      this.loadingCounter++;
      const metaFileLinkId = this.pageForm.value.file.id;
      this.gpDistributionDataService.getMultiDistributionsImportFromExcelFile(this.dialogData.holdingId, metaFileLinkId)
        .subscribe(
          result => {
            if (result.isSuccess) {
              const pluralDistributions = result.distributionsCount > 1 ? 's' : '';
              const pluralTransfers = result.validTransfersCount > 1 ? 's' : '';
              let finalMessage = `${result.distributionsCount} distribution${pluralDistributions} ${pluralDistributions ? 'were' : 'was'} imported successfully`;
              finalMessage += `\n${result.validTransfersCount} transfer${pluralTransfers} in total`;

              this.snackbarService.showGeneralMessage(finalMessage, 5, 'flow-import-distributions-success');
              this.importDistributionSendTelemetryEvent();
              this.dialogRef.close(result);
            } else {
              this.generalServerErrorMessage = this.generateImportErrorMessage(result, 3);
              this.fullErrorListMessage = this.generateImportErrorMessage(result);
              this.isGeneralServerError = true;
              this.loadingCounter--;
            }
          },
          error => {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            } else if (ErrorMatcher.isError(error, ErrorType.BadFileFormatException) ||
              ErrorMatcher.isError(error, ErrorType.ValueNotSupportedException)
            ) {
              this.generalServerErrorMessage = 'Failed importing distribution. Please make sure the file type is .xlsx and that the content is formatted correctly';
            } else if (error.status === HTTP_STATUS_CODES.NOT_ACCEPTABLE) {
              this.generalServerErrorMessage = 'Failed importing distribution. make sure the data is valid.';
            } else {
              this.generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;
            }
            this.isGeneralServerError = true;
            this.loadingCounter--;
          }
        );
    }
  }

  private generateImportErrorMessage(importResult: DistributionsImportResponse, rowLimit: number = null): string {
    let errorMessage = `Failed to import distributions.`;
    if (!importResult.invalidRows.length) {
      return errorMessage;
    }

    errorMessage += ` ${(importResult.invalidRows && 'Found following issue(s):')}`;
    let counter = 1;
    importResult.invalidRows.forEach(row => {
      const rowNumber = row.row;
      row.errors.forEach(error => {
        if (rowLimit && counter > rowLimit) {
          this.hasMoreErrorLines = true;
          return errorMessage;
        }
        counter++;
        let cellRef = `in ` + (error.columnRef ? `cell '${error.columnRef}` : `row '`);
        cellRef += `${rowNumber}'.`;
        const cellIndex = !rowNumber ? '. ' : ' ' + cellRef;
        const errorNumber = `\n`;
        const lineStart = `${errorNumber} Parsing failure${cellIndex}`;

        if (error.corruptedFile) {
          errorMessage += `\n${error.errorMessage}`;
        } else {
          errorMessage += `${lineStart} ${error.errorMessage || ' Valid entry is required.'}`;
        }
      });
    });

    return errorMessage;
  }

  toggleFullErrorList() {
    this.showMoreLines = !this.showMoreLines;
    this.isGeneralServerError = !this.isGeneralServerError;
    this.errorListStatus = this.isGeneralServerError && this.hasMoreErrorLines ? 'more' : 'less';
  }

  private importDistributionSendTelemetryEvent() {
    this.userService.accountDetails$.pipe(take(1)).subscribe(clientDetails => {
      this.telemetryService.create({
        eventID: '108',
        eventTitle: 'DISTRIBUTION - IMPORT (SEND)',
        holdingID: this.holdingId,
        organizationID: clientDetails.organizationDetails.id
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });
  }
}
