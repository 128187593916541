<form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" (ngSubmit)="stepSubmit()"
  class="form-grid-new container-750 terra-error-style">
  <!-- Asset photos -->
  <h3 class="section-title">
    Upload photos (optional, highly recommended)
  </h3>

  <ng-container formArrayName="assetPhotos">
    <div class="photo-uploaders-row full-width">
      <terra-image-uploader-thumbnail formControlName="0" [isSubmitted]="isSubmitted"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="1" [isSubmitted]="isSubmitted"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="2" [isSubmitted]="isSubmitted"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="3" [isSubmitted]="isSubmitted"></terra-image-uploader-thumbnail>
    </div>

    <a class="add-more-photos-link full-width" (click)="showSecondPhotosRow = true" *ngIf="!showSecondPhotosRow">
      <mat-icon class="notranslate" color="primary">add_circle</mat-icon>
      Add more photos
    </a>

    <div class="photo-uploaders-row second-row full-width" *ngIf="showSecondPhotosRow">
      <terra-image-uploader-thumbnail formControlName="4" [isSubmitted]="isSubmitted"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="5" [isSubmitted]="isSubmitted"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="6" [isSubmitted]="isSubmitted"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="7" [isSubmitted]="isSubmitted"></terra-image-uploader-thumbnail>
    </div>
    <p class="error-message full-width" *ngIf="pageForm.get('assetPhotos').invalid">The images marked in red are of an
      unsupported file
      type.
    </p>
    <p class="file-uploader-hint new-column">
      File format must be JPG, JPEG or PNG (20 MB max file size)
    </p>
  </ng-container>

  <!-- Asset down mode -->
  <h3 class="section-title">Asset down mode</h3>
  <terra-holding-is-down-card [holdingDiscriminator]="HoldingDiscriminator.Asset"
    [isPrivate]="pageForm.get('isPrivate').value" class="full-width">
    <mat-slide-toggle hideIcon labelPosition="before" formControlName="isPrivate"></mat-slide-toggle>
  </terra-holding-is-down-card>
</form>

<section class="footer">
  <div class="container-750">
    <!-- Error message -->
    <div *ngIf="isSubmitAttempt" class="error-section">
      <p *ngIf="pageForm.invalid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
    <div class="form-actions">
      <a (click)="back()" class="cancel-button">Back</a>
      <button type="submit" form="stepForm" mat-flat-button color="primary" class="page-cta">
        Next
      </button>
    </div>
  </div>
</section>
