<form [formGroup]="pageForm" (keydown.enter)="$event.preventDefault()">

  <!-- Amount to distribute -->
  <div class="terra-form-field-wrapper full-width distribution-inputs">
    <div class="input-controls">
      <label for="txtAmountGross_{{transactionPurpose}}_{{investingEntityId}}" class="text-input-label">
        Gross distribution
      </label>
        <mat-form-field appearance="outline" [ngClass]="{'readonly': isThisGPDistributeTransfer, 'remove-error-place': isThisGPDistributeTransfer}">
          <input type="text" matInput id="txtAmountGross_{{transactionPurpose}}_{{investingEntityId}}" terraFormattedNumber formControlName="amountGross"
                 autocomplete="amountGross" class="lo_sensitive" [readonly]="isThisGPDistributeTransfer"/>
          <span matSuffix>{{distributionCurrencyIso}}</span>
        </mat-form-field>
        <div *ngIf="isThisGPDistributeTransfer" class="info-message">Sum of all the above investor promote values</div>
    </div>
  </div>

  <!-- Withheld taxes -->
  <div class="terra-form-field-wrapper full-width distribution-inputs">
    <div class="input-controls">
      <label for="txtTaxes_{{transactionPurpose}}_{{investingEntityId}}" class="text-input-label">
        Withheld taxes
      </label>
      <div [ngClass]="!isViewMode && !disableEditMode || (disableEditMode && distributionTransfer.externalPayment) ?'taxes': 'full-width'">
        <mat-form-field appearance="outline" class="taxes-input remove-error-place">
          <input type="text" matInput id="txtTaxes_{{transactionPurpose}}_{{investingEntityId}}" terraFormattedNumber formControlName="taxes" autocomplete="taxes"
                 class="lo_sensitive"/>
          <span matSuffix>{{isPercents && '%' || distributionCurrencyIso}}</span>
        </mat-form-field>
        <div *ngIf="!isViewMode && !disableEditMode || (disableEditMode && distributionTransfer.externalPayment)" class="taxes-type-section">
          <button type="button" mat-stroked-button [ngClass]="{ 'taxes-type-inactive': isPercents }"
                  (click)="switchTaxPercents(true)" class="percent-icon">
            <span>%</span>
          </button>
          <button type="button" mat-stroked-button [ngClass]="{ 'taxes-type-inactive': !isPercents }"
                  (click)="switchTaxPercents(false)" class="currency-icon">
            <span class="">{{distributionCurrencyIso}}</span>
          </button>
          <label class="taxes-result"
                 for="txtTaxes_{{transactionPurpose}}_{{investingEntityId}}">({{isPercents ? distributionCurrencySymbol : ''}}{{taxesSummary || 0 | terraNumber }}{{!isPercents ? '%' : ''}}
            )</label>
        </div>
      </div>
    </div>

    <!-- After taxes -->
    <div class="input-controls">
      <label for="txtAmountAfterTaxes_{{transactionPurpose}}_{{investingEntityId}}" class="text-input-label">
        After taxes
      </label>
      <mat-form-field appearance="outline" class="readonly">
        <input type="text" matInput id="txtAmountAfterTaxes_{{transactionPurpose}}_{{investingEntityId}}" terraFormattedNumber formControlName="amountAfterTaxes"
               autocomplete="amountAfterTaxes" class="lo_sensitive" readonly/>
        <span matSuffix>{{distributionCurrencyIso}}</span>
      </mat-form-field>
    </div>
  </div>

  <!-- Gp Promote -->
  <div class="terra-form-field-wrapper full-width distribution-inputs" *ngIf="!isThisGPDistributeTransfer">
    <div class="input-controls">
      <label for="txtPromote_{{transactionPurpose}}_{{investingEntityId}}" class="text-input-label">
        GP Promote
      </label>
      <div>
        <mat-form-field appearance="outline" class="promote-input remove-error-place">
          <input type="text" matInput id="txtPromote_{{transactionPurpose}}_{{investingEntityId}}" terraFormattedNumber formControlName="gpPromote" autocomplete="gpPromote"
                 class="lo_sensitive"/>
          <span matSuffix>{{distributionCurrencyIso}}</span>
        </mat-form-field>
      </div>
    </div>

    <!-- After promote -->
    <div class="input-controls">
      <label for="txtAmountAfterPromote_{{transactionPurpose}}_{{investingEntityId}}" class="text-input-label">
        After GP promote
      </label>
      <mat-form-field appearance="outline" class="readonly">
        <input type="text" matInput id="txtAmountAfterPromote_{{transactionPurpose}}_{{investingEntityId}}" terraFormattedNumber formControlName="amountAfterPromote"
               autocomplete="amountAfterPromote" class="lo_sensitive" readonly/>
        <span matSuffix>{{distributionCurrencyIso}}</span>
      </mat-form-field>
    </div>
  </div>

  <!-- Adjustments -->
  <div class="terra-form-field-wrapper full-width distribution-inputs">
    <div class="input-controls">
      <label for="txtAdjustments_{{transactionPurpose}}_{{investingEntityId}}" class="text-input-label">
        Adjustments
      </label>
      <mat-form-field appearance="outline" class="remove-error-place">
        <input type="text" matInput id="txtAdjustments_{{transactionPurpose}}_{{investingEntityId}}" terraFormattedNumber formControlName="adjustments"
               autocomplete="adjustments" class="lo_sensitive"/>
        <span matSuffix>{{distributionCurrencyIso}}</span>
      </mat-form-field>
    </div>

    <!-- Net distribution -->
    <div class="input-controls">
      <label for="txtAmountNet_{{transactionPurpose}}_{{investingEntityId}}" class="text-input-label remove-error-place">
        Net distribution
      </label>
      <mat-form-field appearance="outline" class="readonly">
        <input type="text" matInput id="txtAmountNet_{{transactionPurpose}}_{{investingEntityId}}" terraFormattedNumber formControlName="amountNet"
               autocomplete="amountNet" class="lo_sensitive" readonly/>
        <span matSuffix>{{distributionCurrencyIso}}</span>
      </mat-form-field>
    </div>
  </div>

  <!-- Adjustments comment -->
  <div class="terra-form-field-wrapper full-width"
       *ngIf="(distributionTransferDetails.adjustments && !pageForm.get('adjustments').disabled) || distributionTransferDetails.adjustmentsComments">
    <label for="txtAdjustmentsComments_{{transactionPurpose}}_{{investingEntityId}}" class="text-input-label">Adjustments comment</label>
    <mat-form-field appearance="outline" class="full-width remove-error-place">
      <textarea matInput rows="2" formControlName="adjustmentsComments" class="txtAdjustmentsComments" id="txtAdjustmentsComments_{{transactionPurpose}}_{{investingEntityId}}"
                placeholder="{{pageForm.get('adjustments').disabled ? '' : 'Add adjustment comment here (Shift + Enter for a new line)'}}">
      </textarea>
    </mat-form-field>
    <mat-error *ngIf="pageForm.get('adjustmentsComments').hasError('maxlength')"> You have exceeded the maximum length
      of {{pageForm.get('adjustmentsComments').getError('maxlength').requiredLength | terraNumber}} characters
    </mat-error>
  </div>

</form>
