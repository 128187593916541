import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject, debounceTime, take } from 'rxjs';
import InvestingEntityType from 'src/app/dashboard/models/InvestingEntityType.enum';
import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { UserService } from 'src/app/services/shared/user.service';
import { StorageObjectReqRes } from 'src/app/shared/models/StorageObjectReqRes.model';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';

@Component({
  selector: 'terra-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // standalone
})
export class MatchComponent implements OnChanges, OnInit {
  @Input() list = [];
  @Input() holdingId: number;
  @Input() update: Subject<boolean>;
  @Input() investors: Array<{
    id: string;
    name: string;
    email: string;
    investingEntityType: InvestingEntityType;
  }> = [];
  @Output() matchList = new EventEmitter();
  @Output() edited = new EventEmitter();

  constructor(
    private readonly telemetryService: TelemetryService,
    private readonly userService: UserService,
    private storageObjectDataService: StorageObjectDataService,
    private cd: ChangeDetectorRef
  ) {}

  displayedColumns: string[] = ['displayName', 'status', 'sharedWith'];
  dataSource = [];
  suggestionsSource = [];
  unmatched = 0;
  automatched = true;
  InvestingEntityType = InvestingEntityType;

  ngOnInit(): void {
    this.update
      .pipe(debounceTime(500))
      .subscribe(() => this.updateSuggestions());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list && changes.list?.currentValue?.length) {
      this.updateSuggestions();
    }
  }

  private buildDataSource(
    files: StorageObjectReqRes[],
    suggestions = []
  ): void {
    const dataSource = [
      ...files
        .map((file) => {
          const suggestion = suggestions.find(
            (s) => s.storageObjectId === file.id
          );
          const investor = this.investors.find(
            (i) => i.id === suggestion?.investingEntityId
          );
          return {
            file,
            sharedWith: investor,
            initialSharedWith: investor?.id,
            createTimestamp: file.createTimestamp,
            id: file.id,
          };
        })
        .sort((a, b) => (a.sharedWith ? 1 : 0) - (b.sharedWith ? 1 : 0)), // sort by not shared first
    ];

    this.userService
      .getClientDetails()
      .pipe(take(1))
      .subscribe((clientDetails) => {
        this.telemetryService.create(
          {
            eventID: '4007',
            eventTitle: 'Holding-Upload-and-Share-Match-Stats',
            holdingID: this.holdingId,
            organizationID: clientDetails.organizationDetails.id,
            additional: {
              numberOfFiles: files.length,
              numberOfMatches: suggestions.length,
              matchPrecent: (suggestions.length / files.length) * 100,
            },
          },
          AnalyticsServiceNameModel.Mixpanel |
            AnalyticsServiceNameModel.Insights
        );
      });

    this.suggestionsSource = JSON.parse(JSON.stringify(dataSource)); // TODO: use "structuredClone" after UPDATE
    this.automatched = this.suggestionsSource.every((file) => file.sharedWith);
    this.dataSource = dataSource;
    this.updateUnmatched();
    this.matchList.emit(dataSource);
    this.edited.emit(false);
  }

  public notify() {
    if (
      JSON.stringify(this.dataSource) !== JSON.stringify(this.suggestionsSource)
    ) {
      this.edited.emit(true);
    } else {
      this.edited.emit(false);
    }

    this.updateUnmatched();
    this.matchList.emit(this.dataSource);
  }

  private updateUnmatched() {
    this.unmatched = this.dataSource.filter((file) => !file.sharedWith).length;
  }

  private updateSuggestions() {
    if (!this.list.length) {
      this.buildDataSource([]);
      return;
    }
    const ids = this.list.map((file) => file.id);

    this.userService
      .getClientDetails()
      .pipe(take(1))
      .subscribe((clientDetails) => {
        this.telemetryService.create(
          {
            eventID: '4005',
            eventTitle: 'Holding-Upload-and-Share-Match-Screen',
            holdingID: this.holdingId,
            organizationID: clientDetails.organizationDetails.id,
            additional: {
              numberOfFiles: this.list.length,
            },
          },
          AnalyticsServiceNameModel.Mixpanel |
            AnalyticsServiceNameModel.Insights
        );
      });

    this.storageObjectDataService
      .getShareSuggestions(this.holdingId, ids)
      .subscribe((suggestions) => {
        this.buildDataSource(this.list, suggestions?.matches);
      });
  }
}
