<terra-dialog-header>
  <h1>Update status</h1>
</terra-dialog-header>

<mat-dialog-content>
  <mat-radio-group [formControl]="updatedStatus" class="radio-group">

    <mat-radio-button color="primary" [value]="HoldingStatus.Draft" *ngIf="holding.status === HoldingStatus.Draft">
      <div class="status-line">
        <span class="status-name">{{ HoldingStatus.Draft | enumString: HoldingStatus }}</span>
        <span class="description">Importing historical data for the {{holdingDiscriminator}}</span>
      </div>
    </mat-radio-button>

    <mat-radio-button color="primary" [value]="HoldingStatus.Fundraising"
                      *ngIf="holding.status !== HoldingStatus.Draft"
                      [disabled]="holding.status === HoldingStatus.Owned || HoldingStatus.Realized ||
        !context.fundraising || (holding.status === HoldingStatus.Canceled && canSwitchToUnderManagement)">
        <div class="status-line">
          <span class="status-name">{{ HoldingStatus.Fundraising | enumString: HoldingStatus }}</span>
          <span class="description">Raising capital for a new {{holdingDiscriminator}}? Raise funds and track investor commitments</span>
        </div>
    </mat-radio-button>

    <mat-radio-button color="primary" [value]="HoldingStatus.Owned"
                      [disabled]="holding.status === HoldingStatus.Canceled || !canSwitchToUnderManagement">
      <span class="status-name">{{ HoldingStatus.Owned | enumString: HoldingStatus }}</span>
      <span class="description" *ngIf="holding.status !== HoldingStatus.Draft">Raised all commitments? Start managing this {{holdingDiscriminator}}</span>
      <span class="description"
            *ngIf="holding.status === HoldingStatus.Draft">Done all commitments? Start managing this {{holdingDiscriminator}}</span>
    </mat-radio-button>

    <!-- When the holding is a Draft holding, and there is still no completed initial fundraising -->
    <div *ngIf="draftHoldingWithNoInitialFundraising" class="info-message actions-required-message">
      You must add at least one fundraising to be able to move the {{holdingDiscriminator }} to Under Management.
    </div>

    <!-- When the fund can't be moved to under management, display this hint. -->
    <div *ngIf="holding.status === HoldingStatus.Fundraising && !canSwitchToUnderManagement"
         class="info-message actions-required-message">
      Done fundraising? In order to update the status to Under Management,
      make sure you have reached the fundraising target amount, and that all investors are marked as either Potential,
      Invested or Declined.
    </div>
    
    <mat-radio-button color="primary" [value]="HoldingStatus.Realized" [disabled]="!canSwitchToRealizedStatus">
      <div class="status-line">
        <span class="status-name">{{ HoldingStatus.Realized | enumString: HoldingStatus }}</span>
        <span class="description">Finished working on this deal? Mark it as Realized.</span>
      </div>
    </mat-radio-button>

    <mat-radio-button color="primary" [value]="HoldingStatus.Canceled">
      <div class="status-line">
        <span class="status-name">{{ HoldingStatus.Canceled | enumString: HoldingStatus }}</span>
        <span class="description">No longer working on this deal? Cancel it to move it to your {{holdingDiscriminator}}
          archive</span>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button color="primary" class="page-cta" cdkFocusInitial (click)="action()">
    {{ isAnotherStepRequried ? "Continue" : "Update" }}
  </button>

  <button *ngIf="holding.status === HoldingStatus.Draft && updatedStatus.value === HoldingStatus.Owned"
          mat-flat-button (click)="action(true)" color="accent" class="page-cta with-emails-button">
    Update and Notify Investors
    <mat-icon svgIcon="publish" class="notranslate publish-icon"></mat-icon>
  </button>

  <button mat-button [mat-dialog-close]="undefined" type="button" class="cancel-button tall-button" aria-label="Close">
    Cancel
  </button>
</mat-dialog-actions>
