<!-- Header -->
<terra-dialog-header>
  <h1>Select an agreement</h1>
</terra-dialog-header>

<mat-dialog-content>
  <ng-template #loadingOrError>
    <p class="error-message" *ngIf="errorObject; else loading">An error has occurred</p>
    <ng-template #loading>
      <terra-loader></terra-loader>
    </ng-template>
  </ng-template>

  <ng-container *ngIf="templates$ | async as templates; else loadingOrError">
    <mat-selection-list id="agreements-list" #selectList *ngIf="templates" (selectionChange)="selectionChange($event)">
      <mat-list-option #currentOption *ngFor="let template of templates; let i = index" [value]="template.key"
        [ngClass]="{ selected: currentOption.selected }">
        <mat-icon color="primary" matListIcon>insert_drive_file</mat-icon>
        <span class="agreement-name"> {{ template.value }}</span>
      </mat-list-option>
    </mat-selection-list>

    <div *ngIf="!templates || templates.length === 0">
      No templates found
    </div>
  </ng-container>

  <hr class="divider" />

  <!-- This button only triggers an event that tell the parent component that the user wants to upload a new agreement.
    The rest is done at the parent level. -->
  <button class="upload-new" mat-button (click)="uploadNewAgreementClick()">
    <mat-icon class="add-icon notranslate">add</mat-icon>
    Upload a new agreement
  </button>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" [disabled]="!selectedOption" (click)="open()" mat-flat-button color="primary" class="page-cta">Open</button>
  <button type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
