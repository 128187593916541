import {Component, OnInit, Inject, HostBinding} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl} from '@angular/forms';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable, of, timer, combineLatest} from 'rxjs';
import {finalize, switchMap, map, take, shareReplay, filter, withLatestFrom, catchError} from 'rxjs/operators';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {TerraUtils} from 'src/app/shared/TerraUtils';
import FundraisingStatus from 'src/app/shared/enums/FundraisingStatus.enum';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {ClientBankAccountResponseBasic, ClientBankAccountReqRes} from 'src/app/dashboard/models/bankAccount.model';
import {GpBankAccountDataService} from 'src/app/services/gp/gp-bank-account-data.service';
import {ResourceService} from 'src/app/services/resource.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {SearchOptionsRequest} from 'src/app/shared/models/SearchOptionsRequest.model';
import {FundraisingReqRes} from '../fundraisings-tab/FundraisingReqRes.model';
import {MetaFileLink} from 'src/app/models/metaFileLink.model';
import {EditFundraisingDialogContext} from '../fundraisings-tab/edit-fundraising-dialog.context';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {DialogService} from 'src/app/services/dialog.service';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import FundraisingType from 'src/app/shared/enums/FundraisingType.enum';
import PaymentStatus from 'src/app/shared/enums/PaymentStatus.enum';
import {ErrorMatcher, ErrorType} from 'src/app/shared/errors/ErrorMatcher';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {enterAnimation} from 'src/app/shared/animations';
import {DialogWithFormBase} from 'src/app/shared/types/DialogWithFormBase';
import {RoutingService} from 'src/app/services/routing.service';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {CapitalCallService} from '../../capital-call/capital-call.service';
import {HoldingFundraisingService} from '../holding-fundraising.service';
import {UserService} from 'src/app/services/shared/user.service';
import RoleFlags from 'src/app/account/my-account/model/RoleFlags.enum';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import {EditBankAccountContext} from 'src/app/dashboard/bankAccounts/components/edit-bank-account/EditBankAccountContext.model';
import {EditBankAccountComponent} from 'src/app/dashboard/bankAccounts/components/edit-bank-account/edit-bank-account.component';
import {BaseResponseDto, BaseResponseStatus} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';
import {UnitCreateBankAccountComponent} from 'src/app/shared/components/unit/unit-create-bank-account/unit-create-bank-account.component';
import {UnitCreateBankAccountParams} from 'src/app/shared/models/unit-bank-account/UnitCreateBankAccountParams.model';
import {UnitApplicationParentType} from 'src/app/shared/enums/unit-bank-account/UnitApplicationParentType.enum';
import UnitApplicationStatus from 'src/app/shared/enums/unit-bank-account/UnitApplicationStatus.enum';

@Component({
  selector: 'terra-edit-fundraising-dialog',
  templateUrl: './edit-fundraising-dialog.component.html',
  styleUrls: ['./edit-fundraising-dialog.component.scss'],
  animations: [enterAnimation],
})
export class EditFundraisingDialogComponent extends DialogWithFormBase implements OnInit {
  @HostBinding('class.read-only-mode') readOnlyMode = false;
  isSubmitted = false;
  noAccessMessage = '';

  private loadingCounter = 0;

  get loadingPage(): boolean {
    return this.loadingCounter !== 0;
  }

  pageForm: UntypedFormGroup;
  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;
  isFileNotSupported = false;

  fileNotSupportedError = 'The file you uploaded is not supported.';
  hasOrders = this.context.fundraisingDetails.investments.some(i => i.isOrderCreated);
  isContributionInReadOnlyMode$ = this.context.fundraisingDetailsService.isContributionInReadOnlyMode$;

  isCreBankFeatureEnabled$ = this.userService.userHasFeatureFlag(FeatureFlags.CreBanking);
  canCreateBankAccount$ = this.isCreBankFeatureEnabled$
    .pipe(
      untilComponentDestroyed(this),
      shareReplay(1));

  // enum
  FundraisingStatus = FundraisingStatus;
  SecurityType = InvestmentSecurityType;
  HoldingDiscriminator = HoldingDiscriminator;

  // Enum value lists
  SecurityTypesList: InvestmentSecurityType[] = [];

  fundraisingHasBankAccount: boolean;

  bankAccounts$: Observable<ClientBankAccountResponseBasic[]>;

  // newCreatedBankAccount = new BehaviorSubject<ClientBankAccountResponseBasic>(null);
  estimatedClosingDateMinimumDate = new Date('1970-01-01T12:00');

  get isUpdateBankAccountAllowed(): boolean {
    // Find investments (not declined) with  payment request already sent to the investors
    const investmentsWithPaymentRequestSent = this.context.fundraisingDetails.investments
      .filter(i => i.status !== InvestmentStatus.Declined)
      .find(i => i.paymentRequestSendDate !== null);
    // If found, it's not allowed to update bank account
    if (investmentsWithPaymentRequestSent) {
      return false;
    }
    return true;
  }

  get selectedSecurityType(): InvestmentSecurityType {
    try {
      return this.pageForm.get('securityType').value;
    } catch {
      return null;
    }
  }

  get capitalCallForm() {
    return this.pageForm.get('capitalCall') as UntypedFormGroup;
  }

  // get hasCapitalCall() {
  //   return !!this.context.fundraisingDetails.capitalCall;
  // }

  isDeleteFundraisingAllowed = false;

  constructor(
    public dialogRef: MatDialogRef<EditFundraisingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: EditFundraisingDialogContext,
    private fb: UntypedFormBuilder,
    private resourceService: ResourceService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private router: Router,
    private routingService: RoutingService,
    private numberPipe: TerraNumberPipe,
    public gpBankAccountDataService: GpBankAccountDataService,
    private capitalCallService: CapitalCallService,
    private holdingFundraisingService: HoldingFundraisingService,
    private userService: UserService,
    private dialog: MatDialog,
    private utilsService: UtilsService
  ) {
    super();
  }

  ngOnInit() {
    this.fundraisingHasBankAccount = !!this.context.fundraisingDetails.clientBankAccount || !!this.context.fundraisingDetails.unitBankAccount;

    switch (this.context.holdingDiscriminator) {
      case HoldingDiscriminator.Asset:
        this.SecurityTypesList = InvestmentSecurityType.optionsForAssetFundraising();
        break;
      case HoldingDiscriminator.Fund:
        this.SecurityTypesList = InvestmentSecurityType.optionsForFundFundraising();
        break;
    }

    this.generatePageForm(this.context);

    const searchOptions = new SearchOptionsRequest();
    searchOptions.pageNumber = 0;
    searchOptions.pageSize = 100;
    this.loadingCounter++;
    this.gpBankAccountDataService.getListByTargetCurrency(this.context.fundraisingDetails.fundraisingTargetCurrency.id, this.context.fundraisingDetails.holdingId)
      .pipe(
        finalize(() => this.loadingCounter--),
        catchError(async err => {
          if (ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)) {
            this.noAccessMessage = err.responseMessage;
          }
          return new Array<ClientBankAccountResponseBasic>();
        })
      )
      .subscribe(response => {
        this.bankAccounts$ = of(response);
      });

    this.setIsDeleteFundraisingAllowed();
    /*    if (this.hasCapitalCall) {
          this.subscribeToCapitalCallRelatedInfoChange();
        }*/
  }

  /*  subscribeToCapitalCallRelatedInfoChange() {
      this.pageForm.get('estimatedClosingDate').valueChanges.pipe(untilComponentDestroyed(this)).subscribe(closingDate => {
        this.capitalCallForm.get('dueDate').patchValue(closingDate);
      });
      this.pageForm.get('fundraisingTargetAmount').valueChanges.pipe(untilComponentDestroyed(this)).subscribe(targetAmount => {
        this.capitalCallForm.get('callAmount').patchValue(targetAmount);
      });
    }*/

  setIsDeleteFundraisingAllowed(): void {
    this.isDeleteFundraisingAllowed = true;
    const fundraising = this.context.fundraisingDetails;

    if (!fundraising) {
      return;
    }

    if (this.context.holdingStatus === HoldingStatus.Draft ||
      (fundraising.status === FundraisingStatus.Completed &&
        fundraising.type === FundraisingType.Contribution &&
        fundraising.investments.every(i => !i.isOrderCreated))) {
      this.isDeleteFundraisingAllowed = true;
      return;
    }
    // Can't delete the initial fundraising or a Completed fundraising
    if (fundraising.type === FundraisingType.InitialFundraising || fundraising.status === FundraisingStatus.Completed) {
      this.isDeleteFundraisingAllowed = false;
    } else {
      // Deleting is NOT allowed if there is an investment that:
      // has any ACTIVE payments (money already taken from the sender)
      const statusesAllowingDeletion = [PaymentStatus.NotSent, PaymentStatus.Sent, PaymentStatus.Canceled];

      // if all investments payments statuses allow deletion
      const foundInvestmentThatPreventsDeleting = fundraising.investments.some(
        i =>
          !statusesAllowingDeletion.includes(i.paymentStatus)
      );

      // If we didn't find any investment that prevents deletion, deletion is allowed:
      this.isDeleteFundraisingAllowed = !foundInvestmentThatPreventsDeleting;
    }
  }

  bankAccountCompareFn(b1: ClientBankAccountReqRes, b2: ClientBankAccountReqRes) {
    return b1 && b2 ? b1.id === b2.id : b1 === b2;
  }

  generatePageForm(context: EditFundraisingDialogContext) {
    const fundraisingDetails = context.fundraisingDetails;

    context.fundraisingDetailsService.isContributionInReadOnlyMode$.subscribe(isContributionReadOnly => {
      this.readOnlyMode = this.hasOrders && isContributionReadOnly;
      this.pageForm = this.fb.group({
        // fundraisingName: [fundraisingDetails.name, [Validators.required, Validators.minLength(2)], this.validateFundraisingNameNotTaken.bind(this)],
        fundraisingName: [fundraisingDetails.name, [Validators.required, Validators.minLength(2)]],
        fundraisingTargetAmount: [{
          value: fundraisingDetails.fundraisingTargetAmount,
          disabled: this.readOnlyMode
        }, Validators.compose([Validators.required, Validators.min(1)])],
        minimumInvestmentAmount: {
          value: fundraisingDetails.minimumInvestmentAmount,
          disabled: this.readOnlyMode
        },
        estimatedClosingDate: [{
          value: fundraisingDetails.estimatedClosingDate,
          disabled: this.readOnlyMode
        }, [Validators.required]],
        finalClosingDate: {
          value: fundraisingDetails.finalClosingDate,
          disabled: this.readOnlyMode
        },
        securityType: [{
          value: fundraisingDetails.securityType,
          disabled: this.readOnlyMode
        }, Validators.required],
        marketingDeckDocument: {
          value: null,
          disabled: this.readOnlyMode
        },
        isDefaultDeck: {
          value: fundraisingDetails.isDefaultDeck,
          disabled: true
        },
        bankAccount: {
          value: this.holdingFundraisingService.getHoldingBankAccount(fundraisingDetails),
          disabled: !this.isUpdateBankAccountAllowed || this.readOnlyMode
        }
      });

      /*      if (this.hasCapitalCall) {
              this.pageForm.addControl('capitalCall', this.capitalCallService.capitalCallDetailsForm(this.fb, fundraisingDetails));
            }*/

      // check if there is a marketing deck
      const currentMarketingDeck = fundraisingDetails.offeringDeck;
      if (currentMarketingDeck) {
        this.pageForm.get('marketingDeckDocument').patchValue(currentMarketingDeck);
        this.readOnlyMode ? this.pageForm.get('isDefaultDeck').disable() : this.pageForm.get('isDefaultDeck').enable();
      }
    });
  }

  getCountryById(conutryId: number): CountryModel {
    return this.resourceService.getCountryById(conutryId);
  }

  updateBanks() {
    this.loadingCounter++;
    this.gpBankAccountDataService.getListByTargetCurrency(this.context.fundraisingDetails.fundraisingTargetCurrencyId).subscribe(response => {
      this.bankAccounts$ = of(response);
      this.loadingCounter--;
    });
  }

  generateSubmitModel(): FundraisingReqRes {
    const formValues = this.pageForm.getRawValue();
    const fundraisingModel = new FundraisingReqRes();

    fundraisingModel.id = this.context.fundraisingDetails.id;
    fundraisingModel.holdingId = this.context.fundraisingDetails.holdingId;

    fundraisingModel.name = formValues.fundraisingName;
    fundraisingModel.estimatedClosingDate = formValues.estimatedClosingDate;
    fundraisingModel.finalClosingDate = formValues.finalClosingDate;
    fundraisingModel.fundraisingTargetAmount = this.numberPipe.parse(formValues.fundraisingTargetAmount);
    fundraisingModel.minimumInvestmentAmount = this.numberPipe.parse(formValues.minimumInvestmentAmount);
    fundraisingModel.fundraisingTargetCurrencyId = this.context.fundraisingDetails.fundraisingTargetCurrencyId;
    fundraisingModel.securityType = formValues.securityType;

    fundraisingModel.finalClosingDate = formValues.finalClosingDate;

    // Add the offering deck
    if (formValues.marketingDeckDocument) {
      fundraisingModel.offeringDeckId = (formValues.marketingDeckDocument as MetaFileLink).id;
      fundraisingModel.isDefaultDeck = formValues.isDefaultDeck;
    }

    // if the bank account formControl is disabled, its value will not be included in the form group value,
    if (!this.isUpdateBankAccountAllowed) {
      fundraisingModel.clientBankAccountId = this.context.fundraisingDetails.clientBankAccount?.id;
      fundraisingModel.unitBankAccountId = this.context.fundraisingDetails.unitBankAccount?.id;
    } else if (formValues.bankAccount) {
      fundraisingModel.clientBankAccountId = !formValues.bankAccount.isUnitBankAccount ? (formValues.bankAccount as ClientBankAccountReqRes).id : null;
      fundraisingModel.unitBankAccountId = formValues.bankAccount.isUnitBankAccount ? (formValues.bankAccount as ClientBankAccountReqRes).id : null;
    }

    return fundraisingModel;
  }

  save() {
    this.isSubmitted = true;
    this.isGeneralServerError = false;
    this.isFileNotSupported = false;

    if (this.pageForm.valid) {
      this.loadingCounter++;

      const model = this.generateSubmitModel();

      this.context.fundraisingDetailsService.updateFundraising(model).pipe(
        finalize(() => {
          this.loadingCounter--;
        })
      )
        .subscribe(updatedFundraising => {
          this.dialogRef.close(updatedFundraising);
        },
          error => {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            } else if (ErrorMatcher.isError(error, ErrorType.BadFileFormatException)) {
              this.isFileNotSupported = true;
            } else {
              this.isGeneralServerError = true;
            }
            console.log('Error updating contribution', error);
          }
        );
    }
  }

  deleteFundraising() {
    const confirmDialogParams = new ConfirmDialogParams();
    confirmDialogParams.actionLabel = 'Delete';
    confirmDialogParams.title = 'Are you sure you want to delete this contribution?';
    confirmDialogParams.description = `This contribution will be deleted immediately. You cannot undo this action.`;
    this.dialogService
      .confirmDialog(confirmDialogParams)
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.context.fundraisingDetailsService
            .deleteFundraising()
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              response => {
                // navigate back to the holding page, contributions tab
                let url;
                const holdingId = this.context.fundraisingDetails.holdingId;
                switch (this.context.holdingDiscriminator) {
                  case HoldingDiscriminator.Asset:
                    url = this.routingService.gpAssetWithTab(holdingId, 'contributions');
                    break;
                  case HoldingDiscriminator.Fund:
                    url = this.routingService.gpFundWithTab(holdingId, 'contributions');
                    break;
                }

                this.router.navigateByUrl(url);
                this.dialogRef.close(true);
                this.snackbarService.showGeneralMessage(`Contribution deleted`);
              },
              error => {
                if (error instanceof BaseResponseDto) {
                  this.utilsService.alertErrorMessage(error);
                } else {
                  this.snackbarService.showGeneralMessage(`Couldn't delete the contribution`);
                }
              }
            );
        }
      });
  }

  createNewBankAccount() {
    this.canCreateBankAccount$.pipe(
      map((createEnabled) => {
        if (createEnabled) {
          let primaryActionCancelled = true;
          const dialogParams = new ConfirmDialogParams();
          dialogParams.title = `New Bank Account`;
          dialogParams.actionLabel = `OK`;
          dialogParams.secondaryActionLabel = `ADD EXTERNAL ACCOUNT`;
          dialogParams.hideCancel = true;

          if (!!this.context.holding?.isExample) {
            dialogParams.disableAction = true;
          }

          if (!this.context.holding?.unitApplications?.length) {
            dialogParams.description = `To create a Covercy CRE Bank Account for this holding, click "Create Account"
                                              and fill the application form. We recommend waiting until the application is approved
                                              and then associating the new account with your holding.<br>
                                              For now, you may skip this field and set it up later.`;
            dialogParams.actionLabel = `Create Account`;
            dialogParams.secondaryActionLabel = 'Add External account';
            dialogParams.hideCancel = false;
            primaryActionCancelled = false;
          } else {
            switch (this.context.holding.unitApplications[0].status) {
              case UnitApplicationStatus.AwaitingDocuments:
              case UnitApplicationStatus.PendingReview:
              case UnitApplicationStatus.Pending:
              case UnitApplicationStatus.Draft:
                dialogParams.description = `Your application for a new Covercy CRE Bank Account is pending and in the process of approval.
                                              We recommend waiting until the application is approved and then associating the new account with your holding.<br>
                                              For now, you may skip this field and set it up later.`;
                break;
              case UnitApplicationStatus.Denied:
                dialogParams.description = `Your application for a new Covercy CRE Bank Account has been denied.
                                              This might be due to missing documents and could still be authorized.
                                              Please contact us at <a href="mailto:support@covercy.com" target="_blank">support@covercy.com</a> for more information.
                                              We recommend waiting until the application is approved,
                                              and then associating the new account with your holding.<br>
                                              For now, you may skip this field and set it up later.`;
                break;
              case UnitApplicationStatus.Approved:
                this.createCreBankAccount();
                break;
              default:
                break;
            }
          }


          if (dialogParams.description) {
            this.dialogService.confirmDialog(dialogParams)
              .afterClosed()
              .subscribe(res => {
                if (res?.secondaryAction) {
                  this.createExternalBankAccount();
                } else if (!primaryActionCancelled && res) {
                  this.router.navigate([]).then(result => {
                    const url = this.router.serializeUrl(
                      this.router.createUrlTree(
                        [this.routingService.createBankApplicationPage()],
                        {queryParams: {holdingId: this.context.holding.id}}
                      )
                    );
                    window.open(url, '_blank');
                  });
                }
                // this.dialogRef.close();
              });
          }
        } else {
          this.createExternalBankAccount();
        }
      })
    ).subscribe();
  }

  private createExternalBankAccount() {
    const dialogConfig = new MatDialogConfig<EditBankAccountContext>();
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'create-bank-account-dialog';

    const dialogRef: MatDialogRef<EditBankAccountComponent, ClientBankAccountResponseBasic> = this.dialog.open(EditBankAccountComponent, dialogConfig);

    dialogRef.afterClosed()
      .pipe(
        untilComponentDestroyed(this),
        filter(createdBankAccount => !!createdBankAccount),
        withLatestFrom(this.bankAccounts$)
      )
      .subscribe(([createdBankAccount, bankAccounts]) => {
        const newBankAccount = new ClientBankAccountResponseBasic();
        newBankAccount.accountNumber = createdBankAccount.accountNumber;
        newBankAccount.nickname = createdBankAccount.nickname;
        newBankAccount.id = createdBankAccount.id;
        newBankAccount.isUnitBankAccount = false;

        bankAccounts.push(newBankAccount);
        this.pageForm.get('bankAccount').setValue(newBankAccount);
      });
  }

  private createCreBankAccount() {
    // this.asset$.pipe(
    //   switchMap(asset => {
    const dialog = this.dialog.open(UnitCreateBankAccountComponent, {
      width: '50%',
      disableClose: true,
      closeOnNavigation: true,
      data: {
        preselectedType: UnitApplicationParentType.Holding,
        attachedToId: this.context.holding.id,
        hasApprovedApplication: this.context.holding.unitApplications?.some(x => x.status === UnitApplicationStatus.Approved),
        showExternalOptions: true,
        unitApplicationId: this.context.holding.unitApplicationId

      } as UnitCreateBankAccountParams
    });

    dialog.afterClosed().pipe(
      withLatestFrom(this.bankAccounts$)
    ).subscribe(([newCreBankAccount, bankAccounts]) => {
      if (!newCreBankAccount) {
        return;
      }
      if (!bankAccounts) {
        bankAccounts = [];
      }

      if (newCreBankAccount?.reason === 'external') {
        this.createExternalBankAccount();
      } else if (newCreBankAccount?.reason === 'new' || newCreBankAccount?.reason === 'fake') {
        const newBankAccount = new ClientBankAccountResponseBasic();
        newBankAccount.accountNumber = newCreBankAccount.payload.accountNumber;
        newBankAccount.nickname = newCreBankAccount.payload.accountNickname;
        newBankAccount.id = newCreBankAccount.payload.id;
        newBankAccount.isUnitBankAccount = true;

        bankAccounts.unshift(newBankAccount);
        this.pageForm.get('bankAccount').setValue(newBankAccount);
      }
    });
  }

  // Async validation functions:
  validateFundraisingNameNotTaken(control: AbstractControl, originalValue = '') {
    const {value}: {value: string} = control;

    if (!value || value === originalValue) {
      return of(null);
    }
    return timer(1000).pipe(
      switchMap(assetId => this.context.fundraisingDetailsService.isFundraisingNameExists(value)),
      map(isNameExists => {
        return isNameExists ? {fundraisingNameTaken: true} : null;
      }),
      take(1)
    );
  }
}
