<div class="terra-form-field-wrapper">
    <terra-file-uploader no-type-select [formControlName]="docControlName" [isSubmitted]="isSubmitAttempt"
        [required]="false" [isCompressable]="true"
        [isErrorSetFromOutside]="parentFormGroup.get(docControlName).invalid">
    </terra-file-uploader>
    <p class="error-message" *ngIf="parentFormGroup.get(docControlName).hasError('fileTypeNotSupported')">The type of
        the file you
        uploaded is not supported
    </p>
    <div class="offering-deck-zoom-container">
        <mat-radio-group [formControlName]="docZoomControlName">
            <mat-radio-button value="auto" color="primary">Auto-Scale
            </mat-radio-button>
            <mat-radio-button value="manual" color="primary">
                Fixed Zoom
                <mat-slider id="app-slider" color="primary"  showTickMarks discrete [displayWith]="sliderLabel" tickInterval="1" step="1" min="50" max="100">
                    <input matSliderThumb [formControlName]="docZoomValControlName">  
                </mat-slider>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>