import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';

import {GpHoldingService} from '../../../gp-holding.service';
import {ContributionDetailsItemReqRes} from './ContributionDetailsItemReqRes';
import InvestmentSecurityType from '../../../../../../shared/enums/InvestmentSecurityType.enum';
import {TerraUtils} from "../../../../../../shared/TerraUtils";

@Component({
  selector: 'terra-contribution-details',
  templateUrl: './contribution-details.component.html',
  styleUrls: ['./contribution-details.component.scss']
})
export class ContributionDetailsComponent implements OnInit {
  @Input() details: ContributionDetailsItemReqRes;

  pageForm: UntypedFormGroup;
  finalClosingDateStartAt = new Date();
  finalClosingDateMinimum = new Date('1970-01-01T12:00');
  holding$ = this.gpHoldingService.holding$;
  holdingId$ = this.gpHoldingService.holdingId$;
  InvestmentSecurityType = InvestmentSecurityType;
  isAsset = this.gpHoldingService.discriminatorLowercaseStr === 'asset';
  InvestmentSecurityTypeList = this.isAsset ? InvestmentSecurityType.optionsForAssetFundraising() : InvestmentSecurityType.optionsForFundFundraising();

  constructor(
    public gpHoldingService: GpHoldingService,
    private fb: UntypedFormBuilder,
  ) {
  }

  ngOnInit() {
    this.generatePageForm();
    this.updateCurrency();
  }

  updateCurrency() {
    this.holding$.subscribe(holding => {
      this.pageForm.get('currency').setValue(`${holding.initialCurrency.symbol} ${holding.initialCurrency.iso}`);
    });
  }

  generatePageForm() {
    this.pageForm = this.fb.group({
      // Remove validateFundraisingNameNotTaken after July 1 2021 if not in use
      // name: [null, [Validators.required, Validators.minLength(2)], this.validateFundraisingNameNotTaken.bind(this)],
      name: [null, [Validators.required, Validators.minLength(2)]],
      finalClosingDate: [this.finalClosingDateStartAt, [Validators.required]],
      securityType: [InvestmentSecurityType.Equity, Validators.required],
      currency: null,
    });
    this.pageForm.get('currency').disable();
    this.pageForm.markAllAsTouched();

    this.pageForm.valueChanges
      .subscribe(values => {
        this.updateDetailsValues(values);
      });
  }

  private updateDetailsValues(values: any) {
    this.details.name = values.name;
    this.details.finalClosingDate = values.finalClosingDate;
    this.details.securityType = values.securityType;
    this.details.isFormValid = this.pageForm.valid;
  }

  // Remove validateFundraisingNameNotTaken after July 1 2021 if not in use
  // validateFundraisingNameNotTaken(control: AbstractControl, originalValue = '') {
  //   const {value}: { value: string } = control;
  //
  //   if (!value || value === originalValue) {
  //     return of(null);
  //   }
  //   return timer(1000).pipe(
  //     switchMap(_ => this.holdingId$.pipe(take(1))),
  //     switchMap(holdingId => this.gpFundraisingDataService.isFundraisingNameExists(holdingId, value)),
  //     map(isNameExists => {
  //       return isNameExists ? {fundraisingNameTaken: true} : null;
  //     })
  //   );
  // }
}
