<terra-dialog-header>
  <h1>Share files</h1>
</terra-dialog-header>

<div class="dialog-content-wrapper" [ngClass]="{ 'loading': (isLoading$ | async)  }">
  <terra-loader class="dialog-loader" *ngIf="(isLoading$ | async)"></terra-loader>
  <mat-dialog-content *ngIf="!(isLoading$ | async)">
    <ng-container *ngIf="{
      filesReadyStatus: filesReadyStatus$ | async,
      filesProcessingStatus: filesProcessingStatus$ | async } as files">
      <!-- Empty state - no sharable file was selected -->
      <ng-template #emptyState>
        <p class="no-files-message info-message">
          <ng-container *ngIf="isAllSelectedFilesStillProcessing$ | async; else noFilesSelected">
            All files selected for sharing are still being processed. <br>
            Please try again in a few minutes.
          </ng-container>
          <ng-template #noFilesSelected>
            No files were selected
          </ng-template>
        </p>
      </ng-template>
      <!-- When there is at least 1 sharable file -->
      <ng-container *ngIf="files?.filesReadyStatus?.length > 0; else emptyState">
        <label>Selected files to share:</label>
        <ul class="documents-list">
          <li *ngFor="let object of files?.filesReadyStatus" class="list-item">
            <mat-icon *ngIf="object.storageObjectType === StorageObjectType.File" class="item-icon file-icon notranslate"
                      [svgIcon]="object.metaFileLink | mediaFileIcon">
            </mat-icon>
            <mat-icon *ngIf="object.storageObjectType === StorageObjectType.Directory" class="item-icon folder-icon notranslate">
              folder
            </mat-icon>
            <span class="lo_sensitive"
                  [title]="object.displayName?.length > 45 ? object.displayName : ''">{{ object.displayName | truncate : 45 }}.{{ object.extension }}</span>
          </li>
          <!-- Excluded files -->
          <ng-container *ngIf="files?.filesProcessingStatus?.length > 0">
            <li class="divider">These files are still being processed and will not be shared:</li>
            <li *ngFor="let object of files?.filesProcessingStatus" class="list-item excluded">
              <mat-icon *ngIf="object.storageObjectType === StorageObjectType.File" class="item-icon file-icon notranslate"
                        [svgIcon]="object.metaFileLink | mediaFileIcon">
              </mat-icon>
              <mat-icon *ngIf="object.storageObjectType === StorageObjectType.Directory" class="item-icon folder-icon notranslate">
                folder
              </mat-icon>
              <span class="lo_sensitive"
                    [title]="object.displayName.length > 45 ? object.displayName : ''">{{ object.displayName | truncate : 45 }}.{{ object.extension }}</span>
            </li>
          </ng-container>
        </ul>

        <form id="dialogForm" [formGroup]="pageForm" class="form-grid-new terra-error-style">

          <!-- Share option (none/all/specific) -->
          <div class="full-width">
            <label class="text-input-label share-options-label">Share files with:</label>
            <mat-radio-group formControlName="shareWidthOption" class="share-radio-buttons">
              <mat-radio-button [value]="1" color="primary">
                All investors (will be shared automatically with existing and future investors in this {{ holdingDiscriminatorLowerCase }})
              </mat-radio-button>
              <mat-radio-button [value]="2" [disabled]="isDraftHolding$ | async" color="primary">
                Select specific investors <span *ngIf="isDraftHolding$ | async"> (disabled for Draft {{ holdingDiscriminatorLowerCase }}s)</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- Share with specific investors select -->
          <div class="terra-form-field-wrapper" *ngIf="pageForm.get('shareWidthOption').value === 2">
            <mat-form-field appearance="outline">
              <mat-select multiple placeholder="Select" formControlName="contactsToShareWith">
                <mat-select-trigger>
                  <ng-container *ngIf="(allowInvestorName$ | async)">
                    {{ contactsToShareWith[0]?.name }}
                  </ng-container>
                  <ng-container *ngIf="!(allowInvestorName$ | async)">
                    {{ contactsToShareWith[0]?.nickname }}
                  </ng-container>
                  <span *ngIf="contactsToShareWith?.length > 1" class="example-additional-selection">
                    (+{{ contactsToShareWith.length - 1 }} {{ contactsToShareWith?.length === 2 ? 'other' : 'others' }})
                  </span>
                </mat-select-trigger>
                <mat-option class="investors-select lo_sensitive" *ngFor="let contact of context.contacts" [value]="contact">
                  <ng-container *ngIf="allowInvestorName$ | async">
                    {{ contact.name }}
                  </ng-container>
                  <ng-container *ngIf="!(allowInvestorName$ | async)">
                    {{ contact.nickname }}
                  </ng-container>
                  <span class="email">({{ contact.email }})</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="pageForm.get('contactsToShareWith').hasError('required')">Please select at least 1 investor</mat-error>
            </mat-form-field>
          </div>

          <!-- Names of all selected investors -->
          <p *ngIf="contactsToShareWith?.length > 1" class="shared-with-list full-width">
            <label class="shared-with-label">Shared with</label>
            <ng-container class="lo_sensitive" *ngFor="let investor of contactsToShareWith; let i = index">
              <ng-container *ngIf="allowInvestorName$ | async">
                {{ investor.firstName }} {{ investor.middleName }} {{ investor.lastName }}
              </ng-container>
              <ng-container *ngIf="!(allowInvestorName$ | async)">
                {{ investor.nickname }}
              </ng-container>
              {{ i !== contactsToShareWith.length - 1 ? ', ' : '' }}
            </ng-container>
          </p>

          <!-- Personal message -->
          <ng-container *ngIf="!context.isPrivateHolding">
            <label for=" txtMessage" class="text-input-label full-width share-documents-text">Add a personal message that will be sent along (optional):</label>
            <quill-editor id="txtMessage" formControlName="message" format="html" class="lo_sensitive message-input full-width" placeholder="" />

          </ng-container>
        </form>

        <p *ngIf="isAttemptSubmit && pageForm.invalid" class="error-message">
          Please review and correct the fields marked in red
        </p>

        <p #serverError *ngIf="isGeneralServerError" class="error-message">
          <span [innerHTML]="generalServerErrorMessage"></span>
        </p>

        <p class="share-documents-text">Once shared, files will be available for the investor(s) to view and download from the Investor Portal.</p>
      </ng-container>
    </ng-container>
  </mat-dialog-content>
</div>

<mat-dialog-actions class="share-documents-actions">
  <ng-container *ngIf="(filesReadyStatus$ | async)?.length > 0">
    <!-- Holding is Public -->
    <ng-container *ngIf="!context.isPrivateHolding">
      <button type="submit" (click)="save(true)" form="dialogForm" mat-flat-button color="accent" class="share-button"
              [disabled]="processingSubmit$ | async">
        Share and notify via Email
        <mat-icon iconPositionEnd svgIcon="publish" class="notranslate publish-icon"></mat-icon>
      </button>
      <button type="submit" (click)="save(false)" form="dialogForm" mat-stroked-button class="save-only-button"
              [disabled]="processingSubmit$ | async">
        Share without Email
      </button>
    </ng-container>
    <!-- Holding is Private -->
    <ng-container *ngIf="context.isPrivateHolding">
      <button type="submit" color="primary" (click)="save(false)" form="dialogForm" mat-flat-button class="save-button"
              [disabled]="processingSubmit$ | async">
        Update Sharing Permissions
      </button>
    </ng-container>
  </ng-container>

  <!-- When no files can be shared (no selected files / all are still processing) -->
  <button type="button" mat-flat-button class="ok-button" color="primary" *ngIf="(filesReadyStatus$ | async)?.length === 0"
          [mat-dialog-close]="undefined">OK
  </button>

  <!-- Cancel button - always available when files are loading. After files wereloaded, only if some can be shared.   -->
  <button type="button" *ngIf="(loadingFiles$ | async) || (filesReadyStatus$ | async)?.length > 0" mat-button class="cancel-button"
          [mat-dialog-close]="undefined" [disabled]="processingSubmit$ | async">Cancel
  </button>
</mat-dialog-actions>
