import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {MaintenanceGuard} from './guards/maintenance.guard';
import {AuthorizedGpGuard} from './guards/authorized-gp.guard';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PendingChangesGuard} from './guards/pendingChanges.guard';
import {MaintenanceComponent} from './shared/components/maintenance/maintenance.component';
import {RootNavigationGuard} from './guards/root-navigation.guard';
import {NeverGetHereComponent} from './shame/never-get-here/never-get-here.component';
import {LogoutComponent} from './account/logout/logout.component';
import {ForgotPasswordComponent} from './account/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './account/reset-password/reset-password.component';
import {EmailConfirmComponent} from './account/email-confirm/email-confirm.component';
import {EmailSuspendComponent} from './account/email-suspend/email-suspend.component';
import {EmailVerificationComponent} from './account/email-verification/email-verification.component';
import {TwoFactorVerificationComponent} from './account/two-factor-verification/two-factor-verification.component';
import {EmailAlreadyVerifiedGuard} from './guards/email-already-verified.guard';
import {AlreadyLoggedOutGuard} from './guards/already-logged-out.guard';
import {AnonymousPageGuard} from './guards/anonymous-page.guard';
import {SignRequestComponent} from './account/sign-request/sign-request.component';
import {OauthComponent} from './shared/components/oauth/oauth.component';
import {NoAccessComponent} from './shared/components/no-access/no-access.component';
import { SupportComponent } from './shared/components/support/support.component';

const appRoutes: Routes = [
  {
    path: '',
    component: NeverGetHereComponent,
    pathMatch: 'full',
    canActivate: [MaintenanceGuard, RootNavigationGuard]
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent
  },
  {
    path: 'contact-us',
    component: SupportComponent
  },
  {
    path: 'no-access',
    component: NoAccessComponent
  },
  {
    path: 'oauth',
    component: OauthComponent
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AlreadyLoggedOutGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [AnonymousPageGuard]
  },
  {
    // arriving here from email link
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    canActivate: [AnonymousPageGuard]
  },
  {
    // arriving here from email link
    path: 'email-confirm',
    component: EmailConfirmComponent,
    canActivate: [AnonymousPageGuard]
  },
  {
    // Arriving here from email link - When user clicks the "I didn't sign-up for Covercy"
    path: 'email-reject',
    component: EmailSuspendComponent,
    canActivate: [AnonymousPageGuard]
  },
  { // Arriving here after GP noboarding, telling user he is "Almost done... needs to confirm his account"
    path: 'email-verification',
    component: EmailVerificationComponent,
    canActivate: [EmailAlreadyVerifiedGuard]
  },
  {
    path: 'two-factor',
    component: TwoFactorVerificationComponent
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then(m => m.InfoModule)
  },
  {
    // arriving here from email link
    path: 'sign-request/:investmentId/:uniqueIdentifier/:isGp',
    component: SignRequestComponent,
    canActivate: [AnonymousPageGuard]
  },
  {path: '**', redirectTo: 'not-found'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
    useHash: false,
    onSameUrlNavigation: 'reload',
    paramsInheritanceStrategy: 'always'
})
  ],
  exports: [RouterModule],
  providers: [MaintenanceGuard, AuthorizedGpGuard, PendingChangesGuard, RootNavigationGuard]
})
export class AppRoutingModule {
}
