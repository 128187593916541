import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import MetaFileLink from 'src/app/models/metaFileLink.model';

export interface UploadFilaDialogData {
  title: string;
  savedDocuments: MetaFileLink[];
}

@Component({
  selector: 'terra-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFileDialogComponent implements OnInit {

  readonly MAX_UPLOAD_DOCUMENT: number = 8;

  form = this.fb.group({
    documents: this.fb.array([])
  });

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UploadFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadFilaDialogData
  ) { }

  ngOnInit(): void {
    this.addDocument();
    this.dialogRef.updateSize('500px');
  }

  get documents(){
    return this.form.controls['documents'] as UntypedFormArray;
  }

  addDocument(){
    if ((this.documents.length + this.data.savedDocuments.length) >= this.MAX_UPLOAD_DOCUMENT){
      return;
    }
    const document = new UntypedFormControl();
    this.documents.push(document);
  }

  save(){
    const metaFileLinkIds: MetaFileLink[] = this.documents.value.filter(doc => !!doc).map(doc => doc as MetaFileLink);
    this.dialogRef.close(metaFileLinkIds);
  }
}
