import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { enterAnimation } from 'src/app/shared/animations';
import { CreateFundService, CreateFundStepNumber } from './create-fund.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';

@Component({
  selector: 'terra-create-fund',
  templateUrl: './create-fund.component.html',
  styleUrls: ['./create-fund.component.scss'],
  animations: [enterAnimation],
  providers: [CreateFundService]
})
export class CreateFundComponent implements OnInit {

  processingSubmit$ = this.createFundService.processingSubmit$;
  currentStep$ = this.createFundService.currentStep$;

  constructor(private titleService: Title, private createFundService: CreateFundService) { }

  ngOnInit() {
    this.titleService.setTitle('Create Fund' + TerraUtils.consts.GpPageTitleSuffix);
  }

  navigateBackToStep(step: CreateFundStepNumber) {
    const currentStep = this.currentStep$.value;
    if (currentStep > step) {
      this.currentStep$.next(step);
    }
  }

  cancel() {
    this.createFundService.cancel();
  }
}
