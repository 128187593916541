<terra-loader *ngIf="isLoading$ | async" class="loader"></terra-loader>

<div *ngIf="!(isLoading$ | async)" [ngClass]="{'container': true, 'has-no-cre-bank': !hasCreBank}">

  <!-- Descriptions -->
  <div class="description" [ngClass]="{'description-small': (fundraising$ | async).offeringDeck && (fundraising$ | async).isSentOfferingDeck}">
    <div class="title">
      <h3>Offering Deck</h3>

    </div>
    <ng-container>
      <!--  has marketing deck  -->
      <div class="opportunity-page-actions" *ngIf="FeatureFlags.isOn((userService.accountDetails$ | async).featureFlags, FeatureFlags.InvestNow) && marketingDeckLink$ | async as link">
          <span class="opportunity-page-action" [cdkCopyToClipboard]="link" (click)="notifyCopied($event)"><mat-icon class="smaller-icon">link</mat-icon> <span class="link-text">Copy opportunity link</span></span>
        <a [href]="link" class="opportunity-page-action" target="_blank"><mat-icon class="smaller-icon">open_in_new</mat-icon> <span class="link-text">View opportunity</span></a>
      </div>
    </ng-container>
  </div>

  

  <!-- Actions -->
    <!-- sent bulk offering deck -->
  <div [ngClass]="{'action-container': true, 'has-no-cre-bank': !hasCreBank}">
    <!--  has marketing deck  -->
    <div class="opportunity-page-actions" *ngIf="FeatureFlags.isOn((userService.accountDetails$ | async).featureFlags, FeatureFlags.InvestNow) && !hasCreBank && (fundraising$ | async).offeringDeck && marketingDeckLink$ | async as link">
      <span class="opportunity-page-action" [cdkCopyToClipboard]="link" (click)="notifyCopied($event)"><mat-icon class="smaller-icon">link</mat-icon> <span class="link-text">Copy opportunity link</span></span>
      <a [href]="link" class="opportunity-page-action" target="_blank"><mat-icon class="smaller-icon">open_in_new</mat-icon> <span class="link-text">View opportunity</span></a>
    </div>
    <!-- *ngIf="(isSentOfferingDeck$ | async)" -->
    <button mat-flat-button style="width: max-content;" [ngClass]="{'button-with':!hasCreBank}" color="primary" (click)="openEngagementModal()">
      <mat-icon class="material-icons-outlined icon notranslate">dvr</mat-icon>
      Investor Engagement
    </button>

    <!--  no offering deck  -->
    <ng-container *ngIf="!(fundraising$ | async).offeringDeck; else addAndSend">
      <button [ngClass]="{'button-with':!hasCreBank}" mat-stroked-button color="primary" (click)="openUploadModal()"
            [disabled]="!(isActiveFundraising$ | async)"
            pTooltip ="{{ tooltipUploadDeck }}"
            tooltipPosition="bottom" style="width: max-content;">
      <span class="material-icons-outlined icon notranslate">file_upload</span>
      Upload deck
      </button>
      <div class="file-description" *ngIf="!hasCreBank">
        File format must be JPG, JPEG, PNG (20 MB max file size)
        <br>
        or PDF (50 MB max file size)
      </div>
    </ng-container>
    

    <!--  has offering deck  -->
    <ng-template #addAndSend>
      <button mat-stroked-button color="primary" style="width: max-content;" [ngClass]="{'button-with':!hasCreBank}" (click)="openSendModal()" [disabled]="!(isActiveFundraising$ | async)">
        <span class="material-icons-outlined icon notranslate">person_add</span>
        Add investors and send deck
      </button>
    </ng-template>
  </div>
</div>
