import {Component, OnInit, Inject, AfterViewInit} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { take } from 'rxjs/operators';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import { TerraUtils } from 'src/app/shared/TerraUtils';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GpFundDialogContext } from 'src/app/dashboard/funds/gp-fund/Fund.context';
import { EditFundService } from './edit-fund.service';
import { EditFundTabNumber } from './EditFundStepBaseAndInterface';
import { enterAnimation } from 'src/app/shared/animations';
import { DialogWithFormBase } from 'src/app/shared/types/DialogWithFormBase';
import { RoutingService } from 'src/app/services/routing.service';
import { UserService } from 'src/app/services/shared/user.service';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import OwnershipCalculationType from 'src/app/shared/enums/OwnershipCalculationType.enum';

@Component({
  selector: 'terra-edit-fund-dialog',
  templateUrl: './edit-fund-dialog.component.html',
  styleUrls: ['./edit-fund-dialog.component.scss'],
  providers: [EditFundService],
  animations: [enterAnimation]
})
export class EditFundDialogComponent extends DialogWithFormBase implements OnInit, AfterViewInit {


  initialSelectedTab = this.context.initialTabNumber - 1;

  // enum
  FundStatus = HoldingStatus;

  get pageForm(): UntypedFormGroup {
    if (!this.editFundService.fundForm) {
      return null;
    }
    return this.editFundService.fundForm as UntypedFormGroup;
  }

  get isStatusFundRaising() {
    return this.context.fundDetails.status === HoldingStatus.Fundraising;
  }

  get attemptSubmit() {
    return this.editFundService.attemptSubmit;
  }

  processingSubmit$ = this.editFundService.processingSubmit$;

  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;


  get isDeleteFundAllowed(): boolean {
    if (!this.context.fundDetails) {
      return false;
    }
    if (this.context.fundraisingDetails.investments.find(i => i.isAgreementSigned || i.paymentRequestSendDate !== null)) {
      return false;
    }
    return true;
  }

  constructor(
    public dialogRef: MatDialogRef<EditFundDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: GpFundDialogContext,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private router: Router,
    private editFundService: EditFundService,
    private routingService: RoutingService,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private utilsService: UtilsService
  ) {
    super();
    editFundService.editFundDialogContext = context;
    editFundService.dialogRef = dialogRef;
    editFundService.generateForm();
  }

  ngOnInit() {
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
      if(!FeatureFlags.isOn(clientDetails.featureFlags, FeatureFlags.InvestNow)) {
        const isVisibleControl = this.editFundService.getTabForm(3).get('isVisible');
        isVisibleControl?.setValue(false);
        isVisibleControl?.disable();
      }

      this.telemetryService.create({
        eventID: '22',
        eventTitle: 'EDIT FUND (INIT)',
        holdingID: this.context.fundDetails.id,
        organizationID: clientDetails.organizationDetails.id
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });
  }

  ngAfterViewInit(): void {
    if (!!this.context.scrollToId) {
      const htmlElement = document.getElementById(this.context.scrollToId);
      if (!!htmlElement) {
        htmlElement.scrollIntoView({behavior: 'smooth'});
      }
    }
  }

  isShowInvalidTab(tabNumber: EditFundTabNumber) {
    const stepForm = this.editFundService.getTabForm(tabNumber);
    if (!stepForm) {
      return null;
    }
    // The form will be in "PENDING" status while async validation is in progress.
    // In that time both valid and invalid will be false, and we don't want to display an error.
    return !stepForm.valid && stepForm.touched && stepForm.status !== 'PENDING';
  }

  saveChanges() {
    const prevCalculationType = this.context.fundDetails.ownershipCalculationType;
    this.isGeneralServerError = false;
    this.editFundService.saveChanges().subscribe(
      updatedFundAndFundraising => {
        if (updatedFundAndFundraising) {
          this.dialogRef.close(updatedFundAndFundraising);
          this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
            this.telemetryService.create({
              eventID: '25',
              eventTitle: 'EDIT FUND (COMPLETED)',
              holdingID: this.context.fundDetails.id,
              organizationID: clientDetails.organizationDetails.id
            }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
            if (updatedFundAndFundraising.fund.ownershipCalculationType !== prevCalculationType) {
              this.telemetryService.create({
                eventID: '701',
                eventTitle: 'HOLDING-OWNERSHIP-CALCULATION-CHANGED',
                holdingID: this.context.fundDetails.id,
                organizationID: clientDetails.organizationDetails.id,
                holdingType: 'Fund',
                newCalculationType: updatedFundAndFundraising.fund.ownershipCalculationType == OwnershipCalculationType.ByCommitments ? 'Commitments' : 'Contribution'
              }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
            }
          });
        }
      },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          this.isGeneralServerError = true;
        }
      });
  }

  deleteFund() {
    const confirmDialogParams = new ConfirmDialogParams();
    confirmDialogParams.actionLabel = 'Delete';
    confirmDialogParams.title = 'Are you sure you want to delete this fund?';
    confirmDialogParams.description = `This fund's information and all its related data will be deleted immediately. You cannot undo this action.`;
    this.dialogService
      .confirmDialog(confirmDialogParams)
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.context.gpFundService
            .deleteFund()
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              response => {
                this.router.navigateByUrl(this.routingService.gpFundsPage);
                this.dialogRef.close(true);
                this.snackbarService.showGeneralMessage(`Fund deleted`);
              },
              error => {
                if (error instanceof BaseResponseDto) {
                  this.utilsService.alertErrorMessage(error);
                } else {
                  this.snackbarService.showGeneralMessage(`Couldn't delete the fund`);
                }
              }
            );
        }
      });
  }
}
