import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';

import { GpAssetService } from '../../gp-asset/gp-asset.service';
import { AssetFundraisingService } from '../../gp-asset/asset-fundraising.service';
import { AssetPollingService } from '../../gp-asset/asset-polling.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { GpHoldingService } from 'src/app/dashboard/shared/holding/gp-holding.service';
import { HoldingFundraisingService } from 'src/app/dashboard/shared/holding/fundraising/holding-fundraising.service';
import { CapitalCallService } from '../../../shared/holding/capital-call/capital-call.service';
import { catchError, tap } from 'rxjs';

@Component({
  selector: 'terra-asset-layout',
  templateUrl: './gp-asset-layout.component.html',
  styleUrls: ['./gp-asset-layout.component.scss'],
  providers: [
    GpAssetService,
    { provide: GpHoldingService, useExisting: GpAssetService },
    AssetFundraisingService,
    {
      provide: HoldingFundraisingService,
      useExisting: AssetFundraisingService,
    },
    AssetPollingService,
    CapitalCallService,
  ],
})
export class GpAssetLayoutComponent extends OnDestroyMixin implements OnInit {
  assetId$ = this.gpAssetService.holdingId$;

  constructor(
    private assetPollingService: AssetPollingService,
    private titleService: Title,
    private gpAssetService: GpAssetService,
    private vcr: ViewContainerRef
  ) {
    super();
    gpAssetService.vcr = vcr;
    gpAssetService.assetPollingService = assetPollingService;

    this.assetPollingService
      .startPoll()
      .pipe(untilComponentDestroyed(this),
      tap(res=>{
        this.titleService.setTitle(
          `${res ? res.assetDetails.name : ''}${
            TerraUtils.consts.GpPageTitleSuffix
          }`
        );
      }),
      catchError(err=>{
        return err;
      })
      )
      .subscribe();
  }

  ngOnInit() {}
}
