import { HostBinding, Directive } from '@angular/core';

import {EditAssetService} from './edit-asset.service';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import InvestmentType from 'src/app/shared/enums/InvestmentType.enum';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

export type EditAssetTabNumber = 1 | 2 | 3 | 4 | 5;

export interface IEditAssetStep {
  readonly TAB_NUMBER: EditAssetTabNumber;
}

@Directive()
export abstract class EditAssetStepBase extends OnDestroyMixin implements IEditAssetStep {

  @HostBinding('attr.class') hostClasses = 'wizard-step';

  // enums
  HoldingDiscriminator = HoldingDiscriminator;

  abstract TAB_NUMBER: EditAssetTabNumber;

  isSubmitAttempt = false;

  get isInitialFundRaising() {
    return this.dialogContext.asset.status === HoldingStatus.Fundraising;
  }

  get isUnderManagement() {
    return this.dialogContext.asset.status === HoldingStatus.Owned;
  }

  get isExample() {
    return this.dialogContext.asset.isExample;
  }

  private _editAssetService: EditAssetService;

  get dialogContext() {
    if (this._editAssetService) {
      return this._editAssetService.editAssetDialogContext;
    }
  }

  get selectedSecurityType() {
    return this._editAssetService.selectedSecurityType;
  }

  get selectedAreaUnit(): AreaUnit {
    try {
      return Number(this._editAssetService.getTabForm(1).get('areaUnits').value) as AreaUnit;
    } catch {
      return AreaUnit.SquareFeet;
    }
  }

  set selectedAreaUnit(value: AreaUnit) {
    this._editAssetService.getTabForm(1).get('areaUnits').setValue(value);
  }

  get selectedInvestmentType() {
    try {
      return this._editAssetService.getTabForm(1).get('investmentType').value as InvestmentType;
    } catch {
      return null;
    }
  }

  get totalLoans() {
    let totalCapitalization: number;
    let seniorLoan: number;
    let juniorLoan: number;
    try {
      totalCapitalization = this._editAssetService.getTabForm(2).get('cumulativeInformation.totalCapitalization').value;
      if (!totalCapitalization) {
        seniorLoan = +(this._editAssetService.getTabForm(2).get('lenderInformation.amount').value || 0);
        juniorLoan = +(this._editAssetService.getTabForm(2).get('lenderInformation.amountJunior').value || 0);
      }
    } catch {
      return null;
    }
    const result = totalCapitalization || (seniorLoan + juniorLoan);
    if (totalCapitalization !== result) {
      this._editAssetService.getTabForm(2).get('cumulativeInformation.totalCapitalization').setValue(result);
    }
    return result;
  }

  constructor(editAssetService: EditAssetService) {
    super();
    this._editAssetService = editAssetService;
  }

  get pageForm() {
    return this._editAssetService.getTabForm(this.TAB_NUMBER);
  }

  stepSubmit() {
    this.isSubmitAttempt = true;
    this.pageForm.markAllAsTouched();

    this.pageForm.updateValueAndValidity();
    if (this.pageForm.valid) {
      this._editAssetService.isGeneralServerError = false;
      this._editAssetService.saveChanges();
    }
  }
}
