import DistributionTransactionPurpose from '../../../../../../shared/enums/DistributionTransactionPurpose.enum';
import {CurrencyModel} from '../../../../../../shared/models/CurrencyModel';
import DistributionPeriod from '../../../../../../shared/enums/DistributionPeriod.enum';

export class DistributionDetailsItemReqRes {
  public distributionPeriod: DistributionPeriod;
  public distributionName: string;
  public distributionStart: Date;
  public distributionEnd: Date;
  public quarter: number;
  public year: number;
  public type: DistributionTransactionPurpose;
  public typeOther: string;
  public currency: CurrencyModel;
  public isFormValid: boolean;

  constructor() {
    const currentDate = new Date();
    this.quarter = Math.ceil((currentDate.getMonth() + 1) / 3);
    this.year = currentDate.getFullYear();
  }
}
