import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../services/shared/user.service';
import { RoutingService } from '../services/routing.service';

@Injectable({
  providedIn: 'root'
})
export class EmailAlreadyVerifiedGuard  {

  constructor(private userService: UserService, private routingService: RoutingService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean |
    UrlTree |
    Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree> {

    return this.userService.accountDetails$.pipe(map(accountDetais => {
      // User's email is already verified, should be redirected to his default page
      if (accountDetais.mustVerifyEmail === false) {
        return this.router.parseUrl(this.routingService.defaultLoggedInPageBySubdomain);
      }
      // email is not verified, he can access the verify email page
      return true;
    }));
  }
}
