import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="card">
      <!-- <h3 class="card-header">{{ props.label }}</h3> -->
      <div class="card-body">
        <ng-container #fieldComponent />
      </div>
    </div>
  `,
})
export class PanelWrapperComponent extends FieldWrapper {}