import { Component, computed, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatRadioModule,
    FormlyMatToggleModule,
    
    MatSlideToggleModule,
    MatAutocompleteModule,
    FormlyModule,

    MatSelectModule,
    NgxMatSelectSearchModule

  ],
  styles: [`
    :host {
      display: block;
      width: 100%;
      --mat-form-field-container-height: 40px;
      --mat-form-field-container-vertical-padding: 8px;

      --mdc-switch-handle-surface-color: #fff;
      --mdc-switch-unselected-handle-color: #fff;
      --mdc-switch-unselected-hover-handle-color: #fff;
      --mdc-switch-unselected-focus-handle-color: #fff;
      --mdc-switch-unselected-pressed-handle-color: #fff;

      .mdc-form-field--align-end>label {
        padding-right: .75rem; // FIX THIS
      }
    }

    ::ng-deep .mat-mdc-option {
      --mat-option-selected-state-label-text-color: #000;
    }
  `],
  template: `
    <div class="full-width flex flex-column">
    <label class="mb-1">{{ props.label }}</label>
    <input
      hidden
      matInput
      [formControl]="formControl"
      [formlyAttributes]="field"
      [errorStateMatcher]="errorStateMatcher"
    />

    <mat-radio-group color="primary" [formControl]="radioOption" class="full-width flex flex-column gap-1">
    <div class="full-width flex flex-row align-items-center justify-content-between">
      <mat-radio-button value="properties">Select properties</mat-radio-button>
      <mat-slide-toggle [formControl]="inactive" color="primary" labelPosition="before" hideIcon>Show inactive properties</mat-slide-toggle>
    </div>
    <mat-form-field color="primary" appearance="outline">
    <mat-select [formControl]="propertiesControl" color="primary" panelClass="rm-theme" placeholder="Select from list" [multiple]="true" #multiSelect>
      <mat-select-trigger>
        @if ( (propertiesControl.value?.length || 0) ==  filteredProperties().length) {
          <span class="example-additional-selection">All Properties ({{filteredProperties().length}})</span>
        } @else { 
          {{selectedTitle}}
        }
    </mat-select-trigger>
      @if(!propertiesDisabled) {
        <mat-option>
          <ngx-mat-select-search 
              [showToggleAllCheckbox]="true"
              [toggleAllCheckboxIndeterminate]="isIndeterminate"
              [toggleAllCheckboxChecked]="isChecked"
              (toggleAll)="toggleSelectAll($event)"
              [formControl]="searchFilterCtrl" 
              [placeholderLabel]="props.placeholder"
              noEntriesFoundLabel="No matching properties found" />
        </mat-option>
      }
      @for (prop of filteredProperties(); track prop) {
        <mat-option [disabled]="propertiesDisabled" [value]="prop.value || prop.id">{{ prop.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-radio-button value="groups">Select group</mat-radio-button>
  <mat-form-field color="primary" appearance="outline">
    <mat-select color="primary" [formControl]="groupsControl" placeholder="Select from list" [multiple]="true" #groupMultiSelect >
      @for(group of props.groups; track group){
        <mat-option [value]="group.value || group.id">{{ group.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  </mat-radio-group>
  </div>
  `,
})
export class PropertiesSelectTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  radioOption: FormControl<'properties' | 'groups'> = new FormControl('properties');
  propertiesControl = new FormControl({ value: null, disabled: false });
  groupsControl = new FormControl({ value: null, disabled: true });
  inactive = new FormControl(false);
  propertiesDisabled = false;

  searchFilterCtrl = new FormControl(null); // search test

  isIndeterminate = false; // TODO: change to computed signal
  isChecked = false;

  showInactive = signal(false);
  searchValue = signal('');
  filteredProperties = computed(() => {
    return this.props.properties.filter((prop) => prop.name.toLowerCase().includes(this.searchValue()) && (this.showInactive() ? true : prop.isActive));
  });

  get selectedTitle() {
    const selected = this.propertiesControl.value;
    if (!selected?.length) {
      return '';
    }

    const selectedProperties = this.filteredProperties().filter((prop) => selected.includes(prop.value || prop.id));
    const firstPropertyName = selectedProperties[0]?.name;
    return selected.length === 1 ? firstPropertyName : `${selected.length} selected`;
   }

  ngOnInit() {
    this.init()
  }


  init() {

    if(this.formControl?.value){
        this.radioOption.patchValue('properties');
        this.propertiesControl.patchValue(this.formControl.value);
    }

    this.radioOption.valueChanges.subscribe((value) => {
      if (value === 'properties') {
        this.propertiesControl.enable();
        this.groupsControl.patchValue(null);
        this.groupsControl.disable();
        this.propertiesDisabled = false;
      } else {
        this.propertiesControl.patchValue(null);
        this.groupsControl.enable();
        this.propertiesDisabled = true;
        // reset Select All
        this.isChecked = false;
        this.isIndeterminate = false
      }

      this.formControl.patchValue(null);
    });

    this.propertiesControl.valueChanges.subscribe((value) => {
      this.formControl.patchValue(value);
      this.isIndeterminate = this.propertiesControl.value?.length > 0 && this.propertiesControl.value?.length < this.filteredProperties().length;
    });

    this.groupsControl.valueChanges.subscribe((value: number[] | undefined) => {
      const selected = this.filteredProperties().filter(property => 
        property.propertyGroupsIds.some(id => value?.includes(id))
      );

      this.propertiesControl.patchValue(selected.map((prop) => (prop.value || prop.id)));
  
    });

    this.inactive.valueChanges.subscribe((value) => {
      
        if ((this.propertiesControl.value?.length || 0) ==  this.filteredProperties().length){
          if (value){
            this.propertiesControl.patchValue( this.props.properties.map((prop) => (prop.value || prop.id)));
          } else {
            this.propertiesControl.patchValue( this.props.properties.filter((prop) => prop.isActive).map((prop) => (prop.value || prop.id)));
          }
          this.isIndeterminate = false;
        } else {
          const selecteditems = this.props.properties.filter((prop) => this.propertiesControl.value?.includes(prop.value || prop.id)) || [];
          if (value){
            this.propertiesControl.patchValue( selecteditems.map((prop) => (prop.value || prop.id)));
          } else {
            this.propertiesControl.patchValue( selecteditems.filter((prop) => prop.isActive).map((prop) => (prop.value || prop.id)));
          }
        }

      this.showInactive.set(value);
    });

    this.searchFilterCtrl.valueChanges
    .subscribe((value) => {
      this.searchValue.set(value);
    });
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.propertiesControl.patchValue(selectAllValue ? this.filteredProperties().map((prop) => (prop.value || prop.id)) : []);
  }
}
