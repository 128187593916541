import { Component, OnInit } from '@angular/core';

import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import FundInvestmentStrategyType from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import { ResourceService } from 'src/app/services/resource.service';
import { CountryModel } from 'src/app/shared/models/CountryModel';
import { GpFundService } from '../gp-fund.service';

export enum FundDraftTab {
  Overview = 0,
  Reports = 1,
  Documents = 2,
  Fundraisings = 3,
  Distributions = 4
}

@Component({
  selector: 'terra-fund-draft-status',
  templateUrl: './fund-draft-status.component.html',
  styleUrls: ['./fund-draft-status.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush, // can't use because it prevents the initial tab to be selected when the component loads.
})
export class FundDraftStatusComponent implements OnInit {
  tabsData = [
    { label: 'Overview', url: 'overview', index: FundDraftTab.Overview },
    { label: 'Reports', url: 'reports', index: FundDraftTab.Reports },
    { label: 'Documents', url: 'documents', index: FundDraftTab.Documents },
    { label: 'Contributions', url: 'contributions', index: FundDraftTab.Fundraisings },
    { label: 'Distributions', url: 'distributions', index: FundDraftTab.Distributions }
  ];

  // Enum:
  Tab = FundDraftTab;

  fund$ = this.gpFundService.holding$;

  // Enums (used in the template)
  HoldingStatus = HoldingStatus;
  HoldingType = HoldingType;
  InvestmentStrategyType = FundInvestmentStrategyType;

  constructor(
    private resourceService: ResourceService,
    private gpFundService: GpFundService) {
  }

  ngOnInit() { }

  getCountryById(countryId: number): CountryModel {
    return this.resourceService.getCountryById(countryId);
  }
}
