<ng-container *ngIf="!(allowInvestorName$ | async)">
  <div class="not-allow-message">
    <mat-icon class="notranslate">error</mat-icon>
    <h4>You do not have permissions to view investors' documents as these documents might include investors' names.</h4>
  </div>
</ng-container>

<ng-container *ngIf="allowInvestorName$ | async">
  <ng-container *ngIf="(showDocumentsTab$ | async); else documentsPreview">
    <!-- Filters and actions -->
    <section class="header" [formGroup]="pageForm" *ngIf="holdingId$ | async as holdingId">

      <!-- Create Folder -->
      <button type="button" mat-stroked-button color="primary" (click)="createFolder()"
        class="create-folder covercy-wired-button" *ngIf="!(isRealized$ | async)">
        <mat-icon class="open_icon notranslate">add</mat-icon>
        New Folder
      </button>

      <!-- Upload Files -->
      <button mat-flat-button [matMenuTriggerFor]="menu" color="primary"  class="mat-focus-indicator mat-flat-button mat-button-base mat-primary" *ngIf="!(isRealized$ | async)">
        <mat-icon class="open_icon notranslate">upload</mat-icon> Upload
      </button>
      <mat-menu #menu="matMenu" xPosition="before" class="upload-menu">
        <button mat-menu-item class="menu-item-button" mat-menu-item (click)="navigateToUploadAndShare()" >
          <div class="upload-menu-item">
            <span class="menu-item-title">Match Automatically</span>
            <span class="menu-item-subtitle">Upload and automatically match documents, like K1 reports, agreements and more.</span>
          </div>
        </button>
        <button mat-menu-item class="menu-item-button" (click)="openFilePicker()">
          <div class="upload-menu-item">
            <span class="menu-item-title">Upload</span>
            <span class="menu-item-subtitle">Upload single or multiple files</span>
          </div>
        </button>
      </mat-menu>
      <!-- <button type="button"  mat-stroked-button color="primary" class="covercy-wired-button"
        *ngIf="!(isRealized$ | async)">

        File Upload
      </button> -->

    </section>

    <terra-document-display-table (deleteRow)="deleteItems($event)" />

  </ng-container>

  <ng-template #documentsPreview>
    <div class="feature-locked">
      <div class="example-block example-strip">
        <p>
          Manage your documents here.
        </p>
      </div>
      <p class="upsell-text">
        <mat-icon class="notranslate">lock</mat-icon>
        <span>Interested in our premium packages? Please
          <a target="_blank" href="https://www.covercy.com/contact-us" class="contact-us-link link"> contact us</a>
        </span>
      </p>
    </div>
  </ng-template>

</ng-container>
