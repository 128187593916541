import {catchError, debounceTime, filter, map, shareReplay, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Component, EventEmitter, Inject, LOCALE_ID, OnInit, ViewContainerRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import memo from 'memo-decorator';

import {TerraUtils} from 'src/app/shared/TerraUtils';
import {DialogService} from 'src/app/services/dialog.service';
import {RoutingService} from 'src/app/services/routing.service';
import {KeyValuePair} from 'src/app/shared/types/KeyValuePair.model';
import DistributionStatus from 'src/app/shared/enums/DistributionStatus.enum';
import {ClientBankAccountReqRes} from 'src/app/dashboard/models/bankAccount.model';
import {GpHoldingService} from 'src/app/dashboard/shared/holding/gp-holding.service';
import DistributionTransferType from 'src/app/shared/enums/DistributionTransferType.enum';
import {GpDistributionDataService} from 'src/app/services/gp/gp-distribution-data.service';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {DistributionReqRes} from 'src/app/dashboard/shared/holding/distribution/DistributionReqRes.model';
import {ISendPaymentConfirmationContext, SendPaymentConfirmationComponent} from 'src/app/dashboard/shared/holding/distribution/send-payment-confirmation/send-payment-confirmation.component';
import {DepositDetailsDialogComponent, DepositDetailsDialogContext} from 'src/app/dashboard/shared/holding/distribution/deposit-details-dialog/deposit-details-dialog.component';
import {DistributionTransferReqRes} from 'src/app/dashboard/shared/holding/distribution/DistributionTransferReqRes.model';
import {AlertDialogParams} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {GpBankAccountDataService} from 'src/app/services/gp/gp-bank-account-data.service';
import {TerraCurrencyNoSymbolPipe} from 'src/app/shared/pipes/TerraCurrency.pipe';
import {ErrorMatcher, ErrorType} from 'src/app/shared/errors/ErrorMatcher';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {ISendNoticeConfirmationContext, SendNoticeConfirmationComponent} from 'src/app/dashboard/shared/holding/distribution/send-notice-confirmation/send-notice-confirmation.component';
import DistributionTransactionPurpose from '../../../../../shared/enums/DistributionTransactionPurpose.enum';
import DistributionPeriod from '../../../../../shared/enums/DistributionPeriod.enum';
import {CurrencyModel} from '../../../../../shared/models/CurrencyModel';
import {ResourceService} from '../../../../../services/resource.service';
import {UserService} from 'src/app/services/shared/user.service';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {BasePhoneVerificationStrategy} from '../../../../../shared/models/phone-verification-strategies/base-phone-verification-strategy.model';
import {DistributionService} from '../distribution.service';
import {AccountType} from 'src/app/shared/enums/AccountType.enum';
import DistributionTransactionOtherType from '../../../../../shared/enums/DistributionTransactionOtherType.enum';
import UnitStatus from '../../../../../shared/enums/UnitStatus.enum';
import HoldingDiscriminator from '../../../../../shared/enums/HoldingDiscriminator.enum';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';
import {PermissionService} from 'src/app/permission/permission.service';
import {NoPermissionAction} from 'src/app/shared/directives/check-permissions.directive';
import PermissionLevel from 'src/app/permission/enums/permissionLevel.enum';
import {FinancialPermissionLevel} from 'src/app/permission/enums/financialPermissionLevel.enum';
import {HighHolidaysNotificationComponent} from '../../../../../shared/components/high-holidays-notification/high-holidays-notification.component';
import {X} from '@angular/cdk/keycodes';
import {UnitBankAccountReqRes} from 'src/app/shared/models/gp/UnitBankAccountReqRes.model';
import {MergeUnitClientBankAccountDto} from 'src/app/shared/models/MergeUnitClientBankAccountDto.model';
import MergeUnitClientBankAccountType from 'src/app/shared/enums/MergeUnitClientBankAccountType.enum';
import {PaymentType} from 'src/app/shared/enums/payment-type.enum';
import {GeneralSettingsService} from 'src/app/services/shared/general-settings.service';
import UnitAccountProvider from 'src/app/shared/enums/UnitAccountProvider.enum';
import {UnitBankAccountListItem} from 'src/app/shared/models/gp/UnitBankAccountListItem.model';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';

@Component({
  selector: 'terra-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss'],
  providers: [
    DistributionService,
    GpDistributionDataService,
    {provide: BasePhoneVerificationStrategy, useClass: DistributionService},
  ]
})
export class DistributionComponent extends OnDestroyMixin implements OnInit {
  noAccessToBanksMessage = '';
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  isAllContactsVisible$ = this.permissionService.isAllContactsVisible$;
  private highHolidaysMessageDisplayed = false;
  readonly UNIT_TRANSACTION_FEE = 0;
  readonly USCountryId = this.resourceService.getCountryByCode(TerraUtils.consts.countryCode.US).id;

  distributionTransactionOtherType = DistributionTransactionOtherType;
  distributionTransactionOtherTypeAll = DistributionTransactionOtherType.listAll();
  distributionTransactionPurposeAll = DistributionTransactionPurpose.listAll();
  distributionTransactionPurposeAllButMulti = this.distributionTransactionPurposeAll.filter((item) => item !== DistributionTransactionPurpose.MultiType);
  editDistributionReasonsToRenderList;
  distributionTransactionPurpose = DistributionTransactionPurpose;
  distributionStatus = DistributionStatus;
  unitStatus = UnitStatus;
  UnitPaymentType = PaymentType;
  NoPermissionAction = NoPermissionAction;
  FinancialPermissionLevel = FinancialPermissionLevel;
  PermissionLevel = PermissionLevel;
  loadingAction$ = new BehaviorSubject<boolean>(false);

  // distributionCalculationType = DistributionCalculationTypeEnum;
  // selectedDistributionCalculationTypeIndex = 0;

  readonly MISSING_CURRENCY_TITLE_ERROR = 'Bank account currency is missing. Please choose a different bank account from the list, or create a new one.';
  readonly INVESTOR_UPDATE_ACCOUNT_MSG = 'The Investor updated/added the bank details, please review.';
  readonly MISSING_BANK = 'Missing bank account';
  discriminatorStr = this.gpHoldingService.discriminatorStr;
  discriminatorLowerCaseStr = this.gpHoldingService.discriminatorLowercaseStr;

  bankAccounts: KeyValuePair<number, MergeUnitClientBankAccountDto[]>;
  clientBankAccounts: KeyValuePair<number, ClientBankAccountReqRes[]>;
  unitBankAccounts: KeyValuePair<number, UnitBankAccountReqRes[]>;

  bankAccountsForGpPromote: MergeUnitClientBankAccountDto[];
  clientBankAccountsForGpPromote: ClientBankAccountReqRes[];
  unitBankAccountsForGpPromote: UnitBankAccountReqRes[];

  distribution: DistributionReqRes;
  pageForm: UntypedFormGroup;

  initialFormValue: any;
  distributionId: number;
  holdingId: number;
  showInternalLoader = false;
  isDeletingTransfer = false;
  isActionFailed = false;
  actionFailedError = '';
  negativeBalanceError = '';
  amountAchExceedsLimitsError = '';
  amountWireExceedsLimitsError = '';
  isSubmitted = false;
  isGeneralServerError = false;
  isFormError = false;
  errorMarkingAsComplete = false;
  feeCurrency = '';
  feeSymbol = '';
  isShowCovercyBankAccountDetailsOnLoad = false;
  canBeEdited = false;

  isExternalDistribution: boolean;

  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;
  DistributionPeriod = DistributionPeriod;
  holdingWithUnitBankAccount: boolean;

  totalFeeLoading$ = new BehaviorSubject<boolean>(false);
  refreshAmounts$ = new EventEmitter<void>();
  bankApproved$ = new EventEmitter<void>();
  revertAllChanges$ = new EventEmitter<void>();
  updateFormAmounts$ = new Subject<void>();
  updateGpPromote$ = new Subject<void>();
  holdingId$ = this.gpHoldingService.holdingId$;
  holding$ = this.gpHoldingService.holding$;
  organizationTypeCreBankAccounts$ = this.distributionService.OrganizationTypeCreBankAccounts$.pipe(shareReplay(1));

  wireTransactionFee$ = this.generalSettingsService.GetByKey('WireTransactionFee').pipe(shareReplay(1));
  // creBanksListByHolding$ = this.distributionService.creBanksListByHolding$.pipe(shareReplay(1));

  currentYear = new Date().getFullYear();
  yearsList = Array.from({length: 25}, (x, i) => {
    return this.currentYear - i;
  });
  periodMinDate = new Date(this.currentYear - 10, 0, 1);
  periodMaxDate = new Date(this.currentYear + 1, 11, 31);

  signersColumns: string[] = ['name', 'signed', 'signDate'];

  CreAccountType = AccountType;

  organizationDetailsId$ = this.userService.accountDetails$
    .pipe(
      map(accountDetails => accountDetails.organizationDetails.id),
      shareReplay(1)
    );

  params$ = this.route.params.pipe(
    untilComponentDestroyed(this),
    take(1),
    shareReplay(1)
  );

  private signersRefresh$ = this.gpHoldingService.signersRefresh$;

  currencies$: Observable<CurrencyModel[]> = this.resourceService.getOutboundCurrencies()
    .pipe(
      map(response => response.filter(c => c.isInbound)),
      shareReplay(1)
    );

  reasonsForTransfer$ = this.distributionService.reasonsForTransfer$;

  get investorsDistributionTransfer() {
    if (this.distribution && this.distribution.distributionTransfers && this.distribution.distributionTransfers.length > 0) {
      const lpTransfers = this.distribution.distributionTransfers.filter(dt => dt.type !== DistributionTransferType.GP);
      if (lpTransfers && this.canBeEdited) {
        lpTransfers.forEach(transfer => {
          transfer.invalidForm = transfer.adjustmentsComments && transfer.adjustmentsComments.length > TerraUtils.consts.validators.SHORT_TEXT_LENGTH;
          this.isExternalDistribution = transfer.externalPayment;
        });
      }

      this.distributionService.updateTotalTransferAmounts(this.distribution.distributionTransfers);
      // this.investorReasonsForTransfer = this.distributionService.getReasonsForTransfer(res);
      return lpTransfers;
    }
    return null;
  }


  get gpDistributionTransfer() {
    if (this.distribution && this.distribution.distributionTransfers && this.distribution.distributionTransfers.length > 0) {
      const gpTransfer = this.distribution.distributionTransfers.find(dt => dt.type === DistributionTransferType.GP);
      if (gpTransfer && this.canBeEdited) {
        gpTransfer.invalidForm = gpTransfer.adjustmentsComments && gpTransfer.adjustmentsComments.length > TerraUtils.consts.validators.SHORT_TEXT_LENGTH;
      }

      if (!gpTransfer) {
        return null;
      }

      this.distributionService.updateTotalTransferAmounts([gpTransfer]);
      return gpTransfer;
    }
    return null;
  }

  get enableNotice$(): Observable<boolean> {
    if (!this.distribution) {
      return of(false);
    }
    // Conditions which prohibit sending notice
    let conditions$ = [of(false)];

    if (!this.isNoticeSent &&
      !this.isPayoutSent &&
      this.distribution.status === DistributionStatus.New) {
      conditions$ = [of(true)];
    }

    // Holding is down ? can't send notice
    conditions$.push(this.holding$.pipe(map(holding => holding.isPrivate === false)));

    // if at least one condition prohibits sending notice, it should be disabled
    return combineLatest(conditions$).pipe(
      map((conditions: boolean[]) => {
        return conditions.every(x => x === true);
      }));
  }

  get isNoticeSent() {
    if (!this.distribution) {
      return false;
    }

    if (this.distribution.noticeSentTimeStamp) {
      return true;
    }
    return false;
  }

  get isPayoutSent() {
    if (!this.distribution) {
      return false;
    }

    if (this.distribution.payoutSentTimeStamp) {
      return true;
    }
    return false;
  }

  get enablePayout$(): Observable<boolean> {
    // first check any condition with no observable, that should force the button to be disabled
    // if (this.noAccessToBanksMessage ||
    if (
      !this.distribution
      || this.isPayoutSent
      || this.anyUsBankHasNonUsdCurrency() ||
      !(this.distribution.status === DistributionStatus.New || this.distribution.status === DistributionStatus.NoticeSent)) {
      return of(false);
    }
    // second, check conditions in observable:
    return this.holding$.pipe(map(holding => holding.isPrivate === false));
  }

  get isFeeEstimated() {
    if (this.distribution) {
      return (this.distribution.status === DistributionStatus.New ||
          this.distribution.status === DistributionStatus.NoticeSent) &&
        (this.distribution.distributionSourceCurrency.iso !== 'USD');
    }
    return null;
  }

  get isDeleteAllowed() {
    if (!this.distribution) {
      return false;
    }

    return this.distribution.isDeleteAllowed;
  }

  get isDistributingAnyAmountViaCovercy() {
    if (!this.distribution) {
      return false;
    }
    return this.distribution.fees.totalAmountViaCovercy > 0;
  }

  get isUnitBankAccount() {
    if (!this.distribution) {
      return false;
    }
    return this.distribution.unitBankAccount != null;
  }

  get showFinishedDistributionNotification() {
    if (!this.distribution) {
      return false;
    }
    return this.distribution.isManualCompletionAllowed;
  }

  get periodStartDate() {
    return this.pageForm.get('periodStartDate') as UntypedFormControl;
  }

  get periodEndDate() {
    return this.pageForm.get('periodEndDate') as UntypedFormControl;
  }

  get cfsbBank() {
    return this.distribution && this.distribution.unitTransactionId && this.distribution.distributionCovercySourceBankAccount;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private gpHoldingService: GpHoldingService,
    private titleService: Title,
    public routingService: RoutingService,
    public gpDistributionDataService: GpDistributionDataService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private _locale: string,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private gpBankAccountDataService: GpBankAccountDataService,
    private userService: UserService,
    private telemetryService: TelemetryService,
    private resourceService: ResourceService,
    private distributionService: DistributionService,
    private vcr: ViewContainerRef,
    private utilsService: UtilsService,
    private permissionService: PermissionService,
    private generalSettingsService: GeneralSettingsService,
    private numberPipe: TerraNumberPipe
  ) {
    super();
  }

  ngOnInit() {
    this.titleService.setTitle('Manage Distribution' + TerraUtils.consts.GpPageTitleSuffix);

    this.params$.subscribe(params => {
      this.distributionId = +params.distributionId;
    });

    this.route.queryParams
      .pipe(
        untilComponentDestroyed(this)
      ).subscribe(params => {
      if (params && +params.showbankdetails === 1) {
        this.isShowCovercyBankAccountDetailsOnLoad = true;
      }
    });

    // this.fetchData();

    this.gpHoldingService.distributions$.pipe(
      untilComponentDestroyed(this),
      map(distributions => {
        return distributions.find(d => d.id === this.distributionId);
      }),
    ).subscribe(distribution => {
      this.fetchData();
    });

    this.handleBankApproved();

    this.holdingId$.subscribe((holdingId) => {
      this.holdingId = holdingId;
    });

    this.holding$.subscribe(holding => {
      this.holdingWithUnitBankAccount = !!(holding.unitApplications?.length && holding.unitApplications[0].bankAccounts?.length);
    });
  }

  @memo()
  holdingPageUrl(holdingId: number) {
    return this.gpHoldingService.getHoldingPageWithTabRoute(holdingId, 'distributions');
  }

  currencyCompareFn(c1: CurrencyModel, c2: CurrencyModel) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  enableEditing(status: DistributionStatus) {
    return status === DistributionStatus.New
      || status === DistributionStatus.NoticeSent
      || status === DistributionStatus.Completed
      || status === DistributionStatus.WaitingForFunds
      || status === DistributionStatus.ProcessingTransfers
      || status === DistributionStatus.PartialCompleted;
  }

  editDistribution() {
    const openDialogFunc = (msg) => {
      const confirmDialogParams = new ConfirmDialogParams();
      confirmDialogParams.title = 'Are you sure?';
      confirmDialogParams.description = msg;
      confirmDialogParams.actionLabel = 'Edit';
      this.dialogService
        .confirmDialog(confirmDialogParams)
        .afterClosed().pipe(
        take(1),
        filter(c => !!c)
      )
        .subscribe(_ => this.router.navigate(['./edit'], {relativeTo: this.route}));
    };

    if (this.distribution.status === DistributionStatus.NoticeSent) {
      openDialogFunc(`You've already sent a notice to your investors including the distribution period and amounts.`);
    } else if (this.distribution.status === DistributionStatus.Completed
      || this.distribution.status === DistributionStatus.WaitingForFunds
      || this.distribution.status === DistributionStatus.ProcessingTransfers
      || this.distribution.status === DistributionStatus.PartialCompleted
    ) {
      openDialogFunc(`This distribution was already finalized and is visible to your investors.`);
    } else {
      this.router.navigate(['./edit'], {relativeTo: this.route});
    }
  }

  populatePageForm() {
    const quarterName = this.distribution.name.slice(0, 2);
    const period = ['Q1', 'Q2', 'Q3', 'Q4'].includes(quarterName) ? DistributionPeriod.Quarterly : DistributionPeriod.Custom;
    const quarter = ['Q1', 'Q2', 'Q3', 'Q4'].includes(quarterName) ? +quarterName.slice(1) : 1;
    const distYear = period === DistributionPeriod.Quarterly ? +this.distribution.name.slice(3) : this.currentYear;
    this.pageForm = this.fb.group({
      distributionPeriod: [period],
      year: [distYear, Validators.required],
      quarter: [quarter, Validators.required],
      periodStartDate: [this.distribution.periodStartTimeStamp],
      periodEndDate: [this.distribution.periodEndTimeStamp],
      distributionSourceCurrency: [this.distribution.distributionSourceCurrency, Validators.required],
      distributionTransactionPurpose: [this.distribution.reasonForTransaction, Validators.required],
      distributionTransactionPurposeOther: [this.distribution.reasonForTransactionText],
      distributionTransactionPurposeOtherType: [this.distribution.transactionOtherType]
    });

    this.pageForm.get('distributionTransactionPurpose').valueChanges.subscribe(val => {
      if (val === DistributionTransactionPurpose.Other) {
        this.pageForm.get('distributionTransactionPurposeOther').setValidators([Validators.required, Validators.maxLength(200)]);
      } else {
        this.pageForm.get('distributionTransactionPurposeOther').setValidators([Validators.maxLength(200)]);
      }
      this.pageForm.get('distributionTransactionPurposeOther').updateValueAndValidity();
    });

    this.initialFormValue = this.pageForm.value;
  }

  fetchData() {
    this.isGeneralServerError = false;
    this.showInternalLoader = false;

    this.holdingId$.pipe(
      take(1))
      .subscribe(holdingId => {
        forkJoin({
          distribution: this.gpDistributionDataService.getById(holdingId, this.distributionId),
          bankAccountsPerInvestingEntityId: combineLatest([this.gpBankAccountDataService.getListByHolding(holdingId), this.organizationTypeCreBankAccounts$, this.gpBankAccountDataService.getCreBanksListByHolding(holdingId)]).pipe(
            map(([creBanksByHolding, OrgTypeCreBanks, creBanksListByHolding]) => {
              if (OrgTypeCreBanks) {
                this.unitBankAccountsForGpPromote = [...OrgTypeCreBanks, ...creBanksListByHolding];

                const lpBankAccount: MergeUnitClientBankAccountDto[] = [];
                OrgTypeCreBanks.forEach(b => lpBankAccount.push(TerraUtils.convertUnitBankAccountToMergeUnitClient(b)));
                creBanksListByHolding.forEach(b => lpBankAccount.push(TerraUtils.convertUnitBankAccountToMergeUnitClient(b)));
                creBanksByHolding[0] = [...creBanksByHolding[0], ...lpBankAccount];
              }
              return creBanksByHolding;
            }),
            catchError(async err => {
              if (ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)) {
                this.noAccessToBanksMessage = err.responseMessage;
              }
              return new KeyValuePair<number, MergeUnitClientBankAccountDto[]>();
            })
          ),
          clientBankAccountsPerInvestingEntityId: this.distributionService.clientBankAccountsPerInvestingEntityId$.pipe(
            take(1),
            catchError(async err => {
              if (ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)) {
                this.noAccessToBanksMessage = err.responseMessage;
              }
              return new KeyValuePair<number, ClientBankAccountReqRes[]>();
            })
          ),
          unitBankAccountsPerInvestingEntityId: this.distributionService.unitBankAccountsPerInvestingEntityId$.pipe(
            take(1),
            catchError(async err => {
              if (ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)) {
                this.noAccessToBanksMessage = err.responseMessage;
              }
              return new KeyValuePair<number, UnitBankAccountReqRes[]>();
            })
          ),
        })
          .pipe(
            tap(() => {
              this.isGeneralServerError = false;
              this.showInternalLoader = false;
            }),
            withLatestFrom(this.userService.accountDetails$)
          )
          .subscribe(([data, userDetails]) => {
              this.distribution = data.distribution;
              if (userDetails.isHolidayPopupActive &&
                userDetails.holidayPopupTitle &&
                userDetails.holidayPopupDescription) {
                this.displayHighHolidaysNotification(userDetails);
              }
              this.editDistributionReasonsToRenderList = this.distribution.reasonForTransaction === DistributionTransactionPurpose.MultiType
                ? this.distributionTransactionPurposeAll
                : this.distributionTransactionPurposeAllButMulti;
              this.feeCurrency = this.distribution.distributionSourceCurrency.iso;
              this.feeSymbol = this.distribution.distributionSourceCurrency.symbol;
              this.populatePageForm();
              if (this.isShowCovercyBankAccountDetailsOnLoad) {
                this.showDepositAccountDetails();
              }
              this.bankAccountsForGpPromote = data.bankAccountsPerInvestingEntityId[0];
              this.clientBankAccountsForGpPromote = data.clientBankAccountsPerInvestingEntityId[0];

              this.bankAccounts = data.bankAccountsPerInvestingEntityId;
              this.clientBankAccounts = data.clientBankAccountsPerInvestingEntityId;
              this.unitBankAccounts = data.unitBankAccountsPerInvestingEntityId;

              this.distribution.distributionTransfers && this.distribution.distributionTransfers.every(t => !t.orderId && t.externalPayment);
              this.disableDistributionEdit();

              const reasonsForTransfer = this.distributionService.getReasonsForTransfer(this.distribution.distributionTransfers);
              this.distribution.distributionTransfers.forEach(dt => {
                dt.distributionTransfersDetails.sort((a, b) => reasonsForTransfer.indexOf(a.reasonForTransaction) - reasonsForTransfer.indexOf(b.reasonForTransaction));
              });
            },
            error => {
              this.showInternalLoader = false;
              if (ErrorMatcher.isError(error, ErrorType.RecordNotFoundException)) {
                this.backToHoldingPage();
              } else {
                this.isGeneralServerError = true;
              }
            });
      });
  }

  getLpClientDetailsIdFromTransfer(dt: DistributionTransferReqRes) {
    if (dt && dt.investingEntity && dt.investingEntity.contact && dt.investingEntity.contact.lpClientDetailsId) {
      return dt.investingEntity.contact.lpClientDetailsId;
    }
    return null;
  }

  getBankAccountClientIdFromTransfer(dt: DistributionTransferReqRes) {
    if (dt && dt.clientBankAccount) {
      return dt.clientBankAccount.clientDetailsId;
    }
    return null;
  }

  showUnsavedChangesAlert() {
    // make sure there are no unsaved changes before finalizing.
    this.dialogService.alertDialog(new AlertDialogParams('Unsaved Changes', 'You have unsaved changes in one or more of the transfers. In order to proceed with this action, please first save or revert your changes.'));
  }

  showSignConfirmation() {
    const dialogParams = new ConfirmDialogParams();
    dialogParams.actionLabel = `Finalize`;
    dialogParams.title = `Finalize this distribution?`;

    const dialogRef = this.dialogService.confirmDialog(dialogParams);
    return dialogRef.afterClosed().pipe(untilComponentDestroyed(this));
  }

  showPayoutConfirmation() {
    return this.holding$.pipe(
      take(1),
      switchMap(holding => {
        const config = new MatDialogConfig<ISendPaymentConfirmationContext>();
        config.data = {
          holdingName: holding.name,
          totalAmountForDistribution: this.distribution.fees.totalAmountViaCovercy + this.distribution.fees.totalAmountOutsideOfCovercy,
          distributionSourceCurrency: this.distribution.distributionSourceCurrency,
          distribution: this.distribution,
          transfers: this.investorsDistributionTransfer,
          personalMessage: this.distribution.payoutPersonalMessage,
          holdingService: this.gpHoldingService,
        } as ISendPaymentConfirmationContext;
        config.viewContainerRef = this.vcr;
        return this.dialog.open(SendPaymentConfirmationComponent, config).afterClosed();
      }),
      filter(isConfirmed => isConfirmed)
    );
  }

  showNoticeConfirmation() {
    return this.holding$.pipe(
      take(1),
      switchMap(holding => {
        const config = new MatDialogConfig<ISendNoticeConfirmationContext>();
        config.data = {
          holdingName: holding.name,
          totalAmountForDistribution: this.distribution.fees.totalAmountViaCovercy + this.distribution.fees.totalAmountOutsideOfCovercy,
          distributionSourceCurrency: this.distribution.distributionSourceCurrency,
          distributionName: this.distribution.name,
          transfers: this.investorsDistributionTransfer,
          personalMessage: this.distribution.noticePersonalMessage,
          allowLpSetBank: holding.allowLpSetBank
        } as ISendNoticeConfirmationContext;
        config.viewContainerRef = this.vcr;
        return this.dialog.open(SendNoticeConfirmationComponent, config).afterClosed();
      }),
      filter(confirmationWithMessage => confirmationWithMessage)
    );
  }

  backToHoldingPage() {
    this.holdingId$.pipe(take(1)).subscribe(holdingId => {
      this.router.navigateByUrl(this.holdingPageUrl(holdingId));
    });
  }

  disableDistributionEditReason() {
    this.pageForm.get('distributionTransactionPurpose').disable();
    this.pageForm.get('distributionTransactionPurposeOther').disable();
    this.pageForm.get('distributionTransactionPurposeOtherType').disable();
  }

  disableDistributionEdit() {
    if (this.pageForm) {
      this.pageForm.get('distributionPeriod').disable();
      this.pageForm.get('year').disable();
      this.pageForm.get('quarter').disable();
      this.pageForm.get('periodStartDate').disable();
      this.pageForm.get('periodEndDate').disable();
      this.pageForm.get('distributionSourceCurrency').disable();
      this.pageForm.get('distributionTransactionPurpose').disable();
      this.pageForm.get('distributionTransactionPurposeOther').disable();
      this.pageForm.get('distributionTransactionPurposeOtherType').disable();
    }
  }


  clearTransfersFlags() {
    if (!this.distribution) {
      return;
    }
    this.distribution.distributionTransfers.forEach(x => {
      x.isBankAccountChangeUnsaved = false;
      x.invalidForm = false;
    });
  }

  handleBankApproved() {
    this.bankApproved$.pipe(
      untilComponentDestroyed(this),
    ).subscribe(holdingId => {
      const transferWithBankAccountNotApproved = this.distribution.distributionTransfers.find(dt => !dt.bankAccountDetailsApprovedByGp);
      if (!transferWithBankAccountNotApproved) {
        this.isActionFailed = false;
        this.actionFailedError = '';
      }
    });
  }

  sendNotice() {
    this.organizationDetailsId$.pipe(take(1)).subscribe(orgId => {
      this.telemetryService.create({
        eventID: '104',
        eventTitle: 'DISTRIBUTION - SEND NOTICE (CLICKED)',
        organizationID: orgId,
        additional: {
          distributionID: this.distributionId,
        }
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });

    this.isActionFailed = false;
    this.showNoticeConfirmation()
      .pipe(
        tap(_ => {
          this.showInternalLoader = true;
        }),
        map<any, string>(formValues => formValues.personalMessage),
        withLatestFrom(this.holdingId$),
        switchMap(([personalMessage, holdingId]) =>
          this.gpDistributionDataService.distributionNotice(holdingId, this.distributionId, personalMessage)),
        tap(() => {
          this.isGeneralServerError = false;
          this.showInternalLoader = false;
          this.gpHoldingService.refreshDistribution();
          this.snackbarService.showGeneralMessage('Notice sent');
        }))
      .subscribe(
        distribution => {
          this.distribution = distribution;
          this.organizationDetailsId$.pipe(take(1)).subscribe(orgId => {
            this.telemetryService.create({
              eventID: '105',
              eventTitle: 'DISTRIBUTION - SEND NOTICE (SEND)',
              organizationID: orgId,
              additional: {
                distributionID: distribution.id,
              }
            }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
          });
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            this.actionFailedError = `Couldn't send notice. Please contact support.`;
            this.isActionFailed = true;
          }
          this.showInternalLoader = false;
        });
  }

  makePayout() {
    // if any via-choice transfer and choose a investor wire bank account, stop the payout.
    if (!!this.distribution.unitBankAccount
      && this.distribution.unitBankAccount.provider === UnitAccountProvider.Choice
      && this.distribution.distributionTransfers.some(x => x.amountNet > 0 && !x.externalPayment && !!x.clientBankAccount && x.clientBankAccount.paymentType === PaymentType.Wire)) {
      this.dialogService.alertDialog(new AlertDialogParams('Not Supported Account', 'Wire is not supported by your distribution account.'));
      return;
    }


    // if any via-covercy transfer is missing a bank account, stop the payout.
    if (this.distribution.distributionTransfers.some(x => !x.clientBankAccount && !x.unitBankAccount && !x.externalPayment &&
      x.amountNet > 0)) {
      this.dialogService.alertDialog(new AlertDialogParams('Missing Bank Account(s)', 'Please make sure to set bank accounts for all transfers made via Covercy.'));
      return;
    }

    // if any via-covercy transfer has a bank account with missing preferred currency, stop the payout.
    if (this.distribution.distributionTransfers.some(x => x.amountNet > 0 && !x.externalPayment && (x.clientBankAccountId && !x.clientBankAccount?.preferredCurrencyId))) {
      this.dialogService.alertDialog(new AlertDialogParams(`A chosen currency is missing for distributions bank account`,
        `<p>Please make sure all distribution transfers made via Covercy have a chosen currency set for the bank account.</p>
        <p>Either select a different bank account or create a new one.</p>`));
      return;
    }

    // some accounts are missing location details
    const missingLocationTransfer = this.distribution.distributionTransfers.find(x => x.amountNet > 0 && !x.externalPayment && (!x.clientBankAccount?.holderLocationDetails_Id) && !x.unitBankAccountId);
    if (missingLocationTransfer) {
      this.dialogService.alertDialog(new AlertDialogParams(`Bank account is missing some details`,
        `<p>Investor's bank account is missing some details, please select a different account or create a new one.</p>`))
        .afterClosed().subscribe(_ => {
        TerraUtils.scrollToElementById('distributionTransfer_' + missingLocationTransfer.id, true);
      });
      return;
    }

    // if any  transfer bank account was edited by the lp, and is not approved by the GP yet:
    const transferWithBankAccountNotApproved = this.distribution.distributionTransfers.find(dt => dt.amountNet > 0 && !dt.bankAccountDetailsApprovedByGp);
    if (transferWithBankAccountNotApproved) {
      this.dialogService.alertDialog(new AlertDialogParams('Bank account details changed by investors',
        `Please approve all bank accounts that were changed by the investor.`)).afterClosed().subscribe(() => {
        TerraUtils.scrollToElementById('distributionTransfer_' + transferWithBankAccountNotApproved.id, true);
      });
      return;
    }

    const gpDistribution = this.distribution.distributionTransfers.find(d => d.type === DistributionTransferType.GP && d.amountNet > 0);
    if (gpDistribution && (gpDistribution.clientBankAccountId && !gpDistribution.clientBankAccount.preferredCurrencyId) && !gpDistribution.unitBankAccountId) {
      this.dialogService.alertDialog(new AlertDialogParams('GP Bank account is missing a preferred currency',
        `Please set a preferred payment currency for the GP distribution.`)).afterClosed().subscribe(() => {
        TerraUtils.scrollToElementById('distributionTransfer_' + gpDistribution.id, true);
      });
      return;
    }

    this.isActionFailed = false;
    this.showPayoutConfirmation().pipe(
      tap(() => {
        this.showInternalLoader = true;
        this.distribution.isGeneratingOrders = true;
      }),
      withLatestFrom(this.holdingId$),
      switchMap(([formValues, holdingId]) => {
        return this.gpDistributionDataService.distributionPayout(holdingId, this.distributionId, formValues.personalMessage, formValues.sendEmail);
      })
    ).subscribe(
      distribution => {
        this.organizationDetailsId$.pipe(take(1)).subscribe(orgId => {
          this.distribution = distribution;
          this.telemetryService.create({
            eventID: '106',
            eventTitle: 'DISTRIBUTION - FINALIZE',
            organizationID: orgId,
            additional: {
              distributionID: distribution.id,
            }
          }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
        });
        this.showInternalLoader = false;
        this.isGeneralServerError = false;
        this.gpHoldingService.refreshSigners();
        this.gpHoldingService.refreshDistribution();
        this.snackbarService.showGeneralMessage('Finalized distribution');
      },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          this.actionFailedError = `Couldn't finalize distribution and make payout. `;
          if (ErrorMatcher.isError(error, ErrorType.CovercyInvalidOperationException)) {
            this.actionFailedError = error.error.data;
          } else if (ErrorMatcher.isError(error, ErrorType.DataUsedForOperationWasChangedException)) {
            this.gpHoldingService.refreshDistribution();
            this.dialogService.alertDialog(new AlertDialogParams('Bank account details changed by investors',
              `<p>One or more of the investors had updated their preferred bank account details for distributions
                    on this ${this.discriminatorLowerCaseStr}.</p>
                    <p>Please approve the bank account details. (Marked in red)</p>`)).afterClosed()
              .pipe(
                untilComponentDestroyed(this))
              .subscribe(_ => {
                const transferWhereBankAccountNotApproved = this.distribution.distributionTransfers.find(dt => !dt.bankAccountDetailsApprovedByGp);
                if (transferWhereBankAccountNotApproved) {
                  TerraUtils.scrollToElementById('distributionTransfer_' + transferWhereBankAccountNotApproved.id, true);
                }
              });
            this.actionFailedError += 'Please approve the bank account details.';
          } else {
            this.actionFailedError += 'Please contact support.';
          }
          this.isActionFailed = true;
        }

        this.distribution.isGeneratingOrders = false;
        this.showInternalLoader = false;
      }
    );
  }


  showDepositAccountDetails() {
    const config = new MatDialogConfig<DepositDetailsDialogContext>();

    const data = new DepositDetailsDialogContext();
    data.covercySourceBankAccount = this.distribution.distributionCovercySourceBankAccount;
    data.amount = this.distribution.fees.totalAmountAfterFees;
    data.currency = this.distribution.distributionSourceCurrency;
    if (this.distribution.distributingEntity.organizationDetails) {
      data.distributingEntityName = this.distribution.distributingEntity.organizationDetails.name;
    } else {
      data.distributingEntityName = `${this.distribution.distributingEntity.firstName} ${this.distribution.distributingEntity.lastName}`;
    }
    // CT of the dist entity + "distribution deposit"
    data.transactionReference = `${this.distribution.distributingEntity.clientIdentifier} distribution deposit`;

    config.data = data;

    this.dialog.open(DepositDetailsDialogComponent, config);
  }

  getFeesTooltipText() {
    return `Distribution fees breakdown:
    ${this.distribution.fees.gpEffectiveMarkupPercentage}% of the total amount transferred via Covercy, and a fixed fee of
      ${new TerraCurrencyNoSymbolPipe(this._locale).transform(this.distribution.fees.feeAmount)} ${this.feeCurrency}`;
  }

  deleteDistribution() {
    const confirmDialogParams = new ConfirmDialogParams();
    confirmDialogParams.actionLabel = 'Delete';
    confirmDialogParams.title = 'Are you sure you want to delete this distribution?';
    confirmDialogParams.description = `This distribution will be deleted immediately. You cannot undo this action.`;
    this.dialogService
      .confirmDialog(confirmDialogParams)
      .afterClosed()
      .pipe(
        filter(isConfirmed => isConfirmed),
        tap(_ => this.loadingAction$.next(true)),
        switchMap(() => this.holdingId$),
        switchMap(holdingId => this.gpDistributionDataService.delete(holdingId, this.distributionId)),
        tap(() => {
          this.gpHoldingService.refreshDistribution();
          this.snackbarService.showGeneralMessage(`Distribution deleted`);
        })
      )
      .subscribe({
        next: response => {
          this.loadingAction$.next(false);
          this.backToHoldingPage();
        },
        error: error => {
          this.loadingAction$.next(false);
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            this.snackbarService.showGeneralMessage(`Couldn't delete the distribution`);
          }
        }
      }
      );
  }

  markAsCompleted(forced: boolean = false) {
    const params = new ConfirmDialogParams();
    params.title = 'Mark distribution as completed';
    params.description = 'Are you sure you want to mark this distribution as completed?';
    this.errorMarkingAsComplete = false;
    this.dialogService.confirmDialog(params).afterClosed()
      .pipe(
        filter(isConfirmed => isConfirmed),
        tap(() => {
          this.showInternalLoader = true;
        }),
        switchMap(() => this.holdingId$),
        switchMap(holdingId => this.gpDistributionDataService.moveToCompleted(holdingId, this.distributionId, forced)),
        tap(() => {
          this.gpHoldingService.refreshDistribution();
          this.snackbarService.showGeneralMessage('Distribution completed');
        })
      )
      .subscribe(
        status => {
          this.distribution.status = status;
          this.distribution.isManualCompletionAllowed = false;
          this.showInternalLoader = false;
        },
        error => {
          this.showInternalLoader = false;
          this.errorMarkingAsComplete = true;
        });
  }

  holdingDownInfo() {
    this.gpHoldingService.holdingDownInfo();
  }

  cancel() {
    this.backToHoldingPage();
  }

  /** Get a list of the selectable bank account for a distribution transfer */
  getBankAccountsForTransfer(distributionTransfer: DistributionTransferReqRes) {
    if (!this.bankAccounts) {
      return [];
    }
    if (distributionTransfer.investingEntity.contact && distributionTransfer.investingEntity.contact.isAgentContact) {
      return [...this.bankAccounts[distributionTransfer.investingEntityId], ...this.bankAccounts[0]];
    } else {
      return this.bankAccounts[distributionTransfer.investingEntityId];
    }
  }

  getClientBankAccountsForTransfer(distributionTransfer: DistributionTransferReqRes) {
    if (!this.clientBankAccounts) {
      return [];
    }
    if (distributionTransfer.investingEntity.contact && distributionTransfer.investingEntity.contact.isAgentContact) {
      return [...this.clientBankAccounts[distributionTransfer.investingEntityId], ...this.clientBankAccounts[0]];
    } else {
      return this.clientBankAccounts[distributionTransfer.investingEntityId];
    }
  }

  getUnitBankAccountsForTransfer(distributionTransfer: DistributionTransferReqRes) {
    if (!this.unitBankAccounts) {
      return [];
    }
    return this.unitBankAccounts[distributionTransfer.investingEntityId];
  }

  subTextOption(account: UnitBankAccountReqRes) {
    return `$${this.numberPipe.transform(account.balance / 100)} / ${AccountType.toString(account.accountType)} ${account.accountNumber.slice(-4).padStart(8, '*')}`;
  }

  removeTransferFromArray(deletedId: number) {
    this.distribution.distributionTransfers = this.distribution.distributionTransfers.filter(dt => dt.id !== deletedId);
  }


  displayHighHolidaysNotification(userDetails) {
    if (!!this.distribution.distributingEntity && !this.highHolidaysMessageDisplayed && sessionStorage.getItem('closedDialog') !== 'true') {
      this.highHolidaysMessageDisplayed = true;
      const dialogRef = this.dialog.open(HighHolidaysNotificationComponent, {
        width: '640px',
        data: userDetails
      }).afterClosed().subscribe(() => {
        // store close in sessionStorage
        sessionStorage.setItem('closedDialog', 'true');
      });
    }
  }

  getExternalStatusText(transfer: DistributionTransferReqRes) {
    const status = transfer?.order?.externalStatusText || '';
    if (transfer.externalPayment || !this.distribution?.unitTransaction || !transfer.orderId) {
      return status;
    }
    return status?.length ? ' |  ' + status : '';
  }

  getUnitStatusCFSB(transfer: DistributionTransferReqRes): {text:string, isFailed:boolean} {
    if (transfer.externalPayment || !this.distribution?.unitTransaction || !transfer.orderId) {
      return;
    }

    let isFailed = false;
    let statusText: string;
    switch (this.distribution.unitTransaction.unitStatus) {
      case this.unitStatus.Sent:
        statusText = 'completed';
        break;
      case this.unitStatus.Pending:
      case this.unitStatus.PendingReview:
      case this.unitStatus.Clearing:
        statusText = 'processing';
        break;
      default:
        statusText = 'failed';
        isFailed = true;
        break;
    }
    return {text: `ACH payment ${statusText}`, isFailed};
  }

  getUnitStatus(transfer: DistributionTransferReqRes): {text:string, isFailed:boolean} {
    if (!transfer?.unitTransaction) {
      return;
    }

    let isFailed = false;
    let statusText: string;
    switch (transfer?.unitTransaction?.unitStatus) {
      case this.unitStatus.Sent:
        statusText = 'completed';
        break;
      case this.unitStatus.Pending:
      case this.unitStatus.PendingReview:
      case this.unitStatus.Clearing:
        statusText = 'processing';
        break;
      default:
        statusText = 'failed';
        isFailed = true;
        break;
    }
    return {text: 'Transaction ' + statusText, isFailed}
  }

  getUnitTransactionsAmount() {
    const usTransactions = this.getUsTransactions();
    const nonUsTransactions = this.getNonUsTransactions();

    return usTransactions.length + (nonUsTransactions.length ? 1 : 0);
  }

  calculateUnitFee(): Observable<number> {
    const unitBank = this.distribution?.unitBankAccount;
    if (!unitBank) {
      return of(0);
    }
    const wireTransactions = this.getNumberOfWireTransaction();
    const nonWireTransactions = this.getNumberOfNonWireTransaction();

    return this.wireTransactionFee$
      .pipe(
        map(wireTransactionFee => {
          const wireFee = (unitBank.transactionFee / 100) + wireTransactionFee;
          const nonWireFee = (unitBank.transactionFee / 100);
          return wireTransactions * wireFee + nonWireFee * nonWireTransactions;
        })
      );
  }

  getWireTransactions() {
    return this.distribution?.distributionTransfers?.filter(
      (dt) =>
        !dt.externalPayment &&
        dt.amountNet > 0 &&
        dt.clientBankAccount?.country?.code === TerraUtils.consts.countryCode.US
        && dt.clientBankAccount?.paymentType === PaymentType.Wire
    );
  }

  getNumberOfWireTransaction(): number {
    return this.getWireTransactions()?.length;
  }

  getUnitTransactions() {
    return this.distribution?.distributionTransfers?.filter(dt =>
      dt.unitBankAccount
    );
  }

  getUnitUsTransactions() {
    return this.distribution?.distributionTransfers?.filter(
      (dt) =>
        !dt.externalPayment &&
        dt.amountNet > 0 &&
        dt.clientBankAccount?.country?.code === TerraUtils.consts.countryCode.US
        && (dt.clientBankAccount?.paymentType === PaymentType.Ach || dt.clientBankAccount?.paymentType === PaymentType.Both)
    );
  }

  getOutOfUsTransactions() {
    return this.distribution?.distributionTransfers?.filter(
      (dt) =>
        dt.amountNet > 0 &&
        !dt.externalPayment &&
        dt.clientBankAccount?.countryId !== this.USCountryId
    );
  }

  getNumberOfNonWireTransaction() {
    const unitTransactions = this.getUnitTransactions()?.length;
    const usTransactions = this.getUnitUsTransactions()?.length;
    const outOfUsTransactions = this.getOutOfUsTransactions()?.length;

    return usTransactions + (outOfUsTransactions > 1 ? 1 : 0) + unitTransactions;
  }

  calculateFinalBalance(): Observable<number> {
    return this.calculateUnitFee()
      .pipe(
        map(calculateUnitFee => {
          const unitFee = (calculateUnitFee * 100) || 0;

          if (!this.distribution?.unitBankAccount) {
            return 0;
          } else if (DistributionStatus.afterFinalizedStatuses().includes(this.distribution?.status)) {
            return this.distribution?.unitBankAccount?.balance;
          }

          const accountBalanceInCents = this.distribution?.unitBankAccount?.balance;
          const accountBalance = this.distribution?.unitBankAccount?.balance / 100;
          const distributionAmount = this.distribution?.fees?.totalAmountAfterFees;
          const distributionAmountWithFees = distributionAmount + (unitFee / 100);
          const distributionAmountInCents = distributionAmount * 100;

          let finalBalance = accountBalanceInCents - distributionAmountInCents;
          finalBalance -= unitFee;

          if (finalBalance < 0) {
            this.negativeBalanceError = `Insufficient funds - The total distribution amount of $${this.numberPipe.transform(distributionAmountWithFees)} (including all applicable fees) exceeds your current balance of $${this.numberPipe.transform(accountBalance)}.`;
          } else {
            this.negativeBalanceError = '';
          }

          const wiresSum = this.getWireTransactions().reduce((acc, obj) => acc + obj.amountNet, 0);
          const achSum = this.getUnitUsTransactions().reduce((acc, obj) => acc + obj.amountNet, 0);
          const cfsbSum = this.getOutOfUsTransactions().reduce((acc, obj) => acc + obj.amountNet, 0);

          if (((achSum + cfsbSum) * 100) > this.distribution?.unitBankAccount?.accountLimits?.achLimits.maximumCreditAllowed) {
            this.amountAchExceedsLimitsError = 'Distribution ACH payments via Covercy exceeds the Bank\'s ACH payments limit';
          } else {
            this.amountAchExceedsLimitsError = '';
          }

          if ((wiresSum * 100) > this.distribution?.unitBankAccount?.accountLimits?.wireLimits.maximumTransfersAllowed) {
            this.amountWireExceedsLimitsError = 'Distribution WIRE payments via Covercy exceeds the Bank\'s WIRE payments limit';
          } else {
            this.amountWireExceedsLimitsError = '';
          }

          finalBalance = finalBalance / 100;
          return finalBalance;
        })
      );
  }

  anyUsBankHasNonUsdCurrency() {
    return this.distribution.unitBankAccountId &&
      this.distribution.distributionTransfers.some(dt => dt.clientBankAccount?.countryId === this.USCountryId && dt.clientBankAccount?.preferredCurrency?.iso !== 'USD');
  }

  allowForcedMarkAsCompleted() {
    return this.distribution.status === DistributionStatus.PartialCompleted ||
      this.distribution.status === DistributionStatus.Failed ||
      this.distribution.status === DistributionStatus.Canceled;
  }

  updatedAccountByInvestor(distributionTransfer:DistributionTransferReqRes):boolean{
    return distributionTransfer 
    && !distributionTransfer.bankAccountDetailsApprovedByGp 
    && !distributionTransfer.isBankAccountChangeUnsaved
    && !distributionTransfer.externalPayment
    && (distributionTransfer.clientBankAccountId || distributionTransfer.unitBankAccountId)
    && distributionTransfer.amountNet > 0;
  }


  private getUsTransactions() {
    return this.distribution.distributionTransfers.filter(dt => !dt.externalPayment && dt.clientBankAccount?.country?.code === TerraUtils.consts.countryCode.US);
  }

  private getNonUsTransactions() {
    return this.distribution.distributionTransfers.filter(dt =>
      dt.amountGross > 0 &&
      !dt.externalPayment &&
      dt.clientBankAccount !== null &&
      dt.clientBankAccount.countryId !== this.USCountryId
    );
  }
}
