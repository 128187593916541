import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, EMPTY, Observable, of, throwError, timer} from 'rxjs';
import {catchError, finalize, map, switchMap, take, tap} from 'rxjs/operators';

import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {ClientBankAccountResponseBasic} from 'src/app/dashboard/models/bankAccount.model';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import {GpAssetReqRes} from '../../GpAssetReqRes.model';
import {FundraisingReqRes} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';
import {LocationDetailsResponse} from 'src/app/shared/models/LocationDetailsResponse.model';
import {GpAssetDataService} from 'src/app/services/gp/gp-asset-data.service';
import {ProjectedPerformance} from 'src/app/shared/models/ProjectedPerformance.model';
import {LenderInformation} from 'src/app/shared/models/LenderInformation.model';
import {HoldingFileReqRes} from 'src/app/dashboard/shared/holding/HoldingFileReqRes.model';
import {ProjectBudget} from 'src/app/shared/models/ProjectBudget.model';
import {AssetAndFundraisingReqRes} from '../create-asset/AssetAndFundraisingRequest';
import {GpAssetDialogContext} from '../../gp-asset/AssetDetails.context';
import {EditAssetTabNumber} from './EditAssetStepBaseAndInterface';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {EditAssetDialogComponent} from './edit-asset-dialog.component';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {CustomValidators} from 'src/app/shared/validators/custom.validators';
import {GpHoldingDataService} from 'src/app/services/gp/gp-holding-data.service';
import {
  ConfirmInvestorsNotificationDialogComponent,
  ConfirmInvestorsNotificationResult
} from 'src/app/dashboard/shared/holding/confirm-investors-notification-dialog/confirm-investors-notification-dialog.component';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import InvestmentType from '../../../../shared/enums/InvestmentType.enum';
import {AddressFields} from '../../../../shared/components/address-form/address-form.settings';
import {CreateEditHoldingService} from '../../../shared/holding/create-edit-holding.service';
import {ResourceService} from '../../../../services/resource.service';
import {HoldingFundraisingService} from '../../../shared/holding/fundraising/holding-fundraising.service';
import {CapitalCallService} from '../../../shared/holding/capital-call/capital-call.service';
import {OpportunityDto} from 'src/app/dashboard/shared/holding/OpportunityDto.model';
import {Base64} from 'js-base64';
import {LpOwnershipVisibility} from 'src/app/shared/enums/LpOwnershipVisibility.enum';
import {HideCommitmentForNonContributingLps} from 'src/app/shared/enums/HideCommitmentForNonContributingLps.enum';
import {CommitmentsEnabled} from 'src/app/shared/enums/CommitmentsEnabled.enum';
import {OwnershipCalculationType} from 'src/app/shared/enums/OwnershipCalculationType.enum';

@Injectable()
export class EditAssetService extends CreateEditHoldingService {
  editAssetDialogContext: GpAssetDialogContext;

  dialogRef: MatDialogRef<EditAssetDialogComponent>;

  assetForm: UntypedFormGroup;

  attemptSubmit = false;

  processingSubmit$ = new BehaviorSubject(false);
  isGeneralServerError = false;

  bankAccounts$: Observable<ClientBankAccountResponseBasic[]>;

  assetCreated = false;

  get selectedCurrency() {
    const currency = this.getTabForm(1).get('currency').value;
    return currency as CurrencyModel;
  }

  get selectedSecurityType(): InvestmentSecurityType {
    try {
      return this.getTabForm(1).get('investmentSecurityType').value;
    } catch {
      return null;
    }
  }

  get isUpdateBankAccountAllowed(): boolean {
    // Find investments (not declined) with  payment request already sent to the investors
    const investmentsWithPaymentRequestSent = this.editAssetDialogContext.fundraisingDetails.investments
      .filter(i => i.status !== InvestmentStatus.Declined)
      .find(i => i.paymentRequestSendDate);

    // If found, it's not allowed to update bank account
    if (investmentsWithPaymentRequestSent) {
      return false;
    }
    return true;
  }

  // Data points available to collect per HoldingType
  readonly allowedDataPointsListFor = TerraUtils.consts.allowedDataPointsListFor;

  constructor(
    private fb: UntypedFormBuilder,
    private gpAssetDataService: GpAssetDataService,
    private gpHoldingService: GpHoldingDataService,
    private holdingFundraisingService: HoldingFundraisingService,
    private numberPipe: TerraNumberPipe,
    private dialog: MatDialog,
    private capitalCallService: CapitalCallService,
    resourceService: ResourceService) {
    super(resourceService);
  }

  getTabForm(tabNumber: EditAssetTabNumber) {
    const tabFormName = 'tab' + tabNumber;
    if (tabFormName && this.assetForm) {
      return this.assetForm.get(tabFormName) as UntypedFormGroup;
    }
    return null;
  }

  generateForm() {
    const assetDetails = this.editAssetDialogContext.asset;
    const fundraisingDetails = this.editAssetDialogContext.fundraisingDetails;
    const assetStatus = this.editAssetDialogContext.asset.status;

    this.assetForm = this.fb.group({
      tab1: this.fb.group({
        name: [assetDetails.name, [Validators.required, Validators.minLength(2)], this.validateHoldingName.bind(this)],
        legalName: [assetDetails.legalName, [Validators.required]],
        estimatedClosingDate: [fundraisingDetails.estimatedClosingDate, [Validators.required]],
        investmentSecurityType: [fundraisingDetails.securityType, assetStatus === HoldingStatus.Fundraising ? Validators.required : []],
        currency: [{
          value: fundraisingDetails.fundraisingTargetCurrency,
          disabled: assetDetails.status === HoldingStatus.Fundraising ? !this.holdingFundraisingService.isUpdateCurrencyAllowed(fundraisingDetails) : true
        }, [Validators.required]],
        fundraisingTargetAmount: [fundraisingDetails.fundraisingTargetAmount, Validators.compose([Validators.required, Validators.min(1)])],
        minimumInvestmentAmount: fundraisingDetails.minimumInvestmentAmount,
        holdingType: [assetDetails.holdingType, [Validators.required]],
        investmentType: [assetDetails.investmentType, [Validators.required]],
        investmentTypeOther: [assetDetails.investmentTypeOther, assetDetails.investmentType === InvestmentType.Other ?
          [Validators.required, Validators.maxLength(200)] : [Validators.maxLength(200)]],

        areaUnits: assetDetails.areaUnits ? assetDetails.areaUnits : AreaUnit.SquareFeet,
        lotSizeArea: assetDetails.lotSizeArea,
        stories: assetDetails.stories,
        residentialUnits: assetDetails.residentialUnits,
        retailUnits: assetDetails.retailUnits,
        rooms: assetDetails.rooms,
        grossBuildingArea: assetDetails.grossBuildingArea,
        residentialNetSellableArea: assetDetails.residentialNetSellableArea,
        retailNetSellableArea: assetDetails.retailNetSellableArea,
        numberOfSites: assetDetails.numberOfSites,

        assetLocation: [{address: assetDetails.location, street2Name: assetDetails.location?.street2Name}]
      }),
      tab2: this.fb.group({
        projectedPerformance: this.fb.group({
          // If the asset is in Fundraising status, and there is no value in the cumulative field, take the value from the non-cumulative:
          cashOnCashRate: (assetStatus === HoldingStatus.Fundraising) ?
            assetDetails.projectedPerformance.cashOnCashRate : assetDetails.cumulativeProjectedPerformance.cashOnCashRate,
          // Same for all the rest:
          nonLeveragedCapRate: (assetStatus === HoldingStatus.Fundraising) ?
            assetDetails.projectedPerformance.nonLeveragedCapRate : assetDetails.cumulativeProjectedPerformance.nonLeveragedCapRate,

          targetInvestmentIrr: (assetStatus === HoldingStatus.Fundraising) ?
            assetDetails.projectedPerformance.targetInvestmentIrr : assetDetails.cumulativeProjectedPerformance.targetInvestmentIrr,

          targetInvestmentIrrMax: (assetStatus === HoldingStatus.Fundraising) ?
            assetDetails.projectedPerformance.targetInvestmentIrrMax : assetDetails.cumulativeProjectedPerformance.targetInvestmentIrrMax,

          expectedRoi: (assetStatus === HoldingStatus.Fundraising) ?
            assetDetails.projectedPerformance.expectedRoi : assetDetails.cumulativeProjectedPerformance.expectedRoi,

          targetEquityMultiplier: (assetStatus === HoldingStatus.Fundraising) ?
            assetDetails.projectedPerformance.targetEquityMultiplier : assetDetails.cumulativeProjectedPerformance.targetEquityMultiplier,

          targetEquityMultiplierMax: (assetStatus === HoldingStatus.Fundraising) ?
            assetDetails.projectedPerformance.targetEquityMultiplierMax : assetDetails.cumulativeProjectedPerformance.targetEquityMultiplierMax,

          targetInvestmentPeriod: (assetStatus === HoldingStatus.Fundraising) ?
            assetDetails.projectedPerformance.targetInvestmentPeriod : assetDetails.cumulativeProjectedPerformance.targetInvestmentPeriod,

          preferredReturn: (assetStatus === HoldingStatus.Fundraising) ?
            assetDetails.projectedPerformance.preferredReturn : assetDetails.cumulativeProjectedPerformance.preferredReturn
        }),
        projectBudgetOriginal: this.fb.group({
          acquisition: assetDetails.projectBudgetOriginal.acquisition,
          hardCost: assetDetails.projectBudgetOriginal.hardCost,
          softCost: assetDetails.projectBudgetOriginal.softCost,
          financing: assetDetails.projectBudgetOriginal.financing,
          totalCosts: assetDetails.projectBudgetOriginal.totalCosts,
          totalCostsPerGrossAreaUnit: assetDetails.projectBudgetOriginal.totalCostsPerGrossAreaUnit,
          totalCostsPerNetAreaUnit: assetDetails.projectBudgetOriginal.totalCostsPerNetAreaUnit
        }),
        projectBudgetUpdated: this.fb.group({
          acquisition: assetDetails.projectBudgetUpdated.acquisition,
          hardCost: assetDetails.projectBudgetUpdated.hardCost,
          softCost: assetDetails.projectBudgetUpdated.softCost,
          financing: assetDetails.projectBudgetUpdated.financing,
          totalCosts: assetDetails.projectBudgetUpdated.totalCosts,
          totalCostsPerGrossAreaUnit: assetDetails.projectBudgetUpdated.totalCostsPerGrossAreaUnit,
          totalCostsPerNetAreaUnit: assetDetails.projectBudgetUpdated.totalCostsPerNetAreaUnit
        }),
        lenderInformation: this.fb.group({
          lenderName: assetDetails.lenderInformation.lenderName,
          amount: assetDetails.lenderInformation.amount,
          loanToValue: assetDetails.lenderInformation.loanToValue,
          interestRate: assetDetails.lenderInformation.interestRate,
          interestType: assetDetails.lenderInformation.interestType,
          loanType: assetDetails.lenderInformation.loanType,
          closingDate: assetDetails.lenderInformation.closingDate,
          maturityDate: assetDetails.lenderInformation.maturityDate,
          additionalTerms: assetDetails.lenderInformation.additionalTerms,
          totalFinancingPerGrossAreaUnit: assetDetails.lenderInformation.totalFinancingPerGrossAreaUnit,
          totalFinancingPerNetAreaUnit: assetDetails.lenderInformation.totalFinancingPerNetAreaUnit,

          amountJunior: assetDetails.lenderInformation.amountJunior,
          loanToValueJunior: assetDetails.lenderInformation.loanToValueJunior,
          interestRateJunior: assetDetails.lenderInformation.interestRateJunior,
          interestTypeJunior: assetDetails.lenderInformation.interestTypeJunior,
          loanTypeJunior: assetDetails.lenderInformation.loanTypeJunior,
          closingDateJunior: assetDetails.lenderInformation.closingDateJunior,
          maturityDateJunior: assetDetails.lenderInformation.maturityDateJunior,
          additionalTermsJunior: assetDetails.lenderInformation.additionalTermsJunior,
          totalFinancingPerGrossAreaUnitJunior: assetDetails.lenderInformation.totalFinancingPerGrossAreaUnitJunior,
          totalFinancingPerNetAreaUnitJunior: assetDetails.lenderInformation.totalFinancingPerNetAreaUnitJunior
        }),
        finalClosingDate: [
          fundraisingDetails.finalClosingDate ? fundraisingDetails.finalClosingDate : null,
          assetStatus === HoldingStatus.Owned ? Validators.required : []
        ],
        cumulativeInformation: this.fb.group({
          totalCapitalization: [assetDetails.totalCapitalization, assetStatus === HoldingStatus.Owned ? [Validators.required, CustomValidators.minFormattedNumber(0)] : []],
          totalEquityInvestedToDate: assetDetails.totalEquityInvestedToDate,
          totalReturnsToDate: assetDetails.totalReturnsToDate,
          totalInvestorEquity: [assetDetails.totalInvestorEquity, assetStatus === HoldingStatus.Owned ? [Validators.required, CustomValidators.minFormattedNumber(0)] : []],
          plannedCostsToDate: assetDetails.plannedCostsToDate,
          actualCostsToDate: assetDetails.actualCostsToDate,
          gpEquityFromTotalEquity: assetDetails.gpEquityFromTotalEquity,
          outstandingLoanAmountToDate: assetDetails.outstandingLoanAmountToDate,
          estimatedMarketValue: assetDetails.estimatedMarketValue,
          constructionStartDate: assetDetails.constructionStartDate ? new Date(assetDetails.constructionStartDate) : null,
          constructionEndDate: assetDetails.constructionEndDate ? new Date(assetDetails.constructionEndDate) : null
        }),
        commitmentsEnabled: assetDetails.commitmentsEnabled,
        ownershipCalculationType: assetDetails.ownershipCalculationType,
      }),
      tab3: this.fb.group({
        assetPhotos: this.fb.array([]),
        attachments: this.fb.array([]),
        marketingDeckDocument: [fundraisingDetails.offeringDeck, null, this.validateOfferingDeckFileTypeSupported.bind(this)],
        marketingDeckDocumentZoom: [!!assetDetails.opportunity?.documentZoomValue ? 'manual' : 'auto'],
        marketingDeckDocumentZoomValue: assetDetails.opportunity?.documentZoomValue,
        defaultOfferingDeckContribution: this.editAssetDialogContext.fundraisings.filter(f => f.isDefaultDeck)?.[0]?.id,
        description: assetDetails.opportunity?.description || '',
        isVisible: assetDetails.investNow,
        video: assetDetails.opportunity?.video,
        shouldShowPlayer: !!assetDetails.opportunity?.video
      }),
      tab4: this.fb.group({
        bankAccount: [{value: this.holdingFundraisingService.getHoldingBankAccount(fundraisingDetails), disabled: !this.isUpdateBankAccountAllowed}, []],
        isCollectDeposists: assetDetails.isCollectDeposists
      }),
      tab5: this.fb.group({
        email: [assetDetails.email, [Validators.required, CustomValidators.EmailWithSpaces]],
        assetPaymentRequestDocuments: this.fb.array([]),
        isPrivate: assetDetails.isPrivate,
        allowLpSetBank: assetDetails.allowLpSetBank,
        displayPerformanceMetrics: assetDetails.displayPerformanceMetrics,
        lpOwnershipVisibility: assetDetails.lpOwnershipVisibility,
        hideCommitmentForNonContributingLps: assetDetails.hideCommitmentForNonContributingLps,
        displayNetAssetValue: assetDetails.displayNetAssetValue,
      })
    });

    /*    if (!!fundraisingDetails.capitalCall) {
          const capitalCallForm = this.capitalCallService.capitalCallDetailsForm(this.fb, fundraisingDetails);
          (this.assetForm.get('tab1') as FormGroup).addControl('capitalCall', capitalCallForm);
        }*/

    this.assetForm.get('tab1.investmentType').valueChanges.subscribe(val => {
      if (val === InvestmentType.Other) {
        this.assetForm.get('tab1.investmentTypeOther').setValidators([Validators.required, Validators.maxLength(200)]);
      } else {
        this.assetForm.get('tab1.investmentTypeOther').setValue(null);
        this.assetForm.get('tab1.investmentTypeOther').setValidators([Validators.maxLength(200)]);
      }
      this.assetForm.get('tab1.investmentTypeOther').updateValueAndValidity();
    });

    const assetPhotos = assetDetails.attachments.filter(x => x.fileType === HoldingFileType.Photo);
    const photosArray = this.getTabForm(3).get('assetPhotos') as UntypedFormArray;
    for (let i = 0; i < 8; i++) {
      photosArray.push(this.fb.control(
        (i < assetPhotos.length && assetPhotos[i]) ?
          assetPhotos[i].metaFileLink : null,
        {asyncValidators: this.validatePhotoFileTypeSupported.bind(this)}));
    }

    const assetPaymentRequestDocuments = assetDetails.attachments.filter(x => x.fileType === HoldingFileType.PaymentRequestDocument);
    const paymentRequestDocumentsArray = this.getTabForm(5).get('assetPaymentRequestDocuments') as UntypedFormArray;
    for (let i = 0; i < 8; i++) {
      paymentRequestDocumentsArray.push(this.fb.control(
        (i < assetPaymentRequestDocuments.length && assetPaymentRequestDocuments[i]) ?
          assetPaymentRequestDocuments[i].metaFileLink : null,
        {asyncValidators: this.validatePaymentRequestDocumentFileTypeSupported.bind(this)}));
    }

    const opportunityAttachments = assetDetails.opportunity?.attachments;
    const attachmentArray = this.getTabForm(3).get('attachments') as UntypedFormArray;
    opportunityAttachments?.forEach(attachment => {
      attachmentArray.push(this.fb.control(attachment, {asyncValidators: this.validatePhotoFileTypeSupported.bind(this)}), {emitEvent: false});
    });

    this.pushMarketingAttachment();

  }

  pushMarketingAttachment() {
    const attachments = this.assetForm.get('tab3.attachments') as UntypedFormArray;
    if (attachments.length < 10) {
      attachments.insert(0, this.fb.control(null, {asyncValidators: this.validatePhotoFileTypeSupported.bind(this)}), {emitEvent: false});
    }
  }

  popMarketingAttachment(ind: number) {
    const attachments = this.assetForm.get('tab3.attachments') as UntypedFormArray;
    attachments.removeAt(ind, {emitEvent: false});
  }

  generateSubmitModel(): AssetAndFundraisingReqRes {
    const formValues = this.assetForm.getRawValue();
    const tab1 = formValues.tab1;
    const tab2 = formValues.tab2;
    const tab3 = formValues.tab3;
    const tab4 = formValues.tab4;
    const tab5 = formValues.tab5;

    const assetDetailsModel = new GpAssetReqRes();
    const fundraisingModel = new FundraisingReqRes();

    assetDetailsModel.id = this.editAssetDialogContext.asset.id;
    fundraisingModel.id = this.editAssetDialogContext.fundraisingDetails.id;

    // Tab1
    if (!this.editAssetDialogContext.asset.isExample) {
      assetDetailsModel.name = tab1.name;
      assetDetailsModel.legalName = tab1.legalName;
    }
    fundraisingModel.estimatedClosingDate = tab1.estimatedClosingDate;
    fundraisingModel.securityType = tab1.investmentSecurityType;
    assetDetailsModel.initialSecurityType = tab1.investmentSecurityType;

    if (tab1.currency) {
      fundraisingModel.fundraisingTargetCurrencyId = tab1.currency.id;
    }
    fundraisingModel.fundraisingTargetAmount = this.numberPipe.parse(tab1.fundraisingTargetAmount);
    fundraisingModel.minimumInvestmentAmount = this.numberPipe.parse(tab1.minimumInvestmentAmount);
    assetDetailsModel.holdingType = tab1.holdingType;
    assetDetailsModel.investmentType = tab1.investmentType;
    assetDetailsModel.investmentTypeOther = tab1.investmentTypeOther;

    assetDetailsModel.areaUnits = this.numberPipe.parse(tab1.areaUnits);
    assetDetailsModel.lotSizeArea = this.numberPipe.parse(tab1.lotSizeArea);
    assetDetailsModel.stories = this.numberPipe.parse(tab1.stories);
    assetDetailsModel.residentialUnits = this.numberPipe.parse(tab1.residentialUnits);
    assetDetailsModel.retailUnits = this.numberPipe.parse(tab1.retailUnits);
    assetDetailsModel.rooms = this.numberPipe.parse(tab1.rooms);
    assetDetailsModel.grossBuildingArea = this.numberPipe.parse(tab1.grossBuildingArea);
    assetDetailsModel.residentialNetSellableArea = this.numberPipe.parse(tab1.residentialNetSellableArea);
    assetDetailsModel.retailNetSellableArea = this.numberPipe.parse(tab1.retailNetSellableArea);
    assetDetailsModel.numberOfSites = this.numberPipe.parse(tab1.numberOfSites);

    assetDetailsModel.location = {...tab1.assetLocation.address, street2Name: tab1.assetLocation.street2Name} as LocationDetailsResponse;

    // Tab 2
    fundraisingModel.finalClosingDate = tab2.finalClosingDate;

    // When in Fundraising status, store the projected performance as the initial values,
    // and when in UnderManagement status, store the values in the cumulative properties
    let projectedPerfModel: ProjectedPerformance;
    switch (this.editAssetDialogContext.asset.status) {
      case HoldingStatus.Fundraising:
        assetDetailsModel.projectedPerformance = new ProjectedPerformance();
        projectedPerfModel = assetDetailsModel.projectedPerformance;
        break;
      case HoldingStatus.Owned:
        assetDetailsModel.cumulativeProjectedPerformance = new ProjectedPerformance();
        projectedPerfModel = assetDetailsModel.cumulativeProjectedPerformance;
        break;
    }

    projectedPerfModel.cashOnCashRate = this.numberPipe.parse(tab2.projectedPerformance.cashOnCashRate);
    projectedPerfModel.nonLeveragedCapRate = this.numberPipe.parse(tab2.projectedPerformance.nonLeveragedCapRate);
    projectedPerfModel.targetInvestmentIrr = this.numberPipe.parse(tab2.projectedPerformance.targetInvestmentIrr);
    projectedPerfModel.targetInvestmentIrrMax = this.numberPipe.parse(tab2.projectedPerformance.targetInvestmentIrrMax);
    projectedPerfModel.expectedRoi = this.numberPipe.parse(tab2.projectedPerformance.expectedRoi);
    projectedPerfModel.targetEquityMultiplier = this.numberPipe.parse(tab2.projectedPerformance.targetEquityMultiplier);
    projectedPerfModel.targetEquityMultiplierMax = this.numberPipe.parse(tab2.projectedPerformance.targetEquityMultiplierMax);
    projectedPerfModel.targetInvestmentPeriod = this.numberPipe.parse(tab2.projectedPerformance.targetInvestmentPeriod);
    projectedPerfModel.preferredReturn = this.numberPipe.parse(tab2.projectedPerformance.preferredReturn);

    // Tab 2 - Original budget:
    assetDetailsModel.projectBudgetOriginal = new ProjectBudget();
    const budgetModel = assetDetailsModel.projectBudgetOriginal;
    const budgetFormValues = tab2.projectBudgetOriginal;
    budgetModel.acquisition = this.numberPipe.parse(budgetFormValues.acquisition);
    budgetModel.hardCost = this.numberPipe.parse(budgetFormValues.hardCost);
    budgetModel.softCost = this.numberPipe.parse(budgetFormValues.softCost);
    budgetModel.financing = this.numberPipe.parse(budgetFormValues.financing);
    budgetModel.totalCosts = this.numberPipe.parse(budgetFormValues.totalCosts);
    budgetModel.totalCostsPerGrossAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerGrossAreaUnit);
    budgetModel.totalCostsPerNetAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerNetAreaUnit);
    // Tab 2 - Updated Budget
    assetDetailsModel.projectBudgetUpdated = new ProjectBudget();
    const budgetUpdateModel = assetDetailsModel.projectBudgetUpdated;
    const budgetUpdatedFormValues = tab2.projectBudgetUpdated;
    budgetUpdateModel.acquisition = this.numberPipe.parse(budgetUpdatedFormValues.acquisition);
    budgetUpdateModel.hardCost = this.numberPipe.parse(budgetUpdatedFormValues.hardCost);
    budgetUpdateModel.softCost = this.numberPipe.parse(budgetUpdatedFormValues.softCost);
    budgetUpdateModel.financing = this.numberPipe.parse(budgetUpdatedFormValues.financing);
    budgetUpdateModel.totalCosts = this.numberPipe.parse(budgetUpdatedFormValues.totalCosts);
    budgetUpdateModel.totalCostsPerGrossAreaUnit = this.numberPipe.parse(budgetUpdatedFormValues.totalCostsPerGrossAreaUnit);
    budgetUpdateModel.totalCostsPerNetAreaUnit = this.numberPipe.parse(budgetUpdatedFormValues.totalCostsPerNetAreaUnit);


    // Tab 2 - Lender information:
    assetDetailsModel.lenderInformation = new LenderInformation();
    const lenderInfoModel = assetDetailsModel.lenderInformation;

    lenderInfoModel.lenderName = tab2.lenderInformation.lenderName;
    lenderInfoModel.amount = this.numberPipe.parse(tab2.lenderInformation.amount);
    lenderInfoModel.loanToValue = this.numberPipe.parse(tab2.lenderInformation.loanToValue);
    lenderInfoModel.interestRate = this.numberPipe.parse(tab2.lenderInformation.interestRate);
    lenderInfoModel.interestType = this.numberPipe.parse(tab2.lenderInformation.interestType);
    lenderInfoModel.loanType = tab2.lenderInformation.loanType;
    lenderInfoModel.closingDate = tab2.lenderInformation.closingDate;
    lenderInfoModel.maturityDate = tab2.lenderInformation.maturityDate;
    lenderInfoModel.additionalTerms = tab2.lenderInformation.additionalTerms;
    lenderInfoModel.totalFinancingPerGrossAreaUnit = this.numberPipe.parse(tab2.lenderInformation.totalFinancingPerGrossAreaUnit);
    lenderInfoModel.totalFinancingPerNetAreaUnit = this.numberPipe.parse(tab2.lenderInformation.totalFinancingPerNetAreaUnit);

    lenderInfoModel.amountJunior = this.numberPipe.parse(tab2.lenderInformation.amountJunior);
    lenderInfoModel.loanToValueJunior = this.numberPipe.parse(tab2.lenderInformation.loanToValueJunior);
    lenderInfoModel.interestRateJunior = this.numberPipe.parse(tab2.lenderInformation.interestRateJunior);
    lenderInfoModel.interestTypeJunior = this.numberPipe.parse(tab2.lenderInformation.interestTypeJunior);
    lenderInfoModel.loanTypeJunior = tab2.lenderInformation.loanTypeJunior;
    lenderInfoModel.closingDateJunior = tab2.lenderInformation.closingDateJunior;
    lenderInfoModel.maturityDateJunior = tab2.lenderInformation.maturityDateJunior;
    lenderInfoModel.additionalTermsJunior = tab2.lenderInformation.additionalTermsJunior;
    lenderInfoModel.totalFinancingPerGrossAreaUnitJunior = this.numberPipe.parse(tab2.lenderInformation.totalFinancingPerGrossAreaUnitJunior);
    lenderInfoModel.totalFinancingPerNetAreaUnitJunior = this.numberPipe.parse(tab2.lenderInformation.totalFinancingPerNetAreaUnitJunior);

    const cumulativeInformationValues = tab2.cumulativeInformation;
    assetDetailsModel.totalCapitalization = this.numberPipe.parse(cumulativeInformationValues.totalCapitalization);
    assetDetailsModel.totalEquityInvestedToDate = this.numberPipe.parse(cumulativeInformationValues.totalEquityInvestedToDate);
    assetDetailsModel.totalReturnsToDate = this.numberPipe.parse(cumulativeInformationValues.totalReturnsToDate);
    assetDetailsModel.totalInvestorEquity = this.numberPipe.parse(cumulativeInformationValues.totalInvestorEquity);

    assetDetailsModel.commitmentsEnabled = tab2.commitmentsEnabled ? CommitmentsEnabled.Yes : CommitmentsEnabled.No;
    assetDetailsModel.ownershipCalculationType = tab2.ownershipCalculationType ? OwnershipCalculationType.ByCommitments : OwnershipCalculationType.ByContributions;
    assetDetailsModel.plannedCostsToDate = this.numberPipe.parse(cumulativeInformationValues.plannedCostsToDate);
    assetDetailsModel.actualCostsToDate = this.numberPipe.parse(cumulativeInformationValues.actualCostsToDate);
    assetDetailsModel.gpEquityFromTotalEquity = this.numberPipe.parse(cumulativeInformationValues.gpEquityFromTotalEquity);
    assetDetailsModel.outstandingLoanAmountToDate = this.numberPipe.parse(cumulativeInformationValues.outstandingLoanAmountToDate);
    assetDetailsModel.estimatedMarketValue = this.numberPipe.parse(cumulativeInformationValues.estimatedMarketValue);
    assetDetailsModel.constructionStartDate = cumulativeInformationValues.constructionStartDate;
    assetDetailsModel.constructionEndDate = cumulativeInformationValues.constructionEndDate;


    // Tab 3 - Marketing material

    // Add the photos:
    assetDetailsModel.attachments = new Array<HoldingFileReqRes>();
    try {
      const photosArray = tab3.assetPhotos;
      photosArray
        .forEach((photoMetaFile: MetaFileLink) => {
          if (photoMetaFile) {
            const file = new HoldingFileReqRes();
            // Find the attachment (assetFile) with the current metaFileLink inside it:
            const originalAssetFileWithThisMetaFile = this.editAssetDialogContext.asset.attachments.find(a => a.metaFileLink.id === photoMetaFile.id);
            if (originalAssetFileWithThisMetaFile) {
              file.id = originalAssetFileWithThisMetaFile.id;
            }
            file.metaFileLinkId = photoMetaFile.id;
            file.fileType = HoldingFileType.Photo;
            assetDetailsModel.attachments.push(file);
          }
        });
    } catch (error) {
      console.log('ERROR while trying to add photos to the submit model.', error);
    }

    // Add the offering deck
    if (tab3.marketingDeckDocument) {
      fundraisingModel.offeringDeckId = (tab3.marketingDeckDocument as MetaFileLink).id;
    }

    assetDetailsModel.opportunity = this.generateOpportunityModel(tab3);

    assetDetailsModel.investNow = tab3.isVisible;

    // Tab 4
    if (tab4.bankAccount) {
      fundraisingModel.clientBankAccountId = !tab4.bankAccount.isUnitBankAccount ? (tab4.bankAccount as ClientBankAccountResponseBasic).id : null;
      fundraisingModel.unitBankAccountId = tab4.bankAccount.isUnitBankAccount ? (tab4.bankAccount as ClientBankAccountResponseBasic).id : null;
    }
    assetDetailsModel.isCollectDeposists = tab4.isCollectDeposists;

    // Tab 5 - more settings:
    assetDetailsModel.email = tab5.email;
    assetDetailsModel.isPrivate = tab5.isPrivate;
    assetDetailsModel.allowLpSetBank = tab5.allowLpSetBank;
    assetDetailsModel.displayPerformanceMetrics = tab5.displayPerformanceMetrics;
    assetDetailsModel.lpOwnershipVisibility = tab5.lpOwnershipVisibility ? LpOwnershipVisibility.On : LpOwnershipVisibility.Off;
    assetDetailsModel.hideCommitmentForNonContributingLps = tab5.hideCommitmentForNonContributingLps ? HideCommitmentForNonContributingLps.On : HideCommitmentForNonContributingLps.Off;
    assetDetailsModel.displayNetAssetValue = tab5.displayNetAssetValue;

    try {
      const paymentRequestDocumentsArray = tab5.assetPaymentRequestDocuments;
      paymentRequestDocumentsArray
        .forEach((docMetaFile: MetaFileLink) => {
          if (docMetaFile) {
            const file = new HoldingFileReqRes();
            // Find the attachent (assetFile) with the curent metaFileLink inside it:
            const originalAssetFileWithThisMetaFile = this.editAssetDialogContext.asset.attachments.find(a => a.metaFileLink.id === docMetaFile.id);
            if (originalAssetFileWithThisMetaFile) {
              file.id = originalAssetFileWithThisMetaFile.id;
            }
            file.metaFileLinkId = docMetaFile.id;
            file.fileType = HoldingFileType.PaymentRequestDocument;
            assetDetailsModel.attachments.push(file);
          }
        });
    } catch (error) {
      console.log('ERROR while trying to add payment request files to the submit model.', error);
    }
    return new AssetAndFundraisingReqRes(assetDetailsModel, fundraisingModel, false);
  }

  generateOpportunityModel(tab3) {
    if (!!tab3.description || !!tab3.video || !!tab3.attachments || tab3.marketingDeckDocumentZoomValue) {
      const opportunity = new OpportunityDto();
      opportunity.description = tab3.description ? Base64.encode(tab3.description) : undefined;
      opportunity.video = tab3.shouldShowPlayer ? Base64.encode(tab3.video) : undefined;
      opportunity.attachments = [];
      opportunity.documentZoomValue = tab3.marketingDeckDocumentZoom === 'manual' ? tab3.marketingDeckDocumentZoomValue : null;
      opportunity.defaultOfferingDeckFundraisingId = tab3.defaultOfferingDeckContribution;
      tab3.attachments.filter(d => !!d).forEach(d => opportunity.attachments.push(d as MetaFileLink));

      return opportunity;
    }
    return null;
  }

  saveChanges(): Observable<AssetAndFundraisingReqRes> {
    this.assetForm.markAllAsTouched();

    this.attemptSubmit = true;

    this.isGeneralServerError = false;
    if (!this.assetForm.valid) {
      return of(null);
    }

    const model = this.generateSubmitModel();
    const changedToPublic = !model.asset.isPrivate && this.editAssetDialogContext.asset.isPrivate;
    return of(changedToPublic).pipe(
      take(1),
      switchMap(isChangedToPublic => {
        if (isChangedToPublic) {
          const dialogConfig = new MatDialogConfig<HoldingDiscriminator>();
          dialogConfig.data = HoldingDiscriminator.Asset;
          return this.dialog.open<any, HoldingDiscriminator, ConfirmInvestorsNotificationResult>(ConfirmInvestorsNotificationDialogComponent, dialogConfig)
            .afterClosed();
        }
        return of(ConfirmInvestorsNotificationResult.SkipNotification);
      }),
      tap(_ => this.processingSubmit$.next(true)),
      switchMap(selectedOption => {
        if (selectedOption == null || selectedOption === undefined) {
          return EMPTY;
        }
        return this.editAssetDialogContext.gpAssetService
          .updateAsset(model, selectedOption === ConfirmInvestorsNotificationResult.SkipNotification);
      }
      ),
      finalize(() => this.processingSubmit$.next(false)),
      catchError(error => {
        this.processingSubmit$.next(false);
        this.isGeneralServerError = true;
        return throwError(error);
      })
    );
  }

  validateOfferingDeckFileTypeSupported(control: AbstractControl) {
    /*
      Possible using destructing :)
      const { value: metaFile }: { value: MetaFileLink } = control;
      And then: metaFile.id
    */

    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingService.isFileSupportedOfferingDeck(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

  validatePhotoFileTypeSupported(control: AbstractControl) {
    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingService.isFileSupportedPhoto(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

  validatePaymentRequestDocumentFileTypeSupported(control: AbstractControl) {
    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingService.isFileSupportedPaymentRequestDocument(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

  get addressFormFieldsToShow(): AddressFields {
    // tslint:disable-next-line:no-bitwise
    return AddressFields.Country | AddressFields.City | AddressFields.Street |
      AddressFields.Street2 | AddressFields.State | AddressFields.PostalCode;
  }

  protected isHoldingNameExist(holdingName: string): Observable<boolean> {
    return this.gpAssetDataService.isAssetNameExists(holdingName, this.editAssetDialogContext.asset.id);
  }
}
