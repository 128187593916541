import { inject, Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { getOutputTypeName, getRentManagerReportName, MarketRent, RentManagerReport } from './rm.enum';
import { HttpService } from 'src/app/services/http.service';


@Injectable({
  providedIn: 'root'
})
export class RentManagerService {
  private readonly http = inject(HttpService);
  private readonly appSettings = inject(AppSettingsService);
  // private readonly url = 'https://10.0.0.4:7071/api/externalReports';
  private readonly url =`${this.appSettings.resourceUrl}externalReports` ;

  dateRangesPresets = [
    'Custom',
    'This Month',
    'Previous Month',
    'Next Month',
    'This Quarter',
    'Previous Quarter',
    'Next Quarter',
    'This Year',
    'Previous Year',
    'Next Year',
    'Previous 12 Months',
    'Next 12 Months',
    'Since GL Start Date',
  ];

requestRentManagerData(): Observable<any> {
  return this.http.get(`${this.url}/rentManager`)
}

requestRentManagerReport(options) {
  if(!options) {
    console.error('No options provided');
    return of(null);
  };

  const params = {
    // basic options
    outputType: options.output_type,
    rentManagerReport: options.report_type,
    propertyIds: options.properties_type,

    // period
    startDate: this.dateAdapter(options.daterange_type?.start),
    endDate: this.dateAdapter(options.daterange_type?.end),
    asOfDate: this.dateAdapter(options.as_of_date),

    // advanced options
    marketRent: options.advanced_options?.market_rent,
    marketRentEffectiveDate: this.dateAdapter(options.advanced_options?.market_rent_effective_date),
    accountingMethod: options.advanced_options?.accounting_method,
    useWholeDollars: options.advanced_options?.use_whole_dollars,
    excludePeriodAdjustment: options.advanced_options?.exclude_period_adjustment,
    showPercentageOfTotalIncome: options.advanced_options?.show_percentage_of_total_income,
    chartOfAccountMappingId: options.advanced_options?.chart_of_accounts_mapping_id,
    collapseAllParentAccount: options.advanced_options?.collapse_all_parent_account,
    showSummary: options.advanced_options?.show_summary,
    excludeNsfTransactions: options.advanced_options?.exclude_nsf_transactions,
    securityDepositDate: options.advanced_options?.security_deposit_date,
    rentRoleReportMethod: options.advanced_options?.report_method,
    separationMethod: options.advanced_options?.separation_method,

  }

  return this.http.post(`${this.url}/rentManager/run`, params)
}

private getReportType(options: Array<{name: string, id: number}>): FormlyFieldConfig {
  return {
    key: 'report_type',
    type: 'select',
    defaultValue: 0,
    props: {
      required: true,
      label: 'Select report type',
      placeholder: 'Select Report Type',
      options: options.map(({ id }) => ({ id, name: getRentManagerReportName(id) })),
    },
  }
}

private getOutputType(options: Array<{name: string, id: number}>): FormlyFieldConfig {
  return {
    key: 'output_type',
    type: 'select',
    defaultValue: 0,
    props: {
      required: true,
      label: 'Output file type',
      placeholder: 'Select File Type',
      options: options.map(({ id }) => ({ id, name: getOutputTypeName(id) })),
    },
  }
}


private getPropertiesSelect(properties: Array<{id: number, name: string, isActive: boolean, propertyGroupsIds: Array<number>}> = [], propertyGroups:  Array<{name: string, id: number}> = []): FormlyFieldConfig {
  return {
    key: 'properties_type',
    type: 'properties',
    props: {
      required: true,
      validators: [Validators.required, Validators.minLength(1)],
      label: 'Properties Select',
      placeholder: 'Search',
      properties,
      groups: propertyGroups,
    },
  }
}


private getDateType(): FormlyFieldConfig {
    return{
      key: 'as_of_date',
      type: 'date',
      defaultValue: new Date(),
      props: {
        required: true,
        label: 'Select report time period',
        placeholder: 'Select Custom',
        maxDate: new Date(),
      },
      resetOnHide: true,
      expressions: {
        hide: (field: FormlyFieldConfig) => field.parent.model?.report_type !== RentManagerReport.BalanceSheet,
      },
    }
  }

  private getMarketRentEffectiveDateType(): FormlyFieldConfig {
    return{
      key: 'market_rent_effective_date',
      type: 'date',
      defaultValue: new Date(),
      props: {
        required: true,
        placeholder: 'Select Custom',
      },
      resetOnHide: true,
      expressions: {
        hide: (field: FormlyFieldConfig) =>  field.parent.model?.market_rent !== MarketRent.MarketRentBasedOn1Month,
      },
    }
  }
  
  private getDateRangeType(): FormlyFieldConfig {
    return {
        key: 'daterange_type',
        type: 'daterange',
        resetOnHide: true,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.model?.report_type === RentManagerReport.BalanceSheet,
        },
        props: {
          required: true,
          label: 'Select report time period',
          placeholder: 'Select Custom',
          presets: this.dateRangesPresets,
        },
      }
  }

  private getAdvancedOptions(fieldGroup: FormlyFieldConfig[]): FormlyFieldConfig {
    return {
      key: 'advanced_options',
      wrappers: ['advanced'],
      hide: false,
      props: {
        disabled: false,
        label: 'Advanced settings',
      },
      fieldGroup,
    }
  }

  private getAccountingType(): FormlyFieldConfig {
    return {
        key: 'accounting_method',
        type: 'radio',
        defaultValue: 0,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type === RentManagerReport.RentRole,
        },
        props: {
          required: true,
          horizontal: true,
          label: 'Accounting Method',
          options: [
            { id: 0, name: 'Cash' },
            { id: 1, name: 'Accrual' },
          ],
        },
    }
  }

  private getUseWholeDollars(): FormlyFieldConfig {
    return {
        key: 'use_whole_dollars',
        type: 'checkbox',
        defaultValue: false,
        props: {
          label: 'Show whole dollar only',
        },
    }
  }

  private getExcludePeriodAdjustment(): FormlyFieldConfig {
    return {
        key: 'exclude_period_adjustment',
        type: 'checkbox',
        defaultValue: false,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type !== RentManagerReport.ProfitAndLoss,
        },
        props: {
          label: 'Exclude period adjustments',
        },
    }
  }

  private getShowPercentageOfTotalIncome(): FormlyFieldConfig {
    return {
        key: 'show_percentage_of_total_income',
        type: 'checkbox',
        defaultValue: false,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type !== RentManagerReport.ProfitAndLoss,
        },
        props: {
          label: 'Show percent of total income',
        },
    }
  }

  private getRunPropertySeparate(): FormlyFieldConfig {
    return {
        key: 'run_property_separate',
        type: 'checkbox',
        defaultValue: false,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type !== RentManagerReport.RentRole,
        },
        props: {
          label: 'Run property separately',
        },
    }
  }

  private getExcludeNsfTransactions(): FormlyFieldConfig {
    return {
        key: 'exclude_nsf_transactions',
        type: 'checkbox',
        defaultValue: false,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type !== RentManagerReport.RentRole,
        },
        props: {
          label: 'Exclude NSF (Non-sufficient funds) transactions',
        },
    }
  }

  private getCollapseAllParentAccount(): FormlyFieldConfig {
    return {
        key: 'collapse_all_parent_account',
        type: 'checkbox',
        defaultValue: false,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type === RentManagerReport.RentRole,
        },
        props: {
          label: 'Collapse all parent account',
        },
    }
  }


  private getMarketRent(): FormlyFieldConfig {
    return {
        key: 'market_rent',
        type: 'radio',
        defaultValue: 0,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type !== RentManagerReport.RentRole,
        },
        props: {
          vertical: true,
          label: 'Market rent',
          options: [
            { id: 0, name: 'Prorate market rent' },
            { id: 1, name: 'Market rent based on 1 Month' },
          ],
        },
    }
  }

  private getSecurityDepositDate(): FormlyFieldConfig {
    return {
        key: 'security_deposit_date',
        type: 'radio',
        defaultValue: 0,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type !== RentManagerReport.RentRole,
        },
        props: {
          vertical: true,
          label: 'Security deposit date',
          options: [
            { id: 0, name: 'First day of period' },
            { id: 1, name: 'Last day of period' },
          ],
        },
    }
  }

  private getReportMethod(): FormlyFieldConfig {
    return {
        key: 'report_method',
        type: 'radio',
        defaultValue: 0,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type !== RentManagerReport.RentRole,
        },
        props: {
          vertical: true,
          label: 'Report method',
          options: [
            { id: 0, name: 'Current tenants only' },
            { id: 1, name: 'Only activity in the period' },
            { id: 2, name: 'Activity & prior balances' },
          ],
        },
    }
  }

  private getSeparationMethod(): FormlyFieldConfig {
    return {
        key: 'separation_method',
        type: 'radio',
        defaultValue: 0,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type === RentManagerReport.RentRole,
        },
        props: {
          vertical: true,
          label: 'Separation method',
          options: [
            { id: 1, name: 'By properties' },
            { id: 2, name: 'By units' },
            { id: 0, name: 'Do not separate' },
          ],
        },
    }
  }

  private getShowSummary(): FormlyFieldConfig {
    return {
        key: 'show_summary',
        type: 'radio',
        defaultValue: false,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type !== RentManagerReport.CashFlow,
        },
        props: {
          horizontal: true,
          label: 'Show',
          options: [
            { id: false, name: 'Detail' },
            { id: true, name: 'Summary' },
          ],
        },
    }
  }

  private getChartOfAccountsMapping(options: Array<{name: string, id: number}>): FormlyFieldConfig {
    return {
        key: 'chart_of_accounts_mapping_id',
        type: 'select',
        defaultValue: 0,
        expressions: {
          hide: (field: FormlyFieldConfig) => field.parent.parent.model?.report_type === RentManagerReport.RentRole,
        },
        props: {
          label: 'Chart of Accounts Mapping',
          placeholder: 'Select Chart of Accounts Mapping',
          emptyOption: true,
          options,
        },
    }
  }


  private dateAdapter(inputDate: any): string | undefined {
    if(!inputDate) return undefined;
    const date = new Date(inputDate);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}`;
  }

  

  getFormConfig({ properties, propertyGroups, outputTypes, rentManagerReports, chartOfAccountsMappings }): FormlyFieldConfig[] {
    const reportType = this.getReportType(rentManagerReports);
    const outputType = this.getOutputType(outputTypes);
    const propertiesSelect = this.getPropertiesSelect(properties, propertyGroups);
    const dateType = this.getDateType();
    const dateRangeType = this.getDateRangeType();
    
    // advanced options
    const accountingType = this.getAccountingType();
    const useWholeDollars = this.getUseWholeDollars();
    const excludePeriodAdjustment = this.getExcludePeriodAdjustment();
    const showPercentageOfTotalIncome = this.getShowPercentageOfTotalIncome();
    const runPropertySeparate = this.getRunPropertySeparate();
    const excludeNsfTransactions = this.getExcludeNsfTransactions();
    const collapseAllParentAccount = this.getCollapseAllParentAccount();
    const marketRent = this.getMarketRent();
    const marketRentEffectiveDate = this.getMarketRentEffectiveDateType();
    const securityDepositDate = this.getSecurityDepositDate();
    const reportMethod = this.getReportMethod();
    const separationMethod = this.getSeparationMethod();
    const showSummary = this.getShowSummary();
    const chartOfAccountMapping = this.getChartOfAccountsMapping(chartOfAccountsMappings);

    const advancedOptions = this.getAdvancedOptions([ marketRent, marketRentEffectiveDate, securityDepositDate, accountingType, useWholeDollars, excludePeriodAdjustment, showPercentageOfTotalIncome, runPropertySeparate, excludeNsfTransactions,  separationMethod, showSummary, chartOfAccountMapping, collapseAllParentAccount, reportMethod]);
   
    const separator = { template: '<hr class="separator" />'};
    return [reportType,  separator, dateType, dateRangeType, separator, propertiesSelect, separator, outputType, separator, advancedOptions];
  }
  
}
