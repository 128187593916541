import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {take} from 'rxjs/operators';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import {CurrencyModel} from '../../../../../../shared/models/CurrencyModel';
import {DistributionDetailsItemReqRes} from './DistributionDetailsItemReqRes';
import DistributionTransactionPurpose from 'src/app/shared/enums/DistributionTransactionPurpose.enum';
import DistributionPeriod from 'src/app/shared/enums/DistributionPeriod.enum';
import {GpHoldingService} from '../../../gp-holding.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import DistributionTransactionOtherType from '../../../../../../shared/enums/DistributionTransactionOtherType.enum';
import {DistributionService} from '../../distribution.service';

@Component({
  selector: 'terra-distribution-details',
  templateUrl: './distribution-details.component.html',
  styleUrls: ['./distribution-details.component.scss'],
  providers: [DistributionService]
})
export class DistributionDetailsComponent extends OnDestroyMixin implements OnInit, OnChanges {
  @Input() currencies: CurrencyModel[];
  @Input() details: DistributionDetailsItemReqRes;
  pageForm: UntypedFormGroup;

  DistributionPeriod = DistributionPeriod;
  DistributionTransactionPurpose = DistributionTransactionPurpose;
  distributionTransactionPurposeAll = DistributionTransactionPurpose.listAll().filter(reason => reason !== DistributionTransactionPurpose.MultiType);
  distributionTransactionOtherType = DistributionTransactionOtherType;
  distributionTransactionOtherTypeAll = DistributionTransactionOtherType.listAll();

  holding$ = this.gpHoldingService.holding$;

  get periodStartDate() {
    return this.pageForm.get('periodStartDate') as UntypedFormControl;
  }

  get periodEndDate() {
    return this.pageForm.get('periodEndDate') as UntypedFormControl;
  }

  yearsList: number[];
  periodMinDate: Date;
  periodMaxDate: Date;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private gpHoldingService: GpHoldingService,
    private distributionService: DistributionService
  ) {
    super();
  }

  ngOnInit() {
    this.generatePageForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currencies && !changes.currencies.previousValue && this.pageForm) {
      this.pageForm.get('distributionSourceCurrency').setValue(changes.currencies.currentValue[0]);
    }
  }

  private generatePageForm() {
    const startFromYearsAgo = 25;
    this.yearsList = Array.from({length: startFromYearsAgo}, (x, i) => {
      return this.details.year - i;
    });
    this.periodMinDate = new Date(this.details.year - startFromYearsAgo, 0, 1);
    this.periodMaxDate = new Date(this.details.year + 1, 11, 31);

    this.holding$.pipe(take(1)).subscribe(holding => {
      this.pageForm = this.fb.group({
        distributionPeriod: [DistributionPeriod.Quarterly],
        year: [this.details.year, Validators.required],
        quarter: [this.details.quarter, Validators.required],
        periodStartDate: [],
        periodEndDate: [],
        distributionSourceCurrency: [holding.initialCurrency, Validators.required],
        distributionTransactionPurpose: [DistributionTransactionPurpose.PreferredReturn, Validators.required],
        distributionTransactionPurposeOther: [],
        distributionTransactionPurposeOtherType: []
      });
    });

    this.updateDetailsValues(this.pageForm.getRawValue());

    this.pageForm.get('distributionPeriod').valueChanges
    .pipe(untilComponentDestroyed(this))
    .subscribe(val => {
      if (val === DistributionPeriod.Custom) {
        this.pageForm.get('periodStartDate').setValidators([Validators.required]);
        this.pageForm.get('periodEndDate').setValidators([Validators.required]);
      } else {
        this.pageForm.get('periodStartDate').clearValidators();
        this.pageForm.get('periodEndDate').clearValidators();
      }
      this.pageForm.get('periodStartDate').updateValueAndValidity();
      this.pageForm.get('periodEndDate').updateValueAndValidity();
    });

    this.pageForm.get('distributionTransactionPurpose').valueChanges
    .pipe(untilComponentDestroyed(this))
    .subscribe(val => {
      if (val === DistributionTransactionPurpose.Other) {
        this.pageForm.get('distributionTransactionPurposeOther').setValidators([Validators.required, Validators.maxLength(200)]);
        this.pageForm.get('distributionTransactionPurposeOtherType').setValidators([Validators.required]);
      } else {
        this.pageForm.get('distributionTransactionPurposeOther').clearValidators();
        this.pageForm.get('distributionTransactionPurposeOtherType').clearValidators();
      }
      this.pageForm.get('distributionTransactionPurposeOther').updateValueAndValidity();
      this.pageForm.get('distributionTransactionPurposeOtherType').updateValueAndValidity();
    });

    this.pageForm.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(values => {
        this.updateDetailsValues(values);
        this.pageForm.markAllAsTouched();
      });
    this.pageForm.markAllAsTouched();
  }

  private updateDetailsValues(values: any) {
    if (values.distributionPeriod === DistributionPeriod.Custom) {
      if (!(values.periodStartDate && values.periodEndDate)) {
        return this.details.isFormValid = false;
      }
      this.details.distributionStart = values.periodStartDate;
      this.details.distributionEnd = values.periodEndDate;
      this.details.distributionName = `${moment.utc(this.details.distributionStart).format('D MMM YYYY')} - ${moment.utc(this.details.distributionEnd).format('D MMM YYYY')}`;
      this.details.quarter = null;
    } else {
      const dates = this.distributionService.getStartAndEndDates(values.year, values.quarter);
      this.details.distributionStart = dates[0];
      this.details.distributionEnd = dates[1];
      this.details.distributionName = `Q${values.quarter} ${values.year}`;
      this.details.quarter = values.quarter;
    }

    this.details.distributionPeriod = values.distributionPeriod;
    this.details.type = values.distributionTransactionPurpose;
    this.details.typeOther = values.distributionTransactionPurposeOther;
    this.details.currency = values.distributionSourceCurrency;
    this.details.year = values.year;
    this.details.isFormValid = this.pageForm.valid;
  }

  currencyCompareFn(c1: CurrencyModel, c2: CurrencyModel) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
