import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FilestackService } from '@filestack/angular';
import { ClientOptions, Security } from 'filestack-js';
import {
  PickerInstance,
  PickerOptions,
  PickerResponse,
  PickerStoreOptions,
} from 'filestack-js/build/main/lib/picker';
import { of, switchMap, take } from 'rxjs';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { UserService } from 'src/app/services/shared/user.service';
import { FilestackStatus } from 'src/app/shared/enums/filestack-status.enum';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';
import { DiscardUploadComponent } from '../discard-upload/discard-upload.component';
import { UploadDocumentsService } from '../upload-documents.service';
import {
  FILE_FORMATS,
  MAX_FILE_SIZE,
  SHARE_CONTAINER,
  SHARE_LOCATION,
} from './upload.config';

@Component({
  selector: 'terra-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  // standalone
})
export class UploadComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('droppanel') container;
  @Input() holdingId: number;
  @Output() uploadComplited = new EventEmitter();
  @Output() newFolder = new EventEmitter();

  create: boolean = false;
  picker: PickerInstance;
  options: PickerOptions;
  filestackClientOptions: ClientOptions;

  currentFolderId: number;

  finalFiles: any[] = [];
  uploadingFiles = [];
  bufferValue = 0;

  limit: number = 999;
  files$ = this.uploadDocumentsService.files$;
  constructor(
    private readonly telemetryService: TelemetryService,
    private readonly userService: UserService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly appSettingsService: AppSettingsService,
    private readonly uploadDocumentsService: UploadDocumentsService,
    private readonly filestackService: FilestackService
  ) {}

  ngOnInit(): void {
    this.userService
      .getClientDetails()
      .pipe(take(1))
      .subscribe((clientDetails) => {
        this.telemetryService.create(
          {
            eventID: '4003',
            eventTitle: 'Holding-Upload-and-Share-Upload-Screen',
            holdingID: this.holdingId,
            organizationID: clientDetails.organizationDetails.id,
          },
          AnalyticsServiceNameModel.Mixpanel |
            AnalyticsServiceNameModel.Insights
        );
      });

    this.files$.subscribe((files) => {
      this.finalFiles = files.filter(
        (file) => file.filestackStatus === FilestackStatus.Ready
      );
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.currentFolderId = params.folderId;
    });

    this.uploadDocumentsService.documentsChanged$
      .pipe(
        switchMap(() => {
          const ids = this.files$.value.map((file) => file.id);
          return ids.length
            ? this.uploadDocumentsService.updateFiles()
            : of([]);
        })
      )
      .subscribe();

    this.uploadDocumentsService.quotaDetails$.subscribe((quotaDetails) => {
      const maxFiles =
        quotaDetails.maxStorageFilesCount -
        quotaDetails.currentStorageFilesCount;

      if (this.limit !== maxFiles) {
        this.limit = maxFiles;

        this.options = {
          ...this.options,
          maxFiles,
        };

        this.reloadDropPanel(this.options, this.limit > 0);
      }
    });
  }

  ngAfterViewInit(): void {
    this.createUploadContainer();
  }

  ngOnDestroy(): void {
    this.picker?.close(); // fix for duplicating picker initialization
    this.container.nativeElement.textContent = ''; // clear container
  }

  createUploadContainer() {
    this.uploadDocumentsService
      .getFilestackPolicy()
      .pipe(take(1))
      .subscribe((policy) => {
        const pickerOptions: PickerOptions = {
          onUploadStarted: (files) => {
            this.bufferValue = this.files$.value?.length;
            this.uploadingFiles = files;
          },
          onUploadDone: (event) => this.onUploadSuccess(event),
          onFileUploadFailed: (_, error) => this.onUploadError(error),

          // images larger than this size will be resized by the picker
          imageMax: [1920, 1920],
          displayMode: 'dropPane',
          customText: {
            ['Drag and Drop, Copy and Paste Files']: 'Upload Files',
          },
          container: this.container?.nativeElement,
          maxFiles: this.limit,
          maxSize: MAX_FILE_SIZE,
          accept: FILE_FORMATS,
          storeTo: {
            location: SHARE_LOCATION,
            container: SHARE_CONTAINER,
          } as PickerStoreOptions,
        } as PickerOptions;

        const filestackClientOptions = {
          security: {
            policy: policy.filestackPolicy,
            signature: policy.filestackSignature,
          } as Security,
        } as ClientOptions;

        this.options = pickerOptions;
        this.filestackClientOptions = filestackClientOptions;

        this.filestackService.init(
          this.appSettingsService.fileStackApiKey,
          filestackClientOptions
        );

        this.reloadDropPanel(pickerOptions);
      });
  }

  onUploadSuccess(pickerResponse: PickerResponse) {
    this.uploadDocumentsService
      .createFiles(pickerResponse, this.currentFolderId)
      .subscribe((resultFiles) => {
        this.bufferValue = 100;
        this.uploadingFiles = [];
      });
  }

  onUploadError($event) {}

  remove(id: number) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '500px',
      data: {
        title: 'Are you sure you want to delete this file?',
        confirmButton: 'Delete',
      },
    };
    const dialogRef = this.dialog.open(DiscardUploadComponent, config);

    return dialogRef
      .afterClosed()
      .pipe(
        switchMap((confirm) => {
          if (confirm) {
            return this.uploadDocumentsService.deleteFiles([id]);
          }
          return of();
        })
      )
      .subscribe();
  }

  updateRoute(newFolderId: number) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { folderId: newFolderId },
      queryParamsHandling: 'merge',
    });
  }

  disable(isDisabled: boolean) {
    this.uploadComplited.emit(isDisabled);
  }
  updateFolderName(newFolder: boolean) {
    if (!newFolder) {
      this.newFolder.emit('');
    }
  }

  private reloadDropPanel(pickerOptions: PickerOptions, shouldStart = true) {
    if (this.filestackClientOptions) {
      this.picker?.close();

      this.container.nativeElement.textContent = ''; // clear container

      this.picker = this.filestackService.picker(pickerOptions);
      if (shouldStart) {
        this.picker.open();
      }
    }
  }
}
