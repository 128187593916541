// Must match the server enum
export enum DistributionStatus {
  None = 0,
  New = 10,
  NoticeSent = 20,
  WaitingForFunds = 30,
  ProcessingTransfers = 40,
  Completed = 50,
  PartialCompleted = 60,
  Failed = 70,
  Canceled = 80
}

// tslint:disable-next-line:no-namespace
export namespace DistributionStatus {
  export function toString(distributionStatus: DistributionStatus): string {
    switch (distributionStatus) {
      case DistributionStatus.New:
        return 'New';
      case DistributionStatus.NoticeSent:
        return 'Notice Sent to Investors';
      case DistributionStatus.WaitingForFunds:
        return 'Awaiting for Funds Deposit';
      case DistributionStatus.ProcessingTransfers:
        return 'Processing Transfers to Investors';
      case DistributionStatus.Completed:
        return 'Completed';
      case DistributionStatus.PartialCompleted:
        return 'Partially Completed';
      case DistributionStatus.Failed:
        return 'Failed';
      case DistributionStatus.Canceled:
        return 'Canceled';
      default:
        return null;
    }
  }

  export function parse(distributionStatusStr: string): DistributionStatus {
    return DistributionStatus[distributionStatusStr];
  }

  export function finalStatuses(): DistributionStatus[] {
    return [DistributionStatus.Completed, DistributionStatus.PartialCompleted, DistributionStatus.Canceled, DistributionStatus.Failed];
  }

  export function afterFinalizedStatuses(): DistributionStatus[] {
    return [DistributionStatus.Completed, DistributionStatus.PartialCompleted, DistributionStatus.WaitingForFunds, DistributionStatus.ProcessingTransfers];
  }
}

export default DistributionStatus;
