import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {UserService} from 'src/app/services/shared/user.service';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import {LpInterest} from 'src/app/dashboard/models/lpInterest.enum';
import {AssetInvestorService} from '../../asset-investor/asset-investor.service';

@Component({
  selector: 'terra-asset-investor-reactions-to-offering-deck',
  templateUrl: './asset-investor-reactions-to-offering-deck.component.html',
  styleUrls: ['./asset-investor-reactions-to-offering-deck.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetInvestorReactionsToOfferingDeckComponent implements OnInit {

  LpInterest = LpInterest;

  isActiveFeature$ = this.userService.userHasFeatureFlag(FeatureFlags.LpEngagement);

  investmentDetails$ = this.assetInvestorService.investmentDetails$;

  constructor(private userService: UserService, private assetInvestorService: AssetInvestorService) {
  }

  ngOnInit() {
  }
}
