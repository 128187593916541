/*
 *  Every change in this enum should be reflected also in the enum in the server in "DistributionTransactionOtherType"
 */

export enum DistributionTransactionOtherType {
  None = 0,
  Principal = 1,
  Interest = 2
}

// tslint:disable-next-line:no-namespace
export namespace DistributionTransactionOtherType {
  export function toString(type: DistributionTransactionOtherType): string {
    switch (type) {
      case DistributionTransactionOtherType.Principal:
        return 'Principal';
      case DistributionTransactionOtherType.Interest:
        return 'Interest';
      default:
        return null;
    }
  }

  export function parse(distributionTransactionType: string): DistributionTransactionOtherType {
    return DistributionTransactionOtherType[distributionTransactionType];
  }

  export function listAll() {
    return [
      DistributionTransactionOtherType.Principal,
      DistributionTransactionOtherType.Interest
    ];
  }
}

export default DistributionTransactionOtherType;
