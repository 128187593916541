<!-- pro rata calculation -->
<section [formGroup]="pageForm" class="prorata full-width">
  <section class="prorata-gp-promote">
    <div [ngClass]="purposesInvestorFormArray.controls.length > 1 ?'prorata-multi-type' : 'prorata-single'"
         formArrayName="proRataValues">
      <ng-container *ngFor="let valueControl of purposesInvestorFormArray.controls; let i = index;">
        <span *ngIf="purposesInvestorFormArray.controls.length > 1" class="transfer-type-number">{{i + 1}}</span>
        <span class="transfer-type-string">
          {{purposesGpPromoteFormArray.controls.length > 1 ? (distributionPurposes[i] | enumString: DistributionTransactionPurpose) + ' -' : ''}} Total Amount
        </span>
        <mat-form-field appearance="outline" class="remove-error-place prorata-value">
          <input type="text" [formControl]="valueControl" terraFormattedNumber
                 placeholder="0" matInput class="lo_sensitive" autocomplete="off"/>
          <span matSuffix>{{currencyIso}}</span>
        </mat-form-field>
      </ng-container>
    </div>
    <div [ngClass]="purposesGpPromoteFormArray.controls.length > 1 ?'gp-promote-multi-type' : 'gp-promote-single'"
         formArrayName="gpPromoteValues">
      <ng-container *ngFor="let valueControl of purposesGpPromoteFormArray.controls; let i = index;">
        <span class="transfer-type-string">
          GP Promote
        </span>
        <mat-form-field appearance="outline" class="remove-error-place gp-promote-value">
          <input type="text" [formControl]="valueControl" terraFormattedNumber placeholder="0" matInput
                 class="lo_sensitive" autocomplete="off" />
          <span matSuffix>{{currencyIso}}</span>
        </mat-form-field>
      </ng-container>
    </div>
  </section>
  <button type="button" class="tall-button calculate-button" (click)="calculateProRata()"
          [disabled]="disableProRataCalculation" mat-flat-button color="primary">
    Calculate Pro Rata
  </button>
</section>
