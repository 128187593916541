<div class="upload-container">
  <mat-checkbox color="primary" [(ngModel)]="create" class="create-folder" (ngModelChange)="updateFolderName($event)">
    Create a new folder
  </mat-checkbox>

  <ng-container *ngIf="create">
    <terra-create-folder [holdingId]="holdingId" [currentFolderId]="currentFolderId" (disable)="disable($event)" (update)="newFolder.emit($event)">
    </terra-create-folder>
  </ng-container>

  <div class="upload-block">
    <div #droppanel class="drop-pane" [hidden]="limit<=0"></div>
    <div *ngIf="limit>0; else filesLimitReached"></div>
  </div>


  <ng-container *ngIf="files$ | async as files">

    <div class="progress-indicator" *ngIf="uploadingFiles.length || files.length">
      <div class="progress-title">
        <div class="">Processing</div>
        <div class="">
          {{finalFiles.length + uploadingFiles.length}}/{{files.length + uploadingFiles.length}} files</div>
      </div>
      <mat-progress-bar color="secondary" mode="buffer"
        [value]="(finalFiles.length/(uploadingFiles.length + files.length))*100"
        [bufferValue]="bufferValue"></mat-progress-bar>
    </div>


    <!-- table -->

    <table class="gray-theme mat-elevation-z0">
      <tr *ngFor="let file of files">
        <td>
          <terra-file-preview [file]="file"></terra-file-preview>
        </td>
        <td>
          {{file.metaFileLink.sizeB | fileSize}}
        </td>
        <td>
          <terra-uploading-status [status]="file.filestackStatus"></terra-uploading-status>
        </td>
        <td style="text-align: right;">
          <button mat-icon-button aria-label="Remove file" (click)="remove(file.id)">
            <mat-icon class="delete-icon material-icons-outlined" aria-hidden="false" aria-label="Remove file">delete_outline</mat-icon>
          </button>

        </td>
    </table>
  </ng-container>

  <ng-template #filesLimitReached>
    <div class="filesLimitReached">
      <h3>Files Limit Reached</h3>
      <div>
        <p>Your current subscription has reached its maximum document limit.</p>
        <p>To increase your limit, please <a href="https://www.covercy.com/contact-us" target="_blank">contact</a> the Covercy Success Team.</p>
      </div>
    </div>
  </ng-template>
</div>
