<div [ngSwitch]="status" class="uploading-status">
  <ng-container class="" *ngSwitchCase="FilestackStatus.Processing">
    <mat-spinner diameter="20"></mat-spinner>
    Uploading...
  </ng-container>
  <ng-container *ngSwitchCase="FilestackStatus.Ready">
    <mat-icon class="notranslate">check_circle</mat-icon>
    Uploaded
  </ng-container>
  <ng-container *ngSwitchDefault>
    <mat-icon class="notranslate">error</mat-icon>
    Error
  </ng-container>
</div>
