import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { take } from 'rxjs';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';
import { UserService } from 'src/app/services/shared/user.service';
import { GpHoldingService } from '../../../gp-holding.service';

@Component({
  selector: 'terra-prepare',
  templateUrl: './prepare.component.html',
  styleUrls: ['./prepare.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // standalone
})
export class PrepareComponent implements OnInit {
  holdingId$ = this.gpHoldingService.holdingId$;
  constructor(
    private readonly gpHoldingService: GpHoldingService,
    private readonly telemetryService: TelemetryService,
    private readonly userService: UserService
  ) {}
  @Output() onSkip = new EventEmitter();

  ngOnInit(): void {
    this.holdingId$.pipe(take(1)).subscribe((holdingId) => {
      this.userService
        .getClientDetails()
        .pipe(take(1))
        .subscribe((clientDetails) => {
          this.telemetryService.create(
            {
              eventID: '4002',
              eventTitle: 'Holding-Upload-and-Share-Intro-Screen',
              holdingID: holdingId,
              organizationID: clientDetails.organizationDetails.id,
            },
            AnalyticsServiceNameModel.Mixpanel |
              AnalyticsServiceNameModel.Insights
          );
        });
    });
  }
}
