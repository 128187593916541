<form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" (ngSubmit)="stepSubmit()"
  class="form-grid-new container-750 terra-error-style">
  <h3 class="section-title">General</h3>

  <!-- Asset Name -->
  <div class="terra-form-field-wrapper">
    <label for="txtAssetName" class="text-input-label">Asset name</label>
    <mat-form-field appearance="outline">
      <input matInput id="txtAssetName" formControlName="assetName" autocomplete="asset-name" class="lo_sensitive"
        placeholder="e.g. 143 Mott st." />
      <mat-error *ngIf="pageForm.get('assetName').hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="pageForm.get('assetName').hasError('minlength')">
        Please enter at least 2 characters
      </mat-error>
      <mat-error *ngIf="pageForm.get('assetName').hasError('holdingNameTaken')">
        An asset with the same name already exists.
      </mat-error>
      <mat-error *ngIf="pageForm.get('assetName').hasError('includeForbiddenCharacter')">
        Asset name shouldn't contain the following characters:
        {{ forbiddenCharsInHoldingName.join(", ") }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Legal Name -->
  <div class="terra-form-field-wrapper">
    <label for="txtLegalName" class="text-input-label">Legal name (can be changed later)</label>
    <mat-form-field appearance="outline">
      <input matInput id="txtLegalName" formControlName="legalName" autocomplete="investing-entity-name"
        class="lo_sensitive" placeholder="e.g. ABC group LLC" />
      <mat-error *ngIf="pageForm.get('legalName').hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="pageForm.get('legalName').hasError('minlength')">
        Please enter at least 2 characters
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Estimated Closing date -->
  <div class="terra-form-field-wrapper">
    <terra-custom-utc-date-picker id="txtClosingDate" formControlName="estimatedClosingDate"
      label="Estimated / final closing date" [minDate]="estimatedClosingDateMinimumDate"
      [requiredError]="pageForm.get('estimatedClosingDate').hasError('required')"
      [minError]="pageForm.get('estimatedClosingDate').hasError('minDate')"
      minErrorTxt="Please enter a valid closing date"
      [touched]="pageForm.get('estimatedClosingDate').touched"
      [startAt]="estimatedClosingDateStartAt"></terra-custom-utc-date-picker>
  </div>

  <!-- Security type -->
  <div class="terra-form-field-wrapper">
    <label for="ddlInvestmentSecurityType" class="text-input-label">Security type</label>
    <mat-form-field appearance="outline">
      <mat-select id="ddlInvestmentSecurityType" placeholder="Select" formControlName="investmentSecurityType">
        <mat-option *ngFor="let item of InvestmentSecurityTypesList" [value]="item">{{ item | enumString :
          InvestmentSecurityType }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="pageForm.get('investmentSecurityType').hasError('required')">Required</mat-error>
    </mat-form-field>
  </div>

  <!-- FundraisingTargetAmount and currency -->
  <div class="terra-form-field-wrapper amount-with-currency">
    <label for="txtFundraisingTargetAmount" class="amount-label text-input-label">
      {{
      selectedSecurityType === InvestmentSecurityType.DirectOwnership
      ? "Purchase price"
      : "Fundraising target amount"
      }}
    </label>
    <mat-form-field appearance="outline" class="amount-field-wrapper">
      <input type="tel" terraFormattedNumber matInput id="txtFundraisingTargetAmount"
        formControlName="fundraisingTargetAmount" class="lo_sensitive" />
      <mat-error *ngIf="pageForm.get('fundraisingTargetAmount').hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="pageForm.get('fundraisingTargetAmount').hasError('min')">
        {{
        selectedSecurityType === InvestmentSecurityType.DirectOwnership
        ? "Purchase price must be greater than zero"
        : "Target amount must be greater than zero"
        }}
      </mat-error>
    </mat-form-field>
    <label for="ddlCurrency" class="currency-label text-input-label">Currency
    </label>
    <mat-form-field appearance="outline" class="currency-ddl-wrapper">
      <mat-select id="ddlCurrency" placeholder="Select" formControlName="currency" [compareWith]="currencyCompareFn">
        <mat-option *ngFor="let currency of currencies$ | async" [value]="currency">
          {{ currency.symbol }} {{ currency.iso }}</mat-option>
      </mat-select>
      <mat-error *ngIf="pageForm.get('currency').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Minimum investment -->
  <div class="terra-form-field-wrapper">
    <label for="txtMinimumInvestmentAmount" class="text-input-label">
      {{
      selectedSecurityType === InvestmentSecurityType.DirectOwnership
      ? "Initial purchase price (optional)"
      : "Minimum investment (optional)"
      }}
    </label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtMinimumInvestmentAmount" terraFormattedNumber
        formControlName="minimumInvestmentAmount" class="lo_sensitive" />
      <span matSuffix>{{ pageForm.get("currency").value?.iso }}</span>
    </mat-form-field>
  </div>

  <!-- Asset type / Property type -->
  <div class="terra-form-field-wrapper">
    <label for="ddlHoldingType" class="text-input-label">Property type</label>
    <mat-form-field appearance="outline">
      <mat-select id="ddlHoldingType" placeholder="Select" formControlName="holdingType">
        <mat-option *ngFor="let item of assetTypesList" [value]="item">{{
          item | enumString : HoldingType
          }}</mat-option>
      </mat-select>
      <mat-error *ngIf="pageForm.get('holdingType').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Investment type -->
  <div class="terra-form-field-wrapper">
    <label for="ddlInvestmentType" class="text-input-label">
      Investment type
    </label>
    <mat-form-field appearance="outline">
      <mat-select id="ddlInvestmentType" placeholder="Select" formControlName="investmentType">
        <mat-option *ngFor="let item of InvestmentTypesList" [value]="item">{{
          item | enumString : InvestmentType
          }}</mat-option>
      </mat-select>
      <mat-error *ngIf="pageForm.get('investmentType').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Other strategy text -->
  <div class="terra-form-field-wrapper" *ngIf="pageForm.get('investmentType').value === InvestmentType.Other">
    <label for="txtInvestmentTypeOther" class="text-input-label">
      Please specify
    </label>
    <mat-form-field appearance="outline">
      <input type="text" matInput id="txtInvestmentTypeOther" formControlName="investmentTypeOther"
        class="lo_sensitive" />
      <mat-error *ngIf="pageForm.get('investmentTypeOther').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
  </div>

  <div id="capitalCommitments" class="enable-capital-commitment-container">
    <mat-slide-toggle hideIcon labelPosition="before" class="enable-capital-commitment-toggle"
      formControlName="commitmentsEnabled" (change)="enableCommitmentsChanged($event)">
      <span>Enable Capital Commitments</span>
      <mat-icon class="capital-commitments-help-icon"
        pTooltip="Enable this for deals with capital commitments (common in funds and development assets), allowing you to manage and view commitments. Do not enable for deals requiring upfront full payment."
        tooltipPosition="top">help_outline
      </mat-icon>
    </mat-slide-toggle>
    <div class="flex-column" *ngIf="pageForm.get('commitmentsEnabled')?.value">
      <span>Ownership Calculation
        <mat-icon class="capital-commitments-help-icon"
          pTooltip="Select this option to calculate investor share ownership based on their committed amount rather than their actual contributions."
          tooltipPosition="top">help_outline</mat-icon>
      </span>
      <mat-radio-group class="radio-group" aria-label="Select an option" formControlName="ownershipCalculationType">
        <mat-radio-button [value]="OwnershipCalculationType.ByContributions" class="boxed-radio-button"
          color="primary">By Contributions</mat-radio-button>
        <mat-radio-button [value]="OwnershipCalculationType.ByCommitments" class="boxed-radio-button" color="primary">By
          Commitments</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <!-- Asset Address -->
  <ng-container>
    <h3 class="section-title">Asset Address</h3>
    <terra-google-map-search-input class="full-width" formControlName="assetLocation"
      [isOuterFormSubmitted]="isSubmitAttempt"></terra-google-map-search-input>
    <!-- <terra-address-form
      [externalForm]="pageForm" partialFormName="assetLocation" [formSettings]="addressFormSettings"
      [locationDetailsResponse]="location" class="full-width form-grid-new">
    </terra-address-form> -->
  </ng-container>

  <h3 class="section-title asset-details-section-title" *ngIf="pageForm.get('holdingType').value">
    Asset details (optional)
    <span class="area-unit-toggle">
      <label>Units:</label>
      <mat-button-toggle-group formControlName="areaUnits" aria-label="Area unit" [hideSingleSelectionIndicator]="true">
        <mat-button-toggle [value]="AreaUnit.SquareFeet">Square foot</mat-button-toggle>
        <mat-button-toggle [value]="AreaUnit.SquareMeters">Square meter</mat-button-toggle>
      </mat-button-toggle-group>

      <!-- <span (click)="selectedAreaUnit = AreaUnit.SquareFeet" class="unit-button"
        [ngClass]="{'active-unit' : selectedAreaUnit === AreaUnit.SquareFeet }">
        Square foot</span> <span class="separator"> | </span>
      <span (click)="selectedAreaUnit = AreaUnit.SquareMeters" class="unit-button"
        [ngClass]="{'active-unit' : selectedAreaUnit === AreaUnit.SquareMeters }">
        Square meter</span>
      -->
    </span>
  </h3>

  <!-- Lot size in sqft  -->
  <div class="terra-form-field-wrapper" *ngIf="isDisplayDataPoint(allowedDataPointsListFor.lotSizeArea)">
    <label for="txtLotSize" class="text-input-label">Lot size in {{ selectedAreaUnit | enumString : AreaUnit }}</label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtLotSize" terraFormattedNumber formControlName="lotSizeArea"
        class="lo_sensitive" />
    </mat-form-field>
  </div>

  <!-- Stories  -->
  <div class="terra-form-field-wrapper" *ngIf="isDisplayDataPoint(allowedDataPointsListFor.stories)">
    <label for="txtStories" class="text-input-label"> Number of floors </label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtStories" terraFormattedNumber formControlName="stories" class="lo_sensitive" />
    </mat-form-field>
  </div>

  <!-- Residential units  -->
  <div class="terra-form-field-wrapper" *ngIf="isDisplayDataPoint(allowedDataPointsListFor.residentialUnits)">
    <label for="txtResidentialUnits" class="text-input-label">
      Number of residential units
    </label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtResidentialUnits" terraFormattedNumber formControlName="residentialUnits"
        class="lo_sensitive" />
    </mat-form-field>
  </div>

  <!-- Retail units  -->
  <div class="terra-form-field-wrapper" *ngIf="isDisplayDataPoint(allowedDataPointsListFor.retailUnits)">
    <label for="txtRetailUnits" class="text-input-label">
      Number of retail units
    </label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtRetailUnits" terraFormattedNumber formControlName="retailUnits"
        class="lo_sensitive" />
    </mat-form-field>
  </div>

  <!-- Rooms  -->
  <div class="terra-form-field-wrapper" *ngIf="isDisplayDataPoint(allowedDataPointsListFor.rooms)">
    <label for="txtRooms" class="text-input-label"> Number of rooms </label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtRooms" terraFormattedNumber formControlName="rooms" class="lo_sensitive" />
    </mat-form-field>
  </div>

  <!-- Gross building [area] -->
  <div class="terra-form-field-wrapper" *ngIf="isDisplayDataPoint(allowedDataPointsListFor.grossBuildingArea)">
    <label for="txtGrossBuildingArea" class="text-input-label">
      Gross building in {{ selectedAreaUnit | enumString : AreaUnit }}
    </label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtGrossBuildingArea" terraFormattedNumber formControlName="grossBuildingArea"
        class="lo_sensitive" />
    </mat-form-field>
  </div>

  <!--Residential net sellable [area] -->
  <div class="terra-form-field-wrapper" *ngIf="
      isDisplayDataPoint(allowedDataPointsListFor.residentialNetSellableArea)
    ">
    <label for="txtResidentialNetSellableArea" class="text-input-label">
      Residential net sellable in {{ selectedAreaUnit | enumString : AreaUnit }}
    </label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtResidentialNetSellableArea" terraFormattedNumber
        formControlName="residentialNetSellableArea" class="lo_sensitive" />
    </mat-form-field>
  </div>

  <!-- Retail net sellable [area]-->
  <div class="terra-form-field-wrapper" *ngIf="isDisplayDataPoint(allowedDataPointsListFor.retailNetSellableArea)">
    <label for="txtRetailNetSellableArea" class="text-input-label">
      Retail net sellable in {{ selectedAreaUnit | enumString : AreaUnit }}
    </label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtRetailNetSellableArea" terraFormattedNumber
        formControlName="retailNetSellableArea" class="lo_sensitive" />
    </mat-form-field>
  </div>

  <!-- Number of sites-->
  <div class="terra-form-field-wrapper" *ngIf="isDisplayDataPoint(allowedDataPointsListFor.numberOfSites)">
    <label for="txtNumberOfSites" class="text-input-label">
      Number of sites
    </label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtNumberOfSites" terraFormattedNumber formControlName="numberOfSites"
        class="lo_sensitive" />
    </mat-form-field>
  </div>

  <!-- <h3 class="section-title asset-details-section-title">
    Contact information
  </h3>
  <div class="terra-form-field-wrapper">
    <label for="txtEmail" class="text-input-label">
      Email address
    </label>
    <mat-form-field appearance="outline">
      <input matInput id="txtEmail" formControlName="email" class="lo_sensitive" />
      <mat-error *ngIf="pageForm.get('email').hasError('email')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="pageForm.get('email').hasError('required')">
        Email is required
      </mat-error>
    </mat-form-field>
  </div> -->
</form>

<section class="footer">
  <div class="container-750">
    <!-- Error message -->
    <div *ngIf="isSubmitAttempt" class="error-section">
      <p *ngIf="!pageForm.valid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
    <div class="form-actions">
      <a (click)="cancel()" class="cancel-button">Cancel</a>
      <button type="submit" form="stepForm" mat-flat-button color="primary" class="page-cta">
        Next
      </button>
    </div>
  </div>
</section>