import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {take} from 'rxjs/operators';

import {FeatureFlags} from 'src/app/account/my-account/model/FeatureFlags.enum';
import {ShareMarketingParams} from 'src/app/dashboard/assets/gp-asset/share-asset/ShareMarketingParams.model';
import {PermissionService} from 'src/app/permission/permission.service';
import {UserService} from 'src/app/services/shared/user.service';
import {InvestmentSecurityType} from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {UtilsService} from '../../../../../services/utils.service';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {FundInvestorService} from '../../fund-investor/fund-investor.service';

export class FundSendMarketingDialogContext {
  constructor(public shareMarketingParams: ShareMarketingParams, public assetInvestorService: FundInvestorService, public userService: UserService) {
  }
}

@Component({
  selector: 'terra-fund-send-marketing-dialog',
  templateUrl: './fund-send-marketing-dialog.component.html',
  styleUrls: ['./fund-send-marketing-dialog.component.scss']
})
export class FundSendMarketingDialogComponent implements OnInit {
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  pageForm: UntypedFormGroup;
  isSubmitted = false;
  isEmailWhiteLabelingEnabled = false;

  subjectText = `New opportunity from ${this.context.shareMarketingParams.agentCompanyName}`;

  bodyText = '';

  constructor(
    private permissionService: PermissionService,
    public dialogRef: MatDialogRef<FundSendMarketingDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public context: FundSendMarketingDialogContext,
    private utilsService: UtilsService
  ) {
  }

  ngOnInit() {
    this.generateForm();
    this.allowInvestorName$.pipe(take(1)).subscribe(
      allowInvestorName => {
        this.setTexts(allowInvestorName);
        this.pageForm.get('message').patchValue(this.bodyText);
      }
    );
  }

  setTexts(allowInvestorName: boolean) {
    const securityType = this.context.shareMarketingParams.investmentSecurityType;

    const investmentText = !securityType ? '' : InvestmentSecurityType.toString(securityType).toLowerCase();

    this.bodyText =
      `<p>Hi ${allowInvestorName ? this.context.shareMarketingParams.lpFirstName : ''}, </p>
    <p>${this.context.shareMarketingParams.agentCompanyName} is using Covercy to share this new ${investmentText} opportunity with you: ${this.context.shareMarketingParams.assetName}.</p>`;
  }

  generateForm() {
    this.context.userService.userHasFeatureFlag(FeatureFlags.EmailWhitelabeling).pipe(take(1)).subscribe(enabled => this.isEmailWhiteLabelingEnabled = enabled);
    this.pageForm = this.fb.group({
      message: [this.bodyText],
      subject: new UntypedFormControl({value: this.subjectText, disabled: !this.isEmailWhiteLabelingEnabled}, Validators.required)
    });
  }

  send() {
    this.isSubmitted = true;
    if (this.pageForm.valid) {
      this.context.assetInvestorService.sendMarketingDeck(this.pageForm.value.message, this.pageForm.getRawValue().subject).subscribe(
        isMailSent => {
          this.dialogRef.close(isMailSent);
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            console.log('Error', error);
            this.dialogRef.close(false);
          }
        }
      );
    }
  }
}
