import {Injectable, ViewContainerRef} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, combineLatest} from 'rxjs';
import {filter, map, switchMap, take, tap, switchMapTo, withLatestFrom} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import {FundraisingReqRes} from '../fundraising/fundraisings-tab/FundraisingReqRes.model';
import {IssueCapitalCallRequestDto} from './models';
import {GpFundraisingDataService} from '../../../../services/gp/gp-fundraising-data.service';
import {HoldingFundraisingService} from '../fundraising/holding-fundraising.service';
import {TerraUtils} from '../../../../shared/TerraUtils';
import {RoutingService} from '../../../../services/routing.service';
import InvestmentStatus from '../../../../shared/enums/InvestmentStatus.enum';
import {InvestmentReqRes} from '../../../models/investment.model';
import {GpInvestmentDataService} from '../../../../services/gp/gp-investment-data.service';
import {GpHoldingService} from '../gp-holding.service';
import {CapitalCallConfirmDialogComponent, CapitalCallConfirmDialogParams} from './capital-call-confirm-dialog/capital-call-confirm-dialog.component';
import {AlertDialogParams} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {DialogService} from 'src/app/services/dialog.service';
import { GpHolding } from '../GpHolding.model';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Injectable()
export class CapitalCallService {
  forbiddenNameCharacters = TerraUtils.consts.validators.FORBIDDEN_CHARACTERS_IN_NAME;
  fundraising$ = this.holdingFundraisingService.fundraising$;
  investments$ = this.fundraising$.pipe(map(fundraising => fundraising.investments));
  holding$ = this.holdingFundraisingService.holding$;
  manualGeneratingPaymentRequests$ = new BehaviorSubject<boolean>(false);
  isGeneratingPaymentRequests$ = combineLatest([this.fundraising$.pipe(map(fundraising => fundraising.isGeneratingPaymentRequests)), this.manualGeneratingPaymentRequests$])
  .pipe(map(([isGeneratingPaymentRequests, manualGeneratingPaymentRequests]) => isGeneratingPaymentRequests || manualGeneratingPaymentRequests))
  issuableInvestments$ = this.investments$.pipe(map(investments => investments.filter(inv => inv.status === InvestmentStatus.Signed)));

  constructor(private holdingFundraisingService: HoldingFundraisingService,
              private holdingService: GpHoldingService,
              private fundraisingDataService: GpFundraisingDataService,
              private routingService: RoutingService,
              private router: Router,
              private investmentDataService: GpInvestmentDataService,
              private vcr: ViewContainerRef,
              private snackbarService: SnackbarService,
              private dialogService: DialogService,
              private matDialog: MatDialog) {
  }

  /**
   * Updates investment
   * @param investmentDetails investment details
   */
  public updateInvestment(investmentDetails: InvestmentReqRes) {
    return this.fundraising$.pipe(
      take(1),
      switchMap(fundraising => {
        return this.investmentDataService.updateCapitalCallInvestment(fundraising.holdingId, fundraising.id, investmentDetails);
      }),
      tap(updatedInvestment => {
        this.holdingFundraisingService.updatedInvestment$.next(updatedInvestment);
      })
    );
  }

/**
 * Delete Investment
 * @param investmentId
 */
  deleteInvestment(investmentId: number): Observable<boolean>{
    return this.investments$.pipe(
      take(1),
      map(investments => investments.find(inv => inv.id === investmentId)),
      filter(inv => !!inv && inv.status === InvestmentStatus.Declined),
      withLatestFrom(this.fundraising$),
      switchMap(([_, fundraising]) => this.investmentDataService.delete(fundraising.holdingId, fundraising.id, investmentId)),
      tap(deleted => deleted ? this.holdingFundraisingService.deletedInvestmentId$.next(investmentId) : '')
    );
  }

  /**
   * Opens update holding status dialog
   */
  public showUpdateStatus() {
    this.holdingService.showUpdateStatus();
  }

  /**
   * Get investors ids from array from group.
   * @param investments Investments array
   */
  public getInvestmentsIds(investments: InvestmentReqRes[]): number[] {
    return investments.map(item => item.id);
  }

  public getInvestmentsIdsFormGroup(investments: UntypedFormGroup[]): number[] {
    return investments.map(fg => fg.get('id').value);
  }

  /**
   * Confirm the issue capital call
   * @param dataDialog data to display in dialog.
   * @returns
   * @private
   */
  public openConfirmIssueDialog(dataDialog: CapitalCallConfirmDialogParams): Observable<IssueCapitalCallRequestDto> {
    const config = new MatDialogConfig<CapitalCallConfirmDialogParams>();
    config.disableClose = true;
    config.autoFocus = false;
    config.data = dataDialog;
    return this.matDialog.open(CapitalCallConfirmDialogComponent, config)
      .afterClosed()
      .pipe(
        filter(proceed => !!proceed),
        take(1)
      );
  }

  /**
   * Check if the investments to issue are valid.
   * @param investments investments to valid.
   * @returns
   * @private
   */
  private investmentsToIssueIsValid(investments: InvestmentReqRes[]): boolean {
    if (investments.length <= 0) {
      return false;
    }

    const isValid = investments.every(inv => inv.status === InvestmentStatus.Signed && inv.estimatedAmount > 0);
    if (!isValid) {
      const title = `Information Update Required`;
      const description = `
      <p>Some of the selected investors information requires an update, please make sure that:</p>
      <ol>
        <li style="list-style: inherit">Called amount is greater than zero.</li>
      </ol>
      `;

      this.invalidIssueDialog(description, title);
    }
    return isValid;
  }

  /**
   * Open alert dialog that are invalid investments.
   * @private
   */
  private invalidIssueDialog(message: string, title: string = 'Note') {
    const dialogParams = new AlertDialogParams();
    dialogParams.title = title;
    dialogParams.description = message;
    dialogParams.actionLabel = `OK`;
    this.dialogService
      .alertDialog(dialogParams)
      .afterClosed()
      .subscribe(() => {
      });
  }

  /**
   *
   * @param allInvestment All investments that are allowed for the step (setup or track).
   * @param selectedInvestmentsIds Investments that are selected to issue.
   */
  private getInvestmentsToIssue(allInvestment: InvestmentReqRes[], selectedInvestmentsIds: number[]): InvestmentReqRes[]{
    return allInvestment.filter(inv => selectedInvestmentsIds.some(id => id === inv.id));
  }

  /**
   * Check if fundraising has a bank account else open the alert dialog.
   * @returns if fundraising has bank account.
   * @private
   */
  private fundraisingHasBankAccount(fundraising: FundraisingReqRes): boolean {
    if (!fundraising.clientBankAccountId && !fundraising.unitBankAccountId) {
      this.invalidIssueDialog('No bank account for fundraising.');
      return false;
    }
    return true;
  }

  /**
   * Issue capital call
   * @param selectedInvestmentsIds Selected investments for issue
   */
  public issueCapitalCall(selectedInvestmentsIds: number[]) {
    return this.sendCapitalCall(selectedInvestmentsIds);
  }

  /**
   * Get issue or re-issue confirmation dialog data
   * @param investments Investments to issue capital call
   * @param holding holding
   * @param fundraising fundraising
   */
  private getConfirmationDialogParam(investments: InvestmentReqRes[], holding: GpHolding, fundraising: FundraisingReqRes): CapitalCallConfirmDialogParams {
    const title = 'Issue Capital Call';
    const body = `Do you want to Issue Capital Call for ${investments.length} ${investments.length > 1 ? 'Investors' : 'Investor'}?`;

    return new CapitalCallConfirmDialogParams(title, 'campaign', body, holding, fundraising, investments);
  }

  /**
   * Validate and send capital call
   * @param investmentsIds Selected investments ids to send capital calls to
   */
  private sendCapitalCall(investmentsIds: number[]): Observable<FundraisingReqRes> {
    let investmentsToIssue: InvestmentReqRes[];
    let issueCCReq: IssueCapitalCallRequestDto;
    return this.issuableInvestments$.pipe(
      take(1),
      filter(investment => !!investment && investment.length > 0 && !!investmentsIds && investmentsIds.length > 0),
      tap(investments => investmentsToIssue = this.getInvestmentsToIssue(investments, investmentsIds)),
      switchMapTo(this.fundraising$),
      take(1),
      filter(fundraising => this.investmentsToIssueIsValid(investmentsToIssue) && this.fundraisingHasBankAccount(fundraising)),
      withLatestFrom(this.holding$),
      take(1),
      filter(([_, holding]) => {
        if (holding.isPrivate) {
          this.holdingService.holdingDownInfo();
        }

        return !holding.isPrivate;
      })
    ).pipe(
      switchMap(([fundraising, holding]) => {
        const confirmationDialog = this.getConfirmationDialogParam(investmentsToIssue, holding, fundraising);
        return this.openConfirmIssueDialog(confirmationDialog);
      }),
      switchMap((issueReq: IssueCapitalCallRequestDto) => {
        issueCCReq = { ...issueReq };
        return this.fundraising$;
      }),
      take(1),
      switchMap(fundraising => {
        this.snackbarService.showGeneralMessage('Processing capital call');
        this.manualGeneratingPaymentRequests$.next(true);
        issueCCReq.investmentsIds = this.getInvestmentsIds(investmentsToIssue);
        return this.fundraisingDataService.issueCapitalCall(fundraising.holdingId, fundraising.id, issueCCReq);
      }),
      tap(fundraising => {
        this.manualGeneratingPaymentRequests$.next(false);
        this.holdingFundraisingService.changeFundraising(fundraising)
      })
    );
  }

  /**
   * Get the name of investor formatted for display
   * @param investment Investment details
   */
  public getFormattedInvestorName(investment: InvestmentReqRes): Observable<string> {
    return this.holdingFundraisingService.getFormattedInvestorName(investment);
  }
}
