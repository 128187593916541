import {Component, OnInit, Inject, ChangeDetectionStrategy, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {filter, map, shareReplay, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

import {DistributionTransferReqRes} from '../DistributionTransferReqRes.model';
import {CurrencyModel} from '../../../../../shared/models/CurrencyModel';
import {GpHoldingService} from '../../gp-holding.service';
import {UserService} from '../../../../../services/shared/user.service';
import DistributionTransactionPurpose from '../../../../../shared/enums/DistributionTransactionPurpose.enum';
import HoldingDiscriminator from '../../../../../shared/enums/HoldingDiscriminator.enum';
import {OrderExchangeRatesResponse} from 'src/app/shared/models/OrderExchangeRatesResponse.model';
import {GpDistributionDataService} from 'src/app/services/gp/gp-distribution-data.service';
import {DistributionService} from '../distribution.service';
import {PhoneVerificationDialogContext} from '../../../../../shared/components/phone-verification-dialog/PhoneVerificationDialogContext';
import {PhoneVerificationDialogComponent} from '../../../../../shared/components/phone-verification-dialog/phone-verification-dialog.component';
import {DistributionReqRes} from '../DistributionReqRes.model';
import TransactionType from '../../../../../shared/enums/TransactionType.enum';
import {DialogService} from '../../../../../services/dialog.service';
import {ConfirmDialogParams} from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
 

interface ExampleData {
  transfer: DistributionTransferReqRes;
  pricing: OrderExchangeRatesResponse;
}

export interface ISendPaymentConfirmationContext {
  transfers: DistributionTransferReqRes[];
  holdingName: string;
  distribution: DistributionReqRes;
  personalMessage: string;
  distributionSourceCurrency: CurrencyModel;
  totalAmountForDistribution: number;
  holdingService: GpHoldingService;
}

@Component({
  selector: 'terra-send-payment-confirmation',
  templateUrl: './send-payment-confirmation.component.html',
  styleUrls: ['./send-payment-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendPaymentConfirmationComponent implements OnInit {

  // consts

  DistributionType = DistributionTransactionPurpose;
  HoldingType = HoldingDiscriminator;
  TransactionType = TransactionType;

  holdingDetails$ = this.context.holdingService.holding$;
  gpDetails$ = this.userService.accountDetails$;
  isMultiTypeDistribution = this.distributionService.isMultiTypeDistribution(this.context.transfers);

  pageForm = this.fb.group({
    personalMessage: this.context.personalMessage || '',
    sendEmail: true
  });

  personalMessage$ = this.pageForm.get('personalMessage').valueChanges;
  requiredWaterfallReCalculation$ = this.distributionService.requiredWaterfallReCalculation$.pipe(shareReplay(1));

  private get lastIndex() {
    return this.context.transfers.length - 1;
  }

  get isMultipleTransfer() {
    return this.context.transfers.length > 1;
  }

  private _exampleIndex$ = new BehaviorSubject(0);
  exampleIndex$ = this._exampleIndex$.pipe(shareReplay(1));

  private _isLoadingExample$ = new BehaviorSubject(true);
  isLoadingExample$ = this._isLoadingExample$.pipe(shareReplay(1));

  private _isLoadingWaterfallRecalculationRequirement$ = new BehaviorSubject(true);
  isLoadingWaterfallRecalculationRequirement$ = this._isLoadingWaterfallRecalculationRequirement$.pipe(shareReplay(1));

  private currentTransfer$ = this.exampleIndex$.pipe(
    map(index => {
      return this.context.transfers[index];
    }),
    shareReplay(1)
  );

  private pricingsListPerTransfer = new Map<number, OrderExchangeRatesResponse>();

  private pricing$: Observable<OrderExchangeRatesResponse> =
    combineLatest([this.context.holdingService.holding$, this.currentTransfer$])
      .pipe(
        tap(_ => this._isLoadingExample$.next(true)),
        switchMap(([holding, transfer]) => {
          return !transfer || transfer.externalPayment ? of(null) : this.getPricing(transfer.id, holding.id);
        }),
        tap(_ => this._isLoadingExample$.next(false)),
        shareReplay(1));

  currentExample$: Observable<ExampleData> = combineLatest([this.currentTransfer$, this.pricing$]).pipe(
    map(([transfer, pricing]) => ({transfer, pricing} as ExampleData)),
    shareReplay(1)
  );

  get calculatedMarkup() {
    return this.currentExample$.pipe(
      take(1),
      map(example => example.pricing.markupPercent ? ((example.pricing.amount - example.pricing.transferFee) / 100 * example.pricing.markupPercent) : 0),
      shareReplay(1)
    );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public context: ISendPaymentConfirmationContext,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private dialogRef: MatDialogRef<SendPaymentConfirmationComponent>,
    private gpDistributionDataService: GpDistributionDataService,
    private distributionService: DistributionService,
    private dialog: MatDialog,
    private vcr: ViewContainerRef,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    // used to fire the HTTP request before the user clicks on finalize, so when they click finalize they won't have to wait for the response
    this.requiredWaterfallReCalculation$.pipe(
      take(1),
      tap(_ => this._isLoadingWaterfallRecalculationRequirement$.next(false))
    ).subscribe();
  }

  previousExample() {
    this.exampleIndex$.pipe(take(1))
      .subscribe(currentIdx => {
        this._exampleIndex$.next(currentIdx === 0 ? this.lastIndex : (currentIdx - 1));
      });
  }

  nextExample() {
    this.exampleIndex$.pipe(take(1))
      .subscribe(currentIdx => {
        this._exampleIndex$.next(currentIdx === this.lastIndex ? 0 : (currentIdx + 1));
      });
  }

  submit(sendEmail: boolean) {
    const isPhoneVerified$ = this.isPhoneValidationRequired() ? this.verifyPhone() : of(true);
    this.requiredWaterfallReCalculation$.pipe(
      take(1),
      withLatestFrom(this.holdingDetails$),
      switchMap(([requiredWaterfallReCalculation, holdingDetails]) => {
        if (requiredWaterfallReCalculation) {
          const confirmDialogParams = new ConfirmDialogParams('Waterfall Recalculation Required',
            `We detected that some of the financial data related to this ${holdingDetails.discriminator === HoldingDiscriminator.Fund ? 'fund' : 'asset'} and investors has been changed. We highly recommend recalculating the waterfall data before proceeding.`,
            'Recalculate Waterfall', 'Cancel');
          confirmDialogParams.showCloseIcon = false;
          confirmDialogParams.disableClose = true;
          return this.dialogService.confirmDialog(confirmDialogParams).afterClosed().pipe(
            map((reCalculate: boolean) => [reCalculate, requiredWaterfallReCalculation])
          );
        }

        return of([false, requiredWaterfallReCalculation]);
      }),
      filter(([reCalculate, requiredWaterfallReCalculation]) => {
        if (reCalculate) {
          this.router.navigate(['./edit'], { relativeTo: this.route, queryParams: {waterfallAmount: this.context.totalAmountForDistribution} });
          return false;
        }

        return !requiredWaterfallReCalculation;
      }),
      switchMap(_ => isPhoneVerified$),
      take(1)
    ).subscribe(isPhoneVerified => {
      if (!isPhoneVerified) {
        return false;
      }
      this.pageForm.get('sendEmail').setValue(sendEmail);
      this.dialogRef.close({
        ...this.pageForm.value, 
        personalMessage: this.pageForm.value.personalMessage || null
      });
    });
  }

  private getPricing(transferId: number, holdingId: number): Observable<OrderExchangeRatesResponse> {
    if (this.pricingsListPerTransfer.has(transferId)) {
      return of(this.pricingsListPerTransfer.get(transferId));
    }
    return this.gpDistributionDataService.getPricingForTransfer(
      holdingId,
      this.context.distribution.id,
      transferId
    ).pipe(
      tap(pricing => {
        this.pricingsListPerTransfer.set(transferId, pricing);
      }));
  }

  /**
   * Whether this distribution need to be approved before being finalized
   */
  isPhoneValidationRequired(): boolean {
    return !!this.context.distribution.unitBankAccountId && !this.context.distribution.phoneVerification?.isPhoneVerified;
  }

  /**
   * Opens phone verification modal
   */
  verifyPhone(): Observable<boolean> {

    const dialogConfig = new MatDialogConfig<PhoneVerificationDialogContext>();
    const phoneObject = this.context.distribution.unitBankAccount.unitApplication.phone;
    dialogConfig.data = new PhoneVerificationDialogContext();
    dialogConfig.data.phone = `${phoneObject.countryCode}${phoneObject.number}`;
    dialogConfig.data.isUserAbleToEditPhoneNumber = false;
    dialogConfig.viewContainerRef = this.vcr;

    return this.distributionService.SetPhoneVerification().pipe(
      take(1),
      switchMap(phoneObject => {
        dialogConfig.data.phone = phoneObject.phone
        return this.dialog.open(PhoneVerificationDialogComponent, dialogConfig).afterClosed()
      }),
      tap(isVerified => {
        this.context.distribution.phoneVerification.isPhoneVerified = isVerified;
      })
    );
  }
}
