import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap, shareReplay, take} from 'rxjs/operators';

import {FundFundraisingService} from '../../../fund-fundraising.service';
import {FundraisingStatus} from 'src/app/shared/enums/FundraisingStatus.enum';
import {GpFundService} from '../../../gp-fund.service';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import {ResourceService} from 'src/app/services/resource.service';
import {FundraisingReqRes} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {RoutingService} from 'src/app/services/routing.service';
import FeatureFlags from '../../../../../../account/my-account/model/FeatureFlags.enum';
import {UserService} from '../../../../../../services/shared/user.service';
import {PermissionService} from '../../../../../../permission/permission.service';

@Component({
  selector: 'terra-gp-fund-fundraising',
  templateUrl: './gp-fund-fundraising.component.html',
  styleUrls: ['./gp-fund-fundraising.component.scss']
})
export class GpFundFundraisingComponent implements OnInit {

  isAddInvestmentsPanelOpened$ = new BehaviorSubject<boolean>(false);
  fundraisingDetails$ = this.fundFundraisingService.fundraising$.pipe(tap(fundraising => {
    if (fundraising && (!fundraising.investments || !fundraising.investments.length)) {
      this.isAddInvestmentsPanelOpened$.next(true);
    }
  }));

  fund$ = this.gpFundService.holding$;
  fundPage$ = this.fund$.pipe(map(fund => this.routingService.gpFundWithTab(fund.id, 'contributions')), shareReplay(1));
  investments$ = this.fundraisingDetails$.pipe(map(fundraisingDetails => fundraisingDetails.investments));
  fundraisingTargetAmount$ = this.fundraisingDetails$.pipe(map(fundraisingDetails => fundraisingDetails.fundraisingTargetAmount));

  isContributionInReadOnlyMode$ = this.fundFundraisingService.isContributionInReadOnlyMode$;

  isCompletedExternalContribution$ = this.gpFundService.isCompletedExternalContribution$;
  isCompletedContribution$ = this.gpFundService.isCompletedContribution$;


  // Enums:
  FundraisingStatus = FundraisingStatus;
  HoldingType = HoldingType;
  InvestmentStatus = InvestmentStatus;
  userHasCreBanking$ = this.userService.userHasFeatureFlag(FeatureFlags.CreBanking);

  showBankInfo$ = this.permissionService.showBankInfo$;

  constructor(
    public fundFundraisingService: FundFundraisingService,
    private gpFundService: GpFundService,
    private resourceService: ResourceService,
    private userService: UserService,
    private routingService: RoutingService,
    private permissionService: PermissionService
  ) {
  }

  ngOnInit() {
  }

  get bankAccountName$(): Observable<string> {
    return this.fundraisingDetails$.pipe(
      take(1),
      map(fundraisingDetails => this.fundFundraisingService.getHoldingBankAccountDisplayName(fundraisingDetails)),
      shareReplay(1)
    );
  }

  showMoveToCompleted() {
    this.fundFundraisingService.showMoveFundraisingToComplete();
  }

  editFundraising() {
    this.fundFundraisingService.showEditFundraising();
  }

  marketingDeck(fundraisingDetails: FundraisingReqRes) {
    if (!fundraisingDetails.offeringDeck) {
      return null;
    }
    return fundraisingDetails.offeringDeck;
  }

  amountFunded(fundraisingDetails: FundraisingReqRes) {
    return this.fundFundraisingService.amountFunded(fundraisingDetails);
  }

  amountSignedOrFunded(fundraisingDetails: FundraisingReqRes) {
    return this.fundFundraisingService.amountSignedOrInvested(fundraisingDetails);
  }

  getCountryById(countryId: number): CountryModel {
    return this.resourceService.getCountryById(countryId);
  }

  showFinishedFundraisingNotification(fundraisingDetails: FundraisingReqRes) {
    if (fundraisingDetails.status === FundraisingStatus.Completed) {
      return false;
    }
    // check that the funding amount has been reached and all investors are in status invested / declined
    const allowedStatuses = [InvestmentStatus.Invested, InvestmentStatus.Declined, InvestmentStatus.Potential];

    if (this.amountFunded(fundraisingDetails) >= fundraisingDetails.fundraisingTargetAmount) {
      const statusesOk = fundraisingDetails.investments.every(investment => !!allowedStatuses.find(s => s === investment.status));
      if (statusesOk) {
        return true;
      }
    }
    return false;
  }

  showFinishedFundraisingButActionsNeededNotification(fundraisingDetails: FundraisingReqRes) {
    if (fundraisingDetails.status === FundraisingStatus.Completed) {
      return false;
    }
    // check that the funding amount has been reached and there's at least 1 investor in "invested" status
    if (this.amountFunded(fundraisingDetails) >= fundraisingDetails.fundraisingTargetAmount) {
      const statusesOk = fundraisingDetails.investments.findIndex(investment => investment.status === InvestmentStatus.Invested) > -1;
      if (statusesOk) {
        return true;
      }
    }
    return false;
  }

  fundDownInfo() {
    this.gpFundService.holdingDownInfo();
  }

  getHoldingBankAccount(fundraising: FundraisingReqRes) {
    return this.fundFundraisingService.getHoldingBankAccount(fundraising);
  }

  fundraisingComplete(fundraising: FundraisingReqRes) {
    return this.fundFundraisingService.fundraisingComplete(fundraising);
  }

  hideCreCard(fundraisingDetails: FundraisingReqRes) {
    return this.userHasCreBanking$.pipe(
      map(hasCre => {
        return !hasCre || this.fundraisingComplete(fundraisingDetails);
      }));
  }
}
