import { Component, OnInit, Inject, ViewChild, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { debounceTime, shareReplay, map } from 'rxjs/operators';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { KeyValuePair } from 'src/app/shared/types/KeyValuePair.model';
import { ContactDataService } from 'src/app/services/gp/contact-data.service';
import { InvestingEntityTableService } from 'src/app/dashboard/contacts/components/investing-entities-display-table/investing-entity-table.service';
import { InvestingEntitiesDisplayTableComponent } from 'src/app/dashboard/contacts/components/investing-entities-display-table/investing-entities-display-table.component';
import { ContactReferrerDataService } from 'src/app/services/gp/contact-referrer-data.service';
import { PermissionService } from 'src/app/permission/permission.service';

@Component({
  selector: 'terra-find-contact-in-list-dialog',
  templateUrl: './find-contact-in-list-dialog.component.html',
  styleUrls: ['./find-contact-in-list-dialog.component.scss'],
  providers: [InvestingEntityTableService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindContactInListDialogComponent extends OnDestroyMixin implements OnInit, AfterViewInit {

  @ViewChild(InvestingEntitiesDisplayTableComponent) investingEntitiesTable: InvestingEntitiesDisplayTableComponent;

  pageForm: UntypedFormGroup;

  allowInvestorName$ = this.permissionService.allowInvestorName$;

  contactsToAdd$ = new BehaviorSubject<number[]>([]);

  private searchOptions$ = this.investingEntityTableService.searchOptions$;

  private contactTagsResponse$ = this.contactDataService.getContactTags().pipe(shareReplay(1));

  allTags$: Observable<KeyValuePair<number, string>[]> = this.contactTagsResponse$.pipe(map(response => response.tags), shareReplay(1));

  private selectedTagIds$ = this.searchOptions$.pipe(map(s => s.tags));
  selectedTags$ = combineLatest([this.allTags$, this.selectedTagIds$]).pipe(
    map(([allTags, selectedTagIds]) => {
      return selectedTagIds.map(id => allTags.find(t => t.key === id));
    }),
    shareReplay(1)
  );

  allContactReferrers$ = this.contactReferrerDataService.getAll().pipe(
    shareReplay(1)
  );

  constructor(
    private investingEntityTableService: InvestingEntityTableService,
    private contactDataService: ContactDataService,
    private contactReferrerDataService: ContactReferrerDataService,
    private fb: UntypedFormBuilder,
    private permissionService: PermissionService,
    public dialogRef: MatDialogRef<FindContactInListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public fundraisingId: number
  ) {
    super();
    this.investingEntityTableService.updateFundraisingId(this.fundraisingId);
   }

  ngOnInit() {
    this.investingEntityTableService.updateFundraisingId(this.fundraisingId);

    this.pageForm = this.fb.group({
      term: [],
      multiselectTags: [],
      contactReferrerId: []
    });

    this.pageForm
      .get('term')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(300))
      .subscribe(term => {
        this.investingEntityTableService.updateFilter(term);
      });

    this.pageForm
      .get('multiselectTags')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(300))
      .subscribe(tags => {
        this.investingEntityTableService.updateTagsFilter(tags);
      });

    this.pageForm
      .get('contactReferrerId')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(200))
      .subscribe((contactReferrerId: number) => {
        this.investingEntityTableService.updateContactReferrerFilter(contactReferrerId);
      });
  }

  ngAfterViewInit(): void {
    this.investingEntitiesTable.selection.changed.pipe(
      untilComponentDestroyed(this),
      map(() => {
        this.contactsToAdd$.next(this.getInvestingEntitiesToAdd());
      }))
      .subscribe();
  }

  passIds() {
    const investingEntityIdsToCreate = this.getInvestingEntitiesToAdd();
    this.dialogRef.close(investingEntityIdsToCreate);
  }

  private getInvestingEntitiesToAdd() {
    let investingEntityIdsToCreate = new Array<number>();
    if (this.investingEntitiesTable && this.investingEntitiesTable.selectedInvestingEntities) {
      investingEntityIdsToCreate = this.investingEntitiesTable.selectedInvestingEntities.map(c => c.id);
    }
    return investingEntityIdsToCreate ? investingEntityIdsToCreate : [];
  }
}
