import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import memo from 'memo-decorator';

import {TelemetryService} from 'telemetry-library';
import {GpHoldingService} from '../../gp-holding.service';
import {DialogService} from '../../../../../services/dialog.service';
import {UserService} from '../../../../../services/shared/user.service';
import {ContributionDetailsItemReqRes} from './contribution-details/ContributionDetailsItemReqRes';
import {AlertDialogParams} from '../../../../../shared/components/alert-dialog/alert-dialog.component';
import {ContributionsImportResponse} from '../../../../../shared/models/ContributionsImportResponse.model';
import {ImportContributionsDialogComponent} from './import-contributions-dialog/import-contributions-dialog.component';

@Component({
  selector: 'terra-import-contributions',
  templateUrl: './import-contributions.component.html',
  styleUrls: ['./import-contributions.component.scss']
})
export class ImportContributionsComponent extends OnDestroyMixin implements OnInit {
  holding$ = this.gpHoldingService.holding$;
  holdingId$ = this.gpHoldingService.holdingId$;
  contributionsRoute: string;
  isLoading$ = new BehaviorSubject(true);
  discriminatorStr = this.gpHoldingService.discriminatorStr;
  contributionsDetails = new Array<ContributionDetailsItemReqRes>();

  constructor(
    private gpHoldingService: GpHoldingService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.addContribution();
    this.holding$.subscribe(holding => {
      this.setHoldingPageUrl(holding.id)
      this.isLoading$.next(false);
    });
  }

  @memo()
  holdingDownInfo() {
    this.gpHoldingService.holdingDownInfo();
  }

  setHoldingPageUrl(holdingId: number) {
    this.contributionsRoute = this.gpHoldingService.getHoldingPageWithTabRoute(holdingId, 'contributions');
  }

  removeDetail(index: number) {
    this.contributionsDetails.splice(index, 1);
  }

  addContribution() {
    const contribution = new ContributionDetailsItemReqRes();
    this.contributionsDetails.push(contribution);
  }

  importContribution() {
    if (this.contributionsDetails.some(detail => !detail.isFormValid)) {
      return this.dialogService.alertDialog(new AlertDialogParams('Covercy', `Please fill all the required fields marked in red`));
    }

    this.showImportDialog();
  }

  private showImportDialog() {
    this.holdingId$.pipe(
      untilComponentDestroyed(this),
      take(1),
      switchMap(
        holdingId => {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            holdingId,
            contributions: this.contributionsDetails
          };
          return this.dialog.open(ImportContributionsDialogComponent, dialogConfig)
            .afterClosed();
        })
    ).subscribe((res: ContributionsImportResponse) => {
      if (res && res.isSuccess) {
        this.router.navigateByUrl(this.contributionsRoute);
      }
    });
  }

  // TODO
  private importContributionClickedTelemetryEvent() {
    // this.userService.accountDetails$.pipe(take(1)).subscribe(clientDetails => {
    //   this.holdingId$.pipe(take(1)).subscribe(holdingId => {
    //     this.telemetryService.create({
    //       eventID: '???',
    //       eventTitle: 'CONTRIBUTION - IMPORT (CLICKED)',
    //       holdingID: holdingId,
    //       organizationID: clientDetails.organizationDetails.id
    //     });
    //   });
    // });
  }
}
