import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { map, pluck } from 'rxjs/operators';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';

import { HoldingFundraisingService } from '../../holding-fundraising.service';
import PaymentStatus from '../../../../../../shared/enums/PaymentStatus.enum';


interface FundraisingInvestorsStatisticsChartData {
  invested: number;
  transfers: number;
  viewedBy: number;
  called: number;
  signed: number;
  softCircle: number;
  potential: number;
  declined: number;
}


@Component({
  selector: 'terra-fundraising-investors-statistics-chart',
  templateUrl: './fundraising-investors-statistics-chart.component.html',
  styleUrls: ['./fundraising-investors-statistics-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundraisingInvestorsStatisticsChartComponent implements OnInit {

  @ViewChild('chart') chart: ChartComponent;


  readonly category = ['Invested', 'Transferred', 'Viewed By', 'Called', 'Signed', 'Soft Circled', 'Potential', 'Declined'];
  readonly colors = ['#3D41EB', '#4896FF', '#29B1F5', '#6CDBF5', '#03EEF5',  '#98BAE7', '#18364A', '#FF334C'];

  chartSeries$;
  investments$ = this.holdingFundraisingService.fundraising$.pipe(
    pluck('investments')
  );

  chartOptions: ChartComponent;

  constructor(
    private holdingFundraisingService: HoldingFundraisingService
  ) {
    this.setChartData();
    this.setChartOptions();
   }

  ngOnInit(): void {
  }

  private setChartData(){
    this.chartSeries$ = this.holdingFundraisingService.fundraising$.pipe(
      pluck('investments'),
      map(investments => ({
        invested: investments.filter(inv => inv.status === InvestmentStatus.Invested).length,
        transfers: investments.filter(inv => inv.status === InvestmentStatus.Signed && !!inv.paymentRequestSendDate &&
                                             !!inv.transferDate &&
                                             inv.paymentStatus !== PaymentStatus.Canceled).length,
        viewedBy: investments.filter(inv => inv.status === InvestmentStatus.Signed && !!inv.paymentRequestSendDate &&
                                            !!inv.capitalCallViewDate && !inv.transferDate &&
                                            inv.paymentStatus !== PaymentStatus.Canceled).length,
        called: investments.filter(inv => inv.status === InvestmentStatus.Signed && !!inv.paymentRequestSendDate &&
                                          !inv.transferDate).length,
        signed: investments.filter(inv => inv.status === InvestmentStatus.Signed && !inv.paymentRequestSendDate).length,
        softCircle: investments.filter(inv => inv.status === InvestmentStatus.SoftCircled).length,
        potential: investments.filter(inv => inv.status === InvestmentStatus.Potential).length,
        declined: investments.filter(inv => inv.status === InvestmentStatus.Declined).length
      } as FundraisingInvestorsStatisticsChartData)),
      map(obj => ([{ data: Object.values(obj) }]))
    );
  }

  private setChartOptions(){
    this.chartOptions = {
      chart: {
        type: 'bar',
        height: '300px',
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        }
      },
      colors: this.colors,
      plotOptions: {
        bar: {
          columnWidth: '100%',
          distributed: true
        }
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          title: {
            formatter: (seriesName: string): string => {
              return '';
            }
          },
          formatter: (val: number, opts?: any): string => {
            return `${this.category[opts.dataPointIndex]}: ${val}`;
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false
      },
      legend: {
        position: 'right',
        horizontalAlign: 'center',
        fontSize: '16px',
        fontWeight: 500,
        formatter: function(label, opts) {
          return `${label} - ${opts.w.globals.series[0][opts.seriesIndex]}`;
        },
        itemMargin: {
          vertical: 5
        }
      },
      xaxis: {
        categories: this.category,
        labels: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: '#78909C',
        }
      },
      yaxis: {
        labels: {
          formatter: (value, opts): string => value % 2 === 0 ? `${Math.round(value)}` : ''
        },
        max: (max: number) => Math.ceil(++max / 10) * 10,
        axisBorder: {
          show: true,
          color: '#78909C',
        }
      },
      states: {
        hover: {
         filter: {
            type: 'darken',
            value: 0.55,
          }
        }
      }
    } as ChartComponent;
  }

}
