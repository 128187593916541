import { animate, sequence, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output, signal, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { catchError, combineLatest, debounceTime, delay, distinctUntilChanged, finalize, forkJoin, map, of, take, tap } from 'rxjs';
import { MetaFileLink } from 'src/app/models/metaFileLink.model';
import { FormlyStanaloneModule } from './form-fields/form.module';
import { AnimatedElementsComponent } from './loader.component';
import { RentManagerService } from './rm-uploader.service';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';
import { UserService } from 'src/app/services/shared/user.service';
import { GpAssetService } from 'src/app/dashboard/assets/gp-asset/gp-asset.service';

@Component({
  selector: 'terra-rent-manager-uploader',
  templateUrl: './rm-uploader.component.html',
  styleUrls: ['./rm-style.scss', './rm-uploader.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    MatIconButton,
    MatIconModule,
    AnimatedElementsComponent,
    MatProgressSpinnerModule,
    // Form Modules 
    ReactiveFormsModule,
    FormlyStanaloneModule,
  ],
  animations: [
    trigger('dataLoaded', [
      transition(':enter', [
          style({ transform: 'translateY(180px)', opacity: 0 }),
          sequence([
            animate('250ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
          ])
      ]),
  ])
]
})
export class RentManagerUploaderComponent  implements OnInit{
  private cdr = inject(ChangeDetectorRef);
  private dateAdapter = inject(DateAdapter);
  private userService = inject(UserService);
  private service = inject(RentManagerService); 
  private gpAssetService = inject(GpAssetService); 
  private telemetryService = inject(TelemetryService);


  @ViewChild('animatedElements') animatedElements: AnimatedElementsComponent | undefined;

  @Input() dateRange: {start: Date, end: Date} | undefined;

  @Input() openedChange?: (opened: boolean) => void;
  @Output() output = new EventEmitter<any>();

  currentMonth = this.dateAdapter.getMonth(this.dateAdapter.today());
  currentQuarter = Math.floor(this.currentMonth / 3) + 1;

  

  ngOnInit(): void {
    this.openedChange = (opened: boolean) => {
        opened ? this.startEnterAnimation() : this.startLeaveAnimation();
    }

    this.model = this.checkStorage() ?? { daterange_type: this.dateRange}; // OR set date from Report dateRange

    this.form.valueChanges
    .pipe(
      distinctUntilChanged(),
      debounceTime(100)
    )
    .subscribe((config) => {
      localStorage.setItem('RM_CONFIG', JSON.stringify(config));

    });
  }

  protected dataLoaded = false;

  reporstRequested = signal(false);

  // form shape
  form = new FormGroup({});
  model: any = {}; // init Model Data
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = []

  // test Filter function
  filterStates(name) {
    return [].filter((state) => state.toLowerCase().indexOf(name?.toLowerCase()) === 0);
  }

  startEnterAnimation() {
    this.animatedElements?.enter();
  }

  startLeaveAnimation() {
    // reset Form Settings?
  }

  reset() {
    this.dataLoaded = false;
    this.animatedElements?.leave();
  }

  onAnimationComplete() {
    const delay$ = of(null).pipe(delay(4000)); // simulate data loading
    if(!this.dataLoaded) {
      forkJoin([this.service.requestRentManagerData(), delay$]).pipe(
        map(([data]) => {
          this.fields = this.service.getFormConfig(data);
          this.dataLoaded = true;
          this.cdr.markForCheck();
        }),
        catchError((error) => {
          this.dataLoaded = true;
          this.cdr.markForCheck();
          this.output.emit({ error });
          return of(error);
        }),
      ).subscribe();

    }
  }

  generateReport() {
    if(this.reporstRequested()) {
      return;
    }
    this.reporstRequested.set(true);
    this.service.requestRentManagerReport(this.model).pipe(
      finalize(() => {
        this.reporstRequested.set(false);
      }),
      tap((data: MetaFileLink[]) => {
        this.output.emit({ filesReady: data });
      })
    ).subscribe();
    combineLatest([this.userService.accountDetails$, this.gpAssetService.holding$])
    .pipe(take(1)).subscribe(([clientDetails, asset]) => {
    this.telemetryService.create({
      eventID: '407',
      eventTitle: 'GENERATE RM REPORT',
      holdingID: asset.id,
      organizationID: clientDetails.organizationDetails.id,
    }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
  });
  }

  close() {
    this.output.emit({ closed: true });
  }

  private checkStorage(): FormGroup | undefined {
    const storage = localStorage.getItem('RM_CONFIG');
    if(!storage) return undefined
    try {
      return JSON.parse(storage);
     
    } catch (error) {
      return undefined;
    }
  }

}
