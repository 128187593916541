import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';

import { GpFundService } from '../gp-fund/gp-fund.service';
import { FundFundraisingService } from '../gp-fund/fund-fundraising.service';
import { FundPollingService } from '../gp-fund/fund-polling.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { GpHoldingService } from '../../shared/holding/gp-holding.service';
import { HoldingFundraisingService } from '../../shared/holding/fundraising/holding-fundraising.service';
import { CapitalCallService } from '../../shared/holding/capital-call/capital-call.service';
import { catchError, tap } from 'rxjs';

@Component({
  selector: 'terra-gp-fund-layout',
  templateUrl: './gp-fund-layout.component.html',
  styleUrls: ['./gp-fund-layout.component.scss'],
  providers: [
    GpFundService,
    { provide: GpHoldingService, useExisting: GpFundService },
    FundFundraisingService,
    { provide: HoldingFundraisingService, useExisting: FundFundraisingService },
    FundPollingService,
    CapitalCallService,
  ],
})
export class GpFundLayoutComponent extends OnDestroyMixin implements OnInit {
  fundId$ = this.gpFundService.holdingId$;

  constructor(
    private fundPollingService: FundPollingService,
    private titleService: Title,
    private gpFundService: GpFundService,
    private vcr: ViewContainerRef
  ) {
    super();

    gpFundService.vcr = vcr;
    gpFundService.fundPollingService = fundPollingService;

    this.fundPollingService
      .startPoll()
      .pipe(
        untilComponentDestroyed(this),
        tap((res) => {
          this.titleService.setTitle(
            `${res ? res.fund.name : ''}${TerraUtils.consts.GpPageTitleSuffix}`
          );
        }),
        catchError((err) => {
          return err;
        })
      )
      .subscribe();
  }

  ngOnInit() {}
}
