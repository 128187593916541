import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, take, switchMap, map } from 'rxjs/operators';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { TerraNumberPipe } from 'src/app/shared/pipes/TerraNumber.pipe';
import { RoutingService } from 'src/app/services/routing.service';
import { GpAssetReqRes } from '../../../GpAssetReqRes.model';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import { LoggerService } from 'src/app/shared/errors/logger.service';
import { AssetAndFundraisingReqRes } from '../../../components/create-asset/AssetAndFundraisingRequest';
import { GpAssetService } from '../../gp-asset.service';
import { FundraisingReqRes } from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import { ClientBankAccountResponseBasic } from 'src/app/dashboard/models/bankAccount.model';
import { ProjectedPerformance } from 'src/app/shared/models/ProjectedPerformance.model';
import { ProjectBudget } from 'src/app/shared/models/ProjectBudget.model';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';

export type StartInitialFundraisigStepNumber = 1 | 2;

@Injectable()
export class StartInitialFundraisingService  extends OnDestroyMixin {

  readonly IS_STEP_SUBMITTED_FIELD_NAME = 'IS_STEP_SUBMITTED';
  readonly wizardStepPaths = ['fundraising-information', 'investment-details'];

  assetId$ = this.gpAssetService.holdingId$;
  asset$ = this.gpAssetService.holding$;

  currentStep$ = new BehaviorSubject((this.getCurrentStepIndexFromUrl() + 1 || 1) as StartInitialFundraisigStepNumber);
  wizardForm: UntypedFormGroup;

  processingSubmit$ = new BehaviorSubject(false);
  isGeneralServerError = false;

  assetCreated = false;

  get selectedSecurityType(): InvestmentSecurityType {
    return this.getStepForm(1).get('securityType').value as InvestmentSecurityType;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private numberPipe: TerraNumberPipe,
    private routingService: RoutingService,
    private gpAssetService: GpAssetService,
    private logger: LoggerService,
    private utilsService: UtilsService
  ) {
    super();
    this.generateForm();
    this.populateForm();
    this.initStepsNavigationHandler();
  }

  initStepsNavigationHandler() {
    // Whenever the currentStep Changes, the user will be redirected
    this.currentStep$.pipe(distinctUntilChanged()).subscribe(
      step => {
        // Navigate to the first if the previous step was not submitted
        if (step > 1 && !this.isStepSubmitted(step - 1 as StartInitialFundraisigStepNumber)) {
          this.currentStep$.next(1);
        } else {
          this.assetId$.pipe(untilComponentDestroyed(this), take(1))
            .subscribe(assetId => {
              const wizardBaseUrl = this.routingService.createAssetInitialFundraising(assetId);
              const specificStepUrl = this.getStepPathByNumber(step);
              this.router.navigateByUrl(`${wizardBaseUrl}/${specificStepUrl}`);
            });
        }
      });
  }

  cancel() {
    this.assetId$.pipe(untilComponentDestroyed(this), take(1))
      .subscribe(assetId => {
        this.router.navigateByUrl(this.routingService.gpAssetPage(assetId));
      });
  }

  getStepForm(stepNumber: StartInitialFundraisigStepNumber) {
    const stepFormName = 'step' + stepNumber;
    if (stepFormName && this.wizardForm) {
      return this.wizardForm.get(stepFormName) as UntypedFormGroup;
    }
    return null;
  }

  getStepPathByNumber(step: StartInitialFundraisigStepNumber) {
    return this.wizardStepPaths[step - 1];
  }

  isStepSubmitted(stepNumber: StartInitialFundraisigStepNumber) {
    const step = this.getStepForm(stepNumber);
    return step.get(this.IS_STEP_SUBMITTED_FIELD_NAME).value as boolean;
  }

  setStepAsSubmitted(stepNumber: StartInitialFundraisigStepNumber, isSubmitted = true) {
    this.getStepForm(stepNumber).get(this.IS_STEP_SUBMITTED_FIELD_NAME).setValue(isSubmitted);
  }

  getCurrentStepIndexFromUrl() {
    const wizardStep = this.route.snapshot.children[0].url[0].path.toLowerCase();
    const index = this.wizardStepPaths.findIndex(x => x === wizardStep.toString());
    return index;
  }

  generateForm() {

    this.wizardForm = this.fb.group({
      step1: this.fb.group({
        name: [`Contribution #1`, [Validators.required, Validators.minLength(2)]],
        estimatedClosingDate: [null, [Validators.required]],
        minimumInvestmentAmount: null,
        securityType: [null, Validators.required],
        fundraisingTargetAmount: [null, Validators.compose([Validators.required, Validators.min(1)])],
        marketingDeckDocument: null,
        bankAccount: null
      }),
      step2: this.fb.group({
        projectedPerformance: this.fb.group({
          cashOnCashRate: null,
          nonLeveragedCapRate: null,
          targetInvestmentIrr: null,
          targetInvestmentIrrMax: null,
          expectedRoi: null,
          targetEquityMultiplier: null,
          targetEquityMultiplierMax: null,
          targetInvestmentPeriod: null,
          preferredReturn: null
        }),
        projectBudgetOriginal: this.fb.group({
          acquisition: null,
          hardCost: null,
          softCost: null,
          financing: null,
          totalCosts: null,
          totalCostsPerGrossAreaUnit: null,
          totalCostsPerNetAreaUnit: null
        })
      })
    });
    for (let i = 1; i <= 2; i++) {
      this.getStepForm(i as StartInitialFundraisigStepNumber).addControl(this.IS_STEP_SUBMITTED_FIELD_NAME, this.fb.control(false));
    }
  }

  populateForm() {

    this.asset$.subscribe(asset => {
      this.getStepForm(2).patchValue({
        projectedPerformance: {
          cashOnCashRate: asset.projectedPerformance.cashOnCashRate,
          nonLeveragedCapRate: asset.projectedPerformance.nonLeveragedCapRate,
          targetInvestmentIrr: asset.projectedPerformance.targetInvestmentIrr,
          targetInvestmentIrrMax: asset.projectedPerformance.targetInvestmentIrrMax,
          expectedRoi: asset.projectedPerformance.expectedRoi,
          targetEquityMultiplier: asset.projectedPerformance.targetEquityMultiplier,
          targetEquityMultiplierMax: asset.projectedPerformance.targetEquityMultiplierMax,
          targetInvestmentPeriod: asset.projectedPerformance.targetInvestmentPeriod,
          preferredReturn: asset.projectedPerformance.preferredReturn
        },
        projectBudgetOriginal: {
          acquisition: asset.projectBudgetOriginal.acquisition,
          hardCost: asset.projectBudgetOriginal.hardCost,
          softCost: asset.projectBudgetOriginal.softCost,
          financing: asset.projectBudgetOriginal.financing,
          totalCosts: asset.projectBudgetOriginal.totalCosts,
          totalCostsPerGrossAreaUnit: asset.projectBudgetOriginal.totalCostsPerGrossAreaUnit,
          totalCostsPerNetAreaUnit: asset.projectBudgetOriginal.totalCostsPerNetAreaUnit
        }
      });
    });
  }

  generateSubmitModel(): Observable<AssetAndFundraisingReqRes> {
    return this.asset$.pipe(
      take(1),
      map(asset => {
        const fundraisingModel = new FundraisingReqRes();
        const assetModel = new GpAssetReqRes();

        const step1 = this.getStepForm(1).value;
        const step2 = this.getStepForm(2).value;

        // Step 1
        fundraisingModel.name = step1.name;
        fundraisingModel.holdingId = asset.id;
        fundraisingModel.estimatedClosingDate = step1.estimatedClosingDate;
        fundraisingModel.fundraisingTargetAmount = this.numberPipe.parse(step1.fundraisingTargetAmount);
        fundraisingModel.minimumInvestmentAmount = this.numberPipe.parse(step1.minimumInvestmentAmount);
        fundraisingModel.securityType = step1.securityType;
        // Offering deck if added
        if (step1.marketingDeckDocument) {
          fundraisingModel.offeringDeckId = (step1.marketingDeckDocument as MetaFileLink).id;
        }
        // Bank account if selected
        fundraisingModel.clientBankAccountId = step1.bankAccount ? (step1.bankAccount as ClientBankAccountResponseBasic).id : null;

        // Step 2 - projected performance
        assetModel.projectedPerformance = new ProjectedPerformance();
        assetModel.id = asset.id;
        const projectedPerfModel = assetModel.projectedPerformance;
        projectedPerfModel.cashOnCashRate = this.numberPipe.parse(step2.projectedPerformance.cashOnCashRate);
        projectedPerfModel.nonLeveragedCapRate = this.numberPipe.parse(step2.projectedPerformance.nonLeveragedCapRate);
        projectedPerfModel.targetInvestmentIrr = this.numberPipe.parse(step2.projectedPerformance.targetInvestmentIrr);
        projectedPerfModel.targetInvestmentIrrMax = this.numberPipe.parse(step2.projectedPerformance.targetInvestmentIrrMax);
        projectedPerfModel.expectedRoi = this.numberPipe.parse(step2.projectedPerformance.expectedRoi);
        projectedPerfModel.targetEquityMultiplier = this.numberPipe.parse(step2.projectedPerformance.targetEquityMultiplier);
        projectedPerfModel.targetEquityMultiplierMax = this.numberPipe.parse(step2.projectedPerformance.targetEquityMultiplierMax);
        projectedPerfModel.targetInvestmentPeriod = this.numberPipe.parse(step2.projectedPerformance.targetInvestmentPeriod);
        projectedPerfModel.preferredReturn = this.numberPipe.parse(step2.projectedPerformance.preferredReturn);

        // Step 2 - Original budget:
        assetModel.projectBudgetOriginal = new ProjectBudget();
        const budgetModel = assetModel.projectBudgetOriginal;
        const budgetFormValues = step2.projectBudgetOriginal;
        budgetModel.acquisition = this.numberPipe.parse(budgetFormValues.acquisition);
        budgetModel.hardCost = this.numberPipe.parse(budgetFormValues.hardCost);
        budgetModel.softCost = this.numberPipe.parse(budgetFormValues.softCost);
        budgetModel.financing = this.numberPipe.parse(budgetFormValues.financing);
        budgetModel.totalCosts = this.numberPipe.parse(budgetFormValues.totalCosts);
        budgetModel.totalCostsPerGrossAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerGrossAreaUnit);
        budgetModel.totalCostsPerNetAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerNetAreaUnit);

        return new AssetAndFundraisingReqRes(assetModel, fundraisingModel, false);
      })
    );
  }

  createFundraising() {
    this.isGeneralServerError = false;
    if (this.wizardForm.valid) {
      this.processingSubmit$.next(true);
      this.generateSubmitModel().pipe(
        untilComponentDestroyed(this),
        switchMap(model => this.gpAssetService.moveAssetToFundraising(model))
      ).subscribe(
        response => {
          this.processingSubmit$.next(false);
          this.assetCreated = true;
          this.router.navigateByUrl(this.routingService.gpAssetPage(response.asset.id));
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            this.isGeneralServerError = true;
          }
          this.processingSubmit$.next(false);
        }
      );
    }
  }
}
