import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {filter, switchMap, take, withLatestFrom} from 'rxjs/operators';

import {
  FindContactInListDialogComponent
} from 'src/app/dashboard/assets/gp-asset/asset-invite-investor/find-contact-in-list-dialog/find-contact-in-list-dialog.component';
import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import {GpInvestmentDataService} from 'src/app/services/gp/gp-investment-data.service';
import {RoutingService} from 'src/app/services/routing.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {GpHoldingService} from '../../gp-holding.service';
import {HoldingFundraisingService} from '../holding-fundraising.service';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';

@Component({
  selector: 'terra-add-investors-from-list-block',
  templateUrl: './add-investors-from-list-block.component.html',
  styleUrls: ['./add-investors-from-list-block.component.scss']
})
export class AddInvestorsToCompletedFundraisingComponent implements OnInit {

  @Input() title: string = '';
  @Input() description: string = '';

  constructor(
    private gpInvestmentDataService: GpInvestmentDataService,
    private fundraisingService: HoldingFundraisingService,
    public gpHoldingService: GpHoldingService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    public routingService: RoutingService,
    private utilsService: UtilsService) {
  }

  ngOnInit(): void {
  }

  findFromContactList() {
    const config = new MatDialogConfig<number>();
    config.panelClass = 'find-contact-in-list-dialog';
    config.disableClose = true;

    this.fundraisingService.fundraisingId$.pipe(
      take(1),
      switchMap(fundraisingId => {
        config.data = fundraisingId;
        return this.dialog.open(FindContactInListDialogComponent, config).afterClosed();
      }),
      filter((investingEntityIds: number[]) => coerceBooleanProperty(investingEntityIds) && investingEntityIds.length > 0),
      withLatestFrom(this.gpHoldingService.holdingId$, this.fundraisingService.fundraisingId$),
      switchMap(([investingEntityIds, holdingId, fundraisingId]) =>
        this.gpInvestmentDataService.createMultipleInvestmentsFromInvestingEntityIds(holdingId, fundraisingId, investingEntityIds))
    ).subscribe(addedInvestments => {
        this.onSuccessfulInvestmentsCreation(addedInvestments);
      },
      err => {
        if (err instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(err);
        }
        console.log('Investments were not added', err);
      }
    );
  }

  onSuccessfulInvestmentsCreation(addedInvestments: InvestmentReqRes[]) {
    if (addedInvestments) {
      this.investorsAddedMessage(addedInvestments.length);
      addedInvestments.forEach(investment => {
        this.fundraisingService.updatedInvestment$.next(investment);
      });
    }
  }

  private investorsAddedMessage(numberOfInvestors: number) {
    const addedMessage = numberOfInvestors === 1 ? 'Investor added' : `${numberOfInvestors} investors added`;
    this.snackbarService.showGeneralMessage(addedMessage, 3, 'flow-added-investors-success');
  }
}
