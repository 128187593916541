<!-- Loader -->
<ng-template #pageLoader>
  <terra-loader *ngIf="!isGeneralServerError" class="page-loader" />
  <p *ngIf="isGeneralServerError" class="general-error-message">{{ generalServerErrorMessage }}</p>
</ng-template>

<ng-container *ngIf="pageForm && distributionContext$ | async as distributionContext; else pageLoader">

  <!-- holding is down -->
  <div *ngIf="distributionContext.holding.isPrivate" class="holding-down-page-stripe">
    <mat-icon class="notranslate holding-down-icon" svgIcon="holding_down"></mat-icon>
    <span> {{discriminatorStr}} is down. When you’re ready to put it back up, go to the <a
      [routerLink]="holdingPageUrl(distributionContext.holding.id)"
      class="light link">
        {{discriminatorLowerCaseStr}} page</a> and change it.
      <a (click)="holdingDownInfo()" class="light link"> Learn more</a></span>
  </div>

  <!-- Breadcrumbs -->
  <div class="container-800">
    <div class="breadcrumbs">
      <a [routerLink]="holdingPageUrl(distributionContext.holding.id)">
        <span class="lo_sensitive">{{ distributionContext.holding.name }}</span> > Distributions > </a>
      <Span
        class="current">{{distributionContext.isEditMode ? distributionContext.distribution.name : 'New Distribution'}}</Span>
    </div>
  </div>


  <div class="container-800">
    <h1>{{distributionContext.isEditMode ? 'Edit Distribution' : 'Create a new distribution'}}</h1>
    @if(distributionContext.isEditMode){
      <div class="distribution-status">
        Status: {{ distributionContext.distribution.status | enumString : DistributionStatus }}
      </div>
    }
    <form (keydown.enter)="$event.preventDefault()" (ngSubmit)="save()" *ngIf="pageForm && !showInternalLoader"
          [formGroup]="pageForm"
          class="cov-form-grid">

      <!-- Distribution details -->
      <section class="form-section">
        <h3 class="section-title">Distribution details</h3>
        <!-- Report period -->
        <div class="terra-form-field-wrapper">
          <label class="text-input-label" for="ddlReportPeriod">Distribution period</label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="reportPeriod" id="ddlReportPeriod" placeholder="Select">
              <mat-option *ngFor="let period of ReportPeriod.listAll()"
                          [value]="period">{{ period | enumString: ReportPeriod }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Quarter and Year -->
        <div *ngIf="pageForm.get('reportPeriod').value === ReportPeriod.Quarterly"
             class="terra-form-field-wrapper quarter-and-year">
          <div class="quarter-field">
            <label class="text-input-label" for="ddlQuarter">Select quarter</label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="quarter" id="ddlQuarter" placeholder="Select">
                <mat-option *ngFor="let quarter of [1, 2, 3, 4]" [value]="quarter">Q{{ quarter }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="year-field">
            <label class="text-input-label" for="ddlYear">Select year</label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="year" id="ddlYear" placeholder="Select">
                <mat-option *ngFor="let year of yearsList" [value]="year">{{ year }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- Start and End dates of the period  -->
        <ng-container *ngIf="pageForm.get('reportPeriod').value === ReportPeriod.Custom">
          <!-- Start date -->
          <div class="terra-form-field-wrapper new-column">
            <terra-custom-utc-date-picker
            id="txtPeriodStartDate"
            formControlName="periodStartDate"
            label="First day included in distribution"
            [minDate]="periodMinDate"
            [maxDate]="periodMaxDate"
            [requiredError]="periodStartDate.hasError('required')"
            [touched]="periodStartDate.touched"
            [minError]="periodStartDate.hasError('minDate')"
            [maxError]="periodStartDate.hasError('maxDate')"
            ></terra-custom-utc-date-picker>
          </div>
          <!-- End date -->
          <div class="terra-form-field-wrapper">
            <terra-custom-utc-date-picker
          id="txtPeriodEndDate"
          formControlName="periodEndDate"
          label="Last day included in period"
          [minDate]="periodStartDate.value"
          [maxDate]="periodMaxDate"
          [requiredError]="periodEndDate.hasError('required')"
          [touched]="periodEndDate.touched"
          [minError]="periodEndDate.hasError('minDate')"
          [maxError]="periodEndDate.hasError('maxDate')"
          ></terra-custom-utc-date-picker>
          </div>
        </ng-container>

        <!-- Reason for distribution -->
        <div class="terra-form-field-wrapper">
          <label class="text-input-label" for="ddlTransactionPurport">Type of distribution</label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="distributionTransactionPurpose" id="ddlTransactionPurport"
                        placeholder="Select">
              <mat-option [value]="null">
                Select
              </mat-option>
              <mat-option *ngFor="let item of distributionTransactionPurposeAll" [value]="item">
                {{ item | enumString: DistributionTransactionPurpose }}</mat-option>
            </mat-select>
            <mat-error *ngIf="pageForm.get('distributionTransactionPurpose').hasError('required')">
              Required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Reason for distribution - Other -->
        <div class="terra-form-field-wrapper"
             *ngIf="pageForm.get('distributionTransactionPurpose').value === DistributionTransactionPurpose.Other">
          <label for="txtDistributionTransactionPurposeOther" class="text-input-label">
            Please specify
          </label>
          <mat-form-field appearance="outline">
            <input type="text" matInput id="txtDistributionTransactionPurposeOther"
                   formControlName="distributionTransactionPurposeOther" class="lo_sensitive"/>
            <mat-error *ngIf="pageForm.get('distributionTransactionPurposeOther').hasError('required')">
              Required
            </mat-error>
          </mat-form-field>
        </div>

        <!--        <div class="terra-form-field-wrapper" [ngClass]="{'flex-wrapping': unitBankAccounts.length}">-->
        <div class="terra-form-field-wrapper">
          <!-- distribution SourceCurrency -->
          <div class="currency-component">
            <label class="currency-label text-input-label" for="ddlCurrency">Deposit currency
              <mat-icon *ngIf="unitBankAccounts?.length" class="notranslate tooltip-icon" color="primary"
                        matTooltipClass="terra-tooltip" matTooltipPosition="right"
                        matTooltip="You have a USD denominated account linked to this {{discriminatorLowerCaseStr}}, and you can therefore distribute only in USD">
                help
              </mat-icon>
            </label>
            <mat-form-field appearance="outline" class="currency-ddl-wrapper">
              <mat-select (selectionChange)="refreshAmounts$.emit()" [compareWith]="currencyCompareFn"
                          formControlName="distributionSourceCurrency"
                          id="ddlCurrency"
                          placeholder="Select">
                <mat-option *ngFor="let currency of currencies$ | async" [value]="currency">
                  {{ currency.symbol }} {{ currency.iso }}</mat-option>
              </mat-select>
              <mat-error *ngIf="pageForm.get('distributionSourceCurrency').hasError('required')">
                Required
              </mat-error>
            </mat-form-field>
          </div>

          <!-- distribution PaymentType -->
          <!--          <div class="payment-type-component" *ngIf="unitBankAccounts.length">-->
          <div class="payment-type-component" *ngIf="false">
            <label class="text-input-label" for="ddlPaymentType">Payment type
            </label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="unitPaymentType" id="ddlPaymentType" placeholder="Select Type">
                <mat-option *ngFor="let paymentType of UnitPaymentType.listAll()"
                            [value]="paymentType">{{UnitPaymentType.toString(paymentType)}}</mat-option>
              </mat-select>
              <mat-error *ngIf="pageForm.get('unitPaymentType').hasError('required')">Required</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Other transfer purpose -->
        <div class="terra-form-field-wrapper"
             *ngIf="pageForm.get('distributionTransactionPurpose').value === DistributionTransactionPurpose.Other">
          <label for="txtDistributionTransactionPurposeOther" class="text-input-label">
            Transfer Purpose
          </label>
          <mat-form-field appearance="outline">
            <mat-select id="distributionTransactionPurposeOtherType"
                        formControlName="distributionTransactionPurposeOtherType" placeholder="Select"
                        class="lo_sensitive">
              <mat-option *ngFor="let otherType of distributionTransactionOtherTypeAll" [value]="otherType">
                {{otherType | enumString: distributionTransactionOtherType}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="pageForm.get('distributionTransactionPurposeOtherType').hasError('required')">
              Required
            </mat-error>
          </mat-form-field>
        </div>
      </section>

      <!-- Payment settings -->
      <section class="form-section">
        <h3 class="section-title">Payment settings</h3>
        <!-- External Payment -->
        <div class="terra-form-field-wrapper full-width payment-mode-row">
          <div class="toggle-wrapper checkbox-row">
            <mat-slide-toggle formControlName="paymentsViaCovercy" [ngClass]="{ 'active': pageForm.get('paymentsViaCovercy').value === true }" hideIcon (change)="viaCovercyChanged($event.checked)">Activate Covercy Online Distributions</mat-slide-toggle>
          </div>

          <div class="benefits-section full-width">
            <strong>Offline distributions without Covercy:</strong> You may create offline distributions at any time.
          </div>

          <!-- Cre Bank-->
          <div *ngIf="!noAccessToBanksMessage && pageForm.get('paymentsViaCovercy').value && showCreBankSelector()"
               class="unit-bank-section-wrapper">
            <h3 class="section-title">Sender Bank Account</h3>

            <div class="terra-form-field-wrapper half-width select-unit-bank-account">
              <label class="text-input-label" for="ddlUnitBankAccount">
                Select Bank account for the distribution:
              </label>
              <p-dropdown [options]="unitBankAccounts" (onChange)="onSelectionChanged($event)" formControlName="creBankAccountId"  optionValue="id" placeholder="Select CRE Bank Account">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedCREBankAccount">
                      <terra-option-item
                      [text]="selectedCREBankAccount.accountNickname"
                      [subText]="subTextOption(selectedCREBankAccount)"
                      icon="account_balance" />
                    </div>
                </ng-template>
                <ng-template let-unitBankAccount pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <terra-option-item
                      [text]="unitBankAccount.accountNickname"
                      [subText]="subTextOption(unitBankAccount)"
                      icon="account_balance" />
                    </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>

          <!-- distributing ClientDetails -->
          <div *ngIf="pageForm.get('paymentsViaCovercy').value && !allTransactionsAreInUsa"
               class="terra-form-field-wrapper full-width">
            <h3 class="section-title">Cross-border payments</h3>
            <label *ngIf="hasDistributingEntities" class="text-input-label" for="ddlDistributingEntityId">
              Select the entity making this cross border payment:
            </label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="distributingEntityId" id="ddlDistributingEntityId"
                          placeholder="Select distributing entity">
                <mat-option *ngFor="let user of distributingEntities"
                            [value]="user.id">{{ user.organizationName }}</mat-option>
              </mat-select>
              <mat-error *ngIf="pageForm.get('distributingEntityId').hasError('required')"> Required, or turn off the
                toggle above
              </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="!hasDistributingEntities && !allTransactionsAreInUsa" class="info-message bottom-margin">
            <strong>Online distributions with Covercy:</strong> In order to make online distribution payments directly
            from Covercy GP
            you need to open a payment account with Covercy or one of our partners. It normally takes a few days to get
            your account
            approved by our compliance team. Please <a href="//www.covercy.com/contact-us" target="_blank">contact
            us</a> in order to begin this process.
            Terms and conditions apply.
            <ul class="covercy-payment-benefits-list">
              <li>Online tracking of the entire payment process end-to-end on Covercy GP</li>
              <li>Eliminate the risk of wire fraud with our secure, regulated and insured payment solution</li>
              <li>Your investors can opt-in to receive the funds in the account, country and even currency of their
                choice
                and change their decision whenever they want - without any cost or work for you as a GP
              </li>
              <li>Save time and Hassle! Deposit the full distribution amount into a single dedicated client account, and
                we'll distribute
                to each investor individually.
              </li>
            </ul>
          </div>
        </div>
      </section>

        <mat-tab-group class="tabs" [selectedIndex]="selectedDistributionCalculationType" (selectedTabChange)="onTabChanged($event)" mat-stretch-tabs="false" mat-align-tabs="start">
          <mat-tab label="Pro Rata" bodyClass="tab-body">
            <!-- Multi Type distribution types -->
            <terra-distribution-multi-type *ngIf="isMultiTypeDistribution" class="form-section multi-type-types"
                                           [existingDistributionTypes]="reasonsForTransfer"
                                           [updateFormValidity]="updateMultiTypeFormValidity$"
                                           [form]="multiTypeTypesForm" formArrayControlName="types" [disableEditMode]="disableEditMode$ | async" />
  
            <!-- Transfer amount instructions -->
            <section class="form-section">
              <h2 class="section-title">
                Create distribution with one of the following methods:
              </h2>
  
              <h3 class="transfer-option-item full-width">
                1. Import distribution from Excel:
              </h3>
  
              <button type="button" (click)="importProRata()"
                      [disabled]="!pageForm.get('distributionTransactionPurpose').value || (disableEditMode$ | async)"
                      mat-flat-button color="primary" class="tall-button tab-1 import-button">
                Import from File
              </button>
  
              <!-- pro rata calculation -->
              <div class="pro-rata-header full-width">
                <h3 class="transfer-option-item">
                  2. Create pro rata distribution:
                </h3>
                <ng-container *ngIf="selectedCREBankAccount">
                  <terra-buttons
                    type="link"
                    label="Transfer limits"
                    [outlinedIcon]="true"
                    (onClick)="openUnitAccountLimit()"
                  />
                </ng-container>
              </div>
  
              <terra-distribution-pro-rata
                class="full-width tab-1"
                [distributionPurposes]="reasonsForTransfer"
                [currencyIso]="pageForm.get('distributionSourceCurrency').value.iso"
                [investorsDistributionTransfers]="investorsDistributionTransfer"
                [gpPromoteDistributionTransfer]="gpDistributionTransfer"
                [externalPayment]="!this.pageForm.get('paymentsViaCovercy').value"
                [organizationDetailsId]="organizationDetailsId"
                (distributionTransfersChange)="onProRataCalculation($event)" 
                [disableEditMode]="disableEditMode$ | async"/>
  
              <h3 class="transfer-option-item full-width">
                3. Or, specify distribution amounts for {{investorsDistributionTransfer?.length}} investor(s)
                in this {{discriminatorLowerCaseStr}}:
              </h3>
            </section>
          </mat-tab>
          <!-- Waterfalls -->
          <mat-tab label="Waterfall" bodyClass="tab-body">
            <terra-distribution-waterfalls
            class="full-width tab-1" [distributionPeriod]="distributionPeriod"
            [disableWaterfalls]="!(isWaterfallAllowed$ | async)"
            [currencyIso]="pageForm.get('distributionSourceCurrency').value.iso"
            (distributionTransfersChange)="onWaterfallsCalculation($event)"
            [distributionPeriodType]="this.pageForm.get('reportPeriod').value"
            [existingWaterfall]="distributionContext.distribution?.waterfallMetaFileLink"
            [selectedCREBankAccount]="selectedCREBankAccount" 
            [disableEditMode]="disableEditMode$ | async"/>
          </mat-tab>
        </mat-tab-group>

      <!-- Transfers -->
      <section class="form-section">
        <!-- List of investors investments -->
        <mat-accordion #accordion="matAccordion" class="distributions-accordion full-width">
          <mat-expansion-panel *ngFor="let distributionTransfer of investorsDistributionTransfer"
                               [attr.id]="'distributionTransfer_' + distributionTransfer.id">
            <mat-expansion-panel-header collapsedHeight="107px" expandedHeight="113px">
              <mat-panel-title>
                <span class="investor-title">
                  @if(distributionTransfer.invalidForm){
                    <div class="invalid-from">
                      <span class="material-icons field-error">error_outline</span>
                      <span class="missing-info-notice">{{INVALID_FORM_MESSAGE}}</span>
                    </div>
                  }
                  @if(!distributionTransfer.investingEntity.ownershipInHolding && !!(missingOwnershipError$ | async)){
                    <div class="invalid-from">
                      <span class="material-icons field-error">error_outline</span>
                      <span class="missing-info-notice">{{missingOwnershipError$ | async}}</span>
                    </div>
                  }
                  <span class="first-line">
                    <!-- Name -->
                    <span class="investor-name lo_sensitive">
                      <ng-container *ngIf="allowInvestorName$ | async">
                        {{ distributionTransfer.investingEntity.name }}
                        ({{ distributionTransfer.investingEntity.contact?.firstName }}
                        {{ distributionTransfer.investingEntity.contact?.middleName }}
                        {{ distributionTransfer.investingEntity.contact?.lastName }})
                        <span *ngIf="!distributionTransfer.investingEntity.contact?.firstName || !distributionTransfer.investingEntity.contact?.lastName" class="lo_sensitive">
                          ({{distributionTransfer.investingEntity.contact?.email}})
                        </span>
                        - {{distributionTransfer.investingEntity.ownershipInHolding | terraNumber : 2}}%
                        <span class="ie-name" *ngIf="!(isAllContactsVisible$ | async)">Entity {{distributionTransfer.investingEntityId}}</span>
                      </ng-container>

                      <ng-container *ngIf="!(allowInvestorName$ | async)">Entity {{distributionTransfer.investingEntity.nickname}}
                        <ng-container *ngIf="distributionTransfer.investingEntity.contact">(Contact {{distributionTransfer.investingEntity.contact?.nickname}})</ng-container>
                        - {{distributionTransfer.investingEntity.ownershipInHolding | terraNumber : 2}}%
                      </ng-container>
                    </span>
                    <!-- Amount -->
                    <span *ngIf="feeCurrency"
                          class="header-amount">
                      {{ currencySymbol }}{{(distributionTransfer.amountNet | terraCurrency) || 0}} {{ feeCurrency | hideUsdIso }}
                    </span>
                  </span>
                  <span class="second-line">
                    <span *ngIf="updatedAccountByInvestor(distributionTransfer)"
                          class="missing-info-notice">
                          <mat-icon class="notranslate">announcement</mat-icon>{{INVESTOR_UPDATE_ACCOUNT_MSG}}
                        </span>
                        <!-- Missing bank account notice -->
                      <span *ngIf="(isEditMode$ | async) && missingAccountCondition(distributionTransfer)"
                            class="missing-info-notice">
                        {{MISSING_BANK}}
                      </span>
                      <span
                        *ngIf="missingAccountCurrency(distributionTransfer)"
                        class="missing-info-notice">
                        <mat-icon class="notranslate">edit</mat-icon>
                        {{ MISSING_CURRENCY_TITLE_ERROR }}
                      </span>
                  </span>
              </span>
              </mat-panel-title>
              <div *ngIf="distributionTransfer?.externalPayment && investorsDistributionTransfer.length > 1" class="remove-button-wrapper">
                <button (click)="$event.preventDefault();removeTransfer(distributionTransfer)"
                        class="remove-button" mat-icon-button
                        matTooltip="Delete transfer">
                  <mat-icon class="notranslate">remove_circle_outline</mat-icon>
                </button>
              </div>
            </mat-expansion-panel-header>
            <!-- Content -->
            <ng-template matExpansionPanelContent>
              <terra-distribution-transfer-details
              [holdingId]="holdingId$ | async"
              [distributionId]="distributionContext.distribution?.id"
              [distributionStatus]="distributionContext.distribution?.status"
              [distributionTransfer]="distributionTransfer"
              [distributionCurrencyIso]="feeCurrency"
              [distributionCurrencySymbol]="currencySymbol"
              [enablePaymentsViaCovercy]="pageForm.get('paymentsViaCovercy').value"
              [bankAccounts]="getBankAccountsForTransfer(distributionTransfer)"
              [clientBankAccounts]="getClientBankAccountsForTransfer(distributionTransfer)"
              [unitBankAccounts]="getUnitBankAccountsForTransfer(distributionTransfer)"
              (amountChange)="refreshAmounts$.emit()"
              [isThisGPDistributeTransfer]=false
              [distributionPurposes]="reasonsForTransfer"
              [updateFormFromModel]="updateFormAmounts$"
              [isCreateMode]="!distributionContext.isEditMode"
              [disableGrossAmount]="selectedDistributionCalculationType === distributionCalculationType.WATERFALL"
              [waterfallCalculated]="waterfallsCalculated$"
              [disableEditMode]="disableEditMode$ | async"
              [updatePaymentDateFormControl]="updatePaymentDateFormControl$"/>
              <!-- Remove from list -->
              <br/>
              @if(distributionTransfer?.externalPayment && investorsDistributionTransfer.length > 1){
                <a (click)="removeTransfer(distributionTransfer)" class="link remove-investor-link">Remove transfer</a>
              }
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- GP distribution transfer -->
        <mat-accordion #accordion="matAccordion" class="distributions-accordion gp-distribution full-width"
                       *ngIf="gpDistributionTransfer">
          <mat-expansion-panel [attr.id]="'distributionTransfer_' + gpDistributionTransfer.id">
            <mat-expansion-panel-header collapsedHeight="64px" expandedHeight="64px">
              <mat-panel-title>
                <span class="investor-title">
                  <span class="first-line">
                      <!-- Name -->
                    <span class="lo_sensitive investor-name">
                      GP promote (optional)
                    </span>
                    <!-- Amount -->
                     <span class="header-amount"
                           *ngIf="feeCurrency">
                      {{ currencySymbol }}{{(gpDistributionTransfer.amountNet | terraCurrency) || 0}} {{ feeCurrency | hideUsdIso }}
                    </span>
                  </span>
                  <span class="second-line">
                    <!-- Missing bank account notice -->
                    <span *ngIf="(isEditMode$ | async) && missingAccountCondition(gpDistributionTransfer)"
                          class="missing-info-notice">
                      {{MISSING_BANK}}
                    </span>
                    <span
                      *ngIf="missingAccountCurrency(gpDistributionTransfer)"
                      class="missing-info-notice">
                      <mat-icon class="notranslate">edit</mat-icon>
                      {{ MISSING_CURRENCY_TITLE_ERROR }}
                    </span>
                  </span>
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Content -->
            <ng-template matExpansionPanelContent>
              <terra-distribution-transfer-details
              [holdingId]="holdingId$ | async"
              [distributionId]="distributionContext.distribution?.id"
              [distributionStatus]="distributionContext.distribution?.status"
              [distributionTransfer]="gpDistributionTransfer"
              [distributionCurrencyIso]="feeCurrency"
              [distributionCurrencySymbol]="currencySymbol"
              [enablePaymentsViaCovercy]="pageForm.get('paymentsViaCovercy').value"
              [bankAccounts]="bankAccountsForGPPromote"
              [clientBankAccounts]="clientBankAccountsForGPPromote"
              [unitBankAccounts]="unitBankAccountsForGPPromote"
              (amountChange)="refreshAmounts$.emit()"
              [isThisGPDistributeTransfer]=true
              [distributionPurposes]="reasonsForTransfer"
              [updateFormFromModel]="updateFormAmounts$"
              [updateGpPromote]="updateGpPromote$"
              [disableGrossAmount]="selectedDistributionCalculationType === distributionCalculationType.WATERFALL"
              [waterfallCalculated]="waterfallsCalculated$" 
              [disableEditMode]="disableEditMode$ | async"
              [updatePaymentDateFormControl]="updatePaymentDateFormControl$"/>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </section>

      <!-- Summary -->
      <section class="form-section">
        <!-- Summary box -->
        <terra-distribution-total-amounts
          class="full-width"
          [feeCurrency]="feeCurrency"
          [feeSymbol]="currencySymbol"
          [isDistributingAnyAmountViaCovercy]="isDistributingAnyAmountViaCovercy"
          [isFeeEstimated]="isFeeEstimated"
          [distributionTransfers]="distributionTransfers"
          [distributionTotalFee]="distributionTotalFee"
          [feeAmount]="feeAmount"
          [gpEffectiveMarkupPercentage]="gpEffectiveMarkupPercentage"
          [totalAmountAfterFees]="totalAmountAfterFees"
          [totalAmountOutsideOfCovercy]="totalAmountOutsideOfCovercy"
          [totalAdjustmentsAmountViaCovercy]="totalAdjustmentsAmountViaCovercy"
          [totalAdjustmentsAmountOutsideOfCovercy]="totalAdjustmentsAmountOutsideOfCovercy"
          [withheldTaxes]="withheldTaxes"
          [totalAmountViaCovercy]="totalAmountViaCovercy"
          [isUnitBankAccount]="isUnitBankAccount"
          [distributionStatus]="(distribution$ | async)?.status || DistributionStatus.None"
          [reasonsForTransfer]="reasonsForTransfer"
          [unitFees]="calculateUnitFee() | async" 
          [loading]="totalFeeLoading$ | async"/>
          <!-- [unitPaymentType]="pageForm.get('unitPaymentType').value">-->

        <div class="full-width">
          <!-- Error message -->
          <div *ngIf="isSubmitted">
            <p *ngIf="atLeastOneTransferError" class="error-message">Please specify distribution amounts to the
              investors / GP</p>
            <p *ngIf="gpBankAccountMissingCurrencyError" class="error-message">Please select a preferred currency for
              the GP distribution.
            </p>
            <p *ngIf="!pageForm.valid || !multiTypeTypesForm.valid || detailsInvalid" class="error-message"> Please
              review and correct the fields
              marked in red </p>
            <p *ngIf="multiTypeTypesWithoutTransfers?.length > 0" class="error-message">Please specify distribution
              amounts for
              type{{multiTypeTypesWithoutTransfers.length > 1 ? 's' : ''}} {{getMissingTransferTypesString(multiTypeTypesWithoutTransfers)}}</p>
            <p *ngIf="isGeneralServerError && !customError" class="error-message">{{ generalServerErrorMessage }}</p>
            <!--            <p *ngIf="isGeneralServerError && !customError" class="error-message">{{ generalServerErrorMessage }}</p>-->
            <p *ngIf="customError" class="error-message">{{ customError }}</p>
          </div>

          <div class="mt-7">
            <button class="page-cta" color="primary" mat-flat-button
                    type="submit">{{distributionContext.isEditMode ? 'Save changes' : 'Create'}}
                  </button>
            <button (click)="cancel(distributionContext.holding, distributionContext.distribution)"
                    class="cancel-button tall-button" mat-button
                    type="button">Cancel
            </button>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>
