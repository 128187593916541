import { DistributionTransferReqRes } from './DistributionTransferReqRes.model';
import DistributionTransactionPurpose from '../../../../shared/enums/DistributionTransactionPurpose.enum';
import DistributionTransactionType from '../../../../shared/enums/DistributionTransactionOtherType.enum';
import DistributionTransactionOtherType from '../../../../shared/enums/DistributionTransactionOtherType.enum';

export class DistributionTransferDetailsReqRes {
  public id: number;
  public distributionTransferId: number;
  public distributionTransfer: DistributionTransferReqRes;
  public distributionTransactionType: DistributionTransactionType;
  public reasonForTransaction: DistributionTransactionPurpose;
  public transactionOtherType: DistributionTransactionOtherType;
  public reasonForTransactionText: string;
  public taxes: number;
  public amountAfterTaxes: number;
  public amountNet: number;
  public amountGross: number;
  public adjustments: number;
  public gpPromote: number;
  public amountAfterGpPromote: number;
  public adjustmentsComments: string;

  constructor() {
    this.taxes = 0;
    this.amountAfterTaxes = 0;
    this.amountNet = 0;
    this.amountGross = 0;
    this.adjustments = 0;
    this.amountAfterGpPromote = 0;
  }
}
