import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NavigationStart, Router} from '@angular/router';
import {Subscription, filter, of} from 'rxjs';
import {LinkedBankAccountService} from 'src/app/dashboard/bankAccounts/linked-bank-account.service';
import {RoutingService} from 'src/app/services/routing.service';
import {PlaidDataService} from 'src/app/services/shared/plaid-data.service';
import {PlaidService} from 'src/app/services/shared/plaid.service';

@Component({
  selector: 'terra-add-bank-account-dialog',
  templateUrl: './add-bank-account-dialog.component.html',
  styleUrls: ['./add-bank-account-dialog.component.scss'],
  providers: [PlaidService, PlaidDataService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddBankAccountDialogComponent implements OnInit, OnDestroy {
  isRefreshingLinkToken$ = this.linkedBankAccountService.isRefreshingLinkToken$;
  private subscription: Subscription;

  accountTypes = [
    {
      icon: {
        type: "SVG",
        name: "add_bank_account"
      },
      title: "Open Business Account",
      attributes: [
        {
          icon: {
            type: "SVG",
            name: "apy"
          },
          text: "Get up to 3.84% APY"
        },
        {
          icon: {
            type: "SVG",
            name: "automate_operations"
          },
          text: "Automate your finance operations"
        },
        {
          icon: {
            type: "SVG",
            name: "fdic"
          },
          text: "FDIC insured, up to $3M"
        },
        {
          icon: {
            type: "SVG",
            name: "approved"
          },
          text: "Approved instantly"
        },
      ],
      button: {
        icon: {
          type: "TEXT",
          name: "add"
        },
        type: "primary",
        text: "Open Business Account",
        loading$: of(false),
        action: () => {this.createApplication()}
      },
      border: "primary"
    },
    {
      icon: {
        type: "SVG",
        name: "link_bank_account"
      },
      title: "Connect an External Account",
      attributes: [
        {
          icon: {
            type: "SVG",
            name: "balance_visibility"
          },
          text: "Get balance visibility"
        }
      ],
      button: {
        icon: {
          type: "TEXT",
          name: "link"
        },
        type: "secondary",
        text: "Connect Account",
        loading$: this.isRefreshingLinkToken$,
        action: () => {this.linkedBankAccountService.openPlaidLink(null, true);}
      },
      border: "secondary"
    }
  ]
  constructor(
    public dialogRef: MatDialogRef<AddBankAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private linkedBankAccountService: LinkedBankAccountService,
    private router: Router,
    private routingService: RoutingService) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart)
      ).subscribe(_ => this.dialogRef.close())
    this.isRefreshingLinkToken$.subscribe(v => console.log(v))
  }

  createApplication() {
    this.dialogRef.close();
    this.router.navigate([this.routingService.createBankApplicationPage()], {queryParams: this.data});
  }
}
