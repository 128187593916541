import {Component, Input, OnInit} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {OnDestroyMixin} from '@w11k/ngx-componentdestroyed';
import {ActivatedRoute} from '@angular/router';
import {combineLatest} from 'rxjs';
import {filter, switchMap, take, withLatestFrom} from 'rxjs/operators';

import {
  investorDetailsAddToDistribution,
  InvestorsOutOfDistributionDialog
} from './investors-out-of-distribution-dialog/investors-out-of-distribution-dialog.component';
import {GpHoldingService} from '../../gp-holding.service';
import {DistributionService} from '../distribution.service';
import {GpDistributionDataService} from '../../../../../services/gp/gp-distribution-data.service';
import {InvestmentReqRes} from '../../../../models/investment.model';
import {SnackbarService} from '../../../../../services/snackbar.service';

@Component({
  selector: 'terra-add-investors-out-of-distribution',
  templateUrl: './add-investors-out-of-distribution.component.html',
  styleUrls: ['./add-investors-out-of-distribution.component.scss']
})
export class AddInvestorsOutOfDistributionComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private distributionService: DistributionService,
    private gpHoldingService: GpHoldingService,
    private dialog: MatDialog,
    private gpDistributionDataService: GpDistributionDataService,
    private snackbarService: SnackbarService,
  ) {
    super();
  }

  @Input() title: string = '';
  @Input() description: string = '';


  holdingId: number;
  distributionId: number;

  holdingId$ = this.gpHoldingService.holdingId$;

  ngOnInit(): void {
    this.holdingId$.subscribe((holdingId) => {
      this.holdingId = holdingId;
    });

  }

  onSuccessfulAddedInvestors(addedInvestors: InvestmentReqRes[]) {
    if (addedInvestors) {
      this.investorsAddedMessage(addedInvestors.length);
      this.gpHoldingService.refreshDistribution();
    }
  }


  openContactsModal() {
    const config = new MatDialogConfig<investorDetailsAddToDistribution>();
    config.panelClass = 'investors-out-of-distribution-dialog';
    config.disableClose = false;


    combineLatest([this.distributionService.distributionId$, this.holdingId$]).pipe(
      take(1),
      switchMap(([distributionId, holdingId]) => {
        config.data = {distributionId, holdingId};
        return this.dialog.open(InvestorsOutOfDistributionDialog, config).afterClosed();
      }), filter((investingEntitiesIds: number[]) => coerceBooleanProperty(investingEntitiesIds) && investingEntitiesIds.length > 0),
      withLatestFrom(this.gpHoldingService.holdingId$, this.distributionService.distributionId$),
      switchMap(([investingEntitiesIds, holdingId, distributionId]) =>
        this.gpDistributionDataService.addInvestorsOutOfDistribution(holdingId, distributionId, investingEntitiesIds))
    ).subscribe((addedInvestors) => {
        this.onSuccessfulAddedInvestors(addedInvestors);
      }, err => {}
    );
  }

  private investorsAddedMessage(numberOfInvestors: number) {
    const addedMessage = numberOfInvestors === 1 ? 'Investor added' : `${numberOfInvestors} investors added`;
    this.snackbarService.showGeneralMessage(addedMessage, 3, 'flow-added-investors-success');
  }

}
