<div class="new-folder">
  <div class="folder-name">
    Folder Name
    <mat-form-field class="folder-form-field" appearance="outline">
      <div class="folder-field">
        <input matInput type="text" [formControl]="folderName" [placeholder]="placeholder">
        <button class="clear-btn" *ngIf="folderName.value" matSuffix mat-icon-button aria-label="Clear"
          (click)="folderName.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-error *ngIf="folderName.dirty && folderName.invalid">Invalid or already existing folder name</mat-error>
      <mat-progress-bar *ngIf="folderName.pending" class="folder-validation-indicator" mode="indeterminate"></mat-progress-bar>
    </mat-form-field>
  </div>

</div>
