<ng-container *ngIf="{ isUpdatingStatus: isUpdatingStatus$ | async } as data">
  <div class="wrapper {{ currentStatusClass }}">
    <button mat-flat-button class="item potential" (click)="updateStatus(InvestmentStatus.Potential)"
      [disabled]="readOnlyMode || potentialButtonInactive || data.isUpdatingStatus">
      {{ InvestmentStatus.Potential | enumString: InvestmentStatus}}
    </button>
    <button mat-flat-button class="item committed" (click)="updateStatus(InvestmentStatus.SoftCircled)"
      [disabled]="readOnlyMode || softCircledButtonInactive || data.isUpdatingStatus">
      {{ InvestmentStatus.SoftCircled | enumString: InvestmentStatus}}
    </button>
    <button mat-flat-button class="item signed" (click)="updateStatus(InvestmentStatus.Signed)"
      [disabled]="readOnlyMode || signedButtonInactive || data.isUpdatingStatus">
      {{ InvestmentStatus.Signed | enumString: InvestmentStatus}}
    </button>
    <button mat-flat-button class="item invested" (click)="updateStatus(InvestmentStatus.Invested)"
      [disabled]="readOnlyMode || investedButtonInactive || data.isUpdatingStatus">
      {{ InvestmentStatus.Invested | enumString: InvestmentStatus}}
    </button>
  </div>
  <div class="declined-wrapper {{ currentStatusClass }}">
    <button type="button" mat-flat-button class="item declined" (click)="updateStatus(InvestmentStatus.Declined)"
      [disabled]="readOnlyMode || declinedButtonInactive || data.isUpdatingStatus">
      {{ InvestmentStatus.Declined | enumString: InvestmentStatus}}
    </button>
  </div>
</ng-container>
