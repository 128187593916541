import {
  Component,
  ChangeDetectorRef,
  AfterViewChecked,
  OnInit,
  ViewEncapsulation,
  HostListener,
  OnDestroy,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from "@angular/core";
import {trigger, style, animate, transition} from "@angular/animations";
import {MatIconRegistry} from "@angular/material/icon";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DomSanitizer} from "@angular/platform-browser";
import {
  takeWhile,
  filter,
  map,
  flatMap,
  shareReplay,
  withLatestFrom,
  debounceTime,
  distinctUntilChanged,
} from "rxjs/operators";
import {Subscription, interval} from "rxjs";
import {NavigationEnd, Router, Event, Scroll} from "@angular/router";
// import { CookieService, CookieOptions } from 'ngx-cookie';
import {CookieService} from "ngx-cookie-service";
import {ViewportScroller} from "@angular/common";

import {NewVersionSnackbarComponent} from "./shared/components/new-version-snackbar/new-version-snackbar.component";
import {HttpService} from "./services/http.service";
import {AppService} from "./state";
import {HelpSourceTypeService} from "./services/help-source-type.service";
import {AppSettingsService} from "./services/app-settings.service";

@Component({
  selector: "terra-root",
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({
          transform: "translateX(100%)",
          opacity: 0,
        }),
        animate("500ms", style({transform: "translateX(0)", opacity: 1})),
      ]),
      transition(":leave", [
        style({
          transform: "translateX(0)",
          opacity: 1,
        }),
        animate("500ms", style({transform: "translateX(100%)", opacity: 0})),
      ]),
    ]),
  ],
  template: `
    <router-outlet></router-outlet>

    <payapp-lucky-orange></payapp-lucky-orange>
    <payapp-google-analytics></payapp-google-analytics>
    <terra-user-flow></terra-user-flow>
    <input id="organizationDetailsId" *ngIf="organizationDetailsId$ | async" [hidden]="true" [ngModel]="organizationDetailsId$ | async">
    <input id="clientDetailsId" *ngIf="clientDetailsId$ | async" [hidden]="true" [ngModel]="clientDetailsId$ | async">
    <input id="clientIdentifier" *ngIf="clientIdentifier$ | async" [hidden]="true" [ngModel]="clientIdentifier$ | async">
  `,
  // styleUrls: ['lp/styles/info-dialog.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewChecked, OnInit {
  title = "terra-plus";
  @ViewChild('googleTagManager')
  private googleTagManager: TemplateRef<any>;

  private payAppVersion: string;

  accountDetails$ = this.appService.accountDetails$.pipe(
    filter(accountDetails => !!accountDetails),
    debounceTime(300),
    distinctUntilChanged((prev, curr) => {
      return prev.id === curr.id;
    })
  );

  organizationDetailsId$ = this.accountDetails$.pipe(
    map(accountDetails => accountDetails?.organizationDetails?.id)
  );

  clientDetailsId$ = this.accountDetails$.pipe(
    map(accountDetails => accountDetails?.id)
  );

  clientIdentifier$ = this.accountDetails$.pipe(
    map(accountDetails => accountDetails?.clientIdentifier)
  );

  constructor(
    private appService: AppService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private http: HttpService,
    private snackBar: MatSnackBar,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private cookieService: CookieService,
    private appSettingsService: AppSettingsService,
    private helpSourceTypeService: HelpSourceTypeService
  ) {
    this.addSvgIcons();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.applyReferralCookie();
    // this.startVersionMonitoring();
    this.scrollToTopAfterAnyRouteChange();
  }

  scrollToTopAfterAnyRouteChange() {
    const data$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((_) => this.router.routerState.root),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      flatMap((route) => route.data),
      shareReplay(1)
    );

    this.router.events
      .pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
        withLatestFrom(data$)
      )
      .subscribe(([e, data]) => {
        if (e.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          if (!data || !data.preventScroll) {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }

  startVersionMonitoring() {
    // changed to 20 by shlomi - too many request i can't see the console
    const secondsInterval = 20;

    this.checkVersionChanged();

    interval(secondsInterval * 1000)
      .pipe(takeWhile(() => true))
      .subscribe(() => {
        this.checkVersionChanged();
      });
  }

  checkVersionChanged() {
    this.http.get("Utils/Version").subscribe(
      (version: string) => {
        /* if the version returned from the server doesn't match the version printed in the html,
         it means a new version was uploaded WHILE the user was in the site.
         This is the only time we wish to ask the user to refresh the page.
         */
        if (this.payAppVersion) {
          if (version !== this.payAppVersion) {
            this.snackBar.openFromComponent(NewVersionSnackbarComponent, {});
          }
        }
        this.payAppVersion = version;
      },
      (error) => console.log(error)
    );
  }

  private addSvgIcons() {
    // adding custom SVG icon that can be used in a mat-icon:
    // https://material.angular.io/components/icon/examples
    //  https://alligator.io/angular/custom-svg-icons-angular-material/
    // <mat-icon svgIcon="terra-export"></mat-icon>
    this.matIconRegistry.addSvgIcon(
      `terra-export`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/export_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `terra-import`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/import_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `business_center_outline`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/outline-business_center-24px.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `account_circle_outline`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/outline-account_circle-24px.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `pie_chart_quarter`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/pie_chart_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `publish`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/publish_arrow.svg`
      )
    );

    this.matIconRegistry.addSvgIcon(
      `holding_down`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/asset_down.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `drag_handle`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/drag_handle_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `certificate`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/certificate.svg`
      )
    );

    this.matIconRegistry.addSvgIcon(
      `investor_filled`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/investor_filled.svg`
      )
    );

    this.matIconRegistry.addSvgIcon(
      `calculator`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/calculator_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `capital_info`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/capital_info_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `project_info`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/project_info_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `asset_house`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/asset_house_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `stocks_up`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/stocks_up_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `calendar_notice`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/calendar_notice_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `coins`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/coins_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `phone_verification`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/phone_verification.svg`
      )
    );

    // LP Portal icons:
    this.matIconRegistry.addSvgIcon(
      `profile_filled`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/profile_filled.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `profile_nofill`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/profile_nofill.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `portfolio_nofill`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/portfolio_nofill.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `portfolio_filled`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/portfolio_filled.svg`
      )
    );

    // LP Asset tabs:
    this.matIconRegistry.addSvgIcon(
      `information_tab`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/information_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `transactions_tab`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/transactions_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `documents_tab`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/documents_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `thumb_outline`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/outline-thumb.svg`
      )
    );

    // File icons:
    this.matIconRegistry.addSvgIcon(
      `file_icon_general`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_icons/icon_file_text_2.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_icon_image`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_icons/icon_file_image.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_icon_video`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_icons/icon_file_video.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_icon_audio`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_icons/icon_file_audio.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_icon_doc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_icons/icon_file_text.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_icon_presentation`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_icons/icon_file_presentation.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_icon_spreadsheet`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_icons/icon_file_spreadsheet.svg`
      )
    );

    this.matIconRegistry.addSvgIcon(
      `file_image`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_types/image_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_doc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_types/doc_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_general`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_types/general.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_pdf`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_types/pdf_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_ppt`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_types/ppt_icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `file_excel`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/file_types/xls_icon.svg`
      )
    );

    // Transaction icons:
    this.matIconRegistry.addSvgIcon(
      "transaction_outgoing",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/money_out.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "transaction_incoming",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/money_in.svg`
      )
    );

    this.matIconRegistry.addSvgIcon(
      "holding_with_fundraising",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding_with_fundraising.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "holding_without_fundraising",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding_without_fundraising.svg`
      )
    );

    // fundraising icons
    this.matIconRegistry.addSvgIcon(
      "AI-ranking",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/AI-Ranking.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "AI-ranking-disabled",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/AI-Ranking-Disabled.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "AI-ranking-hover",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/AI-Ranking-Hover.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "add-new-contact",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/Add-New-Contact.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "add-new-contact-hover",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/Add-New-Contact-Hover.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "add-from-contacts",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/Add-From-Contact.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "fund",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/Fund.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "fund-hover",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/Fund-Hover.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "fund-disabled",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/Fund-Disabled.svg`
      )
    );

    // AI ranking
    this.matIconRegistry.addSvgIcon(
      "ranking-high",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/Ranking-Higher.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "ranking-normal",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/Ranking-Normal.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "ranking-low",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/Ranking-Low.svg`
      )
    );

    // Unit card
    this.matIconRegistry.addSvgIcon(
      "new-card-not-access",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/unit-card/not-access.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "card-confirm-dialog",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/unit-card/card-confirm-dialog.svg`
      )
    );

    // CRE Account types
    this.matIconRegistry.addSvgIcon(
      "account-type-operational",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/cre-account-type/account-type-operational.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "account-type-primary",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/cre-account-type/account-type-investors.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "account-type-security",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/cre-account-type/account-type-security.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "account-type-other",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/cre-account-type/account-type-other.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "account-type-capex",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/cre-account-type/account-type-capex.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "account-type-reserve",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/cre-account-type/account-type-reserve.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "pending",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/pending.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "rejected",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/rejected.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "sent",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/sent.svg`
      )
    );

    this.matIconRegistry.addSvgIcon(
      "get-cash",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/get-cash.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "gears",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/gears.svg`
      )
    );

    // Capital call
    this.matIconRegistry.addSvgIcon(
      "capital-call-pdf",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/cc-pdf.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "capital-call-payapp",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/cc-payapp.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "capital-call-ach-debit",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/cc-ach-debit.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "capital-call-pdf-selected",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/cc-pdf-selected.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "capital-call-payapp-selected",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/cc-payapp-selected.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "capital-call-ach-debit-selected",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/cc-ach-debit-selected.svg`
      )
    );

    this.matIconRegistry.addSvgIcon(
      "visibility_lock",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/holding/visibility_lock.svg`
      )
    );

    // General
    this.matIconRegistry.addSvgIcon(
      "error_outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/error_outline.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "apk_install",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/apk_install.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "location_on",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/location_on.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "email",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/email.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "building",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/building.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "link",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/link.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "link_broken",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/link_broken.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "add_bank_account",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/external-bank-accounts/add-bank-account.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "business_entity",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/external-bank-accounts/business-entity.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "link_bank_account",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/external-bank-accounts/link-bank-account.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "approved",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/external-bank-accounts/approved.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "apy",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/external-bank-accounts/apy.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "automate_operations",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/external-bank-accounts/automate-operations.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "balance_visibility",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/external-bank-accounts/balance-visibility.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "fdic",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/external-bank-accounts/fdic.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "show_closed_accounts",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/external-bank-accounts/show-closed-accounts.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hide_closed_accounts",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/external-bank-accounts/hide-closed-accounts.svg`
      )
    );

    // DOCUMENTS ICONS
    this.matIconRegistry.addSvgIcon(
      `any_doc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/doc_icons/any-doc.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `pdf_doc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/doc_icons/pdf-doc.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `xls_doc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/doc_icons/xsl-doc.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `csv_doc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/doc_icons/csv-doc.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `jpg_doc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/doc_icons/jpg-doc.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `png_doc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/doc_icons/png-doc.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `gif_doc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/doc_icons/gif-doc.svg`
      )
    );

    // INVESTING ICONS
    this.matIconRegistry.addSvgIcon(
      `inc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/investing/inc.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `personal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/investing/personal.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `llc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/investing/llc.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `llp`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/investing/llp.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `lp`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/investing/lp.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `ltd`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/investing/ltd.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `plan`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/investing/plan.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `trust`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/icons/investing/trust.svg`
      )
    );
  }

  @HostListener("window:beforeunload", ["$event"])
  clearStorageAuthorizationData($event) { }

  private applyReferralCookie() {
    const referralCookieName = "refc";
    const sourceParamName = "utm_source";
    const mediumParamName = "utm_medium";
    const campaignParamName = "utm_campaign";
    const experimentParamName = "experiment_id";
    const urlParamName = "Referer";

    const param = this.getQueryVariable(sourceParamName);
    const medium = this.getQueryVariable(mediumParamName);
    const campaign = this.getQueryVariable(campaignParamName);
    const experiment = this.getQueryVariable(experimentParamName);
    const url = this.getQueryVariable(urlParamName);

    let cookieValue = "";

    if (param !== null && param !== undefined && param.length > 0) {
      cookieValue = this.appendReferralParam(
        cookieValue,
        sourceParamName,
        param
      );
    }
    if (medium !== null && medium !== undefined && medium.length > 0) {
      cookieValue = this.appendReferralParam(
        cookieValue,
        mediumParamName,
        medium
      );
    }
    if (campaign !== null && campaign !== undefined && campaign.length > 0) {
      cookieValue = this.appendReferralParam(
        cookieValue,
        campaignParamName,
        campaign
      );
    }
    if (
      experiment !== null &&
      experiment !== undefined &&
      experiment.length > 0
    ) {
      cookieValue = this.appendReferralParam(
        cookieValue,
        experimentParamName,
        experiment
      );
    }
    if (url !== null && url !== undefined && url.length > 0) {
      cookieValue = this.appendReferralParam(cookieValue, urlParamName, url);
    } else if (
      cookieValue !== null &&
      cookieValue !== undefined &&
      cookieValue.length > 0
    ) {
      if (
        document.referrer !== null &&
        document.referrer !== undefined &&
        document.referrer.length > 0
      ) {
        cookieValue = this.appendReferralParam(
          cookieValue,
          urlParamName,
          encodeURIComponent(document.referrer)
        );
      } else {
        cookieValue = this.appendReferralParam(
          cookieValue,
          urlParamName,
          "Direct"
        );
      }
    }

    if (cookieValue !== null && cookieValue.length > 0) {
      const expiresDate = new Date();
      expiresDate.setDate(expiresDate.getDate() + 180);
      this.cookieService.set(
        referralCookieName,
        cookieValue,
        expiresDate,
        "/",
        this.appSettingsService.cookieDomainNamespace
      );
    }
  }

  private appendReferralParam(result, key, value) {
    if (result != null && result.length > 0) {
      result += "&";
    }
    result += key + "=" + value;
    return result;
  }

  private getQueryVariable(variable) {
    const query = location.search.substring(1);
    const vars = query.split("&");
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) === variable) {
        return pair[1];
      }
    }
  }
}
