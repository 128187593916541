<terra-dialog-header>
  <h1>Import Contribution History</h1>
</terra-dialog-header>

<mat-dialog-content *ngIf="!loadingPage; else loader">
  <form id="pageForm" class="pb-2" (ngSubmit)="import()" [formGroup]="pageForm" (keydown.enter)="$event.preventDefault()">
    <div class="cov-form-grid">
      <div class="full-width">
        <ol class="instructions-list">
          <li><a (click)="downloadTemplate()" class="example-file-link light link">
            Download {{contributionTitle | lowercase}} template</a> (if you haven't already)
          </li>
          <li>
            Edit the file, and update the {{contributionTitle | lowercase}} for each investor
          </li>
          <li>Upload the updated file using the button below, and click "Import"</li>
        </ol>
        <terra-file-uploader no-type-select [supportedExtensions]="supportedFileExtensions" formControlName="file"
                             [isSubmitted]="isSubmitted" class="full-width" />
      </div>
    </div>
    <!-- Error message -->
    <div *ngIf="isSubmitted">
      <p *ngIf="isGeneralServerError" class="error-message" [innerHtml]="generalServerErrorMessage"></p>
      <p *ngIf="showMoreLines" class="error-message" [innerHtml]="fullErrorListMessage"></p>
      <a (click)="toggleFullErrorList()" *ngIf="hasMoreErrorLines" class="link">Show {{errorListStatus}} errors...</a>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button [disabled]="loadingPage" type="submit" form="pageForm" mat-flat-button color="primary" class="page-cta">
    Import
  </button>
  <button [disabled]="loadingPage" type="button" mat-button class="cancel-button tall-button"
          [mat-dialog-close]="undefined">Cancel
  </button>
</mat-dialog-actions>

<ng-template #loader>
  <terra-loader />
</ng-template>
