import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray} from '@angular/forms';
import {BehaviorSubject, of, combineLatest, Observable, throwError} from 'rxjs';
import {switchMap, shareReplay, map, tap, catchError, take} from 'rxjs/operators';

import ReportPeriod from 'src/app/shared/enums/ReportPeriod.enum';
import {GpFundService} from '../../../gp-fund.service';
import {FundReportReqRes} from 'src/app/shared/models/FundReportReqRes.model';
import {ReportFileReqRes} from 'src/app/shared/models/ReportFileReqRes.model';
import ReportFileType from 'src/app/shared/enums/ReportFileType.enum';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {MetaFileLink} from 'src/app/models/metaFileLink.model';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {FundReportDataService} from 'src/app/services/shared/fund-report-data.service';
import {RoutingService} from 'src/app/services/routing.service';

@Injectable()
export class GpFundReportService {
  fund$ = this.gpFundService.holding$;
  fundId$ = this.gpFundService.holdingId$;

  reportId$ = this.route.params.pipe(
    map(params => +params.reportId || -1),
    shareReplay()
  );

  isEditMode$ = this.reportId$.pipe(map(reportId => reportId > 0));

  reportDetails$: Observable<FundReportReqRes> = combineLatest([this.fundId$, this.reportId$]).pipe(
    switchMap(([fundId, reportId]) => (reportId > 0 ? this.fundReportDataService.getByIdForGp(fundId, reportId) : of(null))),
    shareReplay()
  );

  isAlreadyPublished$ = this.reportDetails$.pipe(map(reportDetails => reportDetails && reportDetails.publishDate));

  wizardFormReady$ = new BehaviorSubject(false);

  processingSubmit$ = new BehaviorSubject(false);

  public readonly wizardStepPaths = ['report-information', 'cumulative-info', 'review'];

  private currentYear = new Date().getFullYear();

  currentStep$ = new BehaviorSubject(this.getCurrentStepIndexFromUrl() + 1 || 1);

  isReportDocumentFileNotSupported = false;
  isGeneralServerError = false;

  reportForm: UntypedFormGroup;

  get formStep1(): UntypedFormGroup {
    if (this.reportForm) {
      return this.reportForm.get('step1') as UntypedFormGroup;
    }
    return null;
  }

  get formStep2(): UntypedFormGroup {
    if (this.reportForm) {
      return this.reportForm.get('step2') as UntypedFormGroup;
    }
    return null;
  }

  constructor(
    private fundReportDataService: FundReportDataService,
    private gpFundService: GpFundService,
    private fb: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private numberPipe: TerraNumberPipe,
    private router: Router,
    private routingService: RoutingService
  ) {
    this.generateForm();
  }

  getCurrentStepIndexFromUrl() {
    const wizardStep = this.route.snapshot.children[0].url[0].path.toLowerCase();
    const index = this.wizardStepPaths.findIndex(x => x === wizardStep.toString());
    return index;
  }

  generateForm() {
    combineLatest([this.fund$, this.reportDetails$]).subscribe(([fund, reportDetails]) => {
      if (!fund) {
        return;
      }
      const reportForm = this.fb.group({
        step1: this.fb.group({
          reportPeriod: [ReportPeriod.Quarterly],
          year: [this.currentYear, Validators.required],
          quarter: [1, Validators.required],
          periodStartDate: [],
          periodEndDate: [],

          grossPotentialRent: [],
          vacancyLoss: [],
          delinquentRent: [],
          income: [],
          expenses: [],
          assetManagementFees: [],
          netOperatingIncome: [],
          debtService: [],
          capitalExpenditures: [],
          netIncome: [],
          vacancyRate: [],
          delinquentRate: [],
          operatingExpense: [],
          summary: ['', Validators.maxLength(TerraUtils.consts.validators.GENERAL_LONG_STRING_MAX_LENGTH)],
          capitalInformation: ['', Validators.maxLength(TerraUtils.consts.validators.GENERAL_LONG_STRING_MAX_LENGTH)],
          acquisitionsDispositions: ['', Validators.maxLength(TerraUtils.consts.validators.GENERAL_LONG_STRING_MAX_LENGTH)],
          holdingUpdates: ['', Validators.maxLength(TerraUtils.consts.validators.GENERAL_LONG_STRING_MAX_LENGTH)],
          administrativeUpdates: ['', Validators.maxLength(TerraUtils.consts.validators.GENERAL_LONG_STRING_MAX_LENGTH)],
          reportPdf: [],
          attachments: this.fb.array([]),
          fileattachments:[]
        }),
        step2: this.fb.group({
          totalCapitalization: [fund.totalCapitalization, Validators.required],
          totalInvestorEquity: [fund.totalInvestorEquity, Validators.required],
          gpEquityFromTotalEquity: fund.gpEquityFromTotalEquity,
          estimatedMarketValue: fund.estimatedMarketValue,
          totalEquityInvestedToDate: [fund.totalEquityInvestedToDate],
          totalReturnsToDate: [fund.totalReturnsToDate]
        })
      });

      // find the report attachment:
      if (reportDetails) {
        const reportPdf = reportDetails.attachments.find(att => att.fileType === ReportFileType.ReportDocument);
        let reportMetaFileLink: MetaFileLink = null;
        if (reportPdf) {
          reportMetaFileLink = reportPdf.metaFileLink;
        }

        const quarter = TerraUtils.getQuarterNumber(reportDetails.periodStartDate, reportDetails.periodEndDate);
        const periodStartYear = new Date(reportDetails.periodStartDate).getFullYear();

        reportForm.get('step1').patchValue({
          reportPeriod: quarter ? ReportPeriod.Quarterly : ReportPeriod.Custom,
          year: periodStartYear,
          quarter: quarter ? quarter : 1,
          periodStartDate: reportDetails.periodStartDate ? new Date(reportDetails.periodStartDate) : null,
          periodEndDate: reportDetails.periodEndDate ? new Date(reportDetails.periodEndDate) : null,

          grossPotentialRent: reportDetails.grossPotentialRent,
          vacancyLoss: reportDetails.vacancyLoss,
          delinquentRent: reportDetails.delinquentRent,
          income: reportDetails.income,
          expenses: reportDetails.expenses,
          assetManagementFees: reportDetails.assetManagementFees,
          netOperatingIncome: reportDetails.netOperatingIncome,
          debtService: reportDetails.debtService,
          capitalExpenditures: reportDetails.capitalExpenditures,
          netIncome: reportDetails.netIncome,
          vacancyRate: reportDetails.vacancyRate,
          delinquentRate: reportDetails.delinquentRate,
          operatingExpense: reportDetails.operatingExpense,
          summary: reportDetails.summary,
          capitalInformation: reportDetails.capitalInformation,
          acquisitionsDispositions: reportDetails.acquisitionsDispositions,
          holdingUpdates: reportDetails.holdingUpdates,
          administrativeUpdates: reportDetails.administrativeUpdates,
          reportPdf: reportMetaFileLink
        });

        reportForm.get('step2').patchValue({
          totalCapitalization: reportDetails.totalCapitalization,
          totalEquityInvestedToDate: reportDetails.totalEquityInvestedToDate,
          totalReturnsToDate: reportDetails.totalReturnsToDate,
          gpEquityFromTotalEquity: reportDetails.gpEquityFromTotalEquity,
          estimatedMarketValue: fund.estimatedMarketValue
        });
      }

      const attachmentsArray = reportForm.get('step1.attachments') as UntypedFormArray;
      for (let i = 0; i < 20; i++) {
        attachmentsArray.push(this.fb.control({}));
      }

      // Populate the form with the existing media files
      if (reportDetails) {
        const mediaFiles = reportDetails.attachments.filter(att => att.fileType === ReportFileType.ReportMedia || att.fileType === ReportFileType.ReportMediaRentManager);
        let mediaFilesMetaFiles: MetaFileLink[] = null;
        if (mediaFiles.length > 0) {
          mediaFilesMetaFiles = mediaFiles.map(file => ({...file.metaFileLink, fileType: file.fileType}));
        }
        if (mediaFilesMetaFiles) {
          attachmentsArray.patchValue(mediaFilesMetaFiles);
        }
      }

      this.reportForm = reportForm;
      this.wizardFormReady$.next(true);
    });
  }

  private generateSubmitModel(): Observable<FundReportReqRes> {
    return this.reportDetails$.pipe(
      map(reportDetails => {
        const model = new FundReportReqRes();
        const step1 = this.formStep1.value;
        if (step1.reportPeriod === ReportPeriod.Custom) {
          model.periodStartDate = step1.periodStartDate;
          model.periodStartDate.setHours(12);
          model.periodEndDate = step1.periodEndDate;
          model.periodEndDate.setHours(12);
        } else {
          const dates = TerraUtils.getStartAndEndDates(step1.year as number, step1.quarter as number);
          model.periodStartDate = dates[0];
          model.periodEndDate = dates[1];
        }

        model.grossPotentialRent = this.numberPipe.parse(step1.grossPotentialRent);
        model.vacancyLoss = this.numberPipe.parse(step1.vacancyLoss);
        model.delinquentRent = this.numberPipe.parse(step1.delinquentRent);
        model.income = this.numberPipe.parse(step1.income);
        model.expenses = this.numberPipe.parse(step1.expenses);
        model.assetManagementFees = this.numberPipe.parse(step1.assetManagementFees);
        model.netOperatingIncome = this.numberPipe.parse(step1.netOperatingIncome);
        model.debtService = this.numberPipe.parse(step1.debtService),
          model.capitalExpenditures = this.numberPipe.parse(step1.capitalExpenditures),
          model.netIncome = this.numberPipe.parse(step1.netIncome);
        model.vacancyRate = this.numberPipe.parse(step1.vacancyRate);
        model.delinquentRate = this.numberPipe.parse(step1.delinquentRate);
        model.operatingExpense = this.numberPipe.parse(step1.operatingExpense);

        model.summary = step1.summary;
        model.capitalInformation = step1.capitalInformation;
        model.acquisitionsDispositions = step1.acquisitionsDispositions;
        model.holdingUpdates = step1.holdingUpdates;
        model.administrativeUpdates = step1.administrativeUpdates;

        model.attachments = new Array<ReportFileReqRes>();

        if (step1.reportPdf && step1.reportPdf.id) {
          const _reportPdf = new ReportFileReqRes();
          _reportPdf.fileType = ReportFileType.ReportDocument;
          _reportPdf.metaFileLinkId = step1.reportPdf.id;
          if (reportDetails) {
            const originalPdf = reportDetails.attachments.find(reportFile => reportFile.metaFileLinkId === _reportPdf.metaFileLinkId);
            _reportPdf.id = originalPdf ? originalPdf.id : 0;
          }
          model.attachments.push(_reportPdf);
        }

        const attachmentsForms = step1.attachments.filter(a => a && a.id);
        if (attachmentsForms) {
          attachmentsForms.forEach(attachmentFormValue => {
            const file = new ReportFileReqRes();
            file.fileType = attachmentFormValue.fileType ?? ReportFileType.ReportMedia;
            file.metaFileLinkId = attachmentFormValue.id;
            // if this attachment was already in the report, set the id also:
            if (reportDetails) {
              const reportMediaFile = reportDetails.attachments.find(reportFile => reportFile.metaFileLinkId === file.metaFileLinkId);
              file.id = reportMediaFile ? reportMediaFile.id : 0;
            }
            model.attachments.push(file);
          });
        }

        const step2 = this.formStep2.value;

        model.totalCapitalization = this.numberPipe.parse(step2.totalCapitalization);
        model.totalEquityInvestedToDate = this.numberPipe.parse(step2.totalEquityInvestedToDate);
        model.totalReturnsToDate = this.numberPipe.parse(step2.totalReturnsToDate);
        model.totalInvestorEquity = this.numberPipe.parse(step2.totalInvestorEquity);
        model.gpEquityFromTotalEquity = this.numberPipe.parse(step2.gpEquityFromTotalEquity);
        model.estimatedMarketValue = this.numberPipe.parse(step2.estimatedMarketValue);

        return model;
      })
    );
  }

  saveOnly(): Observable<FundReportReqRes> {
    const submitModel$ = this.generateSubmitModel();
    return combineLatest([this.fundId$, this.reportId$, submitModel$]).pipe(
      // Call update or create
      switchMap(
        ([fundId, reportId, submitModel]): Observable<FundReportReqRes> => {
          if (reportId > 0) {
            return this.fundReportDataService.update(fundId, reportId, submitModel);
          } else {
            return this.fundReportDataService.create(fundId, submitModel);
          }
        }
      ),
      tap(reportResponse => this.triggerFundLocalUpdate(reportResponse)),
      switchMap(reportResponse => {
        return combineLatest([this.reportId$, of(reportResponse)]);
      }),
      switchMap(([reportId, reportResponse]) => {
        const message = reportId > 0 ? 'Report saved' : 'Report created';
        this.snackbarService.showGeneralMessage(message);
        return of(reportResponse);
      })
    );
  }

  saveAndPublish(sendEmail = true, includeSummaryToReport: boolean = true): Observable<boolean> {
    const submitModel$ = this.generateSubmitModel();

    return combineLatest([this.fundId$, this.reportId$, submitModel$]).pipe(
      // Call update or create
      switchMap(([fundId, reportId, submitModel]) => {
        if (reportId > 0) {
          return this.fundReportDataService.update(fundId, reportId, submitModel);
        } else {
          return this.fundReportDataService.create(fundId, submitModel);
        }
      }),
      tap(reportResponse => this.triggerFundLocalUpdate(reportResponse)),
      catchError(err => {
        return throwError(err);
      }),
      // Publish the report
      switchMap(createdReport => combineLatest([of(createdReport), this.fundId$])),
      switchMap(([createdReport, fundId]) => this.fundReportDataService.publishReport(fundId, createdReport.id, sendEmail, includeSummaryToReport)),
      catchError(err => {
        return throwError(err);
      }),
      // Show message
      tap(() => {
        this.snackbarService.showGeneralMessage('Report saved and published');
      }),
      switchMap(response => of(true))
    );
  }

  // Update the local fund details so that other places watching this observable we see the update.
  triggerFundLocalUpdate(fundReportResponse: FundReportReqRes) {
    this.gpFundService.holding$.pipe(take(1))
      .subscribe(currentFund => {
        currentFund.totalCapitalization = fundReportResponse.totalCapitalization;
        currentFund.totalEquityInvestedToDate = fundReportResponse.totalEquityInvestedToDate;
        currentFund.totalReturnsToDate = fundReportResponse.totalReturnsToDate;
        currentFund.totalInvestorEquity = fundReportResponse.totalInvestorEquity;
        currentFund.gpEquityFromTotalEquity = fundReportResponse.gpEquityFromTotalEquity;
        currentFund.estimatedMarketValue = fundReportResponse.estimatedMarketValue;
        this.gpFundService.changeFundOnly(currentFund);
      });
  }

  navigateToFundPage(fundId: number) {
    this.router.navigateByUrl(this.routingService.gpFundWithTab(fundId, 'reports'));
  }
}
