<ng-container *ngIf="fundraising$ | async as fundraising">
  <!--  {-->
  <!--  searchOptions: searchOptions$ | async,-->
  <!--  pageRowsCount: pageRowsCount$ | async,-->
  <!--  totalRowsCount : totalRowsCount$ | async  } as data"-->
  <!-- Move to Under Management -->
  <div *ngIf="showFinishedFundraisingNotification(fundraising); else actionsNeeded" id="finished-fundraising" class="container">
    <img src="assets/images/icons/change_status icon.svg"/>
    <span>
      <strong>Done fundraising? </strong>
      <a class="change-status light link" (click)="showUpdateStatus()">Update asset status to Under Management</a>
    </span>
  </div>

  <!-- When the target amoount was reached, but there are investments preventing moving to under management -->
  <ng-template #actionsNeeded>
    <div *ngIf="showFinishedFundraisingButActionsNeededNotification(fundraising)" id="finished-fundraising" class="container">
      <img src="assets/images/icons/change_status icon.svg"/>
      <span>
        <strong>Done fundraising? </strong>
        To update asset status to Under Management, make sure all investors are marked as either Potential, Invested or Declined.
      </span>
    </div>
  </ng-template>


  <!-- Asset information -->
  <section class="container information" *ngIf="asset$ | async as asset" [ngClass]="{'no-cre' : !(userHasCreBanking$ | async)}">

    <!-- over subscription -->
    <terra-raised-amount-warning 
    [investments]="fundraising.investments" 
    [fundraisingTargetAmount]="fundraising.fundraisingTargetAmount"
    [symbol]="fundraising.fundraisingTargetCurrency.symbol"></terra-raised-amount-warning>

    <!-- Images -->
    <div class="terra-card images-card">
      <terra-holding-images [location]="asset.location" [holdingFiles]="asset.attachments"></terra-holding-images>
    </div>

    <!-- Asset details box -->
    <div class="terra-card asset-details">
      <div class="card-header">
        <h2>Asset info</h2>
      </div>
      <ul class="basic-info-list">
        <!-- Property type -->
        <li class="basic-info-item">
          <mat-icon class="notranslate">home</mat-icon>
          <label>Property type:</label>
          <span class="value">{{ asset.holdingType | enumString: HoldingType }}</span>
        </li>
        <!-- Bank account -->
        <li class="basic-info-item" [ngClass]="{ 'missing-bank-account': !getHoldingBankAccount(fundraising)}">
          <span *ngIf="!getHoldingBankAccount(fundraising)" class="outer-circle">
            <mat-icon class="notranslate dollar-sign">attach_money</mat-icon>
          </span>
          <mat-icon *ngIf="getHoldingBankAccount(fundraising)" class="notranslate dollar-sign">attach_money</mat-icon>
          <label>Bank account: </label>
          <!-- When no bank account -->
          <span class="value" *ngIf="!(showBankInfo$ | async)">
            <span>*****</span>
          </span>
          <span class="value" *ngIf="(showBankInfo$ | async) && !getHoldingBankAccount(fundraising)">
            <a (click)="editAsset(4)" class="light link">
              Missing
            </a>
          </span>
          <span *ngIf="(showBankInfo$ | async) && getHoldingBankAccount(fundraising)" class="value lo_sensitive"
                [matTooltip]="(getHoldingBankAccount(fundraising) | bankAccountDisplayName : true).length > 12 ? (getHoldingBankAccount(fundraising) | bankAccountDisplayName : true) : null"
                matTooltipPosition="right" matTooltipClass="terra-tooltip-compact"
          >
            {{getHoldingBankAccount(fundraising) | bankAccountDisplayName : true | truncate: 12}}
          </span>
        </li>
        <!-- Closing date -->
        <li class="basic-info-item">
          <mat-icon class="notranslate">date_range</mat-icon>
          <label>Closing date: </label>
          <span class="value">{{ fundraising.estimatedClosingDate | utcDate }}</span>
        </li>
      </ul>
    </div>

    <!-- Cre Bank Card-->
    <div class="cre-bank-account terra-card" *ngIf="userHasCreBanking$ | async">
      <terra-holding-cre-bank-account />
    </div>

    <!-- Bulk Marketing Deck -->
    <div class="bulk-offering-deck terra-card">
      <terra-bulk-offering-deck [hasCreBank]="userHasCreBanking$ | async" [fundraisingService]="assetFundraisingService"></terra-bulk-offering-deck>
    </div>
  </section>

  <!-- Investors -->
  <section id="investors" class="container">
    <terra-fundraising-summary></terra-fundraising-summary>
    <terra-fundraising-nav></terra-fundraising-nav>
  </section>
</ng-container>

