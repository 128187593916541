export enum ReportPeriod {
  Quarterly = 1,
  // Yearly = 2,
  Custom = 3
  // if changing or adding values, updadte all other methods (toString, toList).
}

// tslint:disable-next-line:no-namespace
export namespace ReportPeriod {
  export function toString(reportPeriod: ReportPeriod): string {
    switch (reportPeriod) {
      case ReportPeriod.Quarterly:
        return 'Quarterly';
      // case ReportPeriod.Yearly:
      //   return 'Yearly';
      case ReportPeriod.Custom:
        return 'Custom';
      default:
        return null;
    }
  }

  export function listAll() {
    return [ReportPeriod.Quarterly, /*ReportPeriod.Yearly, */ ReportPeriod.Custom];
  }

  export function parse(reportPeriodStr: string): ReportPeriod {
    return ReportPeriod[reportPeriodStr];
  }
}

export default ReportPeriod;
