import { AppSettingsService } from './app-settings.service';

export const AppSettingsServiceFactory = () => {
  // Create env
  const appSettings = new AppSettingsService();

  const VARIABLE_NAME = '__appSettings';
  // Read environment variables from browser window
  const browserWindow = window || {};
  const browserWindowEnv = browserWindow[VARIABLE_NAME] || {};

  // Assign environment variables from browser window to env
  // In the current implementation, properties from env.js overwrite defaults from the EnvService.
  // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
  for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      appSettings[key] = window[VARIABLE_NAME][key];
    }
  }

  return appSettings;
};

export const AppSettingsServiceProvider = {
  provide: AppSettingsService,
  useFactory: AppSettingsServiceFactory,
  deps: []
};
