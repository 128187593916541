import {FundraisingType} from 'src/app/shared/enums/FundraisingType.enum';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {InvestmentSecurityType} from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {MetaFileLink} from 'src/app/models/metaFileLink.model';
import {ClientBankAccountReqRes} from 'src/app/dashboard/models/bankAccount.model';
import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import {FundraisingTableItemResponse} from './FundraisingTableItemResponse.model';
import {UnitBankAccountReqRes} from '../../../../../shared/models/gp/UnitBankAccountReqRes.model';

export class FundraisingReqRes extends FundraisingTableItemResponse {
  public type: FundraisingType;
  public holdingId: number;
  public fundraisingTargetAmount: number;
  public fundraisingTargetCurrencyId: number;
  public fundraisingTargetCurrency: CurrencyModel;
  public securityType: InvestmentSecurityType;
  public offeringDeckId: number;
  public offeringDeck: MetaFileLink;
  public opportunityLink: string;
  public clientBankAccountId: number;
  public clientBankAccount: ClientBankAccountReqRes;
  public investments: InvestmentReqRes[] = [];
  public minimumInvestmentAmount: number;
  public isSentOfferingDeck: boolean;
  public unitBankAccountId: number;
  public unitBankAccount: UnitBankAccountReqRes;
  public contactPhone: string;
  public contactEmail: string;
  public isGeneratingPaymentRequests: boolean;
}
