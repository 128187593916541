import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms';
import {of, BehaviorSubject, Subject, combineLatest, Observable, startWith} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
  throttleTime
} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {PaymentStatus} from 'src/app/shared/enums/PaymentStatus.enum';
import SignPlatform from 'src/app/shared/enums/SignPlatform.enum';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {FundraisingStatus} from 'src/app/shared/enums/FundraisingStatus.enum';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {MetaFileLink} from 'src/app/models/metaFileLink.model';
import {GpFundService} from '../gp-fund.service';
import {FundInvestorService} from './fund-investor.service';
import {FundraisingReqRes} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';
import PaymentPlatform from 'src/app/shared/enums/PaymentPlatform.enum';
import {HoldingDiscriminator} from 'src/app/shared/enums/HoldingDiscriminator.enum';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {CapitalCallService} from 'src/app/dashboard/shared/holding/capital-call/capital-call.service';
import {HoldingInvestorService} from 'src/app/dashboard/shared/holding/holding-investor.service';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';
import {AppSettingsService} from 'src/app/services/app-settings.service';
import {GeneralSettingsService} from 'src/app/services/shared/general-settings.service';
import {SystemFeatureFlagConsts} from 'src/app/shared/consts/SystemFeatureFlags.consts';
import {InvestorsManagementService} from 'src/app/dashboard/shared/holding/Investors-management/investors-management.service';
import {CustomValidators} from 'src/app/shared/validators/custom.validators';


@Component({
  selector: 'terra-fund-investor',
  templateUrl: './fund-investor.component.html',
  styleUrls: ['./fund-investor.component.scss'],
  providers: [
    FundInvestorService,
    {provide: HoldingInvestorService, useExisting: FundInvestorService}
  ]
})
export class FundInvestorComponent extends OnDestroyMixin implements OnInit, OnChanges {
  @Input() investmentId: number;
  @Input() investmentDetails: InvestmentReqRes;
  @Input() hasCommitments: boolean;
  @Input() refreshCommitmentsTrigger$:Subject<void>;

  readonly today = new Date();

  // enums
  InvestmentStatus = InvestmentStatus;
  PaymentStatus = PaymentStatus;
  SignPlatform = SignPlatform;
  HoldingFileType = HoldingFileType;
  PaymentPlatform = PaymentPlatform;
  HoldingDiscriminator = HoldingDiscriminator;
  HoldingStatus = HoldingStatus;
  FundraisingStatus = FundraisingStatus;
  showLoader: boolean;

  get pageForm(): UntypedFormGroup {
    return this.fundInvestorService.pageForm;
  }

  deletingInProgress = false;

  fund$ = this.gpFundService.holding$;

  fundraisingDetails$ = this.gpFundService.fundraising$;

  isContributionInReadOnlyMode$ = this.fundInvestorService.isContributionInReadOnlyMode$;

  providerAccreditationFeatureOff$ = this.generalSettings.systemFeatureFlagKeysValues$.pipe(
    map(featureFlagKeysValues => !featureFlagKeysValues[SystemFeatureFlagConsts.SystemFeatureFlagAccreditationOn])
  );

  savedDocuments$ = this.gpFundService.holding$.pipe(
    map(holding => {
      const holdingAttachments = holding.attachments?.filter(holdingFile => holdingFile.fileType === HoldingFileType.PaymentRequestDocument)?.map(doc => doc.metaFileLink);
      if (!!this.investmentDetails && this.investmentDetails.paymentRequestDocuments.length > 0) {
        return [...this.investmentDetails.paymentRequestDocuments.filter(p => p.id !== this.investmentDetails.agreementMetaFileLinkId), ...holdingAttachments];
      }
      return [...holdingAttachments];
    })
  );

  savedDocumentsLength$ = this.savedDocuments$.pipe(map(savedDocuments => savedDocuments.length));

  isUnsavedAmount$ = new BehaviorSubject(false);
  isUnsavedTotalCommitments$ = new BehaviorSubject(false);
  isUnsavedExternalRemarks$ = new BehaviorSubject(false);
  isUnsavedInternalRemarks$ = new BehaviorSubject(false);
  displayUpdateButton$ = combineLatest([this.isUnsavedAmount$, this.isUnsavedTotalCommitments$, this.isUnsavedExternalRemarks$, this.isUnsavedInternalRemarks$]).pipe(
    map(([isUnsavedAmount, isUnsavedTotalCommitments, isUnsavedExternalRemarks, isUnsavedInternalRemarks]) => isUnsavedAmount || isUnsavedTotalCommitments || isUnsavedExternalRemarks || isUnsavedInternalRemarks)
  );
  updateEstimatedAmountAndRemarks$ = new Subject<void>();
  isUpdatingStatus$ = this.fundInvestorService.isUpdatingStatus$;

  marketingDeck(fundraisingDetails: FundraisingReqRes): MetaFileLink {
    if (fundraisingDetails && fundraisingDetails.offeringDeck) {
      return fundraisingDetails.offeringDeck;
    }
    return null;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private gpFundService: GpFundService,
    private fundInvestorService: FundInvestorService,
    private snackbarService: SnackbarService,
    private numberPipe: TerraNumberPipe,
    private capitalCallService: CapitalCallService,
    private utilsService: UtilsService,
    public appSettingService: AppSettingsService,
    private generalSettings: GeneralSettingsService,
    private investorsManagementService: InvestorsManagementService,
  ) {
    super();
  }

  ngOnInit() {
    // Set the Investment id on the service: The only way to know in which investment we are currently,
    // is from the input parameter, and this is the first time where we have access to it, so we must tell the service this info here.
    this.fundInvestorService.investmentId$.next(this.investmentId);

    this.fundInvestorService.pageForm = this.fb.group({
      estimatedAmount: [],
      totalCommitments: [CustomValidators.minFormattedNumber(1)],
      finalAmount: this.investmentDetails.finalAmount,
      accreditationDate: TerraUtils.forceUtcToLocal(this.investmentDetails.investingEntity.accreditation?.accreditedDate),
      paymentSettlementDate: this.investmentDetails.paymentSettlementDate,
      externalRemarks: [],
      internalRemarks: []
    });

    this.updateForm(this.investmentDetails);

    this.subscribeToContributionReadOnlyModeChange();
    this.subscribeToEstimatedAmountChange();
    this.subscribeToRemarksChange();
    this.handleFinalAmountUpdate();
    this.subscribeToPaymentSettlementChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.investmentDetails) {
      this.updateForm(changes.investmentDetails.currentValue);
    }
  }

  get fundraisingBankAccountName$(): Observable<string> {
    return this.gpFundService.fundraisingBankAccountName$;
  }

  get showRemoveMessage(): boolean {
    return this.investmentDetails.status !== InvestmentStatus.Potential
      && this.investmentDetails.status !== InvestmentStatus.Invested
      && this.investmentDetails.status !== InvestmentStatus.Declined;
  }

  deleteInvestment() {
    if (this.deletingInProgress) {
      return;
    }
    this.deletingInProgress = true;
    this.fundInvestorService.deleteInvestment().subscribe(
      response => {
        this.deletingInProgress = false;
        this.snackbarService.showGeneralMessage(`Investor removed`);
      },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          this.snackbarService.showGeneralMessage(`Couldn't remove the investor`);
        }
        this.deletingInProgress = false;
      }
    );
  }

  validateForm() {
    TerraUtils.setErrorsOnAllFormControls(this.pageForm);
    this.isUnsavedAmount$.pipe(
      untilComponentDestroyed(this),
      take(1)
    ).subscribe(isUnsaved => {
      this.setUnsavedError(isUnsaved, 'estimatedAmount');
    });

    this.isUnsavedTotalCommitments$.pipe(
      untilComponentDestroyed(this),
      take(1)
    ).subscribe(isUnsaved => {
      this.setUnsavedError(isUnsaved, 'totalCommitments');
    });

    this.isUnsavedExternalRemarks$.pipe(
      untilComponentDestroyed(this),
      take(1)
    ).subscribe(isUnsaved => {
      this.setUnsavedError(isUnsaved, 'externalRemarks');
    });

    this.isUnsavedInternalRemarks$.pipe(
      untilComponentDestroyed(this),
      take(1)
    ).subscribe(isUnsaved => {
      this.setUnsavedError(isUnsaved, 'internalRemarks');
    });
  }

  setUnsavedErrorIfRequired(isUnsaved: boolean, formControlName: string) {
    this.setUnsavedError(isUnsaved, formControlName);
  }

  revertEstimatedAmountAndRemarksChanges() {
    const amountField = this.pageForm.get('estimatedAmount');
    amountField.setValue(this.numberPipe.transform(this.investmentDetails.estimatedAmount));
    const totalCommitmentsField = this.pageForm.get('totalCommitments');
    totalCommitmentsField.setValue(this.numberPipe.transform(this.investmentDetails.totalCommitment));
    const externalRemarksField = this.pageForm.get('externalRemarks');
    externalRemarksField.setValue(this.investmentDetails.externalRemarks);
    const internalRemarksField = this.pageForm.get('internalRemarks');
    internalRemarksField.setValue(this.investmentDetails.internalRemarks);
    this.isUnsavedAmount$.next(false);
    this.isUnsavedTotalCommitments$.next(false);
    this.isUnsavedExternalRemarks$.next(false);
    this.isUnsavedInternalRemarks$.next(false);
  }

  private updatePaymentSettlementDate(date: Date) {
    return this.fundInvestorService.updatePaymentSettlementDate(date).pipe(
      distinctUntilChanged(),
      catchError(error => {
        this.snackbarService.showGeneralMessage('An error occurred. Investment date was not updated.', 5);
        return of(null);
      })
    );
  }

  private setUnsavedError(isUnsaved: boolean, formControlName: string) {
    const formField = this.pageForm.get(formControlName);
    if (!formField) {
      return;
    }
    let currentError = formField.errors;
    if (isUnsaved) {
      if (!currentError) {
        currentError = {};
      }
      currentError.unsaved = true;
    } else if (currentError) {
      delete currentError.unsaved;
    }
    formField.setErrors(currentError);
  }

  private updateForm(investment: InvestmentReqRes) {
    if (this.pageForm) {
      this.displayUpdateButton$.pipe(
        take(1),
        untilComponentDestroyed(this))
        .subscribe(displayUpdateButton => {
          if (!displayUpdateButton) {
            this.pageForm.patchValue({
              estimatedAmount: this.numberPipe.transform(investment.estimatedAmount),
              totalCommitments: this.numberPipe.transform(investment.totalCommitment),
              finalAmount: this.numberPipe.transform(investment.finalAmount),
              accreditationDate: TerraUtils.forceUtcToLocal(investment.investingEntity.accreditation?.accreditedDate),
              externalRemarks: investment.externalRemarks,
              internalRemarks: investment.internalRemarks
            }, {emitEvent: false});
          }
          this.setEstimatedAmountFieldValidator(investment);
        });
    }
  }

  /**
   * The Estimated Amount field is required only when the investment is in soft circled status.
   */
  private setEstimatedAmountFieldValidator(investment: InvestmentReqRes) {
    const estimatedAmountControl = this.pageForm.get('estimatedAmount') as UntypedFormControl;

    const isAmountRequired = investment.status === InvestmentStatus.SoftCircled;

    estimatedAmountControl.setValidators(isAmountRequired ? [Validators.required, Validators.min(1)] : [Validators.min(0)]);
    estimatedAmountControl.updateValueAndValidity();
    
    const totalCommitmentControl = this.pageForm.get('totalCommitments') as UntypedFormControl;
    const currentEstimatedAmount = this.numberPipe.parse(estimatedAmountControl.value);
    totalCommitmentControl.setValidators([CustomValidators.minFormattedNumber(currentEstimatedAmount - 0.001)]);
    totalCommitmentControl.updateValueAndValidity();

  }

  private subscribeToEstimatedAmountChange() {
    this.pageForm
      .get('estimatedAmount')
      .valueChanges.pipe(
      untilComponentDestroyed(this),
      map(value => this.numberPipe.parse(value)),
      distinctUntilChanged(),
      tap(estimatedAmount => {
        if (this.investmentDetails.estimatedAmount !== estimatedAmount) {
          this.isUnsavedAmount$.next(true);
        } else {
          this.isUnsavedAmount$.next(false);
        }
      })
    )
      .subscribe();
    
    this.pageForm
      .get('totalCommitments')
      .valueChanges.pipe(
        untilComponentDestroyed(this),
        map(value => this.numberPipe.parse(value)),
        distinctUntilChanged(),
        tap(totalCommitments => {
          if (this.investmentDetails.totalCommitment !== totalCommitments) {
            this.isUnsavedTotalCommitments$.next(true);
          } else {
            this.isUnsavedTotalCommitments$.next(false);
          }
        })
      )
      .subscribe();

    this.updateEstimatedAmountAndRemarks$.pipe(untilComponentDestroyed(this),
      throttleTime(200))
      .subscribe(_ => {
        this.updateEstimatedAmountAndRemarks();
      });
  }

  private handleFinalAmountUpdate() {
    const finalAmountControl = this.fundInvestorService.pageForm.get('finalAmount');

    finalAmountControl.valueChanges.pipe(
      untilComponentDestroyed(this),
      map(val => this.numberPipe.parse(val)),
      filter(val => (val !== null && val !== this.investmentDetails.finalAmount)),
      distinctUntilChanged(),
      debounceTime(1000),
      tap(_ => this.showLoader = true),
      switchMap(finalAmounut => this.fundInvestorService.updateFinalAmount(finalAmounut)),
    )
      .subscribe(response => {
        this.showLoader = false;
      }, error => {
        this.snackbarService.showGeneralMessage('An error occurred. Amount was not updated.', 5);
        this.showLoader = false;
      });
  }

  private subscribeToRemarksChange() {
    this.pageForm.get('externalRemarks')
      .valueChanges.pipe(
      untilComponentDestroyed(this),
      tap(remarks => {
        if (remarks === '' && ['', null, undefined].includes(this.investmentDetails.externalRemarks)) {
          this.isUnsavedExternalRemarks$.next(false);
        } else if (this.investmentDetails.externalRemarks !== remarks) {
          this.isUnsavedExternalRemarks$.next(true);
        } else {
          this.isUnsavedExternalRemarks$.next(false);
        }
      })
    ).subscribe();

    this.pageForm.get('internalRemarks')
      .valueChanges.pipe(
      untilComponentDestroyed(this),
      tap(remarks => {
        if (remarks === '' && ['', null, undefined].includes(this.investmentDetails.internalRemarks)) {
          this.isUnsavedInternalRemarks$.next(false);
        } else if (this.investmentDetails.internalRemarks !== remarks) {
          this.isUnsavedInternalRemarks$.next(true);
        } else {
          this.isUnsavedInternalRemarks$.next(false);
        }
      })
    ).subscribe();
  }

  private subscribeToPaymentSettlementChange() {
    this.fundInvestorService.pageForm.get('paymentSettlementDate').valueChanges
      .pipe(
        untilComponentDestroyed(this),
        debounceTime(1000),
        filter(val => (val !== null && val !== this.investmentDetails.paymentSettlementDate)),
        tap(_ => this.showLoader = true),
        switchMap(val => this.updatePaymentSettlementDate(val)),
      ).subscribe(val => {
      this.showLoader = false;
    });
  }

  private subscribeToContributionReadOnlyModeChange() {
    this.isContributionInReadOnlyMode$
      .pipe(
        take(1),
        untilComponentDestroyed(this)
      )
      .subscribe(isContributionInReadOnlyMode => {
        if (isContributionInReadOnlyMode) {
          this.pageForm.get('estimatedAmount').disable();
        } else {
          this.pageForm.get('estimatedAmount').enable();
        }
      });
  }

  private updateEstimatedAmountAndRemarks() {
    const estimatedAmount = this.numberPipe.parse(this.pageForm.get('estimatedAmount').value);
    const totalCommitments = this.numberPipe.parse(this.pageForm.get('totalCommitments').value);
    const externalRemarks = this.pageForm.get('externalRemarks').value;
    const internalRemarks = this.pageForm.get('internalRemarks').value;
    if (!this.fundInvestorService.isValidEstimatedAmount(this.pageForm.get('estimatedAmount'))) {
      return;
    }

    this.fundInvestorService.updateEstimatedAmountAndRemarks(estimatedAmount, externalRemarks, internalRemarks, totalCommitments).pipe(
      catchError(error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          this.snackbarService.showGeneralMessage('An error occurred. Estimated amount and remarks were not updated.', 5);
          this.pageForm.get('estimatedAmount').setValue(this.numberPipe.transform(this.investmentDetails.estimatedAmount));
          this.pageForm.get('externalRemarks').setValue(this.investmentDetails.externalRemarks);
          this.pageForm.get('internalRemarks').setValue(this.investmentDetails.internalRemarks);
        }
        return of(null);
      })
    ).subscribe(updatedInvestment => {
      if (updatedInvestment !== null) {
        this.isUnsavedAmount$.next(false);
        this.isUnsavedTotalCommitments$.next(false);
        this.isUnsavedExternalRemarks$.next(false);
        this.isUnsavedInternalRemarks$.next(false);
        this.snackbarService.showGeneralMessage('Saved');
      }
    });
    
    if (Math.abs((totalCommitments ?? 0) - (this.investmentDetails.totalCommitment ?? 0)) > 0.001) {
      this.fund$.pipe(
        map(x => x.id),
        take(1),
      ).subscribe(holdingId =>
        this.investorsManagementService.saveTotals(holdingId, {
          totals: [{ investingEntityId: this.investmentDetails.investingEntityId, totalCommitmentAmount: totalCommitments }]
        }).subscribe(result => {
          this.refreshCommitmentsTrigger$.next();
        }));
    }
  }

  public issueCapitalCall() {
    this.capitalCallService.issueCapitalCall([this.investmentId]).pipe(
      take(1)
    ).subscribe(fundraising => {
        if (!!fundraising) {
          this.snackbarService.showGeneralMessage('Issue capital call succeeded');

          // this.snackbarService.showGeneralMessage('Processing capital call');
          /*        if (capitalCall.status === CapitalCallResponseStatus.Success){
                    this.snackbarService.showGeneralMessage('Processing capital call');
                  }
                  else{
                    this.snackbarService.showGeneralMessage('Failed to Issue capital call');
                  }*/
        } else {
          this.snackbarService.showGeneralMessage('Failed to Issue capital call');
        }
      },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          this.snackbarService.showGeneralMessage('Failed to Issue capital call');
        }
      });
  }
}
