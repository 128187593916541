import { Injectable } from '@angular/core';
import {debounceTime, filter, map, scan, shareReplay, switchMap, take} from 'rxjs/operators';
import {BehaviorSubject, merge, Subject} from 'rxjs';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {UntypedFormBuilder} from '@angular/forms';

import {InvestmentSearchOptions} from './InvestmentSearchOptions';
import {CapitalCallService} from '../capital-call.service';
import {TerraNumberPipe} from '../../../../../shared/pipes/TerraNumber.pipe';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { InvestmentReqRes } from 'src/app/dashboard/models/investment.model';
import TrackStatus from '../models/TrackStatus.enum';

import moment from 'moment';
import {LoggerService} from '../../../../../shared/errors/logger.service';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';

@Injectable()
export class CapitalCallInvestmentsTableService extends OnDestroyMixin {

  private _successfulSavedInvestment$: Subject<boolean> = new Subject<boolean>();
  successfulSavedInvestment$ = this._successfulSavedInvestment$.pipe(untilComponentDestroyed(this));

  private _refreshData$ = new Subject<void>();
  refreshData$ = this._refreshData$.asObservable();

  private _doneSaving$ = new Subject<number>();
  doneSaving$ = this._doneSaving$.asObservable();

  private _revertChanges$ = new Subject<number>();
  revertChanges$ = this._revertChanges$.pipe(untilComponentDestroyed(this));

  private pageSize$ = new BehaviorSubject<number>(0);
  private pageNumber$ = new BehaviorSubject<number>(0);
  private nameFilter$ = new BehaviorSubject<string>('');
  private trackStatusFilter$ = new BehaviorSubject<TrackStatus>(TrackStatus.None);
  private orderBy$ = new BehaviorSubject<string>('');
  private sortDirection$ = new BehaviorSubject<string>('asc');

  private pageSizeSource$ = this.pageSize$.pipe(map(pageSize => ({pageSize}) as InvestmentSearchOptions));
  private pageNumberSource$ = this.pageNumber$.pipe(map(pageNumber => ({pageNumber}) as InvestmentSearchOptions));
  private nameFilterSource$ = this.nameFilter$.pipe(map(filter => ({filter}) as InvestmentSearchOptions));
  private trackStatusSource$ = this.trackStatusFilter$.pipe(map(trackStatus => ({trackStatus}) as InvestmentSearchOptions));
  private orderBySource$ = this.orderBy$.pipe(map(orderBy => ({orderBy}) as InvestmentSearchOptions));
  private sortDirectionSource$ = this.sortDirection$.pipe(map(sortOrder => ({sortOrder}) as InvestmentSearchOptions));

  private searchOptionsSources$ = merge(this.pageSizeSource$,
                                        this.pageNumberSource$,
                                        this.nameFilterSource$,
                                        this.trackStatusSource$,
                                        this.orderBySource$,
                                        this.sortDirectionSource$);

  searchOptions$ = this.searchOptionsSources$.pipe(
    untilComponentDestroyed(this),
    scan((acc, value) => {
      const pageNumber = value.pageNumber === undefined ? 0 : value.pageNumber;
      return {...acc, ...value, pageNumber} as InvestmentSearchOptions;
    }, new InvestmentSearchOptions()),
    debounceTime(10),
    shareReplay(1)
  );

  constructor(private fb: UntypedFormBuilder,
              private capitalCallService: CapitalCallService,
              private numberPipe: TerraNumberPipe,
              private snackbarService: SnackbarService,
              private logger: LoggerService,
              private utilsService: UtilsService) {
    super();
  }

  revertChanges(investedId: number){
    this._revertChanges$.next(investedId);
  }

  refresh() {
    this._refreshData$.next();
  }

  updatePageSize(pageSize: number) {
    this.pageSize$.next(pageSize);
  }

  updatePageNumber(pageNumber: number) {
    this.pageNumber$.next(pageNumber);
  }

  updateNameFilter(name: string) {
    this.nameFilter$.next(name);
  }

  updateTrackStatusFilter(trackStatus: TrackStatus) {
    this.trackStatusFilter$.next(trackStatus);
  }

  sort(orderBy: string, sortOrder: string) {
    this.orderBy$.next(orderBy);
    this.sortDirection$.next(sortOrder);
  }

  /**
   * validate data and save change call from fromGroup value change
   * @param data Investment's updated value
   */
   saveChanges(data: InvestmentReqRes){
     this.capitalCallService.investments$.pipe(
      take(1),
      map(investments => investments.find(inv => inv.id === data.id)),
      filter(investment => this.isValidDateToSave(investment, data)),
      switchMap(_ => this.capitalCallService.updateInvestment(data))
    ).subscribe(_ => {
      this.snackbarService.showGeneralMessage('Saved');
      this._successfulSavedInvestment$.next(true);
      this._doneSaving$.next(data.id);
    },
    error => {
      if (error instanceof BaseResponseDto) {
        this.utilsService.alertErrorMessage(error);
      } else {
        this.snackbarService.showGeneralMessage('Save failed');
        this.logger.error(`Error saving investment's changes to server`, error);
      }

      this._successfulSavedInvestment$.next(false);
      this.revertChanges(data.id);
      this._doneSaving$.next(data.id);
    });
  }

  /**
   * Checks if investment's data is valid to save
   * @param originalInvestment Original investment data before the update
   * @param dataToSave Investment's data
   */
  private isValidDateToSave(originalInvestment: InvestmentReqRes, dataToSave: InvestmentReqRes): boolean {
    const today = moment().startOf('day').utc();
    const paymentSettlementDateToSave = !!dataToSave.paymentSettlementDate ? moment(dataToSave.paymentSettlementDate).utc() : null;
    const transferDateToSave = !!dataToSave.transferDate ? moment(dataToSave.transferDate).utc() : null;
    const isValid = dataToSave?.paymentRequestDocuments?.length <= 9 &&
      (!transferDateToSave || transferDateToSave.isSameOrBefore(today)) &&
      (!paymentSettlementDateToSave || paymentSettlementDateToSave.isSameOrBefore(today));
    if (!isValid || (!paymentSettlementDateToSave && dataToSave.status === InvestmentStatus.Invested)){
      this.snackbarService.showGeneralMessage('Save failed');
      this.revertChanges(dataToSave.id);
      return false;
    }

    return isValid;
  }
}
