import {Injectable, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {withLatestFrom, switchMap, take} from 'rxjs/operators';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {GpFundService} from './gp-fund.service';
import {GpInvestmentDataService} from 'src/app/services/gp/gp-investment-data.service';
import {MatDialogConfig, MatDialog} from '@angular/material/dialog';
import {EditFundraisingDialogContext, FundraisingDetailsInterface} from '../../shared/holding/fundraising/fundraisings-tab/edit-fundraising-dialog.context';
import {EditFundraisingDialogComponent} from '../../shared/holding/fundraising/edit-fundraising-dialog/edit-fundraising-dialog.component';
import {FundraisingReqRes} from '../../shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {GpFundraisingDataService} from 'src/app/services/gp/gp-fundraising-data.service';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {GpFundDialogContext} from './Fund.context';
import {PartialEditFundFundraisingDialogComponent} from './fund-management-status/fund-fundraisings/partial-edit-fund-fundraising-dialog/partial-edit-fund-fundraising-dialog.component';
import {AppQuery} from 'src/app/state';
import {HoldingFundraisingService} from '../../shared/holding/fundraising/holding-fundraising.service';
import {GpHoldingService} from '../../shared/holding/gp-holding.service';
import {RoutingService} from 'src/app/services/routing.service';
import {FundAndFundraisingReqRes} from '../components/create-fund/FundAndFundraisingRequest';
import {UserService} from 'src/app/services/shared/user.service';
import {PermissionService} from 'src/app/permission/permission.service';

@Injectable()
export class FundFundraisingService extends HoldingFundraisingService implements FundraisingDetailsInterface {
  fund$ = this.gpFundService.holding$;
  fundraising$ = this.gpFundService.fundraising$;

  constructor(
    private route: ActivatedRoute,
    private appQuery: AppQuery,
    private gpholdingService: GpHoldingService,
    private gpFundraisingDataService: GpFundraisingDataService,
    private gpFundService: GpFundService,
    private gpInvestmentDataService: GpInvestmentDataService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private vcr: ViewContainerRef,
    private router: Router,
    private routingService: RoutingService,
    private permissionService: PermissionService
  ) {
    super(route, appQuery, gpholdingService, gpFundraisingDataService, gpInvestmentDataService, permissionService);

    this.updatedInvestment$.pipe(
      untilComponentDestroyed(this),
      withLatestFrom(this.gpFundService.fundraising$)
    )
      .subscribe(([investment, fundraisingDetails]) => {
        // Find the investment that was updated, and replace it in the fund details:
        const replaceIndex = fundraisingDetails.investments.findIndex(i => i.id === investment.id);
        // New investment - add to the end of the list:
        if (replaceIndex === -1) {
          fundraisingDetails.investments = [...fundraisingDetails.investments, investment];
        } else {
          // update existing investment:
          fundraisingDetails.investments = [
            ...fundraisingDetails.investments.slice(0, replaceIndex),
            investment,
            ...fundraisingDetails.investments.slice(replaceIndex + 1)
          ];
        }
        fundraisingDetails.investments = this.sortInvestments(fundraisingDetails.investments);
        this.gpFundService.changeFundraisingOnly(fundraisingDetails);
      });

    // Refresh relevant parts of the page once an investment was deleted:
    this.deletedInvestmentId$.pipe(
      untilComponentDestroyed(this),
      withLatestFrom(this.gpFundService.fundraising$)
    )
      .subscribe(([investmentId, fundraisingDetails]) => {
        const index = fundraisingDetails.investments.findIndex(x => x.id === investmentId);
        if (index !== -1) {
          fundraisingDetails.investments.splice(index, 1);
          this.gpFundService.changeFundraisingOnly(fundraisingDetails);
        }
      });
  }

  showEditFundraising() {
    combineLatest([this.fundraising$, this.fund$]).pipe(
      take(1),
      switchMap(([fundraising, fund]) => {
        const config = new MatDialogConfig<EditFundraisingDialogContext>();
        config.panelClass = 'edit-fundraising-dialog';
        config.disableClose = true;
        config.closeOnNavigation = true;
        config.autoFocus = false;
        config.viewContainerRef = this.vcr;

        const context = new EditFundraisingDialogContext(fundraising, fund, fund.status, HoldingDiscriminator.Fund, this);
        config.data = context;

        return this.dialog.open(EditFundraisingDialogComponent, config).afterClosed();
      }),
      untilComponentDestroyed(this)
    )
      .subscribe((updatedFundraising: FundraisingReqRes) => {
        if (updatedFundraising) {
          this.snackbarService.showGeneralMessage('Saved changes');
        }
      });
  }

  public showMoveFundraisingToComplete() {
    this.showPartialEditFundraising();
  }

  private showPartialEditFundraising() {
    combineLatest([this.fund$, this.fundraising$]).pipe(
      take(1),
      switchMap(([fund, fundraising]) => {
        const config = new MatDialogConfig<GpFundDialogContext>();
        config.panelClass = 'edit-fundraising-dialog';
        config.disableClose = true;
        config.autoFocus = false;

        const context = new GpFundDialogContext(this.gpFundService, fund, fundraising, null);
        config.data = context;

        return this.dialog.open(PartialEditFundFundraisingDialogComponent, config).afterClosed();
      }),
      untilComponentDestroyed(this)
    )
      .subscribe((updatedFund: FundAndFundraisingReqRes) => {
        if (updatedFund) {
          this.snackbarService.showGeneralMessage('The contribution is complete. Congrats!');
          this.router.navigateByUrl(this.routingService.fundFundraising(updatedFund.fund.id, updatedFund.fundraising.id));
        }
      });
  }
}
