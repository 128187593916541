import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';

export enum ConfirmInvestorsNotificationResult {
  Notify = 1,
  SkipNotification = 2
}

@Component({
  selector: 'terra-confirm-investors-notification-dialog',
  templateUrl: './confirm-investors-notification-dialog.component.html',
  styleUrls: ['./confirm-investors-notification-dialog.component.scss']
})
export class ConfirmInvestorsNotificationDialogComponent implements OnInit {

  ConfirmInvestorsNotificationResult = ConfirmInvestorsNotificationResult;

  get holding() {
    return HoldingDiscriminator.toString(this.holdingDiscriminator).toLowerCase();
  }

  constructor(
    public dialogRef: MatDialogRef<ConfirmInvestorsNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public holdingDiscriminator: HoldingDiscriminator) { }

  ngOnInit() {
  }

  action(option: ConfirmInvestorsNotificationResult) {
    this.dialogRef.close(option);
  }
}
