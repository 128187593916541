<ng-container *ngIf="holding$ | async as holding">
  <!-- Images -->
  <div class="terra-card images-card">
    <terra-holding-images
      [singleLineAddress]="true"
      [location]="holding.location"
      [holdingFiles]="holding.attachments"
    />
  </div>

  <!-- Holding details box -->
  <terra-holding-info-summary
    *ngIf="displayBankPlaceholder"
    class="cre terra-card"
  />

  <!-- CRE Bank Account -->
  <terra-holding-cre-bank-account *ngIf="displayCreCard" class="cre terra-card" />
  <!--  <terra-holding-cre-bank-account-placeholder *ngIf="displayBankPlaceholder" class="cre terra-card"></terra-holding-cre-bank-account-placeholder>-->

  <!-- Holding Actions Links -->
  <terra-holding-actions class="terra-card holding-actions" />
  <div
    class="terra-card equity-warning"
    *ngIf="shouldShowContributionWarningMessage$ | async"
  >
    <mat-icon>info_outline</mat-icon>
    <div class="equity-warning-message">
      Notice! The Total Investor Equity does not equal sum of Total
      Contributions. This could lead to inaccurate ownership percentages and
      affect pro-rata calculations. Please correct this by updating the
      <span
        class="equity-warning-message-clickable"
        (click)="goToEditHoldingInvestmentDetails()"
        >Investment Details</span
      >
      or by adjusting the
      <span class="equity-warning-message-clickable" routerLink="contributions"
        >Contribution Amounts</span
      >.
    </div>
  </div>
  <div
  class="terra-card equity-warning"
  *ngIf="shouldShowCommitmentWarningMessage$ | async"
>
  <mat-icon>info_outline</mat-icon>
  <div class="equity-warning-message">
    Notice! The Total Investor Equity does not equal sum of Total
    Commitments. This could lead to inaccurate ownership percentages and
    affect pro-rata calculations. Please correct this by updating the
    <span
      class="equity-warning-message-clickable"
      (click)="goToEditHoldingInvestmentDetails()"
      >Investment Details</span
    >
    or by adjusting the
    <span class="equity-warning-message-clickable" routerLink="contributions"
      >Committed Amounts</span
    >.
  </div>
</div>
</ng-container>
