import { Component, OnInit } from '@angular/core';
 
import { map } from 'rxjs/operators';
import { AreaUnit } from 'src/app/shared/enums/AreaUnit.enum';
import { InterestType } from 'src/app/shared/enums/InterestType.enum';
import { InvestmentType } from 'src/app/shared/enums/InvestmentType.enum';
import { LoanType } from 'src/app/shared/enums/LoanType.enum';
import { OwnershipCalculationType } from '../../../../../shared/enums/OwnershipCalculationType.enum';
import { GpAssetService } from "../../../gp-asset/gp-asset.service";
import { EditFundAssetStepBase, EditFundAssetTabNumber } from '../EditFundAssetStepBaseAndInterface';
import { EditFundAssetService } from '../edit-fund-asset.service';

@Component({
  selector: 'terra-edit-fund-asset-tab-investment-details',
  templateUrl: './edit-fund-asset-tab-investment-details.component.html',
  styleUrls: ['./edit-fund-asset-tab-investment-details.component.scss']
})
export class EditFundAssetTabInvestmentDetailsComponent extends EditFundAssetStepBase implements OnInit {
  readonly TAB_NUMBER: EditFundAssetTabNumber = 2;

  InvestmentType = InvestmentType;
  LoanType = LoanType;
  InterestType = InterestType;
  AreaUnit = AreaUnit;

  LoanTypesList = LoanType.listAll();
  InterestTypesList = InterestType.listAll();

  currency$ = this.gpAssetService.holding$.pipe(map(asset => asset.initialCurrency));

  closingDateStartAt = new Date();
  closingDateMinimumDate = new Date('1970-01-01T12:00');

  maturityDateStartAt = new Date();
  maturityDateMinimumDate = new Date('1970-01-01T12:00');

  //selectedInvestmentType = this.editFundAssetService.selectedInvestmentType;

  constructor(
    private editFundAssetService: EditFundAssetService,
    private gpAssetService: GpAssetService,
  ) {
    super(editFundAssetService);
  }

  ngOnInit() {
  }

  protected readonly OwnershipCalculationType = OwnershipCalculationType;
}
