import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { AbstractRestService } from '../../shared/types/AbstractRestService';
import { GpPricingTier } from 'src/app/dashboard/shared/holding/investor/send-payment-request-dialog/gpPricingTier.model';
import { HttpService } from '../http.service';

@Injectable()
export class GpPricingTierDataService extends AbstractRestService<GpPricingTier, GpPricingTier> {
  protected baseEndpoint$ = of('gpPricingTier');
  constructor(http: HttpService) {
    super(http);
  }
}
