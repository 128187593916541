import StorageObjectPermissionLevel from 'src/app/shared/enums/StorageObjectPermissionLevel.enum';
import { IdValue } from 'src/app/shared/models/IdValue.model';

export class StorageObjectShareReqRes {
  public storageObjectIds: number[];
  public contactPermissions: IdValue<StorageObjectPermissionLevel>[];
  public isPublic: boolean;
  public sendShareEmails: boolean;
  public personalMessage: string;
  public holdingId: number;
}
