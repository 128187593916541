import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {combineLatest, EMPTY, Observable} from 'rxjs';
import {catchError, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {GpFundDataService} from '../../../../../services/gp/gp-fund-data.service';
import {GpHoldingService} from '../../gp-holding.service';
import FeatureFlags from '../../../../../account/my-account/model/FeatureFlags.enum';
import {UserService} from '../../../../../services/shared/user.service';
import {TerraNumberPipe} from '../../../../../shared/pipes/TerraNumber.pipe';
import {HoldingFundraisingService} from '../../fundraising/holding-fundraising.service';
import {GpInvestmentDataService} from '../../../../../services/gp/gp-investment-data.service';
import {SnackbarService} from '../../../../../services/snackbar.service';
import {RoutingService} from '../../../../../services/routing.service';
import HoldingDiscriminator from '../../../../../shared/enums/HoldingDiscriminator.enum';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';

@Component({
  selector: 'terra-participating-fund-dialog',
  templateUrl: './participating-fund-dialog.component.html',
  styleUrls: ['./participating-fund-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipatingFundDialogComponent implements OnInit {
  isFundFeatureActive$ = this.userService.userHasFeatureFlag(FeatureFlags.Fund);
  currency$ = this.holdingService.holding$.pipe(map(holding => holding.initialCurrency));
  funds$ = combineLatest([this.fundDataService.getFundsForInvestment(), this.holdingService.holding$]).pipe(
    map(([allFunds, currentHolding]) => allFunds.filter(f => f.id !== currentHolding.id)),
    catchError(error => {
      if (error instanceof BaseResponseDto) {
        this.utilsService.alertErrorMessage(error);
        this.dialogRef.close();
      }
      return EMPTY;
    }),
    shareReplay(1)
  );
  hasNoFunds$ = combineLatest([this.funds$, this.isFundFeatureActive$, this.holdingService.holdingId$]).pipe(
    map(([funds, hasFundFeature, holdingId]) =>
      funds.filter(f => f.id !== holdingId).length === 0 && hasFundFeature
    ),
    shareReplay(1)
  );
  isFundSelected$: Observable<boolean>;

  fundInvestmentForm: UntypedFormGroup;

  constructor(private dialogRef: MatDialogRef<any>,
              private fundDataService: GpFundDataService,
              private holdingService: GpHoldingService,
              private userService: UserService,
              private fb: UntypedFormBuilder,
              private numberPipe: TerraNumberPipe,
              private fundraisingService: HoldingFundraisingService,
              private investmentDataService: GpInvestmentDataService,
              private snackbarService: SnackbarService,
              public routingService: RoutingService,
              private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Gets holding discriminator as lower cased string
   */
  get holdingDiscriminatorString$(): Observable<string> {
    return this.holdingService.holding$.pipe(
      take(1),
      map(holding => HoldingDiscriminator.toString(holding.discriminator, true))
    );
  }

  /**
   * Adds fund as investor
   */
  public addFundInvestor() {
    this.fundInvestmentForm.markAllAsTouched();
    if (!this.fundInvestmentForm.valid) {
      return;
    }
    const investingEntityId = this.fundInvestmentForm.value.fund.investingEntityId;
    const amount = this.numberPipe.parse(this.fundInvestmentForm.value.amount);

    this.addSingleFundInvestor(investingEntityId, amount);
  }

  /**
   * Adds an investment of a single investing entity
   * @param investingEntityId Investing entity id
   * @param investedAmount amount invested
   * @private
   */
  private addSingleFundInvestor(investingEntityId: number, investedAmount: number) {
    combineLatest([this.holdingService.holdingId$, this.fundraisingService.fundraisingId$])
      .pipe(
        take(1),
        switchMap(([assetId, fundraisingId]) => {
          return this.investmentDataService.createInvestmentFromFundInvestingEntity(assetId, fundraisingId, investingEntityId, investedAmount);
        }),
        tap(investment => {
          this.fundraisingService.updatedInvestment$.next(investment);
        })
      )
      .subscribe(
        addedInvestment => {
          this.fundInvestmentForm.reset();
          this.investorsAddedMessage(1);
          this.dialogRef.close();
        },
        err => {
          if (err instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(err);
          }
          console.log('Investment was not added', err);
        }
      );
  }

  /**
   * Shows investor added message
   * @param numberOfInvestors Number of investors added
   */
  private investorsAddedMessage(numberOfInvestors: number) {
    const addedMessage = numberOfInvestors === 1 ? 'Investor added' : `${numberOfInvestors} investors added`;
    this.snackbarService.showGeneralMessage(addedMessage, 3, 'flow-added-investors-success');
  }

  /**
   * Inits a new form group
   */
  private initForm() {
    this.isFundFeatureActive$.pipe(take(1)).subscribe(active => {
      if (!active) {
        this.fundInvestmentForm = this.fb.group({
          fund: [{value: null, disabled: true}],
          amount: [{value: null, disabled: true}]
        });
      } else {
        this.fundInvestmentForm = this.fb.group({
          fund: null,
          amount: [null, Validators.required]
        });
      }

      this.isFundSelected$ = this.fundInvestmentForm.valueChanges.pipe(map(fundForm => !!fundForm.fund));
      this.fundInvestmentForm.markAsUntouched();
    });
  }
}
