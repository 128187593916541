import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import memo from 'memo-decorator';

import {GpFundService} from '../../gp-fund.service';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {RoutingService} from 'src/app/services/routing.service';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {GpHoldingListItemReqRes} from 'src/app/dashboard/shared/holding/GpHoldingListItem.model';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {AppService} from 'src/app/state';
import {Sort} from '@angular/material/sort';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';

@Component({
  selector: 'terra-fund-portfolio-tab',
  templateUrl: './fund-portfolio-tab.component.html',
  styleUrls: ['./fund-portfolio-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FundPortfolioTabComponent implements OnInit {

  // consts
  HoldingType = HoldingType;
  HoldingDiscriminator = HoldingDiscriminator;
  HoldingStatus = HoldingStatus;

  emptyState$ = new BehaviorSubject(false);
  displayedColumns: string[] = ['name', 'type', 'status', 'exposure', 'investedAmount', 'investedAmount%', 'action'];

  fundId$ = this.gpFundService.holdingId$;

  fundTotalInvestorEquity$ = this.gpFundService.holding$.pipe(map(f => f.totalInvestorEquity));
  fundName$ = this.gpFundService.holding$.pipe(map(f => f.name));

  holdings$ = this.gpFundService.fundHoldings$.pipe(
    tap(holdings => {
      this.emptyState$.next(holdings.length === 0);
    }),
    share()
  );

  isRealized$ = this.gpFundService.holding$.pipe(map(fund=> fund.status == HoldingStatus.Realized));

  searchOptions$ = this.gpFundService.searchOptionsPortfolio$;

  constructor(private gpFundService: GpFundService,
              public routingService: RoutingService,
              private snackbarService: SnackbarService,
              private appService: AppService,
              private utilsService: UtilsService) { }

  ngOnInit() {
  }

  delete(holding: GpHoldingListItemReqRes) {
    if (!this.canDeleteAsset(holding)) {
      return;
    }
    this.gpFundService.deleteFundAsset(holding).subscribe(
      () => {
        this.snackbarService.showGeneralMessage(`${holding.name} deleted`);
      },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          this.snackbarService.showGeneralMessage(`Couldn't delete ${holding.name}`);
        }
      });
  }

  canDeleteAsset(holding: GpHoldingListItemReqRes) {
    return holding.discriminator === HoldingDiscriminator.Asset && holding.isDeletable;
  }


  @memo({ resolver: (...args: GpHoldingListItemReqRes[]) => args[0].id })
  getHoldingLinkPath(holding: GpHoldingListItemReqRes) {
    switch (holding.discriminator) {
      case HoldingDiscriminator.Asset:
        return this.routingService.gpAssetPage(holding.id);
      case HoldingDiscriminator.Fund:
        return this.routingService.gpFundPage(holding.id);
      default:
        console.log('Incorrect Holding Discriminator for holding:', holding);
        return null;
    }
  }

  getCreateFundAssetRoute(fundId: number) {
    return this.routingService.createFundAsset(fundId);
  }

  sortData(sort: Sort) {
    if (!sort.active || !sort.direction) {
      return;
    }

    this.appService.updateFundsManagingPortfolioSortState({ orderBy: sort.active, direction: sort.direction === 'desc' ? 'desc' : 'asc' });
  }

  public getFundAssetStatus(status: HoldingStatus): string {
    if (status === HoldingStatus.FundAsset) {
      return 'New';
    }

    return HoldingStatus.toString(status);
  }
}
