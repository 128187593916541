<div class="flex-container">
    <div>
        <span>
          <h2>
          <strong>Invested: {{ totalInvested$ | async }}</strong>
          of
          {{ totalInvestors$ | async }}</h2>
        </span>
      </div>
    <terra-fundraising-investors-statistics-chart></terra-fundraising-investors-statistics-chart>
</div>
