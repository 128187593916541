<ng-container *ngIf="{
  isContributionInReadOnlyMode: isContributionInReadOnlyMode$ | async,
  isUpdatingStatus: isUpdatingStatus$ | async,
  fundraisingDetails: fundraisingDetails$ | async,
  holding: asset$ | async
  } as data">
  <terra-loader *ngIf="showLoader || data.isUpdatingStatus; else formElement"></terra-loader>
  <ng-template #formElement>
    <form [formGroup]="pageForm" *ngIf="investmentDetails && !data.isUpdatingStatus"
      (keydown.enter)="$event.preventDefault()">

      <a *ngIf="investmentDetails.status === InvestmentStatus.Potential || investmentDetails.status === InvestmentStatus.Declined"
        (click)="deleteInvestment()" class="light link remove-investor-button">
        Remove investor
      </a>

      <!-- For in progress fundraising: -->
      <ng-container *ngIf="data.fundraisingDetails.status !== FundraisingStatus.Completed">
        <terra-accreditation [onlyDatePicker]="false || providerAccreditationFeatureOff$ | async" [autoSaveDate]="true"
          [holdingId]="data.holding.id" [accreditationDateControl]="pageForm.get('accreditationDate')"
          [investingEntity]="investmentDetails.investingEntity"></terra-accreditation>

        <p class="final-amount-text"
          *ngIf="investmentDetails.status === InvestmentStatus.Signed || investmentDetails.status === InvestmentStatus.Invested">
          <ng-container *ngIf="investmentDetails.status === InvestmentStatus.Signed">Committed to:</ng-container>
          <ng-container *ngIf="investmentDetails.status === InvestmentStatus.Invested">Funds received:</ng-container>
          {{ investmentDetails.finalAmount | number }} {{ investmentDetails.currency.iso }}
        </p>
      </ng-container>

      <terra-asset-investment-status-visual [readOnlyMode]="data.isContributionInReadOnlyMode"
        [investment]="investmentDetails" (validateForm)="validateForm()">
      </terra-asset-investment-status-visual>
      <br>
      <div class="remove-message" *ngIf="showRemoveMessage">
        To remove investors, please mark them as Declined and then remove them.
      </div>

      <!-- For completed fundraisings: -->
      <ng-container *ngIf="data.fundraisingDetails.status === FundraisingStatus.Completed">
        <terra-accreditation [onlyDatePicker]="false || providerAccreditationFeatureOff$ | async" [autoSaveDate]="true"
          [accreditationDateControl]="pageForm.get('accreditationDate')"
          [investingEntity]="investmentDetails.investingEntity"></terra-accreditation>
        <div class="top-form" *ngIf="investmentDetails.status === InvestmentStatus.Invested">
          <!-- If there is a real order, it's not editable: -->
          <span *ngIf="investmentDetails.isOrderCreated">
            Funds received: {{ investmentDetails.finalAmount | number }} {{ investmentDetails.currency.iso }}
          </span>

          <!-- If there is no order, the amount and date are editable: -->
          <ng-container *ngIf="!investmentDetails.isOrderCreated">
            <div class="final-amount-edit">
              <label class="text-input-label">Funds received:</label>
              <mat-form-field appearance="outline">
                <input type="tel" matInput id="txtFinalAmount" terraFormattedNumber formControlName="finalAmount"
                  class="lo_sensitive" />
                <span matSuffix>{{investmentDetails.currency.iso}}</span>
              </mat-form-field>
            </div>

            <div class="investment-date-edit">
              <terra-custom-utc-date-picker id="txtPaymentSettlementDate" formControlName="paymentSettlementDate"
                label="Investment date:" placeholder="Investment date"></terra-custom-utc-date-picker>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <div class="form-grid terra-error-style">
        <!-- Estimated / Soft Circled Amount -->
        <div class="terra-form-field-wrapper half-width"
          *ngIf="investmentDetails.status === InvestmentStatus.Potential || investmentDetails.status === InvestmentStatus.SoftCircled">
          <label for="txtEstimatedAmount" class="text-input-label half-width">
            {{ investmentDetails.status === InvestmentStatus.Potential ? 'Estimated investment amount' : 'Amount soft
            circled' }}
            in
            {{ investmentDetails.currency.iso }}
          </label>
          <mat-form-field appearance="outline" class="half-width" *ngIf="{ isUnsaved: isUnsavedAmount$ | async} as vm">
            <input type="tel" matInput id="txtEstimatedAmount" terraFormattedNumber formControlName="estimatedAmount"
              autocomplete="amount" class="lo_sensitive" (keyup.enter)="updateEstimatedAmountAndRemarks$.next()"
              (focusout)="setUnsavedErrorIfRequired(vm.isUnsaved, 'estimatedAmount')" />
            <mat-error *ngIf="pageForm.get('estimatedAmount').hasError('required') else unsaved">
              Required
            </mat-error>
            <mat-error *ngIf="pageForm.get('estimatedAmount').hasError('min')">
              Amount must be greater than zero
            </mat-error>
            <ng-template #unsaved>
              <mat-error
                *ngIf="!pageForm.get('estimatedAmount').hasError('min') && pageForm.get('estimatedAmount').hasError('unsaved')">
                Please save the amount
              </mat-error>
            </ng-template>
          </mat-form-field>
        </div>
        <div class="terra-form-field-wrapper half-width"
          *ngIf="hasCommitments && (investmentDetails.status === InvestmentStatus.Potential || investmentDetails.status === InvestmentStatus.SoftCircled)">
          <!-- total Commitments-->
          <label for="txtTotalCommitments" class="text-input-label half-width">
            Total commitment amount in {{ investmentDetails.currency.iso }}
          </label>
          <mat-form-field appearance="outline" class="half-width"
            *ngIf="{ isUnsaved: isUnsavedTotalCommitments$ | async} as vm">
            <input type="tel" matInput id="txtTotalCommitments" terraFormattedNumber formControlName="totalCommitments"
              autocomplete="amount" class="lo_sensitive" (keyup.enter)="updateEstimatedAmountAndRemarks$.next()"
              (focusout)="setUnsavedErrorIfRequired(vm.isUnsaved, 'totalCommitments')" />
            <mat-error
              *ngIf="!pageForm.get('totalCommitments').hasError('minFormattedNumber') && pageForm.get('totalCommitments').hasError('unsaved')">
              Please save the amount
            </mat-error>
            <mat-error *ngIf="pageForm.get('totalCommitments').hasError('minFormattedNumber')" class="field-warning">
              Total commitment amount is lower than the contribution
            </mat-error>
          </mat-form-field>
        </div>

        <!--  External Remarks  -->
        <div class="terra-form-field-wrapper full-width"
          *ngIf="investmentDetails.status === InvestmentStatus.Potential || investmentDetails.status === InvestmentStatus.SoftCircled">
          <label *ngIf="investmentDetails.status === InvestmentStatus.SoftCircled" for="txtInvestmentExternalRemarks"
            class="terra-form-field-wrapper full-width text-input-label">
            External Remarks
          </label>
          <mat-form-field
            *ngIf="investmentDetails.status === InvestmentStatus.SoftCircled && { isUnsaved: isUnsavedExternalRemarks$ | async} as vm"
            appearance="outline">
            <textarea matInput rows="2" id="txtInvestmentExternalRemarks" formControlName="externalRemarks"
              class="lo_sensitive remarks"
              placeholder="Add external remarks about this investment here (Shift + Enter for a new line)"
              (focusout)="setUnsavedErrorIfRequired(vm.isUnsaved, 'externalRemarks')">
            </textarea>
            <mat-error *ngIf="pageForm.get('externalRemarks').hasError('unsaved')">
              Please save the remarks
            </mat-error>
          </mat-form-field>
          <!--  Internal Remarks  -->
          <label *ngIf="investmentDetails.status === InvestmentStatus.SoftCircled" for="txtInvestmentInternalRemarks"
            class="terra-form-field-wrapper full-width text-input-label">
            Internal Remarks
          </label>
          <mat-form-field
            *ngIf="investmentDetails.status === InvestmentStatus.SoftCircled && { isUnsaved: isUnsavedInternalRemarks$ | async} as vm"
            appearance="outline">
            <textarea matInput rows="2" id="txtInvestmentInternalRemarks" formControlName="internalRemarks"
              class="lo_sensitive remarks"
              placeholder="Add internal remarks about this investment here (Shift + Enter for a new line)"
              (focusout)="setUnsavedErrorIfRequired(vm.isUnsaved, 'internalRemarks')">
            </textarea>
            <mat-error *ngIf="pageForm.get('internalRemarks').hasError('unsaved')">
              Please save the remarks
            </mat-error>
          </mat-form-field>
          <ng-container *ngIf="displayUpdateButton$ | async">
            <button mat-flat-button color="primary" (click)="updateEstimatedAmountAndRemarks$.next()"
              class="btn-update-amount tall-button">
              Update
            </button>
            <button type="button" mat-button (click)="revertEstimatedAmountAndRemarksChanges()"
              class="btn-revert-update-amount tall-button">
              Revert
            </button>
          </ng-container>
        </div>
      </div>

      <!-- Share asset - Send marketing deck  -->
      <terra-share-asset [isHoldingPrivate]="(asset$ | async).isPrivate" [investment]="investmentDetails"
        [marketingDeck]="marketingDeck(fundraisingDetails$ | async)" [assetName]="(asset$ | async).name"
        *ngIf="investmentDetails.status === InvestmentStatus.Potential && !data.isContributionInReadOnlyMode">
      </terra-share-asset>

      <terra-asset-sign-agreement-block [isHoldingPrivate]="(asset$ | async).isPrivate" [investment]="investmentDetails"
        *ngIf="investmentDetails.status === InvestmentStatus.SoftCircled && !data.isContributionInReadOnlyMode"
        (validateForm)="validateForm()">
      </terra-asset-sign-agreement-block>

      <section class="payment-document-section" *ngIf="((savedDocumentsLength$ | async) > 0 && investmentDetails.status === InvestmentStatus.Invested) ||
                                                       investmentDetails.status === InvestmentStatus.Signed">
        <ng-container>
          <terra-payment-request-document-block [savedDocumentsLength]="savedDocumentsLength$ | async"
            [investmentId]="investmentId" holdingType="Asset">
          </terra-payment-request-document-block>
        </ng-container>
      </section>

      <section class="agreement-section"
        *ngIf="((investmentDetails.status === InvestmentStatus.Signed || investmentDetails.status === InvestmentStatus.Invested) &&
                                                  !investmentDetails.isOrderCreated && !investmentDetails.agreementMetaFileLinkId &&
                                                  investmentDetails.agreementSignPlatform !== SignPlatform.HelloSign)
                                                  ||
                                                  (investmentDetails.status === InvestmentStatus.Signed || investmentDetails.status === InvestmentStatus.Invested)">
        <!-- If the agreement was signed externally -->
        <terra-holding-upload-signed-agreement-block-component id="upload-agreement" holdingType="Asset"
          [holdingId]="(asset$ | async)?.id" [investment]="investmentDetails"
          [readOnlyMode]="data.isContributionInReadOnlyMode">
        </terra-holding-upload-signed-agreement-block-component>
      </section>

      <section class="signed-invested-section"
        *ngIf="investmentDetails.status === InvestmentStatus.Signed || investmentDetails.status === InvestmentStatus.Invested">
        <section id="signed-invested-section-left-side">
          <!-- Send payment request -->
          <terra-holding-send-payment-request-block
            *ngIf="investmentDetails.status === InvestmentStatus.Signed && !data.isContributionInReadOnlyMode"
            id="send-payment-request" (issueCapitalCall)="issueCapitalCall()" [investment]="investmentDetails"
            [isHoldingPrivate]="(asset$ | async).isPrivate" [fundraisingDetails]="fundraisingDetails$ | async"
            [holdingAttachments]="(asset$ | async).attachments" [discriminator]="HoldingDiscriminator.Asset">
          </terra-holding-send-payment-request-block>
          <terra-asset-download-transfer-confirmation-block
            *ngIf="investmentDetails.status === InvestmentStatus.Invested && investmentDetails.paymentSettlementPlatform !== PaymentPlatform.Internal"
            id="download-confirmation" [investment]="investmentDetails"
            [bankAccountNickname]="fundraisingBankAccountName$ | async">
          </terra-asset-download-transfer-confirmation-block>
        </section>
        <section id="signed-invested-section-right-side">
          <!-- Payment request status -->
          <terra-payment-request-status-block id="payment-request-status" [investment]="investmentDetails"
            [bankAccountNickname]="fundraisingBankAccountName$ | async"></terra-payment-request-status-block>
        </section>
      </section>
    </form>
  </ng-template>
</ng-container>