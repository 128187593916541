import {Injectable} from '@angular/core';
import {UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray, AbstractControl} from '@angular/forms';
import {MatDialogRef, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {BehaviorSubject, of, Observable, timer, throwError, EMPTY} from 'rxjs';
import {map, switchMap, finalize, catchError, take, tap} from 'rxjs/operators';

import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {ClientBankAccountResponseBasic} from 'src/app/dashboard/models/bankAccount.model';
import {GpFundReqRes} from 'src/app/dashboard/funds/GpFundReqRes.model';
import {FundraisingReqRes} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';
import {LocationDetailsResponse} from 'src/app/shared/models/LocationDetailsResponse.model';
import {GpFundDataService} from 'src/app/services/gp/gp-fund-data.service';
import {ProjectedPerformance} from 'src/app/shared/models/ProjectedPerformance.model';
import {LenderInformation} from 'src/app/shared/models/LenderInformation.model';
import {HoldingFileReqRes} from 'src/app/dashboard/shared/holding/HoldingFileReqRes.model';
import {ProjectBudget} from 'src/app/shared/models/ProjectBudget.model';
import {FundAndFundraisingReqRes} from '../create-fund/FundAndFundraisingRequest';
import {GpFundDialogContext} from 'src/app/dashboard/funds/gp-fund/Fund.context';
import {EditFundTabNumber} from './EditFundStepBaseAndInterface';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {EditFundDialogComponent} from './edit-fund-dialog.component';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {InvestmentSecurityType} from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {CustomValidators} from 'src/app/shared/validators/custom.validators';
import {GpHoldingDataService} from 'src/app/services/gp/gp-holding-data.service';
import {
  ConfirmInvestorsNotificationDialogComponent,
  ConfirmInvestorsNotificationResult
} from 'src/app/dashboard/shared/holding/confirm-investors-notification-dialog/confirm-investors-notification-dialog.component';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import FundInvestmentStrategyType from '../../../../shared/enums/FundInvestmentStrategyType.enum';
import {AddressFields} from '../../../../shared/components/address-form/address-form.settings';
import {CreateEditHoldingService} from '../../../shared/holding/create-edit-holding.service';
import {ResourceService} from '../../../../services/resource.service';
import {HoldingFundraisingService} from '../../../shared/holding/fundraising/holding-fundraising.service';
import {CapitalCallService} from '../../../shared/holding/capital-call/capital-call.service';
import {OpportunityDto} from 'src/app/dashboard/shared/holding/OpportunityDto.model';
import {Base64} from 'js-base64';
import {LpOwnershipVisibility} from 'src/app/shared/enums/LpOwnershipVisibility.enum';
import {HideCommitmentForNonContributingLps} from 'src/app/shared/enums/HideCommitmentForNonContributingLps.enum';
import {CommitmentsEnabled} from 'src/app/shared/enums/CommitmentsEnabled.enum';
import {OwnershipCalculationType} from 'src/app/shared/enums/OwnershipCalculationType.enum';

@Injectable({
  providedIn: 'root'
})
export class EditFundService extends CreateEditHoldingService {
  editFundDialogContext: GpFundDialogContext;

  dialogRef: MatDialogRef<EditFundDialogComponent>;

  fundForm: UntypedFormGroup;

  attemptSubmit = false;

  processingSubmit$ = new BehaviorSubject(false);
  isGeneralServerError = false;

  bankAccounts$: Observable<ClientBankAccountResponseBasic[]>;

  fundCreated = false;

  get selectedCurrency() {
    const currency = this.getTabForm(1).get('currency').value;
    return currency as CurrencyModel;
  }

  get selectedSecurityType(): InvestmentSecurityType {
    try {
      return this.getTabForm(1).get('investmentSecurityType').value;
    } catch {
      return null;
    }
  }

  get isUpdateBankAccountAllowed(): boolean {
    // Find investments (not declined) with  payment request already sent to the investors
    const investmentsWithPaymentRequestSent = this.editFundDialogContext.fundraisingDetails.investments
      .filter(i => i.status !== InvestmentStatus.Declined)
      .find(i => i.paymentRequestSendDate);

    // If found, it's not allowed to update bank account
    if (investmentsWithPaymentRequestSent) {
      return false;
    }
    return true;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private gpFundDataService: GpFundDataService,
    private gpHoldingService: GpHoldingDataService,
    private holdingFundraisingService: HoldingFundraisingService,
    private numberPipe: TerraNumberPipe,
    private dialog: MatDialog,
    resourceService: ResourceService,
    private capitalCallService: CapitalCallService) {
    super(resourceService);
  }

  getTabForm(tabNumber: EditFundTabNumber) {
    const tabFormName = 'tab' + tabNumber;
    if (tabFormName && this.fundForm) {
      return this.fundForm.get(tabFormName) as UntypedFormGroup;
    }
    return null;
  }

  generateForm() {
    const fundDetails = this.editFundDialogContext.fundDetails;
    const fundraisingDetails = this.editFundDialogContext.fundraisingDetails;
    const fundStatus = this.editFundDialogContext.fundDetails.status;

    this.fundForm = this.fb.group({
      tab1: this.fb.group({
        name: [fundDetails.name, [Validators.required, Validators.minLength(2)], this.validateHoldingName.bind(this)],
        estimatedClosingDate: [fundraisingDetails.estimatedClosingDate, [Validators.required]],
        investmentSecurityType: [fundraisingDetails.securityType, fundStatus === HoldingStatus.Fundraising ? Validators.required : []],
        currency: [{
          value: fundraisingDetails.fundraisingTargetCurrency,
          disabled: !this.holdingFundraisingService.isUpdateCurrencyAllowed(fundraisingDetails)
        }, [Validators.required]],
        fundraisingTargetAmount: [fundraisingDetails.fundraisingTargetAmount, Validators.compose([Validators.required, Validators.min(1)])],
        minimumInvestmentAmount: fundraisingDetails.minimumInvestmentAmount,
        holdingType: [fundDetails.holdingType, [Validators.required]],
        investmentStrategyType: [fundDetails.investmentStrategyType, [Validators.required]],
        investmentStrategyTypeOther: [fundDetails.investmentStrategyTypeOther, fundDetails.investmentStrategyType === FundInvestmentStrategyType.Other ?
          [Validators.required, Validators.maxLength(200)] : [Validators.maxLength(200)]],

        fundLocation: [{address: fundDetails.location, street2Name: fundDetails.location?.street2Name}]
      }),
      tab2: this.fb.group({
        projectedPerformance: this.fb.group({
          // If the fund is in Fundraising status, and there is no value in the cumulative field, take the value from the non-cumulative:
          cashOnCashRate: (fundStatus === HoldingStatus.Fundraising) ?
            fundDetails.projectedPerformance.cashOnCashRate : fundDetails.cumulativeProjectedPerformance.cashOnCashRate,
          // Same for all the rest:
          nonLeveragedCapRate: (fundStatus === HoldingStatus.Fundraising) ?
            fundDetails.projectedPerformance.nonLeveragedCapRate : fundDetails.cumulativeProjectedPerformance.nonLeveragedCapRate,

          targetInvestmentIrr: (fundStatus === HoldingStatus.Fundraising) ?
            fundDetails.projectedPerformance.targetInvestmentIrr : fundDetails.cumulativeProjectedPerformance.targetInvestmentIrr,

          targetInvestmentIrrMax: (fundStatus === HoldingStatus.Fundraising) ?
            fundDetails.projectedPerformance.targetInvestmentIrrMax : fundDetails.cumulativeProjectedPerformance.targetInvestmentIrrMax,

          expectedRoi: (fundStatus === HoldingStatus.Fundraising) ?
            fundDetails.projectedPerformance.expectedRoi : fundDetails.cumulativeProjectedPerformance.expectedRoi,

          targetEquityMultiplier: (fundStatus === HoldingStatus.Fundraising) ?
            fundDetails.projectedPerformance.targetEquityMultiplier : fundDetails.cumulativeProjectedPerformance.targetEquityMultiplier,

          targetEquityMultiplierMax: (fundStatus === HoldingStatus.Fundraising) ?
            fundDetails.projectedPerformance.targetEquityMultiplierMax : fundDetails.cumulativeProjectedPerformance.targetEquityMultiplierMax,

          targetInvestmentPeriod: (fundStatus === HoldingStatus.Fundraising) ?
            fundDetails.projectedPerformance.targetInvestmentPeriod : fundDetails.cumulativeProjectedPerformance.targetInvestmentPeriod,

          preferredReturn: (fundStatus === HoldingStatus.Fundraising) ?
            fundDetails.projectedPerformance.preferredReturn : fundDetails.cumulativeProjectedPerformance.preferredReturn
        }),
        projectBudgetOriginal: this.fb.group({
          acquisition: fundDetails.projectBudgetOriginal.acquisition,
          hardCost: fundDetails.projectBudgetOriginal.hardCost,
          softCost: fundDetails.projectBudgetOriginal.softCost,
          financing: fundDetails.projectBudgetOriginal.financing,
          totalCosts: fundDetails.projectBudgetOriginal.totalCosts,
          totalCostsPerGrossAreaUnit: fundDetails.projectBudgetOriginal.totalCostsPerGrossAreaUnit,
          totalCostsPerNetAreaUnit: fundDetails.projectBudgetOriginal.totalCostsPerNetAreaUnit
        }),
        projectBudgetUpdated: this.fb.group({
          acquisition: fundDetails.projectBudgetUpdated.acquisition,
          hardCost: fundDetails.projectBudgetUpdated.hardCost,
          softCost: fundDetails.projectBudgetUpdated.softCost,
          financing: fundDetails.projectBudgetUpdated.financing,
          totalCosts: fundDetails.projectBudgetUpdated.totalCosts,
          totalCostsPerGrossAreaUnit: fundDetails.projectBudgetUpdated.totalCostsPerGrossAreaUnit,
          totalCostsPerNetAreaUnit: fundDetails.projectBudgetUpdated.totalCostsPerNetAreaUnit
        }),
        lenderInformation: this.fb.group({
          lenderName: fundDetails.lenderInformation.lenderName,
          amount: fundDetails.lenderInformation.amount,
          loanToValue: fundDetails.lenderInformation.loanToValue,
          interestRate: fundDetails.lenderInformation.interestRate,
          interestType: fundDetails.lenderInformation.interestType,
          loanType: fundDetails.lenderInformation.loanType,
          closingDate: fundDetails.lenderInformation.closingDate,
          maturityDate: fundDetails.lenderInformation.maturityDate,
          additionalTerms: fundDetails.lenderInformation.additionalTerms,
          totalFinancingPerGrossAreaUnit: fundDetails.lenderInformation.totalFinancingPerGrossAreaUnit,
          totalFinancingPerNetAreaUnit: fundDetails.lenderInformation.totalFinancingPerNetAreaUnit
        }),
        finalClosingDate: [
          fundraisingDetails.finalClosingDate ? fundraisingDetails.finalClosingDate : null,
          fundStatus === HoldingStatus.Owned ? Validators.required : []
        ],
        cumulativeInformation: this.fb.group({
          totalCapitalization: [fundDetails.totalCapitalization, fundStatus === HoldingStatus.Owned ? [Validators.required, CustomValidators.minFormattedNumber(0)] : []],
          totalEquityInvestedToDate: fundDetails.totalEquityInvestedToDate,
          totalReturnsToDate: fundDetails.totalReturnsToDate,
          totalInvestorEquity: [fundDetails.totalInvestorEquity, fundStatus === HoldingStatus.Owned ? [Validators.required, CustomValidators.minFormattedNumber(0)] : []],
          plannedCostsToDate: fundDetails.plannedCostsToDate,
          actualCostsToDate: fundDetails.actualCostsToDate,
          gpEquityFromTotalEquity: fundDetails.gpEquityFromTotalEquity,
          estimatedMarketValue: fundDetails.estimatedMarketValue
        }),
        commitmentsEnabled: fundDetails.commitmentsEnabled,
        ownershipCalculationType: fundDetails.ownershipCalculationType,
      }),
      tab3: this.fb.group({
        fundPhotos: this.fb.array([]),
        attachments: this.fb.array([]),
        marketingDeckDocument: [fundraisingDetails.offeringDeck, null, this.validateOfferingDeckFileTypeSupported.bind(this)],
        marketingDeckDocumentZoom: [!!fundDetails.opportunity?.documentZoomValue ? 'manual' : 'auto'],
        marketingDeckDocumentZoomValue: fundDetails.opportunity?.documentZoomValue,
        defaultOfferingDeckContribution: this.editFundDialogContext.fundraisings.filter(f => f.isDefaultDeck)?.[0]?.id,
        description: fundDetails.opportunity?.description || '',
        isVisible: fundDetails.investNow,
        video: fundDetails.opportunity?.video,
        shouldShowPlayer: !!fundDetails.opportunity?.video
      }),
      tab4: this.fb.group({
        bankAccount: [{value: this.holdingFundraisingService.getHoldingBankAccount(fundraisingDetails), disabled: !this.isUpdateBankAccountAllowed}, []],
        isCollectDeposists: fundDetails.isCollectDeposists
      }),
      tab5: this.fb.group({
        email: [fundDetails.email, [Validators.required, CustomValidators.EmailWithSpaces]],
        fundPaymentRequestDocuments: this.fb.array([]),
        isPrivate: fundDetails.isPrivate,
        allowLpSetBank: fundDetails.allowLpSetBank,
        displayPerformanceMetrics: fundDetails.displayPerformanceMetrics,
        lpOwnershipVisibility: fundDetails.lpOwnershipVisibility,
        hideCommitmentForNonContributingLps: fundDetails.hideCommitmentForNonContributingLps,
        displayNetAssetValue: fundDetails.displayNetAssetValue
      })
    });

    /*    if (!!fundraisingDetails.capitalCall) {
          const capitalCallForm = this.capitalCallService.capitalCallDetailsForm(this.fb, fundraisingDetails);
          (this.fundForm.get('tab1') as FormGroup).addControl('capitalCall', capitalCallForm);
        }*/

    this.fundForm.get('tab1.investmentStrategyType').valueChanges.subscribe(val => {
      if (val === FundInvestmentStrategyType.Other) {
        this.fundForm.get('tab1.investmentStrategyTypeOther').setValidators([Validators.required, Validators.maxLength(200)]);
      } else {
        this.fundForm.get('tab1.investmentStrategyTypeOther').setValue(null);
        this.fundForm.get('tab1.investmentStrategyTypeOther').setValidators([Validators.maxLength(200)]);
      }
      this.fundForm.get('tab1.investmentStrategyTypeOther').updateValueAndValidity();
    });

    const fundPhotos = fundDetails.attachments.filter(x => x.fileType === HoldingFileType.Photo);
    const photosArray = this.getTabForm(3).get('fundPhotos') as UntypedFormArray;
    for (let i = 0; i < 8; i++) {
      photosArray.push(this.fb.control(
        (i < fundPhotos.length && fundPhotos[i]) ?
          fundPhotos[i].metaFileLink : null,
        {asyncValidators: this.validatePhotoFileTypeSupported.bind(this)}));
    }
    const fundPaymentRequestDocuments = fundDetails.attachments.filter(x => x.fileType === HoldingFileType.PaymentRequestDocument);
    const paymentRequestDocumentsArray = this.getTabForm(5).get('fundPaymentRequestDocuments') as UntypedFormArray;
    for (let i = 0; i < 8; i++) {
      paymentRequestDocumentsArray.push(this.fb.control(
        (i < fundPaymentRequestDocuments.length && fundPaymentRequestDocuments[i]) ?
          fundPaymentRequestDocuments[i].metaFileLink : null,
        {asyncValidators: this.validatePaymentRequestDocumentFileTypeSupported.bind(this)}));
    }

    const opportunityAttachments = fundDetails.opportunity?.attachments;
    const attachmentArray = this.getTabForm(3).get('attachments') as UntypedFormArray;
    opportunityAttachments?.forEach(attachment => {
      attachmentArray.push(this.fb.control(attachment, {asyncValidators: this.validatePhotoFileTypeSupported.bind(this)}), {emitEvent: false});
    });

    this.pushMarketingAttachment();

  }

  pushMarketingAttachment() {
    const attachments = this.fundForm.get('tab3.attachments') as UntypedFormArray;
    if (attachments.length < 10) {
      attachments.insert(0, this.fb.control(null, {asyncValidators: this.validatePhotoFileTypeSupported.bind(this)}), {emitEvent: false});
    }
  }

  popMarketingAttachment(ind: number) {
    const attachments = this.fundForm.get('tab3.attachments') as UntypedFormArray;
    attachments.removeAt(ind, {emitEvent: false});
  }

  generateSubmitModel(): FundAndFundraisingReqRes {
    const formValues = this.fundForm.getRawValue();
    const tab1 = formValues.tab1;
    const tab2 = formValues.tab2;
    const tab3 = formValues.tab3;
    const tab4 = formValues.tab4;
    const tab5 = formValues.tab5;

    const fundDetailsModel = new GpFundReqRes();
    const fundraisingModel = new FundraisingReqRes();

    fundDetailsModel.id = this.editFundDialogContext.fundDetails.id;
    fundraisingModel.id = this.editFundDialogContext.fundraisingDetails.id;

    // Tab1
    if (!this.editFundDialogContext.fundDetails.isExample) {
      fundDetailsModel.name = tab1.name;
    }
    fundraisingModel.estimatedClosingDate = tab1.estimatedClosingDate;
    fundraisingModel.securityType = tab1.investmentSecurityType;

    if (tab1.currency) {
      fundraisingModel.fundraisingTargetCurrencyId = tab1.currency.id;
    }
    fundraisingModel.fundraisingTargetAmount = this.numberPipe.parse(tab1.fundraisingTargetAmount);
    fundraisingModel.minimumInvestmentAmount = this.numberPipe.parse(tab1.minimumInvestmentAmount);
    fundDetailsModel.holdingType = tab1.holdingType;
    fundDetailsModel.investmentStrategyType = tab1.investmentStrategyType;
    fundDetailsModel.investmentStrategyTypeOther = tab1.investmentStrategyTypeOther;
    fundDetailsModel.location = {...tab1.fundLocation.address, street2Name: tab1.fundLocation.street2Name} as LocationDetailsResponse;

    // Tab 2
    fundraisingModel.finalClosingDate = tab2.finalClosingDate;

    // When in Fundraising status, store the projected performance as the initial values,
    // and when in UnderManagement status, store the values in the cumulative properties
    let projectedPerfModel: ProjectedPerformance;
    switch (this.editFundDialogContext.fundDetails.status) {
      case HoldingStatus.Fundraising:
        fundDetailsModel.projectedPerformance = new ProjectedPerformance();
        projectedPerfModel = fundDetailsModel.projectedPerformance;
        break;
      case HoldingStatus.Owned:
        fundDetailsModel.cumulativeProjectedPerformance = new ProjectedPerformance();
        projectedPerfModel = fundDetailsModel.cumulativeProjectedPerformance;
        break;
    }

    projectedPerfModel.cashOnCashRate = this.numberPipe.parse(tab2.projectedPerformance.cashOnCashRate);
    projectedPerfModel.nonLeveragedCapRate = this.numberPipe.parse(tab2.projectedPerformance.nonLeveragedCapRate);
    projectedPerfModel.targetInvestmentIrr = this.numberPipe.parse(tab2.projectedPerformance.targetInvestmentIrr);
    projectedPerfModel.targetInvestmentIrrMax = this.numberPipe.parse(tab2.projectedPerformance.targetInvestmentIrrMax);
    projectedPerfModel.expectedRoi = this.numberPipe.parse(tab2.projectedPerformance.expectedRoi);
    projectedPerfModel.targetEquityMultiplier = this.numberPipe.parse(tab2.projectedPerformance.targetEquityMultiplier);
    projectedPerfModel.targetEquityMultiplierMax = this.numberPipe.parse(tab2.projectedPerformance.targetEquityMultiplierMax);
    projectedPerfModel.targetInvestmentPeriod = this.numberPipe.parse(tab2.projectedPerformance.targetInvestmentPeriod);
    projectedPerfModel.preferredReturn = this.numberPipe.parse(tab2.projectedPerformance.preferredReturn);

    // Tab 2 - Original budget:
    fundDetailsModel.projectBudgetOriginal = new ProjectBudget();
    const budgetModel = fundDetailsModel.projectBudgetOriginal;
    const budgetFormValues = tab2.projectBudgetOriginal;
    budgetModel.acquisition = this.numberPipe.parse(budgetFormValues.acquisition);
    budgetModel.hardCost = this.numberPipe.parse(budgetFormValues.hardCost);
    budgetModel.softCost = this.numberPipe.parse(budgetFormValues.softCost);
    budgetModel.financing = this.numberPipe.parse(budgetFormValues.financing);
    budgetModel.totalCosts = this.numberPipe.parse(budgetFormValues.totalCosts);
    budgetModel.totalCostsPerGrossAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerGrossAreaUnit);
    budgetModel.totalCostsPerNetAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerNetAreaUnit);
    // Tab 2 - Updated Budget
    fundDetailsModel.projectBudgetUpdated = new ProjectBudget();
    const budgetUpdateModel = fundDetailsModel.projectBudgetUpdated;
    const budgetUpdatedFormValues = tab2.projectBudgetUpdated;
    budgetUpdateModel.acquisition = this.numberPipe.parse(budgetUpdatedFormValues.acquisition);
    budgetUpdateModel.hardCost = this.numberPipe.parse(budgetUpdatedFormValues.hardCost);
    budgetUpdateModel.softCost = this.numberPipe.parse(budgetUpdatedFormValues.softCost);
    budgetUpdateModel.financing = this.numberPipe.parse(budgetUpdatedFormValues.financing);
    budgetUpdateModel.totalCosts = this.numberPipe.parse(budgetUpdatedFormValues.totalCosts);
    budgetUpdateModel.totalCostsPerGrossAreaUnit = this.numberPipe.parse(budgetUpdatedFormValues.totalCostsPerGrossAreaUnit);
    budgetUpdateModel.totalCostsPerNetAreaUnit = this.numberPipe.parse(budgetUpdatedFormValues.totalCostsPerNetAreaUnit);


    // Tab 2 - Lender information:
    fundDetailsModel.lenderInformation = new LenderInformation();
    const lenderInfoModel = fundDetailsModel.lenderInformation;

    lenderInfoModel.lenderName = tab2.lenderInformation.lenderName;
    lenderInfoModel.amount = this.numberPipe.parse(tab2.lenderInformation.amount);
    lenderInfoModel.loanToValue = this.numberPipe.parse(tab2.lenderInformation.loanToValue);
    lenderInfoModel.interestRate = this.numberPipe.parse(tab2.lenderInformation.interestRate);
    lenderInfoModel.interestType = this.numberPipe.parse(tab2.lenderInformation.interestType);
    lenderInfoModel.loanType = tab2.lenderInformation.loanType;
    lenderInfoModel.closingDate = tab2.lenderInformation.closingDate;
    lenderInfoModel.maturityDate = tab2.lenderInformation.maturityDate;
    lenderInfoModel.additionalTerms = tab2.lenderInformation.additionalTerms;
    lenderInfoModel.totalFinancingPerGrossAreaUnit = this.numberPipe.parse(tab2.lenderInformation.totalFinancingPerGrossAreaUnit);
    lenderInfoModel.totalFinancingPerNetAreaUnit = this.numberPipe.parse(tab2.lenderInformation.totalFinancingPerNetAreaUnit);

    const cumulativeInformationValues = tab2.cumulativeInformation;
    fundDetailsModel.totalCapitalization = this.numberPipe.parse(cumulativeInformationValues.totalCapitalization);
    fundDetailsModel.totalEquityInvestedToDate = this.numberPipe.parse(cumulativeInformationValues.totalEquityInvestedToDate);
    fundDetailsModel.totalReturnsToDate = this.numberPipe.parse(cumulativeInformationValues.totalReturnsToDate);
    fundDetailsModel.totalInvestorEquity = this.numberPipe.parse(cumulativeInformationValues.totalInvestorEquity);
    fundDetailsModel.commitmentsEnabled = tab2.commitmentsEnabled ? CommitmentsEnabled.Yes : CommitmentsEnabled.No;
    fundDetailsModel.ownershipCalculationType = tab2.ownershipCalculationType ? OwnershipCalculationType.ByCommitments : OwnershipCalculationType.ByContributions;
    fundDetailsModel.plannedCostsToDate = this.numberPipe.parse(cumulativeInformationValues.plannedCostsToDate);
    fundDetailsModel.actualCostsToDate = this.numberPipe.parse(cumulativeInformationValues.actualCostsToDate);
    fundDetailsModel.gpEquityFromTotalEquity = this.numberPipe.parse(cumulativeInformationValues.gpEquityFromTotalEquity);
    fundDetailsModel.estimatedMarketValue = this.numberPipe.parse(cumulativeInformationValues.estimatedMarketValue);

    // Tab 3 - Marketing material

    // Add the photos:
    fundDetailsModel.attachments = new Array<HoldingFileReqRes>();
    try {
      const photosArray = tab3.fundPhotos;
      photosArray
        .forEach((photoMetaFile: MetaFileLink) => {
          if (photoMetaFile) {
            const file = new HoldingFileReqRes();
            // Find the attachent (fundFile) with the curent metaFileLink inside it:
            const originalFundFileWithThisMetaFile = this.editFundDialogContext.fundDetails.attachments.find(a => a.metaFileLink.id === photoMetaFile.id);
            if (originalFundFileWithThisMetaFile) {
              file.id = originalFundFileWithThisMetaFile.id;
            }
            file.metaFileLinkId = photoMetaFile.id;
            file.fileType = HoldingFileType.Photo;
            fundDetailsModel.attachments.push(file);
          }
        });
    } catch (error) {
      console.log('ERROR while trying to add photos to the submit model.', error);
    }

    // Add the offering deck
    if (tab3.marketingDeckDocument) {
      fundraisingModel.offeringDeckId = (tab3.marketingDeckDocument as MetaFileLink).id;
    }

    fundDetailsModel.opportunity = this.generateOpportunityModel(tab3);


    fundDetailsModel.investNow = tab3.isVisible;

    // Tab 4
    if (tab4.bankAccount) {
      fundraisingModel.clientBankAccountId = !tab4.bankAccount.isUnitBankAccount ? (tab4.bankAccount as ClientBankAccountResponseBasic).id : null;
      fundraisingModel.unitBankAccountId = tab4.bankAccount.isUnitBankAccount ? (tab4.bankAccount as ClientBankAccountResponseBasic).id : null;
    }
    fundDetailsModel.isCollectDeposists = tab4.isCollectDeposists;

    // Tab 5 - more settings:
    fundDetailsModel.email = tab5.email;
    fundDetailsModel.isPrivate = tab5.isPrivate;
    fundDetailsModel.allowLpSetBank = tab5.allowLpSetBank;
    fundDetailsModel.displayPerformanceMetrics = tab5.displayPerformanceMetrics;
    fundDetailsModel.lpOwnershipVisibility = tab5.lpOwnershipVisibility ? LpOwnershipVisibility.On : LpOwnershipVisibility.Off;
    fundDetailsModel.hideCommitmentForNonContributingLps = tab5.hideCommitmentForNonContributingLps ? HideCommitmentForNonContributingLps.On : HideCommitmentForNonContributingLps.Off;
    fundDetailsModel.displayNetAssetValue = tab5.displayNetAssetValue;

    try {
      const paymentRequestDocumentsArray = tab5.fundPaymentRequestDocuments;
      paymentRequestDocumentsArray
        .forEach((docMetaFile: MetaFileLink) => {
          if (docMetaFile) {
            const file = new HoldingFileReqRes();
            // Find the attachent (fundFile) with the curent metaFileLink inside it:
            const originalFundFileWithThisMetaFile = this.editFundDialogContext.fundDetails.attachments.find(a => a.metaFileLink.id === docMetaFile.id);
            if (originalFundFileWithThisMetaFile) {
              file.id = originalFundFileWithThisMetaFile.id;
            }
            file.metaFileLinkId = docMetaFile.id;
            file.fileType = HoldingFileType.PaymentRequestDocument;
            fundDetailsModel.attachments.push(file);
          }
        });
    } catch (error) {
      console.log('ERROR while trying to add payment request files to the submit model.', error);
    }
    return new FundAndFundraisingReqRes(fundDetailsModel, fundraisingModel, false);
  }

  generateOpportunityModel(tab3) {
    if (!!tab3.description || !!tab3.video || !!tab3.attachments || tab3.marketingDeckDocumentZoomValue) {
      const opportunity = new OpportunityDto();
      opportunity.description = tab3.description ? Base64.encode(tab3.description) : undefined;
      opportunity.video = tab3.shouldShowPlayer ? Base64.encode(tab3.video) : undefined;
      opportunity.attachments = [];
      opportunity.documentZoomValue = tab3.marketingDeckDocumentZoom === 'manual' ? tab3.marketingDeckDocumentZoomValue : null;
      opportunity.defaultOfferingDeckFundraisingId = tab3.defaultOfferingDeckContribution;

      tab3.attachments.filter(d => !!d).forEach(d => opportunity.attachments.push(d as MetaFileLink));

      return opportunity;
    }
    return null;
  }

  saveChanges(): Observable<FundAndFundraisingReqRes> {
    this.fundForm.markAllAsTouched();

    this.attemptSubmit = true;

    this.isGeneralServerError = false;
    if (!this.fundForm.valid) {
      return of(null);
    }

    const model = this.generateSubmitModel();
    const changedToPublic = !model.fund.isPrivate && this.editFundDialogContext.fundDetails.isPrivate;
    return of(changedToPublic).pipe(
      take(1),
      switchMap(isChangedToPublic => {
        if (isChangedToPublic) {
          const dialogConfig = new MatDialogConfig<HoldingDiscriminator>();
          dialogConfig.data = HoldingDiscriminator.Fund;
          return this.dialog.open<any, HoldingDiscriminator, ConfirmInvestorsNotificationResult>(ConfirmInvestorsNotificationDialogComponent, dialogConfig)
            .afterClosed();
        }
        return of(ConfirmInvestorsNotificationResult.SkipNotification);
      }),
      tap(_ => this.processingSubmit$.next(true)),
      switchMap(selectedOption => {
        if (selectedOption === null || selectedOption === undefined) {
          return EMPTY;
        }
        return this.editFundDialogContext.gpFundService
          .updateFund(model, selectedOption === ConfirmInvestorsNotificationResult.SkipNotification);
      }
      ),
      finalize(() => this.processingSubmit$.next(false)),
      catchError(error => {
        this.processingSubmit$.next(false);
        this.isGeneralServerError = true;
        return throwError(error);
      })
    );
  }

  validateOfferingDeckFileTypeSupported(control: AbstractControl) {

    /*
      Possible using destructing :)
      const { value: metaFile }: { value: MetaFileLink } = control;
      And then: metaFile.id
    */

    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingService.isFileSupportedOfferingDeck(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

  validatePhotoFileTypeSupported(control: AbstractControl) {
    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingService.isFileSupportedPhoto(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

  validatePaymentRequestDocumentFileTypeSupported(control: AbstractControl) {
    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingService.isFileSupportedPaymentRequestDocument(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

  get addressFormFieldsToShow(): AddressFields {
    // tslint:disable-next-line:no-bitwise
    return AddressFields.Country | AddressFields.City | AddressFields.Street |
      AddressFields.Street2 | AddressFields.State | AddressFields.PostalCode;
  }

  protected isHoldingNameExist(holdingName: string): Observable<boolean> {
    return this.gpFundDataService.isFundNameExists(holdingName, this.editFundDialogContext.fundDetails.id);
  }
}
