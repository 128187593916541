import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
 
import { take } from 'rxjs';
import { UserService } from 'src/app/services/shared/user.service';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';
import { GpHoldingService } from '../../../gp-holding.service';

@Component({
  selector: 'terra-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // standalone
})
export class ShareComponent implements OnInit {
  private _list = [];
  @Output() sendEmail = new EventEmitter();
  @Output() shareText = new EventEmitter();

  @Input()
  set list(value) {
    this._list = value;
    this.dataSource = value.filter((item) => item.sharedWith) || [];
  }

  get list() {
    return this._list;
  }

  notify: boolean = true;
  displayedColumns: string[] = ['displayName', 'sharedWith'];
  dataSource = [];
  
  editorControl = new FormControl('' as string);

  holdingId$ = this.gpHoldingService.holdingId$;
  constructor(
    private readonly gpHoldingService: GpHoldingService,
    private readonly telemetryService: TelemetryService,
    private readonly userService: UserService
  ) {}
  ngOnInit(): void {
    this.editorControl.valueChanges.subscribe((value) => {
      this.shareText.emit(value);
    });

    this.holdingId$.pipe(take(1)).subscribe((holdingId) => {
      this.userService
        .getClientDetails()
        .pipe(take(1))
        .subscribe((clientDetails) => {
          this.telemetryService.create(
            {
              eventID: '4009',
              eventTitle: 'Holding-Upload-and-Share-Email-Screen',
              holdingID: holdingId,
              organizationID: clientDetails.organizationDetails.id,
            },
            AnalyticsServiceNameModel.Mixpanel |
              AnalyticsServiceNameModel.Insights
          );
        });
    });
  }
}
