import {Component, EventEmitter, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map, share, switchMap, take, tap} from 'rxjs/operators';

import {HoldingReportListItemReqRes} from 'src/app/shared/models/HoldingReportListItemReqRes.model';
import {SearchOptionsRequest} from 'src/app/shared/models/SearchOptionsRequest.model';
import {HoldingReportTableItem} from 'src/app/dashboard/shared/holding/reports/HoldingReportTableItem.model';
import {GpFundService} from '../../gp-fund.service';
import {DialogService} from 'src/app/services/dialog.service';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {RoutingService} from 'src/app/services/routing.service';
import {FundReportDataService} from 'src/app/services/shared/fund-report-data.service';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {UserService} from 'src/app/services/shared/user.service';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';

@Component({
  selector: 'terra-fund-reports',
  templateUrl: './fund-reports.component.html',
  styleUrls: ['./fund-reports.component.scss']
})
export class FundReportsComponent implements OnInit {
  fundId$ = this.gpFundService.holdingId$;

  filterPeriod$ = new BehaviorSubject(0);

  emptyState$ = new BehaviorSubject(false);

  reportYears$ = new BehaviorSubject(new Array<number>());

  refreshReports$ = new EventEmitter();

  displayedColumns: string[] = ['reportPeriod', 'publishDate', 'action'];

  reports$: Observable<HoldingReportListItemReqRes[]> = merge(this.refreshReports$, this.filterPeriod$).pipe(
    switchMap(() => this.fundId$),
    switchMap(fundId => {
      const year = this.filterPeriod$.value;
      const options = new SearchOptionsRequest();
      options.pageSize = 200;
      options.filter = year.toString();
      return this.fundReportDataService.getList(fundId, options);
    }),
    map(response => response.rows),
    tap(items => {
      if (this.filterPeriod$.value === 0) {
        this.reportYears$.next(this.extractReportYearsFromReports(items));
        this.emptyState$.next(items.length === 0);
      }
    }),
    share()
  );

  reportTableItems$: Observable<HoldingReportTableItem[]> = this.reports$.pipe(
    map(items => {
      return items.map(item => new HoldingReportTableItem(item.id, TerraUtils.getPeriodText(item.periodStartDate, item.periodEndDate, this.datePipe), item.publishDate));
    }),
    share()
  );

  private editReportLink$(reportId: number): Observable<string> {
    return this.fundId$.pipe(
      map(fundId => {
        return this.routingService.gpFundReport(fundId, reportId) + '/report-information';
      })
    );
  }

  private reportFaqsLink$(reportId: number): Observable<string> {
    return this.fundId$.pipe(
      map(fundId => {
        return this.routingService.fundReportFaqs(fundId, reportId);
      })
    );
  }

  constructor(
    private gpFundService: GpFundService,
    private fundReportDataService: FundReportDataService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private router: Router,
    private routingService: RoutingService,
    private userService: UserService,
    private telemetryService: TelemetryService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() { }


  createReport() {
    this.fundId$
      .pipe(
        map(fundId => {
          return this.routingService.createFundReport(fundId); //  + `/report-information`;
        })
      )
      .subscribe(url => this.router.navigateByUrl(url));
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
    this.fundId$.pipe(take(1)).subscribe(fundId => {
      this.telemetryService.create({
        eventID: '403',
        eventTitle: 'GP SHARED REPORT (INIT)',
        holdingID: fundId,
        organizationID: clientDetails.organizationDetails.id
        }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
      });
    });
  }


  onFaqRow(reportId: number) {
    this.reportFaqsLink$(reportId)
      .subscribe(url => this.router.navigateByUrl(url));
  }

  onEditRow(reportId: number) {
    this.editReportLink$(reportId).subscribe(url => this.router.navigateByUrl(url));
  }

  onDeleteRow(reportId: number) {
    this.dialogService
      .confirmDialog(new ConfirmDialogParams('Delete a report', 'Are you sure? this report will no longer be available to your investors'))
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.fundId$.subscribe(fundId => {
            this.fundReportDataService.delete(fundId, reportId)
              .subscribe(response => {
                this.snackbarService.showGeneralMessage('Report deleted');
                this.refreshReports$.next(undefined);
              },
                error => {
                  if (error instanceof BaseResponseDto) {
                    this.utilsService.alertErrorMessage(error);
                  }
                });
          });
        }
      });
  }

  private extractReportYearsFromReports(reports: HoldingReportListItemReqRes[]) {
    if (!reports || reports.length === 0) {
      return [];
    }
    const yearPairs = reports.map(r => [new Date(r.periodStartDate).getFullYear(), new Date(r.periodEndDate).getFullYear()]);
    const allYearsFlat = [].concat(...yearPairs);

    const minYear = Math.min(...allYearsFlat);
    const maxYear = Math.max(...allYearsFlat);

    const allYearsBetween = Array(maxYear - minYear + 1)
      .fill(0)
      .map((_, idx) => minYear + idx);

    const distinctYears = Array.from(new Set(allYearsBetween)) || [];
    return [...distinctYears];
  }
}
