import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {LoggerService} from '../../../../../../shared/errors/logger.service';
import {TerraNumberPipe} from '../../../../../../shared/pipes/TerraNumber.pipe';
import {DistributionTransferReqRes} from '../../DistributionTransferReqRes.model';
import DistributionTransferType from '../../../../../../shared/enums/DistributionTransferType.enum';
import {AlertDialogParams} from '../../../../../../shared/components/alert-dialog/alert-dialog.component';
import {DialogService} from '../../../../../../services/dialog.service';
import DistributionTransactionPurpose from '../../../../../../shared/enums/DistributionTransactionPurpose.enum';

@Component({
  selector: 'terra-distribution-pro-rata',
  templateUrl: './distribution-pro-rata.component.html',
  styleUrls: ['./distribution-pro-rata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributionProRataComponent implements OnInit, OnChanges {
  @Input() organizationDetailsId: number;
  @Input() currencyIso: string;
  @Input() externalPayment: boolean;
  @Input() investorsDistributionTransfers: DistributionTransferReqRes[];
  @Input() gpPromoteDistributionTransfer: DistributionTransferReqRes;
  @Input() distributionPurposes: DistributionTransactionPurpose[];
  @Input() disableEditMode: boolean = false;

  @Output() distributionTransfersChange = new EventEmitter<DistributionTransferReqRes[]>();

  pageForm: UntypedFormGroup;
  componentDidInit = false;
  DistributionTransactionPurpose = DistributionTransactionPurpose;

  get purposesInvestorFormArray(): UntypedFormArray {
    return this.pageForm.get('proRataValues') as UntypedFormArray;
  }

  get purposesGpPromoteFormArray(): UntypedFormArray {
    return this.pageForm.get('gpPromoteValues') as UntypedFormArray;
  }

  get disableProRataCalculation() {
    return this.purposesInvestorFormArray.value.every(val => !val);
  }

  constructor(private telemetryService: TelemetryService,
              private logger: LoggerService,
              private numberPipe: TerraNumberPipe,
              private dialogService: DialogService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.generateForm();
    this.populateForm();
    this.componentDidInit = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentDidInit && changes.distributionPurposes && !!changes.distributionPurposes.currentValue) {
      const currValue = changes.distributionPurposes.currentValue.filter(reasonForTransfer => !!reasonForTransfer) as DistributionTransactionPurpose[];
      const prevValue = changes.distributionPurposes.previousValue as DistributionTransactionPurpose[];
      // Added new reason for transaction
      if (currValue.length > this.purposesInvestorFormArray.length) {
        const numberOfControlsToAdd = currValue.length - this.purposesInvestorFormArray.length;
        for (let i = 0; i < numberOfControlsToAdd; i++) {
          this.addFormControl();
        }
      }
      // changed type from multi type to any other one
      else if (currValue.length === 1 && prevValue.length >= 2) {
        // find if the select value already used in multi type
        const existingReasonIndex = prevValue.findIndex((val) => currValue[0] === val);
        let existingReasonControl = null;
        let existingGpPromoteReasonControl = null;
        if (existingReasonIndex !== -1) {
          existingReasonControl = this.purposesInvestorFormArray.controls[existingReasonIndex];
          existingGpPromoteReasonControl = this.purposesGpPromoteFormArray.controls[existingReasonIndex];
        }
        this.purposesInvestorFormArray.clear();
        this.purposesGpPromoteFormArray.clear();
        this.purposesInvestorFormArray.push(existingReasonControl ?? new UntypedFormControl());
        this.purposesGpPromoteFormArray.push(existingGpPromoteReasonControl ?? new UntypedFormControl());
      }
      // Removed one reason for transaction
      else if (currValue.length + 1 === this.purposesInvestorFormArray.length && this.purposesInvestorFormArray.length !== 1) {
        const removedIndex = currValue.findIndex((val, i) => val !== prevValue[i]);
        this.removeFormControlAtIndex(removedIndex);
      }
      // on component init
      else if (currValue.length === 0 && this.purposesInvestorFormArray.length === 0) {
        this.addFormControl();
      }
      // Changed type from multi type to 'Select'
      else if (currValue.length === 0 && this.purposesInvestorFormArray.length >= 2) {
        this.purposesInvestorFormArray.clear();
        this.purposesGpPromoteFormArray.clear();
        this.addFormControl();
      }
    }
  }

  /**
   * Calculate pro rata for each distribution purpose
   */
  calculateProRata() {
    const valuePerTransferPurpose = this.purposesInvestorFormArray.value as string[];
    const gpPromoteValuePerTransferPurpose = this.purposesGpPromoteFormArray.value as string[];
    for (const [index, amount] of valuePerTransferPurpose.entries()) {
      const totalAmount: number = this.numberPipe.parse(amount) ?? 0;
      const totalGpPromoteAmount: number = this.numberPipe.parse(gpPromoteValuePerTransferPurpose[index]) ?? 0;

      try {
        for (const transfer of this.investorsDistributionTransfers) {
          const entity = transfer.investingEntity;
          if (!entity) {
            continue;
          }
          const proRataCalculation = entity.ownershipInHolding * totalAmount / 100;
          const gpPromoteCalculation = entity.ownershipInHolding * totalGpPromoteAmount / 100;
          transfer.distributionTransfersDetails[index].amountGross = +proRataCalculation.toFixed(2);
          transfer.distributionTransfersDetails[index].gpPromote = +gpPromoteCalculation.toFixed(2);
          transfer.distributionTransfersDetails[index].amountAfterTaxes = +(Math.max(proRataCalculation - transfer.distributionTransfersDetails[index].taxes ?? 0, 0)).toFixed(2);
          transfer.distributionTransfersDetails[index].amountAfterGpPromote = +(Math.max(transfer.distributionTransfersDetails[index].amountAfterTaxes - gpPromoteCalculation, 0)).toFixed(2);
          transfer.distributionTransfersDetails[index].amountNet = +(Math.max(transfer.distributionTransfersDetails[index].amountAfterGpPromote + transfer.distributionTransfersDetails[index].adjustments, 0)).toFixed(2);
        }

        this.gpPromoteDistributionTransfer.distributionTransfersDetails[index].amountGross = +(this.investorsDistributionTransfers.reduce((previousValue, currentTransferDetails) => currentTransferDetails.distributionTransfersDetails[index].gpPromote + previousValue, 0)).toFixed(2);
        this.gpPromoteDistributionTransfer.distributionTransfersDetails[index].amountAfterTaxes = +(Math.max(this.gpPromoteDistributionTransfer.distributionTransfersDetails[index].amountGross - this.gpPromoteDistributionTransfer.distributionTransfersDetails[index].taxes ?? 0, 0)).toFixed(2);
        this.gpPromoteDistributionTransfer.distributionTransfersDetails[index].amountNet = +(Math.max(this.gpPromoteDistributionTransfer.distributionTransfersDetails[index].amountAfterTaxes + this.gpPromoteDistributionTransfer.distributionTransfersDetails[index].adjustments ?? 0, 0)).toFixed(2);
      } catch (err) {
        this.logger.error('Exception in createDistribution => calculateProRata', err);
      }
    }

    this.showAlertDialog(
      'Pro rata calculation succeeded',
      `<p>Please review all distribution details below. You should specifically
        ensure that the correct investor bank accounts are selected.</p>`);

    this.distributionTransfersChange.emit(this.investorsDistributionTransfers);
    this.telemetryService.create({
      eventID: '101',
      eventTitle: 'DISTRIBUTION - CLICKED PRO RATA',
      organizationID: this.organizationDetailsId
    }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
  }

  /**
   * Shows dialog
   * @param title DIalog title
   * @param message Dialog body
   */
  private showAlertDialog(title: string, message: string) {
    this.dialogService.alertDialog(new AlertDialogParams(title, message));
  }


  /**
   * Generating an empty form
   */
  private generateForm() {
    this.pageForm = this.fb.group({
      proRataValues: this.fb.array([]),
      gpPromoteValues: this.fb.array([])
    });
  }

  private addFormControl() {
    this.purposesInvestorFormArray.push(new UntypedFormControl({value: null, disabled: this.disableEditMode}));
    this.purposesGpPromoteFormArray.push(new UntypedFormControl({value: null, disabled: this.disableEditMode}));
  }

  private removeFormControlAtIndex(index: number){
    this.purposesInvestorFormArray.removeAt(index);
    this.purposesGpPromoteFormArray.removeAt(index);
  }

  /**
   * Adds form control for each distributionPurposes
   */
  private populateForm() {
    this.distributionPurposes?.forEach(purpose => {
      this.addFormControl();
    });
  }
}
