import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
 
import { debounceTime, distinctUntilChanged, map, pairwise, take } from 'rxjs/operators';
import { AreaUnit } from 'src/app/shared/enums/AreaUnit.enum';
import { FundInvestmentStrategyType } from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import { InterestType } from 'src/app/shared/enums/InterestType.enum';
import { InvestmentSecurityType } from 'src/app/shared/enums/InvestmentSecurityType.enum';
import { LoanType } from 'src/app/shared/enums/LoanType.enum';
import { FeatureFlags } from '../../../../../account/my-account/model/FeatureFlags.enum';
import { DialogService } from '../../../../../services/dialog.service';
import { UserService } from '../../../../../services/shared/user.service';
import { AlertDialogParams } from '../../../../../shared/components/alert-dialog/alert-dialog.component';
import { ConfirmDialogParams } from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { OwnershipCalculationType } from '../../../../../shared/enums/OwnershipCalculationType.enum';
import { EditFundStepBase, EditFundTabNumber } from '../EditFundStepBaseAndInterface';
import { EditFundService } from '../edit-fund.service';
import {TerraNumberPipe} from '../../../../../shared/pipes/TerraNumber.pipe';

@Component({
  selector: 'terra-edit-fund-tab-investment-details',
  templateUrl: './edit-fund-tab-investment-details.component.html',
  styleUrls: ['./edit-fund-tab-investment-details.component.scss']
})
export class EditFundTabInvestmentDetailsComponent extends EditFundStepBase implements OnInit {

  readonly TAB_NUMBER: EditFundTabNumber = 2;

  // Enums
  InvestmentSecurityType = InvestmentSecurityType;
  InvestmentStrategyType = FundInvestmentStrategyType;
  LoanType = LoanType;
  AreaUnit = AreaUnit;
  InterestType = InterestType;
  OwnershipCalculationType = OwnershipCalculationType;

  // Enum values list
  InvestmentSecurityTypesList = InvestmentSecurityType.optionsForFundFundraising();
  LoanTypesList = LoanType.listAll();

  // Enum Interest Type
  InterestTypesList = InterestType.listAll();

  closingDateStartAt = new Date();
  closingDateMinimumDate = new Date('1970-01-01T12:00');

  maturityDateStartAt = new Date();
  maturityDateMinimumDate = new Date('1970-01-01T12:00');

  CapitalCommitmentAllowed = false;

  get selectedCurrency() {
    return this.editFundService.selectedCurrency;
  }

  constructor(private editFundService: EditFundService,
              private userService: UserService,
              private dialogService: DialogService,
              private terraNumberPipe: TerraNumberPipe,
  ) {
    super(editFundService);
  }

  ngOnInit() {
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
      if (FeatureFlags.isOn(clientDetails.featureFlags, FeatureFlags.AssetsCapitalCommitments)) {
        this.CapitalCommitmentAllowed = true;
      }
    });

    const estMarketValue = this.editFundService.getTabForm(2).get('cumulativeInformation.estimatedMarketValue');
    if (estMarketValue) {
      estMarketValue.valueChanges
        .pipe(
          debounceTime(200),
          map(val => Number(this.terraNumberPipe.parse(val)) || 0),
          pairwise(),
          distinctUntilChanged(),
          untilComponentDestroyed(this),
        ).subscribe(([oldValue, newValue]) => {
        if (oldValue !== newValue && (isNaN(newValue) || newValue <= 0) && this.editFundService.getTabForm(5).get('displayNetAssetValue')?.value) {
          estMarketValue.reset(oldValue);
          this.dialogService.alertDialog(
            new AlertDialogParams(
              'Warning',
              `This value must be higher than zero while "Display net asset value" setting is switched on. To configure it go to Edit Fund tab → More settings -> Display net asset value.`
            )
          );
        }
      });
    }
  }

  enableCommitmentsChanged($event: MatSlideToggleChange) {
    if ($event.checked && !this.CapitalCommitmentAllowed) {
      this.showUpgradePopup(() => $event.source.toggle());
    }
  }

  showUpgradePopup(actionAfter) {
    const confirmDialogParams = new ConfirmDialogParams();
    confirmDialogParams.actionLabel = 'Upgrade Plan';
    confirmDialogParams.title = 'Upgrade Your Subscription Plan';
    confirmDialogParams.description = `To manage Capital Commitments, you need to upgrade your subscription.
    This feature, along with other advanced capabilities, is available in our Pro subscription plan. Upgrade today to unlock these benefits!`;
    this.dialogService
      .confirmDialog(confirmDialogParams)
      .afterClosed()
      .pipe(untilComponentDestroyed(this))
      .subscribe(isConfirmed => {
        actionAfter();
        if (isConfirmed) {
          const url = 'https://www.covercy.com/contact-us/';
          window.open(url, '_blank');
        }
      });
  }
}
