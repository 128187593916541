<ng-container *ngIf="currency$ | async as currency">
  <form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" (ngSubmit)="stepSubmit()" class="form-grid terra-error-style">
    <h3 class="section-title one-line">Please make sure the cumulative information is up to date (optional)</h3>
    <div class="info-message full-width">
      Any optional fields left empty will not be visible to investors in the published report
    </div>
    <!--  ALL TYPES: Total capitalization -->
    <div class="terra-form-field-wrapper">
      <label for="txtTotalCapitalization" class="text-input-label">Total capitalization</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtTotalCapitalization" terraFormattedNumber formControlName="totalCapitalization" class="lo_sensitive" />
        <span matSuffix>{{ currency.iso }}</span>
        <mat-hint>Total investors equity + loan</mat-hint>
      </mat-form-field>
    </div>

    <!--  ALL TYPES:Total Investor Equity  -->
    <div class="terra-form-field-wrapper">
      <label for="txtTotalInvestorEquity" class="text-input-label">Total investor equity</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtTotalInvestorEquity" terraFormattedNumber formControlName="totalInvestorEquity"
          class="lo_sensitive" />
        <span matSuffix>{{ currency.iso  }}</span>
        <mat-error *ngIf="pageForm.get('totalInvestorEquity').hasError('required')"> Required </mat-error>
      </mat-form-field>
    </div>

    <!-- ALL TYPES: % of GP equity from total equity (optional)  -->
    <div class="terra-form-field-wrapper">
      <label for="txtGpEquityFromTotalEquity" class="text-input-label">% of GP equity from total equity (optional)</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtGpEquityFromTotalEquity" terraFormattedNumber formControlName="gpEquityFromTotalEquity"
          class="lo_sensitive" />
      </mat-form-field>
    </div>

    <!-- ALL TYPES: Estimated market value  -->
    <div class="terra-form-field-wrapper">
      <label for="txtEstimatedMarketValue" class="text-input-label">Estimated market value (optional)</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtEstimatedMarketValue" terraFormattedNumber formControlName="estimatedMarketValue"
          class="lo_sensitive" />
        <span matSuffix>{{ currency.iso  }}</span>
      </mat-form-field>
    </div>

    <ng-container *ngIf="fundInvestmentStrategy$ | async as fundInvestmentStrategy">

      <!-- Core + Core PLus -->
      <ng-container
        *ngIf="fundInvestmentStrategy === FundInvestmentStrategyType.Core || fundInvestmentStrategy === FundInvestmentStrategyType.CorePlus">
        <!-- Total equity invested to date -->
        <div class="terra-form-field-wrapper">
          <label for="txtTotalEquityInvestedToDate" class="text-input-label">Total equity invested to date (optional)</label>
          <mat-form-field appearance="outline">
            <input matInput id="txtTotalEquityInvestedToDate" terraFormattedNumber formControlName="totalEquityInvestedToDate"
              class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>

        <!-- Total returns to date -->
        <div class="terra-form-field-wrapper">
          <label for="txtTotalReturnsToDate" class="text-input-label">Total returns to date</label>
          <mat-form-field appearance="outline">
            <input matInput id="txtTotalReturnsToDate" terraFormattedNumber formControlName="totalReturnsToDate" class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>
      </ng-container>

    </ng-container>
  </form>
  <section class="footer">
    <!-- Error message -->
    <div *ngIf="isSubmitted" class="error-section">
      <p *ngIf="!pageForm.valid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
    <div class="form-actions">
      <a (click)="back()" class="cancel-button">Back</a>
      <button type="submit" form="stepForm" mat-flat-button color="primary" class="page-cta">Next</button>
    </div>
  </section>
</ng-container>
