import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

export class ConfirmPaymentRequestDocumentDeletionDialogParams extends ConfirmDialogParams{
    constructor(
        fileName:string = '',
        customPanelClass:string = null
        ) {
        super(`Are you sure you want to remove<br><u>${fileName}</u>?`, 
        null,
        `Yes`,
        null,
        customPanelClass);
      }
}