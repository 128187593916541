import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { StorageFolderTreeNode } from 'src/app/shared/models/StorageFolderTreeNode.model';
import { DocumentsTabService, MoveToFolderEventParam } from '../../documents-tab/documents-tab.service';

@Component({
  selector: 'terra-folder-menu-panel',
  templateUrl: './asset-folder-menu-panel.component.html',
  styleUrls: ['./asset-folder-menu-panel.component.scss']
})
export class FolderMenuPanelComponent implements OnInit {

  @ViewChild('menu', { static: true }) menu: MatMenu;

  @Input() items: StorageFolderTreeNode[];

  @Input() itemIdsToHide: number[];
  @Input() itemIdsToMove: number[];

  @Input() isBaseLevel = true;

  currentFolderId$ = this.documentsTabService.currentFolderId$;

  // only show folders that are not supposed to be hidden
  get filteredItems(): StorageFolderTreeNode[] {
    if (!this.items) {
      return null;
    }
    return this.items.filter(item => !this.itemIdsToHide.find(x => x === item.id));
  }

  constructor(private documentsTabService: DocumentsTabService) { }

  ngOnInit() { }

  move(folderId: number) {
    // if trying to move to the current folder - do nothing
    if (this.currentFolderId$.value === folderId) {
      return;
    }
    const params = new MoveToFolderEventParam();
    params.destinationFolderId = folderId;
    params.objectIdsToMove = this.itemIdsToMove;
    this.documentsTabService.moveToFolderEvent$.next(params);
  }

  // Only the child folders that it's possible to move all the selected items into.
  // (if multiple items are selected, and one of them is a folder, you can't move them all inside that folder)
  allowedChildren(children: StorageFolderTreeNode[]): StorageFolderTreeNode[] {
    if (!children) {
      return null;
    }
    return children.filter(item => !this.itemIdsToHide.find(x => x === item.id));
  }

}
