import {Component, OnInit, Inject} from '@angular/core';
import {Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {finalize} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

import {enterAnimation} from 'src/app/shared/animations';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {GpAssetDialogContext} from '../../../AssetDetails.context';
import {AssetAndFundraisingReqRes} from 'src/app/dashboard/assets/components/create-asset/AssetAndFundraisingRequest';
import {GpAssetReqRes} from 'src/app/dashboard/assets/GpAssetReqRes.model';
import {FundraisingReqRes} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {LenderInformation} from 'src/app/shared/models/LenderInformation.model';
import InvestmentType from 'src/app/shared/enums/InvestmentType.enum';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import LoanType from 'src/app/shared/enums/LoanType.enum';
import {DialogWithFormBase} from 'src/app/shared/types/DialogWithFormBase';
import {BaseResponseDto} from '../../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../../services/utils.service';
import { CustomValidators } from 'src/app/shared/validators/custom.validators';

@Component({
  selector: 'terra-partial-edit-asset-fundraising-dialog',
  templateUrl: './partial-edit-asset-fundraising-dialog.component.html',
  styleUrls: ['./partial-edit-asset-fundraising-dialog.component.scss'],
  animations: [enterAnimation]
})
export class PartialEditAssetFundraisingDialogComponent extends DialogWithFormBase implements OnInit {
  pageForm: UntypedFormGroup;
  attemptSubmit = false;
  processingSubmit$ = new BehaviorSubject(false);

  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;

  selectedInvestmentType = this.context.asset.investmentType;
  selectedAreaUnit = this.context.asset.areaUnits;
  selectedCurrency = this.context.fundraisingDetails.fundraisingTargetCurrency;

  // Enums
  InvestmentType = InvestmentType;
  AreaUnit = AreaUnit;
  LoanType = LoanType;

  // Enum value lists
  LoanTypesList = LoanType.listAll();

  constructor(
    public dialogRef: MatDialogRef<PartialEditAssetFundraisingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: GpAssetDialogContext,
    private fb: UntypedFormBuilder,
    private numberPipe: TerraNumberPipe,
    private utilsService: UtilsService
  ) {
    super();
  }

  ngOnInit() {
    this.generatePageForm(this.context);
  }

  generatePageForm(context: GpAssetDialogContext) {
    const assetDetails = context.asset;
    const fundraisingDetails = context.fundraisingDetails;

    this.pageForm = this.fb.group({
      finalClosingDate: [fundraisingDetails.estimatedClosingDate, [Validators.required]],
      totalCapitalization: [assetDetails.totalCapitalization, [Validators.required, CustomValidators.minFormattedNumber(0)]],
      totalInvestorEquity: [assetDetails.totalInvestorEquity, [Validators.required, CustomValidators.minFormattedNumber(0)]],
      gpEquityFromTotalEquity: assetDetails.gpEquityFromTotalEquity,

      lenderInformation: this.fb.group({
        lenderName: assetDetails.lenderInformation.lenderName,
        amount: assetDetails.lenderInformation.amount,
        loanToValue: assetDetails.lenderInformation.loanToValue,
        interestRate: assetDetails.lenderInformation.interestRate,
        loanType: assetDetails.lenderInformation.loanType,
        totalFinancingPerGrossAreaUnit: assetDetails.lenderInformation.totalFinancingPerGrossAreaUnit,
        totalFinancingPerNetAreaUnit: assetDetails.lenderInformation.totalFinancingPerNetAreaUnit,

        amountJunior: assetDetails.lenderInformation.amountJunior,
        loanToValueJunior: assetDetails.lenderInformation.loanToValueJunior,
        interestRateJunior: assetDetails.lenderInformation.interestRateJunior,
        loanTypeJunior: assetDetails.lenderInformation.loanTypeJunior,
        totalFinancingPerGrossAreaUnitJunior: assetDetails.lenderInformation.totalFinancingPerGrossAreaUnitJunior,
        totalFinancingPerNetAreaUnitJunior: assetDetails.lenderInformation.totalFinancingPerNetAreaUnitJunior
      })
    });
  }

  generateSubmitModel(notifyInvestors: boolean): AssetAndFundraisingReqRes {
    const formValues = this.pageForm.value;

    const assetModel = new GpAssetReqRes();
    const fundraisingModel = new FundraisingReqRes();

    assetModel.id = this.context.asset.id;
    fundraisingModel.id = this.context.fundraisingDetails.id;
    fundraisingModel.isDefaultDeck = this.context.fundraisingDetails.isDefaultDeck;
    fundraisingModel.hasDeck = !!this.context.fundraisingDetails.offeringDeckId

    // Asset details
    fundraisingModel.finalClosingDate = formValues.finalClosingDate;
    assetModel.totalCapitalization = this.numberPipe.parse(formValues.totalCapitalization);
    assetModel.totalInvestorEquity = this.numberPipe.parse(formValues.totalInvestorEquity);
    assetModel.gpEquityFromTotalEquity = this.numberPipe.parse(formValues.gpEquityFromTotalEquity);

    // Lender information (optional)
    assetModel.lenderInformation = new LenderInformation();
    assetModel.lenderInformation.lenderName = formValues.lenderInformation.lenderName;
    assetModel.lenderInformation.amount = this.numberPipe.parse(formValues.lenderInformation.amount);
    assetModel.lenderInformation.loanToValue = this.numberPipe.parse(formValues.lenderInformation.loanToValue);
    assetModel.lenderInformation.interestRate = this.numberPipe.parse(formValues.lenderInformation.interestRate);
    assetModel.lenderInformation.loanType = formValues.lenderInformation.loanType;
    assetModel.lenderInformation.totalFinancingPerGrossAreaUnit = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerGrossAreaUnit);
    assetModel.lenderInformation.totalFinancingPerNetAreaUnit = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerNetAreaUnit);

    assetModel.lenderInformation.amountJunior = this.numberPipe.parse(formValues.lenderInformation.amountJunior);
    assetModel.lenderInformation.loanToValueJunior = this.numberPipe.parse(formValues.lenderInformation.loanToValueJunior);
    assetModel.lenderInformation.interestRateJunior = this.numberPipe.parse(formValues.lenderInformation.interestRateJunior);
    assetModel.lenderInformation.loanTypeJunior = formValues.lenderInformation.loanTypeJunior;
    assetModel.lenderInformation.totalFinancingPerGrossAreaUnitJunior = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerGrossAreaUnitJunior);
    assetModel.lenderInformation.totalFinancingPerNetAreaUnitJunior = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerNetAreaUnitJunior);

    return new AssetAndFundraisingReqRes(assetModel, fundraisingModel, notifyInvestors);
  }

  save(notifyInvestors: boolean) {
    this.attemptSubmit = true;
    this.isGeneralServerError = false;

    if (!this.pageForm.valid) {
      return;
    }

    const model = this.generateSubmitModel(notifyInvestors);
    this.processingSubmit$.next(true);
    this.context.gpAssetService
      .moveFundraisingToCompleted(model)
      .pipe(
        finalize(() => this.processingSubmit$.next(false))
      )
      .subscribe(
        updatedAsset => {
          this.dialogRef.close(updatedAsset);
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            this.isGeneralServerError = true;
          }
          console.log('Error updating asset', error);
        }
      );
  }
}
