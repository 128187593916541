export enum DistributionTransferType {
  None = 0,
  Investor = 1,
  GP = 2
}

// tslint:disable-next-line:no-namespace
export namespace DistributionTransferType {
  export function toString(type: DistributionTransferType): string {
    switch (type) {
      case DistributionTransferType.Investor:
        return 'Investor';
      case DistributionTransferType.GP:
        return 'GP';

      default:
        return null;
    }
  }

  export function parse(distributionTransferTypeStr: string): DistributionTransferType {
    return DistributionTransferType[distributionTransferTypeStr];
  }
}

export default DistributionTransferType;
