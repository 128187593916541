import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import { HostBinding, Directive } from '@angular/core';
import {OnDestroyMixin} from '@w11k/ngx-componentdestroyed';

import { EditFundService } from './edit-fund.service';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import FundInvestmentStrategyType from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';

export type EditFundTabNumber = 1 | 2 | 3 | 4 | 5;

export interface IEditFundStep {
  readonly TAB_NUMBER: EditFundTabNumber;
}

@Directive()
export abstract class EditFundStepBase extends OnDestroyMixin implements IEditFundStep {

  @HostBinding('attr.class') hostClasses = 'wizard-step';

  // enums
  HoldingDiscriminator = HoldingDiscriminator;

  abstract TAB_NUMBER: EditFundTabNumber;

  isSubmitAttempt = false;

  get isInitialFundRaising() {
    return this.dialogContext.fundDetails.status === HoldingStatus.Fundraising;
  }

  get isUnderManagement() {
    return this.dialogContext.fundDetails.status === HoldingStatus.Owned;
  }
  
  get isExample() {
    return this.dialogContext.fundDetails.isExample;
  }

  private _editFundService: EditFundService;

  get dialogContext() {
    if (this._editFundService) {
      return this._editFundService.editFundDialogContext;
    }
  }

  get selectedAreaUnit(): AreaUnit {
    try {
      return Number(this._editFundService.getTabForm(1).get('areaUnits').value) as AreaUnit;
    } catch {
      return AreaUnit.SquareFeet;
    }
  }
  set selectedAreaUnit(value: AreaUnit) {
    this._editFundService.getTabForm(1).get('areaUnits').setValue(value);
  }

  get selectedInvestmentStrategyType() {
    try {
      return this._editFundService.getTabForm(1).get('investmentStrategyType').value as FundInvestmentStrategyType;
    } catch {
      return null;
    }
  }

  constructor(editFundService: EditFundService) {
    super();
    this._editFundService = editFundService;
  }

  get pageForm() {
    return this._editFundService.getTabForm(this.TAB_NUMBER);
  }

  stepSubmit() {
    this.isSubmitAttempt = true;

    this.pageForm.updateValueAndValidity();
    if (this.pageForm.valid) {
      this._editFundService.isGeneralServerError = false;
      this._editFundService.saveChanges();
    }
  }
}
