import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgxCaptchaModule } from 'ngx-captcha';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { AccountModule } from './account/account.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { AnonymousPageGuard } from './guards/anonymous-page.guard';
import { TokenRefreshInterceptor } from './interceptors/token-refresh.interceptor';
import { LpModule } from './lp/lp.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AppSettingsServiceProvider } from './services/app-settings.service.provider';
import { SsoProvider } from './services/sso.provider';
import { NeverGetHereComponent } from './shame/never-get-here/never-get-here.component';
import { CovercySharedComponentsModule } from './shared/CovercySharedComponents.module';
import { GoogleAnalyticsComponent } from './shared/components/google-analytics/google-analytics.component';
import { LuckyOrangeComponent } from './shared/components/lucky-orange/lucky-orange.component';
import { NewVersionSnackbarComponent } from './shared/components/new-version-snackbar/new-version-snackbar.component';
import { UserFlowComponent } from './shared/components/user-flow/user-flow.component';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';

@NgModule({ declarations: [
        AppComponent,
        PageNotFoundComponent,
        NeverGetHereComponent,
        GoogleAnalyticsComponent,
        LuckyOrangeComponent,
        UserFlowComponent,
        NewVersionSnackbarComponent
    ],
    bootstrap: [AppComponent],
    exports: [
        BrowserAnimationsModule
    ], imports: [BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        MaterialModule,
        CovercySharedComponentsModule,
        AccountModule,
        DashboardModule,
        LpModule,
        // The appRoutingModule must come after all other modules which contain routing,
        // so that they will have higher priority, and the empty route will not be reached every time an inner route (like /dashbaord) is navigated to.
        AppRoutingModule,
        // SocialLoginModule,
        // CookieModule.forRoot(),
        LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.WARN }),
        // environment.production ? [] : AkitaNgDevtools.forRoot(),
        // AkitaNgRouterStoreModule
        NgxCaptchaModule], providers: [
        AppSettingsServiceProvider,
        SsoProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenRefreshInterceptor,
            multi: true
        },
        AnonymousPageGuard,
        { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' } },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        },
        DatePipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
