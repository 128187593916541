import {Observable, Subject} from 'rxjs';
import {FundraisingReqRes} from './FundraisingReqRes.model';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {InvestmentReqRes} from '../../../../models/investment.model';
import MetaFileLink from '../../../../../models/metaFileLink.model';
import {GpHolding} from '../../GpHolding.model';

export interface FundraisingDetailsInterface {
  updatedInvestment$: Subject<InvestmentReqRes>;
  fundraisingId$: Observable<number>;
  isContributionInReadOnlyMode$: Observable<boolean>;

  deleteFundraising();

  updateFundraising(model: FundraisingReqRes): Observable<FundraisingReqRes>;

  deleteOfferingDeck(offeringDeck: MetaFileLink): Observable<FundraisingReqRes>;

  isFundraisingNameExists(name: string): Observable<boolean>;

  // patchFundraising(model: AssetAndFundraisingReqRes): Observable<AssetAndFundraisingReqRes>;
}

export class EditFundraisingDialogContext {
  constructor(
    public fundraisingDetails: FundraisingReqRes,
    public holding: GpHolding,
    public holdingStatus: HoldingStatus,
    public holdingDiscriminator: HoldingDiscriminator,
    public fundraisingDetailsService: FundraisingDetailsInterface
  ) {
  }
}
