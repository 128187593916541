<terra-dialog-header>
  <h1>Manage sharing permissions</h1>
</terra-dialog-header>

<div class="dialog-content-wrapper" [ngClass]="{ 'loading': (isLoading$ | async)  }">
  <terra-loader class="dialog-loader" *ngIf="(isLoading$ | async)" />

  <mat-dialog-content *ngIf="!(isLoading$ | async)">
    <ng-container *ngIf="filesReadyStatus$ | async  as filesReadyStatus">

      <!-- Empty state - no file  with permissions was selected -->
      <ng-template #emptyState>
        <p class="no-files-message info-message">
          <ng-container *ngIf="isAllSelectedFilesStillProcessing$ | async; else noFilesSelected">
            All files selected are still being processed. <br>
            Please try again in a few minutes.
          </ng-container>
          <ng-template #noFilesSelected>
            No files were selected
          </ng-template>
        </p>
      </ng-template>

      <!-- When there is at least 1 file with permissions -->
      <ng-container *ngIf="filesReadyStatus.length > 0; else emptyState">
        <label>Selected files:</label>
        <ul class="documents-list">
          <li *ngFor="let object of filesReadyStatus" class="list-item">
            <mat-icon *ngIf="object.storageObjectType === StorageObjectType.File" class="item-icon file-icon notranslate"
              [svgIcon]="object.metaFileLink | mediaFileIcon">
            </mat-icon>

            <mat-icon *ngIf="object.storageObjectType === StorageObjectType.Directory" class="item-icon folder-icon notranslate">
              folder
            </mat-icon>
            <span class="lo_sensitive file-name"
              [title]="object.displayName.length > 64 ? object.displayName : ''">{{object.displayName | truncate : 64}}.{{object.extension}}</span>

            <!-- Public files - user can set to not public -->
            <mat-chip-grid class="file-chip-list" *ngIf="object.isPublic">
              <mat-chip color="accent" [removable]="true" selected (removed)="object.isPublic = false" matTooltip="When a file is shared with all investors, all existing investors on the {{holdingDiscriminatorLowerCase}},
                as well as ones added in the future, will be able to view it." matTooltipClass="terra-tooltip-compact">
                Shared with all investors | <span matChipRemove class="remove-ispublic">Remove</span>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-grid>
          </li>


          <!-- Excluded files -->
          <ng-container *ngIf="(filesProcessingStatus$ | async)?.length  > 0">
            <li class="divider">These files are still being processed and will not be shared:</li>
            <li *ngFor="let object of filesProcessingStatus$ | async" class="list-item excluded">
              <mat-icon *ngIf="object.storageObjectType === StorageObjectType.File" class="item-icon file-icon notranslate"
                [svgIcon]="object.metaFileLink | mediaFileIcon">
              </mat-icon>
              <mat-icon *ngIf="object.storageObjectType === StorageObjectType.Directory" class="item-icon folder-icon notranslate">
                folder
              </mat-icon>
              <span class="lo_sensitive"
                [title]="object.displayName.length > 45 ? object.displayName : ''">{{object.displayName | truncate : 45}}.{{object.extension}}</span>
            </li>
          </ng-container>
        </ul>

        <p class="info-message files-not-shared-message"
          *ngIf="(isFilesInitiallyNotSharedWithInvestor$ | async) && (isAllSelectedFilesPrivate$ | async)">
          The selected files are currently not shared with any investor.
        </p>

        <!-- Investors list -->
        <div *ngIf="!(isFilesInitiallyNotSharedWithInvestor$ | async)" class="form-grid-new terra-error-style">
          <label class="section-title">Investors on this list will be able to view the selected files</label>
          <ul class="investors-list full-width" *ngIf="(isRemovedFromAllInvestors$ | async) === false else noInvestors">
            <ng-container *ngFor="let contactPermission of contactPermissions$ | async">
              <li class="investor-row"
                *ngIf="contactPermission.permissionToFiles === PermissionToFiles.All || contactPermission.permissionToFiles === PermissionToFiles.Varies">
                <!-- Contact name -->
                <span class="contact-details" class="lo_sensitive">
                  <ng-container *ngIf="allowInvestorName$ | async">
                    {{ contactPermission.contact.name}}
                  </ng-container>
                  <ng-container *ngIf="!(allowInvestorName$ | async)">
                    {{ contactPermission.contact.nickname }}
                  </ng-container>
                  <span class="email" class="lo_sensitive">({{ contactPermission.contact.email }})</span>
                </span>
                <!-- Current permission: "All": can view all files / Varies: "Can view at least one file" -->
                <mat-chip-grid class="current-permission">
                  <mat-chip (removed)="contactPermission.permissionToFiles = PermissionToFiles.None" [removable]="true"
                    matTooltipClass="terra-tooltip-compact"
                    [matTooltip]="contactPermission.permissionToFiles === PermissionToFiles.All ?'Can view all files' : 'Can view at least one file'"
                    [disableRipple]="true">
                    {{ contactPermission.permissionToFiles === PermissionToFiles.All  ? 'All' : 'Varies' }}
                    | <span matChipRemove class="remove-investor-permisssions">Remove</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </mat-chip-grid>
              </li>
            </ng-container>
          </ul>
          <ng-template #noInvestors>
            <span class="info-message all-investors-removed full-width">
              You removed view permissions for the selected files from all investors.
            </span>
          </ng-template>
        </div>

        <p #serverError *ngIf="isGeneralServerError" class="error-message">
          <span [innerHTML]="generalServerErrorMessage"></span>
        </p>
        <p>Shared files are available for investors to view and download from the Investor Portal.</p>
      </ng-container>
    </ng-container>


  </mat-dialog-content>
</div>

<mat-dialog-actions>
  <ng-container *ngIf="!(isLoadingFiles$ | async)">

    <!-- When there are no changes that can be made -->
    <ng-container
      *ngIf="(filesReadyStatus$ | async)?.length === 0 || ((isFilesInitiallyNotSharedWithInvestor$ | async) && (isAllSelectedFilesPrivate$ | async)); else changesCanBeSaved">
      <button type="button" mat-flat-button class="ok-button" color="primary" [mat-dialog-close]="undefined">OK</button>
    </ng-container>

    <!-- Chnages can be made -->
    <ng-template #changesCanBeSaved>
      <button type="submit" (click)="save()" [disabled]="processingSubmit$ | async" form="dialogForm" mat-flat-button color="primary"
        class="share-button">
        Update Permissions
      </button>
      <!-- Cancel buttons that appears after loading, to cancel without saving -->
      <button type="button" mat-button [disabled]="processingSubmit$ | async" class="cancel-button" [mat-dialog-close]="undefined">
        Cancel
      </button>
    </ng-template>

  </ng-container>

  <!-- Cancel button that appears only while loading the dialog -->
  <button type="button" *ngIf="isLoadingFiles$ | async" mat-button class="cancel-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
