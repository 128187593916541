<div class="header">
  <h2>
    {{title}}
  </h2>
</div>
<span class="description">
    {{subTitle1}}
    <br *ngIf="!!subTitle2">
    {{subTitle2}}
</span>
