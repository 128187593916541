<terra-dialog-header>
  <h1>Edit Contribution</h1>
</terra-dialog-header>

<mat-dialog-content *ngIf="loadingPage">
  <terra-loader></terra-loader>
</mat-dialog-content>
<mat-dialog-content *ngIf="!loadingPage">
  <p *ngIf="hasOrders && isContributionInReadOnlyMode$ | async" class="info-message">
    This contribution has been marked as complete. Only the contribution name can be edited.
  </p>
  <p *ngIf="context.fundraisingDetails.status === FundraisingStatus.InProgress" class="info-message">
    This contribution is in progress. Investors will not be updated automatically about any changes made here
  </p>
  <form id="dialogForm" (ngSubmit)="save()" [formGroup]="pageForm" (keydown.enter)="$event.preventDefault()">
    <div class="form-grid border-bottom terra-error-style">
      <h3 class="section-title bypass-read-only">Contribution details</h3>

      <!-- Contribution Name -->
      <div class="terra-form-field-wrapper bypass-read-only">
        <label for="txtFundraisingName" class="text-input-label">Contribution name</label>
        <mat-form-field appearance="outline">
          <input matInput autofocus id="txtFundraisingName" formControlName="fundraisingName"
                 autocomplete="fundraising-name"
                 class="lo_sensitive"/>
          <mat-error *ngIf="pageForm.get('fundraisingName').hasError('required')"> Required</mat-error>
          <mat-error *ngIf="pageForm.get('fundraisingName').hasError('minlength')"> Please enter at least 2 characters
          </mat-error>
          <mat-error *ngIf="pageForm.get('fundraisingName').hasError('fundraisingNameTaken')">
            A contribution with the same name already exists
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Estimated closing date - Visible When Status = InProgress -->
      <div class="terra-form-field-wrapper" *ngIf="!(isContributionInReadOnlyMode$ | async)">
        <terra-custom-utc-date-picker id="txtClosingDate" formControlName="estimatedClosingDate"
        label="Estimated / final closing date" [minDate]="estimatedClosingDateMinimumDate"
        [requiredError]="pageForm.get('estimatedClosingDate').hasError('required')"
        [touched]="pageForm.get('estimatedClosingDate').touched"
        [minError]="pageForm.get('estimatedClosingDate').hasError('minDate')"
        minErrorTxt="Please enter a valid closing date"></terra-custom-utc-date-picker>
      </div>

      <!-- Final closing date - Visible When Status = Complete -->
      <div class="terra-form-field-wrapper" *ngIf="isContributionInReadOnlyMode$ | async">
        <terra-custom-utc-date-picker id="txtFinalClosingDate" formControlName="finalClosingDate" label="Final closing date"
        placeholder="Final closing date" [requiredError]="pageForm.get('finalClosingDate').hasError('required')"
        [touched]="pageForm.get('finalClosingDate').touched"></terra-custom-utc-date-picker>
      </div>

      <!-- Security type -->
      <div class="terra-form-field-wrapper" *ngIf="this.context.holdingDiscriminator == HoldingDiscriminator.Asset">
        <label for="ddlSecurityType" class="text-input-label">Security type</label>
        <mat-form-field appearance="outline">
          <mat-select id="ddlSecurityType" placeholder="Select" formControlName="securityType">
            <mat-option *ngFor="let item of SecurityTypesList"
                        [value]="item">{{ item | enumString: SecurityType }}</mat-option>
          </mat-select>
          <mat-error *ngIf="pageForm.get('securityType').hasError('required')"> Required</mat-error>
        </mat-form-field>
      </div>

      <!-- FundraisingTargetAmount -->
      <div class="terra-form-field-wrapper amount-with-currency">
        <label for="txtFundraisingTargetAmount" class="amount-label text-input-label">
          {{!hasOrders ? 'Total amount' : selectedSecurityType === SecurityType.DirectOwnership ? 'Purchase price' : 'Total amount to be raised'}}
        </label>
        <mat-form-field appearance="outline" class="amount-field-wrapper">
          <input type="tel" terraFormattedNumber matInput id="txtFundraisingTargetAmount"
                 formControlName="fundraisingTargetAmount"
                 class="lo_sensitive"/>
          <span matSuffix>{{ context.fundraisingDetails.fundraisingTargetCurrency.iso }}</span>
          <mat-error *ngIf="pageForm.get('fundraisingTargetAmount').hasError('required')"> Required</mat-error>
          <mat-error *ngIf="pageForm.get('fundraisingTargetAmount').hasError('min')"> Amount must be greater than zero
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Minimum investment -->
      <div class="terra-form-field-wrapper">
        <label for="txtMinimumInvestmentAmount" class="text-input-label">
          {{ selectedSecurityType === SecurityType.DirectOwnership ? 'Initial purchase price (optional)' : 'Minimum investment (optional)'}}
        </label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtMinimumInvestmentAmount" terraFormattedNumber
                 formControlName="minimumInvestmentAmount"
                 class="lo_sensitive"/>
          <span matSuffix>{{ context.fundraisingDetails.fundraisingTargetCurrency.iso }}</span>
        </mat-form-field>
      </div>

      <!-- Capital Call -->
      <!--      <ng-container *ngIf="!!context.fundraisingDetails.capitalCall">
              <h3 class="section-title border-top">Capital call details</h3>
              <terra-capital-call-details class="full-width" [pageForm]="pageForm.get('capitalCall')">
              </terra-capital-call-details>
            </ng-container>-->

      <!-- Offering deck -->
      <div *ngIf="!(isContributionInReadOnlyMode$ | async)" class="full-width">
        <h3 class="section-title border-top">Upload an offering deck (optional)</h3>
        <terra-file-uploader class="half-width" no-type-select formControlName="marketingDeckDocument"
                             [disabled]="readOnlyMode"
                             [required]="false"
                             [isCompressable]="true">
        </terra-file-uploader>
        <p class="file-uploader-hint new-row">File format must be JPG, JPEG, PNG (20 MB max file size) or PDF (50 MB max file size)</p>
        <mat-checkbox class="regular-checkbox" color="primary full-width mb-2" formControlName="isDefaultDeck">
          Set as default deck for Invest Now Opportunity Page
        </mat-checkbox>
      </div>

      <!-- Bank account -->
      <h3 class="section-title border-top">Bank account where funds will be deposited</h3>
      <p class="info-message full-width" *ngIf="!noAccessMessage && !isUpdateBankAccountAllowed">
        Changes cannot be made because at least one payment request is pending. If you need to make changes, please contact our Support Team.
      </p>
      <div *ngIf="!noAccessMessage" class="full-width bank-choice">
        <div class="bank-account-select terra-form-field-wrapper">
          <label for="ddlBankAccount" class="text-input-label">Choose from existing:</label>
          <mat-form-field appearance="outline">
            <mat-select id="ddlBankAccount" placeholder="Select" formControlName="bankAccount" [compareWith]="bankAccountCompareFn">
              <mat-option *ngIf="!fundraisingHasBankAccount" [value]="null">Select</mat-option>
              <ng-container *ngFor="let bankAccount of bankAccounts$ | async as bankAccounts; let i = index">
                <mat-option  [value]="bankAccount"
                            [ngClass]="{'unit-account-item': bankAccount.isUnitBankAccount}">
                  {{ bankAccount.nickname }} {{ !bankAccount.nickname ? bankAccount.holderFullName + ', ' : '' }}
                  {{ !bankAccount.nickname ? getCountryById(bankAccount.countryId).code : '' }}
                </mat-option>
                <mat-divider class="unit-accounts-divider" *ngIf="gpBankAccountDataService.isLastUnitBank(bankAccounts, i)" />
              </ng-container>
            </mat-select>
            <mat-error *ngIf="pageForm.get('bankAccount').hasError('required')"> Required</mat-error>
          </mat-form-field>
        </div>
        <div class="add-bank-account-wrapper" *ngIf="isUpdateBankAccountAllowed">
          <a class="link add-bank-account" (click)="createNewBankAccount()">Add new</a>
        </div>
      </div>

      <div class="full-width not-allow-message" *ngIf="noAccessMessage">
        <mat-icon class="notranslate">error</mat-icon>
        <h4>{{noAccessMessage}}</h4>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" form="dialogForm" [disabled]="loadingPage" mat-flat-button color="primary" class="page-cta">Save
    Changes
  </button>
  <button type="button" mat-button [disabled]="loadingPage" class="cancel-button tall-button"
          [mat-dialog-close]="undefined">Cancel
  </button>
  <!-- Error message -->
  <div *ngIf="isSubmitted">
    <p *ngIf="pageForm.invalid" class="error-message">
      Please review and correct the fields marked in red
    </p>
    <!-- we only show this error if the form is valid because in edge cases it might appear with the "Please review and correct.." message -->
    <p *ngIf="pageForm.valid && isGeneralServerError" #serverError class="error-message"><span
      [innerHTML]="generalServerErrorMessage"></span></p>
    <p *ngIf="pageForm.valid && isFileNotSupported" #serverError class="error-message">The type of the file you uploaded
      is not supported
    </p>
  </div>
  <a class="link delete-fundraising-link" *ngIf="isDeleteFundraisingAllowed && !loadingPage"
     (click)="deleteFundraising()">
    Delete Contribution
  </a>
</mat-dialog-actions>
