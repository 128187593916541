<ng-container *ngIf="currency$ | async as currency">
  <form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" (ngSubmit)="stepSubmit()" class="form-grid-new container-750 terra-error-style">

    <h3 class="section-title one-line">Cumulative information (optional)</h3>
    <!--  ALL TYPES: Total capitalization -->
    <div class="terra-form-field-wrapper">
      <label for="txtTotalCapitalization" class="text-input-label">Total capitalization</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtTotalCapitalization" terraFormattedNumber formControlName="totalCapitalization" class="lo_sensitive" />
        <span matSuffix>{{ currency.iso }}</span>
        <mat-hint>Total investors equity + loan</mat-hint>
        <mat-error *ngIf="pageForm.get('totalCapitalization').hasError('required')"> Required </mat-error>
        <mat-error *ngIf="pageForm.get('totalCapitalization').hasError('minFormattedNumber')">Must be a greater than 0</mat-error>
      </mat-form-field>
    </div>

    <!--  ALL TYPES:Total Investor Equity  -->
    <div class="terra-form-field-wrapper">
      <label for="txtTotalInvestorEquity" class="text-input-label">Total investor equity</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtTotalInvestorEquity" terraFormattedNumber formControlName="totalInvestorEquity"
               class="lo_sensitive" />
        <span matSuffix>{{ currency.iso  }}</span>
        <mat-error *ngIf="pageForm.get('totalInvestorEquity').hasError('required')"> Required </mat-error>
        <mat-error *ngIf="pageForm.get('totalInvestorEquity').hasError('minFormattedNumber')">Must be a greater than 0</mat-error>
      </mat-form-field>
    </div>

    <!-- ALL TYPES: % of GP equity from total equity (optional)  -->
    <div class="terra-form-field-wrapper">
      <label for="txtGpEquityFromTotalEquity" class="text-input-label">% of GP equity from total equity (optional)</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtGpEquityFromTotalEquity" terraFormattedNumber formControlName="gpEquityFromTotalEquity"
               class="lo_sensitive" />
      </mat-form-field>
    </div>

    <!-- ALL TYPES: Estimated market value  -->
    <div class="terra-form-field-wrapper">
      <label for="txtEstimatedMarketValue" class="text-input-label">Estimated market value (optional)</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtEstimatedMarketValue" terraFormattedNumber formControlName="estimatedMarketValue"
               class="lo_sensitive" />
        <span matSuffix>{{ currency.iso  }}</span>
      </mat-form-field>
    </div>

    <!-- Original project budget (optional) (old: Planned costs to date)  -->
    <div class="terra-form-field-wrapper" *ngIf="selectedInvestmentType === InvestmentType.ValueAdd">
      <label for="txtPlannedCostsToDate" class="text-input-label">Original project budget (optional)</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtPlannedCostsToDate" terraFormattedNumber formControlName="plannedCostsToDate" class="lo_sensitive" />
        <span matSuffix>{{ currency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Revised project budget (optional)  (old: Actual costs to date)  -->
    <div class="terra-form-field-wrapper" *ngIf="selectedInvestmentType === InvestmentType.ValueAdd">
      <label for="txtActualCostsToDate" class="text-input-label">Revised project budget (optional)</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtActualCostsToDate" terraFormattedNumber formControlName="actualCostsToDate" class="lo_sensitive" />
        <span matSuffix>{{ currency.iso }}</span>
      </mat-form-field>
    </div>

    <!-- Construction start date (optional) -->
    <div class="terra-form-field-wrapper"
         *ngIf="[InvestmentType.ValueAdd, InvestmentType.Opportunistic].includes(selectedInvestmentType)">
      <label for="txtConstructionStartDate" class="text-input-label">Construction start date (optional)</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtConstructionStartDate" [matDatepicker]="constructionStartDatePicker"
               formControlName="constructionStartDate" (focus)="constructionStartDatePicker.open()" class="lo_sensitive" />
        <mat-datepicker-toggle matSuffix [for]="constructionStartDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #constructionStartDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <!-- Construction end date (optional) -->
    <div class="terra-form-field-wrapper"
         *ngIf="[InvestmentType.ValueAdd, InvestmentType.Opportunistic].includes(selectedInvestmentType)">
      <label for="txtConstructionEndDate" class="text-input-label">Construction end date (optional)</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtConstructionEndDate" [matDatepicker]="constructionEndDatePicker" formControlName="constructionEndDate"
               (focus)="constructionEndDatePicker.open()" class="lo_sensitive" />
        <mat-datepicker-toggle matSuffix [for]="constructionEndDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #constructionEndDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <!-- Core + Core PLus -->
    <ng-container *ngIf="[InvestmentType.Core, InvestmentType.CorePlus].includes(selectedInvestmentType)">
      <!-- Total equity invested to date -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalEquityInvestedToDate" class="text-input-label">Total equity invested to date (optional)</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtTotalEquityInvestedToDate" terraFormattedNumber formControlName="totalEquityInvestedToDate"
                 class="lo_sensitive" />
          <span matSuffix>{{ currency.iso }}</span>
        </mat-form-field>
      </div>

      <!-- Total returns to date -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalReturnsToDate" class="text-input-label">Total returns to date</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtTotalReturnsToDate" terraFormattedNumber formControlName="totalReturnsToDate" class="lo_sensitive" />
          <span matSuffix>{{ currency.iso }}</span>
        </mat-form-field>
      </div>

    </ng-container>

    <!-- Outstanding loan amount to date -->
    <!--
    <ng-container *ngIf="assetInvestmentSecurityType$ | async as securityType">
      <div class="terra-form-field-wrapper" *ngIf="securityType==SecurityType.Debt">
        <label for="txtOutstandingLoanAmountToDate" class="text-input-label">Outstanding loan amount to date</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtOutstandingLoanAmountToDate" terraFormattedNumber formControlName="outstandingLoanAmountToDate"
                 class="lo_sensitive" />
          <span matSuffix>{{ currency.iso }}</span>
        </mat-form-field>
      </div>
    </ng-container>
    -->

    <h3 class="section-title">Projected performance of investment (optional)</h3>
    <!-- Projected performance -->
    <ng-container formGroupName="projectedPerformance">

      <!-- Target investment IRR (Min) -->
      <div class="terra-form-field-wrapper new-row"
           *ngIf="[InvestmentType.ValueAdd, InvestmentType.Opportunistic].includes(selectedInvestmentType)">
        <label for="txtTargetInvestmentIrr" class="text-input-label">Target investment IRR %</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTargetInvestmentIrr" terraFormattedNumber formControlName="targetInvestmentIrr"
                 class="lo_sensitive" />
        </mat-form-field>
      </div>
      <!-- Target investment IRR (Max) -->
      <div class="terra-form-field-wrapper" *ngIf="[InvestmentType.ValueAdd, InvestmentType.Opportunistic].includes(selectedInvestmentType)">
        <label for="txtTargetInvestmentIrrMax" class="text-input-label">Target investment IRR % (Max)</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTargetInvestmentIrrMax" terraFormattedNumber formControlName="targetInvestmentIrrMax"
                 class="lo_sensitive" placeholder="Add target investment IRR % range - Optional" />
        </mat-form-field>
      </div>

      <!-- Target equity multiple (Min) (all investmentTypes) -->
      <div class="terra-form-field-wrapper new-row">
        <label for="txtTargetEquityMultiplier" class="text-input-label">Target equity multiple</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTargetEquityMultiplier" terraFormattedNumber formControlName="targetEquityMultiplier"
                 class="lo_sensitive" />
        </mat-form-field>
      </div>
      <!-- Target equity multiple (Max) (all investmentTypes)  -->
      <div class="terra-form-field-wrapper">
        <label for="txtTargetEquityMultiplierMax" class="text-input-label">Target equity multiple (Max)</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTargetEquityMultiplierMax" terraFormattedNumber formControlName="targetEquityMultiplierMax"
                 class="lo_sensitive" placeholder="Add target equity multiplier range - Optional" />
        </mat-form-field>
      </div>

      <!-- Projected cash on cash rate % -->
      <div class="terra-form-field-wrapper" *ngIf="[InvestmentType.Core, InvestmentType.CorePlus].includes(selectedInvestmentType)">
        <label for="txtCashOnCashRate" class="text-input-label">Projected cash on cash rate %</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtCashOnCashRate" terraFormattedNumber formControlName="cashOnCashRate" class="lo_sensitive" />
        </mat-form-field>
      </div>

      <!-- Projected non-leveraged cap rate  % -->
      <div class="terra-form-field-wrapper" *ngIf="[InvestmentType.Core, InvestmentType.CorePlus].includes(selectedInvestmentType)">
        <label for="txtNonLeveragedCapRate" class="text-input-label">Projected non-leveraged cap rate %</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtNonLeveragedCapRate" terraFormattedNumber formControlName="nonLeveragedCapRate"
                 class="lo_sensitive" />
        </mat-form-field>
      </div>

      <!-- Expected ROI -->
      <div class="terra-form-field-wrapper" *ngIf="[InvestmentType.ValueAdd, InvestmentType.Opportunistic].includes(selectedInvestmentType)">
        <label for="txtExpectedRoi" class="text-input-label">Expected ROI %</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtExpectedRoi" terraFormattedNumber formControlName="expectedRoi" class="lo_sensitive" />
        </mat-form-field>
      </div>

      <!-- Preferred return % (all investmentTypes) -->
      <div class="terra-form-field-wrapper">
        <label for="txtPreferredReturn" class="text-input-label">Preferred return %</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtPreferredReturn" terraFormattedNumber formControlName="preferredReturn" class="lo_sensitive" />
        </mat-form-field>
      </div>

      <!-- Target investment period (all investmentTypes) -->
      <div class="terra-form-field-wrapper">
        <label for="txtTargetInvestmentPeriod" class="text-input-label">Target investment period in years</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTargetInvestmentPeriod" terraFormattedNumber formControlName="targetInvestmentPeriod"
                 class="lo_sensitive" />
        </mat-form-field>
      </div>

    </ng-container>

    <!-- Original project budget (optional) -->
    <ng-container *ngIf="selectedInvestmentType === InvestmentType.Opportunistic">
      <h3 class="section-title">Original project budget (optional)</h3>
      <ng-container formGroupName="projectBudgetOriginal">

        <!-- Acquisition -->
        <div class="terra-form-field-wrapper">
          <label for="txtAcquisition" class="text-input-label">Acquisition</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtAcquisition" terraFormattedNumber formControlName="acquisition" class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>

        <!--  Hard cost -->
        <div class="terra-form-field-wrapper">
          <label for="txtHardCost" class="text-input-label"> Hard cost</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtHardCost" terraFormattedNumber formControlName="hardCost" class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>

        <!--  Soft cost -->
        <div class="terra-form-field-wrapper">
          <label for="txtSoftCost" class="text-input-label"> Soft cost</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtSoftCost" terraFormattedNumber formControlName="softCost" class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>

        <!--  Financing -->
        <div class="terra-form-field-wrapper">
          <label for="txtFinancing" class="text-input-label"> Financing</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtFinancing" terraFormattedNumber formControlName="financing" class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>

        <!--  Total costs -->
        <div class="terra-form-field-wrapper">
          <label for="txtTotalCosts" class="text-input-label"> Total costs</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtTotalCosts" terraFormattedNumber formControlName="totalCosts" class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>


        <!--  Total costs per gross area -->
        <div class="terra-form-field-wrapper">
          <label for="txtTotalCostsPerGrossAreaUnit" class="text-input-label"> Total costs per gross
            {{ selectedAreaUnit | enumString : AreaUnit }} </label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtTotalCostsPerGrossAreaUnit" terraFormattedNumber formControlName="totalCostsPerGrossAreaUnit"
                   class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>

        <!--  Total costs per net area  -->
        <div class="terra-form-field-wrapper">
          <label for="txtTotalCostsPerNetAreaUnit" class="text-input-label"> Total costs per net
            {{ selectedAreaUnit | enumString : AreaUnit }} </label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtTotalCostsPerNetAreaUnit" terraFormattedNumber formControlName="totalCostsPerNetAreaUnit"
                   class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>


    <!-- Loan information -->
    <h3 class="section-title">Loan information (optional))</h3>
    <ng-container formGroupName="lenderInformation">
      <!-- Lender Name -->
      <div class="terra-form-field-wrapper">
        <label for="txtLenderName" class="text-input-label">Lender name</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtLenderName" formControlName="lenderName" autocomplete="lender-name" class="lo_sensitive" />
        </mat-form-field>
      </div>

      <!-- Amount -->
      <div class="terra-form-field-wrapper">
        <label for="txtLenderAmount" class="text-input-label">Amount</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtLenderAmount" terraFormattedNumber formControlName="amount" class="lo_sensitive" />
          <span matSuffix>{{ currency.iso }}</span>
        </mat-form-field>
      </div>

      <!-- Loan type -->
      <div class="terra-form-field-wrapper">
        <label for="ddlLoanType" class="text-input-label">Loan type</label>
        <mat-form-field appearance="outline">
          <mat-select id="ddlLoanType" formControlName="loanType" placeholder="Select">
            <mat-option [value]="null">Select</mat-option>
            <mat-option *ngFor="let item of LoanTypesList" [value]="item">{{ item | enumString: LoanType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Loan to value -->
      <div class="terra-form-field-wrapper">
        <label for="txtLoanToValue" class="text-input-label">
          Loan to {{ selectedInvestmentType === InvestmentType.Opportunistic ? 'cost' : 'value'}} %</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtLoanToValue" terraFormattedNumber formControlName="loanToValue" class="lo_sensitive" />
        </mat-form-field>
      </div>

      <!-- Interest and Interest Type -->
      <div class="terra-form-field-wrapper interest-with-type">
        <!-- Interest Rate -->
        <label for="txtInterestRate" class="interest-label text-input-label">
          Interest rate %
        </label>
        <mat-form-field class="interest-field-wrapper" appearance="outline">
          <input type="tel" matInput id="txtInterestRate" terraFormattedNumber formControlName="interestRate" class="lo_sensitive" />
        </mat-form-field>
        <!-- Interest Type -->
        <label for="ddlInterestType" class="interest-type-label text-input-label">
          Interest type
        </label>
        <mat-form-field appearance="outline" class="interest-type-ddl-wrapper">
          <mat-select id="ddlInterestType" placeholder="Select" formControlName="interestType">
            <mat-option *ngFor="let item of InterestTypesList" [value]="item">{{ item | enumString: InterestType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Closing date -->
      <div class="terra-form-field-wrapper">
        <label for="txtClosingDate" class="text-input-label">Closing date</label>
        <mat-form-field appearance="outline">
          <!-- Allow past date to support retroactive creation of funds -->
          <input matInput [min]="closingDateMinimumDate" id="txtClosingDate" [matDatepicker]="closingDateDatePickerA" autocomplete="bday"
                 formControlName="closingDate" (focus)="closingDateDatePickerA.open()" class="lo_sensitive" />
          <mat-datepicker-toggle matSuffix [for]="closingDateDatePickerA"></mat-datepicker-toggle>
          <mat-datepicker #closingDateDatePickerA [startAt]="closingDateStartAt"></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Maturity date -->
      <div class="terra-form-field-wrapper">
        <label for="txtMaturityDate" class="text-input-label">Maturity date</label>
        <mat-form-field appearance="outline">
          <!-- Allow past date to support retroactive creation of funds -->
          <input matInput [min]="maturityDateMinimumDate" id="txtMaturityDate" [matDatepicker]="maturityDateDatePickerA" autocomplete="bday"
                 formControlName="maturityDate" (focus)="maturityDateDatePickerA.open()" class="lo_sensitive" />
          <mat-datepicker-toggle matSuffix [for]="maturityDateDatePickerA"></mat-datepicker-toggle>
          <mat-datepicker #maturityDateDatePickerA [startAt]="maturityDateStartAt"></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Total financing per gross area (only InvestmentType.Opportunistic) -->
      <div class="terra-form-field-wrapper" *ngIf="selectedInvestmentType === InvestmentType.Opportunistic">
        <label for="txtTotalFinancingPerGrossAreaUnit" class="text-input-label">
          Total financing per gross {{ selectedAreaUnit | enumString : AreaUnit }} </label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTotalFinancingPerGrossAreaUnit" terraFormattedNumber
                 formControlName="totalFinancingPerGrossAreaUnit" class="lo_sensitive" />
          <span matSuffix>{{ currency.iso }}</span>
        </mat-form-field>
      </div>

      <!-- Total financing per net area (only InvestmentType.Opportunistic) -->
      <div class="terra-form-field-wrapper" *ngIf="selectedInvestmentType === InvestmentType.Opportunistic">
        <label for="txtTotalFinancingPerNetAreaUnit" class="text-input-label">
          Total financing per net {{ selectedAreaUnit | enumString : AreaUnit }} </label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTotalFinancingPerNetAreaUnit" terraFormattedNumber formControlName="totalFinancingPerNetAreaUnit"
                 class="lo_sensitive" />
          <span matSuffix>{{ currency.iso }}</span>
        </mat-form-field>
      </div>

      <!-- Additional Term -->
      <div class="terra-form-field-wrapper full-width">
        <label for="txtAdditionalTerms" class="text-input-label">Additional Terms</label>
        <p class="field-description">
          Share the loan additional terms here
        </p>
        <quill-editor id="additionalTerms" formControlName="additionalTerms" format="html" class="lo_sensitive" placeholder="" />
        <mat-error *ngIf="pageForm.get('lenderInformation.additionalTerms').hasError('maxlength')">
          You have exceeded the maximum length of
          {{ pageForm.get('lenderInformation.additionalTerms').getError('maxlength').requiredLength | terraNumber }} characters
        </mat-error>
      </div>
    </ng-container>
  </form>

  <section class="footer">
    <div class="container-750">
      <!-- Error message -->
      <div *ngIf="isSubmitAttempt" class="error-section">
        <p *ngIf="!pageForm.valid" class="error-message">
          Please review and correct the fields marked in red
        </p>
      </div>
      <div class="form-actions">
        <a (click)="back()" class="cancel-button">Back</a>
        <button type="submit" form="stepForm" mat-flat-button color="primary" class="page-cta">Next</button>
      </div>
    </div>
  </section>
</ng-container>
