<div *ngIf="numberOfInvestorsReadyForCapitalCall$ | async as numberOfInvestors" class="info-message">
  <span>{{numberOfInvestors}} {{numberOfInvestors > 1 ? 'investors' : 'investor'}} in status Signed {{numberOfInvestors > 1 ? 'were' : 'was'}} not called yet</span>
</div>
<mat-tab-group #tabGroup selectedIndex="0" [terraHeaderLessTabs]="isCompletedDistribution" mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab label="Pipeline">
    <terra-investors-list [isReadOnlyContribution$]="isReadOnlyContribution$" />
  </mat-tab>

  <mat-tab #capitalCallTab label="Capital Call" *ngIf="!isCompletedDistribution">
    <terra-capital-call />
  </mat-tab>
</mat-tab-group>

