import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { HttpEventType } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { catchError, finalize, forkJoin, Observable, of, Subscription, switchMap, tap } from "rxjs";
import MetaFileLink from "src/app/models/metaFileLink.model";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "terra-uploader-block",
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploaderBlockComponent),
      multi: true,
    },
  ],
  imports: [MatChipsModule, MatTooltipModule, MatIconModule],
  template: `
    <div #ref><ng-content /></div>
    @if (!ref.hasChildNodes()){
      @if(files.length){
      <mat-chip-listbox selectable="false">
        @for (file of files; track file) {
        <mat-chip
          selected
          highlighted
          [disabled]="disabled"
          color="accent"
          disableRipple="true"
          [removable]="!disabled"
          (removed)="removeFile(file)"
        >
          <span class="filename">{{ getFileName(file) }}</span>
          @if(!disabled){
          <mat-icon matChipRemove>cancel</mat-icon>
          }
        </mat-chip>
        }
      </mat-chip-listbox>
      } @if (!files.length){
      <span class="placeholder">{{ emptyPlaceholder }}</span>
      }
    }


    <input
      #fileInputEl
      class="hidden"
      #fileInput
      type="file"
      [attr.multiple]="multiple ? '' : null"
      [attr.accept]="accept"
      (change)="fileChange($event?.target?.files)"
    />
  `,
  styles: `
  input {
  width: 0px;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

:host.disabled {
  opacity: 0.5;
  cursor: unset;
}

.placeholder {
  color: grey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

mat-chip {
  max-width: 100%;
}
.filename {
  max-width: calc(100% - 1em);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:host.empty-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-focus-indicator {
  box-shadow: none;
}

.mat-mdc-chip.mat-mdc-standard-chip::after {
  background: unset;
}
  `,
})
export class UploaderBlockComponent {
  private UPLOAD_FILE_ENDPOINT = 'upload'; 
  http = inject(HttpService);

  @HostBinding("class.disabled")
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(val: boolean) {
    this._disabled = coerceBooleanProperty(val);
  }
  @Input()
  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }
  get multiple() {
    return this._multiple;
  }

  @Input()
  set displayFileSize(value: boolean) {
    this._displayFileSize = coerceBooleanProperty(value);
  }
  get displayFileSize() {
    return this._displayFileSize;
  }

  get files() {
    return this._files;
  }

  @HostBinding("class.empty-input")
  get isEmpty() {
    return !this.files?.length;
  }

  get isDragover() {
    return this._isDragOver;
  }
  set isDragover(value: boolean) {
    if (!this.disabled) {
      this._isDragOver = value;
    }
  }


  get uploading() {
    return this._uploading;
  }

  get progress(): number | undefined {
    return [...this._progress.values()].reduce((acc, val) => acc + val, 0) / this._progress.size;
  }
  

  @Output()
  private valueChanged = new EventEmitter<MetaFileLink[]>();

  @ViewChild("fileInputEl")
  private fileInputEl: ElementRef | undefined;

  @Input() accept = "*";

  private _disabled = false;
  private _multiple = false;
  @Input() emptyPlaceholder = `Drop file${
    this.multiple ? "s" : ""
  } or click to select`;
  private _displayFileSize = false;
  private _files: MetaFileLink[] = [];
  private _isDragOver = false;
  private _uploading = false;
  private _progress = new Map<string, number>();
  
  private _uploadSubscriptions = new Map<string, Subscription>(); // To track upload subscriptions


  private _onChange = (_: MetaFileLink[]) => {};
  private _onTouched = () => {};

  writeValue(files: MetaFileLink[]): void {
    const fileArray = this.convertToMetaFileLinkArray(files);
    const nonDuplicateFiles = fileArray.filter(file => !this.isDuplicateMetaFileLink(file));

    if (nonDuplicateFiles.length < 2 || this.multiple) {
      this._files = [...this._files, ...nonDuplicateFiles];
      this.emitChanges(this._files);
    } else {
      throw Error("Multiple files not allowed");
    }
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private emitChanges(files: MetaFileLink[]) {
    this.valueChanged.emit(files);
    this._onChange(files);
  }

  addFiles(files: File[] | FileList | File) {
    const fileArray = this.convertToFileArray(files);
    const nonDuplicateFiles = fileArray.filter(file => !this.isDuplicateFile(file));


    if (this.multiple) {
      const merged = this.files.concat(nonDuplicateFiles.map(file => this.convertToMetaFileLink(file)));
      this.writeValue(merged);
    } else {
      this.writeValue(fileArray.map(file => this.convertToMetaFileLink(file)));
    }
  }

  removeFile(file: MetaFileLink) {
    const fileIndex = this.files.indexOf(file);
    if (fileIndex >= 0) {
      // TODO: replace with toSpliced() method afttr UPDATE
      const updatedFiles = [...this._files];
      updatedFiles.splice(fileIndex, 1);
      this._files = updatedFiles; 
  
      this.emitChanges(this._files);
      this._progress.delete(file.title);
      this.cancelUpload(file.title);
    }
  }

  cancelUpload(fileName: string): void {
    const subscription = this._uploadSubscriptions.get(fileName);
    if (subscription) {
      subscription.unsubscribe(); // Cancel the upload by unsubscribing
      this._progress.delete(fileName); 
      this._uploadSubscriptions.delete(fileName); 
    } else {
      console.error(`No ongoing upload found for ${fileName}`);
    }
  }

  clear() {
    this._progress.clear();
    this.writeValue([]);
  }

  @HostListener("change", ["$event"])
  change(event: Event) {
    event.stopPropagation();
    this._onTouched();
    const fileList = (event.target as HTMLInputElement).files;
    if (fileList?.length) {
      this.addFiles(fileList);
    }
    (event.target as HTMLInputElement).value = "";
  }

  @HostListener("dragenter", ["$event"])
  @HostListener("dragover", ["$event"])
  activate(e: Event) {
    e.preventDefault();
    this.isDragover = true;
  }

  @HostListener("dragleave", ["$event"])
  deactivate(e: {
    dataTransfer?: { files: FileList };
    preventDefault(): void;
  }) {
    e.preventDefault();
    this.isDragover = false;
  }

  @HostListener("drop", ["$event"])
  handleDrop(e: { dataTransfer: { files: FileList }; preventDefault(): void }) {
    this.deactivate(e);
    if (!this.disabled) {
      const fileList = e.dataTransfer.files;
      this.removeDirectories(fileList).then((files: File[]) => {
        if (files?.length) {
          this.addFiles(files);
          this.fileChange(files);
        }
        this._onTouched();
      });
    }
  }

  @HostListener("click")
  open() {
    if (!this.disabled) {
      this.fileInputEl?.nativeElement.click();
    }
  }

  private removeDirectories(files: FileList): Promise<File[]> {
    return new Promise((resolve) => {
      const fileArray = this.convertToFileArray(files);

      const dirnames: string[] = [];

      const readerList = [];

      for (let i = 0; i < fileArray.length; i++) {
        const reader = new FileReader();

        reader.onerror = () => {
          dirnames.push(fileArray[i].name);
        };

        reader.onloadend = () => addToReaderList(i);

        reader.readAsArrayBuffer(fileArray[i]);
      }

      function addToReaderList(val: number) {
        readerList.push(val);
        if (readerList.length === fileArray.length) {
          resolve(
            fileArray.filter((file: File) => !dirnames.includes(file.name))
          );
        }
      }
    });
  }

  private convertToArray<T>(items: T[] | T | FileList | null | undefined): T[] {
    if (items) {
      if (items instanceof FileList) {
        return Array.from(items) as T[];
      } else if (items instanceof Array) {
        return items;
      } else {
        return [items];
      }
    }
    return [];
  }
  
  // Usage for MetaFileLink
  private convertToMetaFileLinkArray(files: MetaFileLink[] | MetaFileLink | undefined): MetaFileLink[] {
    return this.convertToArray<MetaFileLink>(files);
  }
  
  // Usage for File
  private convertToFileArray(files: FileList | File[] | File | null | undefined): File[] {
    return this.convertToArray<File>(files);
  }

  private convertToMetaFileLink(file: File): MetaFileLink {
    const File = new MetaFileLink();
    File.title = file.name;
    File.sizeB = file.size;
    File.mediaType = file.type;
    return File;
  }

  getFileName(file: MetaFileLink): string {
    return file.title;
  }


  fileChange(fileList: File[] = []) {
    this._uploading = true;
    const filesArray = Array.from(fileList);
    const uploadObservables = filesArray.map(file => this.createUploadObservable(file));
   
    // Track all upload subscriptions // ISSUE: creates duplicated subscriptions
    // uploadObservables.forEach((observable, index) => {
    //       const fileName = filesArray[index].name;
    //       const subscription = observable.subscribe();
    //       this._uploadSubscriptions.set(fileName, subscription);
    //     });

    forkJoin(uploadObservables).pipe(
      finalize(() => {
        this._uploading = false;
      })
    ).subscribe();
  }


  private createUploadObservable(file: File): Observable<any> {
    return this.http.compressImage(file).pipe(
      switchMap(compressedFile => {
        return this.http.uploadFile(this.UPLOAD_FILE_ENDPOINT, compressedFile);
      }),
      tap(result => this.handleUploadProgress(file, result)),
      catchError(error => {
        console.error(`Error uploading ${file.name}:`, error);
        return of(null); // Ensure error doesn't break the chain
      }),
      finalize(() => {
        this._uploadSubscriptions.delete(file.name); // Cleanup after upload
      })
    );
  }

  private handleUploadProgress(file: File, result: any) {
    const fileIndex = this._files.findIndex(f => f.title === file.name && f.sizeB === file.size && f.mediaType === file.type);

    if (result.type === HttpEventType.UploadProgress) {
      const progress = Math.round((100 * result.loaded) / result.total);
      if (fileIndex >= 0) {
        this._files[fileIndex].progress = progress;
      } 
      this._progress.set(file.name, progress);
    } else if (result.type === HttpEventType.Response) {
      this._progress.set(file.name, 100); // Mark complete

      const uploadedFile = result.body as MetaFileLink;
  
        if (uploadedFile.id > 0) {
          if (fileIndex >= 0) {
            this._files[fileIndex] = uploadedFile;
          } else {
            this._files.push(uploadedFile);
          }
          this.emitChanges(this._files);
        } else {
          console.error('File upload error: Invalid file ID');
        }
    }
  }

  private isDuplicateFile(file: File): boolean {
    return this._files.some(existingFile => 
      existingFile.title === file.name && 
      existingFile.sizeB === file.size && 
      existingFile.mediaType === file.type
    );
  }

  private isDuplicateMetaFileLink(file: MetaFileLink): boolean {
    return this._files.some(existingFile => 
      existingFile.title === file.title && 
      existingFile.sizeB === file.sizeB && 
      existingFile.mediaType === file.mediaType
    );
  }

}