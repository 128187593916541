<terra-dialog-header>
  <h1>{{ isEditMode ? 'Edit an existing FAQ' : 'Write a new FAQ' }}</h1>
</terra-dialog-header>

<mat-dialog-content>
  <form id="dialogForm" (ngSubmit)="save()" [formGroup]="pageForm">
    <div class="form-grid border-bottom terra-error-style">
      <!-- Question -->
      <div class="terra-form-field-wrapper full-width">
        <label for="txtQuestion" class="text-input-label">Question</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtQuestion" formControlName="question" class="lo_sensitive"
            placeholder="e.g. is the occupancy rate in the report an average over the report period?" />
          <mat-error *ngIf="pageForm.get('question').hasError('required')"> Required </mat-error>
          <mat-error *ngIf="pageForm.get('question').hasError('maxlength')"> You have exceeded the maximum length </mat-error>
        </mat-form-field>
      </div>

      <!-- Answer -->
      <div class="terra-form-field-wrapper full-width">
        <label for="txtAnswer" class="text-input-label">Answer</label>
        <quill-editor id="txtAnswer" formControlName="answer" format="html" class="message-input answer-input" placeholder="" />

        <mat-error *ngIf="(isSubmitted || pageForm.get('answer').touched) && pageForm.get('answer').hasError('required')"> Required
        </mat-error>
        <mat-error *ngIf="pageForm.get('answer').hasError('maxlength')"> You have exceeded the maximum length </mat-error>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" form="dialogForm" mat-flat-button color="primary"
    class="page-cta">{{ isEditMode ? 'Save Changes' : 'Save' }}</button>
  <button type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
