<ng-template #stepLoading>
  <terra-loader class="place-middle"></terra-loader>
</ng-template>

<form id="stepForm" [formGroup]="pageForm" *ngIf="stepIsLoading$ | async; else stepLoading" class="form-grid-new terra-error-style">

  <h3 class="section-title">
    Bank account for collecting deposits
  </h3>

  <div *ngIf="!noAccessMessage">
    <p class="info-message full-width" *ngIf="!isUpdateBankAccountAllowed && isInitialFundRaising">
      Changes cannot be made because at least one payment request is pending. If you need to make changes, please contact our Support
      Team.
    </p>

    <!-- Bank account -->
    <div class="terra-form-field-wrapper">
      <label for="ddlBankAccount" class="text-input-label">Choose from existing:</label>
      <mat-form-field appearance="outline" *ngIf="bankAccounts$ | async as bankAccounts">
        <mat-select id="ddlBankAccount" [compareWith]="bankAccountComparer" [placeholder]="
          bankAccounts.length? 'Select' : 'No ' + selectedCurrency.iso + ' bank accounts found'" formControlName="bankAccount">
          <mat-option *ngIf="!fundHasBankAccount" [value]="null">Select</mat-option>
          @for (bankAccount of bankAccounts; track $index; let i = $index;) {
            <mat-option [value]="bankAccount">
              <!-- {{ bankAccount.nickname }} {{ !bankAccount.nickname ? bankAccount.holderFullName + ', ' : '' }} -->
              {{bankAccount.isUnitBankAccount ? bankAccount.nickname ? bankAccount.nickname : bankAccount.holderFullName + ', ' : bankAccount.nickname + " (External Account)" }} {{ !bankAccount.nickname ? bankAccount.holderFullName + ', ' : '' }}
              {{ !bankAccount.nickname ? getCountryById(bankAccount.countryId).code : '' }}
            </mat-option>
            <mat-divider class="unit-accounts-divider" *ngIf="gpBankAccountDataService.isLastUnitBank(bankAccounts, i)" />
          }
        </mat-select>
        <mat-hint *ngIf="!bankAccounts || !bankAccounts.length">To create a new bank account,
          please navigate to the <a class="link" (click)="gotoBankAccount()">Bank Accounts page</a></mat-hint>
      </mat-form-field>

      <div class="bank-details-wrapper" *ngIf="pageForm.get('bankAccount').value as bankAccount">
        <span class="bank-details-title" *ngIf="bankAccount.nickname">{{ bankAccount.nickname}} details:</span>
        <span class="bank-details-title" *ngIf="!bankAccount.nickname">{{ bankAccount.holderFullName}} account details:</span>
        <ul class="bank-details-list">
          <li *ngIf="bankAccount.holderFullName"><label>Account holder's name</label><span
            class="value">{{ bankAccount.holderFullName }}</span></li>
          <li *ngIf="bankAccount.bankName"><label>Bank</label><span class="value">{{ bankAccount.bankName }}</span></li>
          <li *ngIf="bankAccount.accountNumber"><label>Account number</label><span class="value">{{ bankAccount.accountNumber | accountNumberToAstrix : 4}}</span></li>
          <li *ngIf="bankAccount.ukSortCode"><label>UK sort code</label><span class="value">{{ bankAccount.ukSortCode }}</span></li>
          <li *ngIf="bankAccount.bankBranchName"><label>Bank branch name</label><span class="value">{{ bankAccount.bankBranchName }}</span>
          </li>
          <li *ngIf="bankAccount.bankBranchCode"><label>Bank branch code</label><span class="value">{{ bankAccount.bankBranchCode }}</span>
          </li>
          <li *ngIf="bankAccount.iban"><label>IBANK</label><span class="value">{{ bankAccount.iban }}</span></li>
          <li *ngIf="bankAccount.swift"><label>SWIFT</label><span class="value">{{ bankAccount.swift }}</span></li>
        </ul>
      </div>
      <span></span>
    </div>

    <!-- Early deposit checkbox -->
    <h3 class="section-title">
      Collect deposits into Covercy safeguarded account (optional)
    </h3>

    <p class="early-deposit-text description full-width">
      Guarantee the availability of funds at closing by requesting deposits
      during the fundraising process. The funds received will be parked in a
      segregated account until the closing terms are met.
    </p>

    <div class="full-width"  style="width: max-content;">
      <mat-checkbox class="regular-checkbox early-deposit-checkbox" color="primary" formControlName="isCollectDeposists">I want to collect
        deposits in Covercy's segregated account
      </mat-checkbox>

      <p *ngIf="pageForm.get('isCollectDeposists').value" #collectDepositNotice [@enterAnimation] class="info-message">
        Your Covercy account manager will reach out shortly with more
        information about early deposits.
      </p>
    </div>
  </div>

  <div class="full-width not-allow-message" *ngIf="noAccessMessage">
    <mat-icon class="notranslate">error</mat-icon>
    <h4>{{noAccessMessage}}</h4>
  </div>
</form>
