import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, merge, throwError, Subject, of } from 'rxjs';
import { catchError, switchMap, shareReplay, tap, map, switchMapTo, filter, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { DocumentsTabService } from '../documents-tab/documents-tab.service';
import { StorageObjectReqRes } from 'src/app/shared/models/StorageObjectReqRes.model';
import { GpHoldingService } from 'src/app/dashboard/shared/holding/gp-holding.service';
import { BaseResponseDto } from 'src/app/shared/models/BaseResponseDto.model';
import { UtilsService } from 'src/app/services/utils.service';
import { StorageObjectSearchOptionsResponse } from '../StorageObjectSearchOptionsResponse.model';

export class HoldingStorageObjectDataSource extends DataSource<StorageObjectReqRes> {

  private destroy$ = new Subject<void>();

  // Triggers for refreshing data
  private searchOptions$ = this.documentsTabService.searchOptions$;
  private refreshData$ = this.documentsTabService.refreshData$;
  private detectChangesDocuments$ = this.gpHoldingService.documentsChanged$
    .pipe(shareReplay(1));

  // Total number of rows on all pages
  private _totalRowsCount$ = new BehaviorSubject<number>(0);
  public totalRowsCount$ = this._totalRowsCount$.pipe(takeUntil(this.destroy$), distinctUntilChanged(), shareReplay(1));

  // The full virtual path to the currently displayed folder
  private _foldersPath$ = new BehaviorSubject<StorageObjectReqRes[]>([]);
  public foldersPath$ = this._foldersPath$.pipe(takeUntil(this.destroy$), distinctUntilChanged(), shareReplay(1));

  // For a loading indicator
  private _loading$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = this._loading$.pipe(takeUntil(this.destroy$), distinctUntilChanged(), shareReplay(1));

  public pageRows$ = merge(this.refreshData$, this.searchOptions$, this.detectChangesDocuments$).pipe(
    switchMapTo(this.searchOptions$),
    takeUntil(this.destroy$),
    filter(searchOptions => {
      return searchOptions !== null;
    }),
    switchMap(searchOptions => this.storageObjectDataService.getStorageObjects(searchOptions)),
    catchError(error => {
      if(error instanceof BaseResponseDto){
        this.utilsService.alertErrorMessage(error);
      }
      return of(new StorageObjectSearchOptionsResponse);
    }),
    tap(response => {
      this._totalRowsCount$.next(response.totalCount);
      this._foldersPath$.next(response.parentFolders ? response.parentFolders.reverse() : []);
    }),
    map(response => response.rows),
    shareReplay(1)
  );

  constructor(
    private storageObjectDataService: StorageObjectDataService,
    private documentsTabService: DocumentsTabService,
    private gpHoldingService: GpHoldingService,
    private utilsService: UtilsService) {
    super();
  }

  connect(collectionViewer: CollectionViewer = null): Observable<StorageObjectReqRes[]> {
    this._loading$.next(true);
    return this.pageRows$.pipe(
      tap(() => this._loading$.next(false)),
      catchError((error) => {
        this._loading$.next(false);
        return throwError(error);
      })
    );
  }

  disconnect(collectionViewer: CollectionViewer = null): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
