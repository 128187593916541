import InvestingEntityType from 'src/app/dashboard/models/InvestingEntityType.enum';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';

export class SetPermissionsDialogParams {
  HoldingDiscriminator: HoldingDiscriminator;
  storageObjectsIds: number[];
  contacts: Array<{
    id: number;
    name: string;
    nickname: string;
    email: string;
    type: InvestingEntityType;
  }>;
}
