<ng-container *ngIf="holding$ | async as holding">
  <!-- holding is down -->
  <div class="holding-down-page-stripe" *ngIf="holding.isPrivate">
    <mat-icon svgIcon="holding_down" class="notranslate holding-down-icon"></mat-icon>
    <span> {{discriminatorStr | titlecase}} is down. When you’re ready to put it back up, go to the
      <a class="light link" [routerLink]="distributionsRoute">
        {{discriminatorStr | lowercase}} page</a> and change it.
      <a class="light link" (click)="holdingDownInfo()"> Learn more</a></span>
  </div>

  <!-- Breadcrumbs -->
  <div class="container-800">
    <div class="breadcrumbs">
      <a [routerLink]="distributionsRoute">
        <span class="lo_sensitive">{{ holding.name }}</span> > Distributions >
      </a>
      <Span class="current">Import History</Span>
    </div>
  </div>

  <!-- Loader -->
  <terra-loader *ngIf="(isLoading$ | async)" class="page-loader"></terra-loader>

  <div *ngIf="!(isLoading$ | async)" class="container-800">
    <h1>Import Distribution History</h1>
    <p><cite>Please fill in the basic details for each distribution you wish to add.</cite></p>
    <div *ngIf="distributionsDetails">
      <div class="distributions-list-item mat-expansion-panel"
           *ngFor="let details of distributionsDetails;let i = index">
        <terra-distribution-details [details]="details"
                                    [currencies]="currencies$ | async">
        </terra-distribution-details>
        <button [ngClass]="{'hidden':distributionsDetails.length === 1}" class="remove-button" mat-icon-button
                (click)="removeDetail(i)">
          <span class="material-icons">remove_circle_outline</span>
        </button>
      </div>
    </div>
    <div class="actions">
      <button class="add-distribution-button" mat-button (click)="addDistribution()">
        <span class="material-icons notranslate">add_circle_outline</span>Add Distribution
      </button>
      <button class="import-distribution-button" mat-flat-button color="primary" (click)="importDistribution()">
        <mat-icon class="notranslate">add</mat-icon>
        Import Distribution History
      </button>
    </div>
  </div>

</ng-container>
