export enum OutputType {
    Pdf = 0,
    Csv = 1,
    Excel = 2
}

export enum RentManagerReport {
    ProfitAndLoss = 0,
    BalanceSheet = 1,
    CashFlow = 2,
    RentRole = 3
}

export enum AccountingMethod {
  Cash = 0,
  Accrual = 1
}

export enum MarketRent {
  ProrateMarketRent = 0,
  MarketRentBasedOn1Month = 1
}

export enum SecurityDepositDate {
  FirstDayOfPeriod = 0,
  LastDayOfPeriod = 1
}

export enum RentRoleReportMethod {
  CurrentTenantsOnly = 0,
  ActivityInThePeriod = 1,
  ActivityAndPriorBalances = 2
}

export enum SeparationMethod {
  NoSeparation = 0,
  ByProperty = 1,
  ByUnit = 2
}

export function getOutputTypeName(type: OutputType): string {
    switch (type) {
        case OutputType.Pdf:
            return "PDF";
        case OutputType.Csv:
            return "CSV";
        case OutputType.Excel:
            return "EXCEL";
        default:
            return "Unknown";
    }
}

export function getRentManagerReportName(report: RentManagerReport): string {
    switch (report) {
        case RentManagerReport.ProfitAndLoss:
            return "Profit and Loss";
        case RentManagerReport.BalanceSheet:
            return "Balance Sheet";
        case RentManagerReport.CashFlow:
            return "Cash Flow";
        case RentManagerReport.RentRole:
            return "Rent Roll";
        default:
            return "Unknown"; 
    }
}