import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, of, timer} from 'rxjs';
import {distinctUntilChanged, map, switchMap, take} from 'rxjs/operators';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ResourceService} from 'src/app/services/resource.service';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {ClientBankAccountResponseBasic} from 'src/app/dashboard/models/bankAccount.model';
import {FundAndFundraisingReqRes} from './FundAndFundraisingRequest';
import {GpFundReqRes} from 'src/app/dashboard/funds/GpFundReqRes.model';
import {FundraisingReqRes} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {HoldingFileReqRes} from 'src/app/dashboard/shared/holding/HoldingFileReqRes.model';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';
import {LenderInformation} from 'src/app/shared/models/LenderInformation.model';
import {LocationDetailsResponse} from 'src/app/shared/models/LocationDetailsResponse.model';
import {ProjectedPerformance} from 'src/app/shared/models/ProjectedPerformance.model';
import {GpFundDataService} from 'src/app/services/gp/gp-fund-data.service';
import {RoutingService} from 'src/app/services/routing.service';
import FundInvestmentStrategyType from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {ProjectBudget} from 'src/app/shared/models/ProjectBudget.model';
import {CustomValidators} from 'src/app/shared/validators/custom.validators';
import {UserService} from 'src/app/services/shared/user.service';
import {GpHoldingDataService} from 'src/app/services/gp/gp-holding-data.service';
import {InvestingEntityReqRes} from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import {AddressFields} from '../../../../shared/components/address-form/address-form.settings';
import {CreateEditHoldingService} from '../../../shared/holding/create-edit-holding.service';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';
import { OpportunityDto } from 'src/app/dashboard/shared/holding/OpportunityDto.model';
import { Base64 } from 'js-base64';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import { CommitmentsEnabled } from 'src/app/shared/enums/CommitmentsEnabled.enum';
import { OwnershipCalculationType } from 'src/app/shared/enums/OwnershipCalculationType.enum';

export type CreateFundStepNumber = 1 | 2 | 3 | 4;

@Injectable({
  providedIn: 'root'
})
export class CreateFundService extends CreateEditHoldingService {
  readonly IS_STEP_SUBMITTED_FIELD_NAME = 'IS_STEP_SUBMITTED';
  readonly wizardStepPaths = ['fund-information', 'investment-details', 'marketing-material', 'additional'];

  currentStep$ = new BehaviorSubject((this.getCurrentStepIndexFromUrl() + 1 || 1) as CreateFundStepNumber);
  createFundForm: UntypedFormGroup;

  processingSubmit$ = new BehaviorSubject(false);
  isGeneralServerError = false;

  currencies$: Observable<CurrencyModel[]>;

  defaultCurrencyISO = 'USD';

  fundCreated = false;


  constructor(
    private fb: UntypedFormBuilder,
    private gpFundDataService: GpFundDataService,
    private gpHoldingDataService: GpHoldingDataService,
    private router: Router,
    private route: ActivatedRoute,
    private numberPipe: TerraNumberPipe,
    private routingService: RoutingService,
    private userService: UserService,
    private telemetryService: TelemetryService,
    private utilsService: UtilsService,
    resourceService: ResourceService) {
    super(resourceService);
    this.generateForm();
    this.initStepsNavigationHandler();
  }

  get selectedInvestmentStrategyType() {
    return this.getStepForm(1).get('investmentStrategyType').value as FundInvestmentStrategyType;
  }

  get selectedCurrency() {
    const currency = this.getStepForm(1).get('currency').value;
    return currency as CurrencyModel;
  }

  get selectedSecurityType(): InvestmentSecurityType {
    try {
      return this.getStepForm(1).get('investmentSecurityType').value;
    } catch {
      return InvestmentSecurityType.Equity;
    }
  }

  initStepsNavigationHandler() {
    // Whenever the currentStep Changes, the user will be redirected
    this.currentStep$.pipe(distinctUntilChanged()).subscribe(
      step => {
        // Navigate to the first if the previous step was not submitted
        if (step > 1 && !this.isStepSubmitted(step - 1 as CreateFundStepNumber)) {
          this.currentStep$.next(1);
        } else {
          const createFundWizardBaseUrl = this.routingService.createFund();
          const specificStepUrl = this.getStepPathByNumber(step);
          this.router.navigateByUrl(`${createFundWizardBaseUrl}/${specificStepUrl}`);
        }
      });
  }

  cancel() {
    this.router.navigateByUrl(this.routingService.gpFundsPage);
  }

  getStepForm(stepNumber: CreateFundStepNumber) {
    const stepFormName = 'step' + stepNumber;
    if (stepFormName && this.createFundForm) {
      return this.createFundForm.get(stepFormName) as UntypedFormGroup;
    }
    return null;
  }

  getStepPathByNumber(step: CreateFundStepNumber) {
    return this.wizardStepPaths[step - 1];
  }

  isStepSubmitted(stepNumber: CreateFundStepNumber) {
    const step = this.getStepForm(stepNumber);
    return step.get(this.IS_STEP_SUBMITTED_FIELD_NAME).value as boolean;
  }

  setStepAsSubmitted(stepNumber: CreateFundStepNumber, isSubmitted = true) {
    this.getStepForm(stepNumber).get(this.IS_STEP_SUBMITTED_FIELD_NAME).setValue(isSubmitted);
  }

  getCurrentStepIndexFromUrl() {
    const wizardStep = this.route.snapshot.children[0].url[0].path.toLowerCase();
    const index = this.wizardStepPaths.findIndex(x => x === wizardStep.toString());
    return index;
  }

  generateForm() {

    this.userService.accountDetails$.pipe(untilComponentDestroyed(this), take(1)).subscribe(accountDetails => {

      this.createFundForm = this.fb.group({
        step1: this.fb.group({
          fundName: [null, [Validators.required, Validators.minLength(2)], this.validateHoldingName.bind(this)],
          estimatedClosingDate: [null, [Validators.required]],
          investmentSecurityType: [InvestmentSecurityType.Equity, Validators.required],
          currency: [null, [Validators.required]],
          fundraisingTargetAmount: [null, Validators.compose([Validators.required, Validators.min(1)])],
          minimumInvestmentAmount: null,
          investmentStrategyType: [null, [Validators.required]],
          investmentStrategyTypeOther: null,
          commitmentsEnabled: CommitmentsEnabled.Yes,
          ownershipCalculationType: OwnershipCalculationType.ByContributions,

          fundLocation: null,
          email: [accountDetails.email, [Validators.required, CustomValidators.EmailWithSpaces]]
        }),
        step2: this.fb.group({
          projectedPerformance: this.fb.group({
            cashOnCashRate: null,
            nonLeveragedCapRate: null,
            targetInvestmentIrr: null,
            targetInvestmentIrrMax: null,
            expectedRoi: null,
            targetEquityMultiplier: null,
            targetEquityMultiplierMax: null,
            targetInvestmentPeriod: null,
            preferredReturn: null
          }),
          projectBudgetOriginal: this.fb.group({
            acquisition: null,
            hardCost: null,
            softCost: null,
            financing: null,
            totalCosts: null,
            totalCostsPerGrossAreaUnit: null,
            totalCostsPerNetAreaUnit: null
          }),
          lenderInformation: this.fb.group({
            lenderName: null,
            amount: null,
            loanToValue: null,
            interestRate: null,
            interestType: null,
            closingDate: null,
            maturityDate: null,
            additionalTerms: ['', Validators.maxLength(TerraUtils.consts.validators.GENERAL_LONG_STRING_MAX_LENGTH)],
            loanType: null,
            totalFinancingPerGrossAreaUnit: null,
            totalFinancingPerNetAreaUnit: null
          }),
        }),
        step3: this.fb.group({
          fundPhotos: this.fb.array([]),
          attachments: this.fb.array([]),
          marketingDeckDocument: [null, null, this.validateOfferingDeckFileTypeSupported.bind(this)],
          marketingDeckDocumentZoom: ['auto'],
          marketingDeckDocumentZoomValue: null,
          description: '',
          isVisible: [{value: FeatureFlags.isOn(accountDetails.featureFlags, FeatureFlags.InvestNow), disabled: !FeatureFlags.isOn(accountDetails.featureFlags, FeatureFlags.InvestNow)}],
          video: null,
          shouldShowPlayer: false
        }),
        // investment details
        step4: this.fb.group({
          bankAccount: null,
          isCollectDeposists: false,
          isPrivate: false,
          // contact: this.fb.group({
          //   email: [null, Validators.required]
          // }),
          investingEntity: this.fb.group({
            name: ['', [Validators.required, Validators.minLength(2)]],
            investingEntityType: [null, Validators.required],
            tin: '',
            accountingExternalId: '',
            remarks: '',
          }),
          allowLpSetBank: true
        }),
      });

      this.createFundForm.get('step1.investmentStrategyType').valueChanges.subscribe(val => {
        if (val === FundInvestmentStrategyType.Other) {
          this.createFundForm.get('step1.investmentStrategyTypeOther').setValidators([Validators.required, Validators.maxLength(200)]);
        } else {
          this.createFundForm.get('step1.investmentStrategyTypeOther').setValidators([Validators.maxLength(200)]);
        }
        this.createFundForm.get('step1.investmentStrategyTypeOther').updateValueAndValidity();
      });

      // add the IS_STEP_SUBMITTED field for each step:
      for (let i = 1; i <= 4; i++) {
        this.getStepForm(i as CreateFundStepNumber).addControl(this.IS_STEP_SUBMITTED_FIELD_NAME, this.fb.control(false));
      }

      const photosArray = this.createFundForm.get('step3.fundPhotos') as UntypedFormArray;
      for (let i = 0; i < 8; i++) {
        photosArray.push(this.fb.control(null, {asyncValidators: this.validatePhotoFileTypeSupported.bind(this)}));
      }

      this.pushMarketingAttachment();

      this.getCurrencies();
    });

  }

  pushMarketingAttachment(){
    const attachments = this.createFundForm.get('step3.attachments') as UntypedFormArray;
    if(attachments.length < 10){
      attachments.insert(0, this.fb.control(null, {asyncValidators: this.validatePhotoFileTypeSupported.bind(this)}), {emitEvent:false});
    }
  }

  popMarketingAttachment(ind:number){
    const attachments = this.createFundForm.get('step3.attachments') as UntypedFormArray;
    attachments.removeAt(ind, {emitEvent:false});
  }

  generateSubmitModel(): FundAndFundraisingReqRes {
    const formValues = this.createFundForm.value;
    const step1 = formValues.step1;
    const step2 = formValues.step2;
    const step3 = formValues.step3;
    const step4 = formValues.step4;

    const fundModel = new GpFundReqRes();
    const fundraisingModel = new FundraisingReqRes();

    // Step1
    fundModel.name = step1.fundName;
    fundraisingModel.estimatedClosingDate = step1.estimatedClosingDate;
    fundraisingModel.securityType = InvestmentSecurityType.Equity;
    if (step1.currency) {
      fundraisingModel.fundraisingTargetCurrencyId = step1.currency.id;
    }
    fundraisingModel.fundraisingTargetAmount = this.numberPipe.parse(step1.fundraisingTargetAmount);
    fundraisingModel.minimumInvestmentAmount = this.numberPipe.parse(step1.minimumInvestmentAmount);
    fundModel.investmentStrategyType = step1.investmentStrategyType;
    fundModel.investmentStrategyTypeOther = step1.investmentStrategyTypeOther;
    fundModel.commitmentsEnabled = step1.commitmentsEnabled ? CommitmentsEnabled.Yes : CommitmentsEnabled.No;
    fundModel.ownershipCalculationType = step1.ownershipCalculationType ? OwnershipCalculationType.ByCommitments : OwnershipCalculationType.ByContributions;

    fundModel.email = step1.email;
    fundModel.location = {...step1.fundLocation.address, street2Name: step1.fundLocation.street2Name} as LocationDetailsResponse;

    // Step 2
    fundModel.projectedPerformance = new ProjectedPerformance();
    const projectedPerfModel = fundModel.projectedPerformance;

    projectedPerfModel.cashOnCashRate = this.numberPipe.parse(step2.projectedPerformance.cashOnCashRate);
    projectedPerfModel.nonLeveragedCapRate = this.numberPipe.parse(step2.projectedPerformance.nonLeveragedCapRate);
    projectedPerfModel.targetInvestmentIrr = this.numberPipe.parse(step2.projectedPerformance.targetInvestmentIrr);
    projectedPerfModel.targetInvestmentIrrMax = this.numberPipe.parse(step2.projectedPerformance.targetInvestmentIrrMax);
    projectedPerfModel.expectedRoi = this.numberPipe.parse(step2.projectedPerformance.expectedRoi);
    projectedPerfModel.targetEquityMultiplier = this.numberPipe.parse(step2.projectedPerformance.targetEquityMultiplier);
    projectedPerfModel.targetEquityMultiplierMax = this.numberPipe.parse(step2.projectedPerformance.targetEquityMultiplierMax);
    projectedPerfModel.targetInvestmentPeriod = this.numberPipe.parse(step2.projectedPerformance.targetInvestmentPeriod);
    projectedPerfModel.preferredReturn = this.numberPipe.parse(step2.projectedPerformance.preferredReturn);

    // Step 2 - Original budget:
    fundModel.projectBudgetOriginal = new ProjectBudget();
    const budgetModel = fundModel.projectBudgetOriginal;
    const budgetFormValues = step2.projectBudgetOriginal;
    budgetModel.acquisition = this.numberPipe.parse(budgetFormValues.acquisition);
    budgetModel.hardCost = this.numberPipe.parse(budgetFormValues.hardCost);
    budgetModel.softCost = this.numberPipe.parse(budgetFormValues.softCost);
    budgetModel.financing = this.numberPipe.parse(budgetFormValues.financing);
    budgetModel.totalCosts = this.numberPipe.parse(budgetFormValues.totalCosts);
    budgetModel.totalCostsPerGrossAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerGrossAreaUnit);
    budgetModel.totalCostsPerNetAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerNetAreaUnit);

    // Step 2 - Lender information:
    fundModel.lenderInformation = new LenderInformation();
    const lenderInfoModel = fundModel.lenderInformation;

    lenderInfoModel.lenderName = step2.lenderInformation.lenderName;
    lenderInfoModel.amount = this.numberPipe.parse(step2.lenderInformation.amount);
    lenderInfoModel.loanToValue = this.numberPipe.parse(step2.lenderInformation.loanToValue);
    lenderInfoModel.interestRate = this.numberPipe.parse(step2.lenderInformation.interestRate);
    lenderInfoModel.interestType = this.numberPipe.parse(step2.lenderInformation.interestType);
    lenderInfoModel.closingDate = step2.lenderInformation.closingDate;
    lenderInfoModel.maturityDate = step2.lenderInformation.maturityDate;
    lenderInfoModel.additionalTerms = step2.lenderInformation.additionalTerms;
    lenderInfoModel.loanType = step2.lenderInformation.loanType;
    lenderInfoModel.totalFinancingPerGrossAreaUnit = this.numberPipe.parse(step2.lenderInformation.totalFinancingPerGrossAreaUnit);
    lenderInfoModel.totalFinancingPerNetAreaUnit = this.numberPipe.parse(step2.lenderInformation.totalFinancingPerNetAreaUnit);

    // Step 3

    // Add the offering deck
    if (step3.marketingDeckDocument) {
      fundraisingModel.offeringDeckId = (step3.marketingDeckDocument as MetaFileLink).id;
    }
    // Add the photos:
    fundModel.attachments = new Array<HoldingFileReqRes>();
    try {
      const photosArray = step3.fundPhotos;
      photosArray
        .forEach((photoMetaFileLink: MetaFileLink) => {
          if (photoMetaFileLink) {
            const file = new HoldingFileReqRes();
            file.metaFileLinkId = photoMetaFileLink.id;
            file.fileType = HoldingFileType.Photo;
            fundModel.attachments.push(file);
          }
        });
    } catch (error) {
      console.log('ERROR while trying to add photos to the submit model.', error);
    }

    fundModel.opportunity = this.generateOpportunityModel(step3);


    fundModel.investNow = step3.isVisible;

    // Step 4
    fundraisingModel.clientBankAccountId = step4.bankAccount && !step4.bankAccount.isUnitBankAccount ? (step4.bankAccount as ClientBankAccountResponseBasic).id : null;
    fundraisingModel.unitBankAccountId = step4.bankAccount && step4.bankAccount.isUnitBankAccount ? (step4.bankAccount as ClientBankAccountResponseBasic).id : null;
    fundModel.isCollectDeposists = step4.isCollectDeposists;
    fundModel.isPrivate = step4.isPrivate;
    fundModel.allowLpSetBank = step4.allowLpSetBank;

    var ieValue = step4.investingEntity;
    fundModel.investingEntity = {
      name: ieValue.name,
      investingEntityType: ieValue.investingEntityType,
      tin: ieValue.tin,
      accountingExternalId: ieValue.accountingExternalId,
      remarks: ieValue.remarks,
      // the IE location will use the contact location (which is the fund location)
      useContactLocation: true,
      investingEntityLocation: null
    } as InvestingEntityReqRes;

    return new FundAndFundraisingReqRes(fundModel, fundraisingModel, false);
  }

  generateOpportunityModel(step3){
    if(!!step3.description || !!step3.video || !!step3.attachments || step3.marketingDeckDocumentZoomValue){
      const opportunity = new OpportunityDto();
      opportunity.description = step3.description ? Base64.encode(step3.description) : undefined;
      opportunity.video = step3.shouldShowPlayer ? Base64.encode(step3.video) : undefined;
      opportunity.attachments = [];
      opportunity.documentZoomValue = step3.marketingDeckDocumentZoom === 'manual' ? step3.marketingDeckDocumentZoomValue : null;

      step3.attachments.filter(d => !!d).forEach(d => opportunity.attachments.push(d as MetaFileLink));

      return opportunity;
    }
    return null;
  }

  createFund() {
    this.isGeneralServerError = false;

    if (this.createFundForm.valid) {
      const model = this.generateSubmitModel();

      this.processingSubmit$.next(true);
      this.gpFundDataService
        .createFund(model)
        .subscribe(
          fundAndFundraising => {
            this.processingSubmit$.next(false);
            this.fundCreated = true;
            this.router.navigateByUrl(TerraUtils.consts.paths.FUNDS + '/' + fundAndFundraising.fund.id);
            this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
              this.telemetryService.create({
                eventID: '16',
                eventTitle: 'CREATE FUND (COMPLETED)',
                organizationID: clientDetails.organizationDetails.id,
                holdingID: fundAndFundraising.fund.id
              }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
            });
          },
          error => {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            } else {
              this.isGeneralServerError = true;
            }
            this.processingSubmit$.next(false);
          }
        );
    }
  }

  getCurrencies() {
    this.resourceService.getOutboundCurrencies().subscribe(response => {
      this.currencies$ = of(response.filter(c => !c.isInbound));
      // Set default currency in dropdown if it is available
      const defaultCurrency = response.filter(c => c.iso === this.defaultCurrencyISO)[0];
      if (defaultCurrency) {
        this.getStepForm(1).get('currency').setValue(defaultCurrency);
      }
    });
  }

  protected isHoldingNameExist(holdingName: string): Observable<boolean> {
    return this.gpFundDataService.isFundNameExists(holdingName);
  }

  validateOfferingDeckFileTypeSupported(control: AbstractControl) {

    // const { value: metaFile }: { value: MetaFileLink } = control; // Possible using destructing :)
    // And then: metaFile.id

    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingDataService.isFileSupportedOfferingDeck(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

  validatePhotoFileTypeSupported(control: AbstractControl) {
    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingDataService.isFileSupportedPhoto(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

  get addressFormFieldsToShow(): AddressFields {
    // tslint:disable-next-line:no-bitwise
    return AddressFields.Country | AddressFields.City | AddressFields.Street |
           AddressFields.Street2 | AddressFields.State | AddressFields.PostalCode;
  }
}
