import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
 
import { UserService } from 'src/app/services/shared/user.service';
import { CurrencyModel } from 'src/app/shared/models/CurrencyModel';
import { DistributionTransactionPurpose } from '../../../../../shared/enums/DistributionTransactionPurpose.enum';
import { DistributionTransferReqRes } from '../DistributionTransferReqRes.model';
import { DistributionService } from '../distribution.service';

export interface ISendNoticeConfirmationContext {
  transfers: DistributionTransferReqRes[];
  holdingName: string;
  distributionName: string;
  personalMessage: string;
  distributionSourceCurrency: CurrencyModel;
  totalAmountForDistribution: number;
  allowLpSetBank: boolean;
}

@Component({
  selector: 'terra-send-notice-confirmation',
  templateUrl: './send-notice-confirmation.component.html',
  styleUrls: ['./send-notice-confirmation.component.scss']
})
export class SendNoticeConfirmationComponent implements OnInit {
  DistributionType = DistributionTransactionPurpose;
  gpDetails$ = this.userService.accountDetails$;
  differentialPricings;
  isMultiTypeDistribution = this.distributionService.isMultiTypeDistribution(this.context.transfers);

  pageForm: UntypedFormGroup;

  private _exampleIndex = 0;
  get exampleIndex() {
    return this._exampleIndex;
  }

  private get lastIndex() {
    return this.context.transfers.length - 1;
  }

  get isMultipleTransfer() {
    return this.context.transfers.length > 1;
  }

  nextExample() {
    if (this.exampleIndex === this.lastIndex) {
      this._exampleIndex = 0;
    } else {
      this._exampleIndex++;
    }
  }

  previousExample() {
    if (this.exampleIndex === 0) {
      this._exampleIndex = this.lastIndex;
    } else {
      this._exampleIndex--;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public context: ISendNoticeConfirmationContext,
              private userService: UserService,
              private fb: UntypedFormBuilder,
              private dialogRef: MatDialogRef<SendNoticeConfirmationComponent>,
              private distributionService: DistributionService
  ) {
  }

  ngOnInit() {
    this.gpDetails$.pipe(take(1)).subscribe(details => this.differentialPricings = details.differentialPricings);
    this.pageForm = this.fb.group(
      {
        personalMessage: this.context.personalMessage || ''
      });
  }

  getReceivingCurrency(transfer: DistributionTransferReqRes) {
    return (transfer.clientBankAccount && transfer.clientBankAccount.preferredCurrency) || this.context.distributionSourceCurrency;
  }

  send(){
    this.dialogRef.close({
      ...this.pageForm.value, 
      personalMessage: this.pageForm.value.personalMessage || null
    });
  }
}
