<terra-dialog-header>
  <h1>Add Bank Account</h1>
</terra-dialog-header>

<mat-dialog-content class="flex flex-column gap-3">
  <div class="supportive-text">
    Adding bank account allows you to view your account balances and transaction history in real-time to streamline investments and ensure efficient processing.
  </div>

  <div class="account-types flex flex-row gap-4">
    <div class="account-type-cube flex flex-column flex-1 align-items-center gap-3 p-3" [ngClass]="{'purple-border': accountType.border === 'primary' }" *ngFor="let accountType of accountTypes">
      <terra-name-icon class="account-logo" [svgIcon]="accountType.icon.type === 'SVG' ? accountType.icon.name : null" [icon]="accountType.icon.type === 'TEXT' ? accountType.icon.name : null"></terra-name-icon>
      <span class="account-title">{{accountType.title}}</span>
      <div class="account-attributes w-full	flex flex-column flex-1 justify-content-start gap-3">
        <div class="flex flex-row gap-1" *ngFor="let accountTypeAttr of accountType.attributes">
          <mat-icon class="account-type-attribute-icon" [svgIcon]="accountTypeAttr.icon.type === 'SVG' ? accountTypeAttr.icon.name : null">{{accountTypeAttr.icon.type === 'TEXT' ? accountTypeAttr.icon.name : null}}</mat-icon>
          <span>{{accountTypeAttr.text}}</span>
        </div>
      </div>
      <terra-buttons
      *ngIf="accountType.button"
      [type]="accountType.button.type"
      [label]="accountType.button.text"
      [icon]="accountType.button.icon?.type === 'TEXT' ? accountType.button.icon?.name : null"
      [outlinedIcon]="true"
      [loading]="accountType.button.loading$ | async"
      (click)="accountType.button.action()"
    ></terra-buttons>
    </div>
  </div>
</mat-dialog-content>
