// tslint:disable: no-string-literal

/*

Reports to Lucky Orange on every navigation.

*/
import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, NavigationStart} from '@angular/router';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {UserService} from 'src/app/services/shared/user.service';

@Component({
  selector: 'payapp-lucky-orange',
  templateUrl: './lucky-orange.component.html'
  // styleUrls: ['./lucky-orange.component.css']
})
export class LuckyOrangeComponent extends OnDestroyMixin implements OnInit {

  isLoggedIn = false;

  // clientDetails: ClientDetailsResponse = null;

  constructor(private userService: UserService, private router: Router) {
    super();
    router.events.subscribe(val => {
      if (val instanceof NavigationStart && val.url.includes('reset-password')) {
        val.url = val.url.substr(0, 16); // '/reset-password/' length = 15
      }
      if (val instanceof NavigationEnd) {
        this.setVariables();
      }
    });
  }

  ngOnInit() {
    this.setVariables();
  }

  setVariables() {
    const customData = {
      name: '',
      ClientIdentifier: '',
      isCovercyEmail: null
    };
    if (!this.userService.isLoggedIn()) {
      window['_loq'] = window['_loq'] || [];
      window['_loq'].push(['custom', customData]);
    } else {
      // User is logged in:
      this.userService.accountDetails$.pipe(untilComponentDestroyed(this)).subscribe(
        user => {
          if (user) {
            customData.name = user.clientIdentifier;
            customData.ClientIdentifier = user.clientIdentifier;
            customData.isCovercyEmail = (user.email.toLowerCase().indexOf('covercy') !== -1) ? "yes" : "no";
          }
          window['_loq'] = window['_loq'] || [];

          // if there is no custo data property yet, add it:
          if (!window['_loq'].custom) {
            window['_loq'].push(['custom', customData]);
          } else {
            // If custom data property already exists, only update it.
            window['_loq'].custom = {...window['_loq'].custom, customData};
          }
        },
        error => {
          window['_loq'] = window['_loq'] || [];
          window['_loq'].push(['custom', customData]);
        });
    }
  }
}
