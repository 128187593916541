import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { CreateFolderValidator } from './create-folder.validator';

@Component({
  selector: 'terra-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
  // standalone
})
export class CreateFolderComponent implements OnChanges, OnInit {
  @Input() holdingId!: number; // required
  @Input() currentFolderId: number = null;

  @Output() update: EventEmitter<string> = new EventEmitter();
  @Output() disable: EventEmitter<boolean> = new EventEmitter();

  folderName = new FormControl(''); // updateOn: 'blur'
  placeholder = `K1 ${new Date().getFullYear() - 1}`;
  constructor(
    private readonly storageObjectDataService: StorageObjectDataService
  ) {}

  ngOnInit(): void {
    this.folderName.statusChanges.subscribe((status) => {
      if (status === 'PENDING' || status === 'INVALID') {
        this.disable.emit(true);
      } else {
        this.disable.emit(false);
      }
    });
    this.folderName.valueChanges.subscribe((value) => {
      this.update.emit(value.trim());
    });
  }

  ngOnChanges(): void {
    this.folderName.setAsyncValidators([
      CreateFolderValidator.createValidator(this.storageObjectDataService, {
        currentFolderId: this.currentFolderId,
        holdingId: this.holdingId,
      }),
    ]);
    this.folderName.updateValueAndValidity();
  }
}
