import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {map, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import {EditFundStepBase, EditFundTabNumber} from '../EditFundStepBaseAndInterface';
import FundInvestmentStrategyType from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ResourceService} from 'src/app/services/resource.service';
import {EditFundService} from '../edit-fund.service';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {InvestmentSecurityType} from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {AddressFields, AddressFormSettings} from 'src/app/shared/components/address-form/address-form.settings';

@Component({
  selector: 'terra-edit-fund-tab-fund-information',
  templateUrl: './edit-fund-tab-fund-information.component.html',
  styleUrls: ['./edit-fund-tab-fund-information.component.scss']
})
export class EditFundTabFundInformationComponent extends EditFundStepBase implements OnInit {

  readonly TAB_NUMBER: EditFundTabNumber = 1;
  forbiddenCharsInHoldingName = this.editFundService.forbiddenCharsInHoldingName;

  showInternalLoader = false;

  // enum
  HoldingType = HoldingType;
  FundInvestmentStrategyType = FundInvestmentStrategyType;
  AreaUnit = AreaUnit;
  InvestmentSecurityType = InvestmentSecurityType;

  // Enum value lists
  fundTypesList = HoldingType.listAll();
  InvestmentStrategyTypesList = FundInvestmentStrategyType.listAll();
  InvestmentSecurityTypesList = InvestmentSecurityType.optionsForFundFundraising();

  /// The date that tha picker will open to
  estimatedClosingDateStartAt = new Date();
  estimatedClosingDateMinimumDate = new Date('1970-01-01T12:00');

  currencies$: Observable<CurrencyModel[]> = this.resourceService.getOutboundCurrencies().pipe(
    map(response => response.filter(c => !c.isInbound)),
    shareReplay()
  );


  // bankAccounts$: Observable<ClientBankAccountResponseBasic[]>;

  allCountries = this.resourceService.allCountries;
  allStates = this.resourceService.allStates;
  countryCodes = TerraUtils.consts.countryCode;
  USCountryId = this.resourceService.getCountryByCode(this.countryCodes.US).id;

  location = this.editFundService.editFundDialogContext.fundDetails.location;
  addressFormSettings = new AddressFormSettings(this.editFundService.addressFormFieldsToShow, AddressFields.Country);

  isDisplayDataPoint(fundTypeList: HoldingType[]): boolean {
    if (!this.pageForm.get('holdingType').value) {
      return false;
    }
    const holdingType = Number(this.pageForm.get('holdingType').value) as HoldingType;
    return fundTypeList.some(val => val === holdingType);
  }

  get addressForm() {
    return this.pageForm.get('fundLocation') as UntypedFormGroup;
  }

  get capitalCallForm() {
    return this.pageForm.get('capitalCall') as UntypedFormGroup;
  }

  get selectedSecurityType(): InvestmentSecurityType {
    return this.editFundService.selectedSecurityType;
  }

  // get hasCapitalCall() {
  //   return !!this.dialogContext.fundraisingDetails.capitalCall;
  // }

  constructor(
    private resourceService: ResourceService,
    private editFundService: EditFundService
  ) {
    super(editFundService);
  }

  ngOnInit() {
    // if (this.hasCapitalCall) {
    //   this.subscribeToCapitalCallRelatedInfoChange();
    // }

    if(this.isExample){
      this.pageForm.get('name').disable();
    }
  }

/*  subscribeToCapitalCallRelatedInfoChange() {
    this.pageForm.get('estimatedClosingDate').valueChanges.pipe(untilComponentDestroyed(this)).subscribe(closingDate => {
      this.capitalCallForm.get('dueDate').patchValue(closingDate);
    });
    this.pageForm.get('fundraisingTargetAmount').valueChanges.pipe(untilComponentDestroyed(this)).subscribe(targetAmount => {
      this.capitalCallForm.get('callAmount').patchValue(targetAmount);
    });
    this.pageForm.get('currency').valueChanges.pipe(untilComponentDestroyed(this)).subscribe(currency => {
      this.capitalCallForm.get('currency').patchValue(currency);
    });
  }*/

  conutryTrackByFn(index, item: CountryModel) {
    return item.id; // or index
  }

  currencyCompareFn(c1: CurrencyModel, c2: CurrencyModel) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
