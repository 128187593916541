import { Injectable } from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { PickerResponse } from 'filestack-js';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { LoggerService } from 'src/app/shared/errors/logger.service';
import { StorageFolderTreeNode } from 'src/app/shared/models/StorageFolderTreeNode.model';
import { StorageObjectPolicyReqRes } from 'src/app/shared/models/StorageObjectPolicyReqRes.model';
import {
  HoldingStorageObjectReqRes,
  StorageObjectReqRes,
} from 'src/app/shared/models/StorageObjectReqRes.model';
import { GpHoldingService } from '../../gp-holding.service';

@Injectable()
export class UploadDocumentsService extends OnDestroyMixin {
  constructor(
    private storageObjectDataService: StorageObjectDataService,
    private gpHoldingService: GpHoldingService,
    private logger: LoggerService
  ) {
    super();
  }
  public readonly maxDisplayNameLength = 96;
  private refreshQuotaDetails$ = new Subject<void>();
  private filestackPolicy$ = new BehaviorSubject<StorageObjectPolicyReqRes>(
    null
  );

  private _sessionId: string;

  get sessionId(): string {
    return this._sessionId;
  }

  set sessionId(value: string) {
    this._sessionId = value;
  }

  quotaDetails$ = this.refreshQuotaDetails$.pipe(
    startWith([null]),
    switchMap(() => this.storageObjectDataService.getQuota(this.sessionId)),
    shareReplay(1)
  );

  documentsChanged$ = this.gpHoldingService.documentsChanged$;

  files$ = new BehaviorSubject<HoldingStorageObjectReqRes[]>([]);

  getQuotaRefreshed() {
    return this.storageObjectDataService
      .getQuota(this.sessionId)
      .pipe(tap(() => this.refreshQuotaDetails$.next()));
  }

  getFilestackPolicy(): Observable<StorageObjectPolicyReqRes> {
    if (
      !this.filestackPolicy$.value ||
      this.filestackPolicy$.value.expiry <= new Date()
    ) {
      return this.storageObjectDataService.getFilestackPolicy().pipe(
        map((policy) => {
          policy.expiry = new Date(Date.now() + 50 * 60 * 1000); // 50 minutes
          return policy;
        }),
        tap((policy) => {
          this.filestackPolicy$.next(policy);
        })
      );
    } else {
      return this.filestackPolicy$;
    }
  }

  createFiles(
    pickerResponse: PickerResponse,
    containingFolderId?: number
  ): Observable<HoldingStorageObjectReqRes[]> {
    let model: HoldingStorageObjectReqRes[];
    return this.gpHoldingService.holdingId$.pipe(
      take(1),
      switchMap((holdingId) => {
        model = this.generateCreateFilesSubmitModel(
          pickerResponse,
          this.sessionId,
          holdingId,
          containingFolderId
        );
        return this.storageObjectDataService.createFiles(model);
      }),
      tap((files) => {
        const previousFiles = this.files$.value;
        this.files$.next([...previousFiles, ...files]);
        this.refreshQuotaDetails$.next();
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  updateFiles() {
    const ids = this.files$.value.map((file) => file.id);
    return ids.length
      ? this.storageObjectDataService.getRecursive(ids).pipe(
          tap((files) => {
            this.files$.next([...files]);
            this.refreshQuotaDetails$.next();
          })
        )
      : of([]);
  }

  deleteFiles(storageFileIds: number[]): Observable<any> {
    return this.storageObjectDataService.deleteMultiple(storageFileIds).pipe(
      tap((_) => {
        const files = this.files$.value.filter(
          (file) => !storageFileIds.includes(file.id)
        );
        this.files$.next(files);
        this.refreshQuotaDetails$.next();
      })
    );
  }

  getShareSuggestions(holdingId: number, storageObjectIds: number[]) {
    return this.storageObjectDataService.getShareSuggestions(
      holdingId,
      storageObjectIds
    );
  }

  getFolderPath(holdingId: number, currentFolderId?: number) {
    return this.storageObjectDataService
      .getAllFoldersForHolding(holdingId, this.sessionId)
      .pipe(
        map(({ rows }) =>
          this.folderPath(currentFolderId, this.convertListToTreeNew(rows))
        )
      );
  }

  private convertListToTreeNew(
    list: StorageObjectReqRes[]
  ): StorageFolderTreeNode[] {
    const items: StorageFolderTreeNode[] = list.map((item) => ({
      ...item,
      children: [],
    }));
    const mapObject: Record<string, number> = {};
    const roots: StorageFolderTreeNode[] = [];

    for (const [i, item] of items.entries()) {
      mapObject[item.id] = i;
    }

    for (const item of items) {
      if (
        item.storageObjectParentId &&
        mapObject[item.storageObjectParentId] !== undefined
      ) {
        items[mapObject[item.storageObjectParentId]].children.push(item);
      } else {
        roots.push(item);
      }
    }

    return roots;
  }

  private folderPath(
    id: number,
    folders: StorageFolderTreeNode[]
  ): StorageObjectReqRes[] {
    const path: StorageObjectReqRes[] = [];

    const findPath = (
      id: number,
      folders: StorageFolderTreeNode[]
    ): boolean => {
      for (const folder of folders) {
        if (folder.id === id) {
          path.push(folder);
          return true;
        }

        if (folder.children.length > 0 && findPath(id, folder.children)) {
          path.push(folder);
          return true;
        }
      }

      return false;
    };

    findPath(id, folders);

    return path.reverse();
  }

  private generateCreateFilesSubmitModel(
    pickerResponse: PickerResponse,
    sessionId: string,
    holdingId: number,
    containingFolderId: number
  ): HoldingStorageObjectReqRes[] {
    const model = new Array<HoldingStorageObjectReqRes>();
    pickerResponse.filesUploaded.forEach((file) => {
      const storageObject = new HoldingStorageObjectReqRes();
      storageObject.storageObjectParentId = containingFolderId;
      storageObject.holdingId = holdingId;
      storageObject.filestackHandle = file.handle;
      storageObject.filestackUrl = file.url;
      storageObject.filestackSource = file.source;
      storageObject.displayName =
        file.filename?.length > this.maxDisplayNameLength
          ? this.getShortenDisplayName(file.filename)
          : file.filename;
      storageObject.metaFileLink = new MetaFileLink();
      storageObject.metaFileLink.title = file.filename;
      storageObject.metaFileLink.mediaType = file.mimetype;
      storageObject.metaFileLink.sizeB = file.size;
      storageObject.sessionId = sessionId;
      model.push(storageObject);
    });
    return model;
  }

  // TODO: move to utils
  /**
   * Shorten a long file name to the maximum allowed. maximum file name length defined in maxDisplayNameLength
   * @param originalDisplayName long file name
   */
  private getShortenDisplayName(originalDisplayName: string): string {
    try {
      if (originalDisplayName.length > 0) {
        const nameSplittedByDot = originalDisplayName.split('.');
        if (nameSplittedByDot.length > 1) {
          const extension =
            '.' + nameSplittedByDot[nameSplittedByDot.length - 1];
          let shortName = originalDisplayName.split(extension)?.shift();
          shortName = shortName.substr(
            0,
            this.maxDisplayNameLength - extension.length
          );
          return shortName + extension;
        } else {
          return originalDisplayName.substr(0, this.maxDisplayNameLength);
        }
      }

      return '';
    } catch (ex) {
      this.logger.error(
        `An error occurred while trying to shorten file name. original file name: ${originalDisplayName}`,
        ex
      );
      return originalDisplayName.substr(0, this.maxDisplayNameLength);
    }
  }
}
