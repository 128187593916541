
export interface ITransaction{
    Date:Date;
    AccountName:string;
    BankAccountType:string;
    TransactionType:string;
    description:string;
    Payee:string;
    Amount:number;
    Balance:number;    
}
export class TransactionTableItem{
    id:number;
    date:Date;
    accountName:string;
    bankAccountType:string;
    transactionType:string;
    description:string;
    payee:string;
    amount:number;
    balance:number;
    direction:string;
    totalRowsFromUnitApi:number;
    
    constructor(element?: ITransaction){      
    }
}