<ng-container *ngIf="{ currentStep: currentStep$ | async } as state">
  <section class="page-heading container-750">

    <button class="close-button" mat-flat-button type="button" (click)="cancel()">
      <mat-icon class="notranslate">close</mat-icon>
    </button>
    <h1>Create an asset</h1>

    <h2 class="page-sub-header" *ngIf="currentStep$.value === 1">
      Add as much relevant information about the asset as possible.<br> Once you
      publish the asset, potential investors will see what you add here.
    </h2>

    <ng-container *ngIf="(processingSubmit$ | async) === false">
      <ul class="steps">
        <li [ngClass]="{ active: state.currentStep === 1, 'prev-step': currentStep$.value > 1 }"
          (click)="state.currentStep > 1 ? navigateBackToStep(1) : false">
          <span class="step-number">1</span>
          <label>Asset<br />information</label>
        </li>
        <li [ngClass]="{ active: state.currentStep === 2, 'prev-step': currentStep$.value > 2 }"
          (click)="state.currentStep > 2 ? navigateBackToStep(2) : false">
          <span class="step-number">2</span>
          <label>Investment<br />details</label>
        </li>
        <li [ngClass]="{ active: state.currentStep === 3, 'prev-step': currentStep$.value > 3 }"
          (click)="state.currentStep > 3 ? navigateBackToStep(3) : false">
          <span class="step-number">3</span>
          <label>Marketing<br />materials</label>
        </li>
        <li [ngClass]="{ active: state.currentStep === 4 }">
          <span class="step-number">4</span>
          <label>Bank<br />account</label>
        </li>
      </ul>
    </ng-container>
  </section>
  <ng-container *ngIf="(processingSubmit$ | async) === false; else submitLoader">
    <router-outlet> </router-outlet>
  </ng-container>

  <ng-template #submitLoader>
    <terra-loader class="submit-loader"></terra-loader>
  </ng-template>

</ng-container>
