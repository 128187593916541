import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {skipWhile, map, tap, switchMap, shareReplay, switchMapTo, filter, take} from 'rxjs/operators';
import {Observable, combineLatest, of} from 'rxjs';

import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {GpFundService} from './gp-fund.service';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {EditFundTabNumber} from '../components/edit-fund-dialog/EditFundStepBaseAndInterface';
import {RoutingService} from 'src/app/services/routing.service';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {DialogService} from 'src/app/services/dialog.service';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import {FundraisingReqRes} from '../../shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {ClientBankAccountReqRes} from '../../models/bankAccount.model';
import {UnitBankAccountReqRes} from 'src/app/shared/models/gp/UnitBankAccountReqRes.model';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {FundInvestmentStrategyType} from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import {UserService} from 'src/app/services/shared/user.service';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import InvestmentType from 'src/app/shared/enums/InvestmentType.enum';
import {GpFundReqRes} from '../GpFundReqRes.model';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import {BaseResponseDto} from '../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../services/utils.service';
import {PermissionService} from '../../../permission/permission.service';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

@Component({
  selector: 'terra-gp-fund',
  templateUrl: './gp-fund.component.html',
  styleUrls: ['./gp-fund.component.scss']
})
export class GpFundComponent implements OnInit {

  // Enums (used in the template)
  HoldingStatus = HoldingStatus;
  holdingType = HoldingType;
  InvestmentSecurityType: InvestmentSecurityType;
  InvestmentType: InvestmentType;
  $fundId = this.gpFundService.holdingId$;

  fund$ = this.gpFundService.holding$;

  editDraftFundUrl$ = this.fund$.pipe(
    map(fund => this.routingService.editDraftFund(fund.id)), shareReplay(1));

  isDefaultTab$: Observable<boolean> = this.activatedRoute.data.pipe(map(data => data.isDefaultTab));

  organizationDetails$: Observable<any> = this.userService.accountDetails$.pipe(map(d => d.organizationDetails));

  fundraising$ = this.gpFundService.fundraising$;

  FundInvestmentStrategyType = FundInvestmentStrategyType;
  showBankInfo$ = this.permissionService.showBankInfo$;

  constructor(
    private _vcr: ViewContainerRef,
    private gpFundService: GpFundService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private dialogService: DialogService,
    private userService: UserService,
    private utilsService: UtilsService,
    private permissionService: PermissionService,
    private telemetryService: TelemetryService
  ) {
  }

  ngOnInit() {
    this.fund$
      .pipe(
        skipWhile(fund => !fund),
        tap(fund => this.titleService.setTitle('Fund Details: ' + fund.name + TerraUtils.consts.GpPageTitleSuffix)),
        switchMap(fund => combineLatest([of(fund), this.isDefaultTab$]))
      )
      .subscribe(([fund, isDefaultTab]) => {
        // the last condition added here to fix a bug that occurs sometimes when uploading a file to nested folder (in documents tab)
        // after uploading the user is thrown back to the root folder.
        // the bug here is that activatedRoute has the wrong url inside and isDefaultTab is true while it should be undefined
        if ((fund.status === HoldingStatus.Owned || fund.status === HoldingStatus.Draft || fund.status === HoldingStatus.Realized) && isDefaultTab && !this.router.url?.includes('documents')) {
          this.router.navigate(['overview'], {relativeTo: this.activatedRoute, replaceUrl: true});
        } else {
          // The condition is navigating to the same 'GpAssetComponent', else ensures a single telemetry sending
          this.sendTelemetryEvent();
        }
      });
  }

  private sendTelemetryEvent() {
    combineLatest([this.userService.accountDetails$, this.fund$])
      .pipe(take(1)).subscribe(([userDetails, fund]) => {
      this.telemetryService.create({
        eventID: '3000',
        type: 'Fund',
        status: HoldingStatus.toString(fund.status),
        isExample: fund.isExample,
        eventTitle: 'GP-Holding-Clicked',
        isCovercyUser: userDetails.userName.includes('@covercy.com')
      }, AnalyticsServiceNameModel.Mixpanel);
    });
  }

  editFund(tabNumber: EditFundTabNumber = 1) {
    this.gpFundService.showEditFund(this._vcr, tabNumber);
  }

  updateStatus() {
    this.gpFundService.showUpdateStatus();
  }

  fundDownInfo() {
    this.gpFundService.holdingDownInfo();
  }

  moveToArchive() {
    this.fund$.pipe(
      switchMap(fund => {
        const dialogParams = new ConfirmDialogParams(`Archive Fund: ${fund.name}`,
          `Are you sure?<br>
          If you archive this holding it will no longer appear in the platform. This action is irreversible.
          You may also click "Update status", change the status to "Canceled" to view it under canceled status.`);
        return this.dialogService.confirmDialog(dialogParams).afterClosed();
      }),
      filter(confirmed => !!confirmed),
      switchMapTo(this.gpFundService.moveToArchive())
    ).subscribe(
      () => {
        this.router.navigateByUrl(this.routingService.gpFundsPage);
      },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        }
      }
    );
  }

  deleteDraft() {
    this.fund$.pipe(
      switchMap(fund => {
        const dialogParams = new ConfirmDialogParams(`Delete Fund: ${fund.name}`,
          `Any data associated with this fund will be permanently deleted, including reports, investments, documents etc.<br>
        Are you sure you wish to delete the fund?`);
        return this.dialogService.confirmDialog(dialogParams).afterClosed();
      }),
      filter(confirmed => !!confirmed),
      switchMapTo(this.gpFundService.deleteFund())
    ).subscribe(
      () => {
        this.router.navigateByUrl(this.routingService.gpFundsPage);
      },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        }
      }
    );
  }

  getHoldingBankAccount(fundraising: FundraisingReqRes): ClientBankAccountReqRes {
    if (fundraising.clientBankAccount) {
      return fundraising.clientBankAccount;
    } else if (fundraising.unitBankAccount) {
      return this.unitBankToClientBank(fundraising.unitBankAccount);
    } else {
      return null;
    }
  }

  private unitBankToClientBank(unitBankAccount: UnitBankAccountReqRes) {
    const res = new ClientBankAccountReqRes();
    res.id = unitBankAccount.id;
    res.nickname = unitBankAccount.accountNickname;
    res.bankName = unitBankAccount.name;
    res.holderFullName = unitBankAccount.accountNickname;
    res.accountNumber = unitBankAccount.accountNumber;
    res.country = new CountryModel();
    res.country.code = TerraUtils.consts.countryCode.US;
    res.isUnitBankAccount = true;
    return res;
  }

  showHoldingInfoLine(fund: GpFundReqRes) {
    return this.userService.userHasFeatureFlag(FeatureFlags.CreBanking).pipe(
      map(hasFeature => hasFeature && (fund.status === HoldingStatus.Owned)));
  }
}
