<terra-dialog-header>
  <h1>Distribution awaiting for funds</h1>
</terra-dialog-header>

<mat-dialog-content>
  <h2 class="content-title">Please deposit the funds in Covercy's bank account:</h2>
  <ul class="details-list" *ngIf="context">
    <li>
      <label class="item-label">Amount:</label>
      <label class="item-value">{{ context.amount | terraCurrency }} {{ context.currency.iso }}</label>
    </li>
    <li>
      <label class="item-label">To:</label> <label class="item-value">{{ context.covercySourceBankAccount?.holderName }}</label>
    </li>
    <li>
      <label class="item-label">Bank:</label> <label class="item-value">{{ context.covercySourceBankAccount?.bankBranch?.name }}</label>
    </li>
    <li>
      <label class="item-label">{{context.covercySourceBankAccount?.accountNumberDescriptionText }}:</label> <label
        class="item-value">{{ context.covercySourceBankAccount?.accountNumber }}</label>
    </li>
    <li>
      <label class="item-label">{{ context.covercySourceBankAccount?.branchDescriptionText }}:</label> <label
        class="item-value">{{ context.covercySourceBankAccount?.branch }}</label>
    </li>
    <li>
      <label class="item-label">Branch address:</label> <label
        class="item-value">{{ context.covercySourceBankAccount?.bankBranch?.address }}</label>
    </li>
    <li *ngIf="context.covercySourceBankAccount?.iban">
      <label class="item-label">IBAN:</label> <label class="item-value">{{ context.covercySourceBankAccount.iban }}</label>
    </li>
    <li *ngIf="context.covercySourceBankAccount?.bankBranch?.bic">
      <label class="item-label">SWIFT:</label> <label class="item-value">{{ context.covercySourceBankAccount.bankBranch.bic }}</label>
    </li>
    <li>
      <label class="item-label"><span class="underline">Note</span>: Funds arriving to Covercy must originate from a bank account on the
        name
        of:</label>
      <label class="item-value lo_sensitive">{{ context.distributingEntityName }}</label>
    </li>
    <li>
      <label class="item-label">Include this reference number in case your bank allows it:</label>
      <label class="item-value lo_sensitive">{{ context.transactionReference }}</label>
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" form="dialogForm" mat-flat-button color="primary" [mat-dialog-close]="undefined" class="page-cta">
    Close
  </button>
</mat-dialog-actions>
