import { Component, OnInit } from '@angular/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';

import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  filter,
  finalize,
  interval,
  map,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs';
import InvestingEntityType from 'src/app/dashboard/models/InvestingEntityType.enum';
import { BlockNavigationIfHasChanges } from 'src/app/guards/canDeactivate.guard';
import { PermissionService } from 'src/app/permission/permission.service';
import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { UserService } from 'src/app/services/shared/user.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FilestackStatus } from 'src/app/shared/enums/filestack-status.enum';
import {
  HoldingStorageObjectReqRes,
  StorageObjectReqRes,
} from 'src/app/shared/models/StorageObjectReqRes.model';
import { AppQuery, AppService } from 'src/app/state';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';
import { GpHoldingService } from '../../gp-holding.service';
import { DiscardUploadComponent } from './discard-upload/discard-upload.component';
import { UploadDocumentsService } from './upload-documents.service';
import { BreadcrumbItem } from 'src/app/shared/components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'terra-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss'],
  providers: [UploadDocumentsService],
})
export class UploadDocumentsComponent
  extends OnDestroyMixin
  implements OnInit, BlockNavigationIfHasChanges
{
  selectedStep = new FormControl(0);
  stepChanging = false;

  skipPrepareStep: boolean = false;
  matchListwasEdited: boolean = false;
  loading: boolean = false;

  prepareCompleted = true;
  uploadCompleted = false;
  matchCompleted = false;
  shareCompleted = false;
  uploadIsNotDisabled = false;

  newFolderNmae = '';
  currentFolderId: number;

  FilestackStatus = FilestackStatus;

  breadcrumbs: BreadcrumbItem[];
  shareList = [];
  list = [];
  emailText: string = '';
  notifyByEmail: boolean = true;

  allowInvestorName$ = this.permissionService.allowInvestorName$;
  holdingId;
  holdingName = '';
  investors: Array<{
    id: number;
    name: string;
    email: string;
    nickname: string;
    type: InvestingEntityType;
  }> = [];
  files = [];
  matchFiles: Array<StorageObjectReqRes> = [];

  private lastUpdate = new BehaviorSubject<number | null>(null);
  private repeatAfter = 2 * 60 * 1000; // 2 minutes

  private currentFolderIdQueryParam$ = this.route.queryParams.pipe(
    map((params) => {
      return +params.folderId || null;
    })
  );

  constructor(
    private readonly permissionService: PermissionService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly uploadDocumentsService: UploadDocumentsService,
    private readonly storageObjectDataService: StorageObjectDataService,
    private readonly gpHoldingService: GpHoldingService,
    private readonly snackbarService: SnackbarService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly appService: AppService,
    private readonly appQuery: AppQuery,
    private readonly dialog: MatDialog,
    private readonly telemetryService: TelemetryService,
    private readonly userService: UserService
  ) {
    super();
  }

  updateMatches: Subject<boolean> = new Subject();
  hasChanges: boolean = false;

  openConfirmationModal(): Observable<boolean> {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '500px',
      data: {
        title: 'Discard Match Automatically?',
        description:
          'Are you sure you want to discard this files match automatically?',
        confirmButton: 'Discard',
      },
    };

    const dialogRef = this.dialog.open(DiscardUploadComponent, config);
    return dialogRef.afterClosed().pipe(
      tap((res) => {
        if (res) {
          this.cleanFiles();
        }
      })
    );
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.currentFolderId = params.folderId;
    });

    this.appQuery.gpUiPrefs.pipe(take(1)).subscribe((uiState) => {
      this.skipPrepareStep = uiState.documents.skipPrepareStep;
      // workaround for preloading Upload step
      if (!this.skipPrepareStep) {
        this.selectedStep.setValue(1);
        setTimeout(() => {
          this.selectedStep.setValue(0);
        }, 0);
      }
    });

    this.uploadDocumentsService.sessionId =
      self?.crypto?.randomUUID() || this.tempUUID(); // replace with crypto.randomUUID() when available

    this.uploadDocumentsService.files$.subscribe((files) => {
      this.files = files;
      this.hasChanges = !!files.length;
      this.lastUpdate.next(this.hasChanges ? new Date().getDate() : null);
      this.matchFiles = files.filter(
        (file) => file.filestackStatus === FilestackStatus.Ready
      );

      this.uploadCompleted =
        !!files.length && files.length === this.matchFiles.length;
      this.matchCompleted =
        !!this.matchFiles.length &&
        files.every((file) => file.filestackStatus === FilestackStatus.Ready);
      if (this.matchCompleted) {
        this.lastUpdate.next(null);
      }
    });

    combineLatest([
      this.gpHoldingService.holding$,
      this.currentFolderIdQueryParam$,
    ])
      .pipe(
        untilComponentDestroyed(this),
        switchMap(([holding, currentFolderId]) => {
          this.holdingId = holding.id;
          this.holdingName = holding.name;
          return this.uploadDocumentsService.getFolderPath(
            holding.id,
            currentFolderId
          );
        })
      )
      .subscribe((path) => {
        const breadcrumbs = path.map(({ displayName, id }) => ({
          name: displayName,
          id,
        }));
        this.generateBreadcrumbs(breadcrumbs);
      });

    this.loadInvestors();

    this.checkForPendingFiles();
  }

  private loadInvestors() {
    this.gpHoldingService.accessibleByInvestingEntities$
      .pipe(
        take(1),
        map((investingEntities) => {
          const list = investingEntities.map((investingEntity) => ({
            id: investingEntity.id,
            name: investingEntity?.name,
            nickname: investingEntity?.nickname,
            email: investingEntity?.contact?.email,
            type: investingEntity?.investingEntityType,
          }));

          this.investors = list;
          return list;
        })
      )
      .subscribe();
  }

  upload() {
    if (!this.newFolderNmae) {
      this.shareFiles();
    } else {
      const currentFolderId = this.currentFolderId;
      const model = new HoldingStorageObjectReqRes();
      model.holdingId = this.holdingId;
      model.displayName = this.newFolderNmae;
      model.storageObjectParentId = currentFolderId;
      const fileIds = this.list.map((i) => i.id);
      this.storageObjectDataService
        .createFolder(this.holdingId, model)
        .pipe(
          switchMap((res) =>
            this.storageObjectDataService.move(this.holdingId, fileIds, res.id)
          )
        )
        .subscribe((_) => {
          this.shareFiles();
        });
    }
  }

  shareFiles() {
    const matches = this.list.map((d) => ({
      storageObjectId: d.id,
      investingEntityId: d.sharedWith?.id || null,
    }));

    this.loading = true;
    this.storageObjectDataService
      .postShareSuggestions(
        this.holdingId,
        matches,
        this.notifyByEmail ? this.emailText : '',
        this.notifyByEmail,
        this.uploadDocumentsService.sessionId
      )
      .pipe(
        untilComponentDestroyed(this),
        finalize(() => {
          this.loading = false;

          const sharedFiles = this.list.filter((item) => item.sharedWith);
          const uniqueInvestors = new Set(
            sharedFiles.map((item) => item.sharedWith.id)
          );

          this.snackbarService.showGeneralMessage(
            `${sharedFiles.length} File${
              sharedFiles.length > 1 ? 's' : ''
            } uploaded and shared with ${uniqueInvestors.size} investor${
              uniqueInvestors.size > 1 ? 's' : ''
            }`
          );

          this.hasChanges = false;

          this.userService
            .getClientDetails()
            .pipe(take(1))
            .subscribe((clientDetails) => {
              this.telemetryService.create(
                {
                  eventID: '4008',
                  eventTitle: 'Holding-Upload-and-Share-Match-False-Positive',
                  holdingID: this.holdingId,
                  organizationID: clientDetails.organizationDetails.id,
                  additional: {
                    numberOfChangedMatches: this.list.filter(
                      (item) =>
                        item.initialSharedWith &&
                        item.sharedWith &&
                        item.initialSharedWith.id !== item.sharedWith.id
                    ).length,
                  },
                },
                AnalyticsServiceNameModel.Mixpanel |
                  AnalyticsServiceNameModel.Insights
              );
            });

          this.userService
            .getClientDetails()
            .pipe(take(1))
            .subscribe((clientDetails) => {
              this.telemetryService.create(
                {
                  eventID: '4011',
                  eventTitle: 'Holding-Upload-and-Share-Completed',
                  holdingID: this.holdingId,
                  organizationID: clientDetails.organizationDetails.id,
                },
                AnalyticsServiceNameModel.Mixpanel |
                  AnalyticsServiceNameModel.Insights
              );
            });

          this.router.navigate(['../'], { relativeTo: this.route });
        })
      )
      .subscribe();
  }

  onSkip(skip: boolean): void {
    this.appService.updateDocumentsSkipStepParams(skip);
  }

  cleanFiles(): void {
    const ids = this.list.map((i) => i.id);
    this.uploadDocumentsService.deleteFiles(ids).subscribe();
  }

  uploadComplited(event): void {
    this.uploadIsNotDisabled = event;
  }

  newFolder(name: string) {
    this.newFolderNmae = name;
  }

  matchList(list): void {
    this.list = [...list];
    this.shareList = list.filter((item) => item.sharedWith) || [];
    this.shareCompleted = !!this.shareList.length;
  }

  matchListEdited(edited: boolean): void {
    this.matchListwasEdited = edited;
  }

  sendEmail(event: boolean): void {
    this.notifyByEmail = event;
  }

  shareText(text): void {
    this.emailText = text;
  }

  check() {
    const matchingStep = 2 - (this.skipPrepareStep ? 1 : 0);

    if (this.matchListwasEdited) {
      const config: MatDialogConfig = {
        disableClose: true,
        width: '500px',
        data: {
          title: 'Are you sure?',
          description:
            'Returning to the previous step will undo the file-investor pairings you have manually entered.',
          confirmButton: 'Proceed',
        },
      };
      const dialogRef = this.dialog.open(DiscardUploadComponent, config);
      dialogRef
        .afterClosed()
        .pipe(
          tap((res) => {
            if (res) {
              this.selectedStep.setValue(matchingStep - 1);
              this.updateMatches.next(true);
            }
          })
        )
        .subscribe();
    } else {
      this.selectedStep.setValue(matchingStep - 1);
    }
  }

  onStepChange(event): void {}

  test() {
    this.updateMatches.next(true);
    this.appService.updateDocumentsSkipStepParams(false);
  }

  setStep(step: number) {
    if (!this.stepChanging && this.selectedStep.value !== step) {
      const matchingStep = 2 - (this.skipPrepareStep ? 1 : 0);

      if (step < matchingStep && this.matchListwasEdited) {
        this.check();
      } else {
        this.selectedStep.setValue(step);
        this.stepChanging = true;
      }
    }
  }

  tabChanged() {
    this.stepChanging = false;
  }

  sendbackToIntroEvent() {
    this.userService
      .getClientDetails()
      .pipe(take(1))
      .subscribe((clientDetails) => {
        this.telemetryService.create(
          {
            eventID: '4004',
            eventTitle: 'Holding-Upload-and-Share-Upload-Screen-Back-to-intro',
            holdingID: this.holdingId,
            organizationID: clientDetails.organizationDetails.id,
          },
          AnalyticsServiceNameModel.Mixpanel |
            AnalyticsServiceNameModel.Insights
        );
      });
  }

  sendbackToUploadEvent() {
    this.userService
      .getClientDetails()
      .pipe(take(1))
      .subscribe((clientDetails) => {
        this.telemetryService.create(
          {
            eventID: '4006',
            eventTitle: 'Holding-Upload-and-Share-Match-Screen-Back-to-upload',
            holdingID: this.holdingId,
            organizationID: clientDetails.organizationDetails.id,
          },
          AnalyticsServiceNameModel.Mixpanel |
            AnalyticsServiceNameModel.Insights
        );
      });
  }

  sendBackToMatchEvent() {
    this.userService
      .getClientDetails()
      .pipe(take(1))
      .subscribe((clientDetails) => {
        this.telemetryService.create(
          {
            eventID: '4010',
            eventTitle: 'Holding-Upload-and-Share-Email-Screen-Back-to-match',
            holdingID: this.holdingId,
            organizationID: clientDetails.organizationDetails.id,
          },
          AnalyticsServiceNameModel.Mixpanel |
            AnalyticsServiceNameModel.Insights
        );
      });
  }

  private checkForPendingFiles(): void {
    this.lastUpdate
      .pipe(
        untilComponentDestroyed(this),
        filter((value) => !!value),
        switchMap(() =>
          interval(this.repeatAfter).pipe(
            takeWhile(() => this.lastUpdate.value !== null)
          )
        )
      )
      .subscribe(() => {
        if (
          this.files.length &&
          this.files.some((f) => f.filestackStatus !== FilestackStatus.Ready)
        ) {
          this.storageObjectDataService
            .refreshSessionData(this.uploadDocumentsService.sessionId)
            .subscribe();
        }
      });
  }

  private generateBreadcrumbs(
    breadcrumbs: Array<{ name: string; id: number }> = []
  ) {
    const [root0, root1] = this.router.url.split('/').filter((s) => s !== '');
    const baseUrl = `/${root0}/${root1}/${this.holdingId}`;

    this.breadcrumbs = [
      { label: this.holdingName, url: baseUrl },
      { label: 'Documents', url: `${baseUrl}/documents` },
      ...breadcrumbs.map(({ name, id }) => ({
        label: name,
        url: `${baseUrl}/documents`,
        params: { folderId: String(id) },
      })),
      { label: 'Match Automatically' },
    ];
  }

  private tempUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>
      ((c === 'x' ? Math.random() * 16 : Math.random() * 4 + 8) | 0).toString(
        16
      )
    );
  }
}
