import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
 
import { Subscription } from 'rxjs';
import { CreateAssetStepBase } from '../CreateAssetStepBaseAndInterface';
import { CreateAssetService, CreateAssetStepNumber } from '../create-asset.service';

@Component({
  selector: 'terra-create-asset-marketing-material-step',
  templateUrl: './create-asset-marketing-material-step.component.html',
  styleUrls: ['./create-asset-marketing-material-step.component.scss']
})
export class CreateAssetMarketingMaterialStepComponent extends CreateAssetStepBase implements OnInit, OnDestroy {

  readonly STEP_NUMBER: CreateAssetStepNumber = 3;

  showSecondPhotosRow = false;
  videoUrl: SafeResourceUrl;

  subscription:Subscription = new Subscription();

  constructor(private createAssetService: CreateAssetService,
    private cdr:ChangeDetectorRef,
    protected _sanitizer: DomSanitizer) {
    super(createAssetService);
  }

  ngOnInit() {
    this.createAssetService.currentStep$.next(this.STEP_NUMBER);

    this.additionalAttachment();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  additionalAttachment(){
    this.subscription = this.pageForm.get('attachments').valueChanges.pipe()
    .subscribe((attachments:[] )=> {
      const remInd = attachments.findIndex(c => !c);

      if(remInd >= 0){
        this.createAssetService.popMarketingAttachment(remInd);
      }
      else if(attachments.length < 10){
        this.createAssetService.pushMarketingAttachment();
      }

      this.cdr.markForCheck();
    });
  }

  get attachments(){
    return (this.pageForm.get('attachments') as UntypedFormArray).controls;
  }

  addVideo() {
    this.pageForm.get('shouldShowPlayer').setValue(true);
    this.videoUrl = this.getVideoUrl();
  }

  getVideoUrl(): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.getYoutubeVideoIdFromUrl()}?autoplay=1`);
  }

  getYoutubeVideoIdFromUrl(): string {
    const url = this.pageForm.get("video").value;
    const urlObject = new URL(url);
    let urlOrigin = urlObject.origin;
    let urlPath = urlObject.pathname;

    if (urlOrigin.search('youtu.be') > -1) {
      return urlPath.substr(1);
    }

    if (urlPath.search('embed') > -1) {
      // Örneğin "/embed/wCCSEol8oSc" ise "wCCSEol8oSc" return eder.
      return urlPath.substr(7);
    }


    return urlObject.searchParams.get('v');
  }

  removeVideo() {
    this.pageForm.get("video").reset();
    this.pageForm.get("shouldShowPlayer").setValue(false);
  }
}
