import {Component, OnInit} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

import {EditFundStepBase, EditFundTabNumber} from '../EditFundStepBaseAndInterface';
import {EditFundService} from '../edit-fund.service';
import {AlertDialogParams} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {DialogService} from 'src/app/services/dialog.service';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';

@Component({
  selector: 'terra-edit-fund-tab-more-settings',
  templateUrl: './edit-fund-tab-more-settings.component.html',
  styleUrls: ['./edit-fund-tab-more-settings.component.scss']
})
export class EditFundTabMoreSettingsComponent extends EditFundStepBase implements OnInit {
  readonly TAB_NUMBER: EditFundTabNumber = 5;
  showSecondDocumentsRow = false;

  get navTooltipText() {
    return `Net Asset Value (NAV) is calculated using this formula: (Estimated Market Value - Loan Amount) × Investor Ownership Percentage. To configure this field, navigate to Edit Fund → Investment Details.`;
  }

  constructor(private editFundService: EditFundService, private dialogService: DialogService) {
    super(editFundService);
  }

  ngOnInit() {
    const fundPaymentRequestDocuments = this.dialogContext.fundDetails.attachments.filter(x => x.fileType === HoldingFileType.PaymentRequestDocument);
    this.showSecondDocumentsRow = fundPaymentRequestDocuments && fundPaymentRequestDocuments.length > 4;
  }

  makeSurePrivateStateAllowed(isPrivateToggle: MatSlideToggleChange) {
    const isPrivate = isPrivateToggle.checked;
    // if attempting to change to private, make sure its allowed
    if (isPrivate) {
      if (!this.dialogContext.fundDetails.canSetAsPrivate) {
        this.pageForm.get('isPrivate').setValue(false);
        this.dialogService.alertDialog(
          new AlertDialogParams('Change fund mode to down', `Fund mode cannot be changed to down when there are outstanding payment requests.`));
        return false;
      }
    }
  }

  makeSureNavIsAllowed(displayNetAssetToggle: MatSlideToggleChange) {
    if (displayNetAssetToggle.checked) {
      if (!this.editFundService.fundForm.get('tab2.cumulativeInformation.estimatedMarketValue')?.value) {
        this.dialogService.alertDialog(
          new AlertDialogParams('Missing Details', `The Estimated Market Value needs to be configured as it is essential for calculating the Net Asset Value. To configure it go to Edit Fund tab → Investment Details → Estimated Market Value.`))
          .afterClosed()
          .subscribe(_ => {
              this.pageForm.get('displayNetAssetValue').setValue(false);
              displayNetAssetToggle.source.checked = false;
            }
          );
      }
    }
  }
}
