import {Injectable, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap, take, tap, filter, catchError, startWith, map, shareReplay} from 'rxjs/operators';
import {ReplaySubject, Observable, combineLatest, of, throwError, Subject} from 'rxjs';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import {FundraisingReqRes} from '../../shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {GpFundReqRes} from '../GpFundReqRes.model';
import {GpFundServiceInterface} from './Fund.context';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {GpFundDataService} from 'src/app/services/gp/gp-fund-data.service';
import {HoldingDiscriminator} from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {UtilsService} from 'src/app/services/utils.service';
import {EditFundTabNumber} from 'src/app/dashboard/funds/components/edit-fund-dialog/EditFundStepBaseAndInterface';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {GpFundDialogContext} from 'src/app/dashboard/funds/gp-fund/Fund.context';
import {EditFundDialogComponent} from 'src/app/dashboard/funds/components/edit-fund-dialog/edit-fund-dialog.component';
import {SnackbarService} from '../../../services/snackbar.service';
import {GpHoldingDataService} from 'src/app/services/gp/gp-holding-data.service';
import {GpFundraisingDataService} from 'src/app/services/gp/gp-fundraising-data.service';
import {UpdateHoldingStatusDialogContext} from '../../shared/holding/update-holding-status-dialog/UpdateHoldingStatusDialogContext';
import {UpdateHoldingStatusDialogComponent} from '../../shared/holding/update-holding-status-dialog/update-holding-status-dialog.component';
import {UpdateHoldingStatusResult} from '../../shared/holding/update-holding-status-dialog/UpdateHoldingStatusResult.model';
import HoldingStatus from 'src/app//shared/enums/HoldingStatus.enum';
import {PartialEditFundDialogComponent} from 'src/app/dashboard/funds/components/partial-edit-fund-dialog/partial-edit-fund-dialog.component';
import {FundAndFundraisingReqRes} from '../components/create-fund/FundAndFundraisingRequest';
import {GpDistributionDataService} from 'src/app/services/gp/gp-distribution-data.service';
import {GpHoldingService} from '../../shared/holding/gp-holding.service';
import {RoutingService} from 'src/app/services/routing.service';
import {HoldingUnderManagementTabs} from 'src/app/shared/types/GpTypes';
import {GpHoldingListItemReqRes} from '../../shared/holding/GpHoldingListItem.model';
import {DialogService} from 'src/app/services/dialog.service';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {GpAssetDataService} from 'src/app/services/gp/gp-asset-data.service';
import {LoggerService} from 'src/app/shared/errors/logger.service';
import {AppQuery} from 'src/app/state';
import {SearchOptionsRequest} from 'src/app/shared/models/SearchOptionsRequest.model';
import {ClientPresentationState} from 'src/app/account/my-account/model/ui-state/ClientPresentationState';
import {UserService} from 'src/app/services/shared/user.service';
import {FundPollingService} from './fund-polling.service';
import {BaseResponseDto} from '../../../shared/models/BaseResponseDto.model';

@Injectable()
export class GpFundService extends GpHoldingService implements GpFundServiceInterface {
  readonly FinalInvestmentStatuses = [InvestmentStatus.Invested, InvestmentStatus.Declined, InvestmentStatus.Potential];
  readonly holdingDiscriminator = HoldingDiscriminator.Fund;

  private refreshFundAssets$ = new Subject<void>();

  holding$ = new ReplaySubject<GpFundReqRes>(1);

  fundPollingService: FundPollingService;
  discriminatorName: HoldingDiscriminator = HoldingDiscriminator.Fund;
  searchOptionsPortfolio$: Observable<SearchOptionsRequest> = this.appQuery.gpUiPrefs.pipe(
    map(state => state.fundsManagingPortfolio.sort),
    map(sort => ({orderBy: sort.orderBy, sortOrder: sort.direction} as SearchOptionsRequest))
  );
  fundHoldings$ = combineLatest([this.refreshFundAssets$.pipe(startWith(null)), this.searchOptionsPortfolio$, this.holdingId$]).pipe(
    switchMap(([_, uiState, fundId]) => this.gpFundDataService.getFundHoldings(fundId, uiState)));

  getFundraisingSearchOptionsState = (state: ClientPresentationState) => state.fundsFundraising.sort;
  getDistributionSearchOptionsState = (state: ClientPresentationState) => state.fundsDistribution.sort;

  importContributionRoute$ = this.holding$.pipe(map(holding => this.routingService.importFundContributions(holding.id)), shareReplay(1));

  constructor(
    private logger: LoggerService,
    private gpFundDataService: GpFundDataService,
    private gpHoldingDataService: GpHoldingDataService,
    private gpAssetDataService: GpAssetDataService,
    private gpFundraisingDataService: GpFundraisingDataService,
    private gpDistributionDataService: GpDistributionDataService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private utilsService: UtilsService,
    private routingService: RoutingService,
    private dialogService: DialogService,
    private appQuery: AppQuery,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private router: Router
  ) {
    super(route, gpDistributionDataService, gpFundraisingDataService, gpHoldingDataService, appQuery, utilsService);
  }

  getDistributionPageRoute(holdingId: number, distributionId: number) {
    return this.routingService.fundDistribution(holdingId, distributionId);
  }

  getCreateDistributionRoute(holdingId: number) {
    return this.routingService.createFundDistribution(holdingId);
  }

  getImportDistributionRoute(holdingId: number): string {
    return this.routingService.importFundDistributions(holdingId);
  }

  getHoldingPageWithTabRoute(holdingId: number, tab: HoldingUnderManagementTabs) {
    return this.routingService.gpFundWithTab(holdingId, tab);
  }

  showEditFund(viewContainerRef: ViewContainerRef, initialTabNumber: EditFundTabNumber = 1, scrollToId: string = null) {
    combineLatest([this.holding$, this.fundraising$, this.fundraisings$]).pipe(
      take(1),
      switchMap(([fundDetails, fundraisingDetails, fundraisings]) => {
        const config = new MatDialogConfig<GpFundDialogContext>();
        config.panelClass = 'edit-fund-dialog';
        // config.disableClose = true;
        config.autoFocus = false;
        config.closeOnNavigation = true;

        const context = new GpFundDialogContext(this, fundDetails, fundraisingDetails, fundraisings);
        context.initialTabNumber = initialTabNumber;
        context.scrollToId = scrollToId;
        config.data = context;
        config.viewContainerRef = viewContainerRef;

        return this.dialog.open(EditFundDialogComponent, config).afterClosed();
      }),
      untilComponentDestroyed(this))
      .subscribe((updatedAsset: GpFundReqRes) => {
        if (updatedAsset) {
          this.snackbarService.showGeneralMessage('Saved changes');
          this.contRefresh$.next();
        }
      });
  }

  showEditHolding(vcr: ViewContainerRef, initialTabNumber: number, scrollToId: string = null) {
    this.showEditFund(vcr, initialTabNumber as EditFundTabNumber, scrollToId);
  }

  showPartialEditFund() {
    combineLatest([this.holding$, this.fundraising$, this.fundraisings$]).pipe(
      take(1),
      switchMap(([fundDetails, fundraisingDetails, fundraisings]) => {
        const config = new MatDialogConfig<GpFundDialogContext>();
        config.panelClass = 'edit-fund-dialog';
        config.disableClose = true;
        config.autoFocus = false;

        const context = new GpFundDialogContext(this, fundDetails, fundraisingDetails, fundraisings);
        config.data = context;

        return this.dialog.open(PartialEditFundDialogComponent, config).afterClosed();
      }),
      untilComponentDestroyed(this))
      .subscribe((updatedFund: FundAndFundraisingReqRes) => {
        if (updatedFund) {
          this.snackbarService.showGeneralMessage('Saved changes');
          this.router.navigateByUrl(this.routingService.gpFundPage(updatedFund.fund.id));
        }
      });
  }

  updateStatus(fundStatus: HoldingStatus, draftSendEmailsWhenMovingToUnderManagement = false) {
    return this.holdingId$.pipe(
      switchMap(fundId => this.gpFundDataService.updateStatus(fundId, fundStatus, draftSendEmailsWhenMovingToUnderManagement)),
      tap(fundDetails => {
        // update only the fundDetails part:
        this.holding$.next(fundDetails);
      })
    );
  }

  deleteFund() {
    return this.holdingId$.pipe(
      switchMap(fundId => {
        return this.gpFundDataService.deleteFund(fundId);
      })
    );
  }

  moveToArchive() {
    return this.holdingId$.pipe(
      switchMap(assetId => {
        return this.gpHoldingDataService.moveToArchive(assetId);
      })
    );
  }

  /** Delete an asset, with a confirmation dialog */
  deleteFundAsset(asset: GpHoldingListItemReqRes): Observable<void> {
    return this.dialogService
      .confirmDialog(new ConfirmDialogParams(`Are you sure you want to delete ${asset.name}?`,
        `This asset's information will be deleted immediately. You cannot undo this action.`, 'Delete'))
      .afterClosed().pipe(
        take(1),
        filter((confirmed: boolean) => confirmed),
        switchMap(_ => this.gpAssetDataService.deleteAsset(asset.id)),
        tap(_ => this.refreshFundAssets$.next()),
        catchError(error => {
          this.logger.error(`Couldn't delete assetId: ${asset.id}`, asset);
          return throwError(error);
        })
      );
  }

  updateFund(model: FundAndFundraisingReqRes, skipNotification: boolean): Observable<FundAndFundraisingReqRes> {
    return this.holdingId$.pipe(
      switchMap(fundId => {
        return this.gpFundDataService.updateFund(fundId, model, skipNotification);
      }),
      tap(response => {
        this.holding$.next(response.fund);
        this.fundraising$.next(response.fundraising);
      })
    );
  }

  showUpdateStatus() {
    combineLatest([this.holding$, this.fundraising$]).pipe(
      take(1),
      switchMap(([fundDetails, fundraisingDetails]) => {
        const dialogConfig = new MatDialogConfig<UpdateHoldingStatusDialogContext>();
        dialogConfig.disableClose = false;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.autoFocus = true;

        const dialogContext = new UpdateHoldingStatusDialogContext(fundDetails, fundraisingDetails);
        dialogConfig.data = dialogContext;
        dialogConfig.viewContainerRef = this.vcr;

        const dialogRef: MatDialogRef<UpdateHoldingStatusDialogComponent, UpdateHoldingStatusResult> =
          this.dialog.open(UpdateHoldingStatusDialogComponent, dialogConfig);

        return combineLatest([dialogRef.afterClosed(), of(fundDetails.status)]);
      }),
      untilComponentDestroyed(this)
    )
      .subscribe(([updatedStatusResult, originalFundStatus]) => {
        if (!updatedStatusResult) {
          return;
        }
        const updatedStatus = updatedStatusResult.status;
        if (updatedStatusResult.openPartialEdit) {
          this.showPartialEditFund();
        } else {
          if (updatedStatus && updatedStatus !== originalFundStatus) {
            this.updateStatus(updatedStatus, updatedStatusResult.draftSendEmailsWhenMovingToUnderManagement)
              .subscribe(
                response => {
                  if (updatedStatus === HoldingStatus.Canceled) {
                    this.telemetryMovedToCanceled();
                  }
                  this.snackbarService.showGeneralMessage('Status changed');
                },
                error => {
                  if (error instanceof BaseResponseDto) {
                    this.utilsService.alertErrorMessage(error);
                  }
                  console.log('Error', error);
                }
              );
          }
        }
      });
  }

  moveFundToUnderManagement(model: FundAndFundraisingReqRes): Observable<FundAndFundraisingReqRes> {
    return this.holdingId$.pipe(
      switchMap(fundId => {
        return this.gpFundDataService.moveFundToUnderManagement(fundId, model);
      }),
      tap(response => {
        this.holding$.next(response.fund);
        this.fundraising$.next(response.fundraising);
      })
    );
  }

  moveFundraisingToCompleted(model: FundAndFundraisingReqRes): Observable<FundAndFundraisingReqRes> {
    return this.holdingId$.pipe(
      switchMap(fundId => {
        return this.gpFundDataService.moveFundraisingToCompleted(fundId, model);
      }),
      tap(response => {
        this.holding$.next(response.fund);
        this.fundraising$.next(response.fundraising);
      })
    );
  }

  // Update the fund details object locally (after an update was made in the server, and we don't want to wait for the next polling resopnse)
  changeFundOnly(fund: GpFundReqRes) {
    this.holding$.next(fund);
  }

  // Update the fundraising details object locally (after an update was made in the server, and we don't want to wait for the next polling resopnse)
  changeFundraisingOnly(fundraising: FundraisingReqRes) {
    this.fundraising$.next(fundraising);
  }

  holdingDownInfo() {
    this.utilsService.holdingDownInfo(HoldingDiscriminator.Fund);
  }

  private telemetryMovedToCanceled() {
    combineLatest([this.userService.accountDetails$, this.holdingId$])
      .pipe(take(1)).subscribe(([clientDetails, holdingId]) => {
        this.telemetryService.create({
          eventID: '303',
          eventTitle: 'FUND MOVED TO CANCELED',
          holdingID: holdingId,
          organizationID: clientDetails.organizationDetails.id
        }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
      });
  }

  updateHoldingFromServer() {
    this.fundPollingService.refresh();
  }
}
