import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of, BehaviorSubject } from 'rxjs';
import { shareReplay, map, switchMap, tap, take } from 'rxjs/operators';

import { GpAssetService } from '../../../gp-asset.service';
import { AssetReportReqRes } from '../../../../../../shared/models/AssetReportReqRes.model';
import { AssetReportDataService } from 'src/app/services/shared/asset-report-data.service';
import { ReportFAQReqRes } from 'src/app/shared/models/reportFAQReqRes.model';
import { GpReportFaqDataService } from 'src/app/services/gp/gp-report-faq-data.service';

@Injectable()
export class AssetReportFaqService {

  reportId$ = this.route.params.pipe(
    map(params => +params.reportId || -1),
    shareReplay()
  );

  report$: Observable<AssetReportReqRes> = combineLatest(this.gpAssetService.holdingId$, this.reportId$).pipe(
    switchMap(([assetId, reportId]) => (reportId > 0 ? this.assetReportDataService.getByIdForGp(assetId, reportId) : of(null))),
    shareReplay()
  );

  private refreshFaqs$ = new BehaviorSubject<void>(null);
  faqs$ = this.refreshFaqs$.pipe(switchMap(() => combineLatest(this.gpAssetService.holdingId$, this.reportId$)),
    switchMap(([assetId, reportId]) => this.gpReportFaqDataService.getList(assetId, reportId)),
    map(res => res.rows),
    shareReplay()
  );

  private endpointPrefixIds$ = combineLatest(this.gpAssetService.holdingId$, this.reportId$).pipe(
    shareReplay(),
    map(([assetId, reportId]) => ({ assetId, reportId })));


  constructor(
    private route: ActivatedRoute,
    private gpAssetService: GpAssetService,
    private assetReportDataService: AssetReportDataService,
    private gpReportFaqDataService: GpReportFaqDataService) { }

  create(faq: ReportFAQReqRes) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(ids => {
      return this.gpReportFaqDataService.create(ids.assetId, ids.reportId, faq);
    }));
  }

  update(faq: ReportFAQReqRes) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(ids => {
      return this.gpReportFaqDataService.update(ids.assetId, ids.reportId, faq);
    }));
  }

  createAndUpdateBulk(faqs: ReportFAQReqRes[]) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(ids => {
        return this.gpReportFaqDataService.createAndUpdateBulk(ids.assetId, ids.reportId, faqs);
      }),
      tap(() => this.refreshFaqs$.next()));
  }

  delete(faqId: number) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(ids => {
        return this.gpReportFaqDataService.delete(ids.assetId, ids.reportId, faqId);
      }),
      tap(() => this.refreshFaqs$.next()));
  }
}
