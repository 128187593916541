<terra-dialog-header>
  <h1>Edit Asset Info</h1>
</terra-dialog-header>

<div class="content-wrapper" [ngClass]="{ 'loading': (processingSubmit$ | async)  }">
  <terra-loader class="page-loader" *ngIf="(processingSubmit$ | async)"></terra-loader>

  <mat-dialog-content class="main-content">
    <p *ngIf="context.asset.status === AssetStatus.Fundraising" class="info-message">
      This asset is active. Any changes to this information will be applied immediately.
    </p>

    <mat-tab-group [dynamicHeight]="true" *ngIf="pageForm" [selectedIndex]="initialSelectedTab" mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab>
        <ng-template mat-tab-label><span class="tab-label-text" [class.invalid]="isShowInvalidTab(1)">Asset information</span>
          <mat-chip-grid selectable="false" *ngIf="isShowInvalidTab(1)">
            <mat-chip class="missing-info-in-tab-warning" disabled>Missing</mat-chip>
          </mat-chip-grid>
        </ng-template>
        <terra-edit-fund-asset-tab-asset-information></terra-edit-fund-asset-tab-asset-information>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label><span class="tab-label-text" [class.invalid]="isShowInvalidTab(2)">Investment details</span>
          <mat-chip-grid selectable="false" *ngIf="isShowInvalidTab(2)">
            <mat-chip class="missing-info-in-tab-warning" disabled>Missing</mat-chip>
          </mat-chip-grid>
        </ng-template>
        <terra-edit-fund-asset-tab-investment-details></terra-edit-fund-asset-tab-investment-details>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label><span class="tab-label-text" [class.invalid]="isShowInvalidTab(3)">More settings</span>
          <mat-chip-grid selectable="false" *ngIf="isShowInvalidTab(3)">
            <mat-chip class="missing-info-in-tab-warning" disabled>Missing</mat-chip>
          </mat-chip-grid>
        </ng-template>
        <terra-edit-fund-asset-tab-more-settings></terra-edit-fund-asset-tab-more-settings>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
</div>

<mat-dialog-actions>
  <button type="button" (click)="saveChanges()" [disabled]="processingSubmit$ | async" mat-flat-button color="primary" class="page-cta">Save
    Changes</button>

  <button type="button" mat-button [disabled]="processingSubmit$ | async" class="cancel-button tall-button"
          [mat-dialog-close]="undefined">Cancel</button>

  <!-- Error message -->
  <div *ngIf="attemptSubmit">
    <p *ngIf="pageForm.invalid" class="error-message">
      Please review and correct the fields marked in red
    </p>
    <p *ngIf="pageForm.valid && isGeneralServerError" #serverError class="error-message">
      <span [innerHTML]="generalServerErrorMessage"></span></p>
  </div>

  <!--
  <a class="link delete-asset-link" *ngIf="isDeleteAssetAllowed && !(processingSubmit$ | async)" (click)="deleteAsset()">Delete Asset</a>
  -->
</mat-dialog-actions>
