import {Component, OnInit} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

import {EditFundAssetStepBase, EditFundAssetTabNumber} from '../EditFundAssetStepBaseAndInterface';
import {EditFundAssetService} from '../edit-fund-asset.service';
import {AlertDialogParams} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {DialogService} from 'src/app/services/dialog.service';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';

@Component({
  selector: 'terra-edit-fund-asset-tab-more-settings',
  templateUrl: './edit-fund-asset-tab-more-settings.component.html',
  styleUrls: ['./edit-fund-asset-tab-more-settings.component.scss']
})
export class EditFundAssetTabMoreSettingsComponent extends EditFundAssetStepBase implements OnInit {
  readonly TAB_NUMBER: EditFundAssetTabNumber = 3;

  // enums
  HoldingDiscriminator = HoldingDiscriminator;

  showSecondPhotosRow = false;
  showSecondDocumentsRow = false;


  constructor(private editFundAssetService: EditFundAssetService, private dialogService: DialogService) {
    super(editFundAssetService);
  }

  ngOnInit() {
    // Show the second image uploaders row if there are more than 4 existing images:
    const fundAssetPhotos = this.dialogContext.asset.attachments.filter(x => x.fileType === HoldingFileType.Photo);
    const fundAssetPaymentRequestDocuments = this.dialogContext.asset.attachments.filter(x => x.fileType === HoldingFileType.PaymentRequestDocument);
    this.showSecondPhotosRow = fundAssetPhotos && fundAssetPhotos.length > 4;
    this.showSecondDocumentsRow = fundAssetPaymentRequestDocuments && fundAssetPaymentRequestDocuments.length > 4;
  }

  makeSurePrivateStateAllowed(isPrivateToggle: MatSlideToggleChange) {
    const isPrivate = isPrivateToggle.checked;
    // if attempting to change to private, make sure its allowed
    if (isPrivate) {
      if (!this.dialogContext.asset.canSetAsPrivate) {
        this.pageForm.get('isPrivate').setValue(false);
        this.dialogService.alertDialog(
          new AlertDialogParams('Change asset mode to down', `Asset mode cannot be changed to down when there are outstanding payment requests.`));
        return false;
      }
    }
  }
}
