import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FilestackService } from '@filestack/angular';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { ClientOptions, PickerOptions, Security } from 'filestack-js';
import {
  PickerInstance,
  PickerResponse,
  PickerStoreOptions,
} from 'filestack-js/build/main/lib/picker';
import { Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';

import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import { GpHoldingService } from 'src/app/dashboard/shared/holding/gp-holding.service';
import { PermissionService } from 'src/app/permission/permission.service';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { DialogService } from 'src/app/services/dialog.service';
import { UserService } from 'src/app/services/shared/user.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { AlertDialogParams } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import { StorageObjectType } from 'src/app/shared/enums/StorageFileType.enum';
import { ErrorMatcher, ErrorType } from 'src/app/shared/errors/ErrorMatcher';
import { UtilsService } from '../../../../../services/utils.service';
import { BaseResponseDto } from '../../../../../shared/models/BaseResponseDto.model';
import { HoldingDocumentsSearchOptionsRequest } from '../HoldingDocumentsSearchOptionsRequest.model';
import { CreateFolderDialogComponent } from '../create-folder-dialog/create-folder-dialog.component';
import { DocumentDisplayTableComponent } from '../document-display-table/document-display-table.component';
import { DocumentsTabService } from './documents-tab.service';
import { StorageObjectQuotaReqRes } from 'src/app/shared/models/StorageObjectQuotaReqRes.model';

export enum TimeOfUploadOption {
  AllTimes = 0,
}

@Component({
  selector: 'terra-documents-tab',
  templateUrl: './documents-tab.component.html',
  styleUrls: ['./documents-tab.component.scss'],
  providers: [DocumentsTabService],
})
export class DocumentsTabComponent extends OnDestroyMixin implements OnInit {
  allowInvestorName$ = this.permissionService.allowInvestorName$;

  // enums
  TimeOfUploadOption = TimeOfUploadOption;
  StorageObjectType = StorageObjectType;

  HoldingStatus: HoldingStatus;

  showDocumentsTab$ = this.userService.userHasFeatureFlag(
    FeatureFlags.DocumentsManagement
  );

  picker: PickerInstance;

  fileStackApiKey = this.appSettingsService.fileStackApiKey;

  pageForm: UntypedFormGroup;

  holdingId$ = this.gpHoldingService.holdingId$;
  isRealized$ = this.gpHoldingService.holding$.pipe(
    map((holding) => holding.status == HoldingStatus.Realized)
  );
  initiateSearch = new Subject<boolean>();

  @ViewChild(DocumentDisplayTableComponent)
  documentsTable: DocumentDisplayTableComponent;

  searchOptions: HoldingDocumentsSearchOptionsRequest;

  currentFolderId$ = this.documentsTabService.currentFolderId$;
  currentFolderId;
  foldersPath$ = this.documentsTabService.foldersPath$;

  fileCountLimitReached$ = this.documentsTabService.fileCountLimitReached$;

  url: string = this.router.url;
  constructor(
    private userService: UserService,
    private appSettingsService: AppSettingsService,
    private documentsTabService: DocumentsTabService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private gpHoldingService: GpHoldingService,
    private snackbarService: SnackbarService,
    private filestackService: FilestackService,
    private router: Router,
    private route: ActivatedRoute,
    private telemetryService: TelemetryService,
    private vcr: ViewContainerRef,
    private utilsService: UtilsService,
    private permissionService: PermissionService
  ) {
    super();
  }

  ngOnInit() {
    this.generateForm();

    this.currentFolderId$
      .pipe(
        untilComponentDestroyed(this),
        distinctUntilChanged(),
        withLatestFrom(this.allowInvestorName$),
        filter(([currentFolderId, allowInvestorName]) => allowInvestorName),
        map(([currentFolderId, allowInvestorName]) => currentFolderId)
      )
      .subscribe((currentFolderId) => {
        const queryParams = { folderId: currentFolderId };
        this.currentFolderId = currentFolderId;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams,
          queryParamsHandling: 'merge',
        });
      });
  }

  generateForm() {
    this.pageForm = this.fb.group({
      searchTerm: '',
      periodFilter: 0,
    });
  }

  private checkQuotaDetails(): Observable<null | StorageObjectQuotaReqRes> {
    return this.documentsTabService.getQuotaRefreshed().pipe(
      take(1),
      map((quotaDetails) => {
        if (
          quotaDetails.quotaUsedMB >= quotaDetails.maxStorageQuotaMB ||
          quotaDetails.maxStorageFilesCount -
            quotaDetails.currentStorageFilesCount <=
            0
        ) {
          const dialogParams = new ConfirmDialogParams();
          dialogParams.actionPosition = 'right';
          dialogParams.actionLabel = `Contact Us`;
          dialogParams.title = `File Limit Reached`;
          dialogParams.description = `Your current subscription has reached the maximum document limit. \n To increase your limit, please reach out to Covercy's Success Team.`;

          const dialogRef = this.dialogService.confirmDialog(dialogParams);
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              window.open('https://www.covercy.com/contact-us', '_blank');
            }
          });

          return null;
        } else {
          return quotaDetails;
        }
      })
    );
  }

  navigateToUploadAndShare() {
    this.checkQuotaDetails().subscribe((quotaDetails) => {
      if (quotaDetails) {
        this.router.navigate(['upload'], {
          relativeTo: this.route,
          queryParams: { folderId: this.currentFolderId },
          queryParamsHandling: 'merge',
        });
      }
    });
    this.sendUploadTelemetry();
  }

  openFilePicker() {
    // When we open the file picker, first we want to get the most updadted quota,
    // in case it was changed in another tab or by another user in the organization
    this.checkQuotaDetails().subscribe((quotaDetails) => {
      if (quotaDetails) {
        this.documentsTabService
          .getFilestackPolicy()
          .pipe(take(1))
          .subscribe((policy) => {
            const pickerOptions: PickerOptions = {
              onUploadDone: (event) => this.onUploadSuccess(event),
              onFileUploadFailed: (event, error) => this.onUploadError(error),
              // images larger than this size will be resized by the picker
              imageMax: [1920, 1920],
              maxFiles:
                quotaDetails.maxStorageFilesCount -
                quotaDetails.currentStorageFilesCount,
              maxSize: 500 * 1024 * 1024, // 500MB
              // accept: ['image/*', 'video/*', 'audio/*', 'text/*', '.pdf', '.doc', '.docx', '.docm', '.ppt', '.pptx', '.pptm', '.xls', '.xlsx', '.xlsm', '.odt', '.ods', '.odp'],
              accept: [
                '.jpg',
                '.jpeg',
                '.png',
                '.tif',
                '.tiff',
                '.gif',
                '.bmp',
                '.mp3',
                '.ogg',
                '.wav',
                '.wma',
                '.flac',
                '.alac',
                '.mp4',
                '.webm',
                '.avi',
                '.mpeg',
                '.wmv',
                '.ogg',
                '.pdf',
                '.doc',
                '.docx',
                '.docm',
                '.odt',
                '.txt',
                '.ppt',
                '.pptx',
                '.pptm',
                '.odp',
                '.xls',
                '.xlsx',
                '.xlsm',
                '.ods',
                '.csv',
              ],
              storeTo: {
                location: 'azure',
                container: 'main',
                // workflows: [{ id: '2805fdaf-df4a-4e9b-805f-6d1e86c6a8f4' } as WorkflowConfig],
              } as PickerStoreOptions,
            } as PickerOptions;

            const filestackClientOptions = {
              security: {
                policy: policy.filestackPolicy,
                signature: policy.filestackSignature,
              } as Security,
            } as ClientOptions;

            this.filestackService.init(
              this.appSettingsService.fileStackApiKey,
              filestackClientOptions
            );
            this.picker = this.filestackService.picker(pickerOptions);
            this.picker.open();
          });
      }
    });
  }

  // When filestack completes the upload, we alsso save it to our database
  onUploadSuccess(pickerResponse: PickerResponse) {
    if (pickerResponse.filesFailed.length > 0) {
      this.snackbarService.showGeneralMessage(
        'Error: Failed to save uploaded files'
      );
      return;
    }

    this.documentsTabService.createFiles(pickerResponse).subscribe(
      (resultFiles) => {
        // update the table:
        if (resultFiles.some((file) => file.isDeleted)) {
          const params = new AlertDialogParams();
          params.title = 'Some of the files were not uploaded';
          params.description = `<p>You have exceeded your storage limit. The following files were not uploaded:</p>
        <ul style="padding: 0; max-height: 150px; overflow: auto;">`;
          resultFiles
            .filter((file) => file.isDeleted)
            .forEach((deletedFile) => {
              params.description += `<li>${deletedFile.displayName}.${deletedFile.extension}</li>`;
            });
          params.description += '</ul>';

          this.dialogService.alertDialog(params);
        } else {
          this.holdingId$.pipe(take(1)).subscribe((holdingId) => {
            this.userService
              .getClientDetails()
              .pipe(take(1))
              .subscribe((clientDetails) => {
                this.telemetryService.create(
                  {
                    eventID: '400',
                    eventTitle: 'GP SHARED DOCUMENT WITH INVESTORS',
                    holdingID: holdingId,
                    organizationID: clientDetails.organizationDetails.id,
                    additional: {
                      numberOfFiles: resultFiles.length,
                    },
                  },
                  AnalyticsServiceNameModel.Mixpanel |
                    AnalyticsServiceNameModel.Insights
                );
              });
          });
          this.snackbarService.showGeneralMessage(
            resultFiles.length > 1 ? 'Files uploaded' : 'File uploaded'
          );
        }
      },
      (error) => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          this.snackbarService.showGeneralMessage(
            'Error: Failed to upload file(s)'
          );
        }
      }
    );
  }

  onUploadError(error: any) {
    console.log('onUploadError: ', error);
  }

  sendUploadTelemetry() {
    this.holdingId$.pipe(take(1)).subscribe((holdingId) => {
      this.userService
        .getClientDetails()
        .pipe(take(1))
        .subscribe((clientDetails) => {
          this.telemetryService.create(
            {
              eventID: '4001',
              eventTitle: 'Holding-Upload-and-Share-Start-Clicked',
              holdingID: holdingId,
              organizationID: clientDetails.organizationDetails.id,
            },
            AnalyticsServiceNameModel.Mixpanel |
              AnalyticsServiceNameModel.Insights
          );
        });
    });
  }

  createFolder() {
    const configParams = new MatDialogConfig();
    configParams.closeOnNavigation = true;
    configParams.viewContainerRef = this.vcr;
    this.dialog
      .open(CreateFolderDialogComponent, configParams)
      .afterClosed()
      .pipe(
        filter((folderName) => folderName),
        switchMap((folderName) =>
          this.documentsTabService.createFolder(folderName)
        )
      )
      .subscribe(
        () => {
          this.snackbarService.showGeneralMessage('Folder created');
        },
        (error) => {
          if (ErrorMatcher.isError(error, ErrorType.AlreadyExistsException)) {
            this.snackbarService.showGeneralMessage(
              `A folder with the same name already exists`,
              5
            );
          } else {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            } else {
              console.log(error);
              this.snackbarService.showGeneralMessage(
                `Error: Couldn't create the folder`
              );
            }
          }
        }
      );
  }

  deleteItems(ids: number[]) {
    const dialogParams = new ConfirmDialogParams();
    dialogParams.actionLabel = `Delete`;
    if (ids.length === 1) {
      dialogParams.title = `Delete item`;
      dialogParams.description = `<p>Are you sure you want to delete this item?</p><p>Item will be deleted immediately, and you cannot undo this action.</p>`;
    } else if (ids.length > 1) {
      dialogParams.title = `Delete ${ids.length} items`;
      dialogParams.description = `<p>Are you sure you want to delete these items?</p><p>Items will be deleted immediately, and you cannot undo this action.</p>`;
    }

    this.dialogService
      .confirmDialog(dialogParams)
      .afterClosed()
      .pipe(
        untilComponentDestroyed(this),
        filter((isConfirmed) => isConfirmed),
        switchMap((_) => this.documentsTabService.deleteMultiple(ids))
      )
      .subscribe(
        () => {
          this.snackbarService.showGeneralMessage(
            ids.length === 1 ? 'Item deleted' : `${ids.length} items deleted`
          );
          this.documentsTabService.refreshQuota();
        },
        (error) => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          }
        }
      );
  }
}
