<terra-dialog-header>
  <h1>Upload files</h1>
</terra-dialog-header>

<mat-dialog-content>
  <p class="description">The agreement is made of the following file(s), you can add, remove files, and change the order of files in the
    agreement</p>

  <mat-list class="files-list" cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-list-item class="list-item" cdkDrag *ngFor="let agreementItem of agreementFiles" hover-class="hover">
      <mat-icon svgIcon="drag_handle" class="notranslate drag-handle" cdkDragHandle></mat-icon>
      <span> {{ agreementItem.file.name }}</span>
      <mat-progress-bar *ngIf="agreementItem.isUploading" mode="determinate" [value]="agreementItem.uploadProgress"
        class="custom-progress-bar"></mat-progress-bar>
      <div class="actions">
        <button type="button" tabindex="-1" class="remove-file-button" (click)="removeFile(agreementItem)">
          <mat-icon class="notranslate">close</mat-icon>
        </button>
      </div>
    </mat-list-item>
  </mat-list>

  <terra-files-selector class="file-selector-button" (filesSelected)="moreFilesSelected($event)">
    <mat-icon class="add-icon notranslate">add</mat-icon>
    Upload more files
  </terra-files-selector>

  <p class="file-uploader-hint">File format must be JPG, JPEG, PNG (20 MB max file size) or PDF (50 MB max file size)</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" (click)="submit()" form="dialogForm" [disabled]="isSubmitDisabled" mat-flat-button color="primary" class="page-cta">
    Next
  </button>
  <button type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
