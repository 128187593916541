import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { InvestmentReqRes } from 'src/app/dashboard/models/investment.model';
import { HoldingType } from '../holdingType.type';
import SignPlatform from 'src/app/shared/enums/SignPlatform.enum';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { RoutingService } from 'src/app/services/routing.service';
import { PermissionService } from 'src/app/permission/permission.service';
import { BaseResponseDto } from 'src/app/shared/models/BaseResponseDto.model';
import { GpInvestmentDataService } from 'src/app/services/gp/gp-investment-data.service';
import { BehaviorSubject, combineLatest, filter, finalize, map, of, switchMap, take, tap } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'terra-holding-upload-signed-agreement-block-component',
  templateUrl:
    './holding-upload-signed-agreement-block-component.component.html',
  styleUrls: [
    './holding-upload-signed-agreement-block-component.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldingUploadSignedAgreementBlockComponentComponent
  implements OnInit
{
  @Input() holdingType: HoldingType;
  @Input() holdingId: number;
  @Input() investment: InvestmentReqRes;
  @Input() readOnlyMode = true;

  allowInvestorName$ = this.permissionService.allowInvestorName$;
  // enums
  SignPlatform = SignPlatform;
  InvestmentStatus = InvestmentStatus;

  pageForm: UntypedFormGroup;
  deleteInProcess$ = new BehaviorSubject<boolean>(false);

  get getSignatureUrl(): string {
    const contact = this.investment.investingEntity?.contact;
    let res = '#';
    if (!this.investment || !contact) {
      return res;
    }
    res = this.routingService.gpSignAgreementUrl(
      this.investment.id,
      contact.uniqueIdentifier
    );

    return res;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private gpInvestmentDataService: GpInvestmentDataService,
    private utilsService: UtilsService,
    private permissionService: PermissionService,
    private routingService: RoutingService,
    private cdr: ChangeDetectorRef,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.generateForm();
  }

  generateForm() {
    this.pageForm = this.fb.group({
      signatureDocument: [null, [Validators.required]],
    });

    this.pageForm.get('signatureDocument').valueChanges.pipe(
      filter(metaFileLink => !!metaFileLink),
      switchMap(metaFileLink => this.gpInvestmentDataService.updateDocument(this.holdingId, this.investment.fundraisingId, this.investment.id, metaFileLink.id)),
      switchMap(investment=> combineLatest([this.gpInvestmentDataService.copyAgreementToHoldingDocuments(this.holdingId, this.investment.fundraisingId, this.investment.id), of(investment)])),
      map(([_, investment]) => investment)

    )
    .subscribe({
      next: (response: InvestmentReqRes) => {
        if (response && response.agreementMetaFileLinkId) {
          this.investment.agreementMetaFileLink = response.agreementMetaFileLink;
          this.investment.agreementMetaFileLinkId = response.agreementMetaFileLinkId;
        }
      },
      error: (error) => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        }
      }}
    );
  }


  deleteAgreement(){
    this.deleteInProcess$.next(true);
    return this.gpInvestmentDataService.deleteAgreement(this.holdingId, this.investment.fundraisingId, this.investment.id)
    .pipe(
      take(1),
      finalize(() => this.deleteInProcess$.next(false))
    )
    .subscribe({
      next: (response: InvestmentReqRes) => {
        this.investment.agreementMetaFileLink = null;
        this.investment.agreementMetaFileLinkId = null;
        this.snackbarService.showGeneralMessage("The agreement was successfully deleted");
        this.pageForm.get('signatureDocument').setValue(null, {emitEvent: false})
        this.cdr.detectChanges();
      },
      error: (error) => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        }
      }}
    );
  }
}
