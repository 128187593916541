<ng-container *ngIf="{ photoUrls: photoUrls$ | async, asset: asset$ | async } as data">
  <form id="stepForm" class="form-grid terra-error-style">
    <h3 class="section-title">Preview: this is how your investors will see the report once it’s published on Covercy
      Investor Portal</h3>
    <p class="holding-down-stripe full-width" *ngIf="data.asset.isPrivate">
      When the asset mode is down, it will not be available on Investor Portal, and investors will not receive an update
      about the new
      published report.
    </p>
  </form>

  <div class="phone">
    <div class="preview-wrapper" #previewWrapper>
      <!--      <terra-image-slider id="image-carousel" [imageUrls]="data.photoUrls"></terra-image-slider>-->
      <!-- Asset title -->
      <ng-container *ngIf="lpAssetAsMappedFromGpAsset$ | async as lpAsset">
        <div class="header-section">
          <terra-holding-general-information [holding]="lpAsset" [holdingDiscriminator]="lpAsset.discriminator"
                                             class="preview">
          </terra-holding-general-information>
          <img class="tabs" src="/assets/images/general/report_preview/reports_nav.png"/>
        </div>

        <terra-holding-investment-performance [cumulativeProjectedPerformance]="lpAsset.cumulativeProjectedPerformance" [isGpPreview]="true"
                                              [holdingDiscriminator]="lpAsset.discriminator" class="top-bullets details-section">
        </terra-holding-investment-performance>

        <terra-holding-capital-information [holding]="lpAsset" [isPhonePreview]="true" class="details-section"></terra-holding-capital-information>

        <terra-holding-project-information *ngIf="lpAsset.investmentType === InvestmentType.Opportunistic" [holding]="lpAsset"
                                           class="details-section">
        </terra-holding-project-information>

        <terra-asset-spend-to-date-section *ngIf="lpAsset.projectBudgetUpdated" [asset]="lpAsset" class="details-section">
        </terra-asset-spend-to-date-section>

        <terra-holding-project-information *ngIf="lpAsset.investmentType === InvestmentType.ValueAdd"
                                           [holding]="lpAsset" class="details-section">
        </terra-holding-project-information>

        <!-- Report section -->
        <div class="reports-section">
          <!-- Report selector -->
          <div class="terra-form-field-wrapper report-select">
            <label for="ddlReportId" class="text-input-label">Select a report</label>
            <mat-form-field appearance="outline" class="remove-error-place">
              <mat-select id="ddlReportId" placeholder="Select" [(ngModel)]="reportInformation">
                <mat-option *ngFor="let reportListItem of pseudoReportList" [value]="reportListItem">
                  {{ getReportName(reportListItem) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <terra-holding-report *ngIf="lpAssetReportSection$ | async  as report" [report]="report"
                                [holdingCurrency]="data.asset.initialCurrency" [isExample]="false"
                                [isGpPreviewMode]="true" [holdingDiscriminator]="HoldingDiscriminator.Asset">
          </terra-holding-report>
        </div>
      </ng-container>

    </div>
  </div>

  <section class="footer">
    <!-- Error message -->
    <div class="error-section">
      <p *ngIf="isGeneralServerError" class="error-message">{{ generalServerErrorMessage }}</p>
    </div>
    <div class="form-actions">
      <a (click)="back()" class="cancel-button">Back</a>
      <div class="cta-wrapper">
        <!-- When asset is Private -->
        <button *ngIf="data.asset.isPrivate" type="button" (click)="saveAndPublish()" form="stepForm" mat-flat-button
                color="primary" class="page-cta"> Publish Report
        </button>
        <!-- When asset is Public -->
        <button *ngIf="!data.asset.isPrivate" type="button" (click)="saveAndPublish()" form="stepForm" mat-flat-button
                color="primary" class="page-cta"> Publish Report
        </button>
        <a class="light link save-only"
           (click)="save()">{{ (isAlreadyPublished$ | async) ? 'Save draft' : 'Save draft & publish later' }} </a>
      </div>
    </div>
  </section>
</ng-container>
