import {Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {combineLatest} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import {
  FundSendMarketingDialogComponent,
  FundSendMarketingDialogContext
} from './fund-send-marketing-dialog/fund-send-marketing-dialog.component';
import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import {ShareMarketingParams} from 'src/app/dashboard/assets/gp-asset/share-asset/ShareMarketingParams.model';
import {SnackbarService} from 'src/app/services/snackbar.service';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {UserService} from 'src/app/services/shared/user.service';
import {MetaFileLink} from 'src/app/models/metaFileLink.model';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {GpFundService} from '../gp-fund.service';
import {FundInvestorService} from '../fund-investor/fund-investor.service';
import {FundFundraisingService} from '../fund-fundraising.service';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';

@Component({
  selector: 'terra-share-fund',
  templateUrl: './share-fund.component.html',
  styleUrls: ['./share-fund.component.scss']
})
export class ShareFundComponent extends OnDestroyMixin implements OnInit {
  dateFormat = TerraUtils.consts.LONG_DATE_AND_TIME;

  constructor(
    private dialog: MatDialog,
    private fundInvestorService: FundInvestorService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private gpFundService: GpFundService,
    private fundFundraisingService: FundFundraisingService,
    private telemetryService: TelemetryService,
    private utilsService: UtilsService
  ) {
    super();
  }

  @Input() investment: InvestmentReqRes;

  @Input() marketingDeck: MetaFileLink;

  @Input() holdingName: string;

  @Input() isHoldingPrivate: boolean;

  ngOnInit() {
  }

  showModal() {
    combineLatest([this.userService.accountDetails$, this.fundFundraisingService.fundraising$]).pipe(
      take(1),
      switchMap(([agentDetails, fundraisingDetails]) => {
        const dialogConfig = new MatDialogConfig<FundSendMarketingDialogContext>();
        dialogConfig.disableClose = false;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'send-marketing-dialog';

        const shareMarketingParams = new ShareMarketingParams();
        shareMarketingParams.investmentId = this.investment.id;
        shareMarketingParams.investingEntityName = this.investment.investingEntity.name;
        shareMarketingParams.investingEntityNickname = this.investment.investingEntity.nickname;
        shareMarketingParams.investorName = TerraUtils.getContactFullName(this.investment.investingEntity.contact);
        shareMarketingParams.investorNickname = this.investment.investingEntity.contact?.nickname
        shareMarketingParams.marketingFile = this.marketingDeck;
        shareMarketingParams.agentCompanyName = agentDetails.organizationDetails.name;
        shareMarketingParams.agentFirstName = agentDetails.firstName;
        shareMarketingParams.lpFirstName = this.investment.investingEntity.contact.firstName;
        shareMarketingParams.assetName = this.holdingName;
        shareMarketingParams.investmentSecurityType = fundraisingDetails.securityType;

        dialogConfig.data = new FundSendMarketingDialogContext(shareMarketingParams, this.fundInvestorService, this.userService);

        return this.dialog.open(FundSendMarketingDialogComponent, dialogConfig).afterClosed();
      }),
      untilComponentDestroyed(this))
      .subscribe((isSent: boolean) => {
        if (isSent) {
          this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
            this.telemetryService.create({
              eventID: '402',
              eventTitle: 'MARKETING DECK SENT',
              holdingID: this.investment.assetId,
              organizationID: clientDetails.organizationDetails.id
            }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
          });
          this.snackbarService.showGeneralMessage('Offering deck sent');
        }
      });
  }

  markAsSoftCircled() {
    this.fundInvestorService.updateStatus(InvestmentStatus.SoftCircled)
    .subscribe(
      (updatedInvestment: InvestmentReqRes) => { },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        }
        console.log('Error. Status was not updated.', error);
      }
    );
  }

  holdingDownInfo() {
    this.gpFundService.holdingDownInfo();
  }
}
