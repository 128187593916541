import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { StartInitialFundraisigStepNumber, StartInitialFundraisingService } from './start-initial-fundraising.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';

@Component({
  selector: 'terra-start-initital-fundraising',
  templateUrl: './start-initial-fundraising.component.html',
  styleUrls: ['./start-initial-fundraising.component.scss'],
  providers: [StartInitialFundraisingService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartInitialFundraisingComponent implements OnInit {

  processingSubmit$ = this.startInitialFundraisingService.processingSubmit$;
  currentStep$ = this.startInitialFundraisingService.currentStep$;

  constructor(private titleService: Title, private startInitialFundraisingService: StartInitialFundraisingService) { }

  ngOnInit() {
    this.titleService.setTitle('Create Fundraising' + TerraUtils.consts.GpPageTitleSuffix);
  }

  navigateBackToStep(step: StartInitialFundraisigStepNumber) {
    const currentStep = this.currentStep$.value;
    if (currentStep > step) {
      this.currentStep$.next(step);
    }
  }

  cancel() {
    this.startInitialFundraisingService.cancel();
  }
}
