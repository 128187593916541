<div class="match-block">
  <mat-card class="match-card mat-elevation-z2" [ngClass]="{'warning': unmatched>0, 'success': unmatched ==0}">
    <ng-container *ngIf="unmatched == 0">
      <mat-icon class="share-icon success">
        check
      </mat-icon>
      <span>
        <b>{{dataSource.length}}</b>
        files have been {{automatched ? 'automatically' : ''}} paired with investors.
      </span>
    </ng-container>

    <ng-container *ngIf="unmatched > 0">
      <span class="share-icon warning">i</span>
      <span>
        <b>{{unmatched}}</b>
        files have not been automatically matched, please specify the investing entity you wish to share them with.
      </span>
    </ng-container>
  </mat-card>


  <table mat-table [dataSource]="dataSource" class="cov-basic-table gray-theme mat-elevation-z0">

    <!-- Document Name -->
    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef> Document Name </th>
      <td mat-cell *matCellDef="let element">
        <terra-file-preview [file]="element.file"></terra-file-preview>
      </td>
    </ng-container>

    <!-- Match Status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="center"> Match Status </th>
      <td mat-cell *matCellDef="let element" class="center">
        <mat-icon class="share-icon success" *ngIf="element.sharedWith">
          check_circle
        </mat-icon>
        <mat-icon class="share-icon warning" *ngIf="!element.sharedWith">
          cancel
        </mat-icon>
      </td>
    </ng-container>

    <!-- Investor -->
    <ng-container matColumnDef="sharedWith">
      <th mat-header-cell *matHeaderCellDef> Investor </th>
      <td mat-cell *matCellDef="let element">
        <p-dropdown class="share-input" [ngClass]="{'warning': !element.sharedWith}" [options]="investors"
          (onChange)="notify()" [(ngModel)]="element.sharedWith" optionLabel="name" [filter]="true" filterBy="name"
          [showClear]="true" placeholder="Select investor to match" filterPlaceholder="Search">

          <ng-template pTemplate="selectedItem" let-selectedOption>
            <terra-investor [investor]="selectedOption"></terra-investor>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <terra-investor [investor]="item" full="true"></terra-investor>
          </ng-template>
        </p-dropdown>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
