import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import { CapitalCallService } from '../../capital-call/capital-call.service';

@Component({
  selector: 'terra-fundraising-investors-statistics-card',
  templateUrl: './fundraising-investors-statistics-card.component.html',
  styleUrls: ['./fundraising-investors-statistics-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundraisingInvestorsStatisticsCardComponent implements OnInit {

  totalInvestors$ = this.capitalCallService.investments$.pipe(
    map(investments => investments.length)
  );

  totalInvested$ = this.capitalCallService.investments$.pipe(
    map(investments => investments.filter(inv => inv.status === InvestmentStatus.Invested).length)
  );

  constructor(
    private capitalCallService: CapitalCallService
  ) { }

  ngOnInit(): void {
  }

}
