<div class="sub-section full-width">
  <mat-checkbox color="primary" [(ngModel)]="notify" (change)="sendEmail.emit($event?.checked)">
    Notify by email
  </mat-checkbox>

  <ng-container *ngIf="notify">
    <p>Add a personal message to the email (optional).</p>
    <quill-editor id="txtDescription" [formControl]="editorControl" format="html" class="lo_sensitive description-editor" placeholder="" />

  </ng-container>


  <h4 class="title">Documents for sharing <span>({{dataSource.length}}/{{list.length}})</span></h4>
  <p class="description mt-1">Review the following documents and recipients list before sharing.</p>

  <table mat-table [dataSource]="dataSource" class="cov-basic-table gray-theme mat-elevation-z0">

    <!-- Document Name -->
    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef> Document Name </th>
      <td mat-cell *matCellDef="let element">
        <terra-file-preview [file]="element.file"></terra-file-preview>
      </td>
    </ng-container>

    <!-- Recipient(s) -->
    <ng-container matColumnDef="sharedWith">
      <th mat-header-cell *matHeaderCellDef class="share-th">Recipient{{displayedColumns.length==1 ? 's' : ''}} </th>
      <td mat-cell *matCellDef="let element">
        <terra-investor [investor]="element.sharedWith"></terra-investor>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
