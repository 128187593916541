import { ComponentFactoryResolver, ComponentRef, EnvironmentInjector, Injectable, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidePanelService {
  private sidenav!: MatSidenav;
  private viewContainerRef!: ViewContainerRef;
  private currentComponentRef?: ComponentRef<any>;
  private componentData: any;
  private outputSubject = new Subject<any>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private environmentInjector: EnvironmentInjector
  ) {}

  public setSidenav(sidenav: MatSidenav, viewContainerRef: ViewContainerRef) {
    this.sidenav = sidenav;
    this.viewContainerRef = viewContainerRef;
  }

  public open(
    component: any,
    data: any,
    callbacks?: {
      closedStart?: () => void,
      openedChange?: (opened: boolean) => void
    }
  ): Observable<any> {
    this.componentData = data; 

    const openOrUpdateComponent = () => {
      if (this.currentComponentRef) {
        Object.assign(this.currentComponentRef.instance, this.componentData);
      } else {
        this.viewContainerRef.clear();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.currentComponentRef = this.viewContainerRef.createComponent(componentFactory.componentType, {
          injector: this.environmentInjector
        });

        if (this.componentData) {
          Object.assign(this.currentComponentRef.instance, this.componentData);
        }

        if (callbacks) {
          this.currentComponentRef.instance['openedChange'] = callbacks.openedChange;
          this.currentComponentRef.instance['closedStart'] = callbacks.closedStart;
        }

        if (this.currentComponentRef.instance.output) {
          this.currentComponentRef.instance.output.subscribe((outputData: any) => {
            this.outputSubject.next(outputData);
          });
        }

        this.sidenav.openedChange.subscribe((opened: boolean) => {
          if (callbacks?.openedChange) {
            callbacks.openedChange(opened);
          }
          if (this.currentComponentRef?.instance['openedChange']) {
            this.currentComponentRef.instance['openedChange'](opened);
          }
        });
      }

      this.sidenav.open();
    };

    if (this.sidenav.opened) {
      this.sidenav.close().finally(() => {
        openOrUpdateComponent();
      });
    } else {
      openOrUpdateComponent();
    }

    return this.outputSubject.asObservable();
  }

  public close() {
    return this.sidenav?.close();
  }

  public closeWithWipe(): Promise<void> {
    return new Promise((resolve) => {
      this.viewContainerRef.clear();
      this.sidenav.close().finally(() => {
        resolve();
      });
    });
  }
}