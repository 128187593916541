<h3 class="title">Next: Share this fund with your client</h3>

<p class="holding-down-stripe" *ngIf="isHoldingPrivate">Offering decks cannot be sent when the fund mode is down.
  <a class="light link" (click)="holdingDownInfo()">Learn more</a>
</p>

<div class="info-message" *ngIf="investment.isMarketingDeckWasSent">
  Offering deck sent on {{ investment.marketingDeckSendDate | date: dateFormat }}.<br />
  <span *ngIf="investment.marketingDeckSentEmail" class="lo_sensitive">To {{investment.marketingDeckSentEmail}}</span>
</div>

<button *ngIf="marketingDeck && marketingDeck.id" [disabled]="isHoldingPrivate" (click)="showModal()" mat-flat-button color="primary"
  class="send-marketing-button tall-button">
  Send Offering Deck
</button>

<terra-fund-investor-reactions-to-offering-deck *ngIf="investment.isMarketingDeckWasSent"></terra-fund-investor-reactions-to-offering-deck>

<p *ngIf="!marketingDeck || !marketingDeck.id">
  Please update the fund and add an offering deck file, in order to be able to send it to the contact.
</p>

<span class="poistive-response-text">
  Did you get a positive response from your client?
  <a class="light link" (click)="markAsSoftCircled()">Mark as soft circled</a>
</span>
