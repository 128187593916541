import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { ReportFAQReqRes } from 'src/app/shared/models/reportFAQReqRes.model';
import { CreateEditFaqDialogContext } from './CreateEditFaqDialogContext';
 

@Component({
  selector: 'terra-create-edit-faq',
  templateUrl: './create-edit-faq-dialog.component.html',
  styleUrls: ['./create-edit-faq-dialog.component.scss']
})
export class CreateEditFaqDialogComponent implements OnInit {

  isEditMode = this.context.isEditMode;

  pageForm = this.fb.group({
    question: [this.context.reportFaq.question, [Validators.required, Validators.maxLength(280)]],
    answer: [this.context.reportFaq.answer, [Validators.required, Validators.maxLength(560)]]
  });

  isSubmitted = false;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateEditFaqDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: CreateEditFaqDialogContext) { }

  ngOnInit() {}

  getUpdatedFaq() {
    const formValues = this.pageForm.value;

    let model: ReportFAQReqRes;
    if (this.isEditMode) {
      // If editing a server (existing) faq, we want to duplicate and not change the original, so that we can undo the changes
      if (this.context.reportFaq.id) {
        model = { ...this.context.reportFaq };
      } else {
        // If editing a pending faq (that was not saved yet), update the original, cause we don't need to undo changes.
        // This way we can find it in the list of existing items, and replace it if necessary.
        model = this.context.reportFaq;
      }
    } else {
      // Create mode:
      model = new ReportFAQReqRes();
    }

    // update the values:
    model.isEdited = this.isEditMode;
    model.question = formValues.question;
    model.answer = formValues.answer;

    return model;
  }

  save() {
    this.isSubmitted = true;
    if (!this.pageForm.valid) {
      return;
    }

    if (this.pageForm.pristine) {
      this.dialogRef.close(null);
    } else {
      const faq = this.getUpdatedFaq();
      this.dialogRef.close(faq);
    }
  }
}
