<ng-template #stepLoading>
  <terra-loader class="container-750 place-middle"></terra-loader>
</ng-template>


<form id="stepForm" [formGroup]="pageForm" *ngIf="stepIsLoading$ | async; else stepLoading" (ngSubmit)="stepSubmit()"
      class="form-grid-new container-750 terra-error-style">

  <!-- Investing entity  -->
  <h3 class="section-title">
    Legal entity
  </h3>
  <!-- Investing entity form -->
  <ng-container [formGroup]="pageForm.get('investingEntity')">

    <!-- Name -->
    <div class="terra-form-field-wrapper">
      <label for="txtName" class="text-input-label">Name</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtName" formControlName="name" class="lo_sensitive"
               placeholder="e.g. Fund 3 LLC or e.g my fund LLC"/>
        <mat-error *ngIf="pageForm.get('investingEntity.name').hasError('required')"> Required</mat-error>
        <mat-error *ngIf="pageForm.get('investingEntity.name').hasError('minlength')">Name must be more than one
          character
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Entity Type -->
    <div class="terra-form-field-wrapper">
      <label for="ddlInvestingEntityType" class="text-input-label">Legal entity type</label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="investingEntityType" id="ddlInvestingEntityType" placeholder="Select">
          <mat-option *ngFor="let investingEntityType of InvestingEntityType.listAll()" [value]="investingEntityType">
            {{ investingEntityType | enumString: InvestingEntityType }} </mat-option>
        </mat-select>
        <mat-error *ngIf="pageForm.get('investingEntity.investingEntityType').hasError('required')"> Required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- TIN -->
    <div class="terra-form-field-wrapper tin-field new-row">
      <mat-icon class="notranslate" color="primary" matTooltipClass="terra-tooltip tin-tooltip"
                matTooltipPosition="right" matTooltip="The TIN varies per country, for example:
              UK: The Unique Taxpayer Reference (UTR)
              US: The Employer ID Number (EIN)
              Germany: The Tax ID (IdNr)
              Israel: The Business Registration Number (ח״פ)">help
      </mat-icon>
      <label for="txtTin" class="text-input-label">TIN (optional)</label>
      <mat-form-field appearance="outline" class=half-width>
        <input matInput id="txtTin" formControlName="tin" class="lo_sensitive uppercase">
        <mat-error *ngIf="pageForm.get('investingEntity.tin').hasError('required')">
          required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Accounting external ID (Yardi etc.) -->
    <div class="terra-form-field-wrapper">
      <mat-icon class="notranslate" color="primary" matTooltipClass="terra-tooltip tin-tooltip"
                matTooltipPosition="right"
                matTooltip="If you are using a 3rd party accounting system, you may copy the accounting ID here">help
      </mat-icon>
      <label for="txtAccountingExternalId" class="text-input-label">Accounting ID (optional)</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtAccountingExternalId" formControlName="accountingExternalId" class="lo_sensitive"/>
        <mat-error *ngIf="pageForm.get('investingEntity.accountingExternalId').hasError('required')"> Required
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <!-- Bank account title -->
  <h3 class="section-title">
    Bank account for collecting deposits
  </h3>

  <!-- Bank account -->
  <div class="terra-form-field-wrapper" *ngIf="!noAccessMessage">
    <label for="ddlBankAccount" class="text-input-label">Choose from existing:</label>
    <mat-form-field appearance="outline" *ngIf="bankAccounts$ | async as bankAccounts">
      <mat-select id="ddlBankAccount" [compareWith]="bankAccountComparer" [placeholder]="
          bankAccounts.length? 'Select' : 'No ' + selectedCurrency.iso + ' bank accounts found'"
                  formControlName="bankAccount">
        <mat-option [value]="null">Select</mat-option>
        <mat-option *ngFor="let bankAccount of bankAccounts" [value]="bankAccount">
          {{ bankAccount.nickname }} {{ !bankAccount.nickname ? bankAccount.holderFullName + ', ' : '' }}
          {{ !bankAccount.nickname ? getCountryById(bankAccount.countryId).code : '' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="!noAccessMessage">
    <a class="link add-bank-account" (click)="AddBankAccount()">Add new</a>
  </div>
  <!-- Bank account -->

  <div class="full-width not-allow-message" *ngIf="noAccessMessage">
    <mat-icon class="notranslate">error</mat-icon>
    <h4>{{noAccessMessage}}</h4>
  </div>

  <h3 class="section-title">
    Investor portal
  </h3>
  <div class="full-width terra-form-field-wrapper allow-add-bank">
    <span>Investors can set bank account</span>
    <mat-slide-toggle hideIcon labelPosition="before" formControlName="allowLpSetBank"></mat-slide-toggle>
  </div>

  <!-- Eerly deposit title -->
  <h3 class="section-title">
    Collect deposits into Covercy safeguarded account (optional)
  </h3>
  <!-- Early Deposite description -->
  <p class="early-deposit-text description full-width">
    Guarantee the availability of funds at closing by requesting deposits
    during the fundraising process. The funds received will be parked in a
    segregated account until the closing terms are met.
  </p>
  <!-- Early Deposite checkbox -->
  <div class="full-width">
    <mat-checkbox class="regular-checkbox early-deposit-checkbox" color="primary" formControlName="isCollectDeposists">I
      want to collect
      deposits in Covercy's segregated account
    </mat-checkbox>
    <p *ngIf="pageForm.get('isCollectDeposists').value" [@enterAnimation] class="info-message">
      Your Covercy account manager will reach out shortly with more
      information about early deposits.
    </p>
  </div>

  <!-- Fund down mode -->
  <h3 class="section-title">Fund down mode</h3>
  <terra-holding-is-down-card [holdingDiscriminator]="HoldingDiscriminator.Fund"
                              [isPrivate]="pageForm.get('isPrivate').value"
                              class="full-width">
    <mat-slide-toggle hideIcon labelPosition="before" formControlName="isPrivate"></mat-slide-toggle>
  </terra-holding-is-down-card>
</form>

<section class="footer">
  <div class="container-750">
    <!-- Error message -->
    <div *ngIf="isSubmitAttempt" class="error-section">
      <p *ngIf="pageForm.invalid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
    <div class="form-actions">
      <a (click)="back()" class="cancel-button">Back</a>
      <button type="submit" form="stepForm" mat-flat-button color="primary" class="page-cta">
        Create fund
      </button>
    </div>
  </div>
</section>
