import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

import { InvestmentType } from 'src/app/shared/enums/InvestmentType.enum';
import {InvestmentSecurityType} from 'src/app/shared/enums/InvestmentSecurityType.enum';
import { CreateFundAssetService, CreateFundAssetStepNumber } from 'src/app/dashboard/assets/components/create-fund-asset/create-fund-asset.service';
import { CreateFundAssetStepBase } from 'src/app/dashboard/assets/components/create-fund-asset/CreateFundAssetStepBaseAndInterface';
import {AreaUnit} from 'src/app/shared/enums/AreaUnit.enum';
import {InterestType} from 'src/app/shared/enums/InterestType.enum';
import {LoanType} from 'src/app/shared/enums/LoanType.enum';
 

@Component({
  selector: 'terra-create-fund-asset-investment-details-step',
  templateUrl: './create-fund-asset-investment-details-step.component.html',
  styleUrls: ['./create-fund-asset-investment-details-step.component.scss']
})
export class CreateFundAssetInvestmentDetailsStepComponent extends CreateFundAssetStepBase implements OnInit {
  readonly STEP_NUMBER: CreateFundAssetStepNumber = 2;

  // Enums
  InvestmentType = InvestmentType;
  SecurityType = InvestmentSecurityType;
  AreaUnit = AreaUnit;
  InterestType = InterestType;
  LoanType = LoanType;

  InterestTypesList = InterestType.listAll();
  LoanTypesList = LoanType.listAll();

  selectedInvestmentType = this.createFundAssetService.selectedInvestmentType;

  currency$ = this.createFundAssetService.fund$.pipe(map(fund => fund.initialCurrency));

  closingDateStartAt = new Date();
  closingDateMinimumDate = new Date('1970-01-01T12:00');

  maturityDateStartAt = new Date();
  maturityDateMinimumDate = new Date('1970-01-01T12:00');

  constructor(private createFundAssetService: CreateFundAssetService) {
    super(createFundAssetService);
  }

  ngOnInit() {
    this.createFundAssetService.currentStep$.next(this.STEP_NUMBER);
  }
}
