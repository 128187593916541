import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {share, tap, shareReplay, map, filter} from 'rxjs/operators';
import { Sort } from '@angular/material/sort';

import {GpAssetService} from '../../gp-asset.service';
import {FundraisingTableItemResponse} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingTableItemResponse.model';
import FundraisingStatus from 'src/app/shared/enums/FundraisingStatus.enum';
import {RoutingService} from 'src/app/services/routing.service';
import {AppService} from 'src/app/state';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import { TourType } from 'src/app/services/gp/tours.service';
import FundraisingType from 'src/app/shared/enums/FundraisingType.enum';

@Component({
  selector: 'terra-asset-fundraisings',
  templateUrl: './asset-fundraisings.component.html',
  styleUrls: ['./asset-fundraisings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetFundraisingsComponent implements OnInit {

  // consts
  FundraisingStatus = FundraisingStatus;
  relevantTours = [ TourType.ImportContributions];

  emptyState$ = new BehaviorSubject(false);
  displayedColumns: string[] = ['name', 'closingDate', 'investorCount', 'totalInvestedAmount', 'status'];
  assetId$ = this.gpAssetService.holdingId$;
  isDraft$ = this.gpAssetService.holding$.pipe(map(holding => holding.status == HoldingStatus.Draft));
  isRealized$ = this.gpAssetService.holding$.pipe(map(holding => holding.status == HoldingStatus.Realized));
  
  fundraisings$: Observable<FundraisingTableItemResponse[]> = this.gpAssetService.fundraisings$.pipe(
    tap(items => {
      this.emptyState$.next(items.length === 0);
    }),
    map(x=> x.filter(y => [FundraisingType.None,FundraisingType.InitialFundraising, FundraisingType.Contribution].includes(y.type))),
    share()
  );

  createFundraisingUrl$ = this.assetId$.pipe(map(assetId => this.routingService.createAssetFundraising(assetId)), shareReplay(1));
  importContributionRoute$ = this.gpAssetService.importContributionRoute$;
  searchOptions$ = this.gpAssetService.searchOptionsFundraisings$;

  constructor(
    private gpAssetService: GpAssetService,
    private routingService: RoutingService,
    private appService: AppService
  ) { }

  ngOnInit() { }

  getFundraisingPageUrl(assetId: number, fundraisingId: number) {
    return this.routingService.assetFundraising(assetId, fundraisingId);
  }

  sortData(sort: Sort) {
    if (!sort.active || !sort.direction) {
      return;
    }

    this.appService.updateAssetFundraisingSortState({
      orderBy: sort.active,
      direction: sort.direction === 'desc' ? 'desc' : 'asc'
    });
  }
}
