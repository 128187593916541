<h3 class="title">
    <span *ngIf="investment.paymentStatus === PaymentStatus.NotSent">Next: Send payment request</span>
    <span *ngIf="investment.paymentStatus !== PaymentStatus.NotSent">Next: Receive funds</span>
  </h3>

  <p class="holding-down-stripe" *ngIf="isHoldingPrivate">Payment requests cannot be sent when the holding mode is down.
    <a class="light link" (click)="holdingDownInfo()">Learn more</a>
  </p>

  <p *ngIf="!getHoldingBankAccount(fundraisingDetails)" class="error-message">
    In order to send a payment request, update a bank account for collecting deposits.
  </p>
  <p *ngIf="getHoldingBankAccount(fundraisingDetails) && getHoldingBankAccount(fundraisingDetails).isMissingGpData && investment.paymentStatus === PaymentStatus.NotSent"
     class="error-message">
    Due to regulatory requirements, we need to ask for additional information regarding your bank account before sending any further payment
    requests.
    <br/>
    <a (click)="showEditBankAccount()" class="light link">Please click here to fill in the missing information.</a>

  </p>

  <div class="info-message info-message-text" *ngIf="investment.paymentStatus !== PaymentStatus.NotSent">
    Request sent on {{ investment.paymentRequestSendDate | date: dateFormat }}<br/>
    <span *ngIf="investment.paymentRequestSentEmail" class="lo_sensitive">To {{investment.paymentRequestSentEmail}}</span>
  </div>

  <div class="info-message info-message-text bank-holiday-notice" *ngIf="bankHolidays as holidayCountriesStr">
    <mat-icon svgIcon="calendar_notice" class="notranslate"></mat-icon>
    <span>
      <strong>Take note: </strong> Transfer may be delayed due to holidays in {{ holidayCountriesStr }}
    </span>
  </div>

  <div class="description-text">
    Send your client a payment request to guarantee the availability of funds at closing.
    <br>
    <br>
    <button [disabled]="disableIssueCapitalCall$ | async" mat-flat-button type="button" (click)="issueCapitalCall.emit()"
            class="issue-cc-button tall-button" terraCheckPermissions [noPermissionAction]="NoPermissionAction.PopupOnClick"
            [minimumFinancialPermissionLevel]="FinancialPermissionLevel.AuthorizedSignatory">
        Issue Capital Call
    </button>
  </div>

  <span *ngIf="!investment.isOrderCreated && investment.paymentSettlementPlatform !== PaymentPlatform.achDebit" class="funds-received-text" (click)="markAsInvested()">Were the funds received outside Covercy? <a class="light link"> Mark as
      invested</a></span>
