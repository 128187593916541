// Notice:  This enum also sxists on server side. Make sure they stay in sync.
export enum SignPlatform {
  HelloSign = 1,
  External = 2
}

// tslint:disable-next-line:no-namespace
export namespace SignPlatform {
  export function toString(signPlatform: SignPlatform): string {
    switch (signPlatform) {
      case SignPlatform.HelloSign:
        return 'HelloSign';
      case SignPlatform.External:
        return 'Owned';
      default:
        return null;
    }
  }

  export function parse(SignPlatformStr: string): SignPlatform {
    return SignPlatform[SignPlatformStr];
  }
}

export default SignPlatform;
