import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

export class MarkAsTransferredConfirmDialogParams extends ConfirmDialogParams {
  constructor(customPanelClass: string = null) {
    super(`Have the funds been transferred?`,
      `<p>This action is irreversible</p>`,
      `Mark as Transferred`,
      null,
      customPanelClass);
  }
}
