import { Component, OnInit, Input } from '@angular/core';

import { InvestmentReqRes } from 'src/app/dashboard/models/investment.model';
import PaymentPlatform from 'src/app/shared/enums/PaymentPlatform.enum';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { AssetInvestorService } from '../asset-investor.service';

@Component({
  selector: 'terra-asset-download-transfer-confirmation-block',
  templateUrl: './asset-download-transfer-confirmation-block.component.html',
  styleUrls: ['./asset-download-transfer-confirmation-block.component.scss']
})
export class AssetDownloadTransferConfirmationBlockComponent implements OnInit {
  @Input()
  investment: InvestmentReqRes;

  @Input()
  bankAccountNickname: string;

  isLoading = false;

  paymentPlatform = PaymentPlatform;

  constructor(private assetInvestorService: AssetInvestorService, private appSettings: AppSettingsService) {}

  ngOnInit() {}

  downloadFile(filename: string) {
    const link = document.createElement('a');
    link.href = `${this.appSettings.resourceUrl}document/${filename}/`;
    link.download = filename;
    link.dispatchEvent(new MouseEvent('click'));
    this.isLoading = false;
  }

  public transactionConfirmation() {
    this.isLoading = true;

    this.assetInvestorService
      .generateTransferConfirmation()
      .subscribe(
        pdfUrl => {
          this.downloadFile(pdfUrl);
        },
        error => {
          this.isLoading = false;
        }
      );
  }
}
