import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {InvestmentStatus} from 'src/app/shared/enums/InvestmentStatus.enum';
import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import {DialogService} from 'src/app/services/dialog.service';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import PaymentStatus from 'src/app/shared/enums/PaymentStatus.enum';
import PaymentPlatform from 'src/app/shared/enums/PaymentPlatform.enum';
import {FundInvestorService} from '../fund-investor.service';
import {MarkAsSignedConfirmDialogParams} from 'src/app/dashboard/shared/holding/investor/MarkAsSignedConfirmDialogParams';
import {MarkAsInvestedConfirmDialogParams} from 'src/app/dashboard/shared/holding/investor/MarkAsInvestedConfirmDialogParams';
import {MarkAsDeclinedConfirmDialogParams} from 'src/app/dashboard/shared/holding/investor/MarkAsDeclinedConfirmDialogParams';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';

@Component({
  selector: 'terra-fund-investment-status-visual',
  templateUrl: './fund-investment-status-visual.component.html',
  styleUrls: ['./fund-investment-status-visual.component.scss']
})
export class FundInvestmentStatusVisualComponent implements OnInit {
  @Input()
  investment: InvestmentReqRes;

  @Input()
  readOnlyMode = true;

  @Output()
  validateForm = new EventEmitter();

  InvestmentStatus = InvestmentStatus;

  isUpdatingStatus$ = this.fundInvestorService.isUpdatingStatus$;

  get potentialButtonInactive() {
    return this.investment.status !== InvestmentStatus.Potential;
  }

  get softCircledButtonInactive() {
    return this.investment.status !== InvestmentStatus.Potential && this.investment.status !== InvestmentStatus.SoftCircled;
  }

  get signedButtonInactive() {
    return this.investment.status !== InvestmentStatus.SoftCircled && this.investment.status !== InvestmentStatus.Signed;
  }

  get investedButtonInactive() {
    return this.investment.status !== InvestmentStatus.Signed && this.investment.status !== InvestmentStatus.Invested;
  }

  get declinedButtonInactive() {
    // if the status is Potential or Soft circled - no problem.
    // if it's Signed - disable button if the payment status is submitted  or above.
    // if it's Invested - disable if the payment was done inside covercy.
    return (
      (this.investment.status === InvestmentStatus.Signed &&
        (this.investment.paymentStatus === PaymentStatus.Submitted ||
          this.investment.paymentStatus === PaymentStatus.FundsReceived ||
          this.investment.paymentStatus === PaymentStatus.Approved ||
          this.investment.paymentStatus === PaymentStatus.Completed)) ||
      (this.investment.status === InvestmentStatus.Invested && this.investment.paymentSettlementPlatform === PaymentPlatform.Covercy)
    );
  }

  get currentStatusClass(): string {
    switch (this.investment.status) {
      case InvestmentStatus.Potential:
        return 'currently-potential';
      case InvestmentStatus.SoftCircled:
        return 'currently-committed';
      case InvestmentStatus.Signed:
        return 'currently-signed';
      case InvestmentStatus.Invested:
        return 'currently-invested';
      case InvestmentStatus.Declined:
        return 'currently-declined';
      default:
        return '';
    }
  }

  constructor(private fundInvestorService: FundInvestorService,
              private dialogService: DialogService,
              private utilsService: UtilsService) { }

  ngOnInit() { }

  updateStatus(status: InvestmentStatus) {
    if (status === this.investment.status || (!this.fundInvestorService.pageForm.valid && status !== InvestmentStatus.Declined)) {
      return;
    }

    if (status !== InvestmentStatus.Declined) {
      this.validateForm.emit();
    }
    // If moving to signed, ask the user to cinfirm "Signed out of Covercy"
    if (status === InvestmentStatus.Signed) {
      if (!this.investment.estimatedAmount) {
        this.validateForm.emit();
        return;
      }
      this.callUpdateStatusWithConfirmation(InvestmentStatus.Signed);
    } else if (status === InvestmentStatus.Invested) {
      this.callUpdateStatusWithConfirmation(InvestmentStatus.Invested);
    } else if (status === InvestmentStatus.Declined) {
      this.callUpdateStatusWithConfirmation(InvestmentStatus.Declined);
    } else {
      // confirmation is not required..
      this.callUpdateStatus(status);
    }
  }

  private callUpdateStatusWithConfirmation(status: InvestmentStatus) {
    let confirmParams: ConfirmDialogParams;

    switch (status) {
      case InvestmentStatus.Signed:
        confirmParams = new MarkAsSignedConfirmDialogParams();
        break;
      case InvestmentStatus.Invested:
        confirmParams = new MarkAsInvestedConfirmDialogParams();
        break;
      case InvestmentStatus.Declined:
        confirmParams = new MarkAsDeclinedConfirmDialogParams();
        break;
      default:
        return;
    }

    this.dialogService
      .confirmDialog(confirmParams)
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.callUpdateStatus(status);
        }
      });
  }

  private callUpdateStatus(status: InvestmentStatus) {
    this.fundInvestorService.updateStatus(status)
    .subscribe(
      updatedInvestment => { },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        }
        console.log('Error - Status was not updated.', error);
      }
    );
  }
}
