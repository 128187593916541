import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'terra-never-get-here',
  templateUrl: './never-get-here.component.html'
})
export class NeverGetHereComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
