<ng-container *ngIf="assetDetails$ | async as assetDetails">
  <terra-under-management-information class="container" />

  <mat-card class="container tabs-card">

    <nav class="tabs-navigation" mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false" mat-align-tabs="start">
      <!-- Overview tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Overview].url" routerLinkActive #rla1="routerLinkActive"
         [active]="rla1.isActive">
        {{tabsData[Tab.Overview].label}}
      </a>
      <!-- Reports tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Reports].url" routerLinkActive #rla2="routerLinkActive"
         [active]="rla2.isActive">
        {{tabsData[Tab.Reports].label}}
      </a>
      <!-- Documents tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Documents].url" routerLinkActive #rla3="routerLinkActive"
         [active]="rla3.isActive">
        {{tabsData[Tab.Documents].label}}
      </a>
      <!-- Fundraisings tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Fundraisings].url" routerLinkActive #rla4="routerLinkActive"
         [active]="rla4.isActive">
        {{tabsData[Tab.Fundraisings].label}}
      </a>
      <!-- Distributions tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Distributions].url" routerLinkActive #rla5="routerLinkActive"
         [active]="rla5.isActive">
        {{tabsData[Tab.Distributions].label}}
      </a>
      <!-- Transactions tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Transactions].url" routerLinkActive #rla6="routerLinkActive" *ngIf="creTransactionsTabVisible$ | async"
         [active]="rla6.isActive">
        {{tabsData[Tab.Transactions].label}}
      </a>
    </nav>
    <!-- Tabs content  -->
    <div class="tabs-card-inner">
      <mat-tab-nav-panel #tabPanel>
        <router-outlet />
      </mat-tab-nav-panel>
    </div>

  </mat-card>
</ng-container>
