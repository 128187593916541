import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AppSettingsService} from '../app-settings.service';
import {AiRankingReq} from '../../dashboard/shared/holding/fundraising/AI-investors-ranking/AiRankingReq.model';
import {AiRankingReqRes} from '../../dashboard/shared/holding/fundraising/AI-investors-ranking/AiRankingReqRes.model';
import {HttpService} from '../http.service';

@Injectable()
export class GpAIRankingDataService {
  baseUrl = this.appSettingsService.rankingUrl;
  header: HttpHeaders;

  constructor(private http: HttpService, private appSettingsService: AppSettingsService) {
  }

  public getContactsRanking(request: AiRankingReq): Observable<AiRankingReqRes[]> {
    return this.http.postTyped<AiRankingReqRes[]>(this.baseUrl, request).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
