<div class="details-wrapper">
  <h3>Contribution details</h3>
  <hr>
  <form [formGroup]="pageForm" (keydown.enter)="$event.preventDefault()" class="cov-form-grid">
    <section class="form-section">
      <div class="terra-form-field-wrapper">
        <label for="txtFundraisingName" class="text-input-label">Contribution name</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtFundraisingName" formControlName="name" autocomplete="fundraising-name"
            class="lo_sensitive" placeholder="Contribution name" />
          <mat-error *ngIf="pageForm.get('name').hasError('required')"> Required</mat-error>
          <mat-error *ngIf="pageForm.get('name').hasError('minlength')"> Please enter at least 2 characters</mat-error>
          <mat-error *ngIf="pageForm.get('name').hasError('fundraisingNameTaken')"> A contribution with the same name
            already exists
          </mat-error>
        </mat-form-field>
      </div>
      <div class="terra-form-field-wrapper">
        <terra-custom-utc-date-picker id="txtClosingDate" formControlName="finalClosingDate" label="Final closing date"
          placeholder="Final closing date" [requiredError]="pageForm.get('finalClosingDate').hasError('required')"
          [touched]="pageForm.get('finalClosingDate').touched"
          [startAt]="finalClosingDateStartAt"></terra-custom-utc-date-picker>
      </div>
      <div class="terra-form-field-wrapper">
        <label for="ddlInvestmentSecurityType" class="text-input-label">Security type</label>
        <mat-form-field appearance="outline">
          <mat-select id="ddlInvestmentSecurityType" placeholder="Select" formControlName="securityType">
            <mat-option>Select</mat-option>
            <mat-option *ngFor="let item of InvestmentSecurityTypeList" [value]="item"> {{ item | enumString:
              InvestmentSecurityType }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="pageForm.get('securityType').hasError('required')">Required</mat-error>
        </mat-form-field>
      </div>
      <div class="terra-form-field-wrapper">
        <label for="txtCurrency" class="text-input-label">{{gpHoldingService.discriminatorStr}} Currency</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtCurrency" formControlName="currency" class="lo_sensitive" />
        </mat-form-field>
      </div>
    </section>
  </form>
</div>