<ng-container *ngIf="fund$ | async as fund">
  <section class="container information">
    <!-- Images -->
    <div class="terra-card images-card">
      <terra-holding-images [singleLineAddress]="true" [location]="fund.location"
                            [holdingFiles]="fund.attachments">
      </terra-holding-images>
    </div>

    <!-- Asset details box -->
    <div class="terra-card holding-details">
      <div class="card-header">
        <h2>Fund info</h2>
      </div>
      <ul class="basic-info-list">
        <!-- Investment strategy -->
        <li class="basic-info-item" *ngIf="fund.investmentStrategyType">
          <mat-icon class="notranslate" svgIcon="pie_chart_quarter"></mat-icon>
          <label>Investment strategy:</label>
          <span class="value">{{ fund.investmentStrategyType | enumString: InvestmentStrategyType }}</span>
        </li>

        <!-- Total capitalization -->
        <li class="basic-info-item">
          <mat-icon class="notranslate dollar-sign">attach_money</mat-icon>
          <label>
            Total capitalization:
          </label>
          <span class="value">{{ fund.totalCapitalization | terraCurrency }}
            {{ fund.initialCurrency.iso }}
          </span>
        </li>
      </ul>
    </div>
  </section>

  <mat-card class="container tabs-card">

    <nav class="tabs-navigation" mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false" mat-align-tabs="start">
      <!-- Overview tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Overview].url" routerLinkActive #rla1="routerLinkActive"
         [active]="rla1.isActive">
        {{tabsData[Tab.Overview].label}}
      </a>
      <!-- Reports tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Reports].url" routerLinkActive #rla2="routerLinkActive"
         [active]="rla2.isActive">
        {{tabsData[Tab.Reports].label}}
      </a>
      <!-- Documents tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Documents].url" routerLinkActive #rla3="routerLinkActive"
         [active]="rla3.isActive">
        {{tabsData[Tab.Documents].label}}
      </a>
      <!-- Fundraisings tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Fundraisings].url" routerLinkActive #rla4="routerLinkActive"
         [active]="rla4.isActive">
        {{tabsData[Tab.Fundraisings].label}}
      </a>
      <!-- Distributions tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Distributions].url" routerLinkActive #rla5="routerLinkActive"
         [active]="rla5.isActive">
        {{tabsData[Tab.Distributions].label}}
      </a>
    </nav>

    <!-- Tabs content  -->
      <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>

  </mat-card>
</ng-container>
