<div class="header">
    <button class="close-button" [mat-dialog-close]="undefined" type="button" tabindex="-1" mat-icon-button
        aria-label="Close">
        <mat-icon class="notranslate">clear</mat-icon>
    </button>
    <div class="title">
        <mat-icon class="material-symbols-outlined notranslate">account_balance</mat-icon>
        <p>Select Bank Account</p>
    </div>
</div>

<div class="content-wrapper">

    <form [formGroup]="frm" (submit)="select()">
        <div class="form-inner">
            <mat-form-field appearance="outline">
                <mat-label></mat-label>
                <mat-select formControlName="bankAccountId">
                    <mat-option *ngFor="let dataListItem of (dataList$ | async)" [value]="dataListItem">
                        {{dataListItem.accountNickname}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button type="submit" mat-flat-button color="primary" [disabled]="!frm.valid">
                {{buttonText | uppercase}}
            </button>
        </div>
    </form>

</div>