import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import memo from 'memo-decorator';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  merge,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
  switchMapTo,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { InvestorContactReqRes } from 'src/app/dashboard/contacts/models/investorContactReqRes.model';
import { GpHoldingService } from 'src/app/dashboard/shared/holding/gp-holding.service';
import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { StorageObjectType } from 'src/app/shared/enums/StorageFileType.enum';
import StorageObjectPermissionLevel from 'src/app/shared/enums/StorageObjectPermissionLevel.enum';
import { FilestackStatus } from 'src/app/shared/enums/filestack-status.enum';
import { StorageFolderTreeNode } from 'src/app/shared/models/StorageFolderTreeNode.model';
import {
  HoldingStorageObjectReqRes,
  StorageObjectReqRes,
} from 'src/app/shared/models/StorageObjectReqRes.model';
import { UtilsService } from '../../../../../services/utils.service';
import { BaseResponseDto } from '../../../../../shared/models/BaseResponseDto.model';
import { SetPermissionsDialogParams } from '../SetPermissionsDialogParams.model';
import { ShareDocumentsDialogParams } from '../ShareDocumensDialogParams.model';
import { DocumentsTabService } from '../documents-tab/documents-tab.service';
import { RenameStorageObjectDialogComponent } from '../rename-storage-object-dialog/rename-storage-object-dialog.component';
import { SetDocumentsPermissionsDialogComponent } from '../set-documents-permissions-dialog/set-documents-permissions-dialog.component';
import { ShareDocumentsDialogComponent } from '../share-documents-dialog/share-documents-dialog.component';
import { HoldingStorageObjectDataSource } from './HoldingStorageObjectDataSource';
import { InvestingEntityReqRes } from 'src/app/dashboard/models/InvestingEntityReqRes.model';

@Component({
  selector: 'terra-document-display-table',
  templateUrl: './document-display-table.component.html',
  styleUrls: ['./document-display-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentDisplayTableComponent
  extends OnDestroyMixin
  implements OnInit, AfterViewInit
{
  // enums
  StorageObjectType = StorageObjectType;
  FilestackStatus = FilestackStatus;
  Array = Array;

  storageAboutToBeFullPercents = 85;

  @Input() showMessageWhenListIsEmpty = true;
  @Output() deleteRow: EventEmitter<number[]> = new EventEmitter();

  // The docId / storageObjectId may be in the query string,
  // but most likely the user will have a direct link to the media-preview page, so this logic will not be used.
  private previewFileOnload$ = this.route.queryParams.pipe(
    take(1),
    map((params) => {
      return +params.soid || null;
    }),
    filter((storageObjectId) => storageObjectId !== null),
    switchMap((storageObjectId) =>
      this.storageObjectDataService.getFile(storageObjectId)
    ),
    tap((storageObject) => this.previewFile(storageObject))
  );

  private currentFolderIdQueryParam$ = this.route.queryParams.pipe(
    map((params) => {
      return +params.folderId || null;
    })
  );

  searchOptions$ = this.documentsTabService.searchOptions$;

  moveToMenuesItemIdsToHide$ = new BehaviorSubject<number[]>([]);
  moveToMenuesItemIdsToMove$ = new BehaviorSubject<number[]>([]);

  actionsMenuOpened$ = new Subject<void>();

  allFoldersTree$ = this.documentsTabService.allFoldersTree$;

  filteringText: string = '';

  public get selectedItems(): StorageObjectReqRes[] {
    return this.selection.selected;
  }

  /** Returns true if at least one file is selected (don't count folders) */
  public get isAnyFileSelected(): boolean {
    return this.selectedItems.some(
      (x) => x.storageObjectType === StorageObjectType.File
    );
  }

  /** We don't know what's inside a folder, so we allow the user to try to share it.
   *  If there is nothing / no file allowed to be shared in that folder, the user will be notified about this inside the share dialog
   */
  public get isAnyFileSelectedSharable(): boolean {
    return this.selectedItems.some(
      (x) =>
        x.storageObjectType === StorageObjectType.Directory ||
        x.filestackStatus === FilestackStatus.Ready
    );
  }

  public get selectedItemIds(): number[] {
    return this.selectedItems.map((x) => x.id);
  }

  @ViewChild('rowMenuTrigger')
  contextMenuTrigger: MatMenuTrigger;

  @ViewChild('topMenuTrigger')
  topMenuTrigger: MatMenuTrigger;

  // The context menu's trigger position is bound to this object
  contextMenuPosition = { x: '0px', y: '0px' };

  dataSource = new HoldingStorageObjectDataSource(
    this.storageObjectDataService,
    this.documentsTabService,
    this.gpHoldingService,
    this.utilsService
  );

  isLoading$ = merge(
    this.dataSource.isLoading$,
    this.documentsTabService.triggerLoading$
  );

  quota$ = this.documentsTabService.quotaDetails$;

  pageRowsCount$ = this.dataSource.pageRows$.pipe(
    map((rows) => (rows ? rows.length : 0)),
    shareReplay(1)
  );

  totalRowsCount$ = this.dataSource.totalRowsCount$;

  currentFolderId$ = this.documentsTabService.currentFolderId$;
  foldersPath$ = this.documentsTabService.foldersPath$;

  displayedFolderCount$ = this.dataSource.pageRows$.pipe(
    map((rows) => {
      return !rows
        ? 0
        : rows.filter(
            (r) => r.storageObjectType === StorageObjectType.Directory
          ).length;
    })
  );

  displayedFilesCount$ = this.dataSource.pageRows$.pipe(
    map((rows) => {
      return !rows
        ? 0
        : rows.filter((r) => r.storageObjectType === StorageObjectType.File)
            .length;
    })
  );

  selection = new SelectionModel<StorageObjectReqRes>(true, []);

  displayedColumns: string[] = [
    'select',
    'icon',
    'displayName',
    'sharedWith',
    'createTimestamp',
    'actions',
  ];

  allSelected$ = merge(this.selection.changed, this.pageRowsCount$).pipe(
    switchMapTo(this.pageRowsCount$),
    map((pageRowsCount) => pageRowsCount === this.selection.selected.length),
    shareReplay(1)
  );

  isSelectedItemsMovable$ = this.actionsMenuOpened$.pipe(
    switchMap(() => this.allFoldersTree$),
    map((allFoldersTree) => {
      // The selected items are movable as a whole if at least one UNSELECTED tree node is:
      // 1. Not one of the selected folders
      // 2. Not a child of any of the selected folders
      const unselectedTreeNodes = allFoldersTree.filter(
        (root) => !this.selectedItemIds.some((id) => id === root.id)
      );
      if (unselectedTreeNodes.length === 0) {
        return false;
      }
      for (const treeRoot of unselectedTreeNodes) {
        for (const itemId of this.selectedItemIds) {
          const isInsideFolderOrFolderItself = this.isItemInsideFolder(
            itemId,
            treeRoot
          );
          if (isInsideFolderOrFolderItself) {
            return false;
          }
        }
      }
      return true;
    }),
    shareReplay(1),
    catchError((error) => {
      return EMPTY;
    })
  );

  constructor(
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private gpHoldingService: GpHoldingService,
    private dialog: MatDialog,
    private storageObjectDataService: StorageObjectDataService,
    private documentsTabService: DocumentsTabService,
    private snackbarService: SnackbarService,
    private utilsService: UtilsService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToDataSourceEvents();

    this.dataSource.pageRows$
      .pipe(untilComponentDestroyed(this))
      .subscribe((_) => {
        this.clearSelection();
      });
  }

  ngAfterViewInit(): void {
    this.handleFolderNavigation();
    this.autoPreviewOnLoadOnce();
    this.handleClosingOptionsMenuWhenMovingFiles();
    this.handleMoveToFolderEvent();
  }

  subscribeToDataSourceEvents() {
    this.dataSource.foldersPath$
      .pipe(untilComponentDestroyed(this))
      .subscribe((fullPath) => this.foldersPath$.next(fullPath));
  }

  trackByFunction(index: number, item: StorageObjectReqRes) {
    return item.id;
  }

  sortData(sort: Sort) {
    if (!sort.active || !sort.direction) {
      return;
    }
    this.documentsTabService.sortFiles(sort.active, sort.direction);
  }

  rowCheckToggle(
    row: StorageObjectReqRes,
    unselectOthers = false,
    event = null
  ) {
    if (event && (event.ctrlKey || event.metaKey)) {
      unselectOthers = false;
    }

    const isRowChecked = this.isRowChecked(row);
    const currentNumberOfSelectedItems = this.selectedItems.length;

    if (unselectOthers) {
      this.clearSelection();
    }

    if (
      isRowChecked &&
      (currentNumberOfSelectedItems === 1 || !unselectOthers)
    ) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  isRowChecked(row: StorageObjectReqRes) {
    return this.selection.isSelected(row);
  }

  clearSearch(searchInput: HTMLInputElement = null) {
    if (searchInput) {
      searchInput.value = null;
    }
    this.documentsTabService.search$.next('');
  }

  filterChanged(value: string) {
    if (!value) {
      this.clearSearch();
      return;
    }

    this.documentsTabService.search$.next(value.trim());
  }

  onDeleteRow(id: number) {
    if (!this.deleteRow) {
      return;
    }
    this.deleteRow.emit([id]);
  }

  openMenu(
    event: MouseEvent,
    clickedStorageObject: HoldingStorageObjectReqRes
  ) {
    event.preventDefault();

    // if the clicked item was not one of the selected items, first select only this items
    if (!this.selectedItems.find((x) => x.id === clickedStorageObject.id)) {
      this.rowCheckToggle(clickedStorageObject, true);
    }

    // The menu trigger's position is bound to this object.
    // by setting it, we make sure the menu will open near the user's cursor.
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenuTrigger.menuData = { element: clickedStorageObject };

    this.contextMenuTrigger.openMenu();
  }

  openSideMenu(event: MouseEvent, element) {
    this.selection.clear();
    event.preventDefault();
    event.stopPropagation();
    this.selection.select(element);

    // The menu trigger's position is bound to this object.
    // by setting it, we make sure the menu will open near the user's cursor.

    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenuTrigger.menuData = { element };

    this.contextMenuTrigger.openMenu();
  }

  previewFile(storageObject: StorageObjectReqRes) {
    // preview is only allowed for 1 item that is a file (not a folder)
    if (!storageObject) {
      return;
    }
    if (storageObject.storageObjectType === StorageObjectType.Directory) {
      this.openFolder(storageObject.id);
    } else if (storageObject.filestackStatus === FilestackStatus.Ready) {
      this.documentsTabService.getFilestackPolicy().subscribe((policy) => {
        storageObject.filestackPolicy = policy.filestackPolicy;
        storageObject.filestackSignature = policy.filestackSignature;

        this.storageObjectDataService.previewFile(storageObject, 'dialog');
      });
    }
  }

  masterToggle() {
    this.allSelected$
      .pipe(
        take(1),
        switchMap((isAllSelected) => {
          if (isAllSelected) {
            this.selection.clear();
            return EMPTY;
          } else {
            return this.dataSource.pageRows$;
          }
        }),
        tap((rowsToSelect) => {
          this.selection.select(...rowsToSelect);
        }),
        take(1)
      )
      .subscribe();
  }

  openFolder(folderId: number = null) {
    this.currentFolderId$.next(folderId ? folderId : null);
  }

  rename(storageObject: StorageObjectReqRes) {
    if (!storageObject) {
      return;
    }

    const configParams = new MatDialogConfig<StorageObjectReqRes>();
    // data is the current file name:
    configParams.viewContainerRef = this.viewContainerRef;
    configParams.closeOnNavigation = true;
    configParams.data = storageObject;

    this.dialog
      .open(RenameStorageObjectDialogComponent, configParams)
      .afterClosed()
      .pipe(untilComponentDestroyed(this))
      .subscribe((newName) => {
        if (newName) {
          this.snackbarService.showGeneralMessage(
            storageObject.storageObjectType === StorageObjectType.File
              ? 'File renamed'
              : 'Folder renamed'
          );
        }
      });
  }

  delete() {
    if (this.selectedItems.length === 0) {
      return;
    }
    const ids = this.selectedItems.map((i) => i.id);
    this.deleteRow.emit(ids);
  }

  download(storageObject: StorageObjectReqRes) {
    if (
      !storageObject ||
      storageObject.storageObjectType === StorageObjectType.Directory ||
      storageObject.filestackStatus !== FilestackStatus.Ready
    ) {
      return;
    }
    this.storageObjectDataService
      .getDownloadToken(storageObject.id)
      .subscribe();
  }

  share(element?) {
    if (element) {
      this.rowCheckToggle(element, true);
    }
    if (!this.isAnyFileSelectedSharable) {
      return;
    }

    combineLatest([
      this.gpHoldingService.accessibleByInvestingEntities$,
      this.gpHoldingService.holding$,
    ])
      .pipe(
        untilComponentDestroyed(this),
        take(1),
        switchMap(([investments, holding]) => {
          const config = new MatDialogConfig<ShareDocumentsDialogParams>();
          config.viewContainerRef = this.viewContainerRef;
          config.closeOnNavigation = true;
          const data = new ShareDocumentsDialogParams();
          data.storageObjectsIds = this.selectedItems.map((so) => so.id);
          data.contacts = investments.map((investingEntity) => ({
            id: investingEntity.id,
            name: investingEntity?.name,
            nickname: investingEntity?.nickname,
            email: investingEntity?.contact?.email,
            type: investingEntity?.investingEntityType,
          }));
          data.isPrivateHolding = holding.isPrivate;
          data.HoldingDiscriminator =
            this.gpHoldingService.holdingDiscriminator;
          config.data = data;

          return this.dialog
            .open(ShareDocumentsDialogComponent, config)
            .afterClosed();
        })
      )
      .subscribe((isShared) => {
        if (isShared) {
          this.snackbarService.showGeneralMessage(
            'File(s) shared with investors'
          );
        }
      });
  }

  setPermissions() {
    if (!this.isAnyFileSelectedSharable) {
      return;
    }

    this.gpHoldingService.accessibleByInvestingEntities$
      .pipe(
        untilComponentDestroyed(this),
        take(1),
        switchMap((investments) => {
          const config = new MatDialogConfig<SetPermissionsDialogParams>();
          config.viewContainerRef = this.viewContainerRef;
          config.closeOnNavigation = true;
          config.autoFocus = false;
          config.disableClose = true;
          const data = new SetPermissionsDialogParams();
          data.storageObjectsIds = this.selectedItems.map((so) => so.id);
          data.contacts = investments.map((investingEntity) => ({
            id: investingEntity.id,
            name: investingEntity?.name,
            nickname: investingEntity?.nickname,
            email: investingEntity?.contact?.email,
            type: investingEntity?.investingEntityType,
          }));
          data.HoldingDiscriminator =
            this.gpHoldingService.holdingDiscriminator;
          config.data = data;

          return this.dialog
            .open(SetDocumentsPermissionsDialogComponent, config)
            .afterClosed();
        })
      )
      .subscribe((isShared) => {
        if (isShared) {
          this.snackbarService.showGeneralMessage(
            'Sharing permissions updated'
          );
        }
      });
  }

  getPermittedContacts(
    storageObject: StorageObjectReqRes
  ): Observable<InvestorContactReqRes[]> {
    return this.gpHoldingService.accessibleByInvestingEntities$.pipe(
      map((investingEntities) => {
        const res = [];
        for (const investingEntity of investingEntities) {
          if (
            storageObject.storageObjectPermissions.find(
              (p) =>
                p.permissionLevel !== StorageObjectPermissionLevel.None &&
                p.investingEntityId === investingEntity.id
            )
          ) {
            res.push(investingEntity);
          }
        }
        return res?.length > 0 ? res : null;
      })
    );
  }

  @memo()
  getContactNamesString(contacts: InvestorContactReqRes[]) {
    return contacts
      .map((c) => `${TerraUtils.getContactFullName(c)}`)
      .join(', ');
  }

  @memo()
  getInvestingEntityNamesString(contacts: InvestingEntityReqRes[]) {
    return contacts.map(({ name }) => name).join(', ');
  }

  clearSelection() {
    this.selection.clear();
  }

  private handleMoveToFolderEvent() {
    // Handle move files to folders event emitted
    this.documentsTabService.moveToFolderEvent$
      .pipe(
        untilComponentDestroyed(this),
        switchMap((params) =>
          this.documentsTabService.moveToFolder(
            params.objectIdsToMove,
            params.destinationFolderId
          )
        )
      )
      .subscribe(
        () => {
          this.snackbarService.showGeneralMessage(
            `File(s) moved to the selected folder`,
            3
          );
        },
        (error) => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          }
        }
      );
  }

  // Handle clicking a folder in the move - to menu, which has sub - folder, and doesn't cause the menu to close
  private handleClosingOptionsMenuWhenMovingFiles() {
    this.documentsTabService.moveToFolderEvent$
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        if (this.contextMenuTrigger.menuOpen) {
          this.contextMenuTrigger.closeMenu();
        } else if (this.topMenuTrigger.menuOpen) {
          this.topMenuTrigger.closeMenu();
        }
      });
  }

  private handleFolderNavigation() {
    // trigger navigation when query param folderId changes
    this.currentFolderIdQueryParam$
      .pipe(
        untilComponentDestroyed(this),
        withLatestFrom(this.currentFolderId$)
      )
      .subscribe(([queryFolderId, currentFolderId]) => {
        if (queryFolderId !== currentFolderId) {
          this.openFolder(queryFolderId);
        }
      });
  }

  private autoPreviewOnLoadOnce() {
    this.previewFileOnload$.pipe(untilComponentDestroyed(this)).subscribe();
  }

  private isItemInsideFolder(itemId: number, folder: StorageFolderTreeNode) {
    // Notice: recursion ahead:
    if (!folder.children || folder.children.length === 0) {
      return false;
    }
    return folder.children.some((childFolder) =>
      this.isItemInsideFolder(itemId, childFolder)
    );
  }
}
