import MetaFileLink from 'src/app/models/metaFileLink.model';
import ReportFileType from '../enums/ReportFileType.enum';

export class ReportFileReqRes {
  id: number;
  metaFileLinkId: number;
  metaFileLink: MetaFileLink;
  fileType: ReportFileType;
  url: string;
}
