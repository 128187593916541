import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'terra-capital-call-box-title',
  templateUrl: './capital-call-box-title.component.html',
  styleUrls: ['./capital-call-box-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapitalCallBoxTitleComponent implements OnInit {

  @Input() title = '';
  @Input() subTitle1 = '';
  @Input() subTitle2 = '';

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
