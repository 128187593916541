import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { EMPTY, Observable, merge, of } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, switchMapTo, take, tap } from 'rxjs/operators';

import { InvestmentReqRes } from 'src/app/dashboard/models/investment.model';
import { PermissionService } from 'src/app/permission/permission.service';
import { DialogService } from 'src/app/services/dialog.service';
import { AccreditationStatus } from 'src/app/shared/enums/AccreditationStatus.enum';
import { InvestmentStatus } from 'src/app/shared/enums/InvestmentStatus.enum';
import { TerraNumberPipe } from 'src/app/shared/pipes/TerraNumber.pipe';
import { SnackbarService } from '../../../../../services/snackbar.service';
import { UtilsService } from '../../../../../services/utils.service';
import { getArrayIncludeAllById } from '../../../../../shared/TerraUtils';
import { HoldingFileType } from '../../../../../shared/enums/HoldingFileType.enum';
import { BaseResponseDto } from '../../../../../shared/models/BaseResponseDto.model';
import {
  InvestorContactDetailService
} from '../../../../contacts/components/investor-contact-dialog/investor-contact-detail.service';
import { CapitalCallService } from '../capital-call.service';
import { TrackStatus } from '../models/TrackStatus.enum';
import { UploadFilaDialogData, UploadFileDialogComponent } from '../upload-file-dialog/upload-file-dialog.component';
import { CapitalCallInvestmentsDataSource } from './CapitalCallInvestmentsDataSource';
import { CapitalCallInvestmentsTableService } from './capital-call-investments-table.service';
import { CustomErrorStateMatcher } from './capital-call-investments-table.validators';


@Component({
  selector: 'terra-capital-call-investments-table',
  templateUrl: './capital-call-investments-table.component.html',
  styleUrls: ['./capital-call-investments-table.component.scss'],
  providers: [InvestorContactDetailService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapitalCallInvestmentsTableComponent extends OnDestroyMixin implements OnInit {
  allowInvestorName$ = this.permissionService.allowInvestorName$;

  customErrorStateMatcher = new CustomErrorStateMatcher();
  readonly itemsPerPageOptions = [5, 25, 50, 100, 200, 500];
  displayedColumns = ['select', 'investor', 'dueDate', 'amount', 'transferDate', 'receivedDate', 'trackStatus', 'actions'];
  readonly today = new Date();
  perPageControl = new UntypedFormControl(this.itemsPerPageOptions[0]);
  dataSource = new CapitalCallInvestmentsDataSource(this.capitalCallInvestmentsTableService, this.capitalCallService, this.fb, this.terraNumberPipe, this.dialogService, this.permissionService);
  selection = new SelectionModel<UntypedFormGroup>(true, []);

  searchOptions$ = this.capitalCallInvestmentsTableService.searchOptions$;
  pageRowsCount$ = this.dataSource.pageRows$.pipe(map(rows => rows ? rows.length : 0), shareReplay(1));
  totalRowCount$ = this.dataSource.totalRowsCount$;
  isLoading$ = this.dataSource.isLoading$;
  isAllSelected$ = merge(this.dataSource.pageRows$, this.selection.changed).pipe(
    switchMapTo(this.dataSource.pageRows$),
    map(rows => {
      const availableRowsToSelect = rows.filter(row => this.isAllowedToIssueCapitalCall(row)).map(x => x.value);
      const selectedRows = this.selection.selected.map(x => x.value);
      return getArrayIncludeAllById(availableRowsToSelect, selectedRows);
    })
  );
  capitalCallCurrency$ = this.capitalCallService.fundraising$.pipe(untilComponentDestroyed(this), map(fundraising => fundraising.fundraisingTargetCurrency));

  fundraisingBankAccountName$ = this.capitalCallService.fundraising$.pipe(map(fundraising => {
    let bankAccountName = '';
    if (!!fundraising.clientBankAccount) {
      bankAccountName = fundraising.clientBankAccount.nickname;
    } else if (!!fundraising.unitBankAccount) {
      bankAccountName = fundraising.unitBankAccount.name;
    }
    return bankAccountName;
  }));
  // enums
  InvestmentStatus = InvestmentStatus;
  TrackedStatus = TrackStatus;

  get selectedInvestments() {
    if (!this.selection) {
      return [];
    }
    return this.selection.selected;
  }

  clearSelectedInvestments(){
    this.selection.clear();
  }

  constructor(private capitalCallInvestmentsTableService: CapitalCallInvestmentsTableService,
              private capitalCallService: CapitalCallService,
              private dialogService: DialogService,
              private fb: UntypedFormBuilder,
              private snackbarService: SnackbarService,
              private terraNumberPipe: TerraNumberPipe,
              private matDialog: MatDialog,
              private permissionService: PermissionService,
              private utilsService: UtilsService) {
    super();
  }

  ngOnInit(): void {
    this.capitalCallInvestmentsTableService.updatePageSize(this.itemsPerPageOptions[0]);
    this.perPageControl.valueChanges.pipe(
      untilComponentDestroyed(this),
      debounceTime(200)
    ).subscribe(pageSize => {
      this.capitalCallInvestmentsTableService.updatePageSize(pageSize);
    });
  }

  public masterToggle() {
    this.isAllSelected$.pipe(
      take(1),
      switchMap(isAllSelected => {
        if (isAllSelected) {
          this.selection.clear();
          return EMPTY;
        } else {
          return this.dataSource.pageRows$.pipe(
            map(rows => rows.filter(row => this.isAllowedToIssueCapitalCall(row)))
          );
        }
      }),
      tap(rowsToSelect => {
        this.selection.select(...rowsToSelect);
      }),
      take(1)
    ).subscribe();
  }

  public rowCheckToggle(row: UntypedFormGroup) {
    const isRowChecked = this.isRowChecked(row);

    if (isRowChecked) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  public trackByFn(index: number, investmentFormGroup: UntypedFormGroup) {
    return investmentFormGroup.value.id;
  }

  public pageChange(event: PageEvent) {
    if (event.pageIndex !== event.previousPageIndex){
    this.capitalCallInvestmentsTableService.updatePageNumber(event.pageIndex);
    }
    else{
      this.capitalCallInvestmentsTableService.updatePageSize(event.pageSize);
    }

  }

  public isRowChecked(row: UntypedFormGroup) {
    return this.selection.selected.some(investment => investment.value.id === row.value.id);
  }

  public sort(sort: Sort) {
    if (!sort.active || !sort.direction) {
      return;
    }

    this.capitalCallInvestmentsTableService.sort(sort.active, sort.direction);
  }

  /**
   * Open dialog to file upload and save file after closing dialog.
   * @param investmentFormGroup Investments form group
   */
  public uploadFile(investmentFormGroup: UntypedFormGroup){
    const documentsControl = (investmentFormGroup.get('documents') as UntypedFormArray);
    this.capitalCallService.holding$.pipe(
      take(1),
      switchMap(holding => {
        const config = new MatDialogConfig<UploadFilaDialogData>();
        config.disableClose = true;
        config.autoFocus = false;
        config.panelClass = 'upload-file-dialog';
        config.data = {
          title: 'Attach documents to the payment request',
          savedDocuments: []
        };
        if (investmentFormGroup.get('correspondingInvestment').value.agreementMetaFileLink) {
          config.data.savedDocuments.push(investmentFormGroup.get('correspondingInvestment').value.agreementMetaFileLink);
        }
        const paymentRequestDocuments = holding.attachments.filter(attachment => attachment.fileType === HoldingFileType.PaymentRequestDocument);
        if (paymentRequestDocuments.length > 0) {
          config.data.savedDocuments.push(...paymentRequestDocuments.map(doc => doc.metaFileLink));
        }
        if (documentsControl.value?.length > 0) {
          config.data.savedDocuments.push(...documentsControl.value);
        }

        return this.matDialog.open(UploadFileDialogComponent, config).afterClosed();
      })
    ).subscribe(metaFileLinks => {
      if (metaFileLinks?.length > 0) {
        metaFileLinks.forEach(m => documentsControl.push(this.fb.group(m)));
      }
    });
  }

  /**
   * Checks if need to show CC view date
   * @param investmentFormGroup Table row
   */
  public showViewedDate(investmentFormGroup: UntypedFormGroup): boolean {
    const trackStatus = investmentFormGroup.get('trackStatus').value;
    return trackStatus === TrackStatus.Viewed;
  }

  /**
   * Get the name of investor formatted for display
   * @param investmentFormGroup Investment form group
   */
  public getFormattedInvestorName(investmentFormGroup: UntypedFormGroup): Observable<string> {
    if (!investmentFormGroup) {
      return of('');
    }

    const investment = investmentFormGroup.get('correspondingInvestment').value;
    return this.capitalCallService.getFormattedInvestorName(investment);
  }

  /**
   * Checks if a row is disabled
   * @param investmentFormGroup Table row
   */
  public isAllowedToIssueCapitalCall(investmentFormGroup: UntypedFormGroup): boolean {
    const investment = this.getInvestmentFromFormGroup(investmentFormGroup);
    return investment.status === InvestmentStatus.Signed && !investment.isOrderCreated && (!investment.paymentRequestSendDate || !investment.transferDate);
  }

  getInvestmentFromFormGroup(fg: UntypedFormGroup): InvestmentReqRes {
    return fg.get('correspondingInvestment').value as InvestmentReqRes;
  }

  public isSaving(investmentFormGroup: UntypedFormGroup): Observable<boolean> {
    return investmentFormGroup.value.isSaving$;
  }

  public displayAttachFilesIcon(investmentFormGroup: UntypedFormGroup): boolean {
    const investment = this.getInvestmentFromFormGroup(investmentFormGroup);
    return investment.status === InvestmentStatus.Signed && !investment.paymentRequestSendDate;
  }

  public displayVisibilityIcon(investmentFormGroup: UntypedFormGroup): boolean {
    const investment = this.getInvestmentFromFormGroup(investmentFormGroup);
    return investment.status === InvestmentStatus.Invested || !!investment.paymentRequestSendDate;
  }

  isAccredited(investmentFormGroup: UntypedFormGroup):boolean{
    const investment = this.getInvestmentFromFormGroup(investmentFormGroup);
    return investment.investingEntity.accreditation?.status === AccreditationStatus.Accredited;
  }

  deleteInvestment(investmentForm: UntypedFormGroup){
    investmentForm.value.isSaving$.next(true);
    this.capitalCallService.deleteInvestment(investmentForm.get('id').value)
    .subscribe(
      deleted => {
        investmentForm.value.isSaving$.next(false);
        deleted ? this.snackbarService.showGeneralMessage(`Investor removed`)
        : this.snackbarService.showGeneralMessage(`Couldn't remove the investor`);
      },
      err => {
        if (err instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(err);
        } else {
          this.snackbarService.showGeneralMessage(`Couldn't remove the investor`);
        }
        investmentForm.value.isSaving$.next(false);
      }
    );
  }
}
