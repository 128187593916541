export enum DistributionPeriod {
  Quarterly = 1,
  // Yearly = 2,
  Custom = 3
}

// tslint:disable-next-line:no-namespace
export namespace DistributionPeriod {
  export function toString(distributionPeriod: DistributionPeriod): string {
    switch (distributionPeriod) {
      case DistributionPeriod.Quarterly:
        return 'Quarterly';
      // case DistributionPeriod.Yearly:
      //   return 'Yearly';
      case DistributionPeriod.Custom:
        return 'Custom';
      default:
        return null;
    }
  }

  export function listAll() {
    return [DistributionPeriod.Quarterly, /*ReportPeriod.Yearly, */ DistributionPeriod.Custom];
  }

  export function parse(distributionPeriodStr: string): DistributionPeriod {
    return DistributionPeriod[distributionPeriodStr];
  }
}

export default DistributionPeriod;
