import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EditBankAccountComponent } from 'src/app/dashboard/bankAccounts/components/edit-bank-account/edit-bank-account.component';
import { EditBankAccountContext } from 'src/app/dashboard/bankAccounts/components/edit-bank-account/EditBankAccountContext.model';
import { ClientBankAccountReqRes } from 'src/app/dashboard/models/bankAccount.model';
import { InvestmentReqRes } from 'src/app/dashboard/models/investment.model';
import { DialogService } from 'src/app/services/dialog.service';
import { ResourceService } from 'src/app/services/resource.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UtilsService } from 'src/app/services/utils.service';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import PaymentPlatform from 'src/app/shared/enums/PaymentPlatform.enum';
import PaymentStatus from 'src/app/shared/enums/PaymentStatus.enum';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { FundraisingReqRes } from '../../fundraising/fundraisings-tab/FundraisingReqRes.model';
import { HoldingFundraisingService } from '../../fundraising/holding-fundraising.service';
import { HoldingInvestorService } from '../../holding-investor.service';
import { HoldingFileReqRes } from '../../HoldingFileReqRes.model';
import { MarkAsInvestedConfirmDialogParams } from '../../investor/MarkAsInvestedConfirmDialogParams';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {NoPermissionAction} from '../../../../../shared/directives/check-permissions.directive';
import {FinancialPermissionLevel} from '../../../../../permission/enums/financialPermissionLevel.enum';

@Component({
  selector: 'terra-holding-send-payment-request-block',
  templateUrl: './holding-send-payment-request-block.component.html',
  styleUrls: ['./holding-send-payment-request-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoldingSendPaymentRequestBlockComponent implements OnInit {
  @Input()
  investment: InvestmentReqRes;

  @Input()
  fundraisingDetails: FundraisingReqRes;

  @Input()
  holdingAttachments: HoldingFileReqRes[] = [];

  @Input() isHoldingPrivate: boolean;

  @Input()
  discriminator:HoldingDiscriminator;

  @Output()
  issueCapitalCall: EventEmitter<void> = new EventEmitter<void>();

  PaymentStatus = PaymentStatus;
  PaymentPlatform = PaymentPlatform;
  NoPermissionAction = NoPermissionAction;
  FinancialPermissionLevel = FinancialPermissionLevel;

  dateFormat = TerraUtils.consts.LONG_DATE_AND_TIME;

  isResending = false;

  get bankHolidays() {
    return this.resourceService.generateHolidayCountriesString(this.investment.bankHolidays);
  }

  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private holdingInvestorService: HoldingInvestorService,
    private holdingFundraisingService: HoldingFundraisingService,
    private snackbarService: SnackbarService,
    private resourceService: ResourceService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
  }

  markAsInvested() {
    this.dialogService
      .confirmDialog(new MarkAsInvestedConfirmDialogParams())
      .afterClosed()
      .subscribe(isConfirmed => {
        if (!isConfirmed) {
          return;
        }
        this.holdingInvestorService.updateStatus(InvestmentStatus.Invested)
          .subscribe(
            (updatedInvestment: InvestmentReqRes) => {
            },
            error => {
              if (error instanceof BaseResponseDto) {
                this.utilsService.alertErrorMessage(error);
              }
              console.log('Error. Status was not updated.', error);
            }
          );
      });
  }

  showEditBankAccount() {
    const dialogConfig = new MatDialogConfig<EditBankAccountContext>();
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'create-bank-account-dialog';
    dialogConfig.data = {id: this.fundraisingDetails.clientBankAccount.id} as EditBankAccountContext;

    this.dialog
      .open(EditBankAccountComponent, dialogConfig)
      .afterClosed()
      .subscribe((bankAccountDetails: ClientBankAccountReqRes) => {
        if (bankAccountDetails) {
          this.fundraisingDetails.clientBankAccount = bankAccountDetails;
          this.snackbarService.showGeneralMessage('Bank account updated');
        }
      });
  }

  get disableIssueCapitalCall$(): Observable<boolean> {
    return this.holdingFundraisingService.allowedToIssueCapitalCall(this.investment.id).pipe(
      map(allowed => !allowed || this.isResending)
    );
  }

  holdingDownInfo() {
    this.utilsService.holdingDownInfo(this.discriminator);
  }

  getHoldingBankAccount(fundraising: FundraisingReqRes) {
    return this.holdingFundraisingService.getHoldingBankAccount(fundraising);
  }

}
