import MetaFileLink from "src/app/models/metaFileLink.model";

export class OpportunityDto {
  public id: number;
  public description: string;
  public viewCounter: string;
  public video: string;
  public attachments: MetaFileLink[];
  public documentZoomValue: number;
  public defaultOfferingDeckFundraisingId: number;
}
