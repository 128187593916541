<ng-container *ngIf="{
  investorsOverviewModel: investorsOverviewModel$ | async,
  currency: currency$ | async,
  fund: fund$ | async,
  isLoading: isLoading$ | async
 } as data">

  <terra-loader class="page-loader" *ngIf="data.isLoading"></terra-loader>

  <section class="empty-state"
           *ngIf="!data.isLoading && data.fund.status == HoldingStatus.Draft && (!data.investorsOverviewModel || data.investorsOverviewModel.length == 0)">
    <img src="assets/images/general/empty_state.svg"/>
    <h2>No contributions yet</h2>
    <a [routerLink]="importContributionRoute$ | async"
       class="import-contribution-button-empty" type="button" mat-flat-button color="primary">
      <mat-icon class="notranslate material-icons-outlined">cloud_upload</mat-icon>
      Import Contribution
    </a>
  </section>

  <section class="overview-section" *ngIf="!data.isLoading && data.investorsOverviewModel && data.investorsOverviewModel.length > 0">

    <p-table #pTable [value]="data.investorsOverviewModel" [columns]="cols$ | async" [showCurrentPageReport]="true" [alwaysShowPaginator]="true"
             [showFirstLastIcon]="false" [tableStyle]="{ 'min-width': '50rem' }" [styleClass]="'p-datatable-table'" [rowHover]="true"
             [globalFilterFields]="['name']" responsiveLayout="scroll" *ngIf="data.investorsOverviewModel"
             [scrollable]="true" scrollHeight="flex" (sortFunction)="tableSort($event)" [customSort]="true"
             [totalRecords]="data.investorsOverviewModel.length" [rowsPerPageOptions]="[10, 25, 50, 100]"
             currentPageReportTemplate="{first} - {last} of {totalRecords}">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          <div class="flex">
            <terra-search-field class="search-field" placeholder="Search investors" (filterChange)="filterChanged($event, 'contains')"
                                (clearSearch)="clearSearch()" [(filter)]="filteringText">
            </terra-search-field>
          </div>
          <!--          <terra-buttons [requireContactsVisibility]="true" [popupOnPermission]="true" type="secondary"-->
          <!--                         label="New Email" icon="mail_outline" (onClick)="onRowSelect"></terra-buttons>-->
          <div class="flex header-actions">
            <terra-buttons class="action-button" type="button" mat-stroked-button type="secondary" *ngIf="commitmentsEnabled$ | async"
                           terraCheckPermissions [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.PopupOnClick"
                           (onClick)="sendManageCommitmentTelemetry(false)"
                           [routerLink]="investorsManagementRoute$ | async" label="Manage Commitments">
            </terra-buttons>
            <terra-buttons class="action-button" type="button" mat-stroked-button type="secondary"
                           (onClick)="openOwnershipTransferDialog()" label="Transfer Ownership" icon="cached">
            </terra-buttons>
            <!--            <button class="action-button" type="button" mat-stroked-button color="primary"-->
            <!--                    (click)="openOwnershipTransferDialog()">-->
            <!--              <mat-icon class="notranslate">compare_arrows</mat-icon>-->
            <!--              Transfer Ownership-->
            <!--            </button>-->
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" #headerTemplate>
        <tr class="text-xs">
          <th class="w-1rem"></th>
          <th [pSortableColumn]="col.name" [pSortableColumnDisabled]="!col.shouldSort" *ngFor="let col of cols$ | async">
            {{ col.displayName }}
            <mat-icon *ngIf="col.name === 'ownership'"
                      class="table-tooltip-icon" hideDelay="2000" tooltipPosition="bottom" tooltipStyleClass="text-center wider" [pTooltip]="overviewTooltip$ | async">
              help_outline
            </mat-icon>
            <mat-icon *ngIf="col.displayName === AdjustedXirrColName"
                      class="table-tooltip-icon" hideDelay="2000" tooltipPosition="bottom" tooltipStyleClass="wider" [pTooltip]="adjustedXirrTooltip">
              help_outline
            </mat-icon>
            <p-sortIcon *ngIf="col.shouldSort" [field]="col.name"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-columns="columns" let-rowData>
        <tr>
          <td>
            <span class="icon-container">
              <terra-name-icon [svgIcon]="InvestingEntityType.getIcon(rowData['type'])"></terra-name-icon>
            </span>
          </td>
          <td *ngFor="let col of columns">
            <ng-container *ngIf="(col.name === 'totalCommitments' && !!!rowData[col.name]); else valueCols">
              <terra-buttons class="action-button" type="button" mat-stroked-button type="secondary" (onClick)="sendManageCommitmentTelemetry(true)"
                             terraCheckPermissions [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.PopupOnClick"
                             [routerLink]="investorsManagementRoute$ | async" [icon]="'add'" label="Commitment">
              </terra-buttons>
            </ng-container>
            <ng-template #valueCols>
              <i *ngIf="showCommitmentWarning(rowData, col.name)">
                <mat-icon class="notranslate aterial-icons-outlined warning-icon"
                          pTooltip="Commitment amount is lower than the contribution"
                          tooltipStyleClass="text-center wide-15">error
                </mat-icon>
              </i>
              <div [ngSwitch]="col.type" [ngClass]="col.getClass ? col.getClass() : {class : false}">
                <ng-container *ngSwitchCase="'currency'">
                  <ng-container *ngIf="!!rowData[col.name]; else noValue">{{ data?.currency?.symbol }}{{ rowData[col.name] | terraCurrency }}</ng-container>
                  <ng-template #noValue>-</ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'currency-always'">
                  <ng-container>{{ data?.currency?.symbol }}{{ rowData[col.name] | terraCurrency }}</ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'percent'">
                  <ng-container *ngIf="!!rowData[col.name]; else noValue">{{ rowData[col.name] }}%
                  </ng-container>
                  <ng-template #noValue>-</ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'percentIrr'">
                  <ng-container *ngIf="!!rowData[col.name]; else adjustedXirrPercentage">{{ rowData[col.name] | number: '1.0-2' }}%
                  </ng-container>
                  <ng-template #adjustedXirrPercentage>
                    <ng-container *ngIf="!!rowData['adjustedXirrPercentage']; else noValue">{{ rowData['adjustedXirrPercentage'] | number: '1.0-2' }}%
                    </ng-container>
                  </ng-template>
                  <ng-template #noValue>-</ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'contribution'">
                  <div class="vertical-cell">
                    <ng-container *ngIf="!!rowData[col.name]; else noValue">{{ data?.currency?.symbol }}{{ rowData[col.name] | terraCurrency }}</ng-container>
                    <ng-template #noValue>-</ng-template>
                    <span class="pending-contribution"
                          *ngIf="!!rowData.totalPendingContributions">Pending: {{ data?.currency?.symbol }}{{ rowData.totalPendingContributions | terraCurrency }}</span>
                  </div>
                </ng-container>


                <!-- {{ getInvestorEquityPercentage(rowData.totalContributions, (totalInvestorEquity$ | async)) | terraNumber }}%
                </ng-container> -->
                <ng-container *ngSwitchDefault>
                  {{ rowData[col.name] }}
                  <!-- {{ col.type !== 'Number' ? rowData[col.name] : (rowData[col.name] | terraNumber) }} -->
                </ng-container>
              </div>
            </ng-template>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr class="overview-footer">
          <td>Total</td>
          <td></td>
          <td>{{ !!((totalAmounts$ | async)?.ownershipPercent) ? (totalAmounts$ | async)?.ownershipPercent + '%' : '-' }}</td>
          <!--          <td>{{ !!((totalAmounts$ | async)?.cashOnCashRatePercentage) ? (totalAmounts$ | async)?.cashOnCashRatePercentage + '%' : '' }}</td>-->
          <!--          <td>{{ !!((totalAmounts$ | async)?.xirrPercentage) ? (totalAmounts$ | async)?.xirrPercentage + '%' : '-' }}</td>-->
          <td></td>
          <td></td>
          <td *ngIf="showCommitmentsColumns$ | async">{{ data?.currency?.symbol }}{{ (totalAmounts$ | async)?.totalCommitmentAmount || 0 | terraCurrency }}</td>
          <td>{{ data?.currency?.symbol }}{{ (totalAmounts$ | async)?.totalContributionAmount || 0 | terraCurrency }}</td>
          <td
            *ngIf="showCommitmentsColumns$ | async">{{ data?.currency?.symbol }}{{ Math.abs((totalAmounts$ | async)?.totalCommitmentAmount - (totalAmounts$ | async)?.totalContributionAmount) || 0 | terraCurrency }}
          </td>
          <td>{{ (totalAmounts$ | async)?.totalDistributionAmount }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <ng-container *ngIf="data.investorsOverviewModel.length; else noData">
          <tr class="c-empty-state">
            <td class="text-center" colspan="9">
              <img src="/assets/images/plg/no-results-con.svg" alt="no data here yet"/>
              <p class="text-center font-medium text-lg main-hint">
                No matching results
              </p>
              <p class="text-center secondary-hint">
                Please adjust your search or filter and try again.
              </p>
            </td>
          </tr>
        </ng-container>
        <ng-template #noData>
          <tr class="c-empty-state">
            <td class="text-center" colspan="9">
              <img src="/assets/images/plg/empty-icon.svg" alt="no data here yet"/>
              <p class="text-center font-medium text-lg secondary-hint">
                Nothing here yet
              </p>
            </td>
          </tr>
        </ng-template>
      </ng-template>
    </p-table>


    <!--    <div class="header-actions" *ngIf="data.fund.status != HoldingStatus.Realized">-->
    <!--      <button class="transfer-ownership-button" type="button" mat-stroked-button color="primary" (click)="openOwnershipTransferDialog()">-->
    <!--        <mat-icon class="notranslate">compare_arrows</mat-icon>-->
    <!--        Transfer Ownership-->
    <!--      </button>-->
    <!--    </div>-->

    <!--    <table mat-table [dataSource]="data.investorsOverviewModel" class="cov-basic-table" matSort matSortDisableClear (matSortChange)="sortData($event)"-->
    <!--           [matSortDirection]="(searchOptions$ | async).sortOrder" [matSortActive]="(searchOptions$ | async).orderBy">-->

    <!--      &lt;!&ndash; Name Column &ndash;&gt;-->
    <!--      <ng-container matColumnDef="name">-->
    <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header="Name" [disabled]="!(allowInvestorName$ | async)">Name</th>-->
    <!--        <td mat-cell *matCellDef="let element" class="lo_sensitive">-->
    <!--          {{element.name}}-->
    <!--          <p *ngIf="element.ieName" class="ie-name">{{element.ieName}}</p>-->
    <!--        </td>-->
    <!--        <td mat-footer-cell *matFooterCellDef>Total</td>-->
    <!--      </ng-container>-->

    <!--      &lt;!&ndash; Ownership % &ndash;&gt;-->
    <!--      <ng-container matColumnDef="ownership">-->
    <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header="Ownership">Ownership (%)</th>-->
    <!--        <td mat-cell *matCellDef="let element">-->
    <!--          <ng-container *ngIf="element.totalContributions >= 0">-->
    <!--            {{ getInvestorEquityPercentage(element.totalContributions, (totalInvestorEquity$ | async)) | terraNumber }}%-->
    <!--          </ng-container>-->
    <!--        </td>-->
    <!--        <td mat-footer-cell *matFooterCellDef>-->
    <!--          <ng-container *ngIf="totalContributionsAmount$ | async as totalContributionsAmount">-->
    <!--            <ng-template #finishedAllFundraisings>-->
    <!--              {{ getInvestorEquityPercentage(totalContributionsAmount, totalInvestorEquity$ | async) | terraNumber }}%-->
    <!--            </ng-template>-->
    <!--            &lt;!&ndash; When less than 100% funded  &ndash;&gt;-->
    <!--            <ng-container-->
    <!--              *ngIf="(totalInvestorEquity$ | async) !== totalContributionsAmount else finishedAllFundraisings">-->
    <!--              {{ getInvestorEquityPercentage(totalContributionsAmount, totalInvestorEquity$ | async) | terraNumber }}%-->
    <!--              out of {{ data?.currency?.symbol }}{{totalInvestorEquity$ | async | terraCurrency}}-->
    <!--            </ng-container>-->
    <!--          </ng-container>-->
    <!--        </td>-->
    <!--      </ng-container>-->

    <!--      &lt;!&ndash;  Cash On Cash %  &ndash;&gt;-->
    <!--      <ng-container matColumnDef="cashOnCashRatePercentage">-->
    <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header="CashOnCashRatePercentage">Cash On Cash (%)</th>-->
    <!--        <td mat-cell *matCellDef="let element">-->
    <!--          <ng-container *ngIf="element.cashOnCashRatePercentage">-->
    <!--            {{element.cashOnCashRatePercentage | terraNumber}}%-->
    <!--          </ng-container>-->
    <!--        </td>-->
    <!--        <td mat-footer-cell *matFooterCellDef></td>-->
    <!--      </ng-container>-->

    <!--      &lt;!&ndash;  XIRR %  &ndash;&gt;-->
    <!--      <ng-container matColumnDef="xirrPercentage">-->
    <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header="XirrPercentage"-->
    <!--            matTooltipClass=team-tooltip matTooltipPosition="above" matTooltip="IRR according to the last distribution date">IRR (%)-->
    <!--        </th>-->
    <!--        <td mat-cell *matCellDef="let element">-->
    <!--          <ng-container>-->
    <!--            {{!element.xirrPercentage || element.xirrPercentage < 0 ? '&#45;&#45;' : element.xirrPercentage + '%'}}-->
    <!--          </ng-container>-->
    <!--        </td>-->
    <!--        <td mat-footer-cell *matFooterCellDef></td>-->
    <!--      </ng-container>-->

    <!--      &lt;!&ndash; Total Contributions column &ndash;&gt;-->
    <!--      <ng-container matColumnDef="totalContributions">-->
    <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header="TotalContributions">Total Contributions</th>-->
    <!--        <td mat-cell *matCellDef="let element">-->
    <!--          <ng-container *ngIf="element.totalContributions">-->
    <!--            {{ data?.currency?.symbol }}{{ element.totalContributions | terraCurrency }}-->
    <!--          </ng-container>-->
    <!--        </td>-->
    <!--        <td mat-footer-cell *matFooterCellDef>-->
    <!--          <ng-container *ngIf="totalContributionsAmount$ | async as totalContributionsAmount">-->
    <!--            {{ data?.currency?.symbol }}{{ totalContributionsAmount | terraCurrency }}-->
    <!--          </ng-container>-->
    <!--        </td>-->
    <!--      </ng-container>-->

    <!--      &lt;!&ndash; Total Distributions column &ndash;&gt;-->
    <!--      <ng-container matColumnDef="totalDistributions">-->
    <!--        <th mat-header-cell *matHeaderCellDef>Total Distributions</th>-->
    <!--        <td mat-cell *matCellDef="let element">-->
    <!--          &lt;!&ndash; Arrives stringified from the server (amount per currency), including the curency &ndash;&gt;-->
    <!--          {{ element.totalDistributions }}-->
    <!--        </td>-->
    <!--        <td mat-footer-cell *matFooterCellDef>-->
    <!--          <ng-container *ngIf="totalDistributionsAmount$ | async as totalDistributionsAmount">-->
    <!--            {{ totalDistributionsAmount }}-->
    <!--          </ng-container>-->
    <!--        </td>-->
    <!--      </ng-container>-->

    <!--      <ng-container matColumnDef="unallocatedName">-->
    <!--        <td mat-footer-cell *matFooterCellDef>Unallocated</td>-->
    <!--      </ng-container>-->
    <!--      <ng-container matColumnDef="unallocatedOwnership">-->
    <!--        <td mat-footer-cell *matFooterCellDef>-->
    <!--          <ng-container *ngIf="totalContributionsAmount$ | async as totalContributionsAmount">-->
    <!--            {{getUnallocatedPercentage(totalContributionsAmount, totalInvestorEquity$ | async) | terraNumber}}%-->
    <!--          </ng-container>-->
    <!--        </td>-->
    <!--      </ng-container>-->
    <!--      <ng-container matColumnDef="unallocatedOnCashRatePercentage">-->
    <!--        <td mat-footer-cell *matFooterCellDef></td>-->
    <!--      </ng-container>-->
    <!--      <ng-container matColumnDef="unallocatedXirrPercentage">-->
    <!--        <td mat-footer-cell *matFooterCellDef></td>-->
    <!--      </ng-container>-->
    <!--      <ng-container matColumnDef="unallocatedContributions">-->
    <!--        <td mat-footer-cell *matFooterCellDef>-->
    <!--          <ng-container *ngIf="totalContributionsAmount$ | async as totalContributionsAmount">-->
    <!--            {{data?.currency?.symbol}}{{getUnallocatedContributions(totalContributionsAmount, totalInvestorEquity$ | async) | terraNumber}}-->
    <!--          </ng-container>-->
    <!--        </td>-->
    <!--      </ng-container>-->
    <!--      <ng-container matColumnDef="unallocatedDistributions">-->
    <!--        <td mat-footer-cell *matFooterCellDef></td>-->
    <!--      </ng-container>-->

    <!--      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
    <!--      <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.gp-row]="row.isGP"></tr>-->
    <!--      &lt;!&ndash; Summary row &ndash;&gt;-->
    <!--      <ng-container *ngIf="(getUnallocatedPercentage(totalContributionsAmount$ | async, totalInvestorEquity$ | async) | terraNumber) != '0'">-->
    <!--        <tr mat-footer-row-->
    <!--            *matFooterRowDef="['unallocatedName', 'unallocatedOwnership', 'unallocatedOnCashRatePercentage','unallocatedXirrPercentage', 'unallocatedContributions', 'unallocatedDistributions']"></tr>-->
    <!--      </ng-container>-->
    <!--      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
    <!--    </table>-->
  </section>
</ng-container>



