import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of, BehaviorSubject } from 'rxjs';
import { shareReplay, map, switchMap, tap, take } from 'rxjs/operators';

import { GpFundService } from '../../../gp-fund.service';
import { FundReportReqRes } from 'src/app/shared/models/FundReportReqRes.model';
import { FundReportDataService } from 'src/app/services/shared/fund-report-data.service';
import { ReportFAQReqRes } from 'src/app/shared/models/reportFAQReqRes.model';
import { GpReportFaqDataService } from 'src/app/services/gp/gp-report-faq-data.service';

@Injectable()
export class FundReportFaqService {

  reportId$ = this.route.params.pipe(
    map(params => +params.reportId || -1),
    shareReplay()
  );

  report$: Observable<FundReportReqRes> = combineLatest(this.gpFundService.holdingId$, this.reportId$).pipe(
    switchMap(([fundId, reportId]) => (reportId > 0 ? this.fundReportDataService.getByIdForGp(fundId, reportId) : of(null))),
    shareReplay()
  );

  private refreshFaqs$ = new BehaviorSubject<void>(null);
  faqs$ = this.refreshFaqs$.pipe(switchMap(() => combineLatest(this.gpFundService.holdingId$, this.reportId$)),
    switchMap(([fundId, reportId]) => this.gpReportFaqDataService.getList(fundId, reportId)),
    map(res => res.rows),
    shareReplay()
  );

  private endpointPrefixIds$ = combineLatest(this.gpFundService.holdingId$, this.reportId$).pipe(
    shareReplay(),
    map(([fundId, reportId]) => ({ fundId, reportId })));


  constructor(
    private route: ActivatedRoute,
    private gpFundService: GpFundService,
    private fundReportDataService: FundReportDataService,
    private gpReportFaqDataService: GpReportFaqDataService) { }

  create(faq: ReportFAQReqRes) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(ids => {
      return this.gpReportFaqDataService.create(ids.fundId, ids.reportId, faq);
    }));
  }

  update(faq: ReportFAQReqRes) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(ids => {
      return this.gpReportFaqDataService.update(ids.fundId, ids.reportId, faq);
    }));
  }

  createAndUpdateBulk(faqs: ReportFAQReqRes[]) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(ids => {
        return this.gpReportFaqDataService.createAndUpdateBulk(ids.fundId, ids.reportId, faqs);
      }),
      tap(() => this.refreshFaqs$.next()));
  }

  delete(faqId: number) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(ids => {
        return this.gpReportFaqDataService.delete(ids.fundId, ids.reportId, faqId);
      }),
      tap(() => this.refreshFaqs$.next()));
  }
}
