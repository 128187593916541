import { NgForOf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    FormlyModule
  ],
  styles: [`
    :host {
      display: block;
      width: 100%;
      --mat-form-field-container-height: 40px;
      --mat-form-field-container-vertical-padding: 8px;
    }
    ::ng-deep .mat-mdc-option {
      --mat-minimal-pseudo-checkbox-selected-checkmark-color: #F79009;
    }
    ::ng-deep .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }
    `],
  template: `
  <div class="full-width flex flex-column mb-3">
    <label class="mb-1">{{ props.label }}</label>
    <div class="flex flex-row gap-1">
      <mat-form-field color="primary" appearance="outline" class="w-full">
        <mat-select [formControl]="formControl" [placeholder]="props.placeholder" [formlyAttributes]="field" [errorStateMatcher]="errorStateMatcher">
            @if (props.emptyOption){
              <mat-option />
            }
            <mat-option *ngFor="let opt of props.options" [value]="opt.value || opt.id">{{ opt.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  `,
})
export class SelectTypeComponent extends FieldType<FieldTypeConfig> { }
