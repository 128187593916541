<section class="container information" *ngIf="asset$ | async as asset">
  <!-- Images -->
  <div class="terra-card images-card">
    <terra-holding-images [singleLineAddress]="true" [location]="asset.location" [holdingFiles]="asset.attachments"/>
  </div>

  <!-- Asset details box -->
  <div class="terra-card asset-details">
    <div class="card-header">
      <h2>Asset info</h2>
    </div>
    <ul class="basic-info-list">
      <!-- Property type -->
      <li class="basic-info-item flex flex-row align-items-center gap-2">
        <mat-icon class="notranslate">home</mat-icon>
        <label>Property type:</label>
        <span class="value">{{ asset.holdingType | enumString: HoldingType }}</span>
      </li>
    </ul>
  </div>
</section>

<mat-card class="container tabs-card">
  <mat-tab-group color="primary" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Reports">
      <terra-asset-reports />
    </mat-tab>
  </mat-tab-group>
</mat-card>
