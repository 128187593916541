<div class="tab-label" (click)="onClick($event)" [ngClass]="{'disabled': disabled}">
  <div matRipple [matRippleDisabled]="disabled" class="tab-label-content">
    <div class="tab-status">
      <mat-icon *ngIf="completed" class="tab-status-icon">done</mat-icon>
      <ng-container *ngIf="!completed">
        {{step+1}}
      </ng-container>
    </div>
    <div class="tab-status-label">{{title}}</div>
  </div>
  <mat-icon class="tab-separator-icon">arrow_forward_ios</mat-icon>
</div>
