import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { shareReplay, map, tap, take, switchMap } from 'rxjs/operators';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';

import { StorageObjectReqRes } from 'src/app/shared/models/StorageObjectReqRes.model';
import { StorageObjectType } from 'src/app/shared/enums/StorageFileType.enum';
import { InvestorContactReqRes } from 'src/app/dashboard/contacts/models/investorContactReqRes.model';
import { StorageObjectShareReqRes } from '../StorageObjectShareReqRes.model';
import StorageObjectPermissionLevel from 'src/app/shared/enums/StorageObjectPermissionLevel.enum';
import { DocumentsTabService } from '../documents-tab/documents-tab.service';
import { IdValue } from 'src/app/shared/models/IdValue.model';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { FilestackStatus } from 'src/app/shared/enums/filestack-status.enum';
import { ShareDocumentsDialogParams } from '../ShareDocumensDialogParams.model';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import { UserService } from 'src/app/services/shared/user.service';
import { BaseResponseDto } from '../../../../../shared/models/BaseResponseDto.model';
import { UtilsService } from '../../../../../services/utils.service';
import { PermissionService } from 'src/app/permission/permission.service';
 

@Component({
  selector: 'terra-share-documents-dialog',
  templateUrl: './share-documents-dialog.component.html',
  styleUrls: ['./share-documents-dialog.component.scss'],
})
export class ShareDocumentsDialogComponent
  extends OnDestroyMixin
  implements OnInit
{
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  // enums
  StorageObjectType = StorageObjectType;
  holdingDiscriminatorLowerCase: string = HoldingDiscriminator.toString(
    this.context.HoldingDiscriminator
  ).toLowerCase();
  
  pageForm: UntypedFormGroup;
  isAttemptSubmit = false;
  isGeneralServerError = false;
  generalServerErrorMessage =
    TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;

  loadingFiles$ = new BehaviorSubject(true);
  processingSubmit$ = new BehaviorSubject(false);

  isLoading$ = combineLatest([this.loadingFiles$, this.processingSubmit$]).pipe(
    map(([loadingFiles, processingSubmit]) => loadingFiles || processingSubmit),
    shareReplay(1)
  );

  isDraftHolding$ = this.documentsTabService.isDraftHolding$;

  get contactsToShareWith() {
    return this.pageForm.get('contactsToShareWith').value;
  }

  get shareWithOption(): 0 | 1 | 2 {
    return this.pageForm.get('shareWidthOption').value;
  }

  private allSelectedFiles$ = this.storageObjectDataService
    .getRecursive(this.context.storageObjectsIds)
    .pipe(
      map((allStorageObjects) =>
        allStorageObjects.filter(
          (so) => so.storageObjectType === StorageObjectType.File
        )
      ),
      tap(() => this.loadingFiles$.next(false)),
      shareReplay(1)
    );

  // From the storage objects the user selected, the proccessing files were filtered ahead, and are in the context.excludedFiles property.
  filesReadyStatus$ = this.allSelectedFiles$.pipe(
    map((allFiles) =>
      allFiles.filter((so) => so.filestackStatus === FilestackStatus.Ready)
    ),
    shareReplay(1)
  );

  filesProcessingStatus$ = this.allSelectedFiles$.pipe(
    map((allFiles) =>
      allFiles.filter((so) => so.filestackStatus === FilestackStatus.Processing)
    ),
    shareReplay(1)
  );

  isAllSelectedFilesStillProcessing$ = combineLatest([
    this.allSelectedFiles$,
    this.filesProcessingStatus$,
  ]).pipe(
    map(([allSelectedFiles, processingFiles]) => {
      return (
        allSelectedFiles.length === processingFiles.length &&
        processingFiles.length > 0
      );
    }),
    shareReplay(1)
  );

  constructor(
    private permissionService: PermissionService,
    private documentsTabService: DocumentsTabService,
    private storageObjectDataService: StorageObjectDataService,
    public dialogRef: MatDialogRef<ShareDocumentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: ShareDocumentsDialogParams,
    private fb: UntypedFormBuilder,
    private utilsService: UtilsService
  ) {
    super();
    dialogRef.addPanelClass('share-documents-dialog-component');
  }

  ngOnInit() {
    this.pageForm = this.fb.group({
      shareWidthOption: [null, Validators.required],
      contactsToShareWith: [[]],
      message: '',
    });

    this.isDraftHolding$
      .pipe(take(1), untilComponentDestroyed(this))
      .subscribe((isDraft) => {
        if (isDraft) {
          this.pageForm.get('shareWidthOption').setValue(1);
          this.pageForm.get('shareWidthOption').updateValueAndValidity();
        }
      });

    // if selecting to select investors manually, the investors multi-selecte should have at least 1 investor selected
    this.pageForm
      .get('shareWidthOption')
      .valueChanges.subscribe((selectedOption) => {
        this.pageForm
          .get('contactsToShareWith')
          .setValidators(selectedOption === 2 ? [Validators.required] : []);
        this.pageForm.get('contactsToShareWith').updateValueAndValidity();
      });
    this;
  }

  save(withEmail: boolean) {
    this.isAttemptSubmit = true;
    this.isGeneralServerError = false;
    if (!this.pageForm.valid) {
      return;
    }

    this.processingSubmit$.next(true);
    this.filesReadyStatus$
      .pipe(
        take(1),
        map((storageObjectsToShare) =>
          this.generateSubmitModel(withEmail, storageObjectsToShare)
        ),
        switchMap((model) => this.documentsTabService.share(model))
      )
      .subscribe(
        () => {
          this.processingSubmit$.next(false);
          this.dialogRef.close(true);
        },
        (error) => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            this.isGeneralServerError = true;
            console.log(error);
          }
          this.processingSubmit$.next(false);
        }
      );
  }

  private generateSubmitModel(
    sendEmails: boolean,
    storageObjectsToShare: StorageObjectReqRes[]
  ): StorageObjectShareReqRes {
    const model = new StorageObjectShareReqRes();
    // All files added to be shared
    model.storageObjectIds = storageObjectsToShare.map((s) => s.id);
    model.personalMessage = this.pageForm.value.message || null;
    model.sendShareEmails = sendEmails;

    switch (this.shareWithOption) {
      case 1:
        // all contacts will have view permission.
        // If new investors will later be added to the holding, they will automatically be able to view the documents.
        model.isPublic = true;
        break;
      case 2:
        // Specific investors
        model.isPublic = false;
        // Generate a list of all selected contact to share with, with a View permission for each of them
        model.contactPermissions = this.contactsToShareWith.map(
          (c) =>
            new IdValue<StorageObjectPermissionLevel>(
              c.id,
              StorageObjectPermissionLevel.View
            )
        );
        break;
    }
    return model;
  }
}
