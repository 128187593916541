import { NgModule } from "@angular/core"
import { FormlyModule } from "@ngx-formly/core"
import { FormlyMaterialModule } from "@ngx-formly/material"
import { ObjectTypeComponent } from "../object.type"
import { AdvancedWrapperComponent } from "./advanced-wrapp.component"
import { AutocompleteTypeComponent } from "./autocomplete-type.component"
import { CheckBoxTypeComponent } from "./check-box-type.component"
import { DateSelectTypeComponent } from "./date-select/date-type.component"
import { DateRangeSelectTypeComponent } from "./date-select/period-type.component"
import { PanelWrapperComponent } from "./field-wrapp.component"
import { PropertiesSelectTypeComponent } from "./properties-select-type.component"
import { RadioTypeComponent } from "./radio-type.component"
import { SelectTypeComponent } from "./select-type.component"

const defaultConfig = {
    validationMessages: [
      { name: 'required', message: 'This field is required' },
      { name: 'uniqueItems', message: 'should NOT have duplicate items' },
      { name: 'enum', message: `must be equal to one of the allowed values` },
    ],
    types: [
      { name: 'object', component: ObjectTypeComponent },
      { name: 'autocomplete', component: AutocompleteTypeComponent,  wrappers: ['form-field'], },
      { name: 'properties', component: PropertiesSelectTypeComponent,  wrappers: ['panel'], },
      { name: 'daterange', component: DateRangeSelectTypeComponent,  wrappers: ['panel'], },
      { name: 'date', component: DateSelectTypeComponent,  wrappers: ['panel'], },
      { name: 'select', component: SelectTypeComponent,  wrappers: ['panel'], },
      { name: 'checkbox', component: CheckBoxTypeComponent,  wrappers: ['panel'], },
      { name: 'radio', component: RadioTypeComponent,  wrappers: ['panel'], },

    ],
    wrappers: [
      { name: 'panel', component: PanelWrapperComponent },
      { name: 'advanced', component: AdvancedWrapperComponent },
    ],

  }

  @NgModule({
    imports: [
      FormlyMaterialModule,
      FormlyModule.forRoot(defaultConfig)],
    exports: [FormlyModule],
  })
  export class FormlyStanaloneModule {}