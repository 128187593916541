import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';


import { map, pluck } from 'rxjs/operators';

import { HoldingFundraisingService } from '../holding-fundraising.service';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import { NumberUtils } from 'src/app/shared/utils/number-utils';

@Component({
  selector: 'terra-fundraising-doughnut-card',
  templateUrl: './fundraising-doughnut-card.component.html',
  styleUrls: ['./fundraising-doughnut-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundraisingDoughnutCardComponent implements OnInit {

  currencySymbol$ = this.holdingFundraisingService.fundraising$.pipe(pluck('fundraisingTargetCurrency', 'symbol'));
  targetAmount$ = this.holdingFundraisingService.fundraising$.pipe(pluck('fundraisingTargetAmount'));
  amountInvested$ = this.holdingFundraisingService.fundraising$.pipe(
    pluck('investments'),
    map(investments => NumberUtils.reduceDecimal(investments.filter(inv => inv.status === InvestmentStatus.Invested).map(inv => inv.finalAmount)))
    );

  constructor(private holdingFundraisingService: HoldingFundraisingService) { }

  ngOnInit(): void {
  }
}
