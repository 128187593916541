import { Component, OnInit, EventEmitter, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange, MatListOption } from '@angular/material/list';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { FindContactInListDialogComponent } from '../../asset-invite-investor/find-contact-in-list-dialog/find-contact-in-list-dialog.component';
import { SignatureService } from 'src/app/services/signature.service';
import {FocusMonitor} from '@angular/cdk/a11y';

@Component({
  selector: 'terra-sign-agreement-templates',
  templateUrl: './sign-agreement-templates.component.html',
  styleUrls: ['./sign-agreement-templates.component.scss']
})
export class SignAgreementTemplatesComponent implements OnInit {

  templates$ = this.signatureService.getTemplatesAgreement(0 /* means get all */)
    .pipe(
      catchError(err => {
        this.errorObject = err;
        return throwError(err);
      }));

  errorObject = null;

  uploadNewAgreement = new EventEmitter();

  @ViewChild(MatSelectionList) selectList: MatSelectionList;

  selectedOption: MatListOption;

  constructor(
    private signatureService: SignatureService,
    public dialogRef: MatDialogRef<FindContactInListDialogComponent>,
    private _focusMonitor: FocusMonitor,
    @Inject(MAT_DIALOG_DATA) public fundraisingId: number
  ) { }

  ngOnInit() {
  }

  selectionChange(s: MatSelectionListChange) {
    this.selectList.deselectAll();
    s.options[0].selected = true;
    this.selectedOption = s.options[0];
  }

  open() {
    this.dialogRef.close(this.selectedOption.value);
  }

  uploadNewAgreementClick() {
    this.uploadNewAgreement.next(undefined);
    this.dialogRef.close();
  }

}
