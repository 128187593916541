<terra-dialog-header>
  <h1>Upload offering deck</h1>
</terra-dialog-header>

<mat-dialog-content class="content">
  <form id="uploadMarketingDeck" (ngSubmit)="send()" [formGroup]="pageForm" *ngIf="pageForm" class="terra-error-style">

    <!-- Marketing document uploader -->
    <div class="terra-form-field-wrapper">
      <terra-file-uploader no-type-select formControlName="marketingDeckDocument"  [required]="true" [isSubmitted]="(isSubmitted$ | async)"
                           [isCompressable]="true">
      </terra-file-uploader>
    </div>

    <terra-loader *ngIf="isLoading$ | async" class="loader"></terra-loader>

    <p class="file-uploader-hint">
      File format must be JPG, JPEG, PNG (20 MB max file size) or PDF (50 MB max
      file size)
    </p>
    <mat-checkbox *ngIf="context.type != FundraisingType.InitialFundraising" class="regular-checkbox" color="primary" formControlName="isDefaultDeck">
      Set as default deck for Invest Now Opportunity Page
    </mat-checkbox>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" form="uploadMarketingDeck" mat-flat-button color="primary" class="page-cta">Upload</button>
  <button type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
