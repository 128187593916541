import {Injectable} from '@angular/core';
import {UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray, AbstractControl} from '@angular/forms';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {BehaviorSubject, of, Observable, timer, throwError, EMPTY} from 'rxjs';
import {map, switchMap, finalize, catchError, take, tap} from 'rxjs/operators';

import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ClientBankAccountResponseBasic} from 'src/app/dashboard/models/bankAccount.model';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import {GpAssetReqRes} from '../../GpAssetReqRes.model';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';
import {LocationDetailsResponse} from 'src/app/shared/models/LocationDetailsResponse.model';
import {GpAssetDataService} from 'src/app/services/gp/gp-asset-data.service';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import {LenderInformation} from 'src/app/shared/models/LenderInformation.model';
import {HoldingFileReqRes} from 'src/app/dashboard/shared/holding/HoldingFileReqRes.model';
import {GpAssetDialogContext} from '../../gp-asset/AssetDetails.context';
import {EditFundAssetTabNumber} from './EditFundAssetStepBaseAndInterface';
import {EditFundAssetDialogComponent} from './edit-fund-asset-dialog.component';
import {GpHoldingDataService} from 'src/app/services/gp/gp-holding-data.service';
import {
  ConfirmInvestorsNotificationDialogComponent,
  ConfirmInvestorsNotificationResult
} from 'src/app/dashboard/shared/holding/confirm-investors-notification-dialog/confirm-investors-notification-dialog.component';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import InvestmentType from '../../../../shared/enums/InvestmentType.enum';
import {ProjectedPerformance} from '../../../../shared/models/ProjectedPerformance.model';
import {ProjectBudget} from '../../../../shared/models/ProjectBudget.model';
import { CustomValidators } from 'src/app/shared/validators/custom.validators';

@Injectable({
  providedIn: 'root'
})
export class EditFundAssetService {

  editFundAssetDialogContext: GpAssetDialogContext;

  dialogRef: MatDialogRef<EditFundAssetDialogComponent>;

  assetForm: UntypedFormGroup;

  attemptSubmit = false;

  processingSubmit$ = new BehaviorSubject(false);
  isGeneralServerError = false;

  bankAccounts$: Observable<ClientBankAccountResponseBasic[]>;

  assetCreated = false;

  get selectedInvestmentType() {
    return this.getTabForm(1).get('investmentType').value as InvestmentType;
  }

  // Data points available to collect per HoldingType
  readonly allowedDataPointsListFor = TerraUtils.consts.allowedDataPointsListFor;

  constructor(
    private fb: UntypedFormBuilder,
    private gpAssetDataService: GpAssetDataService,
    private gpHoldingService: GpHoldingDataService,
    private numberPipe: TerraNumberPipe,
    private dialog: MatDialog) {
  }


  getTabForm(tabNumber: EditFundAssetTabNumber) {
    const tabFormName = 'tab' + tabNumber;
    if (tabFormName && this.assetForm) {
      return this.assetForm.get(tabFormName) as UntypedFormGroup;
    }
    return null;
  }

  generateForm() {
    const asset = this.editFundAssetDialogContext.asset;
    this.assetForm = this.fb.group({
      tab1: this.fb.group({
        name: [asset.name, [Validators.required, Validators.minLength(2)], this.validateAssetNameNotTaken.bind(this)],
        legalName: [asset.legalName, [Validators.required]],
        holdingType: [asset.holdingType, [Validators.required]],
        investmentType: [asset.investmentType, [Validators.required]],
        investmentTypeOther: [asset.investmentTypeOther, asset.investmentType === InvestmentType.Other ?
          [Validators.required, Validators.maxLength(200)] : [Validators.maxLength(200)]],

        areaUnits: asset.areaUnits ? asset.areaUnits : AreaUnit.SquareFeet,
        lotSizeArea: asset.lotSizeArea,
        stories: asset.stories,
        residentialUnits: asset.residentialUnits,
        retailUnits: asset.retailUnits,
        rooms: asset.rooms,
        grossBuildingArea: asset.grossBuildingArea,
        residentialNetSellableArea: asset.residentialNetSellableArea,
        retailNetSellableArea: asset.retailNetSellableArea,
        numberOfSites: asset.numberOfSites,

        assetLocation: [{address: asset.location, street2Name: asset.location?.street2Name}],
        initialCurrency: asset.initialCurrency,
      }),
      tab2: this.fb.group({
        totalCapitalization: [asset.totalCapitalization, [Validators.required, CustomValidators.minFormattedNumber(0)]],
        totalInvestorEquity: [asset.totalInvestorEquity, [Validators.required, CustomValidators.minFormattedNumber(0)]],
        gpEquityFromTotalEquity: asset.gpEquityFromTotalEquity,
        totalEquityInvestedToDate: asset.totalEquityInvestedToDate,
        totalReturnsToDate: asset.totalReturnsToDate,
        estimatedMarketValue: asset.estimatedMarketValue,
        plannedCostsToDate: asset.plannedCostsToDate,
        actualCostsToDate: asset.actualCostsToDate,
        constructionStartDate: asset.constructionStartDate,
        constructionEndDate: asset.constructionEndDate,

        projectedPerformance: this.fb.group({
          cashOnCashRate: asset.projectedPerformance.cashOnCashRate,
          nonLeveragedCapRate: asset.projectedPerformance.nonLeveragedCapRate,
          targetInvestmentIrr: asset.projectedPerformance.targetInvestmentIrr,
          targetInvestmentIrrMax: asset.projectedPerformance.targetInvestmentIrrMax,
          expectedRoi: asset.projectedPerformance.expectedRoi,
          targetEquityMultiplier: asset.projectedPerformance.targetEquityMultiplier,
          targetEquityMultiplierMax: asset.projectedPerformance.targetEquityMultiplierMax,
          targetInvestmentPeriod: asset.projectedPerformance.targetInvestmentPeriod,
          preferredReturn: asset.projectedPerformance.preferredReturn
        }),
        projectBudgetOriginal: this.fb.group({
          acquisition: asset.projectBudgetOriginal.acquisition,
          hardCost: asset.projectBudgetOriginal.hardCost,
          softCost: asset.projectBudgetOriginal.softCost,
          financing: asset.projectBudgetOriginal.financing,
          totalCosts: asset.projectBudgetOriginal.totalCosts,
          totalCostsPerGrossAreaUnit: asset.projectBudgetOriginal.totalCostsPerGrossAreaUnit,
          totalCostsPerNetAreaUnit: asset.projectBudgetOriginal.totalCostsPerNetAreaUnit
        }),
        lenderInformation: this.fb.group({
          lenderName: asset.lenderInformation.lenderName,
          amount: asset.lenderInformation.amount,
          loanToValue: asset.lenderInformation.loanToValue,
          interestRate: asset.lenderInformation.interestRate,
          interestType: asset.lenderInformation.interestType,
          loanType: asset.lenderInformation.loanType,
          closingDate: asset.lenderInformation.closingDate,
          maturityDate: asset.lenderInformation.maturityDate,
          additionalTerms: asset.lenderInformation.additionalTerms,
          totalFinancingPerGrossAreaUnit: asset.lenderInformation.totalFinancingPerGrossAreaUnit,
          totalFinancingPerNetAreaUnit: asset.lenderInformation.totalFinancingPerNetAreaUnit
        })
      }),
      tab3: this.fb.group({
        assetPhotos: this.fb.array([]),
        assetPaymentRequestDocuments: this.fb.array([]),
        isPrivate: asset.isPrivate
      })
    });

    this.assetForm.get('tab1.investmentType').valueChanges.subscribe(val => {
      if (val === InvestmentType.Other) {
        this.assetForm.get('tab1.investmentTypeOther').setValidators([Validators.required, Validators.maxLength(200)]);
      } else {
        this.assetForm.get('tab1.investmentTypeOther').setValue(null);
        this.assetForm.get('tab1.investmentTypeOther').setValidators([Validators.maxLength(200)]);
      }
      this.assetForm.get('tab1.investmentTypeOther').updateValueAndValidity();
    });

    const assetPhotos = asset.attachments.filter(x => x.fileType === HoldingFileType.Photo);
    const photosArray = this.getTabForm(3).get('assetPhotos') as UntypedFormArray;
    for (let i = 0; i < 8; i++) {
      photosArray.push(this.fb.control(
        (i < assetPhotos.length && assetPhotos[i]) ?
          assetPhotos[i].metaFileLink : null,
        {asyncValidators: this.validatePhotoFileTypeSupported.bind(this)}));
    }
    const assetPaymentRequestDocuments = asset.attachments.filter(x => x.fileType === HoldingFileType.PaymentRequestDocument);
    const paymentRequestDocumentsArray = this.getTabForm(3).get('assetPaymentRequestDocuments') as UntypedFormArray;
    for (let i = 0; i < 8; i++) {
      paymentRequestDocumentsArray.push(this.fb.control(
        (i < assetPaymentRequestDocuments.length && assetPaymentRequestDocuments[i]) ?
          assetPaymentRequestDocuments[i].metaFileLink : null,
        {asyncValidators: this.validatePaymentRequestDocumentFileTypeSupported.bind(this)}));
    }

  }

  generateSubmitModel(): GpAssetReqRes {
    const formValues = this.assetForm.getRawValue();
    const tab1 = formValues.tab1;
    const tab2 = formValues.tab2;
    const tab3 = formValues.tab3;

    const asset = new GpAssetReqRes();

    asset.id = this.editFundAssetDialogContext.asset.id;

    // Tab1
    if(!this.editFundAssetDialogContext.asset.isExample){
      asset.name = tab1.name;
      asset.legalName = tab1.legalName;
    }
    asset.holdingType = tab1.holdingType;
    asset.investmentType = tab1.investmentType;
    asset.investmentTypeOther = tab1.investmentTypeOther;

    asset.areaUnits = this.numberPipe.parse(tab1.areaUnits);
    asset.lotSizeArea = this.numberPipe.parse(tab1.lotSizeArea);
    asset.stories = this.numberPipe.parse(tab1.stories);
    asset.residentialUnits = this.numberPipe.parse(tab1.residentialUnits);
    asset.retailUnits = this.numberPipe.parse(tab1.retailUnits);
    asset.rooms = this.numberPipe.parse(tab1.rooms);
    asset.grossBuildingArea = this.numberPipe.parse(tab1.grossBuildingArea);
    asset.residentialNetSellableArea = this.numberPipe.parse(tab1.residentialNetSellableArea);
    asset.retailNetSellableArea = this.numberPipe.parse(tab1.retailNetSellableArea);
    asset.numberOfSites = this.numberPipe.parse(tab1.numberOfSites);

    asset.location = {...tab1.assetLocation.address, street2Name: tab1.assetLocation.street2Name} as LocationDetailsResponse;

    // Tab 2 Additional info
    asset.totalCapitalization = this.numberPipe.parse(tab2.totalCapitalization);
    asset.totalEquityInvestedToDate = this.numberPipe.parse(tab2.totalEquityInvestedToDate);
    asset.totalReturnsToDate = this.numberPipe.parse(tab2.totalReturnsToDate);
    asset.totalInvestorEquity = this.numberPipe.parse(tab2.totalInvestorEquity);

    asset.plannedCostsToDate = this.numberPipe.parse(tab2.plannedCostsToDate);
    asset.actualCostsToDate = this.numberPipe.parse(tab2.actualCostsToDate);
    asset.outstandingLoanAmountToDate = this.numberPipe.parse((tab2.outstandingLoanAmountToDate));

    asset.gpEquityFromTotalEquity = this.numberPipe.parse(tab2.gpEquityFromTotalEquity);
    asset.estimatedMarketValue = this.numberPipe.parse(tab2.estimatedMarketValue);

    asset.constructionStartDate = tab2.constructionStartDate;
    asset.constructionEndDate = tab2.constructionEndDate;

    let projectedPerfModel: ProjectedPerformance;
    asset.projectedPerformance = new ProjectedPerformance();
    projectedPerfModel = asset.projectedPerformance;

    projectedPerfModel.cashOnCashRate = this.numberPipe.parse(tab2.projectedPerformance.cashOnCashRate);
    projectedPerfModel.nonLeveragedCapRate = this.numberPipe.parse(tab2.projectedPerformance.nonLeveragedCapRate);
    projectedPerfModel.targetInvestmentIrr = this.numberPipe.parse(tab2.projectedPerformance.targetInvestmentIrr);
    projectedPerfModel.targetInvestmentIrrMax = this.numberPipe.parse(tab2.projectedPerformance.targetInvestmentIrrMax);
    projectedPerfModel.expectedRoi = this.numberPipe.parse(tab2.projectedPerformance.expectedRoi);
    projectedPerfModel.targetEquityMultiplier = this.numberPipe.parse(tab2.projectedPerformance.targetEquityMultiplier);
    projectedPerfModel.targetEquityMultiplierMax = this.numberPipe.parse(tab2.projectedPerformance.targetEquityMultiplierMax);
    projectedPerfModel.targetInvestmentPeriod = this.numberPipe.parse(tab2.projectedPerformance.targetInvestmentPeriod);
    projectedPerfModel.preferredReturn = this.numberPipe.parse(tab2.projectedPerformance.preferredReturn);

    // Tab 2 - Original budget:
    asset.projectBudgetOriginal = new ProjectBudget();
    const budgetModel = asset.projectBudgetOriginal;
    const budgetFormValues = tab2.projectBudgetOriginal;
    budgetModel.acquisition = this.numberPipe.parse(budgetFormValues.acquisition);
    budgetModel.hardCost = this.numberPipe.parse(budgetFormValues.hardCost);
    budgetModel.softCost = this.numberPipe.parse(budgetFormValues.softCost);
    budgetModel.financing = this.numberPipe.parse(budgetFormValues.financing);
    budgetModel.totalCosts = this.numberPipe.parse(budgetFormValues.totalCosts);
    budgetModel.totalCostsPerGrossAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerGrossAreaUnit);
    budgetModel.totalCostsPerNetAreaUnit = this.numberPipe.parse(budgetFormValues.totalCostsPerNetAreaUnit);

    asset.lenderInformation = new LenderInformation();
    const lenderInfo = asset.lenderInformation;

    lenderInfo.lenderName = tab2.lenderInformation.lenderName;
    lenderInfo.amount = this.numberPipe.parse(tab2.lenderInformation.amount);
    lenderInfo.loanToValue = this.numberPipe.parse(tab2.lenderInformation.loanToValue);
    lenderInfo.interestRate = this.numberPipe.parse(tab2.lenderInformation.interestRate);
    lenderInfo.interestType = this.numberPipe.parse(tab2.lenderInformation.interestType);
    lenderInfo.closingDate = tab2.lenderInformation.closingDate;
    lenderInfo.maturityDate = tab2.lenderInformation.maturityDate;
    lenderInfo.additionalTerms = tab2.lenderInformation.additionalTerms;
    lenderInfo.loanType = tab2.lenderInformation.loanType;
    lenderInfo.totalFinancingPerGrossAreaUnit = this.numberPipe.parse(tab2.lenderInformation.totalFinancingPerGrossAreaUnit);
    lenderInfo.totalFinancingPerNetAreaUnit = this.numberPipe.parse(tab2.lenderInformation.totalFinancingPerNetAreaUnit);

    // Tab 3 - more settings:
    asset.isPrivate = tab3.isPrivate;
    // Add the photos:
    asset.attachments = new Array<HoldingFileReqRes>();
    try {
      const photosArray = tab3.assetPhotos;
      photosArray
        .forEach((photoMetaFile: MetaFileLink) => {
          if (photoMetaFile) {
            const file = new HoldingFileReqRes();
            // Find the attachent (assetFile) with the curent metaFileLink inside it:
            const originalAssetFileWithThisMetaFile = this.editFundAssetDialogContext.asset.attachments.find(a => a.metaFileLink.id === photoMetaFile.id);
            if (originalAssetFileWithThisMetaFile) {
              file.id = originalAssetFileWithThisMetaFile.id;
            }
            file.metaFileLinkId = photoMetaFile.id;
            file.fileType = HoldingFileType.Photo;
            asset.attachments.push(file);
          }
        });
    } catch (error) {
      console.log('ERROR while trying to add photos to the submit model.', error);
    }
    try {
      const paymentRequestDocumentsArray = tab3.assetPaymentRequestDocuments;
      paymentRequestDocumentsArray
        .forEach((docMetaFile: MetaFileLink) => {
          if (docMetaFile) {
            const file = new HoldingFileReqRes();
            // Find the attachent (assetFile) with the curent metaFileLink inside it:
            const originalAssetFileWithThisMetaFile = this.editFundAssetDialogContext.asset.attachments.find(a => a.metaFileLink.id === docMetaFile.id);
            if (originalAssetFileWithThisMetaFile) {
              file.id = originalAssetFileWithThisMetaFile.id;
            }
            file.metaFileLinkId = docMetaFile.id;
            file.fileType = HoldingFileType.PaymentRequestDocument;
            asset.attachments.push(file);
          }
        });
    } catch (error) {
      console.log('ERROR while trying to add payment request files to the submit model.', error);
    }
    return asset;
  }

  saveChanges(): Observable<GpAssetReqRes> {
    this.assetForm.markAllAsTouched();

    this.attemptSubmit = true;

    this.isGeneralServerError = false;
    if (!this.assetForm.valid) {
      return of(null);
    }

    const model = this.generateSubmitModel();
    const changedToPublic = !model.isPrivate && this.editFundAssetDialogContext.asset.isPrivate;
    return of(changedToPublic).pipe(
      take(1),
      switchMap(isChangedToPublic => {
        if (isChangedToPublic) {
          const dialogConfig = new MatDialogConfig<HoldingDiscriminator>();
          dialogConfig.data = HoldingDiscriminator.Asset;
          return this.dialog.open<any, HoldingDiscriminator, ConfirmInvestorsNotificationResult>(ConfirmInvestorsNotificationDialogComponent, dialogConfig)
            .afterClosed();
        }
        return of(ConfirmInvestorsNotificationResult.SkipNotification);
      }),
      tap(_ => this.processingSubmit$.next(true)),
      switchMap(selectedOption => {
          if (selectedOption === null || selectedOption === undefined) {
            return EMPTY;
          }
          return this.editFundAssetDialogContext.gpAssetService
            .updateFundAsset(model, selectedOption === ConfirmInvestorsNotificationResult.SkipNotification);
        }
      ),
      finalize(() => this.processingSubmit$.next(false)),
      catchError(error => {
        this.processingSubmit$.next(false);
        this.isGeneralServerError = true;
        return throwError(error);
      })
    );
  }

  // Async validation functions:
  validateAssetNameNotTaken(control: AbstractControl, originalValue = '') {
    originalValue = this.editFundAssetDialogContext.asset.name;

    const {value}: { value: string } = control;

    if (!value || value === originalValue) {
      return of(null);
    }
    return timer(1000).pipe(
      switchMap(_ => this.gpAssetDataService.isAssetNameExists(value)),
      map(isAssetNameExists => {
        return isAssetNameExists ? {assetNameTaken: true} : null;
      })
    );
  }

  validateOfferingDeckFileTypeSupported(control: AbstractControl) {

    /*
      Possible using destructing :)
      const { value: metaFile }: { value: MetaFileLink } = control;
      And then: metaFile.id
    */

    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingService.isFileSupportedOfferingDeck(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

  validatePhotoFileTypeSupported(control: AbstractControl) {
    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingService.isFileSupportedPhoto(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

  validatePaymentRequestDocumentFileTypeSupported(control: AbstractControl) {
    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingService.isFileSupportedPaymentRequestDocument(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }

}
