<div class="flex-container">
  <h3 class="title">
    Payment Request Documents
  </h3>
  <hr>
  <ng-container *ngIf="(allowInvestorName$ | async); else notAllowInvestorName">
    <ng-container *ngIf="{
    holdingPaymentRequestDocument: holdingPaymentRequestDocument$ | async,
    investmentPaymentRequestDocument: investmentPaymentRequestDocument$ | async,
    allPaymentRequestDocumentLength: allPaymentRequestDocumentLength$ | async,
    investment: investment$ | async
} as data">
      <div class="documents">
        <form [formGroup]="form">
          <ng-container *ngFor="let document of data.holdingPaymentRequestDocument">
            <div class="file-saved-container file-container">
              <a target="_blank" class="light link" href="{{ document?.url }}">
                <mat-icon class="notranslate">insert_drive_file</mat-icon>
                {{
                (document?.title || '')| truncate: 50 }}
              </a>
              <mat-icon class="notranslate delete-file-icon delete-blocked" (click)="deleteBlocked()">
                clear
              </mat-icon>
            </div>
          </ng-container>
          <ng-container *ngFor="let document of data.investmentPaymentRequestDocument">
            <div class="file-saved-container file-container">
              <a target="_blank" class="light link" href="{{ document?.url }}">
                <mat-icon class="notranslate">insert_drive_file</mat-icon>
                {{
                (document?.title || '')| truncate: 50 }}
              </a>
              <mat-icon matTooltip="Delete" matTooltipClass="terra-tooltip-compact"
                        class="notranslate delete-file-icon" (click)="delete(document)">clear
              </mat-icon>
            </div>
          </ng-container>

          <ng-container formArrayName="documents" *ngIf="data.investment.status === InvestmentStatus.Signed">
            <div class="file-container" *ngFor="let document of documents.controls; let i = index">
              <terra-file-uploader no-type-select [formControl]="document" [required]="false">
              </terra-file-uploader>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="additional-document" *ngIf="data.investment.status === InvestmentStatus.Signed">
        <a *ngIf="MAX_UPLOAD_DOCUMENT > (documents.length + data.allPaymentRequestDocumentLength)"
           class="add-more-documents-link full-width"
           (click)="addDocument(data.allPaymentRequestDocumentLength)">
          <mat-icon class="notranslate">add_circle</mat-icon>
          Add Additional Documents
        </a>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #notAllowInvestorName>
    <div class="not-allow-message">
      <mat-icon class="notranslate">error</mat-icon>
      <h4>You do not have permissions to view investors' documents as these documents might include investors'
        names.</h4>
    </div>
  </ng-template>
</div>
