import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';

import { GpAssetReportService } from '../gp-asset-report.service';
import { InvestmentType } from 'src/app/shared/enums/InvestmentType.enum';
import { GpAssetService } from '../../../../gp-asset.service';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'terra-asset-report-cumulative-info',
  templateUrl: './asset-report-cumulative-info.component.html',
  styleUrls: ['./asset-report-cumulative-info.component.scss']
})
export class AssetReportCumulativeInfoComponent implements OnInit {
  asset$ = this.gpAssetService.holding$;

  currency$ = this.asset$.pipe(map(asset => asset.initialCurrency));

  fundraising$ = this.gpAssetService.fundraising$;

  pageForm: UntypedFormGroup = this.gpAssetReportService.formStep2;
  isSubmitted = false;

  // Enums
  InvestmentType = InvestmentType;
  SecurityType = InvestmentSecurityType;

  assetInvestmentType$ = this.asset$.pipe(map(assetDetails => assetDetails.investmentType));
  assetInvestmentSecurityType$ = this.asset$.pipe(map(asset => asset.initialSecurityType));

  constructor(private gpAssetReportService: GpAssetReportService, private gpAssetService: GpAssetService) { }

  ngOnInit() { }

  stepSubmit() {
    if (this.pageForm.valid) {
      this.gpAssetReportService.isGeneralServerError = false;
      this.gpAssetReportService.currentStep$.next(3);
    }
  }

  back() {
    this.gpAssetReportService.currentStep$.next(1);
  }
}
