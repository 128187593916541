import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { Observable, combineLatest, distinctUntilChanged, map, shareReplay, switchMap, take } from 'rxjs';
import { RoutingService } from '../services/routing.service';
import { AppQuery } from '../state/app.query';
import { AppService } from '../state/app.service';
import { BankAccountsMsService } from './bankAccounts/bank-accounts-ms.service';
import { PromoDialogComponent } from './promo/promo.component';
import { SidePanelService } from './shared/side-panel/side-panel.service';

@Component({
  selector: 'terra-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends OnDestroyMixin implements OnInit {

  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;
  @ViewChild('placeholder', { read: ViewContainerRef, static: true }) viewContainerRef!: ViewContainerRef;

  
  promo$ = this.appQuery.gpUiPrefs.pipe(map((uiState) => uiState.promo));
  disconnectedBankAccountsNumber$: Observable<number | null> = this.bankAccountsMsService.bankAccountsRefresh$.pipe(
    switchMap(_ => this.bankAccountsMsService.getDisconnectedBankAccountCount()),
    shareReplay(1)
  );
  constructor(
    private readonly appService: AppService,
    private readonly appQuery: AppQuery,
    private readonly dialog: MatDialog,
    private bankAccountsMsService: BankAccountsMsService,
    private router: Router,
    private routingService: RoutingService,
    private sidePanelService: SidePanelService
  ) {
    super();
  }

  ngOnInit() {
    this.sidePanelService.setSidenav(this.sidenav, this.viewContainerRef);

    combineLatest([this.appService.accountDetails$, this.promo$])
      .pipe(distinctUntilChanged(), untilComponentDestroyed(this), take(1))
      .subscribe(([accountDetails, promo]) => {
        if (promo.showCapitalCommitments &&
          this.differenceDays(accountDetails.createTimestamp) > 5 // TODO: move to Project Constants/Config
        ) {
          this.openPromoDialog();
        }
      });
  }

  private differenceDays(date: Date | string) {
    return (
      (new Date().getTime() - new Date(date).getTime()) / (1000 * 3600 * 24)
    );
  }

  private openPromoDialog() {
    const dialogRef = this.dialog.open(PromoDialogComponent,
      {
        height: '756px',
        width: '700px',
        panelClass: 'promo-panel'
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      this.appService.updatePromoUIState(
        'showCapitalCommitments',
        false
      );
    });
  }
  reconnectAction() {
    this.router.navigate([this.routingService.bankAccountsPage("gp")], {queryParams: {action: 'reconnect'}});
  }
}
