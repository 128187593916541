import { Component, OnInit } from '@angular/core';

 
import { AreaUnit } from 'src/app/shared/enums/AreaUnit.enum';
import { FundInvestmentStrategyType } from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import { InterestType } from 'src/app/shared/enums/InterestType.enum';
import { LoanType } from 'src/app/shared/enums/LoanType.enum';
import { CreateFundStepBase } from '../CreateFundStepBaseAndInterface';
import { CreateFundService, CreateFundStepNumber } from '../create-fund.service';

@Component({
  selector: 'terra-create-fund-investment-details-step',
  templateUrl: './create-fund-investment-details-step.component.html',
  styleUrls: ['./create-fund-investment-details-step.component.scss']
})
export class CreateFundInvestmentDetailsStepComponent extends CreateFundStepBase implements OnInit {
  readonly STEP_NUMBER: CreateFundStepNumber = 2;

  // Enums
  FundInvestmentStrategyType = FundInvestmentStrategyType;
  LoanType = LoanType;
  AreaUnit = AreaUnit;
  InterestType = InterestType;

  // Enum values list
  LoanTypesList = LoanType.listAll();

  // Enum Interest Type
  InterestTypesList = InterestType.listAll();

  selectedInvestmentStrategyType = this.createFundService.selectedInvestmentStrategyType;
  selectedCurrency = this.createFundService.selectedCurrency;

  closingDateStartAt = new Date();
  closingDateMinimumDate = new Date('1970-01-01T12:00');

  maturityDateStartAt = new Date();
  maturityDateMinimumDate = new Date('1970-01-01T12:00');


  constructor(private createFundService: CreateFundService) {
    super(createFundService);
  }

  ngOnInit() {
    this.createFundService.currentStep$.next(this.STEP_NUMBER);
  }
}
