import {Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {combineLatest} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {
  AssetSendMarketingDialogComponent,
  AssetSendMarketingDialogContext
} from './asset-send-marketing-dialog/asset-send-marketing-dialog.component';
import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import {ShareMarketingParams} from './ShareMarketingParams.model';
import {SnackbarService} from 'src/app/services/snackbar.service';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {UserService} from 'src/app/services/shared/user.service';
import {MetaFileLink} from 'src/app/models/metaFileLink.model';
import {AssetInvestorService} from '../asset-investor/asset-investor.service';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {GpAssetService} from '../gp-asset.service';
import {AssetFundraisingService} from '../asset-fundraising.service';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';

@Component({
  selector: 'terra-share-asset',
  templateUrl: './share-asset.component.html',
  styleUrls: ['./share-asset.component.scss']
})
export class ShareAssetComponent extends OnDestroyMixin implements OnInit {
  dateFormat = TerraUtils.consts.LONG_DATE_AND_TIME;

  constructor(
    private dialog: MatDialog,
    private assetInvestorService: AssetInvestorService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private assetDetailsService: GpAssetService,
    private assetFundraisingService: AssetFundraisingService,
    private telemetryService: TelemetryService,
    private utilsService: UtilsService
  ) {
    super();
  }


  @Input() investment: InvestmentReqRes;

  @Input() marketingDeck: MetaFileLink;

  @Input() assetName: string;

  @Input() isHoldingPrivate: boolean;

  ngOnInit() {
  }

  showModal() {
    combineLatest([this.userService.accountDetails$, this.assetFundraisingService.fundraising$]).pipe(
      take(1),
      switchMap(([agentDetails, fundraisingDetails]) => {
        const dialogConfig = new MatDialogConfig<AssetSendMarketingDialogContext>();
        dialogConfig.disableClose = false;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'send-marketing-dialog';

        const shareMarketingParams = new ShareMarketingParams();
        shareMarketingParams.investmentId = this.investment.id;
        shareMarketingParams.investingEntityName = this.investment.investingEntity.name;
        shareMarketingParams.investingEntityNickname = this.investment.investingEntity.nickname;
        shareMarketingParams.investorName = TerraUtils.getContactFullName(this.investment.investingEntity.contact);
        shareMarketingParams.investorNickname = this.investment.investingEntity.contact?.nickname
        shareMarketingParams.marketingFile = this.marketingDeck;
        shareMarketingParams.agentCompanyName = agentDetails.organizationDetails.name;
        shareMarketingParams.agentFirstName = agentDetails.firstName;
        shareMarketingParams.lpFirstName = this.investment.investingEntity.contact.firstName;
        shareMarketingParams.assetName = this.assetName;
        shareMarketingParams.investmentSecurityType = fundraisingDetails.securityType;

        dialogConfig.data = new AssetSendMarketingDialogContext(shareMarketingParams, this.assetInvestorService, this.userService);

        return this.dialog.open(AssetSendMarketingDialogComponent, dialogConfig).afterClosed();
      }),
      untilComponentDestroyed(this))
      .subscribe((isSent: boolean) => {
        if (isSent) {
          this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
            this.assetDetailsService.holdingId$.pipe(take(1)).subscribe(assetId => {
              this.telemetryService.create({
                eventID: '402',
                eventTitle: 'MARKETING DECK SENT',
                organizationID: clientDetails.organizationDetails.id,
                holdingID: assetId
              }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
            });
          });
          this.snackbarService.showGeneralMessage('Offering deck sent');
        }
      });
  }

  markAsSoftCircled() {
    this.assetInvestorService.updateStatus(InvestmentStatus.SoftCircled)
    .subscribe(
      (updatedInvestment: InvestmentReqRes) => { },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        }

        console.log('Error. Status was not updated.', error);
      }
    );
  }

  assetDownInfo() {
    this.assetDetailsService.holdingDownInfo();
  }
}
