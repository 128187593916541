import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
} from '@angular/core';

import DistributionStatus from 'src/app/shared/enums/DistributionStatus.enum';
import {TerraCurrencyNoSymbolPipe} from '../../../../../shared/pipes/TerraCurrency.pipe';
import {DistributionTransferReqRes} from '../DistributionTransferReqRes.model';
import DistributionTransactionPurpose from '../../../../../shared/enums/DistributionTransactionPurpose.enum';
import { PaymentType } from 'src/app/shared/enums/payment-type.enum';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import TransactionType from '../../../../../shared/enums/TransactionType.enum';


@Component({
  selector: 'terra-distribution-total-amounts',
  templateUrl: './distribution-total-amounts.component.html',
  styleUrls: ['./distribution-total-amounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributionTotalAmountsComponent implements OnInit {
  @Input() distributionTransfers: DistributionTransferReqRes[];
  @Input() feeCurrency: string;
  @Input() feeSymbol: string;
  @Input() isDistributingAnyAmountViaCovercy: boolean;
  @Input() isFeeEstimated: boolean;
  @Input() distributionTotalFee: number;
  @Input() totalAmountAfterFees: number;
  @Input() totalAmountViaCovercy: number;
  @Input() totalAmountOutsideOfCovercy: number;
  @Input() gpEffectiveMarkupPercentage: number;
  @Input() totalAdjustmentsAmountViaCovercy: number;
  @Input() totalAdjustmentsAmountOutsideOfCovercy: number;
  @Input() withheldTaxes: number;
  @Input() feeAmount: number;
  @Input() distributionStatus: DistributionStatus;
  @Input() reasonsForTransfer: DistributionTransactionPurpose[];
  @Input() unitFees: number;
  @Input() isUnitBankAccount: boolean;
  @Input() loading:boolean = false;
  

  // enums
  DistributionStatus = DistributionStatus;
  DistributionTransactionPurpose = DistributionTransactionPurpose;
  UnitPaymentType = PaymentType;

  constructor(@Inject(LOCALE_ID) private _locale: string) {
  }

  ngOnInit(): void {
  }

  public getFeesTooltipText(): string {
    return `Distribution fees breakdown:
    ${this.gpEffectiveMarkupPercentage}% of the total amount transferred via Covercy, and a fixed fee of
      ${new TerraCurrencyNoSymbolPipe(this._locale).transform(this.feeAmount)} ${this.feeCurrency}`;
  }

  public getTotalAmountForTransferType(transferType: DistributionTransactionPurpose): number {
    let amount = 0;
    this.distributionTransfers.forEach(transfer => {
      const transferDetailsForType = transfer.distributionTransfersDetails.find(transferDetails => transferDetails.reasonForTransaction === transferType);
      if (!!transferDetailsForType) {
        amount += transferDetailsForType.amountNet;
      }
    });

    return amount;
  }
  
  public showTotalDepositedCovercyLabel(): boolean {
    const isNotUnitPayment = this.distributionTransfers
    .some((dt) => 
      (dt.amountNet > 0 && !dt.externalPayment && dt.clientBankAccount) &&
        dt.clientBankAccount?.country?.code !== TerraUtils.consts.countryCode.US && 
        dt.transactionType !== TransactionType.AchDomestic && 
        !this.isUnitBankAccount);

    const apporpriateStatus = (this.distributionStatus === DistributionStatus.New || 
      this.distributionStatus === DistributionStatus.NoticeSent ||
      this.distributionStatus === DistributionStatus.WaitingForFunds)
    
    return isNotUnitPayment && this.isDistributingAnyAmountViaCovercy && apporpriateStatus;
  
    }

    get total():number{
      const amount = sum => sum || 0;
      return amount(this.totalAmountViaCovercy) + amount(this.totalAmountOutsideOfCovercy) + amount(this.withheldTaxes);
    }
}
