<section class="page-heading" [ngClass]="{ 'edit-mode': reportId > 0 }" *ngIf="reportId$ | async as reportId">
  <div class="breadcrumbs" *ngIf="fund$ | async as fundm">
    <a [routerLink]="this.routingService.gpFundWithTab(fundm.id, 'reports')"><span
        class="lo_sensitive">{{ fundm.name }}</span> > Reports > </a>
    <span class="current">{{ reportId > 0 ? 'Edit report' : 'New report' }}</span>
  </div>
  <!--<h1>{{ reportId > 0 ? 'Edit an existing report' : 'Create a new report' }}</h1>-->
  <h1>{{ reportId > 0 ? 'Publish a report' : 'Publish a report' }}</h1>

  <ng-container *ngIf="(processingSubmit$ | async) === false">
    
    <ul class="steps" *ngIf="currentStep$ | async as step">
      <li [ngClass]="{ active: step === 1 }" (click)="reportId > 0 ? navigateToStep(1) : false">
        <span class="step-number">1</span><label>Report<br />information</label>
      </li>
      <li [ngClass]="{ active: step === 2 }" (click)="reportId > 0 ? navigateToStep(2) : false">
        <span class="step-number">2</span><label>Update<br />cumulative info</label>
      </li>
      <li [ngClass]="{ active: step === 3 }" (click)="reportId > 0 ? navigateToStep(3) : false">
        <span class="step-number">3</span><label>Review<br />and publish</label>
      </li>
    </ul>
  </ng-container>
</section>

@if (isShowOutlet$ | async) {
    <!-- The router outlet will not be created until we determine which wizard step to display, based on some params (reportId etc..)  -->
  <router-outlet *ngIf="stepsNavigation$ | async" />
}@else {
  <terra-loader />
}

