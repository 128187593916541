import { Component, OnInit } from '@angular/core';

import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import { InvestmentType } from 'src/app/shared/enums/InvestmentType.enum';
import { ResourceService } from 'src/app/services/resource.service';
import { CountryModel } from 'src/app/shared/models/CountryModel';
import { GpAssetService } from '../gp-asset.service';
import { AppStateForTours, ToursService } from 'src/app/services/gp/tours.service';

export enum AssetDraftTab {
  Overview = 0,
  Reports = 1,
  Documents = 2,
  Fundraisings = 3,
  Distributions = 4
}

@Component({
  selector: 'terra-asset-draft-status',
  templateUrl: './asset-draft-status.component.html',
  styleUrls: ['./asset-draft-status.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush, // can't use because it prevents the initial tab to be selected when the component loads.
})
export class AssetDraftStatusComponent implements OnInit {
  tabsData = [
    { label: 'Overview', url: 'overview', index: AssetDraftTab.Overview },
    { label: 'Reports', url: 'reports', index: AssetDraftTab.Reports },
    { label: 'Documents', url: 'documents', index: AssetDraftTab.Documents },
    { label: 'Contributions', url: 'contributions', index: AssetDraftTab.Fundraisings },
    { label: 'Distributions', url: 'distributions', index: AssetDraftTab.Distributions }
  ];

  // Enum:
  Tab = AssetDraftTab;

  asset$ = this.gpAssetService.holding$;

  // Enums (used in the template)
  HoldingStatus = HoldingStatus;
  HoldingType = HoldingType;
  InvestmentType = InvestmentType;
  InvestmentSecurityType = InvestmentSecurityType;

  constructor(
    private resourceService: ResourceService,
    private gpAssetService: GpAssetService,
    private toursService: ToursService) {
  }

  ngOnInit() {
    this.toursService.setTours(AppStateForTours.DraftAsset);
  }

  getCountryById(countryId: number): CountryModel {
    return this.resourceService.getCountryById(countryId);
  }
}
