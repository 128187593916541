import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {finalize, map, switchMapTo, take, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, startWith, Subject} from 'rxjs';
import {NonNullableFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {DistributionService} from '../../distribution.service';
import {WaterfallsReadDto} from '../../WaterfallsReadDto.model';
import {LoggerService} from '../../../../../../shared/errors/logger.service';
import ReportPeriod from '../../../../../../shared/enums/ReportPeriod.enum';
import MetaFileLink from '../../../../../../models/metaFileLink.model';
import HttpStatusCodesEnum from '../../../../../../shared/enums/HttpStatusCodesEnum';
import {UnitBankAccountReqRes} from 'src/app/shared/models/gp/UnitBankAccountReqRes.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
  UnitBankAccountLimitDialogComponent,
  UnitBankAccountLimitDialogParams
} from 'src/app/shared/components/unit/bank-account-limits/unit-bank-account-limit-dialog/unit-bank-account-limit-dialog.component';
import {BaseResponseStatus} from '../../../../../../shared/models/BaseResponseDto.model';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {AlertDialogParams} from '../../../../../../shared/components/alert-dialog/alert-dialog.component';
import {DialogService} from '../../../../../../services/dialog.service';

@Component({
  selector: 'terra-distribution-waterfalls',
  templateUrl: './distribution-waterfalls.component.html',
  styleUrls: ['./distribution-waterfalls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributionWaterfallsComponent implements OnInit {
  @Input() currencyIso: string;
  @Input() distributionPeriod: string;
  @Input() distributionPeriodType: ReportPeriod;
  @Input() existingWaterfall: MetaFileLink;
  @Input() disableWaterfalls: boolean;
  @Input() selectedCREBankAccount: UnitBankAccountReqRes = null;
  @Output() distributionTransfersChange = new EventEmitter<WaterfallsReadDto>();
  @Input() disableEditMode = false;

  pageForm: UntypedFormGroup = this.fb.group({
    amount: {value: undefined, disabled: this.disableEditMode}
  });

  calculatedWaterfallUrl$ = new Subject<string>();
  errorMessage$ = new BehaviorSubject<string>(null);
  isCalculating$ = new BehaviorSubject(false);
  disableCalculate$ = combineLatest(this.isCalculating$, this.pageForm.controls.amount.valueChanges.pipe(startWith(0))).pipe(
    map(([isCalculating, amount]) => isCalculating || !amount || +amount === 0)
  );

  lastDistribution$ = this.distributionService.lastDistribution$;
  waterfallNotice: string;

  constructor(private fb: NonNullableFormBuilder,
              private distributionService: DistributionService,
              private logger: LoggerService,
              private route: ActivatedRoute,
              private numberPipe: TerraNumberPipe,
              private dialogService: DialogService,
              private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.populateData();
    this.route.queryParams.pipe(
      take(1)
    ).subscribe(params => {
      if (!!params.waterfallAmount) {
        this.pageForm.controls.amount.setValue(params.waterfallAmount);
        this.generateWaterfall();
      }
    });
    if (this.disableEditMode) {
      this.pageForm.controls.amount.disable();
    }
  }

  public generateWaterfall() {
    this.waterfallNotice = null;
    if (!this.distributionPeriod || !this.pageForm.value.amount) {
      return;
    }

    this.lastDistribution$.pipe(
      tap(_ => {
        this.errorMessage$.next(null);
        this.isCalculating$.next(true);
      }),
      switchMapTo(this.distributionService.generateWaterfalls(this.numberPipe.parse(this.pageForm.value.amount), this.distributionPeriod, this.distributionPeriodType)),
      take(1),
      finalize(() => this.isCalculating$.next(false))
    ).subscribe({
      next: (waterfall: WaterfallsReadDto) => {
        this.calculatedWaterfallUrl$.next(waterfall.excelMetaFileLink.url);
        this.distributionTransfersChange.next(waterfall);
        this.waterfallNotice = waterfall.response?.responseStatus === BaseResponseStatus.Notice ? waterfall.response?.responseMessage : null;

        if (waterfall.response?.responseStatus === BaseResponseStatus.Success) {
          this.dialogService.alertDialog(
            new AlertDialogParams(
              'Waterfall Calculation Successfully Completed',
              `<p>Please review the distribution details below and verify that the correct investor bank accounts are selected.</p>`));
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private populateData() {
    if (!!this.existingWaterfall) {
      this.calculatedWaterfallUrl$.next(this.existingWaterfall.url);
    }
  }

  private handleError(error: any) {
    this.distributionService.holdingId$.pipe(
      take(1)
    ).subscribe(holdingId => {
      if (error.status === 0 || error.status === HttpStatusCodesEnum.REQUEST_TIMEOUT) {
        this.errorMessage$.next('Waterfall calculation takes too long, please contact your customer success manager to accelerate the process.');
        this.logger.error(`DistributionWaterfallsComponent => time out error. holdingId: ${holdingId}, period: ${this.distributionPeriod}`, error);
      } else {
        this.errorMessage$.next(error?.error?.exceptionMessage || 'An error occurred, please try again or contact our support team.');
        this.logger.error(`DistributionWaterfallsComponent => generateWaterfall. holdingId: ${holdingId}, period: ${this.distributionPeriod}`, error);
      }
    });
  }

  openUnitAccountLimit() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    const data = new UnitBankAccountLimitDialogParams();
    data.accountLimits = this.selectedCREBankAccount.accountLimits;
    data.displayDailyDebit = false;
    data.displayMonthlyDebit = false;
    config.data = data;
    this.dialog.open(UnitBankAccountLimitDialogComponent, config);
  }
}
