import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RoutingService } from 'src/app/services/routing.service';
import { LoggerService } from 'src/app/shared/errors/logger.service';
import { UnitBankAccountReqRes } from 'src/app/shared/models/gp/UnitBankAccountReqRes.model';
import { GpHoldingService } from '../gp-holding.service';
// import { GpHoldingService } from '../gp-holding.service';


@Component({
  selector: 'terra-bank-account-selection',
  templateUrl: './bank-account-selection.component.html',
  styleUrls: ['./bank-account-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankAccountSelectionComponent implements OnInit {
  @Input() dataList$: Observable<any>;

  holdingId: number = 0;
  frm: UntypedFormGroup;

  buttonText = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<BankAccountSelectionComponent>) { }

  ngOnInit(): void {
    this.buttonText = this.data?.buttonText;
    this.initForm();
  }

  initForm() {
    this.frm = new UntypedFormGroup({
      bankAccountId: new UntypedFormControl(null, Validators.required)
    });
  }

  select() {

    let bankAccount = this.frm.get('bankAccountId').value;

    this.dialogRef.close(bankAccount);
  }
}
