import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ResourceService} from 'src/app/services/resource.service';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {InvestmentType} from 'src/app/shared/enums/InvestmentType.enum';
import {CreateFundAssetService, CreateFundAssetStepNumber} from '../create-fund-asset.service';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import {CreateFundAssetStepBase} from '../CreateFundAssetStepBaseAndInterface';
import {AddressFields, AddressFormSettings} from 'src/app/shared/components/address-form/address-form.settings';

@Component({
  selector: 'terra-create-fund-asset-information-step',
  templateUrl: './create-fund-asset-information-step.component.html',
  styleUrls: ['./create-fund-asset-information-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFundAssetInformationStepComponent extends CreateFundAssetStepBase implements OnInit {
  readonly STEP_NUMBER: CreateFundAssetStepNumber = 1;

  // enum
  HoldingType = HoldingType;
  InvestmentType = InvestmentType;
  AreaUnit = AreaUnit;

  // Enum value lists
  assetTypesList = HoldingType.listAll();
  InvestmentTypesList = InvestmentType.listAll();

  allCountries = this.resourceService.allCountries;
  allStates = this.resourceService.allStates;
  countryCodes = TerraUtils.consts.countryCode;
  USCountryId = this.resourceService.getCountryByCode(this.countryCodes.US).id;

  selectedInvestmentType = this.createFundAssetService.selectedInvestmentType;

  allowedDataPointsListFor = TerraUtils.consts.allowedDataPointsListFor;

  closingDateStartAt = new Date();
  closingDateMinimumDate = new Date('1970-01-01T12:00');

  maturityDateStartAt = new Date();
  maturityDateMinimumDate = new Date('1970-01-01T12:00');

  addressFormSettings = new AddressFormSettings(undefined, AddressFields.Country);
  location = this.createFundAssetService.createAssetForm.value.step1.assetLocation;

  get addressForm() {
    return this.pageForm.get('assetLocation') as UntypedFormGroup;
  }

  constructor(
    private resourceService: ResourceService,
    private createFundAssetService: CreateFundAssetService
  ) {
    super(createFundAssetService);
  }

  ngOnInit() {
    this.isSubmitted = false;
    this.createFundAssetService.currentStep$.next(this.STEP_NUMBER);
  }

  isDisplayDataPoint(assetTypeList: HoldingType[]): boolean {
    if (!this.pageForm.get('holdingType').value) {
      return false;
    }
    const holdingType = Number(this.pageForm.get('holdingType').value) as HoldingType;
    return assetTypeList.some(val => val === holdingType);
  }

  conutryTrackByFn(index, item: CountryModel) {
    return item.id; // or index
  }

  currencyCompareFn(c1: CurrencyModel, c2: CurrencyModel) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  cancel() {
    this.createFundAssetService.cancel();
  }
}



