import { HostBinding, Directive } from '@angular/core';
import { StartInitialFundraisigStepNumber, StartInitialFundraisingService } from './start-initial-fundraising.service';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

export interface IStartInitialFundraisingStepBase {
  readonly STEP_NUMBER: StartInitialFundraisigStepNumber;
}

@Directive()
export abstract class StartInitialFundraisingStepBase  extends OnDestroyMixin implements IStartInitialFundraisingStepBase {

  @HostBinding('attr.class') hostClasses = 'wizard-step';

  abstract STEP_NUMBER: StartInitialFundraisigStepNumber;
  readonly LAST_STEP_NUMBER = 2 as StartInitialFundraisigStepNumber;

  isSubmitAttempt = false;

  private _createFundAssetService: StartInitialFundraisingService;

  constructor(createFundAssetService: StartInitialFundraisingService) {
    super();
    this._createFundAssetService = createFundAssetService;
  }

  get pageForm() {
    return this._createFundAssetService.getStepForm(this.STEP_NUMBER);
  }

  get isSubmitted() {
    return this._createFundAssetService.isStepSubmitted(this.STEP_NUMBER);
  }
  set isSubmitted(val: boolean) {
    this._createFundAssetService.setStepAsSubmitted(this.STEP_NUMBER, val);
  }

  back() {
    if (this.STEP_NUMBER > 1) {
      this._createFundAssetService.currentStep$.next(this.STEP_NUMBER - 1 as StartInitialFundraisigStepNumber);
    }
  }

  stepSubmit() {
    this.isSubmitAttempt = true;

    this.pageForm.updateValueAndValidity();
    if (this.pageForm.valid) {
      this._createFundAssetService.isGeneralServerError = false;
      this.isSubmitted = true;
      // If not the last step - go to the next step
      if (this.STEP_NUMBER < this.LAST_STEP_NUMBER) {
        this._createFundAssetService.currentStep$.next((this.STEP_NUMBER + 1) as StartInitialFundraisigStepNumber);
      } else {
        // If submitting the last step, call the create asset function
        this._createFundAssetService.createFundraising();
      }
    }
  }

}
