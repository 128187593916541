import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';
import {Router} from '@angular/router';

import {TerraUtils} from '../../../../../../shared/TerraUtils';
import DistributionTransactionPurpose from '../../../../../../shared/enums/DistributionTransactionPurpose.enum';
import {TerraNumberPipe} from '../../../../../../shared/pipes/TerraNumber.pipe';
import {DistributionTransferDetailsReqRes} from '../../DistributionTransferDetailsReqRes.model';
import {merge, Subject} from 'rxjs';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {DistributionTransferReqRes} from '../../DistributionTransferReqRes.model';
import DistributionTransferType from '../../../../../../shared/enums/DistributionTransferType.enum';

@Component({
  selector: 'terra-distribution-transfer-amounts',
  templateUrl: './distribution-transfer-amounts.component.html',
  styleUrls: ['./distribution-transfer-amounts.component.scss'],
})
export class DistributionTransferAmountsComponent extends OnDestroyMixin implements OnInit, OnChanges {
  @Input() transactionPurpose: DistributionTransactionPurpose;
  @Input() distributionCurrencyIso: string;
  @Input() distributionCurrencySymbol: string;
  @Input() disableEditMode: boolean = false;
  @Input() isViewMode: boolean;
  @Input() distributionTransferDetails: DistributionTransferDetailsReqRes;
  @Input() distributionTransfer: DistributionTransferReqRes;
  @Input() importedDistribution: boolean;
  @Input() investingEntityId: number;
  @Input() updateFormFromModel: Subject<any>;
  @Input() updateGpPromote: Subject<any>;
  @Input() waterfallCalculated: Subject<any>;
  // @Input() isThisGPDistributeTransfer: boolean;
  @Input() revertAllAmountChanges: EventEmitter<void>;
  @Input() disableGrossAmount = false;

  @Output() amountChange = new EventEmitter();

  pageForm: UntypedFormGroup;
  isPercents: boolean;
  taxesSummary: number;
  isEditMode: boolean;

  // enums
  DistributionTransactionPurpose = DistributionTransactionPurpose;
  private initialFormValue: any;

  constructor(private fb: UntypedFormBuilder,
              private terraNumberPipe: TerraNumberPipe,
              private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.isEditMode = this.router.url.includes('/edit');
    this.initForm();
    this.reflectFormValuesToTransferDetails();
    this.subscribeToModelUpdate();
    if (this.isViewMode || (this.disableEditMode && !this.distributionTransfer.externalPayment)) {
      this.setForEditDistributionMode();
    } else { // when in Create/Edit state:
      this.switchTaxPercents(false);
    }
    this.handleEmitSignalToUpdateFeesCalculationWhenNeeded();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.distributionTransferDetails && !!this.pageForm) {
      const currentValue = changes.distributionTransferDetails.currentValue as DistributionTransferDetailsReqRes;
      this.pageForm.get('amountGross').setValue(currentValue.amountGross);
      this.pageForm.get('amountNet').setValue(currentValue.amountNet);
      if (!this.isThisGPDistributeTransfer) {
        this.pageForm.get('gpPromote').setValue(currentValue.gpPromote);
      }

      this.amountChange.emit();
    }

    if (changes.disableGrossAmount && !!this.pageForm) {
      const currentValue = changes.disableGrossAmount.currentValue as boolean;

      if (currentValue) {
        this.pageForm.get('amountGross').disable();
        this.pageForm.get('gpPromote').disable();
      } else {
        this.pageForm.get('amountGross').enable();
        this.pageForm.get('gpPromote').enable();
      }
    }
  }

  get isThisGPDistributeTransfer(): boolean {
    return this.distributionTransfer?.type === DistributionTransferType.GP;
  }

  private initForm() {
    this.pageForm = this.fb.group({
      amountGross: [{value: this.distributionTransferDetails.amountGross, disabled: this.disableGrossAmount}],
      amountNet: this.distributionTransferDetails.amountNet,
      amountAfterTaxes: this.distributionTransferDetails.amountAfterTaxes,
      taxes: this.distributionTransferDetails.taxes,
      gpPromote: [{value: this.isThisGPDistributeTransfer ? 0 : this.distributionTransferDetails.gpPromote, disabled: this.disableGrossAmount}],
      amountAfterPromote: this.isThisGPDistributeTransfer ? 0 : this.distributionTransferDetails.amountAfterGpPromote,
      adjustments: this.distributionTransferDetails.adjustments,
      adjustmentsComments: [this.distributionTransferDetails.adjustmentsComments, Validators.maxLength(TerraUtils.consts.validators.SHORT_TEXT_LENGTH)]
    });

    this.initialFormValue = this.pageForm.getRawValue();

    if (this.revertAllAmountChanges) {
      this.revertAllAmountChanges.pipe(
        untilComponentDestroyed(this)
      ).subscribe(() => {
        if (this.pageForm) {
          this.pageForm.reset(this.initialFormValue);
        }
      });
    }
  }

  /**
   * Calculate tax based on user's input
   */
  private recalculateTaxes(calculatedSummary: number = null) {
    const values = this.pageForm.getRawValue();
    let calcResult = {
      amountNet: null,
      amountAfterTaxes: null,
      amountAfterPromote: null,
      taxes: null
    };
    let currentAdjustment: number;
    let taxesAmount: number;
    let currentGpPromote: number;

    if (calculatedSummary) {
      values.taxes = calculatedSummary;
    }

    const taxesHaveDecimalPoint = values.taxes?.toString().endsWith('.');
    let tempTaxes = this.terraNumberPipe.parse(values.taxes);
    if (tempTaxes == null || Number.isNaN(tempTaxes) || tempTaxes <= 0) {
      tempTaxes = 0;
    }

    values.amountGross = this.terraNumberPipe.parse(values.amountGross);
    if (values.amountGross || values.gpPromote) {
      currentAdjustment = this.terraNumberPipe.parse(values.adjustments) || 0;
      currentGpPromote = !values.gpPromote && this.pageForm.controls.gpPromote.dirty ? 0 : this.terraNumberPipe.parse(values.gpPromote);

      if (!!tempTaxes) {
        if (values.taxes && this.isPercents) {
          if (tempTaxes > 100) {
            tempTaxes = 100;
          }
          taxesAmount = +(values.amountGross * tempTaxes / 100).toFixed(2);
        } else if (tempTaxes > values.amountGross) {
          taxesAmount = tempTaxes = values.amountGross;
        } else {
          taxesAmount = tempTaxes;
        }
      } else {
        taxesAmount = tempTaxes = 0;
      }

      calcResult.amountAfterTaxes = +(values.amountGross - +taxesAmount).toFixed(2);
      calcResult.amountAfterPromote = +(calcResult.amountAfterTaxes - currentGpPromote).toFixed(2);
      calcResult.amountNet = +(calcResult.amountAfterTaxes + currentAdjustment - currentGpPromote).toFixed(2);
    } else {
      calcResult.amountAfterTaxes = 0;
      calcResult.amountNet = 0;
      currentAdjustment = 0;
      calcResult.amountAfterPromote = 0;
      taxesAmount = 0;
    }

    // if (values.taxes !== tempTaxes) {
    // calcResult.taxes = (tempTaxes || null);
    // }
    if (calcResult.amountAfterTaxes < 0) {
      calcResult.amountAfterTaxes = 0;
    }
    if (calcResult.amountNet < 0) {
      calcResult.amountNet = 0;
    }
    if (calcResult.amountAfterPromote < 0) {
      calcResult.amountAfterPromote = 0;
    }
    this.taxesSummary = this.isPercents ? taxesAmount : values.amountGross && +(taxesAmount / values.amountGross * 100).toFixed(2);
    if (this.pageForm.dirty) {
      this.distributionTransfer.isFormDirty = true;
    }

    this.distributionTransferDetails.amountNet = calcResult.amountNet;
    this.distributionTransferDetails.amountGross = values.amountGross;
    this.distributionTransferDetails.amountAfterTaxes = calcResult.amountAfterTaxes;
    this.distributionTransferDetails.taxes = taxesAmount;
    this.distributionTransferDetails.adjustments = currentAdjustment;
    this.distributionTransferDetails.gpPromote = currentGpPromote;
    this.distributionTransferDetails.amountAfterGpPromote = calcResult.amountAfterPromote;
    this.distributionTransferDetails.adjustmentsComments = currentAdjustment ? values.adjustmentsComments : null;

    calcResult = {
      amountNet: this.terraNumberPipe.transform(calcResult.amountNet),
      amountAfterTaxes: this.terraNumberPipe.transform(calcResult.amountAfterTaxes),
      amountAfterPromote: this.terraNumberPipe.transform(calcResult.amountAfterPromote),
      taxes: (tempTaxes + (taxesHaveDecimalPoint ? '.' : '') || null)
    };
    this.pageForm.patchValue(calcResult, {emitEvent: false});
    this.amountChange.emit();
  }

  /**
   * Updates transfer details object from form values
   */
  private reflectFormValuesToTransferDetails() {
    this.pageForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        const formRawValues = this.pageForm.getRawValue();
        this.distributionTransferDetails.adjustments = formRawValues.adjustments;
        this.distributionTransferDetails.gpPromote = formRawValues.gpPromote;
        this.distributionTransferDetails.adjustmentsComments = formRawValues.adjustmentsComments;
        this.distributionTransferDetails.amountGross = formRawValues.amountGross;
        this.recalculateTaxes();
      });
  }

  /**
   * Switches tax calculation from percents to number and vice versa
   * @param isPercents is percents selected
   */
  switchTaxPercents(isPercents: boolean) {
    this.isPercents = isPercents;
    this.recalculateTaxes(this.taxesSummary);
  }

  /**
   * Disable form controls if in edit mode
   */
  private setForEditDistributionMode() {
    // In edit mode, the amount and ViaCovercy toggle are disabled
    this.pageForm.get('amountGross').disable();
    this.pageForm.get('taxes').disable();
    this.pageForm.get('gpPromote').disable();
    this.pageForm.get('adjustments').disable();
    this.pageForm.get('adjustmentsComments').disable();
    // bank account is completely hidden when it should not be available for change. logic is in the template.
  }

  /**
   * Emit event when need to recalculate fees
   */
  private handleEmitSignalToUpdateFeesCalculationWhenNeeded() {
    // Emit this event to signal that something that affects the fees calculation has changed.
    merge(
      this.pageForm.get('amountGross').valueChanges,
      this.pageForm.get('taxes').valueChanges,
      this.pageForm.get('gpPromote').valueChanges,
      this.pageForm.get('adjustments').valueChanges)
      .pipe(
        untilComponentDestroyed(this)
      ).subscribe(() => {
      this.amountChange.emit();
    });
  }

  private subscribeToModelUpdate() {
    this.updateFormFromModel?.pipe(untilComponentDestroyed(this)).subscribe(_ => {
      this.pageForm.get('amountGross').setValue(this.distributionTransferDetails.amountGross, {emitEvent: false});
      this.pageForm.get('amountNet').setValue(this.distributionTransferDetails.amountNet, {emitEvent: false});
      this.pageForm.get('taxes').setValue(this.distributionTransferDetails.taxes, {emitEvent: false});
      this.pageForm.get('amountAfterTaxes').setValue(this.distributionTransferDetails.amountAfterTaxes, {emitEvent: false});
      if (!this.isThisGPDistributeTransfer) {
        this.pageForm.get('gpPromote').setValue(this.distributionTransferDetails.gpPromote, {emitEvent: false});
        this.pageForm.get('amountAfterPromote').setValue(this.distributionTransferDetails.amountAfterGpPromote, {emitEvent: false});
      }
    });

    if (this.isThisGPDistributeTransfer) {
      this.updateGpPromote?.pipe(untilComponentDestroyed(this)).subscribe(_ => {
        this.pageForm.get('amountGross').setValue(this.distributionTransferDetails.amountGross, {emitEvent: false});
        this.pageForm.get('amountNet').setValue(this.distributionTransferDetails.amountNet, {emitEvent: false});
        this.pageForm.get('amountAfterTaxes').setValue(this.distributionTransferDetails.amountAfterTaxes, {emitEvent: false});
      });
    }

    this.waterfallCalculated?.pipe(
      untilComponentDestroyed(this)
    ).subscribe(_ => {
      this.isPercents = false;
    });
  }
}
