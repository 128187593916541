<div class="example-block example-strip">
  <span class="title">
    Add or manage funds
  </span>
  <p>
    Funds can invest in other assets, and even in other funds.<br>
    Manage more complex investment structures and let your investors access both fund and asset-level data.
  </p>
  <img class="fund-feature-premium" src="../../assets/images/fund-premium.png" alt="Fund Feature - premium" height="600">
</div>

<p class="upsell-text">
  <mat-icon class="notranslate">lock</mat-icon>
  <span>Interested in our premium packages? Please
    <a target="_blank" href="https://www.covercy.com/contact-us" class="contact-us-link link"> contact us</a>
  </span>
</p>
