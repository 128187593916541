import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    FormlyModule,
    MatMenuModule,
  ],
  styles: [`
    :host {
      display: block;
      width: 100%;
      --mat-form-field-container-height: 40px;
      --mat-form-field-container-vertical-padding: 8px;
      --mdc-outlined-button-container-height: 40px;
      --mat-outlined-button-horizontal-padding: 8px;
      --mdc-outlined-button-outline-color: #E5E9EB;
      --mat-outlined-button-touch-target-display: none;

      ::ng-deep .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 8px;
        --mdc-icon-button-state-layer-size: 40px;
      }
      ::ng-deep .mat-mdc-icon-button .mat-mdc-button-touch-target {
        height: 40px;
        width: 40px;
      }
     
    }
    .date-ranges {
      min-width: 40px;
      --mat-outlined-button-icon-offset: 0;
      --mat-outlined-button-icon-spacing: 0;
    }
    ::ng-deep .selected.mat-mdc-menu-item{
      --mat-menu-item-label-text-color: #F79009;
    }
    .date-periods {
      width: 45%;
    }
    .date-custom {
      width: 55%;
    }
    .m-auto {
      margin: auto;
    }
  `],
  template: `
  <div class="full-width flex flex-column">
    <label class="mb-1">{{ props.label }}</label>
    <div class="flex flex-row gap-1">
    <mat-form-field color="primary" class="date-periods" appearance="outline" style="width:45%;">
        <mat-select [formControl]="preset" [formlyAttributes]="field" placeholder="Date periods">
            @for(opt of props.presets; track opt) {
              <mat-option [value]="opt">{{ opt }}</mat-option>
            }
        </mat-select>
      </mat-form-field>
    <mat-form-field appearance="outline" color="primary" class="date-custom" style="width:55%;" >
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker" class="m-auto" [min]="props.minDate"
      [max]="props.maxDate">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker" />
      <mat-date-range-picker #picker color="primary" panelClass="rm-theme">
      </mat-date-range-picker>
    </mat-form-field>
    </div>
            
    <mat-menu #menu="matMenu">
      @for(preset of props.presets; track preset){
        <button mat-menu-item 
        (click)="selectRange(preset)"
        >
          <span>{{preset}}</span>
        </button>
      }
    </mat-menu>
    </div>
  `,
})
export class DateRangeSelectTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  dateAdapter = inject(DateAdapter);

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  preset = new FormControl();

  isSelectdeRange(rangeName: string) {
    const existingRange = this.calculateDateRange(rangeName);
    if (!existingRange) {
      return false;
    }
    const [start, end] = existingRange;
    const selectedRange: any = this.range?.value;

    return (
      selectedRange?.start !== null &&
      selectedRange?.end !== null &&
      new Date(String(selectedRange?.start)).toDateString() ===
        new Date(String(start)).toDateString() &&
      new Date(String(selectedRange?.end)).toDateString() ===
        new Date(String(end)).toDateString()
    );
  }

  selectRange(rangeName: string) {
    const [start, end] = this.calculateDateRange(rangeName);
    this.range.setValue({ start, end });
  }

  private get today() {
    const today = this.dateAdapter.today();
    if (today === null) {
      throw new Error('date creation failed');
    }
    return today;
  }


  private calculateDateRange(rangeName: string) {
    const today = this.dateAdapter.today();
    const currentMonth = this.dateAdapter.getMonth(today);
    const currentQuarter = Math.floor(currentMonth / 3) + 1;

    let startDate: Date;
    let endDate: Date;

    switch (rangeName) {
      case 'This Month': {
        [startDate, endDate] = this.calculateMonth(today);
        break;
      }
      case 'Previous Month': {
        [startDate, endDate] = this.calculateMonth(
          this.dateAdapter.addCalendarMonths(today, -1)
        );
        break;
      }
      case 'Next Month': {
        [startDate, endDate] = this.calculateMonth(
          this.dateAdapter.addCalendarMonths(today, 1)
        );
        break;
      }
      case 'This Quarter': {
        [startDate, endDate] = this.calculateQuarter(today, currentQuarter);
        break;
      }
      case 'Previous Quarter': {
        [startDate, endDate] = this.calculateQuarter(
          today,
          currentQuarter - 1
        );
        break;
      }
      case 'Next Quarter': {
        [startDate, endDate] = this.calculateQuarter(
          today,
          currentQuarter + 1
        );
        break;
      }
      case 'This Year': {
        [startDate, endDate] = this.calculateYear(today);
        break;
      }
      case 'Previous Year': {
        [startDate, endDate] = this.calculateYear(
          this.dateAdapter.addCalendarYears(today, -1)
        );
        break;
      }
      case 'Next Year': {
        [startDate, endDate] = this.calculateYear(
          this.dateAdapter.addCalendarYears(today, 1)
        );
        break;
      }
      case 'Previous 12 Months': {
        const beginingOfMonth = this.dateAdapter.createDate(
          this.dateAdapter.getYear(today),
          this.dateAdapter.getMonth(today),
          1
        );

        startDate = this.dateAdapter.addCalendarMonths(beginingOfMonth, -12);
        endDate = this.dateAdapter.addCalendarDays(beginingOfMonth, -1);
        break;
      }
      case 'Next 12 Months': {
        const year = this.dateAdapter.getYear(today);
        const month = this.dateAdapter.getMonth(today);
        const begining = this.dateAdapter.createDate( year, month, 1);
        
        startDate = this.dateAdapter.addCalendarMonths(begining, 1)
        endDate = this.dateAdapter.addCalendarDays(
          this.dateAdapter.createDate(year+1, month, 1),
          this.dateAdapter.getNumDaysInMonth(today) - 1
        );
        break;
      }
      case 'Since GL Start Date': {
         startDate = this.dateAdapter.createDate(1900, 11, 31);
         endDate =  this.dateAdapter.today();
         break;
      }

      case 'Custom': {
         startDate = null;
         endDate = null;
         break;
      }

      default: { 
        const selectedRange: any = this.range.value;
        startDate = selectedRange?.start
        endDate = selectedRange?.end;
      }
    }
  return [startDate, endDate];
}


  private calculateMonth(forDay) {
    const year = this.dateAdapter.getYear(forDay);
    const month = this.dateAdapter.getMonth(forDay);
    const start = this.dateAdapter.createDate(year, month, 1);
    const end = this.dateAdapter.addCalendarDays(
      start,
      this.dateAdapter.getNumDaysInMonth(forDay) - 1
    );
    return [start, end];
  }

  private calculateQuarter(today: Date, quarter: number): [Date, Date] {
    let year = this.dateAdapter.getYear(today);
  
    if (quarter < 1) {
      quarter = 4;
      year -= 1;
    } else if (quarter > 4) {
      quarter = 1;
      year += 1;
    }
  
    const startMonth = (quarter - 1) * 3;
    const startDate = this.dateAdapter.createDate(year, startMonth, 1);
    const endDate = this.dateAdapter.addCalendarDays(
      this.dateAdapter.addCalendarMonths(startDate, 3),
      -1
    );
  
    return [startDate, endDate];
  }
  
  private calculateYear(today: Date, offset: number = 0): [Date, Date] {
    const year = this.dateAdapter.getYear(today) + offset;
  
    const startDate = this.dateAdapter.createDate(year, 0, 1);
    const endDate = this.dateAdapter.createDate(year, 11, 31);
  
    return [startDate, endDate];
  }
  
  private calculate12Months(today: Date, direction: 'previous' | 'next'): [Date, Date] {
    const offset = direction === 'previous' ? -1 : 1;
    const startDate = this.dateAdapter.addCalendarDays(today, offset);
    const endDate = this.dateAdapter.addCalendarMonths(today, 12 * offset);
  
    return direction === 'previous' ? [endDate, startDate] : [startDate, endDate];
  }


  ngOnInit() {
    this.range.valueChanges.subscribe((value) => {
      const isPresetSelected = this.props.presets.find((preset) => this.isSelectdeRange(preset));
      this.preset.setValue(isPresetSelected ?? 'Custom', { emitEvent: false });

      if (value.start && value.end) {
        this.formControl.setValue(value);
      } else {
        this.formControl.setValue(null);
      }
    });

    this.preset.valueChanges.subscribe((value) => {
      if (value) {
        this.selectRange(value);
      }
    });

    if(this.formControl.value){
      this.range.setValue(this.formControl.value);
    }

  }
}
