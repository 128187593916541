import DistributionTransferType from 'src/app/shared/enums/DistributionTransferType.enum';
import {ClientBankAccountReqRes} from 'src/app/dashboard/models/bankAccount.model';
import {ActivityTrackingOrderResponse} from 'src/app/models/ActivityTrackingOrderResponse.model';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {InvestingEntityReqRes} from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import {DistributionTransferDetailsReqRes} from './DistributionTransferDetailsReqRes.model';
import {UnitTransactionReqRes} from '../../../../shared/models/gp/UnitTransactionReqRes.model';
import TransactionType from '../../../../shared/enums/TransactionType.enum';
import {UnitBankAccountReqRes} from 'src/app/shared/models/gp/UnitBankAccountReqRes.model';

export class DistributionTransferReqRes {

  // front end only property:
  public isRemoved = false;
  public isBankAccountChangeUnsaved = false;
  public isFormDirty = false;
  public invalidForm: boolean;
  public orderId: number;
  public order: ActivityTrackingOrderResponse;
  public currency: CurrencyModel;
  public bankAccountDetailsApprovedByGp: boolean;
  public tempExchangeRate: number;
  public previuosClientBankAccountId: number;

  constructor(
    public id: number = null,
    public externalPayment: boolean,
    public investingEntityId: number,
    public investingEntity: InvestingEntityReqRes,
    public type: DistributionTransferType,
    public clientBankAccountId: number,
    public clientBankAccount: ClientBankAccountReqRes,
    public adjustmentsComments: string,
    public paymentSettlementDate: Date = null,
    public distributionTransfersDetails: DistributionTransferDetailsReqRes[],
    public referenceMemo: string,
    public unitBankAccountId: number = null,
    public unitBankAccount: UnitBankAccountReqRes,
    public amountGross: number = null,
    public amountNet: number = null,
    public taxes: number = null,
    public amountAfterTaxes: number = null,
    public gpPromote: number = null,
    public amountAfterGpPromote: number = null,
    public adjustments: number = null
  ) {
  }

  public unitTransaction: UnitTransactionReqRes;
  public unitTransactionFee: number;
  public transactionType: TransactionType;
}
