import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, take, skipUntil, skipWhile} from 'rxjs/operators';

import {UserService} from '../services/shared/user.service';
import {RoutingService} from '../services/routing.service';

@Injectable({
  providedIn: 'root'
})
export class RootNavigationGuard  {
  constructor(
    private userService: UserService,
    private router: Router,
    private routingService: RoutingService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean |
    UrlTree |
    Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree> {

    const userIsLoggedIn = this.userService.isLoggedIn();
    if (!userIsLoggedIn) {
      // if the user is not logged in, we can tell to which Login page to redirect him by the 'subDomain' appSettings value.
      return of(this.routingService.getLoginUrlTree());
    }
    return this.userService.accountDetails$.pipe(
      skipWhile(clientDetails => clientDetails === null),
      take(1),
      map(clientDetails => {
        return this.router.parseUrl(clientDetails.isAgent ?
          this.routingService.defaultGpPage : this.routingService.lpHomePage);
      })
    );
  }
}
