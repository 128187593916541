import { Component, OnInit } from '@angular/core';

import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import FundInvestmentStrategyType from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import { ResourceService } from 'src/app/services/resource.service';
import { CountryModel } from 'src/app/shared/models/CountryModel';
import { GpFundService } from '../gp-fund.service';
import { FundUnderManagementTabs } from 'src/app/shared/types/GpTypes';
import { map } from 'rxjs/operators';

export enum FundUnderManagementTab {
  Overview = 0,
  Portfolio = 1,
  Reports = 2,
  Documents = 3,
  Fundraisings = 4,
  Distributions = 5,
  Transactions = 6
}

@Component({
  selector: 'terra-fund-management-status',
  templateUrl: './fund-management-status.component.html',
  styleUrls: ['./fund-management-status.component.scss']
})
export class FundManagementStatusComponent implements OnInit {
  tabsData: { label: string, url: FundUnderManagementTabs, index: FundUnderManagementTab }[] =
    [
      { label: 'Overview', url: 'overview', index: FundUnderManagementTab.Overview },
      { label: 'Portfolio', url: 'portfolio', index: FundUnderManagementTab.Portfolio },
      { label: 'Reports', url: 'reports', index: FundUnderManagementTab.Reports },
      { label: 'Documents', url: 'documents', index: FundUnderManagementTab.Documents },
      { label: 'Contributions', url: 'contributions', index: FundUnderManagementTab.Fundraisings },
      { label: 'Distributions', url: 'distributions', index: FundUnderManagementTab.Distributions },
      { label: 'Transactions', url: 'transactions', index: FundUnderManagementTab.Transactions }
    ];

  // Enum:
  Tab = FundUnderManagementTab;

  fund$ = this.gpFundService.holding$.pipe(
    map(h => {
      h.assets = h.assets.filter(a => a.status != HoldingStatus.Canceled)
      return h;
    })
  );

  fundraising$ = this.gpFundService.fundraising$;
  creTransactionsTabVisible$ = this.gpFundService.creTransactionsTabVisible$;
  // Enums (used in the template)
  HoldingStatus = HoldingStatus;
  HoldingType = HoldingType;
  FundInvestmentStrategyType = FundInvestmentStrategyType;

  constructor(
    private resourceService: ResourceService,
    private gpFundService: GpFundService) { }

  ngOnInit() { }

  getCountryById(countryId: number): CountryModel {
    return this.resourceService.getCountryById(countryId);
  }
}
