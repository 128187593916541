import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map, shareReplay, switchMap, take} from 'rxjs/operators';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {NoopScrollStrategy} from '@angular/cdk/overlay';
import moment from 'moment';

import {GpFundReportService} from '../gp-fund-report.service';
import {GpFundService} from '../../../../gp-fund.service';
import {MediaType, MetaFileLink} from 'src/app/models/metaFileLink.model';
import {LocationDetailsResponse} from 'src/app/shared/models/LocationDetailsResponse.model';
import {UserService} from 'src/app/services/shared/user.service';
import {HoldingType} from 'src/app/shared/enums/HoldingType.enum';
import FundInvestmentStrategyType from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import {DialogService} from 'src/app/services/dialog.service';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ReportFileReqRes} from 'src/app/shared/models/ReportFileReqRes.model';
import ReportFileType from 'src/app/shared/enums/ReportFileType.enum';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';
import {ResourceService} from 'src/app/services/resource.service';
import {UtilsService} from 'src/app/services/utils.service';
import {ReportFAQReqRes} from 'src/app/shared/models/reportFAQReqRes.model';
import {ReportFaqsDialogComponent} from 'src/app/lp/shared/holdings/report-faqs-dialog/report-faqs-dialog.component';
import {InfoDialogComponent, InfoDialogModel} from 'src/app/lp/shared/info-dialog/info-dialog.component';
import ReportPeriod from 'src/app/shared/enums/ReportPeriod.enum';
import {ErrorData, ErrorMatcher, ErrorType} from 'src/app/shared/errors/ErrorMatcher';
import {GpFundReqRes} from 'src/app/dashboard/funds/GpFundReqRes.model';
import {GpDetailsReqRes} from 'src/app/lp/models/GpDetailsReqRes.model';
import {LpFundReqRes} from 'src/app/lp/funds/LpFundReqRes.model';
import {FundReportReqRes} from 'src/app/shared/models/FundReportReqRes.model';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {LpHoldingPositionRes} from 'src/app/lp/models/LpHoldingPositionRes.model';
import {DynamicComponentItem} from 'src/app/shared/models/dynamic-component-loader/DynamicComponentItem.model';
import {CheckboxComponent} from 'src/app/shared/components/dynamic-component-loader/checkbox.component';
import {BaseResponseDto} from '../../../../../../../shared/models/BaseResponseDto.model';
import {HoldingDiscriminator} from 'src/app/shared/enums/HoldingDiscriminator.enum';

@Component({
  selector: 'terra-fund-report-review',
  templateUrl: './fund-report-review.component.html',
  styleUrls: ['./fund-report-review.component.scss']
})
export class FundReportReviewComponent implements OnInit, OnDestroy {
  HoldingDiscriminator = HoldingDiscriminator;
  isEditMode: boolean;

  fund$ = this.gpFundService.holding$;
  fundraising$ = this.gpFundService.fundraising$;
  finalClosingDate$ = this.gpFundService.fundraising$.pipe(map(fundraising => fundraising ? fundraising.finalClosingDate : null));
  companyName$ = this.userService.accountDetails$.pipe(map(agent => agent.organizationDetails.name), shareReplay(1));

  // Enum
  HoldingType = HoldingType;
  FundInvestmentStrategyType = FundInvestmentStrategyType;

  cumulativeInfo = this.gpFundReportService.formStep2.value;
  reportInformation = this.gpFundReportService.formStep1.value;
  pseudoReportList: any[] = [this.reportInformation];

  faQs$ = this.gpFundReportService.reportDetails$.pipe(map(report => report ? report.faQs : null));

  // Fund photo urls
  photoUrls$: Observable<string[]> = this.fund$.pipe(
    map(fund => fund.attachments.filter(att => att.fileType === HoldingFileType.Photo).map(att => att.metaFileLink)),
    map(allAttachments => {
      const photoUrls = allAttachments.map(metaFileLink => metaFileLink.url);
      if (!photoUrls || photoUrls.length === 0) {
        photoUrls.push('assets/images/photo-carousel-empty.png');
      }
      return photoUrls;
    }));

  isAlreadyPublished$ = this.gpFundReportService.isAlreadyPublished$;

  lpFundAsMappedFromGpFund$ = combineLatest([this.fund$, this.finalClosingDate$]).pipe(
    map(([fund, finalClosingDate]) =>
      this.mapGpFundToLpFund(fund, finalClosingDate)),
    shareReplay(1));

  lpFundReportSection$ = combineLatest([this.faQs$, this.fund$]).pipe(
    map(([faQs, fund]) => this.getLpFundReport(faQs, fund.investmentStrategyType)),
    shareReplay(1));

  isGeneralServerError = this.gpFundReportService.isGeneralServerError;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;

  // Preview Wrapper element ref
  @ViewChild('previewWrapper') previewWrapperElementRef;

  infoDialogRef: MatDialogRef<any, any> = null;

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (this.infoDialogRef) {
      const wrapperPosition = this.previewWrapperElementRef.nativeElement.getBoundingClientRect();
      this.infoDialogRef.updatePosition({left: `${wrapperPosition.left}px`, top: `${wrapperPosition.top}px`});
    }
  }

  constructor(
    private gpFundReportService: GpFundReportService,
    private gpFundService: GpFundService,
    private matDialog: MatDialog,
    private userService: UserService,
    private resourceService: ResourceService,
    private dialogService: DialogService,
    private utilsService: UtilsService,
    private telemetryService: TelemetryService
  ) {
  }

  ngOnInit() {
  }

  // without publish
  save() {
    if (!this.gpFundReportService.reportForm.valid) {
      console.log('Report form is invalid.', this.gpFundReportService.reportForm);
      return;
    }

    this.gpFundReportService.processingSubmit$.next(true);
    this.gpFundReportService.isGeneralServerError = false;

    this.gpFundReportService
      .saveOnly()
      .pipe(switchMap(response => this.gpFundReportService.fundId$))
      .subscribe(
        fundId => {
          this.gpFundReportService.navigateToFundPage(fundId);
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else if (this.isErrorReportFormat(error)) {
            this.gpFundReportService.isReportDocumentFileNotSupported = true;
            this.gpFundReportService.currentStep$.next(1);
          } else {
            this.gpFundReportService.isGeneralServerError = true;
          }
          this.gpFundReportService.processingSubmit$.next(false);
        }
      );
  }

  isErrorReportFormat(error: any) {
    return ErrorMatcher.isError(error, ErrorType.BadFileFormatException) && (error.error as ErrorData).data === ReportFileType.ReportDocument.toString();
  }

  saveAndPublish() {
    if (!this.gpFundReportService.reportForm.valid) {
      console.log('Report form is invalid.', this.gpFundReportService.reportForm);
      return;
    }
    let includeSummaryToReportVal = true;
    const includeSummaryToReportComponent = new DynamicComponentItem(
      CheckboxComponent,
      {input: 'Add summary to email body', checked: true, disabled: false, output: (val) => includeSummaryToReportVal = val}
    );

    this.gpFundReportService.isGeneralServerError = false;
    this.gpFundReportService.reportId$
      .pipe(
        switchMap(reportId => {
          // Create report mode:
          if (reportId <= 0) {
            // simulate confirmation of publish without showing th confirm dialog
            this.isEditMode = false;
            // return of(true);
          } else {
            this.isEditMode = true;
          }
          return this.dialogService
            .confirmDialog(
              new ConfirmDialogParams(
                'Ready to update your investors?',
                'Would you like to notify the investors about the changes made to this report via email, or only publish in the investor portal?',
                'Publish & send email', '', '', 'publish',
                'Publish only', 'accent', includeSummaryToReportComponent
              )
            ).afterClosed();
        }),
        switchMap(
          (isConfirmed): Observable<boolean> => {
            if (isConfirmed) {
              this.gpFundReportService.processingSubmit$.next(true);
              // will return true if published
              return this.gpFundReportService.saveAndPublish(!isConfirmed.secondaryAction, includeSummaryToReportVal);
            } else {
              return of(false);
            }
          }
        ),
        switchMap(isPublished => {
          // if the user didn't confirm, use 0 as a flag for the next operation
          if (!isPublished) {
            return of(0);
          } else {
            return this.gpFundReportService.fundId$;
          }
        })
      )
      .subscribe(
        fundId => {
          this.gpFundReportService.processingSubmit$.next(false);
          // if the fundId is 0, it means the user canceled the confirmation dialog, and we don't want to navigate
          if (fundId) {
            this.addTelemetryEvent(fundId);
            this.gpFundReportService.navigateToFundPage(fundId);
          }
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else if (this.isErrorReportFormat(error)) {
            this.gpFundReportService.isReportDocumentFileNotSupported = true;
            this.gpFundReportService.currentStep$.next(1);
          } else {
            this.gpFundReportService.isGeneralServerError = true;
          }
          this.gpFundReportService.processingSubmit$.next(false);
        }
      );
  }

  back() {
    this.gpFundReportService.currentStep$.next(2);
  }

  getFormattedAddress(location: LocationDetailsResponse) {
    if (!location) {
      return '';
    }
    let formattedString = '';
    if (location.cityName) {
      formattedString += location.cityName;
      if (location.stateName || location.postalCode) {
        formattedString += ', ';
      }
    }
    if (location.stateName) {
      formattedString += location.stateName;
    }
    if (location.postalCode) {
      formattedString += ' ' + location.postalCode;
    }
    const country = this.resourceService.getCountryById(location.countryId);

    if (country && country.code) {
      if (!formattedString) {
        formattedString += country.name;
      } else {
        formattedString += ', ' + country.code;
      }
    }

    return formattedString;
  }

  getReportName(reportInformation: any) {
    if (reportInformation.reportPeriod !== ReportPeriod.Custom) {
      const dates = TerraUtils.getStartAndEndDates(reportInformation.year as number, reportInformation.quarter as number);
      return this.utilsService.getReportPeriodText(dates[0], dates[1]);
    }
    return this.utilsService.getReportPeriodText(reportInformation.periodStartDate, reportInformation.periodEndDate);
  }

  showFaqsDialog(faqs: ReportFAQReqRes[]) {
    const wrapperPosition = this.previewWrapperElementRef.nativeElement.getBoundingClientRect();
    const dialogConfig = new MatDialogConfig<ReportFAQReqRes[]>();
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '324px';
    dialogConfig.maxWidth = '324px';
    dialogConfig.height = '562px';
    dialogConfig.maxHeight = '562px';
    dialogConfig.panelClass = 'info-dialog-no-shadow';
    dialogConfig.position = {left: `${wrapperPosition.left}px`, top: `${wrapperPosition.top}px`};
    dialogConfig.scrollStrategy = new NoopScrollStrategy();
    dialogConfig.data = faqs;
    this.infoDialogRef = this.matDialog.open(ReportFaqsDialogComponent, dialogConfig);
    this.infoDialogRef.afterClosed().subscribe(() => {
      this.infoDialogRef = null;
    });
  }

  showSimpleInfoDialog(title: string, content: string) {
    const wrapperPosition = this.previewWrapperElementRef.nativeElement.getBoundingClientRect();
    const dialogConfig = new MatDialogConfig<InfoDialogModel>();
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '324px';
    dialogConfig.maxWidth = '324px';
    dialogConfig.height = '562px';
    dialogConfig.maxHeight = '562px';
    dialogConfig.panelClass = 'info-dialog-no-shadow';
    dialogConfig.position = {left: `${wrapperPosition.left}px`, top: `${wrapperPosition.top}px`};
    dialogConfig.scrollStrategy = new NoopScrollStrategy();
    dialogConfig.data = {
      title,
      content
    };
    this.infoDialogRef = this.matDialog.open(InfoDialogComponent, dialogConfig);
    this.infoDialogRef.afterClosed().subscribe(() => {
      this.infoDialogRef = null;
    });
  }

  getReportDocumentFile(fundReport: any): ReportFileReqRes {
    if (!fundReport || !fundReport.reportPdf) {
      return null;
    } else {
      return fundReport.reportPdf;
    }
  }

  getImagesVideoAudioFiles(fundReport: any): MetaFileLink[] {
    if (!fundReport || !fundReport.attachments) {
      return null;
    } else {
      const allowedTypes = [MediaType.Image, MediaType.Video, MediaType.Audio];
      return fundReport.attachments.filter(metaFile => metaFile && metaFile.mediaType && allowedTypes.includes(MetaFileLink.GetMediaType(metaFile)));
    }
  }

  getMediaFilesNotImageVideo(fundReport: any): MetaFileLink[] {
    if (!fundReport || !fundReport.attachments) {
      return null;
    } else {
      const notAllowedTypes = [MediaType.Image, MediaType.Video, MediaType.Audio];
      return fundReport.attachments.filter(metaFile => metaFile && metaFile.mediaType && !notAllowedTypes.includes(MetaFileLink.GetMediaType(metaFile)));
    }
  }

  ngOnDestroy(): void {
    if (this.infoDialogRef) {
      this.infoDialogRef.close();
    }
  }

  private mapGpFundToLpFund(gpFund: GpFundReqRes, finalClosingDate: Date): LpFundReqRes {
    const lpFund = {
      id: gpFund.id,
      name: gpFund.name,
      holdingType: gpFund.holdingType,
      discriminator: gpFund.discriminator,
      investmentStrategyType: gpFund.investmentStrategyType,
      email: gpFund.email,
      attachments: gpFund.attachments,
      initialCurrency: gpFund.initialCurrency,
      location: gpFund.location,
      lenderInformation: gpFund.lenderInformation,
      projectBudgetOriginal: gpFund.projectBudgetOriginal.hasValue ? gpFund.projectBudgetOriginal : null,
      projectBudgetUpdated: gpFund.projectBudgetUpdated.hasValue ? gpFund.projectBudgetUpdated : null,
      // #region Cumulative information:
      totalCapitalization: this.cumulativeInfo.totalCapitalization,
      totalEquityInvestedToDate: this.cumulativeInfo.totalEquityInvestedToDate,
      totalReturnsToDate: this.cumulativeInfo.totalReturnsToDate,
      totalInvestorEquity: this.cumulativeInfo.totalInvestorEquity,
      gpEquityFromTotalEquity: this.cumulativeInfo.gpEquityFromTotalEquity,
      estimatedMarketValue: this.cumulativeInfo.estimatedMarketValue,
      cumulativeProjectedPerformance: gpFund.cumulativeProjectedPerformance,
      // #endregion
      gpDetails: new GpDetailsReqRes(),
      finalClosingDate: finalClosingDate,
      position: [], // new LpHoldingPositionRes(), test THIS
      // This property, on server side, is not part of the LpFundReqRes
      isPrivate: false
    } as LpFundReqRes;

    return lpFund;
  }

  private getLpFundReport(faqs: ReportFAQReqRes[], fundInvestmentStrategy: FundInvestmentStrategyType): FundReportReqRes {
    const report = new FundReportReqRes();

    report.periodStartDate = this.reportInformation.periodStartDate;
    report.periodEndDate = this.reportInformation.periodEndDate;
    report.publishDate = new Date();

    report.grossPotentialRent = this.reportInformation.grossPotentialRent;
    report.vacancyLoss = this.reportInformation.vacancyLoss;
    report.delinquentRent = this.reportInformation.delinquentRent;
    report.income = this.reportInformation.income;
    report.expenses = this.reportInformation.expenses;
    report.assetManagementFees = this.reportInformation.assetManagementFees;
    report.netOperatingIncome = this.reportInformation.netOperatingIncome;
    report.netIncome = this.reportInformation.netIncome;
    report.vacancyRate = this.reportInformation.vacancyRate;
    report.delinquentRate = this.reportInformation.delinquentRate;
    report.operatingExpense = this.reportInformation.operatingExpense;

    report.summary = this.reportInformation.summary;
    report.capitalInformation = this.reportInformation.capitalInformation;
    report.acquisitionsDispositions = this.reportInformation.acquisitionsDispositions;
    report.holdingUpdates = this.reportInformation.holdingUpdates;
    report.administrativeUpdates = this.reportInformation.administrativeUpdates;

    report.investmentStrategyType = fundInvestmentStrategy;

    let allFiles = this.reportInformation.attachments.filter(a => a && a.id).map(metaFileLink => {
      const mediaFile = new ReportFileReqRes();
      mediaFile.fileType = ReportFileType.ReportMedia;
      mediaFile.metaFileLink = metaFileLink;
      return mediaFile;
    });

    if (this.reportInformation.reportPdf) {
      if (!this.reportInformation.attachments.length) {
        allFiles = new Array<ReportFileReqRes>();
      }
      const reportFile = new ReportFileReqRes();
      reportFile.fileType = ReportFileType.ReportDocument;
      reportFile.metaFileLink = this.reportInformation.reportPdf;
      allFiles.push(reportFile);
    }

    report.attachments = allFiles;
    report.faQs = faqs;

    report.totalCapitalization = this.cumulativeInfo.totalCapitalization;
    report.totalEquityInvestedToDate = this.cumulativeInfo.totalEquityInvestedToDate;
    report.totalReturnsToDate = this.cumulativeInfo.totalReturnsToDate;
    report.totalInvestorEquity = this.cumulativeInfo.totalInvestorEquity;
    report.gpEquityFromTotalEquity = this.cumulativeInfo.gpEquityFromTotalEquity;
    report.estimatedMarketValue = this.cumulativeInfo.estimatedMarketValue;

    return report;
  }

  private timeAgo(date: Date) {
    if (new Date(date) >= new Date()) {
      return null;
    }
    return moment(date).fromNow();
  }

  private addTelemetryEvent(fundId: number): void {
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
      if (this.isEditMode) {
        this.telemetryService.create({
          eventID: '404',
          eventTitle: 'GP EDIT REPORT',
          holdingID: fundId,
          organizationID: clientDetails.organizationDetails.id
        }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
      } else {
        this.telemetryService.create({
          eventID: '401',
          eventTitle: 'GP SHARED REPORT (COMPLETED)',
          holdingID: fundId,
          organizationID: clientDetails.organizationDetails.id
        }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
      }
    });
  }
}
