import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, filter, map, shareReplay, take, tap} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {GpHoldingService} from '../../../gp-holding.service';
import {UtilsService} from '../../../../../../services/utils.service';
import {UserService} from '../../../../../../services/shared/user.service';
import {KeyValuePair} from '../../../../../../shared/types/KeyValuePair.model';
import {ContactDataService} from '../../../../../../services/gp/contact-data.service';
import {ContactReferrerDataService} from '../../../../../../services/gp/contact-referrer-data.service';
import {InvestingEntityTableService} from '../../../../../contacts/components/investing-entities-display-table/investing-entity-table.service';
import {InvestingEntitiesDisplayTableComponent} from '../../../../../contacts/components/investing-entities-display-table/investing-entities-display-table.component';
import {InvestingEntityReqRes} from '../../../../../models/InvestingEntityReqRes.model';
import {BulkOfferingDeckReq} from '../BulkOfferingDeckReq.model';
import {GpInvestmentDataService} from '../../../../../../services/gp/gp-investment-data.service';
import InvestmentSecurityType from '../../../../../../shared/enums/InvestmentSecurityType.enum';
import {LoggerService} from '../../../../../../shared/errors/logger.service';
import {SendAllDialogComponent} from './send-all-dialog/send-all-dialog.component';
import {SendConfirmationDialogComponent} from 'src/app/dashboard/shared/holding/fundraising/bulk-offering-deck/send-offering-deck-dialog/send-confirmation-dialog/send-confirmation-dialog.component';
import {BaseResponseDto} from '../../../../../../shared/models/BaseResponseDto.model';
import { PermissionService } from 'src/app/permission/permission.service';
 


@Component({
  selector: 'terra-send-offering-deck-dialog',
  templateUrl: './send-offering-deck-dialog.component.html',
  styleUrls: ['./send-offering-deck-dialog.component.scss'],
  providers: [InvestingEntityTableService]
})
export class SendOfferingDeckDialogComponent extends OnDestroyMixin implements OnInit, AfterViewInit {
  @ViewChild(InvestingEntitiesDisplayTableComponent, { static: false }) investingEntitiesTable: InvestingEntitiesDisplayTableComponent;
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  pageForm: UntypedFormGroup;
  
  private contactTagsResponse$ = this.contactDataService.getContactTags().pipe(shareReplay(1));
  allTags$: Observable<KeyValuePair<number, string>[]> = this.contactTagsResponse$.pipe(map(response => response.tags), shareReplay(1));
  allContactReferrers$ = this.contactReferrerDataService.getAll().pipe(
    shareReplay(1)
  );

  isLoading$ = new BehaviorSubject<boolean>(false);
  selectedInvestingEntities$ = new BehaviorSubject<InvestingEntityReqRes[]>([]);
  isSubmitted$ = new BehaviorSubject<boolean>(false);
  fundraising$ = this.holdingService.fundraising$;
  marketingDeckLink$ = this.fundraising$.pipe(
    map(fundraising => fundraising.offeringDeck.url),
    shareReplay(1));

  bodyText = '';
  subjectText = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public holdingService: GpHoldingService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SendOfferingDeckDialogComponent>,
    private dialog: MatDialog,
    private utilsService: UtilsService,
    private userService: UserService,
    private contactDataService: ContactDataService,
    private contactReferrerDataService: ContactReferrerDataService,
    private investingEntityTableService: InvestingEntityTableService,
    private investmentDataService: GpInvestmentDataService,
    private loggerService: LoggerService,
    private permissionService: PermissionService) {
      super();
    }

  ngOnInit() {
    this.generateForm();
  }

  ngAfterViewInit(): void {
    this.investingEntitiesTable.selection.changed.pipe(
      untilComponentDestroyed(this),
      map(() => {
        this.selectedInvestingEntities$.next(this.getSelectedInvestingEntities());
      }))
      .subscribe();
  }

  private getSelectedInvestingEntities() {
    let investingEntities = new Array<InvestingEntityReqRes>();
    if (this.investingEntitiesTable && this.investingEntitiesTable.selectedInvestingEntities) {
      investingEntities = this.investingEntitiesTable.selectedInvestingEntities;
    }
    return investingEntities ? investingEntities : [];
  }

  bindFilters() {
    this.pageForm
      .get('name')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(300))
      .subscribe(term => {
        this.investingEntityTableService.updateFilter(term);
      });

    this.pageForm
      .get('multiselectTags')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(300))
      .subscribe(tags => {
        this.investingEntityTableService.updateTagsFilter(tags);
      });

    this.pageForm
      .get('contactReferrerId')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(200))
      .subscribe((contactReferrerId: number) => {
        this.investingEntityTableService.updateContactReferrerFilter(contactReferrerId);
      });
  }

  send() {
    if (this.pageForm.valid) {

      this.fundraising$.pipe(take(1)).subscribe(fundraising => {
        this.selectedInvestingEntities$.pipe(take(1)).subscribe(
        investingEntities => {
          const config = new MatDialogConfig();
          config.disableClose = true;
          config.data = investingEntities;
          this.isLoading$.next(true);
          const dRef = this.dialog.open(SendConfirmationDialogComponent, config);
          dRef.afterClosed()
            .pipe(take(1)).subscribe(response => {
            const request = this.generateRequestObject();
            if (response){
              request.investingEntitiesIds = investingEntities.map(ie => ie.id);
              this.investmentDataService.sendBulkOfferingDeck(fundraising.holdingId, fundraising.id, request)
                .subscribe(
                  _ => {
                    this.handleServerResponse(true);
                  },
                  error => {
                    if (error instanceof BaseResponseDto) {
                      this.isLoading$.next(false);
                      this.utilsService.alertErrorMessage(error);
                    } else {
                      this.loggerService.error('error sending bulk offering deck request to server', error);
                      this.handleServerResponse(false);
                    }
                  },
                  () => {
                    this.isLoading$.next(false);
                  });
            } else {
              this.isLoading$.next(false);
            }
          });
        });
      });
    }
  }

  generateForm() {
    this.holdingService.holding$.pipe(take(1)).subscribe(holding => {
      this.fundraising$.pipe(take(1)).subscribe(fundraising => {
        this.userService.accountDetails$.pipe(take(1)).subscribe(account => {
          const securityType = fundraising.securityType;
          const investmentText = !securityType ? '' : InvestmentSecurityType.toString(securityType).toLowerCase();

          this.subjectText = `New opportunity from ${account.organizationDetails.name}`;
          this.bodyText = `<p>Hi {first name} ({investing entity name}), </p>
                         <p>${account.organizationDetails.name}
                         is using Covercy to share this new ${investmentText} opportunity with you: ${holding.name}.</p>`;

          this.pageForm = this.fb.group({
            message: [this.bodyText],
            subject: new UntypedFormControl({ value: this.subjectText, disabled: false }, Validators.required),
            name: [],
            multiselectTags: [],
            contactReferrerId: []
          });

          this.bindFilters();
        });
      });
    });
  }

  private generateRequestObject() {
        const request = new BulkOfferingDeckReq();
        request.message = this.pageForm.value.message;
        request.subject = this.pageForm.value.subject;

        return request;
  }

  private handleServerResponse(success: boolean) {
    this.isLoading$.next(false);
    this.dialogRef.close(success);
  }
}
