<terra-dialog-header>
  <h1 class="lo_sensitive">Prepare and send agreement to {{ personalMessageParams.investingEntityName }} ({{ personalMessageParams.investorName }})</h1>
</terra-dialog-header>

<!-- Loader -->
<div id="page-loader" class="loader-wrapper" *ngIf="showInternalLoader">
  <terra-loader></terra-loader>
</div>

<mat-dialog-content *ngIf="!showInternalLoader">

  <form id="dialogForm" (ngSubmit)="send()" [formGroup]="pageForm" class="vertical-form" (keydown.enter)="$event.preventDefault()">
    <!-- Message -->
    <label for="txtMessage" class="text-input-label">Add a personal message (optional):</label>
    <quill-editor id="txtMessage" formControlName="message" format="html" class="lo_sensitive message-input" placeholder="" />

    <!-- Error message -->
    <div *ngIf="isSubmitted">
      <p *ngIf="pageForm.invalid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" [disabled]="showInternalLoader" form="dialogForm" mat-flat-button color="accent" class="page-cta">
    Prepare Agreement <mat-icon svgIcon="publish" class="notranslate publish-icon"></mat-icon>
  </button>
  <button type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
