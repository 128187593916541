import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {finalize, tap, debounceTime, take} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import HelloSign from 'hellosign-embedded';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {UserService} from 'src/app/services/shared/user.service';
import {SignatureService} from 'src/app/services/signature.service';
import {SignatureRequest} from 'src/app/shared/models/Signature/SignatureRequest.model';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {MetaFileLink} from 'src/app/models/metaFileLink.model';
import {AppSettingsService} from 'src/app/services/app-settings.service';
import {AssetInvestorService} from '../asset-investor.service';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import {SignAgreementTemplatesComponent} from '../sign-agreement-templates/sign-agreement-templates.component';
import {KeyValuePair} from 'src/app/shared/types/KeyValuePair.model';
import {AgreementSelectorDialogComponent} from './agreement-selector-dialog/agreement-selector-dialog.component';
import {FilesSelectorComponent} from 'src/app/shared/components/files-selector/files-selector.component';
import {AgreementPersonalMessageDialogComponent} from './agreement-personal-message-dialog/agreement-personal-message-dialog.component';
import {AgreementPersonalMessageDialogParams} from './agreement-personal-message-dialog/AgreementPersonalMessageDialogParams.model';
import {GpAssetService} from '../../gp-asset.service';
import {AlertDialogParams} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {DialogService} from 'src/app/services/dialog.service';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';
import {PermissionService} from 'src/app/permission/permission.service';
import {ErrorMatcher, ErrorType} from '../../../../../shared/errors/ErrorMatcher';

@Component({
  selector: 'terra-asset-sign-agreement-block',
  templateUrl: './asset-sign-agreement-block.component.html',
  styleUrls: ['./asset-sign-agreement-block.component.scss']
})
export class AssetSignAgreementBlockComponent extends OnDestroyMixin implements OnInit {

  @Input() investment: InvestmentReqRes;
  @Output() validateForm = new EventEmitter();
  @Input() isHoldingPrivate: boolean;

  @ViewChild('filesSelector') filesSelector: FilesSelectorComponent;
  @ViewChild('filesSelectorWhenEmpty') filesSelectorWhenEmpty: FilesSelectorComponent;

  allowInvestorName$ = this.permissionService.allowInvestorName$;

  dateFormat = TerraUtils.consts.LONG_DATE_AND_TIME;

  // helloSignRef: any;
  // helloSignWindowVariable = 'HelloSign';
  processingHelloSignRequest = false;

  isSubmitted = false;

  isLoading = false;

  agreementSent = false;
  agreementReSent = false;

  useDrafts = false;
  useTemplates: boolean;

  metaFileLinkFiles: MetaFileLink[];
  templates: KeyValuePair<string, string>[];
  email = '';

  showRecentlyUsed = false;

  constructor(
    private fb: UntypedFormBuilder,
    private assetInvestorService: AssetInvestorService,
    private signatureService: SignatureService,
    private userService: UserService,
    private appSettings: AppSettingsService,
    private dialog: MatDialog,
    private gpAssetService: GpAssetService,
    private dialogService: DialogService,
    private telemetryService: TelemetryService,
    private utilsService: UtilsService,
    private permissionService: PermissionService
  ) {
    super();
  }

  ngOnInit() {

    this.loadTemplates();

    this.userService.accountDetails$.subscribe(accountDetails => {

      this.useTemplates = FeatureFlags.isOn(accountDetails.featureFlags, FeatureFlags.HelloSignTemplates);

      this.email = accountDetails.email;
    });
  }

  loadTemplates() {
    const defaultTemplatesCount = 5;

    this.signatureService.getTemplatesAgreement(defaultTemplatesCount)
      .subscribe((response: KeyValuePair<string, string>[]) => {
        this.templates = response;

        this.showRecentlyUsed = this.useTemplates && this.templates.length > 0;
      });
  }

  selectExistingTemplate(templateId: string) {

    if (!this.investment.estimatedAmount) {
      this.validateForm.emit();
      return;
    }

    if (!this.useTemplates) {
      return;
    }
    this.signatureService.editTemplateAgreement(templateId)
      .subscribe(response => {
          // this.openHelloSignEmbeddedWindow(response.editUrl);
          this.openPersonalMessageDialog(response.editUrl);
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          }
        });
  }

  showFileBrowser() {
    if (!this.investment.estimatedAmount) {
      this.validateForm.emit();
      return;
    }
    this.filesSelectorWhenEmpty.showFileBrowser();
  }

  sendAgreement() {
    this.isSubmitted = true;

    if (!this.investment.estimatedAmount) {
      this.validateForm.emit();
      return;
    }

    this.isLoading = true;
    this.processingHelloSignRequest = true;

    const sigReq = new SignatureRequest();
    sigReq.investingEntityId = this.investment.investingEntityId;
    sigReq.investmentId = this.investment.id;

    if (this.metaFileLinkFiles) {
      sigReq.metaFileLinkIds = this.metaFileLinkFiles.map(file => file.id);
    }

    const draft = this.useTemplates ?
      this.signatureService.createTemplateDraftAgreement(sigReq) :
      this.signatureService.createDraftAgreement(sigReq);

    draft
      .pipe(
        debounceTime(1000),
        finalize(() => {
          this.isLoading = false;
          this.processingHelloSignRequest = false;
        }),
        untilComponentDestroyed(this)
      )
      .subscribe(response => {

          this.openPersonalMessageDialog(response.claimUrl || response.editUrl);

          // this.openHelloSignEmbeddedWindow(response.claimUrl || response.editUrl);
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          }
        });
  }


  openHelloSignEmbeddedWindow(helloSignUrl: string, personalMessage: string) {

    this.processingHelloSignRequest = true;

    const client = new HelloSign({
      clientId: this.appSettings.helloSignClientId
    });

    client.open(helloSignUrl, {
      debug: true,
      skipDomainVerification: this.appSettings.helloSignSkipDomainVerification
    });

    client.on('createTemplate', (helloSignEventData) => {

      this.finalizeAgreement(helloSignEventData, personalMessage);
    });

    client.on('send', (helloSignEventData) => {

      this.finalizeAgreement(helloSignEventData, personalMessage);
    });

    client.on('cancel', () => {
      this.processingHelloSignRequest = false;
    });

  }

  finalizeAgreement(helloSignEventData: any, personalMessage: string) {
    let agreementSentObservable: Observable<InvestmentReqRes>;

    helloSignEventData.investment_id = this.investment.id;
    this.agreementSent = true;

    if (this.useTemplates) {

      helloSignEventData.template_info = {message: personalMessage};
      helloSignEventData.template_id = helloSignEventData.templateId;
      helloSignEventData.signature_request_id = helloSignEventData.signatureRequestId;

      agreementSentObservable = this.signatureService.sendTemplateAgreement(helloSignEventData);

    } else {

      helloSignEventData.signature_request_info = {message: personalMessage};
      helloSignEventData.signature_request_id = helloSignEventData.signatureRequestId;

      agreementSentObservable = this.signatureService.sendAgreement(helloSignEventData);

    }

    // For both using existing template and generating a new template
    if (agreementSentObservable) {

      agreementSentObservable
        .subscribe(investment => {
            this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
              this.telemetryService.create({
                eventID: '500',
                eventTitle: 'SIGNATURE TEMPLATE USE',
                holdingID: investment.assetId,
                organizationID: clientDetails.organizationDetails.id,
                additional: {
                  investor: investment.investingEntity.name,
                }
              }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
            });
            this.investment = investment;
            this.assetInvestorService.agreementSent(investment);
            this.processingHelloSignRequest = false;

            const dialogParams = new AlertDialogParams();

            dialogParams.title = `Agreement Sent`;
            dialogParams.description = `The agreement was sent successfully`;
            dialogParams.actionLabel = `OK`;

            this.dialogService
              .alertDialog(dialogParams)
              .afterClosed()
              .subscribe(() => {
              });
          },
          error => {
            this.processingHelloSignRequest = false;
          }
        );
    } else {
      this.processingHelloSignRequest = false;
    }
  }

  resendAgreement() {
    this.agreementReSent = false;
    if (!this.investment.estimatedAmount) {
      this.validateForm.emit();
      return;
    }

    this.isLoading = true;

    this.signatureService
      .resendAgreement(this.investment.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        untilComponentDestroyed(this)
      )
      .subscribe((investment: InvestmentReqRes) => {
          // this.investment = investment;
          this.agreementReSent = true;
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else if (ErrorMatcher.isError(error, ErrorType.MaxTriesReachedException)) {
            this.utilsService.alertMaxRetries();
          }
        });
  }

  markAsSigned() {
    if (!this.investment.estimatedAmount || !this.assetInvestorService.pageForm.valid) {
      this.validateForm.emit();
      return;
    }

    this.isLoading = true;

    this.assetInvestorService
      .updateStatus(InvestmentStatus.Signed)
      .subscribe(
        updatedInvestment => {
          this.isLoading = false;
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          }

          console.log('Error. Status was not updated.', error);
          this.isLoading = false;
        }
      );
  }

  moreTemplates() {
    if (!this.useTemplates) {
      return;
    }

    const config = new MatDialogConfig();
    config.panelClass = 'agreement-templates-dialog';
    config.disableClose = true;
    config.autoFocus = false;
    config.data = this.templates;

    const dialogRef = this.dialog
      .open(SignAgreementTemplatesComponent, config);

    dialogRef.afterClosed()
      .subscribe(
        templateId => {
          if (templateId) {
            this.selectExistingTemplate(templateId);
          }
        },
        err => {
        }
      );
    // When the user clicks the "Upload a new agreement" inside the dialog:
    dialogRef.componentInstance.uploadNewAgreement.subscribe(
      () => {
        this.filesSelector.showFileBrowser();
      }
    );


  }

  showAgreementSelectorDialog(files: FileList) {

    if (!this.investment.estimatedAmount) {
      this.validateForm.emit();
      return;
    }

    // This scrolling is a hack to make drag-and-drop work inside a dialog. see: https://github.com/angular/components/issues/15880
    const doc = document.documentElement;
    const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top !== 0 || left !== 0) {
      window.scrollTo({top: 0, left: 0});
    }

    const dialogConfig = new MatDialogConfig<FileList>();
    dialogConfig.panelClass = 'agreement-selector-dialog';
    dialogConfig.data = files;

    this.dialog.open(AgreementSelectorDialogComponent, dialogConfig)
      .afterClosed().pipe(
      tap(() => {
        // Hack continues...
        if (top !== 0 || left !== 0) {
          window.scroll({top, left, behavior: 'auto'});
        }
      })).subscribe(uploadedFiles => {
      this.metaFileLinkFiles = uploadedFiles;
      if (uploadedFiles && uploadedFiles.length > 0) {

        this.sendAgreement();
      }
    });
  }

  removeAgreement() {

    this.isLoading = true;

    this.signatureService
      .removeAgreement(this.investment.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        untilComponentDestroyed(this)
      )
      .subscribe((response: any) => {
          this.agreementSent = false;
          this.investment.agreementSentDate = null;
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          }
        });
  }

  openPersonalMessageDialog(helloSignUrl: string) {
    const dialogConfig = new MatDialogConfig<AgreementPersonalMessageDialogParams>();
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    const contactName = TerraUtils.getContactFullName(this.investment.investingEntity.contact);
    dialogConfig.data = new AgreementPersonalMessageDialogParams(this.investment.id, this.investment.investingEntity.name, contactName, null, false);

    this.dialog
      .open(AgreementPersonalMessageDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((parameters: AgreementPersonalMessageDialogParams) => {

        if (parameters && parameters.sendAgreement) {

          // this.finalizeAgreement(parameters.personalMessage);
          this.openHelloSignEmbeddedWindow(helloSignUrl, parameters.personalMessage);

        } else {
          this.removeAgreement();
          this.agreementSent = false;
        }
      });
  }

  assetDownInfo() {
    this.gpAssetService.holdingDownInfo();
  }
}
