<form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" (ngSubmit)="stepSubmit()"
  class="cov-form-grid container-750">

  <section class="form-section">
    <h3 class="section-title">Fundraising details</h3>

    <!-- Closing date -->
    <div class="terra-form-field-wrapper">
      <terra-custom-utc-date-picker id="txtClosingDate" formControlName="estimatedClosingDate"
        label="Estimated closing date" [minDate]="closingDateMinimumDate"
        [requiredError]="pageForm.get('estimatedClosingDate').hasError('required')"
        [touched]="pageForm.get('estimatedClosingDate').touched"
        [minError]="pageForm.get('estimatedClosingDate').hasError('minDate')"
        minErrorTxt="Please enter a valid closing date" [startAt]="closingDateStartAt"></terra-custom-utc-date-picker>
    </div>

    <!-- Security type -->
    <div class="terra-form-field-wrapper">
      <label for="ddlInvestmentSecurityType" class="text-input-label">Security type</label>
      <mat-form-field appearance="outline">
        <mat-select id="ddlInvestmentSecurityType" placeholder="Select" formControlName="securityType">
          <mat-option>Select</mat-option>
          <mat-option *ngFor="let item of InvestmentSecurityTypesList" [value]="item"> {{ item | enumString:
            InvestmentSecurityType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- FundraisingTargetAmount and currency -->
    <div class="terra-form-field-wrapper new-row">
      <label for="txtFundraisingTargetAmount" class="amount-label text-input-label">
        {{ selectedSecurityType === InvestmentSecurityType.DirectOwnership ? 'Purchase price' : 'Total amount to be
        raised'}}
      </label>
      <mat-form-field appearance="outline" class="amount-field-wrapper">
        <input type="tel" terraFormattedNumber matInput id="txtFundraisingTargetAmount"
          formControlName="fundraisingTargetAmount" class="lo_sensitive" />
        <span *ngIf="assetCurrency$ | async as assetCurrency" matSuffix>{{ assetCurrency.iso }}</span>
        <mat-error *ngIf="pageForm.get('fundraisingTargetAmount').hasError('required')"> Required</mat-error>
        <mat-error *ngIf="pageForm.get('fundraisingTargetAmount').hasError('min')"> Amount must be greater than
          zero</mat-error>
      </mat-form-field>
    </div>

    <!-- Minimum investment -->
    <div class="terra-form-field-wrapper">
      <label for="txtMinimumInvestmentAmount" class="text-input-label">
        {{ selectedSecurityType === InvestmentSecurityType.DirectOwnership ?
        'Initial purchase price (optional)' : 'Minimum investment (optional)'}}
      </label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtMinimumInvestmentAmount" terraFormattedNumber
          formControlName="minimumInvestmentAmount" class="lo_sensitive" />
        <span *ngIf="assetCurrency$ | async as assetCurrency" matSuffix>{{ assetCurrency.iso }}</span>
      </mat-form-field>
    </div>
  </section>

  <section class="form-section">
    <h3 class="section-title">Upload an offering deck (optional)</h3>

    <!-- Marketing document uploader -->
    <terra-file-uploader no-type-select formControlName="marketingDeckDocument" [isSubmitted]="isSubmitted"
      [required]="false" [isCompressable]="true">
    </terra-file-uploader>

    <p class="file-uploader-hint new-row">File format must be JPG, JPEG, PNG (20 MB max file size) or PDF (50 MB max
      file size)</p>

    <h3 class="section-title">Bank account where funds will be collected (may be added later)</h3>

    <!-- Bank account -->
    <ng-template #banksLoader>
      <terra-loader *ngIf="!noAccessMessage"></terra-loader>
    </ng-template>

    <ng-container *ngIf="!noAccessMessage && (bankAccounts$ | async) as bankAccounts; else banksLoader">
      <div class="terra-form-field-wrapper" *ngIf="assetCurrency$ | async as assetCurrency">
        <label for="ddlBankAccount" class="text-input-label">Choose from existing:</label>
        <mat-form-field appearance="outline">
          <mat-select id="ddlBankAccount"
            [placeholder]="bankAccounts.length ? 'Select' : 'No ' + assetCurrency.iso + ' bank accounts found'"
            formControlName="bankAccount" [compareWith]="bankAccountCompareFn">
            <mat-option [value]="null">Select</mat-option>
            <mat-option *ngFor="let bankAccount of bankAccountOptions$ | async" [value]="bankAccount">
              {{ bankAccount.nickname }} {{ !bankAccount.nickname ? bankAccount.holderFullName + ', ' : '' }}
              {{ !bankAccount.nickname ? getCountryById(bankAccount.countryId).code : '' }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <a class="link add-bank-account" (click)="createNewBankAccount()">Add new</a>
      </div>
    </ng-container>

    <div class="full-width not-allow-message" *ngIf="noAccessMessage">
      <mat-icon class="notranslate">error</mat-icon>
      <h4>{{noAccessMessage}}</h4>
    </div>
  </section>
</form>

<section class="footer">
  <div class="container-750">
    <!-- Error message -->
    <div *ngIf="isSubmitAttempt" class="error-section">
      <p *ngIf="!pageForm.valid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
    <div class="form-actions">
      <a (click)="cancel()" class="cancel-button">Cancel</a>
      <button type="submit" form="stepForm" mat-flat-button color="primary" class="page-cta">
        Next
      </button>
    </div>
  </div>
</section>