import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { register as registerSwiperElements } from 'swiper/element/bundle';

import { AppModule } from './app/app.module';

if ((window as any).__appSettings.production) {
  enableProdMode();
}

registerSwiperElements(); // refactor to use in just components that need it

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
