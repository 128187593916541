import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
 
import { Subscription } from 'rxjs';
import { CreateFundStepBase } from '../CreateFundStepBaseAndInterface';
import { CreateFundService, CreateFundStepNumber } from '../create-fund.service';


@Component({
  selector: 'terra-create-fund-marketing-material-step',
  templateUrl: './create-fund-marketing-material-step.component.html',
  styleUrls: ['./create-fund-marketing-material-step.component.scss']
})
export class CreateFundMarketingMaterialStepComponent extends CreateFundStepBase implements OnInit, OnDestroy {
 
  videoUrl : SafeResourceUrl;

  readonly STEP_NUMBER: CreateFundStepNumber = 3;
  
  subscription:Subscription = new Subscription();


  showSecondPhotosRow = false;

  constructor(private createFundService: CreateFundService,
    private cdr:ChangeDetectorRef,
    protected _sanitizer: DomSanitizer) {
    super(createFundService);
  }

  ngOnInit() {
    this.createFundService.currentStep$.next(this.STEP_NUMBER);
    this.additionalAttachment();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  additionalAttachment(){
    this.subscription = this.pageForm.get('attachments').valueChanges.pipe()
    .subscribe((attachments:[] )=> {
      const remInd = attachments.findIndex(c => !c);

      if(remInd >= 0){
        this.createFundService.popMarketingAttachment(remInd);
      }
      else if(attachments.length < 10){
        this.createFundService.pushMarketingAttachment();
      }

      this.cdr.markForCheck();
    });
  }

  get attachments(){
    return (this.pageForm.get('attachments') as UntypedFormArray).controls;
  }

  addVideo() {
    this.pageForm.get('shouldShowPlayer').setValue(true);
    this.videoUrl = this.getVideoUrl();
  }

  getVideoUrl() : SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.getYoutubeVideoIdFromUrl()}?autoplay=1`);
  }

  getYoutubeVideoIdFromUrl() : string {
    const url = this.pageForm.get("video").value;
    const urlObject = new URL(url);
    let urlOrigin = urlObject.origin;
    let urlPath = urlObject.pathname;

    if (urlOrigin.search('youtu.be') > -1) {
        return urlPath.substr(1);
    }

    if (urlPath.search('embed') > -1) {
        // Örneğin "/embed/wCCSEol8oSc" ise "wCCSEol8oSc" return eder.
        return urlPath.substr(7);
    }

   
    return urlObject.searchParams.get('v');
  }

  removeVideo() {
    this.pageForm.get("video").reset();
    this.pageForm.get("shouldShowPlayer").setValue(false);
  }
}
