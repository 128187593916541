import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'terra-fund-premium',
  templateUrl: './fund-premium.component.html',
  styleUrls: ['./fund-premium.component.scss']
})
export class FundPremiumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
