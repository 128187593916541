import { MetaFileLink } from 'src/app/models/metaFileLink.model';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';

export class ShareMarketingParams {

  public investmentId: number;
  public investingEntityName: string;
  public investingEntityNickname: string;
  public investorNickname: string;
  public investorName: string;
  public marketingFile: MetaFileLink;
  public agentCompanyName: string;
  public agentFirstName: string;
  public lpFirstName: string;
  public assetName: string;
  public investmentSecurityType: InvestmentSecurityType;

}
