import {Component, OnInit, ViewChild, HostListener, OnDestroy} from '@angular/core';
import {of, Observable, combineLatest} from 'rxjs';
import {map, switchMap, shareReplay, take} from 'rxjs/operators';
import {MatDialogConfig, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NoopScrollStrategy} from '@angular/cdk/overlay';
import moment from 'moment';

import {GpAssetReportService} from '../gp-asset-report.service';
import {GpAssetService} from '../../../../gp-asset.service';
import {MetaFileLink, MediaType} from 'src/app/models/metaFileLink.model';
import {LocationDetailsResponse} from 'src/app/shared/models/LocationDetailsResponse.model';
import {UserService} from 'src/app/services/shared/user.service';
import {HoldingType} from 'src/app/shared/enums/HoldingType.enum';
import {InvestmentType} from 'src/app/shared/enums/InvestmentType.enum';
import {DialogService} from 'src/app/services/dialog.service';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ReportFileReqRes} from 'src/app/shared/models/ReportFileReqRes.model';
import {AssetReportReqRes} from '../../../../../../../shared/models/AssetReportReqRes.model';
import ReportFileType from 'src/app/shared/enums/ReportFileType.enum';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';
import {ResourceService} from 'src/app/services/resource.service';
import {UtilsService} from 'src/app/services/utils.service';
import {ReportFAQReqRes} from 'src/app/shared/models/reportFAQReqRes.model';
import {ReportFaqsDialogComponent} from 'src/app/lp/shared/holdings/report-faqs-dialog/report-faqs-dialog.component';
import {InfoDialogModel, InfoDialogComponent} from 'src/app/lp/shared/info-dialog/info-dialog.component';
import ReportPeriod from 'src/app/shared/enums/ReportPeriod.enum';
import {ErrorType, ErrorMatcher, ErrorData} from 'src/app/shared/errors/ErrorMatcher';
import {GpAssetReqRes} from 'src/app/dashboard/assets/GpAssetReqRes.model';
import {GpDetailsReqRes} from 'src/app/lp/models/GpDetailsReqRes.model';
import {LpAssetReqRes} from 'src/app/lp/assets/LpAssetReqRes.model';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {LpHoldingPositionRes} from 'src/app/lp/models/LpHoldingPositionRes.model';
import {DynamicComponentItem} from 'src/app/shared/models/dynamic-component-loader/DynamicComponentItem.model';
import {CheckboxComponent} from 'src/app/shared/components/dynamic-component-loader/checkbox.component';
import {BaseResponseDto} from '../../../../../../../shared/models/BaseResponseDto.model';
import {HoldingDiscriminator} from 'src/app/shared/enums/HoldingDiscriminator.enum';

@Component({
  selector: 'terra-asset-report-review',
  templateUrl: './asset-report-review.component.html',
  styleUrls: ['./asset-report-review.component.scss']
})
export class AssetReportReviewComponent implements OnInit, OnDestroy {
  HoldingDiscriminator = HoldingDiscriminator;
  isEditMode: boolean;

  asset$ = this.gpAssetService.holding$;
  fundraising$ = this.gpAssetService.fundraising$;
  finalClosingDate$ = this.gpAssetService.fundraising$.pipe(map(fundraising => fundraising ? fundraising.finalClosingDate : null));
  companyName$ = this.userService.accountDetails$.pipe(map(agent => agent.organizationDetails.name), shareReplay(1));

  // Enum
  HoldingType = HoldingType;
  InvestmentType = InvestmentType;

  cumulativeInfo = this.gpAssetReportService.formStep2.value;
  reportInformation = this.gpAssetReportService.formStep1.value;
  pseudoReportList: any[] = [this.reportInformation];

  faQs$ = this.gpAssetReportService.reportDetails$.pipe(map(report => report ? report.faQs : null));

  // Asset photo urls
  photoUrls$: Observable<string[]> = this.asset$.pipe(
    map(assetDetails => assetDetails.attachments.filter(att => att.fileType === HoldingFileType.Photo).map(att => att.metaFileLink)),
    map(allAttachments => {
      const photoUrls = allAttachments.map(metaFileLink => metaFileLink.url);
      if (!photoUrls || photoUrls.length === 0) {
        photoUrls.push('assets/images/photo-carousel-empty.png');
      }
      return photoUrls;
    }));

  isAlreadyPublished$ = this.gpAssetReportService.isAlreadyPublished$;

  lpAssetAsMappedFromGpAsset$ = combineLatest([this.asset$, this.finalClosingDate$]).pipe(
    map(([asset, finalClosingDate]) =>
      this.mapGpAssetToLpAsset(asset, finalClosingDate)),
    shareReplay(1));

  lpAssetReportSection$ = combineLatest([this.faQs$, this.asset$]).pipe(
    map(([faQs, asset]) => this.getLpAssetReport(faQs, asset.investmentType)),
    shareReplay(1));

  isGeneralServerError = this.gpAssetReportService.isGeneralServerError;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;

  // Preview Wrapper element ref
  @ViewChild('previewWrapper') previewWrapperElementRef;

  infoDialogRef: MatDialogRef<any, any> = null;

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (this.infoDialogRef) {
      const wrapperPosition = this.previewWrapperElementRef.nativeElement.getBoundingClientRect();
      this.infoDialogRef.updatePosition({left: `${wrapperPosition.left}px`, top: `${wrapperPosition.top}px`});
    }
  }

  constructor(
    private gpAssetReportService: GpAssetReportService,
    private gpAssetService: GpAssetService,
    private matDialog: MatDialog,
    private userService: UserService,
    private resourceService: ResourceService,
    private dialogService: DialogService,
    private utilsService: UtilsService,
    private telemetryService: TelemetryService,
  ) {
  }

  ngOnInit() {
  }

  // without publish
  save() {
    if (!this.gpAssetReportService.reportForm.valid) {
      console.log('Report form is invalid.', this.gpAssetReportService.reportForm);
      return;
    }

    this.gpAssetReportService.processingSubmit$.next(true);
    this.gpAssetReportService.isGeneralServerError = false;

    this.gpAssetReportService
      .saveOnly()
      .pipe(switchMap(response => this.gpAssetReportService.assetId$))
      .subscribe(
        assetId => {
          this.gpAssetReportService.navigateToAssetPage(assetId);
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else if (this.isErrorReportFormat(error)) {
            this.gpAssetReportService.isReportDocumentFileNotSupported = true;
            this.gpAssetReportService.currentStep$.next(1);
          } else {
            this.gpAssetReportService.isGeneralServerError = true;
          }
          this.gpAssetReportService.processingSubmit$.next(false);
        }
      );
  }

  isErrorReportFormat(error: any) {
    return ErrorMatcher.isError(error, ErrorType.BadFileFormatException) && (error.error as ErrorData).data === ReportFileType.ReportDocument.toString();
  }

  saveAndPublish() {
    if (!this.gpAssetReportService.reportForm.valid) {
      console.log('Report form is invalid.', this.gpAssetReportService.reportForm);
      return;
    }

    let includeSummaryToReportVal = true;
    const includeSummaryToReportComponent = new DynamicComponentItem(
      CheckboxComponent,
      {input: 'Add summary to email body', checked: true, disabled: false, output: (val) => includeSummaryToReportVal = val}
    );

    this.gpAssetReportService.isGeneralServerError = false;
    this.gpAssetReportService.reportId$
      .pipe(
        switchMap(reportId => {
          // Create report mode:
          if (reportId <= 0) {
            // simulate confirmation of publish without showing th confirm dialog
            this.isEditMode = false;
            // return of(true);
          } else {
            this.isEditMode = true;
          }
          return this.dialogService
            .confirmDialog(
              new ConfirmDialogParams('Ready to update your investors?',
                'Would you like to notify the investors about the changes made to this report via email, or only publish in the investor portal?',
                'Publish & send email', '', '', 'publish',
                'Publish only', 'accent', includeSummaryToReportComponent
              ))
            .afterClosed();
        }),
        switchMap(
          (isConfirmed): Observable<boolean> => {
            if (isConfirmed) {
              this.gpAssetReportService.processingSubmit$.next(true);
              // will return true if published
              return this.gpAssetReportService.saveAndPublish(!isConfirmed.secondaryAction, includeSummaryToReportVal);
            } else {
              return of(false);
            }
          }
        ),
        switchMap(isPublished => {
          // if the user didn't confirm, use 0 as a flag for the next operation
          if (!isPublished) {
            return of(0);
          } else {
            return this.gpAssetReportService.assetId$;
          }
        })
      )
      .subscribe(
        assetId => {
          this.gpAssetReportService.processingSubmit$.next(false);
          // if the assetId is 0, it means the user canceled the confirmation dialog, and we don't want to navigate
          if (assetId) {
            this.addTelemetryEvent(assetId);
            this.gpAssetReportService.navigateToAssetPage(assetId);
          }
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else if (this.isErrorReportFormat(error)) {
            this.gpAssetReportService.isReportDocumentFileNotSupported = true;
            this.gpAssetReportService.currentStep$.next(1);
          } else {
            this.gpAssetReportService.isGeneralServerError = true;
          }
          this.gpAssetReportService.processingSubmit$.next(false);
        }
      );
  }

  back() {
    this.gpAssetReportService.currentStep$.next(2);
  }

  getFormattedAddress(location: LocationDetailsResponse) {
    if (!location) {
      return '';
    }
    let formattedString = '';
    if (location.cityName) {
      formattedString += location.cityName;
      if (location.stateName || location.postalCode) {
        formattedString += ', ';
      }
    }
    if (location.stateName) {
      formattedString += location.stateName;
    }
    if (location.postalCode) {
      formattedString += ' ' + location.postalCode;
    }
    const country = this.resourceService.getCountryById(location.countryId);

    if (country && country.code) {
      if (!formattedString) {
        formattedString += country.name;
      } else {
        formattedString += ', ' + country.code;
      }
    }

    return formattedString;
  }

  getReportName(reportInformation: any) {
    if (reportInformation.reportPeriod !== ReportPeriod.Custom) {
      const dates = TerraUtils.getStartAndEndDates(reportInformation.year as number, reportInformation.quarter as number);
      return this.utilsService.getReportPeriodText(dates[0], dates[1]);
    }
    return this.utilsService.getReportPeriodText(reportInformation.periodStartDate, reportInformation.periodEndDate);
  }

  showFaqsDialog(faqs: ReportFAQReqRes[]) {
    const wrapperPosition = this.previewWrapperElementRef.nativeElement.getBoundingClientRect();
    const dialogConfig = new MatDialogConfig<ReportFAQReqRes[]>();
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '324px';
    dialogConfig.maxWidth = '324px';
    dialogConfig.height = '562px';
    dialogConfig.maxHeight = '562px';
    dialogConfig.panelClass = 'info-dialog-no-shadow';
    dialogConfig.position = {left: `${wrapperPosition.left}px`, top: `${wrapperPosition.top}px`};
    dialogConfig.scrollStrategy = new NoopScrollStrategy();
    dialogConfig.data = faqs;
    this.infoDialogRef = this.matDialog.open(ReportFaqsDialogComponent, dialogConfig);
    this.infoDialogRef.afterClosed().subscribe(() => {
      this.infoDialogRef = null;
    });
  }

  showSimpleInfoDialog(title: string, content: string) {
    const wrapperPosition = this.previewWrapperElementRef.nativeElement.getBoundingClientRect();
    const dialogConfig = new MatDialogConfig<InfoDialogModel>();
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '324px';
    dialogConfig.maxWidth = '324px';
    dialogConfig.height = '562px';
    dialogConfig.maxHeight = '562px';
    dialogConfig.panelClass = 'info-dialog-no-shadow';
    dialogConfig.position = {left: `${wrapperPosition.left}px`, top: `${wrapperPosition.top}px`};
    dialogConfig.scrollStrategy = new NoopScrollStrategy();
    dialogConfig.data = {
      title,
      content
    };
    this.infoDialogRef = this.matDialog.open(InfoDialogComponent, dialogConfig);
    this.infoDialogRef.afterClosed().subscribe(() => {
      this.infoDialogRef = null;
    });
  }

  getReportDocumentFile(assetReport: any): ReportFileReqRes {
    if (!assetReport || !assetReport.reportPdf) {
      return null;
    } else {
      return assetReport.reportPdf;
    }
  }

  getImagesVideoAudioFiles(assetReport: any): MetaFileLink[] {
    if (!assetReport || !assetReport.attachments) {
      return null;
    } else {
      const allowedTypes = [MediaType.Image, MediaType.Video, MediaType.Audio];
      return assetReport.attachments.filter(metaFile => metaFile && metaFile.mediaType && allowedTypes.includes(MetaFileLink.GetMediaType(metaFile)));
    }
  }

  getMediaFilesNotImageVideo(assetReport: any): MetaFileLink[] {
    if (!assetReport || !assetReport.attachments) {
      return null;
    } else {
      const notAllowedTypes = [MediaType.Image, MediaType.Video, MediaType.Audio];
      return assetReport.attachments.filter(metaFile => metaFile && metaFile.mediaType && !notAllowedTypes.includes(MetaFileLink.GetMediaType(metaFile)));
    }
  }

  ngOnDestroy(): void {
    if (this.infoDialogRef) {
      this.infoDialogRef.close();
    }
  }

  private mapGpAssetToLpAsset(gpAsset: GpAssetReqRes, finalClosingDate: Date): LpAssetReqRes {
    const lpAsset = {
      id: gpAsset.id,
      name: gpAsset.name,
      discriminator: gpAsset.discriminator,
      holdingType: gpAsset.holdingType,
      investmentType: gpAsset.investmentType,

      areaUnits: gpAsset.areaUnits,
      lotSizeArea: gpAsset.lotSizeArea,
      stories: gpAsset.stories,
      residentialUnits: gpAsset.residentialUnits,
      retailUnits: gpAsset.retailUnits,
      rooms: gpAsset.rooms,
      grossBuildingArea: gpAsset.grossBuildingArea,
      residentialNetSellableArea: gpAsset.residentialNetSellableArea,
      retailNetSellableArea: gpAsset.retailNetSellableArea,
      numberOfSites: gpAsset.numberOfSites,

      attachments: gpAsset.attachments,
      initialCurrency: gpAsset.initialCurrency,
      location: gpAsset.location,
      lenderInformation: gpAsset.lenderInformation,
      projectBudgetOriginal: gpAsset.projectBudgetOriginal.hasValue ? gpAsset.projectBudgetOriginal : null,
      projectBudgetUpdated: gpAsset.projectBudgetUpdated.hasValue ? gpAsset.projectBudgetUpdated : null,
      // #region Cumulative information:
      totalCapitalization: this.cumulativeInfo.totalCapitalization,
      totalEquityInvestedToDate: this.cumulativeInfo.totalEquityInvestedToDate,
      totalReturnsToDate: this.cumulativeInfo.totalReturnsToDate,
      totalInvestorEquity: this.cumulativeInfo.totalInvestorEquity,
      plannedCostsToDate: this.cumulativeInfo.plannedCostsToDate,
      actualCostsToDate: this.cumulativeInfo.actualCostsToDate,
      gpEquityFromTotalEquity: this.cumulativeInfo.gpEquityFromTotalEquity,
      estimatedMarketValue: this.cumulativeInfo.estimatedMarketValue,
      outstandingLoanAmountToDate: this.cumulativeInfo.outstandingLoanAmountToDate,
      constructionStartDate: this.cumulativeInfo.constructionStartDate,
      constructionEndDate: this.cumulativeInfo.constructionEndDate,
      cumulativeProjectedPerformance: gpAsset.cumulativeProjectedPerformance,
      // #endregion
      gpDetails: new GpDetailsReqRes(),
      finalClosingDate: finalClosingDate,
      position: [],// new LpHoldingPositionRes(), test THIS
      // This property, on server side, is not part of the LpAssetReqRes
      isPrivate: false,
    } as LpAssetReqRes;


    return lpAsset;
  }

  private getLpAssetReport(faqs: ReportFAQReqRes[], investmentType: InvestmentType): AssetReportReqRes {
    const report = new AssetReportReqRes();

    report.periodStartDate = this.reportInformation.periodStartDate;

    report.periodEndDate = this.reportInformation.periodEndDate;
    report.publishDate = new Date();
    report.expenses = this.reportInformation.expenses;
    report.netOperatingIncome = this.reportInformation.netOperatingIncome;
    report.occupancy = this.reportInformation.occupancy;
    report.summary = this.reportInformation.summary;
    report.assetUpdates = this.reportInformation.assetUpdates;
    report.tenantsUpdates = this.reportInformation.tenantsUpdates;
    report.cashFlowUpdates = this.reportInformation.cashFlowUpdates;
    report.marketUpdates = this.reportInformation.marketUpdates;
    report.investmentType = investmentType;

    let allFiles = this.reportInformation.attachments.filter(a => a && a.id).map(metaFileLink => {
      const mediaFile = new ReportFileReqRes();
      mediaFile.fileType = ReportFileType.ReportMedia;
      mediaFile.metaFileLink = metaFileLink;
      return mediaFile;
    });

    if (this.reportInformation.reportPdf) {
      if (!this.reportInformation.attachments.length) {
        allFiles = new Array<ReportFileReqRes>();
      }
      const reportFile = new ReportFileReqRes();
      reportFile.fileType = ReportFileType.ReportDocument;
      reportFile.metaFileLink = this.reportInformation.reportPdf;
      allFiles.push(reportFile);
    }

    report.attachments = allFiles;
    report.faQs = faqs;
    report.totalEquityInvestedToDate = this.cumulativeInfo.totalEquityInvestedToDate;
    report.totalReturnsToDate = this.cumulativeInfo.totalReturnsToDate;
    report.totalCapitalization = this.cumulativeInfo.totalCapitalization;
    report.totalInvestorEquity = this.cumulativeInfo.totalInvestorEquity;
    report.plannedCostsToDate = this.cumulativeInfo.plannedCostsToDate;
    report.actualCostsToDate = this.cumulativeInfo.actualCostsToDate;
    report.gpEquityFromTotalEquity = this.cumulativeInfo.gpEquityFromTotalEquity;
    report.estimatedMarketValue = this.cumulativeInfo.estimatedMarketValue;
    report.outstandingLoanAmountToDate = this.cumulativeInfo.outstandingLoanAmountToDate;
    report.constructionStartDate = this.cumulativeInfo.constructionStartDate;
    report.constructionEndDate = this.cumulativeInfo.constructionEndDate;
    return report;
  }

  private timeAgo(date: Date) {
    if (new Date(date) >= new Date()) {
      return null;
    }
    return moment(date).fromNow();
  }

  private addTelemetryEvent(assetId: number): void {
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
      if (this.isEditMode) {
        this.telemetryService.create({
          eventID: '404',
          eventTitle: 'GP EDIT REPORT',
          holdingID: assetId,
          organizationID: clientDetails.organizationDetails.id
        }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
      } else {
        this.telemetryService.create({
          eventID: '401',
          eventTitle: 'GP SHARED REPORT (COMPLETED)',
          holdingID: assetId,
          organizationID: clientDetails.organizationDetails.id
        }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
      }
    });
  }
}
