import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DocumentsTabService } from '../documents-tab/documents-tab.service';
import { StorageObjectReqRes } from 'src/app/shared/models/StorageObjectReqRes.model';
import { ErrorMatcher, ErrorType } from 'src/app/shared/errors/ErrorMatcher';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';

@Component({
  selector: 'terra-rename-storage-object-dialog',
  templateUrl: './rename-storage-object-dialog.component.html',
  styleUrls: ['./rename-storage-object-dialog.component.scss']
})
export class RenameStorageObjectDialogComponent implements OnInit {

  pageForm: UntypedFormGroup;
  isAttemptSubmit = false;
  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;

  constructor(
    public dialogRef: MatDialogRef<RenameStorageObjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public storageObject: StorageObjectReqRes,
    private fb: UntypedFormBuilder,
    private documentsTabService: DocumentsTabService,
    private utilsService: UtilsService) {
      dialogRef.addPanelClass('rename-storage-object-dialog');
  }

  ngOnInit() {
    this.pageForm = this.fb.group({
      displayName: [this.storageObject.displayName, [Validators.required, Validators.maxLength(this.documentsTabService.maxDisplayNameLength)]]
    });
  }

  save() {
    this.isAttemptSubmit = true;
    this.isGeneralServerError = false;

    if (!this.pageForm.valid) {
      return;
    }

    const newFilename = this.pageForm.get('displayName').value;

    this.documentsTabService.rename(this.storageObject.id, newFilename)
      .subscribe(
        response => {
          this.dialogRef.close(newFilename);
        },
        error => {
          if (ErrorMatcher.isError(error, ErrorType.AlreadyExistsException)) {
            this.pageForm.get('displayName').setErrors({ nameAlreadyExist: true });
          } else {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            } else {
              this.isGeneralServerError = true;
            }
          }
        });
  }
}
