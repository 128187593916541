<div class="form-grid-new">
  <div class="total-box full-width">
    @if(loading){
    <terra-loader></terra-loader>
    }
    @else{
    <!--  Multi Type Breakdown  -->
    <ng-container *ngIf="reasonsForTransfer?.length > 1">
      <div class="list-item" *ngFor="let reasonForTransfer of reasonsForTransfer">
        <span>Total {{reasonForTransfer | enumString: DistributionTransactionPurpose}} distribution</span>
        <span>{{ feeSymbol }}{{(getTotalAmountForTransferType(reasonForTransfer) | terraCurrency) || 0}} {{ feeCurrency
          | hideUsdIso }}</span>
      </div>
    </ng-container>
    <div class="list-item">
      <span>Total distributed via Covercy</span>
      <span>{{ feeSymbol }}{{(totalAmountViaCovercy | terraCurrency) || 0 }} {{ feeCurrency | hideUsdIso }}</span>
    </div>
    @if(!!totalAdjustmentsAmountViaCovercy){
    <div class="list-item adjustment">
      <span>Payment adjustment from total</span>
      <span>{{ feeSymbol }}{{(totalAdjustmentsAmountViaCovercy | terraCurrency) || 0 }} {{ feeCurrency | hideUsdIso
        }}</span>
    </div>
    }
    <div class="list-item" *ngIf="totalAmountOutsideOfCovercy">
      <span>Total distributed outside of Covercy</span>
      <span>{{ feeSymbol }}{{(totalAmountOutsideOfCovercy | terraCurrency) || 0 }} {{ feeCurrency | hideUsdIso }}</span>
    </div>
    @if(!!totalAmountOutsideOfCovercy && !!totalAdjustmentsAmountOutsideOfCovercy){
    <div class="list-item adjustment" *ngIf="totalAmountOutsideOfCovercy">
      <span>Payment adjustment from total</span>
      <span>{{ feeSymbol }}{{(totalAdjustmentsAmountOutsideOfCovercy | terraCurrency) || 0 }} {{ feeCurrency |
        hideUsdIso }}</span>
    </div>
    }
    @if(!!withheldTaxes){
    <div class="list-item">
      <span class="label">Withheld taxes</span>
      <span>{{ feeSymbol }}{{withheldTaxes | terraCurrency }} {{ feeCurrency | hideUsdIso }}</span>
    </div>
    }
    <div class="list-item dist-total" *ngIf="totalAmountOutsideOfCovercy">
      <span class="label">Total</span>
      <span>{{ feeSymbol }}{{total | terraCurrency }} {{ feeCurrency | hideUsdIso }}</span>
    </div>

    <!-- Fees -->
    <ng-container *ngIf="isDistributingAnyAmountViaCovercy">
      <div class="list-item fees-total" *ngIf="unitFees != null">
        <span>Bank fees ${{ unitFees }}</span>
      </div>

      <!-- When fees are estimated -->
      <div class="list-item fees-total" *ngIf="isFeeEstimated; else lockedFees">
        <span class="label">Covercy fees (estimated)
          <mat-icon class="notranslate tooltip-icon" color="primary" matTooltipClass="terra-tooltip"
            matTooltipPosition="right" [matTooltip]="getFeesTooltipText()">help</mat-icon>
        </span>
        <span>{{ feeSymbol }}{{ (distributionTotalFee | terraCurrency) || 0 }} {{ feeCurrency | hideUsdIso }}</span>
      </div>

      <!-- When fees are locked -->
      <ng-template #lockedFees>
        <div class="list-item fees-total">
          <span class="label">Covercy fees
            <mat-icon class="notranslate tooltip-icon" color="primary" matTooltipClass="terra-tooltip"
              matTooltipPosition="right" [matTooltip]="getFeesTooltipText()">help</mat-icon>
          </span>
          <span>
            {{ feeSymbol }}{{ (distributionTotalFee | terraCurrency) || 0 }} {{ feeCurrency | hideUsdIso }}
          </span>
        </div>
      </ng-template>
    </ng-container>

    <!-- Display the amount to deposit until it has been deposited -->
    <div class="total-row list-item" *ngIf="showTotalDepositedCovercyLabel()">
      <span *ngIf="isFeeEstimated">Total to be deposited to Covercy (estimated)</span>
      <span *ngIf="!isFeeEstimated">Total to be deposited to Covercy</span>
      <span>{{ feeSymbol }}{{ (totalAmountAfterFees + unitFees) | terraCurrency }} {{ feeCurrency | hideUsdIso}}</span>
    </div>

    <span class="estimated-note info-message mat-elevation-z2"
      *ngIf="isFeeEstimated && isDistributingAnyAmountViaCovercy">
      Fees and amount to deposit will be locked once the distribution is finalized and payout made.
    </span>

    }
  </div>
</div>