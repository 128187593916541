import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { take } from 'rxjs';
import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { UserService } from 'src/app/services/shared/user.service';
import { StorageObjectReqRes } from 'src/app/shared/models/StorageObjectReqRes.model';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';
import { GpHoldingService } from '../../../gp-holding.service';
import { UploadDocumentsService } from '../upload-documents.service';

@Component({
  selector: 'terra-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // standalone
})
export class FilePreviewComponent {
  @Input() file: StorageObjectReqRes;

  holdingId$ = this.gpHoldingService.holdingId$;
  constructor(
    private readonly uploadDocumentsService: UploadDocumentsService,
    private readonly storageObjectDataService: StorageObjectDataService,
    private readonly gpHoldingService: GpHoldingService,
    private readonly telemetryService: TelemetryService,
    private readonly userService: UserService
  ) {}

  openPreview() {
    this.uploadDocumentsService.getFilestackPolicy().subscribe((policy) => {
      this.file.filestackPolicy = policy.filestackPolicy;
      this.file.filestackSignature = policy.filestackSignature;
      this.storageObjectDataService.previewFile(this.file, 'dialog'); // TODO: use just modalComponent
    });

    this.holdingId$.pipe(take(1)).subscribe((holdingId) => {
      this.userService
        .getClientDetails()
        .pipe(take(1))
        .subscribe((clientDetails) => {
          this.telemetryService.create(
            {
              eventID: '4012',
              eventTitle: 'Holding-Upload-and-Share-File-Preview',
              holdingID: holdingId,
              organizationID: clientDetails.organizationDetails.id,
            },
            AnalyticsServiceNameModel.Mixpanel |
              AnalyticsServiceNameModel.Insights
          );
        });
    });
  }
}
