<ng-container *ngIf="{
    creApplication: creApplication$ | async,
    bankAccounts: bankAccounts$ | async,
    hideBanking: nonAdminHideBanking$ | async,
    disableBankActions: nonAdminViewBanking$ | async
  } as context">

  <div class="bank-card-title">
    <span>Bank Accounts</span>
  </div>

  <terra-loader *ngIf="isLoading$ | async else content" class="loader"></terra-loader>

  <ng-template #content>
    <!-- No application and hovering -->
    <ng-container *ngIf="(!context.bankAccounts.length && !context.creApplication) else hasApplication">
      <div terraCheckPermissions [minimumFinancialPermissionLevel]="FinancialPermissionLevel.NonSignatory" [noPermissionAction]="NoPermissionAction.Blur"
           class="no-bank-account-wrapper">
           <terra-name-icon class="icon" svgIcon="add_bank_account"></terra-name-icon>
           <div class="text-description">Connect or open a FREE bank account
            earning up to 3.84% APY. <a href="https://www.covercy.com/cre-banking/" class="underline learn-more">Learn more</a></div>
      </div>
    </ng-container>

    <!-- Has application or not hovering -->
    <ng-template #hasApplication>
      <div class="bank-account-wrapper" [ngClass]="{ blurText: context.hideBanking}">
        <div *ngIf="context.creApplication && context.creApplication.status !== UnitApplicationStatus.Approved" class="info-row">
          <div class="name-row">{{('[Application] ' + context.creApplication.holding?.name || 'New Bank Account') | truncate : 30 : true}}</div>
          <span class="value current-balance" [ngClass]="'bank-' + getBankStatusClass(context.creApplication.status)">
            {{ getStatusText(context.creApplication.status) }}
          </span>
        </div>
        <a *ngFor="let account of context.bankAccounts" class="bank-row" mat-button [routerLink]="context.hideBanking ? null : goToAccount(account)">
          <div class="info-row">
            <div class="name-row">
              <mat-icon
                class="notranslate material-symbols-rounded"
                [svgIcon]="account.accountProvider === BankAccountProvider.UnitAccount ? null : 'link'">
                {{ account.accountProvider === BankAccountProvider.UnitAccount ? 'account_balance' : null }}
              </mat-icon>
              {{ account.accountNickname | truncate : 30 : true }}
            </div>
            <span class="value current-balance">${{ account.balance | terraNumber }}</span>
          </div>
        </a>
      </div>
    </ng-template>
    <div class="flex justify-content-between w-full">
      <terra-buttons
        type="secondary"
        label="Connect Account"
        icon="link"
        [outlinedIcon]="true"
        [loading]="isRefreshingLinkToken$ | async"
        (click)="connectAccount()"
        [disabledBtn]="context.disableBankActions || context.hideBanking"
      ></terra-buttons>

      <terra-buttons
        type="primary"
        label="Open Bank Account"
        icon="add"
        [outlinedIcon]="true"
        [disabledBtn]="context.disableBankActions || context.hideBanking || (context.creApplication && context.creApplication.status !== UnitApplicationStatus.Approved)"
        (click)="context.creApplication ? createCreBankAccount() : createApplication()"
      ></terra-buttons>
    </div>
  </ng-template>
</ng-container>
