<terra-dialog-header>
  <h1 class="lo_sensitive">Send distribution notices to investors?</h1>
</terra-dialog-header>

<div mat-dialog-content [formGroup]="pageForm">
  <p>
    This will notify all relevant investors about the upcoming distribution and will allow them to update their bank
    details and currency
    preference.
  </p>

  <!-- Personal message -->
  <label for="txtMessage" class="text-input-label">Add a personal message (optional):</label>
  <quill-editor id="txtMessage" formControlName="personalMessage" format="html" class="lo_sensitive personal-message" placeholder="" />

  <label class="text-input-label" *ngIf="context.transfers?.length">Email preview:</label>

  <!-- Email carousel -->
  <div class="email-carousel gray-theme" *ngIf="context.transfers?.length">
    <!-- navigation - left -->
    <section class="navigation left">
      <button mat-fab *ngIf="isMultipleTransfer" (click)="previousExample()" aria-label="Previous investor"
              class="nav-button mat-elevation-z2">
        <mat-icon class="notranslate">keyboard_arrow_left</mat-icon>
      </button>
    </section>
    <!-- Email preview  -->
    <section class="email" *ngIf="context.transfers[exampleIndex] as transfer">
      <p>Hi {{ transfer.investingEntity.contact.firstName}},</p>
      <p><strong>This is your distribution notice for:</strong></p>
      <ul class="details-list">
        <li>{{ transfer.investingEntity.name}},</li>
        <li>Holding name: {{context.holdingName}}</li>
        <li>{{ context.distributionName }}</li>
        <!-- <li>Total distribution to investors: {{ context.totalAmountForDistribution | terraCurrency}}
          {{context.distributionSourceCurrency.iso}}</li> -->
        <li>Distribution currency: {{context.distributionSourceCurrency.iso }}</li>

        <ng-container *ngFor="let transferDetails of transfer.distributionTransfersDetails; let i = index">
          <br>
          <li class="underline">
            Type:
            {{transferDetails.reasonForTransaction | enumString: DistributionType}}
          </li>
          <li *ngIf="transferDetails.taxes || transferDetails.adjustments">
            {{isMultiTypeDistribution ? 'Gross amount' : 'Gross distribution'}}:
            {{context.distributionSourceCurrency.symbol}}{{transferDetails.amountGross | terraCurrency}} {{context.distributionSourceCurrency.iso | hideUsdIso}}
          </li>
          <li *ngIf="transferDetails.taxes">
            Withheld taxes:
            {{context.distributionSourceCurrency.symbol}}{{transferDetails.taxes | terraCurrency }} {{context.distributionSourceCurrency.iso | hideUsdIso}}
          </li>
          <li *ngIf="transferDetails.adjustments">
            Adjustments:
            {{context.distributionSourceCurrency.symbol}}{{transferDetails.adjustments | terraCurrency }} {{context.distributionSourceCurrency.iso | hideUsdIso}}
          </li>
          <li *ngIf="transferDetails.adjustmentsComments">
            Adjustments comments:
            {{transferDetails.adjustmentsComments}}
          </li>
          <li>
            {{isMultiTypeDistribution ? 'Net amount' : 'Net distribution'}}:
            {{context.distributionSourceCurrency.symbol}}{{transferDetails.amountNet | terraCurrency }} {{context.distributionSourceCurrency.iso | hideUsdIso}}
          </li>
          <br *ngIf="i + 1 === transfer.distributionTransfersDetails.length">
        </ng-container>

        <ng-container *ngIf="isMultiTypeDistribution">
          <strong class="underline">Total distribution:</strong>
          <li *ngIf="transfer.taxes || transfer.adjustments">
            Gross distribution:
            {{context.distributionSourceCurrency.symbol}}{{transfer.amountGross | terraCurrency}} {{ context.distributionSourceCurrency.iso | hideUsdIso}}
          </li>
          <li *ngIf="transfer.taxes">
            Withheld taxes:
            {{context.distributionSourceCurrency.symbol}}{{transfer.taxes | terraCurrency }} {{ context.distributionSourceCurrency.iso | hideUsdIso}}
          </li>
          <li *ngIf="transfer.adjustments">
            Adjustments:
            {{context.distributionSourceCurrency.symbol}}{{transfer.adjustments | terraCurrency }} {{ context.distributionSourceCurrency.iso | hideUsdIso}}
          </li>
          <li>
            <strong>
              Net distribution:
              {{context.distributionSourceCurrency.symbol}}{{transfer.amountNet | terraCurrency }} {{ context.distributionSourceCurrency.iso | hideUsdIso}}
            </strong>
          </li>
        </ng-container>
        <!--        <li *ngIf="transfer.unitTransactionFee && !transfer.externalPayment">-->
        <!--          Bank transaction fee: ${{ transfer.unitTransactionFee | terraCurrency }}-->
        <!--        </li>-->
      </ul>

      <!-- Deposit bank account text -->
      <p *ngIf="transfer.clientBankAccount as bankAccount">
        This distribution will be deposited in {{bankAccount.preferredCurrency.iso}} into your predefined bank account
        ending
        with ****{{ (bankAccount.accountNumber ? bankAccount.accountNumber : bankAccount.iban)?.slice(-4) }}.<br/>
        <ng-container *ngIf="context.allowLpSetBank">
          Note: If you wish to have this distribution deposited into a different bank account or in a different currency,
          you may
          <span class="example-link">update your bank account</span>
          before {{ (gpDetails$ | async).firstName }} executes the payment.
        </ng-container>
      </p>

      <p *ngIf="transfer.unitBankAccount as unitBankAccount">
        This distribution will be deposited in USD into your predefined bank account
        ending
        with ****{{ unitBankAccount.accountNumber?.slice(-4) }}.<br/>
        <ng-container *ngIf="context.allowLpSetBank">
          Note: If you wish to have this distribution deposited into a different bank account or in a different currency,
          you may
          <span class="example-link">update your bank account</span>
          before {{ (gpDetails$ | async).firstName }} executes the payment.
        </ng-container>
      </p>

      <p *ngIf="!transfer.clientBankAccount && !transfer.unitBankAccount && context.allowLpSetBank">
        <strong>Attention: Please add your bank details to your account
          so {{ (gpDetails$ | async).organizationDetails.name}} can send the
          distribution funds. <br/><br/>
          <span class="example-link">Add your bank details now</span>
        </strong>
      </p>

      <ng-container *ngIf="gpDetails$ | async as gpDetails">
        <ng-container *ngIf="pageForm.get('personalMessage').value">
          <div [innerHTML]="pageForm.get('personalMessage').value | safeHtml"></div>
        </ng-container>
        <p>
          If you have any questions or concerns about the distribution payment details above,
          please reach out to {{gpDetails.firstName}} {{gpDetails.lastName}}, {{gpDetails.organizationDetails.name}}
          directly.
        </p>
        <p>* The amount is not final</p>
      </ng-container>

    </section>
    <!-- navigation - right -->
    <section class="navigation right">
      <button mat-fab *ngIf="isMultipleTransfer" (click)="nextExample()" aria-label="Next investor"
              class="nav-button mat-elevation-z2">
        <mat-icon class="notranslate">keyboard_arrow_right</mat-icon>
      </button>
    </section>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-flat-button type="button" (click)="send()" form="dialogForm" color="accent"
          class="page-cta">
    Send
    <mat-icon svgIcon="publish" class="notranslate publish-icon"></mat-icon>
  </button>

  <button mat-button type="button" class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</div>
