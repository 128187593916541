import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';

import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import {PaymentStatus} from 'src/app/shared/enums/PaymentStatus.enum';
import PaymentPlatform from 'src/app/shared/enums/PaymentPlatform.enum';

@Component({
  selector: 'terra-payment-request-status-block',
  templateUrl: './payment-request-status-block.component.html',
  styleUrls: ['./payment-request-status-block.component.scss']
})
export class PaymentRequestStatusBlockComponent implements OnInit {

  @Input()
  investment: InvestmentReqRes;

  @Input()
  bankAccountNickname: string = 'bank account';

  paymentStatusEnum = PaymentStatus;

  get error(): string {
    switch (this.investment.paymentStatus) {
      case (PaymentStatus.Canceled):
        return 'The transfer was canceled. Please contact your client for more information.';
      case (PaymentStatus.Other):
        return 'Cannot determine status. Please contact our support team.';
      default:
        return null;
    }
  }

  get sentStatus(): string {
    if (!this.investment.paymentRequestSendDate){
      return 'inactive';
    }
    if (!!this.investment.paymentRequestSendDate && !this.investment.capitalCallViewDate){
      return 'current';
    }
    if (!!this.investment.paymentRequestSendDate && !!this.investment.capitalCallViewDate){
      return 'active';
    }
  }

  get sentDate(): string {
    if (!this.investment.paymentRequestSendDate){
      return 'Awaiting your action';
    }
    if (!!this.investment.paymentRequestSendDate){
      return this.datePipe.transform(this.investment.paymentRequestSendDate);
    }
    else {
      return 'Date unavailable';
    }
  }

  get viewedStatus(): string {
    if (!this.investment.capitalCallViewDate){
      return 'inactive';
    }
    if (!!this.investment.capitalCallViewDate && this.submittedStatus === 'inactive'){
      return 'current';
    }
    if (!!this.investment.capitalCallViewDate && this.submittedStatus !== 'inactive'){
      return 'active';
    }
  }

  get viewedDate(): string {
    if (!this.investment.paymentRequestSendDate){
      return '';
    }
    if (!this.investment.capitalCallViewDate){
      return 'Awaiting Investor action';
    }
    if (!!this.investment.capitalCallViewDate && this.submittedDate === 'inactive'){
      return this.datePipe.transform(this.investment.capitalCallViewDate);
    }
    if (!!this.investment.capitalCallViewDate && this.submittedDate !== 'inactive'){
      return this.datePipe.transform(this.investment.capitalCallViewDate);
    }
  }

  get submittedStatus(): string {
    switch (this.investment.paymentStatus) {
      case (PaymentStatus.NotSent):
      case (PaymentStatus.Other):
      case (PaymentStatus.Canceled):
      case (PaymentStatus.Sent):
        return 'inactive';
      case (PaymentStatus.Submitted):
      case (PaymentStatus.FundsReceived):
        return 'current';
      case (PaymentStatus.Approved):
      case (PaymentStatus.Completed):
        return 'active';
    }
  }

  get submittedDate(): string {
    switch (this.investment.paymentStatus) {
      case (PaymentStatus.NotSent):
      case (PaymentStatus.Other):
      case (PaymentStatus.Canceled):
        return '';
      case (PaymentStatus.Sent):
        return 'Awaiting investor action';
      case (PaymentStatus.Submitted):
      case (PaymentStatus.FundsReceived):
      case (PaymentStatus.Approved):
      case (PaymentStatus.Completed):
        return this.datePipe.transform(this.investment.paymentStatusDates[PaymentStatus.Submitted]);
    }
  }

  get submittedTitle(): string{
    if (this.investment.isOrderCreated && this.submittedStatus !== 'inactive'){
      return 'Transfer submitted on Covercy Pay payment platform';
    }
    else if (this.investment.paymentSettlementPlatform === PaymentPlatform.achDebit && this.submittedStatus !== 'inactive'){
      return 'ACH debit transaction was initiated';
    }
    return 'Transfer submitted';
  }

  get fundsReceivedStatus(): string {
    switch (this.investment.paymentStatus) {
      case (PaymentStatus.NotSent):
      case (PaymentStatus.Other):
      case (PaymentStatus.Canceled):
      case (PaymentStatus.Sent):
      case (PaymentStatus.Submitted):
        return 'inactive';
      case (PaymentStatus.FundsReceived):
        return 'current';
      case (PaymentStatus.Approved):
        return this.investment.isPaymentReversed ? 'inactive' : 'active';
      case (PaymentStatus.Completed):
        return 'active';
    }
  }

  get fundsReceivedDate(): string {
    switch (this.investment.paymentStatus) {
      case (PaymentStatus.NotSent):
      case (PaymentStatus.Other):
      case (PaymentStatus.Canceled):
      case (PaymentStatus.Sent):
        return '';
      case (PaymentStatus.Submitted):
        return this.investment.isPaymentReversed ? '' : 'Awaiting investor to deposit funds';
      case (PaymentStatus.Approved):
        return this.investment.isPaymentReversed ? 'Awaiting investor to deposit funds' : this.datePipe.transform(this.investment.paymentStatusDates[PaymentStatus.FundsReceived]);
      case (PaymentStatus.FundsReceived):
      case (PaymentStatus.Completed):
        return this.datePipe.transform(this.investment.paymentStatusDates[PaymentStatus.FundsReceived]);
    }
  }

  get approvedStatus(): string {
    switch (this.investment.paymentStatus) {
      case (PaymentStatus.NotSent):
      case (PaymentStatus.Other):
      case (PaymentStatus.Canceled):
      case (PaymentStatus.Sent):
      case (PaymentStatus.Submitted):
        return 'inactive';
      case (PaymentStatus.FundsReceived):
        return this.investment.isPaymentReversed ? 'active' : 'inactive';
      case (PaymentStatus.Approved):
        return 'current';
      case (PaymentStatus.Completed):
        return 'active';
    }
  }

  get approvedTitle(): string{
    if (this.investment.isOrderCreated && this.approvedDate !== 'inactive'){
      return 'Transfer approved and guaranteed by Covercy';
    }
    else if (this.investment.paymentSettlementPlatform === PaymentPlatform.achDebit && this.approvedDate !== 'inactive'){
      return 'ACH debit transfer was approved';
    }
    return 'Transfer approved';
  }

  get approvedDate(): string {
    switch (this.investment.paymentStatus) {
      case (PaymentStatus.NotSent):
      case (PaymentStatus.Other):
      case (PaymentStatus.Canceled):
      case (PaymentStatus.Sent):
        return '';
      case (PaymentStatus.Submitted):
        if (this.investment.isOrderCreated && this.investment.isPaymentReversed ){
          return 'Expected approval by ' + this.datePipe.transform(this.investment.paymentStatusDates[PaymentStatus.Approved]);
        }
        return '';
      case (PaymentStatus.FundsReceived):
        if (this.investment.isOrderCreated){
          return this.investment.isPaymentReversed ? this.datePipe.transform(this.investment.paymentStatusDates[PaymentStatus.Approved]) :
            'Expected approval by ' + this.datePipe.transform(this.investment.paymentStatusDates[PaymentStatus.Approved]);
        }
        return '';
      case (PaymentStatus.Approved):
      case (PaymentStatus.Completed):
        return this.datePipe.transform(this.investment.paymentStatusDates[PaymentStatus.Approved]);
    }
  }

  get completedStatus(): string {
    switch (this.investment.paymentStatus) {
      case (PaymentStatus.NotSent):
      case (PaymentStatus.Other):
      case (PaymentStatus.Canceled):
      case (PaymentStatus.Sent):
      case (PaymentStatus.Submitted):
      case (PaymentStatus.FundsReceived):
      case (PaymentStatus.Approved):
        return 'inactive';
      case (PaymentStatus.Completed):
        return 'current';
    }
  }

  get completedDate(): [string, string] {
    switch (this.investment.paymentStatus) {
      case (PaymentStatus.NotSent):
      case (PaymentStatus.Other):
      case (PaymentStatus.Canceled):
      case (PaymentStatus.Sent):
      case (PaymentStatus.Submitted):
        return ['', ''];
      case (PaymentStatus.FundsReceived):
        if (this.investment.isOrderCreated && this.investment.isPaymentReversed){
          return ['Processing payment to ' + this.bankAccountNickname, 'Expected by ' + this.datePipe.transform(this.investment.paymentStatusDates[PaymentStatus.Completed])];
        }
        return ['', ''];
      case (PaymentStatus.Approved):
        if (this.investment.isOrderCreated && !this.investment.isPaymentReversed){
          return  ['Processing payment to ' + this.bankAccountNickname, 'Expected by ' + this.datePipe.transform(this.investment.paymentStatusDates[PaymentStatus.Completed])];
        }
        return ['', ''];
      case (PaymentStatus.Completed):
        return [this.datePipe.transform(this.investment.paymentStatusDates[PaymentStatus.Completed]), ''];
    }
  }

  get isSettledExternal(): boolean {
    return this.investment.paymentSettlementPlatform === PaymentPlatform.External;
  }

  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
  }
}

