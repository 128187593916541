<div class="amounts-wrapper" [ngClass]="{'multi-reasons': !isSingleReasonForTransfer}">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let distributionPurpose of distributionPurposes; let i = index" [hideToggle]="isSingleReasonForTransfer"
                         [expanded]="isSingleReasonForTransfer" [ngClass]="{'single-reason': isSingleReasonForTransfer}">
      <mat-expansion-panel-header [collapsedHeight]="isSingleReasonForTransfer ? '0' : '50px'" [expandedHeight]="isSingleReasonForTransfer ? '0' : '50px'">
        <mat-panel-title *ngIf="!isSingleReasonForTransfer">
          <span>
            <span class="transfer-type-number">{{i + 1}}</span>
            <span class="transfer-type-string">{{distributionPurpose | enumString: DistributionTransactionPurpose}}</span>
          </span>
          <span class="reason-amount">
            {{ distributionCurrencySymbol }}{{getTransferDetails(i).amountNet | terraCurrency}} {{ distributionCurrencyIso | hideUsdIso }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <terra-distribution-transfer-amounts [distributionCurrencyIso]="distributionCurrencyIso"
                                             [distributionCurrencySymbol]="distributionCurrencySymbol"
                                             [importedDistribution]="importedDistribution"
                                             [disableEditMode]="disableEditMode && !distributionTransfer.externalPayment"
                                             [isViewMode]="isViewMode"
                                             [distributionTransferDetails]="getTransferDetails(i)"
                                             [distributionTransfer]="distributionTransfer"
                                             [transactionPurpose]="distributionPurpose"
                                             [updateFormFromModel]="updateFormFromModel"
                                             [updateGpPromote]="updateGpPromote"
                                             [investingEntityId]="distributionTransfer.investingEntityId"
                                             [revertAllAmountChanges]="revertAllAmountChanges$"
                                             (amountChange)="transferDetailsAmountsChanged$.next($event)"
                                             [disableGrossAmount]="disableGrossAmount"
                                             [waterfallCalculated]="waterfallCalculated">
        </terra-distribution-transfer-amounts>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<form [formGroup]="pageForm" *ngIf="{isSaving: isSaving$ | async} as data" class="form-grid-new terra-error-style"
      (keydown.enter)="$event.preventDefault()">

  <!-- Reference Memo -->
  <div class="terra-form-field-wrapper new-column">
    <label for="txtReferenceMemo_{{distributionTransfer.investingEntityId}}" class="text-input-label">Reference memo</label>
    <mat-form-field appearance="outline">
      <input matInput id="txtReferenceMemo_{{distributionTransfer.investingEntityId}}" type="text" formControlName="referenceMemo" class="lo_sen nsitive"/>
    </mat-form-field>
  </div>

  <!-- Payment Date -->
  <div class="terra-form-field-wrapper new-column" *ngIf="pageForm.get('paymentsViaCovercy').value === false">
    <terra-custom-utc-date-picker 
    formControlName="paymentSettlementDate"
    id="txtPaymentSettlementDate" 
    [label]="'Payment date' + (distributionStatus !== DistributionStatus.Completed ? ' (optional)' : '')"
    [requiredError]="pageForm.get('paymentSettlementDate').hasError('required')"></terra-custom-utc-date-picker>
  </div>

  <!-- External Payment -->
  <div class="terra-form-field-wrapper full-width checkbox-row">
    <mat-slide-toggle hideIcon labelPosition="before" formControlName="paymentsViaCovercy">
    </mat-slide-toggle>
    <span>
      Payment to be made via Covercy
    </span>
  </div>

  <!-- Bank account -->
  <!-- notice: bank account is NOT allowed for selection when the distribution is in edit mode, and is after the NoticeSent status  -->
  @if(showBankInfo$ | async){
    @if(allowEditBankAccount){
      <div class="full-width bank-account-with-add-new">
        <div class="terra-form-field-wrapper half-width">
          <label for="ddlBankAccount" *ngIf="isThisGPDistributeTransfer === false" class="text-input-label">
            Choose from {{ bankAccounts.length }} existing investor's accounts
          </label>
          <label for="ddlBankAccount" *ngIf="isThisGPDistributeTransfer === true" class="text-input-label">Choose from {{ bankAccounts.length }} existing GP accounts</label>
          <mat-form-field appearance="outline" class="bank-account-form-field">
            <mat-select [disableControl]="data.isSaving || isParentSaving"
                        *ngIf="(organizationDetailsId$ | async) as organizationDetailsId" id="ddlBankAccount"
                        [compareWith]="bankAccountComparer"
                        [placeholder]="(bankAccounts.length) ? '- Leave unspecified -' : '- Leave unspecified -'"
                        formControlName="bankAccount">
              <mat-option [value]="null">- Leave unspecified -</mat-option>
    
              <!-- The bank account was created by the LP if the ClientDetailsId on it is the Id of the LP. -->
              <mat-option *ngFor="let bankAccount of bankAccounts" #tooltip="matTooltip"
                          [ngClass]="{'unit-account-item': bankAccount.accountType === AccountType.CovercyAccount}"
                          matTooltip="{{bankAccounts.length > 1 ? getBankAccountDetails(bankAccount) : tooltip.hide()}}"
                          matTooltipPosition="right"
                          matTooltipClass="terra-bank-account-details-tooltip" [value]="bankAccount">
                {{ bankAccount?.nickname || bankAccount?.accountNickname }} {{ !bankAccount?.nickname ? bankAccount.holderFullName : '' }}
                {{ lpClientDetailsId && bankAccount?.clientDetailsId === lpClientDetailsId ? '(Provided by investor)' : '' }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="pageForm.get('bankAccount').hasError('required')"> Required</mat-error>
          </mat-form-field>
          <span class="missing-info-notice" *ngIf="isApproveBankAccount"> The Investor updated/added the bank details, please review.</span>
          <span *ngIf="!selectedBankAccount && isThisGPDistributeTransfer === false">Leave unspecified to let investor update this.</span>
        </div>
        <!-- Add new bank account - only if the drop down is also enabled -->
        <span class="add-bank-account"> - or -
          <a *ngIf="pageForm.get('bankAccount').enabled" class="link" (click)="addBankAccount()">Add a new account</a>
        </span>
      </div>

      <!-- Set missing preferred currency for bank account   -->
      <ng-container *ngIf="!!selectedBankAccount && !selectedBankAccount.preferredCurrencyId && selectedBankAccount?.accountType !== AccountType.CovercyAccount">
        <!-- Preferred currency -->
        <label>Select the currency for distributions payments</label>
        <div class="terra-form-field-wrapper new-row">
          <mat-form-field appearance="outline" class="currency-ddl-wrapper">
            <mat-select placeholder="Select" formControlName="preferredCurrency" [compareWith]="currencyCompareFn">
              <mat-option *ngFor="let currency of outboundCurrencies$ | async" [value]="currency">
                {{ currency.symbol }} {{ currency.iso }}</mat-option>
            </mat-select>
            <mat-error *ngIf="pageForm.get('preferredCurrency').hasError('required')">
              Required
            </mat-error>
          </mat-form-field>
        </div>
        <button type="submit" class="set-currency-button" mat-flat-button color="primary"
                [disabled]="savingCurrency$ | async" (click)="setPreferredCurrency()">
          <span>Save Preferred Currency</span>
          <terra-loader *ngIf="savingCurrency$ | async"></terra-loader>
        </button>
  
        <p class="error-message full-width" *ngIf="isCurrencyError">Couldn't set preferred currency. Please contact support.</p>
      </ng-container>
    }
  
    <ng-container *ngIf="selectedBankAccount">
      <!-- Bank account details -->
      <div class="new-row">
        <!-- when bank account is allowed for selection, there is no need for the label, as it is clear from the context. -->
        <label *ngIf="(disableEditMode && !distributionTransfer.externalPayment) || isViewMode">Bank account details:</label>
        <!-- Selected bank account details -->
        <ul class="short-bank-details-list">
          <li *ngIf="selectedBankAccount?.accountNumber">
            <span
              class="label">Account number that ends with: </span>{{ (selectedBankAccount?.accountNumber || '') | accountNumberToAstrix : 4 }}
          </li>
          <li *ngIf="selectedBankAccount?.holderFullName">
            <span class="label">On the name of: </span>{{ selectedBankAccount?.holderFullName }}
          </li>
          <li>
            <span class="label">Destination currency: </span> {{ selectedBankAccount?.iso }}
            <span class="missing-bank-account" *ngIf="!selectedBankAccount?.preferredCurrencyId && selectedBankAccount?.accountType !== AccountType.CovercyAccount">MISSING</span>
          </li>
          <li *ngIf="selectedBankAccount?.countryName">
            <span class="label">Destination country: </span>{{ selectedBankAccount?.countryName }}
          </li>
          <li *ngIf="selectedBankAccount?.paymentType">
            <span class="label">Payment method: </span>{{ selectedBankAccount.paymentType | enumString:PaymentType}}
          </li>
          <li *ngIf="(organizationDetailsId$ | async) as organizationDetailsId">
            <span class="label">Details provided by:
            </span>{{ lpClientDetailsId && selectedBankAccount.clientDetailsId === lpClientDetailsId ? 'Investor' : 'GP' }}
          </li>
          <li *ngIf="selectedBankAccount?.accountType !== AccountType.CovercyAccount">
            <a class="link" (click)="showClientBankAccountDetails(selectedBankAccount?.id)">View Full Bank Account
              Details</a>
          </li>
        </ul>
      </div>
  
      <div class="full-width new-row" *ngIf="!(showBankInfo$ | async)">
        <label>Bank account details:</label>
        <div class="not-allow-message">
          <mat-icon class="notranslate">error</mat-icon>
          <h4>{{noAccessMessage}}</h4>
        </div>
      </div>
    </ng-container>
  }
  

  <!-- Actions: Save / revert bank account change -->
  <div class="actions full-width" *ngIf="isEditMode || isCreateMode">
    <!-- Approve bank account action -->
    <button *ngIf="isApproveBankAccount" type="button"
            [disabled]="data.isSaving || isParentSaving" mat-flat-button color="primary"
            class="approve-bank-account-button" (click)="approveBankAccount()">
      <span>Approve Bank Account</span>
      <terra-loader *ngIf="data.isSaving || isParentSaving"></terra-loader>
    </button>
    <button *ngIf="isApproveBankAccount && (distributionTransfer.previuosClientBankAccountId > 0)" type="button"
            [disabled]="data.isSaving || isParentSaving" mat-flat-button
            class="ignore-bank-account-button" (click)="ignoreBankAccount()">
      <span>Ignore</span>
    </button>
  </div>

  <!-- Error message -->
  <p *ngIf="isServerError" class="error-message full-width">Couldn't save transfer. Please contact
    <a target="_blank" class="light link" mat-button href="//www.covercy.com/contact-us">support</a>.
  </p>
</form>
