import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';

import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import {ClientBankAccountResponseBasic} from 'src/app/dashboard/models/bankAccount.model';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ResourceService} from 'src/app/services/resource.service';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {FundInvestmentStrategyType} from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import {CreateFundService, CreateFundStepNumber} from '../create-fund.service';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import {CreateFundStepBase} from '../CreateFundStepBaseAndInterface';
import {InvestmentSecurityType} from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {AddressFields, AddressFormSettings} from 'src/app/shared/components/address-form/address-form.settings';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {UserService} from 'src/app/services/shared/user.service';
import { OwnershipCalculationType } from 'src/app/shared/enums/OwnershipCalculationType.enum';

@Component({
  selector: 'terra-create-fund-information-step',
  templateUrl: './create-fund-information-step.component.html',
  styleUrls: ['./create-fund-information-step.component.scss']
})
export class CreateFundInformationStepComponent extends CreateFundStepBase implements OnInit {

  readonly STEP_NUMBER: CreateFundStepNumber = 1;
  showInternalLoader = false;
  defaultCurrencyISO = 'USD';

  // enum
  HoldingType = HoldingType;
  FundInvestmentStrategyType = FundInvestmentStrategyType;
  AreaUnit = AreaUnit;
  InvestmentSecurityType = InvestmentSecurityType;
  OwnershipCalculationType = OwnershipCalculationType;

  // Enum value lists
  fundTypesList = HoldingType.listAll();
  InvestmentStrategyType = FundInvestmentStrategyType;
  InvestmentStrategyTypesList = FundInvestmentStrategyType.listAll();
  InvestmentSecurityTypesList = InvestmentSecurityType.optionsForFundFundraising();

  /// The date that tha picker will open to
  estimatedClosingDateStartAt = new Date();
  estimatedClosingDateMinimumDate = new Date('1970-01-01T12:00');

  currencies$: Observable<CurrencyModel[]>;
  bankAccounts$: Observable<ClientBankAccountResponseBasic[]>;

  allCountries = this.resourceService.allCountries;
  allStates = this.resourceService.allStates;
  countryCodes = TerraUtils.consts.countryCode;
  USCountryId = this.resourceService.getCountryByCode(this.countryCodes.US).id;

  addressFormSettings = new AddressFormSettings(this.createFundService.addressFormFieldsToShow, AddressFields.Country);
  location = this.createFundService.createFundForm.value.step1.fundLocation;
  forbiddenCharsInHoldingName = this.createFundService.forbiddenCharsInHoldingName;

  get addressForm() {
    return this.pageForm.get('fundLocation') as UntypedFormGroup;
  }

  get selectedSecurityType(): InvestmentSecurityType {
    return this.createFundService.selectedSecurityType;
  }

  constructor(
    private resourceService: ResourceService,
    private createFundService: CreateFundService,
    private telemetryService: TelemetryService,
    private userService: UserService
  ) {
    super(createFundService);
  }

  ngOnInit() {
    this.isSubmitted = false;
    this.createFundService.currentStep$.next(this.STEP_NUMBER);
    this.getCurrencies();
    this.fillMockData();
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
      this.telemetryService.create({
        eventID: '12',
        eventTitle: 'CREATE FUND (INIT)',
        organizationID: clientDetails.organizationDetails.id
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });
  }

  isDisplayDataPoint(fundTypeList: HoldingType[]): boolean {
    if (!this.pageForm.get('holdingType').value) {
      return false;
    }
    const holdingType = Number(this.pageForm.get('holdingType').value) as HoldingType;
    return fundTypeList.some(val => val === holdingType);
  }

  getCurrencies() {
    this.showInternalLoader = true;
    this.resourceService.getOutboundCurrencies().subscribe(response => {
      this.currencies$ = of(response.filter(c => !c.isInbound));
      // Set default currency in dropdown if it is available
      const defaultCurrency = response.filter(c => c.iso === this.defaultCurrencyISO)[0];
      if (defaultCurrency) {
        this.pageForm.get('currency').setValue(defaultCurrency);
      }
      this.showInternalLoader = false;
    });
  }

  conutryTrackByFn(index, item: CountryModel) {
    return item.id; // or index
  }

  currencyCompareFn(c1: CurrencyModel, c2: CurrencyModel) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  cancel() {
    this.createFundService.cancel();
  }

  fillMockData() {
    // if (this.appSettingService.mockData && !this.pageForm.get('fundName').value) {
    //   this.pageForm.patchValue({
    //     name: 'Test Fund',
    //     estimatedClosingDate: new Date('2019-12-12'),
    //     // currency: 4,
    //     fundraisingTargetAmount: 1000000,
    //     minimumInvestmentAmount: 20000,
    //     fundType: 2,
    //     investmentType: 1,

    //     areaUnits: AreaUnit.SquareFeet,
    //     lotSizeArea: null,
    //     stories: null,
    //     residentialUnits: null,
    //     retailUnits: null,
    //     rooms: null,
    //     grossBuildingArea: null,
    //     residentialNetSellableArea: null,
    //     retailNetSellableArea: null,
    //     fundLocation: {
    //       countryId: 117,
    //       stateName: null,
    //       cityName: 'Test City',
    //       postalCode: '214213'
    //     }
    //   });
    // }
  }
}
