export enum FundraisingType {
  None = 0,
  InitialFundraising = 1,
  Contribution = 2,    
  BalancingCapitalCommitment = 3,
  CapitalCommitment = 4
}

// tslint:disable-next-line:no-namespace
export namespace FundraisingType {
  export function toString(fundraisingType: FundraisingType): string {
    switch (fundraisingType) {
      case FundraisingType.InitialFundraising:
        return 'Initial Fundraising';
      case FundraisingType.Contribution:
        return 'Contribution';
      case FundraisingType.BalancingCapitalCommitment:
        return 'BalancingCapitalCommitment';
      case FundraisingType.CapitalCommitment:
        return 'CapitalCommitment';
      default:
        return null;
    }
  }

  export function parse(fundraisingTypeStr: string): FundraisingType {
    return FundraisingType[fundraisingTypeStr];
  }
}

export default FundraisingType;
