<form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" class="form-grid-new terra-error-style">
  <div class="half-width">
    <h3 class="section-title">
      Contact information
    </h3>
    <!--  Email address -->
    <div class="terra-form-field-wrapper">
      <label for="txtEmail" class="text-input-label">
        Contact email address-<br/>
        Will be presented in the Investor Portal to allow investors to contact you directly via email correspondence
      </label>
      <mat-form-field appearance="outline">
        <input matInput id="txtEmail" formControlName="email" class="lo_sensitive"/>
        <mat-error *ngIf="pageForm.get('email').hasError('email')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="pageForm.get('email').hasError('required')">
          Email is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="half-width">
    <h3 class="section-title">
      Investor portal
    </h3>
    <div class="terra-form-field-wrapper toggle-item">
      <span>Investors can set bank account</span>
      <mat-slide-toggle hideIcon labelPosition="before" formControlName="allowLpSetBank"></mat-slide-toggle>
    </div>
    <div class="terra-form-field-wrapper toggle-item">
      <span>
        Display performance metrics
        <mat-icon class="notranslate" matTooltipClass="terra-tooltip"
                  matTooltip="This setting will affect Cash on Cash and IRR">
          help
        </mat-icon>
      </span>
      <mat-slide-toggle hideIcon labelPosition="before" formControlName="displayPerformanceMetrics"></mat-slide-toggle>
    </div>
    <div class="terra-form-field-wrapper toggle-item">
      <span>Display ownership percentage</span>
      <mat-slide-toggle hideIcon labelPosition="before" formControlName="lpOwnershipVisibility"></mat-slide-toggle>
    </div>
    <div class="terra-form-field-wrapper toggle-item">
      <span>Hide commitment amounts</span>
      <mat-slide-toggle hideIcon labelPosition="before" formControlName="hideCommitmentForNonContributingLps"></mat-slide-toggle>
    </div>
    <div class="terra-form-field-wrapper toggle-item">
      <span>Display net asset value
        <mat-icon class="notranslate terra-tooltip" matTooltipClass="terra-tooltip" matTooltip="{{navTooltipText}}"> help </mat-icon>
      </span>
      <mat-slide-toggle hideIcon labelPosition="before" formControlName="displayNetAssetValue" (change)="makeSureNavIsAllowed($event)"></mat-slide-toggle>
    </div>
  </div>

  <!-- Payment request supporting documents -->
  <h3 class="section-title" id="editHoldingPaymentRequestDocument">
    Upload payment request supporting documents (optional)
  </h3>
  <p class="supporting-documents-hint full-width">
    The following documents will be attached to every payment request sent for this fund
  </p>

  <ng-container formArrayName="fundPaymentRequestDocuments">
    <div class="half-width">
      <terra-file-uploader class="file-uploader" no-type-select formControlName="0" [isSubmitted]="isSubmitAttempt"
                           [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="1" [isSubmitted]="isSubmitAttempt"
                           [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="2" [isSubmitted]="isSubmitAttempt"
                           [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="3" [isSubmitted]="isSubmitAttempt"
                           [required]="false">
      </terra-file-uploader>
    </div>

    <a class="add-more-photos-link full-width" (click)="showSecondDocumentsRow = true" *ngIf="!showSecondDocumentsRow">
      <mat-icon class="notranslate" color="primary">add_circle</mat-icon>
      Add more documents
    </a>

    <div class="half-width" *ngIf="showSecondDocumentsRow">
      <terra-file-uploader class="file-uploader" no-type-select formControlName="4" [isSubmitted]="isSubmitAttempt"
                           [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="5" [isSubmitted]="isSubmitAttempt"
                           [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="6" [isSubmitted]="isSubmitAttempt"
                           [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="7" [isSubmitted]="isSubmitAttempt"
                           [required]="false">
      </terra-file-uploader>
    </div>
    <p class="error-message full-width" *ngIf="pageForm.get('fundPaymentRequestDocuments').invalid">The files marked in
      red are of an
      unsupported file
      type.
    </p>
    <p class="file-uploader-hint new-column">
      File format must be JPG, JPEG, PNG (20 MB max file size) or PDF (50 MB max file size)
    </p>
  </ng-container>

  <!-- Fund down mode -->
  <h3 class="section-title">Fund down mode</h3>
  <terra-holding-is-down-card [holdingDiscriminator]="HoldingDiscriminator.Fund"
                              [isPrivate]="pageForm.get('isPrivate').value"
                              class="full-width">
    <mat-slide-toggle hideIcon labelPosition="before" formControlName="isPrivate" (change)="makeSurePrivateStateAllowed($event)"></mat-slide-toggle>
  </terra-holding-is-down-card>

</form>
