import {Injectable, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialogConfig, MatDialog} from '@angular/material/dialog';
import {tap, take, withLatestFrom, switchMap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {GpAssetService} from './gp-asset.service';
import {GpInvestmentDataService} from 'src/app/services/gp/gp-investment-data.service';
import {EditFundraisingDialogContext, FundraisingDetailsInterface} from '../../shared/holding/fundraising/fundraisings-tab/edit-fundraising-dialog.context';
import {FundraisingReqRes} from '../../shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {GpFundraisingDataService} from 'src/app/services/gp/gp-fundraising-data.service';
import FundraisingStatus from 'src/app/shared/enums/FundraisingStatus.enum';
import {EditFundraisingDialogComponent} from '../../shared/holding/fundraising/edit-fundraising-dialog/edit-fundraising-dialog.component';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {GpAssetDialogContext} from './AssetDetails.context';
import {
  PartialEditAssetFundraisingDialogComponent
} from './asset-management-status/asset-fundraisings/partial-edit-asset-fundraising-dialog/partial-edit-asset-fundraising-dialog.component';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {AppQuery} from 'src/app/state';
import {HoldingFundraisingService} from '../../shared/holding/fundraising/holding-fundraising.service';
import {GpHoldingService} from '../../shared/holding/gp-holding.service';
import {RoutingService} from 'src/app/services/routing.service';
import {AssetAndFundraisingReqRes} from '../components/create-asset/AssetAndFundraisingRequest';
import {UserService} from 'src/app/services/shared/user.service';
import {PermissionService} from 'src/app/permission/permission.service';

@Injectable()
export class AssetFundraisingService extends HoldingFundraisingService implements FundraisingDetailsInterface {
  asset$ = this.gpAssetService.holding$;

  constructor(
    private route: ActivatedRoute,
    private appQuery: AppQuery,
    private gpholdingService: GpHoldingService,
    private gpFundraisingDataService: GpFundraisingDataService,
    private gpAssetService: GpAssetService,
    private gpInvestmentDataService: GpInvestmentDataService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private vcr: ViewContainerRef,
    private routingService: RoutingService,
    private router: Router,
    private permissionService: PermissionService
  ) {
    super(route, appQuery, gpholdingService, gpFundraisingDataService, gpInvestmentDataService, permissionService);

    // Refresh relevant parts of the page once an investment was updated:
    this.updatedInvestment$.pipe(
      untilComponentDestroyed(this),
      withLatestFrom(this.gpAssetService.fundraising$)
    )
      .subscribe(([investment, fundraisingDetails]) => {
        // Find the investment that was updated, and replace it in the asset details:
        const replaceIndex = fundraisingDetails.investments.findIndex(i => i.id === investment.id);
        // New investment - add to the end of the list:
        if (replaceIndex === -1) {
          fundraisingDetails.investments = [...fundraisingDetails.investments, investment];
        } else {
          // update existing investment:
          fundraisingDetails.investments = [
            ...fundraisingDetails.investments.slice(0, replaceIndex),
            investment,
            ...fundraisingDetails.investments.slice(replaceIndex + 1)
          ];
        }
        fundraisingDetails.investments = this.sortInvestments(fundraisingDetails.investments);
        this.gpAssetService.changeFundraisingOnly(fundraisingDetails);
      });

    // Refresh relevant parts of the page once an investment was deleted:
    this.deletedInvestmentId$.pipe(
      untilComponentDestroyed(this),
      withLatestFrom(this.gpAssetService.fundraising$)
    )
      .subscribe(([investmentId, fundraisingDetails]) => {
        const index = fundraisingDetails.investments.findIndex(x => x.id === investmentId);
        if (index !== -1) {
          fundraisingDetails.investments.splice(index, 1);
          this.gpAssetService.changeFundraisingOnly(fundraisingDetails);
        }
      });
  }

  showEditFundraising() {
    combineLatest([this.fundraising$, this.asset$]).pipe(
      take(1),
      switchMap(([fundraising, asset]) => {
        const config = new MatDialogConfig<EditFundraisingDialogContext>();
        config.panelClass = 'edit-fundraising-dialog';
        config.disableClose = true;
        config.closeOnNavigation = true;
        config.autoFocus = false;
        config.viewContainerRef = this.vcr;

        const context = new EditFundraisingDialogContext(fundraising, asset, asset.status, HoldingDiscriminator.Asset, this);
        config.data = context;

        return this.dialog.open(EditFundraisingDialogComponent, config).afterClosed();
      }),
      untilComponentDestroyed(this)
    ).subscribe((updatedFundraising: FundraisingReqRes) => {
      if (updatedFundraising) {
        this.snackbarService.showGeneralMessage('Saved changes');
      }
    });
  }

  public showMoveFundraisingToComplete() {
    this.showPartialEditFundraising();
  }

  private showPartialEditFundraising() {
    combineLatest([this.asset$, this.fundraising$]).pipe(
      take(1),
      switchMap(([asset, fundraisingDetails]) => {
        const config = new MatDialogConfig<GpAssetDialogContext>();
        config.panelClass = 'edit-fundraising-dialog';
        config.disableClose = true;
        config.autoFocus = false;

        const context = new GpAssetDialogContext(this.gpAssetService, asset, fundraisingDetails, null);
        config.data = context;

        return this.dialog.open(PartialEditAssetFundraisingDialogComponent, config).afterClosed();
      }),
      untilComponentDestroyed(this)
    )
      .subscribe((updatedAsset: AssetAndFundraisingReqRes) => {
        if (updatedAsset) {
          this.snackbarService.showGeneralMessage('The contribution is complete. Congrats!');
          this.router.navigateByUrl(this.routingService.assetFundraising(updatedAsset.asset.id, updatedAsset.fundraising.id));
        }
      });
  }

  updateStatus(fundraisingStatus: FundraisingStatus) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => this.gpFundraisingDataService.updateStatus(endpointPrefixIds.holdingId, endpointPrefixIds.fundraisingId, fundraisingStatus)),
      tap(fundraisingDetails => {
        // update only the fundraisingDetails part:
        this.fundraising$.next(fundraisingDetails);
      })
    );
  }
}
