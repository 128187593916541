import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import { Sort } from '@angular/material/sort';
import {Observable, BehaviorSubject} from 'rxjs';
import {map, share, shareReplay, tap} from 'rxjs/operators';
import memo from 'memo-decorator';

import {DistributionTableItemResponse} from '../DistributionTableItemResponse.model';
import DistributionStatus from 'src/app/shared/enums/DistributionStatus.enum';
import {UserService} from 'src/app/services/shared/user.service';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import {GpHoldingService} from 'src/app/dashboard/shared/holding/gp-holding.service';
import DistributionTransactionPurpose from 'src/app/shared/enums/DistributionTransactionPurpose.enum';
import {AppService} from 'src/app/state';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import { TourType } from 'src/app/services/gp/tours.service';

@Component({
  selector: 'terra-distributions-tab',
  templateUrl: './distributions-tab.component.html',
  styleUrls: ['./distributions-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributionsTabComponent implements OnInit {

  // consts
  DistributionStatus = DistributionStatus;
  DistributionType = DistributionTransactionPurpose;
  HoldingStatus = HoldingStatus;

  relevantTours = [ TourType.ImportDistributions];

  emptyState$ = new BehaviorSubject(false);
  displayedColumns: string[] = ['name', 'noticeSentTimeStamp', 'payoutSentTimeStamp', 'overallSettlementDate', 'totalAmountForDistribution', 'reasonForTransaction', 'status'];
  holdingId$ = this.gpHoldingService.holdingId$;

  showDistributionsTab$ = this.userService.userHasFeatureFlag(FeatureFlags.DistributionsGP);
  hasFundraisings$ = this.gpHoldingService.fundraising$.pipe(map(f => !!f), shareReplay(1));
  isDraft$ = this.gpHoldingService.holding$.pipe(map(holding => holding.status == HoldingStatus.Draft));

  isRealized$ = this.gpHoldingService.holding$.pipe(map(holding => holding.status == HoldingStatus.Realized));
  
  createDistributionUrl$ = this.holdingId$.pipe(map(holdingId => this.gpHoldingService.getCreateDistributionRoute(holdingId)), shareReplay(1));
  importDistributionUrl$ = this.holdingId$.pipe(map(holdingId => this.gpHoldingService.getImportDistributionRoute(holdingId)), shareReplay(1));

  distributions$: Observable<DistributionTableItemResponse[]> = this.gpHoldingService.distributions$.pipe(
    tap(items => {
      this.emptyState$.next(items.length === 0);
    }),
    share()
  );

  searchOptions$ = this.gpHoldingService.searchOptionsDistributions$;

  constructor(
    private gpHoldingService: GpHoldingService,
    private userService: UserService,
    private appService: AppService
  ) {
  }

  ngOnInit() {
  }

  @memo({resolver: (...args: number[]) => args[0] + '_' + args[1]})
  getDistributionPageRoute(holdingId: number, distributionId: number) {
    return this.gpHoldingService.getDistributionPageRoute(holdingId, distributionId);
  }

  sortData(sort: Sort) {
    if (!sort.active || !sort.direction) {
      return;
    }

    this.appService.updateDistributionSortState({
      orderBy: sort.active,
      direction: sort.direction === 'desc' ? 'desc' : 'asc'
    }, this.gpHoldingService.discriminatorName);
  }
}
