import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import { GpAssetService } from '../gp-asset.service';

@Component({
  selector: 'terra-fund-asset-status',
  templateUrl: './fund-asset-status.component.html',
  styleUrls: ['./fund-asset-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundAssetStatusComponent {

  // consts
  HoldingType = HoldingType;

  asset$ = this.gpAssetService.holding$;

  constructor(private gpAssetService: GpAssetService) { }

}
