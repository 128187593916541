<terra-dialog-header>
  <h1>New folder</h1>
</terra-dialog-header>

<mat-dialog-content>
  <form id="dialogForm" [formGroup]="pageForm" (ngSubmit)="save()" class="form-grid-new terra-error-style">
    <div class="terra-form-field-wrapper full-width">
      <!-- <label class="text-input-label">New folder name</label> -->
      <mat-form-field appearance="outline">
        <input matInput formControlName="displayName" class="lo_sensitive" placeholder="Untitled folder">
        <mat-error *ngIf="pageForm.get('displayName').hasError('required')">Required</mat-error>
        <mat-error *ngIf="pageForm.get('displayName').hasError('maxlength')">Name is too long</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="reset-style">
  <button type="submit" form="dialogForm" mat-flat-button color="primary" class="save-button">
    Create
  </button>
  <button type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
