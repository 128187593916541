import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Observable, throwError, of } from 'rxjs';
import { switchMap, take, map, tap, shareReplay, startWith, distinctUntilChanged, catchError } from 'rxjs/operators';
import { PickerResponse } from 'filestack-js';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import moment from 'moment';

import { GpHoldingService } from 'src/app/dashboard/shared/holding/gp-holding.service';
import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { HoldingStorageObjectReqRes, StorageObjectReqRes } from 'src/app/shared/models/StorageObjectReqRes.model';
import { StorageFolderTreeNode } from 'src/app/shared/models/StorageFolderTreeNode.model';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import { StorageObjectShareReqRes } from '../StorageObjectShareReqRes.model';
import { StorageObjectPermissionListReqRes } from '../StorageObjectPermissionListReqRes.model';
import { StorageObjectPolicyReqRes } from 'src/app/shared/models/StorageObjectPolicyReqRes.model';
import { HoldingDocumentsSearchOptionsRequest } from '../HoldingDocumentsSearchOptionsRequest.model';
import { AppService, AppQuery } from 'src/app/state';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {LoggerService} from '../../../../../shared/errors/logger.service';
import { UtilsService } from 'src/app/services/utils.service';

export class MoveToFolderEventParam {
  objectIdsToMove: number[];
  destinationFolderId: number;
}

@Injectable()
export class DocumentsTabService extends OnDestroyMixin {
  // Max length for file/folder name.
  // If ever need to change it, remember to change the validator (StorageObjectValidator) in PayAppApi
  public readonly maxDisplayNameLength = 96;
  private refreshQuotaDetails$ = new Subject<void>();

  quotaDetails$ = this.refreshQuotaDetails$.pipe(
    startWith([null]),
    switchMap(() => this.storageObjectDataService.getQuota()),
    shareReplay(1)
  );

  fileCountLimitReached$ = this.quotaDetails$.pipe(
    map(quota => quota.maxStorageFilesCount - quota.currentStorageFilesCount <= 0),
    distinctUntilChanged(),
    shareReplay(1)
  );

  private filestackPolicy$ = new BehaviorSubject<StorageObjectPolicyReqRes>(null);

  foldersPath$ = new BehaviorSubject<StorageObjectReqRes[]>(null);

  refreshData$ = new Subject<void>();

  triggerLoading$ = new BehaviorSubject(false);

  // Search options sources:
  private initialUiSortState$ = this.appQuery.gpUiPrefs.pipe(take(1), map(state => state.documents.sort));

  private _sortSubject$ = new BehaviorSubject<{ orderBy: string, sortOrder: string }>({} as { orderBy: string, sortOrder: string });

  currentFolderId$ = new BehaviorSubject<number>(null);
  search$ = new BehaviorSubject<string>('');

  sort$ = combineLatest([this.initialUiSortState$, this._sortSubject$]).pipe(
    map(([initial, sort]) => ({ orderBy: sort.orderBy || initial.orderBy, sortOrder: sort.sortOrder || initial.direction })),
    distinctUntilChanged(),
    shareReplay(1));

  private _searchOptions$ = combineLatest([this.gpHoldingService.holdingId$, this.sort$, this.search$, this.currentFolderId$.pipe(distinctUntilChanged())])
    .pipe(
      untilComponentDestroyed(this),
      map(([holdingId, sort, search, currentFolderId]) => {
        return {
          pageNumber: 0,
          pageSize: 2147483647,
          includeRemoved: false,
          filter: search,
          holdingId: holdingId,
          storageObjectParentId: currentFolderId || 0,
          sortOrder: sort.sortOrder,
          orderBy: sort.orderBy,
        } as HoldingDocumentsSearchOptionsRequest;
      }),
      distinctUntilChanged(),
      shareReplay(1));

  public searchOptions$ = this._searchOptions$.pipe(shareReplay(1));


  // whenever the refresh data is called, also refresh the entire folders structure
  allFoldersTree$ = this.refreshData$.pipe(
    startWith([null]),
    switchMap(() => this.gpHoldingService.holdingId$),
    switchMap(holdingId => this.storageObjectDataService.getAllFoldersForHolding(holdingId)),
    map(searchResponse => this.convertListToTree(searchResponse.rows)),
    catchError(error => {
      return of([] as StorageFolderTreeNode[]);
    }),
    shareReplay(1)
  );

  moveToFolderEvent$ = new Subject<MoveToFolderEventParam>();

  isDraftHolding$ = this.gpHoldingService.holding$.pipe(
    map(holding => holding.status === HoldingStatus.Draft), shareReplay(1));

  private convertListToTree(list: StorageObjectReqRes[]) {

    const items = list.map(storageObjectReqRes => {
      return { ...storageObjectReqRes } as StorageFolderTreeNode;
    });

    const mapObject = {};
    let node: StorageFolderTreeNode;
    const roots = new Array<StorageFolderTreeNode>();

    for (let i = 0; i < items.length; i++) {
      mapObject[items[i].id] = i; // initialize the map
      items[i].children = []; // initialize the children
    }
    for (let j = 0; j < items.length; j++) {
      node = items[j];
      if (node.storageObjectParentId) {
        // if you have dangling branches check that map[node.parentId] exists
        items[mapObject[node.storageObjectParentId]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  constructor(
    private gpHoldingService: GpHoldingService,
    private storageObjectDataService: StorageObjectDataService,
    private appService: AppService,
    private appQuery: AppQuery,
    private logger: LoggerService,
    private utilsService: UtilsService
  ) {
    super();
  }

  sortFiles(orderBy: string, sortOrder: string) {
    this._sortSubject$.next({ orderBy, sortOrder });
    this.appService.updateDocumentsSortParams({ orderBy, direction: sortOrder === 'desc' ? 'desc' : 'asc' });
  }

  rename(storageObjectId: number, displayName: string) {
    return this.storageObjectDataService.rename(storageObjectId, displayName).pipe(
      tap(() => {
        this.refreshData$.next();
      }));
  }

  /// Returns the number of files created
  createFiles(pickerResponse: PickerResponse): Observable<HoldingStorageObjectReqRes[]> {
    let model: HoldingStorageObjectReqRes[];
    this.triggerLoading$.next(true);
    return this.gpHoldingService.holdingId$.pipe(
      take(1),
      switchMap(holdingId => {
        model = this.generateCreateFilesSubmitModel(pickerResponse, holdingId, this.currentFolderId$.value);
        return this.storageObjectDataService.createFiles(model);
      }),
      tap(() => {
        this.triggerLoading$.next(false);
        this.refreshQuotaDetails$.next();
        this.refreshData$.next();
      }),
      catchError(error => {
        this.triggerLoading$.next(false);
        return throwError(error);
      })
    );
  }

  refreshQuota() {
    this.refreshQuotaDetails$.next();
  }

  /// If the parentId is null, the folder will be a root at the root.
  createFolder(folderName: string) {
    return combineLatest([this.gpHoldingService.holdingId$.pipe(take(1)), this.currentFolderId$.pipe(take(1))]).pipe(
      switchMap(([holdingId, currentFolderId]) => {
        const model = new HoldingStorageObjectReqRes();
        model.holdingId = holdingId;
        model.displayName = folderName;
        model.storageObjectParentId = currentFolderId;
        return this.storageObjectDataService.createFolder(holdingId, model);
      }),
      tap(() => this.refreshData$.next()));
  }

  moveToFolder(idsToMove: number[], destinationFolderId: number) {
    return this.gpHoldingService.holdingId$
      .pipe(
        take(1),
        switchMap(holdingId => this.storageObjectDataService.move(holdingId, idsToMove, destinationFolderId)),
        tap(() => this.refreshData$.next())
      );
  }

  deleteMultiple(storageFileIds: number[]): Observable<any> {
    return this.storageObjectDataService.deleteMultiple(storageFileIds)
      .pipe(tap(_ => this.refreshData$.next()));
  }

  share(model: StorageObjectShareReqRes) {
    return this.gpHoldingService.holdingId$.pipe(
      take(1),
      switchMap(holdingId => {
        model.holdingId = holdingId;
        return this.storageObjectDataService.share(model);
      }),
      tap(_ => this.refreshData$.next()));
  }

  setPermissions(model: StorageObjectPermissionListReqRes) {
    return this.gpHoldingService.holdingId$.pipe(
      take(1),
      switchMap(holdingId => {
        model.holdingId = holdingId;
        return this.storageObjectDataService.setPermissions(model);
      }),
      tap(_ => this.refreshData$.next()));
  }

  /** Use this when you really need the most updated quota from the server.
   * For example, right before uploading more files, to avoid errors caused by reaching the quota.
   */
  getQuotaRefreshed() {
    return this.storageObjectDataService.getQuota().pipe(tap(() => this.refreshQuotaDetails$.next()));
  }

  getFilestackPolicy(): Observable<StorageObjectPolicyReqRes> {
    if (!this.filestackPolicy$.value || this.filestackPolicy$.value.expiry <= new Date()) {
      return this.storageObjectDataService.getFilestackPolicy().pipe(
        map(policy => {
          policy.expiry = moment(new Date()).add(50, 'minute').toDate();
          return policy;
        }),
        tap(policy => {
          this.filestackPolicy$.next(policy);
        }));
    } else {
      return this.filestackPolicy$;
    }
  }

  private generateCreateFilesSubmitModel(pickerResponse: PickerResponse, holdingId: number, containingFolderId: number): HoldingStorageObjectReqRes[] {
    const model = new Array<HoldingStorageObjectReqRes>();
    pickerResponse.filesUploaded.forEach(file => {
      const storageObject = new HoldingStorageObjectReqRes();
      storageObject.storageObjectParentId = containingFolderId;
      storageObject.holdingId = holdingId;
      storageObject.filestackHandle = file.handle;
      storageObject.filestackUrl = file.url;
      storageObject.filestackSource = file.source;
      storageObject.displayName = file.filename?.length > this.maxDisplayNameLength ? this.getShortenDisplayName(file.filename) : file.filename;
      storageObject.metaFileLink = new MetaFileLink();
      storageObject.metaFileLink.title = file.filename;
      storageObject.metaFileLink.mediaType = file.mimetype;
      storageObject.metaFileLink.sizeB = file.size;
      model.push(storageObject);
    });
    return model;
  }

  /**
   * Shorten a long file name to the maximum allowed. maximum file name length defined in maxDisplayNameLength
   * @param originalDisplayName long file name
   */
  private getShortenDisplayName(originalDisplayName: string): string {
    try {
      if (originalDisplayName.length > 0) {
        const nameSplittedByDot = originalDisplayName.split('.');
        if (nameSplittedByDot.length > 1) {
          const extension = '.' + nameSplittedByDot[nameSplittedByDot.length - 1];
          let shortName = originalDisplayName.split(extension)?.shift();
          shortName = shortName.substr(0, this.maxDisplayNameLength - extension.length);
          return shortName + extension;
        } else {
          return originalDisplayName.substr(0, this.maxDisplayNameLength);
        }
      }

      return '';
    } catch (ex) {
      this.logger.error(`An error occurred while trying to shorten file name. original file name: ${originalDisplayName}`, ex);
      return originalDisplayName.substr(0, this.maxDisplayNameLength);
    }
  }
}
