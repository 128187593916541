import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Title} from '@angular/platform-browser';

import {TerraUtils} from '../shared/TerraUtils';
import {UserService} from '../services/shared/user.service';
import {OnboardingRequest} from './OnboardingRequest.model';
import {MetaFileLink} from '../models/metaFileLink.model';
import {ErrorMatcher, ErrorType} from '../shared/errors/ErrorMatcher';
import {AddressFormSettings} from '../shared/components/address-form/address-form.settings';
import {InvestorContactImportDialogComponent} from '../dashboard/contacts/components/investor-contact-import-dialog/investor-contact-import-dialog.component';
import {GoogleMapSearchResult} from '../shared/components/google-map-search/model/google-map-result';

@Component({
  selector: 'terra-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent extends OnDestroyMixin implements OnInit {
  pageForm: UntypedFormGroup;
  isSubmitted = false;
  isLoading = false;
  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;
  addressFormSettings = new AddressFormSettings();

  // File uploaders configs:
  isFileNotSupported = false;
  fileNotSupportedError = 'The file you uploaded is not supported.';
  logoExtensionTypes = TerraUtils.consts.supportedFileExtensions.LOGO;

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title
  ) {
    super();
  }

  ngOnInit() {
    this.titleService.setTitle('Create Account' + TerraUtils.consts.GpPageTitleSuffix);

    this.generateForm();
  }

  generateForm() {
    this.pageForm = this.fb.group({
      organizationName: [null, Validators.required],
      logoDocument: [null],
      address: {}
    });

    this.subscribeToFileUploadrChange();
  }

  subscribeToFileUploadrChange() {
    this.pageForm
      .get('logoDocument')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe(logo => {
        this.isFileNotSupported = false;
      });
  }

  submit() {
    this.isSubmitted = true;
    this.isGeneralServerError = false;
    this.isFileNotSupported = false;

    this.pageForm.markAllAsTouched();

    if (this.pageForm.valid) {
      this.isLoading = true;
      const formValues = this.pageForm.value;
      const model = new OnboardingRequest();

      model.organizationName = formValues.organizationName;
      model.address = formValues.address;
      model.logoFileId = (formValues.logoDocument as MetaFileLink)?.id || null;

      this.userService
        .onboardingSubmit(model)
        .subscribe(
          response => {
            this.isLoading = false;
            if (response.mustVerifyEmail) {
              this.router.navigate([TerraUtils.consts.paths.EMAIL_VERIFICATION]);
            } else {
              this.router.navigateByUrl(TerraUtils.consts.paths.DASHBOARD);
            }

          },
          (err: HttpErrorResponse) => {
            this.isLoading = false;
            // if only the import failed, show error modal and move to the dashboard when confirming.
            if (ErrorMatcher.isError(err, ErrorType.BadFileFormatException)) {
              this.isFileNotSupported = true;
            } else {
              // if everything failed, show error message on this page
              this.isGeneralServerError = true;
            }
          }
        );
    }
  }

  addressSelected(googlePlaceAddressInfo: GoogleMapSearchResult): void {
    //console.log(googlePlaceAddressInfo);
  }

  import() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'investor-contact-import-dialog';
    this.dialog
      .open(InvestorContactImportDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((isRefresh: boolean) => {
        // if (isRefresh) {
        // }
      });
  }
}
