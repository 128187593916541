<div mat-dialog-title class="promo-header">
  <div class="promo-header-row">
    <h2 class="promo-header-title">Introducing Capital Commitment Management <span class="new-badge">NEW!</span></h2>
    <mat-icon class="close-icon" mat-dialog-close cdkFocusInitial>close</mat-icon>
  </div>
</div>
<img src="assets/images/promo/capital-commitment-management.png" alt="Capital Commitment" class="promo-image" />
<div class="promo-description">
  <h3>Your Commitments, Simplified</h3>
  <p>Explore the capabilities of our newly introduced Capital Commitment Management feature, designed to empower both
    GPs and investors with the ability to monitor capital commitments seamlessly. Say goodbye to the cumbersome use of
    Excel spreadsheets and embrace a streamlined approach to manage and overview your deals. With all information in
    one place, making informed decisions and strategizing for the future has never been easier or more efficient.!</p>
</div>
<mat-dialog-actions class="flex flex-row gap-1 promo-actions" align="end">
  <terra-buttons type="secondary" label="Got it" (onClick)="action()"></terra-buttons>
</mat-dialog-actions>