<!-- Feature is {{ (isActiveFeature$ | async) ? 'ON' : 'OFF' }} -->
<ng-container *ngIf="investmentDetails$ | async as investmentDetails">
  <ng-container *ngIf="(isActiveFeature$ | async); else example">
    <div class="details-block" *ngIf="investmentDetails.isMarketingDeckWasSent">
      <span class="title">
        <mat-icon svgIcon="investor_filled" class="notranslate" color="accent"></mat-icon>
        Investor reaction to offering deck
      </span>

      <ul class="details-list">
        <li class="list-item">
          <span class="label">Viewed offering deck:</span
          ><span class="data"> {{ investmentDetails.marketingDeckLpDownloadsCount ? investmentDetails.marketingDeckLpDownloadsCount : 0 }} time(s)</span>
        </li>
        <li class="list-item" *ngIf="investmentDetails.marketingDeckLpLastDownloadTimestamp">
          <span class="label">Recently viewed offering deck on:</span
          ><span class="data"> {{ investmentDetails.marketingDeckLpLastDownloadTimestamp | date }}</span>
        </li>
        <li class="list-item">
          <span class="label">Client reply:</span>
          <span class="data">
            {{ investmentDetails.lpInterestLevel | enumString: LpInterest }}
            <span *ngIf="investmentDetails.lpInterestSubmittedTimestamp"
              >(replied on : {{ investmentDetails.lpInterestSubmittedTimestamp | date }})
            </span></span
          >
        </li>
        <li class="list-item" *ngIf="investmentDetails.lpInterestAmount">
          <span class="label">Amount client consider deploying: </span>
          <span class="data"> {{ investmentDetails.lpInterestAmount | terraCurrency }} {{ investmentDetails.currency.iso }} </span>
        </li>
        <li class="list-item" *ngIf="investmentDetails.lpInterestNote">
          <span class="label">Note from client: </span><span class="data"> {{ investmentDetails.lpInterestNote }}</span>
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-template #example>
    <div class="example-block example-strip">
      <span class="title">
        <mat-icon svgIcon="profile_filled" class="notranslate" color="accent"></mat-icon>
        Investor reaction to offering deck
      </span>

      <ul class="details-list">
        <li class="list-item"><span class="label">Viewed offering deck:</span><span class="dummy-data"> xx times</span></li>
        <li class="list-item"><span class="label">Recently viewed offering deck on:</span><span class="dummy-data"> xx/xx/xxxx</span></li>
        <li class="list-item"><span class="label">Client reply:</span><span class="dummy-data"> xxxxxxxxxxxx</span></li>
        <li class="list-item"><span class="label">Amount client consider deploying: </span><span class="dummy-data"> xxxxxxxxxxxx</span></li>
        <li class="list-item"><span class="label">Note from client: </span><span class="dummy-data"> xxxxxx xxxxxx xxxxx xx xxxxx xxxxx</span></li>
      </ul>
    </div>
    <p class="upsell-text">
      <mat-icon class="notranslate">lock</mat-icon>
      <span
        >Interested in our premium packages? Please
        <a target="_blank" href="https://www.covercy.com/contact-us" class="contact-us-link link"> contact us</a>
      </span>
    </p>
  </ng-template>
</ng-container>
