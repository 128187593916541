import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {InvestmentReqRes} from '../../../../models/investment.model';
import {AiRankingReq} from './AiRankingReq.model';
import {GpAIRankingDataService} from '../../../../../services/gp/gp-AI-ranking-data.service';
import {AiRankingReqRes} from './AiRankingReqRes.model';
import {GpInvestmentDataService} from '../../../../../services/gp/gp-investment-data.service';
import {InvestingEntityReqRes} from '../../../../models/InvestingEntityReqRes.model';
import {InvestorRank} from './InvestorRank.model';


@Injectable({
  providedIn: 'root'
})
export class AIRankingService {

  constructor(private aiRankingDataService: GpAIRankingDataService, private investmentDataService: GpInvestmentDataService ) {
  }

  rankInvestingEntities(investingEntities: InvestingEntityReqRes[], holdingId: number): Observable<AiRankingReqRes[]> {
    const contactsIds = new Set(investingEntities.map(ie => ie.contactId));
    const rankRequest = new AiRankingReq();
    rankRequest.holdingId = holdingId;
    rankRequest.contactsIds = [...contactsIds.values()];
    return this.aiRankingDataService.getContactsRanking(rankRequest);
  }

  rankInvestments(investments: InvestmentReqRes[], holdingId: number): Observable<AiRankingReqRes[]> {
    const contactsIds = new Set(investments.map(i => i.investingEntity.contactId));
    const rankRequest = new AiRankingReq();
    rankRequest.holdingId = holdingId;
    rankRequest.contactsIds = [...contactsIds.values()];
    return this.aiRankingDataService.getContactsRanking(rankRequest);
  }

  reRankActiveInvestments(investments: InvestmentReqRes[], holdingId: number): Observable<boolean> {
    const updatedInvestments = [];

    return this.rankInvestments(investments, holdingId).pipe(
      switchMap(rankingReqRes => {
        rankingReqRes.forEach(ranking => {
          const contactInvestments = investments.filter(i => i.investingEntity.contactId === ranking.contactId);
          contactInvestments.forEach(i => {
            const investment = new InvestmentReqRes();
            investment.id = i.id;
            investment.fundraisingId = i.fundraisingId;
            investment.rank = ranking.rank;
            investment.insight = ranking.insight;
            updatedInvestments.push(investment);
          });
        });

        if (updatedInvestments.length > 0) {
          return this.investmentDataService.updateRanking(updatedInvestments, holdingId, updatedInvestments[0].fundraisingId);
        } else {
          return of(false);
        }
      })
    );
  }

  getRankingIconName(rank: InvestorRank) {
    switch (rank) {
      case InvestorRank.Higher:
        return 'ranking-high';
      case InvestorRank.Normal:
        return 'ranking-normal';
      case InvestorRank.Lower:
        return 'ranking-low';
      default:
        return '';
    }
  }
}
