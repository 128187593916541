<ng-container *ngIf="currency$ | async as currency">
  <form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" (ngSubmit)="stepSubmit()" class="form-grid terra-error-style">
    <h3 class="section-title one-line">Please make sure the cumulative information is up to date (optional)</h3>
    <div class="info-message full-width">
      Any optional fields left empty will not be visible to investors in the published report
    </div>
    <!--  ALL TYPES: Total capitalization -->
    <div class="terra-form-field-wrapper">
      <label for="txtTotalCapitalization" class="text-input-label">Total capitalization</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtTotalCapitalization" terraFormattedNumber formControlName="totalCapitalization" class="lo_sensitive" />
        <span matSuffix>{{ currency.iso }}</span>
        <mat-hint>Total investors equity + loan</mat-hint>
      </mat-form-field>
    </div>

    <!--  ALL TYPES:Total Investor Equity  -->
    <div class="terra-form-field-wrapper">
      <label for="txtTotalInvestorEquity" class="text-input-label">Total investor equity</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtTotalInvestorEquity" terraFormattedNumber formControlName="totalInvestorEquity"
          class="lo_sensitive" />
        <span matSuffix>{{ currency.iso  }}</span>
        <mat-error *ngIf="pageForm.get('totalInvestorEquity').hasError('required')"> Required </mat-error>
      </mat-form-field>
    </div>

    <!-- ALL TYPES: % of GP equity from total equity (optional)  -->
    <div class="terra-form-field-wrapper">
      <label for="txtGpEquityFromTotalEquity" class="text-input-label">% of GP equity from total equity (optional)</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtGpEquityFromTotalEquity" terraFormattedNumber formControlName="gpEquityFromTotalEquity"
          class="lo_sensitive" />
      </mat-form-field>
    </div>

    <!-- ALL TYPES: Estimated market value  -->
    <div class="terra-form-field-wrapper">
      <label for="txtEstimatedMarketValue" class="text-input-label">Estimated market value (optional)</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtEstimatedMarketValue" terraFormattedNumber formControlName="estimatedMarketValue"
          class="lo_sensitive" />
        <span matSuffix>{{ currency.iso  }}</span>
      </mat-form-field>
    </div>

    <ng-container *ngIf="assetInvestmentType$ | async as investmentType">
      <!-- Original project budget (optional) (old: Planned costs to date)  -->
      <div class="terra-form-field-wrapper" *ngIf="investmentType === InvestmentType.ValueAdd">
        <label for="txtPlannedCostsToDate" class="text-input-label">Original project budget (optional)</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtPlannedCostsToDate" terraFormattedNumber formControlName="plannedCostsToDate" class="lo_sensitive" />
          <span matSuffix>{{ currency.iso }}</span>
        </mat-form-field>
      </div>

      <!-- Revised project budget (optional)  (old: Actual costs to date)  -->
      <div class="terra-form-field-wrapper" *ngIf="investmentType === InvestmentType.ValueAdd">
        <label for="txtActualCostsToDate" class="text-input-label">Revised project budget (optional)</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtActualCostsToDate" terraFormattedNumber formControlName="actualCostsToDate" class="lo_sensitive" />
          <span matSuffix>{{ currency.iso }}</span>
        </mat-form-field>
      </div>

      <!-- Construction start date (optional) -->
      <div class="terra-form-field-wrapper"
        *ngIf="investmentType === InvestmentType.ValueAdd || investmentType === InvestmentType.Opportunistic">
        <label for="txtConstructionStartDate" class="text-input-label">Construction start date (optional)</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtConstructionStartDate" [matDatepicker]="constructionStartDatePicker"
            formControlName="constructionStartDate" (focus)="constructionStartDatePicker.open()" class="lo_sensitive" />
          <mat-datepicker-toggle matSuffix [for]="constructionStartDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #constructionStartDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Construction end date (optional) -->
      <div class="terra-form-field-wrapper"
        *ngIf="investmentType === InvestmentType.ValueAdd || investmentType === InvestmentType.Opportunistic">
        <label for="txtConstructionEndDate" class="text-input-label">Construction end date (optional)</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtConstructionEndDate" [matDatepicker]="constructionEndDatePicker" formControlName="constructionEndDate"
            (focus)="constructionEndDatePicker.open()" class="lo_sensitive" />
          <mat-datepicker-toggle matSuffix [for]="constructionEndDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #constructionEndDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Core + Core PLus -->
      <ng-container *ngIf="investmentType === InvestmentType.Core || investmentType === InvestmentType.CorePlus">
        <!-- Total equity invested to date -->
        <div class="terra-form-field-wrapper">
          <label for="txtTotalEquityInvestedToDate" class="text-input-label">Total equity invested to date (optional)</label>
          <mat-form-field appearance="outline">
            <input matInput id="txtTotalEquityInvestedToDate" terraFormattedNumber formControlName="totalEquityInvestedToDate"
              class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>

        <!-- Total returns to date -->
        <div class="terra-form-field-wrapper">
          <label for="txtTotalReturnsToDate" class="text-input-label">Total returns to date</label>
          <mat-form-field appearance="outline">
            <input matInput id="txtTotalReturnsToDate" terraFormattedNumber formControlName="totalReturnsToDate" class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>

      </ng-container>

      <ng-container *ngIf="assetInvestmentSecurityType$ | async as securityType">
        <!-- Outstanding loan amount to date -->
        <div class="terra-form-field-wrapper" *ngIf="securityType==SecurityType.Debt">
          <label for="txtOutstandingLoanAmountToDate" class="text-input-label">Outstanding loan amount to date</label>
          <mat-form-field appearance="outline">
            <input matInput id="txtOutstandingLoanAmountToDate" terraFormattedNumber formControlName="outstandingLoanAmountToDate"
              class="lo_sensitive" />
            <span matSuffix>{{ currency.iso }}</span>
          </mat-form-field>
        </div>
      </ng-container>

    </ng-container>
  </form>
  <section class="footer">
    <!-- Error message -->
    <div *ngIf="isSubmitted" class="error-section">
      <p *ngIf="!pageForm.valid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
    <div class="form-actions">
      <a (click)="back()" class="cancel-button">Back</a>
      <button type="submit" form="stepForm" mat-flat-button color="primary" class="page-cta">Next</button>
    </div>
  </section>
</ng-container>
