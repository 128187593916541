<div class="holding-details">
  <div class="card-header-small">
    <h2>{{(isAsset$ | async) ? 'Asset' : 'Fund'}} info</h2>
  </div>
  <ul class="basic-info-list card-content-small">
    <!-- Asset Property type -->
    <li *ngIf="isAsset$ | async" class="basic-info-item">
      <mat-icon class="notranslate">home</mat-icon>
      <label>Property type:</label>
      <span class="value">{{ (asset$ | async).holdingType | enumString: HoldingType }}</span>
    </li>

    <!-- Fund Investment strategy -->
    <li *ngIf="(isFund$ | async) && (fund$ | async).investmentStrategyType" class="basic-info-item">
      <mat-icon class="notranslate" svgIcon="pie_chart_quarter"></mat-icon>
      <label>Investment strategy:</label>
      <span class="value">{{ (fund$ | async).investmentStrategyType | enumString: FundInvestmentStrategyType }}</span>
    </li>

    <!-- Asset Investment type -->
    <li *ngIf="(isAsset$ | async) && (asset$ | async).investmentType" class="basic-info-item">
      <mat-icon class="notranslate" svgIcon="pie_chart_quarter"></mat-icon>
      <label>Investment type:</label>
      <span class="value">{{ (asset$ | async).investmentType | enumString: InvestmentType }}</span>
    </li>

    <!-- Final closing date -->
    <li class="basic-info-item">
      <mat-icon class="notranslate">date_range</mat-icon>
      <label>Final closing date: </label>
      <span class="value">{{ (fundraising$ | async).finalClosingDate | utcDate }}</span>
    </li>

    <!-- Total capitalization -->
    <li class="basic-info-item">
      <mat-icon class="notranslate dollar-sign">attach_money</mat-icon>
      <label *ngIf="isAsset$ | async">
        {{(fundraising$ | async).securityType === InvestmentSecurityType.Debt ? 'Total loan: ' : 'Total capitalization: '}}
      </label>
      <label *ngIf="isFund$ | async">Total capitalization:</label>
      <span class="value">
        {{(fundraising$ | async).fundraisingTargetCurrency.symbol}}{{(holding$ | async).totalCapitalization | terraCurrency }}
      </span>
    </li>

    <!-- Fund Number of holdings -->
    <li *ngIf="(isFund$ | async) && (fund$ | async).relatedHoldings" class="basic-info-item">
      <mat-icon class="notranslate">home</mat-icon>
      <label>Number of assets/funds:</label>
      <span class="value">{{ (fund$ | async).relatedHoldings.length }}</span>
    </li>
  </ul>
</div>
