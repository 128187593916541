<terra-loader *ngIf="isLoading$ | async" class="loader"></terra-loader>

<div *ngIf="!(isLoading$ | async) && { engagement: engagement$ | async, fundraising: fundraising$ | async } as data" class="engagement-container">

  <div class="overview">
    <h3>Overview</h3>

    <!-- number of views  -->
    <span class="info">
      <span class="material-icons-outlined icon notranslate">visibility</span>
      {{data.engagement.views + data.engagement.opportunityLinkViews}} {{(data.engagement.views + data.engagement.opportunityLinkViews) === 1 ? 'view' : 'views'}}
    </span>

    <!-- number of replies  -->
    <span class="info">
      <span class="material-icons-outlined icon notranslate">reply_all</span>
      {{data.engagement.replies}} {{data.engagement.replies === 1 ? 'reply' : 'replies'}}
    </span>

    <!-- number of interested investors  -->
    <span class="info">
      <span class="material-icons-outlined icon notranslate">thumb_up</span>
      {{data.engagement.interested}} interested
    </span>

    <!-- number of investors that want to learn more  -->
    <span class="info">
      <span class="material-icons-outlined icon notranslate">more_horiz</span>
      {{data.engagement.wantToLearnMore}} want to learn more
    </span>
  </div>

  <div class="chart">
    <h3>View rate</h3>
    <div class="chart-container">
      <p-chart class="chart-view" type="doughnut" [data]="chartData" [options]="chartOptions"></p-chart>

      <ul class="legend">
        <li class="legend-item" [ngStyle]="{'--circle-bg-color': chartData.datasets[0].backgroundColor[labelIndex]}" *ngFor="let label of chartData.labels; index as labelIndex;">
          <label>{{label}} </label>
          <span class="value">{{ chartData.datasets[0].data[labelIndex] | number: '1.0-1' }}%</span>
        </li>
      </ul>
    </div>
  </div>

  <div class="amount">
    <h3>Total amount considered to invest</h3>
    <span class="considered">{{data.fundraising.fundraisingTargetCurrency.symbol}}{{data.engagement.totalAmountConsidered | number}} </span>
    of
    <span class="total"> {{data.fundraising.fundraisingTargetCurrency.symbol}}{{(fundraising$ | async).fundraisingTargetAmount  | number}}</span>
    <br>
    <span class="hint">* Amount received from investors' response to offering deck.</span>
  </div>

</div>
