import {Injectable, ViewContainerRef} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import {GpAssetReqRes} from '../GpAssetReqRes.model';
import {GpAssetDataService} from 'src/app/services/gp/gp-asset-data.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {GpAssetDialogContext, GpAssetServiceInterface, GpFundAssetDialogContext} from './AssetDetails.context';
import {EditAssetDialogComponent} from '../components/edit-asset-dialog/edit-asset-dialog.component';
import {
  PartialEditAssetDialogComponent
} from '../components/partial-edit-asset-dialog/partial-edit-asset-dialog.component';
import {FundraisingReqRes} from '../../shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {AssetAndFundraisingReqRes} from '../components/create-asset/AssetAndFundraisingRequest';
import {GpFundraisingDataService} from 'src/app/services/gp/gp-fundraising-data.service';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {EditAssetTabNumber} from '../components/edit-asset-dialog/EditAssetStepBaseAndInterface';
import {GpDistributionDataService} from 'src/app/services/gp/gp-distribution-data.service';
import {GpHoldingDataService} from 'src/app/services/gp/gp-holding-data.service';
import {UtilsService} from 'src/app/services/utils.service';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {EditFundAssetDialogComponent} from '../components/edit-fund-asset-dialog/edit-fund-asset-dialog.component';
import {EditFundAssetTabNumber} from '../components/edit-fund-asset-dialog/EditFundAssetStepBaseAndInterface';
import {GpHoldingService} from '../../shared/holding/gp-holding.service';
import {
  UpdateHoldingStatusResult
} from '../../shared/holding/update-holding-status-dialog/UpdateHoldingStatusResult.model';
import {
  UpdateHoldingStatusDialogComponent
} from '../../shared/holding/update-holding-status-dialog/update-holding-status-dialog.component';
import {
  UpdateHoldingStatusDialogContext
} from '../../shared/holding/update-holding-status-dialog/UpdateHoldingStatusDialogContext';
import {RoutingService} from 'src/app/services/routing.service';
import {HoldingUnderManagementTabs} from 'src/app/shared/types/GpTypes';
import {AppQuery} from 'src/app/state';
import {ClientPresentationState} from 'src/app/account/my-account/model/ui-state/ClientPresentationState';
import {UserService} from 'src/app/services/shared/user.service';
import {AssetPollingService} from './asset-polling.service';
import {BaseResponseDto} from '../../../shared/models/BaseResponseDto.model';

@Injectable()
export class GpAssetService extends GpHoldingService implements GpAssetServiceInterface {
  readonly FinalInvestmentStatuses = [InvestmentStatus.Invested, InvestmentStatus.Declined, InvestmentStatus.Potential];
  readonly holdingDiscriminator = HoldingDiscriminator.Asset;

  holding$ = new ReplaySubject<GpAssetReqRes>(1);
  discriminatorName: HoldingDiscriminator = HoldingDiscriminator.Asset;
  assetPollingService: AssetPollingService;

  getFundraisingSearchOptionsState = (state: ClientPresentationState) => state.assetsFundraising.sort;
  getDistributionSearchOptionsState = (state: ClientPresentationState) => state.assetsDistribution.sort;

  importContributionRoute$ = this.holding$.pipe(map(holding => this.routingService.importAssetContributions(holding.id)), shareReplay(1));

  constructor(
    private gpAssetDataService: GpAssetDataService,
    private gpHoldingDataService: GpHoldingDataService,
    private gpFundraisingDataService: GpFundraisingDataService,
    private gpDistributionDataService: GpDistributionDataService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private utilsService: UtilsService,
    private routingService: RoutingService,
    private appQuery: AppQuery,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private router: Router
  ) {
    super(route, gpDistributionDataService, gpFundraisingDataService, gpHoldingDataService, appQuery, utilsService);
  }

  getDistributionPageRoute(holdingId: number, distributionId: number) {
    return this.routingService.assetDistribution(holdingId, distributionId);
  }

  getCreateDistributionRoute(holdingId: number) {
    return this.routingService.createAssetDistribution(holdingId);
  }

  getImportDistributionRoute(holdingId: number): string {
    return this.routingService.importAssetDistributions(holdingId);
  }

  getHoldingPageWithTabRoute(holdingId: number, tab: HoldingUnderManagementTabs) {
    return this.routingService.gpAssetWithTab(holdingId, tab);
  }

  updateAsset(model: AssetAndFundraisingReqRes, skipNotification: boolean): Observable<AssetAndFundraisingReqRes> {
    return this.holdingId$.pipe(
      switchMap(assetId => {
        return this.gpAssetDataService.updateAsset(assetId, model, skipNotification);
      }),
      tap(response => {
        this.holding$.next(response.asset);
        this.fundraising$.next(response.fundraising);
      })
    );
  }

  updateFundAsset(model: GpAssetReqRes, skipNotification: boolean): Observable<GpAssetReqRes> {
    return this.holdingId$.pipe(
      switchMap(assetId => {
        return this.gpAssetDataService.updateFundAsset(assetId, model, skipNotification);
      }),
      tap(asset => {
        this.holding$.next(asset);
      })
    );
  }

  /** Create the initial fundraising and move the asset to Fundraising status */
  moveAssetToFundraising(model: AssetAndFundraisingReqRes): Observable<AssetAndFundraisingReqRes> {
    return this.holdingId$.pipe(
      switchMap(assetId => {
        return this.gpAssetDataService.moveAssetToFundraising(assetId, model);
      }),
      tap(assetAndFundraising => {
        this.holding$.next(assetAndFundraising.asset);
        this.fundraising$.next(assetAndFundraising.fundraising);
      })
    );
  }

  moveAssetToUnderManagement(model: AssetAndFundraisingReqRes): Observable<AssetAndFundraisingReqRes> {
    return this.holdingId$.pipe(
      switchMap(assetId => {
        return this.gpAssetDataService.moveAssetToUnderManagement(assetId, model);
      }),
      tap(assetAndFundraising => {
        this.holding$.next(assetAndFundraising.asset);
        this.fundraising$.next(assetAndFundraising.fundraising);
      })
    );
  }

  moveFundraisingToCompleted(model: AssetAndFundraisingReqRes): Observable<AssetAndFundraisingReqRes> {
    return this.holdingId$.pipe(
      switchMap(assetId => {
        return this.gpAssetDataService.moveFundraisingToCompleted(assetId, model);
      }),
      tap(assetAndFundraising => {
        this.holding$.next(assetAndFundraising.asset);
        this.fundraising$.next(assetAndFundraising.fundraising);
      })
    );
  }

  updateStatus(assetStatus: HoldingStatus, draftSendEmailsWhenMovingToUnderManagement = false) {
    return this.holdingId$.pipe(
      switchMap(assetId => this.gpAssetDataService.updateStatus(assetId, assetStatus, draftSendEmailsWhenMovingToUnderManagement)),
      tap(assetDetails => {
        // update only the assetDetails part:
        this.holding$.next(assetDetails);
      })
    );
  }

  deleteAsset() {
    return this.holdingId$.pipe(
      switchMap(assetId => {
        return this.gpAssetDataService.deleteAsset(assetId);
      })
    );
  }

  moveToArchive() {
    return this.holdingId$.pipe(
      switchMap(assetId => {
        return this.gpHoldingDataService.moveToArchive(assetId);
      })
    );
  }


  showUpdateStatus() {
    combineLatest([this.holding$, this.fundraising$]).pipe(
      take(1),
      switchMap(([asset, fundraisingDetails]) => {
        const dialogConfig = new MatDialogConfig<UpdateHoldingStatusDialogContext>();
        dialogConfig.disableClose = false;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.autoFocus = true;

        const dialogContext = new UpdateHoldingStatusDialogContext(asset, fundraisingDetails);
        dialogConfig.data = dialogContext;
        dialogConfig.viewContainerRef = this.vcr;

        const dialogRef: MatDialogRef<UpdateHoldingStatusDialogComponent, UpdateHoldingStatusResult> =
          this.dialog.open(UpdateHoldingStatusDialogComponent, dialogConfig);

        return combineLatest([dialogRef.afterClosed(), of(asset.status)]);
      }),
      untilComponentDestroyed(this)
    )
      .subscribe(([updatedStatusResult, originalAssetStatus]) => {
        if (!updatedStatusResult) {
          return;
        }
        const updatedStatus = updatedStatusResult.status;
        if (updatedStatusResult.openPartialEdit) {
          this.showPartialEditAsset();
        } else {
          if (updatedStatus && updatedStatus !== originalAssetStatus) {
            this.updateStatus(updatedStatus, updatedStatusResult.draftSendEmailsWhenMovingToUnderManagement).subscribe(
              response => {
                if (updatedStatus === HoldingStatus.Canceled) {
                  this.telemetryMovedToCanceled();
                }
                this.snackbarService.showGeneralMessage('Status changed');
              },
              error => {
                if (error instanceof BaseResponseDto) {
                  this.utilsService.alertErrorMessage(error);
                } else {
                  console.log('Error', error);
                }
              }
            );
          }
        }
      });
  }

  showEditAsset(viewContainerRef: ViewContainerRef, initialTabNumber: EditAssetTabNumber = 1, scrollToId: string = null) {
    combineLatest([this.holding$, this.fundraising$, this.fundraisings$]).pipe(
      take(1),
      switchMap(([assetDetails, fundraisingDetails, fundraisings]) => {
        const config = new MatDialogConfig<GpAssetDialogContext>();
        config.panelClass = 'edit-asset-dialog';
        // config.disableClose = true;
        config.autoFocus = false;
        config.closeOnNavigation = true;

        const context = new GpAssetDialogContext(this, assetDetails, fundraisingDetails, fundraisings);
        context.initialTabNumber = initialTabNumber;
        context.scrollToId = scrollToId;
        config.data = context;
        config.viewContainerRef = viewContainerRef;

        return this.dialog.open(EditAssetDialogComponent, config).afterClosed();
      }),
      untilComponentDestroyed(this))
      .subscribe((updatedAsset: GpAssetReqRes) => {
        if (updatedAsset) {
          this.snackbarService.showGeneralMessage('Saved changes');
          this.contRefresh$.next();
        }
      });
  }

  showEditHolding(vcr: ViewContainerRef, initialTabNumber: number, scrollToId: string = null) {
    this.showEditAsset(vcr, initialTabNumber as EditAssetTabNumber, scrollToId);
  }

  showEditFundAsset(viewContainerRef: ViewContainerRef, initialTabNumber: EditFundAssetTabNumber = 1) {

    this.holding$.pipe(
      take(1),
      switchMap((assetDetails) => {
        const config = new MatDialogConfig<GpFundAssetDialogContext>();
        config.panelClass = 'edit-fund-asset-dialog';
        // config.disableClose = true;
        config.autoFocus = false;
        config.closeOnNavigation = true;

        const context = new GpFundAssetDialogContext(this, assetDetails);
        context.initialTabNumber = initialTabNumber;
        config.data = context;
        config.viewContainerRef = viewContainerRef;

        return this.dialog.open(EditFundAssetDialogComponent, config).afterClosed();
      }),
      untilComponentDestroyed(this))
      .subscribe((updatedAsset: GpAssetReqRes) => {
        if (updatedAsset) {
          this.snackbarService.showGeneralMessage('Saved changes');
        }
      });
  }

  showPartialEditAsset() {
    combineLatest([this.holding$, this.fundraising$, this.fundraisings$]).pipe(
      take(1),
      switchMap(([assetDetails, fundraisingDetails, fundraisings]) => {
        const config = new MatDialogConfig<GpAssetDialogContext>();
        config.panelClass = 'edit-asset-dialog';
        config.disableClose = true;
        config.autoFocus = false;

        const context = new GpAssetDialogContext(this, assetDetails, fundraisingDetails, fundraisings);
        config.data = context;

        return this.dialog.open(PartialEditAssetDialogComponent, config).afterClosed();
      }),
      untilComponentDestroyed(this))
      .subscribe((updatedAsset: AssetAndFundraisingReqRes) => {
        if (updatedAsset) {
          this.snackbarService.showGeneralMessage('Saved changes');
          this.router.navigateByUrl(this.routingService.gpAssetPage(updatedAsset.asset.id));
        }
      });
  }

  // Update the asset details object locally (after an update was made in the server, and we don't want to wait for the next polling resopnse)
  changeAssetOnly(asset: GpAssetReqRes) {
    this.holding$.next(asset);
  }

  // Update the fundraising details object locally (after an update was made in the server, and we don't want to wait for the next polling resopnse)
  changeFundraisingOnly(fundraising: FundraisingReqRes) {
    this.fundraising$.next(fundraising);
  }

  holdingDownInfo() {
    this.utilsService.holdingDownInfo(HoldingDiscriminator.Asset);
  }

  private telemetryMovedToCanceled() {
    combineLatest([this.userService.accountDetails$, this.holdingId$])
      .pipe(take(1)).subscribe(([clientDetails, holdingId]) => {
        this.telemetryService.create({
          eventID: '301',
          eventTitle: 'ASSET MOVED TO CANCELED',
          holdingID: holdingId,
          organizationID: clientDetails.organizationDetails.id
        }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
      });
  }

  updateHoldingFromServer() {
    this.assetPollingService.refresh();
  }
}
