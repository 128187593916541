import { Component, OnInit, ChangeDetectionStrategy, Input, ViewContainerRef } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { InvestmentReqRes } from 'src/app/dashboard/models/investment.model';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import { DialogService } from 'src/app/services/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import HoldingFileType from 'src/app/shared/enums/HoldingFileType.enum';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import { HoldingFundraisingService } from '../../fundraising/holding-fundraising.service';
import { GpHoldingService } from '../../gp-holding.service';
import { HoldingInvestorService } from '../../holding-investor.service';
import { ConfirmPaymentRequestDocumentDeletionDialogParams } from './ConfirmPaymentRequestDocumentDeletionDialogParams';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';
import { PermissionService } from 'src/app/permission/permission.service';

@Component({
  selector: 'terra-payment-request-document-block',
  templateUrl: './payment-request-document-block.component.html',
  styleUrls: ['./payment-request-document-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentRequestDocumentBlockComponent extends OnDestroyMixin implements OnInit {
  readonly MAX_UPLOAD_DOCUMENT: number = 8;

  allowInvestorName$ = this.permissionService.allowInvestorName$;

  // @Input() savedDocuments:MetaFileLink[] = [];
  @Input() savedDocumentsLength: number = 0;
  @Input() investmentId: number = 0;
  @Input() holdingType: string = '';

  InvestmentStatus = InvestmentStatus;

  form = this.fb.group({
    documents: this.fb.array([])
  });

  investment$ = this.holdingFundraisingService.fundraising$.pipe(
    map(fundraising => fundraising.investments.find(inv => inv.id === this.investmentId)));

  investmentPaymentRequestDocument$ = this.investment$.pipe(
    map((investment) => {
      if(investment){
        return [...investment.paymentRequestDocuments.filter(p => p.id !== investment.agreementMetaFileLinkId)];
      }
      return [];
    })
  );

  holdingPaymentRequestDocument$ = this.holdingFundraisingService.holding$.pipe(
    withLatestFrom(this.investmentPaymentRequestDocument$),
    map(([holding, investmentPaymentRequestDocument]) =>
      holding.attachments.filter(holdingFile => holdingFile.fileType === HoldingFileType.PaymentRequestDocument)
        // filter out files that already attached to the payment request
        .filter(holdingPaymentRequestDocument => investmentPaymentRequestDocument.findIndex(d => d.id === holdingPaymentRequestDocument.metaFileLink.id) === -1)
        .map(doc => doc.metaFileLink)
    )
  );

  allPaymentRequestDocumentLength$ = combineLatest([this.holdingPaymentRequestDocument$, this.investmentPaymentRequestDocument$]).pipe(
    map(([holdingPaymentRequestDocument, investmentPaymentRequestDocument]) => holdingPaymentRequestDocument.length + investmentPaymentRequestDocument.length),
    tap(allPaymentRequestDocumentLength => {
      if (allPaymentRequestDocumentLength > this.MAX_UPLOAD_DOCUMENT) {
        while (this.documents.length > 0) {
          this.documents.removeAt(0);
        }
      }
    })
  );



  constructor(
    private fb: UntypedFormBuilder,
    private holdingInvestorService: HoldingInvestorService,
    private holdingFundraisingService: HoldingFundraisingService,
    private gpHoldingService: GpHoldingService,
    private vcr: ViewContainerRef,
    private snackbar: SnackbarService,
    private dialogService: DialogService,
    private utilsService: UtilsService,
    private permissionService: PermissionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addDocument(this.savedDocumentsLength || 0);
  }

  get documents() {
    return this.form.controls['documents'] as UntypedFormArray;
  }

  addDocument(savedDocumentsLength: number) {
    if ((this.documents.length + savedDocumentsLength) >= this.MAX_UPLOAD_DOCUMENT) {
      return;
    }
    const document = new UntypedFormControl();
    document.valueChanges.pipe(
      untilComponentDestroyed(this),
      withLatestFrom(this.investment$),
      filter(([metaFileLink, investment]) =>
        !!metaFileLink
        && !!investment
        && investment.status === InvestmentStatus.Signed
        && (this.documents.length + investment.paymentRequestDocuments.length) <= this.MAX_UPLOAD_DOCUMENT),
      map(([metaFileLink, _]) => metaFileLink),
      switchMap(metaFileLink => this.upload((metaFileLink as MetaFileLink).id))
    ).subscribe(
      investmentReqRes => {
        investmentReqRes?.paymentRequestDocuments?.forEach(savedMetaFileLink => {
          this.documents.removeAt(this.documents.value.findIndex(metaFileLink => !!metaFileLink && metaFileLink.id === savedMetaFileLink.id));
        });
        this.holdingFundraisingService.updatedInvestment$.next(investmentReqRes);
        this.snackbar.showGeneralMessage('Save');
      },
      err => {
        if (err instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(err);
        } else {
          this.snackbar.showGeneralMessage('Save Failed');
        }
      }
    );
    this.documents.push(document);
  }


  upload(metaFileLinkId): Observable<InvestmentReqRes> {
    return this.holdingInvestorService.uploadPaymentRequestDocuments([metaFileLinkId])
  }

  delete(metaFileLink:MetaFileLink,){
    if(!metaFileLink){
      return;
    }
    this.dialogService.confirmDialog(new ConfirmPaymentRequestDocumentDeletionDialogParams(metaFileLink.title, 'payment-request-document-block-confirm-dialog'))
    .afterClosed()
    .pipe(
      take(1),
      filter(confirm => !!confirm),
      switchMap(_ => this.holdingInvestorService.deletePaymentRequestDocument(metaFileLink.id))
    )
    .subscribe(
      investmentReqRes => {
        this.holdingFundraisingService.updatedInvestment$.next(investmentReqRes);
        this.snackbar.showGeneralMessage('File Deleted');
      },
      err => {
        if (err instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(err);
        } else {
          this.snackbar.showGeneralMessage('File Deletion Failed');
        }
      }
    )
  }

  deleteBlocked(){

    const confirmDialogParams = new ConfirmDialogParams();
    confirmDialogParams.title = `Can't Delete Documents at Holding's Level`;
    confirmDialogParams.description = `<p>This document was added at the holding's level.</p>
                                       <p>We don't recommend deleting it, this will affect all investors.</p>
                                       <p>If you still wish to delete it, click edit ${this.holdingType}.</p>`;
    confirmDialogParams.actionLabel = `Edit ${this.holdingType}`;
    confirmDialogParams.vcr = this.vcr;

    this.dialogService.confirmDialog(confirmDialogParams)
    .afterClosed().pipe(
      take(1),
      filter(openEdit => !!openEdit)
    )
    .subscribe(
        openEdit => this.gpHoldingService.showEditHolding(this.vcr, 5, 'editHoldingPaymentRequestDocument')
    );
  }

}
