import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {share, tap, map, shareReplay} from 'rxjs/operators';
import { Sort } from '@angular/material/sort';

import {GpFundService} from '../../gp-fund.service';
import {FundraisingTableItemResponse} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingTableItemResponse.model';
import FundraisingStatus from 'src/app/shared/enums/FundraisingStatus.enum';
import {RoutingService} from 'src/app/services/routing.service';
import {AppService} from 'src/app/state';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import { TourType } from 'src/app/services/gp/tours.service';
import FundraisingType from 'src/app/shared/enums/FundraisingType.enum';

@Component({
  selector: 'terra-fund-fundraisings',
  templateUrl: './fund-fundraisings.component.html',
  styleUrls: ['./fund-fundraisings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundFundraisingsComponent implements OnInit {

  // consts
  FundraisingStatus = FundraisingStatus;
  relevantTours = [ TourType.ImportContributions];

  emptyState$ = new BehaviorSubject(false);
  displayedColumns: string[] = ['name', 'closingDate', 'investorCount', 'totalInvestedAmount', 'status'];
  fundId$ = this.gpFundService.holdingId$;


  isDraft$ = this.gpFundService.holding$.pipe(map(holding => holding.status == HoldingStatus.Draft));
  isRealized$ = this.gpFundService.holding$.pipe(map(holding => holding.status == HoldingStatus.Realized));
  
  fundraisings$: Observable<FundraisingTableItemResponse[]> = this.gpFundService.fundraisings$.pipe(
    tap(items => {
      this.emptyState$.next(items.length === 0);
    }),
    map(x=> x.filter(y => [FundraisingType.None,FundraisingType.InitialFundraising, FundraisingType.Contribution].includes(y.type))),
    share()
  );

  createFundraisingUrl$ = this.fundId$.pipe(map(fundId => this.routingService.createFundFundraising(fundId)), shareReplay(1));
  importContributionRoute$ = this.gpFundService.importContributionRoute$;
  searchOptions$ = this.gpFundService.searchOptionsFundraisings$;

  constructor(
    private gpFundService: GpFundService,
    private routingService: RoutingService,
    private appService: AppService
  ) {
  }

  ngOnInit() {
  }

  getFundraisingPageUrl(fundId: number, fundraisingId: number) {
    return this.routingService.fundFundraising(fundId, fundraisingId);
  }

  sortData(sort: Sort) {
    if (!sort.active || !sort.direction) {
      return;
    }

    this.appService.updateFundFundraisingSortState({
      orderBy: sort.active,
      direction: sort.direction === 'desc' ? 'desc' : 'asc'
    });
  }
}
