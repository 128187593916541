import {
  Component,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';

import { CapitalCallService } from './capital-call.service';

@Component({
  selector: 'terra-capital-call',
  templateUrl: './capital-call.component.html',
  styleUrls: ['./capital-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapitalCallComponent implements OnInit {
  isGeneratingPaymentRequests$ = this.capitalCallService.isGeneratingPaymentRequests$;

  constructor(
    private capitalCallService: CapitalCallService
  ) { }

  ngOnInit(): void {
  }
}
