<form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" (ngSubmit)="stepSubmit()" class="cov-form-grid container-750">

  <!-- Projected performance -->
  <section class="form-section" formGroupName="projectedPerformance" *ngIf="(investmentType$ | async) as assetInvestmentType">
    <h3 class="section-title">Projected performance of investment (optional)</h3>

    <!-- Target investment IRR (Min) -->
    <div class="terra-form-field-wrapper new-row"
      *ngIf="[InvestmentType.ValueAdd, InvestmentType.Opportunistic].includes(assetInvestmentType)">
      <label for="txtTargetInvestmentIrr" class="text-input-label">Target investment IRR %</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtTargetInvestmentIrr" terraFormattedNumber formControlName="targetInvestmentIrr"
          class="lo_sensitive" />
      </mat-form-field>
    </div>
    <!-- Target investment IRR (Max) -->
    <div class="terra-form-field-wrapper" *ngIf="[InvestmentType.ValueAdd, InvestmentType.Opportunistic].includes(assetInvestmentType)">
      <label for="txtTargetInvestmentIrrMax" class="text-input-label">Target investment IRR % (Max)</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtTargetInvestmentIrrMax" terraFormattedNumber formControlName="targetInvestmentIrrMax"
          class="lo_sensitive" placeholder="Add target investment IRR % range - Optional" />
      </mat-form-field>
    </div>

    <!-- Target equity multiple (Min) (all investmentTypes) -->
    <div class="terra-form-field-wrapper new-row">
      <label for="txtTargetEquityMultiplier" class="text-input-label">Target equity multiple</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtTargetEquityMultiplier" terraFormattedNumber formControlName="targetEquityMultiplier"
          class="lo_sensitive" />
      </mat-form-field>
    </div>
    <!-- Target equity multiple (Max) (all investmentTypes) -->
    <div class="terra-form-field-wrapper">
      <label for="txtTargetEquityMultiplierMax" class="text-input-label">Target equity multiple (Max)</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtTargetEquityMultiplierMax" terraFormattedNumber formControlName="targetEquityMultiplierMax"
          class="lo_sensitive" placeholder="Add target equity multiplier range - Optional" />
      </mat-form-field>
    </div>

    <!-- Projected cash on cash rate % -->
    <div class="terra-form-field-wrapper" *ngIf="[InvestmentType.Core, InvestmentType.CorePlus].includes(assetInvestmentType)">
      <label for="txtCashOnCashRate" class="text-input-label">Projected cash on cash rate %</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtCashOnCashRate" terraFormattedNumber formControlName="cashOnCashRate" class="lo_sensitive" />
      </mat-form-field>
    </div>

    <!-- Projected non-leveraged cap rate  % -->
    <div class="terra-form-field-wrapper" *ngIf="[InvestmentType.Core, InvestmentType.CorePlus].includes(assetInvestmentType)">
      <label for="txtNonLeveragedCapRate" class="text-input-label">Projected non-leveraged cap rate %</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtNonLeveragedCapRate" terraFormattedNumber formControlName="nonLeveragedCapRate"
          class="lo_sensitive" />
      </mat-form-field>
    </div>

    <!-- Expected ROI -->
    <div class="terra-form-field-wrapper" *ngIf="[InvestmentType.ValueAdd, InvestmentType.Opportunistic].includes(assetInvestmentType)">
      <label for="txtExpectedRoi" class="text-input-label">Expected ROI %</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtExpectedRoi" terraFormattedNumber formControlName="expectedRoi" class="lo_sensitive" />
      </mat-form-field>
    </div>

    <!-- Preferred return % (all investmentTypes) -->
    <div class="terra-form-field-wrapper">
      <label for="txtPreferredReturn" class="text-input-label">Preferred return %</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtPreferredReturn" terraFormattedNumber formControlName="preferredReturn" class="lo_sensitive" />
      </mat-form-field>
    </div>

    <!-- Target investment period (all investmentTypes) -->
    <div class="terra-form-field-wrapper">
      <label for="txtTargetInvestmentPeriod" class="text-input-label">Target investment period in years</label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtTargetInvestmentPeriod" terraFormattedNumber formControlName="targetInvestmentPeriod"
          class="lo_sensitive" />
      </mat-form-field>
    </div>

  </section>

  <!-- Original project budget (optional) -->
  <section class="form-section" *ngIf="(investmentType$ | async) === InvestmentType.Opportunistic">
    <h3 class="section-title">Original project budget (optional)</h3>
    <ng-container formGroupName="projectBudgetOriginal">

      <!-- Acquisition -->
      <div class="terra-form-field-wrapper">
        <label for="txtAcquisition" class="text-input-label">Acquisition</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtAcquisition" terraFormattedNumber formControlName="acquisition" class="lo_sensitive" />
          <span matSuffix>{{ (currency$ | async).iso }}</span>
        </mat-form-field>
      </div>

      <!--  Hard cost -->
      <div class="terra-form-field-wrapper">
        <label for="txtHardCost" class="text-input-label"> Hard cost</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtHardCost" terraFormattedNumber formControlName="hardCost" class="lo_sensitive" />
          <span matSuffix>{{ (currency$ | async).iso }}</span>
        </mat-form-field>
      </div>

      <!--  Soft cost -->
      <div class="terra-form-field-wrapper">
        <label for="txtSoftCost" class="text-input-label"> Soft cost</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtSoftCost" terraFormattedNumber formControlName="softCost" class="lo_sensitive" />
          <span matSuffix>{{ (currency$ | async).iso }}</span>
        </mat-form-field>
      </div>

      <!--  Financing -->
      <div class="terra-form-field-wrapper">
        <label for="txtFinancing" class="text-input-label"> Financing</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtFinancing" terraFormattedNumber formControlName="financing" class="lo_sensitive" />
          <span matSuffix>{{ (currency$ | async).iso }}</span>
        </mat-form-field>
      </div>

      <!--  Total costs -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalCosts" class="text-input-label"> Total costs</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTotalCosts" terraFormattedNumber formControlName="totalCosts" class="lo_sensitive" />
          <span matSuffix>{{ (currency$ | async).iso }}</span>
        </mat-form-field>
      </div>


      <!--  Total costs per gross area -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalCostsPerGrossAreaUnit" class="text-input-label"> Total costs per gross
          {{ (areaUnit$ | async) | enumString : AreaUnit }} </label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTotalCostsPerGrossAreaUnit" terraFormattedNumber formControlName="totalCostsPerGrossAreaUnit"
            class="lo_sensitive" />
          <span matSuffix>{{ (currency$ | async).iso }}</span>
        </mat-form-field>
      </div>

      <!--  Total costs per net area  -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalCostsPerNetAreaUnit" class="text-input-label"> Total costs per net
          {{ (areaUnit$ | async) | enumString : AreaUnit }} </label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTotalCostsPerNetAreaUnit" terraFormattedNumber formControlName="totalCostsPerNetAreaUnit"
            class="lo_sensitive" />
          <span matSuffix>{{ (currency$ | async).iso }}</span>
        </mat-form-field>
      </div>
    </ng-container>
  </section>

</form>

<section class="footer">
  <div class="container-750">
    <!-- Error message -->
    <div *ngIf="isSubmitAttempt" class="error-section">
      <p *ngIf="pageForm.invalid" class="error-message">
        Please review and correct the fields marked in red
      </p>
    </div>
    <div class="form-actions">
      <a (click)="back()" class="cancel-button">Back</a>
      <button type="submit" form="stepForm" mat-flat-button color="primary" class="page-cta">
        Next
      </button>
    </div>
  </div>
</section>
