import { Component, OnInit } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';

import { StartInitialFundraisingStepBase } from '../StartInitialFundraisingStepBaseAndInterface';
import { StartInitialFundraisigStepNumber, StartInitialFundraisingService } from '../start-initial-fundraising.service';
import InvestmentType from 'src/app/shared/enums/InvestmentType.enum';
import { GpAssetService } from '../../../gp-asset.service';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';

@Component({
  selector: 'terra-start-fundraising-investment-step',
  templateUrl: './start-fundraising-investment-step.component.html',
  styleUrls: ['./start-fundraising-investment-step.component.scss']
})
export class StartFundraisingInvestmentStepComponent extends StartInitialFundraisingStepBase implements OnInit {

  readonly STEP_NUMBER: StartInitialFundraisigStepNumber = 2;

  // Enums
  InvestmentType = InvestmentType;
  AreaUnit = AreaUnit;

  showSecondPhotosRow = false;

  areaUnit$ = this.gpAssetService.holding$.pipe(map(asset => asset.areaUnits), shareReplay(1));
  currency$ = this.gpAssetService.holding$.pipe(map(asset => asset.initialCurrency), shareReplay(1));
  investmentType$ = this.gpAssetService.holding$.pipe(map(asset => asset.investmentType), shareReplay(1));

  constructor(private startInitialFundraisingService: StartInitialFundraisingService, private gpAssetService: GpAssetService) {
    super(startInitialFundraisingService);
  }

  ngOnInit() {
    this.startInitialFundraisingService.currentStep$.next(this.STEP_NUMBER);
  }
}
