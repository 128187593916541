import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';

import { GpFundReportService } from '../gp-fund-report.service';
import FundInvestmentStrategyType from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import { GpFundService } from '../../../../gp-fund.service';

@Component({
  selector: 'terra-fund-report-cumulative-info',
  templateUrl: './fund-report-cumulative-info.component.html',
  styleUrls: ['./fund-report-cumulative-info.component.scss']
})
export class FundReportCumulativeInfoComponent implements OnInit {
  fund$ = this.gpFundService.holding$;

  currency$ = this.fund$.pipe(map(fund => fund.initialCurrency));

  pageForm: UntypedFormGroup = this.gpFundReportService.formStep2;
  isSubmitted = false;

  // Enums
  FundInvestmentStrategyType = FundInvestmentStrategyType;

  fundInvestmentStrategy$ = this.fund$.pipe(map(fund => fund.investmentStrategyType));

  constructor(private gpFundReportService: GpFundReportService, private gpFundService: GpFundService) { }

  ngOnInit() { }

  stepSubmit() {
    if (this.pageForm.valid) {
      this.gpFundReportService.isGeneralServerError = false;
      this.gpFundReportService.currentStep$.next(3);
    }
  }

  back() {
    this.gpFundReportService.currentStep$.next(1);
  }
}
