<ng-container *ngIf="{
      holding: holding$ | async,
      enableNotice: enableNotice$ | async,
      enablePayout: enablePayout$ | async,
      finalBalance: calculateFinalBalance() | async
  } as context">
  <!-- Holding is down -->
  <div *ngIf="context?.holding?.isPrivate" class="holding-down-page-stripe">
    <mat-icon class="notranslate holding-down-icon" svgIcon="holding_down"></mat-icon>
    <span> {{ discriminatorStr }} is down. When you’re ready to put it back up, go to the
      <a [routerLink]="holdingPageUrl(context?.holding?.id)" class="light link">
        {{ discriminatorLowerCaseStr }} page</a> and change it.
      <a (click)="holdingDownInfo()" class="light link"> Learn more</a></span>
  </div>

  <!-- Loader -->
  <ng-template #pageLoader>
    <terra-loader class="page-loader"></terra-loader>
  </ng-template>

  <div *ngIf="distribution && !showInternalLoader; else pageLoader" class="container-800">
    <!-- Breadcrumbs -->
    <div class="breadcrumbs">
      <a [routerLink]="holdingPageUrl(context?.holding?.id)"><span class="lo_sensitive">
          {{ context?.holding?.name }}</span> > Distributions >
      </a>
      <span class="current">{{ distribution.name }} distribution</span>
    </div>
    <!-- Title -->
    <span class="title">
      <h1 class="lo_sensitive">{{ context?.holding?.name }} - {{ distribution.name }} distribution</h1>
      <terra-export-distributions-menu
        *ngIf="distribution.status === distributionStatus.Completed || distribution.status === distributionStatus.PartialCompleted"
        [distributionId]="distribution.id">
      </terra-export-distributions-menu>
    </span>

    <div class="distribution-status full-width">
      <ng-container *ngIf="distribution.isGeneratingOrders else distributionStatusRow">
        Status:
        <mat-icon class="processing-icon notranslate">access_time</mat-icon>
        Processing distribution
      </ng-container>
      <ng-template #distributionStatusRow>
        Status: <span [class.failed]="distribution.status === distributionStatus.Failed" class="status-text">{{
          distribution.status |
          enumString : distributionStatus
          }}
          @if(distribution.status === distributionStatus.PartialCompleted){
            <mat-icon class="distribution-status-tooltip-icon" hideDelay="2000"
              pTooltip="To see which payments are completed, in progress, or may require attention, please check the status of each transfer below."
              tooltipPosition="bottom" tooltipStyleClass="text-center wider">
              help_outline
            </mat-icon>
            }</span>
        
        <span class="top-buttons">
          @if(loadingAction$ | async){
          <terra-loader></terra-loader>
          }
          @else {
          <a *ngIf="allowForcedMarkAsCompleted()" (click)="markAsCompleted(true)" class="link">
            Mark as Completed
          </a>
          <a *ngIf="enableEditing(distribution.status)" class="link" (click)="editDistribution()">
            Edit Distribution
          </a>
          <a (click)="deleteDistribution()" *ngIf="isDeleteAllowed" class="link delete-distribution-link">
            Delete Distribution
          </a>
          }
        </span>
      </ng-template>
    </div>

    <div *ngIf="distribution.isGeneratingOrders" class="info-message generating-orders-message">
      Hang tight, your distribution may take a few minutes to process. It is safe to leave this page.
    </div>

    <ng-container *ngIf="!distribution.isGeneratingOrders">
      <!-- What's next - New status -->
      <div *ngIf="distribution.status === distributionStatus.New" class="info-message whats-next full-wdith">
        <h2>What’s next?</h2>
        <ng-container>
          <span>You can send a notice to investors, letting them know about this upcoming distribution, and allowing
            them to update their
            bank details and currency preference for this payment.</span>
          <br /><br />
          <span>Alternatively, signer members can finalize the distribution, and move forward to make the payout.</span>
        </ng-container>
      </div>

      <!-- What's next - NoticeSent status -->
      <div *ngIf="distribution.status === distributionStatus.NoticeSent" class="info-message whats-next full-wdith">
        <h2>What’s next?</h2>
        <ng-container>
          <span>Notice was sent to investors. They can now review and update their bank details and currency preferences
            via the Investor
            Portal.</span>
          <br /><br />
          <span>You can review each payment below to see bank detail updates and decide whether to override.</span>
        </ng-container>
      </div>

      <!-- What's next - Waiting for funds status -->
      <div *ngIf="distribution.status === distributionStatus.WaitingForFunds"
        class="info-message whats-next full-wdith">
        <h2>What’s next?</h2>
        <ng-container *ngIf="distribution.distributionCovercySourceBankAccount; else noSourceBankAccountDefined">
          <span>Please deposit the total amount so Covercy can move forward with distributing the funds to investors.
            <a *ngIf="!noAccessToBanksMessage" (click)="showDepositAccountDetails()" class="link">
              Show bank account details
            </a>
          </span>
        </ng-container>
        <ng-template #noSourceBankAccountDefined>
          <span>
            <a class="light link" href="//www.covercy.com/contact-us" target="_blank">
              Please contact your Covercy Account Manager for next steps.
            </a>
          </span>
        </ng-template>
      </div>

      <!-- What's next - Processing status -->
      <div *ngIf="distribution.status === distributionStatus.ProcessingTransfers"
        class="info-message whats-next full-wdith">
        <h2>What’s next?</h2>
        <ng-container *ngIf="isDistributingAnyAmountViaCovercy; else allExternal">
          <span>Sit back and relax, Covercy is taking care of distributing the funds to the investors. You can view the
            status of each
            individual payment in the list below.</span>
          <br />
          <span>Once all payments have been completed, we'll mark this distribution as completed automatically.</span>
        </ng-container>
        <ng-template #allExternal>
          <span>It's now up to you to process the payments to your investors, and mark complete once finished.</span>
        </ng-template>
      </div>

      <!-- Mark as Complete -->
      <div *ngIf="showFinishedDistributionNotification" class="container" id="finished-distribution">
        <img src="assets/images/icons/change_status icon.svg" />
        <span>
          <strong>Finished distribution? </strong>
          As you marked some/all of the distribution payments to be made outside Covercy, you can manually <a
            (click)="markAsCompleted()" class="change-status light link">Mark this distribution as Complete</a>.
        </span>
      </div>

      <!-- Error when marking as complete failed -->
      <p *ngIf="errorMarkingAsComplete && showFinishedDistributionNotification" class="error-message">
        Couldn't mark the distribution as complete. Please <a href="//www.covercy.com/contact-us"
          target="_blank">contact
          support</a>
      </p>

      <div *ngIf="!distribution.isGeneratingOrders" class="form-grid-new terra-error-style">
        <p *ngIf="context?.holding?.isPrivate && (distribution.status == distributionStatus.New || distribution.status == distributionStatus.NoticeSent)"
          class="holding-down-stripe full-width">
          Distribution notice and finalization cannot be done when the {{ discriminatorLowerCaseStr }} is down.
          <a (click)="holdingDownInfo()" class="light link">Learn more</a>
        </p>

        <!-- Action buttons -->
        <button (click)="sendNotice()" *ngIf="!isNoticeSent && !canBeEdited" [disabled]="!(context?.enableNotice)"
          class="tall-button" color="primary" mat-stroked-button type="button" terraCheckPermissions
          [minimumFinancialPermissionLevel]="FinancialPermissionLevel.AuthorizedSignatory"
          [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.PopupOnClick">
          Send notice to investors
        </button>
        <button *ngIf="isNoticeSent && !canBeEdited" [disabled]="true" class="notice-sent tall-button" color="primary"
          mat-stroked-button type="button">
          <mat-icon class="notranslate">check</mat-icon>
          Notice sent on {{ distribution.noticeSentTimeStamp | utcDate }}
        </button>

        <span *ngIf="!isPayoutSent && !canBeEdited" class="half-width">
          <button (click)="makePayout()"
            [disabled]="!(context?.enablePayout) || !!negativeBalanceError || !!amountAchExceedsLimitsError || !!amountWireExceedsLimitsError"
            terraCheckPermissions [minimumFinancialPermissionLevel]="FinancialPermissionLevel.AuthorizedSignatory"
            [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.PopupOnClick"
            class="finalize-button tall-button" color="primary" mat-stroked-button type="button">
            Finalize distribution and make payout
          </button>
        </span>
        <button *ngIf="isPayoutSent && !canBeEdited" [disabled]="true" class="payout-sent tall-button" color="primary"
          mat-stroked-button type="button">
          <mat-icon class="notranslate">check</mat-icon>
          Finalized on {{ distribution.payoutSentTimeStamp | utcDate }}
        </button>

        <p *ngIf="anyUsBankHasNonUsdCurrency()" class="error-message full-width">
          US bank accounts must have USD as destination currency in order to proceed with the payout
        </p>

        <!-- Error sending notice / payout -->
        <p *ngIf="isActionFailed" class="error-message full-width">{{ actionFailedError }}</p>
        <p *ngIf="negativeBalanceError" class="error-message full-width">{{ negativeBalanceError }}</p>
        <p *ngIf="amountAchExceedsLimitsError" class="error-message full-width">{{ amountAchExceedsLimitsError }}</p>
        <p *ngIf="amountWireExceedsLimitsError" class="error-message full-width">{{ amountWireExceedsLimitsError }}</p>

        <form (keydown.enter)="$event.preventDefault()" *ngIf="pageForm && !showInternalLoader; else pageLoader"
          [formGroup]="pageForm" class="cov-form-grid full-width">
          <!-- Distribution details -->
          <section class="form-section full-width">
            <h3 class="section-title">Distribution details</h3>
            <!-- Distribution period -->
            <div class="terra-form-field-wrapper">
              <label class="text-input-label" for="ddlDistributionPeriod">Distribution period</label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="distributionPeriod" id="ddlDistributionPeriod" placeholder="Select">
                  <mat-option *ngFor="let period of DistributionPeriod.listAll()" [value]="period">{{ period |
                    enumString: DistributionPeriod }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Quarter and Year -->
            <div *ngIf="pageForm.get('distributionPeriod').value === DistributionPeriod.Quarterly"
              class="terra-form-field-wrapper quarter-and-year">
              <div class="quarter-field half-width">
                <label class="text-input-label" for="ddlQuarter">Quarter</label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="quarter" id="ddlQuarter" placeholder="Select">
                    <mat-option *ngFor="let quarter of [1, 2, 3, 4]" [value]="quarter">Q{{ quarter }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="year-field half-width">
                <label class="text-input-label" for="ddlYear">Year</label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="year" id="ddlYear" placeholder="Select">
                    <mat-option *ngFor="let year of yearsList" [value]="year">{{ year }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- Start and End dates of the period  -->
            <ng-container *ngIf="pageForm.get('distributionPeriod').value === DistributionPeriod.Custom">
              <!-- Start date -->
              <div class="terra-form-field-wrapper">
                <terra-custom-utc-date-picker
            id="txtDistributionStartDate"
            formControlName="periodStartDate"
            label="First day included in distribution"
            [minDate]="periodMinDate"
            [maxDate]="periodMaxDate"
            [requiredError]="periodStartDate.hasError('required')"
            [touched]="periodStartDate.touched"
            [minError]="periodStartDate.hasError('minDate')"
            [maxError]="periodStartDate.hasError('maxDate')"
            ></terra-custom-utc-date-picker>
              </div>
              <!-- End date -->
              <div class="terra-form-field-wrapper">
                <terra-custom-utc-date-picker
                id="txtPeriodEndDate"
                formControlName="periodEndDate"
                label="Last day included in period"
                [minDate]="periodStartDate.value"
                [maxDate]="periodMaxDate"
                [requiredError]="periodEndDate.hasError('required')"
                [touched]="periodEndDate.touched"
                [minError]="periodEndDate.hasError('minDate')"
                [maxError]="periodEndDate.hasError('maxDate')"
                ></terra-custom-utc-date-picker>
              </div>
            </ng-container>

            <!-- Reason for distribution -->
            <div class="terra-form-field-wrapper">
              <label class="text-input-label" for="ddlTransactionPurport">Type of distribution</label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="distributionTransactionPurpose" id="ddlTransactionPurport"
                  placeholder="Select">
                  <mat-option [value]="null">
                    Select
                  </mat-option>
                  <mat-option *ngFor="let item of editDistributionReasonsToRenderList" [value]="item">
                    {{ item | enumString: distributionTransactionPurpose }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="pageForm.get('distributionTransactionPurpose').hasError('required')">
                  Required
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Reason for distribution - Other -->
            <div *ngIf="pageForm.get('distributionTransactionPurpose').value === distributionTransactionPurpose.Other"
              class="terra-form-field-wrapper">
              <label class="text-input-label" for="txtDistributionTransactionPurposeOther">
                Please specify
              </label>
              <mat-form-field appearance="outline">
                <input class="lo_sensitive" formControlName="distributionTransactionPurposeOther"
                  id="txtDistributionTransactionPurposeOther" matInput type="text" />
                <mat-error *ngIf="pageForm.get('distributionTransactionPurposeOther').hasError('required')">
                  Required
                </mat-error>
              </mat-form-field>
            </div>

            <div class="terra-form-field-wrapper">
              <!-- distribution SourceCurrency -->
              <div class="currency-component">
                <label class="currency-label text-input-label" for="ddlCurrency">Deposit currency
                </label>
                <mat-form-field appearance="outline" class="currency-ddl-wrapper">
                  <mat-select (selectionChange)="refreshAmounts$.emit()" [compareWith]="currencyCompareFn"
                    formControlName="distributionSourceCurrency" id="ddlCurrency" placeholder="Select">
                    <mat-option *ngFor="let currency of currencies$ | async" [value]="currency">
                      {{ currency.symbol }} {{ currency.iso }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="pageForm.get('distributionSourceCurrency').hasError('required')">
                    Required
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="payment-type-component" *ngIf="false">
                <label class="text-input-label" for="ddlPaymentType">Payment type
                </label>
                <mat-form-field appearance="outline" class="">
                  <mat-select formControlName="unitPaymentType" id="ddlPaymentType" placeholder="Select">
                    <mat-option *ngFor="let paymentType of UnitPaymentType.listAll()" [value]="paymentType">{{
                      UnitPaymentType.toString(paymentType) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- Other transfer purpose -->
            <div class="terra-form-field-wrapper"
              *ngIf="pageForm.get('distributionTransactionPurpose').value === distributionTransactionPurpose.Other">
              <label for="txtDistributionTransactionPurposeOther" class="text-input-label">
                Transfer Purpose
              </label>
              <mat-form-field appearance="outline">
                <mat-select id="distributionTransactionPurposeOtherType"
                  formControlName="distributionTransactionPurposeOtherType" class="lo_sensitive">
                  <mat-option *ngFor="let otherType of distributionTransactionOtherTypeAll" [value]="otherType">
                    {{ otherType | enumString: distributionTransactionOtherType }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="pageForm.get('distributionTransactionPurposeOtherType').hasError('required')">
                  Required
                </mat-error>
              </mat-form-field>
            </div>
          </section>
        </form>

        <!-- UNIT CRE bank account -->
        <div
          *ngIf="!noAccessToBanksMessage && distribution.unitBankAccountId && distribution.status === distributionStatus.New"
          class="full-width unit-bank-section">
          <h3 class="section-title">Sender bank account</h3>
          <div class="unit-bank-info">
            <p-dropdown [options]="[distribution.unitBankAccount]" [ngModel]="distribution.unitBankAccount.id"
              optionValue="id" placeholder="Select CRE Bank Account" [disabled]="true">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="distribution.unitBankAccount">
                  <terra-option-item [text]="distribution.unitBankAccount.accountNickname"
                    [subText]="subTextOption(distribution.unitBankAccount)" icon="account_balance"></terra-option-item>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>

        <div class="terra-holding-card terra-card-hover-effect full-width"
          *ngIf="distribution.status === distributionStatus.Completed">
          <terra-add-investors-out-of-distribution title="Add Investor From Contact List">
          </terra-add-investors-out-of-distribution>
        </div>

        <ng-container *ngIf="!!distribution.waterfallMetaFileLink">
          <span>
            Calculated Waterfall: <a [href]="distribution.waterfallMetaFileLink.url" target="_blank">Download</a>
          </span>
        </ng-container>

        <h3 *ngIf="investorsDistributionTransfer?.length" class="section-title">
          Distribution amounts for {{ investorsDistributionTransfer?.length }} investor(s) in this {{
          discriminatorLowerCaseStr }}:
        </h3>

        <!-- Investors distribution transfers -->
        <mat-accordion #accordion="matAccordion" *ngIf="investorsDistributionTransfer?.length"
          class="distributions-accordion investors-list full-width">
          <terra-loader *ngIf="isDeletingTransfer; else transferData"></terra-loader>
          <ng-template #transferData>
            <mat-expansion-panel *ngFor="let distributionTransfer of investorsDistributionTransfer"
              [attr.id]="'distributionTransfer_' + distributionTransfer.id">
              <mat-expansion-panel-header collapsedHeight="107px" expandedHeight="113px">
                <mat-panel-title>
                  <span *ngIf="distributionTransfer.invalidForm" class="material-icons field-error">error_outline</span>
                  <span class="investor-title">
                    <!-- First line: name and amount -->
                    <span class="first-line">
                      <!-- Name -->
                      <span class="lo_sensitive investor-name">
                        <ng-container *ngIf="allowInvestorName$ | async">{{ distributionTransfer.investingEntity.name }}
                          <ng-container *ngIf="distributionTransfer.investingEntity.contact">(
                            {{ distributionTransfer.investingEntity.contact?.firstName }}
                            {{ distributionTransfer.investingEntity.contact?.middleName }}
                            {{ distributionTransfer.investingEntity.contact?.lastName }}
                            <ng-container
                              *ngIf="!distributionTransfer.investingEntity.contact?.firstName || !distributionTransfer.investingEntity.contact?.lastName">
                              {{ distributionTransfer.investingEntity.contact?.email }}
                            </ng-container>
                            )
                          </ng-container>
                          <span class="ie-name" *ngIf="!(isAllContactsVisible$ | async)">Entity {{
                            distributionTransfer.investingEntityId }}</span>
                        </ng-container>
                        <ng-container *ngIf="!(allowInvestorName$ | async)">Entity {{
                          distributionTransfer.investingEntity.nickname }}
                          <ng-container *ngIf="distributionTransfer.investingEntity.contact">(Contact {{
                            distributionTransfer.investingEntity.contact?.nickname || '' }})</ng-container>
                        </ng-container>
                      </span>
                      <!-- Amount -->
                      <span class="header-amount">{{ feeSymbol }}{{ (distributionTransfer?.amountNet | terraCurrency) ||
                        0 }} {{ feeCurrency | hideUsdIso }}</span>
                    </span>
                    <!-- second line - order status  -->
                    <span [ngClass]="{'second-line': true, 'failed-transaction': getUnitStatus(distributionTransfer)?.isFailed || getUnitStatusCFSB(distributionTransfer)?.isFailed }">
                      <span *ngIf="distributionTransfer?.externalPayment" class="outside-of-covercy-text">Payment to be
                        made outside of Covercy</span>
                      {{ getUnitStatusCFSB(distributionTransfer)?.text }} {{ getExternalStatusText(distributionTransfer) }}
                      {{ getUnitStatus(distributionTransfer)?.text }}
                      <!-- Unsaved changes -->
                      <span *ngIf="distributionTransfer?.isBankAccountChangeUnsaved; else specificProblem"
                        class="missing-info-notice">
                        <mat-icon class="notranslate">edit</mat-icon>Unsaved changes
                      </span>
                      <ng-template #specificProblem>
                        <!-- Bank account changed by LP, and should be approved -
                        If the bank account is an LP preferred account, and it's not yet approved   -->
                        <span *ngIf="updatedAccountByInvestor(distributionTransfer)" class="missing-info-notice">
                          <mat-icon class="notranslate">announcement</mat-icon>{{INVESTOR_UPDATE_ACCOUNT_MSG}}
                        </span>
                        <!-- Missing bank account notice -->
                        <span *ngIf="!distributionTransfer?.externalPayment
                      && !distributionTransfer?.clientBankAccount
                      && !distributionTransfer?.unitBankAccount
                      && distributionTransfer.amountNet > 0" class="missing-info-notice">
                          {{MISSING_BANK}}
                        </span>

                        <!-- Bank account has missing currency -->
                        <span *ngIf="!distributionTransfer?.externalPayment
                        && distributionTransfer?.clientBankAccount
                        && !distributionTransfer?.clientBankAccount.preferredCurrencyId
                        && distributionTransfer.amountNet > 0" class="missing-info-notice">
                          <mat-icon class="notranslate">edit</mat-icon>
                          {{ MISSING_CURRENCY_TITLE_ERROR }}
                        </span>
                      </ng-template>
                      <!-- Bank account supplied by LP is missing location details  -->
                      <span *ngIf="distributionTransfer && distributionTransfer.clientBankAccount
                      && !distributionTransfer.clientBankAccount.holderLocationDetails_Id
                      && distributionTransfer.amountNet > 0" class="missing-info-notice">
                        Investor's bank account is missing some details, please select a different account or create a
                        new one.
                      </span>
                    </span>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- Content -->
              <ng-template matExpansionPanelContent>
                <terra-distribution-transfer-details [holdingId]="holdingId$ | async"
                  [distributionStatus]="distribution.status" [distributionTransfer]="distributionTransfer"
                  [distributionCurrencyIso]="feeCurrency"
                  [bankAccounts]="getBankAccountsForTransfer(distributionTransfer)"
                  [clientBankAccounts]="getClientBankAccountsForTransfer(distributionTransfer)"
                  [unitBankAccounts]="getUnitBankAccountsForTransfer(distributionTransfer)"
                  [distributionId]="distribution.id" [isThisGPDistributeTransfer]=false
                  [revertAllChanges]="revertAllChanges$" (amountChange)="refreshAmounts$.emit()"
                  (bankApproved)="bankApproved$.emit()" [importedDistribution]="distributionTransfer.externalPayment"
                  [distributionPurposes]="reasonsForTransfer$ | async" [updateFormFromModel]="updateFormAmounts$"
                  [disableGrossAmount]="!!distribution.waterfallMetaFileLinkId">
                </terra-distribution-transfer-details>
              </ng-template>
            </mat-expansion-panel>
          </ng-template>
        </mat-accordion>

        <!-- GP distribution transfer -->
        <mat-accordion #accordion="matAccordion" *ngIf="!!gpDistributionTransfer"
          [ngClass]="{'no-padding' : !investorsDistributionTransfer?.length }"
          class="distributions-accordion gp-distribution full-width">
          <terra-loader *ngIf="isDeletingTransfer; else transferData"></terra-loader>
          <ng-template #transferData>
            <mat-expansion-panel [attr.id]="'distributionTransfer_' + gpDistributionTransfer.id">
              <mat-expansion-panel-header collapsedHeight="107px" expandedHeight="113px">
                <mat-panel-title>
                  <span class="investor-title">
                    <!-- First line: name and amount -->
                    <span class="first-line">
                      <span class="investor-name lo_sensitive">
                        GP promote
                      </span>
                      <!-- Amount -->
                      <span class="header-amount">{{ feeSymbol }}{{ (gpDistributionTransfer?.amountNet | terraCurrency)
                        || 0 }} {{ feeCurrency | hideUsdIso }}</span>
                    </span>
                    <!-- second line - order status  -->
                    <span [ngClass]="{'second-line': true, 'failed-transaction': getUnitStatus(gpDistributionTransfer)?.isFailed }">
                      <span *ngIf="gpDistributionTransfer?.externalPayment" class="outside-of-covercy-text">Payment to
                        be made outside of
                        Covercy</span>
                      {{ gpDistributionTransfer?.order?.externalStatusText }}
                      {{ getUnitStatus(gpDistributionTransfer)?.text }}
                      <!-- Unsaved changes -->
                      <span *ngIf="gpDistributionTransfer?.isBankAccountChangeUnsaved; else specificGpProblem"
                        class="missing-info-notice">
                        <mat-icon class="notranslate">edit</mat-icon>Unsaved changes
                      </span>
                      <ng-template #specificGpProblem>
                        <!-- Missing bank account notice -->
                        <span *ngIf="!gpDistributionTransfer?.externalPayment
                        && !gpDistributionTransfer?.clientBankAccountId
                        && !gpDistributionTransfer?.unitBankAccountId
                        && gpDistributionTransfer.amountNet > 0" class="missing-info-notice">
                          Missing bank account
                        </span>

                        <!-- Bank account has missing currency -->
                        <span *ngIf="!gpDistributionTransfer?.externalPayment && gpDistributionTransfer.amountNet > 0
                        && ((gpDistributionTransfer?.clientBankAccount
                        && !gpDistributionTransfer?.clientBankAccount.preferredCurrencyId)
                        || (!gpDistributionTransfer?.clientBankAccount && !gpDistributionTransfer?.unitBankAccount))"
                          class="missing-info-notice">
                          <mat-icon class="notranslate">edit</mat-icon>
                          {{ MISSING_CURRENCY_TITLE_ERROR }}
                        </span>
                      </ng-template>
                    </span>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- Content -->
              <ng-template matExpansionPanelContent>
                <terra-distribution-transfer-details [holdingId]="holdingId$ | async"
                  [distributionStatus]="distribution.status" [distributionTransfer]="gpDistributionTransfer"
                  [distributionCurrencyIso]="feeCurrency" [bankAccounts]="bankAccountsForGpPromote"
                  [clientBankAccounts]="clientBankAccountsForGpPromote"
                  [unitBankAccounts]="unitBankAccountsForGpPromote" [distributionId]="distribution.id"
                  [isThisGPDistributeTransfer]=true [revertAllChanges]="revertAllChanges$"
                  (amountChange)="refreshAmounts$.emit()" [importedDistribution]="canBeEdited"
                  [distributionPurposes]="reasonsForTransfer$ | async" [updateFormFromModel]="updateFormAmounts$"
                  [updateGpPromote]="updateGpPromote$" [disableGrossAmount]="!!distribution.waterfallMetaFileLinkId">
                </terra-distribution-transfer-details>
              </ng-template>
            </mat-expansion-panel>
          </ng-template>
        </mat-accordion>
      </div>

    </ng-container>

    <!-- Summary box -->
    <terra-distribution-total-amounts [feeCurrency]="feeCurrency" [feeSymbol]="feeSymbol"
      [isDistributingAnyAmountViaCovercy]="isDistributingAnyAmountViaCovercy" [isFeeEstimated]="isFeeEstimated"
      [isUnitBankAccount]="isUnitBankAccount" [distributionStatus]="distribution.status"
      [totalAmountViaCovercy]="distribution.fees.totalAmountViaCovercy"
      [totalAmountOutsideOfCovercy]="distribution.fees.totalAmountOutsideOfCovercy"
      [totalAdjustmentsAmountViaCovercy]="distribution.fees.totalAdjustmentsAmountViaCovercy"
      [totalAdjustmentsAmountOutsideOfCovercy]="distribution.fees.totalAdjustmentsAmountOutsideOfCovercy"
      [withheldTaxes]="distribution.fees.withheldTaxes" [totalAmountAfterFees]="distribution.fees.totalAmountAfterFees"
      [gpEffectiveMarkupPercentage]="distribution.fees.gpEffectiveMarkupPercentage"
      [feeAmount]="distribution.fees.feeAmount"
      [distributionTotalFee]="distribution.fees.markupAmount + distribution.fees.feeAmount"
      [distributionTransfers]="distribution.distributionTransfers" [reasonsForTransfer]="reasonsForTransfer$ | async"
      [unitFees]="calculateUnitFee() | async" [loading]="totalFeeLoading$ | async">
    </terra-distribution-total-amounts>
  </div>
</ng-container>