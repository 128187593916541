<div class="flex-container">
    <div mat-dialog-title class="header">
        <span class="icon-currency-exchange">
            <mat-icon class="material-symbols-outlined">
                {{data.iconName}}
            </mat-icon>
        </span>
        <button (click)="close()" type="button" mat-icon-button tabindex="-1" aria-label="Close">
            <mat-icon class="notranslate">clear</mat-icon>
        </button>
        <div class="title">
            <h2>{{data.title}}</h2>
        </div>
    </div>

    <mat-dialog-content>
        <div class="body">
            <div class="capital-call-message">
                <h4 *ngFor="let subTitle of data.subTitles.split('#')">{{subTitle}}</h4>
                <br>
            </div>
            <form id="dialogForm" [formGroup]="pageForm" class="vertical-form terra-form-field-wrapper terra-error-style"
                (keydown.enter)="$event.preventDefault()">
              <!-- Due Date -->
              <div class="terra-form-field-wrapper due-date">
                <terra-custom-utc-date-picker
                id="txtDueDate"
                formControlName="dueDate"
                label="Due Date"
                [minDate]="today"
                [requiredError]="pageForm.get('dueDate').hasError('required')"
                [minError]="pageForm.get('dueDate').hasError('minDate')"
                minErrorTxt="Due date should be in the future"
                [touched]="pageForm.get('dueDate').touched"
                ></terra-custom-utc-date-picker>
              </div>

              <!-- Message -->
              <label for="txtMessage" class="text-input-label">Add a message (optional):</label>
              <quill-editor id="txtMessage" formControlName="message" format="html" class="lo_sensitive message-input" placeholder="" />

              <mat-divider />

              <!-- Advanced -->
              <mat-expansion-panel hideToggle class="advanced">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon>add</mat-icon>
                    Advanced
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <!-- Reference memo -->
                  <label class="text-input-label">Reference memo (optional):</label>
                  <div class="reference-sub-label">
                    The same reference memo will be sent to the <strong>all</strong> checked investors.
                    To send a separate memo to each investor please select investors one by one and issue a capital call separately.
                  </div>
                  <br>
                  <mat-radio-group class="reference-btn" aria-label="Select an option" formControlName="referenceBtn">
                    <mat-radio-button value="1">&lt;{{HoldingDiscriminator.toString(data.holding.discriminator)}} Name&gt; &lt;Investing Entity&gt; e.g. {{data.holding?.name || ''}} John Smith</mat-radio-button>
                    <mat-radio-button value="2">Custom</mat-radio-button>
                  </mat-radio-group>
                  <mat-form-field class="reference" appearance="outline">
                    <input matInput id="senderReference" formControlName="senderReference" class="lo_sensitive" placeholder="example: Payment #1" />
                    <mat-error *ngIf="pageForm.get('senderReference').hasError('maxlength')"> Reference cannot exceed 100 characters</mat-error>
                  </mat-form-field>
                </ng-template>
              </mat-expansion-panel>

              <!-- Error message -->
              <div *ngIf="isSubmitted">
                <p *ngIf="pageForm.invalid" class="error-message">
                  Please review and correct the fields marked in red
                </p>
              </div>
            </form>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="action">
        <button type="button" mat-button (click)="proceed()" class="proceed-btn">
            Issue Capital Call
        </button>
        <button type="button" mat-button class="cancel-btn" (click)="close()">
            Cancel
        </button>
    </mat-dialog-actions>
</div>
