import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  merge,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import memo from 'memo-decorator';

import { TerraUtils } from 'src/app/shared/TerraUtils';
import { GpBankAccountDataService } from 'src/app/services/gp/gp-bank-account-data.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import ReportPeriod from 'src/app/shared/enums/ReportPeriod.enum';
import { GpDistributionDataService } from 'src/app/services/gp/gp-distribution-data.service';
import { DistributionTransferReqRes } from '../DistributionTransferReqRes.model';
import { DistributionReqRes } from '../DistributionReqRes.model';
import DistributionStatus from 'src/app/shared/enums/DistributionStatus.enum';
import DistributionTransferType from 'src/app/shared/enums/DistributionTransferType.enum';
import { ClientDetailsResponse } from 'src/app/account/my-account/model/agentDetailsResponse.model';
import { CurrencyModel } from 'src/app/shared/models/CurrencyModel';
import { ResourceService } from 'src/app/services/resource.service';
import { KeyValuePair } from 'src/app/shared/types/KeyValuePair.model';
import { UserService } from 'src/app/services/shared/user.service';
import DistributionTransactionPurpose from 'src/app/shared/enums/DistributionTransactionPurpose.enum';
import { TerraCurrencyNoSymbolPipe } from 'src/app/shared/pipes/TerraCurrency.pipe';
import { SnackbarService } from 'src/app/services/snackbar.service';
import FundraisingType from 'src/app/shared/enums/FundraisingType.enum';
import { GpHoldingService } from 'src/app/dashboard/shared/holding/gp-holding.service';
import { DistributionImportDialogComponent } from './distribution-import-dialog/distribution-import-dialog.component';
import { LoggerService } from 'src/app/shared/errors/logger.service';
import { AlertDialogParams } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { TerraNumberPipe } from 'src/app/shared/pipes/TerraNumber.pipe';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';
import { DistributionService } from '../distribution.service';
import DistributionPeriod from '../../../../../shared/enums/DistributionPeriod.enum';
import { GpHolding } from '../../GpHolding.model';
import HoldingDiscriminator from '../../../../../shared/enums/HoldingDiscriminator.enum';
import { RoutingService } from '../../../../../services/routing.service';
import { UnitBankAccountReqRes } from '../../../../../shared/models/gp/UnitBankAccountReqRes.model';
import AccountType from '../../../../../shared/enums/AccountType.enum';
import { DistributionTransferDetailsReqRes } from '../DistributionTransferDetailsReqRes.model';
import DistributionTransactionOtherType from '../../../../../shared/enums/DistributionTransactionOtherType.enum';
import { BaseResponseDto } from '../../../../../shared/models/BaseResponseDto.model';
import { UtilsService } from '../../../../../services/utils.service';
import { PermissionService } from 'src/app/permission/permission.service';
import {
  ErrorMatcher,
  ErrorType,
} from '../../../../../shared/errors/ErrorMatcher';
import { MergeUnitClientBankAccountDto } from 'src/app/shared/models/MergeUnitClientBankAccountDto.model';
import MergeUnitClientBankAccountType from 'src/app/shared/enums/MergeUnitClientBankAccountType.enum';
import { PaymentType } from 'src/app/shared/enums/payment-type.enum';
import { ClientBankAccountReqRes } from 'src/app/dashboard/models/bankAccount.model';
import { WaterfallsReadDto } from '../WaterfallsReadDto.model';
import { LastDistributionReqRes } from '../LastDistributionReqRes.model';
import moment from 'moment';
import { GeneralSettingsService } from 'src/app/services/shared/general-settings.service';
import UnitAccountProvider from 'src/app/shared/enums/UnitAccountProvider.enum';
import {
  UnitBankAccountLimitDialogComponent,
  UnitBankAccountLimitDialogParams,
} from 'src/app/shared/components/unit/bank-account-limits/unit-bank-account-limit-dialog/unit-bank-account-limit-dialog.component';
import { UnitBankAccountListItem } from 'src/app/shared/models/gp/UnitBankAccountListItem.model';
import OwnershipCalculationType from 'src/app/shared/enums/OwnershipCalculationType.enum';

export enum DistributionCalculationTypeEnum {
  PRO_RATA = 0,
  WATERFALL = 1,
}

@Component({
  selector: 'terra-create-edit-distribution',
  templateUrl: './create-edit-distribution.component.html',
  styleUrls: ['./create-edit-distribution.component.scss'],
  providers: [DistributionService],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateEditDistributionComponent
  extends OnDestroyMixin
  implements OnInit {
    readonly MISSING_CURRENCY_TITLE_ERROR = 'Bank account currency is missing. Please choose a different bank account from the list, or create a new one.';
    readonly MISSING_BANK = 'Missing bank account';
    readonly INVESTOR_UPDATE_ACCOUNT_MSG = 'The Investor updated/added the bank details, please review.';
    readonly INVALID_FORM_MESSAGE = 'Please review and complete the missing information';
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  isAllContactsVisible$ = this.permissionService.isAllContactsVisible$;

  // enums
  ReportPeriod = ReportPeriod;
  CreAccountType = AccountType;
  UnitPaymentType = PaymentType;
  DistributionTransactionPurpose = DistributionTransactionPurpose;
  distributionTransactionPurposeAll = DistributionTransactionPurpose.listAll();
  distributionTransactionOtherType = DistributionTransactionOtherType;
  distributionTransactionOtherTypeAll =
    DistributionTransactionOtherType.listAll();
  DistributionStatus = DistributionStatus;

  distributionCalculationType = DistributionCalculationTypeEnum;
  selectedDistributionCalculationType =
    this.distributionCalculationType.PRO_RATA;

  discriminatorStr = this.gpHoldingService.discriminatorStr;
  discriminatorLowerCaseStr = this.gpHoldingService.discriminatorLowercaseStr;

  showedFirstRemoveInvestorConfirmation = false;

  currentUserId = 0;
  organizationDetailsId = 0;

  selectedCREBankAccount = null;
  selectedCREBankAccountId = null;

  refreshAmounts$ = new EventEmitter<void>();
  updateGpPromote$ = new Subject<void>();
  updateFormAmounts$ = new Subject<void>();
  waterfallsCalculated$ = new Subject<void>();
  updatePaymentDateFormControl$ = new Subject<void>();
  totalFeeLoading$ = new BehaviorSubject<boolean>(false);

  wireTransactionFee$ = this.generalSettingsService
    .GetByKey('WireTransactionFee')
    .pipe(shareReplay(1));

  private currentYear = new Date().getFullYear();

  // Boundaries of a report period
  periodMinDate = new Date(this.currentYear - 10, 0, 1);
  periodMaxDate = new Date(this.currentYear + 1, 11, 31);

  currencies$: Observable<CurrencyModel[]> = this.resourceService
    .getOutboundCurrencies()
    .pipe(
      map((response) => response.filter((c) => c.isInbound)),
      shareReplay(1)
    );

  pageForm: UntypedFormGroup;
  multiTypeTypesForm: UntypedFormGroup;
  isSubmitted = false;
  showInternalLoader = false;
  isGeneralServerError = false;
  customError: string = null;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;

  atLeastOneTransferError = false;
  gpBankAccountMissingCurrencyError = false;

  /// The date that the picker will open to
  estimatedClosingDateStartAt = new Date();
  estimatedClosingDateMinimumDate = new Date('1970-01-01T12:00');

  yearsList = TerraUtils.getYearsList(
    this.currentYear,
    6,
    new Date().getMonth() >= 8
  );

status:DistributionStatus;

  distributionDateEndPeriod:Date = null;
  holdingId$ = this.gpHoldingService.holdingId$;
  holding$ = this.gpHoldingService.holding$;
  missingOwnershipError$ = this.holding$.pipe(map(h => `Investor ${h.ownershipCalculationType === OwnershipCalculationType.ByCommitments ? 'commitment ' : 'contributions'} value missing`), shareReplay(1));
  distribution$ = this.distributionService.distribution$;
  lastDistribution$ = this.distributionService.lastDistribution$;
  isEditMode$ = this.distribution$.pipe(
    map((distribution) => distribution != null),
    shareReplay(1)
  );
  distributionContext$ = combineLatest([
    this.isEditMode$,
    this.distribution$,
    this.holding$,
  ]).pipe(
    switchMap(([isEditMode, distribution, holding]) => {
      return of({
        isEditMode,
        distribution,
        holding,
      });
    })
  );

  disableEditMode$: Observable<boolean> = this.distribution$.pipe(
    map(distribution => distribution?.status === DistributionStatus.Completed
      ||  distribution?.status === DistributionStatus.WaitingForFunds
    || distribution?.status === DistributionStatus.ProcessingTransfers
    || distribution?.status === DistributionStatus.PartialCompleted),
    shareReplay(1));

  fundraisings$ = this.gpHoldingService.fundraisings$;
  distributionTransfers: DistributionTransferReqRes[];
  gpDistributionTransferIndex$ = new BehaviorSubject<number>(null);
  investorBankAccounts: KeyValuePair<number, MergeUnitClientBankAccountDto[]>;
  investorClientBankAccounts: KeyValuePair<number, ClientBankAccountReqRes[]>;
  investorUnitBankAccounts: KeyValuePair<number, UnitBankAccountReqRes[]>;
  isWaterfallAllowed$ = this.distributionService.isWaterfallAllowed$;
  // bankAccounts: KeyValuePair<number, any[]>;
  unitBankAccounts: UnitBankAccountReqRes[];
  unitBankAccountsWithoutSourceBankAccount: UnitBankAccountReqRes[];
  unitBankAccountsWithoutGPTransferBankAccount: UnitBankAccountReqRes[];

  bankAccountsForGPDistributionTransfer: any[];

  bankAccountsForGPPromote: MergeUnitClientBankAccountDto[];
  clientBankAccountsForGPPromote: ClientBankAccountReqRes[];
  unitBankAccountsForGPPromote: UnitBankAccountReqRes[];

  distributingEntities: ClientDetailsResponse[];
  updateMultiTypeFormValidity$ = new Subject<void>();
  multiTypeTypesWithoutTransfers: DistributionTransactionPurpose[];

  hasDistributingEntities = true;
  allTransactionsAreInUsa = true;
  noAccessToBanksMessage = '';

  get isValidMultiTypeDistribution(): boolean {
    if (this.isMultiTypeDistribution) {
      const selectedReasonsForTransfer = this.multiTypeTypesForm.get('types')
        .value as DistributionTransactionPurpose[];
      const reasonsForTransferWithAmount =
        new Set<DistributionTransactionPurpose>();
      this.distributionTransfers.forEach((transfer) => {
        transfer.distributionTransfersDetails.forEach((transferDetails) => {
          if (transferDetails.amountGross > 0) {
            reasonsForTransferWithAmount.add(
              transferDetails.reasonForTransaction
            );
          }
        });
      });

      this.multiTypeTypesWithoutTransfers = selectedReasonsForTransfer.filter(
        (type) => !!type && !reasonsForTransferWithAmount.has(type)
      );
      return (
        selectedReasonsForTransfer.length === reasonsForTransferWithAmount.size
      );
    }

    return true;
  }

  get feeCurrency() {
    if (!this.pageForm.get('distributionSourceCurrency').value) {
      return '';
    }

    return this.pageForm.get('distributionSourceCurrency').value.iso;
  }

  get currencySymbol() {
    if (!this.pageForm.get('distributionSourceCurrency').value) {
      return '';
    }

    return this.pageForm.get('distributionSourceCurrency').value.symbol;
  }

  get distributionPeriod(): string {
    let distributionPeriod = '';
    if (
      this.pageForm.get('reportPeriod').value === ReportPeriod.Custom &&
      !!this.pageForm.get('periodStartDate').value &&
      !!this.pageForm.get('periodEndDate').value
    ) {
      const periodStartDate = new Date(
        this.pageForm.get('periodStartDate').value
      );
      const periodEndDate = new Date(this.pageForm.get('periodEndDate').value);
      periodStartDate.setHours(12);
      periodEndDate.setHours(12);
      distributionPeriod = `${periodStartDate.toISOString()} ${periodEndDate.toISOString()}`;
    } else {
      distributionPeriod = `${this.pageForm.get('quarter').value} ${this.pageForm.get('year').value
        }`;
    }

    return distributionPeriod;
  }

  // covercy fees
  totalAmount = 0;
  totalAmountAfterFees = 0;
  distributionTotalFee = 0;
  totalAmountViaCovercy = 0;
  totalAmountOutsideOfCovercy = 0;
  gpEffectiveMarkupPercentage = 0;
  totalAdjustmentsAmountViaCovercy = 0;
  totalAdjustmentsAmountOutsideOfCovercy = 0;
  withheldTaxes = 0;
  feeAmount = 0;
  detailsInvalid = false;

  get investorsDistributionTransfer(): DistributionTransferReqRes[] {
    if (this.distributionTransfers && this.distributionTransfers.length > 0) {
      const lpTransfers = this.distributionTransfers.filter(
        (dt) => !dt.isRemoved && dt.type !== DistributionTransferType.GP
      );
      lpTransfers.forEach((transfer) => {
        transfer.invalidForm =
          (transfer.adjustmentsComments &&
          transfer.adjustmentsComments.length >
          TerraUtils.consts.validators.SHORT_TEXT_LENGTH)
          || (this.status === DistributionStatus.Completed && transfer.externalPayment && !transfer.paymentSettlementDate);
      });
      this.distributionService.updateTotalTransferAmounts(
        this.distributionTransfers
      );
      this.reasonsForTransfer = this.distributionService.getReasonsForTransfer(
        this.distributionTransfers
      );
      return lpTransfers;
    }
    return null;
  }

  get gpDistributionTransfer(): DistributionTransferReqRes {
    if (this.distributionTransfers && this.distributionTransfers.length > 0) {
      const gpTransfer = this.distributionTransfers.find(
        (dt) => !dt.isRemoved && dt.type === DistributionTransferType.GP
      );
      if (!gpTransfer) {
        return null;
      }

      gpTransfer.invalidForm =
        gpTransfer.adjustmentsComments &&
        gpTransfer.adjustmentsComments.length >
        TerraUtils.consts.validators.SHORT_TEXT_LENGTH;
      this.distributionService.updateTotalTransferAmounts([gpTransfer]);

      return gpTransfer;
    }
  }

  get periodStartDate() {
    return this.pageForm.get('periodStartDate') as UntypedFormControl;
  }

  get periodEndDate() {
    return this.pageForm.get('periodEndDate') as UntypedFormControl;
  }

  get isDistributingAnyAmountViaCovercy() {
    return this.totalAmountViaCovercy > 0;
  }
  get isUnitBankAccount() {
    if (!this.selectedCREBankAccount) {
      return false;
    }
    return this.selectedCREBankAccount.isUnitBankAccount
  }

  get isFeeEstimated() {
    if (
      this.pageForm.get('distributionSourceCurrency') &&
      this.pageForm.get('distributionSourceCurrency').value !== null
    ) {
      return (
        this.pageForm.get('distributionSourceCurrency').value.iso !== 'USD'
      );
    }

    return false;
  }

  get isMultiTypeDistribution() {
    return (
      this.pageForm?.get('distributionTransactionPurpose')?.value ===
      DistributionTransactionPurpose.MultiType
    );
  }

  get defaultTab$(): Observable<number> {
    return this.activatedRoute.queryParams.pipe(
      map((params) => (!!params.waterfallAmount ? 1 : 0))
    );
  }

  onTabChanged(event) {
    this.selectedDistributionCalculationType = event.index;
  }

  reasonsForTransfer: DistributionTransactionPurpose[];

  readonly ACH_OUT_TRANSACTION_FEE = 0;
  readonly WIRE_OUT_TRANSACTION_FEE = 800;
  readonly USCountryId = this.resourceService.getCountryByCode(
    TerraUtils.consts.countryCode.US
  ).id;
  private USCurrency: CurrencyModel;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private dialogService: DialogService,
    private gpHoldingService: GpHoldingService,
    private titleService: Title,
    public gpDistributionDataService: GpDistributionDataService,
    public gpBankAccountDataService: GpBankAccountDataService,
    private resourceService: ResourceService,
    private userService: UserService,
    @Inject(LOCALE_ID) private _locale: string,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private logger: LoggerService,
    private numberPipe: TerraNumberPipe,
    private telemetryService: TelemetryService,
    private distributionService: DistributionService,
    private routingService: RoutingService,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private permissionService: PermissionService,
    private generalSettingsService: GeneralSettingsService
  ) {
    super();
  }

  ngOnInit() {
    this.distribution$.pipe(take(1)).subscribe((distribution) => {
      const title = distribution
        ? `Edit ${distribution.name} Distribution` +
        TerraUtils.consts.GpPageTitleSuffix
        : 'Create Distribution' + TerraUtils.consts.GpPageTitleSuffix;
      this.titleService.setTitle(title);
      this.selectedDistributionCalculationType =
        !!distribution?.waterfallMetaFileLinkId
          ? DistributionCalculationTypeEnum.WATERFALL
          : DistributionCalculationTypeEnum.PRO_RATA;
    });

    this.activatedRoute.queryParamMap.subscribe((r) => {
      this.selectedCREBankAccountId = +r.get('account');
    });

    this.generatePageForm();
    this.handleAmountUpdates();
  }

  onSelectionChanged(event) {
    this.setSelectedBankAccount(event.value);
  }

  importProRata() {
    this.holdingId$
      .pipe(
        untilComponentDestroyed(this),
        take(1),
        switchMap((holdingId) => {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            holdingId,
            proRata: this.pageForm.get('proRata').value,
            distributionSourceCurrencyIso: this.pageForm.get(
              'distributionSourceCurrency'
            ).value.iso,
            reasonsForTransfer: this.reasonsForTransfer,
          };
          this.telemetryClickedProRataImport();
          return this.dialog
            .open(DistributionImportDialogComponent, dialogConfig)
            .afterClosed();
        })
      )
      .subscribe((transfers: DistributionTransferReqRes[]) => {
        if (transfers) {
          this.updateTransferAmounts(transfers);
          this.showAlertDialog(
            'Import completed successfully',
            `<p>Please review amounts, selected bank accounts and whether payments shall be made via Covercy.</p>`
          );
        }
      });
  }

  currencyCompareFn(c1: CurrencyModel, c2: CurrencyModel) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  @memo()
  holdingPageUrl(holdingId: number) {
    return this.gpHoldingService.getHoldingPageWithTabRoute(
      holdingId,
      'distributions'
    );
  }

  holdingDownInfo() {
    this.gpHoldingService.holdingDownInfo();
  }

  submitValidation(): boolean {
    this.isSubmitted = true;
    this.isGeneralServerError = false;
    this.customError = null;
    this.detailsInvalid = this.investorsDistributionTransfer.some(
      (t) =>
        !t.externalPayment &&
        (t.amountGross > 0 || t.gpPromote > 0) &&
        (t.invalidForm ||  (!t.bankAccountDetailsApprovedByGp && !t.isBankAccountChangeUnsaved))
    );

    if (
      this.pageForm.invalid ||
      this.multiTypeTypesForm.invalid ||
      !this.isValidMultiTypeDistribution ||
      this.detailsInvalid
    ) {
      this.pageForm.updateValueAndValidity();
      this.updateMultiTypeFormValidity$.next();
      return false;
    }

    const validTransfers = this.validTransfers();
    if (!validTransfers) {
      return false;
    }

    return true;
  }

  save() {
    if (!this.submitValidation()) {
      return;
    }

    this.showInternalLoader = true;
    this.holdingId$
      .pipe(
        take(1),
        withLatestFrom(this.isEditMode$, this.distribution$, this.disableEditMode$),
        switchMap(([holdingId, isEditMode, existingDistribution, partialUpdate]) => {
          const distribution = this.generateSubmitModel(
            holdingId,
            existingDistribution,
            isEditMode
          );
          if (!isEditMode) {
            this.telemetryCreateDistribution();
            return this.gpDistributionDataService.create(
              holdingId,
              distribution
            );
          } else if (!partialUpdate) {
            return this.gpDistributionDataService.update(
              holdingId,
              distribution.id,
              distribution
            );
          }
          else {
            return this.gpDistributionDataService.partialUpdate(
              holdingId,
              distribution.id,
              distribution
            );
          }
        }),
        tap(() => {
          this.gpHoldingService.refreshDistribution();
          this.isEditMode$.pipe(take(1)).subscribe((isEditMode) => {
            this.snackbarService.showGeneralMessage(
              isEditMode ? 'Distribution updated' : 'Distribution created'
            );
          });
        })
      )
      .subscribe(
        (distribution) => {
          this.showInternalLoader = false;
          this.isGeneralServerError = false;
          this.customError = null;
          this.router.navigateByUrl(
            this.gpHoldingService.getDistributionPageRoute(
              distribution.holdingId,
              distribution.id
            )
          );
        },
        (error) => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            this.customError =
              error?.error?.data || error?.error?.exceptionMessage;
            this.isGeneralServerError = true;
          }
          this.showInternalLoader = false;
        }
      );
  }

  removeTransfer(distributionTransfer: DistributionTransferReqRes) {
    if (!distributionTransfer) {
      return;
    }

    const params = new ConfirmDialogParams(
      'Remove Transfer',
      `<p>Are you sure you want to exclude this investor from the distribution? In order to re-add the investor, cancel and create a new distribution.</p>
      <p>This confirmation message will not show again.</p>`
    );

    const confirmation$ = this.showedFirstRemoveInvestorConfirmation
      ? of(true)
      : this.dialogService.confirmDialog(params).afterClosed();
    confirmation$
      .pipe(
        filter((isConfirmed) => isConfirmed),
        switchMap(() => {
          this.showedFirstRemoveInvestorConfirmation = true;
          const indexToRemove =
            this.distributionTransfers.indexOf(distributionTransfer);
          if (indexToRemove >= 0) {
            this.distributionTransfers.splice(indexToRemove, 1);
            return of(true);
          }
          return of(false);
        })
      )
      .subscribe((isRemoved) => {
        const contactFullName = TerraUtils.getContactFullName(
          distributionTransfer.investingEntity.contact
        );
        if (isRemoved) {
          this.snackbarService.showGeneralMessage(`Removed ${contactFullName}`);
        } else {
          this.snackbarService.showGeneralMessage(
            `Error: Couldn't remove ${contactFullName}`
          );
        }
      });
  }

  // Fill the amounts for each transfer on the page, using a list of transfers.
  // If the update list doesn't contain a transfer on the page, set the amount to 0
  private updateTransferAmounts(
    updatedTransfers: DistributionTransferReqRes[]
  ) {
    try {
      const distributionExternalPayment =
        !this.pageForm.get('paymentsViaCovercy').value;
      for (const originalTransfer of this.distributionTransfers) {
        // Find the matching transfer with updated amount in the list:
        const updatedTransfer = updatedTransfers.find((t) =>
          originalTransfer.type === DistributionTransferType.GP
            ? t.type === DistributionTransferType.GP
            : t.investingEntityId === originalTransfer.investingEntityId
        );

        // Update list of transfers by index:
        const transferIndex =
          this.distributionTransfers.indexOf(originalTransfer);
        if (transferIndex !== -1) {
          this.distributionTransfers[transferIndex] = {
            ...originalTransfer,
            externalPayment:
              distributionExternalPayment ||
              (updatedTransfer && updatedTransfer.externalPayment),
          };
          updatedTransfer?.distributionTransfersDetails.forEach(
            (updatedTransferDetails) => {
              const transferDetailsIndex = this.distributionTransfers[
                transferIndex
              ].distributionTransfersDetails.findIndex(
                (originalTransferDetails) =>
                  originalTransferDetails.reasonForTransaction ===
                  updatedTransferDetails.reasonForTransaction
              );
              if (transferDetailsIndex !== -1) {
                this.distributionTransfers[
                  transferIndex
                ].distributionTransfersDetails[transferDetailsIndex] =
                  updatedTransferDetails;
              }
            }
          );
        }
      }
      this.refreshAmounts$.next();
      this.selectDefaultSingleBankAccountPerTransfer(
        this.distributionTransfers,
        this.investorBankAccounts
      );
    } catch (err) {
      this.logger.error(
        'Exception in createDistribution => updateTransferAmounts',
        err,
        updatedTransfers
      );
    }
  }

  showAlertDialog(title: string, message: string) {
    this.dialogService.alertDialog(new AlertDialogParams(title, message));
  }

  showCreBankSelector() {
    if (
      !this.unitBankAccountsWithoutGPTransferBankAccount.length ||
      !this.pageForm
    ) {
      this.allTransactionsAreInUsa = false;
      return false;
    }

    const bankAccounts = this.investorBankAccounts
      ? Object.values(this.investorBankAccounts).reduce(
        (r, k) => [...r, ...k],
        []
      )
      : [];

    const selectedDistributionTransferBankIds = this.distributionTransfers
      .filter(
        (dt) => dt.clientBankAccountId && dt.amountGross && !dt.externalPayment
      )
      .map((dt) => dt.clientBankAccountId);

    const grossAmountSum = this.distributionTransfers
      ? this.distributionTransfers.reduce(
        (sum, dTransfer) => sum + dTransfer.amountGross,
        0
      )
      : 0;

    this.allTransactionsAreInUsa = this.distributionTransfers
      .filter(
        (dt) =>
          dt.amountGross > 0 && !dt.externalPayment && !!dt.clientBankAccount
      )
      .every(
        (dt) =>
          dt.clientBankAccount?.country?.code ===
          TerraUtils.consts.countryCode.US
      );

    const someTransactionsAreInUsa =
      this.unitBankAccountsWithoutGPTransferBankAccount.length &&
      bankAccounts
        .filter((ba) => selectedDistributionTransferBankIds.includes(ba.id))
        .some((ba) => ba.country?.code === TerraUtils.consts.countryCode.US);

    if (
      this.unitBankAccountsWithoutGPTransferBankAccount.length &&
      this.allTransactionsAreInUsa
    ) {
      this.pageForm
        .get('creBankAccountId')
        .setValidators([Validators.required]);
      this.pageForm.get('distributingEntityId').clearValidators();
    } else {
      this.pageForm
        .get('distributingEntityId')
        .setValidators([Validators.required]);
      if (this.unitBankAccountsWithoutGPTransferBankAccount.length) {
        this.pageForm
          .get('creBankAccountId')
          .setValidators([Validators.required]);
      } else {
        this.pageForm.get('creBankAccountId').clearValidators();
      }
    }

    this.pageForm.get('creBankAccountId').updateValueAndValidity();
    this.pageForm.get('distributingEntityId').updateValueAndValidity();

    return (
      !grossAmountSum ||
      someTransactionsAreInUsa ||
      this.unitBankAccountsWithoutGPTransferBankAccount.length
    );
  }

  private generatePageForm() {
    this.showInternalLoader = true;
    forkJoin({
      currencies: this.currencies$.pipe(take(1)),
      fundraisings: this.fundraisings$.pipe(take(1)),
      distribution: this.distribution$.pipe(take(1)),
      lastDistribution: this.lastDistribution$.pipe(take(1)),
      user: this.userService.accountDetails$.pipe(take(1)),
      emptyTransfers: this.distributionService.transfers$.pipe(take(1)),
      isEditMode: this.isEditMode$.pipe(take(1)),
      distributionUsers: this.distributionService.distributionUsers$.pipe(
        take(1)
      ),
      bankAccountsPerInvestingEntityId:
        this.distributionService.bankAccountsPerInvestingEntityId$.pipe(
          take(1),
          catchError(async (err) => {
            if (
              ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)
            ) {
              this.noAccessToBanksMessage = err.responseMessage;
            }
            return new KeyValuePair<number, MergeUnitClientBankAccountDto[]>();
          })
        ),
      clientBankAccountsPerInvestingEntityId:
        this.distributionService.clientBankAccountsPerInvestingEntityId$.pipe(
          take(1),
          catchError(async (err) => {
            if (
              ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)
            ) {
              this.noAccessToBanksMessage = err.responseMessage;
            }
            return new KeyValuePair<number, ClientBankAccountReqRes[]>();
          })
        ),
      unitBankAccountsPerInvestingEntityId:
        this.distributionService.unitBankAccountsPerInvestingEntityId$.pipe(
          take(1),
          catchError(async (err) => {
            if (
              ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)
            ) {
              this.noAccessToBanksMessage = err.responseMessage;
            }
            return new KeyValuePair<number, UnitBankAccountReqRes[]>();
          })
        ),
      creBankAccounts: this.distributionService.distributionCreBanksList$.pipe(
        take(1),
        catchError((error) => of([]))
      ),
    })
      .pipe(
        tap(() => {
          this.isGeneralServerError = false;
          this.showInternalLoader = false;
        })
      )
      .subscribe(
        (data) => {
          this.status = data.distribution?.status;
          this.distributionDateEndPeriod = data.distribution?.periodEndTimeStamp;
          this.currentUserId = data.user.id;
          this.organizationDetailsId = data.user.organizationDetails.id;
          this.distributionTransfers = this.mergeDistributionTransfers(
            data.emptyTransfers,
            data.distribution?.distributionTransfers
          );
          this.gpDistributionTransferIndex$.next(
            this.distributionTransfers.findIndex(
              (dt) => dt.type === DistributionTransferType.GP
            )
          );
          this.distributingEntities = data.distributionUsers;
          this.investorBankAccounts = data.bankAccountsPerInvestingEntityId;
          this.investorClientBankAccounts =
            data.clientBankAccountsPerInvestingEntityId;
          this.investorUnitBankAccounts =
            data.unitBankAccountsPerInvestingEntityId;
          this.selectDefaultSingleBankAccountPerTransfer(
            this.distributionTransfers,
            this.investorBankAccounts
          );
          this.hasDistributingEntities = this.distributingEntities.length > 0;
          data.creBankAccounts.forEach((b) => (b.isUnitBankAccount = true));
          this.unitBankAccounts = data.creBankAccounts;
          this.unitBankAccountsWithoutSourceBankAccount = data.creBankAccounts;
          this.unitBankAccountsWithoutGPTransferBankAccount =
            data.creBankAccounts;

          this.bankAccountsForGPDistributionTransfer = [
            ...this.investorClientBankAccounts[0],
            ...this.unitBankAccountsWithoutSourceBankAccount,
          ];

          const unitForGPPromote: MergeUnitClientBankAccountDto[] = [];
          this.unitBankAccountsWithoutSourceBankAccount.forEach((ub) =>
            unitForGPPromote.push(
              TerraUtils.convertUnitBankAccountToMergeUnitClient(ub)
            )
          );
          this.bankAccountsForGPPromote = [
            ...unitForGPPromote,
            ...this.investorBankAccounts[0] || [],
          ];
          this.clientBankAccountsForGPPromote = [
            ...this.investorClientBankAccounts[0] || [],
          ];
          this.unitBankAccountsForGPPromote = [
            ...this.unitBankAccountsWithoutSourceBankAccount,
          ];

          this.USCurrency = data.currencies.find((c) => c.iso === 'USD');

          const initialFundraising = data.fundraisings.find(
            (f) => f.type === FundraisingType.InitialFundraising
          );
          const currency = this.unitBankAccountsWithoutGPTransferBankAccount
            ?.length
            ? this.USCurrency
            : data.currencies.find(
              (c) => c.id === initialFundraising.fundraisingTargetCurrency.id
            );
            let periodToInit = ReportPeriod.Quarterly;
            let periodStart: Date;
            let periodEnd: Date;
            let yearToInit = this.currentYear;
            let quarterToInit = 1;
          if(!data.isEditMode){
            if (
              data.lastDistribution?.distributionPeriod ===
              DistributionPeriod.Quarterly
            ) {
              yearToInit = data.lastDistribution?.quarter === 4 ? data.lastDistribution?.year + 1 : data.lastDistribution?.year;
              quarterToInit = data.lastDistribution?.quarter === 4 ? 1 : data.lastDistribution?.quarter + 1;
            } else if (
              data.lastDistribution?.distributionPeriod ===
              DistributionPeriod.Custom
            ) {
              periodToInit = ReportPeriod.Custom;
              const startMoment = moment(data.lastDistribution.periodEndTimeStamp)
                .add(1, 'day')
                .startOf('day');
              periodStart = startMoment.utc().toDate();
              periodEnd = startMoment
                .add(1, 'day')
                .endOf('month')
                .startOf('day')
                .utc()
                .toDate();
            }
          }
          else{
            const possibleQuaters = ['Q1','Q2','Q3','Q4'];
            var quarterName = data.distribution.name.substring(0, 2);
            if(!!possibleQuaters.find(x => x === quarterName.toUpperCase())){
              periodToInit =  ReportPeriod.Quarterly;
              [quarterToInit, yearToInit] = this.distributionService.getQuarterly(data.distribution.periodStartTimeStamp, data.distribution.periodEndTimeStamp)
            }
            else{
              periodToInit = ReportPeriod.Custom;
              periodStart = data.distribution.periodStartTimeStamp;
              periodEnd = data.distribution.periodEndTimeStamp;
            }
          }
          

          const pageForm = this.fb.group({
            reportPeriod: [periodToInit],
            year: [yearToInit, Validators.required],
            quarter: [quarterToInit, Validators.required],
            periodStartDate: [periodStart],
            periodEndDate: [periodEnd],
            distributingEntityId: [null, Validators.required],
            distributionSourceCurrency: [currency, Validators.required],
            distributionTransactionPurpose: [null, Validators.required],
            distributionTransactionPurposeOther: [],
            distributionTransactionPurposeOtherType: [],
            paymentsViaCovercy: [true],
            proRata: '',
            creBankAccountId: this.selectedCREBankAccountId
              ? this.unitBankAccountsWithoutGPTransferBankAccount.length
                ? [this.selectedCREBankAccountId, Validators.required]
                : []
              : this.unitBankAccountsWithoutGPTransferBankAccount.length
                ? [
                  this.unitBankAccountsWithoutGPTransferBankAccount[0].id,
                  Validators.required,
                ]
                : [],
            unitPaymentType: PaymentType.Ach,
            waterfallMetaFileLinkId: data.distribution?.waterfallMetaFileLinkId,
          });

          this.setSelectedBankAccount(this.selectedCREBankAccountId);

          this.multiTypeTypesForm = this.fb.group({
            types: this.fb.array([]),
          });

          this.setDefaultDistributingEntityId(pageForm);
          pageForm
            .get('distributionTransactionPurpose')
            .valueChanges.subscribe((val) => {
              if (val === DistributionTransactionPurpose.Other) {
                pageForm
                  .get('distributionTransactionPurposeOther')
                  .setValidators([
                    Validators.required,
                    Validators.maxLength(200),
                  ]);
                pageForm
                  .get('distributionTransactionPurposeOtherType')
                  .setValidators([Validators.required]);
              } else {
                pageForm
                  .get('distributionTransactionPurposeOther')
                  .setValidators([Validators.maxLength(200)]);
                pageForm
                  .get('distributionTransactionPurposeOtherType')
                  .clearValidators();
              }

              if (val !== DistributionTransactionPurpose.MultiType) {
                this.updateDistributionTransferDetailsReasonForTransfer(val);
                if (!!this.pageForm && !!data.distribution?.holdingId) {
                  this.calculateFees(data.distribution.holdingId);
                }
              }
              pageForm
                .get('distributionTransactionPurposeOther')
                .updateValueAndValidity();
              pageForm
                .get('distributionTransactionPurposeOtherType')
                .updateValueAndValidity();
            });

          pageForm.get('paymentsViaCovercy').valueChanges.subscribe((val) => {
            pageForm
              .get('distributingEntityId')
              .setValidators(val ? [Validators.required] : []);
            if (!val) {
              pageForm.get('creBankAccountId').clearValidators();
              pageForm.get('distributingEntityId').clearValidators();
            } else {
              if (
                this.unitBankAccountsWithoutGPTransferBankAccount.length &&
                this.showCreBankSelector()
              ) {
                pageForm
                  .get('creBankAccountId')
                  .setValidators([Validators.required]);
              }
              pageForm
                .get('distributingEntityId')
                .setValidators([Validators.required]);
            }
            pageForm.get('creBankAccountId').updateValueAndValidity();
            pageForm.get('distributingEntityId').updateValueAndValidity();
            this.refreshAmounts$.next();
          });

          if (data.distribution) {
            this.populateForm(
              data.distribution,
              pageForm,
              data.lastDistribution
            );
          }

          if (this.unitBankAccountsWithoutGPTransferBankAccount?.length) {
            pageForm.get('distributionSourceCurrency').disable();
          }

          this.pageForm = pageForm;
          this.subscribeToMultiTypeTypesChange();
          this.disableEditMode();
        },
        (error) => {
          this.isGeneralServerError = true;
          this.showInternalLoader = false;
          this.logger.error(
            'Error while creating or updating distribution.',
            error
          );
        }
      );
  }

  viaCovercyChanged(val){
    this.distributionTransfers.forEach(dt => dt.externalPayment = !val);
  }


  private disableEditMode() {
    this.disableEditMode$.pipe(
      take(1),
      filter(disableEditMode => disableEditMode))
      .subscribe(_ => {
        if(this.isMultiTypeDistribution){
          this.pageForm.get('distributionTransactionPurpose').disable();
        }
        else{
          this.distributionTransactionPurposeAll = DistributionTransactionPurpose.listAll()
          .filter(d => d !== DistributionTransactionPurpose.MultiType);
        }
        this.pageForm.get('distributionSourceCurrency').disable();
        this.pageForm.get('distributingEntityId').disable();
        this.pageForm.get('distributionTransactionPurposeOther').disable();
        this.pageForm.get('distributionTransactionPurposeOtherType').disable();
        this.pageForm.get('paymentsViaCovercy').disable();
        this.pageForm.get('proRata').disable();
        this.pageForm.get('creBankAccountId').disable();
        this.pageForm.get('unitPaymentType').disable();
        this.pageForm.get('waterfallMetaFileLinkId').disable();
        this.subscribePeriodChanges();
      });

  }

  private setSelectedBankAccount(bankAccountId: number) {
    if (!bankAccountId) {
      this.selectedCREBankAccount = this.unitBankAccounts?.[0];
      this.unitBankAccountsWithoutGPTransferBankAccount = this.unitBankAccounts;
      this.unitBankAccountsWithoutSourceBankAccount =
        this.unitBankAccounts?.filter(
          (x) => x.id !== this.selectedCREBankAccount?.id
        );
    } else {
      this.selectedCREBankAccount = this.unitBankAccounts?.find(
        (x) => x.id === bankAccountId
      );
      this.unitBankAccountsWithoutGPTransferBankAccount = this.unitBankAccounts;
      this.unitBankAccountsWithoutSourceBankAccount =
        this.unitBankAccounts?.filter((x) => x.id !== bankAccountId);
    }

    this.bankAccountsForGPDistributionTransfer = [
      ...this.investorClientBankAccounts[0] || [],
      ...this.unitBankAccountsWithoutSourceBankAccount,
    ];

    const unitForGPPromote: MergeUnitClientBankAccountDto[] = [];
    this.unitBankAccountsWithoutSourceBankAccount.forEach((ub) =>
      unitForGPPromote.push(
        TerraUtils.convertUnitBankAccountToMergeUnitClient(ub)
      )
    );
    this.bankAccountsForGPPromote = [
      ...unitForGPPromote,
      ...this.investorBankAccounts[0] || [],
    ];
    this.clientBankAccountsForGPPromote = [
      ...this.investorClientBankAccounts[0] || [],
    ];
    this.unitBankAccountsForGPPromote = [
      ...this.unitBankAccountsWithoutSourceBankAccount,
    ];
  }

  private populateForm(
    distributionData: DistributionReqRes,
    pageForm: UntypedFormGroup,
    lastDistribution: LastDistributionReqRes
  ) {
    const quarterName = distributionData.name.slice(0, 2);
    const period = ['Q1', 'Q2', 'Q3', 'Q4'].includes(quarterName)
      ? DistributionPeriod.Quarterly
      : DistributionPeriod.Custom;
    const quarter = ['Q1', 'Q2', 'Q3', 'Q4'].includes(quarterName)
      ? +quarterName.slice(1)
      : 1;
    const distYear =
      period === DistributionPeriod.Quarterly
        ? +distributionData.name.slice(3)
        : this.currentYear;
    pageForm.patchValue({
      distributionPeriod: period,
      year: distYear,
      quarter,
      periodStartDate: distributionData.periodStartTimeStamp,
      periodEndDate: distributionData.periodEndTimeStamp,
      distributionSourceCurrency: distributionData.distributionSourceCurrency,
      distributionTransactionPurpose: distributionData.reasonForTransaction,
      distributionTransactionPurposeOther:
        distributionData.reasonForTransactionText,
      distributionTransactionPurposeOtherType:
        distributionData.transactionOtherType,
      distributingEntityId: distributionData.distributingEntityId,
      paymentsViaCovercy: distributionData.distributionTransfers.some(
        (transfer) => !transfer.externalPayment
      ),
      creBankAccountId: distributionData.unitBankAccountId,
      unitPaymentType: PaymentType.Ach, // unitPaymentType: distributionData.unitPaymentType,
    });

    this.setSelectedBankAccount(distributionData.unitBankAccountId);
  }

  /** Merge a list of empty distribution transfers per investing entity with the pre-populated data. */
  private mergeDistributionTransfers(
    emptyTransfers: DistributionTransferReqRes[],
    populatedTransfers: DistributionTransferReqRes[]
  ): DistributionTransferReqRes[] {
    const merged: DistributionTransferReqRes[] = [];

    if (!populatedTransfers?.length) {
      return [...emptyTransfers.filter(t => t.type === DistributionTransferType.GP || t.investingEntity.ownershipInHolding > 0)];
    }

    populatedTransfers?.forEach((transfer) => {
      const transferToInvestingEntity = emptyTransfers?.find(
        (t) => t.investingEntityId === transfer.investingEntityId
      );
      if (transferToInvestingEntity != null) {
        transferToInvestingEntity.externalPayment = transfer.externalPayment;
        transferToInvestingEntity.paymentSettlementDate =
          transfer.paymentSettlementDate;

        transferToInvestingEntity.clientBankAccountId =
          transfer.clientBankAccountId;
        transferToInvestingEntity.clientBankAccount =
          transfer.clientBankAccount;

        transferToInvestingEntity.unitBankAccountId =
          transfer.unitBankAccountId;
        transferToInvestingEntity.unitBankAccount = transfer.unitBankAccount;

        transferToInvestingEntity.id = transfer.id;
        transferToInvestingEntity.distributionTransfersDetails =
          transfer.distributionTransfersDetails;
        transferToInvestingEntity.referenceMemo = transfer.referenceMemo;
        transferToInvestingEntity.bankAccountDetailsApprovedByGp =
          transfer.bankAccountDetailsApprovedByGp;
        transferToInvestingEntity.previuosClientBankAccountId =
          transfer.previuosClientBankAccountId;

        merged.push(transferToInvestingEntity);
      }
      else{
        merged.push({...transfer})
      }
    });

    return merged;
  }

  private handleAmountUpdates() {
    this.refreshAmounts$
      .pipe(
        untilComponentDestroyed(this),
        debounceTime(300),
        tap(() => {
          this.distributionService.updateGpPromoteAmounts(
            this.gpDistributionTransfer,
            this.investorsDistributionTransfer
          );
          this.updateGpPromote$.next();
        }),
        // only if a currency was selected
        filter(() => !!this.pageForm.get('distributionSourceCurrency').value),
        switchMap(() => this.holdingId$)
      )
      .subscribe((holdingId) => {
        this.calculateFees(holdingId);
      });
  }

  private calculateFees(holdingId: number) {
    this.totalFeeLoading$.next(true);
    const distribution = this.generateSubmitModel(holdingId);
    this.gpDistributionDataService
      .calculateFees(holdingId, distribution)
      .pipe(
        tap(_ => this.totalFeeLoading$.next(false))
      )
      .subscribe(
        (response) => {
          this.distributionTotalFee =
            response.feeAmount + response.markupAmount;
          this.totalAmount =
            response.totalAmountViaCovercy +
            response.totalAmountOutsideOfCovercy;
          this.totalAmountAfterFees = response.totalAmountAfterFees;
          this.totalAmountViaCovercy = response.totalAmountViaCovercy;
          this.totalAmountOutsideOfCovercy =
            response.totalAmountOutsideOfCovercy;
          this.gpEffectiveMarkupPercentage =
            response.gpEffectiveMarkupPercentage;
          this.feeAmount = response.feeAmount;
          this.totalAdjustmentsAmountViaCovercy = response.totalAdjustmentsAmountViaCovercy;
          this.totalAdjustmentsAmountOutsideOfCovercy = response.totalAdjustmentsAmountOutsideOfCovercy;
          this.withheldTaxes = response.withheldTaxes;
        },
        (error) => {
          this.isGeneralServerError = true;
        }
      );
  }

  private validTransfers() {
    // 1. There must be at least one transfer
    const someGrossFilled = this.distributionTransfers.some(
      (dt) => dt.amountGross > 0
    );
    this.atLeastOneTransferError = !someGrossFilled && this.totalAmount === 0;

    // 2. GP distribution selected bank account must have a preferred currency
    const gpTransfer = this.distributionTransfers.find(
      (t) => t.type === DistributionTransferType.GP
    );

    this.gpBankAccountMissingCurrencyError = !!gpTransfer &&
      !gpTransfer.externalPayment &&
      gpTransfer.clientBankAccount &&
      !gpTransfer.clientBankAccount.preferredCurrencyId;

    return (
      !this.atLeastOneTransferError && !this.gpBankAccountMissingCurrencyError
    );
  }

  private generateSubmitModel(
    holdingId: number,
    existingDistribution: DistributionReqRes = null,
    isEditMode: boolean = false
  ): DistributionReqRes {
    const formValues = this.pageForm.getRawValue();
    const distribution = new DistributionReqRes();
    distribution.id = existingDistribution ? existingDistribution.id : 0;
    distribution.holdingId = holdingId;
    distribution.status = DistributionStatus.New;
    // distribution.unitPaymentType = formValues.unitPaymentType;

    if (this.pageForm.get('reportPeriod').value === ReportPeriod.Custom) {
      distribution.periodStartTimeStamp = formValues.periodStartDate;
      distribution.periodEndTimeStamp = formValues.periodEndDate;
    } else {
      const dates = this.distributionService.getStartAndEndDates(
        formValues.year as number,
        formValues.quarter as number
      );
      distribution.periodStartTimeStamp = dates[0];
      distribution.periodEndTimeStamp = dates[1];
    }

    if (
      this.pageForm.get('distributionTransactionPurpose').value ===
      DistributionTransactionPurpose.Other
    ) {
      distribution.transactionOtherType =
        formValues.distributionTransactionPurposeOtherType;
    }

    distribution.distributingEntityId = formValues.distributingEntityId;
    distribution.distributionSourceCurrencyId =
      formValues.distributionSourceCurrency
        ? formValues.distributionSourceCurrency.id
        : null;
    distribution.waterfallMetaFileLinkId = formValues.waterfallMetaFileLinkId;
    distribution.distributionTransfers = [];
    const distributionExternalPayment =
      !this.pageForm.get('paymentsViaCovercy').value;

    if (
      !distributionExternalPayment &&
      this.distributionTransfers.some(
        (dt) => !dt.externalPayment && dt.amountGross > 0
      )
    ) {
      distribution.unitBankAccountId = formValues.creBankAccountId;
    }

    this.distributionTransfers.forEach((transfer) => {
      const distTransfer = new DistributionTransferReqRes(
        transfer.id,
        distributionExternalPayment || transfer.externalPayment,
        transfer.investingEntityId,
        null,
        transfer.type,
        transfer.clientBankAccountId,
        null,
        transfer.adjustmentsComments,
        transfer.paymentSettlementDate || null,
        transfer.distributionTransfersDetails,
        transfer.referenceMemo,
        transfer.unitBankAccountId,
        null,
        transfer.amountGross,
        transfer.amountNet,
        transfer.taxes,
        transfer.amountAfterTaxes,
        transfer.gpPromote,
        transfer.amountAfterGpPromote,
        transfer.adjustments
      );
      if (
        this.pageForm.get('distributionTransactionPurpose').value ===
        DistributionTransactionPurpose.Other
      ) {
        distTransfer.distributionTransfersDetails[0].reasonForTransactionText =
          this.pageForm.get('distributionTransactionPurposeOther').value;
        distTransfer.distributionTransfersDetails[0].transactionOtherType =
          this.pageForm.get('distributionTransactionPurposeOtherType').value;
      }

      distTransfer.bankAccountDetailsApprovedByGp = isEditMode
        ? transfer.bankAccountDetailsApprovedByGp ||
        transfer.isBankAccountChangeUnsaved
        : true;
      distTransfer.previuosClientBankAccountId =
        isEditMode && !distTransfer.bankAccountDetailsApprovedByGp
          ? transfer.previuosClientBankAccountId
          : null;

      distribution.distributionTransfers.push(distTransfer);
    });

    return distribution;
  }

  private telemetryCreateDistribution() {
    this.holdingId$.pipe(take(1)).subscribe((holdingId) => {
      this.telemetryService.create(
        {
          eventID: '103',
          eventTitle: 'DISTRIBUTION - CREATE (PHASE 1)',
          holdingID: holdingId,
          organizationID: this.organizationDetailsId,
        },
        AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights
      );
    });
  }

  private telemetryClickedProRataImport() {
    this.holding$.pipe(take(1)).subscribe((holdingId) => {
      this.telemetryService.create(
        {
          eventID: '102',
          eventTitle: 'DISTRIBUTION - CLICKED EXCEL',
          holdingID: holdingId,
          organizationID: this.organizationDetailsId,
        },
        AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights
      );
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (false) {
      const params = new ConfirmDialogParams(
        `Cancel ${this.discriminatorLowerCaseStr}?`,
        'Any information you added will be lost',
        `Cancel ${this.discriminatorStr}`,
        'Go back'
      );
      return this.dialogService.confirmDialog(params).afterClosed();
    }
    return true;
  }

  cancel(holding: GpHolding, distribution: DistributionReqRes | null) {
    if (!!distribution) {
      const url =
        holding.discriminator === HoldingDiscriminator.Asset
          ? this.routingService.assetDistribution(holding.id, distribution.id)
          : this.routingService.fundDistribution(holding.id, distribution.id);
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl(
        this.gpHoldingService.getHoldingPageWithTabRoute(
          holding.id,
          'distributions'
        )
      );
    }
  }

  getFeesTooltipText() {
    return `Distribution fees breakdown:
    ${this.gpEffectiveMarkupPercentage
      }% of the total amount transferred via Covercy, and a fixed fee of
      ${new TerraCurrencyNoSymbolPipe(this._locale).transform(
        this.feeAmount
      )} ${this.feeCurrency}`;
  }

  /** Get a list of the selectable bank account for a distribution transfer */
  getBankAccountsForTransfer(distributionTransfer: DistributionTransferReqRes) {
    if (!this.investorBankAccounts) {
      return [];
    }
    if (distributionTransfer.investingEntity.contact.isAgentContact) {
      return [
        ...this.investorBankAccounts[distributionTransfer.investingEntityId],
        ...this.investorBankAccounts[0] || [],
      ];
    } else {
      return this.investorBankAccounts[distributionTransfer.investingEntityId];
    }
  }

  /** Get a list of the selectable bank account for a distribution transfer */
  getClientBankAccountsForTransfer(
    distributionTransfer: DistributionTransferReqRes
  ) {
    if (!this.investorClientBankAccounts) {
      return [];
    }
    if (distributionTransfer.investingEntity.contact.isAgentContact) {
      return [
        ...this.investorClientBankAccounts[
        distributionTransfer.investingEntityId
        ],
        ...this.investorClientBankAccounts[0],
      ];
    } else {
      return this.investorClientBankAccounts[
        distributionTransfer.investingEntityId
      ];
    }
  }

  /** Get a list of the selectable bank account for a distribution transfer */
  getUnitBankAccountsForTransfer(
    distributionTransfer: DistributionTransferReqRes
  ) {
    if (!this.investorUnitBankAccounts) {
      return [];
    }
    return this.investorUnitBankAccounts[
      distributionTransfer.investingEntityId
    ];
  }

  private setDefaultDistributingEntityId(pageForm: UntypedFormGroup): void {
    if (pageForm !== undefined && this.distributingEntities !== undefined) {
      this.holding$.pipe(take(1)).subscribe((holding) => {
        if (this.distributingEntities.length > 1 && holding.lastDistributingEntityId !== null) {
          // Select the last distributing entity if it's in the list
          const distributingEntity = this.distributingEntities?.find((x) => x.id === holding.lastDistributingEntityId);
          if (distributingEntity) {
            pageForm.get('distributingEntityId').setValue(distributingEntity.id);
          }
        }
        else if (this.distributingEntities.length === 1) {
          pageForm.get('distributingEntityId').setValue(this.distributingEntities[0].id);
        }
      });
    }
  }

  /** For each distribution transfer that doesn't have a selected bank account,
   * and in case there is only a single bank account available, select it.
   * @param transfers All distribution transfers
   * @param bankAccounts The available bank accounts (value) per investingEntityId (key)
   */
  private selectDefaultSingleBankAccountPerTransfer(
    transfers: DistributionTransferReqRes[],
    bankAccounts: KeyValuePair<number, MergeUnitClientBankAccountDto[]>
  ) {
    transfers
      .filter((dt) => !dt.clientBankAccountId)
      .forEach((dt) => {
        const ieBankAccounts = bankAccounts[dt.investingEntityId];
        if (ieBankAccounts && ieBankAccounts.length === 1) {
          dt.clientBankAccountId =
            ieBankAccounts[0].accountType ===
              MergeUnitClientBankAccountType.ExternalAccount
              ? ieBankAccounts[0].id
              : null;
          dt.unitBankAccountId =
            ieBankAccounts[0].accountType ===
              MergeUnitClientBankAccountType.CovercyAccount
              ? ieBankAccounts[0].id
              : null;
        }
      });
  }

  private subscribeToMultiTypeTypesChange() {
    this.multiTypeTypesForm
      .get('types')
      .valueChanges.pipe(
        debounceTime(200),
        untilComponentDestroyed(this),
        tap((distributionPurposes) => {
          if (
            !!this.distributionTransfers &&
            !!distributionPurposes &&
            distributionPurposes.length > 1
          ) {
            distributionPurposes.forEach((transactionPurpose) => {
              const isPurposeExist = this.investorsDistributionTransfer.some(
                (transfer) =>
                  transfer.distributionTransfersDetails.some(
                    (transferDetails) =>
                      transferDetails.reasonForTransaction ===
                      transactionPurpose
                  )
              );
              if (!isPurposeExist && !!transactionPurpose) {
                this.addTransferDetailsToEachTransfer(transactionPurpose);
              }
            });

            const reasonsForTransferNotInForm = this.reasonsForTransfer.filter(
              (purpose) => !distributionPurposes.includes(purpose)
            );
            const extraReasonsToTransferExistingInForm =
              new Set<DistributionTransactionPurpose>();
            this.distributionTransfers.forEach((dt) => {
              dt.distributionTransfersDetails.forEach((dtd) => {
                if (!distributionPurposes.includes(dtd.reasonForTransaction)) {
                  extraReasonsToTransferExistingInForm.add(
                    dtd.reasonForTransaction
                  );
                }
              });
            });

            this.removeTransferDetailsFromEachTransferByTransferPurpose(
              reasonsForTransferNotInForm.concat([
                ...extraReasonsToTransferExistingInForm,
              ])
            );
          }
        }),
        switchMap(() => this.holdingId$)
      )
      .subscribe((holdingId) => {
        this.calculateFees(holdingId);
      });
  }

  /**
   * Adds a new DistributionTransferDetails to each distribution transfer
   * @param transferPurpose Purpose of the new DistributionTransferDetails
   */
  private addTransferDetailsToEachTransfer(
    transferPurpose: DistributionTransactionPurpose
  ) {
    this.distributionTransfers.forEach((transfer) => {
      const newTransferDetails = new DistributionTransferDetailsReqRes();
      newTransferDetails.reasonForTransaction = transferPurpose;
      transfer.distributionTransfersDetails.push(newTransferDetails);
    });
  }

  /**
   * Removes a DistributionTransferDetails
   * @param transferPurpose Purpose to remove
   */
  private removeTransferDetailsFromEachTransferByTransferPurpose(
    transferPurpose: DistributionTransactionPurpose[]
  ) {
    this.distributionTransfers.forEach((transfer) => {
      transfer.distributionTransfersDetails =
        transfer.distributionTransfersDetails.filter(
          (transferDetails) =>
            !transferPurpose.includes(transferDetails.reasonForTransaction)
        );
    });
  }

  public onProRataCalculation(
    distributionTransfers: DistributionTransferReqRes[]
  ) {
    distributionTransfers.forEach((transfer) => {
      const originalTransfer = this.distributionTransfers.find(
        (t) => t.investingEntityId === transfer.investingEntityId
      );
      if (!!originalTransfer) {
        originalTransfer.distributionTransfersDetails =
          transfer.distributionTransfersDetails;
      }
    });
    this.updateFormAmounts$.next();
    this.refreshAmounts$.next();
    this.selectDefaultSingleBankAccountPerTransfer(
      this.distributionTransfers,
      this.investorBankAccounts
    );
  }

  public onWaterfallsCalculation(waterfall: WaterfallsReadDto) {
    this.waterfallsCalculated$.next();
    const updatedTransfers = waterfall.distributionTransfers;
    const reasonsForTransactions =
      this.distributionService.getReasonsForTransfer(updatedTransfers);
    const transactionPurpose =
      reasonsForTransactions.length > 1
        ? this.DistributionTransactionPurpose.MultiType
        : reasonsForTransactions[0];
    this.distributionTransfers.forEach((originalTransfer) => {
      const indexOfOriginalTransfer =
        this.distributionTransfers.indexOf(originalTransfer);
      const updatedTransfer = updatedTransfers.find(
        (dt) =>
          (originalTransfer.type === DistributionTransferType.Investor &&
            originalTransfer.investingEntityId === dt.investingEntityId) ||
          (originalTransfer.type === DistributionTransferType.GP &&
            dt.type === DistributionTransferType.GP)
      );
      if (!!updatedTransfer) {
        if (indexOfOriginalTransfer !== -1) {
          this.distributionTransfers[
            indexOfOriginalTransfer
          ].distributionTransfersDetails =
            updatedTransfer.distributionTransfersDetails.sort(
              (a, b) =>
                reasonsForTransactions.indexOf(a.reasonForTransaction) -
                reasonsForTransactions.indexOf(b.reasonForTransaction)
            );
        }
      }
    });

    this.pageForm
      .get('distributionTransactionPurpose')
      .setValue(transactionPurpose);
    this.pageForm
      .get('waterfallMetaFileLinkId')
      .setValue(waterfall.excelMetaFileLink.id);
    this.updateFormAmounts$.next();
    this.refreshAmounts$.next();
    this.selectDefaultSingleBankAccountPerTransfer(
      this.distributionTransfers,
      this.investorBankAccounts
    );
  }

  /**
   * Updates the reason for transfer inside distribution transfer details
   * IMPORTANT: Use only when reason for transfer is not Multi Type
   * @param reasonForTransfer Selected distribution reason for transfer
   */
  private updateDistributionTransferDetailsReasonForTransfer(
    reasonForTransfer: DistributionTransactionPurpose
  ) {
    if (
      this.pageForm?.get('distributionTransactionPurpose')?.value !==
      DistributionTransactionPurpose.MultiType
    ) {
      this.distributionTransfers?.forEach((transfer) => {
        // changed distribution type from multi type to something else
        if (transfer.distributionTransfersDetails.length > 1) {
          let newSingleTransferDetails =
            transfer.distributionTransfersDetails.find(
              (dtd) => dtd.reasonForTransaction === reasonForTransfer
            );
          if (!newSingleTransferDetails) {
            newSingleTransferDetails = new DistributionTransferDetailsReqRes();
            newSingleTransferDetails.reasonForTransaction = reasonForTransfer;
          }

          transfer.distributionTransfersDetails = [newSingleTransferDetails];
        } else if (transfer.distributionTransfersDetails.length === 1) {
          transfer.distributionTransfersDetails[0].reasonForTransaction =
            reasonForTransfer;
        }
      });
    }
  }

  @memo()
  public getMissingTransferTypesString(
    missingTypes: DistributionTransactionPurpose[]
  ): string {
    let result = '';
    missingTypes.forEach((type) => {
      result = result.concat(
        ' ' + DistributionTransactionPurpose.toString(type) + ','
      );
    });

    return result.substring(1, result.length - 1);
  }

  calculateUnitFee(): Observable<number> {
    const unitBank =
      this.unitBankAccountsWithoutGPTransferBankAccount?.length &&
      this.unitBankAccountsWithoutGPTransferBankAccount[0];
    if (!unitBank) {
      return of(0);
    }
    const wireTransactions = this.getNumberOfWireTransaction();
    const nonWireTransactions = this.getNumberOfNonWireTransaction();

    return this.wireTransactionFee$.pipe(
      map((wireTransactionFee) => {
        let wireFee = unitBank.transactionFee / 100 + wireTransactionFee;
        let nonWireFee = unitBank.transactionFee / 100;
        return wireTransactions * wireFee + nonWireFee * nonWireTransactions;
      })
    );
  }

  getNumberOfWireTransaction(): number {
    const wireTransactions = this.distributionTransfers.filter(
      (dt) =>
        !dt.externalPayment &&
        dt.amountNet > 0 &&
        dt.clientBankAccount?.country?.code ===
        TerraUtils.consts.countryCode.US &&
        dt.clientBankAccount?.paymentType === PaymentType.Wire
    ).length;

    return wireTransactions;
  }

  getNumberOfNonWireTransaction() {
    const unitTransactions = this.distributionTransfers.filter(
      (dt) => dt.unitBankAccount
    ).length;

    const usTransactions = this.distributionTransfers.filter(
      (dt) =>
        !dt.externalPayment &&
        dt.amountNet > 0 &&
        dt.clientBankAccount?.country?.code ===
        TerraUtils.consts.countryCode.US &&
        dt.clientBankAccount?.paymentType === PaymentType.Ach
    ).length;

    const outOfUsTransactions = this.distributionTransfers.filter(
      (dt) =>
        dt.amountNet > 0 &&
        !dt.externalPayment &&
        dt.clientBankAccount?.countryId !== this.USCountryId
    ).length;

    return (
      usTransactions + (outOfUsTransactions > 1 ? 1 : 0) + unitTransactions
    );
  }

  openUnitAccountLimit() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    const data = new UnitBankAccountLimitDialogParams();
    data.accountLimits = this.selectedCREBankAccount.accountLimits;
    data.displayDailyDebit = false;
    data.displayMonthlyDebit = false;
    config.data = data;
    this.dialog.open(UnitBankAccountLimitDialogComponent, config);
  }

  subTextOption(account: UnitBankAccountListItem) {
    return `$${this.numberPipe.transform(account.balance / 100)} / ${AccountType.toString(account.accountType)} ${account.accountNumber.slice(-4).padStart(8, "*")}`;
  }

  subscribePeriodChanges(){
    const form = this.pageForm;
    
    const getEndDate = (year, quarter) => {
      const date = this.distributionService.getStartAndEndDates(
        year as number,
        quarter as number
      );
      return date[1];
    }

    form.get('year').valueChanges.pipe(
      untilComponentDestroyed(this)
    ).subscribe(year => this.updatePaymentDate(getEndDate(year, form.get('quarter').value)));

    form.get('quarter').valueChanges.pipe(
      untilComponentDestroyed(this)
    ).subscribe(quarter => this.updatePaymentDate(getEndDate(form.get('year').value, quarter)));

    form.get('periodEndDate').valueChanges.pipe(
      untilComponentDestroyed(this)
    ).subscribe(periodEndDate => this.updatePaymentDate(new Date(periodEndDate)));
    
    form.get('reportPeriod').valueChanges.pipe(
      untilComponentDestroyed(this)
    ).subscribe(reportPeriod => this.updatePaymentDate(reportPeriod === ReportPeriod.Custom ? 
      new Date(form.get('periodEndDate').value) 
      : getEndDate(form.get('year').value, form.get('quarter').value)));
  }

  updatePaymentDate(newDistributionDate:Date){
    const equalDate = (d1, d2) => TerraUtils.compareDateWithNull(d1, d2) === 0;

    const alertDialog = (investorsName) => {
      this.showAlertDialog(
        'Notice',
        `<p>You have distribution payments with a payment date different from the main distribution date. These will not be updated automatically. Please update them manually if needed.
        Investors: ${investorsName}</p>`
      );
    }

    const externalPayments = this.distributionTransfers.filter(dt => dt.externalPayment);

    const currentDistributionDateEndPeriod = new Date(this.distributionDateEndPeriod);

    const externalPaymentsToNotice = externalPayments
    .filter(dt => !equalDate(dt.paymentSettlementDate, currentDistributionDateEndPeriod) && !!dt.investingEntity?.name)
    .map(dt => dt.investingEntity.name);

    externalPayments
    .filter(dt => equalDate(dt.paymentSettlementDate, currentDistributionDateEndPeriod))
    .forEach(dt => dt.paymentSettlementDate = new Date(newDistributionDate));

    if(externalPaymentsToNotice.length > 0){
      alertDialog(externalPaymentsToNotice.join(', '));
    } 
    this.distributionDateEndPeriod = new Date(newDistributionDate);
    this.updatePaymentDateFormControl$.next();
  }

  updatedAccountByInvestor(distributionTransfer:DistributionTransferReqRes):boolean{
    return distributionTransfer 
    && !distributionTransfer.bankAccountDetailsApprovedByGp 
    && !distributionTransfer.isBankAccountChangeUnsaved
    && !distributionTransfer.externalPayment
    && (distributionTransfer.clientBankAccountId || distributionTransfer.unitBankAccountId)
    && distributionTransfer.amountNet > 0;
  }

  missingAccountCondition(distributionTransfer:DistributionTransferReqRes):boolean{
    return !distributionTransfer?.externalPayment
    && !distributionTransfer?.clientBankAccount
    && !distributionTransfer?.unitBankAccount
    && distributionTransfer.amountNet > 0;
  }

  missingAccountCurrency(distributionTransfer:DistributionTransferReqRes):boolean{
    return !distributionTransfer?.externalPayment
    && distributionTransfer?.clientBankAccount
    && !distributionTransfer?.clientBankAccount.preferredCurrencyId
    && distributionTransfer.amountNet > 0;
  }
}
