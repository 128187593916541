<ng-container *ngIf="useFund">
  <div class="page-header">
    <div class="title">
      Funds
      <span class="funds-number"
      >(<span *ngIf="funds$ | async as funds; else empty">{{
        funds.length
        }}</span
      ><ng-template #empty><span>0</span></ng-template
      >)</span
      >
    </div>
    <p-menu
      styleClass="new-fund-menu"
      #menu
      [model]="newFundMenuItems"
      [popup]="true"
    ></p-menu>
    <terra-buttons
      type="primary"
      label="New Fund"
      icon="add"
      [outlinedIcon]="true"
      (onClick)="menu.toggle($event)"
    ></terra-buttons>
  </div>

  <p-table
    #pTable
    [value]="funds$ | async"
    [showCurrentPageReport]="true"
    [alwaysShowPaginator]="false"
    [showFirstLastIcon]="false"
    [tableStyle]="{ 'min-width': '50rem' }"
    [styleClass]="'p-datatable-sm'"
    [rowHover]="true"
    [globalFilterFields]="['name', 'status']"
    selectionMode="single"
    (onRowSelect)="onRowSelect($event)"
    responsiveLayout="scroll"
    *ngIf="funds$ | async as assets; else loader"
    [scrollable]="true"
    scrollHeight="flex"
    (sortFunction)="tableSort($event)"
    [customSort]="true"
    [paginator]="true"
    [rows]="25"
    [totalRecords]="(funds$ | async)?.length"
    [rowsPerPageOptions]="[25, 50, 100]"
    currentPageReportTemplate="{first} - {last} of {totalRecords}"
    stateStorage="session"
    stateKey="covercy_funds"
    [filters]="initFilters"
  >
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
        <div class="flex">
          <terra-search-field
            placeholder="Search funds by name"
            (filterChange)="filterChanged($event, 'name', 'contains')"
            (clearSearch)="clearSearch('name')"
            [(filter)]="filteringText"
          ></terra-search-field>

          <p-multiSelect
            *ngIf="availableStatuses$ | async as availableStatuses"
            #pMultiSelect
            [showHeader]="false"
            [options]="availableStatuses"
            [(ngModel)]="selectedStatuses"
            [filter]="false"
            optionValue="value"
            optionLabel="label"
            [showClear]="false"
            (onChange)="statusFilterChanged($event.itemValue, $event.value)"
            class="px-2 c-multiselect"
            (onClear)="clearSearch('status')"
          >
            <ng-template let-value pTemplate="selectedItems">
              <div
                *ngIf="
                  availableStatuses?.length == value?.length ?? 0;
                  then allSelectedTemplate;
                  else elseTemplate
                "
              ></div>

              <ng-template #allSelectedTemplate>All Funds</ng-template>

              <ng-template #elseTemplate>
                <ng-template
                  [ngIf]="value?.length > 2"
                  [ngIfElse]="defaultTemplate"
                >{{ selectedStatuses.length }} Selected
                </ng-template>
                <ng-template #defaultTemplate>{{
                  selectedStatuses
                    | holdingStatusArrayToString : availableStatuses
                  }}</ng-template>
              </ng-template>
            </ng-template>
          </p-multiSelect>
        </div>

        <div class="flex">
          <span>
            <terra-buttons
              [requireContactsVisibility]="true"
              [minimumViewAsInvestorPermission]="ViewAsInvestorPermission.Allowed"
              [popupOnPermission]="true"
              type="secondary"
              label="View as Investor"
              icon="visibility"
              [outlinedIcon]="true"
              (onClick)="openViewAsInvestorDialog()"
            ></terra-buttons>
          </span>

          <div *ngIf="false" class="card flex justify-content-center">
            <p-menu
              #menu
              [model]="menuItems"
              [popup]="true"
              appendTo="body"
            ></p-menu>
            <p-button
              styleClass="p-button-text "
              type="button"
              (click)="menu.toggle($event)"
              icon="pi pi-ellipsis-v"
            ></p-button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" #headerTemplate class="">
      <tr class="text-xs">
        <th class="deals-image">Details</th>
        <th class="font-semibold text-left w-14rem max-w-14rem"></th>

        <th class="text-right w-10rem pr-0" pSortableColumn="investmentsAmount">
          Contributions
          <p-sortIcon class="" field="investmentsAmount"></p-sortIcon>
        </th>
        <th class="text-right w-10rem pr-0" pSortableColumn="distributionsAmount">
          Distributions
          <p-sortIcon class="" field="distributionsAmount"></p-sortIcon>
        </th>
        <th class="text-right w-8rem pr-0" pSortableColumn="cocPercents">
          CoC %
          <p-sortIcon class="" field="cocPercents"></p-sortIcon>
        </th>
        <th class="text-right w-13rem pr-0" pSortableColumn="lastDistribution">
          Last Distribution
          <p-sortIcon class="" field="lastDistribution"></p-sortIcon>
        </th>
        <th class="text-right w-12rem pr-0" pSortableColumn="balance">
          Balance
          <p-sortIcon class="" field="balance"></p-sortIcon>
        </th>

        <th class="text-center w-9rem flex flex-row align-items-center gap-2" *ngIf="allowEditHolding$ | async">
          <span>Investor Portal</span>
          <mat-icon class="investor-portal-info-icon" pTooltip="When the switch is on, investors can view the holding in their Investor Portal and recieve updates."
                    tooltipPosition="bottom">help_outline
          </mat-icon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-holding>
      <tr
        (mouseenter)="holding.hover = true"
        (mouseleave)="holding.hover = false"
        [class.hover]="holding.hover"
      >
        <td [routerLink]="holding.id" class="cursor-pointer deals-image">
          <img [src]="getFileUrl(holding)" [alt]="holding.name" class=""/>
        </td>
        <td
          [routerLink]="holding.id"
          class="cursor-pointer vertical-align-top text-left"
        >
          <div class="flex flex-column gap-3 font-semibold text-sm">
            {{ holding.name }}
            <p-tag
              [value]="holding.status | enumString : holdingStatusEnum"
              styleClass="status {{ getStatusClass(holding) }}"
            ></p-tag>
          </div>
        </td>

        <td
          [routerLink]="holding.id"
          class="cursor-pointer vertical-align-top text-right"
        >
          {{
          holding.initialCurrency?.symbol +
          (holding.investmentsAmount | shortScaleNumber) +
          "/" +
          (holding.fundraisingTargetAmount | shortScaleNumber)
          }}
        </td>
        <td
          [routerLink]="holding.id"
          class="cursor-pointer vertical-align-top text-right"
        >
          <span *ngIf="holding.distributionsAmount">
            {{ "$" + (holding.distributionsAmount | shortScaleNumber) }}
          </span>
        </td>
        <td
          [routerLink]="holding.id"
          class="cursor-pointer vertical-align-top text-right"
        >
          <span *ngIf="holding.cocPercents"> {{ holding.cocPercents }}% </span>
        </td>
        <td
          [routerLink]="holding.id"
          class="cursor-pointer vertical-align-top text-right"
        >
          {{ holding.lastDistribution | utcDate : "MMM dd yyyy" }}
        </td>
        <td
          [routerLink]="holding.hasUnitBank ? holding.id : null"
          class="cursor-pointer vertical-align-top text-right"
        >
          <span *ngIf="!holding.hasUnitBank && holding.status !== holdingStatusEnum.Draft" class="float-right">
            <terra-buttons
              type="small"
              label="Bank Account"
              icon="add"
              [outlinedIcon]="true"
              [disabledBtn]="!(enableAddBankButton$ | async) || holding.isExample"
              (onClick)="openAddBankAccountDialog(holding.id)"
            ></terra-buttons>
          </span>
          <span
            [ngClass]="{
              blurbalance: !isToShowBalances,
              'status-attention': isToShowBalances && holding.balance === null
            }"
            *ngIf="holding.hasUnitBank && !dealsBalancesLoadingError"
          >
            {{
              holding.balance !== null && holding.balance >= 0 ? "$" + (holding.balance | unitNumber) :
                (holding.balance === null ? 'No Active Accounts' : "Pending")
            }}
          </span>
          <terra-loader
            *ngIf="holding.hasUnitBank && !(dealsBalances$ | async)"
          ></terra-loader>
        </td>

        <td
          class="text-center vertical-align-top"
          *ngIf="allowEditHolding$ | async"
        >
          <terra-loader *ngIf="holding.isUpdating"></terra-loader>

          <p-inputSwitch *ngIf="!holding.isUpdating" styleClass="teal-100" inputId="investorPortal" label="investorPortal"
                         [(ngModel)]="holding.investorPortal" (onChange)="investorPortalChanged(holding)"
                         [disabled]="disableInvestorPortal(holding) || holding.isUpdating || !(enableInvestorPortalToggle$ | async)">
          </p-inputSwitch>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <ng-container *ngIf="(funds$ | async).length; else noHoldings">
        <tr [pSelectableRow]="false" class="c-empty-state">
          <td class="text-center" colspan="9">
            <img
              src="/assets/images/plg/no-results-con.svg"
              alt="no deals here yet"
            />
            <p class="text-center font-medium text-lg main-hint">
              No matching results
            </p>
            <p class="text-center secondary-hint">
              Please adjust your search or filter and try again.
            </p>
          </td>
        </tr>
      </ng-container>
      <ng-template #noHoldings>
        <tr [pSelectableRow]="false" class="c-empty-state">
          <td class="text-center" colspan="9">
            <img
              src="/assets/images/plg/empty-icon.svg"
              alt="no deals here yet"
            />
            <p class="text-center font-medium text-lg secondary-hint">
              No deals here yet
            </p>
          </td>
        </tr>
      </ng-template>
    </ng-template>
  </p-table>

  <ng-template #loader>
    <terra-loader></terra-loader>
  </ng-template>
</ng-container>

<ng-container *ngIf="!useFund">
  <terra-fund-premium></terra-fund-premium>
</ng-container>
