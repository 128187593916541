
<div class="container">
  <!-- Generating capital call message -->
  <ng-container *ngIf="isGeneratingPaymentRequests$ | async else notGenerating">
    <div class="info-message">
      Hang tight, your capital call may take a few minutes to process. It is safe to leave this page.
    </div>
  </ng-container>

  <!-- Capital call isn't being generated, showing capital call details -->
  <ng-template #notGenerating>
    <div class="terra-card">
      <terra-capital-call-track></terra-capital-call-track>
    </div>
  </ng-template>
</div>
