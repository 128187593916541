<terra-dialog-header>
  <h1>Send offering deck to the following contacts?</h1>
</terra-dialog-header>

<mat-dialog-content>
  <div class="dialog-body">
    <p class="message">
      This operation will send your offering deck to {{data.length}} contacts, are you sure?<br>
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="button" mat-flat-button color="primary" (click)="send()">Send</button>
  <button type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="false">Cancel</button>
</mat-dialog-actions>
