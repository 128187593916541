import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';

 
import { FundraisingReqRes } from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import { UserService } from 'src/app/services/shared/user.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { enterAnimation } from 'src/app/shared/animations';
import { AreaUnit } from 'src/app/shared/enums/AreaUnit.enum';
import { InterestType } from 'src/app/shared/enums/InterestType.enum';
import { InvestmentSecurityType } from 'src/app/shared/enums/InvestmentSecurityType.enum';
import { InvestmentStatus } from 'src/app/shared/enums/InvestmentStatus.enum';
import { InvestmentType } from 'src/app/shared/enums/InvestmentType.enum';
import { LoanType } from 'src/app/shared/enums/LoanType.enum';
import { LenderInformation } from 'src/app/shared/models/LenderInformation.model';
import { TerraNumberPipe } from 'src/app/shared/pipes/TerraNumber.pipe';
import { DialogWithFormBase } from 'src/app/shared/types/DialogWithFormBase';
import { GpAssetReqRes } from '../../GpAssetReqRes.model';
import { GpAssetDialogContext } from '../../gp-asset/AssetDetails.context';
import { AssetAndFundraisingReqRes } from '../create-asset/AssetAndFundraisingRequest';
import { CustomValidators } from 'src/app/shared/validators/custom.validators';

@Component({
  selector: 'terra-partial-edit-asset-dialog',
  templateUrl: './partial-edit-asset-dialog.component.html',
  styleUrls: ['./partial-edit-asset-dialog.component.scss'],
  animations: [enterAnimation]
})
export class PartialEditAssetDialogComponent extends DialogWithFormBase implements OnInit {
  pageForm: UntypedFormGroup;
  attemptSubmit = false;
  processingSubmit$ = new BehaviorSubject(false);

  selectedInvestmentType = this.context.asset.investmentType as InvestmentType;
  selectedAreaUnit = this.context.asset.areaUnits as AreaUnit;
  selectedCurrency = this.context.fundraisingDetails.fundraisingTargetCurrency;
  selectedSecurityType = this.context.fundraisingDetails.securityType;

  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;

  // Enums
  InvestmentType = InvestmentType;
  InvestmentSecurityType = InvestmentSecurityType;
  AreaUnit = AreaUnit;
  LoanType = LoanType;
  InterestType = InterestType;

  // Enum value lists
  LoanTypesList = LoanType.listAll();

  // Enum Interest Type
  InterestTypesList = InterestType.listAll();

  closingDateStartAt = new Date();
  closingDateMinimumDate = new Date('1970-01-01T12:00');

  maturityDateStartAt = new Date();
  maturityDateMinimumDate = new Date('1970-01-01T12:00');

  get totalLoans() {
    let totalCapitalization: number;
    let seniorLoan: number;
    let juniorLoan: number;
    try {
      totalCapitalization = this.context.asset.totalCapitalization;
      if (!totalCapitalization) {
        seniorLoan = this.context.asset.lenderInformation.amount || 0;
        juniorLoan = this.context.asset.lenderInformation.amountJunior || 0;
      }
    } catch {
      return null;
    }
    const result = totalCapitalization || (seniorLoan + juniorLoan);
    if (totalCapitalization !== result) {
      this.context.asset.totalCapitalization = result;
    }

    return result;
  }

  constructor(
    public dialogRef: MatDialogRef<PartialEditAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: GpAssetDialogContext,
    private fb: UntypedFormBuilder,
    private numberPipe: TerraNumberPipe,
    private telemetryService: TelemetryService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.generatePageForm(this.context);
  }

  generatePageForm(context: GpAssetDialogContext) {
    const assetDetails = context.asset;
    const fundraisingDetails = context.fundraisingDetails;

    this.pageForm = this.fb.group({
      finalClosingDate: [fundraisingDetails.estimatedClosingDate, [Validators.required]],
      totalCapitalization: [assetDetails.totalCapitalization, [Validators.required, CustomValidators.minFormattedNumber(0)]],
      totalInvestorEquity: [this.amountFunded(context.fundraisingDetails), [Validators.required, CustomValidators.minFormattedNumber(0)]],
      gpEquityFromTotalEquity: assetDetails.gpEquityFromTotalEquity,
      outstandingLoanAmountToDate: assetDetails.outstandingLoanAmountToDate,

      lenderInformation: this.fb.group({
        lenderName: assetDetails.lenderInformation.lenderName,
        amount: assetDetails.lenderInformation.amount,
        loanToValue: assetDetails.lenderInformation.loanToValue,
        interestRate: assetDetails.lenderInformation.interestRate,
        interestType: assetDetails.lenderInformation.interestType,
        loanType: assetDetails.lenderInformation.loanType,
        closingDate: assetDetails.lenderInformation.closingDate,
        maturityDate: assetDetails.lenderInformation.maturityDate,
        additionalTerms: assetDetails.lenderInformation.additionalTerms,
        totalFinancingPerGrossAreaUnit: assetDetails.lenderInformation.totalFinancingPerGrossAreaUnit,
        totalFinancingPerNetAreaUnit: assetDetails.lenderInformation.totalFinancingPerNetAreaUnit,

        amountJunior: assetDetails.lenderInformation.amountJunior,
        loanToValueJunior: assetDetails.lenderInformation.loanToValueJunior,
        interestRateJunior: assetDetails.lenderInformation.interestRateJunior,
        interestTypeJunior: assetDetails.lenderInformation.interestTypeJunior,
        loanTypeJunior: assetDetails.lenderInformation.loanTypeJunior,
        closingDateJunior: assetDetails.lenderInformation.closingDateJunior,
        maturityDateJunior: assetDetails.lenderInformation.maturityDateJunior,
        additionalTermsJunior: assetDetails.lenderInformation.additionalTermsJunior,
        totalFinancingPerGrossAreaUnitJunior: assetDetails.lenderInformation.totalFinancingPerGrossAreaUnitJunior,
        totalFinancingPerNetAreaUnitJunior: assetDetails.lenderInformation.totalFinancingPerNetAreaUnitJunior
      })
    });
  }

  generateSubmitModel(notifyInvestors: boolean): AssetAndFundraisingReqRes {
    const formValues = this.pageForm.value;

    const assetModel = new GpAssetReqRes();
    const fundraisingModel = new FundraisingReqRes();

    assetModel.id = this.context.asset.id;
    fundraisingModel.id = this.context.fundraisingDetails.id;

    // Asset details
    fundraisingModel.finalClosingDate = formValues.finalClosingDate;
    assetModel.totalCapitalization = this.numberPipe.parse(formValues.totalCapitalization);
    assetModel.totalInvestorEquity = this.numberPipe.parse(formValues.totalInvestorEquity);
    assetModel.gpEquityFromTotalEquity = this.numberPipe.parse(formValues.gpEquityFromTotalEquity);
    assetModel.outstandingLoanAmountToDate = this.numberPipe.parse(formValues.outstandingLoanAmountToDate);

    // Lender information (optional)
    assetModel.lenderInformation = new LenderInformation();
    assetModel.lenderInformation.lenderName = formValues.lenderInformation.lenderName;
    assetModel.lenderInformation.amount = this.numberPipe.parse(formValues.lenderInformation.amount);
    assetModel.lenderInformation.loanToValue = this.numberPipe.parse(formValues.lenderInformation.loanToValue);
    assetModel.lenderInformation.interestRate = this.numberPipe.parse(formValues.lenderInformation.interestRate);
    assetModel.lenderInformation.interestType = this.numberPipe.parse(formValues.lenderInformation.interestType);
    assetModel.lenderInformation.loanType = formValues.lenderInformation.loanType;
    assetModel.lenderInformation.closingDate = formValues.lenderInformation.closingDate;
    assetModel.lenderInformation.maturityDate = formValues.lenderInformation.maturityDate;
    assetModel.lenderInformation.additionalTerms = formValues.lenderInformation.additionalTerms;
    assetModel.lenderInformation.totalFinancingPerGrossAreaUnit = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerGrossAreaUnit);
    assetModel.lenderInformation.totalFinancingPerNetAreaUnit = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerNetAreaUnit);

    assetModel.lenderInformation.amountJunior = this.numberPipe.parse(formValues.lenderInformation.amountJunior);
    assetModel.lenderInformation.loanToValueJunior = this.numberPipe.parse(formValues.lenderInformation.loanToValueJunior);
    assetModel.lenderInformation.interestRateJunior = this.numberPipe.parse(formValues.lenderInformation.interestRateJunior);
    assetModel.lenderInformation.interestTypeJunior = this.numberPipe.parse(formValues.lenderInformation.interestTypeJunior);
    assetModel.lenderInformation.loanTypeJunior = formValues.lenderInformation.loanTypeJunior;
    assetModel.lenderInformation.closingDateJunior = formValues.lenderInformation.closingDateJunior;
    assetModel.lenderInformation.maturityDateJunior = formValues.lenderInformation.maturityDateJunior;
    assetModel.lenderInformation.additionalTermsJunior = formValues.lenderInformation.additionalTermsJunior;
    assetModel.lenderInformation.totalFinancingPerGrossAreaUnitJunior = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerGrossAreaUnitJunior);
    assetModel.lenderInformation.totalFinancingPerNetAreaUnitJunior = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerNetAreaUnitJunior);

    return new AssetAndFundraisingReqRes(assetModel, fundraisingModel, notifyInvestors);
  }

  save(notifyInvestors: boolean) {
    this.pageForm.markAllAsTouched();
    this.attemptSubmit = true;
    this.isGeneralServerError = false;
    if (!this.pageForm.valid) {
      return;
    }
    const model = this.generateSubmitModel(notifyInvestors);
    this.processingSubmit$.next(true);
    this.context.gpAssetService
      .moveAssetToUnderManagement(model)
      .pipe(
        finalize(() => this.processingSubmit$.next(false))
      )
      .subscribe(
        updatedAsset => {
          this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
            this.telemetryService.create({
              eventID: '300',
              eventTitle: 'ASSET MOVED TO UNDER MANAGEMENT',
              holdingID: updatedAsset.asset.id,
              organizationID: clientDetails.organizationDetails.id
            }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
          });
          this.dialogRef.close(updatedAsset);
        },
        error => {
          this.processingSubmit$.next(false);
          this.isGeneralServerError = true;
        }
      );
  }

  amountFunded(fundraisingDetails: FundraisingReqRes) {
    const invested = fundraisingDetails.investments.filter(x => x.status === InvestmentStatus.Invested);
    if (invested.length > 0) {
      return invested.map(x => x.finalAmount).reduce((sum, current) => sum + current);
    }
    return 0;
  }
}
