<ng-template #loader>
  <terra-loader class="page-loader"></terra-loader>
</ng-template>

<ng-container *ngIf="asset$ | async as asset; else loader">
  <!-- Asset is down -->
  <div class="holding-down-page-stripe" *ngIf="asset.isPrivate && asset.status !== HoldingStatus.Canceled">
    <mat-icon svgIcon="holding_down" class="notranslate holding-down-icon"></mat-icon>
    <ng-container [ngSwitch]="asset.status">
      <span *ngSwitchCase="HoldingStatus.FundAsset"> Asset is down, <a class="link" (click)="editFundAsset(3)">click
          here</a> when you’re
        ready to put it back up.
        <a class="light link" (click)="assetDownInfo()"> Learn more</a></span>
      <span *ngSwitchDefault> Asset is down, <a class="link" (click)="editAsset(5)">click here</a> when you’re ready to
        put it back up.
        <a class="light link" (click)="assetDownInfo()"> Learn more</a></span>
    </ng-container>
  </div>

  <!-- Breadcrumbs -->
  <div class="breadcrumbs container" *ngIf="asset.fund">
    <a [routerLink]="parentFundPageUrl(asset.fundId)"><span class="lo_sensitive">{{ asset.fund.name }}</span> >
      Portfolio >
    </a>
    <span class="current">{{ asset.name }}</span>
  </div>

  <!-- Asset page header -->
  <div class="asset-title-area container">
    <div class="title-area-row">
      <h1 class="lo_sensitive">{{ asset.name | truncate: 45 }}</h1>

      <span *ngIf="asset.status !== HoldingStatus.FundAsset" class="status">{{ asset.status | enumString: HoldingStatus }}</span>
      <span *ngIf="asset.status === HoldingStatus.FundAsset" class="status">{{ asset.fund.name }}</span>
      <!-- Update status button -->
      <a *ngIf="asset.status !== HoldingStatus.FundAsset" role="button" class="link update-status-link"
         (click)="updateStatus()">
        Update status
      </a>

      <div class="actions">
        <!-- Create initial fundraising -->
        <button [routerLink]="createInitialFundraisingUrl$ | async" *ngIf="asset.status === HoldingStatus.FundAsset"
                mat-flat-button color="primary">
          Create Fundraising
        </button>

        <!-- Edit asset (different for FundAsset status and all the rest ) -->

        <button *ngIf="asset.status === HoldingStatus.FundAsset" (click)="editFundAsset(1)"
                [disabled]="asset.status === HoldingStatus.Canceled" mat-stroked-button class="edit-button" color="primary">
          <mat-icon class="notranslate">edit</mat-icon>
          Edit Asset
        </button>

        <button *ngIf="asset.status == HoldingStatus.Fundraising || asset.status == HoldingStatus.Owned"
                (click)="editAsset()" [disabled]="asset.status === HoldingStatus.Canceled" mat-stroked-button
                class="edit-button" color="primary">
          <mat-icon class="notranslate">edit</mat-icon>
          Edit Asset
        </button>

        <a *ngIf="asset.status == HoldingStatus.Draft" [routerLink]="editDraftAssetUrl$ | async"
           [disabled]="asset.status === HoldingStatus.Canceled" mat-stroked-button class="edit-button" color="primary">
          <mat-icon class="notranslate">edit</mat-icon>
          Edit Asset
        </a>

        <!-- More actions menu -->
        <button *ngIf="asset.status === HoldingStatus.Draft || asset.isExample" mat-icon-button color="primary"
                [matMenuTriggerFor]="moreActions" class="more-actions-toggle">
          <mat-icon class="notranslate">more_vert</mat-icon>
        </button>
        <mat-menu #moreActions="matMenu">
          <button mat-menu-item *ngIf="asset.status === HoldingStatus.Draft" (click)="deleteDraft()"
                  class="delete-button">
            <mat-icon class="notranslate">delete_forever</mat-icon>
            Delete Asset
          </button>

          <button mat-menu-item *ngIf="asset.isExample" (click)="moveToArchive()"
                  class="delete-button">
            <mat-icon class="notranslate material-symbols-rounded">inventory_2</mat-icon>
            Archive Asset
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="title-area-row" *ngIf="showHoldingInfoLine(asset) | async">
      <ul class="asset-info-wrapper">
        <li class="asset-info-item">
          <div class="asset-info-item-inner">
            <!-- <mat-icon class="notranslate">home</mat-icon> -->
            <span class="asset-info-item-title">Property Type: <span class="asset-info-item-value">{{
                asset.holdingType
                  | enumString: HoldingType
              }}</span></span>
          </div>
        </li>

        <ng-container *ngIf="(fundraising$ | async) as fundraising">
          <li class="asset-info-item">
            <div class="asset-info-item-inner">
              <!-- <mat-icon class="notranslate">attach_money</mat-icon> -->
              <span class="asset-info-item-title">Bank Account:
                <span *ngIf="(showBankInfo$ | async) && getHoldingBankAccount(fundraising)" class="asset-info-item-value">
                  {{ getHoldingBankAccount(fundraising) | bankAccountDisplayName:true }}
                </span>
                <span class="asset-info-item-value">{{ !(showBankInfo$ | async) ? '*****' : (!getHoldingBankAccount(fundraising) ? 'Missing' : '') }}</span>
                <!-- <a *ngIf="!getHoldingBankAccount(fundraising)" (click)="editAsset(4)" class="asset-info-item-value">
                  Missing
                </a> -->
              </span>
            </div>
          </li>

          <li class="asset-info-item">
            <div class="asset-info-item-inner">
              <!-- <mat-icon class="notranslate">date_range</mat-icon> -->
              <span class="asset-info-item-title">Closing Date: <span class="asset-info-item-value">{{
                  (fundraising.finalClosingDate ? fundraising.finalClosingDate : fundraising.estimatedClosingDate) | utcDate
                }}</span></span>
            </div>
          </li>
        </ng-container>
      </ul>

      <div class="addition-information-wrapper">
        <button mat-button class="account-info-button additional-info"
                [matMenuTriggerFor]="assetAdditionalInfoMenu" aria-label="Show asset additional info">
          Additional information
          <!-- <mat-icon class="notranslate">info</mat-icon> -->
        </button>
        <mat-menu class="cre-account-info-panel" #assetAdditionalInfoMenu="matMenu" xPosition="after" yPosition="below">
          <div (click)="$event.stopPropagation()">
            <!-- <div class="info-header">
              <span class="info-account-name lo_sensitive">{{ asset.holdingLocation }}</span>
              <span class="remark-note">Choice Financial Group</span>
              <div class="info-account-type">
                <mat-icon class="notranslate" [svgIcon]="CreAccountType.getIcon(account.accountType)"></mat-icon>
                {{ account.accountType | enumString : CreAccountType}}
              </div>
            </div> -->
            <div class="info-content">
              <ul class="info-list">
                <li *ngIf="asset?.investmentType"><label>Investment Type: </label>{{ InvestmentType.toString(asset.investmentType) }}
                  <!-- <button mat-icon-button matSuffix [cdkCopyToClipboard]="account.routingNumber" (click)="snackbarMessage('Routing number', $event)">
                    <mat-icon class="notranslate">content_copy</mat-icon>
                  </button> -->
                </li>
                <li *ngIf="fundraising$ | async as fundraising">
                  <label>{{ fundraising.securityType === InvestmentSecurityType.Debt ? 'Total loan: ' : 'Total capitalization: ' }}</label>
                  {{ fundraising.fundraisingTargetCurrency.symbol }}{{ asset.totalCapitalization | terraCurrency }}
                  <!-- <button mat-icon-button matSuffix [cdkCopyToClipboard]="account.accountNumber" (click)="snackbarMessage('Account number', $event)">
                    <mat-icon class="notranslate">content_copy</mat-icon>
                  </button> -->
                </li>
                <!--              <li><label>Owner: </label>XXX Placeholder</li>-->
                <!-- <li><label>Asset/Fund: </label>{{asset.id}} </li> -->
                <li><label>Organization: </label>{{ (organizationDetails$ | async)?.name }}</li>
              </ul>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>

  <!-- Different pages per asset status -->
  <ng-container [ngSwitch]="asset.status">
    <terra-asset-draft-status *ngSwitchCase="HoldingStatus.Draft" />
    <terra-asset-fundraising-status *ngSwitchCase="HoldingStatus.Fundraising" />
    <terra-asset-management-status *ngSwitchCase="HoldingStatus.Owned" />
    <terra-asset-management-status *ngSwitchCase="HoldingStatus.Realized" />
    <terra-fund-asset-status *ngSwitchCase="HoldingStatus.FundAsset" />
  </ng-container>
</ng-container>
