import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  InjectionToken,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { combineLatest, take, tap } from "rxjs";
import { IntegrationStatus } from "src/app/account/my-account/model/OrganizationIntegrationRes.model";
import { GpFundReqRes } from "src/app/dashboard/funds/GpFundReqRes.model";
import { IntegrationRequestDialogComponent } from "src/app/dashboard/shared/integration-request-dialog/integration-request-dialog.component";
import { SidePanelService } from "src/app/dashboard/shared/side-panel/side-panel.service";
import { MediaType, MetaFileLink } from "src/app/models/metaFileLink.model";
import { DialogService } from "src/app/services/dialog.service";
import { UserService } from "src/app/services/shared/user.service";
import { TerraUtils } from "src/app/shared/TerraUtils";
import { ConfirmDialogParams } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { FileExtension } from "src/app/shared/components/file-upload/file-upload.component";
import { FundInvestmentStrategyType } from "src/app/shared/enums/FundInvestmentStrategyType.enum";
import ReportFileType from "src/app/shared/enums/ReportFileType.enum";
import { ReportPeriod } from "src/app/shared/enums/ReportPeriod.enum";
import { RentManagerUploaderComponent } from "src/app/shared/rm-uploader/rm-uploader.component";
import { AnalyticsServiceNameModel, TelemetryService } from "telemetry-library";
import { GpFundService } from "../../../../gp-fund.service";
import { GpFundReportService } from "../gp-fund-report.service";

export const WINDOW = new InjectionToken<Window>('Global window object', {
  factory: () => window
});

@Component({
  selector: "terra-fund-report-information",
  templateUrl: "./fund-report-information.component.html",
  styleUrls: ["./fund-report-information.component.scss"],
})
export class FundReportInformationComponent implements OnInit, AfterViewInit {
  fund$ = this.gpFundService.holding$;
  MetaFileLink = MetaFileLink;
  MediaType = MediaType;
  
  // Enums
  FundInvestmentStrategyType = FundInvestmentStrategyType;
  ReportPeriod = ReportPeriod;

  private currentYear = new Date().getFullYear();

  pageForm: UntypedFormGroup = this.gpFundReportService.formStep1;
  isSubmitted = false;

  get periodStartDate() {
    return this.pageForm.get("periodStartDate") as UntypedFormControl;
  }

  get periodEndDate() {
    return this.pageForm.get("periodEndDate") as UntypedFormControl;
  }

  get filesStatusTitle(): string  {
    const files = this.pageForm.get("fileattachments")?.value as MetaFileLink[] || [];
    if(!files.length) return '';
    const uploadedFiles = files.filter((file) => file && !file.id);
    return `${files.length - uploadedFiles.length}/${files.length} files`;
  }

  @ViewChild("reportDocument")
  reportDocumentElementRef: ElementRef;

  reportUploaderSupportedExtensions = [
    ".pdf",
    ".ppt",
    ".pptx",
  ] as FileExtension[];
  attachmentUploaderSupportedExtensions = [
    ".pdf",
    ".jpg",
    ".jpeg",
    ".png",
    ".mp3",
    ".mp4",
    ".avi",
    ".ppt",
    "pptx",
    ".doc",
    ".docx",
    "xls",
    ".xlsx",
  ] as FileExtension[];
  isReportDocumentFileNotSupported =
    this.gpFundReportService.isReportDocumentFileNotSupported;

  // Boundaries of a report period
  periodMinDate = new Date(this.currentYear - 10, 0, 1);
  periodMaxDate = new Date(this.currentYear + 1, 11, 31);

  yearsList = TerraUtils.getYearsList(
    this.currentYear,
    6,
    new Date().getMonth() >= 8
  );

  visibleAttachmentRows = 1;
  rentManagerIntegrationStatus = signal(0)
  rentManagerIntegrationDisabled = signal(false);

  IntegrationStatus = IntegrationStatus
  
  constructor(
    private userService: UserService,
    private matDialog: MatDialog,
    private gpFundReportService: GpFundReportService,
    private gpFundService: GpFundService,
    private dialogService: DialogService,
    private sidePanelService: SidePanelService,
    private telemetryService: TelemetryService,
    private destroyRef: DestroyRef,
    @Inject(WINDOW) private window: Window,
  ) {}

  ngOnInit() {
    this.subscribeToReportPeriodTypeChanged();
    // show all rows of populated media file uploaders
    this.visibleAttachmentRows = Math.min(
      Math.ceil(
        this.pageForm.value.attachments.filter(
          (a: MetaFileLink) => a && a.id > 0
        ).length / 4
      ),
      5
    );

    this.userService.accountDetails$.pipe(take(1)).subscribe(clientDetails => {
      const status = clientDetails?.organizationDetails.integrations?.find(integration => integration.name === 'RentManager')?.status || 0;
      this.rentManagerIntegrationStatus.set(status);
    })

    // SET fileattachments value from Existing files
    this.pageForm.get('fileattachments').setValue((this.pageForm.get('attachments')?.value || []).filter((file: MetaFileLink) => file && file.id), { emitEvent: false });

    this.pageForm.get('fileattachments').valueChanges.subscribe((files: MetaFileLink[]) => {
      // Update attachments form array
      const attachments = this.pageForm.get('attachments') as FormArray;
      attachments.clear();
      files.forEach((file) => {
        attachments.push(new UntypedFormControl(file));
      });
    })
  }

  ngAfterViewInit() {
    if (this.isReportDocumentFileNotSupported) {
      this.reportDocumentElementRef.nativeElement.scrollIntoView();
    }
  }

  subscribeToReportPeriodTypeChanged() {
    this.pageForm.get("reportPeriod").valueChanges.subscribe((reportPeriod) => {
      const validators =
        reportPeriod === ReportPeriod.Custom ? Validators.required : null;
      this.periodStartDate.setValidators(validators);
      this.periodStartDate.updateValueAndValidity();
      this.periodEndDate.setValidators(validators);
      this.periodEndDate.updateValueAndValidity();
    });
  }

  cancel(fund: GpFundReqRes) {
    this.dialogService
      .confirmDialog(
        new ConfirmDialogParams(
          "Cancel report",
          "Any change you made will be lost. Are you sure?"
        )
      )
      .afterClosed()
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.gpFundReportService.navigateToFundPage(fund.id);
        }
      });
  }

  stepSubmit() {
    this.isSubmitted = true;
    this.gpFundReportService.isReportDocumentFileNotSupported = false;
    if (this.pageForm.valid) {
      this.gpFundReportService.isGeneralServerError = false;
      this.gpFundReportService.currentStep$.next(2);
    }
  }

  attachments(): FormArray {
    return this.pageForm.get("attachments") as FormArray
  }

  setFiles(files: MetaFileLink[] = []) {
      const existingFiles = this.pageForm?.get('fileattachments').value || [];
      this.pageForm?.get('fileattachments').setValue([...existingFiles, ...files]);

      const uploaded_files = (this.pageForm?.get('attachments')?.value || []).filter((file: MetaFileLink) => file && file.id > 0);
      const total_files = uploaded_files.length + files.length;

      for (let i = 0; i < files.length; i++) {
          files[i].fileType = ReportFileType.ReportMediaRentManager; 
          this.attachments().at(uploaded_files.length + i)?.setValue(files[i]);
      }

      this.pageForm.updateValueAndValidity();

      this.visibleAttachmentRows = Math.min( Math.ceil(total_files / 4), 5); // update visible rows // Deprecated
      
  }

  showIntegrationRequestModal() {
    this.matDialog.open(IntegrationRequestDialogComponent, {
      data: {
        title: 'Integrate with Rent Manager',
        description: `To generate Rent Manager reports, please get in touch with your Covercy's Account Manager.`,
        hint: `I'd like to integrate my Rent Manager account with Covercy`,
      }
    }).afterClosed().subscribe();

    combineLatest([this.userService.accountDetails$, this.fund$])
      .pipe(take(1)).subscribe(([clientDetails, fund]) => {
      this.telemetryService.create({
        eventID: '406',
        eventTitle: 'REPORT IMPORT RM OPENED GET IN TOUCH',
        holdingID: fund.id,
        organizationID: clientDetails.organizationDetails.id,
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });

  }

  showIntegrationIncompleteModal() {
    this.matDialog.open(IntegrationRequestDialogComponent, {
      data: {
        title: 'Connection Issue',
        description: `There’s a problem with the Rent Manager Reports integration. Please contact your Covercy Account Manager for assistance.`,
        hint: `Issue with Rent Manager Reports Integration.`,
      }
    }).afterClosed().subscribe();
  }

  openNav() {
    if(this.rentManagerIntegrationDisabled()) {
      this.showIntegrationIncompleteModal();
      return;
    }
    
    const dateRange = this.CalculateSelectedReportPeriod();
    this.sidePanelService.open(RentManagerUploaderComponent, { dateRange })
    .pipe(
      tap(({filesReady, closed, error}: {filesReady: MetaFileLink[], closed: boolean, error}) => {
          if (closed) { 
            this.sidePanelService.close(); 
          }

          if (error) { 
            this.sidePanelService.close();
            this.showIntegrationIncompleteModal();
            this.rentManagerIntegrationDisabled.set(true);
          } 

          if (filesReady) {
            this.setFiles(filesReady);
            this.sidePanelService.close();
          }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    combineLatest([this.userService.accountDetails$, this.fund$])
      .pipe(take(1)).subscribe(([clientDetails, fund]) => {
      this.telemetryService.create({
        eventID: '405',
        eventTitle: 'REPORT IMPORT RM OPENED',
        holdingID: fund.id,
        organizationID: clientDetails.organizationDetails.id,
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });
  }
  

  openPreview(file: MetaFileLink) {
    this.window.open(file.url, '_blank');
  }

  private CalculateSelectedReportPeriod(): {start: Date, end: Date} | undefined {
    if(this.pageForm.get('reportPeriod').value === ReportPeriod.Custom){
      return { start: this.periodStartDate.value, end: this.periodEndDate.value};
    }

    if(this.pageForm.get('reportPeriod').value === ReportPeriod.Quarterly){
      const quarter = this.pageForm.get('quarter').value;
      const year = this.pageForm.get('year').value;
      const quarterStart = new Date(year, (quarter - 1) * 3, 1);
      const quarterEnd = new Date(year, quarter * 3, 0);
      return {start: quarterStart, end: quarterEnd};
    }
  }
}
