
<div class="sidenav h-full rm-theme">
    <div class="sidenav-content " >
        <div class="sidenav-content-header">
            <img src="assets/images/logo/rent_manager_logo_full.png" width="160px" class="company_logo" alt="Rent Manager logo"/>
            <button mat-icon-button class="close-button p-1" type="button" (click)="close()" disableRipple>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="sidenav-content-body">  
        @if (dataLoaded) {
            <div class="form" @dataLoaded>
                <form [formGroup]="form">
                    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form" />
                </form>
            </div>
        } @else {
            <rm-loader #animatedElements (animationComplete)="onAnimationComplete()" />
        }
        </div>
        <div class="sidenav-content-footer">
            <button mat-raised-button type="button" color="primary" class="generateReportButton my-comp" (click)="generateReport()" [disabled]="form.invalid || !dataLoaded">
                @if (reporstRequested()) {
                    <span class="flex flex-row gap-2 ">
                        <mat-spinner diameter="20" />
                        <span>Generating Report</span>
                    </span> 
                }@else {
                    Generate & Import Report
                }
            </button>
        </div>
    </div>
</div>
