import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { StorageObjectDataService } from 'src/app/services/shared/storage-object-data.service';
import { HoldingStorageObjectReqRes } from 'src/app/shared/models/StorageObjectReqRes.model';

export class CreateFolderValidator {
  static createValidator(
    storageObjectDataService: StorageObjectDataService,
    data
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if(!control.value){
        return of(null);
      }

      if (!data.holdingId) {
        return of({ invalidAsync: true });
      }

      return of(control.value).pipe(
        delay(500),
        switchMap((value) => {
          const model = new HoldingStorageObjectReqRes();
          model.holdingId = data.holdingId;
          model.displayName = value;
          model.storageObjectParentId = data.currentFolderId;
          return storageObjectDataService.validateFolderName(model).pipe(
            map((res) => (res ? null : { invalidAsync: true })),
            catchError((err) => of({ error: err }))
          )}
        )
      );
    };
  }
}
