export class InvestorOverviewTableItem {
  public isGP = false;
  public name = '';
  public ieName = '';
  public totalContributions = 0;
  public totalCommitments = 0;
  public totalPendingContributions = 0;
  public totalDistributions = '';
  public cashOnCashRatePercentage = 0;
  public xirrPercentage = 0;
  public adjustedXirrPercentage = 0;
  public type = 0;
  public ownership = 0;
  public uncalled = 0;
}
