import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

export class MarkAsDeclinedConfirmDialogParams extends ConfirmDialogParams {
  constructor() {
    super(
      `Did the client decline to invest?`,
      `Once you mark as declined, you cannot go back to the current status.<br/>If this client changes their mind later, you can add them to the asset as an investor again.`,
      `Mark as Declined`
    );
  }
}
