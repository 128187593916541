<h3 class="title">Next: Have your client sign the investment agreement</h3>

<p class="holding-down-stripe" *ngIf="isHoldingPrivate">Agreements cannot be sent when the asset mode is down.
  <a class="light link" (click)="holdingDownInfo()">Learn more</a>
</p>

<!-- When the asset is in Private mode - the user can't do things that will send a sign agreement to the LPs -->
<ng-container *ngIf="investment && !isHoldingPrivate">
  <!-- Before agreement was sent (at least once) -->
  <ng-container *ngIf="!investment.agreementSentDate">
    <!-- If the user doesn't have the feature to use existing templates open, or there are still no templates to use -->
    <ng-container *ngIf="useDrafts && !useTemplates">
      <button class="upload-new-agreement-button" (click)="showFileBrowser()" mat-flat-button color="primary">
        <mat-icon class="add-icon notranslate">add</mat-icon>
        Upload a new agreement
      </button>
      <!-- This file selector is hidden and is triggered using the above button -->
      <terra-files-selector #filesSelectorWhenEmpty class="hidden-file-selector" (filesSelected)="showAgreementSelectorDialog($event)">
      </terra-files-selector>
      <p class="file-uploader-hint">File format must be JPG, JPEG, PNG (20 MB max file size) or PDF (50 MB max file size)</p>
    </ng-container>

    <!-- Select an existing template -->
    <div *ngIf="useTemplates && templates && templates.length >= 0" class="existing-templates-section" [ngClass]="{ 'use-templates-blocked': !useTemplates }">

      <div class="templates-list-wrapper" *ngIf="showRecentlyUsed">
        <p>
          Select an agreement template
        </p>
        <ul class="template-container">
          <li class="template-item" *ngFor="let template of templates | slice:0:4;" (click)="selectExistingTemplate(template.key)">
            <mat-icon color="primary" class="file-icon notranslate">insert_drive_file</mat-icon>
            <span class="template-name">{{ template.value }}</span>
          </li>
          <li>
            <a href="javascript:void(0)" class="light link cancel-agreement" (click)="removeAgreement()"
               *ngIf="investment.agreementSentDate">Cancel agreement</a>
          </li>
        </ul>

      </div>
      <a *ngIf="templates && templates.length > 4" class="full-list-link light link" (click)="moreTemplates()">
        <mat-icon class="notranslate" *ngIf="!useTemplates">lock</mat-icon>
        View full list
      </a>
    </div>


    <div class="example-block example-strip" *ngIf="!useTemplates">
      <span class="title">
        Select an agreement template
      </span>
      <p>
        Templates include signatures placeholders and <br/>
        any other components you previously added
      </p>
      <p class="recently-used">
        Recently used templates:
      </p>
      <ul class="template-container">
        <li class="template-item">
          <mat-icon color="primary" class="file-icon notranslate">insert_drive_file</mat-icon>
          <span class="template-name dummy-data">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</span>
        </li>
        <li class="template-item">
          <mat-icon color="primary" class="file-icon notranslate">insert_drive_file</mat-icon>
          <span class="template-name dummy-data">xxxxxxxxxxxxxxxxxxxxx</span>
        </li>
        <li class="template-item">
          <mat-icon color="primary" class="file-icon notranslate">insert_drive_file</mat-icon>
          <span class="template-name dummy-data">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</span>
        </li>
        <li class="template-item">
          <mat-icon color="primary" class="file-icon notranslate">insert_drive_file</mat-icon>
          <span class="template-name dummy-data">xxxxxxxxxxxxxxxxxxxxxxxx</span>
        </li>
      </ul>
    </div>

    <p class="upsell-text" *ngIf="!useTemplates">
      <mat-icon class="notranslate">lock</mat-icon>
      <span>Interested in our premium packages? Please
        <a target="_blank" href="https://www.covercy.com/contact-us" class="contact-us-link link"> contact us</a>
      </span>
    </p>

    <p *ngIf="useTemplates && templates && templates.length > 0">
      Or
    </p>

    <!-- Files selector - when the user has the feature flag for existing templates -->
    <ng-container *ngIf="useTemplates">
      <terra-files-selector #filesSelector class="file-selector-button" (filesSelected)="showAgreementSelectorDialog($event)">
        <mat-icon class="add-icon notranslate">add</mat-icon>
        Upload a new agreement
      </terra-files-selector>
      <p class="file-uploader-hint">File format must be JPG, JPEG, PNG (20 MB max file size) or PDF (50 MB max file size)</p>
    </ng-container>

    <terra-loader *ngIf="processingHelloSignRequest"></terra-loader>
  </ng-container>
  <!-- After agreement was sent -->
  <ng-container *ngIf="investment.agreementSentDate">
    <!-- Agreement is still un-signed  -->
    <div class="info-message" *ngIf="!investment.isAgreementSigned">
      Agreement sent on {{ investment.agreementSentDate | date: dateFormat }}<br/>
      <span *ngIf="investment.agreementSentEmail" class="lo_sensitive">To {{investment.agreementSentEmail}}<br/></span>
      Waiting for client signature
    </div>
    <div class="agreement-details">
      <mat-icon color="primary" class="notranslate">insert_drive_file</mat-icon>
      <span class="agreement-name"> {{ investment.agreementDraftTitle }} </span>
      <a href="javascript:void(0)" class="light link cancel-agreement" (click)="removeAgreement()"
         *ngIf="investment.agreementSentDate">Cancel agreement</a>
    </div>

    <!-- Allow user to re-send the agreement -->
    <button [disabled]="isLoading || isHoldingPrivate || !investment.agreemenHelloSigntSignatureRequestId" (click)="resendAgreement()" mat-flat-button
            color="primary" class="send-agreement-button tall-button">
      Resend Agreement
    </button>
    <span class="sent-success" *ngIf="agreementReSent">
      Sent Successfully
    </span>
  </ng-container>
</ng-container>

<span class="agreement-signed-text" (click)="markAsSigned()">
  Was the agreement signed outside Covercy? <a class="light link"> Mark agreement as signed</a>
</span>

<terra-loader [centerToParent]="true" *ngIf="isLoading"></terra-loader>
