import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  standalone: true,
  providers: [
    provideMomentDateAdapter({
      parse: {
        dateInput: 'LL',
      },
      display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    }),
  ],
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormlyModule
  ],
  styles: [`
    :host {
      display: block;
      width: 100%;
      
      --mat-form-field-container-height: 40px;
      --mat-form-field-container-vertical-padding: 8px;
      
      ::ng-deep .mat-mdc-icon-button.mat-mdc-button-base {
          padding: 8px;
          --mdc-icon-button-state-layer-size: 40px;
        }

      ::ng-deep .mat-mdc-icon-button .mat-mdc-button-touch-target {
        height: 40px;
        width: 40px;
      }
       
    }
  `],
  template: `
  <div class="full-width flex flex-column">
    <label class="mb-1">{{ props.label }}</label>
      <div class="flex flex-row gap-1">
        <mat-form-field appearance="outline" color="primary" class="w-full"> 
          <input matInput
          [min]="props.minDate"
          [max]="props.maxDate"
          [matDatepicker]="picker" 
          [formControl]="formControl"
          [formlyAttributes]="field"
          [placeholder]="props.placeholder"
          [errorStateMatcher]="errorStateMatcher">
          <mat-datepicker-toggle matIconSuffix [for]="picker" />
          <mat-datepicker #picker  color="primary" panelClass="rm-theme" />
        </mat-form-field>
      </div>
  </div>
  `,
})
export class DateSelectTypeComponent extends FieldType<FieldTypeConfig> {}
