import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {take} from 'rxjs/operators';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import {TerraUtils} from 'src/app/shared/TerraUtils';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {DialogService} from 'src/app/services/dialog.service';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {GpAssetDialogContext} from '../../gp-asset/AssetDetails.context';
import {EditAssetService} from './edit-asset.service';
import {EditAssetTabNumber} from './EditAssetStepBaseAndInterface';
import {enterAnimation} from 'src/app/shared/animations';
import {DialogWithFormBase} from 'src/app/shared/types/DialogWithFormBase';
import {RoutingService} from 'src/app/services/routing.service';
import {UserService} from 'src/app/services/shared/user.service';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import OwnershipCalculationType from 'src/app/shared/enums/OwnershipCalculationType.enum';

@Component({
  selector: 'terra-edit-asset-dialog',
  templateUrl: './edit-asset-dialog.component.html',
  styleUrls: ['./edit-asset-dialog.component.scss'],
  providers: [EditAssetService],
  animations: [enterAnimation]
})
export class EditAssetDialogComponent extends DialogWithFormBase implements OnInit, AfterViewInit {

  initialSelectedTab = this.context.initialTabNumber - 1;

  // enum
  AssetStatus = HoldingStatus;

  get pageForm(): UntypedFormGroup {
    if (!this.editAssetService.assetForm) {
      return null;
    }
    return this.editAssetService.assetForm as UntypedFormGroup;
  }

  get isStatusFundRaising() {
    return this.context.asset.status === HoldingStatus.Fundraising;
  }

  get attemptSubmit() {
    return this.editAssetService.attemptSubmit;
  }

  processingSubmit$ = this.editAssetService.processingSubmit$;

  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;


  get isDeleteAssetAllowed(): boolean {
    if (!this.context.asset) {
      return false;
    }

    if (![HoldingStatus.Fundraising, HoldingStatus.Draft].includes(this.context.asset.status)) {
      return false;
    }

    if (this.context.fundraisingDetails.investments.find(i => i.isAgreementSigned || i.paymentRequestSendDate !== null)) {
      return false;
    }
    return true;
  }

  constructor(
    public dialogRef: MatDialogRef<EditAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: GpAssetDialogContext,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private router: Router,
    private editAssetService: EditAssetService,
    private routingService: RoutingService,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private utilsService: UtilsService
  ) {
    super();
    editAssetService.editAssetDialogContext = context;
    editAssetService.dialogRef = dialogRef;
    editAssetService.generateForm();
  }

  ngOnInit() {
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
      if(!FeatureFlags.isOn(clientDetails.featureFlags, FeatureFlags.InvestNow)) {
        const isVisibleControl = this.editAssetService.getTabForm(3).get('isVisible');
        isVisibleControl?.setValue(false);
        isVisibleControl?.disable();
      }

      this.telemetryService.create({
        eventID: '21',
        eventTitle: 'EDIT ASSET (INIT)',
        holdingID: this.context.asset.id,
        organizationID: clientDetails.organizationDetails.id
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });
  }

  ngAfterViewInit(): void {
    if (!!this.context.scrollToId) {
      const htmlElement = document.getElementById(this.context.scrollToId);
      if (!!htmlElement) {
        htmlElement.scrollIntoView({behavior: 'smooth'});
      }
    }
  }

  isShowInvalidTab(tabNumber: EditAssetTabNumber) {
    const stepForm = this.editAssetService.getTabForm(tabNumber);
    if (!stepForm) {
      return null;
    }
    // The form will be in "PENDING" status while async validation is in progress.
    // In that time both valid and invalid will be false, and we don't want to display an error.
    return !stepForm.valid && stepForm.touched && stepForm.status !== 'PENDING';
  }

  saveChanges() {
    const prevCalculationType = this.context.asset.ownershipCalculationType;
    this.isGeneralServerError = false;
    this.editAssetService.saveChanges().subscribe(
      updatedAssetAndFundraising => {
        if (updatedAssetAndFundraising) {
          this.dialogRef.close(updatedAssetAndFundraising);
          this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
            this.telemetryService.create({
              eventID: '24',
              eventTitle: 'EDIT ASSET (COMPLETED)',
              holdingID: this.context.asset.id,
              organizationID: clientDetails.organizationDetails.id
            }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
            if (updatedAssetAndFundraising.asset.ownershipCalculationType !== prevCalculationType) {
              this.telemetryService.create({
                eventID: '701',
                eventTitle: 'HOLDING-OWNERSHIP-CALCULATION-CHANGED',
                holdingID: this.context.asset.id,
                organizationID: clientDetails.organizationDetails.id,
                holdingType: 'Asset',
                newCalculationType: updatedAssetAndFundraising.asset.ownershipCalculationType == OwnershipCalculationType.ByCommitments ? 'Commitments' : 'Contribution'
              }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
            }
          });
        }
      },
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        } else {
          this.isGeneralServerError = true;
        }
      });
  }

  deleteAsset() {
    const confirmDialogParams = new ConfirmDialogParams();
    confirmDialogParams.actionLabel = 'Delete';
    confirmDialogParams.title = 'Are you sure you want to delete this asset?';
    confirmDialogParams.description = `This asset's information and all its related data will be deleted immediately. You cannot undo this action.`;
    this.dialogService
      .confirmDialog(confirmDialogParams)
      .afterClosed()
      .pipe(untilComponentDestroyed(this))
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.context.gpAssetService
            .deleteAsset()
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              response => {
                this.router.navigateByUrl(this.routingService.gpAssetsPage);
                this.dialogRef.close(true);
                this.snackbarService.showGeneralMessage(`Asset deleted`);
              },
              error => {
                this.snackbarService.showGeneralMessage(`Couldn't delete the asset`);
              }
            );
        }
      });
  }
}
