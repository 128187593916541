import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import { HostBinding, Directive } from '@angular/core';
import { EditFundAssetService } from './edit-fund-asset.service';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import InvestmentType from 'src/app/shared/enums/InvestmentType.enum';

export type EditFundAssetTabNumber = 1 | 2 | 3;

export interface IEditFundAssetStep {
  readonly TAB_NUMBER: EditFundAssetTabNumber;
}

@Directive()
export abstract class EditFundAssetStepBase implements IEditFundAssetStep {

  @HostBinding('attr.class') hostClasses = 'wizard-step';

  abstract TAB_NUMBER: EditFundAssetTabNumber;

  isSubmitAttempt = false;

  get isInitialFundRaising() {
    return this.dialogContext.asset.status === HoldingStatus.Fundraising;
  }

  get isUnderManagement() {
    return this.dialogContext.asset.status === HoldingStatus.Owned;
  }

  get isExample() {
    return this.dialogContext.asset.isExample;
  }

  private _editFundAssetService: EditFundAssetService;

  get dialogContext() {
    if (this._editFundAssetService) {
      return this._editFundAssetService.editFundAssetDialogContext;
    }
  }

  get selectedAreaUnit(): AreaUnit {
    try {
      return Number(this._editFundAssetService.getTabForm(1).get('areaUnits').value) as AreaUnit;
    } catch {
      return AreaUnit.SquareFeet;
    }
  }
  set selectedAreaUnit(value: AreaUnit) {
    this._editFundAssetService.getTabForm(1).get('areaUnits').setValue(value);
  }

  get selectedInvestmentType() {
    try {
      return this._editFundAssetService.getTabForm(1).get('investmentType').value as InvestmentType;
    } catch {
      return null;
    }
  }

  constructor(editFundAssetService: EditFundAssetService) {
    this._editFundAssetService = editFundAssetService;
  }

  get pageForm() {
    return this._editFundAssetService.getTabForm(this.TAB_NUMBER);
  }

  stepSubmit() {
    this.isSubmitAttempt = true;

    this.pageForm.updateValueAndValidity();
    if (this.pageForm.valid) {
      this._editFundAssetService.isGeneralServerError = false;
      this._editFundAssetService.saveChanges();
    }
  }
}
