import { HostBinding, Directive } from '@angular/core';

import { CreateFundStepNumber, CreateFundService } from './create-fund.service';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

export interface ICreateFundStep {
  readonly STEP_NUMBER: CreateFundStepNumber;
}

@Directive()
export abstract class CreateFundStepBase extends OnDestroyMixin implements ICreateFundStep {

  @HostBinding('attr.class') hostClasses = 'wizard-step';

  // enums
  HoldingDiscriminator = HoldingDiscriminator;

  abstract STEP_NUMBER: CreateFundStepNumber;
  readonly LAST_STEP_NUMBER = 4 as CreateFundStepNumber;

  isSubmitAttempt = false;

  private _createFundService: CreateFundService;

  get selectedAreaUnit(): AreaUnit {
    try {
      return Number(this._createFundService.createFundForm.get('step1.areaUnits').value) as AreaUnit;
    } catch {
      return AreaUnit.SquareFeet;
    }
  }
  set selectedAreaUnit(value: AreaUnit) {
    this._createFundService.createFundForm.get('step1.areaUnits').setValue(value);
  }

  constructor(createFundService: CreateFundService) {
    super();
    this._createFundService = createFundService;
  }

  get pageForm() {
    return this._createFundService.getStepForm(this.STEP_NUMBER);
  }

  get isSubmitted() {
    return this._createFundService.isStepSubmitted(this.STEP_NUMBER);
  }
  set isSubmitted(val: boolean) {
    this._createFundService.setStepAsSubmitted(this.STEP_NUMBER, val);
  }

  back() {
    if (this.STEP_NUMBER > 1) {
      this._createFundService.currentStep$.next(this.STEP_NUMBER - 1 as CreateFundStepNumber);
    }
  }

  stepSubmit() {
    this.isSubmitAttempt = true;

    this.pageForm.updateValueAndValidity();
    this.pageForm.markAllAsTouched();

    if (this.pageForm.valid) {
      this._createFundService.isGeneralServerError = false;
      this.isSubmitted = true;
      // If not the last step - go to the next step
      if (this.STEP_NUMBER < this.LAST_STEP_NUMBER) {
        this._createFundService.currentStep$.next((this.STEP_NUMBER + 1) as CreateFundStepNumber);
      } else {
        // If submitting the last step, call the create fund function
        this._createFundService.createFund();
      }
    }
  }

}
