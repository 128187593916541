<section [formGroup]="pageForm" *ngIf="!disableWaterfalls">
  <div class="terra-form-field-wrapper">
    <div class="label-container">
      <label for="distribution-amount">Distribution amount:</label>
      <ng-container *ngIf="selectedCREBankAccount">
        <terra-buttons
          type="link"
          label="Transfer limits"
          [outlinedIcon]="true"
          (onClick)="openUnitAccountLimit()"
        ></terra-buttons>
      </ng-container>
    </div>
    <mat-form-field appearance="outline" class="remove-error-place total-distribution-input">
      <input id="distribution-amount" type="text" formControlName="amount" terraFormattedNumber placeholder="0"
             matInput class="lo_sensitive" autocomplete="off" [disableControl]="isCalculating$ | async"/>
      <span matSuffix>{{ currencyIso }}</span>
    </mat-form-field>
  </div>
  <button (click)="generateWaterfall()" type="button" class="tall-button calculate-waterfalls" mat-flat-button color="primary"
          [disabled]="disableEditMode || (disableCalculate$ | async)">
    Calculate waterfall
    <terra-loader *ngIf="isCalculating$ | async"></terra-loader>
  </button>

  <p class="waterfall-notice" *ngIf="waterfallNotice">{{ waterfallNotice }}</p>
  <a class="waterfalls-link" *ngIf="calculatedWaterfallUrl$ | async as downloadExcelUrl" [href]="downloadExcelUrl" target="_blank">Download</a>
  <mat-error *ngIf="errorMessage$ | async">{{ errorMessage$ | async }}</mat-error>
</section>

<section *ngIf="disableWaterfalls" class="disabled-waterfalls info-message-compact">
  Automated waterfall distributions are not included in your current Covercy subscription plan.<br>
  <a href="https://www.covercy.com/contact-us/" target="_blank">Contact us</a> to unlock this powerful feature.
</section>
