<div class="files-limit">
  <div class="progress">
    <div class="limit-content">
      <div class="limit-title">Files limit usage </div>
      <div class="limit-value">{{value}}/{{max}} files</div>
    </div>
    <mat-progress-bar color="secondary" clas="limit-indicator" mode="determinate" [value]="(value/max)*100"></mat-progress-bar>
  </div>
  <a mat-button class="limit-link" color="primary" target="_blank" [href]="link">Increase Limit</a>
</div>
