<div class="breadcrumbs container" *ngIf="asset$ | async as asset">
  <a [routerLink]="assetPageUrl$ | async">
    <span class="lo_sensitive">{{ asset.name }}</span> > Contributions > </a>
  <Span class="current">New Contribution</Span>
</div>
<h1>Create a new contribution</h1>

<p class="page-description">
  Fill out the following information to open a new contribution.<br />
  You'll be able to add investors in the next screen.
</p>

<form *ngIf="pageForm" (ngSubmit)="createFundraising()" [formGroup]="pageForm"
  (keydown.enter)="$event.preventDefault()">
  <!-- Loader -->
  <div class="loader-wrapper full-width" *ngIf="showInternalLoader">
    <terra-loader></terra-loader>
  </div>

  <div class="form-grid border-bottom terra-error-style" *ngIf="!showInternalLoader">
    <h3 class="section-title">Contribution details</h3>

    <!-- Contribution Name -->
    <div class="terra-form-field-wrapper">
      <label for="txtFundraisingName" class="text-input-label">Contribution name</label>
      <mat-form-field appearance="outline">
        <input matInput id="txtFundraisingName" formControlName="name" autocomplete="fundraising-name"
          class="lo_sensitive" />
        <mat-error *ngIf="pageForm.get('name').hasError('required')"> Required</mat-error>
        <mat-error *ngIf="pageForm.get('name').hasError('minlength')"> Please enter at least 2 characters</mat-error>
        <mat-error *ngIf="pageForm.get('name').hasError('fundraisingNameTaken')"> A contribution with the same name
          already exists
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Closing date -->
    <div class="terra-form-field-wrapper">
      <terra-custom-utc-date-picker id="txtClosingDate" formControlName="estimatedClosingDate"
        label="Estimated closing date" [minDate]="estimatedClosingDateMinimumDate"
        [requiredError]="pageForm.get('estimatedClosingDate').hasError('required')"
        [touched]="pageForm.get('estimatedClosingDate').touched"
        [minError]="pageForm.get('estimatedClosingDate').hasError('minDate')"
        minErrorTxt="Please enter a valid closing date"
        [startAt]="estimatedClosingDateStartAt"></terra-custom-utc-date-picker>
    </div>

    <!-- Security type -->
    <div class="terra-form-field-wrapper">
      <label for="ddlInvestmentSecurityType" class="text-input-label">Security type</label>
      <mat-form-field appearance="outline">
        <mat-select id="ddlInvestmentSecurityType" placeholder="Select" formControlName="securityType">
          <mat-option>Select</mat-option>
          <mat-option *ngFor="let item of InvestmentSecurityTypesList" [value]="item"> {{ item | enumString:
            InvestmentSecurityType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- FundraisingTargetAmount and currency -->
    <div class="terra-form-field-wrapper new-row">
      <label for="txtFundraisingTargetAmount" class="amount-label text-input-label">
        {{ selectedSecurityType === InvestmentSecurityType.DirectOwnership ? 'Purchase price' : 'Total amount to be
        raised'}}
      </label>
      <mat-form-field appearance="outline" class="amount-field-wrapper">
        <input type="tel" terraFormattedNumber matInput id="txtFundraisingTargetAmount"
          formControlName="fundraisingTargetAmount" class="lo_sensitive" />
        <span *ngIf="assetCurrency$ | async as assetCurrency" matSuffix>{{ assetCurrency.iso }}</span>
        <mat-error *ngIf="pageForm.get('fundraisingTargetAmount').hasError('required')"> Required</mat-error>
        <mat-error *ngIf="pageForm.get('fundraisingTargetAmount').hasError('min')"> Amount must be greater than
          zero</mat-error>
      </mat-form-field>
    </div>

    <!-- Minimum investment -->
    <div class="terra-form-field-wrapper">
      <label for="txtMinimumInvestmentAmount" class="text-input-label">
        {{ selectedSecurityType === InvestmentSecurityType.DirectOwnership ?
        'Initial purchase price (optional)' : 'Minimum investment (optional)'}}
      </label>
      <mat-form-field appearance="outline">
        <input type="tel" matInput id="txtMinimumInvestmentAmount" terraFormattedNumber
          formControlName="minimumInvestmentAmount" class="lo_sensitive" />
        <span *ngIf="assetCurrency$ | async as assetCurrency" matSuffix>{{ assetCurrency.iso }}</span>
      </mat-form-field>
    </div>


    <h3 class="section-title">Upload an offering deck (optional)</h3>

    <!-- Marketing document uploader -->
    <terra-file-uploader no-type-select formControlName="marketingDeckDocument" [isSubmitted]="isSubmitted"
      [required]="false" [isCompressable]="true">
    </terra-file-uploader>

    <p class="file-uploader-hint new-row">File format must be JPG, JPEG, PNG (20 MB max file size) or PDF (50 MB max
      file size)</p>
    <mat-checkbox class="regular-checkbox" color="primary full-width mt-2" formControlName="isDefaultDeck">
      Set as default deck for Invest Now Opportunity Page
    </mat-checkbox>

    <h3 class="section-title">Bank account where funds will be collected (may be added later)</h3>

    <!-- Bank account -->
    <ng-template #banksLoader>
      <terra-loader *ngIf="!noAccessMessage"></terra-loader>
    </ng-template>

    <ng-container *ngIf="!noAccessMessage && (bankAccounts$ | async) as bankAccounts; else banksLoader">
      <div class="terra-form-field-wrapper" *ngIf="assetCurrency$ | async as assetCurrency">
        <label for="ddlBankAccount" class="text-input-label">Choose from existing:</label>
        <mat-form-field appearance="outline">
          <mat-select id="ddlBankAccount"
            [placeholder]="bankAccounts.length ? 'Select' : 'No ' + assetCurrency.iso + ' bank accounts found'"
            formControlName="bankAccount" [compareWith]="bankAccountCompareFn">
            <mat-option [value]="null">Select</mat-option>
            @for ((bankAccount of (bankAccountOptions$ | async)); track $index; let i = $index;) {
            <mat-option [value]="bankAccount" [ngClass]="{'unit-account-item': bankAccount.isUnitBankAccount}">
              {{ bankAccount.nickname }} {{ !bankAccount.nickname ? bankAccount.holderFullName + ', ' : '' }}
              {{ !bankAccount.nickname ? getCountryById(bankAccount.countryId).code : '' }}
            </mat-option>
            <mat-divider class="unit-accounts-divider"
              *ngIf="gpBankAccountDataService.isLastUnitBank(bankAccounts, i)" />
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <a class="link add-bank-account" (click)="createNewBankAccount()">Add new</a>
      </div>
      <!-- <div>
        <a class="link add-bank-account" (click)="openBankAccountDialog()">Add New Bank Account</a>
      </div> -->
    </ng-container>

    <div class="full-width not-allow-message" *ngIf="noAccessMessage">
      <mat-icon class="notranslate">error</mat-icon>
      <h4>{{noAccessMessage}}</h4>
    </div>
  </div>

  <div class="form-grid" *ngIf="!showInternalLoader">
    <div class="full-width">
      <!-- Error message -->
      <div *ngIf="isSubmitted">
        <p *ngIf="!pageForm.valid" class="error-message">
          Please review and correct the fields marked in red
        </p>
        <p *ngIf="isGeneralServerError" class="error-message">{{ generalServerErrorMessage }}</p>
        <p *ngIf="isFileNotSupported" class="error-message">{{ fileNotSupportedError }}</p>
        <p *ngIf="isSameNameException" class="error-message">{{ sameNameExceptionErrorMessage }}</p>
      </div>
      <div class="action-buttons">
        <button type="submit" mat-flat-button color="primary" class="page-cta">Create Contribution</button>
        <button type="button" mat-button class="cancel-button tall-button" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</form>