import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {InvestmentReqRes} from '../../models/investment.model';

@Injectable({
  providedIn: 'root'
})
export abstract class HoldingInvestorService {

  abstract investmentId$;

  abstract isUpdatingStatus$;

  abstract investmentDetails$;

  abstract isContributionInReadOnlyMode$;

  constructor() { }

  abstract set pageForm(value: UntypedFormGroup);

  abstract get pageForm();

  abstract deleteInvestment();

  abstract updateFinalAmount(finalAmount: number);

  abstract updatePaymentSettlementDate(updatePaymentSettlement: Date);

  abstract updateStatus(status: InvestmentStatus);

  abstract updateEstimatedAmountAndRemarks(estimatedAmount: number, externalRemarks: string, internalRemarks: string, totalCommitment: number);

  abstract sendMarketingDeck(message: string, subject: string);

  abstract updateDocument(metaFileLinkId: number);

  abstract generateTransferConfirmation();

  abstract agreementSent(investment: InvestmentReqRes);

  abstract uploadPaymentRequestDocuments(metaFileLinkId: number[]): Observable<InvestmentReqRes>;

  abstract deletePaymentRequestDocument(metaFileLinkId: number): Observable<InvestmentReqRes>;

  public isValidEstimatedAmount(estimatedAmountFormControl: AbstractControl): boolean {
    return !estimatedAmountFormControl.hasError('min') && !estimatedAmountFormControl.hasError('required');
  }
}
