import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, of, timer} from 'rxjs';
import {finalize, switchMap, map} from 'rxjs/operators';

import {FundraisingReqRes} from '../../fundraisings-tab/FundraisingReqRes.model';
import {MetaFileLink} from '../../../../../../models/metaFileLink.model';
import {TerraNumberPipe} from '../../../../../../shared/pipes/TerraNumber.pipe';
import {GpFundraisingDataService} from '../../../../../../services/gp/gp-fundraising-data.service';
import {GpHoldingDataService} from '../../../../../../services/gp/gp-holding-data.service';
import {BaseResponseDto} from '../../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../../services/utils.service';
import FundraisingType from 'src/app/shared/enums/FundraisingType.enum';


@Component({
  selector: 'terra-upload-offering-deck-dialog',
  templateUrl: './upload-offering-deck-dialog.component.html',
  styleUrls: ['./upload-offering-deck-dialog.component.scss']
})
export class UploadOfferingDeckDialogComponent implements OnInit {

  pageForm: UntypedFormGroup;
  isSubmitted$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(false);
  fileNotSelected$ = new BehaviorSubject<boolean>(false);
  FundraisingType = FundraisingType;

  constructor(
    public dialogRef: MatDialogRef<UploadOfferingDeckDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public context: FundraisingReqRes,
    private numberPipe: TerraNumberPipe,
    private gpHoldingDataService: GpHoldingDataService,
    private fundraisingDataService: GpFundraisingDataService,
    private utilsService: UtilsService) {
  }

  ngOnInit() {
    this.pageForm = this.fb.group({
      marketingDeckDocument: [this.context.offeringDeck, null, this.validateOfferingDeckFileTypeSupported.bind(this)],
      isDefaultDeck: true
    });
  }

  send() {
    this.isSubmitted$.next(true);
    this.fileNotSelected$.next(false);
    const formValues = this.pageForm.getRawValue();
    if (formValues.marketingDeckDocument === null) {
      this.fileNotSelected$.next(true);
      return;
    }

    const offeringDeckId = (formValues.marketingDeckDocument as MetaFileLink).id;
    this.isLoading$.next(true);
    this.fundraisingDataService.updateOfferingDeck(this.context.holdingId, this.context.id, offeringDeckId, formValues.isDefaultDeck)
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
        })
      )
      .subscribe(
        updatedFundraising => {
          this.dialogRef.close(updatedFundraising);
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          }
        }
      );
  }

  validateOfferingDeckFileTypeSupported(control: AbstractControl) {
    if (!control.value || !control.value.id) {
      return of(null);
    }
    const metaFileLinkId = control.value.id;
    return timer(500).pipe(
      switchMap(_ => this.gpHoldingDataService.isFileSupportedOfferingDeck(metaFileLinkId)),
      map(isSupportedFileType => {
        return isSupportedFileType ? null : {fileTypeNotSupported: true};
      })
    );
  }
}
