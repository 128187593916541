import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import MetaFileLink from 'src/app/models/metaFileLink.model';
import { HttpService } from 'src/app/services/http.service';


export class AgreementListItem {
  public ordinal: number;
  public metaFileLink: MetaFileLink;
  public file: File;
  public uploadProgress = 0;
  public isUploading = false;
}

@Component({
  selector: 'terra-agreement-selector-dialog',
  templateUrl: './agreement-selector-dialog.component.html',
  styleUrls: ['./agreement-selector-dialog.component.scss']
})
export class AgreementSelectorDialogComponent implements OnInit {

  agreementFiles: AgreementListItem[] = [];
  private UPLOAD_FILE_ENDPOINT = 'upload';
  private compressableTypes = ['jpg', 'jpeg', 'png'];

  get isSubmitDisabled() {
    return this.agreementFiles.length === 0 || this.agreementFiles.some(x => x.isUploading);
  }

  constructor(
    private dialogRef: MatDialogRef<AgreementSelectorDialogComponent>,
    private http: HttpService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public fileList: FileList) {
  }

  ngOnInit() {
    if (!this.fileList || this.fileList.length === 0) {
      this.agreementFiles = new Array<AgreementListItem>();
    } else {
      this.agreementFiles = this.generateAgreementListFromFiles(this.fileList);
      this.uploadFiles();

    }
  }

  generateAgreementListFromFiles(fileList: FileList) {
    const list = new Array<AgreementListItem>();
    for (let i = 0; i < fileList.length; i++) {
      const item2Add = new AgreementListItem();
      item2Add.ordinal = i;
      item2Add.file = fileList[i];
      list.push(item2Add);
    }
    return list;
  }

  uploadFiles() {
    const filesThatWereNotUploaded = this.agreementFiles.filter(agreementItem => !agreementItem.metaFileLink);

    filesThatWereNotUploaded.map(agreement => {
      const fileToUpload = agreement.file;
      let uploadObservable: Observable<HttpEvent<{}>>;

      const fileType = fileToUpload.name.substr(fileToUpload.name.lastIndexOf('.') + 1);

      if (this.compressableTypes.includes(fileType.toLowerCase())) {
        uploadObservable = this.http.compressImage(fileToUpload).pipe(
          switchMap(compressedFile => {
            return this.http.uploadFile(this.UPLOAD_FILE_ENDPOINT, compressedFile);
          })
        );
      } else {
        uploadObservable = this.http.uploadFile(this.UPLOAD_FILE_ENDPOINT, fileToUpload);
      }


      agreement.isUploading = true;
      uploadObservable.subscribe(
        result => {
          if (result.type === HttpEventType.UploadProgress) {
            agreement.uploadProgress = Math.round((100 * result.loaded) / result.total);
          } else if (result.type === HttpEventType.Response) {
            agreement.isUploading = false;
            const file = result.body as MetaFileLink;
            if (file.id > 0) {
              agreement.metaFileLink = file;
              this.changeDetectorRef.detectChanges();
              // this.isDocumentSelected = true;
            } else {
              // this.showFileUploadError = true;
            }
          }
        },
        // Error
        () => {
          agreement.isUploading = false;
          // this.showFileUploadError = true;
        }
      );


    });
  }

  moreFilesSelected(moreFilesList: FileList) {
    if (moreFilesList && moreFilesList.length > 0) {
      const filesToAddToList = this.generateAgreementListFromFiles(moreFilesList);
      this.agreementFiles.push(...filesToAddToList);
      this.uploadFiles();
    }
  }


  removeFile(file2Remove: AgreementListItem) {
    const index = this.agreementFiles.indexOf(file2Remove);
    this.agreementFiles.splice(index, 1);
  }

  // Closes the dialog and returns the selected files in their order
  submit() {
    const selectedMetaFileLinks = this.agreementFiles.map(a => a.metaFileLink);
    this.dialogRef.close(selectedMetaFileLinks);
  }

  drop(event: CdkDragDrop<AgreementListItem[]>) {
    moveItemInArray(this.agreementFiles, event.previousIndex, event.currentIndex);
  }
}
