export class AgreementPersonalMessageDialogParams {

  constructor(
    public investmentId: number,
    public investingEntityName: string,
    public investorName: string,
    public personalMessage: string,
    public sendAgreement = false
    ) {
  }
}
