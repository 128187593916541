/** The application status provided by unit
 * Notice: This enum must match the server side enum.
 */

export enum UnitStatus {
  Pending = 0,
  PendingReview = 1,
  Rejected = 2,
  Clearing = 3,
  /* Final status */
  Sent = 4,
  Canceled = 5,
  Returned = 6,
}

// tslint:disable-next-line:no-namespace
export namespace UnitStatus {
  export function toString(status: UnitStatus): string {
    switch (status) {
      case UnitStatus.Pending:
        return 'Pending';
      case UnitStatus.PendingReview:
        return 'Pending Review';
      case UnitStatus.Rejected:
        return 'Rejected';
      case UnitStatus.Clearing:
        return 'Clearing';
      case UnitStatus.Sent:
        return 'Sent';
      case UnitStatus.Canceled:
        return 'Canceled';
      case UnitStatus.Returned:
        return 'Returned';
      default:
        return '';
    }
  }

  export function parse(unitStatus: string): UnitStatus {
    return UnitStatus[unitStatus];
  }
}

export default UnitStatus;
