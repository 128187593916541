<terra-dialog-header>
  <h1>Change {{holding}} mode to up</h1>
</terra-dialog-header>

<mat-dialog-content>
  <p>
    The {{holding}} will become up and visible to your investors on their Investor Portal.
  </p>
  <p>
    Please choose whether you want to inform them via email about this change or not.
  </p>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-flat-button color="accent" class="btn-yes" (click)="action(ConfirmInvestorsNotificationResult.Notify)">
    Save and inform <mat-icon svgIcon="publish" class="notranslate publish-icon"></mat-icon>
  </button>
  <button type="button" mat-button class="btn-skip-notification" (click)="action(ConfirmInvestorsNotificationResult.SkipNotification)">
    Save without informing investors
  </button>
</div>
