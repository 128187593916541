<form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" class="form-grid-new terra-error-style">

  <!-- Asset photos -->
  <h3 class="section-title">
    Upload asset photos (optional, highly recommended)
  </h3>

  <ng-container formArrayName="assetPhotos">
    <div class="photo-uploaders-row full-width">
      <terra-image-uploader-thumbnail formControlName="0" [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="1" [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="2" [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="3" [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
    </div>

    <a class="add-more-photos-link full-width" (click)="showSecondPhotosRow = true" *ngIf="!showSecondPhotosRow">
      <mat-icon class="notranslate" color="primary">add_circle</mat-icon>
      Add more photos
    </a>

    <div class="photo-uploaders-row second-row full-width" *ngIf="showSecondPhotosRow">
      <terra-image-uploader-thumbnail formControlName="4" [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="5" [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="6" [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
      <terra-image-uploader-thumbnail formControlName="7" [isSubmitted]="isSubmitAttempt"></terra-image-uploader-thumbnail>
    </div>
    <p class="error-message full-width" *ngIf="pageForm.get('assetPhotos').invalid">The images marked in red are of an unsupported file
      type.
    </p>
    <p class="file-uploader-hint new-column">
      File format must be JPG, JPEG or PNG (20 MB max file size)
    </p>
  </ng-container>

  <!-- Payment request supporting documents -->
  <h3 class="section-title" id="editHoldingPaymentRequestDocument">
    Upload payment request supporting documents (optional)
  </h3>
  <p class="supporting-documents-hint full-width">
    The following documents will be attached to every payment request sent for this asset
  </p>

  <ng-container formArrayName="assetPaymentRequestDocuments">
    <div class="half-width">
      <terra-file-uploader class="file-uploader" no-type-select formControlName="0" [isSubmitted]="isSubmitAttempt" [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="1" [isSubmitted]="isSubmitAttempt" [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="2" [isSubmitted]="isSubmitAttempt" [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="3" [isSubmitted]="isSubmitAttempt" [required]="false">
      </terra-file-uploader>
    </div>

    <a class="add-more-photos-link full-width" (click)="showSecondDocumentsRow = true" *ngIf="!showSecondDocumentsRow">
      <mat-icon class="notranslate" color="primary">add_circle</mat-icon>
      Add more documents
    </a>

    <div class="half-width" *ngIf="showSecondDocumentsRow">
      <terra-file-uploader class="file-uploader" no-type-select formControlName="4" [isSubmitted]="isSubmitAttempt" [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="5" [isSubmitted]="isSubmitAttempt" [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="6" [isSubmitted]="isSubmitAttempt" [required]="false">
      </terra-file-uploader>
      <terra-file-uploader class="file-uploader" no-type-select formControlName="7" [isSubmitted]="isSubmitAttempt" [required]="false">
      </terra-file-uploader>
    </div>
    <p class="error-message full-width" *ngIf="pageForm.get('assetPaymentRequestDocuments').invalid">The files marked in red are of an
      unsupported file
      type.
    </p>
    <p class="file-uploader-hint new-column">
      File format must be JPG, JPEG, PNG (20 MB max file size) or PDF (50 MB max file size)
    </p>
  </ng-container>

  <!-- Asset down mode -->
  <h3 class="section-title">Asset down mode</h3>
  <terra-holding-is-down-card [holdingDiscriminator]="HoldingDiscriminator.Asset" [isPrivate]="pageForm.get('isPrivate').value"
    class="full-width">
    <mat-slide-toggle hideIcon labelPosition="before" formControlName="isPrivate" (change)="makeSurePrivateStateAllowed($event)"></mat-slide-toggle>
  </terra-holding-is-down-card>

</form>
