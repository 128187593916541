
export enum TrackStatus {
  None = 0,
  NotCalled = 1,
  NotViewed = 2,
  Viewed = 3,
  Transferred = 4,
  Invested = 5,
  Declined = 6,
}

// tslint:disable-next-line:no-namespace
export namespace TrackStatus {
  export function toString(trackStatus: TrackStatus) {
    switch (trackStatus) {
      case TrackStatus.None:
        return '';
      case TrackStatus.NotCalled:
        return 'Not Called';
      case TrackStatus.NotViewed:
        return 'Not Viewed';
      case TrackStatus.Viewed:
        return 'Viewed';
      case TrackStatus.Transferred:
        return 'Transferred';
      case TrackStatus.Invested:
        return 'Invested';
      case TrackStatus.Declined:
        return 'Declined';
    }
  }

  export function listAll(withNone: boolean = true){
    const listAll = [TrackStatus.None, TrackStatus.NotCalled, TrackStatus.NotViewed, TrackStatus.Viewed, TrackStatus.Transferred,
                     TrackStatus.Invested, TrackStatus.Declined];
    if (withNone){
      return listAll;
    }
    else {
      return listAll.slice(1, listAll.length);
    }
  }
}

export default TrackStatus;
