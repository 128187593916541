import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {combineLatest, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';

import {InvestmentReqRes} from 'src/app/dashboard/models/investment.model';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import {GpFundraisingDataService} from 'src/app/services/gp/gp-fundraising-data.service';
import {GpInvestmentDataService} from 'src/app/services/gp/gp-investment-data.service';
import FundraisingStatus from 'src/app/shared/enums/FundraisingStatus.enum';
import {SearchOptionsRequest} from 'src/app/shared/models/SearchOptionsRequest.model';
import {AppQuery} from 'src/app/state';
import {GpHoldingService} from '../gp-holding.service';
import {InvestorsOverviewResponse} from '../investor-overview/InvestorsOverviewResponse.model';
import {FundraisingDetailsInterface} from './fundraisings-tab/edit-fundraising-dialog.context';
import {FundraisingReqRes} from './fundraisings-tab/FundraisingReqRes.model';
import InvestmentStatus from '../../../../shared/enums/InvestmentStatus.enum';
import {ClientBankAccountReqRes} from '../../../models/bankAccount.model';
import {TerraUtils} from '../../../../shared/TerraUtils';
import {CountryModel} from '../../../../shared/models/CountryModel';
import {UnitBankAccountReqRes} from '../../../../shared/models/gp/UnitBankAccountReqRes.model';
import {TransferOwnershipReqRes} from './ownership-transfer-dialog/TransferOwnershipReqRes.model';
import PaymentStatus from '../../../../shared/enums/PaymentStatus.enum';
import {PermissionService} from 'src/app/permission/permission.service';
import HoldingDiscriminator from '../../../../shared/enums/HoldingDiscriminator.enum';

class EndpointPrefixIds {
  constructor(public holdingId: number, public fundraisingId: number) {
  }
}

@Injectable()
export class HoldingFundraisingService extends OnDestroyMixin implements FundraisingDetailsInterface {
  fundraisingIdForPollingOnly$ = this._route.params.pipe(
    map(params => +params.fundraisingid),
    distinctUntilChanged(),
    map(fundraisingIdFromUrl => fundraisingIdFromUrl || 0),
    shareReplay(1)
  );

  holding$ = this._gpHoldingService.holding$;
  fundraising$ = this._gpHoldingService.fundraising$;

  fundraisingId$ = combineLatest([this.fundraisingIdForPollingOnly$, this.fundraising$]).pipe(
    map(([fundraisingIdFromUrl, fundraising]) => {
      return fundraisingIdFromUrl || (fundraising ? fundraising.id : null);
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  endpointPrefixIds$ = combineLatest([this._gpHoldingService.holdingId$, this.fundraisingId$]).pipe(
    shareReplay(1),
    map(([holdingId, fundraisingId]) => new EndpointPrefixIds(holdingId, fundraisingId))
  );

  updatedInvestment$ = new Subject<InvestmentReqRes>();
  // Emits the deleted investmentId
  deletedInvestmentId$ = new Subject<number>();

  // flag to decide if the contribution page should load in a read-only mode or not (in a read only mode - rename to contribution is allowed)
  isContributionInReadOnlyMode$ = this.fundraising$.pipe(
    map(fundraisingDetails => fundraisingDetails.status === FundraisingStatus.Completed),
    distinctUntilChanged(),
    shareReplay(1)
  );

  private uiStateSearchOptionsSource$: Observable<SearchOptionsRequest> = combineLatest(([this.holding$, this._appQuery.gpUiPrefs]))
    .pipe(
      map(([holding, state]) => {
        return holding.discriminator === HoldingDiscriminator.Fund ? state.fundsManagingOverview.sort : state.assetInvestorOverview.sort;
      }),
      map(sort => ({orderBy: sort.orderBy, sortOrder: sort.direction}) as SearchOptionsRequest));

  // Search options state
  searchOptionsOverview$: Observable<SearchOptionsRequest> = this.uiStateSearchOptionsSource$
    .pipe(
      untilComponentDestroyed(this)
    );

  constructor(
    private _route: ActivatedRoute,
    private _appQuery: AppQuery,
    private _gpHoldingService: GpHoldingService,
    private _gpFundraisingDataService: GpFundraisingDataService,
    private _gpInvestmentDataService: GpInvestmentDataService,
    private _permissionService: PermissionService
  ) {
    super();
  }

  public isUpdateCurrencyAllowed(fundraisingDetails: FundraisingReqRes): boolean {
    // Find investments (not declined) with payment request already sent to the investors, or status Invested
    const investmentsWithPaymentRequestSentOrInvested = fundraisingDetails.investments
      .filter(i => i.status !== InvestmentStatus.Declined)
      .find(i => i.paymentRequestSendDate || i.status === InvestmentStatus.Invested);
    // If found, it's not allowed to update bank account
    return !investmentsWithPaymentRequestSentOrInvested;
  }

  /**
   * Updates fundraising$
   * @param fundraising New fundraising data
   */
  changeFundraising(fundraising: FundraisingReqRes) {
    this.fundraising$.next(fundraising);
  }

  deleteFundraising() {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this._gpFundraisingDataService.delete(endpointPrefixIds.holdingId, endpointPrefixIds.fundraisingId);
      })
    );
  }

  updateFundraising(model: FundraisingReqRes): Observable<FundraisingReqRes> {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this._gpFundraisingDataService.update(endpointPrefixIds.holdingId, endpointPrefixIds.fundraisingId, model);
      }),
      tap(fundraising => {
        this.fundraising$.next(fundraising);
      })
    );
  }

  deleteOfferingDeck(offeringDeck: MetaFileLink) {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this._gpFundraisingDataService.deleteOfferingDeck(endpointPrefixIds.holdingId, endpointPrefixIds.fundraisingId, offeringDeck.id);
      })
    );
  }

  isFundraisingNameExists(name: string): Observable<boolean> {
    return combineLatest([this.holding$, this.fundraisingId$]).pipe(
      switchMap(([holding, fundraisingId]) => this._gpFundraisingDataService.isFundraisingNameExists(holding.id, name, fundraisingId))
    );
  }

  getInvestorsOverview(skipXirrCalculation: boolean = false): Observable<InvestorsOverviewResponse> {
    return combineLatest([this.endpointPrefixIds$, this.uiStateSearchOptionsSource$]).pipe(
      untilComponentDestroyed(this),
      switchMap(([endpointPrefixIds, searchOptions]) =>
        this._gpInvestmentDataService.getInvestorsOverview(endpointPrefixIds.holdingId, searchOptions, skipXirrCalculation))
    );
  }

  sortInvestments(investments: InvestmentReqRes[]) {
    if (investments) {
      return investments.sort((a, b) => b.id - a.id);
    }
    return [];
  }

  amountFunded(fundraisingDetails: FundraisingReqRes) {
    return this.amountByStatus(fundraisingDetails, InvestmentStatus.Invested);
  }

  amountSignedOrInvested(fundraisingDetails: FundraisingReqRes) {
    return this.amountByStatus(fundraisingDetails, InvestmentStatus.Invested) + this.amountByStatus(fundraisingDetails, InvestmentStatus.Signed);
  }

  getHoldingBankAccount(fundraising: FundraisingReqRes): ClientBankAccountReqRes {
    if (fundraising.clientBankAccount) {
      return fundraising.clientBankAccount;
    } else if (fundraising.unitBankAccount) {
      return this.unitBankToClientBank(fundraising.unitBankAccount);
    } else {
      return null;
    }
  }

  getHoldingBankAccountDisplayName(fundraising: FundraisingReqRes): string {
    const bankAccount = this.getHoldingBankAccount(fundraising);
    if (!!bankAccount) {
      if (!!bankAccount.nickname) {
        return bankAccount.nickname;
      } else {
        return bankAccount.holderFullName;
      }
    }

    return '';
  }

  private unitBankToClientBank(unitBankAccount: UnitBankAccountReqRes) {
    const res = new ClientBankAccountReqRes();
    res.id = unitBankAccount.id;
    res.nickname = unitBankAccount.accountNickname;
    res.bankName = unitBankAccount.name;
    res.holderFullName = unitBankAccount.accountNickname;
    res.accountNumber = unitBankAccount.accountNumber;
    res.country = new CountryModel();
    res.country.code = TerraUtils.consts.countryCode.US;
    res.isUnitBankAccount = true;
    return res;
  }

  private amountByStatus(fundraisingDetails: FundraisingReqRes, status: InvestmentStatus) {
    const investmentsInStatus = fundraisingDetails.investments.filter(x => x.status === status);
    if (investmentsInStatus.length > 0) {
      return investmentsInStatus.map(x => x.finalAmount).reduce((sum, current) => sum + current);
    }
    return 0;
  }

  fundraisingComplete(fundraising: FundraisingReqRes) {
    return fundraising.status === FundraisingStatus.Completed;
  }

  transferOwnership(model: TransferOwnershipReqRes): Observable<FundraisingReqRes> {
    return this.endpointPrefixIds$.pipe(
      take(1),
      switchMap(endpointPrefixIds => {
        return this._gpFundraisingDataService.transferOwnership(endpointPrefixIds.holdingId, model);
      })
    );
  }

  /**
   * show dialog to move fundraising to complete (method that override)
   * do not delete
   */
  public showMoveFundraisingToComplete() {
  }

  /**
   * Get the name of investor formatted for display
   * @param investment Investment details
   */
  public getFormattedInvestorName(investment: InvestmentReqRes): Observable<string> {
    return this._permissionService.allowInvestorName$
      .pipe(
        map(allowInvestorName => {
          if (allowInvestorName) {
            return `${investment.investingEntity.name} (${investment.investingEntity.contact.firstName} ${investment.investingEntity.contact.middleName} ${investment.investingEntity.contact.lastName})`;
          } else {
            return `Entity ${investment.investingEntity.nickname} (Contact ${investment.investingEntity.contact.nickname})`;
          }
        })
      );
  }

  public allowedToIssueCapitalCall(investmentId: number): Observable<boolean> {
    return combineLatest([this.holding$, this.fundraising$]).pipe(
      map(([holding, fundraising]) => {
        const investment = fundraising.investments.find(i => i.id === investmentId);
        if (!investment) {
          return false;
        }

        const bankAccountForFundraising = this.getHoldingBankAccount(fundraising);
        return !investment.isOrderCreated && !!bankAccountForFundraising && !holding.isPrivate &&
          (!investment.paymentRequestSendDate || !investment.transferDate) &&
          (!bankAccountForFundraising.isMissingGpData || investment.paymentStatus !== PaymentStatus.NotSent);
      })
    );
  }
}
