import {Observable} from 'rxjs';

import {FundraisingReqRes} from '../../shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {AssetAndFundraisingReqRes} from '../components/create-asset/AssetAndFundraisingRequest';
import {EditAssetTabNumber} from '../components/edit-asset-dialog/EditAssetStepBaseAndInterface';
import {GpAssetReqRes} from '../GpAssetReqRes.model';
import {EditFundAssetTabNumber} from 'src/app/dashboard/assets/components/edit-fund-asset-dialog/EditFundAssetStepBaseAndInterface';
import {FundraisingTableItemResponse} from '../../shared/holding/fundraising/fundraisings-tab/FundraisingTableItemResponse.model';

export interface GpAssetServiceInterface {
  allInvestmentsAreDone(fundraisingDetails: FundraisingReqRes): boolean;
  deleteAsset();
  updateAsset(model: AssetAndFundraisingReqRes, skipNotification: boolean): Observable<AssetAndFundraisingReqRes>;
  updateFundAsset(model: GpAssetReqRes, skipNotification: boolean): Observable<GpAssetReqRes>;
  moveAssetToUnderManagement(model: AssetAndFundraisingReqRes): Observable<AssetAndFundraisingReqRes>;
  moveFundraisingToCompleted(model: AssetAndFundraisingReqRes): Observable<AssetAndFundraisingReqRes>;
}

export class GpFundAssetDialogContext {

  // This is used in Edit asset only
  public initialTabNumber: EditFundAssetTabNumber;

  constructor(
    public gpAssetService: GpAssetServiceInterface,
    public asset: GpAssetReqRes
  ) { }
}


export class GpAssetDialogContext {

  // This is used in Edit asset only
  public initialTabNumber: EditAssetTabNumber;
  /**
   * Id of an HTML element to scroll to when opening the dialog
   */
  public scrollToId: string;

  constructor(
    public gpAssetService: GpAssetServiceInterface,
    public asset: GpAssetReqRes,
    public fundraisingDetails: FundraisingReqRes,
    public fundraisings: FundraisingTableItemResponse[]
  ) { }
}
