import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {filter, map, shareReplay} from 'rxjs/operators';

import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {GpHoldingService} from '../../gp-holding.service';
import {GpAssetReqRes} from '../../../../assets/GpAssetReqRes.model';
import {GpFundReqRes} from '../../../../funds/GpFundReqRes.model';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import FundInvestmentStrategyType from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import InvestmentType from 'src/app/shared/enums/InvestmentType.enum';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';


@Component({
  selector: 'terra-holding-info-summary',
  templateUrl: './holding-info-summary.component.html',
  styleUrls: ['./holding-info-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoldingInfoSummaryComponent extends OnDestroyMixin implements OnInit {
  // enums
  HoldingDiscriminator = HoldingDiscriminator;
  HoldingType = HoldingType;
  FundInvestmentStrategyType = FundInvestmentStrategyType;
  InvestmentType = InvestmentType;
  InvestmentSecurityType = InvestmentSecurityType;

  fundraising$ = this.holdingService.fundraising$;
  holding$ = this.holdingService.holding$;
  asset$ = this.holding$.pipe(
    untilComponentDestroyed(this),
    filter(holding => holding.discriminator === HoldingDiscriminator.Asset),
    map(holding => holding as GpAssetReqRes),
    shareReplay(1)
  );
  fund$ = this.holding$.pipe(
    untilComponentDestroyed(this),
    filter(holding => holding.discriminator === HoldingDiscriminator.Fund),
    map(holding => holding as GpFundReqRes),
    shareReplay(1)
  );
  isAsset$ = this.holding$.pipe(
    untilComponentDestroyed(this),
    map(holding => holding.discriminator === HoldingDiscriminator.Asset),
    shareReplay(1));
  isFund$ = this.isAsset$.pipe(
    untilComponentDestroyed(this),
    map(isAsset => !isAsset),
    shareReplay(1)
  );

  constructor(private holdingService: GpHoldingService) {
    super();
  }

  ngOnInit(): void {
  }

}
