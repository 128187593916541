import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {switchMap, take, tap, distinctUntilChanged, map, shareReplay, delayWhen, delay} from 'rxjs/operators';
import {combineLatest, Observable, BehaviorSubject, of} from 'rxjs';

import {GpAssetService} from './gp-asset.service';
import {AssetFundraisingService} from './asset-fundraising.service';
import {GpAssetDataService} from 'src/app/services/gp/gp-asset-data.service';
import {AssetPollingReqRes} from '../AssetPollingReqRes.model';
import { CapitalCallService } from '../../shared/holding/capital-call/capital-call.service';

@Injectable()
export class AssetPollingService {

  private fundraisingIdForPollingOnly$ = this.route.params.pipe(
    map(params => +params.fundraisingid),
    distinctUntilChanged(),
    map(fundraisingIdFromUrl => {
      if (fundraisingIdFromUrl >= 0) {
        return fundraisingIdFromUrl;
      } else {
        return 0;
      }
    }),
    shareReplay(1)
  );

  private distributionIdForPollingOnly$ = this.route.params.pipe(
    map(params => +params.distributionId),
    distinctUntilChanged(),
    map(distributionIdFromUrl => {
      if (distributionIdFromUrl >= 0) {
        return distributionIdFromUrl;
      } else {
        return 0;
      }
    }),
    shareReplay(1)
  );

  private changedSince$ = new BehaviorSubject(new Date(1970, 0, 1));
  private lastChangeTimestamp : Date = new Date(1970, 1, 1);
  constructor(
    private gpAssetService: GpAssetService,
    private assetFundraisingService: AssetFundraisingService,
    private gpAssetDataService: GpAssetDataService,
    private capitalCallService: CapitalCallService,
    private route: ActivatedRoute
  ) {
  }

  startPoll() {
    return this.changedSince$.pipe(
      switchMap(changedsince => this.poll(changedsince))
    );
  }

  refresh() {
    const now = new Date();
    now.setDate(now.getDate() - 1);
    this.changedSince$.next(now);
  }

  /// fundraisingId = 0 will get the initial fundraising.
  private poll(changedSince: Date): Observable<AssetPollingReqRes> {
    return combineLatest([this.gpAssetService.holdingId$, this.fundraisingIdForPollingOnly$, this.distributionIdForPollingOnly$]).pipe(
      take(1),
      switchMap(([assetId, fundraisingId, distributionId]) => {
        return this.gpAssetDataService.detectChanges(assetId, fundraisingId, distributionId, changedSince);
      }),
      delayWhen((pollingResponse) => pollingResponse ? of(0) : of(pollingResponse).pipe(delay(1000 * 15))),
      tap(pollingResponse => {
        if(!pollingResponse) {
          this.changedSince$.next(this.lastChangeTimestamp);
          return;
        }
        if (pollingResponse.storageObjectsChanged) {
          this.gpAssetService.documentsChanged$.next();
        }
        if (pollingResponse.distributionsChanged) {
          this.gpAssetService.refreshDistribution();
        }

        if (pollingResponse.signaturesChanged) {
          this.gpAssetService.refreshSigners();
        }

        if (pollingResponse.fundraisingDetails) {
          pollingResponse.fundraisingDetails.investments = [...this.assetFundraisingService.sortInvestments(pollingResponse.fundraisingDetails.investments)];
        }

        this.gpAssetService.changeAssetOnly(pollingResponse.assetDetails);
        this.gpAssetService.changeFundraisingOnly(pollingResponse.fundraisingDetails);
        this.lastChangeTimestamp = pollingResponse.timeStamp;
        this.changedSince$.next(pollingResponse.timeStamp);
      })
    );
  }
}
