<ng-template #loading>
  <terra-loader></terra-loader>
</ng-template>

<ng-container *ngIf="holdings$ | async as holdings; else loading">
  <!-- Empty state -->
  <section class="empty-state" *ngIf="emptyState$ | async; else assetsContent">
    <img src="assets/images/general/empty_state.svg" />
    <h2>No holdings yet</h2>

    <ng-container *ngIf="fundId$ | async as fundId">
      <button *ngIf="!(isRealized$ | async)" [routerLink]="getCreateFundAssetRoute(fundId)"
      class="create-asset-button-empty" type="button" mat-flat-button color="primary">
      <mat-icon class="notranslate">add</mat-icon> Add a New Asset
    </button>
    </ng-container>
    

  </section>
  <!-- Content -->
  <ng-template #assetsContent>
    <section class="assets-content" *ngIf="fundId$ | async as fundId">
      <!-- Actions -->
      <div class="header-actions">
        <button class="create-asset-button" *ngIf="!(isRealized$ | async)" type="button" [routerLink]="getCreateFundAssetRoute(fundId)"
          mat-stroked-button color="primary">
          <mat-icon class="notranslate">add</mat-icon>Add a New Asset
        </button>
      </div>

      <!-- Holdings table -->
      <table mat-table [dataSource]="holdings" class="cov-basic-table" matSort matSortDisableClear
        (matSortChange)="sortData($event)" [matSortDirection]="(searchOptions$ | async).sortOrder"
        [matSortActive]="(searchOptions$ | async).orderBy">
        {{(searchOptions$ | async).sortOrder}}
        <!-- Name column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Name">Name</th>
          <td mat-cell *matCellDef="let holding" class="lo_sensitive">{{ holding.name }}</td>
        </ng-container>

        <!-- Portfolio Type column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let holding">{{ holding.discriminator | enumString: HoldingDiscriminator }}
            <ng-container *ngIf="holding.discriminator == HoldingDiscriminator.Asset && holding.holdingType">
              ({{holding.holdingType | enumString: HoldingType}})</ng-container>
          </td>
        </ng-container>

        <!-- Status -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Status">Status</th>
          <td mat-cell *matCellDef="let holding">{{ getFundAssetStatus(holding.status) }}
        </ng-container>

        <!-- Exposure -->
        <ng-container matColumnDef="exposure">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Exposure">Exposure (%)</th>
          <td mat-cell *matCellDef="let holding">
            {{ holding.exposure | terraNumber }}%
          </td>
        </ng-container>

        <!-- Invested Amount -->
        <ng-container matColumnDef="investedAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="TotalInvestedInHoldingByFund">Invested Amount&nbsp;
            <mat-icon class="notranslate header-tooltip" color="primary" matTooltipClass="terra-tooltip"
              matTooltip="Fund equity invested in the asset/fund">
              help
            </mat-icon>
          </th>
          <td mat-cell *matCellDef="let holding">
            {{ holding.initialCurrency.symbol}}{{ holding.totalInvestedInHoldingByFund | terraCurrency }}
          </td>
        </ng-container>

        <!-- Ownership Stake (%)  -->
        <ng-container matColumnDef="investedAmount%">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Ownership">Ownership Stake (%)&nbsp;
            <mat-icon class="notranslate header-tooltip" color="primary" matTooltipClass="terra-tooltip"
              matTooltip="% of the asset/fund owned by {{fundName$ | async}}">
              help
            </mat-icon>
          </th>
          <td mat-cell *matCellDef="let holding">{{ holding.ownership | terraNumber }}%
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="actions-col-header"></th>
          <td mat-cell class="actions value-actions" *matCellDef="let holding">
            <!-- Delete asset -->
            <button mat-icon-button class="delete-button" *ngIf="canDeleteAsset(holding)"
              (click)="delete(holding); $event.stopPropagation();" disableRipple matTooltip="Delete">
              <mat-icon class="delete-icon">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let holding; columns: displayedColumns" [routerLink]="getHoldingLinkPath(holding)">
        </tr>
      </table>
    </section>
  </ng-template>
</ng-container>
