import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {SharedModule} from '../shared/shared.module';
import {AssetsComponent} from './assets/assets.component';
import {DashboardComponent} from './dashboard.component';
import {GpAssetLayoutComponent} from './assets/components/gp-asset-layout/gp-asset-layout.component';
import {GpFundLayoutComponent} from './funds/gp-fund-layout/gp-fund-layout.component';
import {GpAssetComponent} from './assets/gp-asset/gp-asset.component';
import {GpFundComponent} from './funds/gp-fund/gp-fund.component';
import {CreateAssetComponent} from './assets/components/create-asset/create-asset.component';
import {AssetDisplayTableComponent} from './assets/components/asset-display-table/asset-display-table.component';
import {EditBankAccountComponent} from './bankAccounts/components/edit-bank-account/edit-bank-account.component';
import {ViewBankAccountComponent} from './bankAccounts/components/view-bank-account/view-bank-account.component';
import {AssetInvestorComponent} from './assets/gp-asset/asset-investor/asset-investor.component';
import {ShareAssetComponent} from './assets/gp-asset/share-asset/share-asset.component';
import {AssetSendMarketingDialogComponent} from './assets/gp-asset/share-asset/asset-send-marketing-dialog/asset-send-marketing-dialog.component';
import {FundSendMarketingDialogComponent} from './funds/gp-fund/share-fund/fund-send-marketing-dialog/fund-send-marketing-dialog.component';
import {SendPaymentRequestDialogComponent} from './shared/holding/investor/send-payment-request-dialog/send-payment-request-dialog.component';
import {AssetSignAgreementBlockComponent} from './assets/gp-asset/asset-investor/asset-sign-agreement-block/asset-sign-agreement-block.component';
import {FundSignAgreementBlockComponent} from './funds/gp-fund/fund-investor/fund-sign-agreement-block/fund-sign-agreement-block.component';
import {PaymentRequestStatusBlockComponent} from './shared/holding/investor/payment-request-status-block/payment-request-status-block.component';
import {InvestmentStatusVisualComponent} from './assets/gp-asset/asset-investor/asset-investment-status-visual/asset-investment-status-visual.component';
import {
  AssetDownloadTransferConfirmationBlockComponent
} from './assets/gp-asset/asset-investor/asset-download-transfer-confirmation-block/asset-download-transfer-confirmation-block.component';
import {FundDownloadTransferConfirmationBlockComponent} from './funds/gp-fund/fund-investor/fund-download-transfer-confirmation-block/fund-download-transfer-confirmation-block.component';
import {DeleteBankAccountComponent} from './bankAccounts/components/delete-bank-account/delete-bank-account.component';
import {EditAssetDialogComponent} from './assets/components/edit-asset-dialog/edit-asset-dialog.component';
import {FindContactInListDialogComponent} from './assets/gp-asset/asset-invite-investor/find-contact-in-list-dialog/find-contact-in-list-dialog.component';
import {PendingChangesGuard} from '../guards/pendingChanges.guard';
import {AuthorizedGpGuard} from '../guards/authorized-gp.guard';
import {PartialEditAssetDialogComponent} from './assets/components/partial-edit-asset-dialog/partial-edit-asset-dialog.component';
import {AssetFundraisingStatusComponent} from './assets/gp-asset/asset-fundraising-status/asset-fundraising-status.component';
import {AssetDraftStatusComponent} from './assets/gp-asset/asset-draft-status/asset-draft-status.component';
import {FundDraftStatusComponent} from './funds/gp-fund/fund-draft-status/fund-draft-status.component';
import {AssetManagementStatusComponent} from './assets/gp-asset/asset-management-status/asset-management-status.component';
import {AssetInvestorsOverviewComponent} from './assets/gp-asset/asset-management-status/asset-investors-overview/asset-investors-overview.component';
import {FundInvestorsOverviewComponent} from './funds/gp-fund/fund-management-status/fund-investors-overview/fund-investors-overview.component';
import {AssetReportsComponent} from './assets/gp-asset/asset-management-status/reports/asset-reports.component';
import {FundReportsComponent} from './funds/gp-fund/fund-management-status/reports/fund-reports.component';
import {AssetReportComponent} from './assets/gp-asset/asset-management-status/reports/gp-asset-report/gp-asset-report.component';
import {AssetReportInformationComponent} from './assets/gp-asset/asset-management-status/reports/gp-asset-report/asset-report-information/asset-report-information.component';
import {FundReportInformationComponent} from './funds/gp-fund/fund-management-status/reports/gp-fund-report/fund-report-information/fund-report-information.component';
import {AssetReportCumulativeInfoComponent} from './assets/gp-asset/asset-management-status/reports/gp-asset-report/asset-report-cumulative-info/asset-report-cumulative-info.component';
import {FundReportCumulativeInfoComponent} from './funds/gp-fund/fund-management-status/reports/gp-fund-report/fund-report-cumulative-info/fund-report-cumulative-info.component';
import {AssetReportReviewComponent} from './assets/gp-asset/asset-management-status/reports/gp-asset-report/asset-report-review/asset-report-review.component';
import {FundReportReviewComponent} from './funds/gp-fund/fund-management-status/reports/gp-fund-report/fund-report-review/fund-report-review.component';
import {AssetFundraisingsComponent} from './assets/gp-asset/asset-management-status/asset-fundraisings/asset-fundraisings.component';
import {FundFundraisingsComponent} from './funds/gp-fund/fund-management-status/fund-fundraisings/fund-fundraisings.component';
import {GpAssetFundraisingComponent} from './assets/gp-asset/asset-management-status/asset-fundraisings/asset-fundraising/gp-asset-fundraising.component';
import {GpFundFundraisingComponent} from './funds/gp-fund/fund-management-status/fund-fundraisings/fund-fundraising/gp-fund-fundraising.component';
import {CreateAssetFundraisingComponent} from './assets/gp-asset/asset-management-status/asset-fundraisings/create-asset-fundraising/create-asset-fundraising.component';
import {CreateFundFundraisingComponent} from './funds/gp-fund/fund-management-status/fund-fundraisings/create-fund-fundraising/create-fund-fundraising.component';
import {EditFundraisingDialogComponent} from './shared/holding/fundraising/edit-fundraising-dialog/edit-fundraising-dialog.component';
import {
  PartialEditAssetFundraisingDialogComponent
} from './assets/gp-asset/asset-management-status/asset-fundraisings/partial-edit-asset-fundraising-dialog/partial-edit-asset-fundraising-dialog.component';
import {
  PartialEditFundFundraisingDialogComponent
} from './funds/gp-fund/fund-management-status/fund-fundraisings/partial-edit-fund-fundraising-dialog/partial-edit-fund-fundraising-dialog.component';
import {GpAssetDataService} from '../services/gp/gp-asset-data.service';
import {GpFundraisingDataService} from '../services/gp/gp-fundraising-data.service';
import {ContactDataService} from '../services/gp/contact-data.service';
import {GpPricingTierDataService} from '../services/gp/gp-pricing-tier-data.service';
import {GpInvestmentDataService} from '../services/gp/gp-investment-data.service';
import {AssetReportDataService} from '../services/shared/asset-report-data.service';
import {AssetReportFaqsComponent} from './assets/gp-asset/asset-management-status/reports/faqs/asset-report-faqs.component';
import {FundReportFaqsComponent} from './funds/gp-fund/fund-management-status/reports/faqs/fund-report-faqs.component';
import {CreateEditFaqDialogComponent} from './shared/holding/reports/create-edit-faq/create-edit-faq-dialog.component';
import {VerifyEmailGuard} from '../guards/verify-email.guard';
import {MaintenanceGuard} from '../guards/maintenance.guard';
import {OnboardingComponent} from '../onboarding/onboarding.component';
import {SubdomainGpGuard} from '../guards/subdomain-gp.guard';
import {GpOnboardingRequiredGuard} from '../guards/gp-onboarding-required.guard';
import {GpOnboardingPassedGuard} from '../guards/gp-onboarding-passed.guard';
import {AgreementSelectorDialogComponent} from './assets/gp-asset/asset-investor/asset-sign-agreement-block/agreement-selector-dialog/agreement-selector-dialog.component';
import {SignAgreementTemplatesComponent} from './assets/gp-asset/asset-investor/sign-agreement-templates/sign-agreement-templates.component';
import {AssetInvestorReactionsToOfferingDeckComponent} from './assets/gp-asset/share-asset/asset-investor-reactions-to-offering-deck/asset-investor-reactions-to-offering-deck.component';
import {FundInvestorReactionsToOfferingDeckComponent} from './funds/gp-fund/share-fund/fund-investor-reactions-to-offering-deck/fund-investor-reactions-to-offering-deck.component';
import {
  AgreementPersonalMessageDialogComponent
} from './assets/gp-asset/asset-investor/asset-sign-agreement-block/agreement-personal-message-dialog/agreement-personal-message-dialog.component';
import {CreateAssetInformationStepComponent} from './assets/components/create-asset/create-asset-information-step/create-asset-information-step.component';
import {CreateFundAssetInformationStepComponent} from './assets/components/create-fund-asset/create-fund-asset-information-step/create-fund-asset-information-step.component';
import {CreateAssetInvestmentDetailsStepComponent} from './assets/components/create-asset/create-asset-investment-details-step/create-asset-investment-details-step.component';
import {CreateAssetMarketingMaterialStepComponent} from './assets/components/create-asset/create-asset-marketing-material-step/create-asset-marketing-material-step.component';
import {
  CreateFundAssetMarketingMaterialStepComponent
} from './assets/components/create-fund-asset/create-fund-asset-marketing-material-step/create-fund-asset-marketing-material-step.component';
import {CreateAssetBankAccountStepComponent} from './assets/components/create-asset/create-asset-bank-account-step/create-asset-bank-account-step.component';
import {EditAssetTabAssetInformationComponent} from './assets/components/edit-asset-dialog/edit-asset-tab-asset-information/edit-asset-tab-asset-information.component';
import {EditAssetTabInvestmentDetailsComponent} from './assets/components/edit-asset-dialog/edit-asset-tab-investment-details/edit-asset-tab-investment-details.component';
import {EditAssetTabMarketingMaterialComponent} from './assets/components/edit-asset-dialog/edit-asset-tab-marketing-material/edit-asset-tab-marketing-material.component';
import {EditAssetTabBankAccountComponent} from './assets/components/edit-asset-dialog/edit-asset-tab-bank-account/edit-asset-tab-bank-account.component';
import {EditAssetTabMoreSettingsComponent} from './assets/components/edit-asset-dialog/edit-asset-tab-more-settings/edit-asset-tab-more-settings.component';
import {DistributionsTabComponent} from './shared/holding/distribution/distributions-tab/distributions-tab.component';
import {CreateEditDistributionComponent} from './shared/holding/distribution/create-edit-distribution/create-edit-distribution.component';
import {DistributionImportDialogComponent} from './shared/holding/distribution/create-edit-distribution/distribution-import-dialog/distribution-import-dialog.component';
import {DistributionComponent} from './shared/holding/distribution/distribution/distribution.component';
import {DistributionTransferDetailsComponent} from './shared/holding/distribution/distribution-transfer-details/distribution-transfer-details.component';
import {LpModule} from '../lp/lp.module';
import {AssetInvestorsListComponent} from './assets/gp-asset/asset-investors-list/asset-investors-list.component';
import {SendPaymentConfirmationComponent} from './shared/holding/distribution/send-payment-confirmation/send-payment-confirmation.component';
import {DepositDetailsDialogComponent} from './shared/holding/distribution/deposit-details-dialog/deposit-details-dialog.component';
import {SendNoticeConfirmationComponent} from './shared/holding/distribution/send-notice-confirmation/send-notice-confirmation.component';
import {DocumentsTabComponent} from './shared/holding/documents/documents-tab/documents-tab.component';
import {DocumentDisplayTableComponent} from './shared/holding/documents/document-display-table/document-display-table.component';
import {RenameStorageObjectDialogComponent} from './shared/holding/documents/rename-storage-object-dialog/rename-storage-object-dialog.component';
import {CreateFolderDialogComponent} from './shared/holding/documents/create-folder-dialog/create-folder-dialog.component';
import {FolderMenuPanelComponent} from './shared/holding/documents/document-display-table/folder-menu-panel/asset-folder-menu-panel.component';
import {ShareDocumentsDialogComponent} from './shared/holding/documents/share-documents-dialog/share-documents-dialog.component';
import {SetDocumentsPermissionsDialogComponent} from './shared/holding/documents/set-documents-permissions-dialog/set-documents-permissions-dialog.component';
import {GpInvestingEntityDataService} from '../services/gp/gp-investing-entity-data.service';
import {SelectContactInvestingEntityComponent} from './assets/gp-asset/asset-invite-investor/select-contact-investing-entity/select-contact-investing-entity.component';
import {ConfirmInvestorsNotificationDialogComponent} from './shared/holding/confirm-investors-notification-dialog/confirm-investors-notification-dialog.component';
import {CovercySharedComponentsModule} from '../shared/CovercySharedComponents.module';
import {FundReportDataService} from '../services/shared/fund-report-data.service';
import {FundsComponent} from './funds/funds.component';
import {FundDisplayTableComponent} from './funds/components/fund-display-table/fund-display-table.component';
import {CreateFundComponent} from './funds/components/create-fund/create-fund.component';
import {CreateFundInformationStepComponent} from './funds/components/create-fund/create-fund-information-step/create-fund-information-step.component';
import {CreateFundInvestmentDetailsStepComponent} from './funds/components/create-fund/create-fund-investment-details-step/create-fund-investment-details-step.component';
import {CreateFundMarketingMaterialStepComponent} from './funds/components/create-fund/create-fund-marketing-material-step/create-fund-marketing-material-step.component';
import {CreateFundBankAccountStepComponent} from './funds/components/create-fund/create-fund-bank-account-step/create-fund-bank-account-step.component';
import {FundFundraisingStatusComponent} from './funds/gp-fund/fund-fundraising-status/fund-fundraising-status.component';
import {FundManagementStatusComponent} from './funds/gp-fund/fund-management-status/fund-management-status.component';
import {FundInvestorsListComponent} from './funds/gp-fund/fund-investors-list/fund-investors-list.component';
import {FundInvestorComponent} from './funds/gp-fund/fund-investor/fund-investor.component';
import {FundInvestmentStatusVisualComponent} from './funds/gp-fund/fund-investor/fund-investment-status-visual/fund-investment-status-visual.component';
import {ShareFundComponent} from './funds/gp-fund/share-fund/share-fund.component';
import {GpHoldingDataService} from '../services/gp/gp-holding-data.service';
import {EditFundDialogComponent} from './funds/components/edit-fund-dialog/edit-fund-dialog.component';
import {EditFundTabFundInformationComponent} from './funds/components/edit-fund-dialog/edit-fund-tab-fund-information/edit-fund-tab-fund-information.component';
import {EditFundTabBankAccountComponent} from './funds/components/edit-fund-dialog/edit-fund-tab-bank-account/edit-fund-tab-bank-account.component';
import {EditFundTabInvestmentDetailsComponent} from './funds/components/edit-fund-dialog/edit-fund-tab-investment-details/edit-fund-tab-investment-details.component';
import {EditFundTabMarketingMaterialComponent} from './funds/components/edit-fund-dialog/edit-fund-tab-marketing-material/edit-fund-tab-marketing-material.component';
import {EditFundTabMoreSettingsComponent} from './funds/components/edit-fund-dialog/edit-fund-tab-more-settings/edit-fund-tab-more-settings.component';
import {FundPortfolioTabComponent} from './funds/gp-fund/fund-management-status/fund-portfolio-tab/fund-portfolio-tab.component';
import {FundAssetStatusComponent} from './assets/gp-asset/fund-asset-status/fund-asset-status.component';
import {PartialEditFundDialogComponent} from './funds/components/partial-edit-fund-dialog/partial-edit-fund-dialog.component';
import {FundPremiumComponent} from './funds/gp-fund/fund-premium/fund-premium.component';
import {GpFundReportComponent} from './funds/gp-fund/fund-management-status/reports/gp-fund-report/gp-fund-report.component';
import {CreateFundAssetComponent} from './assets/components/create-fund-asset/create-fund-asset.component';
import {EditFundAssetDialogComponent} from './assets/components/edit-fund-asset-dialog/edit-fund-asset-dialog.component';
import {EditFundAssetTabAssetInformationComponent} from './assets/components/edit-fund-asset-dialog/edit-fund-asset-tab-asset-information/edit-fund-asset-tab-asset-information.component';
import {EditFundAssetTabMoreSettingsComponent} from './assets/components/edit-fund-asset-dialog/edit-fund-asset-tab-more-settings/edit-fund-asset-tab-more-settings.component';
import {StartInitialFundraisingComponent} from './assets/gp-asset/fund-asset-status/start-initial-fundraising/start-initial-fundraising.component';
import {
  StartFundraisingInformationStepComponent
} from './assets/gp-asset/fund-asset-status/start-initial-fundraising/start-fundraising-information-step/start-fundraising-information-step.component';
import {
  StartFundraisingInvestmentStepComponent
} from './assets/gp-asset/fund-asset-status/start-initial-fundraising/start-fundraising-investment-step/start-fundraising-investment-step.component';
import {UpdateHoldingStatusDialogComponent} from './shared/holding/update-holding-status-dialog/update-holding-status-dialog.component';
import {
  CreateFundAssetInvestmentDetailsStepComponent
} from './assets/components/create-fund-asset/create-fund-asset-investment-details-step/create-fund-asset-investment-details-step.component';
import {EditFundAssetTabInvestmentDetailsComponent} from './assets/components/edit-fund-asset-dialog/edit-fund-asset-tab-investment-details/edit-fund-asset-tab-investment-details.component';
import {ImportDistributionsComponent} from './shared/holding/distribution/import-distributions/import-distributions.component';
import {DistributionDetailsComponent} from './shared/holding/distribution/import-distributions/distribution-details/distribution-details.component';
import {ImportDistributionsDialogComponent} from './shared/holding/distribution/import-distributions/import-distributions-dialog/import-distributions-dialog.component';
import {APP_SETTINGS, USER_DETAILS} from 'telemetry-library';
import {UserService} from '../services/shared/user.service';
import {AppSettingsService} from '../services/app-settings.service';
import {FundraisingGetStartedComponent} from './shared/holding/fundraising/fundraising-summary/fundraising-summary.component';
import {InvestorsListComponent} from './shared/holding/investors-list/investors-list.component';
import {AIRankingDialogComponent} from './shared/holding/fundraising/AI-investors-ranking/AI-ranking-dialog/AI-ranking-dialog.component';
import {GpAIRankingDataService} from '../services/gp/gp-AI-ranking-data.service';
import {AIRankingDisplayTableComponent} from './shared/holding/fundraising/AI-investors-ranking/AI-ranking-display-table/AI-ranking-display-table.component';
import {LoaderAiComponent} from '../shared/loader-ai/loader-ai.component';
import {GpSharedModule} from '../shared/gp-shared.module';
import {MaterialModule} from '../shared/material.module';
import {ImportContributionsComponent} from './shared/holding/fundraising/import-fundraisings/import-contributions.component';
import {ContributionDetailsComponent} from './shared/holding/fundraising/import-fundraisings/contribution-details/contribution-details.component';
import {ImportContributionsDialogComponent} from './shared/holding/fundraising/import-fundraisings/import-contributions-dialog/import-contributions-dialog.component';
import {BulkOfferingDeckComponent} from './shared/holding/fundraising/bulk-offering-deck/bulk-offering-deck.component';
import {UploadOfferingDeckDialogComponent} from './shared/holding/fundraising/bulk-offering-deck/upload-offering-deck-dialog/upload-offering-deck-dialog.component';
import {SendOfferingDeckDialogComponent} from './shared/holding/fundraising/bulk-offering-deck/send-offering-deck-dialog/send-offering-deck-dialog.component';
import {InvestorEngagementDialogComponent} from './shared/holding/fundraising/bulk-offering-deck/investor-engagement-dialog/investor-engagement-dialog.component';
import {EngagementInfoComponent} from './shared/holding/fundraising/bulk-offering-deck/investor-engagement-dialog/engagement-info/engagement-info.component';
import {EngagementDisplayTableComponent} from './shared/holding/fundraising/bulk-offering-deck/investor-engagement-dialog/engagement-display-table/engagement-display-table.component';
import {SendAllDialogComponent} from './shared/holding/fundraising/bulk-offering-deck/send-offering-deck-dialog/send-all-dialog/send-all-dialog.component';
import {SendConfirmationDialogComponent} from './shared/holding/fundraising/bulk-offering-deck/send-offering-deck-dialog/send-confirmation-dialog/send-confirmation-dialog.component';
import {DistributionTransferAmountsComponent} from './shared/holding/distribution/distribution-transfer-details/distribution-transfer-amounts/distribution-transfer-amounts.component';
import {DistributionMultiTypeComponent} from './shared/holding/distribution/create-edit-distribution/distribution-multi-type/distribution-multi-type.component';
import {DistributionProRataComponent} from './shared/holding/distribution/create-edit-distribution/distribution-pro-rata/distribution-pro-rata.component';
import {AddInvestorsToCompletedFundraisingComponent} from './shared/holding/fundraising/add-investors-from-list-block/add-investors-from-list-block.component';
import {UnderManagementInformationComponent} from './shared/holding/under-management-information/under-management-information.component';
import {HoldingInfoSummaryComponent} from './shared/holding/under-management-information/holding-info-summary/holding-info-summary.component';
import {HoldingCreBankAccountComponent} from './shared/holding/under-management-information/holding-cre-bank-account/holding-cre-bank-account.component';
import {HoldingCreBankAccountPlaceholderComponent} from './shared/holding/under-management-information/holding-cre-bank-account-placeholder/holding-cre-bank-account.component-placeholder';
import {DistributionTotalAmountsComponent} from './shared/holding/distribution/distribution-total-amounts/distribution-total-amounts.component';
import {CreBankAccountSectionComponent} from './shared/holding/distribution/cre-bank-account-section/cre-bank-account-section.component';
import {ContactsModule} from './contacts/contacts.module';
import {ContactsComponent} from './contacts/contacts.component';
import {InvestorsContactsComponent} from './contacts/investors-contacts/investors-contacts.component';
import {RecipientsContactsComponent} from './contacts/recipients-contacts/recipients-contacts.component';
import {FeatureFlagGuard} from '../guards/feature-flag.guard';
import FeatureFlags from '../account/my-account/model/FeatureFlags.enum';
import {AddInvestorsOutOfDistributionComponent} from './shared/holding/distribution/add-investors-out-of-distribution/add-investors-out-of-distribution.component';
import {
  InvestorsOutOfDistributionDialog
} from './shared/holding/distribution/add-investors-out-of-distribution/investors-out-of-distribution-dialog/investors-out-of-distribution-dialog.component';
import {TransactionsComponent} from './shared/holding/transactions/transactions.component';
import {GpAssetService} from './assets/gp-asset/gp-asset.service';
import {GpFundService} from './funds/gp-fund/gp-fund.service';
import {OwnershipTransferDialogComponent} from './shared/holding/fundraising/ownership-transfer-dialog/ownership-transfer-dialog.component';
import {FundraisingDoughnutChartComponent} from './shared/holding/fundraising/fundraising-doughnut-card/fundraising-doughnut-chart/fundraising-doughnut-chart.component';
import {FundraisingDoughnutCardComponent} from './shared/holding/fundraising/fundraising-doughnut-card/fundraising-doughnut-card.component';
import {CapitalCallDetailsComponent} from './shared/holding/capital-call/capital-call-details/capital-call-details.component';
import {CapitalCallDetailsDialogComponent} from './shared/holding/capital-call/capital-call-details-dialog/capital-call-details-dialog.component';
import {CapitalCallConfirmDialogComponent} from './shared/holding/capital-call/capital-call-confirm-dialog/capital-call-confirm-dialog.component';
import {CapitalCallComponent} from './shared/holding/capital-call/capital-call.component';
import {CapitalCallInvestmentsTableComponent} from './shared/holding/capital-call/capital-call-investments-table/capital-call-investments-table.component';
import {UploadFileDialogComponent} from './shared/holding/capital-call/upload-file-dialog/upload-file-dialog.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {CapitalCallTrackComponent} from './shared/holding/capital-call/capital-call-track/capital-call-track.component';
import {CapitalCallBoxTitleComponent} from './shared/holding/capital-call/capital-call-box-title/capital-call-box-title.component';
import {FundraisingInvestorsStatisticsCardComponent} from './shared/holding/fundraising/fundraising-investors-statistics-card/fundraising-investors-statistics-card.component';
import {
  FundraisingInvestorsStatisticsChartComponent
} from './shared/holding/fundraising/fundraising-investors-statistics-card/fundraising-investors-statistics-chart/fundraising-investors-statistics-chart.component';
import {FundraisingNavComponent} from './shared/holding/fundraising/fundraising-nav/fundraising-nav.component';
import {ParticipatingFundDialogComponent} from './shared/holding/investor/participating-fund-dialog/participating-fund-dialog.component';
import {TermsAndConditionsGuard} from '../guards/terms-and-conditions-guard';
import {HoldingActionsComponent} from './shared/holding/under-management-information/holding-actions/holding-actions.component';
import {BankAccountSelectionComponent} from './shared/holding/bank-account-selection/bank-account-selection.component';
import {HoldingSendPaymentRequestBlockComponent} from './shared/holding/payment-request/holding-send-payment-request-block/holding-send-payment-request-block.component';
import {PaymentRequestDocumentBlockComponent} from './shared/holding/payment-request/payment-request-document-block/payment-request-document-block.component';
import {PermissionGuard} from '../guards/permission-guard.service';
import SectionsAccess from '../permission/enums/sectionsAccess.enum';
import {CreBankAccountDataService} from '../services/gp/cre-bank-account-data.service';
import {UnitBankAccountDataService} from '../services/shared/unit-bank-account-data.service';
import {BankApplicationDataService} from '../services/gp/bank-application-data.service';
import {UnitBankApplicationDataService} from '../services/shared/unit-bank-application-data.service';
import {HelpSourceType} from '../shared/enums/HelpSourceType.enum';
import {DistributionWaterfallsComponent} from './shared/holding/distribution/create-edit-distribution/distribution-waterfalls/distribution-waterfalls.component';
import {UnitCardDataService} from '../services/shared/unit-card-data.service';
import {AccreditationModule} from './shared/accreditation/accreditation.module';
import {GoogleMapSearchModule} from '../shared/components/google-map-search/google-map-search.module';
import {InvestorCommunicationDialogComponent} from './shared/investor-communication/investor-communication-dialog/investor-communication-dialog.component';
import {InvestorCommunicationResultSnackbarComponent} from './shared/investor-communication/investor-communication-result-snackbar/investor-communication-result-snackbar.component';
import {ContactsEmailTrackingComponent} from './contacts/contacts-email-tracking/contacts-email-tracking.component';
import {RaisedAmountWarningComponent} from './shared/holding/raised-amount-warning/raised-amount-warning.component';

import {PromoDialogComponent} from './promo/promo.component';
import {UploadDocumentsComponent} from './shared/holding/documents/upload-documents/upload-documents.component';
import {PrepareComponent} from './shared/holding/documents/upload-documents/prepare/prepare.component';
import {UploadComponent} from './shared/holding/documents/upload-documents/upload/upload.component';
import {MatchComponent} from './shared/holding/documents/upload-documents/match/match.component';
import {ShareComponent} from './shared/holding/documents/upload-documents/share/share.component';
import {UploadingStatusComponent} from './shared/holding/documents/upload-documents/uploading-status/uploading-status.component';
import {FilesLimitComponent} from './shared/holding/documents/upload-documents/files-limit/files-limit.component';
import {FilePreviewComponent} from './shared/holding/documents/upload-documents/file-preview/file-preview.component';
import {CreateFolderComponent} from './shared/holding/documents/upload-documents/create-folder/create-folder.component';
import {CanDeactivateNavigationIfHasChanges} from '../guards/canDeactivate.guard';
import {DiscardUploadComponent} from './shared/holding/documents/upload-documents/discard-upload/discard-upload.component';
import {StepLabelComponent} from './shared/holding/documents/upload-documents/step-label/step-label.component';
import {
  HoldingUploadSignedAgreementBlockComponentComponent
} from './shared/holding/holding-upload-signed-agreement-block-component/holding-upload-signed-agreement-block-component.component';
import {AddBankAccountDialogComponent} from './shared/holding/add-bank-account-dialog/add-bank-account-dialog.component';
import {BreadcrumbComponent} from '../shared/components/breadcrumb/breadcrumb.component';
import {InvestorsManagementService} from './shared/holding/Investors-management/investors-management.service';
import {FinancialPermissionLevel} from '../permission/enums/financialPermissionLevel.enum';
import { MarketingDocumentUploaderComponent } from './shared/marketing-document-uploader/marketing-document-uploader.component';
import { RentManagerUploaderComponent } from '../shared/rm-uploader/rm-uploader.component';
import { UploaderBlockComponent } from '../shared/components/uploader-block/uploader-block.component';

const fundTabRoutes: Routes = [
  {
    path: 'overview',
    component: FundInvestorsOverviewComponent,
    data: {preventScroll: true},
  },
  {
    path: 'portfolio',
    component: FundPortfolioTabComponent,
    data: {preventScroll: true},
  },
  {
    path: 'reports',
    component: FundReportsComponent,
    data: {preventScroll: true},
  },
  {
    path: 'documents',
    component: DocumentsTabComponent,
    data: {preventScroll: true},
  },
  {
    path: 'contributions',
    component: FundFundraisingsComponent,
    data: {preventScroll: true},
  },
  {
    path: 'distributions',
    component: DistributionsTabComponent,
    data: {preventScroll: true},
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
    data: {preventScroll: true},
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview',
  },
];

const assetTabRoutes: Routes = [
  {
    path: 'overview',
    component: AssetInvestorsOverviewComponent,
    data: {preventScroll: true},
  },
  {
    path: 'reports',
    component: AssetReportsComponent,
    data: {preventScroll: true},
  },
  {
    path: 'documents',
    component: DocumentsTabComponent,
    data: {preventScroll: true},
  },
  {
    path: 'contributions',
    component: AssetFundraisingsComponent,
    data: {preventScroll: true},
  },
  {
    path: 'distributions',
    component: DistributionsTabComponent,
    data: {preventScroll: true},
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
    data: {preventScroll: true},
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview',
  },
];

const assetReportWizardSteps: Routes = [
  {
    path: 'report-information',
    component: AssetReportInformationComponent,
  },
  {
    path: 'cumulative-info',
    component: AssetReportCumulativeInfoComponent,
  },
  {
    path: 'review',
    component: AssetReportReviewComponent,
  },
];

const fundReportWizardSteps: Routes = [
  {
    path: 'report-information',
    component: FundReportInformationComponent,
  },
  {
    path: 'cumulative-info',
    component: FundReportCumulativeInfoComponent,
  },
  {
    path: 'review',
    component: FundReportReviewComponent,
  },
];

const assetReportChildRoutes: Routes = [
  {
    path: 'create',
    pathMatch: 'full',
    redirectTo: 'create/report-information',
  },
  {
    path: 'create',
    component: AssetReportComponent,
    children: assetReportWizardSteps,
  },
  {
    path: ':reportId/faqs',
    component: AssetReportFaqsComponent,
  },
  {
    path: ':reportId',
    component: AssetReportComponent,
    children: assetReportWizardSteps,
  },
];

const fundReportChildRoutes: Routes = [
  {
    path: 'create',
    pathMatch: 'full',
    redirectTo: 'create/report-information',
  },
  {
    path: 'create',
    component: GpFundReportComponent,
    children: fundReportWizardSteps,
  },
  {
    path: ':reportId/faqs',
    component: FundReportFaqsComponent,
  },
  {
    path: ':reportId',
    component: GpFundReportComponent,
    children: fundReportWizardSteps,
  },
];

const createAssetWizardSteps: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'asset-information',
  },
  {
    path: 'asset-information',
    component: CreateAssetInformationStepComponent,
  },
  {
    path: 'investment-details',
    component: CreateAssetInvestmentDetailsStepComponent,
  },
  {
    path: 'marketing-material',
    component: CreateAssetMarketingMaterialStepComponent,
  },
  {
    path: 'bank-account',
    component: CreateAssetBankAccountStepComponent,
  },
];

const createFundAssetWizardSteps: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'asset-information',
  },
  {
    path: 'asset-information',
    component: CreateFundAssetInformationStepComponent,
  },
  {
    path: 'investment-details',
    component: CreateFundAssetInvestmentDetailsStepComponent,
  },
  {
    path: 'marketing-material',
    component: CreateFundAssetMarketingMaterialStepComponent,
  },
];

const routes = RouterModule.forChild([
  {
    path: 'onboarding',
    component: OnboardingComponent,
    canActivate: [
      MaintenanceGuard,
      SubdomainGpGuard,
      AuthorizedGpGuard,
      GpOnboardingPassedGuard,
    ],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [
      MaintenanceGuard,
      SubdomainGpGuard,
      AuthorizedGpGuard,
      VerifyEmailGuard,
      GpOnboardingRequiredGuard,
      TermsAndConditionsGuard,
    ],
    children: [
      {
        path: 'funds',
        component: FundsComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Funds},
      },
      {
        path: 'funds/create',
        component: CreateFundComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Funds},
        canDeactivate: [PendingChangesGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'fund-information',
          },
          {
            path: 'fund-information',
            component: CreateFundInformationStepComponent,
          },
          {
            path: 'investment-details',
            component: CreateFundInvestmentDetailsStepComponent,
          },
          {
            path: 'marketing-material',
            component: CreateFundMarketingMaterialStepComponent,
          },
          {
            path: 'additional',
            component: CreateFundBankAccountStepComponent,
          },
        ],
      },
      {
        path: 'funds/create-draft',
        loadChildren: () =>
          import(
            './funds/components/create-edit-draft-fund/create-edit-draft-fund.module'
            ).then((m) => m.CreateDraftFundModule),
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Funds},
      },
      {
        path: 'funds/:id/edit',
        loadChildren: () =>
          import(
            './funds/components/create-edit-draft-fund/create-edit-draft-fund.module'
            ).then((m) => m.CreateDraftFundModule),
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Funds},
      },
      {
        path: 'assets',
        component: AssetsComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets},
      },
      {
        path: 'assets/create',
        component: CreateAssetComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets},
        canDeactivate: [PendingChangesGuard],
        children: createAssetWizardSteps,
      },
      {
        path: 'assets/create-draft',
        loadChildren: () =>
          import(
            './assets/components/create-edit-draft-asset/create-edit-draft-asset.module'
            ).then((m) => m.CreateDraftAssetModule),
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets},
      },
      {
        path: 'assets/:id/edit',
        loadChildren: () =>
          import(
            './assets/components/create-edit-draft-asset/create-edit-draft-asset.module'
            ).then((m) => m.CreateDraftAssetModule),
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets},
      },
      {
        path: 'assets/:id/start-fundraising',
        component: GpAssetLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets},
        children: [
          {
            path: '',
            component: StartInitialFundraisingComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'fundraising-information',
              },
              {
                path: 'fundraising-information',
                component: StartFundraisingInformationStepComponent,
              },
              {
                path: 'investment-details',
                component: StartFundraisingInvestmentStepComponent,
              },
            ],
          },
        ],
      },
      // These 4 routes are outside of the assets/:id because we need access to the fundraisingid from the assetLayout,
      // because that is the level we trigger the polling that uses it
      {
        path: 'assets/:id/contributions/create',
        component: GpAssetLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets},
        children: [
          {
            path: '',
            component: CreateAssetFundraisingComponent,
          },
        ],
      },
      {
        path: 'assets/:id/contributions/import',
        component: GpAssetLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets},
        children: [
          {
            path: '',
            component: ImportContributionsComponent,
          },
        ],
      },
      {
        path: 'assets/:id/contributions/:fundraisingid',
        component: GpAssetLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets},
        children: [
          {
            path: '',
            component: GpAssetFundraisingComponent,
          },
        ],
      },
      {
        path: 'assets/:id',
        component: GpAssetLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets},
        children: [
          {
            path: 'documents/upload',
            component: UploadDocumentsComponent,
            canDeactivate: [CanDeactivateNavigationIfHasChanges]
          },
          {
            path: 'distributions/create',
            component: CreateEditDistributionComponent,
            canActivate: [PermissionGuard],
            data: {requiredSectionsAccess: SectionsAccess.Funds, minimumFinancialPermissionLevel: FinancialPermissionLevel.NonSignatory}
          },
          {
            path: 'distributions/import',
            component: ImportDistributionsComponent,
          },
          {
            path: 'investors-management',
            loadChildren: () =>
              import('./shared/holding/Investors-management/investors-management.module').then(
                (m) => m.InvestorsManagementModule
              )
          },
          {
            path: '',
            component: GpAssetComponent,
            pathMatch: 'full',
            // In case the asset is under management, and no specific tab is provided,
            // this parameter will tell the componet to navigate to the default tab.
            data: {
              isDefaultTab: true,
            },
          },
          {
            path: '',
            component: GpAssetComponent,
            children: assetTabRoutes,
          },
          {
            path: 'reports',
            children: assetReportChildRoutes,
          },
        ],
      },
      // This route is outside of the funds/:id because we need access to the distributionId from the fundLayout,
      // because that is the level we trigger the polling that uses it
      {
        path: 'assets/:id/distributions/:distributionId',
        component: GpAssetLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets},
        children: [
          {
            path: '',
            component: DistributionComponent,
          },
        ],
      },
      {
        path: 'assets/:id/distributions/:distributionId/edit',
        component: GpAssetLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Assets, minimumFinancialPermissionLevel: FinancialPermissionLevel.NonSignatory},
        children: [
          {
            path: '',
            component: CreateEditDistributionComponent,
          },
        ],
      },
      // These 3 routes are outside of the funds/:id because we need access to the fundraisingid from the fundLayout,
      // because that is the level we trigger the polling that uses it
      {
        path: 'funds/:id/contributions/create',
        component: GpFundLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Funds},
        children: [
          {
            path: '',
            component: CreateFundFundraisingComponent,
          },
        ],
      },
      {
        path: 'funds/:id/contributions/import',
        component: GpFundLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Funds},
        children: [
          {
            path: '',
            component: ImportContributionsComponent,
          },
        ],
      },
      {
        path: 'funds/:id/contributions/:fundraisingid',
        component: GpFundLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Funds},
        children: [
          {
            path: '',
            component: GpFundFundraisingComponent,
          },
        ],
      },
      {
        path: 'funds/:id',
        component: GpFundLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Funds},
        children: [
          {
            path: 'documents/upload',
            component: UploadDocumentsComponent,
            canDeactivate: [CanDeactivateNavigationIfHasChanges]
          },
          {
            path: 'distributions/create',
            component: CreateEditDistributionComponent,
            canActivate: [PermissionGuard],
            data: {requiredSectionsAccess: SectionsAccess.Funds, minimumFinancialPermissionLevel: FinancialPermissionLevel.NonSignatory}
          },
          {
            path: 'distributions/import',
            component: ImportDistributionsComponent,
          },
          {
            path: 'investors-management',
            loadChildren: () =>
              import('./shared/holding/Investors-management/investors-management.module').then(
                (m) => m.InvestorsManagementModule
              )
          },
          {
            path: '',
            component: GpFundComponent,
            pathMatch: 'full',
            // In case the asset is under management, and no specific tab is provided,
            // this parameter will tell the componet to navigate to the default tab.
            data: {
              isDefaultTab: true,
            },
          },
          {
            path: 'portfolio/create',
            component: CreateFundAssetComponent,
            children: createFundAssetWizardSteps,
          },
          {
            path: '',
            component: GpFundComponent,
            children: fundTabRoutes,
          },
          {
            path: 'reports',
            children: fundReportChildRoutes,
          },
        ],
      },
      // This route is outside of the funds/:id because we need access to the distributionId from the fundLayout,
      // because that is the level we trigger the polling that uses it
      {
        path: 'funds/:id/distributions/:distributionId',
        component: GpFundLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Funds},
        children: [
          {
            path: '',
            component: DistributionComponent,
          },
        ],
      },
      {
        path: 'funds/:id/distributions/:distributionId/edit',
        component: GpFundLayoutComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Funds, minimumFinancialPermissionLevel: FinancialPermissionLevel.NonSignatory},
        children: [
          {
            path: '',
            component: CreateEditDistributionComponent,
          },
        ],
      },
      {
        path: 'contacts',
        component: ContactsComponent,
        canActivate: [PermissionGuard],
        data: {requiredSectionsAccess: SectionsAccess.Contacts},
        children: [
          {
            path: 'investors',
            component: InvestorsContactsComponent,
          },
          {
            path: 'recipients',
            component: RecipientsContactsComponent,
            data: {featureFlag: FeatureFlags.CreBanking},
            canActivate: [FeatureFlagGuard],
          },
          {
            path: 'emailtracking',
            component: ContactsEmailTrackingComponent,
            data: {featureFlag: FeatureFlags.EmailTracking},
            canActivate: [FeatureFlagGuard],
          },
          {
            path: '',
            redirectTo: 'investors',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'bank-accounts',
        loadChildren: () =>
          import('./bankAccounts/bank-accounts.module').then(
            (m) => m.BankAccountsModule
          ),
        data: {helpSourceType: HelpSourceType.GpBanking},
        // component: BankAccountsComponent
      },

      {
        path: 'positions',
        loadChildren: () =>
          import('./positions/positions.module').then((m) => m.PositionsModule),
      },
      {
        path: 'insights',
        loadChildren: () =>
          import('./insights/insights.module').then((m) => m.InsightsModule),
      },
      {path: '**', redirectTo: 'assets'},
    ],
  },
]);

@NgModule({
  declarations: [
    OnboardingComponent,
    DashboardComponent,
    AssetsComponent,
    AssetDisplayTableComponent,
    GpAssetComponent,
    CreateAssetComponent,
    CreateFundAssetComponent,
    EditBankAccountComponent,
    ViewBankAccountComponent,
    AssetInvestorComponent,
    InvestmentStatusVisualComponent,
    ShareAssetComponent,
    AssetDownloadTransferConfirmationBlockComponent,
    FundDownloadTransferConfirmationBlockComponent,
    AssetSendMarketingDialogComponent,
    FundSendMarketingDialogComponent,
    SendPaymentRequestDialogComponent,
    AssetSignAgreementBlockComponent,
    FundSignAgreementBlockComponent,
    PaymentRequestStatusBlockComponent,
    DeleteBankAccountComponent,
    UpdateHoldingStatusDialogComponent,
    EditAssetDialogComponent,
    FindContactInListDialogComponent,
    PartialEditAssetDialogComponent,
    AssetDraftStatusComponent,
    FundDraftStatusComponent,
    AssetFundraisingStatusComponent,
    AssetManagementStatusComponent,
    AssetInvestorsOverviewComponent,
    FundInvestorsOverviewComponent,
    AssetReportsComponent,
    FundReportsComponent,
    AssetReportComponent,
    GpFundReportComponent,
    AssetReportInformationComponent,
    FundReportInformationComponent,
    AssetReportCumulativeInfoComponent,
    FundReportCumulativeInfoComponent,
    GpAssetLayoutComponent,
    AssetReportReviewComponent,
    FundReportReviewComponent,
    AssetFundraisingsComponent,
    FundFundraisingsComponent,
    GpAssetFundraisingComponent,
    GpFundFundraisingComponent,
    CreateAssetFundraisingComponent,
    CreateFundFundraisingComponent,
    EditFundraisingDialogComponent,
    PartialEditAssetFundraisingDialogComponent,
    PartialEditFundFundraisingDialogComponent,
    AssetReportFaqsComponent,
    FundReportFaqsComponent,
    CreateEditFaqDialogComponent,
    AgreementSelectorDialogComponent,
    SignAgreementTemplatesComponent,
    AssetInvestorReactionsToOfferingDeckComponent,
    FundInvestorReactionsToOfferingDeckComponent,
    AgreementPersonalMessageDialogComponent,
    // Create asset - steps
    CreateAssetInformationStepComponent,
    CreateFundAssetInformationStepComponent,
    CreateAssetInvestmentDetailsStepComponent,
    CreateAssetMarketingMaterialStepComponent,
    CreateFundAssetMarketingMaterialStepComponent,
    CreateAssetBankAccountStepComponent,

    EditAssetTabAssetInformationComponent,
    EditAssetTabInvestmentDetailsComponent,
    EditAssetTabMarketingMaterialComponent,
    EditAssetTabBankAccountComponent,
    EditAssetTabMoreSettingsComponent,
    AgreementPersonalMessageDialogComponent,
    DistributionsTabComponent,
    CreateEditDistributionComponent,
    DistributionImportDialogComponent,
    DistributionComponent,
    FundAssetStatusComponent,
    DistributionTransferDetailsComponent,
    AssetInvestorsListComponent,
    SendNoticeConfirmationComponent,
    SendPaymentConfirmationComponent,
    DepositDetailsDialogComponent,
    DocumentsTabComponent,
    DocumentDisplayTableComponent,
    UploadDocumentsComponent,
    RenameStorageObjectDialogComponent,
    CreateFolderDialogComponent,
    FolderMenuPanelComponent,
    ShareDocumentsDialogComponent,
    SetDocumentsPermissionsDialogComponent,
    SelectContactInvestingEntityComponent,
    ConfirmInvestorsNotificationDialogComponent,
    FundsComponent,
    FundDisplayTableComponent,
    CreateFundComponent,
    CreateFundInformationStepComponent,
    CreateFundInvestmentDetailsStepComponent,
    CreateFundMarketingMaterialStepComponent,
    CreateFundBankAccountStepComponent,
    GpFundLayoutComponent,
    GpFundComponent,
    FundFundraisingStatusComponent,
    FundManagementStatusComponent,
    FundInvestorsListComponent,
    FundInvestorComponent,
    FundInvestmentStatusVisualComponent,
    ShareFundComponent,
    EditFundDialogComponent,
    EditFundTabFundInformationComponent,
    EditFundTabBankAccountComponent,
    EditFundTabInvestmentDetailsComponent,
    EditFundTabMarketingMaterialComponent,
    EditFundTabMoreSettingsComponent,
    FundPortfolioTabComponent,
    FundAssetStatusComponent,
    PartialEditFundDialogComponent,
    FundPremiumComponent,
    EditFundAssetDialogComponent,
    EditFundAssetTabAssetInformationComponent,
    EditFundAssetTabMoreSettingsComponent,
    StartInitialFundraisingComponent,
    StartFundraisingInformationStepComponent,
    StartFundraisingInvestmentStepComponent,
    CreateFundAssetInvestmentDetailsStepComponent,
    EditFundAssetTabInvestmentDetailsComponent,
    ImportDistributionsComponent,
    DistributionDetailsComponent,
    ImportDistributionsDialogComponent,
    FundraisingGetStartedComponent,
    InvestorsListComponent,
    AIRankingDialogComponent,
    AIRankingDisplayTableComponent,
    LoaderAiComponent,
    ImportContributionsComponent,
    ContributionDetailsComponent,
    ImportContributionsDialogComponent,
    ImportContributionsDialogComponent,
    BulkOfferingDeckComponent,
    UploadOfferingDeckDialogComponent,
    SendOfferingDeckDialogComponent,
    InvestorEngagementDialogComponent,
    EngagementInfoComponent,
    EngagementDisplayTableComponent,
    SendAllDialogComponent,
    SendConfirmationDialogComponent,
    DistributionTransferAmountsComponent,
    DistributionMultiTypeComponent,
    DistributionProRataComponent,
    AddInvestorsToCompletedFundraisingComponent,
    UnderManagementInformationComponent,
    HoldingInfoSummaryComponent,
    HoldingCreBankAccountComponent,
    HoldingCreBankAccountPlaceholderComponent,
    CreBankAccountSectionComponent,
    DistributionTotalAmountsComponent,
    AddInvestorsOutOfDistributionComponent,
    InvestorsOutOfDistributionDialog,
    TransactionsComponent,
    OwnershipTransferDialogComponent,
    FundraisingDoughnutChartComponent,
    FundraisingDoughnutCardComponent,
    CapitalCallDetailsComponent,
    CapitalCallDetailsDialogComponent,
    CapitalCallConfirmDialogComponent,
    CapitalCallComponent,
    CapitalCallInvestmentsTableComponent,
    UploadFileDialogComponent,
    CapitalCallTrackComponent,
    CapitalCallBoxTitleComponent,
    FundraisingInvestorsStatisticsCardComponent,
    FundraisingInvestorsStatisticsChartComponent,
    FundraisingNavComponent,
    ParticipatingFundDialogComponent,
    HoldingSendPaymentRequestBlockComponent,
    PaymentRequestDocumentBlockComponent,
    HoldingActionsComponent,
    BankAccountSelectionComponent,
    HoldingSendPaymentRequestBlockComponent,
    DistributionWaterfallsComponent,
    BankAccountSelectionComponent,
    InvestorCommunicationDialogComponent,
    InvestorCommunicationResultSnackbarComponent,
    AddBankAccountDialogComponent,
    HoldingUploadSignedAgreementBlockComponentComponent,

    //
    PromoDialogComponent,
    StepLabelComponent,
    PrepareComponent,
    UploadComponent,
    MatchComponent,
    ShareComponent,
    DiscardUploadComponent,
    UploadingStatusComponent,
    FilesLimitComponent,
    FilePreviewComponent,
    CreateFolderComponent,
    MarketingDocumentUploaderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    CovercySharedComponentsModule,
    GpSharedModule,
    routes,
    LpModule,
    ContactsModule,
    NgApexchartsModule,
    AccreditationModule,
    GoogleMapSearchModule,
    RaisedAmountWarningComponent,
    BreadcrumbComponent,


    // Standalone
    RentManagerUploaderComponent,
    UploaderBlockComponent,
  ],
  providers: [
    PendingChangesGuard,
    CanDeactivateNavigationIfHasChanges,
    AuthorizedGpGuard,
    MaintenanceGuard,
    ContactDataService,
    GpInvestingEntityDataService,
    GpAssetDataService,
    GpHoldingDataService,
    GpFundraisingDataService,
    GpPricingTierDataService,
    GpInvestmentDataService,
    AssetReportDataService,
    FundReportDataService,
    GpAIRankingDataService,
    GpAssetService,
    GpFundService,
    InvestorsManagementService,
    CreBankAccountDataService,
    {
      provide: UnitBankAccountDataService,
      useExisting: CreBankAccountDataService,
    },
    BankApplicationDataService,
    {
      provide: UnitBankApplicationDataService,
      useExisting: BankApplicationDataService,
    },
    UnitCardDataService,
    // {provide: 'UnitCardBaseEndpoint', useValue: of('gp/unit-card')},
    {provide: USER_DETAILS, useExisting: UserService},
    {provide: APP_SETTINGS, useExisting: AppSettingsService},
  ],
})
export class DashboardModule {
}
