import {HostBinding, Directive} from '@angular/core';

import {CreateAssetStepNumber, CreateAssetService} from './create-asset.service';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {OnDestroyMixin} from '@w11k/ngx-componentdestroyed';

export interface ICreateAssetStep {
  readonly STEP_NUMBER: CreateAssetStepNumber;
}

@Directive()
export abstract class CreateAssetStepBase extends OnDestroyMixin implements ICreateAssetStep {
  @HostBinding('attr.class') hostClasses = 'wizard-step';

  // enums
  HoldingDiscriminator = HoldingDiscriminator;

  abstract STEP_NUMBER: CreateAssetStepNumber;
  readonly LAST_STEP_NUMBER = 4 as CreateAssetStepNumber;

  isSubmitAttempt = false;

  private _createAssetService: CreateAssetService;

  get selectedAreaUnit(): AreaUnit {
    try {
      return Number(this._createAssetService.createAssetForm.get('step1.areaUnits').value) as AreaUnit;
    } catch {
      return AreaUnit.SquareFeet;
    }
  }

  set selectedAreaUnit(value: AreaUnit) {
    this._createAssetService.createAssetForm.get('step1.areaUnits').setValue(value);
  }

  constructor(createAssetService: CreateAssetService) {
    super();
    this._createAssetService = createAssetService;
  }

  get pageForm() {
    return this._createAssetService.getStepForm(this.STEP_NUMBER);
  }

  get isSubmitted() {
    return this._createAssetService.isStepSubmitted(this.STEP_NUMBER);
  }

  set isSubmitted(val: boolean) {
    this._createAssetService.setStepAsSubmitted(this.STEP_NUMBER, val);
  }

  back() {
    if (this.STEP_NUMBER > 1) {
      this._createAssetService.currentStep$.next(this.STEP_NUMBER - 1 as CreateAssetStepNumber);
    }
  }

  stepSubmit() {
    this.isSubmitAttempt = true;

    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
    if (this.pageForm.valid) {
      this._createAssetService.isGeneralServerError = false;
      this.isSubmitted = true;
      // If not the last step - go to the next step
      if (this.STEP_NUMBER < this.LAST_STEP_NUMBER) {
        this._createAssetService.currentStep$.next((this.STEP_NUMBER + 1) as CreateAssetStepNumber);
      } else {
        // If submitting the last step, call the create asset function
        this._createAssetService.createAsset();
      }
    }
  }

}
