import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {HoldingStatus} from 'src/app/shared/enums/HoldingStatus.enum';
import {UpdateHoldingStatusDialogContext} from './UpdateHoldingStatusDialogContext';
import {UpdateHoldingStatusResult} from './UpdateHoldingStatusResult.model';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {GpHoldingService} from '../gp-holding.service';
import {ConfirmDialogParams} from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {DialogService} from '../../../../services/dialog.service';

@Component({
  selector: 'terra-update-holding-status-dialog',
  templateUrl: './update-holding-status-dialog.component.html',
  styleUrls: ['./update-holding-status-dialog.component.scss']
})
export class UpdateHoldingStatusDialogComponent implements OnInit {
  // Enum
  HoldingStatus = HoldingStatus;
  updatedStatus = new UntypedFormControl();

  holding = this.context.holding;

  get isAnotherStepRequried() {
    const newStatus = this.updatedStatus.value as HoldingStatus;
    const currentStatus = this.holding.status;
    if (newStatus == currentStatus) {
      return false;
    }
    const switchToUnderManagement = newStatus === HoldingStatus.Owned;
    return switchToUnderManagement && this.holding.status !== HoldingStatus.Draft;
  }

  holdingDiscriminator = HoldingDiscriminator.toString(this.holding.discriminator).toLowerCase();

  draftHoldingWithNoInitialFundraising = this.holding.status === HoldingStatus.Draft && !this.context.fundraising;

  canSwitchToUnderManagement =
    (this.holdingService.allInvestmentsAreDone(this.context.fundraising) &&
      (this.holding.status === HoldingStatus.Draft ||
        this.holding.status === HoldingStatus.Fundraising ||
        this.holding.status === HoldingStatus.Owned ||
        (this.holding.status === HoldingStatus.Canceled && this.holding.totalCapitalization !== null))) ||
    this.holding.status === HoldingStatus.Realized;

  canSwitchToRealizedStatus = (this.holding.status === HoldingStatus.Canceled || this.holding.status === HoldingStatus.Owned);
  constructor(
    private dialogService: DialogService,
    public holdingService: GpHoldingService,
    public dialogRef: MatDialogRef<UpdateHoldingStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: UpdateHoldingStatusDialogContext,
  ) {
    dialogRef.addPanelClass('update-holding-status-dialog');
  }

  ngOnInit() {
    this.updatedStatus.setValue(this.context.holding.status);
  }

  action(draftSendEmailsWhenMovingToUnderManagement = false) {
    const newStatus = this.updatedStatus.value as HoldingStatus;
    if (newStatus === HoldingStatus.Canceled){
      const confirmChange = new ConfirmDialogParams(
        'Are you sure?',
        'This action is irreversible.',
        `Cancel ${this.holdingDiscriminator}`,
        'Go Back');
      this.dialogService.confirmDialog(confirmChange).afterClosed().subscribe(res => {
        if (res){
          this.changeAssetStatus(newStatus, draftSendEmailsWhenMovingToUnderManagement);
        }
      });
    }
    else {
      this.changeAssetStatus(newStatus, draftSendEmailsWhenMovingToUnderManagement);
    }
  }

  changeAssetStatus(newStatus: HoldingStatus, draftSendEmailsWhenMovingToUnderManagement: boolean){
    const switchToUnderManagement = newStatus === HoldingStatus.Owned;
    if (switchToUnderManagement) {
      const openPartialEditDialog = this.holding.status !== HoldingStatus.Draft;
      this.dialogRef.close(new UpdateHoldingStatusResult(HoldingStatus.Owned, openPartialEditDialog, draftSendEmailsWhenMovingToUnderManagement));
    } else {
      // Return the new status only if it was changed.
      const returnValue = this.holding.status === newStatus ? null : newStatus;
      this.dialogRef.close(new UpdateHoldingStatusResult(returnValue, false));
    }
  }
}
