import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {tap, map} from 'rxjs/operators';

import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ResourceService} from 'src/app/services/resource.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {GpAssetService} from '../gp-asset.service';
import {FundraisingReqRes} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {EditAssetTabNumber} from '../../components/edit-asset-dialog/EditAssetStepBaseAndInterface';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import {UtilsService} from 'src/app/services/utils.service';
import {AssetFundraisingService} from '../asset-fundraising.service';
import {AppStateForTours, ToursService} from 'src/app/services/gp/tours.service';
import FeatureFlags from '../../../../account/my-account/model/FeatureFlags.enum';
import {UserService} from '../../../../services/shared/user.service';
import {PermissionService} from "../../../../permission/permission.service";

@Component({
  selector: 'terra-asset-fundraising-status',
  templateUrl: './asset-fundraising-status.component.html',
  styleUrls: ['./asset-fundraising-status.component.scss']
})
export class AssetFundraisingStatusComponent implements OnInit {
  // Enums (used in the template)
  HoldingStatus = HoldingStatus;
  HoldingType = HoldingType;
  InvestmentStatus = InvestmentStatus;

  // to scroll to when having investment parameter
  scrollToInvestmentId = 0;

  asset$ = this.gpAssetService.holding$;

  isAddInvestmentsPanelOpened$ = new BehaviorSubject<boolean>(false);
  fundraising$ = this.gpAssetService.fundraising$.pipe(tap(fundraising => {
    if (!fundraising.investments || !fundraising.investments.length) {
      this.isAddInvestmentsPanelOpened$.next(true);
    }
  }));

  fundraisingTargetAmount$ = this.fundraising$.pipe(map(fundraisingDetails => fundraisingDetails.fundraisingTargetAmount));
  userHasCreBanking$ = this.userService.userHasFeatureFlag(FeatureFlags.CreBanking);

  showBankInfo$ = this.permissionService.showBankInfo$;

  constructor(
    private _vcr: ViewContainerRef,
    private resourceService: ResourceService,
    private gpAssetService: GpAssetService,
    private snackbarService: SnackbarService,
    private utilsService: UtilsService,
    private toursService: ToursService,
    private userService: UserService,
    public assetFundraisingService: AssetFundraisingService,
    private permissionService: PermissionService
  ) {
  }

  ngOnInit() {
    this.toursService.setTours(AppStateForTours.FunraisingAsset);
  }

  marketingDeck(fundraisingDetails: FundraisingReqRes): MetaFileLink {
    if (!fundraisingDetails.offeringDeck) {
      return null;
    }
    return fundraisingDetails.offeringDeck;
  }

  getCountryById(countryId: number): CountryModel {
    return this.resourceService.getCountryById(countryId);
  }

  showFinishedFundraisingNotification(fundraisingDetails: FundraisingReqRes) {
    // check that the funding amount has been reached and  all investors are in status invested / declined
    const allowedStatuses = [InvestmentStatus.Invested, InvestmentStatus.Declined];

    if (this.assetFundraisingService.amountFunded(fundraisingDetails) >= fundraisingDetails.fundraisingTargetAmount) {
      const statusesOk = fundraisingDetails.investments.every(investment => !!allowedStatuses.find(s => s === investment.status));
      if (statusesOk) {
        return true;
      }
    }
    return false;
  }

  showFinishedFundraisingButActionsNeededNotification(fundraisingDetails: FundraisingReqRes) {
    // check that the funding amount has been reached and there's at least 1 investor in "invested" status
    if (this.assetFundraisingService.amountFunded(fundraisingDetails) >= fundraisingDetails.fundraisingTargetAmount) {
      const statusesOk = fundraisingDetails.investments.findIndex(investment => investment.status === InvestmentStatus.Invested) > -1;
      if (statusesOk) {
        return true;
      }
    }
    return false;
  }

  editAsset(initialTabNumber: EditAssetTabNumber = 1) {
    this.gpAssetService.showEditAsset(this._vcr, initialTabNumber);
  }

  showUpdateStatus() {
    this.gpAssetService.showUpdateStatus();
  }

  startConfettiParticle(): any {
    TerraUtils.appendExternalScript(TerraUtils.consts.EXTERNAL_SCRIPTS.ConfettiParticle).then(() => {
      const fnName = 'startConfettiParticle';
      window[fnName]();

      this.snackbarService.showGeneralMessage(`Asset funded successfully! Let's celebrate :)`, 10);
    });
  }

  getHoldingBankAccount(fundraising) {
    return this.assetFundraisingService.getHoldingBankAccount(fundraising);
  }
}
