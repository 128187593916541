import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { tap, map, skipWhile } from 'rxjs/operators';

import { GpFundReportService } from './gp-fund-report.service';
import { GpFundService } from '../../../gp-fund.service';
import { RoutingService } from 'src/app/services/routing.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';

@Component({
  selector: 'terra-gp-fund-report',
  templateUrl: './gp-fund-report.component.html',
  styleUrls: ['./gp-fund-report.component.scss'],
  providers: [GpFundReportService]
})
export class GpFundReportComponent implements OnInit {
  fund$ = this.gpFundService.holding$;

  currentStep$ = this.gpFundReportService.currentStep$;

  reportId$ = this.gpFundReportService.reportId$;

  // Whenever the currentStep Changes, the user will be redirected
  stepsNavigation$ = combineLatest([this.reportId$, this.gpFundService.holdingId$, this.currentStep$]).pipe(
    tap(([reportId, fundId, step]) => {
      // Build a different path for edit and craete mode:
      const isEditMode = reportId > 0;
      let url = isEditMode ? this.routingService.gpFundReport(fundId, reportId) : this.routingService.createFundReport(fundId);
      // Add the wizard step path:
      url = url + '/' + this.gpFundReportService.wizardStepPaths[step - 1];
      // navigate:
      this.router.navigateByUrl(url);
    })
  );

  processingSubmit$ = this.gpFundReportService.processingSubmit$;

  isShowOutlet$: Observable<boolean> = combineLatest(
    this.gpFundReportService.wizardFormReady$,
    this.gpFundService.holding$,
    this.gpFundReportService.processingSubmit$
  ).pipe(
    map(([isFormReady, fund, processingSubmit]) => {
      return isFormReady && !!fund && !processingSubmit;
    })
  );

  navigateToStep(step: number) {
    const currentStep = this.currentStep$.value;
    // navigating back shoud simply change step
    if (currentStep > step) {
      this.currentStep$.next(step);
      return;
    }
    // Navigating forward:
    const stepToValidate = step === 2 ? this.gpFundReportService.formStep1 : this.gpFundReportService.formStep2;
    if (stepToValidate.valid) {
      this.gpFundReportService.isGeneralServerError = false;
      this.currentStep$.next(step);
    }
  }

  constructor(
    private router: Router,
    private gpFundReportService: GpFundReportService,
    private gpFundService: GpFundService,
    private titleService: Title,
    public routingService: RoutingService
  ) {}

  ngOnInit() {
    this.reportId$
    .pipe(skipWhile(reportId => !reportId))
    .subscribe( reportId => {
      if (reportId > 0) {
        this.titleService.setTitle('Edit Fund Report' + TerraUtils.consts.GpPageTitleSuffix);
      } else {
        this.titleService.setTitle('Create Fund Report' + TerraUtils.consts.GpPageTitleSuffix);
      }
    });
  }
}
