<terra-dialog-header>
  <h1 class="lo_sensitive">Send offering deck to
    <ng-container *ngIf="allowInvestorName$ | async">
      {{context.shareMarketingParams.investingEntityName}} ({{ context.shareMarketingParams.investorName }})
    </ng-container>
    <ng-container *ngIf="!(allowInvestorName$ | async)">
      {{context.shareMarketingParams.investingEntityNickname}} ({{ context.shareMarketingParams.investorNickname }})
    </ng-container>
  </h1>
</terra-dialog-header>

<div class="terra-error-style">
  <mat-dialog-content>
    <form id="dialogForm" (ngSubmit)="send()" [formGroup]="pageForm" class="vertical-form" (keydown.enter)="$event.preventDefault()">
      <label class="text-input-label">Subject</label>
      <mat-form-field appearance="outline" class="input-width">
        <input matInput formControlName="subject"/>
        <mat-error *ngIf="pageForm.get('subject').hasError('required')">Required</mat-error>
      </mat-form-field>

      <!-- Message -->
      <label for="txtMessage" class="text-input-label">Add a personal message (optional):</label>
      <quill-editor id="txtMessage" formControlName="message" format="html" class="lo_sensitive message-input" placeholder="" />

      <label class="text-input-label">The following document will be attached to the offering deck:</label>
      <div class="file">
        <mat-icon class="notranslate"> insert_drive_file</mat-icon>
        <a href="{{ context.shareMarketingParams.marketingFile.url }}" target="_blank" class="lo_sensitive">
          {{ context.shareMarketingParams.marketingFile.title }}
        </a>
      </div>
      <hr />

      <!-- Error message -->
      <div *ngIf="isSubmitted">
        <p *ngIf="pageForm.invalid" class="error-message">
          Please review and correct the fields marked in red
        </p>
        <!-- <p *ngIf="isGeneralServerError" class="error-message">{{ generalServerErrorMessage }}</p> -->
      </div>
    </form>
  </mat-dialog-content>
</div>

<mat-dialog-actions>
  <button type="submit" form="dialogForm" mat-flat-button color="accent" class="page-cta">
    Send <mat-icon svgIcon="publish" class="notranslate publish-icon"></mat-icon>
  </button>
  <button type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
