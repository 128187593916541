import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import DistributionTransactionPurpose from '../../../../../../shared/enums/DistributionTransactionPurpose.enum';
import {Subject} from 'rxjs';


@Component({
  selector: 'terra-distribution-multi-type',
  templateUrl: './distribution-multi-type.component.html',
  styleUrls: ['./distribution-multi-type.component.scss']
})
export class DistributionMultiTypeComponent extends OnDestroyMixin implements OnInit {
  @Input() existingDistributionTypes: DistributionTransactionPurpose[];
  @Input() form: UntypedFormGroup;
  @Input() formArrayControlName: string;
  @Input() updateFormValidity: Subject<void>;
  @Input() disableEditMode: boolean = false;

  readonly multiTypeTypes = DistributionTransactionPurpose.listAll()
    .filter(type => type !== DistributionTransactionPurpose.MultiType &&
      type !== DistributionTransactionPurpose.Other);
  readonly maxDistributionTypes = 5;
  DistributionTransactionPurpose = DistributionTransactionPurpose;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.generateForm();
  }

  get typesFormArray(): UntypedFormArray {
    return this.form.get(this.formArrayControlName) as UntypedFormArray;
  }

  /**
   * Adds a new reason for transaction
   * @param type New type
   */
  public addType(type: DistributionTransactionPurpose = null) {
    this.typesFormArray.push(new UntypedFormControl({value: type, disabled: this.disableEditMode}, [Validators.required]));
  }

  /**
   * Delete existing reason for transaction
   * @param index The index of the control at typesFormArray
   */
  public deleteType(index: number) {
    this.typesFormArray.removeAt(index);
  }

  /**
   * Get a list unused reasons for transactions
   * @param typeIndex The index of the control from typesFormArray
   */
  public getAvailableTypes(typeIndex: number): DistributionTransactionPurpose[] {
    const usedTypes = this.typesFormArray.getRawValue().filter((val, index) => index !== typeIndex);
    return this.multiTypeTypes.filter(type => !usedTypes.includes(type));
  }


  /**
   * Generating an empty form
   */
  private generateForm() {
    if (!this.existingDistributionTypes || this.existingDistributionTypes.length < 2) {
      this.typesFormArray.clear();
      this.addType(DistributionTransactionPurpose.Principal);
      this.addType(DistributionTransactionPurpose.Interest);
    } else {
      this.existingDistributionTypes.forEach(type => {
        this.addType(type);
      });
    }
    this.updateFormValidity.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.typesFormArray.controls.forEach(c => c.markAsTouched());
      this.typesFormArray.controls.forEach(c => c.updateValueAndValidity());
    });
  }
}
