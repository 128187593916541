import {ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {distinctUntilChanged, map, shareReplay, startWith, switchMapTo, take, tap} from 'rxjs/operators';
import {Sort} from '@angular/material/sort';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import memo from 'memo-decorator';
import {Table} from 'primeng/table';
import {SortEvent} from 'primeng/api';
import {AppQuery, AppService} from 'src/app/state';
import {GpAssetService} from '../../gp-asset.service';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {AssetFundraisingService} from '../../asset-fundraising.service';
import InvestingEntityType from 'src/app/dashboard/models/InvestingEntityType.enum';
import {InvestorOverviewTableItem} from 'src/app/dashboard/shared/holding/investor-overview/InvestorOverviewTableItem.model';
import {OwnershipTransferDialogComponent, OwnershipTransferParams} from '../../../../shared/holding/fundraising/ownership-transfer-dialog/ownership-transfer-dialog.component';
import {PermissionService} from 'src/app/permission/permission.service';
import {RecipientType} from '../../../../shared/investor-communication/recipient-type';
import {ContactsGroupType} from '../../../../shared/investor-communication/contacts-group-type';
import AccountType from '../../../../../shared/enums/AccountType.enum';
import {RoutingService} from '../../../../../services/routing.service';
import HoldingDiscriminator from '../../../../../shared/enums/HoldingDiscriminator.enum';
import {CommitmentsEnabled} from '../../../../../shared/enums/CommitmentsEnabled.enum';
import {EditFundAssetTabNumber} from '../../../components/edit-fund-asset-dialog/EditFundAssetStepBaseAndInterface';
import {GpHoldingService} from '../../../../shared/holding/gp-holding.service';
import OwnershipCalculationType from '../../../../../shared/enums/OwnershipCalculationType.enum';
import PermissionLevel from 'src/app/permission/enums/permissionLevel.enum';
import {NoPermissionAction} from 'src/app/shared/directives/check-permissions.directive';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {UserService} from 'src/app/services/shared/user.service';

@Component({
  selector: 'terra-asset-investors-overview',
  templateUrl: './asset-investors-overview.component.html',
  styleUrls: ['./asset-investors-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AssetInvestorsOverviewComponent extends OnDestroyMixin implements OnInit {
  @ViewChild('pTable') pTable: Table;
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  isAllContactsVisible$ = this.permissionService.isAllContactsVisible$;

  protected readonly RecipientType = RecipientType;
  protected readonly ContactsGroupType = ContactsGroupType;
  protected readonly AccountType = AccountType;
  protected readonly Math = Math;
  private readonly ownershipFieldName = 'ownership';
  private readonly xirrFieldName = 'xirrPercentage';
  private readonly adjustedXirrFieldName = 'adjustedXirrPercentage';
  protected XirrColName = 'IRR';
  protected AdjustedXirrColName = 'Adj.IRR';

  filteringText: string;

  // consts:
  HoldingStatus = HoldingStatus;
  PermissionLevel = PermissionLevel;
  NoPermissionAction = NoPermissionAction;

  displayedColumns: string[] = ['name', this.ownershipFieldName, 'cashOnCashRatePercentage', this.xirrFieldName, 'totalContributions', 'totalDistributions'];

  isLoading$ = new BehaviorSubject(true);

  refreshInvestors$ = new Subject();

  investorsOverview$ = this.refreshInvestors$.pipe(
    untilComponentDestroyed(this),
    startWith(''),
    switchMapTo(this.assetFundraisingService.getInvestorsOverview()),
    shareReplay(1)
  );

  InvestingEntityType = InvestingEntityType;

  asset$ = this.gpAssetService.holding$;

  importContributionRoute$ = this.gpAssetService.importContributionRoute$;

  commitmentsUiState$ = this.appQuery.gpUiPrefs.pipe(
    map((uiState) => uiState.commitments)
  );

  commitmentsEnabled$ = this.asset$.pipe(
    map(h => h.commitmentsEnabled === CommitmentsEnabled.Yes),
    shareReplay(1)
  );

  showCommitmentsColumns$ = combineLatest([this.commitmentsEnabled$, this.commitmentsUiState$])
    .pipe(distinctUntilChanged(), untilComponentDestroyed(this), take(1),
      map(([commitmentsEnabled, commitmentUiState]) => commitmentsEnabled && !commitmentUiState.hideCommitmentsColumns)
    );

  cols$ = combineLatest([this.investorsOverview$, this.showCommitmentsColumns$]).pipe(
    map(([io, showCommitmentsColumns]) => {
      return [
        {
          displayName: 'Name',
          name: 'name',
          type: String,
          shouldSort: false,
          getClass: () => ({'name-column': true})
        },
        {
          displayName: 'Ownership (%)',
          name: this.ownershipFieldName,
          type: 'percent',
          shouldSort: true,
        },
        {
          displayName: 'CoC',
          name: 'cashOnCashRatePercentage',
          type: 'percent',
          shouldSort: true,
        },
        //  investingEntity?.id == 0 is GP promote
        {
          displayName: io.investorOverviews.some(o => o.xirrPercentage <= 0 && o.investingEntity?.id) ? this.AdjustedXirrColName : this.XirrColName,
          name: this.xirrFieldName,
          type: 'percentIrr',
          shouldSort: true,
        },
        ...(showCommitmentsColumns ? [{
          displayName: 'Committed',
          name: 'totalCommitments',
          type: 'currency',
          shouldSort: true
        }] : []),
        {
          displayName: 'Contributed',
          name: 'totalContributions',
          type: 'contribution',
          shouldSort: true,
        },
        ...(showCommitmentsColumns ? [{
          displayName: 'Uncalled',
          name: 'uncalled',
          type: 'currency-always',
          shouldSort: true
        }] : []),
        {
          displayName: 'Distributed',
          name: 'totalDistributions',
          type: String,
          shouldSort: true,
        },
      ];
    })
  );

  investorsManagementRoute$ = this.asset$.pipe(
    map(a => {
      return this.routingService.investorsManagement(a.id, HoldingDiscriminator.Asset);
    }),
    shareReplay(1)
  );

  investorsOverviewModel$ = combineLatest([this.investorsOverview$, this.allowInvestorName$, this.isAllContactsVisible$]).pipe(
    untilComponentDestroyed(this),
    map(([response, allowInvestorName, isAllContactsVisible]) => {
      const items = response.investorOverviews.map<InvestorOverviewTableItem>(item => {
        const tableItem = new InvestorOverviewTableItem();
        tableItem.isGP = item.investingEntity.contactId === null;
        tableItem.name = allowInvestorName ? item.investingEntity.name : `Entity ${item.investingEntity.nickname}`;
        tableItem.ieName = !allowInvestorName || isAllContactsVisible ? `` : `(Entity ${item.investingEntity.nickname})`;
        tableItem.totalContributions = item.totalContributionAmount;
        tableItem.totalCommitments = item.totalCommitmentAmount;
        tableItem.totalPendingContributions = item.pendingContributionAmount;
        tableItem.totalDistributions = item.totalDistributionAmount;
        tableItem.cashOnCashRatePercentage = item.cashOnCashRatePercentage;
        tableItem.xirrPercentage = item.xirrPercentage;
        tableItem.adjustedXirrPercentage = item.adjustedXirrPercentage;
        tableItem.type = item.investingEntity.investingEntityType;
        tableItem.ownership = item.ownershipPercent;
        const rawUncalled = item.totalCommitmentAmount - item.totalContributionAmount - item.pendingContributionAmount;
        tableItem.uncalled = rawUncalled > 0 ? rawUncalled : 0;
        return tableItem;
      });

      return items;
    }),
    map(tableItems => {
      const gpRowIndex = tableItems.findIndex(c => c.isGP);
      if (gpRowIndex > 0) {
        const gpRow = tableItems.splice(gpRowIndex, 1)[0];
        tableItems = [gpRow, ...tableItems];
      }
      return tableItems;
    }),
    tap(_ => this.isLoading$.next(false)),
    shareReplay(1)
  );

  totalAmounts$ = this.investorsOverview$.pipe(
    map(investorsOverview => investorsOverview.grandTotal),
    shareReplay(1)
  );

  currency$ = this.gpAssetService.holding$.pipe(map(holding => holding.initialCurrency));

  totalInvestorEquity = 0;
  totalInvestorEquity$ = this.gpAssetService.holding$.pipe(
    tap(h => this.totalInvestorEquity = h.totalInvestorEquity),
    map(holding => holding.totalInvestorEquity));

  searchOptions$ = this.assetFundraisingService.searchOptionsOverview$;

  adjustedXirrTooltip = 'The Adjusted IRR represents the annualized ROI based on the current cash flows, even if the full principal amount has not yet been returned. It reflects both the received returns and the remaining investment balance.';

  overviewTooltip$ = this.asset$.pipe(
    map(h => {
      const method = OwnershipCalculationType.toString(h.ownershipCalculationType)?.toLowerCase();
      return `Calculated by '${method}'. Manage calculation method in Edit ${HoldingDiscriminator.toString(h.discriminator)}`;
    })
  );

  constructor(
    private gpAssetService: GpAssetService,
    private gpHoldingService: GpHoldingService,
    private assetFundraisingService: AssetFundraisingService,
    private appService: AppService,
    private permissionService: PermissionService,
    private readonly appQuery: AppQuery,
    private dialog: MatDialog,
    private vcr: ViewContainerRef,
    private routingService: RoutingService,
    private telemetryService: TelemetryService,
    private userService: UserService,
  ) {
    super();
  }

  ngOnInit() {
  }

  tableSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let result = null;
      let value1 = data1[event.field];
      let value2 = data2[event.field];

      switch (event.field) {
        case this.ownershipFieldName:
          if (this.totalInvestorEquity > 0) {
            value1 = +this.getInvestorEquityPercentage(data1.totalContributions, this.totalInvestorEquity);
            value2 = +this.getInvestorEquityPercentage(data2.totalContributions, this.totalInvestorEquity);
          }
          break;
        case this.xirrFieldName:
          value1 = (data1[event.field] > 0 ? data1[event.field] : data1[this.adjustedXirrFieldName]) || 0;
          value2 = (data2[event.field] > 0 ? data2[event.field] : data2[this.adjustedXirrFieldName]) || 0;
          break;
        default:
          break;
      }

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = value1 - value2;
      }

      return event.order * result;
    });
  }

  clearSearch(searchInput: HTMLInputElement = null) {
    if (searchInput) {
      searchInput.value = null;
    }
    this.pTable.filterGlobal(null, '');
  }

  filterChanged(value: any, matchMode: string) {
    if (!value) {
      this.clearSearch();
    } else if (value) {
      this.pTable.filterGlobal(value, matchMode);
    }
  }

  onRowSelect(event) {
    console.log(event);
  }

  @memo({resolver: (...args: number[]) => args[0] + '_' + args[1]})
  getInvestorEquityPercentage(totalContributions: number, totalInvestorEquity: number) {
    const result = 100 * (totalContributions / totalInvestorEquity);
    return result < 100 && result > 99.99 ? 99.99 : result.toFixed(2);
  }

  @memo({resolver: (...args: number[]) => args[0] + '_' + args[1]})
  getUnallocatedPercentage(totalContributions: number, totalInvestorEquity: number) {
    let result = 100 * (totalContributions / totalInvestorEquity);
    result = result <= 100 ? 100 - result : 0;
    return result < 0.01 && result > 0 ? 0.01 : result.toFixed(2);
  }

  @memo({resolver: (...args: number[]) => args[0] + '_' + args[1]})
  getUnallocatedContributions(totalContributions: number, totalInvestorEquity: number) {
    const result = totalInvestorEquity - totalContributions;
    return result > 0 && result.toFixed(2) || 0;
  }

  sortData(sort: Sort) {
    if (!sort.active || !sort.direction) {
      return;
    }

    this.appService.updateAssetInvestorOverviewSortState({
      orderBy: sort.active,
      direction: sort.direction === 'desc' ? 'desc' : 'asc'
    });
  }

  openOwnershipTransferDialog() {
    combineLatest([this.asset$, this.investorsOverview$])
      .pipe(
        take(1)
      ).subscribe(([asset, investorsOverview]) => {
      const dialogConfig = new MatDialogConfig<OwnershipTransferParams>();
      dialogConfig.viewContainerRef = this.vcr;
      dialogConfig.disableClose = true;
      dialogConfig.closeOnNavigation = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        holding: asset,
        investorsOverview: investorsOverview.investorOverviews
      } as OwnershipTransferParams;

      this.dialog.open(OwnershipTransferDialogComponent, dialogConfig)
        .afterClosed().pipe(untilComponentDestroyed(this))
        .subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.refreshInvestors$.next(undefined);
          }
        });
    });
  }


  editHolding(initialTab: EditFundAssetTabNumber = 2) {
    this.gpHoldingService.showEditHolding(this.vcr, initialTab, 'capitalCommitments');
  }

  showCommitmentWarning(rowData: InvestorOverviewTableItem, colName: string) {
    if (!!!rowData || colName !== 'totalCommitments') {
      return false;
    }

    return rowData.totalContributions > rowData.totalCommitments;
  }

  sendManageCommitmentTelemetry(inline: boolean) {
    combineLatest([this.userService.accountDetails$, this.asset$])
      .pipe(take(1)).subscribe(([clientDetails, asset]) => {
      this.telemetryService.create({
        eventID: '700',
        eventTitle: 'HOLDING COMMITMENT MANAGE CLICKED',
        holdingID: asset.id,
        organizationID: clientDetails.organizationDetails.id,
        button: inline ? 'Inline-button' : 'Main-button'
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });
  }
}
