import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { tap, map, skipWhile } from 'rxjs/operators';

import { GpAssetReportService } from './gp-asset-report.service';
import { GpAssetService } from '../../../gp-asset.service';
import { RoutingService } from 'src/app/services/routing.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';

@Component({
  selector: 'terra-gp-asset-report',
  templateUrl: './gp-asset-report.component.html',
  styleUrls: ['./gp-asset-report.component.scss'],
  providers: [GpAssetReportService]
})
export class AssetReportComponent implements OnInit {
  asset$ = this.gpAssetService.holding$;

  currentStep$ = this.gpAssetReportService.currentStep$;

  reportId$ = this.gpAssetReportService.reportId$;

  // Whenever the currentStep Changes, the user will be redirected
  stepsNavigation$ = combineLatest([this.reportId$, this.gpAssetService.holdingId$, this.currentStep$]).pipe(
    tap(([reportId, assetId, step]) => {
      // Build a different path for edit and craete mode:
      const isEditMode = reportId > 0;
      let url = isEditMode ? this.routingService.gpAssetReport(assetId, reportId) : this.routingService.createAssetReport(assetId);
      // Add the wizard step path:
      url = url + '/' + this.gpAssetReportService.wizardStepPaths[step - 1];
      // navigate:
      this.router.navigateByUrl(url);
    })
  );

  processingSubmit$ = this.gpAssetReportService.processingSubmit$;

  isShowOutlet$: Observable<boolean> = combineLatest(
    this.gpAssetReportService.wizardFormReady$,
    this.gpAssetService.holding$,
    this.gpAssetReportService.processingSubmit$
  ).pipe(
    map(([isFormReady, assetDetails, processingSubmit]) => {
      return isFormReady && !!assetDetails && !processingSubmit;
    })
  );

  navigateToStep(step: number) {
    const currentStep = this.currentStep$.value;
    // navigating back shoud simply change step
    if (currentStep > step) {
      this.currentStep$.next(step);
      return;
    }
    // Navigating forward:
    const stepToValidate = step === 2 ? this.gpAssetReportService.formStep1 : this.gpAssetReportService.formStep2;
    if (stepToValidate.valid) {
      this.gpAssetReportService.isGeneralServerError = false;
      this.currentStep$.next(step);
    }
  }

  constructor(
    private router: Router,
    private gpAssetReportService: GpAssetReportService,
    private gpAssetService: GpAssetService,
    private titleService: Title,
    public routingService: RoutingService
  ) { }

  ngOnInit() {
    this.reportId$
      .pipe(skipWhile(reportId => !reportId))
      .subscribe(reportId => {
        if (reportId > 0) {
          this.titleService.setTitle('Edit Asset Report' + TerraUtils.consts.GpPageTitleSuffix);
        } else {
          this.titleService.setTitle('Create Asset Report' + TerraUtils.consts.GpPageTitleSuffix);
        }
      });
  }
}
