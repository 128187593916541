import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { AppSettingsService, SubdomainEnum } from '../services/app-settings.service';
import { DialogService } from '../services/dialog.service';
import { RoutingService } from '../services/routing.service';
import { UserService } from '../services/shared/user.service';
import { StorageService } from '../services/storage.service';
import { TerraUtils } from '../shared/TerraUtils';
import { AlertDialogParams } from '../shared/components/alert-dialog/alert-dialog.component';
import HTTP_STATUS_CODES from '../shared/enums/HttpStatusCodesEnum';
import { ErrorMatcher, ErrorType } from '../shared/errors/ErrorMatcher';
import { LoggerService } from '../shared/errors/logger.service';
import { BaseResponseDto } from '../shared/models/BaseResponseDto.model';

@Injectable()
export class TokenRefreshInterceptor implements HttpInterceptor {
  private AUTH_API_BASE_URL = this.appSettings.authUrl;
  private REFRESH_TOKEN_GRANT_TYPE = 'refresh_token';
  private TOKEN_ENDPOINT = 'token';

  private get COVERCY_CLIENT_ID(): string {
    return this.appSettings.subDomain === SubdomainEnum.GP ? 'covercyNgGpApp' : 'covercyNgLpApp';
  }

  private refreshTokenInProgress = false;
  private refreshTokenRequestCount = 0;

  /* Refresh token subject track the current token, or is null if no token is currently available (e.g. refresh pending).  */
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private routingService: RoutingService,
    private userService: UserService,
    private appSettings: AppSettingsService,
    private dialogService: DialogService,
    private storageService: StorageService,
    private logger: LoggerService,
    private dialog: MatDialog
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.userService && this.userService.isTryingToAccessOtherUsersData()) {
      window.location.reload();
      return;
    }

    // Let all http requests pass through, and only handle errors:
    return next.handle(req).pipe(
      catchError(error => {
          if (error instanceof HttpErrorResponse) {
            if ((ErrorMatcher.isError(this.updateError(error), ErrorType.SuspendedAccountException))) {
              return this.handleSuspended(error);
            } else if (error.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
              return this.handle401Error(req, next, error);
            } else if (error.status === HTTP_STATUS_CODES.PRECONDITION_FAILED) {
              return this.handle412Error(error);
            } else if (error.status === HTTP_STATUS_CODES.SERVICE_UNAVAILABLE) {
              return this.handle503Error(error);
            } else if (error.status === HTTP_STATUS_CODES.CONFLICT) {
              return this.handle409Error(error);
            } else if (error.status === HTTP_STATUS_CODES.FORBIDDEN) {
              return this.handle403Error(error);
            }

            error['originalRequest'] = req;
            return throwError(error);
          } else {
            this.storageService.removeAuthorizationData();
            this.routingService.navigateToLoginWithQuery();
            error.originalRequest = req;
            return throwError(error);
          }
        }
      )
    );
  }

  private handleSuspended(error: any) {
    setTimeout(() => {
      this.storageService.removeAuthorizationData();
      this.routingService.navigateToLoginNoQuery();
    }, 300); // Hack to get this routing to work...

    return throwError(error);
  }

  private updateError(error: any): HttpErrorResponse {
    let updateError: HttpErrorResponse = error;
    try {
      updateError = {...error, error: JSON.parse(error.error.message)};
    } catch {
    }
    return updateError;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: any): Observable<any> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.refreshToken(error).pipe(
        switchMap((token: any) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(true);
          return next.handle(this.cloneRequest(request));
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(refreshed => refreshed === true),
        take(1),
        switchMap(jwt => {
          return next.handle(this.cloneRequest(request));
        }));
    }
  }

  private handle412Error(error: any) {
    if (error.error && error.error.message === '2fa not verified') {
      setTimeout(() => {
        const twoFactorUrl = TerraUtils.consts.paths.TWO_FACTOR_VERIFICATION;
        const returnUrl = location.pathname + location.search;
        if (returnUrl.indexOf(twoFactorUrl) === 0) {
          return throwError(error);
        }
        if (returnUrl) {
          this.routingService.setReturnUrlInCookie(returnUrl);
        }
        this.router.navigateByUrl(twoFactorUrl);
      }, 100); // Hack to get this routing to work...
    }
    return throwError(error);
  }

  private handle403Error(error: any) {
    if (!!error.error.responseStatus && !!error.error.responseMessage) {
      const missingPermissionResponse = new BaseResponseDto(error.error.responseStatus, error.error.responseMessage);
      return throwError(missingPermissionResponse);
    }

    return throwError(error);
  }

  private handle503Error(error: any) {
    setTimeout(() => this.router.navigateByUrl(TerraUtils.consts.paths.MAINTENANCE), 100); // Hack to get this routing to work...
    return throwError(error);
  }

  private handle409Error(error: any) {
    this.router.navigateByUrl(this.routingService.defaultLoggedInPageBySubdomain);
    return throwError(error);
  }

  cloneRequest(original: HttpRequest<any>): any {
    const authData = this.storageService.getAuthorizationData();
    if (!authData) {
      return of({});
    }
    const accessToken = authData.access_token;

    if (!accessToken) {
      return of({});
    }
    const newReq = original.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return newReq;
  }

  public refreshToken(error: any): Observable<any> {
    const authDataJson = this.storageService.getAuthorizationData();
    if (!authDataJson) {
      this.refreshTokenInProgress = false;
      this.storageService.removeAuthorizationData();
      this.routingService.navigateToLoginWithQuery();
      return throwError(error);
    }

    return this.httpClient
      .request('POST', this.AUTH_API_BASE_URL + this.TOKEN_ENDPOINT, this.getRefreshTokenRequestOptions(authDataJson.refresh_token))
      .pipe(
        map(response => {
          // If the token was refreshed successfully:
          if (response.status === HTTP_STATUS_CODES.OK && response && response.body) {
            this.refreshTokenRequestCount++;
            this.storageService.setAuthorizationData(JSON.stringify(response.body));
          } else {
            // Token coudln't be refreshed
            this.storageService.removeAuthorizationData();
            this.routingService.navigateToLoginWithQuery();
          }
          return response.body;
        }),
        catchError(err => {
          this.refreshTokenInProgress = false;
          this.storageService.removeAuthorizationData();

          if (this.refreshTokenRequestCount === 0) {
            // redirect only if the anonymous until refresh flag was not set
            if (!this.userService.isAnonymousUntilNextNavigation) {
              this.routingService.navigateToLoginWithQuery(true);
            }
          } else if (this.refreshTokenRequestCount > 0) {
            // redirect only if the anonymous until refresh flag was not set
            if (!this.userService.isAnonymousUntilNextNavigation) {
              const dialogRef = this.dialogService.alertDialog(new AlertDialogParams('Session Timeout', 'For your security, Covercy sessions automatically end after 30 minutes of inactivity. Please sign in again to continue.', 'Sign in', '', 'accent-backdrop'));
              dialogRef.afterClosed().subscribe(_ => {
                this.routingService.navigateToLoginWithQuery(true);
              });
            }
          }
          return EMPTY;
        })
      );
  }

  private getRefreshTokenRequestOptions(refreshToken: string): { headers: HttpHeaders, withCredentials: boolean, body: string, observe: 'response' } {
    try {
      // Set the request headers:
      const requestHeaders = new HttpHeaders();
      requestHeaders.append('Accept', 'application/json');
      requestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      return {
        headers: requestHeaders,
        withCredentials: true,
        body: 'grant_type=' + this.REFRESH_TOKEN_GRANT_TYPE + '&refresh_token=' + refreshToken + '&client_id=' + this.COVERCY_CLIENT_ID,
        observe: 'response'
      };
    } catch (err) {
      this.logger.error('Exception in TokenRefreshInterceptor => getRefreshTokenRequestOptions()', err);
    }
  }
}
