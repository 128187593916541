<h3 class="section-title">Type of Transfers</h3>
<p class="full-width sub-title">Please choose the type for each distribution you wish to add</p>
<div [formGroup]="form" class="types-wrapper">
  <div [formArrayName]="formArrayControlName" class="cov-form-grid single-column">
    <div *ngFor="let typeControl of typesFormArray.controls; let i = index;" class="transfer-grid">
      <p class="transfer-type-title">Type of transfer</p>
      <span class="transfer-type-number">{{i + 1}}</span>
      <div class="terra-form-field-wrapper">
        <mat-form-field appearance="outline">
          <mat-select [formControl]="typeControl" placeholder="Select">
            <mat-option [value]="null">Select</mat-option>
            <mat-option *ngFor="let availableType of getAvailableTypes(i)" [value]="availableType">{{availableType | enumString: DistributionTransactionPurpose}}</mat-option>
          </mat-select>
          <mat-error *ngIf="typeControl.hasError('required')">
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <button *ngIf="typesFormArray.length > 2" [disabled]="disableEditMode" (click)="deleteType(i)" class="remove-type" mat-button>
        <mat-icon class="notranslate">highlight_off</mat-icon>
      </button>
    </div>
  </div>

  <button *ngIf="typesFormArray.length < 5" [disabled]="disableEditMode" (click)="addType()" type="button" class="add-new-transfer-type" mat-button>
    <mat-icon class="notranslate">add_circle_outline</mat-icon>
    Add Transfer Type
  </button>
</div>
