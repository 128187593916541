<h3 class="title no-underline" *ngIf="investment.paymentSettlementPlatform === paymentPlatform.External">
  This client deposited their investment outside Covercy
</h3>

<ng-container *ngIf="investment.paymentSettlementPlatform === paymentPlatform.Covercy">
  <h3 class="title lo_sensitive">
    Funds sent to {{bankAccountNickname}} successfully
  </h3>
  <button [disabled]="isLoading" (click)="transactionConfirmation()" mat-flat-button color="primary"
    class="send-payment-request-button tall-button">
    <span>Download Transfer Confirmation</span>
    <terra-loader *ngIf="isLoading"></terra-loader>
  </button>
</ng-container>
