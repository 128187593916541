import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { AnalyticsServiceNameModel, TelemetryService } from 'telemetry-library';

 
import { FundraisingReqRes } from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import { UserService } from 'src/app/services/shared/user.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { enterAnimation } from 'src/app/shared/animations';
import { AreaUnit } from 'src/app/shared/enums/AreaUnit.enum';
import { FundInvestmentStrategyType } from 'src/app/shared/enums/FundInvestmentStrategyType.enum';
import { InterestType } from 'src/app/shared/enums/InterestType.enum';
import { InvestmentStatus } from 'src/app/shared/enums/InvestmentStatus.enum';
import { LoanType } from 'src/app/shared/enums/LoanType.enum';
import { LenderInformation } from 'src/app/shared/models/LenderInformation.model';
import { TerraNumberPipe } from 'src/app/shared/pipes/TerraNumber.pipe';
import { DialogWithFormBase } from 'src/app/shared/types/DialogWithFormBase';
import { GpFundReqRes } from '../../GpFundReqRes.model';
import { GpFundDialogContext } from '../../gp-fund/Fund.context';
import { FundAndFundraisingReqRes } from '../create-fund/FundAndFundraisingRequest';
import { CustomValidators } from 'src/app/shared/validators/custom.validators';

@Component({
  selector: 'terra-partial-edit-fund-dialog',
  templateUrl: './partial-edit-fund-dialog.component.html',
  styleUrls: ['./partial-edit-fund-dialog.component.scss'],
  animations: [enterAnimation]
})
export class PartialEditFundDialogComponent extends DialogWithFormBase implements OnInit {
  pageForm: UntypedFormGroup;
  attemptSubmit = false;
  processingSubmit$ = new BehaviorSubject(false);

  selectedInvestmentStrategyType = this.context.fundDetails.investmentStrategyType as FundInvestmentStrategyType;
  // selectedAreaUnit = this.context.fundDetails.areaUnits as AreaUnit;
  selectedCurrency = this.context.fundraisingDetails.fundraisingTargetCurrency;

  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;

  // Enums
  FundInvestmentStrategyType = FundInvestmentStrategyType;
  AreaUnit = AreaUnit;
  LoanType = LoanType;
  InterestType = InterestType;

  // Enum value lists
  LoanTypesList = LoanType.listAll();

  // Enum Interest Type
  InterestTypesList = InterestType.listAll();

  closingDateStartAt = new Date();
  closingDateMinimumDate = new Date('1970-01-01T12:00');

  maturityDateStartAt = new Date();
  maturityDateMinimumDate = new Date('1970-01-01T12:00');

  constructor(
    public dialogRef: MatDialogRef<PartialEditFundDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: GpFundDialogContext,
    private fb: UntypedFormBuilder,
    private numberPipe: TerraNumberPipe,
    private telemetryService: TelemetryService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.generatePageForm(this.context);
  }

  generatePageForm(context: GpFundDialogContext) {
    const fundDetails = context.fundDetails;
    const fundraisingDetails = context.fundraisingDetails;

    this.pageForm = this.fb.group({
      finalClosingDate: [fundraisingDetails.estimatedClosingDate, [Validators.required]],
      totalCapitalization: [fundDetails.totalCapitalization, [Validators.required, CustomValidators.minFormattedNumber(0)]],
      totalInvestorEquity: [this.amountFunded(context.fundraisingDetails), [Validators.required, CustomValidators.minFormattedNumber(0)]],
      gpEquityFromTotalEquity: fundDetails.gpEquityFromTotalEquity,

      lenderInformation: this.fb.group({
        lenderName: fundDetails.lenderInformation.lenderName,
        amount: fundDetails.lenderInformation.amount,
        loanToValue: fundDetails.lenderInformation.loanToValue,
        interestRate: fundDetails.lenderInformation.interestRate,
        interestType: fundDetails.lenderInformation.interestType,
        loanType: fundDetails.lenderInformation.loanType,
        closingDate: fundDetails.lenderInformation.closingDate,
        maturityDate: fundDetails.lenderInformation.maturityDate,
        additionalTerms: fundDetails.lenderInformation.additionalTerms,
        totalFinancingPerGrossAreaUnit: fundDetails.lenderInformation.totalFinancingPerGrossAreaUnit,
        totalFinancingPerNetAreaUnit: fundDetails.lenderInformation.totalFinancingPerNetAreaUnit
      })
    });
  }

  generateSubmitModel(notifyInvestors: boolean): FundAndFundraisingReqRes {
    const formValues = this.pageForm.value;

    const fundModel = new GpFundReqRes();
    const fundraisingModel = new FundraisingReqRes();

    fundModel.id = this.context.fundDetails.id;
    fundraisingModel.id = this.context.fundraisingDetails.id;

    // Fund details
    fundraisingModel.finalClosingDate = formValues.finalClosingDate;
    fundModel.totalCapitalization = this.numberPipe.parse(formValues.totalCapitalization);
    fundModel.totalInvestorEquity = this.numberPipe.parse(formValues.totalInvestorEquity);
    fundModel.gpEquityFromTotalEquity = this.numberPipe.parse(formValues.gpEquityFromTotalEquity);

    // Lender information (optional)
    fundModel.lenderInformation = new LenderInformation();
    fundModel.lenderInformation.lenderName = formValues.lenderInformation.lenderName;
    fundModel.lenderInformation.amount = this.numberPipe.parse(formValues.lenderInformation.amount);
    fundModel.lenderInformation.loanToValue = this.numberPipe.parse(formValues.lenderInformation.loanToValue);
    fundModel.lenderInformation.interestRate = this.numberPipe.parse(formValues.lenderInformation.interestRate);
    fundModel.lenderInformation.interestType = this.numberPipe.parse(formValues.lenderInformation.interestType);
    fundModel.lenderInformation.loanType = formValues.lenderInformation.loanType;
    fundModel.lenderInformation.closingDate = formValues.lenderInformation.closingDate;
    fundModel.lenderInformation.maturityDate = formValues.lenderInformation.maturityDate;
    fundModel.lenderInformation.additionalTerms = formValues.lenderInformation.additionalTerms;
    fundModel.lenderInformation.totalFinancingPerGrossAreaUnit = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerGrossAreaUnit);
    fundModel.lenderInformation.totalFinancingPerNetAreaUnit = this.numberPipe.parse(formValues.lenderInformation.totalFinancingPerNetAreaUnit);

    return new FundAndFundraisingReqRes(fundModel, fundraisingModel, notifyInvestors);
  }

  save(notifyInvestors: boolean) {
    this.attemptSubmit = true;
    this.isGeneralServerError = false;
    if (!this.pageForm.valid) {
      return;
    }

    const model = this.generateSubmitModel(notifyInvestors);
    this.processingSubmit$.next(true);
    this.context.gpFundService
      .moveFundToUnderManagement(model)
      .pipe(
        finalize(() => this.processingSubmit$.next(false))
      )
      .subscribe(
        updatedFund => {
          this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
            this.telemetryService.create({
              eventID: '302',
              eventTitle: 'FUND MOVED TO UNDER MANAGEMENT',
              holdingID: updatedFund.fund.id,
              organizationID: clientDetails.organizationDetails.id
            }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
          });
          this.dialogRef.close(updatedFund);
        },
        error => {
          this.processingSubmit$.next(false);
          this.isGeneralServerError = true;
        }
      );
  }

  amountFunded(fundraisingDetails: FundraisingReqRes) {
    const invested = fundraisingDetails.investments.filter(x => x.status === InvestmentStatus.Invested);
    if (invested.length > 0) {
      return invested.map(x => x.finalAmount).reduce((sum, current) => sum + current);
    }
    return 0;
  }
}
