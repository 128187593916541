import {Component, OnInit, ChangeDetectionStrategy, ViewContainerRef} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {FilestackService} from '@filestack/angular';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {ClientOptions, PickerOptions, Security} from 'filestack-js';
import {PickerInstance, PickerResponse, PickerStoreOptions} from 'filestack-js/build/main/lib/picker';
import {combineLatest, Observable,} from 'rxjs';
import {filter, map, shareReplay, switchMap, take} from 'rxjs/operators';

import {AppSettingsService} from 'src/app/services/app-settings.service';
import {DialogService} from 'src/app/services/dialog.service';
import {RoutingService} from 'src/app/services/routing.service';
import {UserService} from 'src/app/services/shared/user.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {AlertDialogParams} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {TransferUsingPlaidDialogComponent, TransferUsingPlaidParams} from 'src/app/shared/components/unit/transfer-using-plaid-dialog/transfer-using-plaid-dialog.component';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {UnitBankAccountReqRes} from 'src/app/shared/models/gp/UnitBankAccountReqRes.model';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {BankAccountSelectionComponent} from '../../bank-account-selection/bank-account-selection.component';
import {DocumentsTabService} from '../../documents/documents-tab/documents-tab.service';
import {OwnershipTransferDialogComponent, OwnershipTransferParams} from '../../fundraising/ownership-transfer-dialog/ownership-transfer-dialog.component';
import {GpHoldingService} from '../../gp-holding.service';
import {GpHolding} from '../../GpHolding.model';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';
import {HoldingFundraisingService} from '../../fundraising/holding-fundraising.service';
import {FinancialPermissionLevel} from 'src/app/permission/enums/financialPermissionLevel.enum';
import PermissionLevel from 'src/app/permission/enums/permissionLevel.enum';
import { NoPermissionAction } from 'src/app/shared/directives/check-permissions.directive';
import { AssetFundraisingService } from 'src/app/dashboard/assets/gp-asset/asset-fundraising.service';
import { GpBankAccountDataService } from 'src/app/services/gp/gp-bank-account-data.service';

@Component({
  selector: 'terra-holding-actions',
  templateUrl: './holding-actions.component.html',
  styleUrls: ['./holding-actions.component.scss'],
  providers: [DocumentsTabService, AssetFundraisingService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldingActionsComponent extends OnDestroyMixin implements OnInit {

  holdingId: number;
  holding: GpHolding;

  // holdingId$ = this.gpHoldingService.holdingId$.pipe(map(data => this.holdingId = data));
  holding$ = this.gpHoldingService.holding$.pipe(
    map(data => {
      this.holding = data;
      this.holdingId = data.id;
      return data;
    }));

  creApplication$ = this.holding$.pipe(
    filter(holding => holding.unitApplications?.length > 0),
    map(holding => {
      // this.holdingId = holding.id;
      this.holding = holding;

      return holding.unitApplications[0];
    })
  );

  hasApplication$ = this.creApplication$.pipe(
    map(application => !!application)
  );

  distributionCreBanksList$ = this.holding$.pipe(
    switchMap(holding => this.bankAccountDataService.getDistributionCreBankAccounts(holding.id)),
    shareReplay(1)
  );

  // bankAccounts$: Observable<UnitBankAccountReqRes[]> = this.creApplication$.pipe(
  //   filter(application => application.bankAccounts?.length > 0),
  //   map(application => application.bankAccounts)
  // );

  investorsOverview$ = this.holdingFundraisingService.getInvestorsOverview(true)
    .pipe(
      untilComponentDestroyed(this),
      shareReplay(1)
    );

  picker: PickerInstance;

  fileStackApiKey = this.appSettingsService.fileStackApiKey;
  NoPermissionAction = NoPermissionAction;
  FinancialPermissionLevel = FinancialPermissionLevel;
  PermissionLevel = PermissionLevel;

  constructor(
    private gpHoldingService: GpHoldingService,
    private routingService: RoutingService,
    private holdingFundraisingService: HoldingFundraisingService,
    private bankAccountDataService: GpBankAccountDataService,
    private userService: UserService,
    private telemetryService: TelemetryService,
    private documentsTabService: DocumentsTabService,
    private dialogService: DialogService,
    private filestackService: FilestackService,
    private appSettingsService: AppSettingsService,
    private snackbarService: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private utilsService: UtilsService) {
    super();
  }

  ngOnInit(): void {
  }

  actionClicked(event, buttonText, actionType) {

    let dialogRef = this.dialog.open(BankAccountSelectionComponent, {
      viewContainerRef: this.viewContainerRef,
      width: '350px',
      data: {
        buttonText: buttonText
      }
    });

    let component = dialogRef.componentInstance;
    component.dataList$ = this.distributionCreBanksList$;

    dialogRef.afterClosed()
      .subscribe(dialogResult => {

        if (dialogResult && dialogResult.id) {

          switch (actionType) {
            case 'pay':
              this.goToPayment(dialogResult.id);
              break;
            case 'fund':
              this.openFundAccountDialog(false, dialogResult);
              break;
            case 'contribute':
              this.goToContribution(dialogResult);
              break;
            case 'distribute':
              this.goToDistribution(dialogResult);
              break;
            default:
              break;
          }
        }
        // this.router.navigate([this.routingService.createCreBankPage()], {queryParams:{holdingId:this.holdingId}});
      });
  }

  createBankAccount() {

    if (!!this.holding.unitApplications) {
      this.router.navigate([this.routingService.createCreBankPage()], {queryParams: {holdingId: this.holdingId}});
    } else {
      this.createApplication();
    }
  }

  private createApplication() {
    this.router.navigate([this.routingService.createBankApplicationPage()], {queryParams: {holdingId: this.holdingId}});
  }

  goToDistribution(bankAccount?: any) {

    if (bankAccount) {
      this.holding?.discriminator === HoldingDiscriminator.Fund ?
        this.router.navigate([this.routingService.createFundDistribution(this.holdingId)], {queryParams: {account: bankAccount.id}}) :
        this.router.navigate([this.routingService.createAssetDistribution(this.holdingId)], {queryParams: {account: bankAccount.id}});
    } else {
      this.holding?.discriminator === HoldingDiscriminator.Fund ?
        this.router.navigate([this.routingService.createFundDistribution(this.holdingId)]) :
        this.router.navigate([this.routingService.createAssetDistribution(this.holdingId)]);
    }
  }

  goToContribution(bankAccount?: any) {

    const options = {};
    if (bankAccount) {
      options['queryParams'] =  {account: bankAccount.id};
    }
      this.holding?.discriminator === HoldingDiscriminator.Fund ?
        this.router.navigate([this.routingService.createFundFundraising(this.holdingId)], options) :
        this.router.navigate([this.routingService.createAssetFundraising(this.holdingId)], options);
  }

  private goToPayment(bankAccountId) {
    this.router.navigate([this.routingService.createCrePayment()], {queryParams: {account: bankAccountId}});
  }

  private openFundAccountDialog(continueOAuthFlow: boolean = false, bankAccount) {
    const dialogConfig = new MatDialogConfig<TransferUsingPlaidParams>();
    dialogConfig.disableClose = true;
    dialogConfig.viewContainerRef = this.viewContainerRef;
    dialogConfig.data = {
      unitBankAccountId: bankAccount.id,
      unitBankAccountName: bankAccount.accountNickname,
      holdingId: this.holdingId,
      continueOAuthFlow,
      displayAchDebitLimits: true,
      lockAmount: false,
      title: 'Fund your account',
      amountStepButtonText: 'FUND ACCOUNT',
      paymentToken: undefined,
      amount: undefined,
      paymentDescription: `Funding account ${bankAccount.accountNickname}`,
      paymentAddenda: `Account ${bankAccount.accountNickname}`
    };

    this.dialog.open(TransferUsingPlaidDialogComponent, dialogConfig);

  }

  public openOwnershipTransferDialog() {
    combineLatest([this.holding$, this.investorsOverview$])
      .pipe(
        untilComponentDestroyed(this),
        take(1)
      ).subscribe(([holding, investorsOverview]) => {
      const ownershipDialogConfig = new MatDialogConfig<OwnershipTransferParams>();
      ownershipDialogConfig.viewContainerRef = this.viewContainerRef;
      ownershipDialogConfig.disableClose = true;
      ownershipDialogConfig.closeOnNavigation = true;
      ownershipDialogConfig.autoFocus = true;
      ownershipDialogConfig.panelClass = 'transfer-of-ownership-dialog';
      ownershipDialogConfig.data = {
        holding,
        investorsOverview: investorsOverview.investorOverviews
      } as OwnershipTransferParams;

      this.dialog.open(OwnershipTransferDialogComponent, ownershipDialogConfig);
    });
  }

  public createReport() {
    this.holding$
      .pipe(
        map(holding => {
          return this.routingService.createAssetReport(holding.id); //  + `/report-information`;
        })
      )
      .subscribe(url => this.router.navigateByUrl(url));
    this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
      this.holding$.pipe(take(1)).subscribe(holding => {
        this.telemetryService.create({
          eventID: '403',
          eventTitle: 'GP SHARED REPORT (INIT)',
          holdingID: holding.id,
          organizationID: clientDetails.organizationDetails.id
        }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
      });
    });
  }

  public openFilePicker() {
    // When we open the file picker, first we want to get the most updadted quota,
    // in case it was changed in another tab or by another user in the organization
    this.documentsTabService.getQuotaRefreshed().pipe(take(1))
      .subscribe(quotaDetails => {
        if (quotaDetails.quotaUsedMB >= quotaDetails.maxStorageQuotaMB ||
          quotaDetails.maxStorageFilesCount - quotaDetails.currentStorageFilesCount <= 0) {
          this.dialogService.alertDialog(new AlertDialogParams(
            'Storage is full',
            `<p>You have reached your storage limit.</p>
            For more space, <a target="_blank" class="light link" href="//www.covercy.com/contact-us">contact support</a>`
          ));
        } else {
          this.documentsTabService.getFilestackPolicy()
            .pipe(take(1))
            .subscribe(policy => {

              const pickerOptions: PickerOptions = {
                onUploadDone: (event) => this.onUploadSuccess(event),
                onFileUploadFailed: (event, error) => this.onUploadError(error),
                // images larger than this size will be resized by the picker
                imageMax: [1920, 1920],
                maxFiles: quotaDetails.maxStorageFilesCount - quotaDetails.currentStorageFilesCount,
                maxSize: 500 * 1024 * 1024, // 500MB
                // accept: ['image/*', 'video/*', 'audio/*', 'text/*', '.pdf', '.doc', '.docx', '.docm', '.ppt', '.pptx', '.pptm', '.xls', '.xlsx', '.xlsm', '.odt', '.ods', '.odp'],
                accept: [
                  '.jpg', '.jpeg', '.png', '.tif', '.tiff', '.gif', '.bmp',
                  '.mp3', '.ogg', '.wav', '.wma', '.flac', '.alac',
                  '.mp4', '.webm', '.avi', '.mpeg', '.wmv', '.ogg',
                  '.pdf', '.doc', '.docx', '.docm', '.odt', '.txt',
                  '.ppt', '.pptx', '.pptm', '.odp',
                  '.xls', '.xlsx', '.xlsm', '.ods', '.csv'],
                storeTo: {
                  location: 'azure',
                  container: 'main',
                  // workflows: [{ id: '2805fdaf-df4a-4e9b-805f-6d1e86c6a8f4' } as WorkflowConfig],
                } as PickerStoreOptions
              } as PickerOptions;

              const filestackClientOptions = {
                security: {
                  policy: policy.filestackPolicy,
                  signature: policy.filestackSignature
                } as Security
              } as ClientOptions;

              this.filestackService.init(this.appSettingsService.fileStackApiKey, filestackClientOptions);
              this.picker = this.filestackService.picker(pickerOptions);
              this.picker.open();
            });
        }
      });


  }

  // When filestack completes the upload, we alsso save it to our database
  onUploadSuccess(pickerResponse: PickerResponse) {
    if (pickerResponse.filesFailed.length > 0) {
      this.snackbarService.showGeneralMessage('Error: Failed to save uploaded files');
      return;
    }

    this.documentsTabService.createFiles(pickerResponse).subscribe(resultFiles => {
      // update the table:
      if (resultFiles.some(file => file.isDeleted)) {
        const params = new AlertDialogParams();
        params.title = 'Some of the files were not uploaded';
        params.description = `<p>You have exceeded your storage limit. The following files were not uploaded:</p>
        <ul style="padding: 0; max-height: 150px; overflow: auto;">`;
        resultFiles.filter(file => file.isDeleted).forEach(deletedFile => {
          params.description += `<li>${deletedFile.displayName}.${deletedFile.extension}</li>`;
        });
        params.description += '</ul>';

        this.dialogService.alertDialog(params);
      } else {
        this.holding$.pipe(take(1)).subscribe(holding => {
          this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
            this.telemetryService.create({
              eventID: '400',
              eventTitle: 'GP SHARED DOCUMENT WITH INVESTORS',
              holdingID: holding.id,
              organizationID: clientDetails.organizationDetails.id,
              additional: {
                numberOfFiles: resultFiles.length,
              }
            }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
          });
        });
        this.snackbarService.showGeneralMessage(resultFiles.length > 1 ? 'Files uploaded' : 'File uploaded');
      }
    }, error => {
      if (error instanceof BaseResponseDto) {
        this.utilsService.alertErrorMessage(error);
      } else {
        this.snackbarService.showGeneralMessage('Error: Failed to upload file(s)');
      }
    });
  }

  onUploadError(error: any) {
    console.log('onUploadError: ', error);
  }
}
