import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Observable, combineLatest, of} from 'rxjs';
import {map, distinctUntilChanged, shareReplay, switchMap, take, tap, filter, delayWhen, delay} from 'rxjs/operators';

import {GpFundPollingReqRes} from './GpFundPollingReqRes.model';
import {GpFundService} from './gp-fund.service';
import {GpFundDataService} from 'src/app/services/gp/gp-fund-data.service';
import {FundFundraisingService} from './fund-fundraising.service';
import { CapitalCallService } from '../../shared/holding/capital-call/capital-call.service';

@Injectable()
export class FundPollingService {

  private fundraisingIdForPollingOnly$ = this.route.params.pipe(
    map((params) => +params.fundraisingid),
    distinctUntilChanged(),
    map((fundraisingIdFromUrl) => {
      if (fundraisingIdFromUrl >= 0) {
        return fundraisingIdFromUrl;
      } else {
        return 0;
      }
    }),
    shareReplay(1)
  );

  private distributionIdForPollingOnly$ = this.route.params.pipe(
    map((params) => +params.distributionId),
    distinctUntilChanged(),
    map((distributionIdFromUrl) => {
      if (distributionIdFromUrl >= 0) {
        return distributionIdFromUrl;
      } else {
        return 0;
      }
    }),
    shareReplay(1)
  );

  private changedSince$ = new BehaviorSubject(new Date(1970, 0, 1));
  private lastChangeTimestamp: Date = new Date(1970, 1, 1);

  constructor(
    private gpFundService: GpFundService,
    private gpFundDataService: GpFundDataService,
    private fundFundraisingService: FundFundraisingService,
    private capitalCallService: CapitalCallService,
    private route: ActivatedRoute
  ) {
  }

  startPoll() {
    return this.changedSince$.pipe(
      switchMap((changedsince) => this.poll(changedsince))
    );
  }

  refresh() {
    const now = new Date();
    now.setDate(now.getDate() - 1);
    this.changedSince$.next(now);
  }

  /// fundraisingId = 0 will get the initial fundraising.
  private poll(changedSince: Date): Observable<GpFundPollingReqRes> {
    return combineLatest([this.gpFundService.holdingId$, this.fundraisingIdForPollingOnly$, this.distributionIdForPollingOnly$])
      .pipe(
        take(1),
        switchMap(([fundId, fundraisingId, distributionId]) => {
          return this.gpFundDataService.detectChanges(fundId, fundraisingId, distributionId, changedSince);
        }),
      delayWhen((pollingResponse) =>
        pollingResponse ? of(0) : of(pollingResponse).pipe(delay(1000 * 15))
      ),
        tap((pollingResponse) => {
        if (!pollingResponse) {
          this.changedSince$.next(this.lastChangeTimestamp);
          return;
        }
          if (pollingResponse.storageObjectsChanged) {
            this.gpFundService.documentsChanged$.next();
          }
          if (pollingResponse.distributionsChanged) {
            this.gpFundService.refreshDistribution();
          }

          if (pollingResponse.signaturesChanged) {
            this.gpFundService.refreshSigners();
          }

          if (pollingResponse.fundraising) {
            pollingResponse.fundraising.investments = [...this.fundFundraisingService.sortInvestments(pollingResponse.fundraising.investments)];
          }

          this.gpFundService.changeFundOnly(pollingResponse.fund);
          this.gpFundService.changeFundraisingOnly(pollingResponse.fundraising);
          this.lastChangeTimestamp = pollingResponse.timeStamp;
          this.changedSince$.next(pollingResponse.timeStamp);
        })
      );
  }
}
