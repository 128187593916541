import { NgForOf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormlyModule
  ],
  styles: [],
  template: `
  <div class="full-width flex flex-column">
    <!-- <label class="mb-1">{{ props.label }}</label> -->
    <div class="flex flex-row gap-1">
    <mat-checkbox color="primary"  class="" [formControl]="formControl"  [formlyAttributes]="field">
      {{props.label}}
    </mat-checkbox>
  </div>
  `,
})
export class CheckBoxTypeComponent extends FieldType<FieldTypeConfig> { }
