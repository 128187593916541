import {Component, OnInit} from '@angular/core';

import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import HoldingType from 'src/app/shared/enums/HoldingType.enum';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {InvestmentType} from 'src/app/shared/enums/InvestmentType.enum';
import {ResourceService} from 'src/app/services/resource.service';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {GpAssetService} from '../gp-asset.service';
import {AppStateForTours, ToursService} from 'src/app/services/gp/tours.service';

export enum AssetUnderManagementTab {
  Overview = 0,
  Reports = 1,
  Documents = 2,
  Fundraisings = 3,
  Distributions = 4,
  Transactions = 5
}

@Component({
  selector: 'terra-asset-management-status',
  templateUrl: './asset-management-status.component.html',
  styleUrls: ['./asset-management-status.component.scss']
})
export class AssetManagementStatusComponent implements OnInit {
  tabsData = [
    {label: 'Overview', url: 'overview', index: AssetUnderManagementTab.Overview},
    {label: 'Reports', url: 'reports', index: AssetUnderManagementTab.Reports},
    {label: 'Documents', url: 'documents', index: AssetUnderManagementTab.Documents},
    {label: 'Contributions', url: 'contributions', index: AssetUnderManagementTab.Fundraisings},
    {label: 'Distributions', url: 'distributions', index: AssetUnderManagementTab.Distributions},
    {label: 'Transactions', url: 'transactions', index: AssetUnderManagementTab.Transactions}
  ];

  // Enum:
  Tab = AssetUnderManagementTab;

  assetDetails$ = this.gpAssetService.holding$;
  fundraisingDetails$ = this.gpAssetService.fundraising$;
  creTransactionsTabVisible$ = this.gpAssetService.creTransactionsTabVisible$;

  // Enums (used in the template)
  HoldingStatus = HoldingStatus;
  HoldingType = HoldingType;
  InvestmentType = InvestmentType;
  InvestmentSecurityType = InvestmentSecurityType;

  constructor(
    private resourceService: ResourceService,
    private gpAssetService: GpAssetService,
    private toursService: ToursService) {
  }

  ngOnInit() {
    this.toursService.setTours(AppStateForTours.UnderManagementAsset);
  }

  getCountryById(countryId: number): CountryModel {
    return this.resourceService.getCountryById(countryId);
  }
}
