<mat-menu #menu>
  <!-- Move to root -->
  <ng-container *ngIf="isBaseLevel && (currentFolderId$ | async) !== null">
    <button mat-menu-item (click)="move(null)">Top folder</button>
    <mat-divider></mat-divider>
  </ng-container>
  <!-- Other folders -->
  <ng-container *ngFor="let folder of filteredItems">
    <ng-container *ngIf="allowedChildren(folder?.children) as numberOfChildren">
      <!-- Show a button if there are no children or there is 1 child and he is disabled -->
      <button
        *ngIf="!numberOfChildren?.length || (numberOfChildren.length === 1 && numberOfChildren[0].id === (currentFolderId$ | async)); else branch"
        [disabled]="(currentFolderId$ | async) === folder.id" mat-menu-item (click)="move(folder.id)">{{folder.displayName}}</button>
      <!-- Otherwise show another nested menu -->
      <ng-template #branch>
        <button (click)="move(folder.id)" mat-menu-item [matMenuTriggerFor]="innerPanel.menu">{{folder.displayName}}</button>
        <terra-folder-menu-panel #innerPanel [isBaseLevel]="false" [items]="folder.children" [itemIdsToMove]="itemIdsToMove"
          [itemIdsToHide]="itemIdsToHide">
        </terra-folder-menu-panel>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-menu>
