import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';
import {TelemetryService} from 'telemetry-library';

import {TerraUtils} from 'src/app/shared/TerraUtils';
import {UtilsService} from 'src/app/services/utils.service';
import {UserService} from 'src/app/services/shared/user.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import HTTP_STATUS_CODES from 'src/app/shared/enums/HttpStatusCodesEnum';
import {ErrorMatcher, ErrorType} from 'src/app/shared/errors/ErrorMatcher';
import {GpFundraisingDataService} from 'src/app/services/gp/gp-fundraising-data.service';
import {ContributionDetailsItemReqRes} from '../contribution-details/ContributionDetailsItemReqRes';
import {ContributionsImportResponse} from 'src/app/shared/models/ContributionsImportResponse.model';
import {BaseResponseDto} from '../../../../../../shared/models/BaseResponseDto.model';

@Component({
  selector: 'terra-import-contributions-dialog',
  templateUrl: './import-contributions-dialog.component.html',
  styleUrls: ['./import-contributions-dialog.component.scss']
})
export class ImportContributionsDialogComponent extends OnDestroyMixin implements OnInit {
  supportedFileExtensions = TerraUtils.consts.supportedFileExtensions.CREATE_CONTRIBUTION_IMPORT;
  isGeneralServerError = false;
  generalServerErrorMessage: string;
  fullErrorListMessage: string;
  isSubmitted = false;
  showMoreLines = false;
  hasMoreErrorLines = false;
  errorListStatus = 'more';
  private contributions: ContributionDetailsItemReqRes[];
  private holdingId: any;
  private loadingCounter = 0;

  pageForm = this.fb.group({
    file: [null, Validators.required],
  });

  get contributionTitle(): string {
    return 'contribution' + (this.contributions.length > 1 ? 's' : '');
  }

  get loadingPage(): boolean {
    return this.loadingCounter !== 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private gpFundraisingDataService: GpFundraisingDataService,
    private dialogRef: MatDialogRef<ImportContributionsDialogComponent>,
    private utilsService: UtilsService,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private telemetryService: TelemetryService,
  ) {
    super();
    this.contributions = dialogData.contributions;
    this.holdingId = dialogData.holdingId;
    dialogRef.addPanelClass('import-contributions-dialog');
  }

  ngOnInit() {
    this.handleFileInputValueChange();
  }

  downloadTemplate() {
    this.loadingCounter++;
    this.gpFundraisingDataService.getMultiContributionsTemplate(this.holdingId, this.contributions)
      .subscribe(fileUrl => {
        if (fileUrl) {
          this.utilsService.downloadFileFromUrl(fileUrl);
        }
        this.loadingCounter--;
      });
  }

  private handleFileInputValueChange() {
    this.pageForm.get('file').valueChanges.pipe(untilComponentDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        if (value == null) {
          this.clearErrors();
        }
      });
  }

  private clearErrors() {
    this.isGeneralServerError = false;
    this.generalServerErrorMessage = null;
    this.fullErrorListMessage = null;
    this.hasMoreErrorLines = false;
    this.showMoreLines = false;
    this.errorListStatus = 'more';
  }

  import() {
    this.isSubmitted = true;
    this.isGeneralServerError = false;
    this.clearErrors();
    if (this.pageForm.valid) {
      this.loadingCounter++;
      const metaFileLinkId = this.pageForm.value.file.id;
      this.gpFundraisingDataService.getMultiContributionsImportFromExcelFile(this.dialogData.holdingId, metaFileLinkId)
        .subscribe(
          result => {
            if (result.isSuccess) {
              const pluralContributions = result.contributionsCount > 1 ? 's' : '';
              const finalMessage = `${result.contributionsCount} contribution${pluralContributions} ${pluralContributions ? 'were' : 'was'} imported successfully`;

              this.snackbarService.showGeneralMessage(finalMessage, 5, 'flow-import-contributions-success');
              this.dialogRef.close(result);
            } else {
              this.generalServerErrorMessage = this.generateImportErrorMessage(result, 3);
              this.fullErrorListMessage = this.generateImportErrorMessage(result);
              this.isGeneralServerError = true;
              this.loadingCounter--;
            }
          },
          error => {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            } else if (ErrorMatcher.isError(error, ErrorType.BadFileFormatException) ||
              ErrorMatcher.isError(error, ErrorType.ValueNotSupportedException)
            ) {
              this.generalServerErrorMessage = 'Failed importing contribution. Please make sure the file type is .xlsx and that the content is formatted correctly';
            } else if (error.status === HTTP_STATUS_CODES.NOT_ACCEPTABLE) {
              this.generalServerErrorMessage = 'Failed importing contribution. make sure the data is valid.';
            } else {
              this.generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;
            }
            this.isGeneralServerError = true;
            this.loadingCounter--;
          }
        );
    }
  }

  private generateImportErrorMessage(importResult: ContributionsImportResponse, rowLimit: number = null): string {
    let errorMessage = `Failed to import contributions.`;
    if (!importResult.invalidRows.length) {
      return errorMessage;
    }

    errorMessage += ` ${(importResult.invalidRows && 'Found following issue(s):')}`;
    let counter = 1;
    importResult.invalidRows.forEach(row => {
      const rowNumber = row.row;
      row.errors.forEach(error => {
        if (rowLimit && counter > rowLimit) {
          this.hasMoreErrorLines = true;
          return errorMessage;
        }
        counter++;
        let cellRef = `in ` + (error.columnRef ? `cell '${error.columnRef}` : `row '`);
        cellRef += `${rowNumber}'.`;
        const cellIndex = !rowNumber ? '. ' : ' ' + cellRef;
        const errorNumber = `\n`;
        const lineStart = `${errorNumber} Parsing failure${cellIndex}`;

        if (error.corruptedFile) {
          errorMessage += `\n${error.errorMessage}`;
        } else {
          errorMessage += `${lineStart} ${error.errorMessage || ' Valid entry is required.'}`;
        }
      });
    });

    return errorMessage;
  }

  toggleFullErrorList() {
    this.showMoreLines = !this.showMoreLines;
    this.isGeneralServerError = !this.isGeneralServerError;
    this.errorListStatus = this.isGeneralServerError && this.hasMoreErrorLines ? 'more' : 'less';
  }

  // TODO
  private importContributionSendTelemetryEvent() {
    // this.userService.accountDetails$.pipe(take(1)).subscribe(clientDetails => {
    //   this.telemetryService.create({
    //     eventID: '???',
    //     eventTitle: 'CONTRIBUTION - IMPORT (SEND)',
    //     holdingID: this.holdingId,
    //     organizationID: clientDetails.organizationDetails.id
    //   });
    // });
  }
}
