import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import userflow from 'userflow.js';

import { AppSettingsService } from 'src/app/services/app-settings.service';
import { UserService } from 'src/app/services/shared/user.service';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';

@Component({
  selector: 'terra-user-flow',
  template: '',
  styles: [],
})
export class UserFlowComponent implements OnInit {

  private userCt$ = this.userService.accountDetails$.pipe(
    map(account => account.clientIdentifier), distinctUntilChanged(), shareReplay(1));

  private userFullName$ = this.userService.accountDetails$.pipe(
    map(account => `${account.firstName} ${account.lastName}`), distinctUntilChanged(), shareReplay(1));

  private userEmail$ = this.userService.accountDetails$.pipe(
    map(account => account.email), distinctUntilChanged(), shareReplay(1));

  private signedUpDate$ = this.userService.accountDetails$.pipe(
    map(account => account.createTimestamp), distinctUntilChanged(), shareReplay(1));

  private hasFundsFeature$ =  this.userService.userHasFeatureFlag(FeatureFlags.Fund).pipe(distinctUntilChanged(), shareReplay(1));

  // private organizationId$ = this.userService.accountDetails$.pipe(
  //   map(account => account.organizationDetails ? account.organizationDetails.id : null), distinctUntilChanged(), shareReplay(1));

  constructor(
    private appSettings: AppSettingsService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.setUserFlow();
  }

  private setUserFlow() {
    const USERFLOW_TOKEN = this.appSettings.userFlowToken;

    combineLatest([
      this.userCt$,
      this.userFullName$,
      this.userEmail$,
      this.signedUpDate$,
      this.hasFundsFeature$
      /*, this.organizationId$*/])
    .pipe()
    .subscribe(([clientCt, fullName, email, signedUpDate, hasFundsFeature/*, organizationId*/]) => {
      userflow.init(USERFLOW_TOKEN);
      const USER_ID =  clientCt;
      userflow.identify(USER_ID, {
        name: fullName,
        email,
        signed_up_at: signedUpDate,
        hasFundsFeature
      });

      //// Groups are not supported on our UserFlow plan.
      // const GROUP_ID = organizationId ? organizationId.toString() : null;
      // userflow.group(GROUP_ID);
    });
  }
}
