<terra-dialog-header>
  <h1>Update contribution status to Complete</h1>
</terra-dialog-header>
<div class="content-wrapper" [ngClass]="{ 'loading': (processingSubmit$ | async)  }">
  <terra-loader class="page-loader" *ngIf="(processingSubmit$ | async)"></terra-loader>
  <mat-dialog-content class="main-content">
    <p class="info-message">
      Once the contribution is complete, you cannot make changes to the contribution or add/remove investors
    </p>

    <p class="holding-down-stripe full-width" *ngIf="context.fundDetails.isPrivate">
      When the fund mode is down, it will not be available on Investor Portal, and investors will not receive an update
      about the status
      change.
    </p>

    <form id="dialogForm" [formGroup]="pageForm" *ngIf="pageForm" class="form-grid-new terra-error-style border-bottom"
      (keydown.enter)="$event.preventDefault()">
      <h3 class="section-title">
        Please fill in the following information before updating the contribution’s status. <br />
        This information will be shared with your investors.
      </h3>

      <!-- Final closing date -->
      <div class="terra-form-field-wrapper">
        <terra-custom-utc-date-picker id="txtClosingDate" formControlName="finalClosingDate" label="Final closing date"
          [requiredError]="pageForm.get('finalClosingDate').hasError('required')"
          [touched]="pageForm.get('finalClosingDate').touched"></terra-custom-utc-date-picker>
      </div>

      <!-- Total capitalization -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalCapitalization" class="text-input-label">Fund’s total capitalization to date</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtTotalCapitalization" formControlName="totalCapitalization" terraFormattedNumber
            autocomplete="total-capitalization" class="lo_sensitive" />
          <span matSuffix>{{ selectedCurrency?.iso }}</span>
          <mat-error *ngIf="pageForm.get('totalCapitalization').hasError('required')"> Required </mat-error>
          <mat-error *ngIf="pageForm.get('totalCapitalization').hasError('minFormattedNumber')">Must be a greater than
            0</mat-error>
          <mat-hint>Total investors equity + loan</mat-hint>
        </mat-form-field>
      </div>

      <!-- Total Investor Equity  -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalInvestorEquity" class="text-input-label">Total investor equity</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTotalInvestorEquity" terraFormattedNumber
            formControlName="totalInvestorEquity" class="lo_sensitive" />
          <span matSuffix>{{ selectedCurrency?.iso }}</span>
          <mat-error *ngIf="pageForm.get('totalInvestorEquity').hasError('required')"> Required </mat-error>
          <mat-error *ngIf="pageForm.get('totalInvestorEquity').hasError('minFormattedNumber')">Must be a greater than
            0</mat-error>
        </mat-form-field>
      </div>

      <!-- % of GP equity from total equity (optional)  -->
      <div class="terra-form-field-wrapper">
        <label for="txtGpEquityFromTotalEquity" class="text-input-label">% of GP equity from total equity
          (optional)</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtGpEquityFromTotalEquity" terraFormattedNumber
            formControlName="gpEquityFromTotalEquity" class="lo_sensitive" />
        </mat-form-field>
      </div>

      <!-- Loan information -->
      <h3 class="section-title border-top">Fund’s Loan information (optional)</h3>

      <ng-container formGroupName="lenderInformation">
        <!-- Lender Name -->
        <div class="terra-form-field-wrapper">
          <label for="txtLenderName" class="text-input-label">Lender name</label>
          <mat-form-field appearance="outline">
            <input matInput id="txtLenderName" formControlName="lenderName" autocomplete="lender-name"
              class="lo_sensitive" />
          </mat-form-field>
        </div>

        <!-- Amount -->
        <div class="terra-form-field-wrapper">
          <label for="txtLenderAmount" class="text-input-label">Amount</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtLenderAmount" terraFormattedNumber formControlName="amount"
              class="lo_sensitive" />
            <span matSuffix>{{ selectedCurrency?.iso }}</span>
          </mat-form-field>
        </div>

        <!-- Loan type -->
        <div class="terra-form-field-wrapper">
          <label for="ddlLoanType" class="text-input-label">Loan type</label>
          <mat-form-field appearance="outline">
            <mat-select id="ddlLoanType" formControlName="loanType" placeholder="Select">
              <mat-option [value]="null">Select</mat-option>
              <mat-option *ngFor="let item of LoanTypesList" [value]="item">{{ item | enumString: LoanType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Loan to value -->
        <div class="terra-form-field-wrapper">
          <label for="txtLoanToValue" class="text-input-label">Loan to value %</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtLoanToValue" terraFormattedNumber formControlName="loanToValue"
              class="lo_sensitive" />
          </mat-form-field>
        </div>

        <!-- interestRate -->
        <div class="terra-form-field-wrapper">
          <label for="txtInterestRate" class="text-input-label">Interest rate %</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtInterestRate" terraFormattedNumber formControlName="interestRate"
              class="lo_sensitive" />
          </mat-form-field>
        </div>

        <!-- Total financing per gross area (only InvestmentType.Opportunistic) -->
        <!-- <div class="terra-form-field-wrapper" *ngIf="selectedInvestmentType === InvestmentType.Opportunistic">
          <label for="txtTotalFinancingPerGrossAreaUnit" class="text-input-label">
            Total financing per gross {{ selectedAreaUnit | enumString : AreaUnit }} </label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtTotalFinancingPerGrossAreaUnit" terraFormattedNumber
              formControlName="totalFinancingPerGrossAreaUnit" class="lo_sensitive" />
          </mat-form-field>
        </div> -->

        <!-- Total financing per net area (only InvestmentType.Opportunistic) -->
        <!-- <div class="terra-form-field-wrapper" *ngIf="selectedInvestmentType === InvestmentType.Opportunistic">
          <label for="txtTotalFinancingPerNetAreaUnit" class="text-input-label">
            Total financing per net {{ selectedAreaUnit | enumString : AreaUnit }} </label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtTotalFinancingPerNetAreaUnit" terraFormattedNumber
              formControlName="totalFinancingPerNetAreaUnit" class="lo_sensitive" />
          </mat-form-field>
        </div> -->
      </ng-container>
    </form>
  </mat-dialog-content>
</div>

<mat-dialog-actions>
  <button *ngIf="!context.fundDetails.isPrivate" (click)="save(true)" type="button" form="dialogForm"
    [disabled]="processingSubmit$ | async" mat-flat-button color="accent" class="page-cta">
    Update Status and Notify Investors <mat-icon svgIcon="publish" class="notranslate publish-icon"></mat-icon>
  </button>

  <button type="button" (click)="save(false)" form="dialogForm" [disabled]="processingSubmit$ | async" mat-flat-button
    color="primary" class="page-cta">
    Update Status
  </button>

  <button type="button" mat-button class="cancel-button tall-button" [disabled]="processingSubmit$ | async"
    [mat-dialog-close]="undefined">Cancel</button>

  <!-- Error message -->
  <div class="full-width" *ngIf="attemptSubmit">
    <p *ngIf="pageForm.invalid" class="error-message">
      Please review and correct the fields marked in red
    </p>
    <p *ngIf="pageForm.valid && isGeneralServerError" #serverError class="error-message">
      <span [innerHTML]="generalServerErrorMessage"></span>
    </p>
  </div>
</mat-dialog-actions>