import {BehaviorSubject, merge, Observable, of, Subject, throwError} from 'rxjs';
import {DataSource} from '@angular/cdk/table';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CollectionViewer} from '@angular/cdk/collections';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import {InvestmentReqRes} from '../../../../models/investment.model';
import {CapitalCallInvestmentsTableService} from './capital-call-investments-table.service';
import {CapitalCallService} from '../capital-call.service';
import {InvestmentSearchOptions} from './InvestmentSearchOptions';
import InvestmentStatus from '../../../../../shared/enums/InvestmentStatus.enum';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import MetaFileLink from 'src/app/models/metaFileLink.model';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import PaymentPlatform from '../../../../../shared/enums/PaymentPlatform.enum';
import TrackStatus from '../models/TrackStatus.enum';
import PaymentStatus from '../../../../../shared/enums/PaymentStatus.enum';
import { DialogService } from 'src/app/services/dialog.service';
import { MarkAsInvestedConfirmDialogParams } from '../../investor/MarkAsInvestedConfirmDialogParams';
import { MarkAsTransferredConfirmDialogParams } from '../capital-call-track/MarkAsTransferredConfirmDialogParams';
import { PermissionService } from 'src/app/permission/permission.service';

export class CapitalCallInvestmentsDataSource extends DataSource<UntypedFormGroup> {
  private destroy$ = new Subject<void>();
  investmentsForm: UntypedFormGroup;

  allowInvestorName$ = this.permissionService.allowInvestorName$;

  // Trigger for refreshing data
  private searchOptions$ = this.capitalCallInvestmentsTableService.searchOptions$;
  private refreshData$ = this.capitalCallInvestmentsTableService.refreshData$.pipe(tap(_ => this.capitalCallInvestmentsTableService.updatePageNumber(0)));

  private _totalRowsCount$ = new BehaviorSubject(0);
  public totalRowsCount$ = this._totalRowsCount$.pipe(takeUntil(this.destroy$), distinctUntilChanged(), shareReplay(1));

  // For a loading indicator
  private _loading$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = this._loading$.pipe(takeUntil(this.destroy$), shareReplay(1));

  public pageRows$ = merge(this.refreshData$, this.searchOptions$)
    .pipe(
      switchMapTo(this.searchOptions$),
      takeUntil(this.destroy$),
      filter(searchOptions => {
        return searchOptions !== null;
      }),
      tap(() => this._loading$.next(true)),
      switchMap(searchOptions => this.getInvestments(searchOptions)),
      tap(() => this._loading$.next(false)),
      shareReplay(1)
    );

  constructor(private capitalCallInvestmentsTableService: CapitalCallInvestmentsTableService,
              private capitalCallService: CapitalCallService,
              private fb: UntypedFormBuilder,
              private terraNumberPipe: TerraNumberPipe,
              private dialogService: DialogService,
              private permissionService: PermissionService) {
    super();
    this.generateForm();
    this.revertChanges();
    this.doneSaving();
  }

  connect(collectionViewer: CollectionViewer): Observable<UntypedFormGroup[] | ReadonlyArray<UntypedFormGroup>> {
    this._loading$.next(true);
    return this.pageRows$.pipe(
      tap(() => this._loading$.next(false)),
      catchError((error) => {
        this._loading$.next(false);
        return throwError(error);
      })
    );
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.investmentFormArray.controls.forEach(investmentFormGroup => {
      investmentFormGroup.value?.destroyUpdatesSubscription$?.next();
      investmentFormGroup.value?.destroyUpdatesSubscription$?.complete();
      investmentFormGroup.value?.isSaving$?.complete();
    });
  }

  /**
   * Get investments form array
   * @private
   */
  private get investmentFormArray(): UntypedFormArray {
    return this.investmentsForm.get('investments') as UntypedFormArray;
  }

  /**
   * Get investment form group by investment id
   * @param investmentId investment id
   */
  private getFormGroupByInvestmentId(investmentId: number): UntypedFormGroup {
    return this.investmentFormArray.controls.find(investment => investment.value.id === investmentId) as UntypedFormGroup;
  }

  /**
   * Get investments as form groups
   * @param searchOptions Investment search options
   */
  private getInvestments(searchOptions: InvestmentSearchOptions): Observable<UntypedFormGroup[]> {
    return this.capitalCallService.investments$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.allowInvestorName$),
      map(([investments, allowInvestorName]) => {
        if (!investments || investments.length === 0 || !searchOptions) {
          return [];
        }

        let investmentsToDisplay = [...investments];
        investmentsToDisplay = this.applyFilters(investmentsToDisplay, searchOptions, allowInvestorName);
        this.applySorting(investmentsToDisplay, searchOptions.orderBy, searchOptions.sortOrder);
        this._totalRowsCount$.next(investmentsToDisplay.length);
        // apply paging
        investmentsToDisplay = investmentsToDisplay.slice(searchOptions.pageNumber * searchOptions.pageSize, searchOptions.pageNumber * searchOptions.pageSize + searchOptions.pageSize );
        // removes investments that are not being displayed
        this.removeUndisplayedInvestments(investmentsToDisplay);
        // create or update investment form groups which should be displayed
        investmentsToDisplay.forEach(investment => {
          this.addOrUpdateInvestmentForm(investment);
        });
        // sort form groups according to the order of investmentsToDisplay
        this.investmentFormArray.controls.sort((a, b) => investmentsToDisplay.findIndex(investment => investment.id === a.value.id) - investmentsToDisplay.findIndex(investment => investment.id === b.value.id));
        this.disableFormValuesIfNeeded();
        return this.investmentFormArray.controls as UntypedFormGroup[];
      })
    );
  }

  /**
   * Applying sorting on investments array
   * @param investments The investments
   * @param orderBy Order by field name
   * @param sortOrder Sort order - asc/desc
   */
  private applySorting(investments: InvestmentReqRes[], orderBy: string, sortOrder: string) {
    if (!!orderBy && !!sortOrder) {
      switch (orderBy) {
        case 'name':
          investments.sort((a, b) => a.investingEntity.name.toLowerCase() > b.investingEntity.name.toLowerCase() ? 1 : -1);
          break;
        case 'status':
          investments.sort((a, b) => a.status > b.status ? 1 : -1);
          break;
        case 'dueDate':
          investments.sort((a, b) => a.capitalCallDueDate > b.capitalCallDueDate ? 1 : -1);
          break;
        case 'amount':
          investments.sort((a, b) => a.estimatedAmount > b.estimatedAmount ? 1 : -1);
          break;
        case 'transferDate':
          investments.sort((a, b) => TerraUtils.compareDateWithNull(a.transferDate, b.transferDate));
          break;
        case 'receivedDate':
          investments.sort((a, b) => TerraUtils.compareDateWithNull(a.paymentSettlementDate, b.paymentSettlementDate));
          break;
        case 'trackStatus':
          investments.sort((a, b) => this.getTrackedInvestmentStatus(a) > this.getTrackedInvestmentStatus(b) ? 1 : -1);
          break;
      }

      if (sortOrder === 'desc') {
        investments.reverse();
      }
    }
  }

  /**
   * Filter investments
   * @param investments The investments
   * @param searchOptions Investment search options
   */
  private applyFilters(investments: InvestmentReqRes[], searchOptions: InvestmentSearchOptions, allowInvestorName:boolean) {
    investments = investments.filter(investment => investment.status === InvestmentStatus.Signed ||
                                                   investment.status === InvestmentStatus.Invested ||
                                                   investment.status === InvestmentStatus.Declined
    );

    // filter by name
    if (searchOptions.filter.trim().length > 0) {
      const trimmedAndLoweredFilter = searchOptions.filter.trim().toLowerCase();
      if(allowInvestorName){
        investments = investments.filter(investment =>
          `${investment.investingEntity.name.toLowerCase()} ${investment.investingEntity.id} ${investment.investingEntity.contactId}`.indexOf(trimmedAndLoweredFilter) >= 0);
      }
      else{
        investments = investments.filter(investment => `${investment.investingEntity.id} ${investment.investingEntity.contactId}`.indexOf(trimmedAndLoweredFilter) >= 0);
      }
    }

    // filter by track status
    if (searchOptions.trackStatus !== null) {
      if (searchOptions.trackStatus !== TrackStatus.None){
        switch (searchOptions.trackStatus){
          case TrackStatus.NotViewed:
            investments = investments.filter(investment => !!investment.paymentRequestSendDate &&
                                                           !investment.capitalCallViewDate &&
                                                           investment.status === InvestmentStatus.Signed &&
                                                           !investment.transferDate);
            break;
          case TrackStatus.Viewed:
            investments = investments.filter(investment => !!investment.capitalCallViewDate &&
                                                           investment.status === InvestmentStatus.Signed &&
                                                           !investment.transferDate &&
                                                           investment.paymentStatus !== PaymentStatus.Canceled);
            break;
          case TrackStatus.Transferred:
            investments = investments.filter(investment => !!investment.transferDate &&
                                                           investment.status === InvestmentStatus.Signed &&
                                                           investment.paymentStatus !== PaymentStatus.Canceled);
            break;
          case TrackStatus.Invested:
            investments = investments.filter(investment => investment.status === InvestmentStatus.Invested);
            break;
          case TrackStatus.Declined:
            investments = investments.filter(investment => investment.status === InvestmentStatus.Declined);
            break;
          case TrackStatus.NotCalled:
            investments = investments.filter(investment => investment.status === InvestmentStatus.Signed &&
                                                           !investment.paymentRequestSendDate);
            break;
          default:
            break;
        }
      }
    }
    return investments;
  }

  /**
   * Revert investment's form values to the saved investment values
   * @private
   */
  private revertChanges(){
    this.capitalCallInvestmentsTableService.revertChanges$
    .pipe(
      switchMap(investmentId => this.capitalCallService.investments$.pipe(
        take(1),
        map(investments => investments.find(inv => inv.id === investmentId))
      )),
      filter(investment => !!investment)
    )
    .subscribe(
      investment => {
        const existingInvestmentFormGroup = this.getFormGroupByInvestmentId(investment.id);
        const investmentForm = this.generateFormGroupForInvestment(investment);
        existingInvestmentFormGroup.patchValue(investmentForm.value, { emitEvent: false });
      }
    );
  }

  private doneSaving() {
    this.capitalCallInvestmentsTableService.doneSaving$.pipe(
      tap(investmentId => {
        const investmentFormGroup = this.getFormGroupByInvestmentId(investmentId);
        investmentFormGroup.value.isSaving$.next(false);
      })
    ).subscribe();
  }

  /**
   * Generates the investments form
   */
  private generateForm() {
    this.investmentsForm = this.fb.group({
      investments: this.fb.array([])
    });
  }

  /**
   * Add a new investment form to this.allInvestmentsControllers
   * @param investment The investment
   */
  private addOrUpdateInvestmentForm(investment: InvestmentReqRes) {
    const existingInvestmentFormGroup = this.getFormGroupByInvestmentId(investment.id);
    const investmentForm = this.generateFormGroupForInvestment(investment);
    if (!!existingInvestmentFormGroup && !this.areEqualInvestments(existingInvestmentFormGroup.value.correspondingInvestment, investment)) {
      existingInvestmentFormGroup.patchValue(investmentForm.value, { emitEvent: false });
      const documents = existingInvestmentFormGroup.get('documents') as UntypedFormArray;
      const newDocuments = investmentForm.get('documents') as UntypedFormArray;
      this.patchDocuments(documents, newDocuments);
    } else if (!existingInvestmentFormGroup) {
      investmentForm.valueChanges
        .pipe(
          takeUntil(investmentForm.value.destroyUpdatesSubscription$),
          debounceTime(500),
          filter(_ => !investmentForm.getRawValue().isSaving$.value && this.isInvestmentFormChanged(investmentForm.getRawValue())),
          switchMap(_ => this.confirmReceivedAndTransferredDateChanged(investmentForm.getRawValue())),
          filter(confirmReceivedDateChanged => confirmReceivedDateChanged)
        ).subscribe(_ => {
          const dataToSave = this.getDataToSave(investmentForm.getRawValue());
          investmentForm.getRawValue().isSaving$.next(true);
          this.capitalCallInvestmentsTableService.saveChanges(dataToSave);
      });
      this.investmentFormArray.push(investmentForm);
    }
  }

  private patchDocuments(documents: UntypedFormArray, newDocuments: UntypedFormArray){
    if (documents.length !== newDocuments.length){
      const documentsIds = documents.value.map(d => d.id);
      const newDocTaAdd = (newDocuments.value as MetaFileLink[]).filter(val => !documentsIds.includes(val.id));
      if (!!newDocTaAdd){
        newDocTaAdd?.forEach(p => documents.push(this.fb.group(p)));
      }
    }
  }

  /**
   * Get an object of investment's data to save
   * @param investmentFormValues Investment's form values
   */
  private getDataToSave(investmentFormValues: any): InvestmentReqRes {
    return {
      id: investmentFormValues.id,
      paymentRequestDocuments: investmentFormValues.documents.map(doc => ({id: doc.id}) as MetaFileLink),
      transferDate: !!investmentFormValues.transferDate ? investmentFormValues.transferDate : null,
      paymentSettlementDate: !!investmentFormValues.receivedDate ? investmentFormValues.receivedDate : null
    } as InvestmentReqRes;
  }

  /**
   * Checks if 2 investment are equal
   * @param investmentA The first investment
   * @param investmentB The second investment
   */
  private areEqualInvestments(investmentA: InvestmentReqRes, investmentB: InvestmentReqRes): boolean {
    return investmentA.id === investmentB.id &&
           investmentA.status === investmentB.status &&
           investmentA.capitalCallDueDate === investmentB.capitalCallDueDate &&
           investmentA.estimatedAmount === investmentB.estimatedAmount &&
           investmentA.paymentRequestDocuments?.length === investmentB.paymentRequestDocuments?.length &&
           investmentA.transferDate === investmentB.transferDate &&
           investmentA.paymentSettlementDate === investmentB.paymentSettlementDate &&
           investmentA.finalAmount === investmentB.finalAmount &&
           investmentA.capitalCallViewDate === investmentB.capitalCallViewDate &&
           investmentA.paymentSettlementDate === investmentB.paymentSettlementDate &&
           investmentA.agreementMetaFileLinkId === investmentB.agreementMetaFileLinkId;
  }

  /**
   * Generate form group for investment
   * @param investment Investment's details
   * @returns Investment's form group
   */
  private generateFormGroupForInvestment(investment: InvestmentReqRes): UntypedFormGroup {
    // for some reason, the numbers lose their formatting when they are displayed in the table, hence i'm, formatting them here
    return this.fb.group({
      id: investment.id,
      dueDate: investment.capitalCallDueDate,
      amount: this.terraNumberPipe.transform(investment.estimatedAmount),
      investingEntityName: investment.investingEntity.name,
      documents: this.fb.array(investment.paymentRequestDocuments?.map(p => this.fb.group(p))),
      correspondingInvestment: investment,
      sentDate: investment.paymentRequestSendDate,
      viewDate: investment.capitalCallViewDate,
      transferDate: investment.transferDate,
      receivedDate: investment.paymentSettlementDate,
      trackStatus: this.getTrackedInvestmentStatus(investment),
      destroyUpdatesSubscription$: new Subject<void>(),
      isSaving$: new BehaviorSubject<boolean>(false)
    });
  }

  /**
   * Deletes investments form groups from investmentFormArray which are not in investmentsToDisplay
   * @param investmentsToDisplay Array of investments to display
   * @private
   */
  private removeUndisplayedInvestments(investmentsToDisplay: InvestmentReqRes[]) {
    const investmentIdsToRemove = [];
    this.investmentFormArray.controls.forEach(investmentFormGroup => {
        if (!investmentsToDisplay.find(investment => investment.id === investmentFormGroup.value.id)) {
          investmentIdsToRemove.push(investmentFormGroup.value.id);
          investmentFormGroup.value.destroyUpdatesSubscription$.next();
        }
      }
    );
    investmentIdsToRemove.forEach(id => {
      this.investmentFormArray.removeAt(this.investmentFormArray.controls.findIndex(investment => investment.value.id === id));
    });
  }

  /**
   * Checks if investment's form values changed compared to the corresponding investment object
   * @param investmentFormValues Investment's form values
   */
  private isInvestmentFormChanged(investmentFormValues: any): boolean {
    const correspondingInvestment = investmentFormValues.correspondingInvestment as InvestmentReqRes;
    return investmentFormValues.documents.length !== correspondingInvestment.paymentRequestDocuments?.length ||
           TerraUtils.compareDateWithNull(investmentFormValues.transferDate, correspondingInvestment.transferDate) !== 0 ||
           TerraUtils.compareDateWithNull(investmentFormValues.receivedDate, correspondingInvestment.paymentSettlementDate) !== 0;
  }

  private confirmReceivedAndTransferredDateChanged(investmentFormValues: any): Observable<boolean>{
    const correspondingInvestment = investmentFormValues.correspondingInvestment as InvestmentReqRes;
    const isTransferDateInitChanged = !!investmentFormValues.transferDate &&  !correspondingInvestment.transferDate;
    const isReceivedDateInitChanged = !!investmentFormValues.receivedDate && !correspondingInvestment.paymentSettlementDate;
    if (isTransferDateInitChanged) {
      return this.dialogService
          .confirmDialog(new MarkAsTransferredConfirmDialogParams('capital-call-transferred-confirmation'))
          .afterClosed()
          .pipe(take(1), tap(isConfirmed => !isConfirmed ? this.capitalCallInvestmentsTableService.revertChanges(correspondingInvestment.id) : ''));
    }

    if (isReceivedDateInitChanged){
      if (correspondingInvestment.status === InvestmentStatus.Invested){
        return of(true);
      }
      return this.dialogService
          .confirmDialog(new MarkAsInvestedConfirmDialogParams('capital-call-received-confirmation'))
          .afterClosed()
          .pipe(take(1), tap(isConfirmed => !isConfirmed ? this.capitalCallInvestmentsTableService.revertChanges(correspondingInvestment.id) : ''));
    }

    return of(true);
  }

  /**
   * Disable form controls if the payment process began or completed through Covercy
   */
  private disableFormValuesIfNeeded() {
    this.investmentFormArray.controls.forEach(investmentFormGroup => {
      const investment = investmentFormGroup.value.correspondingInvestment as InvestmentReqRes;
      investmentFormGroup.get('dueDate').disable({emitEvent: false});
      investmentFormGroup.get('amount').disable({emitEvent: false});
      // invested through Covercy
      // the LP completed the process on PayApp
      // the LP completed the process on LP portal
      if ((investment.status === InvestmentStatus.Invested && investment.paymentSettlementPlatform !== PaymentPlatform.External) ||
        investment.isOrderCreated ||
        investment.paymentSettlementPlatform === PaymentPlatform.achDebit ||
        investment.status === InvestmentStatus.Declined) {
        investmentFormGroup.disable({emitEvent: false});
        // Invested external
      } else if (!investment.paymentRequestSendDate ||
                  (investment.status === InvestmentStatus.Invested && investment.paymentSettlementPlatform === PaymentPlatform.External)) {
        investmentFormGroup.get('transferDate').disable({emitEvent: false});
      }
    });
  }

  /**
   * Get Investment's status for tracked table
   * @param investment Investment details
   */
  private getTrackedInvestmentStatus(investment: InvestmentReqRes): TrackStatus {
    if (investment.status === InvestmentStatus.Signed) {
      if (!!investment.transferDate) {
        return TrackStatus.Transferred;
      } else if (!!investment.capitalCallViewDate) {
        return TrackStatus.Viewed;
      } else if (!!investment.paymentRequestSendDate){
        return TrackStatus.NotViewed;
      } else {
        return TrackStatus.NotCalled;
      }
    } else if (investment.status === InvestmentStatus.Invested) {
      return TrackStatus.Invested;
    } else if (investment.status === InvestmentStatus.Declined) {
      return TrackStatus.Declined;
    }
  }
}
