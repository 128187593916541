import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SearchOptionsRequest } from 'src/app/shared/models/SearchOptionsRequest.model';
import { SearchOptionsResponse } from 'src/app/shared/models/SearchOptionsResponse.model';
import { ReportFAQReqRes } from 'src/app/shared/models/reportFAQReqRes.model';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class GpReportFaqDataService {

  constructor(private http: HttpService) { }

  generateEndpointPrefix(assetId: number, reportId: number) {
    return `assets/${assetId}/reports/${reportId}/faqs/`;
  }

  protected getSearchOptionsToQuerystring(options: SearchOptionsRequest): string {
    return `?filter=${options.filter ? options.filter : ''}&sortOrder=${options.sortOrder ? options.sortOrder : ''}&pageNumber=${
      options.pageNumber ? options.pageNumber : 0
      }&pageSize=${options.pageSize}`;
  }

  public getList(assetId: number, reportId: number, options: SearchOptionsRequest = null): Observable<SearchOptionsResponse<ReportFAQReqRes>> {
    if (!options) {
      options = new SearchOptionsRequest();
    }
    const querystring = this.getSearchOptionsToQuerystring(options);
    return this.http.getTyped<SearchOptionsResponse<ReportFAQReqRes>>(this.generateEndpointPrefix(assetId, reportId) + querystring).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public create(assetId: number, reportId: number, model: ReportFAQReqRes): Observable<ReportFAQReqRes> {
    return this.http.postTyped<ReportFAQReqRes>(`${this.generateEndpointPrefix(assetId, reportId)}`, model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public update(assetId: number, reportId: number, model: ReportFAQReqRes): Observable<ReportFAQReqRes> {
    return this.http.putTyped<ReportFAQReqRes>(`${this.generateEndpointPrefix(assetId, reportId)}${model.id}`, model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public createAndUpdateBulk(assetId: number, reportId: number, model: ReportFAQReqRes[]): Observable<ReportFAQReqRes> {
    return this.http.postTyped<ReportFAQReqRes>(`${this.generateEndpointPrefix(assetId, reportId)}bulk`, model).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public delete(assetId: number, reportId: number, faqId: number): Observable<void> {
    return this.http.deleteTyped<void>(this.generateEndpointPrefix(assetId, reportId) + faqId).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
