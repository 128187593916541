import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {startWith, switchMap, shareReplay, map, catchError} from 'rxjs/operators';

import {EditAssetStepBase, EditAssetTabNumber} from '../EditAssetStepBaseAndInterface';
import {ResourceService} from 'src/app/services/resource.service';
import {GpBankAccountDataService} from 'src/app/services/gp/gp-bank-account-data.service';
import {EditAssetService} from '../edit-asset.service';
import {ClientBankAccountReqRes} from 'src/app/dashboard/models/bankAccount.model';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {enterAnimation} from 'src/app/shared/animations';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {RoutingService} from 'src/app/services/routing.service';
import {BaseResponseStatus} from '../../../../../shared/models/BaseResponseDto.model';
import {ErrorMatcher, ErrorType} from "../../../../../shared/errors/ErrorMatcher";

@Component({
  selector: 'terra-edit-asset-tab-bank-account',
  templateUrl: './edit-asset-tab-bank-account.component.html',
  styleUrls: ['./edit-asset-tab-bank-account.component.scss'],
  animations: [enterAnimation]
})
export class EditAssetTabBankAccountComponent extends EditAssetStepBase implements OnInit {
  noAccessMessage = '';
  readonly TAB_NUMBER: EditAssetTabNumber = 4;

  // A way to refer to a view element that has ngIf on it
  private collectDepositNotice: ElementRef;

  @ViewChild('collectDepositNotice') set content(content: ElementRef) {
    this.collectDepositNotice = content;
    if (content && content.nativeElement) {
      TerraUtils.scrollToElement(content.nativeElement);
    }
  }

  selectedCurrency = this.editAssetService.selectedCurrency;
  currentHoldingId = this.editAssetService.editAssetDialogContext?.asset?.id;

  refreshBankAccounts$ = new Subject<void>();

  bankAccounts$ = this.editAssetService.getTabForm(1).get('currency').valueChanges.pipe(
    startWith(this.editAssetService.selectedCurrency),
    switchMap((currency: CurrencyModel) => this.gpBankAccountDataService.getListByTargetCurrency(currency.id, this.currentHoldingId)),
    shareReplay(1)
  );

  // If more stuff should cause the step loader to be displayed, combine them into that observable.
  stepIsLoading$ = this.bankAccounts$.pipe(
    map(accounts => !!accounts),
    catchError(async err => {
      if (ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)) {
        this.noAccessMessage = err.responseMessage;
        return true;
      }
    })
  );

  isUpdateBankAccountAllowed = this.editAssetService.isUpdateBankAccountAllowed;

  assetHasBankAccount = !!this.dialogContext.fundraisingDetails.clientBankAccount || this.dialogContext.fundraisingDetails.unitBankAccount;

  constructor(
    private resourceService: ResourceService,
    private editAssetService: EditAssetService,
    private router: Router,
    public routingService: RoutingService,
    public gpBankAccountDataService: GpBankAccountDataService
  ) {
    super(editAssetService);
  }

  gotoBankAccount() {
    this.editAssetService.dialogRef.close(undefined);
    this.editAssetService.dialogRef.afterClosed().subscribe(() => {
      this.router.navigateByUrl(this.routingService.bankAccountsPage('gp'));
    });
  }

  ngOnInit() {
  }

  getCountryById(countryId: number): CountryModel {
    return this.resourceService.getCountryById(countryId);
  }

  bankAccountComparer(b1: ClientBankAccountReqRes, b2: ClientBankAccountReqRes) {
    return b1 && b2 ? b1.id === b2.id : b1 === b2;
  }
}
