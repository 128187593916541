<div class="prepare-block">
  <div class="">
    <h4>Name Files Correctly Before Uploading.</h4>
    <p>To facilitate precise bulk document matching and sharing with the right investor, it is essential to
      include the investor's <span class="bolder">Entity Name</span> or <span class="bolder">Accounting ID</span> in the document name, as it's listed in the Contacts
      section.</p>
    <img src="/assets/images/upload-documents/before.png" alt="Prepare" />
  </div>
  <div class="">
    <h4>Example for Proper File Naming</h4>
    <p></p>
    <img src="/assets/images/upload-documents/file-names.png" alt="Prepare names" />
  </div>
</div>
<mat-checkbox class="skip-step" color="primary" (change)="onSkip.emit($event?.checked)">
  Skip this step next time
</mat-checkbox>
