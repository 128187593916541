<terra-main-nav [showNav]="false"></terra-main-nav>

<terra-loader *ngIf="isLoading"></terra-loader>

<div *ngIf="!isLoading" class="page-content">
  <h2 class="title-underline">The basics</h2>

  <p class="page-description">Tell us about your company</p>

  <form
    (ngSubmit)="submit()"
    [formGroup]="pageForm"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="form-grid terra-error-style">
      <h3 class="form-section-title">Company profile</h3>

      <!-- Organization Name -->
      <div class="terra-form-field-wrapper">
        <label for="txtOrganizationName" class="text-input-label"
        >Company name</label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            id="txtOrganizationName"
            formControlName="organizationName"
            autocomplete="organization-name"
            class="lo_sensitive"
          />
          <mat-error
            *ngIf="pageForm.get('organizationName').hasError('required')"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Logo -->
      <terra-image-uploader-thumbnail formControlName="logoDocument" [isSubmitted]="isSubmitted">
        <label class="text-input-label">Upload your logo (optional)</label>
      </terra-image-uploader-thumbnail>

      <p class="file-uploader-hint new-column">
        File format must be JPG, JPEG or PNG (20 MB max file size)
      </p>

      <h3 class="form-section-title">Company Address</h3>

      <!-- <div class="terra-form-field-wrapper">
        <label for="txtOrganizationName" class="text-input-label">Address</label>
        <terra-google-map-search-input (setAddress)="addressSelected($event)"></terra-google-map-search-input>

      </div> -->

      <terra-address-form
        [externalForm]="pageForm"
        partialFormName="address"
        class="full-width form-grid-new"
        [formSettings]="addressFormSettings"
      >
      </terra-address-form>

      <!--      <h3 class="form-section-title">Import your contacts (optional)</h3>-->

      <!--      &lt;!&ndash; Import button &ndash;&gt;-->
      <!--      <button type="button" (click)="import()" mat-flat-button color="accent" class="import-button tall-button">-->
      <!--        <mat-icon svgIcon="terra-import" class="notranslate"></mat-icon>-->
      <!--        Import-->
      <!--      </button>-->

      <!-- Error message -->
      <div *ngIf="isSubmitted" class="full-width">
        <p *ngIf="pageForm.invalid" class="error-message">
          Please review and correct the fields marked in red
        </p>
        <p *ngIf="isGeneralServerError" class="error-message">
          {{ generalServerErrorMessage }}
        </p>
        <p *ngIf="isFileNotSupported" class="error-message">
          {{ fileNotSupportedError }}
        </p>
      </div>
    </div>

    <button type="submit" mat-flat-button color="primary" class="page-cta">
      Create Account
    </button>
  </form>
</div>
