<terra-dialog-header>
  <h1>Select investing entity
  </h1>
</terra-dialog-header>

<mat-dialog-content>

  <label class="text-input-label">
    Please select from available investing entities (this can be modified under the Edit Contact screen):
  </label>
  <mat-radio-group [formControl]="selected" class="radio-buttons">
    <mat-radio-button *ngFor="let ie of investingEntities" [value]="ie" color="primary">
      {{ ie.name }}
    </mat-radio-button>
  </mat-radio-group>


</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" [mat-dialog-close]="selected.value" [disabled]="!selected.value" mat-flat-button color="primary"
    class="save-button">Select</button>
  <button type="button" mat-button class="cancel-button" [mat-dialog-close]="undefined">
    Cancel
  </button>
</mat-dialog-actions>
