<terra-dialog-header>
  <h1>{{data.title}}</h1>
</terra-dialog-header>

<mat-dialog-content>
  <p>{{data.description}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end" class="dialog-actions">
  <button type="button" mat-stroked-button color="warn" class="cancel-button"
    [mat-dialog-close]="undefined">Cancel</button>
  <button type="submit" autofocus mat-flat-button color="warn" class="save-button" [mat-dialog-close]="true">
    {{data.confirmButton || 'Confirm'}}
  </button>
</mat-dialog-actions>
