import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {combineLatest, EMPTY, Observable, of, Subject} from 'rxjs';
import {catchError, map, shareReplay, startWith, switchMap, tap} from 'rxjs/operators';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {CreateFundStepBase} from '../CreateFundStepBaseAndInterface';
import {CreateFundService, CreateFundStepNumber} from '../create-fund.service';
import {EditBankAccountComponent} from 'src/app/dashboard/bankAccounts/components/edit-bank-account/edit-bank-account.component';
import {GpBankAccountDataService} from 'src/app/services/gp/gp-bank-account-data.service';
import {ClientBankAccountReqRes} from 'src/app/dashboard/models/bankAccount.model';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {ResourceService} from 'src/app/services/resource.service';
import {enterAnimation} from 'src/app/shared/animations';
import InvestingEntityType from 'src/app/dashboard/models/InvestingEntityType.enum';
import {EditBankAccountContext} from 'src/app/dashboard/bankAccounts/components/edit-bank-account/EditBankAccountContext.model';
import {ErrorMatcher, ErrorType} from "../../../../../shared/errors/ErrorMatcher";

@Component({
  selector: 'terra-create-fund-bank-account-step',
  templateUrl: './create-fund-bank-account-step.component.html',
  styleUrls: ['./create-fund-bank-account-step.component.scss'],
  animations: [enterAnimation],
})
export class CreateFundBankAccountStepComponent extends CreateFundStepBase implements OnInit {
  noAccessMessage = '';
  readonly STEP_NUMBER: CreateFundStepNumber = 4;

  // contst
  InvestingEntityType = InvestingEntityType;

  selectedCurrency = this.createFundService.selectedCurrency;

  refreshBankAccounts$ = new Subject<void>();

  // tslint:disable:deprecation
  bankAccounts$ = this.refreshBankAccounts$.pipe(
    startWith(null),
    switchMap(() => this.gpBankAccountDataService.getListByTargetCurrency(this.selectedCurrency.id)),
    shareReplay(1)
  );

  // If more stuff should cause the step loader to be displayed, combine them into that observable.
  stepIsLoading$ = this.bankAccounts$.pipe(
    map(accounts => !!accounts),
    catchError(async err => {
      if (ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)) {
        this.noAccessMessage = err.responseMessage;
        return true;
      }
    })
  );

  constructor(
    private resourceService: ResourceService,
    private createFundService: CreateFundService,
    private gpBankAccountDataService: GpBankAccountDataService,
    private dialog: MatDialog) {
    super(createFundService);
  }

  ngOnInit() {
    this.createFundService.currentStep$.next(this.STEP_NUMBER);
  }

  AddBankAccount() {
    const dialogConfig = new MatDialogConfig<EditBankAccountContext>();
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'create-bank-account-dialog';
    dialogConfig.data = {
      enablePreferredCurrency: true,
      isAnOrganizationAccountDefault: false,
    } as EditBankAccountContext;
    const createdBankAccount$: Observable<ClientBankAccountReqRes> = this.dialog.open(EditBankAccountComponent, dialogConfig)
      .afterClosed().pipe(
        switchMap(createdAccount => createdAccount ? of(createdAccount) : EMPTY),
        tap(() => this.refreshBankAccounts$.next()));

    combineLatest(createdBankAccount$, this.bankAccounts$).pipe(untilComponentDestroyed(this)).subscribe(
      ([createdBankAccount, bankAccounts]) => {
        const bankAccountToSelect = bankAccounts.find(account => account.id === createdBankAccount.id);
        if (bankAccountToSelect) {
          this.pageForm.get('bankAccount').setValue(bankAccountToSelect);
        }
      }
    );
  }

  getCountryById(conutryId: number): CountryModel {
    return this.resourceService.getCountryById(conutryId);
  }


  bankAccountComparer(bank1: ClientBankAccountReqRes, bank2: ClientBankAccountReqRes) {
    if (bank1 && bank2) {
      return bank1.id === bank2.id;
    }
  }
}
