import FundInvestmentStrategyType from '../enums/FundInvestmentStrategyType.enum';
import {HoldingReportReqRes} from './HoldingReportReqRes';


export class FundReportReqRes extends HoldingReportReqRes{
  capitalInformation: string;
  acquisitionsDispositions: string;
  holdingUpdates: string;
  administrativeUpdates: string;
  investmentStrategyType: FundInvestmentStrategyType;
}

