import { Injectable } from '@angular/core';
import { map, shareReplay, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';

import { DistributionReqRes } from './DistributionReqRes.model';
import { GpDistributionDataService } from '../../../../services/gp/gp-distribution-data.service';
import { GpBankAccountDataService } from '../../../../services/gp/gp-bank-account-data.service';
import DistributionTransactionPurpose from '../../../../shared/enums/DistributionTransactionPurpose.enum';
import { DistributionTransferReqRes } from './DistributionTransferReqRes.model';
import {
    BasePhoneVerificationStrategy
} from '../../../../shared/models/phone-verification-strategies/base-phone-verification-strategy.model';
import { PhoneVerificationReq } from '../../../../shared/components/phone-verification-dialog/PhoneVerificationReq.model';
import {
    PhoneVerificationReqRes
} from '../../../../shared/components/phone-verification-dialog/PhoneVerificationReqRes.model';
import { WaterfallsCreateDto } from './WaterfallsCreateDto.model';
import { WaterfallsReadDto } from './WaterfallsReadDto.model';
import ReportPeriod from '../../../../shared/enums/ReportPeriod.enum';
import FeatureFlags from '../../../../account/my-account/model/FeatureFlags.enum';
import { UserService } from '../../../../services/shared/user.service';
import { LastDistributionReqRes } from './LastDistributionReqRes.model';
import { TerraUtils } from 'src/app/shared/TerraUtils';

@Injectable()
export class DistributionService extends BasePhoneVerificationStrategy {
    distributionId$ = this.route.params.pipe(map(params => params.distributionId ? +params.distributionId : null), shareReplay(1));
    holdingId$ = this.route.params.pipe(map(params => params.id ? +params.id : null), shareReplay(1));
    distribution$: Observable<DistributionReqRes> = combineLatest([this.holdingId$, this.distributionId$]).pipe(
        switchMap(([holdingId, distributionId]) => {
            if (distributionId) {
                return this.distributionDataService.getById(holdingId, distributionId);
            } else {
                return of(null);
            }
        }),
        shareReplay(1));
    lastDistribution$: Observable<LastDistributionReqRes> = this.holdingId$.pipe(
        switchMap(holdingId => {
            if (holdingId) {
                return this.distributionDataService.getLastDistributionForHolding(holdingId);
            } else {
                return of(null);
            }
        }),
        shareReplay(1));
    transfers$ = this.holdingId$.pipe(
        switchMap(holdingId => this.distributionDataService.getTransfersForHolding(holdingId)),
        shareReplay(1)
    );
    distributionUsers$ = this.holdingId$.pipe(
        switchMap(holdingId => this.distributionDataService.distributionUsers(holdingId)),
        shareReplay(1)
    );

    bankAccountsPerInvestingEntityId$ = this.holdingId$.pipe(
        switchMap(holdingId => this.bankAccountDataService.getListByHolding(holdingId)),
        shareReplay(1)
    );

    clientBankAccountsPerInvestingEntityId$ = this.holdingId$.pipe(
        switchMap(holdingId => this.bankAccountDataService.getInvestorClientListByHolding(holdingId)),
        shareReplay(1)
    );

    unitBankAccountsPerInvestingEntityId$ = this.holdingId$.pipe(
        switchMap(holdingId => this.bankAccountDataService.getInvestorUnitAccountListByHolding(holdingId)),
        shareReplay(1)
    );
    /**
     * Gets reasons for transfers of existing distribution
     */
    reasonsForTransfer$ = this.distribution$.pipe(map(distribution => {
        return this.getReasonsForTransfer(distribution.distributionTransfers);
    }));

    distributionCreBanksList$ = this.holdingId$.pipe(
        switchMap(holdingId => this.bankAccountDataService.getDistributionCreBankAccounts(holdingId)),
        shareReplay(1)
    );

    creBanksListByHolding$ = this.holdingId$.pipe(
        switchMap(holdingId => this.bankAccountDataService.getCreBanksListByHolding(holdingId)),
        map(bankAccounts => bankAccounts.filter(ba => ba.isDistributionEnabled)),
        shareReplay(1)
    );

    OrganizationTypeCreBankAccounts$ = this.bankAccountDataService.getOrganizationTypeCreBankAccounts();
    isWaterfallAllowed$ = this.userService.userHasFeatureFlag(FeatureFlags.WaterfallDistributions);
    requiredWaterfallReCalculation$ = this.distribution$.pipe(
        withLatestFrom(this.isWaterfallAllowed$),
        switchMap(([distribution, isWaterfallAllowed]) => {
            if (isWaterfallAllowed && !!distribution.waterfallMetaFileLinkId) {
                return this.distributionChangedSinceWaterfallCalculation();
            } else {
                return of(false);
            }
        })
    );

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private distributionDataService: GpDistributionDataService,
        private bankAccountDataService: GpBankAccountDataService,
        private userService: UserService
    ) {
        super();
    }

    SetPhoneVerification(): Observable<PhoneVerificationReqRes> {
        return combineLatest(([this.holdingId$, this.distributionId$])).pipe(
            switchMap(([holdingId, distributionId]) => {
                return this.distributionDataService.SetPhoneVerification(holdingId, distributionId);
            })
        );
    }

    sendVerificationCode(): Observable<PhoneVerificationReqRes> {
        return combineLatest(([this.holdingId$, this.distributionId$])).pipe(
            switchMap(([holdingId, distributionId]) => {
                return this.distributionDataService.sendVerificationCode(holdingId, distributionId);
            })
        );
    }

    resendVerificationCode(): Observable<PhoneVerificationReqRes> {
        return combineLatest(([this.holdingId$, this.distributionId$])).pipe(
            switchMap(([holdingId, distributionId]) => {
                return this.distributionDataService.sendVerificationCode(holdingId, distributionId);
            })
        );
    }

    verifyPhone(request: PhoneVerificationReq): Observable<PhoneVerificationReqRes> {
        return combineLatest(([this.holdingId$, this.distributionId$])).pipe(
            switchMap(([holdingId, distributionId]) => {
                return this.distributionDataService.verifyPhone(holdingId, distributionId, request);
            })
        );
    }

    /**
     * Gets a list of reasons for transfers
     * @param distributionTransfers List of distribution transfers
     */
    public getReasonsForTransfer(distributionTransfers: DistributionTransferReqRes[]): DistributionTransactionPurpose[] {
        if (!!distributionTransfers) {
            const reasonsForTransactions = new Set<DistributionTransactionPurpose>();
            distributionTransfers.forEach(dt => {
                dt.distributionTransfersDetails
                    .map(dtd => dtd.reasonForTransaction)
                    .forEach(reasonForTransaction => {
                        reasonsForTransactions.add(reasonForTransaction);
                    });
            });
            return [...reasonsForTransactions].length === 1 ? [...reasonsForTransactions] : [...reasonsForTransactions].filter(x => x !== DistributionTransactionPurpose.None);
        }

        return [];
    }

    /**
     * Sums DistributionTransferDetails amounts into DistributionTransfer
     */
    public updateTotalTransferAmounts(transfers: DistributionTransferReqRes[]) {
        transfers.forEach(transfer => {
            const transferDetails = transfer.distributionTransfersDetails;
            transfer.amountGross = transferDetails.reduce((previousValue, currentTransferDetails) => currentTransferDetails.amountGross + previousValue, 0);
            transfer.amountNet = transferDetails.reduce((previousValue, currentTransferDetails) => currentTransferDetails.amountNet + previousValue, 0);
            transfer.amountAfterTaxes = transferDetails.reduce((previousValue, currentTransferDetails) => currentTransferDetails.amountAfterTaxes + previousValue, 0);
            transfer.taxes = transferDetails.reduce((previousValue, currentTransferDetails) => currentTransferDetails.taxes + previousValue, 0);
            transfer.adjustments = transferDetails.reduce((previousValue, currentTransferDetails) => currentTransferDetails.adjustments + previousValue, 0);
            transfer.gpPromote = transferDetails.reduce((previousValue, currentTransferDetails) => currentTransferDetails.gpPromote + previousValue, 0);
            transfer.amountAfterGpPromote = transferDetails.reduce((previousValue, currentTransferDetails) => currentTransferDetails.amountAfterGpPromote + previousValue, 0);
        });
    }

    /**
     * Whether or not this distribution transfer(s) belongs to multi type distribution
     * @param distributionTransfers distribution transfer or array of distribution transfers
     */
    public isMultiTypeDistribution(distributionTransfers: DistributionTransferReqRes | DistributionTransferReqRes[]): boolean {
        const transfersArray = distributionTransfers as DistributionTransferReqRes[];
        const transfer = distributionTransfers as DistributionTransferReqRes;
        if (!!transfersArray) {
            return transfersArray.some(t => t.distributionTransfersDetails.length > 1);
        } else {
            return transfer.distributionTransfersDetails.length > 1;
        }
    }

    public generateWaterfalls(distributionAmount: number, distributionPeriod: string, periodType: ReportPeriod): Observable<WaterfallsReadDto> {
        let startDate;
        let endDate;
        let distributionPeriodText;
        const periodSplitBySpace = distributionPeriod.split(' ');
        if (periodType === ReportPeriod.Quarterly) {
            const [quarter, year] = periodSplitBySpace;
            [startDate, endDate] = this.getStartAndEndDates(+year, +quarter);
            distributionPeriodText = `Q${quarter} ${year}`;
        } else if (periodType === ReportPeriod.Custom) {
            distributionPeriodText = 'Months';
            [startDate, endDate] = periodSplitBySpace;
        }

        const waterfallsRequest = new WaterfallsCreateDto();
        waterfallsRequest.distributionAmount = distributionAmount;
        waterfallsRequest.distributionPeriod = distributionPeriodText;
        waterfallsRequest.distributionStartDate = startDate;
        waterfallsRequest.distributionEndDate = endDate;
        waterfallsRequest.distributionDate = new Date();
        return this.holdingId$.pipe(
            take(1),
            switchMap(holdingId => this.distributionDataService.generateWaterfalls(holdingId, waterfallsRequest))
        );
    }

    /**
     * Get distribution start and end date base on distribution quarter
     */
    public getStartAndEndDates(year: number, quarter: number): [Date, Date] {
        const getDate = (month, day) => TerraUtils.forceUtc(new Date(year, month, day));
        const response: [Date, Date] = [null, null];
        switch (quarter) {
            case 1:
                response[0] = getDate(0, 1);
                response[1] = getDate(2, 31);
                break;
            case 2:
                response[0] = getDate(3, 1);
                response[1] = getDate(5, 30);
                break;
            case 3:
                response[0] = getDate(6, 1);
                response[1] = getDate(8, 30);
                break;
            case 4:
                response[0] = getDate(9, 1);
                response[1] = getDate(11, 31);
                break;
        }
        return response;
    }

    public getQuarterly(dateStart, dateEnd: Date): [number, number] {
        dateStart = TerraUtils.forceUtcToLocal(dateStart);
        dateEnd = TerraUtils.forceUtcToLocal(dateEnd);
        const checkQuarter = (ds: Date, de: Date, monthStart: number, dayEnd: number, monthEnd: number) =>
            ds.getDate() === 1 && ds.getMonth() === monthStart
            && de.getDate() === dayEnd && de.getMonth() === monthEnd;

        if (checkQuarter(dateStart, dateEnd, 0, 31, 2))
            return [1, dateStart.getFullYear()];

        if (checkQuarter(dateStart, dateEnd, 3, 30, 5))
            return [2, dateStart.getFullYear()];

        if (checkQuarter(dateStart, dateEnd, 6, 30, 8))
            return [3, dateStart.getFullYear()];

        if (checkQuarter(dateStart, dateEnd, 9, 31, 11))
            return [4, dateStart.getFullYear()];
    }

    private distributionChangedSinceWaterfallCalculation(): Observable<boolean> {
        return combineLatest([this.distributionId$, this.holdingId$]).pipe(
            switchMap(([distributionId, holdingId]) => this.distributionDataService.distributionChangedSinceWaterfallCalculation(holdingId, distributionId))
        );
    }

    public updateGpPromoteAmounts(gpDistributionTransfer: DistributionTransferReqRes, investorsDistributionTransfer: DistributionTransferReqRes[]) {
        if (!!gpDistributionTransfer && !!investorsDistributionTransfer && investorsDistributionTransfer.length > 0 &&
            investorsDistributionTransfer.some(dt => dt.distributionTransfersDetails.some(dtd => dtd.gpPromote !== null))) {
            for (let i = 0; i < gpDistributionTransfer.distributionTransfersDetails.length; i++) {
                const currentGpHurdleReasonForTransfer = gpDistributionTransfer.distributionTransfersDetails[i].reasonForTransaction;
                gpDistributionTransfer.distributionTransfersDetails[i].amountGross = investorsDistributionTransfer.reduce((previousValue, currentTransferDetails) => (currentTransferDetails.distributionTransfersDetails.find(dtd => dtd.reasonForTransaction === currentGpHurdleReasonForTransfer)?.gpPromote ?? 0) + previousValue, 0);
                if (gpDistributionTransfer.distributionTransfersDetails[i].amountGross > 0) {
                    gpDistributionTransfer.distributionTransfersDetails[i].amountAfterTaxes = gpDistributionTransfer.distributionTransfersDetails[i].amountGross - (gpDistributionTransfer.distributionTransfersDetails[i].taxes ?? 0);
                    gpDistributionTransfer.distributionTransfersDetails[i].amountNet = gpDistributionTransfer.distributionTransfersDetails[i].amountAfterTaxes + gpDistributionTransfer.distributionTransfersDetails[i].adjustments ?? 0;
                } else {
                    gpDistributionTransfer.distributionTransfersDetails[i].amountAfterTaxes = 0;
                    gpDistributionTransfer.distributionTransfersDetails[i].amountNet = 0;
                }
            }
        }
    }
}
