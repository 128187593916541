<ng-container *ngIf="{
    bankAccounts: creBankAccounts$ | async,
    transactions: pageData$ | async,
    searchOptions: searchOptions$ | async,
    pageRowsCount: pageRowsCount$ | async,
    totalRowsCount : totalRowsCount$ | async ,
    isLoading: isLoading$ | async,
    showClearButton: showClearFilter$ | async
} as data">

  <!-- <section class="transactions-title-wrapper">
      <h3 class="transactions-title">Last Transactions</h3>
      <button type="button" mat-icon-button aria-label="More Options">
          <mat-icon *ngIf="false">more_vert</mat-icon>
      </button>
  </section> -->

  <section class="filter-form">
    <form [formGroup]="transactionsFilterForm">
      <!--            <div class="terra-form-field-wrapper date-range">-->
      <!--              <mat-form-field appearance="outline">-->
      <!--                <mat-date-range-input formGroupName="transactionDateRange"-->
      <!--                                      [rangePicker]="transactionDateRangePicker">-->
      <!--                  <input matStartDate formControlName="transactionStartDate" placeholder="Start date">-->
      <!--                  <input matEndDate formControlName="transactionEndDate" placeholder="End date"-->
      <!--                         (dateChange)="onTransactionEndDateChanged($event)">-->
      <!--                </mat-date-range-input>-->
      <!--                <mat-datepicker-toggle matSuffix [for]="transactionDateRangePicker"></mat-datepicker-toggle>-->
      <!--                <mat-date-range-picker #transactionDateRangePicker></mat-date-range-picker>-->
      <!--                <mat-error-->
      <!--                  *ngIf="transactionDateRangeGroup.controls.transactionStartDate.hasError('matStartDateInvalid')">-->
      <!--                  Invalid start-->
      <!--                  date-->
      <!--                </mat-error>-->
      <!--                <mat-error-->
      <!--                  *ngIf="transactionDateRangeGroup.controls.transactionEndDate.hasError('matEndDateInvalid')">-->
      <!--                  Invalid end date-->
      <!--                </mat-error>-->
      <!--              </mat-form-field>-->
      <!--            </div>-->

      <div class="terra-form-field-wrapper date-range">
        <terra-date-range-picker class="filter-form-field date-picker" (dateRangeSelected)="dateRangeChanged($event)" #dateRange
                                 [initialStartDate]="transactionDateRangeGroup.controls.transactionStartDate.value"
                                 [initialEndDate]="transactionDateRangeGroup.controls.transactionEndDate.value">
        </terra-date-range-picker>
      </div>

      <div class="terra-form-field-wrapper account-name">
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="selectedAccount" placeholder="Account" [ngModelOptions]="{standalone: true}" #accountSelectControl
                      (selectionChange)="onAccountChanged($event)">
            <mat-option value="">All Accounts</mat-option>
            <mat-option *ngFor="let creBankAccount of data.bankAccounts" [value]="creBankAccount">
              {{creBankAccount.accountNickname}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="terra-form-field-wrapper transaction-type">
        <mat-form-field appearance="outline">
          <mat-select formControlName="transactionType" placeholder="Direction"
                      (selectionChange)="onTransactionTypeChanged($event)">
            <mat-option value="Debit">Debit</mat-option>
            <mat-option value="Credit">Credit</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="terra-form-field-wrapper name-search">
        <mat-form-field appearance="outline" matTooltip="Search on Description, Beneficiary and Amount fields" matTooltipPosition="above">
          <input matInput formControlName="freeText" placeholder="Search by Description"/>
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>

      <!-- clear button -->
      <button class="clear-filter-button tall-button" type="button" mat-button aria-label="Close" *ngIf="data.showClearButton"
              (click)="clearForm()">
        Clear
      </button>

      <terra-export-transactions-menu class="export-menu" *ngIf="(searchOptions$ | async) as searchOptions"
                                      [isDisabled]="!data.transactions?.length"
                                      [exportTransactionsParameters]="{searchOptions, bankAccountId: selectedAccount.id, bankType: 'unit'}" (exportFormat)="onExportSelected($event)"
      ></terra-export-transactions-menu>
    </form>
  </section>

  <section class="page-loader" *ngIf="data.isLoading">
    <terra-loader></terra-loader>
  </section>
  <section class="transactions-table" *ngIf="data.transactions?.length && !data.isLoading">
    <table mat-table class="cov-basic-table" [dataSource]="data.transactions" matSort
           (matSortChange)="sortData($event)" [matSortDirection]="data.searchOptions.sortOrder"
           [matSortActive]="data.searchOptions.orderBy"
           matSortDisableClear>

      <!-- Account Name  -->
      <ng-container matColumnDef="accountName">
        <th mat-header-cell *matHeaderCellDef>Account Name</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive">
                    <span>{{ element.accountName }}
                      <!-- <span class="small-line"> /{{element.bankAccountType | enumString : CreAccountType }}</span> -->
                    </span>
        </td>
      </ng-container>

      <!-- Transaction Type -->
      <ng-container matColumnDef="transactionType">
        <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive">
          <div class="transaction-type-wrapper"
               [ngClass]="element.direction === transactionDirection.Debit ? 'dir-out' : 'dir-in'">
            <mat-icon class="action-icon notranslate">{{element.direction === transactionDirection.Debit ?
              'logout' : 'login'}}
            </mat-icon>
            <span>{{ element.direction }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Transaction Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">Date</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive"> {{ element.date | date: dateFormat }}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive"> {{ element.description }}</td>
      </ng-container>

      <!-- Payee -->
      <ng-container matColumnDef="payee">
        <th mat-header-cell *matHeaderCellDef>Beneficiary</th>
        <td mat-cell *matCellDef="let element">{{ element.payee }}</td>
      </ng-container>

      <!-- Amount (USD) -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element">

                    <span class="cell-inner dir-out" *ngIf="element.direction === transactionDirection.Debit">
                        (${{ (element.amount / 100) | number : '1.2-2'}})
                    </span>

          <span class="cell-inner dir-in" *ngIf="element.direction === transactionDirection.Credit">
                        ${{ (element.amount / 100) | number : '1.2-2'}}
                    </span>
        </td>
      </ng-container>

      <!-- Balance (USD) -->
      <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef>Balance</th>
        <td mat-cell *matCellDef="let element">
          <span class="bold">${{(element.balance / 100) | number : '1.2-2' }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator (page)="pageChange($event)" [length]="data.totalRowsCount" [hidePageSize]="true"
                   [pageSize]="data.searchOptions.pageSize" [showFirstLastButtons]="true"
                   [pageIndex]="data.searchOptions.pageNumber" *ngIf="data.totalRowsCount > 0">
    </mat-paginator>
  </section>

  <section *ngIf="!data.transactions?.length && !data.isLoading" class="empty-state">
    <div class="empty-state-inner">
      <div class="empty-state-img">
        <img src="assets/images/general/empty_state.svg"/>
      </div>
      <div class="empty-state-msg">
        <span class="big-line">No transactions found.</span>
        <span class="medium-line">Refine your search and try again.</span>
      </div>
    </div>
  </section>
</ng-container>
