import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig} from '@abacritt/angularx-social-login';
import {of} from 'rxjs';
import {AppSettingsService} from './app-settings.service';

export const SocialAuthServiceConfigFactory = (appSettingsService: AppSettingsService) => {
  let providers = [];
  providers.push({
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(appSettingsService.ssoGoogleClientId),
  });
  providers.push({
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(appSettingsService.ssoFacebookClientId),
  });

  const config = {
    autoLogin: false,
    providers: providers,
  } as SocialAuthServiceConfig;

  return config;
};

export const SsoProvider = {
  provide: 'SocialAuthServiceConfig',
  useFactory: SocialAuthServiceConfigFactory,
  deps: [AppSettingsService]
};
