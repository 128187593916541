import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { UserService } from '../services/shared/user.service';
import { RoutingService } from '../services/routing.service';

@Injectable({
  providedIn: 'root'
})
export class GpOnboardingRequiredGuard  {

  constructor(private userService: UserService, private router: Router, private routingService: RoutingService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean |
    UrlTree |
    Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree> {

    return this.userService.accountDetails$.pipe(
      map(clientDetails => {
        // Onboarding is required if it wasn't already filled, and if the user is the owner of the organization he is in
        if (clientDetails.organizationDetails.ownerClientId === clientDetails.id && !clientDetails.isClientFilledPersonalDetails) {
          return this.router.parseUrl(this.routingService.gpOnboardingPage);
        }
        return true;
      })
    );
  }

}
