import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

export class MarkAsInvestedConfirmDialogParams extends ConfirmDialogParams {
  constructor(customPanelClass:string = null) {
    super(`Have the funds been deposited?`, 
    `<p>Make sure you've received this client's funds before marking as invested.</p>
    <p><strong>This action is irreversible</strong></p>`, 
    `Mark as Invested`,
    null,
    customPanelClass);
  }
}
