import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {SnackbarService} from '../../../../../services/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {debounceTime, filter, shareReplay, take} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import moment from 'moment';

import {GpHolding} from '../../GpHolding.model';
import {TerraUtils} from '../../../../../shared/TerraUtils';
import {DialogService} from '../../../../../services/dialog.service';
import {TransferOwnershipReqRes} from './TransferOwnershipReqRes.model';
import {HoldingFundraisingService} from '../holding-fundraising.service';
import {TerraNumberPipe} from '../../../../../shared/pipes/TerraNumber.pipe';
import {CustomValidators} from '../../../../../shared/validators/custom.validators';
import {InvestingEntityReqRes} from '../../../../models/InvestingEntityReqRes.model';
import {InvestorContactReqRes} from '../../../../contacts/models/investorContactReqRes.model';
import {InvestorOverviewResponse} from '../../investor-overview/InvestorsOverviewResponse.model';
import {GpInvestingEntityDataService} from '../../../../../services/gp/gp-investing-entity-data.service';
import {ConfirmDialogParams} from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';
import { CommitmentsEnabled } from 'src/app/shared/enums/CommitmentsEnabled.enum';

export interface OwnershipTransferParams {
  holding: GpHolding;
  investorsOverview: InvestorOverviewResponse[];
}

@Component({
  selector: 'terra-ownership-transfer-dialog',
  templateUrl: './ownership-transfer-dialog.component.html',
  styleUrls: ['./ownership-transfer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwnershipTransferDialogComponent extends OnDestroyMixin implements OnInit {
  fromLps$ = new BehaviorSubject<InvestingEntityReqRes[]>([]);
  toLps$ = new BehaviorSubject<InvestingEntityReqRes[]>([]);

  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;
  generalFrontendError = TerraUtils.consts.messages.GENERAL_FRONTEND_ERROR;
  isGeneralServerError: boolean;
  isFrontEndError: boolean;
  pageForm: UntypedFormGroup;
  allInvestingEntities: InvestingEntityReqRes[];
  isSubmitting$ = new BehaviorSubject<boolean>(false);
  hasCommitments$ = new BehaviorSubject<boolean>(false);

  minDate = new Date(2000, 0, 1);
  maxDate = new Date();

  constructor(
    private dialogRef: MatDialogRef<OwnershipTransferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OwnershipTransferParams,
    private holdingFundraisingService: HoldingFundraisingService,
    private fb: UntypedFormBuilder,
    private gpInvestingEntityDataService: GpInvestingEntityDataService,
    private snackbarService: SnackbarService,    
    private numberPipe: TerraNumberPipe,
    private dialogService: DialogService,
    private utilsService: UtilsService
  ) {
    super();
  }

  ngOnInit(): void {
    // this.dialogRef.addPanelClass('transfer-of-ownership-dialog');
    this.initForm();
  }

  onSubmit() {
    this.clearErrors();

    if (this.pageForm.invalid) {
      return this.isFrontEndError = true;
    }

    const model = this.generateSubmitModel();

    const dialogParams = new ConfirmDialogParams();
    dialogParams.panelClass = 'transfer-ownership-confirmation';
    dialogParams.actionLabel = `Confirm`;
    dialogParams.title = `Transfer of ownership`;
    dialogParams.description = this.generateConfirmationDescription(model);

    this.dialogService.confirmDialog(dialogParams).afterClosed().pipe(
      untilComponentDestroyed(this)
    ).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.setSubmitting(true);
        this.holdingFundraisingService.transferOwnership(model).pipe(
          untilComponentDestroyed(this)
        ).subscribe(contribution => {
            this.snackbarService.showGeneralMessage(`Ownership Transferred successfully`, 5);
            this.dialogRef.close(true);
          },
          error => {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            } else {
              this.isGeneralServerError = true;
            }
          }
        ).add(() => {
          // Called when both success and error are complete
          this.setSubmitting(false);
        });
      }
      // else {
      //   this.setSubmitting(false);
      // }
    });
  }

  incAmount() {
    let amount = this.numberPipe.parse(this.pageForm.get('amount').value);
    if (amount) {
      amount += 1;
      this.pageForm.get('amount').patchValue(this.numberPipe.transform(amount.toString(), 2));
    } else {
      this.pageForm.get('amount').patchValue(1);
    }
  }

  decAmount() {
    let amount = this.numberPipe.parse(this.pageForm.get('amount').value);
    if (amount && amount > 0) {
      amount -= 1;
      this.pageForm.get('amount').patchValue(this.numberPipe.transform(amount.toString(), 2));
    }
  }

  clearErrors() {
    this.isGeneralServerError = false;
    this.isFrontEndError = false;
  }

  onFocusOut(controlName: string) {
    const value = this.pageForm.get(controlName).value;
    if (typeof value === 'string') {
      this.pageForm.get(controlName).patchValue(null);
    }
  }

  displayFn(value?: number): string | undefined {
    // Get the view value from the options
    if (value) {
      const option = this['options']['_results'].find(x => x.value === value);
      return option ? option.viewValue : undefined;
    } else {
      return undefined;
    }
  }

  getContactName(contact: InvestorContactReqRes) {
    return contact ? `(${contact?.firstName} ${contact.lastName})` : ``;
  }

  private initForm() {
    this.initDataSources();
    this.pageForm = this.fb.group({
      fromLp: [{value: null, disabled: true}, [Validators.required]],
      toLp: [{value: null, disabled: true}, [Validators.required]],
      amount: [{value: null, disabled: true}, this.getNumberValidators()],
      percents: [{value: null, disabled: true}],
      commitmentAmount:[{value: null, disabled: true}, this.getNumberValidators()],
      commitmentPercents:[{value: null, disabled: true}],
      closingDate: [this.maxDate, Validators.required]
    });
    if (this.hasCommitments$.value) {
      this.pageForm.addControl('commitmentAmount', new UntypedFormControl({value: null, disabled: true}, this.getNumberValidators()));
      this.pageForm.addControl('commitmentPercents', new UntypedFormControl({value: null, disabled: true}, this.getNumberValidators()));
      this.pageForm.addValidators(this.createAtLeastOneShouldBeGreaterThan0Validator(['amount', 'commitmentAmount']));
    }

    this.pageForm.get('fromLp').valueChanges.pipe(
      debounceTime(300),
      untilComponentDestroyed(this)
    ).subscribe(value => {
      const toLp = this.pageForm.get('toLp').value || 0;
      if (!value) {
        this.pageForm.get('amount').disable();
        this.pageForm.get('percents').disable();
        if (this.hasCommitments$.value) {
          this.pageForm.get('commitmentAmount').disable();
          this.pageForm.get('commitmentPercents').disable();
        }
        this.reloadToLpDataSource();
        this.fromLps$.next(this.data.investorsOverview.map(io => io.investingEntity));
        return;
      } else if (typeof value === 'number') {
        this.fromLps$.next(this.data.investorsOverview.filter(io => io.investingEntity.id !== toLp && io.investingEntity.id === value).map(io => io.investingEntity));
        const fromLp = this.data.investorsOverview.find(io => io.investingEntity.id === value);
        const currentAmount = this.pageForm.get('amount').value || 0;
        this.pageForm.get('amount').setValidators(this.getNumberValidators(fromLp.totalContributionAmount));
        this.pageForm.get('amount').patchValue(fromLp.totalContributionAmount ?? 0);
        
        if (this.hasCommitments$.value) {
          this.pageForm.get('commitmentAmount').setValidators(this.getNumberValidators(fromLp.totalCommitmentAmount - fromLp.totalContributionAmount));
          var suggestedCommitment = fromLp.totalCommitmentAmount - (currentAmount > 0 ? currentAmount  : fromLp.totalContributionAmount)
          this.pageForm.get('commitmentAmount').patchValue(suggestedCommitment > 0 ? suggestedCommitment : 0);
        }
        this.reloadToLpDataSource(value);
      } else {
        this.fromLps$.next(this.data.investorsOverview.filter(io => io.investingEntity.id !== toLp &&
          (io.investingEntity.name.toLowerCase().includes(value.toLowerCase()) || (io.investingEntity.contact?.firstName + ' ' + io.investingEntity.contact?.lastName).toLowerCase().includes(value.toLowerCase()))
        ).map(io => io.investingEntity));
        this.pageForm.get('amount').setValidators(this.getNumberValidators());
        if (this.hasCommitments$.value) {
          this.pageForm.get('commitmentAmount').setValidators(this.getNumberValidators());
        }
      }
      if (this.pageForm.get('amount').disabled) {
        this.pageForm.get('amount').enable();
        this.pageForm.get('percents').enable();
      }
      if (this.hasCommitments$.value && this.pageForm.get('commitmentAmount').disabled) {
        this.pageForm.get('commitmentAmount').enable();
        this.pageForm.get('commitmentPercents').enable();
      }
    });

    this.pageForm.get('toLp').valueChanges.pipe(
      debounceTime(300),
      untilComponentDestroyed(this)
    ).subscribe(value => {
      const fromLp = this.pageForm.get('fromLp').value || 0;
      if (!value) {
        this.reloadToLpDataSource(fromLp);
      } else if (typeof value === 'number') {
        this.toLps$.next(this.allInvestingEntities.filter(aie => aie.id === value && aie.id !== fromLp));
      } else {
        this.toLps$.next(this.allInvestingEntities.filter(aie => aie.id !== fromLp &&
          (aie.name.toLowerCase().includes(value.toLowerCase()) || (aie.contact?.firstName + ' ' + aie.contact?.lastName)?.toLowerCase().includes(value.toLowerCase())))
        );
      }
    });

    this.pageForm.get('amount').valueChanges.pipe(
      untilComponentDestroyed(this),
    ).subscribe(this.handleAmountChange('amount', () => {
      this.recalculatePercents();
      this.recalculateCommitmentPercents();
    }));

    this.pageForm.get('percents').valueChanges.pipe(
      untilComponentDestroyed(this),
    ).subscribe(this.handlePercentChange('percents', (value) => {
      this.recalculatePercents(value);
      this.recalculateCommitmentPercents();
    }));

    this.pageForm.get('commitmentAmount').valueChanges.pipe(
      untilComponentDestroyed(this),
    ).subscribe(this.handleAmountChange('commitmentAmount', () => this.recalculateCommitmentPercents()));

    this.pageForm.get('commitmentPercents').valueChanges.pipe(
      untilComponentDestroyed(this),
    ).subscribe(this.handlePercentChange('commitmentPercents', (value) => {
      this.recalculateCommitmentPercents(value);
    }));
  }

  private handleAmountChange(controlName: string, next: () => void) : (value: any) => void {
    return value => {
      if (value?.toString().endsWith('.') || value?.toString().endsWith('.0')) {
        return;
      }
      value = this.numberPipe.transform(this.numberPipe.parse(value?.toString(), 2));
      this.pageForm.get(controlName).patchValue(this.numberPipe.transform(value, 2), {emitEvent: false});
      next();
    }
  }

  private handlePercentChange(controlName: string, next: (value: any) => void) : (value: any) => void {
    return value => {
    if (value?.toString().endsWith('.') || value?.toString().endsWith('.0')) {
        return;
      }
      value = this.numberPipe.parse(value?.toString(), 2);
      if (value > 100) {
        value = 100;
      } else if (!value || value < 0) {
        value = 0;
      }

      this.pageForm.get(controlName).patchValue(this.numberPipe.transform(value), {emitEvent: false});
      next(value);
    }
  }

  private initDataSources() {
    this.data.investorsOverview = this.data.investorsOverview.filter(ie => ie.totalContributionAmount > 0 || ie.totalCommitmentAmount > 0);
    this.gpInvestingEntityDataService.getAll().pipe(take(1), shareReplay(), untilComponentDestroyed(this))
      .subscribe(aie => {
        this.allInvestingEntities = aie;
        this.pageForm.get('fromLp').enable();
        this.pageForm.get('toLp').enable();
        this.reloadToLpDataSource();
      });
    this.fromLps$.next(this.data.investorsOverview.map(io => io.investingEntity));
    this.hasCommitments$.next(this.data.holding.commitmentsEnabled == CommitmentsEnabled.Yes);
  }

  private generateSubmitModel(): TransferOwnershipReqRes {
    const formValues = this.pageForm.getRawValue();
    const model = new TransferOwnershipReqRes();

    model.fromId = formValues.fromLp;
    model.toId = formValues.toLp;
    model.investmentAmount = this.numberPipe.parse(formValues.amount);
    model.closingDate = moment(Date.UTC(formValues.closingDate.getFullYear(), formValues.closingDate.getMonth(), formValues.closingDate.getDate())).toDate();
    if (this.hasCommitments$.value)
    {
      const fromInvestor = this.data.investorsOverview.find(io => io.investingEntity.id === model.fromId);
      var currntCommitments = fromInvestor.totalCommitmentAmount;
      var currentInvesments = fromInvestor.totalContributionAmount;
      if (currntCommitments > 0 && currentInvesments > currntCommitments)  {
        //ignore field and use percentage part of commitment
        model.commitmentAmount = Math.round((model.investmentAmount / currentInvesments) * currntCommitments * 100) / 100;
      } else {
        model.commitmentAmount = this.numberPipe.parse(formValues.commitmentAmount) + model.investmentAmount;
      }
    }

    return model;
  }

  private generateConfirmationDescription(model: TransferOwnershipReqRes) {
    const fromInvestor = this.data.investorsOverview.find(io => io.investingEntity.id === model.fromId)?.investingEntity;
    const toInvestor = this.allInvestingEntities.find(io => io.id === model.toId);
    const amount = model.investmentAmount;
    if (this.hasCommitments$.value) {
      const commitmentAmount = model.commitmentAmount;
      return `Transfer ${this.data.holding.initialCurrency.symbol}${amount} investment and ${this.data.holding.initialCurrency.symbol}${commitmentAmount} total commitment from ${fromInvestor.name} to ${toInvestor.name}?`;
    }

    return `Transfer ${this.data.holding.initialCurrency.symbol}${amount} from ${fromInvestor.name} to ${toInvestor.name}?`;
  }

  public trackByLpFn(index: number, item: InvestingEntityReqRes) {
    return item.id;
  }

  private reloadToLpDataSource(selectedFromId: number = null) {
    if (!!selectedFromId) {
      this.toLps$.next(this.allInvestingEntities.filter(io => io.id !== selectedFromId));
    } else {
      this.toLps$.next(this.allInvestingEntities);
    }
  }

  private getNumberValidators(maxValue?: number): ValidatorFn[] {
    var result = [Validators.required, CustomValidators.minFormattedNumber(-0.001)];
    if (maxValue != null) {
      result.push(CustomValidators.maxFormattedNumber(maxValue > 0 ? maxValue : 0));
    }
    return result;
  }

  private setSubmitting(status: boolean) {
    this.isSubmitting$.next(status);
    status ? this.pageForm.disable({emitEvent: false}) : this.pageForm.enable({emitEvent: false});
  }

  private recalculatePercents(percents: number = null) {
    this.isSubmitting$.pipe(
      filter(isSubmitting => !isSubmitting),
      take(1)
    ).subscribe((_ => {
        const fromLp = this.pageForm.get('fromLp').value;
        const totalAvailable = this.data.investorsOverview.find(io => io.investingEntity.id === fromLp)?.totalContributionAmount;
        if (percents !== null && totalAvailable) {
          const result = this.numberPipe.parse(((totalAvailable * percents) / 100).toString(), 2);
          this.pageForm.get('amount').patchValue(this.numberPipe.transform(result), {emitEvent: false});
        } else if (percents !== 0 && totalAvailable) {
          const amount = this.numberPipe.parse(this.pageForm.get('amount').value);
          const result = this.numberPipe.parse(((amount / totalAvailable) * 100).toString());
          this.pageForm.get('percents').patchValue(this.numberPipe.transform(result), {emitEvent: false});
        } else {
          this.pageForm.get('percents').patchValue(null, {emitEvent: false});
        }
      })
    );
  }

  private recalculateCommitmentPercents(percents: number = null) {
    const fromLp = this.pageForm.get('fromLp').value;
    const lpData = this.data.investorsOverview.find(io => io.investingEntity.id === fromLp)
    var totalAvailable = lpData?.totalCommitmentAmount - lpData?.totalContributionAmount;
    totalAvailable = totalAvailable > 0 ? totalAvailable : 0;
    if (percents !== null && totalAvailable) {
      const result = this.numberPipe.parse(((totalAvailable * percents) / 100).toString(), 2);
      this.pageForm.get('commitmentAmount').patchValue(this.numberPipe.transform(result), {emitEvent: false});
    } else if (percents !== 0 && totalAvailable) {
      const amount = this.numberPipe.parse(this.pageForm.get('commitmentAmount').value);
      const result = this.numberPipe.parse(((amount / totalAvailable) * 100).toString());
      this.pageForm.get('commitmentPercents').patchValue(this.numberPipe.transform(result), {emitEvent: false});
    } else {
      this.pageForm.get('commitmentPercents').patchValue(null, {emitEvent: false});
    }
  }

  private createAtLeastOneShouldBeGreaterThan0Validator(controlNames: string[]): ValidatorFn {
    return (form: UntypedFormGroup): ValidationErrors | null => controlNames.map(x => this.numberPipe.parse(form.get(x).value)).some(x => x > 0) ? null : { atLeastOnePositive: true } 
  }
}