<form id="stepForm" [formGroup]="pageForm" *ngIf="pageForm" class="form-grid-new terra-error-style">
  <h3 class="section-title first">General</h3>

  <!-- Fund Name -->
  <div class="terra-form-field-wrapper">
    <label for="txtName" class="text-input-label">Fund name</label>
    <mat-form-field appearance="outline">
      <input matInput id="txtName" formControlName="name" autocomplete="fund-name" class="lo_sensitive"
        placeholder="e.g. 143 Mott st." />
      <mat-error *ngIf="pageForm.get('name').hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="pageForm.get('name').hasError('minlength')">
        Please enter at least 2 characters
      </mat-error>
      <mat-error *ngIf="pageForm.get('name').hasError('holdingNameTaken')">
        Fund name already exists
      </mat-error>
      <mat-error *ngIf="pageForm.get('name').hasError('includeForbiddenCharacter')">
        Fund name shouldn't contain the following characters:
        {{ forbiddenCharsInHoldingName.join(", ") }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Estimated Closing date -->
  <div *ngIf="isInitialFundRaising" class="terra-form-field-wrapper">
    <terra-custom-utc-date-picker id="txtClosingDate" formControlName="estimatedClosingDate"
        label="Estimated / final closing date" [minDate]="estimatedClosingDateMinimumDate"
        [requiredError]="pageForm.get('estimatedClosingDate').hasError('required')"
        [touched]="pageForm.get('estimatedClosingDate').touched"
        [minError]="pageForm.get('estimatedClosingDate').hasError('minDate')"
        minErrorTxt="Please enter a valid closing date" [startAt]="estimatedClosingDateStartAt"></terra-custom-utc-date-picker>
  </div>
  <!-- FundraisingTargetAmount and currency -->
  <div *ngIf="isInitialFundRaising" class="terra-form-field-wrapper amount-with-currency">
    <label for="txtFundraisingTargetAmount" class="amount-label text-input-label">
      Fundraising target amount
    </label>
    <mat-form-field appearance="outline" class="amount-field-wrapper">
      <input type="tel" terraFormattedNumber matInput id="txtFundraisingTargetAmount"
        formControlName="fundraisingTargetAmount" class="lo_sensitive" />
      <mat-error *ngIf="pageForm.get('fundraisingTargetAmount').hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="pageForm.get('fundraisingTargetAmount').hasError('min')">
        Target amount must be greater than zero
      </mat-error>
    </mat-form-field>
    <label for="ddlCurrency" class="currency-label text-input-label">Currency
    </label>
    <mat-form-field appearance="outline" class="currency-ddl-wrapper">
      <mat-select id="ddlCurrency" placeholder="Select" formControlName="currency" [compareWith]="currencyCompareFn">
        <mat-option *ngFor="let currency of currencies$ | async" [value]="currency">
          {{ currency.symbol }} {{ currency.iso }}</mat-option>
      </mat-select>
      <mat-error *ngIf="pageForm.get('currency').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Minimum investment -->
  <div *ngIf="isInitialFundRaising" class="terra-form-field-wrapper">
    <label for="txtMinimumInvestmentAmount" class="text-input-label">
      Minimum investment (optional)
    </label>
    <mat-form-field appearance="outline">
      <input type="tel" matInput id="txtMinimumInvestmentAmount" terraFormattedNumber
        formControlName="minimumInvestmentAmount" class="lo_sensitive" />
      <span matSuffix>{{ pageForm.get("currency").value?.iso }}</span>
    </mat-form-field>
  </div>

  <!-- Investment type -->
  <div class="terra-form-field-wrapper">
    <label for="ddlInvestmentType" class="text-input-label">
      Investment Strategy
    </label>
    <mat-form-field appearance="outline">
      <mat-select id="ddlInvestmentType" placeholder="Select" formControlName="investmentStrategyType">
        <mat-option *ngFor="let item of InvestmentStrategyTypesList" [value]="item">{{ item | enumString :
          FundInvestmentStrategyType }}</mat-option>
      </mat-select>
      <mat-error *ngIf="pageForm.get('investmentStrategyType').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Other strategy text -->
  <div class="terra-form-field-wrapper" *ngIf="
      pageForm.get('investmentStrategyType').value ===
      FundInvestmentStrategyType.Other
    ">
    <label for="txtInvestmentStrategyOther" class="text-input-label">
      Please specify
    </label>
    <mat-form-field appearance="outline">
      <input type="text" matInput id="txtInvestmentStrategyOther" formControlName="investmentStrategyTypeOther"
        class="lo_sensitive" />
      <mat-error *ngIf="pageForm.get('investmentStrategyTypeOther').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
  </div>

  <!--  <ng-container *ngIf="hasCapitalCall" formGroupName="capitalCall">
    &lt;!&ndash; Capital call &ndash;&gt;
    <h3 class="section-title" id="capitalCall">Capital call</h3>
    <terra-capital-call-details class="full-width" [pageForm]="capitalCallForm">
    </terra-capital-call-details>
  </ng-container>-->

  <!-- Fund Address -->
  <ng-container>
    <h3 class="section-title">Fund Address</h3>
    <terra-google-map-search-input class="full-width" formControlName="fundLocation"
      [isOuterFormSubmitted]="isSubmitAttempt"></terra-google-map-search-input>
  </ng-container>
</form>