import { KeyValuePair } from '../../types/KeyValuePair.model';

export class SignatureRequest {

  public testMode: boolean;
  public message: string;
  public subject: string;
  public title: string;

  public ccs: string[];
  public files: any[];
  public fileUrls: string[];
  public useTextTags: boolean;
  public usePreexistingFields: boolean;
  public hideTextTags: boolean;
  public allowReassign: boolean;
  public formFieldsPerDocument: any[];

  public investmentId: number;
  public investingEntityId: number;
  public metaFileLinkIds: number[];
  public fileUrl: string;

  public metaData: KeyValuePair<string, string>[];
}
