import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {of, combineLatest, Observable, BehaviorSubject, timer} from 'rxjs';
import {tap, switchMap, map, skipWhile, shareReplay, take, withLatestFrom, filter, catchError} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {TerraUtils} from 'src/app/shared/TerraUtils';
import {FundraisingReqRes} from 'src/app/dashboard/shared/holding/fundraising/fundraisings-tab/FundraisingReqRes.model';
import {GpBankAccountDataService} from 'src/app/services/gp/gp-bank-account-data.service';
import {DialogService} from 'src/app/services/dialog.service';
import {ResourceService} from 'src/app/services/resource.service';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {MetaFileLink} from 'src/app/models/metaFileLink.model';
import {ClientBankAccountResponseBasic, ClientBankAccountReqRes} from 'src/app/dashboard/models/bankAccount.model';
import {GpFundraisingDataService} from 'src/app/services/gp/gp-fundraising-data.service';
import {GpFundService} from '../../../gp-fund.service';
import {EditBankAccountComponent} from 'src/app/dashboard/bankAccounts/components/edit-bank-account/edit-bank-account.component';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {RoutingService} from 'src/app/services/routing.service';
import {ErrorMatcher, ErrorType} from 'src/app/shared/errors/ErrorMatcher';
import {EditBankAccountContext} from 'src/app/dashboard/bankAccounts/components/edit-bank-account/EditBankAccountContext.model';
import {UserService} from 'src/app/services/shared/user.service';
import FeatureFlags from 'src/app/account/my-account/model/FeatureFlags.enum';
import {BaseResponseDto, BaseResponseStatus} from '../../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../../services/utils.service';
import {UnitCreateBankAccountComponent} from 'src/app/shared/components/unit/unit-create-bank-account/unit-create-bank-account.component';
import {UnitCreateBankAccountParams} from 'src/app/shared/models/unit-bank-account/UnitCreateBankAccountParams.model';
import {UnitApplicationParentType} from 'src/app/shared/enums/unit-bank-account/UnitApplicationParentType.enum';
import UnitApplicationStatus from 'src/app/shared/enums/unit-bank-account/UnitApplicationStatus.enum';

@Component({
  selector: 'terra-create-fund-fundraising',
  templateUrl: './create-fund-fundraising.component.html',
  styleUrls: ['./create-fund-fundraising.component.scss']
})
export class CreateFundFundraisingComponent extends OnDestroyMixin implements OnInit {
  noAccessMessage = '';
  isSubmitted = false;
  fundraisingCreated = false;
  pageForm: UntypedFormGroup;
  showInternalLoader = false;
  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR;
  isFileNotSupported = false;
  isSameNameException = false;
  sameNameExceptionErrorMessage = 'A contribution with the same name already exists.';
  fileNotSupportedError = 'The file you uploaded is not supported.';

  // enum
  InvestmentSecurityType = InvestmentSecurityType;
  // Enum value lists
  InvestmentSecurityTypesList = InvestmentSecurityType.optionsForFundFundraising();

  /// The date that the picker will open to
  estimatedClosingDateStartAt = new Date();
  estimatedClosingDateMinimumDate = new Date('1970-01-01T12:00');

  fundId$ = this.gpFundService.holdingId$;

  fund$ = this.gpFundService.holding$;

  fundPageUrl$ = this.fundId$.pipe(map(fundId => this.routingService.gpFundWithTab(fundId, 'contributions')), shareReplay(1));

  fundCurrency$ = this.gpFundService.fundraising$.pipe(
    skipWhile(value => value === null),
    map(initialFundraisingDetails => initialFundraisingDetails.fundraisingTargetCurrency)
  );

  bankAccounts$ = this.fundCurrency$.pipe(
    withLatestFrom(this.fundId$),
    switchMap(([currency, holdingId]) => {
      return this.gpBankAccountDataService.getListByTargetCurrency(currency.id, holdingId);
    }),
    shareReplay(),
    catchError(async err => {
      if (ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)) {
        this.noAccessMessage = err.responseMessage;
      }
      return new Array<ClientBankAccountResponseBasic>();
    }));

  numberOfExistingFundraisings$ = this.gpFundService.fundraisingSearchResponse$.pipe(
    map(response => response.totalCount),
    shareReplay()
  );

  newCreatedBankAccount$ = new BehaviorSubject<ClientBankAccountResponseBasic>(null);
  bankAccountOptions$ = combineLatest([this.bankAccounts$, this.newCreatedBankAccount$]).pipe(
    map(([accounts, newAccount]) => {
      if (newAccount) {
        accounts.push(newAccount);
      }
      return accounts;
    }),
    shareReplay()
  );

  isCreBankFeatureEnabled$ = this.userService.userHasFeatureFlag(FeatureFlags.CreBanking);
  canCreateBankAccount$ = this.isCreBankFeatureEnabled$
    .pipe(
      untilComponentDestroyed(this),
      shareReplay(1));

  get selectedSecurityType(): InvestmentSecurityType {
    try {
      return this.pageForm.get('securityType').value;
    } catch {
      return null;
    }
  }

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private resourceService: ResourceService,
    private gpFundService: GpFundService,
    private gpFundraisingDataService: GpFundraisingDataService,
    private titleService: Title,
    private numberPipe: TerraNumberPipe,
    private routingService: RoutingService,
    public gpBankAccountDataService: GpBankAccountDataService,
    private userService: UserService,
    private utilsService: UtilsService
  ) {
    super();
  }

  ngOnInit() {
    this.titleService.setTitle('Create Contribution' + TerraUtils.consts.GpPageTitleSuffix);
    this.generatePageForm();
  }

  createFundraising() {
    this.isSubmitted = true;
    this.pageForm.markAllAsTouched();
    this.isFileNotSupported = false;
    this.isSameNameException = false;
    this.isGeneralServerError = false;
    if (!this.pageForm.valid) {
      return;
    }

    this.showInternalLoader = true;
    this.generateSubmitModel().pipe(
      switchMap(model => this.gpFundraisingDataService.create(model.holdingId, model)),
      tap(() => {
        this.showInternalLoader = false;
        this.fundraisingCreated = true;
      }),
      withLatestFrom(this.fundId$)
    )
      .subscribe(([fundraising, fundId]) => {
        const url = this.routingService.fundFundraising(fundId, fundraising.id);
        this.router.navigateByUrl(url);
      },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else if (ErrorMatcher.isError(error, ErrorType.BadFileFormatException)) {
            this.isFileNotSupported = true;
          } else if (ErrorMatcher.isError(error, ErrorType.AlreadyExistsException)) {
            this.isSameNameException = true;
          } else {
            this.isGeneralServerError = true;
          }
          this.showInternalLoader = false;
        }
      );

  }

  generateSubmitModel(): Observable<FundraisingReqRes> {
    return combineLatest([this.fundCurrency$, this.fundId$]).pipe(
      take(1),
      map(([currency, fundId]) => {
        const formValues = this.pageForm.value;
        const fundraisingModel = new FundraisingReqRes();
        fundraisingModel.name = formValues.name;
        fundraisingModel.holdingId = fundId;
        fundraisingModel.estimatedClosingDate = formValues.estimatedClosingDate;
        fundraisingModel.fundraisingTargetAmount = this.numberPipe.parse(formValues.fundraisingTargetAmount);
        fundraisingModel.fundraisingTargetCurrencyId = currency.id;
        fundraisingModel.minimumInvestmentAmount = this.numberPipe.parse(formValues.minimumInvestmentAmount);
        fundraisingModel.securityType = formValues.securityType;
        // Offering deck if added
        if (formValues.marketingDeckDocument) {
          fundraisingModel.offeringDeckId = (formValues.marketingDeckDocument as MetaFileLink).id;
          fundraisingModel.isDefaultDeck = formValues.isDefaultDeck;
        }
        if (formValues.bankAccount) {
          // Bank account if selected
          fundraisingModel.clientBankAccountId = !formValues.bankAccount.isUnitBankAccount ? (formValues.bankAccount as ClientBankAccountResponseBasic).id : null;
          fundraisingModel.unitBankAccountId = formValues.bankAccount.isUnitBankAccount ? (formValues.bankAccount as ClientBankAccountResponseBasic).id : null;
        }
        return fundraisingModel;
      })
    );
  }

  generatePageForm() {
    this.numberOfExistingFundraisings$.subscribe(numberOfExistingFundraisings => {
      this.pageForm = this.fb.group({
        name: [`Contribution #${numberOfExistingFundraisings + 1}`, [Validators.required, Validators.minLength(2)], this.validateFundraisingNameNotTaken.bind(this)],
        estimatedClosingDate: [null, [Validators.required]],
        minimumInvestmentAmount: null,
        securityType: [InvestmentSecurityType.Equity, Validators.required],
        fundraisingTargetAmount: [null, Validators.compose([Validators.required, Validators.min(1)])],
        marketingDeckDocument: null,
        isDefaultDeck: [{value: true, disabled: true}],
        bankAccount: null
      });

      this.subscribeToMarketingDeckFileChange();
    });

  }

  subscribeToMarketingDeckFileChange() {
    this.pageForm
      .get('marketingDeckDocument')
      .valueChanges.pipe(untilComponentDestroyed(this), tap(val => val ? this.pageForm.get('isDefaultDeck').enable() : this.pageForm.get('isDefaultDeck').disable()))
      .subscribe(() => {
        this.isFileNotSupported = false;
      });
  }

  createNewBankAccount() {
    combineLatest([this.fund$, this.canCreateBankAccount$]).pipe(
      map(([fund, createEnabled]) => {
        if (createEnabled) {
          let primaryActionCancelled = true;
          const dialogParams = new ConfirmDialogParams();
          dialogParams.title = `New Bank Account`;
          dialogParams.actionLabel = `OK`;
          dialogParams.secondaryActionLabel = `ADD EXTERNAL ACCOUNT`;
          dialogParams.hideCancel = true;

          if (!!fund?.isExample) {
            dialogParams.disableAction = true;
          }

          if (!fund?.unitApplications?.length) {
            dialogParams.description = `To create a Covercy CRE Bank Account for this holding, click "Create Account"
                                              and fill the application form. We recommend waiting until the application is approved
                                              and then associating the new account with your holding.<br>
                                              For now, you may skip this field and set it up later.`;
            dialogParams.actionLabel = `Create Account`;
            dialogParams.secondaryActionLabel = 'Add External account';
            dialogParams.hideCancel = false;
            primaryActionCancelled = false;
          } else {
            switch (fund.unitApplications[0].status) {
              case UnitApplicationStatus.AwaitingDocuments:
              case UnitApplicationStatus.PendingReview:
              case UnitApplicationStatus.Pending:
              case UnitApplicationStatus.Draft:
                dialogParams.description = `Your application for a new Covercy CRE Bank Account is pending and in the process of approval.
                                              We recommend waiting until the application is approved and then associating the new account with your holding.<br>
                                              For now, you may skip this field and set it up later.`;
                break;
              case UnitApplicationStatus.Denied:
                dialogParams.description = `Your application for a new Covercy CRE Bank Account has been denied.
                                              This might be due to missing documents and could still be authorized.
                                              Please contact us at <a href="mailto:support@covercy.com" target="_blank">support@covercy.com</a> for more information.
                                              We recommend waiting until the application is approved,
                                              and then associating the new account with your holding.<br>
                                              For now, you may skip this field and set it up later.`;
                break;
              case UnitApplicationStatus.Approved:
                this.createCreBankAccount();
                break;
              default:
                break;
            }
          }

          if (dialogParams.description) {
            this.dialogService.confirmDialog(dialogParams)
              .afterClosed()
              .subscribe(res => {
                if (res?.secondaryAction) {
                  this.createExternalBankAccount();
                } else if (!primaryActionCancelled && res) {
                  this.router.navigate([]).then(result => {
                    const url = this.router.serializeUrl(
                      this.router.createUrlTree(
                        [this.routingService.createBankApplicationPage()],
                        {queryParams: {holdingId: fund.id}}
                      )
                    );
                    window.open(url, '_blank');
                  });
                }
                // this.dialogRef.close();
              });
          }
        } else {
          this.createExternalBankAccount();
        }
      })
    ).subscribe();
  }

  bankAccountCompareFn(b1: ClientBankAccountReqRes, b2: ClientBankAccountReqRes) {
    return b1 && b2 ? b1.id === b2.id : b1 === b2;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.pageForm.pristine && !this.fundraisingCreated) {
      const params = new ConfirmDialogParams('Cancel new contribution?', 'Any information you added will be lost', 'Cancel Contribution', 'Go back');
      return this.dialogService.confirmDialog(params).afterClosed();
    }
    return true;
  }

  getCountryById(conutryId: number): CountryModel {
    return this.resourceService.getCountryById(conutryId);
  }

  cancel() {
    this.fundId$.subscribe(fundId => {
      const url = this.routingService.gpFundWithTab(fundId, 'contributions');
      this.router.navigateByUrl(url);
    });
  }

  // Async validation functions:
  validateFundraisingNameNotTaken(control: AbstractControl, originalValue = '') {
    const {value}: {value: string} = control;

    if (!value || value === originalValue) {
      return of(null);
    }
    return timer(1000).pipe(
      switchMap(_ => this.fundId$.pipe(take(1))),
      switchMap(fundId => this.gpFundraisingDataService.isFundraisingNameExists(fundId, value)),
      map(isNameExists => {
        return isNameExists ? {fundraisingNameTaken: true} : null;
      })
    );
  }

  private createExternalBankAccount() {
    const dialogConfig = new MatDialogConfig<EditBankAccountContext>();
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'create-bank-account-dialog';

    const dialogRef: MatDialogRef<EditBankAccountComponent, ClientBankAccountResponseBasic> = this.dialog
      .open(EditBankAccountComponent, dialogConfig);

    dialogRef.afterClosed()
      .pipe(
        untilComponentDestroyed(this),
        filter(createdBankAccount => !!createdBankAccount),
        tap(newAccount => this.newCreatedBankAccount$.next(newAccount)),
        withLatestFrom(this.bankAccounts$)
      )
      .subscribe(([createdBankAccount, bankAccounts]) => {
        // Update the list of bank account (to get only the supprted accounts for the asset currency), and select the created account if supported
        const bankAccountToSelect = bankAccounts.find(account => account.id === createdBankAccount.id);
        if (bankAccountToSelect) {
          this.pageForm.get('bankAccount').setValue(bankAccountToSelect);
        }
      });
  }

  private createCreBankAccount() {
    this.fund$.pipe(
      switchMap(fund => {
        const dialog = this.dialog.open(UnitCreateBankAccountComponent, {
          width: '50%',
          disableClose: true,
          data: {
            preselectedType: UnitApplicationParentType.Holding,
            attachedToId: fund.id,
            hasApprovedApplication: fund.unitApplications?.some(x => x.status === UnitApplicationStatus.Approved),
            showExternalOptions: true,
            unitApplicationId: fund.unitApplicationId

          } as UnitCreateBankAccountParams
        });
        return dialog.afterClosed();
      })).pipe(
        withLatestFrom(this.bankAccounts$))
      .subscribe(([newCreBankAccount, bankAccounts]) => {
        if (!newCreBankAccount) {
          return;
        }
        if (!bankAccounts) {
          bankAccounts = [];
        }

        if (newCreBankAccount?.reason === 'external') {
          this.createExternalBankAccount();
        } else if (newCreBankAccount?.reason === 'new' || newCreBankAccount?.reason === 'fake') {
          const newBankAccount = new ClientBankAccountResponseBasic();
          newBankAccount.accountNumber = newCreBankAccount.payload.accountNumber;
          newBankAccount.nickname = newCreBankAccount.payload.accountNickname;
          newBankAccount.id = newCreBankAccount.payload.id;
          newBankAccount.isUnitBankAccount = true;

          bankAccounts.unshift(newBankAccount);
          this.pageForm.get('bankAccount').setValue(newBankAccount);
        }
      });
  }
}
