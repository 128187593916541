import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {switchMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import memo from 'memo-decorator';

import {GpHoldingService} from '../../gp-holding.service';
import {CurrencyModel} from '../../../../../shared/models/CurrencyModel';
import {ResourceService} from '../../../../../services/resource.service';
import {DistributionDetailsItemReqRes} from './distribution-details/DistributionDetailsItemReqRes';
import {AlertDialogComponent} from '../../../../../shared/components/alert-dialog/alert-dialog.component';
import {ImportDistributionsDialogComponent} from './import-distributions-dialog/import-distributions-dialog.component';
import {DistributionsImportResponse} from '../../../../../shared/models/DistributionsImportResponse.model';
import {UserService} from '../../../../../services/shared/user.service';

@Component({
  selector: 'terra-import-distributions',
  templateUrl: './import-distributions.component.html',
  styleUrls: ['./import-distributions.component.scss']
})
export class ImportDistributionsComponent extends OnDestroyMixin implements OnInit {
  holding$ = this.gpHoldingService.holding$;
  holdingId$ = this.gpHoldingService.holdingId$;
  currencies$: Observable<CurrencyModel[]>;
  discriminatorStr = this.gpHoldingService.discriminatorStr;
  distributionsDetails = new Array<DistributionDetailsItemReqRes>();
  distributionsRoute: string;
  isLoading$ = new BehaviorSubject(true);

  constructor(
    private gpHoldingService: GpHoldingService,
    private resourceService: ResourceService,
    private dialog: MatDialog,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.holding$.pipe(
      take(1),
      untilComponentDestroyed(this))
      .subscribe(holding => {
        this.setHoldingPageUrl(holding.id);
      });
    this.getCurrencies();
    this.addDistribution();
    this.importDistributionClickedTelemetryEvent();
  }

  @memo()
  holdingDownInfo() {
    this.gpHoldingService.holdingDownInfo();
  }

  setHoldingPageUrl(holdingId: number) {
    this.distributionsRoute = this.gpHoldingService.getHoldingPageWithTabRoute(holdingId, 'distributions');
  }

  getCurrencies() {
    this.resourceService.getOutboundCurrencies().subscribe(response => {
      this.currencies$ = of(response.filter(c => !c.isInbound));
      this.isLoading$.next(false);
    });
  }

  removeDetail(index: number) {
    this.distributionsDetails.splice(index, 1);
  }

  addDistribution() {
    const distribution = new DistributionDetailsItemReqRes();
    this.distributionsDetails.push(distribution);
  }

  importDistribution() {
    if (this.distributionsDetails.some(detail => !detail.isFormValid)) {
      return this.showAlertDialog(`Please fill all the required fields marked in red`);
    }

    this.showImportDialog();

  }

  private showAlertDialog(message: string, msgTitle = 'Covercy') {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'alert-dialog';
    dialogConfig.data = {
      title: msgTitle,
      description: message,
      actionLabel: `OK`
    };
    this.dialog.open(AlertDialogComponent, dialogConfig);
  }

  private showImportDialog() {
    this.holdingId$.pipe(
      untilComponentDestroyed(this),
      take(1),
      switchMap(
        holdingId => {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            holdingId,
            distributions: this.distributionsDetails
          };
          return this.dialog.open(ImportDistributionsDialogComponent, dialogConfig)
            .afterClosed();
        })
    ).subscribe((res: DistributionsImportResponse) => {
      if (res && res.isSuccess) {
        this.gpHoldingService.refreshDistribution();
        this.router.navigateByUrl(this.distributionsRoute);
      }
    });
  }

  private importDistributionClickedTelemetryEvent() {
    this.userService.accountDetails$.pipe(take(1)).subscribe(clientDetails => {
      this.holdingId$.pipe(take(1)).subscribe(holdingId => {
        this.telemetryService.create({
          eventID: '107',
          eventTitle: 'DISTRIBUTION - IMPORT (CLICKED)',
          holdingID: holdingId,
          organizationID: clientDetails.organizationDetails.id
        }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
      });
    });
  }
}
