<div class="container upload-documents">
  <!-- Breadcrumbs  -->
  <terra-breadcrumb [items]="breadcrumbs"></terra-breadcrumb>
  <h1 class="page-header">
    <span routerLink=".." class="header-link">
      <mat-icon color="primary" class="material-icons-outlined header-link-icon">arrow_back</mat-icon>
      Match Automatically
    </span>
  </h1>

  <mat-tab-group [disableRipple]="true" [@.disabled]="true" (animationDone)="tabChanged()" preserveContent
    class="upload-tabs-stepper" [selectedIndex]="selectedStep.value" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab *ngIf="!skipPrepareStep">
      <ng-template mat-tab-label>
        <terra-step-label title="Prepare" [step]="0" (stepClick)="setStep(0);" [completed]="selectedStep.value!== 0">
        </terra-step-label>
      </ng-template>
      <!-- Prepare -->
      <div class="step-content">
        <terra-prepare (onSkip)="onSkip($event)"></terra-prepare>
      </div>
      <div class="step-controls">
        <div class=""></div>
        <button mat-flat-button color="primary" (click)="setStep(1)">Continue
          <mat-icon class="" iconPositionEnd>
            arrow_forward_ios
          </mat-icon>
        </button>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <terra-step-label title="Upload" [step]="1-(skipPrepareStep ? 1 : 0)" (stepClick)="setStep($event);"
          [completed]="selectedStep.value !== 1-(skipPrepareStep ? 1 : 0) && matchCompleted && !uploadIsNotDisabled">
        </terra-step-label>
      </ng-template>
      <!-- Upload -->
      <div class="step-content">
        <h4 class="title">
          Upload your files
        </h4>
        <terra-upload [holdingId]="holdingId" (uploadComplited)="uploadComplited($event)"
          (newFolder)="newFolder($event)">
        </terra-upload>
      </div>
      <div class="step-controls">
        <div class="">
          <button mat-stroked-button class="light" color="primary" (click)="setStep(0); sendbackToIntroEvent()" *ngIf="!skipPrepareStep">
            <mat-icon class="">
              arrow_back_ios
            </mat-icon> Back</button>
        </div>
        <button mat-flat-button color="primary" (click)="setStep(1+ (skipPrepareStep ? 0 : 1))"
          [disabled]="!matchCompleted || uploadIsNotDisabled">Continue <mat-icon class="" iconPositionEnd>
            arrow_forward_ios
          </mat-icon>
        </button>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <terra-step-label title="Match" [step]="2-(skipPrepareStep ? 1 : 0)" (stepClick)="setStep($event)"
          [disabled]="!matchCompleted || uploadIsNotDisabled"
          [completed]="selectedStep.value !== 2-(skipPrepareStep ? 1 : 0) && shareList.length">
        </terra-step-label>
      </ng-template>
      <!-- Match -->
      <div class="step-content">
        <h4 class="title">
          Match files to investors
        </h4>
        <div class="description">
          Confirm that the following files are correctly matched with the intended investing entity. Note that not
          matched file will not be shared.
        </div>
        <terra-match (matchList)="matchList($event)" [holdingId]="holdingId" [update]="updateMatches"
          (edited)="matchListEdited($event)" [investors]="investors" [list]="matchFiles">
        </terra-match>
      </div>
      <div class="step-controls">
        <button mat-stroked-button color="primary" class="light" (click)="check(); sendbackToUploadEvent()">
          <mat-icon class="">
            arrow_back_ios
          </mat-icon> Back</button>
        <button mat-flat-button color="primary" (click)="setStep(2+(skipPrepareStep ? 0 : 1))"
          [disabled]="!shareList.length">Continue <mat-icon class="" iconPositionEnd>
            arrow_forward_ios
          </mat-icon>
        </button>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <terra-step-label title="Share" [step]="3-(skipPrepareStep ? 1 : 0)" (stepClick)="setStep($event)"
          [completed]="selectedStep.value !== 3-(skipPrepareStep ? 1 : 0) && shareList.length" [disabled]="!shareList.length">
        </terra-step-label>
      </ng-template>
      <!-- Share -->
      <div class="step-content">
        <h4 class="title">
          Share with investors.
        </h4>
        <div class="description">
          Once shared, the files will be ready for viewing and downloading on the Investor Portal.
        </div>
        <terra-share [list]="list" (sendEmail)="sendEmail($event)" (shareText)="shareText($event)"></terra-share>
      </div>
      <div class="step-controls">
        <button mat-stroked-button color="primary" class="light" (click)="setStep(2-(skipPrepareStep ? 1 : 0)); sendBackToMatchEvent()">
          <mat-icon class="">
            arrow_back_ios
          </mat-icon> Back</button>
        <div style="display:flex; flex-direction: column;">
          <button mat-flat-button color="primary" (click)="upload()" [disabled]="loading" *ngIf="!loading">
            <div class="share-btn">
              Match Automatically
            </div>
          </button>
          <button mat-flat-button color="primary" [disabled]="loading" *ngIf="loading">
            <div class="share-btn"><mat-spinner diameter="20"></mat-spinner>Uploading...</div>
          </button>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>

</div>
