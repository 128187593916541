/*

Reports to Google Analytics on every navigation change event.

*/
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { UserService } from 'src/app/services/shared/user.service';

@Component({
  selector: 'payapp-google-analytics',
  templateUrl: './google-analytics.component.html'
})
export class GoogleAnalyticsComponent implements OnInit {
  isLoggedIn = false;
  trackingCode = '';

  constructor(private router: Router, private appSettings: AppSettingsService, private userService: UserService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setVariables(event);
      }
    });
  }
  ngOnInit() { }

  setVariables(event: NavigationEnd) {

    this.trackingCode = this.appSettings.googleAnalyticsTrackingCode;

    if (!this.userService.isLoggedIn()) {
      window['clientIdentifier'] = '';
    } else {
      this.userService.accountDetails$.subscribe(user => {
        if (user !== null) {
          window['clientIdentifier'] = user.clientIdentifier;
        } else {
          window['clientIdentifier'] = '';
        }

        if (window['gtag'] && typeof window['gtag'] === 'function') {
          window['gtag']('config', this.trackingCode, {
            page_path: event.urlAfterRedirects
          });
        }
      });
    }
  }
}
