import { HostBinding, Directive } from '@angular/core';
import { CreateFundAssetService, CreateFundAssetStepNumber } from './create-fund-asset.service';
import AreaUnit from 'src/app/shared/enums/AreaUnit.enum';

export interface ICreateFundAssetStep {
  readonly STEP_NUMBER: CreateFundAssetStepNumber;
}

@Directive()
export abstract class CreateFundAssetStepBase implements ICreateFundAssetStep {

  @HostBinding('attr.class') hostClasses = 'wizard-step';

  abstract STEP_NUMBER: CreateFundAssetStepNumber;
  readonly LAST_STEP_NUMBER = 3 as CreateFundAssetStepNumber;

  isSubmitAttempt = false;

  private _createFundAssetService: CreateFundAssetService;

  get selectedAreaUnit(): AreaUnit {
    try {
      return Number(this._createFundAssetService.createAssetForm.get('step1.areaUnits').value) as AreaUnit;
    } catch {
      return AreaUnit.SquareFeet;
    }
  }

  set selectedAreaUnit(value: AreaUnit) {
    this._createFundAssetService.createAssetForm.get('step1.areaUnits').setValue(value);
  }

  constructor(createFundAssetService: CreateFundAssetService) {
    this._createFundAssetService = createFundAssetService;
  }

  get pageForm() {
    return this._createFundAssetService.getStepForm(this.STEP_NUMBER);
  }

  get isSubmitted() {
    return this._createFundAssetService.isStepSubmitted(this.STEP_NUMBER);
  }
  set isSubmitted(val: boolean) {
    this._createFundAssetService.setStepAsSubmitted(this.STEP_NUMBER, val);
  }

  back() {
    if (this.STEP_NUMBER > 1) {
      this._createFundAssetService.currentStep$.next(this.STEP_NUMBER - 1 as CreateFundAssetStepNumber);
    }
  }

  stepSubmit() {
    this.isSubmitAttempt = true;
    this.pageForm.markAllAsTouched();

    this.pageForm.updateValueAndValidity();
    if (this.pageForm.valid) {
      this._createFundAssetService.isGeneralServerError = false;
      this.isSubmitted = true;
      // If not the last step - go to the next step
      if (this.STEP_NUMBER < this.LAST_STEP_NUMBER) {
        this._createFundAssetService.currentStep$.next((this.STEP_NUMBER + 1) as CreateFundAssetStepNumber);
      } else {
        // If submitting the last step, call the create asset function
        this._createFundAssetService.createFundAsset();
      }
    }
  }

}
