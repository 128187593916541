<h3 class="title">Payment status:</h3>
<p class="error-message" *ngIf="error">{{error}}</p>
<div *ngIf="!error && !isSettledExternal">
  <div class="status" [ngClass]="'circle ' + sentStatus">Payment request sent to investor</div>
  <div class="status" [ngClass]="'line ' + sentStatus">
    <span [ngClass]="'date ' + sentStatus">
      <mat-icon class="date-icon notranslate" *ngIf="!!sentDate">access_time</mat-icon>{{sentDate}}
    </span>
  </div>
  <div class="status" [ngClass]="'circle ' + viewedStatus">Investor viewed the request</div>
  <div class="status" [ngClass]="'line ' + viewedStatus">
    <span [ngClass]="'date ' + viewedStatus">
      <mat-icon class="date-icon notranslate" *ngIf="!!viewedDate">access_time</mat-icon>{{viewedDate}}
    </span>
  </div>
  <div class="status" [ngClass]="'circle ' + submittedStatus">{{submittedTitle}}</div>
  <div class="status" [ngClass]="'line ' + submittedStatus">
    <span [ngClass]="'date ' + submittedStatus">
      <mat-icon class="date-icon notranslate" *ngIf="!!submittedDate">access_time</mat-icon>{{submittedDate}}
    </span>
  </div>
  <ng-container *ngIf="this.investment.isOrderCreated">
    <div class="status" [ngClass]="'circle ' + fundsReceivedStatus" *ngIf="!investment.isPaymentReversed">Funds received at Covercy</div>
    <div class="status" [ngClass]="'line ' + fundsReceivedStatus" *ngIf="!investment.isPaymentReversed">
      <span [ngClass]="'date ' + fundsReceivedStatus">
        <mat-icon class="date-icon notranslate" *ngIf="!!fundsReceivedDate">access_time</mat-icon>{{fundsReceivedDate}}
      </span>
    </div>
  </ng-container>
  <div class="status" [ngClass]="'circle ' + approvedStatus">{{approvedTitle}}</div>
  <div class="status" [ngClass]="'line ' + approvedStatus">
    <span [ngClass]="'date ' + approvedStatus">
      <mat-icon class="date-icon notranslate" *ngIf="!!approvedDate">access_time</mat-icon>{{approvedDate}}
    </span>
  </div>
  <ng-container *ngIf="this.investment.isOrderCreated">
    <div class="status" [ngClass]="'circle ' + fundsReceivedStatus" *ngIf="investment.isPaymentReversed">Funds received at Covercy</div>
    <div class="status" [ngClass]="'line ' + fundsReceivedStatus" *ngIf="investment.isPaymentReversed">
      <span [ngClass]="'date ' + fundsReceivedStatus">
        <mat-icon class="date-icon notranslate" *ngIf="!!fundsReceivedDate">access_time</mat-icon>{{fundsReceivedDate}}
      </span>
    </div>
  </ng-container>
  <div class="status" [ngClass]="'circle ' + completedStatus">Transfer completed</div>
  <div class="status line hidden">
    <span [ngClass]="'date multiline ' + completedStatus">
      <mat-icon class="date-icon notranslate" *ngIf="!!completedDate[0]">access_time</mat-icon>{{completedDate[0]}}<br />
      <mat-icon class="date-icon notranslate" *ngIf="!!completedDate[1]">access_time</mat-icon>{{completedDate[1]}}
    </span>
  </div>
</div>

<div *ngIf="!error && isSettledExternal">
  <div class="status" [ngClass]="'circle ' + completedStatus">Transfer completed</div>
  <div class="status line hidden">
    <span [ngClass]="'date multiline ' + completedStatus">
      <mat-icon class="date-icon notranslate" *ngIf="!!completedDate[0]">access_time</mat-icon>{{completedDate[0]}} Transfer was manually marked as Invested
    </span>
  </div>
</div>
