<ng-container *ngIf="{
  isLoading: isLoading$ | async,
  pageRowsCount: pageRowsCount$ | async,
  displayedFolderCount: displayedFolderCount$ | async,
  displayedFilesCount: displayedFilesCount$ | async,
  allFoldersTree: allFoldersTree$ | async  } as data">

  <!-- Breadcrumb - displayed if not in root -->
  <ul class="breadcrumb" *ngIf="currentFolderId$ | async as currentFolderId">
    <li><a (click)="openFolder(null)" class="light link">Documents</a></li>
    <ng-container *ngFor="let folder of foldersPath$ | async">
      <li *ngIf="folder.storageObjectType === StorageObjectType.Directory">
        <a (click)="folder.id === currentFolderId ? false : openFolder(folder.id)" class="light link"
          [class.current-folder]="folder.id === currentFolderId"
          [title]="folder.displayName.length > 30 ? folder.displayName : ''">{{folder.displayName | truncate : 30}}</a>
      </li>
    </ng-container>
  </ul>
  <!-- <ng-template #topFolder>
      <ul class="breadcrumb">
        <li>Documents</li>
      </ul>
    </ng-template> -->

  <div class="results-pane-title">
    <!-- Actions general menu button -->
    <button class="general-menu-action-button" mat-stroked-button color="primary" #topMenuTrigger="matMenuTrigger" (menuOpened)="actionsMenuOpened$.next()"
      [matMenuTriggerFor]="actionsMenu" [disabled]="selectedItems.length === 0 || data.pageRowsCount === 0">
      Actions
      <mat-icon class="notranslate" iconPositionEnd>expand_more</mat-icon>
    </button>

    <!-- actions menu options -->
    <mat-menu #actionsMenu="matMenu">
      <!-- Preview file -->
      <button mat-menu-item (click)="previewFile(selectedItems[0])" *ngIf="selectedItems?.length === 1 && selectedItems[0].storageObjectType === StorageObjectType.File &&
        selectedItems[0].filestackStatus === FilestackStatus.Ready ">
        <mat-icon class="notranslate">visibility</mat-icon>View
      </button>
      <!-- Share -->
      <button mat-menu-item (click)="share()" *ngIf="isAnyFileSelectedSharable">
        <mat-icon class="notranslate">group_add</mat-icon>Share
      </button>
      <!-- Edit permissions -->
      <button mat-menu-item (click)="setPermissions()" *ngIf="isAnyFileSelectedSharable">
        <mat-icon class="notranslate">group</mat-icon>Manage permissions
      </button>
      <!-- Rename file -->
      <button mat-menu-item (click)="rename(selectedItems[0])" *ngIf="selectedItems?.length === 1">
        <mat-icon class="notranslate">edit</mat-icon>Rename
      </button>
      <!-- Move to folder -->
      <button mat-menu-item [matMenuTriggerFor]="panel.menu" *ngIf="isSelectedItemsMovable$ | async">
        <mat-icon class="notranslate">folder</mat-icon>
        <span>Move to </span>
      </button>
      <!-- Download (can only download a file, not folders, and one at a time for now) -->
      <button mat-menu-item (click)="download(selectedItems[0])" *ngIf="selectedItems?.length === 1 &&
          selectedItems[0].storageObjectType === StorageObjectType.File  && selectedItems[0].filestackStatus === FilestackStatus.Ready">
        <mat-icon class="notranslate">get_app</mat-icon>Download
      </button>
      <!-- Delete files -->
      <button mat-menu-item (click)="delete()" *ngIf="selectedItems?.length > 0">
        <mat-icon class="notranslate">delete</mat-icon>Delete
      </button>
    </mat-menu>

    <button type="button" mat-button *ngIf="selectedItems.length > 1" (click)="clearSelection()" class="clear-selection-button">
      Clear selection
    </button>

    <div class="search">
      <terra-search-field
            placeholder="Search by file name or Investing Entity name"
            (filterChange)="filterChanged($event)"
            (clearSearch)="clearSearch()"
            [(filter)]="filteringText"
          ></terra-search-field>
    </div>

    <!-- Number of files / folders label -->
    <span class="showing-files-folder-label" *ngIf="data.pageRowsCount > 0">
      <ng-container *ngIf="data.displayedFolderCount && data.displayedFilesCount else onlyFilesOrOnlyFolder">
        Showing {{ data.displayedFolderCount | terraNumber }} folder(s) and {{ data.displayedFilesCount | terraNumber}} file(s)
      </ng-container>
      <ng-template #onlyFilesOrOnlyFolder>
        <ng-container *ngIf="data.displayedFolderCount">Showing {{ data.displayedFolderCount | terraNumber }} folder(s)</ng-container>
        <ng-container *ngIf="data.displayedFilesCount">Showing {{ data.displayedFilesCount | terraNumber }} file(s)</ng-container>
      </ng-template>
    </span>
  </div>

  <!-- Documents table  -->
  <table mat-table [dataSource]="dataSource" [trackBy]="trackByFunction" matSort matSortDisableClear (matSortChange)="sortData($event)"
    [matSortDirection]="(searchOptions$ | async).sortOrder" [matSortActive]="(searchOptions$ | async).orderBy"
    class="documets-table cov-basic-table gray-theme mat-elevation-z0">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="gray-theme checkbox-col-header">
        <mat-checkbox (change)="$event ? masterToggle() : null" [indeterminate]="selection.hasValue() && !(allSelected$ | async)"
          [checked]="selection.hasValue() && (allSelected$ | async)" [disabled]="data.pageRowsCount === 0" color="primary">
        </mat-checkbox>
      </th>
      <td mat-cell class="gray-theme" *matCellDef="let row" (click)="$event.stopPropagation(); rowCheckToggle(row)">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? rowCheckToggle(row) : null" [checked]="isRowChecked(row)"
          color="accent">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Icon Column -->
    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef class="icon-col-header"></th>
      <td mat-cell *matCellDef="let element" class="icon-col-value">
        <mat-icon *ngIf="element.storageObjectType === StorageObjectType.File" class="file-icon notranslate"
          [svgIcon]="element.metaFileLink | mediaFileIcon">
        </mat-icon>
        <mat-icon *ngIf="element.storageObjectType === StorageObjectType.Directory" class="folder-icon notranslate">
          folder
        </mat-icon>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="DisplayName" class="name-col-header">Name</th>
      <td mat-cell *matCellDef="let element" class="name-col-value lo_sensitive">
        <span [title]="element.displayName.length > 45 ? element.displayName : ''">{{ element.displayName | truncate: 45 }}</span>
        <span class="extension" *ngIf="element.storageObjectType === StorageObjectType.File">.{{element.extension}}</span>
        <span *ngIf="element?.metaFileLink?.sizeB"> ({{ element.metaFileLink.sizeB | fileSize : 2 }})</span>
        <span *ngIf="element.filestackStatus === FilestackStatus.Processing" class="file-processing">
          <mat-icon class="access_time notranslate">
            access_time
          </mat-icon> processing
        </span>
      </td>
    </ng-container>

    <!-- Shared With Column -->
    <ng-container matColumnDef="sharedWith">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="Sharing"  class="tags-col-header">Shared with</th>
      <td mat-cell *matCellDef="let element" class="tags-column-content">
        <span *ngIf="element.storageObjectType == StorageObjectType.File && element.isPublic">All investors</span>
        <ng-container *ngIf="element.storageObjectType === StorageObjectType.File && !element.isPublic">
          <ng-container *ngIf="getPermittedContacts(element) | async as contacts; else shareTempl">
            <!-- Single contact -->
            <span *ngIf="contacts && contacts.length === 1" class="lo_sensitive">
              {{ contacts[0].name }}
            </span>
            <!-- Multiple contacts -->
            <span *ngIf="contacts && contacts.length > 1" matTooltipClass="terra-tooltip" [matTooltip]="getInvestingEntityNamesString(contacts)">
              {{ contacts.length | terraNumber}} investors
            </span>
          </ng-container>
          <ng-template #shareTempl>
            <button mat-stroked-button color="primary" class="share-small-btn" *ngIf="element.filestackStatus === FilestackStatus.Ready" (click)="share(element);" >
              <mat-icon class="notranslate">share</mat-icon>  Share
            </button>
          </ng-template>
        </ng-container>


        <!--  -->
      </td>
    </ng-container>

    <!-- createTimestamp Column -->
    <ng-container matColumnDef="createTimestamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="CreateTimestamp" class="create-timestamp-col-header">Date Added</th>
      <td mat-cell *matCellDef="let element" class="create-timestamp-col-value">
        {{ element.createTimestamp | date:'MMM d, y, h:mm:ss a' }}
      </td>
    </ng-container>

    <!-- actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="actions-col-header"></th>
      <td mat-cell *matCellDef="let element" class="actions-col-value">
        <button mat-icon-button aria-label="menu" (click)="openSideMenu($event, element)">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <!-- Document row  -->
    <tr mat-row class="values-row" [class.hide]="data.isLoading" *matRowDef="let row; columns: displayedColumns"
      [class.selected-row]="isRowChecked(row)" (click)="rowCheckToggle(row, true, $event)"
      (dblclick)="previewFile(row)" >
      <!-- (contextmenu)="openMenu($event, row)" context Menu moved to side menu" -->
      <!-- THE ACTUAL MENU TRIGGER IS BELOW -->
    </tr>
  </table>

  <!-- This is the trigger for the context menu (on table rows).
    By setting this element as the trigger, and not the row which the user clicked, we can control the position of the menu.
    We just move this hidden element, and the menu will open at this place -->
  <div #rowMenuTrigger="matMenuTrigger" style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="rootMenu" (menuOpened)="actionsMenuOpened$.next()">
  </div>

  <terra-loader *ngIf="data.isLoading"></terra-loader>

  <div class="empty-list-message" *ngIf="!data.isLoading && data.pageRowsCount == 0 && showMessageWhenListIsEmpty">
    <p>No files or folders here. Use the Upload button.</p>
  </div>

  <!-- Quota  -->
  <div class="below-table-details">
    <!-- Quota box -->
    <ng-container *ngIf="quota$ | async as quota">
      <!-- Show only if about to reach limit -->
      <div class="quota-box">

        <!-- Files limit -->
        <terra-files-limit [value]="quota.currentStorageFilesCount" [max]="quota.maxStorageFilesCount" link="//www.covercy.com/contact-us"></terra-files-limit>

        <!-- OLD Storage meter -->
        <!-- Files count -->
        <!-- Add more space (when file count or storage reaches 85%) -->

        <!-- <ng-container *ngIf="(quota.quotaUsedMB / quota.maxStorageQuotaMB) * 100 > storageAboutToBeFullPercents">
          <mat-progress-bar mode="determinate" [value]="quota.quotaUsedMB / quota.maxStorageQuotaMB * 100"
            [color]="quota.quotaUsedMB / quota.maxStorageQuotaMB * 100 < storageAboutToBeFullPercents ? 'accent' : 'warn'"
            class="custom-progress-bar">
          </mat-progress-bar>
          <span class="used-space">{{quota.quotaUsedMB | terraNumber}} MB
            ({{quota.quotaUsedMB / quota.maxStorageQuotaMB * 100 | number: '1.0-2'}}%)</span>
          <span class="storage-space"> Total space: {{quota.maxStorageQuotaMB | terraNumber }} MB</span>
        </ng-container>
        <span class="files-used-description"
          *ngIf="(quota.currentStorageFilesCount / quota.maxStorageFilesCount) * 100 > storageAboutToBeFullPercents">
          You have used {{quota.currentStorageFilesCount}} out of {{quota.maxStorageFilesCount}} files limit in your current plan.
        </span>
        <span class="upgrade-text full-width" *ngIf="(quota.quotaUsedMB / quota.maxStorageQuotaMB) * 100 > storageAboutToBeFullPercents ||
            (quota.currentStorageFilesCount / quota.maxStorageFilesCount) * 100 > storageAboutToBeFullPercents">
          To increase your quota, <a target="_blank" class="light link" href="//www.covercy.com/contact-us">contact your Covercy Account
            manager</a>
        </span> -->
      </div>
    </ng-container>
  </div>
</ng-container>


<!-- Context menu -->

<!--  [overlapTrigger]="false" -->
<mat-menu #rootMenu="matMenu">
  <ng-template matMenuContent let-element="element">
    <!-- Preview file -->
    <button mat-menu-item (click)="previewFile(selectedItems[0])" *ngIf="selectedItems?.length === 1 && selectedItems[0].storageObjectType === StorageObjectType.File &&
    selectedItems[0].filestackStatus === FilestackStatus.Ready">
      <mat-icon class="notranslate">visibility</mat-icon><span>View</span>
    </button>
    <!-- Share -->
    <button mat-menu-item (click)="share()" *ngIf="isAnyFileSelectedSharable">
      <mat-icon class="notranslate">group_add</mat-icon><span>Share</span>
    </button>
    <!-- remove permissions -->
    <button mat-menu-item (click)="setPermissions()" *ngIf="isAnyFileSelectedSharable">
      <mat-icon class="notranslate">group</mat-icon><span>Manage permissions</span>
    </button>
    <!-- Rename file -->
    <button mat-menu-item (click)="rename(selectedItems[0])" *ngIf="selectedItems.length === 1">
      <mat-icon class="notranslate">edit</mat-icon><span>Rename</span>
    </button>
    <!-- Move to folder -->
    <button mat-menu-item [matMenuTriggerFor]="panel.menu" *ngIf="isSelectedItemsMovable$ | async">
      <mat-icon class="notranslate">folder</mat-icon><span>Move to </span>
    </button>
    <!-- Download (can only download a file, not folders, and one at a time for now) -->
    <button mat-menu-item (click)="download(element)" *ngIf="selectedItems.length === 1 && selectedItems[0].storageObjectType === StorageObjectType.File &&
      selectedItems[0].filestackStatus === FilestackStatus.Ready">
      <mat-icon class="notranslate">get_app</mat-icon><span>Download</span>
    </button>
    <!-- Delete files -->
    <button mat-menu-item (click)="delete()">
      <mat-icon class="notranslate">delete</mat-icon><span>Delete</span>
    </button>
  </ng-template>
</mat-menu>

<terra-folder-menu-panel #panel [items]="allFoldersTree$ | async" [itemIdsToMove]="selectedItemIds" [itemIdsToHide]="selectedItemIds">
</terra-folder-menu-panel>
