<terra-dialog-header>
  <h1>Update status to Under Management</h1>
</terra-dialog-header>

<div class="content-wrapper" [ngClass]="{ 'loading': (processingSubmit$ | async)  }">
  <terra-loader class="page-loader" *ngIf="(processingSubmit$ | async)"></terra-loader>
  <mat-dialog-content class="main-content">
    <p class="info-message">
      Once in Under Management status, you cannot go back to Fundraising status
    </p>

    <p class="holding-down-stripe full-width" *ngIf="context.fundDetails.isPrivate">
      When the fund mode is down, it will not be available on Investor Portal, and investors will not receive an update about the status
      change.
    </p>

    <form id="dialogForm" [formGroup]="pageForm" class="form-grid-new terra-error-style border-bottom"
          (keydown.enter)="$event.preventDefault()">
      <h3 class="section-title">
        Please fill in the following information before updating the fund’s status. <br />
        This information will be shared with your investors.
      </h3>

      <h3 class="section-title">Fund details</h3>

      <!-- Initial closing date -->
      <div class="terra-form-field-wrapper">
        <terra-custom-utc-date-picker id="txtClosingDate" formControlName="finalClosingDate" label="Initial closing date"
          [requiredError]="pageForm.get('finalClosingDate').hasError('required')"
          [touched]="pageForm.get('finalClosingDate').touched"></terra-custom-utc-date-picker>
      </div>

      <!-- Total capitalization -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalCapitalization" class="text-input-label">Total capitalization</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtTotalCapitalization" formControlName="totalCapitalization" terraFormattedNumber
                 autocomplete="total-capitalization" class="lo_sensitive" />
          <span matSuffix>{{ selectedCurrency?.iso }}</span>
          <mat-error *ngIf="pageForm.get('totalCapitalization').hasError('required')"> Required </mat-error>
          <mat-error *ngIf="pageForm.get('totalCapitalization').hasError('minFormattedNumber')">Must be a greater than 0</mat-error>
          <mat-hint>Total investors equity + loan</mat-hint>
        </mat-form-field>
      </div>

      <!-- Total Investor Equity  -->
      <div class="terra-form-field-wrapper">
        <label for="txtTotalInvestorEquity" class="text-input-label">Total investor equity</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtTotalInvestorEquity" terraFormattedNumber formControlName="totalInvestorEquity"
                 class="lo_sensitive" />
          <span matSuffix>{{ selectedCurrency?.iso }}</span>
          <mat-error *ngIf="pageForm.get('totalInvestorEquity').hasError('required')"> Required </mat-error>
          <mat-error *ngIf="pageForm.get('totalInvestorEquity').hasError('minFormattedNumber')">Must be a greater than 0</mat-error>
        </mat-form-field>
      </div>

      <!-- % of GP equity from total equity (optional)  -->
      <div class="terra-form-field-wrapper">
        <label for="txtGpEquityFromTotalEquity" class="text-input-label">% of GP equity from total equity (optional)</label>
        <mat-form-field appearance="outline">
          <input type="tel" matInput id="txtGpEquityFromTotalEquity" terraFormattedNumber formControlName="gpEquityFromTotalEquity"
                 class="lo_sensitive" />
        </mat-form-field>
      </div>

      <!-- Lender information -->
      <h3 class="section-title border-top">Loan information (optional)</h3>

      <ng-container formGroupName="lenderInformation">
        <!-- Lender Name -->
        <div class="terra-form-field-wrapper">
          <label for="txtLenderName" class="text-input-label">Lender name</label>
          <mat-form-field appearance="outline">
            <input matInput id="txtLenderName" formControlName="lenderName" autocomplete="lender-name" class="lo_sensitive" />
          </mat-form-field>
        </div>

        <!-- Amount -->
        <div class="terra-form-field-wrapper">
          <label for="txtLenderAmount" class="text-input-label">Amount</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtLenderAmount" terraFormattedNumber formControlName="amount" class="lo_sensitive" />
            <span matSuffix>{{ selectedCurrency?.iso }}</span>
          </mat-form-field>
        </div>

        <!-- Loan type -->
        <div class="terra-form-field-wrapper">
          <label for="ddlLoanType" class="text-input-label">Loan type</label>
          <mat-form-field appearance="outline">
            <mat-select id="ddlLoanType" formControlName="loanType" placeholder="Select">
              <mat-option [value]="null">Select</mat-option>
              <mat-option *ngFor="let item of LoanTypesList" [value]="item">{{ item | enumString: LoanType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Loan to value -->
        <div class="terra-form-field-wrapper">
          <label for="txtLoanToValue" class="text-input-label">
            Loan to {{ selectedInvestmentStrategyType === FundInvestmentStrategyType.Opportunistic ? 'cost' : 'value'}} %</label>
          <mat-form-field appearance="outline">
            <input type="tel" matInput id="txtLoanToValue" terraFormattedNumber formControlName="loanToValue" class="lo_sensitive" />
          </mat-form-field>
        </div>

        <!-- Interest and Interest Type -->
        <div class="terra-form-field-wrapper interest-with-type">
          <!-- Interest Rate -->
          <label for="txtInterestRate" class="interest-label text-input-label">
            Interest rate %
          </label>
          <mat-form-field class="interest-field-wrapper" appearance="outline">
            <input type="tel" matInput id="txtInterestRate" terraFormattedNumber formControlName="interestRate"
                   class="lo_sensitive" />
          </mat-form-field>
          <!-- Interest Type -->
          <label for="ddlInterestType" class="interest-type-label text-input-label">
            Interest type
          </label>
          <mat-form-field appearance="outline" class="interest-type-ddl-wrapper">
            <mat-select id="ddlInterestType" placeholder="Select" formControlName="interestType">
              <mat-option *ngFor="let item of InterestTypesList" [value]="item">{{ item | enumString: InterestType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Closing date -->
        <div class="terra-form-field-wrapper">
          <label for="txtClosingDate" class="text-input-label">Closing date</label>
          <mat-form-field appearance="outline">
            <!-- Allow past date to support retroactive creation of funds -->
            <input matInput [min]="closingDateMinimumDate" id="txtClosingDate" [matDatepicker]="closingDateDatePickerPU"
                   autocomplete="bday" formControlName="closingDate" (focus)="closingDateDatePickerPU.open()" class="lo_sensitive" />
            <mat-datepicker-toggle matSuffix [for]="closingDateDatePickerPU"></mat-datepicker-toggle>
            <mat-datepicker #closingDateDatePickerPU [startAt]="closingDateStartAt"></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Maturity date -->
        <div class="terra-form-field-wrapper">
          <label for="txtMaturityDate" class="text-input-label">Maturity date</label>
          <mat-form-field appearance="outline">
            <!-- Allow past date to support retroactive creation of funds -->
            <input matInput [min]="maturityDateMinimumDate" id="txtMaturityDate" [matDatepicker]="maturityDateDatePickerPU"
                   autocomplete="bday" formControlName="maturityDate" (focus)="maturityDateDatePickerPU.open()" class="lo_sensitive" />
            <mat-datepicker-toggle matSuffix [for]="maturityDateDatePickerPU"></mat-datepicker-toggle>
            <mat-datepicker #maturityDateDatePickerPU [startAt]="maturityDateStartAt"></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Additional Term -->
        <div class="terra-form-field-wrapper full-width">
          <label for="txtAdditionalTerms" class="text-input-label">Additional Terms</label>
          <p class="field-description">
            Share the loan additional terms here
          </p>
          <quill-editor id="txtAdditionalTerms" formControlName="additionalTerms" format="html" class="lo_sensitive" placeholder="" />

          <mat-error *ngIf="pageForm.get('lenderInformation.additionalTerms').hasError('maxlength')"> You have exceeded the maximum length of {{
            pageForm.get('lenderInformation.additionalTerms').getError('maxlength').requiredLength | terraNumber
            }} characters </mat-error>
        </div>

      </ng-container>
    </form>
  </mat-dialog-content>
</div>

<mat-dialog-actions>
  <button *ngIf="!context.fundDetails.isPrivate" (click)="save(true)" type="button" form="dialogForm" [disabled]="processingSubmit$ | async" mat-flat-button
          color="accent" class="page-cta">
    Update Status and Notify Investors
    <mat-icon svgIcon="publish" class="notranslate publish-icon"></mat-icon>
  </button>
  <button  type="button" (click)="save(false)" form="dialogForm" [disabled]="processingSubmit$ | async" mat-flat-button
          color="primary" class="page-cta">
    Update Status
  </button>
  <button type="button"  mat-button class="cancel-button tall-button" [disabled]="processingSubmit$ | async"
          [mat-dialog-close]="undefined">Cancel</button>
  <!-- Error message -->
  <div class="full-width" *ngIf="attemptSubmit">
    <p *ngIf="pageForm.invalid" class="error-message">
      Please review and correct the fields marked in red
    </p>
    <p *ngIf="pageForm.valid && isGeneralServerError" #serverError class="error-message">
      <span [innerHTML]="generalServerErrorMessage"></span></p>
  </div>
</mat-dialog-actions>
