import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';

import { UserService } from '../services/shared/user.service';
import { RoutingService } from '../services/routing.service';

@Injectable({
  providedIn: 'root'
})
export class GpOnboardingPassedGuard  {

  constructor(private userService: UserService, private router: Router, private routingService: RoutingService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean |
    UrlTree |
    Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree> {

    // Make a call that equires 2-factor to complete successfully. This will trigger the interceptor that will redirect the user to the 2 factor page
    return this.userService.twoFactorPing().pipe(
      switchMap(() => this.userService.accountDetails$),
      map(clientDetails => {
        // Prevent clients who already completed the onboarding process, or or don't need to fill it, to access it again
        if (clientDetails.isClientFilledPersonalDetails || clientDetails.organizationDetails.ownerClientId !== clientDetails.id) {
          return this.router.parseUrl(this.routingService.defaultLoggedInPageBySubdomain);
        }
        return true;
      })
    );
  }
}
