import MergeUnitClientBankAccountType from '../enums/MergeUnitClientBankAccountType.enum';
import UnitApplicationStatus from '../enums/unit-bank-account/UnitApplicationStatus.enum';
import {CurrencyModel} from './CurrencyModel';
import {ClientBankAccountReqRes} from '../../dashboard/models/bankAccount.model';
import { PaymentType } from '../enums/payment-type.enum';


export class MergeUnitClientBankAccountDto {
  public id: number;
  public clientDetailsId: number;
  public accountType: MergeUnitClientBankAccountType;
  public accountNickname: string;
  public applicationName: string;
  public holderFullName: string;
  public bankName: string;
  public accountNumber: string;
  public iban: string;
  public iso: string;
  public unitBalance: number;
  public unitStatus: UnitApplicationStatus;
  public isApplication: boolean;
  public holderLocationDetails_Id: number;
  public preferredCurrencyId: number;
  public preferredCurrency: CurrencyModel;
  public countryName: string;
  public isDefault: boolean;
  public paymentType: PaymentType;
  public swift: string;

  constructor(clientBankAccount: ClientBankAccountReqRes) {
    this.id = clientBankAccount.id;
    this.clientDetailsId = clientBankAccount.clientDetailsId;
    this.accountType = MergeUnitClientBankAccountType.ExternalAccount;
    this.accountNickname = clientBankAccount.nickname;
    this.holderFullName = clientBankAccount.holderFullName;
    this.bankName = clientBankAccount.bankName;
    this.accountNumber = clientBankAccount.accountNumber;
    this.iban = clientBankAccount.iban;
    this.iso = clientBankAccount.preferredCurrency?.iso;
    this.holderLocationDetails_Id = clientBankAccount.holderLocationDetails_Id;
    this.preferredCurrencyId = clientBankAccount.preferredCurrencyId;
    this.preferredCurrency = clientBankAccount.preferredCurrency;
    this.countryName = clientBankAccount.country?.name;
    this.paymentType = clientBankAccount.paymentType;
    this.swift = clientBankAccount.swift;
    this.iban = clientBankAccount.iban;
  }
}
