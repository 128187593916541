import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Injectable} from '@angular/core';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {StorageService} from '../services/storage.service';
import {RoutingService} from '../services/routing.service';
import {UserService} from '../services/shared/user.service';
import {AppSettingsService} from '../services/app-settings.service';
import {ApproveTermsAndConditionsDialogComponent, ApproveTermsAndConditionsParams} from '../shared/components/approve-terms-and-conditions/approve-terms-and-conditions-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsGuard extends OnDestroyMixin  {
  private static isModalOpen = false;

  constructor(
    private appSettingsService: AppSettingsService,
    private routingService: RoutingService,
    private storageService: StorageService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    super();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const termsRetryApprove = this.storageService.getTermsApproveRetry();
    const termsToVerify = this.storageService.getTermsData();

    if (termsRetryApprove) {
      // silently approve terms
      return this.userService.updateTermsAndConditions().pipe(
        take(1),
        map(res => {
          if (res) {
            this.storageService.removeTermsApproveRetry();
          }
          return res;
        })
      );
    } else if (termsToVerify?.length && !TermsAndConditionsGuard.isModalOpen) {
      TermsAndConditionsGuard.isModalOpen = true;
      const config = new MatDialogConfig<ApproveTermsAndConditionsParams>();
      config.disableClose = true;
      config.closeOnNavigation = true;
      config.data = new ApproveTermsAndConditionsParams(termsToVerify);

      return this.dialog.open(ApproveTermsAndConditionsDialogComponent, config)
        .afterClosed()
        .pipe(
          untilComponentDestroyed(this),
          take(1),
          map(res => {
            return res;
          })
        );
    } else {
      return true;
    }

// this.userService.accountDetails$.pipe(
//   take(1)
// ).subscribe(accountDetails => {
//   const newTermsAvailable = !accountDetails.termsVersion?.toLowerCase().includes(this.appSettingsService.termsVersion?.toLowerCase());
//   if (newTermsAvailable && !TermsAndConditionsGuard.isModalOpen) {
//     const config = new MatDialogConfig();
//     config.disableClose = true;
//     config.closeOnNavigation = true;
//     TermsAndConditionsGuard.isModalOpen = true;
//     this.dialog.open(ApproveTermsAndConditionsComponent, config)
//       .afterClosed()
//       .pipe(
//         take(1),
//         switchMap(_ => {
//           return this.accountService.approveTermsAndConditions().pipe(take(1));
//         })
//       ).subscribe();
//   }
// });

  }
}
