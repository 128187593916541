import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {EMPTY, merge, Observable} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {Sort} from '@angular/material/sort';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {debounceTime, map, shareReplay, switchMap, switchMapTo, take, tap} from 'rxjs/operators';
import {SelectionModel} from '@angular/cdk/collections';
import {PageEvent} from '@angular/material/paginator';

import {InvestmentReqRes} from '../../../../../../models/investment.model';
import {EngagementTableService} from './engagement-table.service';
import {EngagementDataSource} from './engagementDataSource';
import {GpInvestmentDataService} from '../../../../../../../services/gp/gp-investment-data.service';
import {GpHoldingService} from '../../../../gp-holding.service';
import {LpInterest} from '../../../../../../models/lpInterest.enum';
import InvestmentStatus from '../../../../../../../shared/enums/InvestmentStatus.enum';
import {SnackbarService} from '../../../../../../../services/snackbar.service';
import {BaseResponseDto} from '../../../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../../../services/utils.service';

@Component({
  selector: 'terra-engagement-display-table',
  templateUrl: './engagement-display-table.component.html',
  styleUrls: ['./engagement-display-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EngagementDisplayTableComponent extends OnDestroyMixin implements OnInit, AfterViewInit {
  lpInterest = LpInterest;
  investmentStatus = InvestmentStatus;

  displayedColumns: string[] = ['select', 'name', 'amount', 'last-viewed', 'reply', 'message', 'next-step'];
  itemsPerPageOptions = [25, 50, 100, 200, 500];
  perPageControl = new UntypedFormControl(this.itemsPerPageOptions[0]);
  dataSource = new EngagementDataSource(this.engagementTableService, this.investmentDataService);

  searchOptions$ = this.engagementTableService.searchOptions$;
  perPageChanged$: Observable<number> = this.perPageControl.valueChanges.pipe(untilComponentDestroyed(this), debounceTime(200));
  pageRowsCount$ = this.dataSource.pageRows$.pipe(map(rows => rows ? rows.length : 0), shareReplay(1));
  totalRowsCount$ = this.dataSource.totalRowsCount$;
  isLoading$ = this.dataSource.isLoading$;
  selection = new SelectionModel<InvestmentReqRes>(true, []);
  isAllSelected$ = merge(this.selection.changed, this.pageRowsCount$).pipe(
    switchMapTo(this.pageRowsCount$),
    map(pageRowsCount => pageRowsCount === this.selection.selected.length),
    shareReplay(1));

  get selectedInvestors() {
    if (!this.selection) {
      return [];
    }
    return this.selection.selected;
  }

  constructor(private engagementTableService: EngagementTableService,
    private investmentDataService: GpInvestmentDataService,
    private snackbarService: SnackbarService,
    private holdingService: GpHoldingService,
    private utilsService: UtilsService) {
    super();
  }

  ngOnInit() {
    this.engagementTableService.updatePageSize(this.itemsPerPageOptions[0]);

    this.perPageChanged$.pipe(untilComponentDestroyed(this)).subscribe(
      pageSize => {
        this.engagementTableService.updatePageSize(pageSize);
      });

    this.dataSource.pageRows$.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.clearSelection();
    });

    this.holdingService.holdingId$.pipe(take(1)).subscribe(holdingId => {
      this.holdingService.fundraising$.pipe(take(1)).subscribe(fundraising => {
        this.engagementTableService.updateFundraisingId(fundraising.id);
        this.engagementTableService.updateHoldingId(holdingId);
      });
    });
  }

  ngAfterViewInit(): void {
    this.engagementTableService.refresh();
  }

  public trackByFunction(index: number, item: InvestmentReqRes) {
    return item.id;
  }

  isRowChecked(row: InvestmentReqRes) {
    return this.selection.selected.some(x => x.id === row.id);
  }

  masterToggle() {
    this.isAllSelected$.pipe(
      take(1),
      switchMap(isAllSelected => {
        if (isAllSelected) {
          this.selection.clear();
          return EMPTY;
        } else {
          return this.dataSource.pageRows$;
        }
      }),
      tap(rowsToSelect => {
        this.selection.select(...rowsToSelect);
      }),
      take(1)
    ).subscribe();
  }

  pageChange(event: PageEvent) {
    this.engagementTableService.updatePageNumber(event.pageIndex);
  }

  rowCheckToggle(row: InvestmentReqRes, unselectOthers = false, event = null) {
    if (event && (event.ctrlKey || event.metaKey)) {
      unselectOthers = false;
    }

    const isRowChecked = this.isRowChecked(row);
    const currentNumberOfSelectedItems = this.selectedInvestors.length;

    if (unselectOthers) {
      this.clearSelection();
    }

    if (isRowChecked && (currentNumberOfSelectedItems === 1 || !unselectOthers)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  private clearSelection() {
    this.selection.clear();
  }

  updateStatus(investment: InvestmentReqRes, newStatus: InvestmentStatus) {
    this.holdingService.holdingId$.pipe(take(1)).subscribe(holdingId => {
      this.investmentDataService.updateStatus(holdingId, investment.fundraisingId, investment.id, newStatus)
        .pipe(take(1))
        .subscribe(updatedInvestment => {
          this.engagementTableService.refresh();
          if (newStatus === InvestmentStatus.Potential) {
            this.snackbarService.showGeneralMessage('Investor added');
          } else if (newStatus === InvestmentStatus.SoftCircled) {
            this.snackbarService.showGeneralMessage('Investor added');
          }
        },
          error => {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            }
          });
    });
  }

  sortData(sort: Sort) {
    if (!sort.active || !sort.direction) {
      return;
    }
    this.engagementTableService.sort(sort.active, sort.direction);
  }
}
