<ng-container *ngIf="fund$ | async as fund">
  <terra-under-management-information class="container"></terra-under-management-information>

  <mat-card class="container tabs-card">

    <nav class="tabs-navigation" mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false" mat-align-tabs="start">
      <!-- Overview tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Overview].url" routerLinkActive #rla1="routerLinkActive"
        [active]="rla1.isActive">
        {{tabsData[Tab.Overview].label}}
      </a>
      <!-- Assets tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Portfolio].url" routerLinkActive #rla2="routerLinkActive"
        [active]="rla2.isActive">
        {{tabsData[Tab.Portfolio].label}}
      </a>
      <!-- Reports tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Reports].url" routerLinkActive #rla3="routerLinkActive"
        [active]="rla3.isActive">
        {{tabsData[Tab.Reports].label}}
      </a>
      <!-- Documents tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Documents].url" routerLinkActive #rla4="routerLinkActive"
        [active]="rla4.isActive">
        {{tabsData[Tab.Documents].label}}
      </a>
      <!-- Fundraisings tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Fundraisings].url" routerLinkActive #rla5="routerLinkActive"
        [active]="rla5.isActive">
        {{tabsData[Tab.Fundraisings].label}}
      </a>
      <!-- Distributions tab -->
      <a mat-tab-link [routerLink]="tabsData[Tab.Distributions].url" routerLinkActive #rla6="routerLinkActive"
        [active]="rla6.isActive">
        {{tabsData[Tab.Distributions].label}}
      </a>

      <a mat-tab-link [routerLink]="tabsData[Tab.Transactions].url" routerLinkActive #rla7="routerLinkActive"
        [active]="rla7.isActive" *ngIf="creTransactionsTabVisible$ | async">
        {{tabsData[Tab.Transactions].label}}
      </a>
    </nav>

    <!-- Tabs content  -->
    <div class="tabs-card-inner">
      <mat-tab-nav-panel #tabPanel>
        <router-outlet />
      </mat-tab-nav-panel>
    </div>
  </mat-card>
</ng-container>
