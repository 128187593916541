import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import * as CustomEditor from 'src/plugins/ckeditor/ckeditor';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {TerraUtils} from 'src/app/shared/TerraUtils';
import {InvestmentType} from 'src/app/shared/enums/InvestmentType.enum';
import {StartInitialFundraisigStepNumber, StartInitialFundraisingService} from '../start-initial-fundraising.service';
import {StartInitialFundraisingStepBase} from '../StartInitialFundraisingStepBaseAndInterface';
import InvestmentSecurityType from 'src/app/shared/enums/InvestmentSecurityType.enum';
import {map, switchMap, shareReplay, filter, tap, withLatestFrom, catchError, startWith} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, of} from 'rxjs';
import {ClientBankAccountResponseBasic, ClientBankAccountReqRes} from 'src/app/dashboard/models/bankAccount.model';
import {GpBankAccountDataService} from 'src/app/services/gp/gp-bank-account-data.service';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {ResourceService} from 'src/app/services/resource.service';
import {MatDialogConfig, MatDialogRef, MatDialog} from '@angular/material/dialog';
import {EditBankAccountComponent} from 'src/app/dashboard/bankAccounts/components/edit-bank-account/edit-bank-account.component';
import {EditBankAccountContext} from 'src/app/dashboard/bankAccounts/components/edit-bank-account/EditBankAccountContext.model';
import {BaseResponseStatus} from '../../../../../../shared/models/BaseResponseDto.model';
import {ErrorMatcher, ErrorType} from "../../../../../../shared/errors/ErrorMatcher";

@Component({
  selector: 'terra-start-fundraising-information-step',
  templateUrl: './start-fundraising-information-step.component.html',
  styleUrls: ['./start-fundraising-information-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartFundraisingInformationStepComponent extends StartInitialFundraisingStepBase implements OnInit {
  readonly STEP_NUMBER: StartInitialFundraisigStepNumber = 1;
  noAccessMessage = '';
  Editor = CustomEditor;

  // enum
  InvestmentType = InvestmentType;
  InvestmentSecurityType = InvestmentSecurityType;

  // Enum value lists
  InvestmentSecurityTypesList = InvestmentSecurityType.optionsForAssetFundraising();

  asset$ = this.startInitialFundraisingService.asset$;
  assetCurrency$ = this.asset$.pipe(map(asset => asset.initialCurrency));

  bankAccounts$ = this.assetCurrency$.pipe(
    switchMap(currency => {
      return this.gpBankAccountDataService.getListByTargetCurrency(currency.id);
    }),
    shareReplay(1),
    catchError(async err => {
      if (ErrorMatcher.isError(err, ErrorType.NoAccessToResourcePermission)) {
        this.noAccessMessage = err.responseMessage;
      }
      return new Array<ClientBankAccountResponseBasic>();
    })
  );

  newCreatedBankAccount = new BehaviorSubject<ClientBankAccountResponseBasic>(null);
  bankAccountOptions$ =
    combineLatest([this.bankAccounts$, this.newCreatedBankAccount]).pipe(
      map(([accounts, newAccount]) => {
        if (newAccount) {
          accounts.push(newAccount);
        }
        return accounts;
      }),
      shareReplay()
    );

  allowedDataPointsListFor = TerraUtils.consts.allowedDataPointsListFor;

  closingDateStartAt = new Date();
  closingDateMinimumDate = new Date('1970-01-01T12:00');

  get selectedSecurityType(): InvestmentSecurityType {
    return this.startInitialFundraisingService.selectedSecurityType;
  }

  constructor(
    private startInitialFundraisingService: StartInitialFundraisingService,
    private gpBankAccountDataService: GpBankAccountDataService,
    private resourceService: ResourceService,
    private dialog: MatDialog
  ) {
    super(startInitialFundraisingService);
  }

  ngOnInit() {
    this.isSubmitted = false;
    this.startInitialFundraisingService.currentStep$.next(this.STEP_NUMBER);
  }

  createNewBankAccount() {
    const dialogConfig = new MatDialogConfig<EditBankAccountContext>();
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'create-bank-account-dialog';

    const dialogRef: MatDialogRef<EditBankAccountComponent, ClientBankAccountResponseBasic> = this.dialog
      .open(EditBankAccountComponent, dialogConfig);

    dialogRef.afterClosed()
      .pipe(
        untilComponentDestroyed(this),
        filter(createdBankAccount => !!createdBankAccount),
        tap(newAccount => this.newCreatedBankAccount.next(newAccount)),
        withLatestFrom(this.bankAccounts$)
      )
      .subscribe(([createdBankAccount, bankAccounts]) => {
        // Update the list of bank account (to get only the supprted accounts for the asset currency), and select the created account if supported
        const bankAccountToSelect = bankAccounts.find(account => account.id === createdBankAccount.id);
        if (bankAccountToSelect) {
          this.pageForm.get('bankAccount').setValue(bankAccountToSelect);
        }
      });
  }


  bankAccountCompareFn(b1: ClientBankAccountReqRes, b2: ClientBankAccountReqRes) {
    return b1 && b2 ? b1.id === b2.id : b1 === b2;
  }


  getCountryById(conutryId: number): CountryModel {
    return this.resourceService.getCountryById(conutryId);
  }

  cancel() {
    this.startInitialFundraisingService.cancel();
  }
}



