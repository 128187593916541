<terra-main-nav />
<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav #sidenav class="sidenav"
    mode="over" position="end" 
    [fixedInViewport]="true" 
    [fixedTopGap]="0"
    [fixedBottomGap]="0"
    [opened]="false"
    [style.visibility]="sidenav.opened ? 'visible' : 'hidden'">
    <ng-template #placeholder></ng-template>
  </mat-sidenav>

  <mat-sidenav-content>
    <div *ngIf="disconnectedBankAccountsNumber$ | async as disconnectedNumber"
      class="flex flex-row justify-content-center align-items-center gap-4 p-4 reconnect-bank-accounts-message">
      <mat-icon>info_outline</mat-icon>
      <div class="message">
        <strong>{{disconnectedNumber}} Bank Accounts Disconnected:</strong>
        Please update your bank connection to access the latest transactions and balances.
        <span class="clickable" (click)="reconnectAction()" >Reconnect</span>
      </div>
    </div>
    <router-outlet />
  </mat-sidenav-content>
</mat-sidenav-container>
