import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DocumentsTabService} from '../documents-tab/documents-tab.service';

@Component({
  selector: 'terra-create-folder-dialog',
  templateUrl: './create-folder-dialog.component.html',
  styleUrls: ['./create-folder-dialog.component.scss']
})
export class CreateFolderDialogComponent implements OnInit {

  pageForm: UntypedFormGroup;
  isAttemptSubmit = false;

  constructor(
    public dialogRef: MatDialogRef<CreateFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private documentsTabService: DocumentsTabService) {
      dialogRef.addPanelClass('create-folder-dialog');
  }

  ngOnInit() {
    this.pageForm = this.fb.group({
      displayName: [null, [Validators.required, Validators.maxLength(this.documentsTabService.maxDisplayNameLength)]]
    });
  }

  save() {
    this.isAttemptSubmit = true;
    if (!this.pageForm.valid) {
      return;
    }
    this.dialogRef.close(this.pageForm.get('displayName').value);
  }
}
