<ng-template #loader>
  <terra-loader class="page-loader"></terra-loader>
</ng-template>

<ng-container *ngIf="fund$ | async as fund; else loader">
  <!-- Fund is down -->
  <div class="holding-down-page-stripe" *ngIf="fund.isPrivate && fund.status !== HoldingStatus.Canceled">
    <mat-icon svgIcon="holding_down" class="notranslate holding-down-icon"></mat-icon>
    <span> Fund is down, <a class="link" (click)="editFund(5)">click here</a> when you’re ready to put it back up.
      <a class="light link" (click)="fundDownInfo()"> Learn more</a></span>
  </div>
  <!-- Fund page header -->
  <div class="fund-title-area container">
    <div class="title-area-row">
      <h1 class="lo_sensitive">{{ fund.name | truncate: 45 }}</h1>
      <span class="status">{{ fund.status | enumString: HoldingStatus }}</span>
      <!-- Update status button -->
      <a class="link update-status-link" role="button" (click)="updateStatus()">Update status</a>

      <div class="actions">
        <button *ngIf="fund.status == HoldingStatus.Fundraising || fund.status == HoldingStatus.Owned"
                (click)="editFund()" [disabled]="fund.status === HoldingStatus.Canceled" mat-stroked-button
                class="edit-button" color="primary">
          <mat-icon class="notranslate">edit</mat-icon>
          Edit Fund
        </button>

        <a *ngIf="fund.status == HoldingStatus.Draft" [routerLink]="editDraftFundUrl$ | async"
           [disabled]="fund.status === HoldingStatus.Canceled" mat-stroked-button class="edit-button" color="primary">
          <mat-icon class="notranslate">edit</mat-icon>
          Edit Fund
        </a>

        <!-- More actions menu -->
        <button mat-icon-button *ngIf="fund.status === HoldingStatus.Draft || fund.isExample" color="primary"
                [matMenuTriggerFor]="moreActions" class="more-actions-toggle">
          <mat-icon class="notranslate">more_vert</mat-icon>
        </button>
        <mat-menu #moreActions="matMenu">
          <button mat-menu-item *ngIf="fund.status === HoldingStatus.Draft" (click)="deleteDraft()" class="delete-button">
            <mat-icon class="notranslate">delete_forever</mat-icon>
            Delete Fund
          </button>

          <button mat-menu-item *ngIf="fund.isExample" (click)="moveToArchive()"
                  class="delete-button">
            <mat-icon class="notranslate material-symbols-rounded">inventory_2</mat-icon>
            Archive Fund
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="title-area-row" *ngIf="showHoldingInfoLine(fund) | async">
      <ul class="fund-info-wrapper">
        <li class="fund-info-item">
          <div class="fund-info-item-inner">
            <!-- <mat-icon class="notranslate">home</mat-icon> -->
            <span class="fund-info-item-title">Number of assets/funds: <span class="fund-info-item-value">{{ fund.assets.length }}</span></span>
          </div>
        </li>

        <ng-container *ngIf="(fundraising$ | async) as fundraising">
          <li class="fund-info-item">
            <div class="fund-info-item-inner">
              <!-- <mat-icon class="notranslate">attach_money</mat-icon> -->
              <span class="fund-info-item-title">Bank Account:
                <span *ngIf="(showBankInfo$ | async) && getHoldingBankAccount(fundraising)" class="fund-info-item-value">
                  {{getHoldingBankAccount(fundraising) | bankAccountDisplayName :true}}
                </span>
                <span class="fund-info-item-value">{{!(showBankInfo$ | async) ? '*****' : (!getHoldingBankAccount(fundraising) ? 'Missing' : '')}}</span>
                <!-- <a *ngIf="!getHoldingBankAccount(fundraising)" (click)="editAsset(4)" class="asset-info-item-value">
                  Missing
                </a> -->
              </span>
            </div>
          </li>

          <li class="fund-info-item">
            <div class="fund-info-item-inner">
              <!-- <mat-icon class="notranslate">date_range</mat-icon> -->
              <span class="fund-info-item-title">Closing Date: <span class="fund-info-item-value">{{
                (fundraising.finalClosingDate ? fundraising.finalClosingDate : fundraising.estimatedClosingDate) | utcDate }}</span></span>
            </div>
          </li>
        </ng-container>
      </ul>

      <div class="addition-information-wrapper">
        <button mat-button class="account-info-button additional-info" [matMenuTriggerFor]="fundAdditionalInfoMenu" aria-label="Show asset additional info">
          Additional information
        </button>
        <mat-menu class="cre-account-info-panel" #fundAdditionalInfoMenu="matMenu" xPosition="after" yPosition="below">
          <div (click)="$event.stopPropagation()">
            <div class="info-content">
              <ul class="info-list">
                <li *ngIf="fund?.investmentStrategyType"><label>Investment Strategy:</label>
                  {{fund.investmentStrategyType | enumString: FundInvestmentStrategyType}}
                </li>
                <li>
                  <label>Total capitalization:</label>
                  {{(fundraising$ | async).fundraisingTargetCurrency.symbol}}{{fund.totalCapitalization | terraCurrency }}
                </li>
                <li><label>Organization: </label>{{(organizationDetails$ | async)?.name }}</li>
              </ul>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>

  <!-- Different pages per fund status -->
  <ng-container [ngSwitch]="fund.status">
    <terra-fund-draft-status *ngSwitchCase="HoldingStatus.Draft"></terra-fund-draft-status>
    <terra-fund-fundraising-status *ngSwitchCase="HoldingStatus.Fundraising"></terra-fund-fundraising-status>
    <terra-fund-management-status *ngSwitchCase="HoldingStatus.Owned"></terra-fund-management-status>
    <terra-fund-management-status *ngSwitchCase="HoldingStatus.Realized"></terra-fund-management-status>
  </ng-container>
</ng-container>
